import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import NoImage from "../../../assets/images/House.png";
import useRequestGet from '../../../hooks/useRequestGet'
import { getActivityDetails } from '../../../constants/axios/apis'

import { formatDateTimeMeridiem } from '../../../constants/generics/dateTimeFormater';
import { ImageViewerWithPopup } from '../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../constants/generics/userCheck';
import { CloseActivity } from './CloseActivity';
import { LoadingErrorAlert } from '../../../constants/generics/customAlerts';

import { useTranslation } from 'react-i18next';
import { LinkedIncidentDetails } from './LinkedIncidentDetails';

export function ActivityDetails({setPopupSuccessMsg}:any) {
    const { t } = useTranslation();
    const history = useHistory();
    
    let params: any = {};
    params = useLocation();
    const { activityData } = params.state;
    
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    const [activityDetails, setActivityDetails] = useState<any>('');
    const [outcomeOptions, setOutcomeOptions] = useState<any>(['']);
    const [closeReasonOption, setCloseReasonOption] = useState<any>(['']);
    const [isLoading, setIsLoading] = useState<any>({
        reasons: true,
        details: true,
    })

    const isClosed = activityDetails?.status === "closed" ? true : false; 
    const {requestState:masterData, refreshRequest: refreshMasterData} = useRequestGet("/getMasterData");
    useEffect(()=>{
        if(Array.isArray(masterData.response)){
            if(masterData.response.length > 0){
                const outcomeVal = masterData.response.find((el:any)=>el.category_name === "outcome");
                setOutcomeOptions(outcomeVal?.master_data ? outcomeVal.master_data : []);

                const closeReasonVal = masterData.response.find((el:any)=>el.category_name === "activity_closed_reason");
                setCloseReasonOption(closeReasonVal?.master_data ? closeReasonVal.master_data : []);
            }
        }
        setIsLoading((oState: any) => ({...oState, reasons: false}))
    },[masterData]);

    useEffect(()=>{
        getActivityDetails(activityData?.id).then((response:any) => {
            if(response.errorCode === 0 && response?.data){
                setActivityDetails(response.data)
            }
            setIsLoading((oState: any) => ({...oState, details: false}))
        }).catch((e) => {setIsLoading((oState: any) => ({...oState, details: false}))})
    },[activityData]);

    const outcomeData = outcomeOptions.find((el:any)=> activityDetails?.outcome === el?.value)

    let activityImage = NoImage;
    if(activityDetails?.attachment){
        if(Array.isArray(activityDetails.attachment) && activityDetails.attachment.length >0)
            activityImage = activityDetails.attachment[0]
        else if(activityDetails.attachment?.media && !Array.isArray(activityDetails.attachment?.media))
            activityImage = activityDetails.attachment?.media
        else if(activityDetails.attachment?.media && Array.isArray(activityDetails.attachment?.media) && activityDetails.attachment.media.length >0)
            activityImage = activityDetails.attachment?.media[0]
        else if(activityDetails.attachment?.supp_document && Array.isArray(activityDetails.attachment?.supp_document) && activityDetails.attachment.supp_document.length >0)
            activityImage = activityDetails.attachment?.supp_document[0]
    }

    const loading = isLoading.reasons || isLoading.details
    const loadingError = activityDetails === '' ? 'No details found!'
        : closeReasonOption.length === 0 && activityDetails?.status === "open" ? t('No close reason data found!')
        : undefined


    // let building_img = activityDetails.buildingIcon && activityDetails.buildingIcon.length ? activityDetails.buildingIcon : NoImage;
    let building_img = activityDetails.communityLogo && activityDetails.communityLogo.length ? activityDetails.communityLogo : NoImage;

    return (
        <div className="com-card-section myunit-page">
            <div className="row flex-stack announcement-sec mb-6">
                <div className="col-md-6 col-12">
                    <h4 className="card-title font-18 text-black">
                        {/* <Link to={isClosed ? '/activities/close' : '/activities/open'} className=''>
                            <img src={LeftArrow} alt="Arrow Left" className="img-fluid me-5" />
                        </Link> */}
                        <span className='c-pointer'
                            onClick={() => { history.goBack() }}
                        >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        <span className="font-18 text-black"> {activityDetails.master_data_name} </span>
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-4 col-lg-3 col-xl-3">
                                    <div className='approval-move mb-2'>
                                        {/* <img src={building_img} alt='image' className="img-fluid w-100 h-100 rounded-5 wo-img" /> */}
                                        <img src={building_img} alt='image' className="img-fluid rounded-5" />
                                    </div>                                    
                                </div>
                                <div className="col-md-8 col-lg-9 col-xl-9">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <span className="text-black fw-bolder font-15 d-block mb-4">
                                                {t("Request Type")} : {activityDetails.master_data_name}
                                            </span>
                                        </div>
                                        { user_role === 'FMT' &&
                                            <>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Tenant Name")} </h6>
                                                    <span className="text-black fw-bold font-15"> {activityDetails.tenantName ? activityDetails.tenantName : <i>{t("Not available")}</i>} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Tenant Contact Number")} </h6>
                                                    <span className="text-black fw-bold font-15"> {activityDetails.tenantNumber ? activityDetails.tenantNumber : <i>{t("Not available")}</i>} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Building Name")} </h6>
                                                    <span className="text-black fw-bold font-15"> {activityDetails.building_name} </span>
                                                </div>
                                            </>
                                        }
                                        <div className="col-md-4 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Unit")} </h6>
                                            <span className="text-black fw-bold font-15"> {activityDetails.property_code ? activityDetails.property_code : <i>{t("Not available")}</i>} </span>
                                        </div>
                                        <div className="col-md-4 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Date")} </h6>
                                            {/* <span className="text-black fw-bold font-15"> { moment(activityData.created_at).format('YYYY-MM-DD hh:mm:ss') } </span> */}
                                            <span className="text-black fw-bold font-15"> { formatDateTimeMeridiem(activityDetails.created_at) } </span>
                                        </div>
                                        { outcomeData && 
                                            <div className="col-md-4 col-12 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Outcome")} </h6>
                                                <span className="text-black fw-bold font-15"> {outcomeData?.name} </span>
                                            </div>
                                        }
                                        { (activityDetails.activity_category_name && activityDetails.activity_category_name !== '') && 
                                            <div className="col-md-4 col-12 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Request Category")} </h6>
                                                <span className="text-black fw-bold font-15"> {activityDetails.activity_category_name} </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <LoadingErrorAlert loading={loading} loadinError={loadingError} />
                            <div className="row form-add-design">
                                <div className="col-md-2 col-12 mb-5">
                                    <div className="">
                                        <label className="form-label fw-bolder"> {t("Status")} </label>
                                        <span className="font-14 text-black fw-bold d-block"> {isClosed ? t('Closed') : t('Open')} </span>
                                    </div>
                                </div>
                                {activityDetails.details_json?.precise_location_name &&
                                    <div className="col-md-2 col-12 mb-5">
                                        <div className="">
                                            <label className="form-label fw-bolder"> {t("Precise Location")} </label>
                                            <span className="font-14 text-black fw-bold d-block"> {activityDetails.details_json?.precise_location_name} </span>
                                        </div>
                                    </div>
                                }
                                {activityImage !== NoImage &&
                                    <div className="col-md-2 col-12 mb-5">
                                        <div className="">
                                            <label className="form-label fw-bolder"> {t("Image")} </label>
                                            <ImageViewerWithPopup imageFile={activityImage} imgID={'activity'}/>
                                        </div>
                                    </div>
                                }
                                {(activityDetails.description && activityDetails.activity_category_name !== "Fire Alarm") ?
                                    <div className="col-md-8 col-12">
                                        <label className="form-label fw-bolder"> {t("Description")} </label>
                                        <span className="font-14 text-black fw-bold d-block">
                                            {activityDetails.description}
                                        </span>
                                    </div>:''
                                }
                            </div>
                            { activityDetails.activity_category_name === "Fire Alarm" &&
                                <LinkedIncidentDetails activityDetails={activityDetails}/>
                            }

                            
                            {!loading &&
                                <>
                                    {isClosed ?
                                        <div className="row border-top pt-10 mt-10">
                                            <div className="col-md-2 col-lg-2 col-12 pb-4 pb-lg-0 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Closed Media")} </h6>
                                                {/* <span className="text-black fw-bold font-14"> {'-'} </span> */}
                                                {activityDetails?.media?.map((item:any, index:any) => {
                                                    return (
                                                        <div key={index}>
                                                            <ImageViewerWithPopup imageFile={item} imgID={index}/>
                                                        </div>
                                                    );
                                                })}
                                                {activityDetails?.media.length === 0 &&
                                                    t('No media!')
                                                }
                                            </div>
                                            <div className="col-md-3 col-lg-2 col-12 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Closed Reason")} </h6>
                                                <span className="text-black fw-bold font-14"> { activityDetails?.reason_type ? activityDetails?.reason_type : activityDetails?.reason } </span>
                                            </div>
                                            <div className="col-md-3 col-lg-2 col-12 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Closed At")} </h6>
                                                <span className="text-black fw-bold font-14"> {formatDateTimeMeridiem(activityDetails.updated_at)} </span>
                                            </div>
                                            <div className="col-md-3 col-lg-2 col-12 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Closed By")} </h6>
                                                <span className="text-black fw-bold font-14"> { activityDetails?.closedByName ? activityDetails?.closedByName : "-" } </span>
                                            </div>
                                            <div className="col-12">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Comments")} </h6>
                                                <div className="form-control bg-light border-0">
                                                    <span className="font-14 text-black fw-bold d-block"> {activityDetails?.closed_comments ? activityDetails?.closed_comments : '-'} </span>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <CloseActivity activityID={activityData?.id} closeReasonOption={closeReasonOption} setPopupSuccessMsg={setPopupSuccessMsg} loading={loading}/>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}