import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
// import BackArrow from '../../../../assets/images/back_arrow_color.png'
import { useLocation } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import { useTranslation } from 'react-i18next'
import { getAllowedProfileRoutes, getAllowedRoutes } from '../../../../configs/roleUtils'
import PrivateRoutesConfig from '../../../../configs/PrivateRoutesConfig'
import { getUserRole } from '../../../../constants/generics/userCheck'
import { CustomLoader1 } from '../../../../constants/generics/customActios'
import PrivateRoutesConfigProfileMenu from '../../../../configs/PrivateRoutesConfigProfileMenu'
import { ssoUserDetailswithLocations } from '../../../../constants/generics/sso'

export function AsideMenuMainNew(props: any) {
    const dispatch = useDispatch()

    // const [permissions, setPermissions] = useState<any>([])
    // useEffect(() => {
    //     getMenu()
    //         .then((response: any) => {
    //             const customData = {
    //                 permissions: response?.data?.data,
    //                 errorCode: response?.data?.errorCode,
    //                 statusCode: response?.status,
    //                 apiStatusCode: response?.data?.code,
    //                 fullResponse: response
    //             }
    //             if (response?.status === 200 && response?.data?.errorCode != 1) {

    //                 dispatch(General.actions.permissions(customData))
    //             } else {
    //                 dispatch(General.actions.permissions(customData))
    //             }
    //         })
    //         .catch((e) => {
    //         })
    // }, [])
    const allPermissions: any = useSelector<RootState>(({ General }) => General.permissions)
    // console.log("allPermissions", allPermissions)


    const intl = useIntl()
    const location = useLocation()
    const [menu, setMenu] = useState('')
    const history = useHistory()
    const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }

    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
        }
    })

    const onLogout = () => {
        let confirm = window.confirm('Are you sure you want to logout?')
        if (confirm) {
            window.localStorage.clear()
            history.push('/logout')
        }
    }
    const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
    const handleFind = () => {
        const location = `/properties-listing`
        window.location.assign(`${ssoUserDetailswithLocations(userDetail, accessToken, location)}`)
    }

    const { t } = useTranslation();

    const state: any = useSelector(state => state);
    const userRoleCode = getUserRole(state?.auth?.user?.data);

    let allowedRoutes = allPermissions != undefined ? getAllowedRoutes(PrivateRoutesConfig, userRoleCode, allPermissions) : 0;
    var allowedProfileRoutes = allPermissions != undefined ? getAllowedProfileRoutes(PrivateRoutesConfigProfileMenu, userRoleCode, allPermissions) : [];

    //Valid profile menu urls - for aside
    let profileMenuUrls = allowedProfileRoutes?.map((route: any, index: number) => {
        let temp = new Array()
        route.menu?.supportedComponents?.forEach((subItem: any) => {
            if (subItem.path)
                temp.push(subItem.path)
        })
        return [route.path, ...route.menu.dynaimcPaths, ...temp]
    })
    profileMenuUrls = ['/profile', '/notifications', ...profileMenuUrls.flat()]
    // console.log( profileMenuUrls, 'profileMenuUrls');

    if (allPermissions === undefined) {
        return (<div><CustomLoader1 height={'40'} /></div>)
    }
    return (
        <>
            {/* Start Profile Screens - Dynamic */}
            {profileMenuUrls && profileMenuUrls.includes(menu) ? (
                <div className='side-menu-custom Main'>
                    <div className='dash-icon-menu'>
                        <AsideMenuItem
                            to={
                                userRole === 6 ? '/broker-admin-dashboard/available-properties' :
                                // userRole === 20 || userRole === 16 ? '/marketing-admin-dashboard' :
                                '/dashboard'
                            }
                            icon='/media/icons/Dashboard_InActive.png'
                            title={t("Dashboard")}
                        />
                    </div>
                    <div className='personal-information-icon-menu'>
                        <AsideMenuItem
                            to='/profile'
                            title={t("My Profile")}
                            icon='/media/icons/Dashboard_InActive.png'
                        />
                    </div>
                    <div className='notifications-icon-menu'>
                        <AsideMenuItem
                            to='/notifications'
                            title={t("Notifications")}
                            icon='/media/icons/alert-inactive.png'
                        />
                    </div>
                    {userRoleCode && ['PM', 'PE'].includes(userRoleCode) ?
                        <div className='my-documents-icon-menu' onClick={handleFind}>
                            <AsideMenuItem
                                to={'#'}
                                title={t("Property Search")}
                                icon='/media/icons/properties_inactive.png'
                            />
                        </div>
                        : ''
                    }
                    {userRoleCode && ['BR'].includes(userRoleCode) ?
                        <div className='my-documents-icon-menu'>
                            <AsideMenuItem
                                to={'/pt-faq'}
                                title={t("FAQ")}
                                icon='/media/icons/Dashboard_InActive.png'
                            />
                        </div>
                        : ''
                    }
                    {userRoleCode && ['BR'].includes(userRoleCode) ?
                        <div className='my-documents-icon-menu'>
                            <AsideMenuItem
                                to={'/pt-settings'}
                                title={t("Settings")}
                                icon='/media/icons/settings-inactive.png'
                            />
                        </div>
                        : ''
                    }
                    {
                        allowedProfileRoutes?.map(({ path, menu }: any, index: number) => (
                            <div className={menu.iconClass} key={index}>
                                <AsideMenuItem
                                    to={
                                        Array.isArray(menu.dynaimcPaths) && menu.dynaimcPaths.length && (menu.dynaimcPaths).includes(location.pathname) ?
                                            location.pathname : menu.menuPath
                                    }
                                    icon={menu.iconFile}
                                    title={t(menu.title)}
                                    supportedPaths={menu.supportedComponents?.map((el: any) => el.path)}
                                />
                            </div>
                        ))
                    }

                    <div
                        className='logout-icon-menu'
                        // onClick={() => onLogout()}
                        data-bs-toggle="modal"
                        data-bs-target="#logout-modal"
                    >
                        <div className='menu-item'>
                            <span className='menu-link without-sub'>
                                <span className='menu-icon'>
                                    <KTSVG path='/media/icons/Dashboard_InActive.png' className='svg-icon-2' />
                                </span>
                                <span className='menu-title'>{t("Logout")}</span>
                            </span>
                        </div>
                    </div>
                    <div className='menu-item'></div>
                </div>
                // End Profile Screen
            ) : (

                //AsideMenu Dynamic
                <div className='side-menu-custom'>
                    {userRole != 6 && 
                        <div className='dash-icon-menu'>
                            <AsideMenuItem
                                to={
                                    userRole === 6 ? '/broker-admin-dashboard/available-properties':
                                    // userRole === 20 || userRole === 16 ? '/marketing-admin-dashboard/all':
                                    '/dashboard'
                                }
                                icon='/media/icons/Dashboard_InActive.png'
                                title={t('Dashboard')}
                            />
                        </div>
                    }

                    {
                        allowedRoutes?.map(({ path, menu }: any, index: number) => {
                            if (menu?.subMenu?.length && Array.isArray(menu.subMenu)) {
                                return (
                                    <div className={menu.iconClass} id={menu.menu_wrapper_id} key={index}>
                                        <AsideMenuItemWithSub
                                            to=''
                                            icon={menu.iconFile}
                                            title={t(menu.title)}
                                            children={
                                                <>
                                                    {
                                                        menu?.subMenu?.map((subMenu: any, index:any) => (
                                                            <AsideMenuItem
                                                                key={index}
                                                                to={
                                                                    Array.isArray(subMenu.menu.dynaimcPaths) && subMenu.menu.dynaimcPaths.length && (subMenu.menu.dynaimcPaths).includes(location.pathname) ?
                                                                        location.pathname : subMenu.menu.menuPath
                                                                }
                                                                icon={subMenu.menu.iconFile}
                                                                title={t(subMenu.menu.title)}
                                                                supportedPaths={subMenu.menu.supportedComponents?.map((el: any) => el.path)}
                                                            />
                                                        ))
                                                    }
                                                </>
                                            }
                                        />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className={menu.iconClass} key={index}>
                                        <AsideMenuItem
                                            to={
                                                Array.isArray(menu.dynaimcPaths) && menu.dynaimcPaths.length && (menu.dynaimcPaths).includes(location.pathname) ?
                                                    location.pathname : menu.menuPath
                                            }
                                            icon={menu.iconFile}
                                            title={t(menu.title)}
                                            supportedPaths={menu.supportedComponents?.map((el: any) => el.path)}
                                        />
                                    </div>
                                )
                            }
                        }
                        )

                    }
                </div>

                //
                // <div className='side-menu-custom'>
                //   { userRole === 8 /* || userEmail === 'fc@demo.com' */ ? (
                //     <>
                //       {/* Start Facility Contractor  Screens */}

                //       <div className='dash-icon-menu'>
                //         <AsideMenuItem
                //           to='/dashboard'
                //           icon='/media/icons/Dashboard_InActive.png'
                //           title={t("Dashboard")}
                //         />
                //       </div>
                //       <div className='workorder-icon-menu'>
                //         {/* <AsideMenuItem
                //           to={'/fc-workorder/corrective/open'}
                //           icon='/media/icons/workOrder_InActive.png'
                //           title={t("Work Orders")}
                //         /> */}
                //         <AsideMenuItem
                //           to={
                //             location.pathname === '/facility-workorder/list/clearance/open'
                //               ? '/facility-workorder/list/clearance/open'
                //               : location.pathname === '/facility-workorder/list/clearance/confirmed'
                //                 ? '/facility-workorder/list/clearance/confirmed'
                //                 : location.pathname === '/facility-workorder/list/clearance/completed'
                //                   ? '/facility-workorder/list/clearance/completed'
                //                   : location.pathname === '/facility-workorder/list/corrective/in-progress'
                //                     ? '/facility-workorder/list/corrective/in-progress'
                //                     : location.pathname === '/facility-workorder/list/corrective/onhold'
                //                       ? '/facility-workorder/list/corrective/onhold'
                //                       : location.pathname === '/facility-workorder/list/corrective/completed'
                //                         ? '/facility-workorder/list/corrective/completed'
                //                         : location.pathname === '/facility-workorder/list/preventive/open'
                //                           ? '/facility-workorder/list/preventive/open'
                //                           : location.pathname === '/facility-workorder/list/preventive/onhold'
                //                             ? '/facility-workorder/list/preventive/onhold'
                //                             : location.pathname === '/facility-workorder/list/preventive/completed'
                //                               ? '/facility-workorder/list/preventive/completed'
                //                               : location.pathname === '/facility-workorder/list/open/turn-around'
                //                                 ? '/facility-workorder/list/open/turn-around'
                //                                 // : location.pathname === '/fc-workorder/work-order-details'
                //                                 // ? '/fc-workorder/work-order-details'
                //                                 // : location.pathname === '/fc-workorder/check-list'
                //                                 // ? '/fc-workorder/check-list'
                //                                 // : location.pathname === '/fc-workorder/moveout-inspection'
                //                                 // ? '/fc-workorder/moveout-inspection'
                //                                 // : location.pathname === '/fc-workorder/add'
                //                                 // ? '/fc-workorder/add'
                //                                 // : '/fc-workorder/corrective/open'
                //                                 : '/facility-workorder/list/corrective/open'

                //           }
                //           icon='/media/icons/workOrder_InActive.png'
                //           title={t("Work Orders")}
                //         />
                //       </div>
                //       <div className='announce-icon-menu'>
                //         {/* <AsideMenuItem
                //           to={
                //             location.pathname === '/fc-ss-announcements/add'
                //               ? '/fc-ss-announcements/add'
                //               : '/fc-ss-announcements/list'
                //           }
                //           icon='/media/icons/announcement_InActive.png'
                //           title={t("Announcements")}
                //         /> */}
                //         <AsideMenuItem
                //           to={'/su-announcement'
                //             // location.pathname === '/su-create-announcements'
                //             //   ? '/su-create-announcements'
                //             //   : '/su-announcement/open/services'
                //           }
                //           icon='/media/icons/announcement_InActive.png'
                //           title={t("Announcements")}
                //         />
                //       </div>
                //       {/* <div className='reports-icon-menu'>
                //         <AsideMenuItem
                //           to='/fc-reports'
                //           icon='/media/icons/reports_InActive.png'
                //           title={t("Reports")}
                //         />
                //       </div> */}
                //       <div className='calender-icon-menu'>
                //         <AsideMenuItem
                //           to={
                //             location.pathname === '/fc-su-calendar/attendance/total'
                //               ? '/fc-su-calendar/attendance/total'
                //               : location.pathname === '/fc-su-calendar/attendance/present'
                //                 ? '/fc-su-calendar/attendance/present'
                //                 : location.pathname === '/fc-su-calendar/attendance/absent'
                //                   ? '/fc-su-calendar/attendance/absent'
                //                   : location.pathname === '/fc-su-calendar/attendance/add-attendance'
                //                     ? '/fc-su-calendar/attendance/add-attendance'
                //                     : '/fc-su-calendar/all'
                //           }
                //           icon='/media/icons/calender_InActive.png'
                //           title={t("Calendar")}
                //         />
                //       </div>

                //       {/* End Facility Contractor Screens */}
                //     </>
                //   ) : null}
                // </div>
            )}
        </>
    )
}
