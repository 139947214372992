import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { UserRolesList } from './role-component/UserRolesList'
import { EditRole } from './role-component/EditRole'

const PMUserRoles: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/user-roles/list'>
                            <UserRolesList />
                        </Route>
                        <Route path='/user-roles/edit'>
                            <EditRole />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMUserRolesWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMUserRoles />
        </>
    )
};

export { PMUserRolesWrapper }

