import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../components/layout/core'
// import {AnnouncementTabs} from './components/ApprovalTabs/AnnouncementTabs'
// import {OpenAnnouncements} from './components/ApprovalTabs/UpcomingApprovals/OpenAnnouncements'
// import {ClosedAnnouncements} from './components/ApprovalTabs/ClosedApprovals/ClosedAnnouncements'
// import {Redirect, Route, Switch} from 'react-router-dom'
import {AnnouncementsList} from './AnnouncementsList/AnnouncementsList';


export const SupervisorAnnouncementListPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
            <AnnouncementsList />
            {/*<Switch>*/}
                {/*<Route path='/su-announcement/open'>*/}
                    {/*<AnnouncementTabs />*/}
                    {/*<OpenAnnouncements />*/}
                {/*</Route>*/}
                {/*<Route path='/su-announcement/closed'>*/}
                    {/*<AnnouncementTabs />*/}
                    {/*<ClosedAnnouncements />*/}
                {/*</Route>*/}
            {/*</Switch>*/}
        </div>
    </div>
    </>
);

const FCSupervisorAnnouncementWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}> Community Post </PageTitle>
        <SupervisorAnnouncementListPage />
        </>
    )
};

export {FCSupervisorAnnouncementWrapper}
