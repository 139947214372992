import React, {FC, useState, useEffect } from 'react';
import {useIntl} from 'react-intl';
import { PageTitle } from '../../components/layout/core';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AttendanceTabs} from './add/AttendanceTabs/AttendanceTabs';
import {AttendanceCard} from './add/AttendanceCard/AttendanceCard';
import {Total} from './add/Total/Total';
import {Present} from './add/Present/Present';
import {Absent} from './add/Absent/Absent';
import {AddAttendance} from './add/AddAttendance/AddAttendance';
import {CalenderAll} from './components/CalendarAll/CalendarAll';

import { useSelector } from 'react-redux';
import { generateAttendanceListFCS } from '../../utils/makeRequest';
import { IState } from '../../utils/interface';
import Loader from '../../components/Loader';
import { CookieComponent } from '../../setup/ts/components';
import moment from 'moment';


const CalenderPage: FC = () => {
    let currentDate = moment().format("YYYY-MM-DD");
    const state:any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    if(userToken === "undefined"){
        // token undefined
    }
    
    const [fcsAttendanceList, setFcsAttendanceList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    useEffect(()=>{
        generateAttendanceListFCS(setFcsAttendanceList,userToken, currentDate);
    },[]);

    // console.log("fcsAttendanceList: ",fcsAttendanceList);
    return (
        <>
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>
                <Switch>
                    <Route path='/fc-su-calendar/all'>
                        <CalenderAll />
                    </Route>
                    {/* <Route path='/fc-su-calendar/attendance/total'>
                        <AttendanceCard fcsAttendanceList={fcsAttendanceList} />
                        <AttendanceTabs />
                        <Total fcsAttendanceList={fcsAttendanceList} />
                    </Route>
                    <Route path='/fc-su-calendar/attendance/present'>
                        <AttendanceCard fcsAttendanceList={fcsAttendanceList} />
                        <AttendanceTabs />
                        <Present fcsAttendanceList={fcsAttendanceList} />
                    </Route>
                    <Route path='/fc-su-calendar/attendance/absent'>
                        <AttendanceCard fcsAttendanceList={fcsAttendanceList} />
                        <AttendanceTabs />
                        <Absent fcsAttendanceList={fcsAttendanceList} />
                    </Route>
                    <Route path='/fc-su-calendar/attendance/add-attendance'>
                        <AddAttendance />
                    </Route> */}
                    <Redirect to='/error/403' />
                </Switch>
            </div>
        </div>
        </>
    )
}

const FCSupervisorCalenderWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}> Calender </PageTitle>
        <CalenderPage />
        </>
    )
};

export {FCSupervisorCalenderWrapper}
