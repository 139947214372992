import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import { PortalModal } from '../Modal/PortalModal';

const portalData = [{
    "portal": "Dubizzle",
    "api": "AIzaSyClzfrOzB818x55FASHvX4JuGQciR9lv7q",
    "xml": "http://xml.realcube.com/feed/xml.php?cl-1066&nid-8345&access"


},
{
    "portal": "Dubizzle",
    "api": "AIzaSyClzfrOzB818x55FASHvX4JuGQciR9lv7q",
    "xml": "http://xml.realcube.com/feed/xml.php?cl-1066&nid-8345&access"


},
{
    "portal": "Dubizzle",
    "api": "AIzaSyClzfrOzB818x55FASHvX4JuGQciR9lv7q",
    "xml": "http://xml.realcube.com/feed/xml.php?cl-1066&nid-8345&access",

},
{
    "portal": "Dubizzle",
    "api": "AIzaSyClzfrOzB818x55FASHvX4JuGQciR9lv7q",
    "xml": "http://xml.realcube.com/feed/xml.php?cl-1066&nid-8345&access"


},
{
    "portal": "Dubizzle",
    "api": "AIzaSyClzfrOzB818x55FASHvX4JuGQciR9lv7q",
    "xml": "http://xml.realcube.com/feed/xml.php?cl-1066&nid-8345&access"

},
{
    "portal": "Dubizzle",
    "api": "AIzaSyClzfrOzB818x55FASHvX4JuGQciR9lv7q",
    "xml": "http://xml.realcube.com/feed/xml.php?cl-1066&nid-8345&access"

},
];
export function Portal() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)
    return (
        <>
            <div className='row align-items-center my-5'>
                <div className="col-6">
                    <h3 className=''>Portal</h3>
                </div>
                <div className="col-6 text-end">
                    <Link to>
                        <button data-bs-toggle="modal" data-bs-target="#Portal-popup" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                            Add Portal
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className='row align-items-center mb-5 table-drop'>
                        <div className="col-10"></div>
                        <div className="col-2 text-end d-flex align-items-center">
                            <span className="me-2">Show</span>
                            <select className="form-control form-select">
                                <option>10 </option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                            <span className="ms-2">entries</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div className='table-crm-payment'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='table-responsive'>
                                            <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                    <tr>
                                                        <th> PORTAL</th>
                                                        <th> API KEY </th>
                                                        <th> XML LINK </th>
                                                        <th> PROPERTY ENDPOINT</th>
                                                        <th>LEAD ENDPOINT </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {portalData.length > 0 ? portalData.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{item.portal}</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="bg-white px-2 py-1 border rounded d-inline-block">
                                                                        {item.api}
                                                                    </div>
                                                                    <button type="button" className="btn btn-outline-primary font-12 py-3 font-weight-semibold ms-2"><i className="fas fa-redo"></i></button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="bg-white px-2 py-1 border rounded d-inline-block">
                                                                        {item.xml}
                                                                    </div>
                                                                    <button type="button" className="btn btn-outline-primary font-12 py-3 font-weight-semibold ms-2"><i className="fas fa-redo"></i></button>
                                                                 </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )) : <tr><td colSpan={3}>No data..</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                        <CompactPagination
                            pageSize={pageSize}
                            totalCount={portalData.length}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={false}
                        />
                    </div>
                </div>
            </div>
            <PortalModal/>
        </>
    )
}

