import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {Redirect, Route, Switch} from 'react-router-dom'
import { PageTitle } from '../../../components/layout/core'
import { MoveoutRequestListWrapper } from './MoveoutRequestList/MoveoutRequestListWrapper'
import { MoveoutRequestViewWrapper } from './MoveoutRequestView/MoveoutRequestViewWrapper'
import {useTranslation} from 'react-i18next'
import { CustomRequestSuccessfulPopup } from '../../SuccessfulPopup/CustomRequestSuccessfulPopup'

const ServicesListPage: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/finance-moveout-requests/list'>
                            <MoveoutRequestListWrapper />
                        </Route>
                        <Route path='/finance-moveout-requests/view'>
                            <MoveoutRequestViewWrapper setPopupSuccessMsg={setPopupSuccessMsg} />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
            <CustomRequestSuccessfulPopup 
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    )
}

const FinanceMoveoutRequestsWrapper: FC = () => {
    const intl = useIntl();
    const {t} = useTranslation()

    return (
        <>
        <PageTitle breadcrumbs={[]}> {t("Services Request")} </PageTitle>
        <ServicesListPage />
        </>
    )
};

export {FinanceMoveoutRequestsWrapper}
