import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination';
import { useSelector } from 'react-redux';
import { myDashboard } from '../../../constants/axios/apis';
import { getInvoiceList } from '../../../utils/makeRequest';
import { IState } from '../../../utils/interface';
import { Analytics } from './Graphs/AnalyticsGraph';
import { FilterModal } from './Modal-filter';


const websitedata = [{
    "ref": "1",
    "campaignName": "Campaign 1",
    "createdDate": "28/08/2121",
    "Status": "Active",
    "comments": "Successful",
},
{
    "ref": "2",
    "campaignName": "Campaign 1",
    "createdDate": "28/08/2121",
    "Status": "Active",
    "comments": "Successful",
},
{
    "ref": "3",
    "campaignName": "Campaign 1",
    "createdDate": "28/08/2121",
    "Status": "Active",
    "comments": "Successful",
},
{
    "ref": "4",
    "campaignName": "Campaign 1",
    "createdDate": "28/08/2121",
    "Status": "Active",
    "comments": "Successful",

},
{
    "ref": "5",
    "campaignName": "Campaign 1",
    "createdDate": "28/08/2121",
    "Status": "Active",
    "comments": "Successful",

},
{
    "ref": "6",
    "campaignName": "Campaign 1",
    "createdDate": "28/08/2121",
    "Status": "Active",
    "comments": "Successful",

},
];

export function WebsiteListing() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    const state: any = useSelector(state => state);
    const [chartData, setChartData] = useState<any>([])
    const [loading, setLoading] = useState<any>(true)
    const userToken = state?.auth?.accessToken;

    useEffect(() => {
        myDashboard()
            .then((response: any) => {
                setLoading(false)
                if (response?.status === 200 && response?.data?.errorCode != 1) {
                    setChartData(response)
                } else {
                    setChartData(response)
                }
            })
            .catch((e) => {
            })
    }, [])

    if (userToken === "undefined") {
        // token undefined
    }

    const [invoiceList, setInvoiceList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    const { response: invoices, error } = invoiceList;
    useEffect(() => {
        getInvoiceList(setInvoiceList, userToken);
    }, []);
    function yearMonths() {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    function getInvoiceMonths(invoices: any[], status: string) {
        const months = yearMonths();

        let invoiceMonth = new Set(
            invoices.filter(invoice => invoice.status === status).map(invoice => {
                let date = new Date(invoice.invoiceDate);
                return date.getMonth()
            }).sort().map(d => {
                return months[d];
            })
        );
        let invoiceMonths: any[] = [];

        invoiceMonth.forEach(function (month: any) {
            invoiceMonths.push(month);
        });

        return invoiceMonths;
    }

    function getTotalInvoiceByMonth(invoices: any[], status: string, invoiceMonths: any[]) {
        const months = yearMonths();
        const returnArray: any[] = [];

        let invoice = invoices.filter(invoice => invoice.status === status)

        invoiceMonths.forEach(function (month: any) {
            let totalInvoice = invoice.filter(inv => {
                let d = new Date(inv.invoiceDate);

                return months[d.getMonth()] === month;
                //let invoiceMonth = d.getMonth();
            });
            returnArray.push(totalInvoice.length);
        })
        return returnArray;
    }

    const [chartDataPending, setChartDataPending] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'In-Progress',
                data: [1, 2, 3, 4],
                backgroundColor: '#002C6A',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });

    useEffect(() => {
        if (Array.isArray(invoices)) {
            if (invoices.length > 0) {
                let pendingInvoiceMonth = getInvoiceMonths(invoices, "Pending");
                setChartData(setChartDataPending);

            }
        }
    }, [invoices]);

    return (
        <>

            <div className="row my-7 form-add-design">
                <div className="col-md-6">
                    <h3 className="theme-color font-22 mb-4">
                        Website Campaigns
                    </h3>
                </div>
                <div className="col-md-6 text-end">
                    <Link to='add' className="btn btn-primary radius-10  px-4 font-12"> Create New Campaign </Link>
                    <a className="btn btn-primary ms-2 font-12 radius-10 font-weight-semibold d-inline-flex flex-wrap align-items-center px-4 float-right mb-2 mb-md-0" target="_blank" href="ref">Download Report</a>
                </div>
            </div>
            <div className='row social-media mb-7'>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <div className="card radius-12 h-100 border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to={'click-through-rate'} className="font-weight-semibold text-warning font-15 border-bottom pb-2 mb-3 d-block">                        
                                            Total CTR
                                        </Link>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <h5 className="font-weight-bold mb-0"> 5,000 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <div className="card radius-12 h-100 border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to={'impressions'} className="font-weight-semibold text-warning font-15 border-bottom pb-2 mb-3 d-block"> Total Impressions </Link>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <h5 className="font-weight-bold mb-0"> 2,000 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <div className="card radius-12 h-100 border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to={'cost-per-click'} className="font-weight-semibold text-warning font-15 border-bottom pb-2 mb-3 d-block">
                                          
                                            Total CPC
                                        </Link>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <h5 className="font-weight-bold mb-0"> 500 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <div className="card radius-12 h-100 border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to={'BounceRates'} className="font-weight-semibold text-warning font-15 border-bottom pb-2 mb-3 d-block">
                                            Total Bounce Rates
                                        </Link>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <h5 className="font-weight-bold mb-0"> 75 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              
            </div>
            <div className='row'>
                <div className="col-8">
                    <div className="card">
                        <div className="card-body">
                            <div className='row align-items-center mb-5 table-drop'>
                                <div className="col-8">
                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#Filter-popup">
                                        <i className="fas fa-filter"></i> Filter
                                    </button>
                                </div>
                                <div className="col-4 text-end d-flex align-items-center">
                                    <span className="me-2">Show</span>
                                    <select className="form-control form-select">
                                        <option>10 </option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </select>
                                    <span className="ms-2">entries</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 col-12'>
                                    <div className='table-crm'>
                                        <div className='row'>
                                            <div className='col-md-12 col-12'>
                                                <div className='table-crm'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='table-responsive'>
                                                                <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                                    <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                        <tr>
                                                                            <th> #</th>
                                                                            <th> CAMPAIGN NAME </th>
                                                                            <th> CREATED DATE </th>
                                                                            <th> STATUS</th>
                                                                            <th> COMMENTS </th>
                                                                            <th> Action </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {websitedata.length > 0 ? websitedata.map((item, i) => (
                                                                            <tr key={i}>
                                                                                <td className="text-warning">{item.ref}</td>
                                                                                <td>{item.campaignName}</td>
                                                                                <td>{item.createdDate}</td>
                                                                                <td><span className="active-bg d-inline px-4 font-weight-semibold"> {item.Status} </span></td>
                                                                                <td>{item.comments}</td>
                                                                                <td className="action-button text-end">
                                                                                    <div className="dropdown">
                                                                                        <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                                            <i className="fa fa-ellipsis-v text-black"></i>
                                                                                        </button>
                                                                                        <div className="dropdown-menu">
                                                                                            <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                                                <li>
                                                                                                    <Link to='edit' className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                        <i className="fa fa-edit"></i>
                                                                                                        <p className="m-0">Edit</p>
                                                                                                    </Link>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className='w-100 d-flex justify-content-end'>
                                                                    <CompactPagination
                                                                        pageSize={pageSize}
                                                                        totalCount={websitedata.length}
                                                                        defaultPage={currentPage}
                                                                        onPageChange={setCurrentPage}
                                                                        loading={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="card-body p-3">

                            <Analytics chartData={chartData} />
                        </div>
                    </div>
                </div>
            </div>
            <FilterModal />

        </>
    )
}