import React, { useState, useEffect } from 'react'

export function Notes() {
    return (      
        <>
        <div id="notes-list" className="notes-list">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-md-6 mb-3">
                            <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row no-gutters">
                                                        <div className="col-md-9">
                                                            <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                            <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                                
                                                            </h6>
                                                        </div>
                                                        
                                                    </div>
                                                    <hr className="my-2"/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="">
                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                        <p className="font-12 mb-2 ellipsis"></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="">
                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                        <p className="font-12 mb-2 ellipsis"></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="">
                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                        <p className="font-12 mb-2 ellipsis"></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="">
                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                        <p className="font-12 mb-2 ellipsis"></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="">
                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                        <p className="font-12 mb-0"></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="">
                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                        <p className="font-12 mb-2"></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6 col-12">
                                                    <div className="">
                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                        <p className="font-12 mb-2"></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 text-right">
                                                    <a href="<?php echo base_url('offer-detail').'/'.$offer['offer_id']?>" className="text-decoration-none">
                                                        <button type="button" className="btn btn-warning font-12 btn-sm"> View More </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-md-12">
                            <div className="row align-items-center">
                                <div className="col-md-12 text-center py-4">
                                    <i className="fas fa-file-alt text-gray-400 font-44"></i>
                                    <h3 className="text-gray-400 mt-4 mb-0"> No Notes found </h3>
                                </div>
                            </div>
                        </div>

                    </div>        
                </div>
            </div>
        </div>
    </>
    )
}

