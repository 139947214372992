import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {getFcsMyTeamList} from '../../utils/makeRequest'
import {IState} from '../../utils/interface'

import {PageTitle} from '../../components/layout/core'
import {Reports} from './components/Reports/Reports'
import {MyTeamList} from './components/MyTeamList/MyTeamList'
import {AddContractors} from './components/AddContractors/AddContractors'
import {RequestSuccessfulPopup} from '../SuccessfulPopup/RequestSuccessfulPopup'
import { useTranslation } from 'react-i18next';

type Props = {
  searchItem?: any
  setSearchItem?: any
}

const TitleandReportWrapper: FC<Props> = ({searchItem, setSearchItem}) => {
  const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row mb-6 flex-stack'>
        <div className='col-5'>
          <h4 className='card-title font-18 text-black mt-2'>{t("My Team")}</h4>
        </div>
        <div className='col-7 text-end'>
          <div className='d-inline-flex pm-topbar-buttons mx-4'>
            <div className='form-group has-search'>
              <span className='fa fa-search pt-2 form-control-feedback'></span>
              <input
                type='text'
                className='form-control ps-10'
                onChange={(e) => setSearchItem(e.target.value)}
                placeholder={t('Search')}
              />
            </div>
          </div>
          <Link
            to={{pathname: '/fm-myteam/add', state: {addRole: 'FCS'}}}
            className='btn btn-primary btn-sm font-16'
          >
            <i className='bi bi-plus-circle-fill align-middle'></i> {t("Add Supervisor")}
          </Link>
        </div>
        <div className='col-md-4 col-12 my-auto'></div>
      </div>
      {/* <Reports /> */}
    </div>
  )
}

const MyTeamPage: FC = () => {
  const [searchItem, setSearchItem] = useState<any>('')
  // const state: any = useSelector(state => state);
  // const userToken = state?.auth?.accessToken;

  // const [fcsMyTeamList, setFcsMyTeamList] = useState<IState['APIState']>({
  //     loading: false,
  //     response: [],
  //     error: ''
  // });

  // useEffect(() => {
  //     getFcsMyTeamList(setFcsMyTeamList, userToken);
  // }, []);

  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/fm-myteam/add'>
              <AddContractors />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/fm-myteam'>
              <TitleandReportWrapper setSearchItem={setSearchItem} searchItem={searchItem} />
              <MyTeamList setSearchItem={setSearchItem} searchItem={searchItem} />
            </Route>
            <Redirect to='/error/403' />
          </Switch>
        </div>
      </div>
    </>
  )
}

const FMTeamMyTeamWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}> My Team </PageTitle>
      <MyTeamPage />
    </>
  )
}

export {FMTeamMyTeamWrapper}
