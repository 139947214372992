import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function Notes() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                            {t("Notes")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}