import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import WorksIcon from "../../../../assets/images/que-icon.png";
import Propertie from '../../../../assets/images/latest-one.png';
import bed from "../../../../assets/images/bed.svg";
import Bath from "../../../../assets/images/bath.svg";
import Place from "../../../../assets/images/place.svg";
import SQR from "../../../../assets/images/sqft.svg";
import Yes from "../../../../assets/images/yes.svg";
import { useDispatch, useSelector } from 'react-redux';
import Nouislider from "nouislider-react";
import "nouislider/dist/nouislider.css";
import { RootState } from '../../../../constants';
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import { getAddOns } from '../../../../constants/axios/apis';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../constants/generics/customActios';

const MakeAnOfferPage: React.FC = () => {
    const ref = useRef<any>(0)
    const dispatch = useDispatch()
    let customerDetails: any
    customerDetails = useSelector<RootState>(({ General }) => General.pmMakeAnOfferData)
    const location = useLocation()
    const [isPayByEmployer, setIsPayByEmployer] = useState(false);
    const [addOns, setAddOns] = useState([]);
    const [noOfPayments, setNoOfPayments] = useState(1);
    const [modeOfPayment, setmodeOfPayment] = useState('CREDIT_CARD');
    const [allowance, setAllowance] = useState<any>('');
    const [allowanceError, setAllowanceError] = useState<any>('');
    const [employerName, setEmployerName] = useState<any>('');
    const [employerNameError, setEmployerNameError] = useState<any>('');
    const [myOfferPrice, setMyOfferPrice] = useState(customerDetails?.proprtyDetails?.data?.price);
    const [dateStartValue, setDateStartValue] = useState(new Date());
    const [dateEndValue, setDateEndValue] = useState(moment(dateStartValue).add(1, "years").toDate());
    const { t } = useTranslation();

    const handleDropdown = (e: any) => {
        if (e.target.value === "PAY_BY_EMP") {
            setIsPayByEmployer(true)
            setmodeOfPayment(e.target.value)
        } else {
            setIsPayByEmployer(false)
            setmodeOfPayment(e.target.value)
        }

    }
    useEffect(() => {
        getAddOns().then((response) => {
            setAddOns(response.data)
        })
    }, [isPayByEmployer])
    function onChange(value: any) {
        setMyOfferPrice(parseInt(value[0]))
    }
    const selectedAddOns = (checkedValues: any) => {
        // console.log("🚀 ~ file: MakeAnOfferPage.tsx ~ line 56 ~ selectedAddOns ~ checkedValues", checkedValues.target.value)
    }
    const handleSubmit = () => {
        const endDate = moment(dateStartValue).add(1, "years").toDate()
        if (isPayByEmployer) {
            if (employerName != '' && allowance != '' && allowance > 0) {
                setAllowanceError('')
                setEmployerNameError('')
                const makeAnOfferData = {
                    customerData: customerDetails,
                    installment: noOfPayments,
                    start_date: moment(dateStartValue).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                    mode_payment: modeOfPayment,
                    offer_request_price: myOfferPrice,
                    employer_name: employerName,
                    housing_allowance: allowance,
                    add_on: "1,2"
                }
                dispatch(General.actions.pmMakeAnOfferPopUpData(makeAnOfferData))
                ref.current.click();
            } else {                
                if (employerName == '') {
                    setEmployerNameError(t("Please enter employer name"))
                }else{setEmployerNameError('')}
                if (allowance == '') {
                    setAllowanceError(t("Please enter valid allowance"))
                }else if(allowance <= 0){
                    setAllowanceError(t("Allowance should be greater than 0"))
                }
                else{setAllowanceError('')}
            }
        } else {
            const makeAnOfferData = {
                customerData: customerDetails,
                installment: noOfPayments,
                start_date: moment(dateStartValue).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                mode_payment: modeOfPayment,
                offer_request_price: myOfferPrice
            }
            dispatch(General.actions.pmMakeAnOfferPopUpData(makeAnOfferData))
            ref.current.click();
        }
    }
    return (
        <div className="col-md-12 col-12">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center">
                        <div className="col-12 mb-6">
                            <h4 className="card-title font-18 text-black mb-0">
                                <Link to={'/pm-availability/property-details'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {t("Make an Offer")}
                            </h4>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body px-5 py-5">
                                    <div className="row form-add-design">
                                        <div className="col-12">
                                            <div className="properties_list_view mb-10">
                                                <div className="card">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4 col-lg-3 text-xxl-center">
                                                            <div className="pt-make-offer_main_img">
                                                                <img src={customerDetails?.proprtyDetails?.data?.coverImage?.length > 0 ? customerDetails?.proprtyDetails?.data?.coverImage : Propertie} alt="Work Orders" className="img-fluid" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 col-lg-9">
                                                            <div className="offer_main_details px-3 py-3">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <p className="text-black fw-bolder fs-16 d-flex align-items-baseline mb-1">
                                                                            <span className="me-2">{t("AED")}</span> <h2 className="me-2">{customerDetails?.proprtyDetails?.data?.price?currencyFormater(customerDetails?.proprtyDetails?.data?.price):'-'}</h2> <span> / {t("Year")}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <p className="mb-3 fw-bold">{customerDetails?.proprtyDetails?.data?.propretyName}</p>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="d-flex border-bottom">
                                                                            <p className="me-4">
                                                                                <img src={bed} className="img-fluid me-3" />
                                                                                {customerDetails?.proprtyDetails?.data?.bedrooms?customerDetails?.proprtyDetails?.data?.bedrooms:'-'}
                                                                            </p>
                                                                            <p className="me-4">
                                                                                <img src={Bath} className="img-fluid me-3" />
                                                                                {customerDetails?.proprtyDetails?.data?.bathrooms?customerDetails?.proprtyDetails?.data?.bathrooms:'-'}
                                                                            </p>
                                                                            <p className="sqft me-4">
                                                                                <img src={SQR} className="img-fluid me-3" />
                                                                                {customerDetails?.proprtyDetails?.data?.builtInArea?customerDetails?.proprtyDetails?.data?.builtInArea:'-'} {t("Sqmt")}
                                                                            </p>
                                                                            <p className="me-4">
                                                                                <img src={Yes} className="img-fluid me-3" />
                                                                                {customerDetails?.proprtyDetails?.data?.isParkingAvailable === 'Y' ? t("Yes") : t("No")}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 offer_main_details_div_3">
                                                                        <p className="mb-0 pt-2 text-black">
                                                                            <img src={Place} className="img-fluid me-3" />
                                                                            {customerDetails?.proprtyDetails?.data?.propertyCode?.substring(customerDetails?.proprtyDetails?.data?.propertyCode?.lastIndexOf("-") + 1, customerDetails?.proprtyDetails?.data?.propertyCode?.length)} | {customerDetails?.proprtyDetails?.data?.buildingType} | {customerDetails?.proprtyDetails?.data?.Address} | {customerDetails?.proprtyDetails?.data?.location}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row mb-5">
                                                <div className="col-12 col-sm-4">
                                                    <div className="mb-7">
                                                        <label className="form-label fw-bolder font-16 mb-6">
                                                            {t("Customer Name")} <span className="color-red" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control fw-bolder" placeholder={customerDetails?.name} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <div className="mb-7 date-picker">
                                                        <label className="form-label fw-bolder font-16 mb-6">
                                                            {t("Lease Start Date")} <span className="color-red" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <DatePicker
                                                            onChange={setDateStartValue}
                                                            value={dateStartValue}
                                                            className='form-control fw-bolder form-control-solid'
                                                            format="dd-MM-y"
                                                            minDate={new Date(new Date().toLocaleDateString())}
                                                            clearIcon={null}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <div className="mb-7 date-picker">
                                                        <label className="form-label fw-bolder font-16 mb-6">
                                                            {t("Lease End Date")} <span className="color-red" style={{ color: 'red' }} >*</span>
                                                        </label>
                                                        <DatePicker
                                                            onChange={setDateStartValue}
                                                            value={moment(dateStartValue).add(1, "years").toDate()}//{dateStartValue}
                                                            className='form-control fw-bolder form-control-solid'
                                                            format="dd-MM-y"
                                                            minDate={new Date(new Date().toLocaleDateString())}
                                                            disabled
                                                            clearIcon={null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="mb-10 position-relative">
                                                        <h4 className="form-label fw-bolder font-14 mb-6">{t("Method of Payments")}</h4>
                                                        <select className="form-control form-select" data-control="select2" data-allow-clear="true" onChange={handleDropdown}>
                                                            {/* <option selected disabled>Please Select</option> */}
                                                            <option selected value="CREDIT_CARD">{t("Credit Card")}</option>
                                                            <option value="CHEQUE">{t("Cheque")}</option>
                                                            <option value="PAY_BY_EMP">{t("Payment By Employer")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <div className="mb-10 ps-10 position-relative">
                                                        <h4 className="form-label fw-bolder d-block font-14 mb-9">{t("Choose the number of payments")}</h4>
                                                        <div className="form-check form-check-inline col-2">
                                                            <input className="form-check-input" type="radio" name="period" id="inlineRadio1" value='1' onClick={(e) => setNoOfPayments(1)} checked={noOfPayments === 1 ? true : false} />
                                                            <label className="form-check-label text-black fw-bolder font-14" htmlFor="inlineRadio1">{t("1 Payment")}</label>
                                                        </div>
                                                        <div className="form-check form-check-inline col-3">
                                                            <input className="form-check-input" type="radio" name="period" id="inlineRadio3" onClick={(e) => setNoOfPayments(2)} />
                                                            <label className="form-check-label text-black fw-bolder font-14" htmlFor="inlineRadio3">{t("2 Payment")}</label>
                                                        </div>
                                                        <div className="form-check form-check-inline col-3">
                                                            <input className="form-check-input" type="radio" name="period" id="inlineRadio2" onClick={(e) => setNoOfPayments(3)} />
                                                            <label className="form-check-label text-black fw-bolder font-14" htmlFor="inlineRadio2">{t("3 Payment")}</label>
                                                        </div>
                                                        <div className="form-check form-check-inline col-3">
                                                            <input className="form-check-input" type="radio" name="period" id="inlineRadio4" onClick={(e) => setNoOfPayments(4)} />
                                                            <label className="form-check-label text-black fw-bolder font-14" htmlFor="inlineRadio4">{t("4 Payment")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center mb-5">
                                            <div className="make-an-offer-year p-5 rounded">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h2 className="text-black fw-boldest font-34 mb-5">
                                                            {myOfferPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("AED")}
                                                            <span className="fw-bold font-20">
                                                                /  {t("Year")}
                                                            </span>
                                                        </h2>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 text-center">
                                                            <h6 className="font-13 fw-normal">
                                                                {t("You pay")} <span className="text-black font-14 fw-boldest"> {(myOfferPrice / noOfPayments).toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("AED")} </span> {t("Per Installment")}
                                                            </h6>
                                                        </div>
                                                        {/*<div className="col-6 text-start">*/}
                                                        {/*<div className="mb-5 text-start">*/}
                                                        {/*<div className="bg-white shadow-sm py-2 px-5 rounded-5 d-inline">*/}
                                                        {/*<span className="text-black fw-boldest font-12 c-pointer" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right" data-bs-content="Right popover">*/}
                                                        {/*How renting works*/}
                                                        {/*<img src={WorksIcon} className="img-fluid ms-2" />*/}
                                                        {/*</span>*/}
                                                        {/*</div>*/}
                                                        {/*</div>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-1 text-center">
                                            <p className="text-black font-13 fw-bold">
                                                {t("If you don't think a price is fair, make your own offer for the landlord to consider.")}
                                            </p>
                                        </div>
                                        <div className="col-12 mb-5">
                                            <div className="bg-white pt-5 pb-8 px-10 shadow-sm rounded-5">
                                                <div className="">
                                                    <label htmlFor="customRange1" className="form-label mb-5 text-center text-black fw-bolder font-18 d-block">
                                                        {t("Make an Offer")}
                                                    </label>
                                                    <Nouislider
                                                        start={0}
                                                        step={500}
                                                        onChange={onChange}
                                                        range={{
                                                            min: 0,
                                                            max: 1000000
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isPayByEmployer &&
                                        <><div className="row form-add-design">
                                            <div className="col-12 col-sm-4">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder font-16 mb-6">
                                                        {t("Housing Allowance")} <span className="color-red" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control fw-bolder" placeholder={t("Housing Allowance")} value={allowance} onChange={(e) => setAllowance(e?.target?.value)}
                                                        maxLength={50}
                                                        min="1"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                <p style={{ color: 'red' }}> {allowanceError}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder font-16 mb-6">
                                                        {t("Employer Name")} <span className="color-red" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <input type="text" className="form-control fw-bolder" placeholder={t("Employer Name")} value={employerName} onChange={(e) => setEmployerName(e?.target?.value)}
                                                        onKeyPress={(event) => {
                                                            if (!/[a-zA-z ]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                <p style={{ color: 'red' }}> {employerNameError}</p>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12">
                                                    <div className="mb-7">
                                                        <label className="fw-bolder mb-6"> {t("Customer Name")} </label>
                                                        <input type="text" className="form-control fw-bolder" placeholder={customerDetails?.name} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <div className="mb-7 border rounded p-4">
                                                        <p className="mb-2 text-center"> {t("Lease Start Date")} </p>
                                                        <p className="fw-bolder text-center">{moment(dateStartValue).format('DD-MM-YYYY')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <div className="mb-7 border rounded p-4">
                                                        <p className="mb-2 text-center"> {t("Payment Mode")} </p>
                                                        <p className="fw-bolder text-center">{t("Payment By Employer")}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <div className="mb-7 border rounded p-4">
                                                        <p className="mb-2 text-center"> {t("My Offer")} </p>
                                                        <p className="fw-bolder text-center">{myOfferPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("AED")}</p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <p className="fw-bolder mb-7 text-black"> {t("Add ons")} </p>
                                                    {addOns?.map((addOn: any, index: any) => {
                                                        return (
                                                            <div className="col-md-4 ps-5">
                                                                {addOn?.isMultiple != '1' ? (
                                                                    <div className="form-check form-check-sm custom mb-4">
                                                                        <input className="form-check-input fc-wo-checkbox" type="checkbox" id={`flexCheckDefault1-${addOn.id}`} value={addOn.id} onChange={selectedAddOns} />
                                                                        <label className="form-check-label font-12 fw-normal" htmlFor={`flexCheckDefaul1`}>
                                                                            {addOn?.addOn}
                                                                        </label>
                                                                    </div>
                                                                ) : addOn.isMultiple == '1' ? (
                                                                    <div >
                                                                        <div className="form-check form-check-sm custom mb-4">
                                                                            <input className="form-check-input fc-wo-checkbox" type="checkbox" id={`flexCheckDefault1-${addOn.id}`} value={addOn.id} onChange={selectedAddOns} />
                                                                            <label className="form-check-label font-12 fw-normal" htmlFor={`flexCheckDefaul1`}>
                                                                                {addOn?.addOn}
                                                                            </label>
                                                                        </div>
                                                                        <div className='w-50 mb-2'>
                                                                            <input className="form-control" type="number" />
                                                                        </div>
                                                                    </div>
                                                                ) : null
                                                                }
                                                            </div>)
                                                    })}
                                                </div>
                                            </div>
                                        </>}
                                    <div className="row">
                                        <div className="col-12 text-end">
                                            <button onClick={handleSubmit}
                                                type='submit' className='btn btn-primary fw-bold px-10 py-3'
                                            >
                                                {t("Make an Offer")}
                                            </button>
                                            <p ref={ref} data-bs-target="#make-offer-popUp" data-bs-toggle="modal" >
                                                {/*  */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { MakeAnOfferPage }
