import React, { FC, useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap-v5'
// import { Redirect, Route, Switch } from 'react-router-dom'
import axios from 'axios'
import { APIs } from '../../constants/axios/endpoints'
import { useTranslation } from 'react-i18next'

const ProfileSetting: FC = () => {
	const [showButton, setShowButton] = React.useState(true)
	const [loading, setLoading] = useState(false)
	const [show, setShow] = useState(false)
	const [showMsg, setShowMsg] = useState('')
	const [shown, setShown] = React.useState(false)
	const [shown1, setShown1] = React.useState(false)
	const [shown2, setShown2] = React.useState(false)
	const [pError, setPError] = useState(false)
	const [cError, setCError] = useState(false)
	const [currentPasswords, setCurrentPassword] = React.useState('')
	const [passwords, setPassword] = React.useState('')
	const [cPasswords, setCPassword] = React.useState('')
    const { t } = useTranslation();

	const currentPassword = (password: any) => {
		setCurrentPassword(password.target.value)
	}
	const password = (password: any) => {
		if (password.target.value.length > 30) {
			setPError(true)
			setShowButton(false)
		} else {
			setPError(false)
			setPassword(password.target.value)
		}
	}
	const cPassword = (password: any) => {
		setCPassword(password.target.value)
		if (passwords === password.target.value) {
			setCError(false)
			setShowButton(false)
		} else {
			setCError(true)
			setShowButton(true)
		}
	}

	const handleSubmit = async () => {
		setLoading(true)
		setShowButton(true)
		var data = {
			old_password: currentPasswords,
			password: passwords,
			confirm_password: cPasswords,
		}
		const res = await axios.post(`${APIs.URL}/updatePassword`, data)
		if (res?.data?.code === 200 && res?.data?.description === 'Password Updated Successfully') {
			setLoading(false)
			setShowButton(false)
			setLoading(false)
			setShow(true)
			setShowMsg(res?.data?.description)
			setTimeout(() => {
				setShow(false)
				window.location.href = '/'
			}, 2000)
		} else {
			setLoading(false)
			setShowButton(false)
			setShow(true)
			setShowMsg(res?.data?.errorDescription)
			setTimeout(() => {
				setShow(false)
			}, 2000)
		}
	}
	return (
		<>
			<div className='row gy-5 g-xl-8 dashboard-page'>
				<div className='col-xxl-12 mt-0'>
					<div className='row'>
						<div className='col-md-12 col-12'>
							<h4 className='card-title font-18 text-black mb-6 fw-bolder'> {t("Settings")} </h4>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12 col-12'>
							<div className='card mb-5 mb-xl-10 rounded-10'>
								<div className='card-body py-9 px-6 px-md-14'>
									<div className='row form-add-design'>
										{loading ? <Spinner animation='border' variant='primary' /> : null}
										{show ? (
											<Alert className='font-14 fw-bold mb-8' variant='info'>
												{showMsg}!
											</Alert>
										) : null}
										<input type='hidden' />
										<div className='col-12 col-md-4'>
											<div className='mb-10 pt-password'>
												<label className='form-label fw-bolder'>
													{t("Current Password")} <span className='text-danger me-1'>*</span>
												</label>
												<div className='d-flex bg-dark-grey'>
													<input
														type={shown ? 'text' : 'password'}
														onChange={currentPassword}
														className='form-control bg-dark-grey border-0'
													/>
													<button className='btn border-0' onClick={() => setShown(!shown)}>
														{shown ? (
															<i className='bi bi-eye font-17'></i>
														) : (
															<i className='bi bi-eye-slash font-17'></i>
														)}
													</button>
												</div>
											</div>
										</div>
										<div className='col-12 col-md-4'>
											<div className='mb-10 pt-password'>
												<label className='form-label fw-bolder'>
													{t("New Password")} <span className='text-danger me-1'>*</span>
												</label>
												<div className='d-flex bg-dark-grey'>
													<input
														type={shown1 ? 'text' : 'password'}
														onChange={password}
														className='form-control bg-dark-grey border-0'
													/>
													<button className='btn border-0' onClick={() => setShown1(!shown1)}>
														{shown1 ? (
															<i className='bi bi-eye font-17'></i>
														) : (
															<i className='bi bi-eye-slash font-17'></i>
														)}
													</button>
												</div>
												{pError ? (
													<div className='text-danger'>
														{t("Password cannot be more than 30 characters long!")}
													</div>
												) : null}
											</div>
										</div>
										<div className='col-12 col-md-4'>
											<div className='mb-10 pt-password'>
												<label className='form-label fw-bolder'>
													{t("Confirm Password")} <span className='text-danger me-1'>*</span>
												</label>
												<div className='d-flex bg-dark-grey'>
													<input
														type={shown2 ? 'text' : 'password'}
														onChange={cPassword}
														className='form-control bg-dark-grey border-0'
													// onKeyPress={() => {
													//   passwords === cPasswords ? setCError(false) : setCError(true)
													// }}
													/>
													<button className='btn border-0' onClick={() => setShown2(!shown2)}>
														{shown2 ? (
															<i className='bi bi-eye font-17'></i>
														) : (
															<i className='bi bi-eye-slash font-17'></i>
														)}
													</button>
												</div>
												{cError ? (
													<div className='text-danger'>
														{t("The two passwords that you entered do not match!")}
													</div>
												) : null}
											</div>
										</div>
										<div className='col-12 text-end'>
											<button
												className='btn btn-primary fw-bold px-10 py-3'
												onClick={handleSubmit}
												disabled={showButton}
											>
												{t("Save")}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export { ProfileSetting }
