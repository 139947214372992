import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {RecentRequests} from './components/RecentRequests/RecentRequests'
import {QuickLinks} from './components/QuickLinks/QuickLinks'
import {Announcements} from './components/Announcements/Announcements'
import {MarketPlace} from './components/MarketPlace/MarketPlace'

const FMTeamDashboardPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
        <div className='col-xxl-12 mt-0'>
            <RecentRequests />
            <QuickLinks />
            <Announcements />
            <MarketPlace />
        </div>
    </div>
    </>
);

const FMTeamDashboardWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FMTeamDashboardPage />
        </>
    )
};

export {FMTeamDashboardWrapper}
