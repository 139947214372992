import React, { useState, useEffect } from 'react'

export function PropertyDetails() {
    return (      
        <>
        <div id="property-details" className="property-details mt-2 active">
            <div className="row">
                <div className="col-md-12 mb-3 col-12">
                    <div className="card radius-15">
                        <div className="card-body">
                            <div className="row">
                                <h5 className="font-16 font-weight-semibold text-black"> About community</h5>
                                <p className="font-12">NATURE AT YOUR DOORSTEP The ideal residential getaway for those who love nature, value tranquility and privacy, yet desire to be within close distance to many of Dubai’s world-class attractions, landmarks and transport links. Offering a true urban oasis in the heart of Dubai, each residence is influenced by modern London architecture, integrating nature in the right spaces with avenues and greenways that links the indoors and the outdoors. Wilton Park Residences creates the ideal art of living well combining city and suburb living without compromising elegance, functionality and convenience at your doorstep. PROJECT CONCEPT Wilton Park Residences comes with a living experience concept that is considered unique especially in the community of Mohammed Bin Rashid City. The development envisages to create a multifamily community inspired by high quality lifestyles achieved through architecture and design that transcends fashion and trends, efficient space planning and elegant details. It is also designed to let its residents experience a balanced indoor and outdoor lifestyle where the apartments and residences flow uninterrupted into the outdoor spaces, seamlessly extending the interiors. Every element of these 12-storied residential twin towers has been carefully considered, with each detail contributing to inspire contemporary European style living: from the selection of high-quality finishes, intricate textures and its unique colour palette, brought together with seamless skill and finesse. Wilton Park Residences is an icon of understated and restrained elegance comprising of perfectly crafted studios, 1 & 2 bedrooms. The perfect investment for home buyers who desire a harmonious relationship between aesthetic design, privacy and community living in Dubai.</p>
                                <div className="col-md-6 col-12">
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                        <label className="font-12 font-weight-semibold mb-0 col-sm-6 col-auto text-blue-theme"> Start Date : </label> 
                                        <div className="col-auto">
                                            <span className="font-weight-semibold text-black font-12">31/12/2020</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                        <label className="font-12 font-weight-semibold mb-0 col-sm-6 col-auto text-blue-theme"> End Date : </label>
                                        
                                        <div className="col-auto ">
                                            <span className="font-weight-semibold text-black font-12">19/12/2020</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                        <label className="font-12 font-weight-semibold mb-0 col-sm-6 col-auto text-blue-theme"> Property Stage : </label>
                                        
                                        <div className="col-auto ">
                                            <span className="font-weight-semibold text-black font-12">Stage 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="font-16 mb-0 font-weight-semibold text-black"> Amenities </h5>
                                    <hr className=""/>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Power Backup </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Swimming Pool </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Golf Course </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Bar </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Squash courts </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

