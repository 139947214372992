import moment from 'moment';
import React, { useState, useEffect } from 'react';
import GoogleAds from '../../../../../../../../assets/images/google-ads-icon.svg';
import DatePicker from 'react-date-picker';
import { RootState } from '../../../../../../../../constants';
import { useSelector } from 'react-redux';
import { checkEmirates } from '../../../../../../../../constants/axios/apis';

export function StepOne(props: any) {
    let offerList: any
    offerList = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)
    const [value, onChange] = useState(moment().subtract(18, "years").toDate());
    const [value1, onExpiryDate] = useState(new Date());
    const [value2, onPassportExpiryDate] = useState(new Date());
    const { tenantDetails } = props
    const { tenant } = props
    const Tdata = tenant?.length > 0 ? JSON.parse(tenant) : ''
    const { formErrors } = props
    const [checkEmiratesId, setCheckEmiratesId] = useState<any>("");
    const [checkEmiratesIdState, setCheckEmiratesIdState] = useState<boolean>(false);
    const [emiratesID, setEmiratesID] = useState(Tdata?.emiratesID?.length > 0 ? Tdata?.emiratesID : '')
    const [tName, setTName] = useState(offerList?.customerName)
    const [tLastName, setTLastName] = useState(offerList?.customerLastName)
    const [dob, setDOB] = useState(Tdata?.dob1?.length > 0 ? new Date(Tdata?.dob1) : moment().subtract(18, "years").toDate());
    const [dob1, setDOB1] = useState('');
    const [emiratesIdExpiry, setEmiratesIdExpiry] = useState(Tdata?.emiratesIdExpiry1?.length > 0 ? new Date(Tdata?.emiratesIdExpiry1) : new Date());
    const [emiratesIdExpiry1, setEmiratesIdExpiry1] = useState("");
    const [passport, setPassport] = useState(Tdata?.passport?.length > 0 ? Tdata?.passport : '')
    const [passportExpiry, setPassportExpiry] = useState(Tdata?.passportExpiry1?.length > 0 ? new Date(Tdata?.passportExpiry1) : new Date());
    const [passportExpiry1, setPassportExpiry1] = useState("");

    tenantDetails(
        JSON.stringify({
            emiratesID,
            tName,
            tLastName,
            dob1,
            emiratesIdExpiry1,
            passport,
            passportExpiry1,
            checkEmiratesIdState
        }))

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    useEffect(() => {
        setDOB1(moment(dob).format('YYYY-MM-DD'))
        setEmiratesIdExpiry1(moment(emiratesIdExpiry).format('YYYY-MM-DD'))
        setPassportExpiry1(moment(passportExpiry).format('YYYY-MM-DD'))
    }, [dob, emiratesIdExpiry, passportExpiry])
    useEffect(() => {
		var FormData = require('form-data')
		var pdcDetails = new FormData()
		pdcDetails.append('emirates_id', emiratesID);
        checkEmirates(pdcDetails).then((data) => {
			if (data.code === 200 && data.errorCode != 1 && data?.data==='true') {
                setCheckEmiratesId(data.description)
                setCheckEmiratesIdState(true)
			}else{
                setCheckEmiratesId("")
                setCheckEmiratesIdState(false)
			}
		})
    }, [emiratesID])
    return (
        <div className="tenant-form py-6 step-one">
            <div className="mb-5">
                <label className="form-label text-black"> Emirates ID Number <span className="text-danger">*</span> </label>
                <input type="text" name="emirate_id" className="form-control text-black" placeholder="Emirates ID Number"
                    maxLength={19}
                    onChange={(e) => setEmiratesID(e?.target?.value)}
                    value={emiratesID}
                    onKeyPress={(event) => {
                        if (!/[a-zA-z0-9-]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    autoComplete="off"
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }} onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                // style={{ border: emiratesID?.length > 0 ? '1px solid green' : '1px solid red' }}
                />
                <p style={{ color: "red" }}>
                    {formErrors?.emiratesID}
                </p>
                <p style={{ color: "red" }}>
                    {checkEmiratesId}
                </p>
            </div>
            <div className="mb-5">
                <label className="form-label text-black"> First Name <span className="text-danger">*</span> </label>
                <input type="text" name="username" className="form-control text-black" placeholder="First Name" maxLength={30}
                    onChange={(e) => setTName(e?.target?.value)}
                    value={tName}
                    onKeyPress={(event) => {
                        if (!/[a-zA-z ]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    autoComplete="off"
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }} onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                />
                <p style={{ color: "red" }}>
                    {formErrors?.tName}
                </p>
            </div>
            <div className="mb-5">
                <label className="form-label text-black"> Last Name </label>
                <input type="text" name="username" className="form-control text-black" placeholder="Last Name" maxLength={30}
                    onChange={(e) => setTLastName(e?.target?.value)}
                    value={tLastName}
                    onKeyPress={(event) => {
                        if (!/[a-zA-z ]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    autoComplete="off"
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }} onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                />
                {/* <p style={{ color: "red" }}>
                    {formErrors?.tLastName}
                </p> */}
            </div>
            <div className="mb-5 date-picker"
                onKeyPress={(event) => {
                    if (!/[a-zA-z]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}>
                <label className="form-label text-black"> Date of Birth <span className="text-danger">*</span> </label>
                {/* <input type="date" name="dob" className="form-control" placeholder="Date of Birth" onChange={(e) => setDOB(e?.target?.value)}
                    max={moment().format("YYYY-MM-DD")}
                disabled={tName?.length > 3 ? false : true}
                title={tName?.length > 3 ? '' : 'Please fill all values'}
                style={{
                    border: dob?.length > 0 ? '1px solid green' : '1px solid red',
                    cursor: tName?.length > 3 ? 'text' : 'not-allowed'
                }}
                /> */}
                <DatePicker
                    onChange={setDOB}
                    value={dob}
                    format='dd-MM-y'
                    minDate={moment().subtract(500, "years").toDate()}
                    maxDate={moment().subtract(18, "years").toDate()}
                    clearIcon={null}
                    className="form-control fw-bolder form-control-solid text-black"
                />
            </div>
            <div className="mb-5 date-picker"
                onKeyPress={(event) => {
                    if (!/[a-zA-z]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}>
                <label className="form-label text-black"> Emirates ID Expiry Date <span className="text-danger">*</span>   </label>
                <DatePicker
                    onChange={setEmiratesIdExpiry}
                    value={emiratesIdExpiry}
                    format='dd-MM-y'
                    minDate={moment().toDate()}
                    className="form-control fw-bolder form-control-solid text-black"
                    clearIcon={null}
                />
            </div>
            <div className="mb-5">
                <label className="form-label text-black">   Passport Number <span className="text-danger">*</span> </label>
                <input type="text" name="passport_number " className="form-control text-black" placeholder="Passport Number"
                    maxLength={30} onChange={(e) => setPassport(e?.target?.value)}
                    value={passport}
                    onKeyPress={(event) => {
                        if (!/[a-zA-z0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    autoComplete="off"
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }} onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                />
                <p style={{ color: "red" }}>
                    {formErrors?.passport}
                </p>
            </div>
            <div className="mb-5 date-picker"
                onKeyPress={(event) => {
                    if (!/[a-zA-z]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}>
                <label className="form-label text-black"> Passport Expiry Date <span className="text-danger">*</span>  </label>
                <DatePicker
                    onChange={setPassportExpiry}
                    value={passportExpiry}
                    format='dd-MM-y'
                    minDate={moment().toDate()}
                    clearIcon={null}
                    className="form-control fw-bolder form-control-solid text-black"
                />
            </div>
        </div>
    );
}