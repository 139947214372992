import React, { useState, FC } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom';
import {Link} from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import {RaiseProformaTabs} from './components/RaiseProformaTabs/RaiseProformaTabs';
import {NewChequeDetails} from './components/NewChequeDetails/NewChequeDetails';
import {OpenChequeDetails} from './components/OpenChequeDetails/OpenChequeDetails';
import {RaiseProformaDetails} from './components/RaiseProformaDetails/RaiseProformaDetails';

const RaiseProformaWrapper: React.FC = () => {
    return (
        <>
        <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="row">
                    <div className="col-12">
                        <h4 className="card-title font-18 text-black mb-6">
                            <Link to={'/pm-unrenewed-leases'} className=''>
                                <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            </Link>
                            Raise Proforma
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-7">
                       <RaiseProformaDetails />
                    </div>
                </div>
                <Switch>
                    <Route path='/pm-raise-proforma/open-cheque-details'>
                        <RaiseProformaTabs />
                        <OpenChequeDetails />
                    </Route>
                    <Route path='/pm-raise-proforma/new-cheque-details'>
                        <RaiseProformaTabs />
                        <NewChequeDetails />
                    </Route>

                </Switch>
            </div>
        </div>
        </>
    );
}

export {RaiseProformaWrapper}

