/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { WelcomeBanner } from './components/WelcomeBanner/WelcomeBanner'
import { MarketPlace } from './components/MarketPlace/MarketPlace'
import { Announcements } from './components/Announcements/Announcements'
import { useSelector } from 'react-redux'
import { RootState } from '../../constants'
import { ScheduleMeeting } from '../my-services/add/ScheduleMeeting/ScheduleMeeting'
import { MyServicesList } from '../my-services/components/MyServicesList/MyServicesList'
import { getServiceRequestsCategories } from '../../constants/axios/apis'

interface Props {
  contractData: any
  services?: any
}
const DashboardPage: FC<Props> = ({ contractData, services }) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        <WelcomeBanner contract={contractData} />
        <MyServicesList services={services} />
        <ScheduleMeeting contract={contractData} />
        <Announcements />
        <MarketPlace />
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [services, setServices] = useState<any>()

  useEffect(() => {
    getServiceRequestsCategories().then(({ data: serviceRequests }) => {
      if (serviceRequests) {
        setServices(serviceRequests)
      }
    })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage contractData={currentContract} services={services} />
    </>
  )
}

export { DashboardWrapper }
