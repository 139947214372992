import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
//import {Link} from 'react-router-dom'
import {MyTeamWrapper} from './components/MyTeamWrapper';
import {MyTeamTechniciansTable} from './components/Technicians/MyTeamTechnicians/MyTeamTechnicians';
import {RecentWorkOrdersTable} from './components/RecentWorkOrders/RecentWorkOrders';

import { useSelector } from 'react-redux';
import { getFcsMyTeamList, getFcsWorkOrders } from '../../utils/makeRequest';
import { IState } from '../../utils/interface';
import { RootState } from '../../constants';
import { getWorkOrdersList } from '../../constants/axios/apis';
import { useTranslation } from 'react-i18next';

const FCSupervisorMyTeamPage: FC = () => {
    const { t } = useTranslation();
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)
    const [searchItem, setSearchItem] = useState<any>('');
    const [activeButton, setActiveButton] = useState<any>("technician");
    // const state:any = useSelector(state => state);
    // const userToken = state?.auth?.accessToken;
    // if(userToken === "undefined"){
    //     // token undefined
    // }

    const [fcsWorkOrderList, setFcsWorkOrderList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    // useEffect(()=>{
    //     getFcsWorkOrders(setFcsWorkOrderList,userToken);
    // },[]);

    useEffect(()=>{
        setFcsWorkOrderList({ loading: true, response: [], error: '' })
        const payload = currentBuilding ? "?building_id=" + currentBuilding : "?building_id=0"
        getWorkOrdersList(payload).then(({ data }) => {
            setFcsWorkOrderList({
                loading: false,
                response: (data && Array.isArray(data)) ? data : [],
                error: ''
            })
        }).catch((e)=>{setFcsWorkOrderList({
            loading: false,
            response: [],
            error: t('Some error occured!'),
        })})
    }, [currentBuilding])

return (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <MyTeamWrapper fcsWorkOrderList={fcsWorkOrderList}/>
                {/* <MyTeamWrapper fcsMyTeamList={fcsMyTeamList}/> */}
                <div className="col-md-12 col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-md-6 col-12">
                                    <div className='d-flex py-2'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <a  onClick={()=>{ setActiveButton('technician') }}
                                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'technician' && 'active')}> {t("Technicians")} </a>
                                            </li>
                                            {/* <li className='nav-item'>
                                                <a  onClick={()=>{ setActiveButton('fcss') }}
                                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'fcss' && 'active')}> FCSS </a>
                                            </li> */}
                                            <li className='nav-item'>
                                                <a  onClick={()=>{ setActiveButton('workOrder') }}
                                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'workOrder' && 'active')}> {t("Recent Work Orders")} </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                                <div className='d-flex pm-topbar-buttons float-end'>
                                <div className='form-group has-search'>
                                    <span className='fa fa-search pt-2 form-control-feedback'></span>
                                    <input
                                    type='text'
                                    className='form-control ps-10'
                                    onChange={(e) => setSearchItem(e.target.value)}
                                    placeholder={t('Search')}
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                { activeButton === 'technician' ?
                    // <MyTeamTechniciansTable fcsMyTeamList={fcsMyTeamUsers}/>
                    <MyTeamTechniciansTable searchItem={searchItem} setSearchItem={setSearchItem} filterRole={'FC'}/>
                    // : activeButton === 'fcss' ? 
                    // <MyTeamTechniciansTable filterRole={'FCSS'}/>
                    :<RecentWorkOrdersTable fcsWorkOrderList={fcsWorkOrderList}/>
                }
            </div>
        </div>
    </>
    );

}

const FCSupervisorMyTeamWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FCSupervisorMyTeamPage />
        </>
    )
};

export {FCSupervisorMyTeamWrapper}
