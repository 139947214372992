import React from 'react'

import { useTranslation } from 'react-i18next';

export function ImageViewerWithPopup({imageFile, noImage=false, imgID='img'}: any) {
    const { t } = useTranslation();
    if(noImage){
        return(
            <div className="position-relative">
                {t("No image")}
            </div>
        )
    }else{
        return(
            <>
                <div className="position-relative">
                    <div className="upload-image mt-0">
                        <button
                            className='border-0 bg-transparent p-0'
                            data-bs-target={'#image-view-popUp'+imgID}
                            data-bs-toggle='modal'
                            data-bs-dismiss='modal'
                            type='button'
                        >
                            <img src={imageFile} alt="Preview" className="form-img__img-preview"/>
                            {/* <div className="close-icon" onClick={()=>{}}>
                                <i className="bi bi-eye text-black font-17"></i>
                            </div> */}
                        </button>
                    </div>
                </div>
                <div className='modal fade add-broker-modal' aria-hidden='true' id={'image-view-popUp'+imgID}>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content p-2'>
                            <div className='modal-header p-0'>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                ></button>
                            </div>
                            <div className='modal-body px-0 pb-0 pt-0'>
                                <div className='m-5'>
                                    <div className='row'>
                                        <img src={imageFile} className='img-fluid va-baseline' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
