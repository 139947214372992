/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
// import { MdArrowBackIosNew } from 'react-icons/md';
// import { MdKeyboardArrowLeft, MdArrowBackIosNew, MdChevronLeft, MdOutlineArrowBackIosNew, MdOutlineArrowBackIos, MdArrowBackIos } from 'react-icons/md';
// import { MdKeyboardArrowRight } from 'react-icons/md';
// import { IoIosArrowBack } from 'react-icons/io';
// import { IoChevronBackOutline, IoChevronBack } from 'react-icons/io5';
import { BsDash } from 'react-icons/bs';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';

type Props = {
    pageSize: number,
    totalCount: number
    defaultPage: number,
    onPageChange: any,
    loading: boolean
}

const CompactPagination: React.FC<Props> = ({pageSize, totalCount, defaultPage, onPageChange, loading=false }) => {
    const [startVal, setStartVal] = useState<number>(0)
    const [endVal, setEndVal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(defaultPage)
    const [lastPage, setLastPage] = useState<number>(0)
    // console.log(startVal, endVal, currentPage, lastPage, totalCount, pageSize, Math.ceil(totalCount / pageSize));
    useEffect(()=>{
        setCurrentPage(defaultPage)
    }, [defaultPage])

    useEffect(()=>{
        if(totalCount > 0){
            setStartVal(pageSize*(currentPage-1)+1)
            const temp = currentPage*pageSize
            setEndVal(temp > totalCount ? totalCount : temp)
            setLastPage( Math.ceil(totalCount / pageSize))
        }
    }, [totalCount, pageSize])

    useEffect(()=>{
        setStartVal(pageSize*(currentPage-1)+1)
        const temp = currentPage*pageSize
        setEndVal(temp > totalCount ? totalCount : temp)
        onPageChange(currentPage)
    }, [currentPage])

    const handleBack = () => {
        if(currentPage > 1)
            setCurrentPage((oState)=> oState -1 )
    }

    const handleNext = () => {
        if(currentPage < lastPage)
            setCurrentPage((oState)=> oState +1 )
    }
    // console.log(defaultPage, startVal);
    return (
        <>
            <div className="text-end font-14 text-black py-5 pagination-sec-table">
                <span className="">{startVal}</span>
                <span className=""><BsDash /></span>
                <span className="">{endVal}</span>
                <span className="px-1">of</span>
                <span className="pe-5">{totalCount}</span>
                <span className="">
                    { currentPage > 1 && !loading?
                        <button className="text-primary bg-white border-0 me-5"
                            onClick={handleBack}
                        >
                            <IoChevronBackSharp />
                        </button>
                    :
                        <button className="text-secondary bg-white border-0 me-5" disabled>
                            <IoChevronBackSharp />
                        </button>
                    }
                </span>
                <span className="">
                    { currentPage < lastPage && !loading?
                        <button className="text-primary bg-white border-0"
                            onClick={handleNext}
                        >
                            <IoChevronForwardSharp />
                        </button>
                    :
                        <button className="text-secondary bg-white border-0" disabled>
                            <IoChevronForwardSharp />
                        </button>
                    }
                </span>
            </div>
        </>
    )
}

export { CompactPagination }
