import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getApprovals } from '../../../constants/axios/apis';
import { CustomLoader2 } from '../../../constants/generics/customActios';
import { getUserRole } from '../../../constants/generics/userCheck';

export function FCSSApprovalHandler() {

    const history = useHistory()
    let params: any = {};
    params = useLocation();
    const ApprovalID = params.state.approval.id
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    
    useEffect(() => {
        getApprovals(`?id=${ApprovalID}`).then((response: any) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                let url = ''
                const approvalData = response.data[0]
                if(approvalData.subReference === 6){
                    //Moveout
                    if(user_role === 'FCSS'){
                        if( approvalData.handoverStatus === 'Closed')
                            url = '/fcss-moveoutDetails1/building-access-card'
                        else
                            url = '/fcss-moveoutDetails/building-access-card'
                    }else{
                        if(approvalData.status === 'Approved')
                            url = '/fcss-moveoutDetails1/building-access-card'
                        else
                            url = '/fcss-moveoutDetails/building-access-card'
                    }
                }else{
                    //Movein
                    if(user_role === 'FCSS'){
                        if( approvalData.status === 'Approved')
                            url = '/fcss-moveinDetails1/building-access-card'
                        else
                            url = '/fcss-moveinDetails/building-access-card'
                    }else{
                        if(approvalData.handoverStatus === 'Closed')
                            url = '/fcss-moveinDetails1/building-access-card'
                        else
                            url = '/fcss-moveinDetails/building-access-card'
                    }
                }
                
                history.push({
                    pathname: url,
                    state: {approval: approvalData}
                })
            }else{

            }
        })
    }, [ApprovalID])

    return <>
        <CustomLoader2 height={'166'}/>
    </>
}