import React from 'react'
import {useIntl} from 'react-intl'

export function Search() {
    const intl = useIntl()
    return (
        <>
        <div className='row'>
            <div className='col-lg-12 pe-3 my-auto'>
                <div className="input-group custom-search-form">
                    <input type="text" className="form-control search-input" />
                    <span className="input-group-btn">
                        <button className="search_icon" type="button">
                            <span className=""> </span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        </>
    );
}
