import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import MoveOutIcon from "../../../../assets/images/move-out-icon.svg";
import MaintenanceIcon from "../../../../assets/images/Maintenance_Active.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'
import Loader from '../../../../components/Loader'
import { formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import moment from 'moment';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';

import { useTranslation } from 'react-i18next';


export const SupervisorRecentRequests:React.FC<any> = ({fcsWorkOrderList, noncompletedWorkOrders}) => {
    const {loading, response, error} = fcsWorkOrderList;

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body px-7 py-7">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h4 className="card-title">{t("Actions Required")}</h4>
                                </div>
                                {noncompletedWorkOrders?.length > 2 ? (
                                    <div className="col-md-6 col-12 d-flex justify-content-end align-items-center pe-md-20">
                                        <Link to={'/facility-workorder/list/corrective/open'} className="text-decoration-none view-all">
                                            <span> {t("View All")} </span>
                                            <img src={RightArrow} className="img-fluid" />
                                        </Link>
                                    </div>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            <div className="rec-request-sec">
                                <div>
                                    <div className="row">
                                        {loading && (
                                            <CustomLoader2 height={'166'}/>
                                        )}
                                        {!loading && (
                                            <div className="col-12 p-0">
                                                { noncompletedWorkOrders?.length ? 
                                                    (
                                                        <Slider {...settings}>
                                                            {noncompletedWorkOrders.map((recent: any, index: number)=>{
                                                                // const viewLink = recent.issueCategory === "Clearance" ?
                                                                //     ( recent.status === 'Completed' ? '/fc-workorder/moveoutinspection/completed'
                                                                //     : recent.status === 'Confirmed' ? '/fc-workorder/moveoutinspection/confirmed'
                                                                //     : recent.status === 'Inprogress' ? '/workorder/details'
                                                                //     : recent.status === 'Open' ? '/workorder/details'
                                                                //     : '/'
                                                                //     ): '/workorder/details'

                                                                const linkText = 
                                                                    recent.status === 'Open' && (!recent.assignedTo || recent.assignedTo === '') ? (t('Assign'))
                                                                    : recent.status === 'Inprogress' ? (t('View'))
                                                                    : recent.issueCategory === "Clearance" && recent.status === 'Confirmed' ? (t('Add Pricing'))
                                                                    : recent.status === (t('On hold')) ? (t('Re-Assign')): (t('View'))

                                                                return (
                                                                    <div key={index}>
                                                                        {/* <Link to={'/moveoutapproval'} className="text-decoration-none"> */}
                                                                        <Link to={{'pathname':'/facility-workorder/view', state: {'workOrder': recent}}}>
                                                                        {/* <Link to={{'pathname':viewLink, state: {'workOrder': recent}}}> */}
                                                                            <div className="card bg-white border mt-4">
                                                                                <div className="card-body px-8 py-7">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-8 col-md-12">
                                                                                            <div className="mb-3">
                                                                                                <img src={recent.typeText =="Move Out" ? MoveOutIcon: MaintenanceIcon}
                                                                                                     className="img-fluid me-8 d-inline"
                                                                                                     style={{filter:'grayscale(1)'}}
                                                                                                />
                                                                                                <span className="card-title">
                                                                                                    {recent.typeText}
                                                                                                </span>
                                                                                            </div>
                                                                                            {/* <h6 className="font-14 fw-normal text-black">
                                                                                                Action Required
                                                                                            </h6> */}
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12 mb-2">
                                                                                                    <p className="mb-0 text-muted">{t("Issue")}</p>
                                                                                                    <p className="mb-0 text-dark fcs-dashboard-request fcs-unit-number">{recent.issueCategory === 'Clearance' ? recent.typeText === 'Move Out' ? 'Move Out Inspection' : recent.typeText : recent.subCategory} ({recent.status})</p>
                                                                                                </div> 
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6 pe-0">
                                                                                                    <p className="mb-0 text-muted">{t("Unit Number")}</p>
                                                                                                    <p className="mb-0 text-dark font-12 fcs-unit-number">{recent.property_code+" "}</p>
                                                                                                </div>
                                                                                                <div className="col-sm-6 pe-0">
                                                                                                    <p className="mb-0 text-muted">{t("Date/Time")}</p>
                                                                                                    <p className="mb-0 text-dark font-12 fcs-unit-number">
                                                                                                        {moment(recent.date).format("DD-MM-YYYY")} {' '}
                                                                                                        {/* {formatTimeMeridiem(recent.time)} */}
                                                                                                        {recent.issueCategory === "Clearance" ? recent?.objectDetails?.referenceDetails?.time_slot : formatTimeMeridiem(recent.time)}

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4 text-end mt-auto">
                                                                                            <button className="btn btn-primary rounded w-100 py-2 mt-13">
                                                                                                {linkText}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Slider>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <NoDataFound1 height={'110'} />
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}