import React, { useState, useEffect, useRef } from 'react';
import WorkOrdersImg from "../../../../assets/images/ac-repair.png";
import {Uploaded} from '../EditPropertyDetails/Uploaded';
import { useTranslation } from 'react-i18next'

export function UploadImage() {
    const [file, setFile] = useState([] as any);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const { t } = useTranslation();

    function uploadSingleFile(e:any) {
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        console.log("file", file);
    }
    const [visible, setVisible] = useState(true);
    function removeImg() {
        setVisible(false);
    }
    const [{alt, src}, setImg] = useState({
        src: WorkOrdersImg,
        alt: 'Upload an Image'
    });
    function upload(e:any) {
        e.preventDefault();
        console.log(file);
    }
    function deleteFile(e:any) {
        const s = file.filter((item:any, index:any) => index !== e);
        setFile(s);
        console.log(s);
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };
    return (
        <div className="property-details-edit-upload">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="property-details-edit-upload-input">
                        <Uploaded />
                        <div className="">
                            {file.length > 0 &&
                            <div className="position-relative">
                                {file.map((item:any, index:any) => {
                                    return (
                                        <div className="upload-image" key={item}>
                                            <img src={item} alt="" />
                                            <div className="close-icon" onClick={() => deleteFile(index)}>
                                                &times;
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="properties_buttons float-end">
                        <div className="mb-7 upload-input text-end">
                            <input ref={inputRef} onChange={uploadSingleFile} className="d-none" type="file" multiple/>
                            <button onClick={handleUpload} className={`btn btn-primary fw-bold px-3 py-3-${uploadedFileName ? "primary" : "primary"}`}>
                                <span>{uploadedFileName ? uploadedFileName : <span>{t("Add Photo")}  <i className="bi bi-plus-circle-fill align-middle ms-2 font-18"></i></span>} </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}