import React from 'react';
import { useTranslation } from 'react-i18next'

export function Details({ teamDetailData }: any) {
    let userEmail = "";
    let userDetail = localStorage.getItem("userDetail");
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email;
    };
    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2"> {t("First Name")} </label>
                    <span className="text-black font-15 fw-bold"> {teamDetailData?.data?.first_name ?teamDetailData?.data?.first_name :'-'} </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2"> {t("Last Name")} </label>
                    <span className="text-black font-15 fw-bold"> {teamDetailData?.data?.last_name?teamDetailData?.data?.last_name:'-'} </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2"> {t("Emirates Id")} </label>
                    <span className="text-black font-15 fw-bold"> {teamDetailData?.data?.emirates_id?teamDetailData?.data?.emirates_id:'-'} </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">{t("Mobile Number")} </label>
                    <span className="text-black font-15 fw-bold"> {teamDetailData?.data?.phone?teamDetailData?.data?.phone:'-'} </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">{t("Email Address")} </label>
                    <span className="text-black font-15 fw-bold"> {teamDetailData?.data?.email?teamDetailData?.data?.email:'-'} </span>
                </div>
            </div>
            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">{t("Alternate Contact No.")}</label>
                    <span className="text-black font-15 fw-bold"> {'-'} </span>
                </div>
            </div> */}
            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">{t("Preferred Language")}</label>
                    <span className="text-black font-15 fw-bold"> {'-'} </span>
                </div>
            </div> */}
            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">{t("Emergency Contact Name")}</label>
                    <span className="text-black font-15 fw-bold"> {'-'} </span>
                </div>
            </div> */}
            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">{t("Emergency Contact Number")}</label>
                    <span className="text-black font-15 fw-bold"> {'-'} </span>
                </div>
            </div> */}
        </div>
    );
}