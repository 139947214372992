import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import { pmGetLeadsList } from '../../../../constants/axios/apis'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import moment from 'moment'
import { CustomLoader, NoDataFound } from '../../../../constants/generics/customActios'
import { formatDate } from '../../../../constants/generics/dateTimeFormater'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination'

export function Rejected() {
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(10)
    const [leadsData, setLeadsData] = useState<any>([])
    const [totalLeadsList, setTotalLeadsList] = useState<any>([])
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        setCurrentPage(1)
    }, [currentBuilding])

    
    useEffect(() => {
        setLoading(true)
        const qry = `id_sort=1&currentPage=${currentPage}&pageSize=${pageSize}&building_id=${currentBuilding && currentBuilding !== '' ? currentBuilding : '0'}&status=rejected` //building_id=${currentBuilding}
        pmGetLeadsList(qry)
            .then((response: any) => {
                setLoading(false)
                if (response?.status === 200) {
                    setLeadsData(response?.data)
                    setTotalLeadsList(response?.data?.dataCount)
                } else {
                    setLeadsData(response)
                }
            })
            .catch((e) => { })
    }, [currentPage, currentBuilding])

    const handleClick = (newCurrentPage: any) => {
        setCurrentPage(newCurrentPage)
    }
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handleClick(value)
    }
    if (leadsData.length === 0 || loading) {
        return <CustomLoader />
    }

    if (leadsData?.errorDescription === 'No data found.') {
        return <NoDataFound />
    }
    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body p-0'>
                            <div className='row'>
                                <div className='col-md-12 col-12'>
                                    <div className='rec-payment-act-sec'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='table-responsive'>
                                                    <table className='table table-striped gy-4 gx-4 mb-0'>
                                                        <thead>
                                                            <tr>
                                                                {/* <th>
                                                                    <input
                                                                        className='form-check-input fc-wo-checkbox'
                                                                        data-bs-target='#CheckBoxPopup'
                                                                        data-bs-toggle='modal'
                                                                        type='checkbox'
                                                                        id='flexCheckDefault'
                                                                    />
                                                                </th> */}
                                                                <th> Ticket No </th>
                                                                <th> Customer Name </th>
                                                                <th> Unit </th>
                                                                <th> Unit</th>
                                                                <th> Date </th>
                                                                <th> Status </th>
                                                                <th> Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {leadsData?.errorCode != 1 &&
                                                                leadsData?.data?.map((item: any, index: number) => {
                                                                    const sl = pageSize * (currentPage - 1) + index + 1
                                                                    return (
                                                                        <tr>
                                                                            {/* <td>
                                                                                <input
                                                                                    className='form-check-input fc-wo-checkbox'
                                                                                    type='checkbox'
                                                                                    id='flexCheckDefault'
                                                                                />
                                                                            </td> */}
                                                                            <td>{item?.requestNo ? item.requestNo : ('000'+ sl)}</td>
                                                                            <td>{item?.customerName ? item.customerName : '-'}</td>
                                                                            <td>{item?.property_name ? item.property_name : '-'}</td>
                                                                            <td>{item?.property_name ? item.property_name : '-'}</td>
                                                                            <td>{item?.createdAt ? formatDate(item?.createdAt) : '-'}</td>
                                                                            <td className='text-danger text-capitalize'>
                                                                                {item.statusText ? item.statusText : '-'}
                                                                            </td>
                                                                            <td>
                                                                                <Link to={{
                                                                                    pathname: '/pm-leads/leads-details',
                                                                                    state: { id: item?.id },
                                                                                }}>
                                                                                    <span className='view-option text-theme fw-bolder'>
                                                                                        View
                                                                                    </span>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                        </tbody>
                                                    </table>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        {/* <Stack spacing={2}>
                                                            <Pagination count={totalLeadsList % pageSize === 0 ? totalLeadsList / pageSize : Math.ceil(totalLeadsList / pageSize)} onChange={handlePageChange} />
                                                        </Stack> */}
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={parseInt(totalLeadsList)}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
