import moment from 'moment';
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { marketPlaceList } from '../../../../constants/axios/apis';
import { getUserRole } from '../../../../constants/generics/userCheck';
import { PromotionHeader } from './PromotionHeader/PromotionHeader';
import { PromotionListing } from './PromotionListing';
import { PromotionTabPrimary } from './PromotionTabs/PromotionTabPrimary';
import { PromotionTabSecondary } from './PromotionTabs/PromotionTabSecondary';

export function PromotionListWrapper() {
    const location = useLocation();
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    const [loading, setLoading] = useState<any>(true);

    const pageSize = 9
    const [totalCount, setTotalCount] = useState<any>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    // const [payloadPage, setPayloadPage] = useState<any>(1)
    const [allList, setAllList] = useState<any>([]);

    useEffect(() => {
        let newFilters = ''
        const isAdminUser = user_role ? ['MA', 'MT'].includes(user_role) : false

        let activeLink = location.pathname
        let mainLink = activeLink.substring(activeLink.lastIndexOf("/list/") + 6, activeLink.lastIndexOf("/"))
        let subLink = activeLink.substring(activeLink.lastIndexOf("/") + 1, activeLink.length)

        const isExpired = (mainLink === 'upcoming' ? 0 : mainLink === 'closed' ? 1 : undefined)
        const promotionType = (subLink === 'events' ? 3 : subLink === 'offers' ? 1 : subLink === 'vouchers' ? 2 : undefined)
        
        const isRedeemed = (subLink === 'redeemed' ? 1 : undefined)
        // const approvalStatus = (subLink === 'active' || category !== undefined || !isAdminUser ? 'approved' : subLink === 'inactive' ? 'pending' : undefined)
        const approvalStatus = 'approved'
        // const isExpired = (category !== undefined || approvalStatus !== undefined ? 0 : subLink === 'expired' ? 1 : undefined)

        if (promotionType !== undefined)
            newFilters += '&flag=' + promotionType
        if (isRedeemed !== undefined)
            newFilters += '&is_redeemed=' + isRedeemed
        // if (category !== undefined)
        //     newFilters += '&category=' + category
        if (approvalStatus !== undefined)
            newFilters += '&approvalStatus=' + approvalStatus
        if (isExpired !== undefined)
            newFilters += '&is_expired=' + isExpired

        setLoading(true)
        setAllList([])
        setTotalCount(0)
        const payload = '?currentPage=' + currentPage + '&pageSize=' + pageSize + '&id_sort=0' + newFilters
        marketPlaceList(payload)
            .then(({ data, dataCount }: any) => {
                if (data && Array.isArray(data) && dataCount) {
                    setAllList(data)
                    setTotalCount(dataCount?.dataCount ? dataCount?.dataCount : 0)
                }
                setLoading(false)
            })
            .catch((e) => setLoading(false))
    }, [location.pathname, currentPage]);

    // useEffect(() => {
    //     setPayloadPage(1)
    //     // setCurrentPage(1)
    // }, [location.pathname]);

    // useEffect(() => {
    //     setPayloadPage(currentPage)
    // }, [currentPage]);

    return (
        <>
            <PromotionHeader />
            <PromotionTabPrimary />
            <div className="com-card-section">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3 movein-moveout">
                            <div className="card-body px-8 py-6">
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <Switch>
                                            <Route path='/promotions/list/upcoming'>
                                                <PromotionTabSecondary />
                                                <PromotionListing marketPlaceList={allList} loading={loading} totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </Route>
                                            <Route path='/promotions/list/closed'>
                                                <PromotionTabSecondary />
                                                <PromotionListing marketPlaceList={allList} loading={loading} totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </Route>
                                            <Redirect to='/error/403' />
                                        </Switch>
                                        {/* <RedeemOfferPopup />
                                    <RedeemOfferSubmitPopup /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

