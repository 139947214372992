import React, {useState, useEffect} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function ParkingSearchPopup({parkingSlots}:any ) {
  const { t } = useTranslation();

  const [filteredParkingSlots, setFilteredParkingSlots] = useState<any>()
  const [searchKey, setSearchKey] = useState<any>('')

  useEffect(() => {
    let bookedSlots = parkingSlots?.parking_slot?.filter((el:any)=>el.status === 1 )
    let filteredList
    if(searchKey !==''){
        const searchRegex = new RegExp(searchKey, 'i');
        filteredList = bookedSlots.filter((item:any)=>item?.tenantName?.match(searchRegex) || item?.objectDetails?.tenantNumber?.match(searchRegex))
    }else{
        filteredList = bookedSlots
    }
    setFilteredParkingSlots(filteredList)
  }, [parkingSlots, searchKey])

  return (
    <div
      className='modal fade'
      id='parkingsearchPopupmodal'
      aria-labelledby='parkingsearchPopupmodalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header p-5'>
            <div className='input-group w-100'>
              <div className='form-outline w-100 d-flex'>
                <input
                  type='text'
                  placeholder={t('Search')}
                  className='form-control search-input'
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
            </div>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body px-7 pt-0'>
            <div className='row'>
              <div
                style={{
                  overflowY: 'scroll',
                  height: 'auto',
                }}
                className='table-responsive fc-su-ser-tabel px-0'
              >
                <table className='table align-middle table-striped gy-4 gx-4 mb-4 fcs-assign-to-technician'>
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>
                      <th>{t("Contact")}</th>
                      <th>{t("Slot")}</th>
                      <th className='tab-action-select'>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredParkingSlots?.length === 0 ? (
                      <tr>
                        <td colSpan={4}>{t("No Data")}</td>
                      </tr>
                    ) : null}
                    {filteredParkingSlots?.map((selectedSlot: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {selectedSlot.tenantName ? selectedSlot.tenantName : '-'}
                          </td>
                          <td>
                            {selectedSlot.tenantNumber
                              ? selectedSlot.tenantNumber
                              : '-'}
                          </td>
                          <td>
                            {selectedSlot.slotNumber
                              ? selectedSlot.slotNumber
                              : '-'}
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: '/parking/report-issues',
                                state: {selectedSlot: selectedSlot},
                              }}
                              className='menu-link  text-black fs-12 w-100 ps-2 pe-0 py-2'
                            >
                              <button
                                data-bs-dismiss='modal'
                                className='view-option border-0 bg-transparent text-theme fw-bolder'
                              >
                                {t("Next")}
                              </button>
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>        
  )
}
