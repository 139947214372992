import React, { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import Pic from '../../../../assets/images/select-team-pic.png';
import { Search } from '../TeamMembers/Search/Search';
import { AddedSuccessfulPopup } from '../../../SuccessfulPopup/AddedSuccessfulPopup'
import { DynamicPopup } from '../../../../pages/SuccessfulPopup/DynamicPopup';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { getPMmyTeam, assignMember } from './../../../../constants/axios/apis';
import { CustomLoader1, DefaultAvatar, NoDataFound } from '../../../../constants/generics/customActios';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import * as PM from '../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { useTranslation } from 'react-i18next'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import MyPic from '../../../../assets/images/DefaultUser.png';

export function SelectTeamMemberPopup(props: any) {
    const ref = useRef<any>(0)
    const ref1 = useRef<any>(0)
    const dispatch = useDispatch()
    const Pid: any = useSelector<RootState>(({ General }) => General.pid)
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [loading, setLoading] = useState<any>(false)
    const [searchItem, setSearchItem] = useState<any>('')
    const [pageSize, setPageSize] = useState<any>(5)
    const [totalTeamList, setTotalTeamList] = useState<any>([]);
    const [message, setMessage] = useState<any>()
    const [myTeamData, setMyTeamData] = useState<any>([])
    const [selectedTeamMember, setSelectedTeamMember] = useState<any>()
    const [msg, setMsg] = useState<any>()
    const intl = useIntl()
    const btnStyle = location.pathname === '/pm-myteam-action-required' ? 'btn btn-outline-primary px-14 py-1' : 'btn btn-outline-primary px-5 py-1 font-12 text-decoration-none text-black'
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true)
        const roleType = 'PE'
        getPMmyTeam(roleType, currentPage, pageSize, searchItem)
            .then((response: any) => {
                setLoading(false)
                if (response?.errorCode === 0) {
                    setMyTeamData(response)
                    setTotalTeamList(response.dataCount);
                } else {
                    setMyTeamData(response)
                }
            })
            .catch((e) => {
            })
    }, [, currentPage, searchItem])
    const handleClick = (newCurrentPage: any) => {
        setCurrentPage(newCurrentPage)
    };
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handleClick(value);
    };
    const handleAssign = () => {
        if (selectedTeamMember > 0) {
            const assginData = {
                "property_id": Pid,
                "user_id": selectedTeamMember
            }
            assignMember(assginData)
                .then((response: any) => {
                    if (response?.code === 200 && response?.errorCode === 0) {
                        setMessage({
                            error: 0,
                            message: response.description,
                        })
                        // dispatch(PM.actions.proprtylist(`available?currentPage=${1}&pageSize=${9}&building_id=${currentBuilding}`))
                        setTimeout(() => {
                            ref.current.click();
                            setTimeout(() => {
                                ref.current.click();
                                dispatch(PM.actions.proprtylist(`available?currentPage=${1}&pageSize=${4}&building_id=${currentBuilding}`))
                            }, 1000)
                        }, 1000)
                    } else {
                        setMessage({
                            error: 1,
                            message: response.errorDescription,
                        })
                        setTimeout(() => {
                            ref.current.click();
                        }, 1000)
                    }
                })
                .catch((e) => {
                })
        } else {
            setMsg(t("Please select a team member"))
            setTimeout(() => {
                setMsg('')
            }, 2000)
        }
    }
    const handleChecked = (e: any) => {
        setSelectedTeamMember(e)
    }

    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])

    const image = myTeamData?.profileImage ? myTeamData?.profileImage : MyPic;

    return (
        <><>
            <div className='row'>
                <div className='col-lg-12 my-auto'>
                    <button data-bs-target="#SelectTeamMember" data-bs-toggle="modal"
                        data-bs-dismiss="modal" type='submit'
                        className={btnStyle}>
                        {t("Assign")}
                    </button>
                    <div className="modal fade add-broker-modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden='true' id="SelectTeamMember">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header px-7 py-7 pop_model_c">
                                    <div className="col-12">
                                        <div className="row align-items-center">
                                            <div className="col-8 text-start">
                                                <h4 className="card-title font-18 text-black mb-0">
                                                    {msg ? <p style={{ color: 'red' }} > {msg} </p> : t("Select Team Member")}
                                                </h4>
                                            </div>
                                            <div className="col-4">
                                                <div className='row'>
                                                    <div className='col-lg-12 pe-3 my-auto'>
                                                        <div className="input-group custom-search-form">
                                                            <input type="text" placeholder='Search' className="form-control search-input" onChange={(e) => setSearchItem(e.target.value)} />
                                                            <span className="input-group-btn">
                                                                <button className="search_icon" type="button">
                                                                    <span className=""> </span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body px-10 pt-0">
                                    <div className="row">
                                        <div className="table-responsive fc-su-ser-tabel px-0">
                                            <table className="table text-start align-middle table-striped gy-4 gx-4 mb-4">
                                                <thead className="fw-bolder">
                                                    <tr>
                                                        <th>{t("No.")}</th>
                                                        {/* <th>{t("Image")}</th> */}
                                                        <th>{t("Name")}</th>
                                                        <th>{t("Emirates Id")} </th>
                                                        <th>{t("Contact No.")}</th>
                                                        <th className="text-center">{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {myTeamData?.errorCode != 1 && myTeamData?.data?.teamList?.length > 0 ? myTeamData?.data?.teamList?.map((item: any, index: number) => {
                                                        const sl = (pageSize * (currentPage - 1)) + index + 1
                                                        return (
                                                            <tr>
                                                                <td>{sl}</td>
                                                                <td className="member_name">
                                                                    {/* {item?.profileImage != null ? <img src={item?.profileImage} alt="Pic" className="img-fluid me-4" /> :
                                                                        <img src={MyPic} className="img-fluid me-4" height={50} alt='Pic' />} */}
                                                                    <img src={MyPic} width='32'
                                                                        height='32'
                                                                        className='img-fluid me-2 rounded-circle' alt='Pic' />
                                                                    {item?.name ? item.name : '-'}
                                                                </td>
                                                                <td>{item?.emiratesId ? item?.emiratesId : '-'}</td>
                                                                <td>{item?.mobile ? item?.mobile : '-'}</td>
                                                                <td className="text-center">
                                                                    <input className="form-check-input align-middle fc-wo-checkbox" name="flexRadioDefault" type="radio" id={`inline-${item?.user_id}`} onClick={() => handleChecked(item?.user_id)} />
                                                                </td>
                                                            </tr>);
                                                    })
                                                        : <NoDataFound />}
                                                </tbody>
                                            </table>
                                            <div className="w-100 d-flex justify-content-end">
                                                {/* <Stack spacing={2}>
                                                    <Pagination count={totalTeamList % pageSize === 0 ? totalTeamList / pageSize : Math.ceil(totalTeamList / pageSize)} onChange={handlePageChange} />
                                                </Stack> */}
                                                <CompactPagination
                                                    pageSize={pageSize}
                                                    totalCount={parseInt(totalTeamList)}
                                                    defaultPage={currentPage}
                                                    onPageChange={setCurrentPage}
                                                    loading={loading}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer pb-7">
                                    <button type='submit' className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white' data-bs-dismiss="modal">
                                        {t("Cancel")}
                                    </button>
                                    {selectedTeamMember > 0 ?
                                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' onClick={handleAssign}
                                            data-bs-dismiss="modal"
                                            data-bs-toggle="modal"
                                        >
                                            {t("Assign")}
                                        </button> :
                                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' onClick={handleAssign}
                                        >
                                            {t("Assign")}
                                        </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <AddedSuccessfulPopup /> data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#added-success-modal"*/}
                </div>
            </div>
        </><p ref={ref} className="mb-0" data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p>
            <p ref={ref1} className="mb-0" data-bs-dismiss="modal">
                {/*  */}
            </p></>
    )
}
