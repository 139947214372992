import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function PropertyRequirementPopup() {
    return ( 
        <> 
            <div className="modal fade prop-req-modal" id="prop-req-modal">
                <div className="modal-dialog radius-15 modal-lg">
                    <div className="modal-content radius-15 border-0">
                        <div className="modal-header bg-primary">
                            <h5 className="modal-title font-16 font-weight-semibold text-white">Property Requirement</h5>
                            <button type="button" className="close text-warning bg-white rounded-circle" data-bs-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="font-12">
                                            Please populate the property requirements using the fields below. Enter as many fields as possible to get better matching results.
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="prop-detail-tab">
                                            <ul className="nav nav-pills mb-7 border-bottom" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <Link to className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">United Arab Emirates</Link>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <Link to className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">International</Link>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Category</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select" name="prop_req_category" id="prop_req_category">
                                                                    <option value="">Select</option> 
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Min Beds</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select"  id="prop_req_min_beds">
                                                                    <option value="">Select</option>
                                                                    <option value="0.5">Studio</option><option value="1">1 bed</option><option value="2">2 beds</option><option value="3">3 beds</option><option value="4">4 beds</option><option value="5">5 beds</option><option value="6">6 beds</option><option value="7">7 beds</option><option value="8">8 beds</option><option value="9">9 beds</option><option value="10">10 beds</option><option value="11">11 beds</option><option value="12">12 beds</option><option value="13">13 beds</option><option value="14">14 beds</option><option value="15">15 beds</option><option value="16">16 beds</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Max Beds</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select" id="prop_req_max_beds">
                                                                    <option value="">Select</option>
                                                                    <option value="0.5">Studio</option><option value="1">1 bed</option><option value="2">2 beds</option><option value="3">3 beds</option><option value="4">4 beds</option><option value="5">5 beds</option><option value="6">6 beds</option><option value="7">7 beds</option><option value="8">8 beds</option><option value="9">9 beds</option><option value="10">10 beds</option><option value="11">11 beds</option><option value="12">12 beds</option><option value="13">13 beds</option><option value="14">14 beds</option><option value="15">15 beds</option><option value="16">16 beds</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Emirate</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select"  id="prop_req_emirate">
                                                                    <option value="">Select</option>
                                                                    
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Min Price</label>
                                                                <input type="text" className="form-control form-height font-13 convertThis" name="" placeholder="" id="prop_req_min_price"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Max Price</label>
                                                                <input type="text" className="form-control form-height font-13 convertThis" name="" placeholder="" id="prop_req_max_price"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Community</label>
                                                                <select className="selectpicker form-control form-height w-100 community_id" data-live-search="true" title="Select" id="prop_req_community">
                                                                    <option value="">Select</option>
                                                                    
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Min Area</label>
                                                                <input type="text" className="form-control form-height font-13 convertThis" name="" placeholder="" id="prop_req_min_area"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Max Area</label>
                                                                <input type="text" className="form-control form-height font-13 convertThis" name="" placeholder="" id="prop_req_max_area"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Units</label>
                                                                
                                                                <select className="selectpicker form-control form-height unit_id w-100" data-live-search="true" title="Select" id="prop_unit">
                                                                    <option value="">Select</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Location</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select"  id="prop_req_location">
                                                                    <option value="">Select</option>
                                                                    
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Furnished Type</label>
                                                                <select className="form-control selectpicker form-height" data-size="7" id="prop_is_furnished" data-live-search="true"  data-title="Select" data-width="100%">
                                                                    <option> Please Select </option>
                                                                    <option value="504"> Furnished </option>
                                                                    <option value="505"> Semi Furnished </option>
                                                                    <option value="503"> Not Furnished </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-12 text-end">
                                                            <button type="button" className="btn btn-primary px-4">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Category</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select">
                                                                    <option value="" selected={true}>Select</option>
                                                                    <option id="Apartment2" rel="Apartment, " value="1">Apartment</option>
                                                                    <option id="Villa2" rel="Villa, " value="2">Villa</option>
                                                                    <option id="Office2" rel="Office, " value="3">Office</option>
                                                                    <option id="Retail2" rel="Retail, " value="4">Retail</option>
                                                                    <option id="Hotel Apartment2" rel="Hotel Apartment, " value="5">Hotel Apartment</option>
                                                                    <option id="Warehouse2" rel="Warehouse, " value="6">Warehouse</option>
                                                                    <option id="Land Commercial2" rel="Land Commercial, " value="7">Land Commercial</option>
                                                                    <option id="Labour Camp2" rel="Labour Camp, " value="8">Labour Camp</option>
                                                                    <option id="Residential Building2" rel="Residential Building, " value="9">Residential Building</option>
                                                                    <option id="Multiple Sale Units2" rel="Multiple Sale Units, " value="10">Multiple Sale Units</option>
                                                                    <option id="Land Residential2" rel="Land Residential, " value="11">Land Residential</option>
                                                                    <option id="Commercial Full Building2" rel="Commercial Full Building, " value="12">Commercial Full Building</option>
                                                                    <option id="Penthouse2" rel="Penthouse, " value="13">Penthouse</option>
                                                                    <option id="Duplex2" rel="Duplex, " value="14">Duplex</option>
                                                                    <option id="Loft Apartment2" rel="Loft Apartment, " value="15">Loft Apartment</option>
                                                                    <option id="Townhouse2" rel="Townhouse, " value="16">Townhouse</option>
                                                                    <option id="Hotel2" rel="Hotel, " value="17">Hotel</option>
                                                                    <option id="Land Mixed Use2" rel="Land Mixed Use, " value="18">Land Mixed Use</option>
                                                                    <option id="Compound2" rel="Compound, " value="21">Compound</option>
                                                                    <option id="Half Floor2" rel="Half Floor, " value="24">Half Floor</option>
                                                                    <option id="Full Floor2" rel="Full Floor, " value="27">Full Floor</option>
                                                                    <option id="Commercial Villa2" rel="Commercial Villa, " value="30">Commercial Villa</option>
                                                                    <option id="Bungalow2" rel="Bungalow, " value="48">Bungalow</option>
                                                                    <option id="Factory2" rel="Factory, " value="50">Factory</option>
                                                                    <option id="Staff Accommodation2" rel="Staff Accommodation, " value="52">Staff Accommodation</option>
                                                                    <option id="Multiple Rental Units2" rel="Multiple Rental Units, " value="55">Multiple Rental Units</option>
                                                                    <option id="Residential Full Floor2" rel="Residential Full Floor, " value="58">Residential Full Floor</option>
                                                                    <option id="Commercial Full Floor2" rel="Commercial Full Floor, " value="61">Commercial Full Floor</option>
                                                                    <option id="Residential Half Floor2" rel="Residential Half Floor, " value="64">Residential Half Floor</option>
                                                                    <option id="Commercial Half Floor2" rel="Commercial Half Floor, " value="67">Commercial Half Floor</option>
                                                                    <option id="Show Room2" rel="Show Room, " value="68">Show Room</option>
                                                                    <option id="Shop2" rel="Shop, " value="70">Shop</option>
                                                                    <option id="Business Center2" rel="Business Center, " value="71">Business Center</option>
                                                                    <option id="Land Industrial2" rel="Land Industrial, " value="72">Land Industrial</option>
                                                                    <option id="Open Yard2" rel="Open Yard, " value="75">Open Yard</option>
                                                                    <option id="Land Agricultural2" rel="Land Agricultural, " value="77">Land Agricultural</option>
                                                                    <option id="Student Accommodation2" rel="Student Accommodation, " value="79">Student Accommodation</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Min Beds</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select">
                                                                    <option value="">Select</option>
                                                                    <option value="0.5">Studio</option><option value="1">1 bed</option><option value="2">2 beds</option><option value="3">3 beds</option><option value="4">4 beds</option><option value="5">5 beds</option><option value="6">6 beds</option><option value="7">7 beds</option><option value="8">8 beds</option><option value="9">9 beds</option><option value="10">10 beds</option><option value="11">11 beds</option><option value="12">12 beds</option><option value="13">13 beds</option><option value="14">14 beds</option><option value="15">15 beds</option><option value="16">16 beds</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Max Beds</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select">
                                                                    <option value="">Select</option>
                                                                    <option value="0.5">Studio</option><option value="1">1 bed</option><option value="2">2 beds</option><option value="3">3 beds</option><option value="4">4 beds</option><option value="5">5 beds</option><option value="6">6 beds</option><option value="7">7 beds</option><option value="8">8 beds</option><option value="9">9 beds</option><option value="10">10 beds</option><option value="11">11 beds</option><option value="12">12 beds</option><option value="13">13 beds</option><option value="14">14 beds</option><option value="15">15 beds</option><option value="16">16 beds</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Country</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select">
                                                                    <option value="Afganistan">Afghanistan</option>
                                                                    <option value="Albania">Albania</option>
                                                                    <option value="Algeria">Algeria</option>
                                                                    <option value="American Samoa">American Samoa</option>
                                                                    <option value="Andorra">Andorra</option>
                                                                    <option value="Angola">Angola</option>
                                                                    <option value="Anguilla">Anguilla</option>
                                                                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                                    <option value="Argentina">Argentina</option>
                                                                    <option value="Armenia">Armenia</option>
                                                                    <option value="Aruba">Aruba</option>
                                                                    <option value="Australia">Australia</option>
                                                                    <option value="Austria">Austria</option>
                                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                                    <option value="Bahamas">Bahamas</option>
                                                                    <option value="Bahrain">Bahrain</option>
                                                                    <option value="Bangladesh">Bangladesh</option>
                                                                    <option value="Barbados">Barbados</option>
                                                                    <option value="Belarus">Belarus</option>
                                                                    <option value="Belgium">Belgium</option>
                                                                    <option value="Belize">Belize</option>
                                                                    <option value="Benin">Benin</option>
                                                                    <option value="Bermuda">Bermuda</option>
                                                                    <option value="Bhutan">Bhutan</option>
                                                                    <option value="Bolivia">Bolivia</option>
                                                                    <option value="Bonaire">Bonaire</option>
                                                                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                                    <option value="Botswana">Botswana</option>
                                                                    <option value="Brazil">Brazil</option>
                                                                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                                    <option value="Brunei">Brunei</option>
                                                                    <option value="Bulgaria">Bulgaria</option>
                                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                                    <option value="Burundi">Burundi</option>
                                                                    <option value="Cambodia">Cambodia</option>
                                                                    <option value="Cameroon">Cameroon</option>
                                                                    <option value="Canada">Canada</option>
                                                                    <option value="Canary Islands">Canary Islands</option>
                                                                    <option value="Cape Verde">Cape Verde</option>
                                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                                    <option value="Central African Republic">Central African Republic</option>
                                                                    <option value="Chad">Chad</option>
                                                                    <option value="Channel Islands">Channel Islands</option>
                                                                    <option value="Chile">Chile</option>
                                                                    <option value="China">China</option>
                                                                    <option value="Christmas Island">Christmas Island</option>
                                                                    <option value="Cocos Island">Cocos Island</option>
                                                                    <option value="Colombia">Colombia</option>
                                                                    <option value="Comoros">Comoros</option>
                                                                    <option value="Congo">Congo</option>
                                                                    <option value="Cook Islands">Cook Islands</option>
                                                                    <option value="Costa Rica">Costa Rica</option>
                                                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                                                    <option value="Croatia">Croatia</option>
                                                                    <option value="Cuba">Cuba</option>
                                                                    <option value="Curaco">Curacao</option>
                                                                    <option value="Cyprus">Cyprus</option>
                                                                    <option value="Czech Republic">Czech Republic</option>
                                                                    <option value="Denmark">Denmark</option>
                                                                    <option value="Djibouti">Djibouti</option>
                                                                    <option value="Dominica">Dominica</option>
                                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                                    <option value="East Timor">East Timor</option>
                                                                    <option value="Ecuador">Ecuador</option>
                                                                    <option value="Egypt">Egypt</option>
                                                                    <option value="El Salvador">El Salvador</option>
                                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                                    <option value="Eritrea">Eritrea</option>
                                                                    <option value="Estonia">Estonia</option>
                                                                    <option value="Ethiopia">Ethiopia</option>
                                                                    <option value="Falkland Islands">Falkland Islands</option>
                                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                                    <option value="Fiji">Fiji</option>
                                                                    <option value="Finland">Finland</option>
                                                                    <option value="France">France</option>
                                                                    <option value="French Guiana">French Guiana</option>
                                                                    <option value="French Polynesia">French Polynesia</option>
                                                                    <option value="French Southern Ter">French Southern Ter</option>
                                                                    <option value="Gabon">Gabon</option>
                                                                    <option value="Gambia">Gambia</option>
                                                                    <option value="Georgia">Georgia</option>
                                                                    <option value="Germany">Germany</option>
                                                                    <option value="Ghana">Ghana</option>
                                                                    <option value="Gibraltar">Gibraltar</option>
                                                                    <option value="Great Britain">Great Britain</option>
                                                                    <option value="Greece">Greece</option>
                                                                    <option value="Greenland">Greenland</option>
                                                                    <option value="Grenada">Grenada</option>
                                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                                    <option value="Guam">Guam</option>
                                                                    <option value="Guatemala">Guatemala</option>
                                                                    <option value="Guinea">Guinea</option>
                                                                    <option value="Guyana">Guyana</option>
                                                                    <option value="Haiti">Haiti</option>
                                                                    <option value="Hawaii">Hawaii</option>
                                                                    <option value="Honduras">Honduras</option>
                                                                    <option value="Hong Kong">Hong Kong</option>
                                                                    <option value="Hungary">Hungary</option>
                                                                    <option value="Iceland">Iceland</option>
                                                                    <option value="Indonesia">Indonesia</option>
                                                                    <option value="India">India</option>
                                                                    <option value="Iran">Iran</option>
                                                                    <option value="Iraq">Iraq</option>
                                                                    <option value="Ireland">Ireland</option>
                                                                    <option value="Isle of Man">Isle of Man</option>
                                                                    <option value="Israel">Israel</option>
                                                                    <option value="Italy">Italy</option>
                                                                    <option value="Jamaica">Jamaica</option>
                                                                    <option value="Japan">Japan</option>
                                                                    <option value="Jordan">Jordan</option>
                                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                                    <option value="Kenya">Kenya</option>
                                                                    <option value="Kiribati">Kiribati</option>
                                                                    <option value="Korea North">Korea North</option>
                                                                    <option value="Korea Sout">Korea South</option>
                                                                    <option value="Kuwait">Kuwait</option>
                                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                                    <option value="Laos">Laos</option>
                                                                    <option value="Latvia">Latvia</option>
                                                                    <option value="Lebanon">Lebanon</option>
                                                                    <option value="Lesotho">Lesotho</option>
                                                                    <option value="Liberia">Liberia</option>
                                                                    <option value="Libya">Libya</option>
                                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                                    <option value="Lithuania">Lithuania</option>
                                                                    <option value="Luxembourg">Luxembourg</option>
                                                                    <option value="Macau">Macau</option>
                                                                    <option value="Macedonia">Macedonia</option>
                                                                    <option value="Madagascar">Madagascar</option>
                                                                    <option value="Malaysia">Malaysia</option>
                                                                    <option value="Malawi">Malawi</option>
                                                                    <option value="Maldives">Maldives</option>
                                                                    <option value="Mali">Mali</option>
                                                                    <option value="Malta">Malta</option>
                                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                                    <option value="Martinique">Martinique</option>
                                                                    <option value="Mauritania">Mauritania</option>
                                                                    <option value="Mauritius">Mauritius</option>
                                                                    <option value="Mayotte">Mayotte</option>
                                                                    <option value="Mexico">Mexico</option>
                                                                    <option value="Midway Islands">Midway Islands</option>
                                                                    <option value="Moldova">Moldova</option>
                                                                    <option value="Monaco">Monaco</option>
                                                                    <option value="Mongolia">Mongolia</option>
                                                                    <option value="Montserrat">Montserrat</option>
                                                                    <option value="Morocco">Morocco</option>
                                                                    <option value="Mozambique">Mozambique</option>
                                                                    <option value="Myanmar">Myanmar</option>
                                                                    <option value="Nambia">Nambia</option>
                                                                    <option value="Nauru">Nauru</option>
                                                                    <option value="Nepal">Nepal</option>
                                                                    <option value="Netherland Antilles">Netherland Antilles</option>
                                                                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                                    <option value="Nevis">Nevis</option>
                                                                    <option value="New Caledonia">New Caledonia</option>
                                                                    <option value="New Zealand">New Zealand</option>
                                                                    <option value="Nicaragua">Nicaragua</option>
                                                                    <option value="Niger">Niger</option>
                                                                    <option value="Nigeria">Nigeria</option>
                                                                    <option value="Niue">Niue</option>
                                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                                    <option value="Norway">Norway</option>
                                                                    <option value="Oman">Oman</option>
                                                                    <option value="Pakistan">Pakistan</option>
                                                                    <option value="Palau Island">Palau Island</option>
                                                                    <option value="Palestine">Palestine</option>
                                                                    <option value="Panama">Panama</option>
                                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                                    <option value="Paraguay">Paraguay</option>
                                                                    <option value="Peru">Peru</option>
                                                                    <option value="Phillipines">Philippines</option>
                                                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                                                    <option value="Poland">Poland</option>
                                                                    <option value="Portugal">Portugal</option>
                                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                                    <option value="Qatar">Qatar</option>
                                                                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                                    <option value="Republic of Serbia">Republic of Serbia</option>
                                                                    <option value="Reunion">Reunion</option>
                                                                    <option value="Romania">Romania</option>
                                                                    <option value="Russia">Russia</option>
                                                                    <option value="Rwanda">Rwanda</option>
                                                                    <option value="St Barthelemy">St Barthelemy</option>
                                                                    <option value="St Eustatius">St Eustatius</option>
                                                                    <option value="St Helena">St Helena</option>
                                                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                                    <option value="St Lucia">St Lucia</option>
                                                                    <option value="St Maarten">St Maarten</option>
                                                                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                                    <option value="Saipan">Saipan</option>
                                                                    <option value="Samoa">Samoa</option>
                                                                    <option value="Samoa American">Samoa American</option>
                                                                    <option value="San Marino">San Marino</option>
                                                                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                                    <option value="Senegal">Senegal</option>
                                                                    <option value="Seychelles">Seychelles</option>
                                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                                    <option value="Singapore">Singapore</option>
                                                                    <option value="Slovakia">Slovakia</option>
                                                                    <option value="Slovenia">Slovenia</option>
                                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                                    <option value="Somalia">Somalia</option>
                                                                    <option value="South Africa">South Africa</option>
                                                                    <option value="Spain">Spain</option>
                                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                                    <option value="Sudan">Sudan</option>
                                                                    <option value="Suriname">Suriname</option>
                                                                    <option value="Swaziland">Swaziland</option>
                                                                    <option value="Sweden">Sweden</option>
                                                                    <option value="Switzerland">Switzerland</option>
                                                                    <option value="Syria">Syria</option>
                                                                    <option value="Tahiti">Tahiti</option>
                                                                    <option value="Taiwan">Taiwan</option>
                                                                    <option value="Tajikistan">Tajikistan</option>
                                                                    <option value="Tanzania">Tanzania</option>
                                                                    <option value="Thailand">Thailand</option>
                                                                    <option value="Togo">Togo</option>
                                                                    <option value="Tokelau">Tokelau</option>
                                                                    <option value="Tonga">Tonga</option>
                                                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                                    <option value="Tunisia">Tunisia</option>
                                                                    <option value="Turkey">Turkey</option>
                                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                                    <option value="Tuvalu">Tuvalu</option>
                                                                    <option value="Uganda">Uganda</option>
                                                                    <option value="United Kingdom">United Kingdom</option>
                                                                    <option value="Ukraine">Ukraine</option>
                                                                    <option value="United Arab Erimates">United Arab Emirates</option>
                                                                    <option value="United States of America">United States of America</option>
                                                                    <option value="Uraguay">Uruguay</option>
                                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                                    <option value="Vanuatu">Vanuatu</option>
                                                                    <option value="Vatican City State">Vatican City State</option>
                                                                    <option value="Venezuela">Venezuela</option>
                                                                    <option value="Vietnam">Vietnam</option>
                                                                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                                    <option value="Wake Island">Wake Island</option>
                                                                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                                    <option value="Yemen">Yemen</option>
                                                                    <option value="Zaire">Zaire</option>
                                                                    <option value="Zambia">Zambia</option>
                                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Min Price</label>
                                                                <input type="text" className="form-control form-height font-13" name="" placeholder=""/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Max Price</label>
                                                                <input type="text" className="form-control form-height font-13" name="" placeholder=""/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">City</label>
                                                                <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select">
                                                                    <option value="">Select</option>
                                                                    <option value="2">Abu Dhabi</option>
                                                                    <option value="4">Ajman</option>
                                                                    <option value="8">Al Ain</option>
                                                                    <option value="1">Dubai</option>
                                                                    <option value="7">Fujairah</option>
                                                                    <option value="6">Ras Al Khaimah</option>
                                                                    <option value="3">Sharjah</option>
                                                                    <option value="5">Umm Al Quwain</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Min Area</label>
                                                                <input type="text" className="form-control form-height font-13" name="" placeholder=""/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Max Area</label>
                                                                <input type="text" className="form-control form-height font-13" name="" placeholder=""/>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Location</label>
                                                                <input type="text" className="form-control form-height font-13" name="" placeholder=""/>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-md-4">
                                                            <div className="mb-7">
                                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Sub Location</label>
                                                                <input type="text" className="form-control form-height font-13" name="" placeholder=""/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 text-end">
                                                            <button type="button" className="btn btn-priamry px-4">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
	                
        </>
    )
}