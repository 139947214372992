import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

type Props = {
    activeOption: any
    setActiveOption: any
}

const MyTeamGraphTabs: React.FC<Props> = ({activeOption, setActiveOption}) => {
    // const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row access-cd-tabs">
                            <div className="col-sm-12">
                                <div className='d-flex py-2 mb-10'>
                                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                        <li className='nav-item'>
                                            <a
                                                onClick={() => { setActiveOption('open') }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeOption === 'open' && 'active')}>
                                                {t("Open")}
                                            </a>
                                            {/* <Link
                                                className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                (location.pathname === '/fc-su-myteam/open' && 'active')}
                                                to='/fc-su-myteam/open'>
                                                Open
                                            </Link> */}
                                        </li>
                                        <li className='nav-item'>
                                            <a
                                                onClick={() => { setActiveOption('onhold') }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeOption === 'onhold' && 'active')}>
                                                {t("On Hold")}
                                            </a>
                                            {/* <Link
                                                className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                (location.pathname === '/fc-su-myteam/onhold' && 'active')}
                                                to='/fc-su-myteam/onhold'>
                                                On Hold
                                            </Link> */}
                                        </li>
                                        <li className='nav-item'>
                                            <a
                                                onClick={() => { setActiveOption('completed') }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeOption === 'completed' && 'active')}>
                                                {t("Completed")}
                                            </a>
                                            {/* <Link
                                                className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                (location.pathname === '/fc-su-myteam/completed' && 'active')}
                                                to='/fc-su-myteam/completed'>
                                                Completed
                                            </Link> */}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                {/*<Link to={'/'} className="text-decoration-none view-all-2 d-block mt-2">*/}
                                    {/*<span> View All </span>*/}
                                {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {MyTeamGraphTabs}
