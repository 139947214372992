import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom'
import Pic from '../../../../../assets/images/select-team-pic.png'

export function ParkedSlotPopup({ selectedSlot }: any) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className='modal fade custom-modal' id='parked-slot-popup-modal'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header bg-white border-0'>
            {/*<h5 className="modal-title" id="exampleModalLabel">Parked Slot Details</h5>*/}
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'>
            </button>
          </div>
          <div className='modal-body py-5'>
            <div className='row mb-5'>
              <div className='col-sm-6 col-12'>
                <h5 className='modal-title font-16' id='exampleModalLabel'>
                  {t("Parking Slot Details")}
                </h5>
              </div>
              <div className='col-sm-6 col-12 text-end'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {t("Slot Number :")} {selectedSlot?.slotNumber}
                </h5>
              </div>
            </div>
            <div className='row'>
              <div className='table-responsive fc-su-ser-tabel px-0'>
                <table className='table align-middle table-striped gy-4 gx-4'>
                  <thead>
                    <tr>
                      <th>
                        <strong>{t("Tenant Name")}</strong>
                      </th>
                      <th>
                        <strong>{t("Unit")}</strong>
                      </th>
                      <th>
                        <strong>{t("Reg. Plate No")}</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{selectedSlot?.tenantName ? selectedSlot?.tenantName : "--"}</td>
                      <td>{selectedSlot?.property_code ? selectedSlot?.property_code : "--"}</td>
                      <td>{selectedSlot?.vehicle_details?.regno ? selectedSlot?.vehicle_details?.regno : "--"}</td>
                    </tr>
                    <tr>
                      <th>
                        <strong>{t("Brand")}</strong>
                      </th>
                      <th>
                        <strong>{t("Model")}</strong>
                      </th>
                      <th>
                        <strong>{t("Color")}</strong>
                      </th>
                    </tr>
                    <tr>
                      <td>{selectedSlot?.vehicle_details?.brand ? selectedSlot?.vehicle_details?.brand : "--"}</td>
                      <td>{selectedSlot?.vehicle_details?.model ? selectedSlot?.vehicle_details?.model : "--"}</td>
                      <td>{selectedSlot?.vehicle_details?.color ? selectedSlot?.vehicle_details?.color : "--"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 text-end'>
                <div className="row justify-content-end">
                  <div className="col-lg-6 col-md-6 col-6">
                    <Link to={{
                      'pathname': '/parking/report-issues',
                      state: { 'selectedSlot': selectedSlot }
                    }} className=''>
                      <button
                        data-bs-dismiss='modal'
                        //  data-bs-target='#parked-report-issues-popup-modal'
                        //  data-bs-toggle='modal'
                        className='btn btn-outline-primary fw-bolder font-12 btn-block px-5'
                        type='submit'
                      >
                        {t("Report a Issue")}
                      </button>
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6">
                    <button
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      data-bs-target='#parked-contact-popup-modal'
                      className='btn btn-primary fw-bolder font-12 btn-block' type='button'>
                      {t("Contact")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
