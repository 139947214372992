export function Basic({chooseMessage}:any) {
    return (
        <>
            <div className="card">
                <div className="card-body">
                <div className="row mb-4">
                    <div className="col-md-12">
                        <form >
                            <div className="form-add-design">
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder text-uppercase mb-0">Ref Id</label>
                                                <input type="text" className="form-control fw-bolder form-control-solid active" disabled value="REF-UN-1689243911" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Select Community</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                                    <option> Please Select </option>
                                                    <option selected> WIlton Park Residencies</option>    
                                                    <option> Belgravia Square</option>    
                                                    <option> Emirates Hills </option>    
                                                    <option> The Sloan by Belgravia Heights</option>           
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Select Building</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_building" data-live-search="true" title="Select Building" required>
                                                    <option selected> Building Name </option>
                                                    <option > WIlton Park Residencies 1</option>    
                                                    <option> WIlton Park Residencies 2</option>    
                                                    <option> WIlton Park Residencies 3 </option>    
                                                    <option> WIlton Park Residencies 4</option>           
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Type</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" required>
                                                    <option selected> Sale </option>
                                                    <option > Lease</option>               
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Category</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" required>
                                                    <option selected> Project Type </option>
                                                    <option > Villa</option> 
                                                    <option > Townhall</option>     
                                                    <option > Apartment</option>           
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Floor</label>
                                                <input type="text" className="form-control font-13 form-height" name="name" placeholder="Floor" value="" required/>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Unit Name</label>
                                                <input type="text" className="form-control font-13 form-height" name="name" placeholder="Listing Headline" value="" required/>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Assign To</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" required>
                                                    <option selected> Select </option>
                                                    <option > Remo</option> 
                                                    <option > Rms Demo Agencies</option>     
                                                    <option > vignes</option>           
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
                
            </div>
            <div className="text-end my-6">
                {/* <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3">Previous</button> */}
                <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('2')}>Next</button>
            </div>
        </>
    )
}