import React, { useState, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { FinancePaymentTable } from './FinancePaymentTable/FinancePaymentTable';
import { FinancePaymentMainTab } from './FinancePaymentTabs/FinancePaymentMainTab';
import { FinancePaymentSubTab } from './FinancePaymentTabs/FinancePaymentSubTab';

export function FinancePaymentListWrapper() {
    const [searchItem, setSearchItem] = useState<any>('');
    const [payload, setPayload] = useState<any>('');

    const location = useLocation();
    useEffect(() => {
        let s = location.pathname
        let mainLink = s.substring(s.lastIndexOf("/list/") + 6, s.lastIndexOf("/"))
        let subLink = s.substring(s.lastIndexOf("/") + 1, s.length)
        const paymentType = (subLink === 'creditCard-payment' ? 1 : subLink === 'bank-transfer' ? 4 : subLink === 'cheque' ? 2 : 1)
        const paymentMethod = (mainLink === 'rent' ? 1 : mainLink === 'security-deposits' ? 2 : mainLink === 'service-charges' ? 3 : mainLink === 'other-charges' ? 4 : "")
        setPayload(`?payment_type=${paymentType}&payment_for=${paymentMethod}&id_sort=1&`)
    }, [location])

    return (
        <>
            <FinancePaymentMainTab searchItem={searchItem} setSearchItem={setSearchItem} />
            <div className="com-card-section">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3 movein-moveout">
                            <div className="card-body px-8 py-6">
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <FinancePaymentSubTab />
                                        <FinancePaymentTable payload={payload} searchItem={searchItem} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}