import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'

const contactData = [{
    "refrence": "REF-BLDG-1615462991",
    "name": "Demo User",
    "personalMobile": "9809898989",
    "source": "--",
    "score": "10%",
},
{
    "refrence": "REF-BLDG-1615462992",
    "name": "Shiv",
    "personalMobile": "9809898989",
    "source": "--",
    "score": "10%"
},
{
    "refrence": "REF-BLDG-1615462993",
    "name": "Ram",
    "personalMobile": "9809898989",
    "source": "--",
    "score": "10%",
},
{
    "refrence": "REF-BLDG-1615462994",
    "name": "User",
    "personalMobile": "9809898989",
    "source": "--",
    "score": "10%"
},
{
    "refrence": "REF-BLDG-1615462995",
    "name": "User",
    "personalMobile": "9809898989",
    "source": "--",
    "score": "20%"
},
{
    "refrence": "REF-BLDG-1615462996",
    "name": "Demo",
    "personalMobile": "9809898989",
    "source": "--",
    "score": "70%"
},
];

export function ContactsListing() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-sm">
                    <h3 className=''>Contacts</h3>
                </div>
                <div className="col-sm-auto text-right">
                    <Link to className="btn btn-outline-primary">Import <span className="d-none d-sm-inline-block">Contacts</span></Link>
                    <Link to="/contacts/add" className="btn btn-primary ms-2"> Add New Contact </Link>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Reference Id </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Reference Id" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Name</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Name" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Personal Mobile</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Personal Mobile" />
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='table-crm'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th> Reference ID</th>
                                                                    <th> Name </th>
                                                                    <th> Personal Mobile </th>
                                                                    <th> Source</th>
                                                                    <th> Score </th>
                                                                    <th> Action </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {contactData.length > 0 ? contactData.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className="text-warning">{item.refrence}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.personalMobile}</td>
                                                                        <td>{item.source}</td>
                                                                        <td>{item.score}</td>
                                                                        <td className="action-button text-end">
                                                                            <div className="dropdown">
                                                                                <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v text-black"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                                        <li>
                                                                                            <Link to="/contacts/edit" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-edit"></i>
                                                                                                <p className="m-0">Edit</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to="/contacts/preview" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-eye"></i>
                                                                                                <p className="m-0">Preview</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-share"></i>
                                                                                                <p className="m-0">Share</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={contactData.length}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
