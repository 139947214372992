import fb from '../../../assets/images/fb.png'
import insta from '../../../assets/images/in.png'
import linkedin from '../../../assets/images/li.png'
import property from '../../../assets/images/pr.png'
import grid from '../../../assets/images/se.png'
import whts from '../../../assets/images/wt.png'
import facebook from '../../../assets/images/facebook.png'
import instagram from '../../../assets/images/instagram.png'
import linkedin1 from '../../../assets/images/linkedin.png'
import propertytxt from '../../../assets/images/property.png'
import gridtxt from '../../../assets/images/sendgrid.png'
import whatsapp from '../../../assets/images/whatsapp.png'
import calendericon from '../../../assets/images/calendar-ico.png'
import chaticon from '../../../assets/images/chat-icon.png'

export const Settings = () => {
    return (
        <>

            <div className="">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="theme-color font-22">
                            Account Settings
                        </h6>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 py-3">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="projects-nav">
                                <h4 className="font-16">Integration</h4>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade show active py-4" id="nav-integrate">
                                    <div className="row">
                                        <div className="col-md-12 mb-5">
                                            <div className="row mt-2">
                                                <div className="col-md-6 col-12 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-body py-4">
                                                            <div className="row py-3 align-items-center">
                                                                <div className="col-md-3">
                                                                    <img src={fb} className="img-fluid" width="35px" />
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <img src={facebook} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-body py-4">
                                                            <div className="row py-3 align-items-center">
                                                                <div className="col-md-3">
                                                                    <img src={insta} className="img-fluid" width="35px" />
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <img src={instagram} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-body py-4">
                                                            <div className="row py-3 align-items-center">
                                                                <div className="col-md-3">
                                                                    <img src={linkedin} className="img-fluid" width="35px" />
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <img src={linkedin1} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-body py-4">
                                                            <div className="row py-3 align-items-center">
                                                                <div className="col-md-3">
                                                                    <img src={property} className="img-fluid" width="35px" />
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <img src={propertytxt} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-body py-4">
                                                            <div className="row py-3 align-items-center">
                                                                <div className="col-md-3">
                                                                    <img src={grid} className="img-fluid" width="" />
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <img src={gridtxt} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-body py-4">
                                                            <div className="row py-3 align-items-center">
                                                                <div className="col-md-3">
                                                                    <img src={whts} className="img-fluid" width="" />
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <img src={whatsapp} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-1 text-center">
                                                            <div className="circle-calendar">
                                                                <img src={calendericon}  className="img-fluid" width="20px" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-11">
                                                            <h6 className="text-uppercase font-weight-bold font-14"> Scheduling Made Easy! </h6>
                                                            <p className="font-12 font-weight-semibold">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut
                                                            </p>
                                                            <button className="btn btn-outline-secondary font-weight-semibold text-uppercase font-12">
                                                                Connect your calendar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-1 text-center">
                                                            <div className="circle-calendar">
                                                                <img src={chaticon}  className="img-fluid" width="20px" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-11">
                                                            <h6 className="text-uppercase font-weight-bold font-14"> Chat Setting </h6>
                                                            <p className="font-12 font-weight-semibold">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut
                                                            </p>
                                                            <button className="btn btn-outline-secondary font-weight-semibold text-uppercase font-12">
                                                                Connect your Chat
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}