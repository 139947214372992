import React from 'react'
import { Link } from 'react-router-dom'
import Building from "../../../../assets/images/dash-building.png";


export function Properties() {
  
  return (
   <>
     
    <div className="row">
        <div className="col-12">
            <div className="card mb-6">
                <div className="card-body p-7">
                    <div className="row align-items-center mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex d-block theme-color">
                                <img src={Building} className="img-fluid build" />
                                <div className='ms-3'>
                                    <h3 className="font-weight-bold mb-0">102</h3>
                                    <p>Units</p>
                                </div>
                            </div>
                            <Link to className="text-warning font-12 font-weight-bold mt-3">
                                <span className='me-2'>See all Properties</span> 
                                <i className="fa fa-angle-right text-warning"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="row units-det mx-0 py-3">
                        <div className="col-sm-4 text-center theme-color py-2 px-2">
                            <Link to className="text-decoration-none text-dark">
                                <h3 className="font-weight-bold">27</h3>
                                <p className="font-10 mb-0 text-theme-light">Units Available</p>
                            </Link>
                        </div>
                        <div className="col-sm-4 text-center theme-color py-2 px-2 b-color border-left border-right no-border">
                            <Link to className="text-decoration-none text-dark">
                                <h3 className="font-weight-bold">0</h3>
                                <p className="font-10 mb-0 text-theme-light">Units Sold</p>
                            </Link>
                        </div>
                        <div className="col-sm-4 text-center theme-color py-2 px-2">
                            <Link to className="text-decoration-none text-dark">
                                <h3 className="font-weight-bold">0</h3>
                                <p className="font-10 mb-0 text-theme-light">Under Offer</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>
                
   </>
  )
}
