import { AnnouncementsDetails } from '../pages/announcements/components/AnnouncementsDetails/AnnouncementsDetails'
import { ApprovalsListWrapper } from '../pages/approvals/ApprovalsListWrapper'
import { MoveInDetailsWrapper1 } from '../pages/approvals/components/ApprovalTabs/ClosedApprovals/MoveIn/MoveInDetailsWrapper/MoveInDetailsWrapper'
import { MoveOutDetailsWrapper1 } from '../pages/approvals/components/ApprovalTabs/ClosedApprovals/MoveOut/MoveOutDetailsWrapper/MoveOutDetailsWrapper'
import { MoveInDetailsWrapper } from '../pages/approvals/components/ApprovalTabs/UpcomingApprovals/MoveIn/MoveInDetailsWrapper/MoveInDetailsWrapper'
import { MoveOutDetailsWrapper } from '../pages/approvals/components/ApprovalTabs/UpcomingApprovals/MoveOut/MoveOutDetailsWrapper/MoveOutDetailsWrapper'
import { FCSupervisorActivitiesWrapper } from '../pages/facility-activities/FCSupervisorActivitiesWrapper'
import { FacilityMaintenanceWrapper } from '../pages/facility-maintenance/FacilityMaintenanceWrapper'
import { FacilityWorkorderWrapper } from '../pages/facility-workorders/FacilityWorkorderWrapper'
import { FCDashboardWrapper } from '../pages/fc-dashboard/FCDashboardWrapper'
import { FCReportsWrapper } from '../pages/fc-reports/FCReportsWrapper'
import { ScheduledVisitWrapper } from '../pages/fc-scheduled-visit/ScheduledVisitWrapper'
import { InActiveDetailWrapper } from '../pages/fc-soft-services-services/access-cards/components/ViewDetails/InActiveDetailWrapper/InActiveDetailWrapper'
import { FCSSViewDetailsWrapper } from '../pages/fc-soft-services-services/access-cards/components/ViewDetails/ViewDetailsWrapper/ViewDetailsWrapper'
import { ServicesWrapper } from '../pages/fc-soft-services-services/ServicesWrapper'
import { FCSoftServicesAnnouncementsWrapper } from '../pages/fc-ss-announcements/FCSoftServicesAnnouncementsWrapper'
import { FCSSCalenderWrapper } from '../pages/fc-ss-calender/FCSSCalenderWrapper'
import { ParkingWrapper } from '../pages/fc-ss-parking/ParkingWrapper'
import { SupervisorCreateAnnouncements } from '../pages/fc-supervisor-announcements/add/CreateAnnouncements/CreateAnnouncements'
import { FCSupervisorAnnouncementWrapper } from '../pages/fc-supervisor-announcements/FCSupervisorAnnouncements'
import { FCSupervisorCalenderWrapper } from '../pages/fc-supervisor-calender/FCSupervisorCalenderWrapper'
import { AddTechnicianPage } from '../pages/fc-supervisor-myteam/components/Add/AddTechnician'
import { EditMember } from '../pages/fc-supervisor-myteam/components/Edit/EditMember'
import { FCSMyTeamDetailsWrapper } from '../pages/fc-supervisor-myteam/FCSMyTeamDetails/FCSMyTeamDetailsWrapper'
import { FCSupervisorMyTeamWrapper } from '../pages/fc-supervisor-myteam/FCSupervisorMyTeam'
import { FinanceCalendarWrapper } from '../pages/finance-calendar/FinanceCalendarWrapper'
import { FinanceServiceRequestLeaseWrapper } from '../pages/finance-lease-details/FinanceServiceRequestLeaseWrapper'
import { FinancePaymentWrapper } from '../pages/finance-payments/FinancePaymentWrapper'
import { FinanceReportsWrapper } from '../pages/finance-reports/FinanceReportsWrapper'
import { FinanceServiceRequestReceiptsWrapper } from '../pages/finance-services-receipts/FinanceServicesReceiptWrapper'
import { FinanceServiceRequestRefundsWrapper } from '../pages/finance-services-request-refunds/FinanceServiceRequestRefundsWrapper'
import { FinanceServiceRequestWrapper } from '../pages/finance-services-request/FinanceServiceRequestWrapper'
import { FinanceMoveoutRequestsWrapper } from '../pages/finance-services/MoveoutRequests/FinanceMoveoutRequestsWrapper'
import { MyTeamWrapper } from '../pages/fmsm-myteam/MyTeamWrapper'
import { FMTeamViewDetailsWrapper } from '../pages/fmteam-approvals/components/ViewDetailsWrapper/ViewDetailsWrapper'
import { FMTeamApprovalsListWrapper } from '../pages/fmteam-approvals/FMTeamApprovalsListWrapper'
import { FMTeamMyTeamWrapper } from '../pages/fmteam-myteam/MyTeamWrapper'
import { FMTeamReportsWrapper } from '../pages/fmteam-report/FMTeamReportWrapper'
import { MarketPlaceWrapper } from '../pages/market-place/MarketPlaceWrapper'
import { MTAApprovalsWrapper } from '../pages/mta-approvals/MTAApprovalsWrapper'
import { MTAMarketPlaceWrapper } from '../pages/mta-market-place/MTAMarketPlaceWrapper'
import { MTAMarketPlaceWrapperOld } from '../pages/mta-market-place/MTAMarketPlaceWrapper(backup)'
import { MTAMerchantsWrapper } from '../pages/mta-merchants/MTAMerchantsWrapper'
import { MTAMyteamWrapperNew } from '../pages/mta-myteam/MTAMyteamWrapperNew'
import { MTAPromotionsWrapper } from '../pages/mta-promotions/MTAPromotionsWrapper'
import { EditPropertyDetails } from '../pages/mta-properties/components/EditPropertyDetails/EditPropertyDetails'
import { MTAMoveinProcessWrapper } from '../pages/mta-properties/components/MTAMoveinProcess/MTAMoveinProcessWrapper'
import { MTAPropertyDetails } from '../pages/mta-properties/components/MTAPropertyDetails/MTAPropertyDetails'
import { MTAPropertiesWrapper } from '../pages/mta-properties/MTAPropertiesWrappers'
import { MTAReportWrapper } from '../pages/mta-report/MTAReportWrapper'
import { ContractWrapper } from '../pages/pm-approvals/Contract/ContractWrapper'
import { InsufficientNoticeWrapper } from '../pages/pm-approvals/InsufficientNotice/InsufficientNoticeWrapper'
import { OfferDetailsWrapper } from '../pages/pm-approvals/OfferDetails/OfferWrapper'
import { PMApprovalsWrapper } from '../pages/pm-approvals/PMApprovalsWrapper'
import { PMEarlyTerminationRequestWrapper } from '../pages/pm-approvals/PMEarlyTerminationRequest/PMEarlyTerminationRequestWrapper'
import { ServiceRequestWrapper } from '../pages/pm-approvals/ServiceRequests/serviceRequestWrapper'
import { PMAvailabilityWrapper } from '../pages/pm-availability/PMAvailabilityWrapper'
import { PMBuildingWrapper } from '../pages/pm-buildings/PMBuildingSWrapper'
import {PMAccountsWrapper}  from '../pages/pm-accounts/PMAccountsSWrapper'
import { PMCalenderWrapper } from '../pages/pm-calender/PMCalenderWrapper'
import { PMCommunitiesWrapper } from '../pages/pm-communities/PMCommunitiesWrapper'
import { PmContactLeadsWrapper } from '../pages/pm-contact-leads/PmContactLeadsWrapper'
import { PmContactTasksWrapper } from '../pages/pm-contact-tasks/PmContactTasksWrapper'
import { PMContactWrapper } from '../pages/pm-contacts/PMContactWrapper'
import { PMLeadsWrapper } from '../pages/pm-leads/PMLeadsWrapper'
import { PMMyteamActionRequired } from '../pages/pm-myteam/components/ActionRequired/ActionRequired'
import { PMMyTeamAddMember } from '../pages/pm-myteam/components/AddMember/AddMember'
import { PMTeamMembersDetails } from '../pages/pm-myteam/components/TeamMembersDetails/TeamMembersDetails'
import { PMMyTeamWrapper } from '../pages/pm-myteam/PMMyTeamWrapper'
import { ApprovalsOfferWrapper } from '../pages/pm-offers-and-renewals/components/ApprovalsOffer/ApprovalsOfferWrapper'
import { PMOffersAndRenewalsWrapper } from '../pages/pm-offers-and-renewals/PMOffersAndRenewalsWrapper'
import { PMOpportunitesWrapper } from '../pages/pm-opportunites/PMOpportunitesWrapper'
import { PMPropertyDataWrapper } from '../pages/pm-property-data/PMPropertyDataWrapper'
import { PMReportWrapper } from '../pages/pm-report/PmReportWrapper'
import { PMTenantView } from '../pages/pm-tenant-search/pm-tenant-view/PMTenantView'
import { PMTenantSearchWrapper } from '../pages/pm-tenant-search/PMTenantSearchWrapper'
import { PMUnitWrapper } from '../pages/pm-units/PMUnitsWrapper'
import { PMMyCalendarWrapper } from '../pages/pm-my-calender/PMMyCalendarWrapper'
import {PMPropertiesWrapper} from '../pages/Pm-reports/propertiesWrapper'
import { PMPaymentWrapper } from '../pages/Pm-reports/paymentHistoryWrapper'
import { PMEmailTemplateWrapper } from '../pages/pm-communication/PMEmailWrapper'
import { PMDocumentWrapper } from '../pages/pm-documents/PMDocumentWrapper'
import { PMUserWrapper } from '../pages/pm-settings/PMUserWrapper'
import { PMMenusWrapper } from '../pages/pm-settings/PMMenusWrapper'
import { PMUserRolesWrapper } from '../pages/pm-settings/PMUserRolesWrapper'
import { PMSettingsWrapper } from '../pages/pm-settings/PMSettingsWrapper'
import { PMOverviewWrapper } from '../pages/pm-marketing/PMOverviewWrapper'
import { PMLeadRuleWrapper } from '../pages/pm-settings/PMLeadRulesWrapper'
import { PMSocialMediaWrapper } from '../pages/pm-marketing/PMSocialMediaWrapper'
import { PMAdsWrapper } from '../pages/pm-marketing/PMAdsWrapper'
import { PMChatWrapper } from '../pages/pm-marketing/PMChatWrapper'
import { PMSettingsMaketingWrapper } from '../pages/pm-marketing/PMSettingsMarketingWrapper'
import { PMEmailWrapper } from '../pages/pm-marketing/PMEmailWrapper'
import { PMWebsiteWrapper } from '../pages/pm-marketing/PMWebsiteWrapper'
// import { PMWebsiteWrapper } from '../pages/pm-marketing/PMWebsiteWrapper'



export default [

	// {
	// 	// component: FCDashboardWrapper,
	// 	path: '/dashboard',
	// 	menu: {
	// 		menuPath: '/dashboard',
	// 		title: 'Dashboard',
	// 		iconClass: 'dash-icon-menu',
	// 		iconFile: '/media/icons/Dashboard_InActive.png',
	// 		dynaimcPaths:[],
	// 		supportedPaths:[],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		'FC', 'FCSS', 'FCS', 'FT', 'FMT', 'FMSM', 'MT'
	// 	],
	// },
	{//Work Orders
		component: FacilityWorkorderWrapper,
		path: '/facility-workorder',
		menuCode: 'Workorder',
		menu: {
			menuPath: '/facility-workorder/list/corrective/open',
			title: 'Work Orders',
			// iconClass: 'workorder-icon-menu',
			iconClass: 'work-orders-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			supportedComponents: [
				{ path: '/facility-workorder/view' },
				{ path: '/facility-workorder/check-list' },
				{ path: '/facility-workorder/inspection' },
				{ path: '/facility-workorder/inspection-update' },
				{ path: '/facility-workorder/inspection-view' },
			],
			dynaimcPaths: [
				'/facility-workorder/list/clearance/open',
				'/facility-workorder/list/clearance/confirmed',
				'/facility-workorder/list/clearance/completed',
				'/facility-workorder/list/corrective/in-progress',
				'/facility-workorder/list/corrective/onhold',
				'/facility-workorder/list/corrective/completed',
				'/facility-workorder/list/preventive/open',
				'/facility-workorder/list/preventive/onhold',
				'/facility-workorder/list/preventive/completed',
				'/facility-workorder/list/open/turn-around',
			],
		},
		exact: true,
		permission: [
			'FC', 'FCS', 'FMT'
		],
	},
	{ //Approvals-FMSM (Movein/out, AccessCard)
		component: ApprovalsListWrapper,
		path: '',
		menuCode: 'Approvals',//* API code/name
		menu: {
			isSubMenu: true,
			// menuPath: '',
			title: 'Approvals',
			iconClass: 'approval-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'FC-supervisor-services',
			subMenu: [
				{
					component: ApprovalsListWrapper,
					path: '/approvals',
					menuCode: 'Approvals',//* API code/name
					menu: {
						menuPath: '/approvals/upcoming/movein',
						title: 'Move in & Move out Approvals',
						dynaimcPaths: [
							'/approvals/closed/moveout',
							'/approvals/closed/movein',
							'/approvals/upcoming/movein',
							'/approvals/upcoming/moveout',
						],
						supportedComponents: [
							{
								path: '/fcss-moveinDetails',
								component: MoveInDetailsWrapper
							},
							{ path: '/fcss-moveinDetails/building-access-card' },
							{ path: '/fcss-moveinDetails/parking-access-card' },
							{ path: '/fcss-moveinDetails/other-access-card' },
							{
								path: '/fcss-moveoutDetails',
								component: MoveOutDetailsWrapper,
							},
							{ path: '/fcss-moveoutDetails/building-access-card' },
							{ path: '/fcss-moveoutDetails/parking-access-card' },
							{ path: '/fcss-moveoutDetails/other-access-card' },
							{
								path: '/fcss-moveinDetails1',
								component: MoveInDetailsWrapper1,
							},
							{ path: '/fcss-moveinDetails1/building-access-card' },
							{ path: '/fcss-moveinDetails1/parking-access-card' },
							{ path: '/fcss-moveinDetails1/other-access-card' },
							{
								path: '/fcss-moveoutDetails1',
								component: MoveOutDetailsWrapper1,
							},
							{ path: '/fcss-moveoutDetails1/building-access-card' },
							{ path: '/fcss-moveoutDetails1/parking-access-card' },
							{ path: '/fcss-moveoutDetails1/other-access-card' },
						],
						// supportedComponents: [
						// 	{ path: '/approvals/movein/details' },
						// 	{ path: '/approvals/moveout/details' }
						// ],
					},
					// permission: ['FMSM'],
				},
				{
					component: ServicesWrapper,
					path: '/access-cards',
					menuCode: 'Approvals',//* API code/name
					menu: {
						menuPath: '/access-cards/open',
						title: 'Access Cards',
						dynaimcPaths: [
							'/access-cards/open',
							'/access-cards/active',
							'/access-cards/inactive',
							'/access-cards/closed'
						],
						supportedComponents: [
							{
								path: '/fcss-viewDetails-inactive',
								component: InActiveDetailWrapper
							},
							// { path: '/access-cards/details'},
							{
								path: '/fcss-viewDetails',
								component: FCSSViewDetailsWrapper
							},
							// { path: '/fcss-viewDetails/main-door-keys'},
							// { path: '/fcss-viewDetails/parking-access-card'},

						],
					},
					// permission: ['FMSM'],
				},
			]
		},
		exact: true,
		permission: [
			'FMSM'
		],
	},
	{//Parking -FMSM
		component: ParkingWrapper,
		path: '/parking',
		menuCode: 'Parking',
		menu: {
			menuPath: '/parking/all',
			title: 'Parking',
			iconClass: 'parking-icon-menu',
			iconFile: '/media/icons/Scheduled_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				// { path: '/parking/vehicle-details' },
				{ path: '/parking/add-parking' },
				{ path: '/parking/report-issues' },
			],
		},
		exact: true,
		permission: [
			'FMSM'
		],
	},
	{//Activities
		component: FCSupervisorActivitiesWrapper,
		path: '/activities',
		menuCode: 'Activites',
		menu: {
			menuPath: '/activities/list/open',
			title: 'Activities',
			iconClass: 'activities-icon-menu',
			iconFile: '/media/icons/team_activity_2.png',
			dynaimcPaths: [
				'/activities/list/open',
				'/activities/list/close'
			],
			supportedComponents: [
				{ path: '/activities/add' },
				{ path: '/activities/view' },
			],
		},
		exact: true,
		permission: [
			'FCS', 'FMT', 'FMSM'
		],
	},
	{//My Team-FMSM
		component: MyTeamWrapper,
		path: '/fmsm-my-team',
		menuCode: 'My team',
		menu: {
			menuPath: '/fmsm-my-team/supervisor',
			title: 'My Team',
			iconClass: 'fe-su-myteam-icon-menu',
			iconFile: '/media/icons/fmsm-team-inactive.png',
			dynaimcPaths: [],
			supportedComponents: [
				{ path: '/fmsm-my-team/add/fcs/supervisor' },
				{ path: '/fmsm-my-team/team' },
				{ path: '/fmsm-my-team/supervisor' },
				{
					path: '/fc-su-myteam-details',
					component: FCSMyTeamDetailsWrapper
				},
				{
					path: '/edit-member',
					component: EditMember
				},
			],
		},
		exact: true,
		permission: [
			'FMSM'
		],
	},
	{//Maintenance
		component: FacilityMaintenanceWrapper,
		path: '/facility-maintenance',
		menuCode: 'Maintenance',
		menu: {
			menuPath: '/facility-maintenance/list/open',
			title: 'Maintenance',
			iconClass: 'maint-icon-menu',
			iconFile: '/media/icons/announcement_InActive.png',
			dynaimcPaths: [
				'/facility-maintenance/list/open',
				'/facility-maintenance/list/resolved'
			],
			supportedComponents: [
				{ path: '/facility-maintenance/view' },
				{ path: '/facility-maintenance/add' },
			],
		},
		exact: true,
		permission: [
			'FCS', 'FMT'
		],
	},
	{//FM-Approvals
		component: FMTeamApprovalsListWrapper,
		path: '/fmteam-approvals',
		menuCode: 'Approvals',
		menu: {
			menuPath: '/fmteam-approvals/permit-work/upcoming',
			title: 'Approvals',
			iconClass: 'approval-icon-menu',
			iconFile: '/media/icons/team_activity_2.png',
			dynaimcPaths: [
				'/fmteam-approvals/permit-work/upcoming',
				'/fmteam-approvals/permit-work/closed',
				'/fmteam-approvals/noc/upcoming',
				'/fmteam-approvals/noc/closed',
				'/fmteam-approvals/on-hold/upcoming',
				'/fmteam-approvals/on-hold/closed',
				'/fmteam-approvals/technician/upcoming',
				'/fmteam-approvals/technician/closed',
			],
			supportedComponents: [
				{
					path: '/fmteam-viewDetails/technician',
					component: FMTeamViewDetailsWrapper
				},
				{
					path: '/fmteam-viewDetails/onhold',
					component: FMTeamViewDetailsWrapper
				},
				{
					path: '/fmteam-viewDetails/ptw',
					component: FMTeamViewDetailsWrapper
				},
				{
					path: '/fmteam-viewDetails/noc',
					component: FMTeamViewDetailsWrapper
				},
				// { path: '/facility-maintenance/add' },
			],
		},
		exact: true,
		permission: [
			'FMT'
		],
	},
	{//FM-Directory
		component: ServicesWrapper,
		path: '/directory',
		menuCode: 'Directory',
		menu: {
			menuPath: '/directory/residents',
			title: 'Directory',
			iconClass: 'directory-icon-menu',
			iconFile: '/media/icons/team_activity_2.png',
			dynaimcPaths: [
				'/directory/staff',
				'/directory/residents'
			],
			supportedComponents: [
				{ path: '/directory/staff-deatils' },
				{ path: '/directory/residents-deatils' },
			],
		},
		exact: true,
		permission: [
			'FMT'
		],
	},
	{//FMSM-Directory
		component: ServicesWrapper,
		path: '/directory',
		menuCode: 'Directory',
		menu: {
			menuPath: '/directory/residents',
			title: 'Directory',
			iconClass: 'directory-icon-menu',
			iconFile: '/media/icons/team_activity_2.png',
			dynaimcPaths: [
				'/directory/staff',
				'/directory/residents'
			],
			supportedComponents: [
				{ path: '/directory/staff-deatils' },
				{ path: '/directory/residents-deatils' },
				{ path: '/fc-ss-documents/add', component: ServicesWrapper },
			],
		},
		exact: true,
		permission: [
			'FMSM'
		],
	},
	{//Approval-FCSS
		component: ApprovalsListWrapper,
		path: '/approvals',
		menuCode: 'Approvals',
		menu: {
			menuPath: '/approvals/upcoming/movein',
			title: 'Approvals',
			iconClass: 'approval-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [
				'/approvals/upcoming/moveout',
				'/approvals/upcoming/movein',
				'/approvals/closed/movein',
				'/approvals/closed/moveout',
				'/approvals/moveout/details',
				'/approvals/movein/details'
			],
			supportedComponents: [
				{
					path: '/fcss-moveinDetails',
					component: MoveInDetailsWrapper
				},
				{ path: '/fcss-moveinDetails/building-access-card' },
				{ path: '/fcss-moveinDetails/parking-access-card' },
				{ path: '/fcss-moveinDetails/other-access-card' },
				{
					path: '/fcss-moveoutDetails',
					component: MoveOutDetailsWrapper,
				},
				{ path: '/fcss-moveoutDetails/building-access-card' },
				{ path: '/fcss-moveoutDetails/parking-access-card' },
				{ path: '/fcss-moveoutDetails/other-access-card' },
				{
					path: '/fcss-moveinDetails1',
					component: MoveInDetailsWrapper1,
				},
				{ path: '/fcss-moveinDetails1/building-access-card' },
				{ path: '/fcss-moveinDetails1/parking-access-card' },
				{ path: '/fcss-moveinDetails1/other-access-card' },
				{
					path: '/fcss-moveoutDetails1',
					component: MoveOutDetailsWrapper1,
				},
				{ path: '/fcss-moveoutDetails1/building-access-card' },
				{ path: '/fcss-moveoutDetails1/parking-access-card' },
				{ path: '/fcss-moveoutDetails1/other-access-card' },
			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//Incidents-FCSS
		component: ServicesWrapper,
		path: '/incidents',
		menuCode: 'Incidents',
		menu: {
			menuPath: '/incidents/open-requests',
			title: 'Incidents',
			iconClass: 'incidents-icon-menu',
			iconFile: '/media/icons/Scheduled_InActive.png',
			dynaimcPaths: [
				'/incidents/resolved-requests',
				'/incidents/open-requests',
				// '/incidents/fire-alarm',
				// '/incidents/vehicle-accident',
			],
			supportedComponents: [
				{ path: '/incidents/details' },
				{ path: '/incidents/new-incidents' },
			],
		},
		exact: true,
		permission: [
			'FCSS', 'FMSM'
		],
	},
	{//Access Cards-FCSS
		component: ServicesWrapper,
		path: '/access-cards',
		menuCode: 'Access card',
		menu: {
			menuPath: '/access-cards/open',
			title: 'Access Cards',
			iconClass: 'access-cards-icon-menu',
			iconFile: '/media/icons/Scheduled_InActive.png',
			dynaimcPaths: [
				'/access-cards/open',
				'/access-cards/active',
				'/access-cards/inactive',
				'/access-cards/closed',
			],
			supportedComponents: [
				{ path: '/access-cards/details' },
				{
					path: '/fcss-viewDetails',
					component: FCSSViewDetailsWrapper
				},
				{
					path: '/fcss-viewDetails-inactive',
					component: InActiveDetailWrapper
				},
			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//Visitors-FCSS
		component: ServicesWrapper,
		path: '/visitors',
		menuCode: 'Visitors',
		menu: {
			menuPath: '/visitors/all',
			title: 'Visitors',
			iconClass: 'visitors-icon-menu',
			iconFile: '/media/icons/Scheduled_InActive.png',
			dynaimcPaths: [
				'/visitors/all',
				'/visitors/check-in',
				'/visitors/check-out',
			],
			supportedComponents: [
				{ path: '/visitors/add' },
				{ path: '/visitors/details' },
			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//ADD Documents-FCSS
		component: ServicesWrapper,
		path: '/fc-ss-documents',
		menuCode: 'Documents',
		menu: {
			menuPath: '/fc-ss-documents/open-documents/cheques',
			title: 'Documents',
			iconClass: 'documents-icon-menu',
			iconFile: '/media/icons/Scheduled_InActive.png',
			dynaimcPaths: [
				'/fc-ss-documents/open-documents/cheques',
				'/fc-ss-documents/open-documents/renewal-notices',
				'/fc-ss-documents/open-documents/contracts',
				'/fc-ss-documents/sent-documents/cheques',
				'/fc-ss-documents/sent-documents/renewal-notices',
				'/fc-ss-documents/sent-documents/contracts',
			],
			supportedComponents: [
				{ path: '/fc-ss-documents/add' },
				{ path: '/fc-ss-documents/document-details' },
			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//Parking -FCSS
		component: ParkingWrapper,
		path: '/parking',
		menuCode: 'Parking',
		menu: {
			menuPath: '/parking/all',
			title: 'Parking',
			iconClass: 'parking-icon-menu',
			iconFile: '/media/icons/Scheduled_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				// { path: '/parking/vehicle-details' },
				{ path: '/parking/add-parking' },
				{ path: '/parking/report-issues' },
			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//FCSS-Directory
		component: ServicesWrapper,
		path: '/directory',
		menuCode: 'Directory',
		menu: {
			menuPath: '/directory/residents',
			title: 'Directory',
			iconClass: 'directory-icon-menu',
			iconFile: '/media/icons/team_activity_2.png',
			dynaimcPaths: [
				'/directory/staff',
				'/directory/residents'
			],
			supportedComponents: [
				{ path: '/directory/staff-deatils' },
				{ path: '/directory/residents-deatils' },
			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//Scheduled Visits-FCSS,FMSM
		component: ScheduledVisitWrapper,
		path: '/scheduled-visit',
		menuCode: 'Schedule visit',
		menu: {
			menuPath: '/scheduled-visit/all',
			title: 'Scheduled Visits',
			iconClass: 'scheduled-visit-icon-menu',
			iconFile: '/media/icons/Scheduled_InActive.png',
			dynaimcPaths: [
				'/scheduled-visit/all',
				'/scheduled-visit/completed',
				'/scheduled-visit/upcoming',
				'/scheduled-visit/ongoing'
			],
			supportedComponents: [
				{ path: '/scheduled-visit/view' },
			],
		},
		exact: true,
		permission: [
			'FCSS', 'FMSM'
		],
	},
	{//Announcements
		component: FCSupervisorAnnouncementWrapper,
		path: '/su-announcement',
		menuCode: 'Announcements',
		menu: {
			menuPath: '/su-announcement',
			title: 'Community Post',
			iconClass: 'announce-icon-menu',
			iconFile: '/media/icons/announcement_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				{
					path: '/announcements-details',
					component: AnnouncementsDetails
				},
				{
					path: '/su-create-announcements',
					component: SupervisorCreateAnnouncements,
				},

			],
		},
		exact: true,
		permission: [
			'FC', 'FCS', 'FMT', 'FMSM'
		],
	},
	{//Announcements - fcss
		component: FCSoftServicesAnnouncementsWrapper,
		path: '/fc-ss-announcements',
		menuCode: 'Announcements',
		menu: {
			menuPath: '/fc-ss-announcements/list',
			title: 'Community Post',
			iconClass: 'announce-icon-menu',
			iconFile: '/media/icons/announcement_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				// { path: '/fc-ss-announcements/add' },
				{
					path: '/announcements-details',
					component: AnnouncementsDetails
				},
				// {
				// 	path: '/su-create-announcements',
				// 	component: SupervisorCreateAnnouncements,
				// },

			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//Maintenance-FCSS
		component: FacilityMaintenanceWrapper,
		path: '/facility-maintenance',
		menuCode: 'Maintenance',
		menu: {
			menuPath: '/facility-maintenance/list/open',
			title: 'Maintenance',
			iconClass: 'maint-icon-menu',
			iconFile: '/media/icons/announcement_InActive.png',
			dynaimcPaths: [
				'/facility-maintenance/list/open',
				'/facility-maintenance/list/resolved'
			],
			supportedComponents: [
				{ path: '/facility-maintenance/view' },
				{ path: '/facility-maintenance/add' },
			],
		},
		exact: true,
		permission: [
			'FCSS'
		],
	},
	{//FM-Marketplace - TODO
		component: MarketPlaceWrapper,	//Coming soon
		// component: MTAMarketPlaceWrapper, //All module
		path: '/market-place',
		menuCode: 'Market place',
		menu: {
			menuPath: '/market-place',
			// menuPath: '/market-place/list/events/all',
			title: 'Market Place',
			iconClass: 'mp-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			// dynaimcPaths: [
			// 	'/market-place/list/events',
			// 	'/market-place/list/events/all',
			// 	'/market-place/list/events/dining',
			// 	'/market-place/list/events/lifestyle',
			// 	'/market-place/list/events/entertainment',
			// 	'/market-place/list/offers',
			// 	'/market-place/list/offers/all',
			// 	'/market-place/list/offers/dining',
			// 	'/market-place/list/offers/lifestyle',
			// 	'/market-place/list/offers/entertainment',
			// 	'/market-place/list/vouchers',
			// 	'/market-place/list/vouchers/all',
			// 	'/market-place/list/vouchers/dining',
			// 	'/market-place/list/vouchers/lifestyle',
			// 	'/market-place/list/vouchers/entertainment',
			// 	'/market-place/list/redeemed',
			// 	'/market-place/list/redeemed/all',
			// 	'/market-place/list/redeemed/dining',
			// 	'/market-place/list/redeemed/lifestyle',
			// 	'/market-place/list/redeemed/entertainment',
			// ],
			// supportedComponents: [
			// 	{ path: '/market-place/view' }
			// ],
		},
		exact: true,
		permission: [
			'FMT', 'FMSM'
		],
	},
	{//My Team - FCS
		component: FCSupervisorMyTeamWrapper,
		path: '/fc-su-myteam',
		menuCode: 'My team',
		menu: {
			menuPath: '/fc-su-myteam',
			title: 'My Team',
			iconClass: 'fe-su-myteam-icon-menu',
			iconFile: '/media/icons/MyTeam.png',
			dynaimcPaths: [],
			supportedComponents: [
				{
					path: '/addtechnician',
					component: AddTechnicianPage
				},
				{
					path: '/fc-su-myteam-details',
					component: FCSMyTeamDetailsWrapper
				},
				{
					path: '/edit-member',
					component: EditMember
				},
			],
		},
		exact: true,
		permission: [
			'FCS'
		],
	},
	{//My Team - FM
		component: FMTeamMyTeamWrapper,
		path: '/fm-myteam',
		menuCode: 'My team',
		menu: {
			menuPath: '/fm-myteam',
			title: 'My Team',
			iconClass: 'fe-su-myteam-icon-menu',
			iconFile: '/media/icons/fmsm-team-inactive.png',
			dynaimcPaths: [
				'/fm-myteam',
				'/fm-myteam/add',
			],
			supportedComponents: [
				{
					path: '/fc-su-myteam-details',
					component: FCSMyTeamDetailsWrapper
				},
				{
					path: '/edit-member',
					component: EditMember
				},
			],
		},
		exact: true,
		permission: [
			'FMT'
		],
	},
	{//Reports-FC
		component: FCReportsWrapper,
		path: '/fc-reports',
		menuCode: 'Reports',
		menu: {
			menuPath: '/fc-reports',
			title: 'Reports',
			iconClass: 'reports-icon-menu',
			iconFile: '/media/icons/reports_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			// 'FC'	//TO Hide
		],
	},
	{//Reports-FCS
		component: FCReportsWrapper,
		path: '/fc-su-report',
		menuCode: 'Reports',
		menu: {
			menuPath: '/fc-su-report',
			title: 'Reports',
			iconClass: 'reports-icon-menu',
			iconFile: '/media/icons/reports_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			// 'FCS'	//TO Hide
		],
	},
	{//Reports-FM
		component: FMTeamReportsWrapper,
		path: '/fm-report',
		menuCode: 'Reports',
		menu: {
			menuPath: '/fm-report',
			title: 'Reports',
			iconClass: 'reports-icon-menu',
			iconFile: '/media/icons/reports_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'FMT', 'FMSM'	//TO Hide
		],
	},
	{//Calendar-FC,FCS,FM
		component: FCSupervisorCalenderWrapper,
		path: '/fc-su-calendar',
		menuCode: 'Calander',
		menu: {
			menuPath: '/fc-su-calendar/all',
			title: 'Calendar',
			iconClass: 'calender-icon-menu',
			iconFile: '/media/icons/calender_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				{ path: '/fc-su-calendar/all' },
				// { path: '/fc-su-calendar/attendance/total' },
				// { path: '/fc-su-calendar/attendance/present' },
				// { path: '/fc-su-calendar/attendance/absent' },
				// { path: '/fc-su-calendar/attendance/add-attendance' },
			],
		},
		exact: true,
		permission: [
			'FC', 'FCS', 'FMT',
		],
	},
	{//Calendar-FCSS
		component: FCSSCalenderWrapper,
		path: '/fc-ss-calendar',
		menuCode: 'Calander',
		menu: {
			menuPath: '/fc-ss-calendar/all',
			title: 'Calendar',
			iconClass: 'calender-icon-menu',
			iconFile: '/media/icons/calender_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				{ path: '/fc-ss-calendar/all' },
				// { path: '/fc-ss-calendar/attendance/total' },
				// { path: '/fc-ss-calendar/attendance/present' },
				// { path: '/fc-ss-calendar/attendance/absent' },
				// { path: '/fc-ss-calendar/attendance/add-attendance' },
			],
		},
		exact: true,
		permission: [
			'FCSS', 'FMSM'
		],
	},

	{//PM-Offers
		component: PMOffersAndRenewalsWrapper,
		path: '/pm-offers-and-renewals',
		menuCode: 'Offer',
		menu: {
			menuPath: '/pm-offers-and-renewals/offer',
			title: 'Offers',
			iconClass: 'offers-icon-menu',
			iconFile: '/media/icons/tenant-search-active.svg',
			dynaimcPaths: [
				'/pm-offers-and-renewals/offer',
				'/pm-offers-and-renewals/renewals',
			],
			supportedComponents: [
				{ path: '/pm-approvals-offer', component: ApprovalsOfferWrapper },
				{ path: '/pm-approvals-offer/offer-details' },
				{ path: '/pm-approvals-offer/payment-plan-details' },
				{ path: '/pm-approvals-offer/documents' },
				{ path: '/pm-approvals-offer/approval-history' },
				{ path: '/pm-approvals-offer/address-book' },
				{ path: '/pm-approvals-offer/addons' },
				{ path: '/pm-approvals-offer/tenant-details' },
				{ path: '/pm-approvals-offer/notes' },
				// { path: '/fc-ss-calendar/attendance/total' },
				// { path: '/fc-ss-calendar/attendance/present' },
				// { path: '/fc-ss-calendar/attendance/absent' },
				// { path: '/fc-ss-calendar/attendance/add-attendance' },
			],
		},
		exact: true,
		permission: [
			// 'PM', 
			'PE'
		],
	},

	{ //PM Properties (Static)
		component: PMCommunitiesWrapper,
		path: '',
		menuCode: 'Offer',//* API code/name
		menu: {
			isSubMenu: true,
			// menuPath: '',
			title: 'Properties',
			iconClass: 'offers-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'FC-supervisor-services',
			subMenu: [
				{
					component: PMCommunitiesWrapper,
					path: '/communities',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/communities/list',
						title: 'Communities',
						dynaimcPaths: [
							'/communities/add',
							'/communities/list',
							'/communities/create-building',
							'/communities/preview',
							'/communities/edit',
							'/communities/create-unit',
						],
						// supportedComponents: [
						// 	{
						// 		path: '/fcss-moveinDetails',
						// 		component: MoveInDetailsWrapper
						// 	},
						// 	{ path: '/fcss-moveinDetails/building-access-card' },
						// 	{ path: '/fcss-moveinDetails/parking-access-card' },
						// 	{ path: '/fcss-moveinDetails/other-access-card' },
						// 	{
						// 		path: '/fcss-moveoutDetails',
						// 		component: MoveOutDetailsWrapper,
						// 	},
						// 	{ path: '/fcss-moveoutDetails/building-access-card' },
						// 	{ path: '/fcss-moveoutDetails/parking-access-card' },
						// 	{ path: '/fcss-moveoutDetails/other-access-card' },
						// 	{
						// 		path: '/fcss-moveinDetails1',
						// 		component: MoveInDetailsWrapper1,
						// 	},
						// 	{ path: '/fcss-moveinDetails1/building-access-card' },
						// 	{ path: '/fcss-moveinDetails1/parking-access-card' },
						// 	{ path: '/fcss-moveinDetails1/other-access-card' },
						// 	{
						// 		path: '/fcss-moveoutDetails1',
						// 		component: MoveOutDetailsWrapper1,
						// 	},
						// 	{ path: '/fcss-moveoutDetails1/building-access-card' },
						// 	{ path: '/fcss-moveoutDetails1/parking-access-card' },
						// 	{ path: '/fcss-moveoutDetails1/other-access-card' },
						// ],
					},
				},
				{
					component: PMBuildingWrapper,
					path: '/building',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/building/list',
						title: 'Buildings',	
						dynaimcPaths: [
							'/building/add',
							'/building/list',
							'/building/create-unit',
							'/building/preview',
							'/building/edit',
						],					
					},
				},
				{
					component: PMUnitWrapper,
					path: '/units',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/units/list',
						title: 'Units',
					},
				},
				{
					component: PMAccountsWrapper,
					path: '/accounts',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/accounts/list',
						title: 'Accounts',
						dynaimcPaths: [
							'/accounts/add',
							'/accounts/list',
							'/accounts/edit',
						],
					},
				},
			]
		},
		exact: true,
		permission: [
			'PM'
		],
	},

	{ //PM Contacts (Static)
		component: PMOffersAndRenewalsWrapper,
		path: '',
		menuCode: 'Offer',//* API code/name
		menu: {
			isSubMenu: true,
			title: 'Contacts',
			iconClass: 'offers-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'FC-supervisor-services',
			subMenu: [
				{
					component: PMContactWrapper,
					path: '/contacts',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/contacts/list',
						title: 'Contacts',
						dynaimcPaths: [
							'/contacts/add',
							'/contacts/list',
							'/contacts/imports',
							'/contacts/edit',
							'/contacts/preview',
						],
					},
				},
				{
					component: PmContactLeadsWrapper,
					path: '/lead',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/lead/list',
						title: 'Leads',
						dynaimcPaths: [
							'/lead/list',
							'/lead/add',
							'/lead/view',
							'/lead/edit',
						],
					},
				},
				{
					component: PMOpportunitesWrapper,
					path: '/opportunites',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/opportunites/list',
						title: 'Opportunities',	
						dynaimcPaths: [
							'/opportunites/list',
							'/opportunites/add',
							'/opportunites/send/offer',
							'/opportunites/edit',
							'/opportunites/preview',
							'/opportunites/offerList',
							'/opportunites/offerPreview'
						],					
					},
				},
				{
					component: PmContactTasksWrapper,
					path: '/task',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/task/list',
						title: 'Tasks',
						dynaimcPaths: [
							'/task/list',
						],
					},
				},
				
			]
		},
		exact: true,
		permission: [
			'PM'
		],
	},

	{//PM-Calendar
		component: PMMyCalendarWrapper,
		path: '/my-calendar',
		menuCode: 'Calander',
		menu: {
			menuPath: '/my-calendar',
			title: 'My Calendar',
			iconClass: 'calender-icon-menu',
			iconFile: '/media/icons/calender_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'PM', 
			// 'PE'
		],
	},

	{//PM-Documents (Static)
		component: PMDocumentWrapper,
		path: '/pm-documents',
		menuCode: 'Offer',
		menu: {
			menuPath: '/pm-documents',
			title: 'Documents',
			iconClass: 'my-documents-icon-menu',
			iconFile: '/media/icons/calender_InActive.png',
			dynaimcPaths: [
				'/pm-documents/list',
			],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'PM'
		],
	},

	{ //PM Reports (Static)
		component: PMOffersAndRenewalsWrapper,
		path: '',
		menuCode: 'Offer',//* API code/name
		menu: {
			isSubMenu: true,
			title: 'Reports',
			iconClass: 'offers-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'FC-supervisor-services',
			subMenu: [
				// {
				// 	component: PMOffersAndRenewalsWrapper,
				// 	path: '/report-properties',
				// 	menuCode: 'Offer',//* API code/name
				// 	menu: {
				// 		menuPath: '/report-properties',
				// 		title: 'Properties',						
				// 	},
				// },
				{
					component: PMPropertiesWrapper,
					path: '/propertiesReport',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/propertiesReport',
						title: 'Properties',
						dynaimcPaths: [
							'/propertiesReport',
						],
					},
				},
				// {
				// 	component: PMOffersAndRenewalsWrapper,
				// 	path: '/payment-history',
				// 	menuCode: 'Offer',//* API code/name
				// 	menu: {
				// 		menuPath: '/payment-history',
				// 		title: 'Payment History',						
				// 	},
				// },
				{
					component: PMPaymentWrapper,
					path: '/PaymentReport',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/PaymentReport',
						title: 'Payment',
						dynaimcPaths: [
							'/PaymentReport',
						],
					},
				},
				
			]
		},
		exact: true,
		permission: [
			'PM'
		],
	},

	{ //PM Communication (Static)
		component: PMOffersAndRenewalsWrapper,
		path: '',
		menuCode: 'Offer',//* API code/name
		menu: {
			isSubMenu: true,
			title: 'Communication',
			iconClass: 'offers-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'FC-supervisor-services',
			subMenu: [
				{
					component: PMEmailTemplateWrapper,
					path: '/communication',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/communication/email-templates',
						title: 'Email Templates',						
					},
					dynaimcPaths: [
						'/communication/email-templates',
						'/communication/addemail-templates',
						'/communication/editemail-templates',
						'/communication/email-templates-preview'
					],
				},
				{
					component: PMEmailTemplateWrapper,
					path: '/communication',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/communication/messages',
						title: 'Messages',						
					},
					dynaimcPaths: [
						'/communication/messages'
						
					],
				},
				
			]
		},
		exact: true,
		permission: [
			'PM'
		],
	},
	
	{ //PM Settings (Static)
		component: PMUserWrapper,
		path: '',
		menuCode: 'Offer',//* API code/name
		menu: {
			isSubMenu: true,
			title: 'Settings',
			iconClass: 'offers-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'FC-supervisor-services',
			subMenu: [
				{
					component: PMUserWrapper,
					path: '/admin-users',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/admin-users/list',
						title: 'Admin Users',
						dynaimcPaths: [
							'/admin-users/list',
							'/admin-users/add',
							'/admin-users/edit',
						],

					},
				},
				{
					component: PMUserRolesWrapper,
					path: '/user-roles',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/user-roles/list',
						title: 'User Roles',
						dynaimcPaths: [
							'/user-roles/list',
							'/user-roles/edit',
						],
					},
				},
				{
					component: PMMenusWrapper,
					path: '/menus',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/menus/list',
						title: 'Menus',
						dynaimcPaths: [
							'/menus/list',
							'/menus/edit',
							'/menus/add',
						],
					},
				},
				{
					component: PMSettingsWrapper,
					path: '/settings',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/settings',
						title: 'Settings',						
					},
					dynaimcPaths: [
						'/settings'

					],
				},
				{
					component: PMOffersAndRenewalsWrapper,
					path: '/portal',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/portal',
						title: 'Portal',						
					},
				},
				{
					component: PMLeadRuleWrapper,
					path: '/lead-rules',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/lead-rules/list',
						title: 'Lead Rules',
						dynaimcPaths: [
							'lead-rules/list',
							'/lead-rules/add'
						],
					},
				},
			]
		},
		exact: true,
		permission: [
			'PM'
		],
	},

	{ //PM Marketing (Static)
		component: PMOverviewWrapper,
		path: '',
		menuCode: 'Offer',//* API code/name
		menu: {
			isSubMenu: true,
			title: 'Marketing',
			iconClass: 'offers-icon-menu',
			iconFile: '/media/icons/Approvals_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'FC-supervisor-services',
			subMenu: [
				{
					component: PMOverviewWrapper,
					path: '/marketing',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/marketing/overview',
						title: 'Overview',
						dynaimcPaths: [
							'/marketing/overview',
						],
					},
				},
				{
					component: PMEmailWrapper,
					path: '/email',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/email/list',
						title: 'Email',		
						dynaimcPaths: [
							'/email/list',
							'/email/add',
							'/email/template'
						],				
					},
				},
				{
					component: PMSocialMediaWrapper,
					path: '/social-media',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/social-media/list',
						title: 'Social Media',	
						dynaimcPaths: [
							'/social-media/list',
							'/social-media/add',
							'/social-media/edit',
							'/social-media/facebook-campaign',
							'/social-media/twitter-campaign',
							'/social-media/instagram-campaign',
							'/social-media/linkedin-campaign'
						],					
					},
				},
				{
					component: PMWebsiteWrapper,
					path: '/website',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/website/list',
						title: 'Website',	
						dynaimcPaths: [
							'/website/list',
							'/website/add',
							'/website/edit',
							'/website/BounceRates',
							'/website/cost-per-click',
							'/website/click-through-rate',
							'/website/impressions'
						],					
					},
				},
			
				{
					component: PMSettingsMaketingWrapper,
					path: '/settings-marketing',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/settings-marketing',
						title: 'Settings',	
						dynaimcPaths: [
							'/settings-marketing'
						],					
					},
				},
				{
					component: PMAdsWrapper,
					path: '/ads',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/ads/marketing',
						title: 'Ads',	
						dynaimcPaths: [
							'/ads/marketing'
						],					
					},
				},
				{
					component: PMChatWrapper,
					path: '/chat',
					menuCode: 'Offer',//* API code/name
					menu: {
						menuPath: '/chat/marketing',
						title: 'Chat',	
						dynaimcPaths: [
							'/chat/marketing'
						],					
					},
				},
				
			]
		},
		exact: true,
		permission: [
			'PM'
		],
	},

	{//PM-Offers
		component: PMApprovalsWrapper,
		path: '/pm-approvals',
		menuCode: 'Approvals',
		menu: {
			menuPath: '/pm-approvals/open-requests',
			title: 'Approvals',
			iconClass: 'pm-approval-icon-menu',
			iconFile: '/media/icons/approval-in-active-icon.svg',
			dynaimcPaths: [
				'/pm-approvals/open-requests',
				'/pm-approvals/resolved-requests',
			],
			supportedComponents: [
				{
					path: '/pm-insufficient-notice',
					component: InsufficientNoticeWrapper
				},
				{ path: '/pm-insufficient-notice/request-details' },
				{ path: '/pm-insufficient-notice/payment' },
				{ path: '/pm-insufficient-notice/documents' },
				{ path: '/pm-insufficient-notice/history' },
				{ path: '/pm-insufficient-notice/approval-history' },
				{ path: '/pm-insufficient-notice/notes' },
				{
					path: '/pm-service-request',
					component: ServiceRequestWrapper
				},
				{ path: '/pm-service-request/request-details' },
				{ path: '/pm-service-request/payment' },
				{ path: '/pm-service-request/documents' },
				{ path: '/pm-service-request/history' },
				{ path: '/pm-service-request/approval-history' },
				{ path: '/pm-service-request/notes' },
				{
					path: '/pm-early-termination-request',
					component: PMEarlyTerminationRequestWrapper
				},
				{ path: '/pm-early-termination-request/request-details' },
				{ path: '/pm-early-termination-request/payment' },
				{ path: '/pm-early-termination-request/documents' },
				{ path: '/pm-early-termination-request/history' },
				{ path: '/pm-early-termination-request/approval-history' },
				{ path: '/pm-early-termination-request/notes' },
				{
					path: '/offer-details',
					component: OfferDetailsWrapper
				},
				{ path: '/offer-details/request-details' },
				{ path: '/offer-details/payment' },
				{ path: '/offer-details/documents' },
				{ path: '/offer-details/history' },
				{ path: '/offer-details/approval-history' },
				{ path: '/offer-details/notes' },
				{
					path: '/contract',
					component: ContractWrapper
				},
				{ path: '/contract/request-details' },
				{ path: '/contract/payment' },
				{ path: '/contract/documents' },
				{ path: '/contract/history' },
				{ path: '/contract/approval-history' },
				{ path: '/contract/notes' },
				
			],
		},
		exact: true,
		permission: [
			// 'PM'
		],
	},


	// {//PM-Tenant Search
	// 	component: PMTenantSearchWrapper,
	// 	path: '/pm-tenant-search',
	// 	menuCode: 'Tenant search',
	// 	menu: {
	// 		menuPath: '/pm-tenant-search',
	// 		title: 'Tenant Search',
	// 		iconClass: 'tenant-search-icon-menu',
	// 		iconFile: '/media/icons/tenant-search-active.svg',
	// 		dynaimcPaths: [
	// 		],
	// 		supportedComponents: [
	// 			{path: '/pm-tenant-view', component: PMTenantView}
	// 		],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		// 'PM',
	// 		'PE'
	// 	],
	// },
	{//PM-Property Data
		component: PMPropertyDataWrapper,
		path: '/pm-property-data',
		menuCode: 'Property data',
		menu: {
			menuPath: '/pm-property-data/available',
			title: 'Property Data',
			iconClass: 'mta-properties-icon-menu',
			iconFile: '/media/icons/properties_active.png',
			dynaimcPaths: [
				'/pm-property-data/upcoming',
				'/pm-property-data/edit-details',
				'/pm-property-data/available'
			],
			supportedComponents: [
				{
					path: '/pm-availability',
					component: PMAvailabilityWrapper
				},
				{ path: '/pm-availability/property-details' }
				
			],
		},
		exact: true,
		permission: [
			// 'PM', 
			'PE'
		],
	},
	{//PM-My Team
		component: PMMyTeamWrapper,
		path: '/pm-myteam',
		menuCode: 'My team',
		menu: {
			menuPath: '/pm-myteam/all',
			title: 'My Team',
			iconClass: 'mta-myteam-icon-menu',
			iconFile: '/media/icons/mta_myteam_un_active.png',
			dynaimcPaths: [
				'/pm-myteam/all',
				// '/pm-myteam-action-required',
				// '/pm-myteam-add-member',
				'/pm-myteam/month',
				'/pm-myteam/week',
				'/pm-myteam/all'
			],
			supportedComponents: [
				{
					path: '/pm-myteam-action-required',
					component: PMMyteamActionRequired
				},
				{
					path: '/pm-myteam-add-member',
					component: PMMyTeamAddMember
				},
				{
					path: '/pm-myteam-team-members-details',
					component: PMTeamMembersDetails
				},
				
			],
		},
		exact: true,
		permission: [
			// 'PM',
		],
	},
	// {//PM-Report
	// 	component: PMReportWrapper,
	// 	path: '/pm-report',
	// 	menuCode: 'Reports',
	// 	menu: {
	// 		menuPath: '/pm-report',
	// 		title: 'Report',
	// 		iconClass: 'reports-icon-menu',
	// 		iconFile: '/media/icons/reports_InActive.png',
	// 		dynaimcPaths: [],
	// 		supportedComponents: [],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		'PM', 'PE'
	// 	],
	// },
	{//Announcements
		component: FCSupervisorAnnouncementWrapper,
		path: '/su-announcement',
		menuCode: 'Announcements',
		menu: {
			menuPath: '/su-announcement',
			title: 'Community Post',
			iconClass: 'announce-icon-menu',
			iconFile: '/media/icons/announcement_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				{
					path: '/announcements-details',
					component: AnnouncementsDetails
				},
				{
					path: '/su-create-announcements',
					component: SupervisorCreateAnnouncements,
				},

			],
		},
		exact: true,
		permission: [
			// 'PM',
			'PE'
		],
	},
	
	

	//Finance
	{
		component: ApprovalsListWrapper,
		path: '',
		menuCode: 'Service Requests',//* API code/name
		menu: {
			isSubMenu: true,
			// menuPath: '',
			title: 'Services Request',
			iconClass: 'my-serv-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'sr-menu',
			subMenu: [
				{
					component: FinanceServiceRequestWrapper,
					path: '/finance-services-request',
					menuCode: 'Service Requests',
					menu: {
						menuPath: '/finance-services-request/list/open',
						title: 'Services',
						dynaimcPaths: [
							'/finance-services-request/list/open',
							'/finance-services-request/list/closed',
						],
						supportedComponents: [
							{ path: '/finance-services-request/view' },
						],
					},
				},
				{
					component: FinanceServiceRequestRefundsWrapper,
					path: '/finance-services-request-refunds',
					menuCode: 'Service Requests',
					menu: {
						menuPath: '/finance-services-request-refunds/list/open-requests-list',
						title: 'Refunds',
						dynaimcPaths: [
							'/finance-services-request-refunds/list/open-requests-list',
							'/finance-services-request-refunds/list/closed-requests-list',
						],
						supportedComponents: [
							{ path: '/finance-services-request-refunds/view' },
						],
					},
				},
				{
					component: FinanceMoveoutRequestsWrapper,
					path: '/finance-moveout-requests',
					menuCode: 'Service Requests',
					menu: {
						menuPath: '/finance-moveout-requests/list/open',
						title: 'Move-out Requests',
						dynaimcPaths: [
							'/finance-moveout-requests/list/open',
							'/finance-moveout-requests/list/closed',
						],
						supportedComponents: [
							{ path: '/finance-moveout-requests/view' },
						],
					},
				},
				{
					component: FinanceServiceRequestReceiptsWrapper,
					path: '/finance-services-receipt',
					menuCode: 'Service Requests',
					menu: {
						menuPath: '/finance-services-receipt/list/day-list',
						title: 'Invoices',
						dynaimcPaths: [
							'/finance-services-receipt/list/day-list',
							'/finance-services-receipt/list/month-list',
							'/finance-services-receipt/list/week-list',
							'/finance-services-receipt/list/day-list',
							'/finance-services-receipt/list/all-list',
						],
						supportedComponents: [
							{ path: '/finance-services-receipt/view' },
						],
					},
				},
				{
					component: FinanceServiceRequestLeaseWrapper,
					path: '/finance-services-lease',
					menuCode: 'Service Requests',
					menu: {
						menuPath: '/finance-services-lease/list/day-list',
						title: 'Lease Details',
						dynaimcPaths: [
							'/finance-services-lease/list/day-list',
							'/finance-services-lease/list/month-list',
							'/finance-services-lease/list/week-list',
							'/finance-services-lease/list/all-list',
						],
						supportedComponents: [
							{ path: '/finance-services-lease/view' },
						],
					},
				},
				{
					component: FinancePaymentWrapper,
					path: '/finance-payments',
					menuCode: 'Service Requests',
					menu: {
						menuPath: '/finance-payments/list/all/creditCard-payment',
						title: 'Payments',
						dynaimcPaths: [
							'/finance-payments/list/all/creditCard-payment',
							'/finance-payments/list/all/bank-transfer',
							'/finance-payments/list/all/cheque',
							'/finance-payments/list/rent/creditCard-payment',
							'/finance-payments/list/rent/bank-transfer',
							'/finance-payments/list/rent/cheque',
							'/finance-payments/list/security-deposits/creditCard-payment',
							'/finance-payments/list/security-deposits/bank-transfer',
							'/finance-payments/list/security-deposits/cheque',
							'/finance-payments/list/service-charges/creditCard-payment',
							'/finance-payments/list/service-charges/bank-transfer',
							'/finance-payments/list/service-charges/cheque',
							'/finance-payments/list/other-charges/creditCard-payment',
							'/finance-payments/list/other-charges/bank-transfer',
							'/finance-payments/list/other-charges/cheque',
						],
						supportedComponents: [
							{ path: '/finance-payments/view' },
						],
					},
				},
				
			]
		},
		exact: true,
		permission: [
			'FT'
		],
	},
	{//FT-Report
		component: FinanceReportsWrapper,
		path: '/finance-reports',
		menuCode: 'Reports',
		menu: {
			menuPath: '/finance-reports',
			title: 'Report',
			iconClass: 'reports-icon-menu',
			iconFile: '/media/icons/reports_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'FT'
		],
	},
	{//FT-Marketplace
		component: MarketPlaceWrapper,	//Coming soon
		path: '/market-place',
		menuCode: 'Market place',
		menu: {
			menuPath: '/market-place',
			title: 'Market Place',
			iconClass: 'mp-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'FT'
		],
	},
	{//FT-Calendar
		component: FinanceCalendarWrapper,
		path: '/finance-calendar',
		menuCode: 'Market place',
		menu: {
			menuPath: '/finance-calendar',
			title: 'Calendar',
			iconClass: 'calender-icon-menu',
			iconFile: '/media/icons/calender_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'FT'
		],
	},

	// {//MT+MTA-Properties
	// 	component: MTAPropertiesWrapper,
	// 	path: '/property',
	// 	menuCode: 'Properties',
	// 	menu: {
	// 		menuPath: '/property/list',
	// 		title: 'Properties',
	// 		iconClass: 'mta-properties-icon-menu',
	// 		iconFile: '/media/icons/properties_active.png',
	// 		dynaimcPaths: [],
	// 		supportedComponents: [
	// 			{ path: '/property/view'},
	// 			// { path: '/property/edit'},
	// 		],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		'MA', 'MT'
	// 	],
	// },	
	{//MTA-Promotions
		component: MTAPromotionsWrapper,
		path: '/promotions',
		menuCode: 'Promotions',
		menu: {
			menuPath: '/promotions/list/upcoming/events',
			title: 'Promotions',
			iconClass: 'campaign-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			dynaimcPaths: [
				'/promotions/list/upcoming/events',
				'/promotions/list/upcoming/offers',
				'/promotions/list/upcoming/vouchers',
				'/promotions/list/closed/events',
				'/promotions/list/closed/offers',
				'/promotions/list/closed/vouchers',
			],
			supportedComponents: [
				{path: '/promotions/view'},
				{path: '/promotions/add/event'},
				{path: '/promotions/add/offer'},
				{path: '/promotions/add/voucher'},
			],
		},
		exact: true,
		permission: [
			'MT', 'MA'
		],
	},
	{//MTA-My Team
		component: MTAMyteamWrapperNew,
		path: '/mta-myteam',
		menuCode: 'My team',
		menu: {
			menuPath: '/mta-myteam/list',
			title: 'My Team',
			iconClass: 'mta-myteam-icon-menu',
			iconFile: '/media/icons/mta_myteam_un_active.png',
			dynaimcPaths: [
				// '/mta-myteam/list/rejected',
				// '/mta-myteam/list/approved',
				// '/mta-myteam/list/pending',
			],
			supportedComponents: [
				{path: '/mta-myteam/view'},
				{path: '/mta-myteam/add'},
				{path: '/mta-myteam/list-all'},
			],
		},
		exact: true,
		permission: [
			'MA'
		],
	},
	// {//MTA-Marketplace
	// 	component: MTAMarketPlaceWrapperOld,
	// 	path: '/mta-market-place',
	// 	menuCode: 'Market place',
	// 	menu: {
	// 		menuPath: '/mta-market-place/total',
	// 		title: 'Market Place Old',
	// 		iconClass: 'mp-icon-menu',
	// 		iconFile: '/media/icons/My_Lease_InActive.png',
	// 		dynaimcPaths: [
	// 			'/mta-market-place/create-event',
	// 			'/mta-market-place/create-offer',
	// 			'/mta-market-place/create-voucher',
	// 			'/mta-market-place/market-place-offer-details',
	// 			'/mta-market-place/redeemed',
	// 			'/mta-market-place/vouchers',
	// 			'/mta-market-place/discounts',
	// 			'/mta-market-place/total'
	// 		],
	// 		supportedComponents: [],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		'MA', 'MT'
	// 	],
	// },

	{//MTA-Approval
		component: MTAApprovalsWrapper,
		path: '/mta-approvals',
		menuCode: 'Approvals',
		menu: {
			menuPath: '/mta-approvals/list/pending',
			title: 'Approvals',
			iconClass: 'mta-approvals-icon-menu',
			iconFile: '/media/icons/approval-active.png',
			dynaimcPaths: [
				'/mta-approvals/list/pending',
				'/mta-approvals/list/rejected',
				'/mta-approvals/list/approved',
			],
			supportedComponents: [
				{ path: '/mta-approvals/view'}
			],
		},
		exact: true,
		permission: [
			'MA', 'MT'
		],
	},
	{//MTA-Marketplace
		component: MTAMarketPlaceWrapper,
		path: '/market-place',
		menuCode: 'Market place',
		menu: {
			menuPath: '/market-place/list/events/all',
			title: 'Market Place',
			iconClass: 'mp-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/market-place/list/events',
				'/market-place/list/events/all',
				'/market-place/list/events/dining',
				'/market-place/list/events/lifestyle',
				'/market-place/list/events/entertainment',
				'/market-place/list/events/active',
				'/market-place/list/events/inactive',
				'/market-place/list/events/expired',
				'/market-place/list/offers',
				'/market-place/list/offers/all',
				'/market-place/list/offers/dining',
				'/market-place/list/offers/lifestyle',
				'/market-place/list/offers/entertainment',
				'/market-place/list/offers/active',
				'/market-place/list/offers/inactive',
				'/market-place/list/offers/expired',
				'/market-place/list/vouchers',
				'/market-place/list/vouchers/all',
				'/market-place/list/vouchers/dining',
				'/market-place/list/vouchers/lifestyle',
				'/market-place/list/vouchers/entertainment',
				'/market-place/list/vouchers/active',
				'/market-place/list/vouchers/inactive',
				'/market-place/list/vouchers/expired',
				'/market-place/list/redeemed',
				'/market-place/list/redeemed/all',
				'/market-place/list/redeemed/dining',
				'/market-place/list/redeemed/lifestyle',
				'/market-place/list/redeemed/entertainment',
				'/market-place/list/redeemed/active',
				'/market-place/list/redeemed/inactive',
				'/market-place/list/redeemed/expired',
			],
			supportedComponents: [
				// { path: '/market-place/add/event' },
				// { path: '/market-place/add/offer' },
				// { path: '/market-place/add/voucher' },
				{ path: '/market-place/view' }
			],
		},
		exact: true,
		permission: [
			'MA', 'MT'
		],
	},
	{//MTA-Merchants
		component: MTAMerchantsWrapper,
		path: '/merchant',
		menuCode: 'Merchant',
		menu: {
			menuPath: '/merchant/list',
			title: 'Merchants',
			iconClass: 'directory-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [
				{path: '/merchant/view'},
			],
		},
		exact: true,
		permission: [
			'MA', 'MT'
		],
	},
	{//MTA-Report
		component: MTAReportWrapper,
		path: '/mta-report',
		menuCode: 'Dashboard',
		menu: {
			menuPath: '/mta-report',
			title: 'Report',
			iconClass: 'reports-icon-menu',
			iconFile: '/media/icons/reports_InActive.png',
			dynaimcPaths: [],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'MA', 'MT'
		],
	},

	//  {
	//   component: ModuleN,
	//   path: '/module-n',
	//   title: 'Module - N',
	//   permission: [
	//    Roles.SUPER_ADMIN,
	//    Roles.ADMIN,
	//    Roles.MANAGER
	//   ],
	//   children: [
	//    {
	//     component: ModuleNChild1,
	//     path: '/child-1',
	//     title: 'Child - 1',
	//    },
	//    {
	//     component: ModuleNChild2,
	//     path: '/child-2',
	//     title: 'Child - 2',
	//    },
	//    {
	//     component: ModuleNChild3,
	//     path: '/child-3',
	//     title: 'Child - 3',
	//     permission: [
	//      Roles.SUPER_ADMIN,
	//      Roles.ADMIN
	//     ]
	//    }
	//   ]
	//  },
]