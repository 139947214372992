import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import pdficon from "../../../../../assets/images/pdf-file.png";
import previewImg from '../../../../../assets/images/home.png'
import eye from '../../../../../assets/images/preview-eye.png'
import { RootState } from '../../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import moment from 'moment'
import { ViewPopup } from './ViewPopup';
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';

export function Documents() {
    let offerList: any = []
    offerList = useSelector<RootState>(({ General }) => General.offersRenewlaData)
    const [viewImage, setViewImage] = useState<any>()
    const handleView = (data: any) => {
        setViewImage(data)
    }
    const { t } = useTranslation();

    const docData = offerList?.documents?.length > 0 ? offerList?.documents : []

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                {docData.length > 0
                                    ? docData?.map((item: any, index: number) => {
                                        return (
                                            <div className="col-md-6 col-12 mb-6" key={index}>
                                                <div className='com-docx-sec'>
                                                    <div className='row align-items-center'>
                                                        <div className="col-lg-7">
                                                            <div className="row">
                                                                <div className='col-12 mb-0'>
                                                                    <div className="position-relative image-preview-box">
                                                                        <img src={item?.filename} alt="images" className="img-fluid img-preview" />
                                                                        <div className="image-preview">
                                                                            <button
                                                                                className='btn border-0'
                                                                                data-bs-target='#view-popUpreschedule'
                                                                                data-bs-toggle='modal'
                                                                                data-bs-dismiss='modal'
                                                                                type='button'
                                                                                onClick={() => {
                                                                                    handleView(item?.filename)
                                                                                }}>
                                                                                <img src={eye} alt="images" width="29" height="15" className="img-fluid" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-12 mb-4'>
                                                                    <span><img src={pdficon} alt="docx-icon" className='docx-icon me-3' /></span>
                                                                    <span className="docx-title"> </span>
                                                                </div> */}

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <div className="row">
                                                                <div className='col-12 mb-3'>
                                                                    <p className='text-normal font-12 mb-1'>{t("Created On")}:</p>
                                                                    <p className='mb-0 text-black font-12 fw-normal'>{formatDate(item.created_at)}</p>
                                                                </div>
                                                                <div className='col-12 mb-3'>
                                                                    <p className='text-normal font-12 mb-1'>{t("Expiries On")}:</p>
                                                                    <p className='mb-0 text-black font-12 fw-normal'>{formatDate(item.expiry_date)}</p>
                                                                </div>
                                                                <div className='col-12 mb-2'>
                                                                    <p className='text-normal font-12 mb-0'>{t("File Name")}:</p>
                                                                    <p className='mb-0 text-black font-12 fw-normal'>
                                                                        <span className='docx-title'>{item.original_name}</span>
                                                                    </p>
                                                                </div>
                                                                {/* <div className='col-12 col-md-12 mb-3'>
                                                                    <button className='btn btn-outline-primary text-decoration-none py-2 px-6 w-100' 
                                                                    data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(item?.filename)
                                                                        }}>
                                                                        {t("Preview")}
                                                                    </button>
                                                                </div> */}
                                                                <ViewPopup viewImage={viewImage}  />
                                                                <div className='col-12 col-md-12'>
                                                                    <a href={item?.filename} target="_blank" className="btn btn-primary text-decoration-none py-2 px-6 w-100">{t("Download")}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : t("No Data Found")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}