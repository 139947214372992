import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../../../constants'
import { Alert, Spinner } from 'react-bootstrap-v5'
import axios from 'axios'
interface Props {
	tabKey: any
}
const API_URL = process.env.REACT_APP_API_URL
const CARD_PAYMENT = `${API_URL}/payment/pay`
export const CardPayment: React.FC = () => {
	const history = useHistory()
	const [checked, setChecked] = useState(false)
	const [loading, setLoading] = useState(false)
	const handleCheck = (e: any) => {
		setChecked(e.target.checked)
	}
	let offerList: any
	offerList = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)
	const onSubmit = async () => {
		setLoading(true)
		const formData = {
			offer_id: offerList?.offerId,
			amount: offerList?.tabMode === 2 ? offerList?.securityDeposit : offerList?.amount,//offerList?.amount,
			payment_category: '1',
			payment_for: offerList?.tabMode,
			property_id: offerList?.propertyId
		}
		const resp = await axios.post(CARD_PAYMENT, formData)
		if (resp.data.code === 200 && resp.data.errorCode != 1) {
			setLoading(false)
			window.open(
				resp.data.data.paymentUrl,
				'_self'
			)
		} else {
			alert('Something went wrong')
		}
	}

    // const handleTermsClick = () => {
    //     window.open("https://property.realcube.estate/terms-and-conditions");
    // };

	return (
		<div className=' '>
			<div className='row'>
				<div className='col-12'>
					<div className='row form-add-design'>
						{/* <div className="col-12">
                            <div className="mb-5 position-relative p-5 bg-white rounded">
                                <input className="form-check-input me-5" type="radio" name="period" id="inlineRadio1" checked/>
                                <label className="form-check-label text-black fw-bolder font-14" htmlFor="inlineRadio1">Credit Card</label>
                            </div>
                        </div> */}
						{/* <div className="col-12">
                            <div className="mb-5 position-relative p-5 bg-white rounded">
                                <div className="row">
                                    <div className="col-12 mb-5">
                                        <label className="font-14 fw-bold mb-3 text-black d-block">Enter Credit Card Number</label>
                                        <input type="text" className="form-control fw-bolder bg-secon" placeholder="" />
                                    </div>
                                    <div className="col-6 mb-5">
                                        <label className="font-14 fw-bold mb-3 text-black d-block">Expiry Date</label>
                                        <input type="text" className="form-control fw-bolder bg-secon" placeholder="" />
                                    </div>
                                    <div className="col-6 mb-5">
                                        <label className="font-14 fw-bold mb-3 text-black d-block">CVV  <span className="float-end text-secondary">Help?</span></label>
                                        <input type="text" className="form-control fw-bolder bg-secon" placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
						<div className="col-12 text-center">
							{loading ? <Spinner animation='border' variant='primary' /> : null}
						</div>
						<div className='col-12'>
							<div className='mb-5 form-check'>
								<input
									className='form-check-input fc-wo-checkbox'
									type='checkbox'
									id='flexCheckDefault'
									onChange={handleCheck}
								/>
								<label className='form-check-label' htmlFor='flexCheckDefault'>
									Accept
								</label>
								<Link to={{pathname:'https://property.realcube.estate/terms-and-conditions'}} target="_blank">
								<span className="ps-1 c-pointer text-theme fw-bold font-14">
									Terms and Conditions
								</span>
								</Link>
							</div>
						</div>
						<div className='col-12'>
							<button
								className='btn btn-primary fw-bold px-10 py-3 w-100'
								// data-bs-toggle='modal'
								// data-bs-target='#extend-offer-popup'
								disabled={!checked ? !loading ? true : false : false}
								onClick={onSubmit}
								style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
							// title={!checked ? 'Please accept the terms and conditions' : ''}
							>
								{!checked ? 'Please accept the terms and conditions' : 'Confirm Payment'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
