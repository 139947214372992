import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {CompactPagination} from '../../../../components/partials/content/CompactPagination/CompactPagination'
import {CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'
import {useTranslation} from 'react-i18next'
import {RootState} from '../../../../constants'
import {getFinanceServiceRequestList} from '../../../../constants/axios/apis'

export function FinanceServiceOpenRequest({searchItem, setSearchItem}: any) {
  const {t} = useTranslation()
  const pageSize = 10
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalServiceRequestCount, setTotalServiceRequestCount] = useState<any>(0)
  const [serviceRequestList, setServiceRequestList] = useState<any>([])
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)

  useEffect(() => {
    setCurrentPage(1)
  }, [currentBuilding, searchItem])

  useEffect(() => {
    setLoading(true)
    let newPayload =
      '?currentPage=' +
      currentPage +
      '&pageSize=' +
      pageSize +
      '&status=open' +
      '&id_sort=1' +
      '&search=' +
      searchItem
    newPayload = currentBuilding
      ? newPayload + '&building_id=' + currentBuilding
      : newPayload + '&building_id=0'
    getFinanceServiceRequestList(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setServiceRequestList(response.data)
        setTotalServiceRequestCount(response.dataCount)
      } else {
        setServiceRequestList([])
        setTotalServiceRequestCount(0)
      }
      setLoading(false)
    })
  }, [currentPage, currentBuilding, searchItem])

  if (!loading && (serviceRequestList === undefined || serviceRequestList?.length === 0)) {
    return <NoDataFound1 />
  }

  const rowData = serviceRequestList?.map((data: any, index: any) => (
    <tr key={data.id}>
      {/* <td>{(currentPage - 1) * pageSize + index + 1}</td> */}
      <td>{data?.ticket ? data.ticket : '-'}</td>
      <td>{data?.category ? data.category : '-'}</td>
      <td>{data?.tenantName ? data.tenantName : '-'}</td>
      <td>{data?.propertyCode ? data.propertyCode : '-'}</td>
      <td className='text-warning'>{data.approvalStatusText}</td>
      <td>
        <Link
          to={{
            pathname: '/finance-services-request/view',
            state: {serviceDetail: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <div className='row'>
                  <div className='col-12 pt-5'>
                    <div className='rec-payment-act-sec'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='table-responsive'>
                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                              <thead>
                                <tr>
                                  {/* <th className=''> {t('No')} </th> */}
                                  <th className=''> {t('Ticket')}</th>
                                  <th className=''> {t('Category')} </th>
                                  <th className=''> {t('Tenant Name')} </th>
                                  <th className=''> {t('Unit')} </th>
                                  <th className=''> {t('Status')} </th>
                                  <th className=''> {t('Action')} </th>
                                </tr>
                              </thead>
                              {loading ? (
                                <tbody>
                                  <tr>
                                    <td colSpan={7} className='text-center pt-10 pb-0'>
                                      <CustomLoader2 />
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>{serviceRequestList ? rowData : ''}</tbody>
                                // <tbody>
                                //   {serviceRequestList?.length > 0 ? (
                                //     rowData
                                //   ) : (
                                //     <tr>
                                //       <td colSpan={7} className='text-center pt-10 pb-0'>
                                //         <NoDataFound1 />
                                //       </td>
                                //     </tr>
                                //   )}
                                // </tbody>
                              )}
                            </table>
                          </div>
                          {/* {!loading && serviceRequestList?.length > 0 ? ( */}
                          <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalServiceRequestCount)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                          />
                          {/* ) : (
                            ''
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
