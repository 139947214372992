import React, { useState, useEffect, useRef } from 'react'
import LeftArrow from '../../../assets/images/Left_Arrow.png'
import uploadImg from "../../../assets/images/file-upload.svg";
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  createMaintenanceRequest,
  getAvailableTimings,
  getMaintenanceRequestsCategories,
  // getMasterData,
  // getFacilityCompanyData,
  // getFmCommunityUser,
  getPreciseLocationsCommon,
  getFacilityBuildingList,
  getFacilityBuildingContractList,
} from '../../../constants/axios/apis'
// import {getUserBuildingCommunityList} from '../../../utils/makeRequest'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { RootState } from '../../../constants'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { Time } from './Time'
import DatePicker from 'react-date-picker'
import moment from 'moment'
// import { RequestFailedPopup } from '../../SuccessfulPopup/RequestFailedPopup'
import { PreventDatePickerInput } from '../../../constants/generics/dateTimeFormater'
import { CustomRequestSuccessfulPopup } from '../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { LoadingErrorAlert, LoadingSubmitAlert } from '../../../constants/generics/customAlerts'
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { validateAndCompressImage } from '../../../constants/generics/fileUploadHandler'

const today = new Date()
today.setHours(0, 0, 0, 0)
var _dd = String(today.getDate()).padStart(2, '0');
var _mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var _yyyy = today.getFullYear();

// let todays_date = _yyyy + '-' + _mm + '-' + _dd;


export function FacilityMaintenanceAdd({setPopupSuccessMsg}:any) {
  const { t } = useTranslation();
  const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)
  const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

  // const currentContract: any = useSelector<RootState>(({Tdashboard}) => Tdashboard.selectedContract)
  const location: any = useLocation()
  const quickLinkCategory = location?.state?.quickLinkCategory || undefined
  const state: any = useSelector(state => state);
  const userId = state?.auth?.user?.data?.id;
  const history = useHistory()

  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const inputRef = useRef<HTMLInputElement>(null)
  const { Option } = Select;

  let today_data_object = new Date();
  
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [categories, setCategories] = useState<any>([])
  // const [selectedCategory, setSelectedCategory] = useState<any>()
  const [subCategories, setSubCategories] = useState<any>()
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>()
  const [precise_locations, setPreciseLocations] = useState<any>([])
  const [timeSlots, setTimeSlots] = useState<any>([])
  const [buildingList, setBuildingList] = useState<any>([])
  const [buildingUnitList, setBuildingUnitList] = useState<any>([])
  const [unitListOptions, setUnitListOptions] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>({
    building: false, // coming from RootState
    unit: true,
    location: true,
    categories: true
  })
  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    isSubmitted: false,
    message: '',
  })
  // const [propertyList, setPropertyList] = useState<any>([])
  const [submitError, setSubmitError] = useState('')

  const initialValues = {
    category_id: quickLinkCategory ? quickLinkCategory.category_id : '',
    category_type_id: quickLinkCategory ? quickLinkCategory.id : '',
    issue_type: '',
    // property_id: 0,
    building_id: currentBuilding ? currentBuilding : '',
    property_id: '',
    date: '',
    time: '',
    description: '',
    precise_location: '',
    photo: '',
  }

  const newRequestSchema = Yup.object().shape({
    building_id: Yup.number().required(t('Please select a Building!')),
    property_id: Yup.number().required(t('Please select a Unit!')),
    category_type_id: Yup.number().required(t('Please choose an issue category!')),
    issue_type: Yup.number().required(t('Please choose an issue type!')),
    // date: Yup.date()
    //   .typeError('Please select a date')
    //   .min(today, 'Selected date cannot be in the past')
    //   .required('Please select a date'),
    date: Yup.string().required(t('Please select a date!'))
      .test('futureDate', t('Selected date cannot be in the past!'), (item) => moment(item).diff(today, 'days') >= 0),
    time: Yup.string().required(t('Time must be picked!')),
    description: Yup.string()
      .required(t('Please provide a description of the problem!'))
      .matches(/^[^\s].*/, t('Description cannot start with a blankspace!'))
      .max(250, t('Description should be 250 characters!')),
    precise_location: Yup.string().required(t('Please select a location!')),
    // photo: Yup.mixed()
    //   .test('type', t('Only the following formats are acceptable: .jpeg, .jpg, .png'), (value) => {
    //     if (value) {
    //       return (
    //         value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png'
    //       )
    //     }
    //     return true
    //   })
    //   .test('fileSize', t('The file is too large!'), (value) => {
    //     if (value) {
    //       return value?.size <= 2000000
    //     }
    //     return true
    //   }),
  })

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }
  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('photo', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('photo')
          formik.setFieldValue('photo', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('photo', true, false)
          formik.setFieldValue('photo', '', false)
          formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', '', false)
      formik.setFieldError('photo', t('File upload error!'))
      setUploading(false)
    }
    // inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
    // formik.setFieldTouched('photo')
    // formik.setFieldValue('photo', e.currentTarget.files[0])
  }

  useEffect(() => {
    getMaintenanceRequestsCategories().then(({ data: categoriesData }) => {
      // if(isFCS)//Ignore Indoor services based on user
      // categoriesData = categoriesData?.filter((category: any) => category?.categoryName === 'Outdoor')
      setCategories(categoriesData ? categoriesData : [])
      setIsLoading((oState: any) => ({...oState, categories: false}))
    }).catch((e) => {setIsLoading((oState: any) => ({...oState, categories: false}))})

    getPreciseLocationsCommon().then(({ data: preciseLocationList }) => {
      setPreciseLocations(preciseLocationList ? preciseLocationList : [])
      setIsLoading((oState: any) => ({...oState, location: false}))
    }).catch((e) => {setIsLoading((oState: any) => ({...oState, location: false}))})

    // getFmCommunityUser('?user_id=' + userId).then(({ data }) => {
    //   if (data && Array.isArray(data)) {
    //     setBuildingList(data.map((item: any) => ({ id: `${item.building_id}`, name: item.building_name, communityId: `${item.community_id}` })))

    //     // const communityList =  data.map((item: any) => ({ id: `${item.community_id}`, name: item.community }))
    //     // const filteredCommulityList = communityList?.filter((value, index, self) => index === self.findIndex((el) => ( el.id === value.id )))
    //     // setPropertyList(filteredCommulityList)
    //   }
    //   setIsLoading((oState: any) => ({ ...oState, building: false }))
    // }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, building: false })); })

    // getUserBuildingCommunityList(userId).then(({buildingListData, communityListData}) => {
    //   setBuildingList(buildingListData)
    //   setIsLoading((oState: any) => ({...oState, building: false}))
    // })
    // .catch((e) => { setIsLoading((oState: any) => ({ ...oState, building: false })) })

    /* Fetch all units */
    getFacilityBuildingList().then(({ data }) => {
      setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({ buildingId: item.id, unitList: item.property })) : [])
      setIsLoading((oState: any) => ({ ...oState, unit: false }))
    }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, unit: false })) })
    
    /* Fetch units having contract only */
    // getFacilityBuildingContractList().then(({data}) => {
    //   setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({buildingId: `${item.id}`, unitList: item.property})) : [])
    //   setIsLoading((oState: any) => ({...oState, unit: false}))
    // }).catch((e) => {setIsLoading((oState: any) => ({...oState, unit: false}))})
  }, [])

  useEffect(() => {
    if (quickLinkCategory) {
      setSelectedSubCategory(subCategories?.find((item: any) => item.id == quickLinkCategory.id))
    }
  }, [subCategories])

  useEffect(() => {
    setSubCategories(
      categories
        ?.map((cat: any) => {
          return cat?.service_category_types
        })
        .flat()
    )
  }, [categories])

  // const handleSelect = (e: any) => {
  //   formik.handleChange(e)
  //   setSelectedSubCategory(subCategories.find((item: any) => item.id == e.target.value))
  //   formik.setFieldValue(
  //     'category_id',
  //     subCategories.find((item: any) => item.id == e.target.value).category_id
  //   )
  //   formik.setFieldValue('issue_type', '')
  //   formik.setFieldTouched('issue_type', false)
  // }

  const handleSelect = (val: any) => {
    // formik.handleChange(e)
    formik.setFieldValue('category_type_id', val)

    setSelectedSubCategory(subCategories.find((item: any) => item.id == val))
    formik.setFieldValue(
      'category_id',
      subCategories.find((item: any) => item.id == val).category_id
    )
    formik.setFieldValue('issue_type', '')
    formik.setFieldTouched('issue_type', false)
  }

  const handleBuildingSelect = (val: any) => {
    // formik.handleChange(e)
    formik.setFieldValue('building_id', val)
    formik.setFieldValue('property_id', '')
    formik.setFieldTouched('property_id', false)
  }

  // const handleDate = (e: any) => {
  //   // console.log(e,'date changed');

  //   formik.setFieldValue('date', new Date(e))
  //   formik.setFieldValue('time', '')
  //   formik.setFieldTouched('time', false)
  // }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: newRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

      setSubmitStatus((oStatus:any)=>({...oStatus, loading: true, error: false}))

      setTimeout(() => {
        createMaintenanceRequest(
          values.category_id,
          values.category_type_id,
          values.issue_type,
          values.property_id,
          moment(values.date).format('YYYY-MM-DD'),
          values.time,
          values.description,
          values.precise_location,
          values.photo
        )
          .then((response: any) => {
            if (response.errorCode === 1)
              setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
            else if (response.errorCode === 0) {
              setPopupSuccessMsg(response.data)
              setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.data, isSubmitted: true}))
              successRef.current.click()
              setTimeout(() => {
                formik.resetForm()
                history.push("/facility-maintenance/list/open")
              }, 1000)
            } else
              setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
          })
          .catch((e) => {
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (formik.values.date && formik.values.date !== '') {
      getAvailableTimings(
        0, //Property ID - to fetch all time slots
        moment(formik.values.date).format('YYYY-MM-DD'),
        0
      ).then(({data}) => {
        //Validate/Filter and display only future date & time slots.
        function validateTime(inputTime: any) {
          const dt = moment(moment(formik.values.date).format('YYYY-MM-DD') + ' ' + inputTime + ':00')
          if (moment().diff(dt, 'minutes') > 0)
            return false
          return true
        }
        const validTimes = data.filter((element: any) => validateTime(element));
        setTimeSlots(validTimes)
      })
    } else {
      setTimeSlots([])
    }
  }, [formik.values.date])

  // const subCategoryOptions = subCategories?.map((subCategory: any) => (
  //   <option key={subCategory.id} value={subCategory.id}>
  //     {subCategory.name}
  //   </option>
  // ))

  // const issueTypes = selectedSubCategory?.service_issue_types?.map((issueType: any) => (
  //   <option key={issueType.id} value={issueType.id}>
  //     {issueType.issue}
  //   </option>
  // ))

  useEffect(() => {
    const selectedBuildingId = formik.values.building_id
    const selectedBuildingUnits = (selectedBuildingId && selectedBuildingId !== "") ? buildingUnitList?.find((el: any) => el.buildingId === selectedBuildingId) : []
    setUnitListOptions(selectedBuildingUnits?.unitList?.length ? selectedBuildingUnits.unitList : [])
    formik.setFieldValue('property_id', '')
  }, [formik.values.building_id, buildingUnitList])

  const [dateValue, setDateValue] = useState<any>()

  const handleDateChange = (e: any) => {
    // console.log(new Date(e), 'handleDateChange');
    setDateValue(e);
    var menDate = e ? moment(e).format('YYYY-MM-DD') : '';
    // var menDate = new Date(e).toISOString().slice(0, 10);
    formik.setFieldValue("date", menDate);
    // formik.setFieldTouched("date");
    formik.setFieldValue('time', '')
    formik.setFieldTouched('time', false)
  }

  

  const loading = isLoading.categories || isLoading.location ||isLoading.unit ||isLoading.building
  const loadingError = categories.length === 0 ? (t('No category data found!'))
    : precise_locations.length === 0 ? (t('No location data found!'))
    : buildingList.length === 0 ? (t('No building data found!'))
    : buildingUnitList.length === 0 ? (t('No unit data found!'))
    : undefined

  PreventDatePickerInput()

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            {/* <Link to={'/facility-maintenance/list/open'} className=''> */}
            <span
              className='c-pointer'
              onClick={() => {
                history.goBack()
              }}
            >
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              {/* </Link> */}
            </span>
              <span> {t("New Maintenance Request")}</span>
          </h4>
        </div>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='card mb-3'>
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              <div className='card-body px-8 py-6'>
                <LoadingErrorAlert loading={loading} loadinError={loadingError} />
                <div className='row form-add-design'>
                  {/* { isLoading.building || isLoading.unit ? (
                    <div className="col-12 d-flex flex-column justify-content-center">
                        <Loader />
                    </div>
                    ): (isLoadingError !== '' && (
                        <div className="col-12">
                            <div className="alert alert-danger mt-2 text-center w-100" role="alert">
                                { isLoadingError }
                            </div>
                        </div>
                    ))
                  } */}
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Issue Category")}
                        <span className='text-danger'> * </span>
                      </label>
                      <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder={t("Select Issue Category")}
                        optionFilterProp="children"
                        defaultValue={formik.values.category_type_id !=='' ? formik.values.category_type_id : null}
                        onChange={handleSelect}
                        onBlur={formik.handleBlur}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {subCategories?.map((el: any, index: number) => 
                           <Option key={index} value={el.id}>{el.name}</Option>
                        )}
                      </Select>
                      {/* <select
                        className='form-control form-select fw-bolder form-control-solid'
                        data-control='select2'
                        data-allow-clear='true'
                        name='category_type_id'
                        onChange={(e) => handleSelect(e)}
                        value={formik.values.category_type_id}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          Please Select
                        </option>
                        {categories ? subCategoryOptions : ''}
                      </select> */}
                      {formik.touched.category_type_id && formik.errors.category_type_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.category_type_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Issue Type")} <span className='text-danger'> * </span>
                      </label>
                      <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder={t("Select Issue Type")}
                        optionFilterProp="children"
                        value={formik.values.issue_type !=='' ? formik.values.issue_type : null}
                        onChange={(val)=> {formik.setFieldValue("issue_type", val)}}
                        onBlur={formik.handleBlur}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {selectedSubCategory?.service_issue_types?.map((el: any, index: number) => 
                           <Option key={index} value={el.id}>{el.issue}</Option>
                        )}
                      </Select>
                      {/* <select
                        className='form-control form-select fw-bolder form-control-solid'
                        data-control='select2'
                        data-allow-clear='true'
                        name='issue_type'
                        onChange={formik.handleChange}
                        value={formik.values.issue_type}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          Please Select
                        </option>
                        {subCategories ? issueTypes : ''}
                      </select> */}
                      {formik.touched.issue_type && formik.errors.issue_type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.issue_type}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10 position-relative date-picker'>
                      <label className='form-label fw-bolder'>
                        {t("Date")} <span className='text-danger'> * </span>
                      </label>
                      {/* <DatePicker
                        onChange={(e: any) => handleDate(e)}
                        // onKeyDown={(e: any) => handleDate(e)}
                        value={formik.values.date}
                        minDate={new Date(new Date().toLocaleDateString())}
                        className={clsx(
                          'form-control fw-bolder form-control-solid',
                          {
                            'is-invalid': formik.touched.date && formik.errors.date,
                          },
                          {
                            'is-valid': formik.touched.date && !formik.errors.date,
                          }
                          )}
                        format="yyyy-MM-dd"
                      /> */}
                      <DatePicker
                        value={dateValue}
                        className='form-control fw-bolder form-control-solid'
                        format='dd-MM-y'
                        minDate={today_data_object}
                        onChange={handleDateChange}
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.date}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-12'>
                    <Time timeSlots={timeSlots} formik={formik} />
                  </div>
                  <div className='col-12'>
                    <div className='mb-10 w-100 overflow-hidden'>
                      <label className='form-label fw-bolder d-block mb-3'>
                        {t("Precise Location")} <span className='text-danger'> * </span>
                      </label>
                      <div className='overflow-scroll pb-3'>
                        <div className='btn-group locat-group maintanance_time_bt'>
                          {precise_locations?.map((location: any) => {
                            return (
                              <div key={location.id}>
                                <input
                                  type='radio'
                                  className='btn-check'
                                  name='precise_location'
                                  value={location.id}
                                  onChange={formik.handleChange}
                                  //id='btnradio7'
                                  id={`btnradio_${location.id}`}
                                  autoComplete='off'
                                  onBlur={formik.handleBlur}
                                  checked={formik.values.precise_location == location.id}
                                />
                                <label
                                  className='btn btn-outline-primary me-4'
                                  htmlFor={`btnradio_${location.id}`}
                                >
                                  <img
                                    src={location.icon}
                                    alt='img'
                                    className='img-fluid mb-3 d-block'
                                  />
                                  {location.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>

                        {formik.touched.precise_location && formik.errors.precise_location && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.precise_location}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Building Name")} <span className='text-danger'> * </span>
                      </label>
                      <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder={t("Select a Building")}
                        optionFilterProp="children"
                        defaultValue={formik.values.building_id!==''?formik.values.building_id :null}
                        onChange={handleBuildingSelect}
                        onBlur={formik.handleBlur}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {listBuilding?.map((el: any, index: number) => 
                           <Option key={index} value={el.id}>{el.name}</Option>
                        )}
                      </Select>
                      {/* <select
                        {...formik.getFieldProps('building_id')}
                        className='form-control form-select fw-bolder form-control-solid'
                      >
                        <option value=''>Select</option>
                        {listBuilding?.map((el: any, index: number) => {
                          return (
                            <option key={index} value={el.id}>{el.name}</option>
                          )
                        })}
                      </select> */}
                      {formik.touched.building_id && formik.errors.building_id ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.building_id}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Unit")} <span className='text-danger'> * </span>
                      </label>
                      <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder={t("Select a Unit")}
                        optionFilterProp="children"
                        value={formik.values.property_id!==''?formik.values.property_id :null}
                        onChange={(val)=> {formik.setFieldValue("property_id", val)}}
                        onBlur={formik.handleBlur}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {unitListOptions?.map((el: any, index: number) => 
                           <Option key={index} value={el.id}>{el.property_code}</Option>
                        )}
                      </Select>
                      {/* <select
                        className='form-control form-select fw-bolder form-control-solid'
                        data-control='select2'
                        data-allow-clear='true'
                        name='property_id'
                        onChange={formik.handleChange}
                        value={formik.values.property_id}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          Please Select
                        </option>
                        {unitListOptions?.map((el: any, index: number) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.property_code}
                            </option>
                          )
                        })}
                      </select> */}
                      {formik.touched.property_id && formik.errors.property_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.property_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 col-12'>
                    <div className='mb-10 upload-input'>
                      <label className='form-label fw-bolder'>
                        {t("Upload Image")}
                      </label>
                      <input
                        ref={inputRef}
                        onChange={(e) => handleDisplayFileDetails(e)}
                        className='d-none'
                        type='file'
                        accept=".png,.jpg,.jpeg"
                        // onBlur={formik.handleBlur}
                      />
                      <button
                        type='button'
                        onClick={handleUpload}
                        disabled={uploading}
                        className={`w-100 py-3 ellipsis btn btn-outline-${
                          uploadedFileName ? 'primary' : 'primary'
                        }`}
                      >
                        <span>
                          {
                            uploading ?
                              <span className='indicator-progress d-block'>
                                {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                              :
                              uploadedFileName ? (
                                uploadedFileName
                              ) : (
                          <span className="txt">
                            <img
                                    alt="File"
                                    src={uploadImg}
                            className="position-relative"
                            loading="lazy"/>
                                  {t("Upload")}{' '}
                                  <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                </span>
                              )}{' '}
                        </span>
                      </button>
                      {formik.touched.photo && formik.errors.photo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.photo}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Description")} <span className='text-danger'> * </span>
                      </label>
                      <textarea
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.description && formik.errors.description,
                          },
                          {
                            'is-valid': formik.touched.description && !formik.errors.description,
                          }
                        )}
                        name='description'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        data-kt-autosize='true'
                        rows={3}
                        placeholder={t('Type here...')}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.description}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-12 text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                      disabled={submitStatus.loading}
                      onClick={() => {
                        formik.resetForm()
                        history.push('/facility-maintenance/list/open')
                      }}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary fw-bold px-10 py-3'
                      disabled={submitStatus.loading || loading || uploading}
                    >
                      {submitStatus.loading ? 
                        <span className='indicator-progress d-block'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                          {t("Submit")}
                        </span>
                        :
                        <span className='indicator-label'>{t("Submit")}</span>
                      }
                    </button>
                  </div>
                </div>
                <LoadingSubmitAlert submitStatus={submitStatus}/>
              </div>
              
              {/* {submitError && (
                // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
                <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                  {submitError}
                </div>
              )} */}
              {/* <span
                ref={successRef}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='d-none'
              ></span> */}
              {/* <CustomRequestSuccessfulPopup
                successMessage={submitStatus.message}
                href={'/facility-maintenance/list/open'}
              /> */}
            </div>
          </form>
        </div>
      </div>
      {/* <p
        ref={successRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
      </p>
      <RequestFailedPopup message={message} /> */}
      <span
        ref={successRef}
        data-bs-target='#req-submitted-popup-redirect'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='d-none'
      ></span>
    </div>
  )
}
