import React, { useState, useRef, useEffect } from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../layout/core'
// import {Overview} from './components/Overview'
// import {Projects} from './components/Projects'
// import {Campaigns} from './components/Campaigns'
// import {Documents} from './components/Documents'
// import {Connections} from './components/Connections'
import ProfileHeader from './ProfileHeader'
import {ProfileDetails} from './ProfileDetails'
import ProfileEdit from './ProfileEdit'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/profile/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const ProfilePage: React.FC = () => {

    const [isEdit, setEdit] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    const onEdit = () => {
      setEdit(true);
    };

    const onDetail = () => {
      setEdit(false);
    };
    
    useEffect(() => {
      setTimeout(()=>{
        setSuccessMsg('')
      },3000)
    }, [successMsg])

    const uploadEl = useRef<HTMLInputElement>(null);

    const [profilePicUploadError, setProfilePicUploadError] = useState<string[]>(['']);

    return (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
      <div className='col-xxl-12 mt-0'>
       
            <div className='row'>
              <div className="col-lg-3 col-12">
               <div className='card  rounded-5 h-100'>
          <div className='card-body p-8'>
                  <ProfileHeader 
                    isEdit={isEdit} 
                    onEdit={onEdit} 
                    onDetail={onDetail}  
                    uploadEl={uploadEl}
                    profilePicUploadError={profilePicUploadError}
                    setProfilePicUploadError={setProfilePicUploadError}
    
                  />
                   </div>
              </div>
              </div>
              <div className="col-lg-9 prof-det col-12">
              <div className='card  rounded-5 h-100'>
          <div className='card-body p-8'>
                  {!isEdit ?
                      <ProfileDetails
                        successMsg={successMsg}
                      /> :
                      <ProfileEdit
                        onDetail={onDetail}
                        setSuccessMsg={setSuccessMsg} 
                        uploadEl={uploadEl}
                        profilePicUploadError={profilePicUploadError}
                        setProfilePicUploadError={setProfilePicUploadError}
                      />
                  }
              </div>
            </div>
          </div>
              </div>
      </div>
    </div>
      {/*<Switch>*/}
        {/*<Route path='/crafted/pages/profile/overview'>*/}
          {/*<PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>*/}
          {/*<Overview />*/}
        {/*</Route>*/}
        {/*<Route path='/crafted/pages/profile/projects'>*/}
          {/*<PageTitle breadcrumbs={profileBreadCrumbs}>Projects</PageTitle>*/}
          {/*<Projects />*/}
        {/*</Route>*/}
        {/*<Route path='/crafted/pages/profile/campaigns'>*/}
          {/*<PageTitle breadcrumbs={profileBreadCrumbs}>Campaigns</PageTitle>*/}
          {/*<Campaigns />*/}
        {/*</Route>*/}
        {/*<Route path='/crafted/pages/profile/documents'>*/}
          {/*<PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>*/}
          {/*<Documents />*/}
        {/*</Route>*/}
        {/*<Route path='/crafted/pages/profile/connections'>*/}
          {/*<PageTitle breadcrumbs={profileBreadCrumbs}>Connections</PageTitle>*/}
          {/*<Connections />*/}
        {/*</Route>*/}
        {/*<Redirect from='/crafted/pages/profile' exact={true} to='/crafted/pages/profile/overview' />*/}
        {/*<Redirect to='/crafted/pages/profile/overview' />*/}
      {/*</Switch>*/}
    </>
  )
};

export {ProfilePage}
