import React, {useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import { PaymentInfo } from './PaymentInfo/PaymentInfo'
import { SendAlert } from './PaymentInfo/SendAlert'

export function PaymentDetails({leaseDetail}: any) {
  const {t} = useTranslation()
  
  const pendingPayments = leaseDetail?.paymentDetails
    ? leaseDetail?.paymentDetails?.filter((item: any) => item.status === "Upcoming")
    // ? leaseDetail?.paymentDetails?.filter((item: any) => item.paymentStatus === 'Pending')
    : []

  const topPendingPayment = Array.isArray(pendingPayments) && pendingPayments.length > 0 ? pendingPayments[0] : undefined

  return (
    <>
      <div className='card px-7 py-7 mb-7'>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <h4 className='font-16 text-black fw-bolder mb-8'>{t('Payment Status')}</h4>
          </div>
        </div>
        <div className='row payment-st-body'>
          {topPendingPayment && 
            <SendAlert
              id={topPendingPayment?.id}
              user_id={leaseDetail?.userId}
              type={topPendingPayment?.subCategory}
              due_date={topPendingPayment?.dueDate}
              amount={topPendingPayment?.amount}
            />
          }

          {leaseDetail?.paymentDetails
            ? leaseDetail?.paymentDetails?.map((paymentData: any, index: number) => {
                return (
                  <PaymentInfo
                    key={index}
                    id={paymentData?.id}
                    due_date={paymentData?.dueDate}
                    paid_date={paymentData?.paymentReceived}
                    method={paymentData?.paymentType}
                    type={paymentData?.subCategory}
                    amount={paymentData?.amount}
                    email={leaseDetail?.email}
                    invoice={paymentData?.invoice_file}
                    receipt={paymentData?.receipt}
                    ref_id={paymentData?.referenceId}
                    payment_status={paymentData?.status === 'Upcoming' ? 0 : 1} //TODO: for pain,pending and failed
                  />
                )
            }):''
          }

          {/* {topPendingPayment ? (
            <div className='col-12 mb-6'>
              <div className='pay-alert-box rounded py-3 ps-3 pe-8'>
                <div className='row align-items-center'>
                  <div className='col-sm-6 col-md-2 col-lg-2 col-xl-1 col-xxl-1'>
                    <div className='bg-gray py-4 px-2'>
                      <img src={HomeSmall} className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-3 col-lg-2 col-xl-2 col-xxl-2'>
                    <div className='ps-xxl-5 ps-xl-5'>
                      <h6 className='font-12 fw-bold text-black mb-4'>{t('Type of Payment')}</h6>
                      <h5 className='font-14 fw-boldest text-black mb-0'>
                        {topPendingPayment?.subCategory ? topPendingPayment.subCategory : '-'}
                      </h5>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    {topPendingPayment?.dueDate &&
                      <div className='ps-xxl-5 ps-xl-5'>
                        <h6 className='font-12 fw-bold text-black mb-4'>{t('Due Date')}</h6>
                        <h5 className='font-14 fw-boldest text-black mb-0'>
                          {formatDate(topPendingPayment?.dueDate)}
                        </h5>
                      </div>
                    }
                  </div>
                  <div className='col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <h6 className='font-12 fw-bold text-black mb-4'>{t('Amount')}</h6>
                    <h5 className='font-14 fw-b dest text-black mb-0'>
                      {t('AED')}{' '}
                      {topPendingPayment?.amount
                        ? currencyFormater(topPendingPayment?.amount)
                        : '-'}
                    </h5>
                  </div>
                  <div className='col-md-3 col-lg-4 col-xl-5 col-xxl-5 text-end'>
                    {submitting.loading ? (
                      <button type='button' className='btn btn-primary fw-bold px-10 py-3' disabled>
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {t('Please wait...')}{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    ) : (
                      <>
                        <button
                          type='submit'
                          onClick={() => sendAlertNotification()}
                          // disabled={isSubmitted}
                          className='btn btn-primary font-14 fw-bold px-8 px-lg-15  py-3'
                        >
                          <span className='indicator-label'>{t('Send Alert')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {submitError && (
                <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                  {submitError}
                </div>
              )}
              <p
                ref={buttonEl}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='invisible d-none btn btn-primary fw-bold px-10 py-3'
              />
              <CustomRequestSuccessfulPopup successMessage={successMsg} />
            </div>
          ) : (
            ''
          )} */}


          {/* {leaseDetail?.paymentDetails
            ? leaseDetail?.paymentDetails?.map((paymentData: any, index: number) => {
                return (
                  <div className='col-12 mb-6' key={index}>
                    <div className='bg-dark-grey mb-5 invoice-no rounded-10 px-8 py-5'>
                      <div className='row'>
                        <div className='col-md-6 mb-md-0 mb-3'>
                          <h6 className='font-14 fw-normal text-black mb-0'>
                            {(paymentData?.subCategory === 'Rent' || paymentData?.subCategory === 'Security Deposit') ?
                              formatDate(paymentData?.status === 'Upcoming' ? paymentData?.dueDate : paymentData?.paymentReceived)
                              : t("Pending")
                            }
                          </h6>
                        </div>

                        <div className='col-md-6 text-md-end text-start'>
                          <h6 className='font-14 fw-normal text-black mb-0'>
                            {t('Invoice Number')} - {' '}{`0000${paymentData?.id}`}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='row align-items-center'>
                      <div className='col-sm-6 col-md-2 col-lg-2 col-xl-1 col-xxl-1 mb-md-0 mb-5'>
                        <div className='border-img rounded px-5 py-5 d-inline-block'>
                          <img src={HomePayment} className='img-fluid' />
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-0 mb-5'>
                        <div className='ps-xxl-5 ps-xl-5'>
                          <h4 className='font-15 fw-bolder mb-3 text-black'> {''} </h4>
                          <h6 className='font-13 text-black mb-3 fw-normal'>
                            {t('Payment Method')} :
                            <span>
                              {' '}
                              {paymentData?.paymentType ? paymentData?.paymentType : '-'}{' '}
                            </span>
                          </h6>
                          <span
                            onClick={()=>onShow(index)}
                            className='text-theme d-block font-11 fw-bold c-pointer'
                          >
                            {isShow[index] ? t('Hide Details') : t('View Details')}
                          </span>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-0 mb-5'>
                        <div className='ps-xxl-5 ps-xl-5'>
                          <h6 className='font-13 text-black mb-0 fw-normal'>
                            {t('Type of Payment')} :
                            <span>
                              {' '}
                              {paymentData?.subCategory ? paymentData?.subCategory : '-'}{' '}
                            </span>
                          </h6>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-4 col-lg-4 col-xl-5 col-xxl-5 mb-md-0 mb-5 text-md-end text-start'>
                        <div className='pe-5'>
                          <h5 className='fw-bolder font-14 mb-0 text-gray-500'>
                            {t('AED')}{' '}
                            {paymentData?.amount ? currencyFormater(paymentData?.amount) : '-'}
                          </h5>
                        </div>
                      </div>
                    </div>
                    {isShow[index] ? (
                      paymentData?.status !== 'Upcoming' ? (
                      // paymentData?.paymentStatus === 'Success' ? (
                        <div className='row align-items-center mt-8'>
                          <div className='offset-lg-2 offset-xl-1 offset-xxl-1 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='ps-xxl-5 ps-xl-5'>
                              <div className='payment-progress'>
                                <div className='step'>
                                  <div>
                                    <div className='circle success'>
                                      <i className='bi bi-check-circle-fill'></i>
                                    </div>
                                  </div>
                                  <div>
                                    <div className='pay-status'> {t('Payment Received')} </div>
                                    <div className='time-info'>
                                      {paymentData?.paymentReceived
                                        ? formatDateTimeMeridiem(paymentData?.paymentReceived)
                                        : '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-7 col-xl-8 col-xxl-8'>
                            <div className='row'>
                              <div className='col-md-12'>
                                <h6 className='font-13 text-black mb-3 fw-normal'>
                                  {t('Payment Transfer Reference Id')} :
                                  <span>
                                    {' '}
                                    {paymentData?.referenceId ? paymentData?.referenceId : '-'}
                                  </span>
                                </h6>
                              </div>
                              <div className='col-12'>
                                <div className='bg-generate rounded p-7'>
                                  <div className='row align-items-center'>
                                    <div className='col-lg-4'>
                                      <h5 className='text-theme fw-bolder font-14'>
                                        {paymentData?.subCategory} {t('Receipt')}
                                      </h5>
                                      <h6 className='text-black fw-normal font-11'>
                                        {t('Receipt has been generated')}
                                      </h6>
                                    </div>
                                    <div className='col-lg-4'>
                                      <a
                                        href={paymentData?.receipt}
                                        className='btn btn-light font-14 fw-bold w-100 py-3'
                                        target='_blank'
                                      >
                                        {t('View')}
                                      </a>
                                    </div>

                                    <div className='col-lg-4'>
                                      {submitting.loading ? (
                                        <button
                                          type='button'
                                          className='btn btn-primary fw-bold px-10 py-3'
                                          disabled
                                        >
                                          <span
                                            className='indicator-progress'
                                            style={{display: 'block'}}
                                          >
                                            {t('Please wait...')}{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                          </span>
                                        </button>
                                      ) : (
                                        <>
                                          <button
                                            type='submit'
                                            onClick={() => sendReceipt(paymentData.id)}
                                            // disabled={isSubmitted}
                                            className='btn btn-primary font-14 fw-bold w-100 py-3'
                                          >
                                            <span className='indicator-label'>
                                              {t('Send Receipt')}
                                            </span>
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p
                                ref={buttonEl}
                                data-bs-target='#req-submitted-popup'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                              />
                              <CustomRequestSuccessfulPopup
                                successMessage={successMsg}
                                // close={false}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='row align-items-center mt-8'>
                          <div className='offset-lg-2 offset-xl-1 offset-xxl-1 col-lg-3 col-xl-3 col-xxl-3'>
                            <div className='ps-xxl-5 ps-xl-5'>
                              <div className='payment-progress'>
                                {paymentData.paymentStatus === 'Failed' ? (
                                  <div className='step'>
                                    <div>
                                      <div className='circle failed'>
                                        <i className='bi bi-exclamation-circle-fill'></i>
                                      </div>
                                    </div>
                                    <div>
                                      <div className='pay-status'> {t('Payment Failed')} </div>
                                      <div className='time-info'>
                                        {' '}
                                        {paymentData?.paymentReceived
                                          ? formatDate(paymentData?.paymentReceived)
                                          : '-'}{' '}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='step'>
                                    <div>
                                      <div className='circle light'>
                                        <i className='bi bi-exclamation-circle-fill text-warning'></i>
                                      </div>
                                    </div>
                                    <div>
                                      <div className='pay-status'> {t('Payment Due')} </div>
                                      <div className='time-info'>
                                        {paymentData?.dueDate
                                          ? formatDate(paymentData?.dueDate)
                                          : t('Pending')}
                                      </div>
                                    </div>
                                  </div>  
                                )}
                              </div>
                            </div>
                          </div>

                            <div className='col-lg-7 col-xl-8 col-xxl-8'>
                              <div className='row'>
                                <div className='col-12'>
                                  <div className='bg-generate rounded p-7'>
                                    <div className='row align-items-center'>
                                      <div className='col-lg-4'>
                                        <h5 className='text-theme fw-bolder font-14'>{paymentData?.subCategory} {t('Invoice')}</h5>
                                        {paymentData?.invoice_file &&
                                          <h6 className='text-black fw-normal font-11'>
                                            {t('Invoice has been generated')}
                                          </h6>
                                        }
                                      </div>
                                      <div className='col-lg-4'>
                                        <a
                                          href={paymentData?.invoice_file}
                                          className={'btn btn-light font-14 fw-bold w-100 py-3' + (paymentData?.invoice_file ? '' : ' disabled')}
                                          target='_blank'
                                        >
                                          {t('View')}
                                        </a>
                                      </div>

                                      <div className='col-lg-4'>
                                        {submitting.loading ? (
                                          <button
                                            type='button'
                                            className='btn btn-primary font-14 fw-bold w-100 py-3'
                                          // disabled
                                          >
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                              {t('Please wait...')}{' '}
                                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                          </button>
                                        ) : (
                                          <>
                                            <button
                                              type='submit'
                                              onClick={() => sendInnovice(paymentData.id)}
                                              // disabled={isSubmitted || true}
                                              className='btn btn-primary font-14 fw-bold w-100 py-3'
                                            >
                                              <span className='indicator-label'>{t('Send Invoice')}</span>
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                                <p
                                  ref={buttonEl}
                                  data-bs-target='#req-submitted-popup'
                                  data-bs-toggle='modal'
                                  data-bs-dismiss='modal'
                                  className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                                />
                                <CustomRequestSuccessfulPopup
                                  successMessage={submitting.message}
                                // close={false}
                                />
                              </div>
                            </div>

                        </div>
                      )
                    ) : null}
                  </div>
                )
              })
            : ''} */}
        </div>
      </div>
    </>
  )
}
