import React from 'react'
import Siwm from '../../../../assets/images/swimming.svg';
import Gym from '../../../../assets/images/gym.svg';
import Hall from '../../../../assets/images/mall.svg';
import WiFi from '../../../../assets/images/wifi.svg';
import Tennis from '../../../../assets/images/tennis.svg';
import Power from '../../../../assets/images/power.svg';
import * as MyCustomeIcons from 'react-icons/md';
import { CustomDdIcon } from '../../../../constants/generics/customIcons';

export function Amenities(props: any) {
    const proprtyDetails = props.proprtyDetails
    return (
        <div className="row">

            {proprtyDetails && proprtyDetails.amenities.length
                ? proprtyDetails.amenities.map((item: any, index: number) => {
                    if (item.isAvailable === 'Y')
                        return (
                            <div className="col-6 mb-3" key={index}>
                                <p>
                                    <span>
                                        <CustomDdIcon name={item.icon} className="img-fluid me-2"/> {""}
                                    </span>
                                    {item?.amenity}
                                </p>
                            </div>);
                })
                : null}


        </div>
    )
};