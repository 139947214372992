import React, {FC, useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import StarActive from '../../../../assets/images/Star_Active.png'
import StarInactive from '../../../../assets/images/nortificationbell.svg'
import ViewRightArrow from '../../../../assets/images/Right_arrow_white.png'
import {getUserNotifications, postReadNotifications} from '../../../../constants/axios/apis'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getUserRole } from '../../../../constants/generics/userCheck'
import { getNotificationLinks } from '../../../../constants/generics/notificationLinks'
import {useTranslation} from 'react-i18next'
import { AiOutlineReload } from "react-icons/ai";
import { Spin } from 'antd'
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import { RootState } from '../../../../constants'


export function ActivityDrawer() {
  const {t} = useTranslation()
  const history = useHistory()

  const [notifications, setNotifications] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)
  const [reload, setReload] = useState<any>(true)
  const [readNotifs, setReadNotifs] = useState<any>([])

  const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);

  const successRef: any = useRef(null)
  const dispatch = useDispatch()
  let notifCount: any
    notifCount = useSelector<RootState>(({ General }) => General.notif)

  const handleModal = (notification:any) =>{
    successRef.current.click()

  //   if(notification?.is_read === 0){
  //     const formData = new FormData()
  //     formData.append('notification_id', notification?.id)
  //     postReadNotifications(formData).then((response: any) => {
  //       //no action required
  //       // console.log(response, 'response');
  //       const notifCountTemp = isNaN(notifCount) ? '' : Number(notifCount) - 1
  //       dispatch(General.actions.notif(notifCountTemp))
  //     })
  //   }
  // }
  if(notification?.is_read === 0 && !(readNotifs.includes(notification.id))){
    const formData = new FormData()
    formData.append('notification_id', notification?.id)
    postReadNotifications(formData).then((response: any) => {
      //no action required
      // console.log(response, 'response');
      const notifCountTemp = isNaN(notifCount) ? '' : Number(notifCount) - 1
      dispatch(General.actions.notif(notifCountTemp))
    })
  }
}

const handleRead = (notification:any) =>{
  if(notification?.is_read === 0 && !(readNotifs.includes(notification.id))){
    const formData = new FormData()
    formData.append('notification_id', notification?.id)
    postReadNotifications(formData).then((response: any) => {
      //no action required
      // console.log(response, 'response');
      const notifCountTemp = isNaN(notifCount) ? '' : Number(notifCount) - 1
      dispatch(General.actions.notif(notifCountTemp))
      
    })
    setReadNotifs((oState:any)=>{
      let tempArr = [...oState]
      tempArr.push(notification?.id)
      return tempArr})
  }
}

  useEffect(() => {
    if(reload){
      setLoading(true)
      getUserNotifications('').then((response: any) => {
      // getUserNotifications('?pageSize=15').then((response: any) => {
        if (response.data && Array.isArray(response.data)) {
          setNotifications(response.data.length > 15 ? response.data.slice(0, 15) : response.data)
          // dispatch(General.actions.notif(20))
          dispatch(General.actions.notif(response.unreadCount))
        }
        setLoading(false)
        setReload(false)
      }).catch(()=>{setLoading(false); setReload(false)})
    }
  }, [reload])

  const linkState:any = getNotificationLinks()


  const notificationList = notifications?.map((notification: any) => {

    let targetLink = ''
    let targetStateKey = ''
    let targetStateValName = ''
    let stateVal = ''
    let viewBtnText

    linkState.every((element:any) => {
      if(element.notif_type.includes(notification.notification_type) && (element.permission === undefined || element.permission.includes(user_role))){
        targetLink = element.link;
        targetStateKey = element.stateKey
        targetStateValName = element.stateValName
        stateVal = (element.keyname ? notification?.other_data?.[element.keyname] : notification.notification_id)
        viewBtnText = element.viewBtn ? element.viewBtn : (t("View"))
        return false
      }
      return true
    });
    const isAlreadyRead = notification.is_read === 1 || (readNotifs.includes(notification.id))
    
    return(
      <div key={notification.id} className='card mb-3'>
        <span
          ref={successRef}
          data-bs-dismiss='modal'
          className='invisible d-none' ></span>

        {/* <div className='card-body p-5'> */}
        <div className={isAlreadyRead ? 'card-body p-5' : 'card-body p-5 c-pointer'} onClick={() => handleRead(notification)}>
          <div className='row align-items-center'>
            <div className='col-md-1 col-12 pe-md-0'>
              <img src={StarInactive} className='img-fluid' />
            </div>
            <div className='col-md-9 col-12'>
              <div className='d-flex'>
                <h4 className='notif-title font-17 fw-bold mb-3 mt-3 mt-md-0'>
                  {notification.title}
                  
                </h4>
                <span className='notif-time font-14 fw-bold d-block mb-3 mb-md-0'>
                  {moment(notification.created_at).fromNow()}
                  {!isAlreadyRead &&
                    <span className='ms-2 bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-5 start-70 animation-blink'></span>
                  }
                </span>
              </div>
              <div className=''>
                <h6 className='notif-desc fw-normal font-14'>{notification.description}</h6>
              </div>
            </div>

                {
                  user_role && targetLink !== '' && ['FC', 'FCS', 'FCSS', 'FMT', 'FMSM','FT','MA','MT', 'PM', 'PE'].includes(user_role) && stateVal ?
                    <>
                        <div className='col-2 text-end'>
                          <div className='btn-group notif-btn-group w-100'>
                              <Link
                                to={{
                                  pathname: targetLink,
                                  // state: targetStateKey !== "" ? {[targetStateKey]: {[targetStateValName]: stateVal}} : '',
                                  state: targetStateKey !== "" ?
                                      {[targetStateKey]: targetStateValName ? {[targetStateValName]: stateVal} : stateVal} : '',
                                }}
                                onClick={(e)=>handleModal(notification)}
                                // data-bs-dismiss='modal'
                                className='btn btn-primary'
                              >
                                {viewBtnText}
                              </Link>
                          </div>
                        </div>
                    </>
                    :
                    ''
                }

              </div>
            </div>
          </div>
    )
  })

  const handleReload = () =>{
    setReload(true)
  }
  
  return (
    <div className='modal fade notif-popup' id='exampleModal'>
      <div className='modal-dialog modal-dialog-scrollable'>
        <div className='modal-content rounded-10'>
          <div className='modal-header border-0'>
            <h5 className='modal-title fw-bold'>{t("Inbox")}</h5>
            {loading ?
              <span className='d-inline-block font-22 mx-3'>
                <Spin size="default" style={{color:'#F3B521'}}/>
            </span>
              :
              <span className='c-pointer d-inline-block font-22 mx-3' onClick={handleReload}><AiOutlineReload /></span>
            }
            <button type='button' className='btn-close text-black' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-12'>
                {notifications ? notificationList : ''}
                 {/*Your contract Extension Request Accept*/}
                {/* <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>Your offer has been Approved</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your offer has been Approved by the Owner/Agent
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                      onClick={() => history.push('/')}
                                      data-bs-dismiss='modal'
                                      className='btn btn-outline-primary w-100'
                                  >
                                    View Offer
                                  </button>
                                </div>
                                <div className='col-md-5'>
                                  <Link to={''} className='btn btn-primary w-100'
                                    data-bs-target='#pm-approved-notification'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                  >
                                    Proceed To Payment
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>Your Contract Extension Request Accept</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Pay due Amount click the pay now button.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                      onClick={() => history.push('/contract-extension-request-accept')}
                                      data-bs-dismiss='modal'
                                      className='btn btn-outline-primary w-100'
                                  >
                                    View Request
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <Link to={''} className='btn btn-primary w-100' data-bs-target='#payment-modal-new'
                                        data-bs-toggle='modal'
                                        data-bs-dismiss='modal'>
                                    Pay Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Your offer has been Approved
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your offer has been Approved by the Owner/Agent
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 gx-3'>
                                <div className='col-md-3'> </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-primary w-100'>
                                    View Offer
                                  </button>
                                </div>
                                <div className='col-md-5'>
                                  <button
                                    className='btn btn-primary w-100'
                                    data-bs-target='#make-an-offer-notification'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                  >
                                    Proceed To Payment
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Upcoming Service Request
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Upcoming Service Request
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary disabled w-100'>
                                    Arrived
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-danger disabled w-100'>
                                    Not Arrived
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-primary disabled w-100'>
                                    Arrived Late
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              You AC Repair is done on 12-Jun-2021 at 11:29 AM
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>12:35am</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary disabled w-100'>
                                    Resolved
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-secondary disabled w-100'>
                                    Not Resolved
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Upcoming Service Request
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>1:35pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta. Lorem Ipsum ha estat el text estàndard de la
                              tres tipogràfiques.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary w-100'>Reschedule</button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-secondary w-100'>
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              You AC Repair is done on 12-Jun-2021 at 11:29 AM
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>12:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta. Lorem Ipsum ha estat el text estàndard de la
                              tres tipogràfiques.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                    data-bs-target='#feedback-popup'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                    className='btn btn-outline-secondary w-100'
                                  >
                                    Rate Us
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              The Contract is Generated
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>07:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta. Lorem Ipsum ha estat el text estàndard de la
                              tres tipogràfiques.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-secondary w-100'>
                                    Download
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>Contract Expiry</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your contract has been expiring soon please renew it.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                    onClick={() => history.push('/my-services/extend-contract')}
                                    data-bs-dismiss='modal'
                                    className='btn btn-primary w-100'
                                  >
                                    Extend Contract
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <Link to={''} className='btn btn-outline-primary w-100'>
                                    Move-out
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Your Move-in has been Approved
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Move-in Request has been Approved
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary w-100'>View Report</button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary w-100'>Download Report</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Your contract is expiring soon
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>8:25pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your contract has been expiring soon please renew it.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                    onClick={() => history.push('/my-services/contract-renewal')}
                                    data-bs-dismiss='modal'
                                    className='btn btn-primary w-100'
                                  >
                                    Renew Contract
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button
                                    onClick={() => history.push('/my-services/early-termination')}
                                    data-bs-dismiss='modal'
                                    className='btn btn-outline-danger w-100'
                                  >
                                    Terminate Contract
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <Link className='fw-bold text-white font-19' to={'/notifications'}>
            <div className='modal-footer justify-content-center border-0' data-bs-dismiss='modal'>
              <span>{t('View all')}</span>
              <img src={ViewRightArrow} className='img-fluid ms-4 h-21px w-21px' />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
