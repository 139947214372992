import React, { useState } from 'react'
import { useSelector } from 'react-redux';
// import ProfileImage from '../../../../../assets/images/profile-av-lg.jpg'
import Avatar from '../../../../../assets/images/DefaultUser.png';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { CustomRequestSuccessfulPopup } from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup';
import { UpdateAccountStatus } from './UpdateAccountStatus';
import { useTranslation } from 'react-i18next';

export function Profile({userData}:any) {
  const { t } = useTranslation();
  const [accountStatusMsg, setAccountStatusMsg] = useState<any>('')

  const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);
  // const user_data = state?.auth?.user?.data;

  const isTeam = userData?.designation === 'Staff'
  const allowStatusChange = (user_role==='FMT' && ((userData.user_role === 8 && userData.isFmApproved === 1) || userData.user_role === 10 ) ) || (userData.user_role === 9 && user_role==='FMSM')

  return (
    <>
      <div className='profile-img-card rounded-5 w-100 py-9 px-5'>
        <div className='img border w-100 text-center'>
          <img src={userData?.profile_img ? userData?.profile_img : Avatar} className='img-fluid rounded-circle shadow' />
        </div>
        <div className='mx-3'>
          <h5 className='text-black font-16 fw-bolder mt-3 text-center'>{userData?.first_name + ' ' + userData?.last_name}</h5>
          <span className='font-10 text-center d-block fw-bolder mb-3'>({userData?.designation})</span>
          {
            userData?.staff_type === "Tenant" &&
            <>
              <p className='text-center mb-3 text-muted fw-bold font-14'>{t("Unit")}</p>
              <p className='text-center mb-3 fw-bold font-14'>{userData?.property_code}</p>
              <p className='text-center mb-3 text-muted fw-bold font-14'>{t("Building")}</p>
              <p className='text-black font-16 fw-bolder mt-3 mb-0 text-center'>{userData?.building_name}</p>
            </>
          }
          {
            userData?.staff_type !== "Tenant" &&
            <>
              <p className='text-center mb-3 text-muted fw-bold font-14'>{t("Staff Type")}</p>
              <p className='text-center mb-3 fw-bold font-14'>{userData?.staff_type}</p>
            </>
          }
          { isTeam &&
          <>
            <p className='text-center mb-3 text-muted fw-bold font-14'>{t("Account Status")}</p>
            {/* <div className='text-center'><span className='text-black font-16 mt-3 mb-0 bg-success rounded px-2'>{'Active'}</span></div> */}
            
            {
              allowStatusChange ? 
              <UpdateAccountStatus userData={userData} setAccountStatusMsg={setAccountStatusMsg}/>
              :
              <>
                { (userData.user_role === 8 && userData.isFmApproved === 0)?
                    <p className='font-16 mt-3 mb-0 text-center bg-white text-warning px-5 py-3 rounded fw-bolder' >{t("Approval Pending")}</p>
                  : userData?.status === 0 ?
                    <p className='font-16 mt-3 mb-0 text-center bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder' >{t("Inactive")}</p>
                  :<p className='font-16 mt-3 mb-0 text-center bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder' >{t("Active")}</p>
                }
              </>
            }
          </>


          }
          
        </div>
      </div>
      <CustomRequestSuccessfulPopup
          successMessage={accountStatusMsg}
          customID={'-account'}
          // href={'/fc-su-myteam'} //For FCS module
          // close={false}
          showOkBtn={true}
      />
    </>
  )
}
