import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
// import useRequestGet from '../../../../hooks/useRequestGet'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { getActivitiesList } from '../../../../constants/axios/apis';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

export function CloseActivities({searchItem}: any) {
  const { t } = useTranslation();
  const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)
  const [loading, setLoading] = useState<any>(true)
  const pageSize = 10
  const [activitesList, setActivitesList] = useState<any>()
  const [totalActivitesCount, setTotalActivitesCount] = useState<any>(0)
  const [currentPage, setCurrentPage] = useState<any>(1)

  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding]) 

  useEffect(()=>{
    setLoading(true)
    let page = currentPage
    const payload = '?currentPage=' + page + '&pageSize=' + pageSize +'&search=' + searchItem  +'&status=closed&id_sort=1' + (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getActivitiesList(payload).then(( response:any) => {
      if(Array.isArray(response.data) && response.data.length >0){
        setActivitesList(response.data)
        setTotalActivitesCount(response.dataCount)
      } else {
        setActivitesList([])
        setTotalActivitesCount(0)
      }
      setLoading(false)
    }).catch((e:any)=>setLoading(false))
  }, [currentPage, currentBuilding,searchItem])

  if (!loading && (activitesList === undefined || activitesList?.length === 0)) {
    return <NoDataFound1 />
  }

    const activitesData = activitesList?.map((data: any, index: any) => (
        <tr key={data.id}>
          <td>{(currentPage - 1) * pageSize + index + 1}</td>
          <td>{data.master_data_name ? data.master_data_name : '--'}</td>
          <td>{data.activity_category_name ? data.activity_category_name : '--'}</td>
          <td>{data.property_code ? data.property_code : '--'}</td>
          <td>{moment(data.created_at ? data.created_at : '--').format('YYYY-MM-DD hh:mm A')}</td>
          <td>
            <Link
              to={{
                pathname: '/activities/view',
                state: {activityData: data},
              }}
              className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
            >
              <span className='view-option text-theme fw-bolder'>{t("View")}</span>
            </Link>
          </td>
        </tr>
      ))         

    return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body p-0'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                        <thead>
                          <tr>
                            <th className=''> {t("No.")} </th>
                            <th className=''> {t("Request Type")}</th>
                            <th className=''> {t("Request Category")} </th>
                            <th className=''> {t("Unit")} </th>
                            <th className=''> {t("Date & Time")} </th>
                            <th className=''> {t("Action")} </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={6}>
                                <CustomLoader2 />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>{activitesList ? activitesData : ''}</tbody>
                        )}
                      </table>
                      <CompactPagination
                        pageSize={pageSize}
                        totalCount={parseInt(totalActivitesCount)}
                        defaultPage={currentPage}
                        onPageChange={setCurrentPage}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
