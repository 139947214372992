import React, {useState, useEffect, useRef} from 'react'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import Pic from '../../../../../assets/images/announcement-four.png'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {getFacilityDocuments, submitDocumentStatus} from '../../../../../constants/axios/apis'
import {RequestFailedPopup} from '../../../../SuccessfulPopup/RequestFailedPopup'
import pdficon from '../../../../../assets/images/documentImage.png'
import docicon from '../../../../../assets/images/doc-ioc.png'
import {CustomRequestSuccessfulPopup} from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { useTranslation } from 'react-i18next'
import { CustomLoader2 } from '../../../../../constants/generics/customActios'

export function DocumentDetails() {
  let params: any = {}
  params = useLocation()

  let documentId = ''
  if (params.state.documentDetail) {
    documentId = params.state.documentDetail.id
  }

  const [documentDetail, setDocumentDetail] = useState<any>({})

  // const {documentDetail} = params.state

  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  const history = useHistory()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [isLoading, setIsLoading]=useState(true)
  const buttonEl: any = useRef(null)
  const [submitError, setSubmitError] = useState('')

  useEffect(() => {
    setIsLoading(true)
    setDocumentDetail({})
    getFacilityDocuments(`?id=${documentId}`).then((response: any) => {
      if (response?.data && Array.isArray(response.data) && response.data.length > 0)
        setDocumentDetail(response.data[0])
      setIsLoading(false)
    })
  }, [documentId])

  const sendDocumentStatus = () => {
    setTimeout(() => {
      setLoading(true)
      setSubmitError('')
      submitDocumentStatus(documentDetail.id)
        .then((response: any) => {
          setLoading(false)

          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          } else if (response.errorCode === 0) {
            // formik.resetForm()
            setLoading(false)
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/fc-ss-documents/sent-documents/cheques')
            }, 4000)
          } else {
            setSubmitError(t('Some error occured. Please try again !'))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          }
        })
        //   if (response.errorCode === 0) {
        //     history.push('/fc-ss-documents/sent-documents/cheques', {})
        //     successRef.current.click()
        //   } else {
        //     setMessage(response.errorDescription)
        //     failRef.current.click()
        //   }
        // })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(t('Invalid Data Provided'))
          setTimeout(() => {
            setSubmitError('')
          }, 5000)
        })
    }, 1000)
  }

  return (
    <>
      <div className='com-card-section'>
      {
          isLoading?
          <CustomLoader2 /> :
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              {/* <Link to={'/fc-ss-documents/open-documents/cheques'} className=''> */}
              <span
                className='me-5 c-pointer'
                onClick={() => {
                  history.goBack()
                }}
              >
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                {/* </Link> */}
              </span>
              <span className='text-black'>{t("Document Details")}</span>
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-6 py-6'>
                <div className='row'>
                  <div className='col-md-12 col-lg-12 col-12'>
                    <div className='row'>
                      <div className="col-12">
                        <div className="row flex-stack">
                          <div className='col-8'>
                            <span className='text-black fw-bolder font-15 d-block mb-7'>
                              {t("Request Type")}{' : '}
                              {documentDetail?.requestType ? documentDetail?.requestType : '--'}
                            </span>
                          </div>
                          <div className='col-4 text-end'>
                            <span
                              className={
                                documentDetail?.status === 'Open'
                                  ? 'bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                                  : documentDetail?.status === 'Sent'
                                  ? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                                  : 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                              }
                            >
                              {' '}
                              {documentDetail?.status === 'Open'
                                ? (t('Open'))
                                : documentDetail?.status === 'Sent'
                                ? (t('Collected'))
                                : documentDetail?.status}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 col-6 mb-7'>
                        <div className=''>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Uploaded By")} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {documentDetail?.uploaded_user
                              ? documentDetail?.uploaded_user
                              : '--'}{' '}
                          </span>
                        </div>
                      </div>
                      <div className='col-md-3 col-6 mb-7'>
                        <div className=''>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>{t("Building")} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {documentDetail?.building ? documentDetail?.building : '--'}{' '}
                          </span>
                        </div>
                      </div>

                      <div className='col-md-3 col-6 mb-7'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Unit")} </h6>
                        <span className='text-black fw-bold font-15'>
                          {' '}
                          {documentDetail?.property_code
                            ? documentDetail?.property_code
                            : '--'}{' '}
                        </span>
                      </div>
                      <div className='col-md-3 col-6 mb-7'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Status")} </h6>
                        <span className='text-black fw-bold font-15'>
                          {documentDetail?.status ? documentDetail.status === 'Sent' ? t('Closed') : documentDetail.status : '--'}
                        </span>
                      </div>
                      <div className='col-md-6 col-12 mb-7'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Document")} </h6>
                        <div className='border rounded p-4'>
                          <div className='row align-items-center'>
                            <div className='col-sm-9 col-12'>
                              <span>
                                <img src={docicon} alt='docx-icon' className='docx-icon me-3' />
                              </span>
                              <span className='text-black fw-bold font-14 text-short mb-0'>
                                {documentDetail?.requestType ? documentDetail?.requestType : '--'}
                              </span>
                            </div>
                            <div className='col-sm-3 col-12'>
                              <a
                                // className='btn btn-primary w-100 font-12 px-3 py-3'
                                href={documentDetail?.attachments}
                                target='_blank'
                              >
                                {t("Download")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-12'>
                    <h6 className='text-theme-gray mb-3 fw-normal font-14'>{t("Description")}</h6>
                    <div className='data-contain h-auto 75 px-5 py-4 rounded-10 mb-2'>
                      <p className='font-14 fw-bold d-block mb-0'>
                        {documentDetail?.comments ? documentDetail?.comments : '--'}
                      </p>
                    </div>
                  </div>
                </div>
                {documentDetail?.status !== 'Sent' && (
                  <div className='row mt-3'>
                    <div className='col-12 text-end'>
                      <button
                        type='submit'
                        onClick={() => sendDocumentStatus()}
                        className='btn btn-primary fw-bold px-10 py-3'
                        disabled={loading}
                      >
                        {!loading && <span className='indicator-label'>{t("Collect Document")}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {t("Please wait...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {submitError && (
                      <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                        {submitError}
                      </div>
                    )}
                    <p
                      ref={buttonEl}
                      data-bs-target='#req-submitted-popup'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                    />
                    <CustomRequestSuccessfulPopup
                      successMessage={t('Document Collected Successfully')}
                      // href={'/fc-ss-documents/sent-documents/cheques'}
                      close={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </>
  )
}
