import React, { useState, FC, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { Link, useParams, useLocation } from 'react-router-dom';
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { Addons } from './Addons/Addons';
import { ApprovalHistory } from './ApprovalHistory/ApprovalHistory';
import { ApprovalsOfferTab } from './ApprovalsOfferTab/ApprovalsOfferTab';
import { Documents } from './Documents/Documents';
import { AddressBook } from './AddressBook/AddressBook';
import { ExtendOfferButtons } from './ExtendOfferButtons/ExtendOfferButtons';
import { MainOfferCard } from './MainOfferCard/MainOfferCard';
import { OfferDetails } from './OfferDetails/OfferDetails';
import { PaymentPlanDetails } from './PaymentPlanDetails/PaymentPlanDetails';
import { CreateContractPopup } from "../../PMCreateContract/CreateContractPopup";
import { TenantDetails } from './TenantDetails/TenantDetails';
import { Notes } from './Notes/Notes';
import { useDispatch, useSelector } from 'react-redux'
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import { getOffersAndRenewalsDetails } from '../../../../constants/axios/apis';
import { RootState } from '../../../../constants';
import { useTranslation } from 'react-i18next'

type LocationState = {
    id?: any
}


const ApprovalsOfferWrapper: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation<LocationState>();
    const { id } = location.state || {};
    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            getOffersAndRenewalsDetails(id)
                .then((response: any) => {
                    if (response?.errorCode === 0) {
                        dispatch(General.actions.offersRenewlaData(response?.data))
                    }
                })
                .catch((e) => {
                })
        } else {
        }
    }, [id])

    let offersList: any = []
    offersList = useSelector<RootState>(({ General }) => General.offersRenewlaData)
    
    return (
        <>
            <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className="row align-items-center mb-6">
                        <div className="col-lg-6 col-sm-12">
                            <h4 className="card-title font-18 text-black mb-0">
                                <Link to={'/pm-offers-and-renewals/offer'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {t("Offer No")} : {offersList?.offer_details?.offerNumber ? offersList?.offer_details?.offerNumber :"-"}
                            </h4>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end">
                            {/* <button className='btn btn-primary fw-bold px-4 py-3 me-4'>
                                <i className="bi bi-pencil me-2"></i> {t("Update Offer")} 
                            </button> */}
                            <button className='btn btn-theme fw-bold px-4 py-3' data-bs-target='#pm-create-contract-popup'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                disabled>
                                {t("Create Contract")} (Coming Soon)
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-7">
                            <MainOfferCard main={offersList} />
                        </div>
                    </div>
                    <Switch>
                        <Route path='/pm-approvals-offer/offer-details'>
                            <ApprovalsOfferTab />
                            <OfferDetails />
                            <CreateContractPopup />
                        </Route>
                        <Route path='/pm-approvals-offer/payment-plan-details'>
                            <ApprovalsOfferTab />
                            <PaymentPlanDetails payment={offersList} />
                            <CreateContractPopup />
                        </Route>
                        <Route path='/pm-approvals-offer/documents'>
                            <ApprovalsOfferTab />
                            <Documents />
                            <CreateContractPopup />
                        </Route>
                        <Route path='/pm-approvals-offer/approval-history'>
                            <ApprovalsOfferTab />
                            <ApprovalHistory />
                            <CreateContractPopup />
                        </Route>
                        <Route path='/pm-approvals-offer/address-book'>
                            <ApprovalsOfferTab />
                            <AddressBook />
                            <CreateContractPopup />
                        </Route>
                        <Route path='/pm-approvals-offer/addons'>
                            <ApprovalsOfferTab />
                            <Addons />
                            <CreateContractPopup />
                        </Route>
                        <Route path='/pm-approvals-offer/tenant-details'>
                            <ApprovalsOfferTab />
                            <TenantDetails />
                            <CreateContractPopup />
                        </Route>
                        <Route path='/pm-approvals-offer/notes'>
                            <ApprovalsOfferTab />
                            <Notes />
                            <CreateContractPopup />
                        </Route>
                    </Switch>
                    <div className="row">
                        {/* <div className="col-12 mt-1">
                            <ExtendOfferButtons />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export { ApprovalsOfferWrapper }

