import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import ActionDotsIoc from "../../../../../assets/images/Action_dots.png";
import { formatDateTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater';
import {useTranslation} from 'react-i18next'

export function Documents({documentData}: any) {
    const {t} = useTranslation()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="rec-payment-act-sec">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='table-responsive'>
                                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th className=''> {t("No")} </th>
                                                        <th className=''> {t("Date")} </th>
                                                        {/* <th className=''> {t("Status")} </th> */}
                                                        {/* <th className=''> {t("Amount")} </th> */}
                                                        <th className=''> {t("Type")} </th>
                                                        <th className=''> {t("Action")} </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        documentData.all_documents.map((doc:any, index:number)=>{
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{index + 1} </td>
                                                                    <td>{formatDateTimeMeridiem(doc.created)} </td>
                                                                    <td>{doc.document_type === 'addc_clearance_letter' ? t("ADDC Clearance Letter") : doc.document_type}</td>
                                                                    <td>
                                                                        {
                                                                            doc.filename ?
                                                                                <a href={doc.filename} className='view-option text-theme fw-bolder' target='_blank'>
                                                                                    {/* <span className="view-icon"></span> */}
                                                                                    {t("View")}
                                                                                </a>
                                                                                // <span className="ms-lg-2" id='kt_header_user_menu_toggle'>
                                                                                //     <span className="cursor-pointer" data-kt-menu-trigger='click'
                                                                                //         data-kt-menu-attach='parent'
                                                                                //         data-kt-menu-placement='bottom-end'
                                                                                //         data-kt-menu-flip='bottom'>
                                                                                //         <img src={ActionDotsIoc} alt="Dots" className="img-fluid" />
                                                                                //     </span>
                                                                                //     <div className='menu menu-sub menu-sub-dropdown menu-column rounded-12 menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-3 w-145px'
                                                                                //         data-kt-menu='true'>
                                                                                //         <div className='menu-item px-2'>
                                                                                //             <a href={doc.filename} className='menu-link text-black fs-7 ps-2 pe-0 py-2' target='_blank'>
                                                                                //                 <span className="view-icon"></span>
                                                                                //                 {t("View")}
                                                                                //             </a>
                                                                                //             {/* <Link to={''} className='menu-link text-black fs-7 ps-2 pe-0 py-2'>
                                                                                //                 <span className="view-icon"></span>
                                                                                //                 {t("View")}
                                                                                //             </Link> */}
                                                                                //         </div>
                                                                                //     </div>
                                                                                // </span>
                                                                            :
                                                                            <span className='text-muted'><i>{t("No link!")}</i></span>
                                                                        }
                                                                        
                                                                    </td>
                                                                    {/* <td>-
                                                                        <span className="ms-lg-2" id='kt_header_user_menu_toggle'>
                                                                            <span className="cursor-pointer" data-kt-menu-trigger='click'
                                                                                data-kt-menu-attach='parent'
                                                                                data-kt-menu-placement='bottom-end'
                                                                                data-kt-menu-flip='bottom'>
                                                                                <img src={ActionDotsIoc} alt="Dots" className="img-fluid" />
                                                                            </span>
                                                                            <div className='menu menu-sub menu-sub-dropdown menu-column rounded-12 menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-3 w-145px'
                                                                                data-kt-menu='true'>
                                                                                <div className='menu-item px-2'>
                                                                                    <Link to={''} className='menu-link text-black fs-7 ps-2 pe-0 py-2'>
                                                                                        <span className="view-icon"></span>
                                                                                        {t("View")}
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                {/*                                                     
                                                    <tr>
                                                        <td>
                                                            04
                                                        </td>
                                                        <td>
                                                            01-11-2021
                                                        </td>
                                                        <td>
                                                            <span className="cleared-theme">
                                                                {t("Cleared")}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            John Doe
                                                        </td>
                                                        <td>
                                                            <span className="ms-lg-2" id='kt_header_user_menu_toggle'>
                                                                <span className="cursor-pointer" data-kt-menu-trigger='click'
                                                                      data-kt-menu-attach='parent'
                                                                      data-kt-menu-placement='bottom-end'
                                                                      data-kt-menu-flip='bottom'>
                                                                    <img src={ActionDotsIoc} alt="Dots" className="img-fluid" />
                                                                </span>
                                                                <div className='menu menu-sub menu-sub-dropdown menu-column rounded-12 menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-3 w-145px'
                                                                     data-kt-menu='true'>
                                                                     <div className='menu-item px-2'>
                                                                        <Link to={''} className='menu-link text-black fs-7 ps-2 pe-0 py-2'>
                                                                            <span className="view-icon"></span>
                                                                            {t("View")}
                                                                        </Link>
                                                                     </div>
                                                                </div>
                                                            </span>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}