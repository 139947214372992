import React from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { AssignWorkOrderPopup } from '../AssignWorkOrderPopup';
import { useTranslation } from 'react-i18next';

export function ClearanceWorkorderActions({workOrder, setPopupSuccessMsg}:any) {
    const { t } = useTranslation();

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
 
    const isOnHold = workOrder.status === 'On hold'
    const allowAssign = workOrder.is_FMapproved === 1
    const isAssignedUser = state?.auth?.user?.data.id === workOrder.assignedToUserId

    return (
        <div className="row form-add-design">
            <div className="col-12 text-end">
                { (isAssignedUser && workOrder.status === 'Open' ) &&
                    <Link to={{ 'pathname': '/facility-workorder/inspection', state: { 'workOrder': workOrder } }}>
                        <button
                            className='btn btn-primary fw-bold px-10 py-3 me-3'>
                            {t("Start Job")}
                        </button>
                    </Link>
                }
                { (user_role === 'FCS' && (workOrder.status === "Open" || workOrder.status === "On hold")) ?
                    <>
                        <button data-bs-target="#assign-popup"
                            data-bs-toggle="modal" data-bs-dismiss="modal"
                            type='button'
                            disabled={isOnHold && !allowAssign}
                            // disabled={!isValidBuilding || (isOnHold && !allowAssign)}
                            className={isAssignedUser ?
                                'ms-3 px-10 py-3 text-start corrective_table_popup_bt btn btn-primary'
                                : 'px-10 py-3 text-start corrective_table_popup_bt btn btn-primary' }
                            >
                            {(workOrder?.assignedTo?.length > 0)? (t('Re-Assign')) : (t('Assign'))}
                        </button>
                        <AssignWorkOrderPopup
                            workOrder={workOrder}
                            setPopupSuccessMsg={setPopupSuccessMsg}
                        />
                    </>
                    :''
                }
            </div>
        </div>
    );
}