import React, {useState, useEffect} from 'react'
import Loader from '../../../../../components/Loader'
import {getIncidentList} from '../../../../../constants/axios/apis'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import {Link} from 'react-router-dom'
import {formatTimeMeridiem} from '../../../../../constants/generics/dateTimeFormater'
import {RootState} from '../../../../../constants'
import {useSelector} from 'react-redux'
import {CustomLoader2, NoDataFound1} from '../../../../../constants/generics/customActios'
import {CompactPagination} from '../../../../../components/partials/content/CompactPagination/CompactPagination'

import { useTranslation } from 'react-i18next';

export function ResolvedRequests({searchItem, setSearchItem}: any) {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const { t } = useTranslation();
  const pageSize = 10
  const [incidentList, setIncidentList] = useState<any>()
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalIncidentList, setTotalIncidentList] = useState<any>(0)
  const [page, setPage] = React.useState(1)

  const handleClick = (newCurrentPage: any) => {
    setCurrentPage(newCurrentPage)
  }
  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding])
  
  useEffect(() => {
    setLoading(true)
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&status=resolved' +
      '&id_sort=1' +
      '&search=' + searchItem +
      '&time_sort=1' +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getIncidentList(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setIncidentList(response.data)
        // setCurrentPage(page);
        setTotalIncidentList(response.dataCount)
      } else {
        setIncidentList([])
        setTotalIncidentList(0)
      }
      setLoading(false)
    }).catch(()=>{setLoading(false);setIncidentList([]);setTotalIncidentList(0)})
  }, [currentPage, currentBuilding,searchItem])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleClick(value)
  }

  if (!loading && (incidentList === undefined || incidentList.length === 0)) {
    return <NoDataFound1 />
  }

  const incidentData = incidentList?.map((data: any, index: any) => (
    <tr key={data.id}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      {/* <td>{data.categoryParent ? data.categoryParent : '--'}</td> */}
      <td>{data.category ? data.category : '--'}</td>
      <td>{data.buildingLocation ? data.buildingLocation : '--'}</td>
      <td>{formatTimeMeridiem(data.time ? data.time : '--')}</td>
      <td>
        <Link
          to={{
            pathname: '/incidents/details',
            state: {incidentData: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t("View")}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body p-0'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                        <thead>
                          <tr>
                            <th className=''> {t("No.")} </th>
                            {/* <th className=''> {t("Category")}</th> */}
                            <th className=''> {t("Area")} </th>
                            <th className=''> {t("Building Name")} </th>
                            <th className=''> {t("Time")} </th>
                            <th className=''> {t("Action")} </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={5}>
                                <CustomLoader2 />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>{incidentList ? incidentData : ''}</tbody>
                        )}
                      </table>
                      <div className='w-100 d-flex justify-content-end pt-5'>
                        {/* <Stack spacing={2}>
                          <Pagination
                            count={
                              totalIncidentList % pageSize === 0
                                ? totalIncidentList / pageSize
                                : Math.ceil(totalIncidentList / pageSize)
                            }
                            onChange={handlePageChange}
                          />
                        </Stack> */}
                        <CompactPagination
                          pageSize={pageSize}
                          totalCount={parseInt(totalIncidentList)}
                          defaultPage={currentPage}
                          onPageChange={setCurrentPage}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
