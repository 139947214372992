import ChatPage from '../components/apps/chat/ChatPage'
import { Faq } from '../components/faq/Faq'
import { ProfileSetting } from '../components/profile/ProfileSetting'
import { DocumentsPage } from '../pages/documents/DocumentsPage'
import { ServicesWrapper } from '../pages/fc-soft-services-services/ServicesWrapper'

export default [

	{//Visitor & Staff - FMSM
		component: ServicesWrapper,	//* aside menu component
		path: '/visitors',//* aside menu path
		menuCode: 'Visitor and staff',//* API code/name
		menu: {
			menuPath: '/visitors/all',//* default path
			title: 'Visitors and Staff',//* title
			iconClass: 'visitors-staff-icon-menu',//*icon class
			profileIconClass: 'item-icon doc-ioc',//*profile dropdown icon class
			iconFile: '/media/icons/Dashboard_InActive.png',//*Icon name 
			supportedComponents: [
				{ path: '/visitors/details' },
				{ path: '/visitors/add' }
			],//**Inner pages */
			dynaimcPaths: [//* tab urls
				'/visitors/all',
				'/visitors/check-out',
				'/visitors/check-in'
			],
		},
		exact: true,
		permission: [
			'FMSM'
		],
	},
	{//Documents
		component: DocumentsPage,	//* aside menu component
		path: '/documents',//* aside menu path
		menuCode: 'My Documents',//* API code/name
		menu: {
			menuPath: '/documents/emirates-id',//* default path
			title: 'My Documents',//* title
			iconClass: 'my-documents-icon-menu',//*icon class
			profileIconClass: 'item-icon doc-ioc',//*profile dropdown icon class
			iconFile: '/media/icons/Dashboard_InActive.png',//*Icon name 
			supportedComponents: [],//**Inner pages */
			dynaimcPaths: [
				'/documents/passport',//* tab urls
				'/documents/driving-license',
				'/documents/emirates-id'
			],
		},
		exact: true,
		permission: [
			'FC', 'FCS', 'FMT', 'FCSS', 'FMSM', 'FT', 'PM', 'PE', 'MA', 'MT'
		],
	},
	{ // Chat
		component: ChatPage,
		path: '/chat/all',
		menuCode: 'Chat With Us',
		menu: {
			menuPath: '/chat/all',
			title: 'Chat With Us',
			iconClass: 'chat-with-us-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			profileIconClass: 'item-icon chat-ioc',//*profile dropdown icon class
			dynaimcPaths: [
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'FT', 'PM', 'PE'
		],
	},
	{ // FAQ
		component: Faq,
		path: '/faq',
		menuCode: 'FAQ',
		menu: {
			menuPath: '/faq',
			title: 'FAQ',
			iconClass: 'faq-icon-menu',
			iconFile: '/media/icons/faq-inactive.png',
			profileIconClass: 'item-icon quest-ioc',//*profile dropdown icon class
			dynaimcPaths: [
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'FC', 'FCS', 'FMT', 'FCSS', 'FMSM', 'FT', 'PM', 'PE', 'MA', 'MT'
		],
	},
	{ // Settings
		component: ProfileSetting,
		path: '/setting',
		menuCode: 'Settings',
		menu: {
			menuPath: '/setting',
			title: 'Settings',
			iconClass: 'settings-icon-menu',
			iconFile: '/media/icons/settings-inactive.png',
			profileIconClass: 'item-icon settings-ioc',//*profile dropdown icon class
			dynaimcPaths: [
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'FC', 'FCS', 'FMT', 'FCSS', 'FMSM', 'FT', 'PM', 'PE', 'MA', 'MT'
		],
	},

]