import React, {FC, useState, useEffect} from 'react';
import { Redirect, Route, Switch} from 'react-router-dom';
// import {WorkOrderStatusTabs} from './new/WorkOrderStatusTabs/WorkOrderStatusTabs'
// import {CorrectiveWorkOrders} from './new/CorrectiveWorkOrders/CorrectiveWorkOrders'
// import {ClearanceWorkOrders} from './new/ClearanceWorkOrders/ClearanceWorkOrders'
// import {PreventiveWorkOrders} from './new/PreventiveWorkOrders/PreventiveWorkOrders'
// import {SLAWorkOrders} from './new/SLAOrders/SLAWorkOrders'
import { useSelector } from 'react-redux';
import { getFcsWorkOrders } from '../../../utils/makeRequest';
import { IState } from '../../../utils/interface';
import { RootState } from '../../../constants';
import { getWorkOrdersList } from '../../../constants/axios/apis';
import { WorkOrderStatusTabs } from './WorkOrderStatusTabs/WorkOrderStatusTabs';
import { CorrectiveWorkorderWrapper } from './CorrectiveWorkorder/CorrectiveWorkorderWrapper';
import { ClearanceWorkorderWrapper } from './ClearanceWorkorder/ClearanceWorkorderWrapper';
import { PreventiveWorkorderWrapper } from './PreventiveWorkorder/PreventiveWorkorderWrapper';
import { SLAWorkOrderWrapper } from './SlaWorkorder/SLAWorkOrderWrapper';

export const FacilityWorkorderListWrapper: FC = () => {
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    // const state:any = useSelector(state => state);
    // const userToken = state?.auth?.accessToken;
    // if(userToken === "undefined"){
    //     // token undefined
    // }
    
    const [fcsWorkOrderList, setFcsWorkOrderList] = useState<IState['APIState']>({
        loading: true,
        response: [],
        error: ''
    });

    const [correctiveWorkOrder,setCorrectiveWorkOrder] = useState<any>([]);
    const [clearanceWorkOrder,setClearanceWorkOrder] = useState<any>([]);
    const [preventiveWorkOrder,setPreventiveWorkOrder] = useState<any>([]);
    const [slaWorkOrder,setSLAWorkOrder] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);

    const [stateMonitor, setStateMonitor] = useState(0);
    const [searchKey, setSearchKey] = useState('');
    const [allWorkOrdersList, setAllWorkOrdersList] = useState<any>([]);

    useEffect(()=>{
        setAllWorkOrdersList((Array.isArray(fcsWorkOrderList.response) && fcsWorkOrderList.response.length > 0) ? fcsWorkOrderList.response : []);
    },[fcsWorkOrderList.response]);

    useEffect(()=>{
        setCorrectiveWorkOrder([]);
        setClearanceWorkOrder([]);
        setPreventiveWorkOrder([]);
        setSLAWorkOrder([]);

        setFcsWorkOrderList({ loading: true, response: [], error: '' })
        const payload = currentBuilding ? "?building_id=" + currentBuilding : "?building_id=0"
        getWorkOrdersList(payload).then(({ data }) => {
            let sortedData = (data && Array.isArray(data)) ? data : [];
            sortedData = sortedData.sort((x, y) => {
                return new Date(x.updated_at) < new Date(y.updated_at) ? 1 : -1
            });
            setFcsWorkOrderList({
                loading: false,
                response: sortedData,
                error: ''
            })
        }).catch((e)=>{setFcsWorkOrderList({
            loading: false,
            response: [],
            error: 'Some error occured'
        })})
    }, [currentBuilding])

    useEffect(()=>{
        // if(Array.isArray(fcsWorkOrderList.response) && fcsWorkOrderList.response.length > 0){
            /* console.log('fcsWorkOrderList.response: ',fcsWorkOrderList.response);
            console.log('fcsWorkOrderList.response: ',typeof fcsWorkOrderList.response); */
            let filteredList
            if(searchKey !==''){
                const searchRegex = new RegExp(searchKey, 'i');
                filteredList = allWorkOrdersList.filter((item:any)=>item?.WorkorderNo?.match(searchRegex) || item?.objectDetails?.ticketNo?.match(searchRegex))
            }else{
                filteredList = allWorkOrdersList
            }

            const correctiveWorkOrderVal =  filteredList?.filter((workOrder: any, index: number)=>{
                return workOrder.issueCategory === "Corrective";
            });
            const clearanceWorkOrderVal =  filteredList?.filter((workOrder: any, index: number)=>{
                return workOrder.issueCategory === "Clearance";
            });
            const preventiveWorkOrderVal =  filteredList?.filter((workOrder: any, index: number)=>{
                return workOrder.issueCategory === "Preventive";
            });
            const slaWorkOrderVal =  filteredList?.filter((workOrder: any, index: number)=>{
                return workOrder.issueCategory === "SLA";
            });

            setCorrectiveWorkOrder(correctiveWorkOrderVal);
            setClearanceWorkOrder(clearanceWorkOrderVal);
            setPreventiveWorkOrder(preventiveWorkOrderVal);
            setSLAWorkOrder(slaWorkOrderVal);
            setLoading(false)
        // }
    },[allWorkOrdersList, searchKey] );

    // console.log(searchKey, 'searchKey');
    
    return (
        <>
            <Switch>
                <Route path='/facility-workorder/list/corrective'>
                    <WorkOrderStatusTabs searchKey={searchKey} setSearchKey={setSearchKey}/>
                    <CorrectiveWorkorderWrapper
                        fcsWorkOrderList={fcsWorkOrderList}
                        workOrder={correctiveWorkOrder}
                        loading={loading}
                    />
                </Route>
                <Route path='/facility-workorder/list/clearance'>
                    <WorkOrderStatusTabs searchKey={searchKey} setSearchKey={setSearchKey}/>
                    <ClearanceWorkorderWrapper
                        fcsWorkOrderList={fcsWorkOrderList}
                        workOrder={clearanceWorkOrder}
                        loading={loading}
                    />
                </Route>
                <Route path='/facility-workorder/list/preventive'>
                    <WorkOrderStatusTabs searchKey={searchKey} setSearchKey={setSearchKey}/>
                    <PreventiveWorkorderWrapper
                        fcsWorkOrderList={fcsWorkOrderList}
                        workOrder={preventiveWorkOrder}
                        loading={loading}
                    />
                </Route>
                <Route path='/facility-workorder/list/sla'>
                    <WorkOrderStatusTabs searchKey={searchKey} setSearchKey={setSearchKey}/>
                    <SLAWorkOrderWrapper
                        fcsWorkOrderList={fcsWorkOrderList}
                        workOrder={slaWorkOrder}
                        loading={loading}
                    />
                </Route>
                <Redirect to='/error/403' />
            </Switch>
        </>
    );
}