import React, {FC, useEffect, useRef, useState} from 'react'
import {CalenderCard} from '../CalenderCard/CalenderCard'
import {CalenderDetails} from '../CalenderDetails/CalenderDetails'
import {Link} from 'react-router-dom'

import { EventInput } from '@fullcalendar/react'


import { useSelector } from 'react-redux';
import { getFcsWorkOrders } from '../../../../utils/makeRequest';
import { IState } from '../../../../utils/interface';
import Loader from '../../../../components/Loader';
import { RootState } from '../../../../constants'
import { getWorkOrdersList } from '../../../../constants/axios/apis'
import moment from 'moment'

import { useTranslation } from 'react-i18next';

export function CalenderAll() {
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    // const state:any = useSelector(state => state);
    // const userToken = state?.auth?.accessToken;
    // const id_role = state?.auth?.user?.data?.id_role;

    const [selectedDate,setSelectedDate] = useState<any>(new Date());
    const [eventState, setEventState] = useState<EventInput[]>([]);
    const [fcsWorkOrderList, setFcsWorkOrderList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    const [selectedWorkOrders, setselectedWorkOrders] = useState<any>([]);

    // if(userToken === "undefined"){
    //     // token undefined
    // }
    

    // useEffect(()=>{
    //     getFcsWorkOrders(setFcsWorkOrderList,userToken);
    // },[]);

    useEffect(()=>{
        setFcsWorkOrderList({ loading: true, response: [], error: '' })
        const payload = currentBuilding ? "?building_id=" + currentBuilding : "?building_id=0"
        getWorkOrdersList(payload).then(({ data }) => {
            setFcsWorkOrderList({
                loading: false,
                response: (data && Array.isArray(data)) ? data : [],
                error: ''
            })
        }).catch((e)=>{setFcsWorkOrderList({
            loading: false,
            response: [],
            error: 'Some error occured'
        })})
    }, [currentBuilding])


    
    const sameDateCheck = (date1: any, date2: any) => {
		var d1 = new Date(date1);
		var d2 = new Date(date2);

		d1.setHours(0,0,0,0);
		d2.setHours(0,0,0,0);
		// return d1.getDate() === d2.getDate();
		return d1.getTime() === d2.getTime();
	}

	useEffect(()=>{
        // console.log(selectedDate, 'selectedDate');
        
        if(Array.isArray(fcsWorkOrderList.response) && fcsWorkOrderList.response.length > 0){
            let eventList = fcsWorkOrderList.response.map((event: any ,index: number)=>{
                return {
                    id: String(index+1),
                    /* title: 'Test', */
                    // title: event.subCategory ? : event.subCategory,
                    title: event.issueCategory === 'Clearance' ? event.typeText === "Move Out" ? "Move Out Inspection" : event.typeText : event.subCategory ,
                    date: event.date,
                }
            });
            setEventState(eventList);

            let todaysEventList = fcsWorkOrderList.response.filter((event: any ,index: number)=>{
                return sameDateCheck(event.date, selectedDate);
                /* return sameDateCheck(event.date,"2022-03-22"); */
            });
            setselectedWorkOrders(todaysEventList);
        }else{
            setEventState([])
            setselectedWorkOrders([])
        }
    },[fcsWorkOrderList, selectedDate]);

    // console.log(eventState, 'eventState');
    
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row flex-stack announcement-sec mb-6">
                <div className="col-sm-6 col-12">
                    <h4 className="card-title font-18 su-ca-add-attence text-black">{t("Calendar")}</h4>
                </div>
                {/* {id_role !== 8 && (
                    <div className="col-sm-6 col-12 text-end">
                        <Link to={'/fc-su-calendar/attendance/total'} className="btn btn-primary btn-sm su-ca-add-attence font-16">
                            View Attendance
                        </Link>
                    </div>
                )} */}
            </div>
            <div className="row">
                <div className="col-xl-7">
                    <CalenderCard eventState={eventState} setSelectedDate={setSelectedDate}/>
                </div>
                <div className="col-xl-5">
                    <CalenderDetails selectedWorkOrders={selectedWorkOrders} loading={fcsWorkOrderList.loading} selectedDate={selectedDate}/>
                </div>
            </div>
        </div>
    );
}