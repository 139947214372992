import React, {useState, useEffect} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import InactiveSlot from '../../../../../assets/images/parking-inactive.png'
import ActiveSlot from '../../../../../assets/images/parking-active.png'
import * as Yup from 'yup'
import {useFormik} from 'formik'
// import { getFmCommunityUser } from '../../../../../constants/axios/apis'
import {useSelector, useDispatch} from 'react-redux'
import {getUserBuildingCommunityList} from '../../../../../utils/makeRequest'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { NoDataFound1 } from '../../../../../constants/generics/customActios'


export function ParkingSlots({
  parkingSlots,
  selectSlot,
  handleBuilding,
  parkingSearchItem,
  setParkingSearchItem,
  parkingSearchNumber,
  setParkingSearchNumber,
  setSelectedBuilding,
  selectedBuilding,
}: any) {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const history = useHistory()
  const location = useLocation()
  const [filteredParkingSlots, setFilteredParkingSlots] = useState<any>()
  const [searchKey, setSearchKey] = useState<any>('')
  // const [selectedParkingBlock, setSelectedParkingBlock] = useState<any>()
  // const [parkingSlotNumbers, setParkingSlotNumbers] = useState<any>()
  const [selectedVisualBlock, setSelectedVisualBlock] = useState<any>()
  const [visualParkingSlots, setVisualParkingSlots] = useState<any>([])
  // const [buildingListId, setBuildingListId] = useState([])
  const [buildingList, setBuildingList] = useState<any>([])
  // const [pageChange, setPageChange] = useState<any>('')

  const [isLoading, setIsLoading] = useState<any>({
    building: true,
    property: true,
  })

  const state: any = useSelector((state) => state)
  const userId = state?.auth?.user?.data?.id
  const initialValues = {
    parking_slot: '',
    parking_number: '',
    buildingLocation: '',
  }

  const bookParkingSchema = Yup.object().shape({
    parking_slot: Yup.string().required(t('Please select a Parking Slot!')),
    parking_number: Yup.string().required(t('Parking Number is required!')),
    buildingLocation: Yup.string().required(t('Select a building location!')),
  })

  useEffect(() => {
    // if (parkingSlots) {
    //   setSelectedVisualBlock(parkingSlots?.block_name?.[0])
    // }
    let bookedSlots = parkingSlots?.parking_slot?.filter((el:any)=>el.status === 1 )

    let filteredList
    if(searchKey !==''){
        const searchRegex = new RegExp(searchKey, 'i');
        filteredList = bookedSlots.filter((item:any)=>item?.tenantName?.match(searchRegex) || item?.objectDetails?.tenantNumber?.match(searchRegex))
    }else{
        filteredList = bookedSlots
    }

    setFilteredParkingSlots(filteredList)
  // }, [parkingSlots, parkingSearchItem, parkingSearchNumber])
  }, [parkingSlots, searchKey])

  useEffect(() => {
    setVisualParkingSlots(
      parkingSlots?.parking_slot?.filter((slot: any) => slot.blockId === selectedVisualBlock?.id)
    )
  }, [selectedVisualBlock, parkingSlots])

  useEffect(() => {
    if (parkingSlots?.block_name?.length) {
      setSelectedVisualBlock(parkingSlots?.block_name[0])
      formik.setFieldValue('parking_slot', parkingSlots?.block_name[0]?.id)
    }
  }, [parkingSlots])

  // useEffect(() => {
  //   setParkingSlotNumbers(
  //     parkingSlots?.parking_slot?.filter((slot: any) => slot.blockId === selectedParkingBlock?.id && slot.status === 0)
  //   )
  // }, [selectedParkingBlock])

  const handlParkingBlock = (e: any) => {
    setSelectedVisualBlock(
      parkingSlots?.block_name?.find((block: any) => block.id == e.target.value)
    )
    formik.handleChange(e)
  }

  const handlBuilding = (e: any) => {
    handleBuilding(e.target.value)
    setSelectedBuilding(e.target.value)
    formik.handleChange(e)
  }
  const handleBuildingSelect = (val: any) => {
    formik.setFieldValue('buildingLocation', val)
    formik.setFieldValue('property_id', '')
    formik.setFieldTouched('property_id', false)
  }
  useEffect(() => {
    // getFmCommunityUser('?user_id=' + userId).then(({ data }) => {
    //   setBuildingList(data);
    //   handleBuilding(data[0]?.building_id)
    //   formik.setFieldValue('buildingLocation', data[0]?.building_id)
    // })
    getUserBuildingCommunityList(userId).then(({buildingListData, communityListData}) => {
      setBuildingList(buildingListData)
      if(selectedBuilding != null) {
        handleBuilding(selectedBuilding)
      formik.setFieldValue('buildingLocation', selectedBuilding)
    } else {
        handleBuilding(buildingListData[0]?.id)
        formik.setFieldValue('buildingLocation', buildingListData[0]?.id)
      }
    })
  }, [location?.key])

  // console.log('se', selectedBuilding)

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: bookParkingSchema,
    onSubmit: (values) => {
      history.push('/parking/add-parking', {
        parking_number: values.parking_number,
        // parking_number: values.parking_number,
      })
    },
  })

  // useEffect(() => {

  //   formik.setFieldValue('parking_slot', parkingSlots?.parking_slot?.filter((slot: any) => slot.blockId === formik.values.buildingLocation))

  // }, [formik.values.buildingLocation])

  // console.log(formik.values, "parking_slots");
  // console.log(parkingSlots, 'parkingSlots')

  const parkingBlocks = parkingSlots?.block_name?.map((block: any, index:number) => (
    <option key={block.id + index} value={block.id}>
      {block.name}
    </option>
  ))

  // const buildingOptions = buildingList?.map((building: any) => (
  //   <option key={building.building_id} value={building.building_id}>
  //     {building.building_name}
  //   </option>
  // ))
  const buildingOptions = buildingList?.map((building: any) => (
    <option key={building.id} value={building.id}>
      {building.name}
    </option>
  ))

  // const parkingSlotsList = parkingSlotNumbers?.map((slot: any) => (
  //   <option key={slot.id} value={slot.slotNumber}>
  //     {slot.slotNumber}
  //   </option>
  // ))

  // const visualParkingBlocks = parkingSlots?.block_name?.map((block: any) => (
  //   <option key={block.id} value={block.id}>
  //     {block.name}
  //   </option>
  // ))

  const VisualParkingSlotsList = visualParkingSlots?.map((slot: any, selectedSlot: any) => (
    <div className='col text-center parking-slot-sec' key={slot.id}>
      {slot.status === 0 ? (
        <Link
          to={{
            pathname: '/parking/add-parking',
            state: {parking_number: slot, selectedSlot: selectedSlot},
          }}
        >
          <img src={InactiveSlot} className='img-fluid mb-4' alt='Slot Not Booked' />
          <h6 className='text-black fw-bold font-12 mb-4 ms-0'>{slot.slotNumber}</h6>
        </Link>
      ) : (
        <button
          data-bs-target='#parked-slot-popup-modal'
          data-bs-toggle='modal'
          className='btn border-0 text-center pt-0'
          type='button'
          onClick={() => selectSlot(slot)}
        >
          {/* // <> */}
          <img src={ActiveSlot} className='img-fluid mb-4' alt='Slot Booked' />
          <h6 className='text-black fw-bold font-12 mb-4 ms-0'>{slot.slotNumber}</h6>
          {/* </> */}
        </button>
        // <Link
        //   data-bs-toggle='modal'
        //   to={{pathname: '#parked-slot-popup-modal', state: {parking_number: slot}}}
        //   role='button'
        // >
        //   <img src={ActiveSlot} className='img-fluid mb-4' alt='Slot Booked' />
        //   <h6 className='text-black fw-bold font-12 mb-4 ms-0'>{slot.slotNumber}</h6>
        // </Link>
      )}
    </div>
  ))

  // console.log(VisualParkingSlotsList, 'VisualParkingSlotsList');
  

  const handlSearch = (e: any) => {
    const updatedSlots = parkingSlots?.parking_slot?.filter((slot: any) => {
      return slot.tenantName === e.target.value || slot.tenantNumber === e.target.value
    })
    setFilteredParkingSlots(e.target.value === '' ? parkingSlots?.parking_slot : updatedSlots)
  }

  const handleChange=(e:any)=>{
    setParkingSearchItem(e.target.value)
    setParkingSearchNumber(e.target.value)
  }
  const {Option} = Select
  
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-4'>
            <div className='card-body px-9 pt-9 pb-5'>
              <div className='row'>
                <div className='col-12'>
                  <div
                    className='modal fade'
                    id='parkingsearchPopupmodal'
                    aria-labelledby='exampleModalLabel'
                    aria-hidden='true'
                  >
                    <div className='modal-dialog modal-dialog-centered'>
                      <div className='modal-content'>
                        <div className='modal-header p-5'>
                          <div className='input-group w-100'>
                            <div className='form-outline w-100 d-flex'>
                              {/* <input
                                onChange={(e) => handlSearch(e)}
                                type='search'
                                id='form1'
                                className='form-control'
                                placeholder='Search...'
                              /> */}
                              <input
                                type='text'
                                placeholder={t('Search')}
                                className='form-control search-input'
                                // onChange={(e)=>handleChange(e)}
                                // onChange={(e) => setParkingSearchItem(e.target.value)}
                                onChange={(e) => setSearchKey(e.target.value)}
                              />
                            </div>
                          </div>
                          <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div className='modal-body px-7 pt-0'>
                          <div className='row'>
                            <div
                              style={{
                                overflowY: 'scroll',
                                height: 'auto',
                              }}
                              className='table-responsive fc-su-ser-tabel px-0'
                            >
                              <table className='table align-middle table-striped gy-4 gx-4 mb-4 fcs-assign-to-technician'>
                                <thead>
                                  <tr>
                                    <th>{t("Name")}</th>
                                    <th>{t("Contact")}</th>
                                    <th>{t("Slot")}</th>
                                    <th className='tab-action-select'>{t("Action")}</th>
                                  </tr>
                                </thead>
                                {filteredParkingSlots?.length === 0 ? (
                                  <tr>
                                    <td colSpan={4}>{t("No Data")}</td>
                                  </tr>
                                ) : null}
                                <tbody>
                                  {filteredParkingSlots?.map((selectedSlot: any, index: number) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {selectedSlot.tenantName ? selectedSlot.tenantName : '-'}
                                        </td>
                                        <td>
                                          {selectedSlot.tenantNumber
                                            ? selectedSlot.tenantNumber
                                            : '-'}
                                        </td>
                                        <td>
                                          {selectedSlot.slotNumber
                                            ? selectedSlot.slotNumber
                                            : '-'}
                                        </td>
                                        <td>
                                          <Link
                                            to={{
                                              pathname: '/parking/report-issues',
                                              state: {selectedSlot: selectedSlot},
                                            }}
                                            className='menu-link  text-black fs-12 w-100 ps-2 pe-0 py-2'
                                          >
                                            <button
                                              data-bs-dismiss='modal'
                                              className='view-option border-0 bg-transparent text-theme fw-bolder'
                                            >
                                              {t("Next")}
                                            </button>
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='accessCardRequestForm'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design'>
                    <div className='col-md-4 col-lg-4 col-sm-6 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t("Building Location")}
                          <span className='text-danger'> * </span>
                        </label>
                        {/* <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder='Select a Building'
                          optionFilterProp='children'
                          defaultValue={
                            formik.values.buildingLocation !== '' ? formik.values.buildingLocation : null
                          }
                          onChange={handleBuildingSelect}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {buildingList?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select> */}
                        <select
                          className='form-control form-select fw-bolder form-control-solid'
                          data-control='select2'
                          data-allow-clear='true'
                          name='buildingLocation'
                          onChange={(e) => handlBuilding(e)}
                          value={formik.values.buildingLocation}
                          onBlur={formik.handleBlur}                          
                        >
                          <option value='' disabled>
                            {t("Please Select")}
                          </option>
                          {buildingList ? buildingOptions : ''}
                        </select>
                        {formik.touched.buildingLocation && formik.errors.buildingLocation && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.buildingLocation}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-md-4 col-lg-4 col-sm-6 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t("Parking Block")} <span className='text-danger'> * </span>{' '}
                        </label>
                        <select
                          className='form-control form-select fw-bolder form-control-solid'
                          data-control='select2'
                          data-allow-clear='true'
                          name='parking_slot'
                          // onChange={(e) =>
                          //   setSelectedVisualBlock(
                          //     parkingSlots?.block_name?.find(
                          //       (block: any) => block.id == e.target.value
                          //     )
                          //   )
                          // }
                          onChange={(e) => handlParkingBlock(e)}
                          value={formik.values.parking_slot}
                          onBlur={formik.handleBlur}
                        >
                          <option value='' disabled>
                            {t("Please Select")}
                          </option>
                          {parkingSlots ? parkingBlocks : ''}
                        </select>
                        {formik.touched.parking_slot && formik.errors.parking_slot && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.parking_slot}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-6 col-12'>
                      <div className='mb-7'>
                        <div className='d-flex float-end mt-8'>
                          <button
                            type='button'
                            className='btn btn-primary fw-bold px-5 py-3'
                            data-bs-toggle='modal'
                            data-bs-target='#parkingsearchPopupmodal'
                            disabled={!parkingSlots?.parking_slot?.length}
                          >
                            <i className='fas fa-search'></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='row mt-5'>
                  <div className='col-md-12'>
                    <div className='text-end mb-10 justify-content-end'>
                      <span className='avail-parking me-10'>{t("Available")}</span>
                      <span className='booked-parking'>{t("Booked")}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 '>
                    <div className='row'>{visualParkingSlots?.length ? VisualParkingSlotsList : <NoDataFound1 />}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
