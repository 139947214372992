import React, {FC, useEffect, useRef, useState, Component} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
 import {KTSVG} from '../../../../../components/helpers'
 import {Field, ErrorMessage} from 'formik'
import UploadIcon from "../../../../../assets/images/upload-ioc.png";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next'

const Step2: FC = () => {
    const intl = useIntl()
  const location = useLocation()
  const [menu, setMenu] = useState('')
  const { t } = useTranslation();

  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  useEffect(() => {
    if (window.location.pathname != menu) {
      setMenu(window.location.pathname)
      console.log(window.location.pathname)
    }
  })

    const containerStyle = {
        width: '100%',
        height: '450px',
        borderRadius:'10px'
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };
  return (
      <>
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-md-12">
                    <div className="row form-add-design">
                        <div className="col-md-12 col-12">
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Locality")}
                                    <span className="text-danger"> * </span>
                                    </label>
                                    <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                        <option selected disabled>{t("Please Select")}</option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-12">
                            <LoadScript
                                googleMapsApiKey="https://www.google.com/maps/d/viewer?msa=0&hl=en&ie=UTF8&ll=12.22867028649322%2C76.65628877252196&spn=0.016651%2C0.038624&t=h&z=15&mid=132uDjpCB5qV9r-ZiNy7SzSnZnpk"
                            >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={10}
                                >
                                    { /* Child components, such as markers, info windows, etc. */ }
                                    <></>
                            </GoogleMap>
                        </LoadScript>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
  )
}

export {Step2}
