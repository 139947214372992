import React, { useState, useEffect } from 'react';
import Loader from '../../../../components/Loader';
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const RecentWorkOrdersTable: React.FC<any> = ({ fcsWorkOrderList }) => {
    const { t } = useTranslation();
    const { loading, response: workOrders, error } = fcsWorkOrderList;

    const columns = [
        {
            field: 'no',
            headerName: t('No'),
            sortable: false,
            disableColumnMenu: true,
            width: 100,
        },
        {
            field: 'WorkOrderNo',
            headerName: t('Work order No'),
            minWidth: 200,
            flex: 1
        },
        // {
        //     field: 'tenantName',
        //     headerName: t('Tenant Name'),
        //     minWidth: 200,
        //     flex: 1,
        //     renderCell: (val:any) => {
        //         return( val.value && val.value !== '' ?
        //             <>{val.value}</> : <i>No Tenant</i>
        //     )}
        // },
        {
            field: 'issueType',
            headerName: t('Issue Type'),
            minWidth: 200,
            flex: 1
        },
        {
            field: 'unit',
            headerName: t('Unit'),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'assignedTo',
            headerName: t('Assigned to'),
            minWidth: 150,
            flex: 1,
            renderCell: (val:any) => {
                return( val.value && val.value !== '' ?
                    <>{val.value}</> : <i>{t("Not assigned yet!")}</i>
            )}
        },
        {
            field: 'workOrderState',
            headerName: t('Action'),
            className: '',
            disableColumnMenu: true,
            minWidth: 80,
            flex: 1,
            renderCell: (val:any) => {
                // const viewLink = val.value.issueCategory === "Clearance" ?
                //     ( val.value.status === 'Completed' ? '/fc-workorder/moveoutinspection/completed'
                //     : val.value.status === 'Confirmed' ? '/fc-workorder/moveoutinspection/confirmed'
                //     : val.value.status === 'Inprogress' ? '/workorder/details'
                //     : val.value.status === 'Open' ? '/workorder/details'
                //     : '/'
                //     ): '/workorder/details'
                const viewLink = '/facility-workorder/view'
                return(
                    <Link to={{'pathname': viewLink, state: {'workOrder': val.value}}}>
                        <span className="view-option text-theme fw-bolder"> {t("View")} </span>
                    </Link>
            )}
            ,
        }
    ];
    const [workOrderRow, setWorkOrderRow] = useState<any>([]);
    useEffect(() => {
        if (Array.isArray(workOrders) && workOrders.length > 0) {
            const workOrderRow = workOrders.map((workOrder: any, index: number) => {
                return {
                    no: index + 1,
                    WorkOrderNo: workOrder.WorkorderNo,
                    // tenantName: workOrder.tenantName,
                    issueType: workOrder.issueCategory === 'Clearance' ? (workOrder.typeText === 'Move Out' ? 'Move Out Inspection' : workOrder.typeText): workOrder.issue_type,
                    unit: workOrder.property_code,
                    // assignedTo: workOrder.assignedTo && workOrder.assignedTo != '' ? workOrder.assignedTo: '-',
                    assignedTo: workOrder.assignedTo,
                    workOrderState :workOrder
                }
            });
            setWorkOrderRow(workOrderRow);
        }else{
            setWorkOrderRow([])
        }
    }, [workOrders]);

    return (
        <div className="card mb-3">
            <div className="card-body px-8 py-2">
                <div className="rec-payment-act-sec pb-5">
                    <div className="row">
                        <div className="col-12 pt-5">
                            <div className="rec-payment-act-sec">
                                <div className="row">
                                    <div className="col-12">
                                    {!error && (
                                    <div className="data_table">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className='table-responsive'>
                                                    <div style={{ height: 650 }}>
                                                        <DataGrid
                                                            rows={workOrderRow}
                                                            getRowId={(row) => row.no}
                                                            columns={columns}
                                                            pageSize={10}
                                                            rowsPerPageOptions={[10]}
                                                            disableSelectionOnClick
                                                            loading={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    {error && (
                                        <div className="alert alert-danger w-100 mt-2" role="alert">
                                            {error}
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}