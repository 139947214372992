import React, { useState, useEffect } from 'react'

export function PropertyDetails() {
    return (      
        <>
        <div id="property-details" className="property-details mt-2">
            <div className="row mb-4">
                <div className="col-md-9 mb-3 col-12">
                    <div className="card radius-15 h-100">
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-12">
                                <h5 className="font-16 mb-4 font-weight-semibold text-black"> About property </h5>
                                <p className="font-13">
                                    <label className="mb-0 d-inline-block float-left">Portals <span className="float-right">&nbsp;:&nbsp;</span></label>
                                    <span className="d-inline-block">Propertyfinder, Rightmove, Own Website, Luxury Estate, PropSpace MLS</span>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-12 mb-3">
                    <div className="card radius-10 h-100 mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 text-center mb-2">
                                    <img src="https://pm-crm.realcube.estate/img/avatar-con.jpeg" className="img-fluid border rounded p-1 mb-2" width="50%" height="50%"/>
                                    <h6 className="text-gray-500 mt-2 mb-0"> Agent not assigned </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-3 col-12">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="bg-white border-0 p-0">
                                <h5 className="font-16 mb-0 font-weight-semibold text-black"> Property Details </h5>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-6 col-12">

                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Property ID </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> 1 </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Trakshee permit </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> --- </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Completion Status </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12">
                                        ---                                                                    </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Category </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> Apartment </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Furnishing Status </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> Studio </span>
                                    </div>
                                    </div>

                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Lease duration </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12">
                                        ---                                                                    </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Listing category </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12">
                                        Sale                                                                                                                                            </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Community Name </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> WIlton Park Residencies </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Sub Community Name </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> Wilton Park Residencies 2 </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Available for virtual visit  </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12">
                                        ---                                                                    </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Available for on-site visit  </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12">
                                        <span className="font-weight-semibold text-black font-12">
                                        Yes                                                                                                                                            </span>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Floor Plan </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> 3 </span>
                                    </div>
                                    </div>

                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Price </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> AED 3,694,000 </span>
                                    </div>
                                    </div>

                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Listing Date </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> 17/11/2020 </span>
                                    </div>
                                    </div>
                                
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Deposit </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> AED 32,767 </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Commission </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> 5 </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Installments </label>
                                    <div className="col-sm-6">
                                        <span className="font-weight-semibold text-black font-12"> 1 Payment </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> City </label>
                                    <div className="col-sm-6"><span className="font-weight-semibold text-black font-12"> --- </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Country </label>
                                    <div className="col-sm-6"><span className="font-weight-semibold text-black font-12"> --- </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Location </label>
                                    <div className="col-sm-6"><span className="font-weight-semibold text-black font-12"> Mohammad Bin Rashid City </span>
                                    </div>
                                    </div>
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                    <label className="font-14 font-weight-semibold mb-0 col-sm-6"> Zipcode </label>
                                    <div className="col-sm-6"><span className="font-weight-semibold text-black font-12"> --- </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <h5 className="font-16 mb-0 font-weight-semibold text-black"> Amenities </h5>
                                    <hr/>
                                </div>
                                <div id="amenities" className="card-body p-3">
                                    <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" checked={true} className="form-check-input comm_amenities" name="comm_amenities[]" value="115" id="cammenity115"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity115">Guest parking</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="118" id="cammenity118"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity118">Gym</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" checked={true} className="form-check-input comm_amenities" name="comm_amenities[]" value="123" id="cammenity123"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity123">Power Backup</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="125" id="cammenity125"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity125">Air Conditioner</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="127" id="cammenity127"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity127">Hospital</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="129" id="cammenity129"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity129">Electrical Point (Car Charging)</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="137" id="cammenity137"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity137">Badminton Court</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" checked={true} className="form-check-input comm_amenities" name="comm_amenities[]" value="138" id="cammenity138"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity138">Swimming Pool</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="141" id="cammenity141"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity141">Library</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" checked={true} className="form-check-input comm_amenities" name="comm_amenities[]" value="143" id="cammenity143"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity143">Golf Course</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="144" id="cammenity144"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity144">Bicycle Track</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="145" id="cammenity145"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity145">Gated Community</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="146" id="cammenity146"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity146">Jacuzzi</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="148" id="cammenity148"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity148">Barbeque</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" checked={true} className="form-check-input comm_amenities" name="comm_amenities[]" value="149" id="cammenity149"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity149">Bar</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="189" id="cammenity189"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity189">Sustainable Community</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="201" id="cammenity201"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity201">Built in wardrobes</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="204" id="cammenity204"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity204">Covered parking</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="205" id="cammenity205"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity205">Maid's room</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="207" id="cammenity207"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity207">Private garage</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="208" id="cammenity208"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity208">Private garden</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="212" id="cammenity212"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity212">24 hours Maintenance</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="213" id="cammenity213"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity213">Bank/ATM Facility</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="214" id="cammenity214"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity214">Basketball Court</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="217" id="cammenity217"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity217">Business Center</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="219" id="cammenity219"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity219">Children's play area</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="220" id="cammenity220"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity220">Clubhouse</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="222" id="cammenity222"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity222">Concierge service</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="223" id="cammenity223"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity223">Cycling tracks</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="225" id="cammenity225"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity225">Laundry Service</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="232" id="cammenity232"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity232">Tennis courts</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" checked={true} className="form-check-input comm_amenities" name="comm_amenities[]" value="271" id="cammenity271"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity271">Squash courts</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="314" id="cammenity314"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity314">Basement parking</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="370" id="cammenity370"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity370">Private swimming pool</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="382" id="cammenity382"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity382">Walking Trails</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="387" id="cammenity387"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity387">Fitness Center</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="403" id="cammenity403"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity403">Balcony</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="405" id="cammenity405"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity405">Driver's Room</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="477" id="cammenity477"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity477">Carpets</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1746" id="cammenity1746"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity1746">Dining in building</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1808" id="cammenity1808"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity1808">Private gym</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1902" id="cammenity1902"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity1902">Conference room</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1923" id="cammenity1923"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity1923">Prayer room</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2538" id="cammenity2538"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity2538">Walk-in Closet</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2539" id="cammenity2539"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity2539">Built in Kitchen Appliances</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2545" id="cammenity2545"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity2545">Pets Allowed</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2550" id="cammenity2550"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity2550">Landscaped Garden</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2551" id="cammenity2551"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity2551">Laundry/washing room</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="custom-control custom-checkbox mb-5 custom-control-inline mr-0">
                                            <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2552" id="cammenity2552"/>
                                            <label className="custom-control-label font-14 ms-2" htmlFor="cammenity2552">Storage room</label>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

