import NoDataFoundImage from '../../../../assets/images/no-data-found.svg';

export const VisitedRequest = () => {
    return <>
        <div className="col-12">
            <div className="card border-0 shadow-none rounded-0 no-data-found-cd">
                <div className="card-body border-0">
                    <div className="row justify-content-center">
                        {/* <div className="col-12 text-center">
                            <img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" />
                        </div> */}
                        <div className="col-12 text-center">
                            <span className='d-block text-center font-13 link-theme'> No Visit Request found. </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;

}