import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function AddLeadRules() {
    return (
        <>
            <div className="modal fade" aria-hidden='true' id="addLeadPopup">
                <div className="modal-dialog modal-md modal-dialog-centered radius-15">
                    <div className="modal-content radius-15 overflow-hidden border-0">
                        <div className="modal-header badge-dark">
                            <h5 className="modal-title font-16 font-weight-semibold text-white">Add Rule</h5>
                            <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="row form-add-design">
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> Rule Name </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Rule Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">Type</label>
                                            {/* <textarea className="form-control fw-bolder" placeholder="Description" rows={3} /> */}
                                            <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                                <option selected> Please Select </option>
                                                <option>User</option>
                                                <option>Role</option>
                                                <option>Group</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> Users </label>
                                            <textarea className="form-control form-height font-13 rows={3} form-control-solid active" disabled/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> Source </label>
                                            <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                                <option selected> Please Select </option>
                                                <option >Buyer</option>
                                                <option>Lease</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> Lead Type </label>
                                            <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                                <option selected> Please Select </option>
                                                <option >Facebook</option>
                                                <option>Google</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> Priority </label>
                                            <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                                <option selected> Please Select </option>
                                                <option >Urgent</option>
                                                <option>High</option>
                                                <option>Low</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase font-13">Nationality</label>
                                        <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                            <option selected> Please Select </option>
                                            <option >United Arab Emirates</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase font-13">Language</label>
                                        <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                            <option selected> Please Select </option>
                                            <option >English</option>
                                            <option >Arabic</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="col-12 text-end">
                                <button data-bs-dismiss="modal" className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                    Close
                                </button>
                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
