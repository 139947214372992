import React, {useState, useEffect} from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import QuickLinkOne from '../../../../assets/images/work-order-quick-link.svg'
import QuickLinkTwo from '../../../../assets/images/activities-quick-link.svg'
import QuickLinkThree from '../../../../assets/images/maintenance-quick-link.svg'
import QuickLinkFour from '../../../../assets/images/aproval-quick-link.svg'
import QuickLinkFive from '../../../../assets/images/directory-quick-link.svg'
import QuickLinkSix from '../../../../assets/images/attendance.png'
import QuickLinkSeven from '../../../../assets/images/access-card-quick-link.svg'
import QuickLinkEight from '../../../../assets/images/parking-quick-link.svg'
import QuickLinkNine from '../../../../assets/images/move-in-out-approval-quick-link.svg'
import QuickLinkTen from '../../../../assets/images/schedhule-quick-link.svg'
import QuickLink1 from '../../../../assets/images/incident-quick-link.svg'
import QuickLinksReport from '../../../../assets/images/reports-quick-link.png'
import QuickLinksMyTeam from '../../../../assets/images/my-team-quick-link.svg'
import QuickLinksMarketPlace from '../../../../assets/images/marketplace-quick-link.svg'
import QuickLinksPTW from '../../../../assets/images/permit-to-work-quick-link.png'
import CalanderIcon from "../../../../assets/images/calendar-quick-link.svg";

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../../../constants/generics/userCheck'

import { useTranslation } from 'react-i18next';


export function QuickLinks() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }
  // let userEmail = "";
  // let userDetail = localStorage.getItem("userDetail");
  // if(userDetail){
  //     userEmail = JSON.parse(userDetail).email;
  // };
  const state: any = useSelector((state) => state)
  const user_role = getUserRole(state?.auth?.user?.data)

  const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <h4 className='card-title'> {t("Quick Links")} </h4>
                </div>
              </div>
              {user_role === 'FMT' ? (
                <div className='quick-link-sec'>
                  <div>
                    <div className='row'>
                      <div className='col-12 p-0'>
                        <Slider {...settings}>
                          <div>
                            <Link
                              to={'/facility-workorder/list/corrective/open'}
                              className='text-decoration-none'
                            >
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkOne}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'> {t("Work Orders")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/activities/list/open'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img src={QuickLinkTwo} alt='Refunds' className='img-fluid' />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Activities")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link
                              to={'/facility-maintenance/list/open'}
                              className='text-decoration-none'
                            >
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkThree}
                                        alt='MoveOut Requests'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Maintenance")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link
                              to={'/fmteam-approvals/on-hold/upcoming'}
                              // to={'/fmteam-approvals/permit-work/upcoming'}
                              className='text-decoration-none'
                            >
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkFour}
                                        alt='Receipts'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Approvals")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/directory/residents'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkFive}
                                        alt='Lease Details'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Directory")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/market-place/nearby'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinksMarketPlace}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Market Place")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/fm-myteam'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinksMyTeam}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("My Team")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/fc-su-calendar/all'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={CalanderIcon}
                                        alt='Calendar'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Calendar")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          {/* <div>
                            <Link to={'/fm-report'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinksReport}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Reports")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div> */}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='quick-link-sec'>
                  <div>
                    <div className='row'>
                      <div className='col-12 p-0'>
                        <Slider {...settings}>
                          <div>
                            <Link
                              to={'/approvals/upcoming/movein'}
                              className='text-decoration-none'
                            >
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkNine}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0 font-12'>
                                        {t("Move in & Move out Approvals")}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/access-cards/open'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkSeven}
                                        alt='Refunds'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Access Cards")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/parking/all'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkEight}
                                        alt='Receipts'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Parking")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/activities/list/open'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img src={QuickLinkTwo} alt='Refunds' className='img-fluid' />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Activities")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/directory/residents'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkFive}
                                        alt='Lease Details'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Directory")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          {/* <div>
                            <Link
                              to={'/approvals/upcoming/movein'}
                              className='text-decoration-none'
                            >
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkNine}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0 font-12'>
                                        {t("Move in & Move out Approvals")}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div> */}
                          <div>
                            <Link to={'/scheduled-visit/all'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinkTen}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'> {t("Scheduled Visits")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          {/* <div>
                            <Link
                              to={'/fmteam-approvals/permit-work/upcoming'}
                              className='text-decoration-none'
                            >
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinksPTW}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Permit To Work")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div> */}
                          <div>
                            <Link to={'/incidents/open-requests'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img src={QuickLink1} alt='Services' className='img-fluid' />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'> {t("Incidents")} </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/market-place/nearby'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinksMarketPlace}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Market Place")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div>
                            <Link to={'/fmsm-my-team/team'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinksMyTeam}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("My Team")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          {/* <div>
                            <Link to={'/fc-reports'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={QuickLinksReport}
                                        alt='Services'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Reports")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div> */}
                          <div>
                            <Link to={'/fc-ss-calendar/all'} className='text-decoration-none'>
                              <div className='card mt-4'>
                                <div className='card-body py-7 px-7'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-4'>
                                      <img
                                        src={CalanderIcon}
                                        alt='Calendar'
                                        className='img-fluid'
                                      />
                                    </div>
                                    <div className='col-8'>
                                      <h6 className='card-title mb-0'>{t("Calendar")}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
