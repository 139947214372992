import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PageLink, PageTitle } from '../../layout/core'
import { Private } from './components/Private'
import { Group } from './components/Group'
import { Drawer } from './components/Drawer'
import { ChatMainPage } from './components/ChatMainPage'
import { ChatAll } from './components/ChatAll/ChatAll'
import { ChatUnread } from './components/ChatUnread/ChatUnread'

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Chat',
    path: '/chat',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ChatPage: React.FC = () => {
  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/chat'>
              <ChatMainPage />
            </Route>
            <Route path='/chat/group-chat'>
              <Group />
            </Route>
            <Route path='/chat/drawer-chat'>
              <Drawer />
            </Route>
            <Redirect from='/chat' exact={true} to='/chat' />
            <Redirect to='/chat' />
          </Switch>
        </div>
      </div>
    </>
  )
}

export default ChatPage
