import React, { useState, useEffect } from 'react';
import ActionDotsIoc from "../../../../assets/images/Action_dots.png";
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';

export function Documents() {
    const { t } = useTranslation();
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    contractApprovalData = contractApprovalData[0]

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="rec-payment-act-sec">
                                        <div className="row g-0">
                                            {contractApprovalData?.attachments_json?.length > 0 ? <div className="col-12">
                                                <div className='table-responsive col-12'>
                                                    <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th className=''> {t("No")} </th>
                                                                <th className=''> {t("Created on")} </th>
                                                                <th className=''> {t("Type of Document")} </th>
                                                                <th className=''> {t("Upload By")} </th>
                                                                <th className=''> {t("Action")} </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    01
                                                                </td>
                                                                <td>
                                                                    Realcube45467
                                                                </td>
                                                                <td>
                                                                    {t("Insufficient Notice")}
                                                                </td>
                                                                <td>
                                                                    19
                                                                </td>
                                                                <td>
                                                                    <span className="ms-lg-2" id='kt_header_user_menu_toggle'>
                                                                        <span className="cursor-pointer" data-kt-menu-trigger='click'
                                                                            data-kt-menu-attach='parent'
                                                                            data-kt-menu-placement='bottom-end'
                                                                            data-kt-menu-flip='bottom'>
                                                                            <img src={ActionDotsIoc} className="img-fluid" />
                                                                        </span>

                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    01
                                                                </td>
                                                                <td>
                                                                    Realcube45467
                                                                </td>
                                                                <td>
                                                                    {t("Tawtheeq Contract")}
                                                                </td>
                                                                <td>
                                                                    19
                                                                </td>
                                                                <td>
                                                                    <span className="ms-lg-2" id='kt_header_user_menu_toggle'>
                                                                        <span className="cursor-pointer" data-kt-menu-trigger='click'
                                                                            data-kt-menu-attach='parent'
                                                                            data-kt-menu-placement='bottom-end'
                                                                            data-kt-menu-flip='bottom'>
                                                                            <img src={ActionDotsIoc} className="img-fluid" />
                                                                        </span>

                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    01
                                                                </td>
                                                                <td>
                                                                    Realcube45467
                                                                </td>
                                                                <td>
                                                                    {t("Move In Inspection Report")}
                                                                </td>
                                                                <td>
                                                                    19
                                                                </td>
                                                                <td>
                                                                    <span className="ms-lg-2" id='kt_header_user_menu_toggle'>
                                                                        <span className="cursor-pointer" data-kt-menu-trigger='click'
                                                                            data-kt-menu-attach='parent'
                                                                            data-kt-menu-placement='bottom-end'
                                                                            data-kt-menu-flip='bottom'>
                                                                            <img src={ActionDotsIoc} className="img-fluid" />
                                                                        </span>

                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    01
                                                                </td>
                                                                <td>
                                                                    Realcube45467
                                                                </td>
                                                                <td>
                                                                    {t("Move Out Inspection")}
                                                                </td>
                                                                <td>
                                                                    19
                                                                </td>
                                                                <td>
                                                                    <span className="ms-lg-2" id='kt_header_user_menu_toggle'>
                                                                        <span className="cursor-pointer" data-kt-menu-trigger='click'
                                                                            data-kt-menu-attach='parent'
                                                                            data-kt-menu-placement='bottom-end'
                                                                            data-kt-menu-flip='bottom'>
                                                                            <img src={ActionDotsIoc} className="img-fluid" />
                                                                        </span>

                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : 
                                            <div className="col-12">
                                                <div className="card  payment-card-sec">
                                                    <div className="card-body px-8 py-6">
                                                        {t("No Data Found")}
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}