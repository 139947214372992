import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'

export function FinanceServiceRefundsTabs({ searchItem, setSearchItem, activeButton, setActiveButton }: any) {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row flex-stack announcement-sec mb-6">
                        <div className="col-md-12">
                            <h4 className="card-title font-18 text-black"> {t("Refunds")} </h4>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                        (location.pathname === '/finance-services-request-refunds/list/open-requests-list' && 'active')}
                                                    to='/finance-services-request-refunds/list/open-requests-list'>
                                                    {t("Open Requests")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                        (location.pathname === '/finance-services-request-refunds/list/closed-requests-list' && 'active')}
                                                    to='/finance-services-request-refunds/list/closed-requests-list'>
                                                    {t("Closed Requests")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                                    <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                                        <div className='form-group has-search'>
                                            <span className='fa fa-search pt-2 form-control-feedback'></span>
                                            <input
                                                type='text'
                                                className='form-control ps-10'
                                                onChange={(e) => setSearchItem(e.target.value)}
                                                placeholder={t('Search')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}