import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/

export const GET_PROFILE = `${API_URL}/profile`
export const GET_FAQ = `${API_URL}/faq`

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
export const actionTypes = {
  Profile: '[Profile] Action',
  Faqs: '[Faqs] Action',

  ProfileLoaded: '[Load Profile] Load data',
  FaqLoaded: '[Load FaqLoaded] Load data',
}

// ** Define initialProfileState **
const initialProfileState: IProfileState = {
  profile: undefined,
  faq: undefined,
}
export interface IProfileState {
  profile?: ''
  faq?: ''
}
export const reducer = persistReducer(
  {storage, key: 'Profile', blacklist: ['profile']},
  (state: IProfileState = initialProfileState, action: ActionWithPayload<IProfileState>) => {
    switch (action.type) {
      case actionTypes.ProfileLoaded: {
        const profile = action.payload?.profile
        return {...state, profile}
      }
      case actionTypes.FaqLoaded: {
        const faq = action.payload?.faq
        return {...state, faq}
      }
      default:
        return state
    }
  }
)
export function myProfile() {
  return axios.get(GET_PROFILE)
}
export function myFaq() {
  return axios.get(GET_FAQ)
}

export const actions = {
  // ** Get My Profile **
  profile: () => ({type: actionTypes.Profile}),
  fulfillMyProfile: (profile: '') => ({type: actionTypes.ProfileLoaded, payload: {profile}}),
  // ** Get My FAQS **
  faq: () => ({type: actionTypes.Faqs}),
  fulfillMyFaq: (faq: '') => ({type: actionTypes.FaqLoaded, payload: {faq}}),
}

export function* Profilesaga() {
  // ** Get My Profile Dispatch**
  yield takeLatest(actionTypes.Profile, function* userRequested() {
    const {data: profile} = yield myProfile()
    yield put(actions.fulfillMyProfile(profile))
  })
  yield takeLatest(actionTypes.Faqs, function* userRequested() {
    const {data: faq} = yield myFaq()
    yield put(actions.fulfillMyFaq(faq))
  })
}
