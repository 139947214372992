import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { getUserRole } from '../../../../../constants/generics/userCheck';

export function PromotionTabSecondary() {
    const { t } = useTranslation();
    // const location = useLocation();
    // let s = location.pathname
    // let link = s.substring(s.lastIndexOf("/list/") + 6, s.lastIndexOf("/"))
    const state: any = useSelector(state => state)
    const user_role = getUserRole(state?.auth?.user?.data)

    const location = useLocation();
    let s = location.pathname
    let secondaryLink = s.substring(s.lastIndexOf("/") + 1, s.length)
    let primaryLink = s.substring(s.lastIndexOf("/list/") + 6, s.lastIndexOf("/"))

    const tabsList = [
        {
            title: t('Events'),
            value: 'events'
        },
        {
            title: t('Offers'),
            value: 'offers'
        },
        {
            title: t('Vouchers'),
            value: 'vouchers'
        },
        {
            title: t('Redeemed'),
            value: 'redeemed'
        }
        
    ]
    
    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className='d-flex overflow-hidden'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        {tabsList.map((item: any, index: number) => {
                            return (
                                <li className='nav-item' key={index}>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-14 ${secondaryLink === item.value && 'active'}`}
                                        to={`/promotions/list/${primaryLink}/${item.value}`}>
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}