import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {NOCTabs} from './NOCTabs/NOCTabs'
import {Upcoming} from './Upcoming/Upcoming'
import {Closed} from './Closed/Closed'

export function NOC() {

    const location = useLocation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3 movein-moveout">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <Switch>
                                        <Route path='/fmteam-approvals/noc/upcoming'>
                                            <NOCTabs />
                                            <Upcoming />
                                        </Route>
                                        <Route path='/fmteam-approvals/noc/closed'>
                                            <NOCTabs />
                                            <Closed />
                                        </Route>
                                        <Redirect to='/error/403' />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}