import React, {FC, useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {PageTitle} from '../../components/layout/core'
import { RootState } from '../../constants'
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { AddParking } from './components/AddParking/AddParking'
import { ParkingStatusWrapper } from './components/ParkingStatusWrapper/ParkingStatusWrapper'
import { ReportIssues } from './components/ReportIssues/ReportIssues'

const ParkingPage: FC = () => {
  const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

  const [parkingSuccessMsg, setParkingSuccessMsg] = useState<any>('');
  const [selectedBuilding, setSelectedBuilding] = useState<any>(currentBuilding)
  const [selectedBlock, setSelectedBlock] = useState<any>('')

  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/parking/all'>
              <ParkingStatusWrapper
                selectedBuilding={selectedBuilding}
                setSelectedBuilding={setSelectedBuilding}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
               />
            </Route>
            <Route path='/parking/add-parking'>
              <AddParking setParkingSuccessMsg={setParkingSuccessMsg}/>
            </Route>
            <Route path='/parking/report-issues'>
              <ReportIssues setParkingSuccessMsg={setParkingSuccessMsg}/>
            </Route>
            <Redirect to='/error/403' />
          </Switch>
        </div>
        <CustomRequestSuccessfulPopup
          customID='-parking'
          successMessage={parkingSuccessMsg}
        />
      </div>
    </>
  )
}

const ParkingWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}> Parking </PageTitle>
      <ParkingPage />
    </>
  )
}

export {ParkingWrapper}
