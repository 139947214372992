import React, { useState, useEffect, useRef } from 'react';
import CheckImg from "../../../../../../assets/images/check-img.png";

export function NewChequeDetails() {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const [visible, setVisible] = useState(true);
    function removeImg() {
        setVisible(false);
    }
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };
    const [{alt, src}, setImg] = useState({
        src: CheckImg,
        alt: 'Upload an Image'
    });

    const handleImg = (e:any) => {
        if(e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name
            });
        }
    }
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="row form-add-design">
                                    <div className="col-md-4">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">Cheque Number</label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">876567657</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">Account Number</label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">5469859874548</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">Account Holder Name</label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">RealCube L.L.C</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">Bank Name</label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">Abu Dhabi Commercial Bank </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">Amount</label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">AED 219999</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">Cheque Date</label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">01/Jan/2020</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10 upload-input">
                                            <label className="form-label fw-bolder d-block">Photo</label>
                                            <div className="position-relative">
                                                <div className="upload-image">
                                                    <img src={src} alt={alt} className="form-img__img-preview"/>
                                                    <div className="close-icon">&times;</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-end">
                                        <button className="btn btn-outline-primary fw-bold px-10 py-3 me-5">View</button>
                                        <button type="submit" className="btn btn-primary fw-bold px-10 py-3">Download</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}