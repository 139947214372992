import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  saveApprovalsData,
  saveApprovalsDataFMSM,
} from '../../../../../../../../constants/axios/apis'
import {RequestFailedPopup} from '../../../../../../../SuccessfulPopup/RequestFailedPopup'
import {getUserRole} from '../../../../../../../../constants/generics/userCheck'
import {useSelector} from 'react-redux'
import {CustomRequestSuccessfulPopup} from '../../../../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import {NoDataFound} from '../../../../../../../../constants/generics/noDataFound'
import { useTranslation } from 'react-i18next'

const saveFCSSApprovalsDataRequest = Yup.object().shape({
  // card_number: Yup.array().min(2, 'Please fill out all card numbers'),
  signature: Yup.string().required('Please add your signature'),
})

const saveFMSMApprovalsDataRequest = Yup.object().shape({})

export function OtherAccessCard({
  count,
  cardDetails,
  allotedAccessID,
  cardSignature,
  cardSignature2,
  setReloadData,
  type = 'movein',
  approval
}: any) {
  let params: any = {}
  params = useLocation()
  // const {approval} = params.state

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)
  const [submitError, setSubmitError] = useState('')

  const defaultCount = count
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [cardInputs, setCardInputs] = useState<any>([])
  const [inputCount, setInputCount] = useState<any>(defaultCount)
  const allotedAccessId = allotedAccessID
  const buttonEl: any = useRef(null)
  const { t } = useTranslation();

  const backArrowRedirection =
    approval?.subReference === 6 ? '/approvals/upcoming/moveout' : '/approvals/upcoming/movein'

  // const cardDetails = cardDetails
  const validationSchema =
    user_role === 'FCSS' ? saveFCSSApprovalsDataRequest : saveFMSMApprovalsDataRequest

  const initialValues = {
    card_number:
      user_role !== 'FCSS' && (!cardSignature || cardSignature === '') ? cardDetails : [],
    signature: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setSubmitError('')
      setTimeout(() => {
        if (user_role === 'FCSS') {
          saveApprovalsData(
            approval.propertyID,
            approval.user_id,
            allotedAccessId,
            // values.card_number,
            values.signature,
            approval.subReference === 5 ? 1 : approval.subReference === 6 ? 2 : '', //ref-5= movein(1), ref-6= moveout(2)
            approval.id
          )
            .then((response: any) => {
              setLoading(false)
              if (response.errorCode === 0) {
                clearForm()
                // successRef.current.click()
                setReloadData(true)
                var ev = new Event('click', {bubbles: true})
                buttonEl.current.dispatchEvent(ev)
              } else {
                setSubmitError('Some error occured. Please try again !')
                // setMessage(response.errorDescription)
                // failRef.current.click()
              }
            })
            .catch((e) => {
              setLoading(false)
              setSubmitting(false)
              setStatus('Invalid Data Provided')
            })
        } else {
          // for FMSM
          saveApprovalsDataFMSM(
            approval.propertyID,
            approval.user_id,
            allotedAccessId,
            values.signature,
            approval.subReference === 5 ? 1 : approval.subReference === 6 ? 2 : '', //ref-5= movein(1), ref-6= moveout(2)
            approval.id,
            values.card_number,
            'other_access_cards'
          )
            .then((response: any) => {
              setLoading(false)
              if (response.errorCode === 0) {
                clearForm()
                // successRef.current.click()
                setReloadData(true)
                var ev = new Event('click', {bubbles: true})
                buttonEl.current.dispatchEvent(ev)
              } else {
                setSubmitError('Some error occured. Please try again !')
                // setMessage(response.errorDescription)
                // failRef.current.click()
              }
            })
            .catch((e) => {
              setLoading(false)
              setSubmitting(false)
              setStatus('Invalid Data Provided')
            })
        }
      }, 1000)
    },
  })

  const sigCanvas = useRef<any>()

  function clearSignature() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }

  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  const clearForm = () => {
    formik.resetForm()
    formik.values.card_number?.map((card: any, index: any) =>
      formik.setFieldValue(`card_number[${index}]`, '')
    )
    formik.setFieldValue('card_number', [])
    clearSignature()
  }

  // useEffect(() => {
  //   if (defaultCount) {
  //     if (user_role === 'FCSS' || cardSignature?.length > 0) {
  //       const inputs = new Array(defaultCount).fill(0).map((item, index) => (
  //         <input
  //           type='text'
  //           className='form-control fw-bolder form-control-solid expected-rent-input'
  //           // name={`card_number[${index}]`}
  //           // onChange={formik.handleChange}
  //           // value={formik.values.card_number?.[index]}
  //           value={cardDetails?.[index]}
  //           autoComplete='off'
  //           required
  //           maxLength={25}
  //           disabled
  //         />
  //       ))
  //       setCardInputs(inputs)
  //     } else {
  //       const inputs = new Array(defaultCount)
  //         .fill(0)
  //         .map((item, index) => (
  //           <input
  //             type='text'
  //             className='form-control fw-bolder form-control-solid expected-rent-input'
  //             name={`card_number[${index}]`}
  //             onChange={formik.handleChange}
  //             value={formik.values.card_number?.[index]}
  //             autoComplete='off'
  //             required
  //             maxLength={25}
  //           />
  //         ))
  //       setCardInputs(inputs)
  //     }
  //   }
  //   // else(defaultCount===undefined||defaultCount===0)
  //   // {
  //   //   <p>No Cards Assigned</p>
  //   // }
  // }, [])

  const handleAdd = () => {
    const newCount = inputCount
    const newInput = (
      <input
        type='text'
        className='form-control fw-bolder form-control-solid'
        name={`card_number[${newCount}]`}
        onChange={formik.handleChange}
        value={formik.values.card_number?.[newCount]}
        autoComplete='off'
        required
        maxLength={25}
      />
    )
    setCardInputs([...cardInputs, newInput])
    setInputCount(newCount + 1)
  }

  const handleRemove = () => {
    const lastIndex = inputCount - 1

    if (inputCount > defaultCount) {
      const updatedInputs = cardInputs
      updatedInputs.pop()
      setCardInputs(updatedInputs)
      setInputCount(inputCount - 1)

      let updatedValues = [...formik.values.card_number]
      updatedValues.pop()
      formik.setFieldValue('card_number', updatedValues)
    }
  }

  if (defaultCount === undefined || defaultCount === 0)
    return (
      <div className='card'>
        <div className='card-body p-7 '>
          <div className='row'>
            <NoDataFound />
          </div>
        </div>
      </div>
    )

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='accessCardRequestForm'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design'>
                    {formik.touched.card_number && formik.errors.card_number && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.card_number}
                          </span>
                        </div>
                      </div>
                    )}
                    {/* {cardInputs?.map((cardInput: any, index: any) => { */}
                    {user_role === 'FCSS' ||
                    cardSignature?.length > 0 ||
                    (user_role === 'FMSM' && type === 'moveout') ? (
                      <>
                        {new Array(defaultCount).fill(0).map((value: any, index: any) => {
                          return (
                            <div key={index} className='col-sm-6 col-md-4 col-12 label_close'>
                              <div className='mb-5'>
                                <label className='form-label fw-bolder'>
                                  {' '}
                                  {t("Card Number")} {index + 1}{' '}
                                </label>
                                <div className='d-flex'>
                                  {/* {cardInput} */}
                                  <input
                                    type='text'
                                    className='form-control fw-bolder form-control-solid expected-rent-input bg-light'
                                    value={cardDetails?.[index]}
                                    autoComplete='off'
                                    required
                                    maxLength={25}
                                    disabled
                                  />

                                  {index === inputCount - 1 && index > defaultCount - 1 && (
                                    <button
                                      type='button'
                                      className='bg-white cancel-bts px-7'
                                      onClick={handleRemove}
                                    >
                                      <i className='bi bi-x-circle font-18 text-black'></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        {new Array(defaultCount).fill(0).map((value: any, index: any) => {
                          return (
                            <div key={index} className='col-sm-6 col-md-4 col-12 label_close'>
                              <div className='mb-5'>
                                <label className='form-label fw-bolder'>
                                  {' '}
                                  {t("Card Number")} {index + 1}{' '}
                                </label>
                                <div className='d-flex'>
                                  <input
                                    type='text'
                                    className='form-control fw-bolder form-control-solid expected-rent-input'
                                    name={`card_number[${index}]`}
                                    // onChange={(e)=> handleValueChange(e,index)}
                                    onChange={formik.handleChange}
                                    value={formik.values.card_number?.[index]}
                                    autoComplete='off'
                                    required
                                    maxLength={25}
                                  />
                                  {index === inputCount - 1 && index > defaultCount - 1 && (
                                    <button
                                      type='button'
                                      className='bg-white cancel-bts px-7'
                                      onClick={handleRemove}
                                    >
                                      <i className='bi bi-x-circle font-18 text-black'></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}

                    {/* {
                        (count > inputCount) &&
                        <div className="col-sm-6 col-md-4 col-12">
                          <div className="mb-6">
                            <label className="form-label fw-bolder d-block">&nbsp;</label>
                            <button type="button" className='btn btn-primary fw-bold px-10 py-3'
                              onClick={handleAdd}
                            >
                              <span className='indicator-label'>Add More</span>
                            </button>
                          </div>
                        </div>
                      }  */}
                    <div className='row form-add-design'>
                      {!cardSignature?.length ? (
                        <div className='col-md-12'>
                          <div className='row'>
                            <div className='col-sm-8 col-md-4 col-12'>
                              <div className='mb-md-0 mb-4'>
                                <label className='form-label fw-bolder'>
                                  {user_role === 'FCSS' ? t('Customer Signature') : t('FCSS Signature')}
                                </label>
                                <div className='w-100 form-control'>
                                  <SignatureCanvas
                                    ref={sigCanvas}
                                    penColor='black'
                                    canvasProps={{height: 80, width: 320, className: 'sigCanvas'}}
                                    onEnd={handleSignature}
                                  />
                                </div>
                                {formik.touched.signature && formik.errors.signature && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>
                                        {formik.errors.signature}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-sm-4 col-md-4 my-auto col-12'>
                              <div className='mt-0 mt-md-3 mb-4 mb-md-0'>
                                <button
                                  type='button'
                                  className='btn btn-light fw-bold px-10 py-2'
                                  onClick={clearSignature}
                                >
                                  {t("Clear")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='col-sm-6 col-md-4 col-12'>
                            <div className='mb-6'>
                              <label className='form-label fw-bolder d-block'>
                                {user_role === 'FCSS' ? t('Customer Signature') : t('FCSS Signature')}
                              </label>
                              <span className='sign-box1'>
                                <img src={cardSignature} className='img-fluid' />
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    
                    {user_role === 'FMSM' && cardSignature2 && cardSignature2 !== '' && (
                      <div className='col-sm-6 col-md-4 col-12'>
                        <div className='mb-6'>
                          <label className='form-label fw-bolder d-block'>{t("Customer Signature")}</label>
                          <span className='sign-box1'>
                            <img src={cardSignature2} className='img-fluid' />
                          </span>
                        </div>
                      </div>
                    )}
                    </div>
                    {!cardSignature?.length && (
                      <div className='col-12 text-end'>
                        <Link to={backArrowRedirection} className=''>
                          <button
                            type='button'
                            className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                            onClick={() => clearForm()}
                          >
                            {t("Cancel")}
                          </button>
                        </Link>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                          {!loading && <span className='indicator-label'>{t("Save")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {submitError && (
        // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
        <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
          {submitError}
        </div>
      )}
      <button
        ref={buttonEl}
        data-bs-target='#req-submitted-popup2'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        type='button'
        className='invisible d-none btn btn-primary fw-bold px-10 py-3'
      ></button>
      <CustomRequestSuccessfulPopup successMessage={'Other Access Card Saved Successfully!'} customID={'2'} />

      {/* <p
         ref={successRef}
       data-bs-target='#req-submitted-popup'
       data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
      
       </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
         data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
     
      </p>
       <RequestFailedPopup message={message} /> */}
    </>
  )
}
