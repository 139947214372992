import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler';

export function AddInspectionForm({ selectedInspectionData, serviceCategoryData, precise_locations, handleFormSave, handleCancel , addedDataList}: any) {
    const { t } = useTranslation();
    // let existingImage = ''
    // if(selectedInspectionData?.image && selectedInspectionData?.image !== ''){
    //     existingImage = URL.createObjectURL(selectedInspectionData?.image)
    // }

    const [imgPreview, setImgPreview] = useState('')
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState('')

    useEffect(() => {
        if(selectedInspectionData?.image && selectedInspectionData?.image !== ''){
            setImgPreview(URL.createObjectURL(selectedInspectionData?.image))
        }
    }, [])

    const initialValues = {
        category: selectedInspectionData?.category_type_id ? selectedInspectionData.category_type_id : "",
        precise_location: selectedInspectionData?.room_id ? selectedInspectionData.room_id : "",
        quantity: selectedInspectionData?.qty ? selectedInspectionData.qty : "",
        description: selectedInspectionData?.description ? selectedInspectionData.description : "",
        mediaUpload: selectedInspectionData?.image ? selectedInspectionData.image : "",
    }
    
    const onSubmit = (values: any) => {
        // setError('')
        // console.log(values, 'values');
        // check is values already added to addedDataList

        // console.log(values.category, values.precise_location, 'new Data');
        // console.log(addedDataList, 'addedDataList');
        // let isAlreadyAdded = false
        // for (const item of addedDataList) {
        //     if(item.category_type_id === values.category && item.room_id === values.precise_location){
        //         isAlreadyAdded = true;
        //         break
        //     }
        // }
        
        // if(isAlreadyAdded){
        //     setError('Category & Room already exist! Please edit or select other Category/Room.')
            
        // }else{
        //     handleFormSave(values)
        //     formik.resetForm();
        // }

        if(error === ''){
            handleFormSave(values)
            formik.resetForm();
        }
    }

    const validationSchema = Yup.object({
        category: Yup.string().required(t('Category is required!')),
        precise_location: Yup.string().required(t('Room is required!')),
        quantity: Yup.string()
            // .max(3, 'Mobile No should be smaller then 3 digits')
            .matches(/^[0-9]*$/, t('Only numbers allowed!'))
            .required(t('Quantity is required!'))
            .test(
                'Is positive?', 
                t('Quantity must be greater than 0!'), 
                (value) => Number(value) > 0
              ),
        description: Yup.string()
            .max(250,t("Max length is 250 characters!"))
            .matches(/^[^\s].*/, t('Description cannot start with a blankspace!'))
            .required(t("Description is required!")),
        // mediaUpload: Yup.mixed()
        //     // .required('Image is required')
        //     .test('type', t('Only the following formats are acceptable: .jpeg, .jpg, .png'), (value) =>
        //         value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true )
        //     .test('fileSize', t('The file is too large!'), (value) => value ? value?.size <= 2000000 : true ),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    useEffect(() => {
        let isAlreadyAdded = false
        if(!selectedInspectionData || !(selectedInspectionData.category_type_id === formik.values.category && selectedInspectionData.room_id === formik.values.precise_location)){
            for (const item of addedDataList) {
                if(item.category_type_id === formik.values.category && item.room_id === formik.values.precise_location){
                    isAlreadyAdded = true;
                    break
                }
            }
            if(isAlreadyAdded){
                setError(t('Inspection for selected Category & Room already added! Please edit existing or select other Category/Room.'))
            }else{
                setError('')
            }
        }else{
            setError('')
        }     
    }, [formik.values.category, formik.values.precise_location])

    const [uploading, setUploading] = useState(false)
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = (e: any) => {
        setUploading(true)
        formik.setFieldError('mediaUpload', undefined)
        try {
            validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
                if (compressResponse?.success) {
                    inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
                    formik.setFieldTouched('mediaUpload')
                    formik.setFieldValue('mediaUpload', compressResponse.file)
                    setImgPreview(URL.createObjectURL(compressResponse?.file))
                } else {
                    setUploadedFileName(null)
                    formik.setFieldTouched('mediaUpload', true, false)
                    formik.setFieldValue('mediaUpload', '', false)
                    formik.setFieldError('mediaUpload', t(compressResponse?.message || 'File compression error!'))
                }
                setUploading(false)
            })
        } catch (error) {
            setUploadedFileName(null)
            formik.setFieldTouched('mediaUpload')
            formik.setFieldValue('mediaUpload', '', false)
            formik.setFieldError('mediaUpload', t('File upload error!'))
            setUploading(false)
        }
        // inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name);
        // setImgPreview(URL.createObjectURL(e.currentTarget.files[0]))
        // formik.setFieldTouched('mediaUpload')
        // formik.setFieldValue('mediaUpload', e.currentTarget.files[0])
    };
    const handleClearImage = () => {
        setImgPreview('')
        formik.setFieldValue('mediaUpload', '')
    }
    const handleCancelClear = () => {
        formik.resetForm();
        handleCancel()
    }

    const subCategoryOptions = serviceCategoryData?.map((subCategory: any) => (
        <option key={subCategory.id} value={subCategory.id}>
            {subCategory.name}
        </option>
    ))
    const roomOptions = precise_locations?.map((location: any) => (
        <option key={location.id} value={location.id}>
            {location.name}
        </option>
    ))

    return (
        <div className="col-12">
            <div className="card mb-7">
                <div className="card-body p-7">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row form-add-design">
                            <div className="col-12">
                                <h4 className="card-title font-16 text-black mb-5">
                                    {selectedInspectionData ? 'Edit':'Add'} {t("Inspection Details")}
                                </h4>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="mb-7">
                                    <label className='form-label fw-bolder'>{t("Select Category")} <span className="text-danger"> * </span> </label>
                                    <select
                                        className='form-control form-select fw-bolder form-control-solid'
                                        data-control="select2" data-allow-clear="true"
                                        {...formik.getFieldProps('category')}
                                    >
                                        <option value="">{t("Please Select")}</option>
                                        {subCategoryOptions ? subCategoryOptions : ''}
                                    </select>
                                    {formik.touched.category && formik.errors.category && (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.category}</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className='form-label fw-bolder'>{t("Select Room")} <span className="text-danger"> * </span> </label>
                                    <select
                                        className='form-control form-select fw-bolder form-control-solid'
                                        data-control="select2" data-allow-clear="true"
                                        {...formik.getFieldProps('precise_location')}
                                    >
                                        <option value="">{t("Please Select")}</option>
                                        {roomOptions ? roomOptions : ''}
                                    </select>
                                    {formik.touched.precise_location && formik.errors.precise_location && (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.precise_location}</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder"> {t("Required Quantity")} <span className="text-danger"> * </span> </label>
                                    <input
                                        type="text"
                                        {...formik.getFieldProps('quantity')}
                                        className="form-control fw-bolder form-control-solid" placeholder="" />

                                    {formik.touched.quantity && formik.errors.quantity ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.quantity}</small>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* <div>
                                {imgPreview !== '' &&  <img src={imgPreview} alt='img' /> }
                            </div> */}
                            {imgPreview === '' ?
                                <div className="col-md-4 col-12">
                                    <div className="mb-7 upload-input">
                                        <label className="form-label fw-bolder d-block mb-6">{t("Attach Image")} ({t("Optional")})</label>
                                        <input
                                            ref={inputRef}
                                            className="d-none"
                                            type="file"
                                            onChange={(e) => handleDisplayFileDetails(e)}
                                            // onBlur={formik.handleBlur}
                                            accept=".png,.jpg,.jpeg"
                                            />
                                        <button onClick={handleUpload} type='button' className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`} disabled={uploading}>
                                            <span>{uploading ?
                                                <span className='indicator-progress d-block'>
                                                    {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                                : uploadedFileName ? uploadedFileName : <span>{t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                        </button>
                                        {formik.touched.mediaUpload && formik.errors.mediaUpload ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.mediaUpload}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                :
                                <div className="col-md-4 col-12">
                                    <div className="mb-7 upload-input">
                                        <label className="form-label fw-bolder d-block mb-6">{t("Attached Image")} ({t("Optional")})</label>
                                        <div className="position-relative">
                                            <div className="upload-image mt-0">
                                                <img src={imgPreview} alt="Preview" className="form-img__img-preview" />
                                                <div className="close-icon" onClick={handleClearImage}>&times;</div>
                                            </div>
                                        </div>
                                        {formik.touched.mediaUpload && formik.errors.mediaUpload ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.mediaUpload}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            }
                            <div className="col-md-8 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Description")} <span className="text-danger"> * </span> </label>
                                    <textarea
                                        className='form-control fw-bolder form-control-solid'
                                        data-kt-autosize='true'
                                        rows={3}
                                        placeholder='Type here...'
                                        {...formik.getFieldProps('description')}
                                    />
                                    {formik.touched.description && formik.errors.description ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.description}</small>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 text-end">
                                <button type='button' className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                                    onClick={handleCancelClear}>
                                    {t("Cancel")}
                                </button>
                                <button onClick={formik.submitForm} type='button' className='btn btn-primary fw-bold px-10 py-3' disabled={uploading}>
                                    {t("Next")}
                                </button>
                            </div>
                        </div>
                    </form>
                    {error !== '' && (
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="alert alert-danger w-100 mt-5 mb-0" role="alert">
                                    {error}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}