import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Propertie from '../../../../assets/images/latest-one.png';
import Place from '../../../../assets/images/place.svg'
// import bed from '../../../../assets/images/icon-bed.svg'
// import Bath from '../../../../assets/images/icon-bath.svg'
// import SQR from '../../../../assets/images/icon-sqft.svg'
// import Yes from '../../../../assets/images/icon-parking.svg'
import SQR from '../../../../assets/images/icon-sqft.svg'
import bed from '../../../../assets/images/icon-bed.svg'
import Bath from '../../../../assets/images/icon-bath.svg'
import Yes from '../../../../assets/images/icon-parking.svg'
import { RootState } from '../../../../constants';
import NoDataFoundImage from '../../../../assets/images/no-data-found.svg';
import * as PM from '../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { CustomLoader } from './../../../../constants/generics/customLoader';
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../constants/generics/customActios';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';

export function ListView() {
    
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const location = useLocation()
    const dispatch = useDispatch()
    
    let proprtyList: any
    proprtyList = useSelector<RootState>(({ PM }) => PM.proprtylist)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(10)
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(PM.actions.proprtylist(`upcoming?currentPage=${currentPage}&pageSize=${pageSize}&id_sort=1&building_id=${currentBuilding && currentBuilding !== '' ? currentBuilding : '0'}`))
    }, [, currentBuilding])
    const handleClick = (newCurrentPage: any) => {
        setCurrentPage(newCurrentPage)
    };
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handleClick(value);
    };
    if (proprtyList === undefined) { return <CustomLoader /> }
    return (
        <div className="com-card-section">
            <div className="row">
                                {proprtyList?.errorCode != 1 && Array.isArray(proprtyList?.data) && proprtyList?.data?.length > 0 ? proprtyList?.data?.map((item: any, index: number) => {
                                    return (<div className="col-sm-12 col-xs-12 col-lg-6">
                                        <Link to={{
                                            pathname: '/pm-availability/property-details',
                                            state: { id: item?.id }
                                        }}>
                                            <div className="properties_list_view mb-5">
                                                <div className="card p-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-12">
                                                            <div className="offer_main_img">
                                                                <img src={item?.coverImage?.length > 0 ? item?.coverImage : Propertie} alt="Work Orders" className="img-fluid" />
                                                                <span className="badge badge-pill badge-dark prop-type-badge">{item?.property_search_type === 1 ? t("Residential") : item?.property_search_type === 2 ? t("Commercial") : item?.property_search_type}</span>
                                                                <div className="property_card_headding py-3 px-3">
                                                                    <div className='row'>
                                                                        <div className='col-6'><p className="fw-bolder mb-0 text-white">{item?.communityName ? item.communityName : '-'}</p></div>
                                                                        <div className='col-6 text-end'>
                                                                        <h4 className="text-white fw-bolder me-3">
                                                                            <small>{t("AED")}</small> <span className="fw-boldest">{item?.price ? currencyFormater(item?.price) : '-'}</span>
                                                                        </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>    
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="offer_main_details p-3">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <p className="text-black fw-bolder fs-16">{item?.property_name ? item.property_name : '-'}</p>
                                                                        </div>
                                                                        {/* <div className="col-2 text-end">
                                                                            <p className="available_checkbox_list mt-n3">
                                                                                <input className="form-check-input fc-wo-checkbox rounded-5" type="checkbox" id="flexCheckDefault" />
                                                                            </p>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="offer_main_details_div_2">
                                                                        {(item?.bedrooms && item?.bedrooms !== '' && item?.bedrooms !== '0') &&
                                                                            <p className='me-3'>
                                                                                <img src={bed} className="img-fluid" />
                                                                                {item?.bedrooms}
                                                                            </p>
                                                                        }
                                                                        {item?.bathrooms &&
                                                                            <p className='me-3'>
                                                                                <img src={Bath} className="img-fluid" />
                                                                                {item?.bathrooms ? item?.bathrooms : '-'}
                                                                            </p>
                                                                        }
                                                                        <p className="sqft">
                                                                            <img src={SQR} className="img-fluid" />
                                                                            {item?.builtInArea ? item?.builtInArea : '-'} {t("Sqmt")}
                                                                        </p>
                                                                        <p>
                                                                            <img src={Yes} className="img-fluid" />
                                                                            {item?.is_parking_available === 'Y' ? t("Yes") : t("No")}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 offer_main_details_div_3">
                                                                    <p className="mb-0 pt-2">
                                                                        <img src={Place} className="img-fluid" />
                                                                        {/* {item?.propertyType} | {item?.address}  {item?.location} */}
                                                                        {item?.property_code ? item?.property_code?.substring(
                                                                            item?.property_code?.lastIndexOf('-') + 1,
                                                                            item?.property_code?.length
                                                                        ) + ' | ' : ''}{' '}
                                                                        {item?.buildingType && item?.buildingType + ' | '}{' '}
                                                                        {item?.address && item?.address + ' | '}{' '}
                                                                        {item?.location && item?.location}

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    )
                                }) : <div className="col-12 com-card-section">
                                    <div className="card border-0 shadow-none no-data-found-cd">
                                        <div className="card-body border-0">
                                            <div className="row justify-content-center">
                                                <div className="col-12 text-center">
                                                    <img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" />
                                                </div>
                                                <div className="col-12 text-center">
                                                    <span className='d-block text-center font-18 fw-bolder text-black'> {t("No Data Found")} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className="w-100 d-flex justify-content-end">
                                    {/* {proprtyList?.data?.length > 0 && <Stack spacing={2}>
                                        <Pagination page={currentPage} count={proprtyList?.dataCount % pageSize === 0 ? proprtyList?.dataCount / pageSize : Math.ceil(proprtyList?.dataCount / pageSize)} onChange={handlePageChange} />
                                    </Stack>} */}
                                    <CompactPagination
                                        pageSize={pageSize}
                                        totalCount={proprtyList?.dataCount ? parseInt(proprtyList?.dataCount) : 0}
                                        defaultPage={currentPage}
                                        onPageChange={setCurrentPage}
                                        loading={false}
                                    />
                                </div>
                            </div>
        </div >
    );
}