import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function  EmailDistributeModal() {

    return ( 
        <> 
           <div className="modal fade prop-enq-modal" id="email_dist_modal">
                <div className="modal-dialog overflow-hidden modal-lg">
                    <div className="modal-content overflow-hidden border-0">
                        <div className="modal-header bg-primary text-white">
                            <h5 className="modal-title font-16 text-white font-weight-semibold">Send Email</h5>
                            <button type="button" className="close text-warning bg-white rounded-circle" data-bs-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form className="d-block">
                                <div className='row'>
                                    <div className="col-lg-6 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Name</label>
                                        <input type="text" className="form-control font-13" name="" placeholder=""/>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Email</label>
                                        <input type="email" className="form-control font-13" name="" placeholder=""/>
                                    </div>
                                    <div className="col-lg-12 col-sm-12 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Subject</label>
                                        <input type="text" className="form-control font-13" name="" placeholder=""/>
                                    </div>
                                    <div className="col-lg-12 col-sm-6 mb-7">
                                        <label className="d-block text-uppercase mb-0 font-13">Massage</label>
                                        <div className="">
                                            <textarea className="form-control font-13" rows={4}></textarea>
                                        </div>
                                    </div>  		
                                    <div className="col-12 text-end">
                                        <button className="btn btn-primary font-12">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
	                
        </>
    )
}