import { Bar } from 'react-chartjs-2';

export const options = {
    responsive: true,
    indexAxis: 'y' as const,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Top Agents',
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };
  
const labels = ['Facebook', 'Linkedin', 'Twitter', 'Instagram', 'Print', 'Emailar','Whats App'];

export const data = {
labels,
datasets: [
    {
        // label: 'Social',
        data: [92, 60, 72, 54, 40, 32, 75],
        backgroundColor: ['#3956a2','#0177b5','#3cbdf7','#c23081','#6eb39e','#ffa837','#000000' ]
    } 
],
};

export const Expenditure = (props:any) => {

    return(
        <div className="com-card-section">
            <div className="row">
            </div>
            <div className="row">
                <div className="col-12">
                    <Bar options={options} data={data} width={100} height={100}/>
                </div>
            </div>
        </div>
    )
}
