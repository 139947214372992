const linkState = [
  {
    notif_type: ['edit_profile'],
    link: '/profile',
    stateKey: '',
    stateValName: '',
  },
  {
    //Finance Services
    notif_type: [
      'other_Charges_Dispute_raised',
      'clearance_Charges_Dispute_raised',
      'cheque_replace',
      'add_invoice_followUp',
      'dispute_charges_approved',
      'cheque_delay_approved',
      'cheque_replace_approved',
      'invoice_followup_approved',
      'clearance_dispute_charges_approved',
      'other_dispute_charges_approved',
    ],
    link: '/finance-services-request/view',
    stateKey: 'serviceDetail',
    stateValName: 'id',
    keyname: 'service_request_id',
    permission: ['FT'],
  },
  {
    //Finance Services Cheque Delay
    notif_type: ['cheque_delay_raised'],
    link: '/finance-services-request/view',
    stateKey: 'serviceDetail',
    stateValName: 'id',
  },

  {
    //Finance Refunds
    notif_type: ['refund_request_raised', 'refund_request_approved'],
    link: '/finance-services-request-refunds/view',
    stateKey: 'refundDetail',
    stateValName: 'id',
    keyname: 'refund_request_id',
    permission: ['FT'],
  },
  {
    //Finance MoveOut
    notif_type: ['moveout_request_raised'],
    link: '/finance-moveout-requests/view', 
    stateKey: 'requestDetail',
    stateValName: 'id',
    keyname: 'object_id',
    permission: ['FT'],
  },

  {
    //Finance MoveOut
    notif_type: ['moveout_request_approve'],
    link: '/finance-moveout-requests/view',
    stateKey: 'requestDetail',
    stateValName: 'id',
    permission: ['FT'],
  },

  {
    //Finance Payment
    notif_type: [
      'rent_payment_done',
      'ac_payment_done',
      'sd_payment_done',
      'clearance_charge_payment_done',
      'service_charge_payment_done',
      'other_charge_payment_done',
      'vat_payment_done',
      'both_clearance_other_charge_payment_done',
    ],
    link: '/finance-payments/view',
    stateKey: 'paymentDetails',
    stateValName: 'id',
    keyname: 'payment_list_id',
    permission: ['FT'],
  },

  {
    //Finance Payment
    notif_type: ['payment_receipt'],
    link: '/finance-payments/view',
    stateKey: 'paymentDetails',
    stateValName: 'id',
    permission: ['FT'],
  },

  {
    notif_type: [
      'work_assign',
      'on_hold',
      'work_order_checklist',
      'tenant_not_available',
      'work_order_closed',
      'onhold_book_appointment',
      'approved_onhold',
      'rejected_onhold',
    ],
    link: '/facility-workorder/view',
    stateKey: 'workOrder',
    stateValName: 'id',
  },
  {
    notif_type: [
      'maintenance_acitvity_request',
      'activity_closed',
      'leasing_acitvity_request',
      'activity_request',
    ],
    link: '/activities/view',
    stateKey: 'activityData',
    stateValName: 'id',
  },
  {
    notif_type: ['fire_alarm_closed', 'fire_alarm'],
    link: '/activities/view',
    stateKey: 'activityData',
    stateValName: 'id',
    permission: ['FCS'],
  },
  {
    notif_type: ['fc_create_announcements', 'create_announcements'],
    link: '/announcements-details',
    stateKey: 'item',
    stateValName: 'id',
  },
  {
    notif_type: ['maintenance_request_submited', 'maintenance_resolved'],
    link: '/facility-maintenance/view',
    stateKey: 'maintenanceDetail',
    stateValName: 'id',
    permission: ['FCS', 'FMT', 'FCSS', 'FMSM'],
  },
  {
    notif_type: ['maintenance_request'],
    link: '/facility-maintenance/view',
    stateKey: 'maintenanceDetail',
    stateValName: 'id',
    permission: ['FCSS', 'FMSM'],
  },
  {
    notif_type: ['clearance_moveout_confirm'],
    link: '/facility-workorder/view',
    stateKey: 'workOrder',
    stateValName: 'id',
    keyname: 'work_order_id',
    viewBtn: 'View | Add Price',
    permission: ['FC', 'FCS', 'FMT'],
  },
  {
    notif_type: ['mo_clearance'],
    link: '/facility-workorder/view',
    stateKey: 'workOrder',
    stateValName: 'id',
    keyname: 'work_order_id',
    viewBtn: 'View',
    permission: ['FC', 'FCS', 'FMT'],
  },
  {
    notif_type: [
      'maintenance_request_received',
      'maintenance_request',
      'maintenance_request_feedback',
      'moveOut_inspection',
    ],
    link: '/facility-workorder/view',
    // link2: '/facility-maintenance/view',
    stateKey: 'workOrder',
    // stateKey2: 'maintenanceDetail',
    stateValName: 'id',
    // stateValName2: 'id',
    keyname: 'work_order_id',
    viewBtn: 'View Workorder',
    permission: ['FCS', 'FMT'],
  },
  {
    // notif_type: ['incident_create', 'fire_alarm_closed'],
    notif_type: ['incident_create', 'incident_resolved'],
    link: '/incidents/details',
    stateKey: 'incidentData',
    stateValName: 'id',
  },
  {
    notif_type: ['fire_alarm_closed', 'fire_alarm'],
    link: '/incidents/details',
    stateKey: 'incidentData',
    stateValName: 'id',
    keyname: 'incident_id',
    permission: ['FCSS', 'FMSM'],
  },
  {
    notif_type: ['visitors_create', 'visitor_check_in', 'visitor_check_out'],
    link: '/visitors/details',
    stateKey: 'visitorsData',
    stateValName: 'id',
  },
  {
    notif_type: ['documents_create', 'documents_collect'],
    link: '/fc-ss-documents/document-details',
    stateKey: 'documentDetail',
    stateValName: 'id',
  },
  {
    notif_type: ['upcoming_visit'],
    link: '/scheduled-visit/view',
    stateKey: 'scheduledData',
    stateValName: 'id',
  },
  {
    notif_type: ['visit_schedule', 'reschedule_visit', 'cancel_visit'],
    link: '/scheduled-visit/view',
    stateKey: 'scheduledData',
    stateValName: 'id',
    keyname: 'visit_id',
    permission: ['FCSS', 'FMSM'],
  },
  {
    notif_type: ['teamMember_added'],
    link: '/user-redirect',
    stateKey: 'userData',
    stateValName: 'id',
    // permission: ['FCS']
    permission: ['FCS', 'FMT', 'FMSM', 'FT', 'MA']
  },
  {
    notif_type: ['approved_techinican', 'rejected_techinican'],
    link: '/user-redirect',
    stateKey: 'userData',
    stateValName: 'id',
    permission: ['FCS'],
  },
  {
    notif_type: ['meeting_schedule'],
    link: '/scheduled-visit/view',
    stateKey: 'scheduledData',
    stateValName: 'id',
  },

  {
    //FCSS/FMSM - Approvals Movein/Moveout
    notif_type: [
      'moveout_request_approve',
      'access_card_returned',
      'mo_access_card_details_handover',
      'mo_access_card_handover',
      'access_card_details_added',
      'mi_access_card_handover',
      'access_card_details_handover',
    ],
    link: '/move-approval-redirect',
    stateKey: 'approval',
    stateValName: 'id',
    permission: ['FCSS, FMSM'],
  },
  {
    //FCSS/FMSM - Approvals Movein/Moveout
    notif_type: ['move_in_approval'],
    link: '/move-approval-redirect', 
    stateKey: 'approval',
    stateValName: 'id',
    keyname: 'object_id',
  },

  // {
  //     notif_type: ['add_contractor', 'add_member'], // TODO
  //     link: '',
  //     stateKey: '',
  //     stateValName: ''
  // },
  {
    notif_type: ['approve_onhold'],
    // link: '/fmteam-approvals/on-hold/upcoming',
    // stateKey: '',
    // stateValName: '',
    // keyname: 'object_id',
    // viewBtn: 'View List',
    link: '/fmteam-viewDetails/onhold',
    stateKey: 'approval',
    stateValName: 'id',
    permission: ['FMT'],
  },
  {
    notif_type: ['technician_added'],
    // link: '/fmteam-approvals/technician/upcoming',
    // stateKey: '',
    // stateValName: '',
    // viewBtn: 'View List',
    link: '/fmteam-viewDetails/technician',
    stateKey: 'approval',
    stateValName: 'id',
    keyname: 'approval_id',
    permission: ['FMT'],
  },
  {
    notif_type: ['self_approved_onhold', 'self_rejected_onhold'],
    link: '/fmteam-viewDetails/onhold',
    stateKey: 'approval',
    stateValName: 'id',
    permission: ['FMT'],
  },
  {
    notif_type: ['self_approved_techinican', 'self_rejected_techinican'],
    link: '/fmteam-viewDetails/technician',
    stateKey: 'approval',
    stateValName: 'id',
    permission: ['FMT'],
  },

  // Marketing Admin & Team 
  { 
    notif_type: ['create_events', 'create_offer', 'create_voucher'],
    link: '/promotions/view',
    stateKey: 'marketData',
    stateValName: 'id',
    permission: ['MA'],
  },
  { 
    notif_type: [
                  'events_approval', 'offer_approval', 'voucher_approval', //New approvals
                  'voucher_approved', 'offer_approved', 'event_approved', //When approved
                  'offer_rejected', 'event_rejected', 'voucher_rejected', //When rejected
                ],
    link: '/mta-approvals/view',
    stateKey: 'approvalData',
    stateValName: 'id',
    keyname: 'approval_id',
    permission: ['MA'],
  },
  { 
    notif_type: ['approval_rmi_raised', 'promotion_rmi_fromMA', 'promotion_rmi_reply', ], //For MT/MA rmi
    link: '/mta-approvals/view',
    stateKey: 'approvalData',
    stateValName: 'id',
    keyname: 'approval_id',
    permission: ['MA', 'MT'],
  },
  { 
    notif_type: [
                  'create_events', 'create_offer', 'create_voucher',
                  'event_approve', 'offer_approve', 'voucher_approve',  //When approved by MA
                  'event_reject', 'reject_offer', 'voucher_reject',  //When rejected by MA
                ],
    link: '/mta-approvals/view',
    stateKey: 'approvalData',
    stateValName: 'id',
    keyname: 'approval_id',
    permission: ['MT'],
  },

  //PM notifications
  {
    notif_type: ['edit_property_details'],
    link: '/pm-availability/property-details',
    stateKey: 'id',
    // stateValName: 'id',
    permission: ['PM', 'PE'],
  },
  {
    notif_type: ['teamMember_added'],
    link: '/pm-myteam-team-members-details',
    stateKey: 'id',
    // stateValName: 'id',
    permission: ['PM', 'PE'],
  },
  {
    notif_type: ['approval_request', 'access_card_approval', 'cheque_replace'],
    link: '/pm-service-request/request-details',
    stateKey: 'id',
    // stateValName: 'id',
    permission: ['PM', 'PE'],
  },
]

export const getNotificationLinks = () => {
  return linkState
}
