import React, { FC, useEffect, useRef, useState } from 'react'
import Dateicon from "../../../../../assets/images/Calendar.png";
import UploadIcon from "../../../../../assets/images/upload-ioc.png";
import { Link } from 'react-router-dom'
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import DatePicker from 'react-date-picker';
import { getMasterData } from '../../../../constants/axios/apis';
import Loader from '../../../../components/Loader';
import moment from 'moment';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { PreventDatePickerInput } from '../../../../constants/generics/dateTimeFormater';
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { Select } from 'antd';

export const ReportCard: FC<any> = ({ reportState, setReportState, genReport, buildingListState, setReportType }) => {
    const today_data_object = new Date();
    const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    const [loading, setLoading] = useState<any>(true)
    const [reportList, setReportList] = useState<any>()
    const {Option} = Select

    const initialValues = {
        building_id: currentBuilding ? currentBuilding : "0",
        issue_category: "0",
        from_date: "",
        to_date: "",
        send_email: 'false',
        proper_date: ''
    };

    const onSubmit = (values: any) => {
        /* console.log('Form: ',values); */
        const sendMailVal = values.send_email === true ? "1" : "0";
        // const params = `?building_id=${values.building_id}&issue_category=${values.issue_category}&from_date=${values.from_date}&to_date=${values.to_date}&send_email=${sendMailVal}`;
        const params = `?building_id=${values.building_id}&from_date=${values.from_date}&to_date=${values.to_date}&send_email=${sendMailVal}`;
        genReport(params);
        const reportTypeVal = reportList?.find((el: any) => values.issue_category == el.value)?.name
        setReportType(reportTypeVal ? reportTypeVal : '')
    }

    const validate = (values: any) => {
        let errors: any = {}

        if (!values.building_id) {
            errors.building_id = 'Required!';
        }

        // if(!values.issue_category){
        //     errors.issue_category = 'Required';
        // }

        if (!values.from_date) {
            errors.from_date = 'Required!';
        }

        if (!values.to_date) {
            errors.to_date = 'Required!';
        }

        var date1 = new Date(values.from_date);
        var date2 = new Date(values.to_date);

        if (values.from_date && values.to_date) {
            if (date1.getTime() > date2.getTime()) {
                errors.proper_date = '\'To date\' must be greater then \'From date\' !';
            }
        }
        return errors;
    }
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    });

    const [fromDateValue, setFromDateValue] = useState<any>();
    const [toDateValue, setToDateValue] = useState<any>();
    const handleFromDateChange = (e: any) => {
        setFromDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') :'';
        formik.setFieldValue("from_date", menDate);        
    }
    const handleToDateChange = (e: any) => {
        setToDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') :'';
        formik.setFieldValue("to_date", menDate);        
    }


    useEffect(() => {
        setLoading(true)
        setReportList([])
        getMasterData().then(({ data }) => {
            const reportList = data.find((el: any) => el.category_name === "fc_report_type")
            setReportList(reportList?.master_data ? reportList?.master_data : [])
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
    }, [])

    const reportOption = reportList?.map((reportItem: any) => (
        <option key={reportItem.value} value={reportItem.value} className="text-capitalize">
            {reportItem.name}
        </option>
    ))

    PreventDatePickerInput()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        Report
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="card-body px-8 py-6">
                            {loading && (
                                <CustomLoader2 height={'126'}/>
                            )}
                            {!loading && (
                                <>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row form-add-design">
                                            <div className="col-md-3 col-sm-6 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">Select Building <span className="text-danger"> * </span></label>
                                                    <select
                                                        name="building_id"
                                                        className="form-control form-select fw-bolder form-control-solid"
                                                        onChange={formik.handleChange}
                                                        defaultValue={currentBuilding ? currentBuilding : '0'}
                                                    >
                                                        <option value="0">All</option>
                                                        {listBuilding?.map((building: any, index: number) =>
                                                            <option value={building.id} key={index}>{building.name}</option>
                                                        )}
                                                    </select>
                                                    {formik.errors.building_id ? (
                                                        <small className="text-danger">
                                                            {formik.errors.building_id}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-sm-6 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">Report Type <span className="text-danger"> * </span></label>
                                                    {/* <Select
                                                    className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                                                    showSearch
                                                    placeholder='Select a Report Type'
                                                    optionFilterProp='children'
                                                    value={
                                                        formik.values.issue_category !== '' ? formik.values.issue_category : null
                                                    }
                                                    onChange={(val) => {
                                                    formik.setFieldValue('issue_category', val)
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    filterOption={(input, option) =>
                                                        ((option!.children as unknown) as string)
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                    }
                                                    >
                                                    {reportList?.map((el: any, index: number) => (
                                                    <Option key={index} value={el.value}>
                                                    {el.name}
                                                    </Option>
                                                    ))}
                                                    </Select> */}
                                                    <select
                                                        name="issue_category"
                                                        className="form-control form-select fw-bolder form-control-solid text-capitalize"
                                                        onChange={formik.handleChange}
                                                    >
                                                        <option value="0">All</option>
                                                        {reportList ? reportOption : ''}
                                                    </select>
                                                    {/* {formik.errors.issue_category ? (
                                                        <small className="text-danger">
                                                            {formik.errors.issue_category}
                                                        </small>
                                                    ): null } */}
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12">
                                                <div className="row mb-5">
                                                    <div className="col-md-6 col-sm-6 col-12">
                                                        <div className="mb-2 date-picker">
                                                            <label className="form-label fw-bolder">From Date
                                                                <span className="text-danger"> * </span>
                                                            </label>
                                                            {/* <input
                                                                onChange={formik.handleChange}
                                                                value={formik.values.from_date}
                                                                name="from_date" type="date" className="form-control fw-bolder form-control-solid" placeholder=""
                                                                onBlur={formik.handleBlur} /> */}
                                                            <DatePicker
                                                                value={fromDateValue}
                                                                className='form-control fw-bolder form-control-solid'
                                                                format="dd-MM-y"
                                                                // maxDate={today_data_object}
                                                                onChange={handleFromDateChange}
                                                                dayPlaceholder="DD"
                                                                monthPlaceholder="MM"
                                                                yearPlaceholder="YYYY"
                                                            />
                                                            {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            {formik.touched.from_date && formik.errors.from_date ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.from_date}
                                                                </small>
                                                            ) : null}
                                                            {(formik.touched.from_date && formik.touched.to_date) && formik.errors.proper_date ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.proper_date}
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-12">
                                                        <div className="mb-2 date-picker">
                                                            <label className="form-label fw-bolder">To Date
                                                                <span className="text-danger"> * </span>
                                                            </label>
                                                            {/* <input
                                                                onChange={formik.handleChange}
                                                                value={formik.values.to_date}
                                                                name="to_date" type="date" className="form-control fw-bolder form-control-solid" placeholder=""
                                                                onBlur={formik.handleBlur} /> */}
                                                            <DatePicker
                                                                value={toDateValue}
                                                                className='form-control fw-bolder form-control-solid'
                                                                format="dd-MM-y"
                                                                // maxDate={today_data_object}
                                                                onChange={handleToDateChange}
                                                                dayPlaceholder="DD"
                                                                monthPlaceholder="MM"
                                                                yearPlaceholder="YYYY"
                                                            />
                                                            {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            {formik.touched.to_date && formik.errors.to_date ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.to_date}
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-lg-4 col-xl-6 col-12">
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-6 col-xl-3 col-12 py-4">
                                                <div className="form-check form-check-sm custom float-end">
                                                    <input
                                                        onChange={formik.handleChange}
                                                        value={formik.values.send_email}
                                                        name="send_email" className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                    <label className="form-check-label font-12 fw-normal" htmlFor="flexCheckDefault">
                                                        Send A Copy Via Email
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-6 col-xl-3 col-12">
                                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3 d-block w-100 GenerateReport'>
                                                    Generate Report
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}