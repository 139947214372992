import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import moment from 'moment'
import { getMarketPlaceDetails, marketPlaceList } from '../../../../constants/axios/apis'
import { CustomLoaderCompact, NoDataFound } from '../../../../constants/generics/customActios'
import { formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'
import { PromotionViewDetails } from './PromotionViewDetails'
import { SendOfferPopup } from './SendOfferPopup'
import { useTranslation } from 'react-i18next'

export function PromotionViewWrapper({setPopupSuccessMsg}:any) {
    const location = useLocation<any>()
    const history = useHistory()

    const [loading, setLoading] = useState<any>(true);
    const [loadingError, setLoadingError] = useState<any>(false);
    const [marketPlaceDetails, setMarketPlaceDetails] = useState<any>({})
    const { t } = useTranslation();

    useEffect(() => {
        if (location?.state?.marketData?.id) {
            const { marketData } = location.state

            // getMarketPlaceDetails(marketPlaceID).then(({ data }: any) => {
            //     if (data && data[0]) {
            //         setMarketPlaceDetails(data[0])
            //     } else {
            //         setMarketPlaceDetails({})
            //     }
            //     setLoading(false)
            // })
            marketPlaceList(`?id=${marketData.id}`).then((response: any) => {
                if (response?.errorCode === 0 && Array.isArray(response.data) && response.data.length > 0) {
                    setMarketPlaceDetails(response.data[0])
                    setLoadingError(false)
                } else {
                    setMarketPlaceDetails({})
                    setLoadingError(true)
                }
                setLoading(false)
            }).catch((e) => {setLoading(false); setLoadingError(true)})
        }
    }, [location])

    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-md-12 col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <span onClick={() => { history.goBack() }} className='c-pointer'>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        <span className='text-black'>{(marketPlaceDetails?.type? marketPlaceDetails.type : '-') + " " + t("Details")}</span>
                    </h4>
                </div>
            </div>
            {loading ? <CustomLoaderCompact />
                : loadingError ? <div className="rounded"><NoDataFound /></div>
                    :
                    <>
                        <PromotionViewDetails marketPlaceDetails={marketPlaceDetails} />
                        {marketPlaceDetails?.type === 'Offer' &&
                            <SendOfferPopup marketPlaceDetails={marketPlaceDetails} setPopupSuccessMsg={setPopupSuccessMsg} />
                        }
                    </>
            }
        </div>
    )
}
