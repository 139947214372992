import { Link } from "react-router-dom";
import { Facebook } from "./Graphs/FacebookCampaigns";
import { useState } from "react";
import { CompactPagination } from "../../../components/partials/content/CompactPagination/CompactPagination";

const facebookData = [{
    
},
];
export function FacebookCampaign() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)
    return (
        <>
            <div className="row mb-4 community align-items-center">
                <div className="col-md-6">
                    <h5 className="theme-color font-22 d-inline-block float-left m-0">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>  Facebook Campaign
                    </h5>
                </div>
                <div className="col-md-6 text-end">
                    <Link to='add' className="btn btn-primary radius-10"> Create New Campaign </Link>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-10">
                            <h6 className="font-weight-bold theme-color font-14 mb-3">Timeline Reports</h6>
                        </div>
                        <div className="col-2 text-end">
                            <div className="d-block date-pic position-relative date-range float-right">
                                <select className="form-control font-14">
                                    <option>Daily</option>
                                    <option>Weekly</option>
                                    <option>Monthly</option>
                                </select>
                                <ul id="ranges" className="ranges"></ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-12 cpt-tenant-det2">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item tab_nav_li" role="presentation">
                                        <button className="nav-link pb-4 position-relative active" id="pills-home-tab" data-bs-toggle="pill"
                                            data-bs-target="#summer" type="button" role="tab" aria-controls="pills-home"
                                            aria-selected="false">
                                            Post
                                        </button>
                                    </li>
                                    <li className="nav-item tab_nav_li" role="presentation">
                                        <button className="nav-link pb-4 position-relative" id="pills-profile-tab" data-bs-toggle="pill"
                                            data-bs-target="#Leads" type="button" role="tab" aria-controls="pills-profile"
                                            aria-selected="true">
                                            Fan
                                        </button>
                                    </li>
                                    <li className="nav-item tab_nav_li" role="presentation">
                                        <button className="nav-link pb-4 position-relative" id="pills-profile-tab" data-bs-toggle="pill"
                                            data-bs-target="#Viewing" type="button" role="tab" aria-controls="pills-profile"
                                            aria-selected="true">
                                            Likes
                                        </button>
                                    </li>
                                    <li className="nav-item tab_nav_li" role="presentation">
                                        <button className="nav-link pb-4 position-relative" id="pills-profile-tab" data-bs-toggle="pill"
                                            data-bs-target="#offer" type="button" role="tab" aria-controls="pills-profile"
                                            aria-selected="true">
                                            Comments
                                        </button>
                                    </li>
                                    <li className="nav-item tab_nav_li" role="presentation">
                                        <button className="nav-link pb-4 position-relative" id="pills-profile-tab" data-bs-toggle="pill"
                                            data-bs-target="#offer" type="button" role="tab" aria-controls="pills-profile"
                                            aria-selected="true">
                                            Shares
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="summer" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <Facebook />
                                    </div>
                                    <div className="tab-pane fade" id="Leads" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <Facebook />
                                    </div>
                                    <div className="tab-pane fade" id="Viewing" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <Facebook />
                                    </div>
                                    <div className="tab-pane fade" id="offer" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <Facebook />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className='row align-items-center my-5 table-drop'>
                        <div className="col-10"></div>
                        <div className="col-2 text-end d-flex align-items-center">
                            <span className="me-2">Show</span>
                            <select className="form-control form-select">
                                <option>10 </option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                            <span className="ms-2">entries</span>
                        </div>
                    </div>
                    <div className='com-card-section'>
                        <div className='table-crm'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='table-responsive'>
                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                <tr>
                                                    <th> #</th>
                                                    <th> CAMPAIGN NAME </th>
                                                    <th> CHANNEL </th>
                                                    <th> STATUS</th>
                                                    <th> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {facebookData.length > 0 ? facebookData.map((item, i) => (
                                                    <td colSpan={5} className="text-center">No data..</td>
                                                    
                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='w-100 d-flex justify-content-end'>
                                        <CompactPagination
                                            pageSize={pageSize}
                                            totalCount={facebookData.length}
                                            defaultPage={currentPage}
                                            onPageChange={setCurrentPage}
                                            loading={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}




