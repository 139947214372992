import React, { useState, useEffect, useRef } from 'react';
// import NoImage from "../../../../../../assets/images/House.png";
// import { ImageViewerWithPopup } from '../../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';
import { useTranslation } from 'react-i18next';

export function UserDetails({userDetails, loading}:any) {
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row form-add-design ">
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Staff Type")} </label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <p className='font-14 fw-bold d-block mb-0'>{userDetails?.staff_type}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("User Type")} </label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <p className='font-14 fw-bold d-block mb-0'>{userDetails?.user_type_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Designation")} </label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <p className='font-14 fw-bold d-block mb-0'>{userDetails?.user_designation}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Company Name")} </label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <p className='font-14 fw-bold d-block mb-0'>{userDetails?.companyName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Supervisor Name")} </label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <p className='font-14 fw-bold d-block mb-0'>{userDetails?.createdBy?.[0].first_name + ' ' + userDetails?.createdBy?.[0].last_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}