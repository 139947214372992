import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import {ApprovalTabs} from './components/ApprovalTabs/ApprovalTabs'
import {PermitToWork} from './components/ApprovalTabs/PermitToWork/PermitToWork'
import {NOC} from './components/ApprovalTabs/NOC/NOC'
import {OnHold} from './components/ApprovalTabs/OnHold/OnHold'
import {Redirect, Route, Switch} from 'react-router-dom'
import { Technician } from './components/ApprovalTabs/Technician/Technician'

const FMTeamApprovalsListPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
            <Switch>
                <Route path='/fmteam-approvals/permit-work'>
                    <ApprovalTabs />
                    <PermitToWork />
                </Route>
                <Route path='/fmteam-approvals/noc'>
                    <ApprovalTabs />
                    <NOC />
                </Route>
                <Route path='/fmteam-approvals/on-hold'>
                    <ApprovalTabs />
                    <OnHold />
                </Route>
                <Route path='/fmteam-approvals/technician'>
                    <ApprovalTabs />
                    <Technician />
                </Route>
                <Redirect to='/error/403' />
            </Switch>
        </div>
    </div>
    </>
);

const FMTeamApprovalsListWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}> Approvals </PageTitle>
        <FMTeamApprovalsListPage />
        </>
    )
};

export {FMTeamApprovalsListWrapper}
