import React, {FC, useState, useEffect} from 'react';
import { Redirect, Route, Switch} from 'react-router-dom';
// import {WorkOrderStatusTabs} from './new/WorkOrderStatusTabs/WorkOrderStatusTabs'
// import {CorrectiveWorkOrders} from './new/CorrectiveWorkOrders/CorrectiveWorkOrders'
// import {ClearanceWorkOrders} from './new/ClearanceWorkOrders/ClearanceWorkOrders'
// import {PreventiveWorkOrders} from './new/PreventiveWorkOrders/PreventiveWorkOrders'
// import {SLAWorkOrders} from './new/SLAOrders/SLAWorkOrders'
import { useSelector } from 'react-redux';
import { CorrectiveTabs } from './CorrectiveTabs';
import { OpenTableData } from '../FacilityWorkorderTables/OpenTableData/OpenTableData';
import { OnHoldTableData } from '../FacilityWorkorderTables/OnHoldTableData/OnHoldTableData';
import { CompletedTableData } from '../FacilityWorkorderTables/CompletedTableData/CompletedTableData';
// import { getFcsWorkOrders } from '../../../utils/makeRequest';
// import { IState } from '../../../utils/interface';
// import { RootState } from '../../../constants';
// import { getWorkOrdersList } from '../../../constants/axios/apis';
// import { WorkOrderStatusTabs } from './WorkOrderStatusTabs/WorkOrderStatusTabs';

export const CorrectiveWorkorderWrapper = ({fcsWorkOrderList, workOrder, loading}:any) => {
    
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3 movein-moveout ">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <Switch>
                                        <Route path='/facility-workorder/list/corrective/open'>
                                            <CorrectiveTabs />
                                            <OpenTableData
                                                fcsWorkOrderList={fcsWorkOrderList}
                                                workOrder={workOrder}
                                                loading={loading}
                                            />
                                        </Route>
                                        <Route path='/facility-workorder/list/corrective/onhold'>
                                            <CorrectiveTabs />
                                            <OnHoldTableData
                                                fcsWorkOrderList={fcsWorkOrderList}
                                                workOrder={workOrder}
                                                loading={loading}
                                            />
                                        </Route>
                                        <Route path='/facility-workorder/list/corrective/completed'>
                                            <CorrectiveTabs />
                                            <CompletedTableData
                                                fcsWorkOrderList={fcsWorkOrderList}
                                                workOrder={workOrder}
                                                loading={loading}
                                            />
                                        </Route>
                                        <Redirect to='/error/403' />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}