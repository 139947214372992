import React from 'react';
import { useSelector } from 'react-redux';
import { formatDateTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { useTranslation } from 'react-i18next';


export function WorkorderStatusView({workOrder}:any) {
    const { t } = useTranslation();

    // const redirectLink  = workOrder.issueCategory === 'Corrective' ? '/workorders/clearance/open' : '/workorders/corrective/open';
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    const isOnHold = workOrder.status === 'On hold'
    // const isPending =  workOrder.is_FMapproved === 0 || workOrder.is_FCSreassigned === 0
    const statusText = workOrder.is_FMapproved === 0 ? t('FM Approval Pending')
        : user_role === 'FCS' ?
            t('FM Approved')
        : workOrder.is_FCSreassigned === 0 ? (t('FCS Re-Assign Pending'))
            : (t('FM Approved & Re-Assigned'))

    return (
        <>
            <h5 className="mb-5">{t("Completion/Inspection Details")}</h5>
            <div className="row">
                <div className="table-responsive">
                    <table className="table table-striped corrective_table">
                        <tbody>
                            <tr>
                                <td className="ps-10">{t("Assigned to")}</td>
                                <td className="text-center">{workOrder.assignedTo ? workOrder.assignedTo : <i>{t("Not assigned yet!")}</i>}</td>
                                {/* <td className="text-center">TODO</td> */}
                                <td className="text-end pe-8">
                                    {/* <WorkOrderPopup 
                                    technicians={technicians}
                                    selectedTechnician={selectedTechnician}
                                    setSelectedTechnician={setSelectedTechnician}
                                /> */}
                                </td>
                            </tr>
                            <tr>
                                <td className="ps-10">{t("Status")}</td>
                                <td className="text-center">{workOrder?.status}
                                    {isOnHold ? ` (${statusText})` : ''}
                                </td>
                                <td className="text-end pe-8">

                                </td>
                            </tr>
                            {
                                (workOrder?.status === 'Completed' || workOrder?.status === 'Under Observation') &&
                                <tr>
                                    <td className="ps-10">{t("Completed On")}</td>
                                    <td className="">{formatDateTimeMeridiem(workOrder?.updated_at)}</td>
                                    <td className="text-end pe-8"></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {workOrder.status === "On hold" &&
                <div className='border-top pt-8 mt-5'>
                    <div className="row form-add-design">
                        <div className="col-md-4 col-12 mb-5">
                            <label className="form-label fw-bolder">{t("On-Hold Reason")}</label>
                            <div className='data-contain px-5 py-4 rounded-10'>
                                <p className='font-14 fw-bold d-block mb-0'>{workOrder.onHoldReason ? workOrder.onHoldReason : '-'}</p>
                            </div>
                        </div>
                        <div className="col-md-8 col-12">
                            <label className="form-label fw-bolder">{t("On-Hold Comment")}</label>
                            <div className='data-contain px-5 py-4 rounded-10'>
                                <p className='font-14 fw-bold d-block mb-0'>{workOrder.onHoldComment ? workOrder.onHoldComment : '-'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        
        </>
    );
}