import moment from 'moment'
import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {CompactPagination} from '../../../../components/partials/content/CompactPagination/CompactPagination'
import {RootState} from '../../../../constants'
import {getReceiptList} from '../../../../constants/axios/apis'
import {
  currencyFormater,
  CustomLoader2,
  NoDataFound1,
} from '../../../../constants/generics/customActios'

export function FinanceReceiptAllRequest({
  searchItem,
  setSearchItem,
  activeButton,
  setActiveButton,
}: any) {
  const location = useLocation()
  const {t} = useTranslation()

  const pageSize = 10
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalServiceReceiptCount, setTotalServiceReceiptCount] = useState<any>(0)
  const [serviceReceiptList, setServiceReceiptList] = useState<any>([])
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchItem, currentBuilding])

  useEffect(() => {
    setLoading(true)
    let page = currentPage
    let newPayload =
      '?currentPage=' + page + '&pageSize=' + pageSize + '&search=' + searchItem + '&id_sort=1'
    newPayload = currentBuilding
      ? newPayload + '&building_id=' + currentBuilding
      : newPayload + '&building_id=0'
    getReceiptList(newPayload).then((response: any) => {
      if (Array.isArray(response.data.receiptList) && response.data.receiptList.length > 0) {
        setServiceReceiptList(response.data.receiptList)

        setTotalServiceReceiptCount(response.dataCount)
      } else {
        setServiceReceiptList([])
        setTotalServiceReceiptCount(0)
      }
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    })
  }, [currentPage, currentBuilding, searchItem])

  if (!loading && (serviceReceiptList === undefined || serviceReceiptList?.length === 0)) {
    return <NoDataFound1 />
  }

  const rowData = serviceReceiptList?.map((data: any, index: any) => (
    <tr key={index}>
      {/* <td>{(currentPage - 1) * pageSize + index + 1}</td> */}
      <td>{`0000${data.id}`}</td>
      {/* <td>{data?.invoiceNumber ? data.invoiceNumber : '-'}</td> */}
      <td>{data?.tenantName ? data.tenantName : '-'}</td>
      <td>{data?.leaseNumber ? data.leaseNumber : '-'}</td>
      <td>{data?.propertyCode ? data.propertyCode : '-'}</td>
      <td>
        {t('AED')} {currencyFormater(data?.annualRent)}
      </td>
      <td>
        <Link
          to={{
            pathname: '/finance-services-receipt/view',
            state: {receiptDetail: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <div className='row'>
                  <div className='col-12 pt-5'>
                    <div className='rec-payment-act-sec'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='table-responsive'>
                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                              <thead>
                                <tr>
                                  {/* <th className=''> {t('No')} </th> */}
                                  <th className=''> {t('Invoice Number')} </th>
                                  <th className=''> {t('Tenant Name')} </th>
                                  <th className=''> {t('Lease Number')} </th>
                                  <th className=''> {t('Unit')} </th>
                                  <th className=''> {t('Amount')} </th>
                                  <th className=''> {t('Action')} </th>
                                </tr>
                              </thead>
                              {loading ? (
                                <tbody>
                                  <tr>
                                    <td colSpan={8} className='text-center pt-10 pb-0'>
                                      <CustomLoader2 />
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>{serviceReceiptList ? rowData : ''}</tbody>
                              )}
                            </table>
                          </div>
                          <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalServiceReceiptCount)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
