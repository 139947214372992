import { useState } from "react";

export function Publish({ chooseMessage }: any) {
    const [showRange, setShowRange] = useState<any>(false);
    return (
        <>

            <div className="card">
                <div className="card-body">
                    <div className="row mb-4 priview-community">
                        <div className="col-4">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked/>
                                <label className="form-check-label" >
                                    Publish Now
                                </label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-check">
                                
                                <input className="form-check-input" type="radio" name="flexRadioDefault" onChange={() => setShowRange(!showRange)}  />
                                <label className="form-check-label" >
                                    Schedule
                                </label>
                            </div>
                        </div>
                    </div>
                    {showRange ?
                    <div className="row">
                        <div className="col-4">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Select Date </label>
                                    <input type="date" className="form-control form-height font-13"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Select Time </label>
                                    <input type="time" className="form-control form-height font-13"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-primary mt-5">Submit</button>
                        </div>
                    </div>
                    : ''}
                </div>
            </div>
            <div className="text-end my-6">
                <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('2')}>Previous</button>
                <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('4')}>Next</button>
            </div>
        </>
    )
}