import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {saveAccessData, saveAccessDataFMSM} from '../../../../../../../constants/axios/apis'
import {RequestFailedPopup} from '../../../../../../SuccessfulPopup/RequestFailedPopup'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../../../../../../constants/generics/userCheck'
import {CustomRequestSuccessfulPopup} from '../../../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import {NoDataFound} from '../../../../../../../constants/generics/noDataFound'
import { useTranslation } from 'react-i18next';

export function BuildingAccessCard({
  cardTitle,
  count,
  cardDetails,
  allotedAccessID,
  cardSignature,
  setReloadData,
  cardSignature2,
}: any) {
  let params: any = {}
  params = useLocation()
  const {accessData} = params.state

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)
  const [submitError, setSubmitError] = useState('')
  
  const defaultCount = count
  const buttonEl: any = useRef(null)
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [cardInputs, setCardInputs] = useState<any>([])
  const [submitSuccessError, setSubmitSuccessError] = useState('')
  const [inputCount, setInputCount] = useState<any>(defaultCount)
  const [isSign, setIsSign] = useState<any>(false)
  const allotedAccessId = allotedAccessID
  const { t } = useTranslation();

  const saveFCSSApprovalsDataRequest = Yup.object().shape({
    signature: Yup.string().required(t('Please add signature')),
  })
  
  const saveFMSMApprovalsDataRequest = Yup.object().shape({
    // card_number: Yup.array().min(1, 'Card Number Required'),
    card_number: Yup.mixed()
      .test('exist', (t('Please enter atleast one card number')), (value: any) => {
        let status = false
        value?.map((number: any) => {
          if (number && number != '') {
            status = true
          }
        })
        return status
      })
      .test('exist', (t('Any Card number cannot start with a blankspace!')), (value: any) => {
        let status = true
        value?.map((number: any) => {
          if (/^\s/.test(number)) {
            status = false
          }
        })
        return status
      }),
  })

  const validationSchema =
    user_role === 'FCSS' ? saveFCSSApprovalsDataRequest : saveFMSMApprovalsDataRequest

  var cardArray = new Array()
  for (let i = 0; i < defaultCount; i++) {
    cardArray.push('')
  }
  const initialValues = {
    card_number:
      user_role !== 'FCSS' && cardDetails && cardDetails.length ? cardDetails : cardArray,
    signature: '',
  }


  const validate = (values: any) => {
    let errors: any = {}

    if(user_role === 'FMSM' && isSign){
      let filledCardCount = 0
      values.card_number.forEach((el:any)=>{
        if(el.trim().length >0)
          filledCardCount++
      })
      
      if(filledCardCount !== count)
        errors.card_number = t('Please fill all card to take signature!');
    }
    
    values.card_number.forEach((el:any)=>{
      if(el.length > 25)
        errors.card_number = t('Card Number cannot be more that 25 characters!');
    })
    
    return errors;
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validate,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setSubmitError('')
      setTimeout(() => {
        if (user_role === 'FCSS') {
          saveAccessData(
            accessData.propertyId,
            accessData.userID,
            allotedAccessId,
            values.signature,
            1,
            accessData.id
          )
            .then((response: any) => {
              setLoading(false)
              if (response.errorCode === 0) {
                clearForm()
                // successRef.current.click()
                setReloadData(true)
                var ev = new Event('click', {bubbles: true})
                buttonEl.current.dispatchEvent(ev)
              } else {
                setSubmitError(t('Some error occured. Please try again !'))
                // setMessage(response.errorDescription)
                // failRef.current.click()
              }
            })
            .catch((e) => {
              setLoading(false)
              setSubmitting(false)
              setStatus(t('Invalid Data Provided'))
            })
        } else {
          // for FMSM
          saveAccessDataFMSM(
            accessData.propertyId,
            accessData.userID,
            allotedAccessId,
            values.signature,
            1,
            accessData.id,
            1,
            values.card_number,
            cardTitle === 'Parking Access Card' ? 'parking_access_card' : 'building_access_card'
          )
            .then((response: any) => {
              setLoading(false)
              if (response.errorCode === 1) {
                setSubmitSuccessError(response.errorDescription)
                setTimeout(() => {
                  setSubmitSuccessError('')
                }, 5000)
              } else if (response.errorCode === 0) {
                // clearForm()
                // setReloadData(true)
                setLoading(false)
                var ev = new Event('click', {bubbles: true})
                buttonEl.current.dispatchEvent(ev)
              } else {
                setSubmitError(t('Some error occured. Please try again !'))
                // setMessage(response.errorDescription)
                // failRef.current.click()
              }
            })
            .catch((e) => {
              setLoading(false)
              setSubmitting(false)
              setStatus(t('Invalid Data Provided'))
              setSubmitSuccessError(t('Some error occured. Please try again !'))
              setTimeout(() => {
                setSubmitSuccessError('')
              }, 5000)
            })
        }
      }, 1000)
    },
  })

  const handleValueChange = (e: any, index: any) => {}

  const sigCanvas = useRef<any>()

  function clearSignature() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
    // formik.setFieldTouched('signature');
    setIsSign(false)
  }

  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
    setIsSign(true)
  }

  const clearForm = () => {
    formik.resetForm()
    formik.values.card_number?.map((card: any, index: any) =>
      formik.setFieldValue(`card_number[${index}]`, '')
    )
    formik.setFieldValue('card_number', [])
    clearSignature()
  }
  // useEffect(() => {
  //   if (defaultCount) {
  //     if (user_role === 'FCSS') {
  //       const inputs = new Array(defaultCount).fill(0).map((item, index) => (
  //         <input
  //           type='text'
  //           className='form-control fw-bolder form-control-solid expected-rent-input'
  //           // name={`card_number[${index}]`}
  //           // onChange={formik.handleChange}
  //           // value={formik.values.card_number?.[index]}
  //           value={cardDetails?.[index]}
  //           autoComplete='off'
  //           required
  //           maxLength={25}
  //           disabled
  //         />
  //       ))
  //       setCardInputs(inputs)
  //     }
  //     // else if (user_role !== 'FCSS' && (!cardSignature || cardSignature === '')) {
  //     //   // formik.setFieldValue('card_number', cardDetails)
  //     //   const inputs = new Array(defaultCount).fill(0).map((item, index) => (
  //     //     <input
  //     //       type='text'
  //     //       className='form-control fw-bolder form-control-solid expected-rent-input'
  //     //       name={`card_number[${index}]`}
  //     //       onChange={formik.handleChange}
  //     //       value={formik.values.card_number?.[index]}
  //     //       // value={cardDetails?.[index]}
  //     //       autoComplete='off'
  //     //       required
  //     //       maxLength={25}
  //     //     />
  //     //   ))
  //     //   setCardInputs(inputs)
  //     // }
  //     else {
  //       const inputs = new Array(defaultCount).fill(0).map((item, index) => (
  //         <input
  //           type='text'
  //           className='form-control fw-bolder form-control-solid expected-rent-input'
  //           name={`card_number[${index}]`}
  //           onChange={(e)=> handleValueChange(e,index)}
  //           value={formik.values.card_number?.[index]}
  //           // value={cardDetails?.[index]}
  //           autoComplete='off'
  //           required
  //           maxLength={25}
  //         />
  //       ))
  //       setCardInputs(inputs)
  //     }
  //   }
  // }, [])

  const handleAdd = () => {
    const newCount = inputCount
    const newInput = (
      <input
        type='text'
        className='form-control fw-bolder form-control-solid'
        name={`card_number[${newCount}]`}
        onChange={formik.handleChange}
        value={formik.values.card_number?.[newCount]}
        autoComplete='off'
        required
        maxLength={25}
      />
    )
    setCardInputs([...cardInputs, newInput])
    setInputCount(newCount + 1)
  }

  const handleRemove = () => {
    const lastIndex = inputCount - 1

    if (inputCount > defaultCount) {
      const updatedInputs = cardInputs
      updatedInputs.pop()
      setCardInputs(updatedInputs)
      setInputCount(inputCount - 1)

      let updatedValues = [...formik.values.card_number]
      updatedValues.pop()
      formik.setFieldValue('card_number', updatedValues)
    }
  }

  if (defaultCount === undefined || defaultCount === 0)
    return (
      <div className='card'>
        <div className='card-body p-7 '>
          <div className='row'>
            <NoDataFound />
          </div>
        </div>
      </div>
    )

 
  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <div className='col-md-12 col-12'>
                  <h4 className='card-title font-18 text-black mb-6'>
                    <span className='text-black'>{t(cardTitle)}</span>
                  </h4>
                  { formik.touched.card_number && formik.errors.card_number && (
                    <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                      {formik.errors.card_number}
                    </div>
                  )}
                </div>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='accessCardRequestForm'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design'>
                    {/* {formik.touched.card_number && formik.errors.card_number && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger fcss-card-number-error-msg'>
                            {formik.errors.card_number}
                          </span>
                        </div>
                      </div>
                    )} */}

                    {user_role === 'FCSS' || cardSignature?.length > 0 ? (
                      <>
                        {new Array(defaultCount).fill(0).map((value: any, index: any) => {
                          return (
                            <div key={index} className='col-sm-6 col-md-4 col-12 label_close'>
                              <div className='mb-5'>
                                <label className='form-label fw-bolder'>
                                  {' '}
                                  {t("Card Number")} {index + 1}{' '}
                                </label>
                                <div className='d-flex'>
                                  <input
                                    type='text'
                                    className='form-control fw-bolder form-control-solid expected-rent-input'
                                    value={cardDetails?.[index]}
                                    autoComplete='off'
                                    required
                                    maxLength={25}
                                    disabled
                                  />
                                  {index === inputCount - 1 && index > defaultCount - 1 && (
                                    <button
                                      type='button'
                                      className='bg-white cancel-bts px-7'
                                      onClick={handleRemove}
                                    >
                                      <i className='bi bi-x-circle font-18 text-black'></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        {formik.values.card_number.map((value: any, index: any) => {
                          // console.log(formik.values.card_number[index],value)

                          return (
                            <div key={index} className='col-sm-6 col-md-4 col-12 label_close'>
                              <div className='mb-5'>
                                <label className='form-label fw-bolder'>
                                  {' '}
                                  {t("Card Number")} {index + 1}
                                  { index === 0 && <span className="text-danger"> * </span> }
                                </label>
                                <div className='d-flex'>
                                  <input
                                    type='text'
                                    className='form-control fw-bolder form-control-solid expected-rent-input'
                                    name={`card_number[${index}]`}
                                    // onChange={(e)=> handleValueChange(e,index)}
                                    onChange={formik.handleChange}
                                    value={formik.values.card_number[index]}
                                    autoComplete='off'
                                    required
                                    maxLength={25}
                                    placeholder={t('Enter card number')}
                                  />
                                  {/* {formik.touched.card_number && formik.errors.card_number ? (
                          <small className='text-danger'>* {formik.errors.card_number}</small>
                        ) : null} */}

                                  {index === inputCount - 1 && index > defaultCount - 1 && (
                                    <button
                                      type='button'
                                      className='bg-white cancel-bts px-7'
                                      onClick={handleRemove}
                                    >
                                      <i className='bi bi-x-circle font-18 text-black'></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}
                    {/* {
                        (count > inputCount) &&
                        <div className="col-sm-6 col-md-4 col-12">
                          <div className="mb-6">
                            <label className="form-label fw-bolder d-block">&nbsp;</label>
                            <button type="button" className='btn btn-primary fw-bold px-10 py-3'
                              onClick={handleAdd}
                            >
                              <span className='indicator-label'>Add More</span>
                            </button>
                          </div>
                        </div>
                      } */}
                    <div className='col-md-12 col-12'>
                      {
                        !cardSignature?.length ? (
                          <div className='row'>
                            <div className='col-sm-8 col-md-4 col-12'>
                              <div className='mb-md-0 mb-4'>
                                <label className='form-label fw-bolder'>
                                  {user_role === 'FCSS' ? (t('Customer Signature')) : (t('FCSS Signature'))}
                                </label>
                                <div className='w-100 form-control'>
                                  <SignatureCanvas
                                    ref={sigCanvas}
                                    penColor='black'
                                    canvasProps={{height: 80, width: 320, className: 'sigCanvas'}}
                                    onEnd={handleSignature}
                                  />
                                </div>
                                {formik.touched.signature && formik.errors.signature && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>
                                        {formik.errors.signature}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-sm-4 col-md-4 my-auto col-12'>
                              <div className='mt-0 mt-md-3 mb-4 mb-md-0'>
                                <button
                                  type='button'
                                  className='btn btn-light fw-bold px-10 py-2'
                                  onClick={clearSignature}
                                >
                                  {t("Clear")}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className='row'>
                              <div className='col-sm-6 col-md-4 col-12'>
                                <div className='mb-6'>
                                  <label className='form-label fw-bolder d-block'>
                                    {user_role === 'FCSS' ? (t('Customer Signature')) : (t('FCSS Signature'))}
                                  </label>
                                  <span className='sign-box1'>
                                    <img src={cardSignature} className='img-fluid' />
                                  </span>
                                </div>
                              </div>
                              {cardSignature2 && cardSignature2 !== '' && (
                                <div className='col-sm-6 col-md-4 col-12'>
                                  <div className='mb-6'>
                                    <label className='form-label fw-bolder d-block'>
                                      {t("Customer Signature")}
                                    </label>
                                    <span className='sign-box1'>
                                      <img src={cardSignature2} className='img-fluid' />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )

                        // <label className='form-label fw-bolder'>Signature
                        //   <img src={cardSignature} className='w-20 h-50' />
                        // </label>
                      }
                    </div>
                    {!cardSignature?.length && (
                      <div className='col-12 text-end'>
                        <Link to={'/access-cards/open'} className=''>
                          <button
                            type='button'
                            className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                          >
                            {t("Cancel")}
                          </button>
                        </Link>
                        <button
                          type='submit'
                          className='btn btn-primary fw-bold px-10 py-3'
                          disabled={loading}
                        >
                          {!loading && <span className='indicator-label'>{t("Save")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {submitError && (
        <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
          {submitError}
        </div>
      )}
      <p
        ref={buttonEl}
        data-bs-target='#req-submitted-popup2'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='invisible d-none btn btn-primary fw-bold px-10 py-3'
      />
      {/* {console.log(formik.values.signature !=="" , 'formik.values.signature.length')} */}
      {formik.values.signature !=="" ? (
        <CustomRequestSuccessfulPopup
        successMessage={t('Saved Successfully!')}
        href={'/access-cards/closed'}
        close={false}
        customID={'2'}
      />
        ) : (
          <CustomRequestSuccessfulPopup successMessage={t('Saved Successfully!')} customID={'2'} />
      )}
    </>
  )
}
