import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
// import WorkOrdersIcon from "../../../../assets/images/work-order-quick-link.png";
import ApprovalIcon from "../../../../assets/images/aproval-quick-link.svg";
import PropertyIcon from "../../../../assets/images/home-icon.png";
import MyTeamIcon from "../../../../assets/images/my-team-quick-link.svg";
import Merchants from "../../../../assets/images/Merchants.svg";
import MarketIcon from "../../../../assets/images/marketplace-quick-link.svg";
import Promotions from "../../../../assets/images/Promotions.svg";
import ReportsActive from "../../../../assets/images/Reports1.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../constants/generics/userCheck';


export function QuickLinks() {
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow:4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow:3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };
    const state: any = useSelector(state => state);
    const userRoleCode = getUserRole(state?.auth?.user?.data);
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h4 className="card-title"> {t("Quick Links")} </h4>
                                </div>
                            </div>
                            <div className="quick-link-sec">
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <Slider {...settings}>
                                                
                                                {/* <div>
                                                    <Link to={'/property/list'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={PropertyIcon} alt="properties" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Properties")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div> */}
                                                <div>
                                                    <Link to={'/promotions/list/upcoming/events'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Promotions} alt="promotions" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Promotions")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/mta-approvals/list/pending'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={ApprovalIcon} alt="approval" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Approvals")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>                                                
                                                <div>
                                                    <Link to={'/market-place/list/events/all'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={MarketIcon} alt="market" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Market Place")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>    
                                                {userRoleCode === 'MA' ?
                                                    <div>
                                                        <Link to={'/mta-myteam/list'} className="text-decoration-none">
                                                            <div className="card mt-4">
                                                                <div className="card-body py-7 px-7">
                                                                    <div className="row g-0 align-items-center">
                                                                        <div className="col-4">
                                                                            <img src={MyTeamIcon} alt="team" className="img-fluid" />
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <h6 className="card-title mb-0"> {t("My Team")} </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Link to={'/merchant/list'} className="text-decoration-none">
                                                            <div className="card mt-4">
                                                                <div className="card-body py-7 px-7">
                                                                    <div className="row g-0 align-items-center">
                                                                        <div className="col-4">
                                                                            <img src={Merchants} alt="team" className="img-fluid" />
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <h6 className="card-title mb-0"> {t("Merchants")} </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                } 
                                                <div>
                                                    <Link to={'/mta-report'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={ReportsActive} alt="market" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Report")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>                                             
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}