import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {RecentRequests} from './components/RecentRequests/RecentRequests'
import {WorkOrders} from './components/WorkOrders/WorkOrders'
import {UpcomingWorkOrders} from './components/UpcomingWorkOrders/UpcomingWorkOrders'
import {Announcements} from './components/Announcements/Announcements'

import { useSelector } from 'react-redux';
import { getFcsWorkOrders } from '../../utils/makeRequest';
import { IState } from '../../utils/interface';
import Loader from '../../components/Loader';
import { RootState } from '../../constants'
import { getWorkOrdersList } from '../../constants/axios/apis'


const FCDashboardPage: FC = () => {
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    // const state:any = useSelector(state => state);
    // const userToken = state?.auth?.accessToken;
    // if(userToken === "undefined"){
    //     // token undefined
    // }
    
    const [fcsWorkOrderList, setFcsWorkOrderList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    
    const [noncompletedWorkOrders,setNoncompletedWorkOrders] = useState<any>([]);
    const [openWorkOrders,setOpenWorkOrders] = useState<any>([]);

    // useEffect(()=>{
    //     getFcsWorkOrders(setFcsWorkOrderList,userToken);
    // },[]);

    useEffect(()=>{
        setFcsWorkOrderList({ loading: true, response: [], error: '' })
        const payload = currentBuilding ? "?building_id=" + currentBuilding : "?building_id=0"
        getWorkOrdersList(payload).then(({ data }) => {
            setFcsWorkOrderList({
                loading: false,
                response: (data && Array.isArray(data)) ? data : [],
                error: ''
            })
        }).catch((e)=>{setFcsWorkOrderList({
            loading: false,
            response: [],
            error: 'Some error occured'
        })})
    }, [currentBuilding])
    
    useEffect(()=>{
        if(Array.isArray(fcsWorkOrderList.response) && fcsWorkOrderList.response.length > 0){
            const noncompletedWorkOrderVal =  fcsWorkOrderList.response.filter((workOrder: any, index: number)=>{
                return ["Open", "Inprogress", "On hold", "Confirmed"].includes(workOrder.status);
                // return workOrder.status !== "Completed" && workOrder.status !== "Under Observation";
            }).slice(0,15);
            const openWorkOrderVal =  fcsWorkOrderList.response.filter((workOrder: any, index: number)=>{
                return workOrder.status === "Open";
            }).slice(0,15);
            
            setNoncompletedWorkOrders(noncompletedWorkOrderVal);
            setOpenWorkOrders(openWorkOrderVal);
        }else{
            setNoncompletedWorkOrders([])
            setOpenWorkOrders([])
        }
    },[fcsWorkOrderList]);
    
    return (
        <>
        <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <RecentRequests 
                    fcsWorkOrderList={fcsWorkOrderList} 
                    noncompletedWorkOrders={noncompletedWorkOrders}
                />
                <WorkOrders />
                <UpcomingWorkOrders 
                    fcsWorkOrderList={fcsWorkOrderList} 
                    noncompletedWorkOrders={openWorkOrders}
                />
                <Announcements />
            </div>
        </div>
        </>
    );
}

const FCDashboardWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FCDashboardPage />
        </>
    )
};

export {FCDashboardWrapper}
