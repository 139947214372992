import React, { useState, useEffect, useRef } from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom'
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import NoteIcon from "../../../../../assets/images/note-icon.png";
import CameraIcon from "../../../../../assets/images/camera-icon.png";
import Loader from '../../../../../components/Loader';

import SignatureCanvas from 'react-signature-canvas'
import useRequestGet from '../../../../../hooks/useRequestGet'
import { validateRequired,
    validateAlphabets,
    validateAlphaNumeric,
    validateNumbers,
    validateLength,
    validateEmail,
    validateMediaRequired,
    validateMediaSize,
    validateImageType,
    removeItem } from '../../../../../utils/validtionFunction';
import { postWorkOrderChecklist } from '../../../../../constants/axios/apis';
import { useTranslation } from 'react-i18next';
import { validateAndCompressImage } from '../../../../../constants/generics/fileUploadHandler';


export function CheckList({setPopupSuccessMsg}:any) {
    const { t } = useTranslation();
    const history = useHistory();

    let params: any = {};
    params = useLocation(); 
    const { workOrder } = params.state;
    const [workOrderDetails, setWorkOrderDetails] = useState(workOrder);
    const [signatureValue, setSignatureValue] = useState<any>()
    
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const [submitSuccessMsg, setSubmitSuccessMsg] = useState('')
    const sigCanvas = useRef<any>();
    const sigCanvasEmpty = useRef<any>();
    const successRef:any = useRef(null);
    const [questionAnswer, setQuestionAnswer] = useState<any>([]);
        
    const [noteField, setNoteField] = useState("");
    const [noteFieldError, setNoteFieldError] = useState<string[]>(['']);
    const [qaError, setQaError] = useState<string[]>(['']);
    const [sigError, setSigError] = useState(false)

    const [workImages, setWorkImages] = useState([])
    const [workImagesError, setWorkImagesError] = useState('')
    //{fileUrl, fileName, inputFile, imageError}
    const inputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState([] as any);

    const {requestState:checklistQuestions, refreshRequest} = useRequestGet("/facility/work/order/checklist/questions?service_category_type="+ workOrderDetails?.subCategoryId);
    const {loading, response: allCheckList, error} = checklistQuestions;

    // const {requestState:checklistQuestionsPost, setRequestParams} = useRequestPost("/facility/work/order/checklist/damage/create", true, successPopup);
    // const {
    //     loading: loadingPost, 
    //     response: postResponse, 
    //     error: errorPost } = checklistQuestionsPost;

    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
            setSignatureValue(blob)
        }, 'image/png')
    }
    function clear() {
        sigCanvas.current.clear();
        setSignatureValue(undefined)
    }

    const handleUpload = () => {
        inputRef.current?.click();
    };
    function deleteFile(e:any) {
        setWorkImagesError('')
        const s = file.filter((item:any, index:any) => index !== e);
        const i = workImages.filter((item:any, index:any) => index !== e);
        setFile(s);
        setWorkImages(i)
        if(inputRef.current)
            inputRef.current.value = "";
    }

    function validateImageUpload(imageFile:any){
        if (typeof imageFile !== 'undefined') {
            // if (imageFile.size >= 2101546) {
            //     return t('Photo file size should not be more than 2MB')
            // }
            // if ( !(['image/png', 'image/jpeg', 'image/jpg'].includes(imageFile.type)) ) {
            //     return t('Only the following formats are acceptable: .jpeg, .jpg, .png')
            // }
            if(workImages.length >= 10)
                return t('Max 10 Photos allowed')
        }else {
              return t('Photo is required')
        }
        return ''
    }
    const [uploading, setUploading] = useState(false)
    const handleWorkPhoneUpload = (e:any) => {
        setWorkImagesError('')
        setUploading(true)
        if(inputRef.current?.files){
            let newImage = inputRef.current.files[0]
            const imageError = validateImageUpload(newImage)
            
            let isExisting = false
            workImages.forEach((item:any)=>{
                if(item.name === newImage.name)
                    isExisting = true
            })

            if(imageError === ''){
                if(isExisting){
                    setWorkImagesError(t('Photo already exist'))
                }else{
                    setUploading(true)
                    try {
                        validateAndCompressImage(newImage).then((compressResponse) => {
                          if (compressResponse?.success) {
                            let imageList: any = [...workImages]
                            imageList.push(compressResponse.file)
                            setWorkImages( imageList )
            
                            const newUrl = URL.createObjectURL(compressResponse.file)
                            const urlList = [...file]
                            urlList.push(newUrl)
                            setFile(urlList);
                          } else {
                            setWorkImagesError(t(compressResponse?.message || 'File compression error!'))
                          }
                          setUploading(false)
                        })
                      } catch (error) {
                        setWorkImagesError(t('File upload error!'))
                        setUploading(false)
                      }
                    // let imageList: any = [...workImages]
                    // imageList.push(newImage)
                    // setWorkImages( imageList )
    
                    // const newUrl = URL.createObjectURL(e.target.files[0])
                    // const urlList = [...file]
                    // urlList.push(newUrl)
                    // setFile(urlList);
                }
            }else{
                setWorkImagesError(imageError) 
            }
        }
    };

    const questionHandle = (data: any) => {
        setQuestionAnswer((preVal: any)=>{
            if(preVal.length === 0){
                return [data]
            }
            let temp = [...preVal];

            for (var index = 0; index < temp.length; index++) {
                var qa = temp[index]
                if(data.questionId === qa.questionId){
                    temp[index] = data;
                    return temp;
                }
            }

            return [...temp, data];
        })
    }

    const onChangeNote = (e: any) => {
        setNoteField(e.target.value);

        setNoteFieldError([]);
        validateRequired(e.target.value, setNoteFieldError, 'Note');
        // validateNote(e.target.value, setNoteFieldError, 'Note');
        validateLength(e.target.value, setNoteFieldError, 'Note', 250);
    }

    const onSubmit = (flag="1") => {
        setNoteFieldError([]);
        validateRequired(noteField, setNoteFieldError, 'Note');
        // validateNote(noteField, setNoteFieldError, 'Note');
        validateLength(noteField, setNoteFieldError, 'Note', 250);
        
        const imageError = workImages.length === 0
        if(imageError)
            setWorkImagesError(t(`Photo is required`))

        const signURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        const emptySign = sigCanvasEmpty.current.getTrimmedCanvas().toDataURL("image/png") 
        
        if(Object.keys(allCheckList).length === questionAnswer.length){
            if (signURL === emptySign || !signatureValue) {
                setSigError(true)
            }
            else {
                setSigError(false)
                if( noteFieldError.length == 0 && !imageError){
    
                    const formData = new FormData();
            
                    formData.append("work_order_id", workOrder.id);
                    formData.append("comment", noteField);
                    formData.append("flag", flag);
                    // sigCanvas.current?.files &&
                    formData.append("customer_signature", signatureValue, 'signature.png');
                    // inputRef.current?.files &&
                    // formData.append("customer_signature", inputRef.current?.files[0]);
                    
                    inputRef.current?.files &&
                    // formData.append("damage_images", inputRef.current?.files[0]);
                    workImages.forEach((image:any, index: number)=>{
                        formData.append("damage_images[]", image);
                    })
                    
                    questionAnswer.forEach((qa:any, index: number)=>{
                        formData.append(`questions[${index}][question_id]`, qa.questionId);
                        formData.append(`questions[${index}][is_damaged]`, qa.is_damaged);
                    })
    
                    setSubmitError('')
                    setIsSubmitting(true)
                    setTimeout(() => {
                        postWorkOrderChecklist(formData)
                            .then((response: any) => {
                                setIsSubmitting(false)
                                if (response.errorCode === 1) {
                                    setSubmitError(response.errorDescription)
                                    setTimeout(() => {
                                        setSubmitError('')
                                    }, 10000)
                                } else if(response.errorCode === 0) {
                                    setPopupSuccessMsg(response.description)
                                    setSubmitSuccessMsg(response.description)
                                    successRef.current.click();
                                    setTimeout(() => {
                                        history.push("/facility-workorder/list/corrective/completed")
                                        // window.location.replace("/fc-workorder/corrective/completed");
                                        // window.location.replace("/facility-workorder/list/corrective/completed");
                                    }, 500)
                                } else{
                                    setSubmitError(t("Some error occured!"))
                                    setTimeout(() => {
                                        setSubmitError('')
                                    }, 10000)
                                }
                            })
                            .catch((e) => {
                                // console.log(e, 'Error');
                                setIsSubmitting(false)
                                setSubmitError(t('Some error occured!'))
                                setTimeout(() => {
                                    setSubmitError('')
                                }, 5000)
                            })
                    }, 1000)

                    // setRequestParams(formData);
    
                }
            }
    
        }else{
            setSigError(signURL === emptySign)
            setQaError([(t("Fill the check list"))]);
        }
    }

    

    return (
        <div className="com-card-section myunit-page">
            <div className="row flex-stack announcement-sec mb-6">
                <div className="col-md-12 col-12">
                    <div className="row g-0">
                        <div className="col-auto">
                            <span onClick={history.goBack} className='c-pointer'>
                                    <img src={LeftArrow} alt='image' className="img-fluid me-5 left-arrow-back" />
                            </span>
                            {/* <Link className='me-5' to={{'pathname':'/fc-workorder/work-order-details', state: {'workOrder': workOrderDetails}}}>
                                <img src={LeftArrow} alt="Left Arrow" className="img-fluid" />
                            </Link> */}
                        </div>
                        <div className="col">
                            <h4 className="card-title font-18 text-black">
                                <span className=''>
                                    {workOrderDetails.subCategory}
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-6 text-end">
                    <button className="rounded-2 bg-white text-primary btn btn-sm px-4 py-2 border-0 fw-bold font-16 on-hold-btn"
                        data-bs-toggle="modal" data-bs-target="#onHoldPopUp" type="button">
                        <img src={OnHold} alt="Hold" className="img-fluid me-3" />ON HOLD
                    </button>
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-5 mb-md-0 mb-5">
                    <div className="card mb-7 h-100">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="text-black font-18 fw-normal mb-6">{t("Checklist")} <span className="text-danger"> * </span></h4>
                                </div>
                                <div className="col-md-12 check-list-scroll">
                                    {loading && (
                                        <Loader />
                                    )}
                                    {error && (
                                        <div className="alert alert-danger w-100 mt-2" role="alert">
                                            {error}
                                        </div>
                                    )}
                                    {Object.keys(allCheckList).length !== 0 && 
                                        allCheckList.map((checkList: any, index: number)=>{
                                            return (
                                                <div className="checklist-box active" key={index}>
                                                    <h4 className="question-checklist">{index+1}. {checkList.question}</h4>
                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                        <input 
                                                            type="radio"
                                                            className="btn-check"
                                                            id={`btnradio${index*3+1}`}
                                                            name={`btnradio-${index}`}
                                                        />
                                                        <label 
                                                            onClick={()=>{
                                                                questionHandle({
                                                                    questionId: checkList.id,
                                                                    is_damaged: 1
                                                                })
                                                            }}
                                                            className="btn btn-outline-primary btn-pass" 
                                                            htmlFor={`btnradio${index*3+1}`}>Pass</label>

                                                        <input 
                                                            type="radio"
                                                            className="btn-check"
                                                            id={`btnradio${index*3+2}`}
                                                            name={`btnradio-${index}`}
                                                        />
                                                        <label 
                                                            onClick={()=>{
                                                                questionHandle({
                                                                    questionId: checkList.id,
                                                                    is_damaged: 0
                                                                })
                                                            }}
                                                            className="btn btn-outline-primary btn-fail"
                                                            htmlFor={`btnradio${index*3+2}`}>Fail</label>

                                                        <input 
                                                            type="radio"
                                                            className="btn-check"
                                                            id={`btnradio${index*3+3}`}
                                                            name={`btnradio-${index}`}
                                                        />
                                                        <label 
                                                            onClick={()=>{
                                                                questionHandle({
                                                                    questionId: checkList.id,
                                                                    is_damaged: 2
                                                                })
                                                            }} 
                                                            className="btn btn-outline-primary btn-na" 
                                                            htmlFor={`btnradio${index*3+3}`}>NA</label>
                                                    </div>
                                                    {/* <div className="checklist-action-items">
                                                        <span className="me-5 c-pointer"><img src={NoteIcon} alt="Note" className="me-2 img-fluid" />Note</span>
                                                        <span className="me-5 c-pointer"><img src={CameraIcon} alt="Camera" className="me-2 img-fluid" />Photo</span>
                                                        <span className="me-5 c-pointer"><img src={ActionIcon} alt="Action" className="me-2 img-fluid" />Action</span>
                                                    </div> */}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-md-12">
                                    <div className='d-flex flex-column text-danger'>
                                        {qaError.map((error,index)=>{
                                            return (
                                                <small className="text-danger" key={index}>{t(error)}</small>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-md-12 mt-4">
                                    <h4 className="text-black font-18 fw-boldest mt-4 mb-6">{t("Customer Signature")} <span className="text-danger"> * </span></h4>
                                    <div className="row form-add-design">
                                        <div className="col-md-12 col-12">
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <div className="mb-0">
                                                        <div className="w-100 form-control">
                                                            <SignatureCanvas
                                                                ref={sigCanvas}
                                                                penColor='black'
                                                                canvasProps={{height: 60, width: 320, className: 'sigCanvas'}}
                                                                onEnd={handleSignature}
                                                            />
                                                        </div>
                                                        <div className="d-none">
                                                            <SignatureCanvas    //Only to compare and check empty 
                                                                ref={sigCanvasEmpty}
                                                                penColor='black'
                                                                canvasProps={{height: 60, width: 320, className: 'sigCanvas'}}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {sigError &&
                                                    <div className="col-md-12">
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{t("Signature is required")}</small>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-12 text-end col-12">
                                                    <div className="mt-4">
                                                        <button className='btn btn-light fw-bold px-10 py-2' onClick={clear}>
                                                            {t("Clear")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="card mb-7 h-100">
                        <div className="card-body p-7">
                            <div className="row form-add-design">

                                <div className="col-md-12 col-12 mb-7">
                                    <div className="">
                                        <label className="form-label fw-bolder mb-6"><img src={NoteIcon} alt="Note" className="me-3 img-fluid" /> {t("Note")} <span className="text-danger"> * </span></label>
                                        <textarea onChange={onChangeNote} className="form-control fw-bolder form-control-solid" placeholder="Type your notes" rows={4}></textarea>
                                    </div>
                                    <div className='d-flex flex-column text-danger'>
                                        {noteFieldError.map((error,index)=>{
                                            return (
                                                <small className="text-danger" key={index}>{t(error)}</small>
                                            )
                                        })}
                                    </div>
                                </div>
    
                                <div className="col-md-12 col-12 mb-7">
                                    <div className="">
                                        <div className="upload-input">
                                            <label className="form-label fw-bolder mb-6"><img src={CameraIcon} alt="camera-icon" className="me-3 img-fluid" /> {t("Photo")} <span className="text-danger"> * </span></label>
                                            <input ref={inputRef}  onChange={handleWorkPhoneUpload} type="file" className="d-none" accept=".png,.jpg,.jpeg" /* multiple *//>
                                            <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-primary`}  disabled={uploading}>
                                                {uploading ?
                                                    <span className='indicator-progress d-block'>{t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>
                                                    : 
                                                    <span>{workImages?.length > 0 ? t("Upload More") : t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column text-danger'>
                                        {/* {workPhotoError.map((error,index)=>{
                                            return (
                                                <small className="text-danger" key={index}>{error}</small>
                                            )
                                        })} */}
                                        {workImagesError !== '' && 
                                            <small className="text-danger">{workImagesError}</small>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    {file.length > 0 &&
                                    <div className="mt-10 position-relative">
                                        <label className="form-label fw-bolder d-block">{workImages?.length > 1 ? t("Attached Images") : t("Attached Image")}</label>
                                        {file.map((item:any, index:any) => {
                                            return (
                                                <div className="upload-image" key={item}>
                                                    <img src={item} alt="" />
                                                    <div className="close-icon" onClick={() => deleteFile(index)}>
                                                        &times;
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row mt-10">
                <div className="col-md-12 text-end">
                    {isSubmitting && (
                        <Loader />
                    )}
                    {(!isSubmitting && submitSuccessMsg === '') && (
                        <div className="btn-sections">
                            <button type="button" disabled={uploading} onClick={()=>{
                                onSubmit("0")
                            }} className="btn btn-outline-primary px-5 py-3 me-3 mb-sm-0 mb-3">{t("Under Observation")}</button>
                            <button
                                type="button"
                                disabled={uploading}
                                onClick={()=>{
                                    onSubmit("1")
                                }}
                                className="btn btn-primary px-5 py-3 mb-sm-0 mb-3">{t("Complete")}</button>
                        </div>
                    )}
                    {submitError !== '' && (
                        <div 
                            // style={{width: 'fit-content'}}
                            className="alert alert-danger mt-2 text-center" role="alert">
                            {submitError}
                        </div>
                    )}
                </div>
            </div>
            <p
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='invisible d-none'
                />
        </div>
    );
}