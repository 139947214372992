import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import TableData from './TableData'
import { useTranslation } from 'react-i18next';
import { getApprovals } from '../../../../constants/axios/apis';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import { formatDateTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';

export function Rejected() {
    const { t } = useTranslation()
    const pageSize = 10
    const [approvalList, setApprovalList] = useState([]);
    const [loading, setLoading] = useState<any>(true)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [totalCount, setTotalCount] = useState<any>(0)

    useEffect(() => {
        const newPayload = `?currentPage=${currentPage}&pageSize=${pageSize}&date_sort=1&type=5&status=rejected`
        getApprovals(newPayload).then((response: any) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                setApprovalList(response.data)
                setTotalCount(response.dataCount)
            } else {
                setApprovalList([])
                setTotalCount(0)
            }
            setLoading(false)
        }).catch((e) => setLoading(false))
    }, [currentPage])

    if (!loading && (approvalList === undefined || approvalList?.length === 0)) {
        return <NoDataFound1 />
    }
    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body px-4 py-4'>
                            <div className='rec-payment-act-sec'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='table-responsive'>
                                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th className=''> {t('No.')} </th>
                                                        <th className=''> {t('Event Name')}</th>
                                                        <th className=''> {t('Event Start Date/Time')} </th>
                                                        <th className=''> {t('Event End Date/Time')} </th>
                                                        {/* <th className=''> {t('Time')}</th> */}
                                                        <th className=''> {t('Action')} </th>
                                                    </tr>
                                                </thead>
                                                {loading ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <CustomLoader2 />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                        <tbody>
                                                            { approvalList?.map((data: any, index: any) => (
                                                                    <tr key={`${data.id}${index}`}>
                                                                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                                        <td>{data?.other_data_json?.event_name ? data.other_data_json.event_name : '-'}</td>
                                                                        <td>{data?.other_data_json?.event_start_date ? formatDateTimeMeridiem(data?.other_data_json?.event_start_date + ' ' + data?.other_data_json?.event_start_time): '-'}</td>
                                                                        <td>{data?.other_data_json?.event_end_date ? formatDateTimeMeridiem(data?.other_data_json?.event_end_date + ' ' + data?.other_data_json?.event_end_time): '-'}</td>
                                                                        <td>
                                                                            <Link
                                                                                to={{ pathname: '/mta-approvals/view', state: { 'approvalData': data } }}
                                                                                className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
                                                                            >
                                                                                <span className='view-option text-theme fw-bolder'>{t('View')}</span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                )}
                                            </table>
                                            <CompactPagination
                                                pageSize={pageSize}
                                                totalCount={parseInt(totalCount)}
                                                defaultPage={currentPage}
                                                onPageChange={setCurrentPage}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}