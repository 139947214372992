import React, { FC, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { LeadsTabs } from './components/LeadsTabs/LeadsTabs';
import { CheckBoxPopup } from '../pm-unrenewed-leases/components/CheckBoxPopup/CheckBoxPopup';
import { NewLead } from './components/NewLead/NewLead';
import { Qualified } from './components/Qualified/Qualified';
import { Rejected } from './components/Rejected/Rejected';
import { LeadsDetails } from './components/LeadsDetails/LeadsDetails';
import { MakeLeadOfferPage } from './components/MakeLeadOffer/MakeLeadOfferPage';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';

const PMLeadsPage: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return(
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/pm-leads/new-lead'>
                            <LeadsTabs />
                            <NewLead />
                            <CheckBoxPopup />
                        </Route>
                        <Route path='/pm-leads/qualified'>
                            <LeadsTabs />
                            <Qualified />
                            <CheckBoxPopup />
                        </Route>
                        <Route path='/pm-leads/rejected'>
                            <LeadsTabs />
                            <Rejected />
                            <CheckBoxPopup />
                        </Route>
                        <Route path='/pm-leads/leads-details'>
                            <LeadsDetails setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/pm-leads/leads-offer'>
                            <MakeLeadOfferPage setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                    </Switch>
                </div>
            </div>
            <CustomRequestSuccessfulPopup 
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    );
}

const PMLeadsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMLeadsPage />
        </>
    )
};

export { PMLeadsWrapper }
