import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import { ImageViewerWithPopup } from '../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup'
import { formatDate } from '../../../../constants/generics/dateTimeFormater'
import { currencyFormater } from '../../../../constants/generics/customActios'

export function ChequeDelay({serviceDetail}:any) {
    const {t} = useTranslation()
    let chequeImg =''
    if(serviceDetail?.chequeDetails?.cheque_img && serviceDetail?.chequeDetails?.cheque_img!=='')
        chequeImg = serviceDetail?.chequeDetails?.cheque_img
    return (
        <>
            <div className="row">

                <div className='col-12'>
                    <div className='card mb-7 serv-data'>
                        <div className='card-body px-8 py-6'>
                            <div className='row mb-7'>
                                <div className='col-sm-6 card-title font-16 text-black'>
                                    <span className='text-black'>{t("Cheque Details")}</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Cheque Number")}</h6>
                                    <span className='text-black fw-bold font-15'>
                                        {serviceDetail?.chequeDetails?.cheque_number ? serviceDetail?.chequeDetails?.cheque_number : '-'}
                                    </span>
                                </div>
                            <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Account Number")} </h6>
                                <span className='text-black fw-bold font-15'>
                                    {serviceDetail?.chequeDetails?.acc_number ? serviceDetail?.chequeDetails?.acc_number : '-'}
                                </span>
                            </div>
                            <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                <h6 className='text-theme-gray mb-5 fw-normal font-13'>
                                {t("Account Holder Name")}
                                </h6>
                                <span className='text-black fw-bold font-15'>
                                    {serviceDetail?.chequeDetails?.acc_holder_name ? serviceDetail?.chequeDetails?.acc_holder_name : '-'}
                                </span>
                            </div>
                            <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                <h6 className='text-theme-gray mb-5 fw-normal font-13'>{t("Bank")} </h6>
                                <span className='text-black fw-bold font-15'>
                                    {serviceDetail?.chequeDetails?.bank ? serviceDetail?.chequeDetails?.bank : '-'}
                                </span>
                            </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Amount")}</h6>
                                    <span className='text-black fw-bold font-15'>
                                        {t('AED')} {serviceDetail?.chequeDetails?.amount ? currencyFormater(serviceDetail?.chequeDetails?.amount) : '-'}
                                    </span>
                                </div>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Cheque Date")} </h6>
                                    <span className='text-black fw-bold font-15'>
                                        {serviceDetail?.chequeDetails?.cheque_date ? formatDate(serviceDetail?.chequeDetails?.cheque_date) : '-'}
                                    </span>
                                </div>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Cheque Image")} </h6>
                                    <ImageViewerWithPopup imageFile={chequeImg} noImage={chequeImg === ''} imgID={'cheque'}/>
                                    {/* <img
                                        src={serviceDetail?.chequeDetails?.cheque_img ? serviceDetail?.chequeDetails?.cheque_img : '-'}
                                        className='form-img__img-preview'
                                    /> */}
                                </div>
                                {chequeImg !== '' && 
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <a href={chequeImg}
                                            target='_blank'
                                            className='btn btn-primary fw-bold px-12 py-3'
                                            download
                                        >
                                            {t('Download Cheque')}
                                        </a>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className='card mb-7 serv-data'>
                        <div className='card-body px-8 py-6'>
                            <div className='row mb-7'>
                                <div className='col-sm-6 card-title font-16 text-black'>
                                    <span className='text-black'>{t("Delay Reason")}</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 col-md-4 col-lg-4 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Type of Reason")}</h6>
                                    <span className='text-black fw-bold font-15'>
                                        {serviceDetail?.details?.reasonText ? serviceDetail?.details?.reasonText : '-'}
                                    </span>
                                </div>
                                <div className='col-sm-6 col-md-8 col-lg-8 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Description")} </h6>
                                    <span className='text-black fw-bold font-15'>
                                        {serviceDetail?.details?.description ? serviceDetail?.details?.description : '-'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}