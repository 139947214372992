import React, { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { generateAttendanceListFCS } from '../../utils/makeRequest'
import { IState } from '../../utils/interface'
import Loader from '../../components/Loader'
import { CookieComponent } from '../../setup/ts/components'
import moment from 'moment'
import { CalendarAll } from './components/CalendarAll/CalendarAll'

const CalendarPage: FC = () => {
  let currentDate = moment().format('YYYY-MM-DD')
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  if (userToken === 'undefined') {
    // token undefined
  }

  const [fcsAttendanceList, setFcsAttendanceList] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })

  useEffect(() => {
    generateAttendanceListFCS(setFcsAttendanceList, userToken, currentDate)
  }, [])

  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/my-calendar'>
              <CalendarAll />
            </Route>            
          </Switch>
        </div>
      </div>
    </>
  )
}

const PMMyCalendarWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}> Calender </PageTitle>
      <CalendarPage />
    </>
  )
}

export { PMMyCalendarWrapper }
