import React, { FC, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { SecurityDeposit } from './SecurityDeposit'
export const PaymentModal: React.FC = () => {
    return (
        <div className='modal fade notif-popup' id='payment-modal-new'>
            <div className='modal-dialog modal-dialog-scrollable'>
                <div className='modal-content rounded-10'>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-12'>
                               <h6 className="font-16 text-normal fw-bold mb-4">Your Contract Renewal Request Accept</h6>
                                <SecurityDeposit />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
