import moment from 'moment'
import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'

const offersData = [{
    "refrence": "REF-L-1627017165",
    "stats": "New",
    "firstName": "Abdul",
    "lastName": "Kadhar",
    "OfferDate": "	23/07/2021",
    "Validto": "23/07/2021",
    "subStatus": "Not yet Contacted", 
    "leadType": "Buyer",
    "BuyerType" : "End User",
    "FinanceType" : "Cash",
    "OfferPrice" : "60,000",
    "installment": "--"
    
   
},
{
    "refrence": "REF-L-1615803878",
    "stats": "Active",
    "firstName": "Brian",
    "lastName": "Ross",
    "OfferDate": "31/12/2020",
    "Validto": "23/03/2021",
    "subStatus": "Offer Made",
    "leadType": "Buyer",
    "BuyerType" : "End User",
    "FinanceType" : "Cash",
    "OfferPrice" : "444",
    "installment": "1 payment"
    
    
},
{
    "refrence": "REF-L-1616475236",
    "stats": "Active",
    "firstName": "Demo",
    "lastName": "Test",
    "OfferDate": "03/02/2020",
    "Validto": "22/04/2121",
    "leadType": "Buyer",
    "BuyerType" : "End User",
    "FinanceType" : "Cash",
    "OfferPrice" : "20,000",
    "installment": "--"
    
   
},
{
    "refrence": "REF-L-1616132103",
    "stats": "New",
    "firstName": "muhammadu",
    "lastName": "-",
    "OfferDate": "19/03/2121",
    "Validto": "19/03/2121",
    "leadType": "Buyer",
    "BuyerType" : "End User",
    "FinanceType" : "Cash",
    "OfferPrice" : "10,000",
    "installment": "--"
  
    
},
{
    "refrence": "REF-L-1626945778",
    "stats": "Wom",
    "firstName": "Rafic",
    "lastName": "hamad",
    "OfferDate": "	23/07/2121",
    "Validto": "23/07/2121",
    "leadType": "Lease",
    "BuyerType" : "End User",
    "FinanceType" : "Cash",
    "OfferPrice" : "80,000",
    "installment": "6 payment"
    
   
}
];

export function OfferListing() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col">
                    <h3 className=''>Offers</h3>
                </div>
                <div className="col-auto text-end">
                    <Link to='/opportunites/send/offer' className='btn btn-primary fw-bold px-10 py-3'>
                        Add Offers
                    </Link>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">56</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Offer Open</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">2</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Offer Close</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">15</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Expired Offer</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">2</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Accepted Offer</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Rejected Offer</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='table-crm'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='table-responsive'>
                                <table className='table mb-0 gx-4 text-nowrap table-bordered'>
                                    <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                        <tr>
                                            <th> Ref.</th>
                                            <th> Status </th>
                                            <th> First Name</th>
                                            <th> Last Name </th>
                                            <th> Offer Date </th>
                                            <th> Valid To </th>
                                            <th> Lead Type </th>
                                            <th> Buyer Type </th>
                                            <th> Finance Type </th>
                                            <th> Offer Price (AED)  </th>
                                            <th> Installment  </th>
                                            <th> Action  </th>
                                        </tr>
                                        <tr>
                                            <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char' /></th>
                                            <th> <select className="rounded border px-3 py-1">
                                                <option>Select</option>
                                                <option>Open</option>
                                                <option>Closed</option>
                                            </select>
                                            </th>
                                            <th> <input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char' /></th>
                                            <th> <input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char' /></th>
                                            <th> <input type="text" className="rounded border px-3 py-1" /></th>
                                            <th> <input type="text" className="rounded border px-3 py-1" /></th>
                                            <th>
                                                <select className="rounded border px-3 py-1">
                                                    <option>Select</option>
                                                    <option>Buyer</option>
                                                    <option>Lease</option>
                                                </select>
                                            </th>
                                            <th>
                                                <select className="rounded border px-3 py-1">
                                                    <option>Select</option>
                                                    <option>End User</option>
                                                    <option>Investor</option>
                                                </select>
                                            </th>
                                            <th>
                                                <select className="rounded border px-3 py-1">
                                                    <option>Select</option>
                                                    <option>Cash </option>
                                                    <option>Mortagage</option>
                                                </select>
                                            </th>

                                            <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char' /> </th>
                                            
                                            <th> <select className="rounded border px-3 py-1">
                                                <option>Select</option>
                                                <option>1 Paymeny</option>
                                                <option>2 Paymeny</option>
                                                <option>6 Paymeny</option>
                                                <option>12 Paymeny</option>
                                            </select>
                                            </th>
                                            <th className="border-0" style={{ background: '#dedede' }}><button className="btn btn-secondary font-12 refereshResults">Reset</button>  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {offersData.length > 0 ? offersData.map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-warning">{item.refrence}</td>
                                                <td>{item.stats}</td>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.OfferDate}</td>
                                                <td>{item.Validto}</td>
                                                <td>{item.leadType}</td>
                                                <td>{item.BuyerType}</td>
                                                <td>{item.FinanceType}</td>
                                                <td>{item.OfferPrice}</td>
                                                <td>{item.installment}</td>
                                                <td className="action-button text-end">
                                                    <div className="dropdown">
                                                        <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                            <i className="fa fa-ellipsis-v text-black"></i>
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                <li>
                                                                    <Link to={"/opportunites/offerPreview"} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-eye"></i>
                                                                        <p className="m-0">Preview</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-share"></i>
                                                                        <p className="m-0">Share</p>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr><td colSpan={3}>No data..</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='w-100 d-flex justify-content-end'>
                                <CompactPagination
                                    pageSize={pageSize}
                                    totalCount={offersData.length}
                                    defaultPage={currentPage}
                                    onPageChange={setCurrentPage}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
