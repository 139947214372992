import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RuleModal } from '../Modal/RuleModal'

export function LeadRules() {
    return (
        <>


            <div className='row align-items-center my-5'>
                <div className="col-6">
                    <h3 className=''>Lead Allocation Rules</h3>
                </div>
                <div className="col-6 text-end">
                    <Link to>
                        <button data-bs-toggle="modal" data-bs-target="#Rule-popup" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                            Add Rule
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className='row align-items-center mb-5 table-drop'>
                        <div className="col-10"></div>
                        <div className="col-2 text-end d-flex align-items-center">
                            <span className="me-2">Show</span>
                            <select className="form-control form-select">
                                <option>10 </option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                            <span className="ms-2">entries</span>
                        </div>
                    </div>
                    <div className='com-card-section'>
                        <div className='table-crm-payment'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='table-responsive'>
                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                <tr>
                                                    <th> RULE ID</th>
                                                    <th> RULE NAME </th>
                                                    <th> AGENT TYPE </th>
                                                    <th> Users</th>
                                                    <th> Created </th>
                                                </tr>
                                                <tr>
                                                    <th> </th>
                                                    <th> <input type="text" placeholder='Min 3 Char' className="rounded border px-3 py-1" /> </th>
                                                    <th> <select className="rounded border px-3 py-1">
                                                        <option>Select</option>
                                                        <option>User</option>
                                                        <option>Role</option>
                                                        <option>Group</option>
                                                    </select>
                                                    </th>
                                                    <th><input type="text" placeholder='Min 3 Char' className="rounded border px-3 py-1" /></th>
                                                    <th><input type="date" placeholder='Min 3 Char' className="rounded border px-3 py-1" /></th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr >
                                                    <td >1</td>
                                                    <td><Link data-bs-toggle="modal" data-bs-target="#Rule-popup" to className='text-warning'>Language</Link></td>
                                                    <td>user</td>
                                                    <td>["112"]</td>
                                                    <td>2021-07-29 09:44:34</td>
                                                </tr>
                                                <tr >
                                                    <td >2</td>
                                                    <td><Link data-bs-toggle="modal" data-bs-target="#Rule-popup" to className='text-warning'>Lead Assignment</Link></td>
                                                    <td>user</td>
                                                    <td>null</td>
                                                    <td>2021-07-29 09:44:38</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RuleModal />
        </>
    )
}

