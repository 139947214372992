import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const RequestTabs = ({searchItem, setSearchItem,activeButton, setActiveButton}:any) => {

    const location = useLocation();
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-4 py-2">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className='d-flex'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                    (location.pathname === '/facility-maintenance/list/open' && 'active')}
                                                    to='/facility-maintenance/list/open'>
                                                    {t("Open Requests")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/facility-maintenance/list/resolved' && 'active')}
                                                    to='/facility-maintenance/list/resolved'>
                                                    {t("Resolved Requests")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                                    <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                                        <div className='form-group has-search'>
                                            <span className='fa fa-search pt-2 form-control-feedback'></span>
                                            <input
                                                type='text'
                                                className='form-control ps-10'
                                                onChange={(e) => setSearchItem(e.target.value)}
                                                placeholder={t('Search')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {RequestTabs}