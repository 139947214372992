import Loader from "../../components/Loader";
import { CustomLoaderCompact } from "./customActios";

export const LoadingErrorAlert = ({loading, loadingError}:any) => {
    if(loading)
        return<>
            <CustomLoaderCompact />
            {/* <Loader /> */}
        </>
    else if(loadingError)
        return<>
            <div className='alert alert-danger w-100 text-center' role='alert'>
                {loadingError}
            </div>
        </>
    else
        return <></>
};

export const LoadingSubmitAlert = ({submitStatus}:any) => {
    if(!submitStatus.loading && submitStatus?.error)
        return<>
            <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
            {submitStatus?.message}
            </div>
        </>
    return <></>
};