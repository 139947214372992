import React from "react";
import { useTranslation } from 'react-i18next'
import { Button, Row, Col, Space} from 'antd';
import Map from "./Map";

const NearByLocation = () => {
    const { t } = useTranslation();


    return (
        <div className="near-by-location-main-div">
            <Row gutter={[16, 24, 30, 40]}>
                {/* <Col lg={24} md={24} xs={24} className="mb-10px">
                    <Space direction="vertical">
                        <Space wrap>
                            <Button shape="round">Restaurant</Button>
                            <Button shape="round">Gym</Button>
                            <Button shape="round">Metro</Button>
                        </Space>
                    </Space >
                </Col> */}
                <Col lg={24} md={24} xs={24}>
                    <Map />
                </Col>
            </Row>
        </div>
    );
};
export default NearByLocation;
