import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom'
import { getFacilityUserList, postSendOffer } from '../../../../constants/axios/apis';

export function SendOfferPopup({ marketPlaceDetails, setPopupSuccessMsg }: any) {
    const { t } = useTranslation();
    const history = useHistory();

    const successRef: any = useRef(null)
    const [audienceList, setaudienceList] = useState<any>([])
    const [selectedUser, setSelectedUser] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true)
    const [searchText, setSearchTech] = useState<any>('');
    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        isSubmitted: false,
        message: '',
    })

    useEffect(() => {
        if (marketPlaceDetails?.building_id)
            getFacilityUserList(`?type=residents&building_id=${marketPlaceDetails?.building_id}`).then(({ data }) => {
                if ((data && Array.isArray(data))) {
                    const alreadySentList = marketPlaceDetails?.sendUsers && Array.isArray(marketPlaceDetails.sendUsers) ? marketPlaceDetails.sendUsers : []
                    const formatedUsers = data.map((item: any) => ({
                        id: item.id,
                        name: item.first_name + ' ' + item.last_name,
                        mobile: item.mobile,
                        disabled: alreadySentList.includes(item.id)
                    }))
                    setaudienceList(formatedUsers)

                    // console.log(formatedUsers, alreadySentList, 'all');
                    
                }
                // setaudienceList((data && Array.isArray(data)) ? data : [])
                // setaudienceList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: item.id, name: item.first_name + ' ' + item.last_name})) : [])
                setLoading(false)
            }).catch((e) => setLoading(false))
    }, [marketPlaceDetails])

    // console.log(audienceList, 'audienceList');


    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setSubmitStatus((oStatus: any) => ({ ...oStatus, message: "", error: false }))
        // setSelectedUser(e.target.value);

        let userList = [...(selectedUser)]
        if (e?.target?.checked) { //add
            userList.push(e?.target?.value)
        } else {//remove
            var index = userList.indexOf(e?.target?.value);
            if (index !== -1)
                userList.splice(index, 1);
        }
        setSelectedUser(userList)
        
    }

    const handleAssign = () => {
        console.log('selectedUser',selectedUser);
        if (Object.keys(selectedUser).length === 0) {
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Please select a user!"), error: true}))
        } else {
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: true, message: '', error: false}))


            const formData = new FormData()
            formData.append("market_place_id", marketPlaceDetails?.id)
            formData.append("flag", '1') //offer
            selectedUser?.forEach((element:any, index:any) => {
                formData.append("user_id[]", element);
            });

            postSendOffer(formData)
                .then((response: any) => {
                    if (response.errorCode === 1) {
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
                    } else if(response.errorCode === 0) {
                        setPopupSuccessMsg(response.description)
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.description, isSubmitted: true}))
                        successRef.current.click()
                        setTimeout(() => {
                            history.push("/promotions/list/upcoming/offers")
                        }, 100)
                    } else{
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                    }
                })
                .catch((e) => {
                    setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                })
        }
    }

    const filteredUsers = (searchText === '') ? audienceList : audienceList.filter((user: any) => {
        const searchRegex = new RegExp(searchText.replaceAll('+', ''), 'i');
        return user.name.match(searchRegex) || user.mobile.match(searchRegex)
    })

    console.log(selectedUser, 'selectedUser');
    

    return (
        <>
            <div className="row">
                <div className="col-12 text-end">
                    <button className="btn btn-primary btn-sm font-16"
                        data-bs-toggle='modal'
                        data-bs-target='#send-mp-offer'
                    >
                        {t("Send Offer")}
                    </button>
                </div>
            </div>
            <div className="modal fade custom-modal text-start" id="send-mp-offer">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-10">
                        <div className='modal-header py-3 border-0'>
                            <h5 className='modal-title'>{t("Send Offer")}</h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body p-8">
                            <div className="form-group has-search mb-5">
                                <span className="fa fa-search pt-2 form-control-feedback"></span>
                                <input
                                    onChange={(e: any) => { setSearchTech(e.target.value) }}
                                    type="text" className="form-control ps-10" placeholder={t("Search")} />
                            </div>

                            <div
                                style={{
                                    overflowY: "scroll",
                                    height: "400px"
                                }}
                                className="table-responsive fc-su-ser-tabel px-0">
                                <table className="table align-middle table-striped gy-4 gx-4 mb-4 fcs-assign-to-technician">
                                    <thead>
                                        <tr>
                                            <th>{t("No.")}</th>
                                            <th>{t("Name")}</th>
                                            <th>{t("Mobile Number")}</th>
                                            <th className="tab-action-select">{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUsers.length === 0 ? (
                                            <tr>
                                                <td colSpan={4}>{t("No user found!")}</td>
                                            </tr>
                                        ) : null}
                                        {filteredUsers.map((user: any, index: number) => {
                                            return (
                                                <tr key={index + 1}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.mobile}</td>
                                                    <td className="tab-action-select">
                                                        {
                                                            user.disabled ? 
                                                            <input name="technician" className="form-check-input fc-wo-checkbox c-pointer" type="checkbox" disabled checked/>
                                                            :
                                                            <input name="technician" onChange={handleSelect} className="form-check-input fc-wo-checkbox c-pointer" value={user.id} type="checkbox" id={user.id} checked={selectedUser.includes(`${user.id}`)}/>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>


                            {(!submitStatus.loading && submitStatus.error) && (
                                <div className="alert alert-danger w-100 mt-2 text-center py-3" role="alert">
                                    {submitStatus.message}
                                </div>
                            )}
                            {/* {(selectedUser.length > 0)  &&
                                <div className="fw-normal text-muted font-12">
                                    {t("Selected")} : {selectedUser.length}
                                </div>
                            } */}
                            <button
                                // data-bs-target="#filter-popup"
                                // data-bs-toggle="modal" data-bs-dismiss="modal"
                                onClick={handleAssign}
                                type='button'
                                disabled={submitStatus.isSubmitted || submitStatus.loading}
                                // disabled={true}
                                className='d-block w-100 btn btn-primary fw-bold px-10 py-3'>
                                {submitStatus.loading ?
                                    <span className='indicator-progress d-block'>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                                        {t("Send")}
                                    </span>
                                    :
                                    <span className='indicator-label'>{t("Assign")}</span>
                                }
                            </button>
                            <p
                                ref={successRef}
                                data-bs-target='#req-submitted-popup-redirect'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                className='invisible d-none'
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}