import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import email from '../../assets/images/email1.png'

export function PriviewModal() {


    return (
        <div className="modal fade" aria-hidden='true' id="PriviewPopup">
            <div className="modal-dialog modal-lg modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Preview</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="d-block border-bottom pb-3 mb-3 theme-color">
                            <div className="mb-3 d-flex">
                                <b className="d-inline-block col-md-12 col-lg-2 font-weight-normal font-12">Template : </b>
                                <select className="form-control tag-ip d-inline-block col-md-12 col-lg-10" name="template" disabled>
                                    <option value="">Offer Accept Template</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-block border-bottom pb-3 mb-3 theme-color">
                            <div className="mb-3 d-flex">
                                <b className="d-inline-block col-md-12 col-lg-2 font-weight-normal font-12">To : </b>
                                vicky3074@yahoo.com
                            </div>
                        </div>
                        <div className="d-block border-bottom pb-3 mb-3 theme-color">
                            <div className="mb-3 d-flex">
                                <b className="d-inline-block col-md-12 col-lg-2 font-weight-normal font-12">Subject : </b>
                                You have received an offer for &nbsp;<span className="email_reference_title">REF-UN-1689589116  - test</span>
                            </div>
                        </div>
                        <div className="d-block border-bottom pb-3 mb-3 theme-color">
                            <div className="mb-3 d-flex">
                                <b className="d-inline-block col-md-12 col-lg-2 font-weight-normal font-12">Attachment : </b>
                                <input type="hidden" name="id_contact" id="id_contact" value="56" />
                                <a href="/previewPdf/NDQ0LXVuZGVmaW5lZC01Ng==" id="previewPDF" target="_blank">Offer.pdf</a>

                            </div>
                        </div>
                        <img className="mt-3" src={email}/>
                    </div>
                    <div className="col-12 text-end py-5 px-5">
                        <button data-bs-dismiss="modal" className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                            Close
                        </button>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}