import React, { useState, useEffect } from 'react'

export function DashboardSettings() {
    const [documentsCount, setDocumentsCount] = useState<any>(1);
    return (      
        <>
        <div className="col-md-12 mt-7">
            <h6 className="text-black mb-3"> Communities </h6>
        </div>
       <div className="card">
        <div className="card-body">
        <div className="row mt-7 form-add-design">
            { Array(documentsCount).fill(1).map((data, index) =>{
                    return(
                        <div className="row upload-filename mt-6">
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase font-13">COMMUNITY</label>
                                    <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select" required>
                                        <option selected>Select</option>
                                        <option > Willton Park Residency</option>
                                        <option >Belgravia Square</option>
                                        <option >Emirites Hills</option>
                                        <option >The Sloan by Belgravia Heights</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase font-13">Order</label>
                                    <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select" required>
                                         <option selected>Select</option>
                                        <option> 1</option>
                                        <option >2</option>
                                        <option >3</option>
                                        <option >4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-3 col-sm-6 d-flex align-items-center">
                                <div className=" text-end">
                                { documentsCount === (index+1) &&
                                    <button type="button" className="btn btn-primary me-3" onClick={()=>{setDocumentsCount((oState:any)=>oState+1)}}><span className="fas fa-plus"></span></button>
                                }
                                    <button type="button" className="btn btn-primary" onClick={()=>{setDocumentsCount((oState:any)=>oState-1)}}><span className="fas fa-minus"></span></button>
                                </div>
                            </div>
                        </div>
                    ) } ) }
            </div>
            <div className="col-12 text-end">
                <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                    Submit
                </button>
            </div>
        </div>
       </div>
                    
       
    </>
    )
}

