import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import moment from 'moment';
// import ApprovalIcon from '../../../../assets/images/approvals.png'
import ApprovalIcon from '../../../../assets/images/Approvals_Active.png'

import { useTranslation } from 'react-i18next';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { formatDateTimeMeridiem, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import { getApprovals } from '../../../../constants/axios/apis';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../constants/generics/userCheck';

const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false,
                arrows: true
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false,
                arrows: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true
            }
        }
    ]
};

export const ActionRequired: React.FC<any> = ({ }) => {
    const [pendingApprovals, setPendingApprovals] = useState<any>([])
    const [loading, setLoading] = useState(true)

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    useEffect(() => {
        setLoading(true)
        const newPayload = `?currentPage=1&pageSize=10&date_sort=1&type=5&status=pending`
        getApprovals(newPayload).then((response: any) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                setPendingApprovals(response.data)
            }
            setLoading(false)
        })
            .catch((e) => setLoading(false))
    }, [])

    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body px-7 py-7">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h4 className="card-title">{user_role === 'MA' ? t("Action Required") : t("Recent Requests")}</h4>
                                </div>
                                {pendingApprovals?.length > 2 ? (
                                    <div className="col-md-6 col-12 d-flex justify-content-end align-items-center pe-md-20">
                                        <Link to={'/mta-approvals/list/pending'} className="text-decoration-none view-all">
                                            <span> {t("View All")} </span>
                                            <img src={RightArrow} className="img-fluid" />
                                        </Link>
                                    </div>
                                ) : (
                                    <></>
                                )
                                }
                            </div>
                            <div className="rec-request-sec">
                                <div>
                                    <div className="row">
                                        {loading && (
                                            <CustomLoader2 height={'166'} />
                                        )}
                                        {!loading && (
                                            <div className="col-12 p-0">
                                                {pendingApprovals?.length ?
                                                    (
                                                        <Slider {...settings}>
                                                            {pendingApprovals.map((recent: any, index: number) => {

                                                                return (
                                                                    <div key={index}>
                                                                        <Link
                                                                            to={{ pathname: '/mta-approvals/view', state: { 'approvalData': recent } }}
                                                                            className='text-decoration-none'
                                                                        >
                                                                            <div className='card mt-4'>
                                                                                <div className='card-body px-5 py-5 px-md-8 py-md-7'>
                                                                                    <div className='row'>
                                                                                        <div className='col-lg-8'>
                                                                                            <div className='mb-3'>
                                                                                                <img
                                                                                                    src={ApprovalIcon}
                                                                                                    className='img-fluid me-5 d-inline'
                                                                                                    style={{filter:'grayscale(1)'}}
                                                                                                />
                                                                                                <span className='card-title'>
                                                                                                    {recent?.other_data_json?.event_name ? recent.other_data_json.event_name : '-'}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-sm-12 mb-2">
                                                                                                    <p className="mb-0 text-muted">{t("Type")}</p>
                                                                                                    <p className="mb-0 text-dark fcs-dashboard-request fcs-unit-number">{recent?.other_data_json?.event_type ? recent.other_data_json.event_type : '-'}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6 pe-0">
                                                                                                    <p className="mb-0 text-muted">{t("Start Date")}</p>
                                                                                                    <p className="mb-0 text-dark font-12 fcs-unit-number">{recent?.other_data_json?.event_start_date ? formatDateTimeMeridiem(recent?.other_data_json?.event_start_date + ' ' + recent?.other_data_json?.event_start_time) : '-'}</p>
                                                                                                </div>
                                                                                                <div className="col-sm-6 pe-0">
                                                                                                    <p className="mb-0 text-muted">{t("End Date")}</p>
                                                                                                    <p className="mb-0 text-dark font-12 fcs-unit-number">{recent?.other_data_json?.event_end_date ? formatDateTimeMeridiem(recent?.other_data_json?.event_end_date + ' ' + recent?.other_data_json?.event_end_time) : '-'}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-lg-4 text-end mt-auto'>
                                                                                            <button className='btn btn-primary rounded w-100 py-2 mt-13'>
                                                                                                {t('View')}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Slider>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <NoDataFound1 height={'110'} />
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}