import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import { formatDateTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import { getMerchantList } from '../../../../constants/axios/apis';

export function MerchantList() {
    const { t } = useTranslation()
    const pageSize = 10
    const [rowList, setRowList] = useState([]);
    const [loading, setLoading] = useState<any>(true)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [totalCount, setTotalCount] = useState<any>(0)

    useEffect(() => {
        const newPayload = ''
        getMerchantList(newPayload).then((response: any) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                setRowList(response.data)
                setTotalCount(response.dataCount)
            } else {
                setRowList([])
                setTotalCount(0)
            }
            setLoading(false)
        }).catch((e)=>setLoading(false))
    }, [currentPage])

    if (!loading && (rowList === undefined || rowList?.length === 0)) {
        return <NoDataFound1 />
    }

    return (
        <div className='com-card-section'>
            <div className="row">
                <div className="col-12">
                    <h4 className="card-title mb-5 font-18 text-black"> {t("Merchants")} </h4>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body px-4 py-4'>
                            <div className='rec-payment-act-sec'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='table-responsive'>
                                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th className=''> {t('No.')} </th>
                                                        <th className=''> {t('Merchant Name')}</th>
                                                        <th className=''> {t('Pending')} </th>
                                                        <th className=''> {t('Approved')} </th>
                                                        <th className=''> {t('Rejected')} </th>
                                                        <th className=''> {t('Action')} </th>
                                                    </tr>
                                                </thead>
                                                {loading ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <CustomLoader2 />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                        <tbody>
                                                            { rowList?.map((data: any, index: any) => (
                                                                    <tr key={`${data.id}${index}`}>
                                                                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                                        <td>{data?.userName}</td>
                                                                        <td>{data?.approvalCount?.pending}</td>
                                                                        <td>{data?.approvalCount?.approved}</td>
                                                                        <td>{data?.approvalCount?.rejected}</td>
                                                                        <td>
                                                                            <Link
                                                                                to={{ pathname: '/merchant/view', state: { 'merchantData': data } }}
                                                                                className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
                                                                            >
                                                                                <span className='view-option text-theme fw-bolder'>{t('View')}</span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                )}
                                            </table>
                                            <CompactPagination
                                                pageSize={pageSize}
                                                totalCount={parseInt(totalCount)}
                                                defaultPage={currentPage}
                                                onPageChange={setCurrentPage}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}