import React, {FC} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {SecurityDeposit} from './SecurityDeposit'

export function PMApprovedNotification() {
    const history = useHistory();
    return (
        <div className="modal fade notif-popup" id="pm-approved-notification">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content rounded-10">
                    {/*<div className="modal-header border-0">*/}
                    {/*<h5 className="modal-title fw-bold">Inbox</h5>*/}
                    {/*<button type="button" className="btn-close text-black" data-bs-dismiss="modal"></button>*/}
                    {/*</div>*/}
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-pills mb-3 make-offer-notification" id="pills-tab" role="tablist">
                                    <li className="nav-item tab_nav_li" role="presentation">
                                        <button className="nav-link active px-10 py-5 w-100 fw-bolder" id="pills-home-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                                aria-selected="true">
                                            Security Deposit
                                        </button>
                                    </li>
                                    <li className="nav-item tab_nav_li" role="presentation">
                                        <button className="nav-link px-10 py-5 w-100 fw-bolder" id="pills-profile-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-profile"
                                                aria-selected="false">
                                            Rent
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <SecurityDeposit />
                                    </div>
                                    {/*<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">*/}
                                    {/*<SecurityDeposit />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
