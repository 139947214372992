import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'

const menuData = [{
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Dashboard",
        "menu_link": ['Dashboard'],
        "display": "Yes",
        "status": "Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Accounts",
        "menu_link": ['Brokers, Agents'],
        "display": "Yes",
        "status": "In Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Properties",
        "menu_link": ['Units', 'Buildings', 'Community', 'Accounts', 'Sales Listing', 'Communities'],
        "display": "Yes",
        "status": "Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Leads",
        "menu_link": ['Tasks', 'Opportunities', 'Contacts', 'Leads'],
        "display": "Yes",
        "status": "In Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Contacts",
        "menu_link": ['Assign Lead', 'Offers', 'Create Lead', 'Leads', 'Contacts', 'Tasks', 'Opportunities'],
        "display": "Yes",
        "status": "Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Lead",
        "menu_link": ['Closed',' All Leads', 'Contacted', 'Likes', 'Visits', 'Contracts', 'Rejected'],
        "display": "Yes",
        "status": "In Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Sales",
        "menu_link": ['Viewing',' Create Contact', 'Oppertunity', 'Contacts'],
        "display": "Yes",
        "status": "Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "My Calender",
        "menu_link": ['My Calender'],
        "display": "Yes",
        "status": "Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Documents",
        "menu_link": ['Invoice', 'Contracts', 'Create Contracts'],
        "display": "Yes",
        "status": "Active"
    },
    {
        "icon": "https://pm-crm.realcube.estate/img/Dashboard/Dashboard.svg",
        "name": "Reports",
        "menu_link": ['Agents', 'Payment History', 'Properties'],
        "display": "Yes",
        "status": "Active"
    },
];

export function MenusList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)
    return (
        <>
        <div className='com-card-section'>
            <div className='row align-items-center mb-5'>
                <div className="col">
                    <h3 className=''>Menus</h3>
                </div>
                <div className='col-auto'>
                    <Link to={'add'} className="btn btn-primary">Add Menu</Link>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className='row align-items-center'>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder">Name </label>
                                <input type="text" placeholder="Name" className="form-control font-13 form-height" name="role"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder">Name </label>
                                <input type="text" placeholder="Name" className="form-control font-13 form-height" name="role"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder">Dispaly in Menu? </label>
                                <select className="form-control form-select fw-bolder">
                                    <option>Please Select </option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Status </label>
                                <select className="form-control form-select fw-bolder">
                                    <option>Please Select </option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </select>
                            </div>
                        </div>
                        <div className="col text-end">
                            <button type='reset' className='btn btn-outline-primary me-3'>
                                Cancel
                            </button>
                            <button type='submit' className='btn btn-primary px-7'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='table-crm'>
                <div className='table-responsive'>
                    <table className='table gy-4 gx-4 mb-0 table-bordered'>
                        <thead className='text-uppercase bg-projects-list font-weight-normal'>
                            <tr>
                                <th>Menu Icon</th>
                                <th>Name</th>
                                <th>Menu Links</th>
                                <th>Display in Menu?</th>
                                <th>Status</th>
                                <th className="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {menuData.length > 0 ? menuData.map((item, i) => (
                                <tr key={i}>
                                    <td><img alt='icon' src={item.icon} /></td>
                                    <td>{item.name}</td>
                                    <td>{item.menu_link}</td>
                                    <td>{item.display}</td>
                                    <td>{item.status}</td>
                                    <td className="action-button text-end">
                                        <div className="dropdown">
                                            <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                <i className="fa fa-ellipsis-v text-black"></i>
                                            </button>
                                            <div className="dropdown-menu mw-auto">
                                                <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                    <li>
                                                        <Link to={'edit'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                            <i className="fa fa-edit"></i>
                                                            <p className="m-0">Edit</p>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )) : <tr><td colSpan={3}>No data..</td></tr>
                            }
                        </tbody>
                    </table>
                    <div className='w-100 d-flex justify-content-end'>
                        <CompactPagination
                            pageSize={pageSize}
                            totalCount={menuData.length}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={false}
                        />
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}
