import React, {useState, useEffect, useLayoutEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {Spinner} from 'react-bootstrap-v5'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import {tenantSearch, createTenant} from '../../../../constants/axios/apis'
import {RootState} from '../../../../constants'
import {useTranslation} from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import IntlTelInput from 'react-intl-tel-input'
import {Alert} from 'antd'

export function MakeonOfferPopup() {
  let proprtyDetails: any
  proprtyDetails = useSelector<RootState>(({PM}) => PM.proprtydetails)
  const intl = useIntl()
  const ref = useRef<any>(0)
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  // const [submit, setSubmit] = useState(false)
  const [userAvailble, setUserAvailble] = useState(false)
  // const [userAvailble1, setUserAvailble1] = useState(false)
  const [message, setMessage] = useState('')
  const [message1, setMessage1] = useState('')
  const [message2, setMessage2] = useState('')

  const [mobile, setMobile] = useState('')
  const [mobileError, setMobileError] = useState('')

  const [emirates, setEmirates] = useState('')
  const [emiratesError, setEmiratesError] = useState('')
  const [customerType, setCustomerType] = useState('')
  const [customerTypeError, setCustomerTypeError] = useState('')
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [comments, setComments] = useState('')
  const [lastname, setLastName] = useState('')
  const [lastNameError, setLastError] = useState('')
  const [searching, setSearching] = useState(false)
  const [showMsg, SetshowMsg] = useState('')
  const [selectedMobileCode, setMelectedMobileCode] = useState<any>('')
  const {t} = useTranslation()

  const emailRegex = /\S+@\S+\.\S+/

  const validateEmail = (event: any) => {
    setMessage1('')

    // setSubmit(false)
    setUserAvailble(false)
    setName('')
    setMobile('')
    setEmirates('')
    setEmail('')
    setLastName('')
    setCustomerType('')

    const email = event.target.value
    if (emailRegex.test(email)) {
      setIsValid(true)
      setEmail(event.target.value)
      setMessage('')
      setMessage1('')
      setMessage2('')
      var data = new FormData()
      data.append('email_id', email?.length ? email : '')
      const config = `?email_id=${email}`
      setSearching(true)
      tenantSearch(config)
        .then((response) => {
          // setSubmit(true)
          if (response?.code === 200 && response?.errorCode != 1) {
            setUserAvailble(true)
            setName(response?.data[0]?.customer_name)
            setLastName(response.data[0]?.last_name)
            setMobile(response?.data[0]?.mobile_number)
            setEmirates(response?.data[0]?.emirates_id)
            setEmail(response?.data[0]?.email)
            const customerData = {
              userId: response?.data[0]?.user_id,
              name: response?.data[0]?.customer_name,
              mobile: response?.data[0]?.mobile_number,
              emirates: response?.data[0]?.emirates_id,
              email: response?.data[0]?.email,
              lastname: response?.data[0].last_name,
              proprtyDetails: proprtyDetails,
            }
            dispatch(General.actions.pmMakeAnOfferData(customerData))
          } else {
            setMessage1(
              t('No user found with this email. You can create one by entering your details below')
            )
            // setTimeout(() => {
            //   setMessage1('')
            // }, 2000)
            // setSubmit(false)
            setUserAvailble(false)
            setName('')
            setMobile('')
            setEmirates('')
            setEmail(event.target.value)
            setLastName('')
          }
          setSearching(false)
        })
        .catch((e) => setSearching(false))
    } else {
      setUserAvailble(false)
      // setSubmit(false)
      setIsValid(false)
      // setEmail(event.target.value)
      setMessage(t('Please enter a valid email!'))
    }
  }

  useLayoutEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100%'
      document.body.style.pointerEvents = 'none'
    }
    if (!loading) {
      document.body.style.overflow = 'auto'
      document.body.style.height = 'auto'
      document.body.style.pointerEvents = 'all'
    }
  }, [loading])

  const handleSubmit = (event: any) => {
    if (userAvailble) {
      ref.current.click()
      history.push('/pm-availability/make-an-offer')
    } else {
      setLoading(true)

      const emirateValidation =
        emirates?.length === 0
          ? t('Please enter emirates id')
          : emirates?.length < 15
          ? t('Minimum length is 15')
          : emirates?.length > 25
          ? t('Maximum 25 characters')
          : emirates?.length > 25
          ? t('Maximum 25 characters')
          : /^(\d+-?)+\d+$/.test(emirates)
          ? ''
          : t('Emirates Id contains only numbers and hypen')
      const emailValidate =
        email?.length > 0
          ? emailRegex.test(email)
            ? ''
            : t('Please enter a valid email!')
          : t('Please Enter Email ID')
      const nameValidate =
        !userAvailble && name?.length > 0
          ? name?.length < 3
            ? t('Min length is 3')
            : name?.length > 25
            ? t('Max length is 25')
            : ''
          : customerType === '2'
          ? t('Please Enter Organization Name')
          : t('Please Enter First Name')

      if (
        customerType?.length > 0 &&
        // (name?.length >= 3 && name?.length <= 25) &&
        // (customerType === '2' || (customerType !== '2' && lastname?.length > 0)) &&
        mobile?.length > 0 &&
        // emirates?.length > 0 &&
        nameValidate === '' &&
        emirateValidation === '' &&
        emailValidate === ''
        // email?.length > 0
      ) {
        // if (name?.length > 0 && mobile?.length > 0 && emirates?.length > 0 && email?.length > 0)
        var data = new FormData()
        data.append('email_id', email)
        data.append('mobile_no', mobile)
        // data.append('tenant_name', name);
        data.append('first_name', name)
        data.append('last_name', customerType === '2' ? '' : lastname)
        data.append('emirates_id', emirates)
        data.append('customer_type', customerType)
        data.append('comment', comments)

        createTenant(data).then((response) => {
          setLoading(false)
          if (response?.code === 200 && response?.errorCode != 1) {
            const customerData = {
              userId: response?.data?.user_id,
              name: name,
              mobile: mobile,
              emirates: emirates,
              email: email,
              lastname: customerType === '2' ? '' : lastname,
              proprtyDetails: proprtyDetails,
            }
            dispatch(General.actions.pmMakeAnOfferData(customerData))
            ref.current.click()
            history.push('/pm-availability/make-an-offer')
          }
          //  else {
          //     setMessage2(t("Something went wrong. Please try again later"))
          //     setTimeout(() => {
          //         setMessage2("")
          //     }, 2000);
          // }
          else if (response?.data?.code === 200 && response?.data?.errorCode === 1) {
            SetshowMsg(response?.data?.errorDescription)
            setTimeout(() => {
              SetshowMsg('')
            }, 3000)
          } else {
            SetshowMsg(t('Something went wrong. Please try again later'))
            setTimeout(() => {
              SetshowMsg('')
            }, 5000)
          }
        })
      } else {
        setLoading(false)
        setMobileError(mobile?.length > 0 ? '' : t('Please enter mobile number'))
        setEmiratesError(emirateValidation)
        // setEmiratesError(emirates?.length > 0 ? '' : t("Please enter emirates id"))
        setNameError(nameValidate)
        // setNameError(name?.length > 0 ? '' : t("Please enter name"))
        setLastError(
          !userAvailble && customerType !== '2' && lastname?.length > 0
            ? lastname?.length < 3
              ? t('Min length is 3')
              : lastname?.length > 25
              ? t('Max length is 25')
              : ''
            : ''
        )

        // setEmailError(email?.length > 0 ? '' : t("Please enter email"))
        setEmailError(emailValidate)
        setCustomerTypeError(customerType?.length > 0 ? '' : t('Please select customer type'))
        // setCustomerTypeError(customerType?.length > 0 ? '' : t("Please select customer type"))
        setTimeout(() => {
          setMobileError('')
          setEmiratesError('')
          setNameError('')
          setEmailError('')
          setLastError('')
          setCustomerTypeError('')
        }, 5000)
      }
    }
  }

  const checkInput = (e: any) => {
    const onlyDigits = e.target.value.replace(/\D/g, '')
    if (e.target.value?.length <= 7) {
      setMobileError(t('Please Enter Valid Mobile No.'))
    } else {
      setMobileError('')
    }
  }

  const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
    setMelectedMobileCode(
      country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : ''
    )
    setMobile(phoneNumber)
    setMobileError('')
    //   formik.setFieldValue('mobileNumberField', phoneNumber)
    //   formik.setFieldTouched('mobileNumberField')
  }

  return (
    <div className='modal fade add-broker-modal' aria-hidden='true' id='make-on-offer-popup'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content p-10'>
          <div className='modal-header p-0'>
            <h5 className='modal-title'>{t('Make an Offer')}</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body px-0 pb-5 pt-0'>
            <div className='mb-5'>
              <div className='row'>
                <div className='col-12 my-auto'>
                  <div className='my-5 input-group custom-search-form'>
                    <input
                      type='text'
                      placeholder={t('Enter an e-mail id')}
                      onChange={(e) => validateEmail(e)}
                      className='form-control search-input'
                    />
                    <span className='input-group-btn'>
                      <button className='search_icon' type='button'>
                        <span className=''> </span>
                      </button>
                    </span>
                  </div>

                  {searching && <div className='text-muted text-center'>{t('Please wait...')}</div>}
                  <p style={{color: 'red'}}> {message}</p>
                  <p style={{color: 'red'}}> {message1}</p>
                </div>

                <div className='col-12'>
                  <div className='mb-5 position-relative'>
                    <label className='form-label font-12'>
                      {t('Select Type')}
                      <span className='text-danger'> * </span>
                    </label>
                    <select
                      className='form-control form-select font-12'
                      onChange={(e) => setCustomerType(e.target.value)}
                      data-control='select2'
                      data-allow-clear='true'
                      value={customerType}
                    >
                      <option selected disabled value="">
                        {t('Please Select')}
                      </option>
                      <option value={1}>{t('Individual')}</option>
                      <option value={2}>{t('Organization')}</option>
                    </select>
                  </div>
                  <p style={{color: 'red'}}> {customerTypeError}</p>
                </div>

                <div className='col-12'>
                  <div className='mb-5 position-relative'>
                    <label className='form-label font-12'>
                      {customerType === '2' ? t('Organization Name') : t('First Name')}
                      <span className='text-danger'> * </span>
                    </label>
                    <input
                      type='text'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className='form-control font-12'
                      placeholder='Name'
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z ]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      disabled={customerType !== '2' && userAvailble}
                    />
                  </div>
                  <p style={{color: 'red'}}> {nameError}</p>
                </div>

                {customerType !== '2' && (
                  <div className='col-12'>
                    <div className='mb-5 position-relative'>
                      <label className='form-label font-12'>{t('Last Name')}</label>
                      <input
                        type='text'
                        value={lastname}
                        onChange={(e) => setLastName(e.target.value)}
                        className='form-control font-12'
                        placeholder='Last Name'
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z ]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        disabled={userAvailble}
                      />
                    </div>
                    <p style={{color: 'red'}}> {lastNameError}</p>
                  </div>
                )}

                <div className='col-12'>
                  <div className='mb-5 position-relative'>
                    <label className='form-label font-12'>
                      {t('Email Id')}
                      <span className='text-danger'> * </span>
                    </label>
                    <input
                      type='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='form-control font-12'
                      placeholder={t('Email Id')}
                      disabled={userAvailble}
                    />
                  </div>
                  <p style={{color: 'red'}}> {emailError}</p>
                  {/* <p style={{color: 'red'}}> {userAvailble1 ? t('Mail id already exists') : ''}</p> */}
                </div>

                {userAvailble && (
                  <div className='col-12'>
                    <div className='mb-5 position-relative'>
                      <label className='form-label font-12'>
                        {t('Mobile Number')}
                        <span className='text-danger'> * </span>
                      </label>
                      <input
                        type='text'
                        value={mobile}
                        className='form-control font-12'
                        placeholder='Mobile Number'
                        disabled={userAvailble}
                      />
                    </div>
                    <p style={{color: 'red'}}> {mobileError}</p>
                  </div>
                )}

                {!userAvailble && (
                  <div className='col-12'>
                    <div className='mb-5 position-relative'>
                      <label className='form-label font-12'>
                        {t('Mobile Number')}
                        <span className='text-danger'> * </span>
                      </label>

                      <IntlTelInput
                        preferredCountries={['ae', 'in', 'us']}
                        containerClassName='intl-tel-input w-100'
                        separateDialCode={true}
                        fieldName='mob'
                        value={mobile}
                        inputClassName='form-control form-control-lg mb-4 w-100'
                        onPhoneNumberChange={handlePhoneNumber}
                        onPhoneNumberBlur={handlePhoneNumber}
                        placeholder={t('Mobile Number')}
                      />
                      <p style={{color: 'red'}}> {mobileError}</p>
                    </div>
                  </div>
                )}

                <div className='col-12'>
                  <div className='mb-5 position-relative'>
                    <label className='form-label font-12'>
                      {t('Emirates Id')}
                      <span className='text-danger'> * </span>
                    </label>
                    <input
                      type='text'
                      value={emirates}
                      onChange={(e) => setEmirates(e.target.value)}
                      className='form-control font-12'
                      placeholder='Emirates Id'
                      onKeyPress={(event) => {
                        if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      disabled={userAvailble}
                    />
                  </div>
                  <p style={{color: 'red'}}> {emiratesError}</p>
                </div>

                <div className='col-12'>
                  <div className='position-relative'>
                    <label className='form-label font-12'>{t('Comments(Optional)')}</label>
                    <textarea
                      className='form-control font-12 h-25'
                      data-kt-autosize='true'
                      rows={4}
                      placeholder='Comments(Optional)'
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer px-0 py-0'>
            <button
              type='submit'
              className='btn btn-primary fw-bold px-10 py-3 w-100'
              onClick={handleSubmit}
            >
              {loading && (
                <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
              )}
              {loading ? t('Loading...') : t('Submit')}
            </button>
            <p ref={ref} data-bs-toggle='modal' data-bs-dismiss='modal'>
              
            </p>
            {showMsg?.length > 0 && <Alert description={showMsg} type='error' closable showIcon />}
          </div>
        </div>
      </div>
    </div>
  )
}
