import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {Redirect, Route, Switch} from 'react-router-dom';
import { PageTitle } from '../../components/layout/core'
import {MarketPlaceTab} from './components/MarketPlaceTab/MarketPlaceTab';
import {All} from './components/MarketPlace/All/All';
import {Month} from './components/MarketPlace/Month/Month';
import {Week} from './components/MarketPlace/Week/Week';
import {MyTeam} from './components/MyTeam/MyTeam';
import {CampaignAnalytics} from './components/CampaignAnalytics/CampaignAnalytics';
import {NewCampaign} from './components/NewCampaign/NewCampaign';
import { ActionRequired } from './components/ActionRequired/ActionRequired';
import { QuickLinks } from './components/QuickLinks/QuickLinks';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../constants/generics/userCheck';

const MTADashboardPage: FC = () => {
    const state: any = useSelector(state => state);
    const userRoleCode = getUserRole(state?.auth?.user?.data);
    return(
        <>
        <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="com-card-section">
                    <div className="row">
                        <div className="col-12">
                            <ActionRequired />
                        </div>
                        <div className="col-12">
                            <QuickLinks />
                        </div>
                        <div className="col-12">
                            <div className="row">
                                {/* <div className={userRoleCode === 'MT' ? "col-12" : "col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                    <div className={userRoleCode === 'MT' ? "card mb-3" : "card mb-3 marketPlace"}>
                                        <div className="card-body px-8 py-2">
                                            <div className="row">
                                                <MarketPlaceTab />
                                                <All/>
                                                <Switch>
                                                    <Route path='/marketing-admin-dashboard/all'>
                                                        <MarketPlaceTab />
                                                        <All/>
                                                    </Route>
                                                    <Route path='/marketing-admin-dashboard/week'>
                                                        <MarketPlaceTab />
                                                        <Week />
                                                    </Route>
                                                    <Route path='/marketing-admin-dashboard/month'>
                                                        <MarketPlaceTab />
                                                        <Month />
                                                    </Route>
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {userRoleCode === 'MA' && 
                                    <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="card marketPlace">
                                            <div className="card-body px-8 py-2">
                                                <div className="row">
                                                    <MyTeam />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-12">
                                    <div className="card mt-5">
                                        <div className="card-body px-8 py-2">
                                            <div className="row">
                                               <CampaignAnalytics />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12 col-12">
                                    <NewCampaign />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

const MTADashboardWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <MTADashboardPage />
        </>
    )
};

export {MTADashboardWrapper}
