import React, {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import IssueImg from "../../../../../../assets/images/issue-img-one.png";
import UploadIcon from "../../../../../../assets/images/upload-ioc.png";
import { useTranslation } from 'react-i18next'

const Step1: FC = () => {
    const intl = useIntl()
  const location = useLocation()
  const [menu, setMenu] = useState('')
  const { t } = useTranslation();

  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  useEffect(() => {
    if (window.location.pathname != menu) {
      setMenu(window.location.pathname)
      console.log(window.location.pathname)
    }
  })

  return (
        <>
            <div className="com-card-section w-100 p-7">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row form-add-design">
                            <div className="col-md-12 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block mb-5">{t("Property Available for")}</label>
                                    <div className="btn-group" id="property_available">
                                        <input className="form-check-input me-5" type="radio" name="radio" id="btnradio1" checked/>
                                        <label className="form-check-label me-5 text-black" htmlFor="btnradio1"> {t("Rent")} </label>

                                        <input className="form-check-input me-5" type="radio" name="radio" id="btnradio2" />
                                        <label className="form-check-label text-black" htmlFor="btnradio2"> {t("Not Available")} </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10 position-relative">
                                    <label className="form-label fw-bolder">{t("Title")} </label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Title")} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Property Type")} <span className="text-danger"> * </span></label>
                                    <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                        <option selected disabled>{t("Please Select")}</option>
                                        <option>Air conditioning Failure</option>
                                        <option>Water Leakage Issue</option>
                                        <option>Exhaust Failure</option>
                                        <option>Washing Machine Issue</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("BHK Type")} <span className="text-danger"> * </span></label>
                                    <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                        <option selected disabled>{t("Please Select")}</option>
                                        <option>Air conditioning Failure</option>
                                        <option>Water Leakage Issue</option>
                                        <option>Exhaust Failure</option>
                                        <option>Washing Machine Issue</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Property Size")} <span className="text-danger"> * </span></label>
                                    <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                        <option selected disabled>{t("Please Select")}</option>
                                        <option>12000</option>
                                        <option>50000</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Facing")}</label>
                                    <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                        <option selected disabled>{t("Please Select")}</option>
                                        <option>East</option>
                                        <option>West</option>
                                        <option>North</option>
                                        <option>South</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Property Age")} <span className="text-danger"> * </span></label>
                                    <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                        <option selected disabled>{t("Please Select")}</option>
                                        <option>Newly Built</option>
                                        <option>5Years Old</option>
                                        <option>10Years Old</option>
                                        <option>15Years Old</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Floor")} <span className="text-danger"> * </span></label>
                                    <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                        <option selected disabled>{t("Please Select")}</option>
                                        <option>20</option>
                                        <option>50</option>
                                        <option>100</option>
                                        <option>150</option>
                                    </select>
                                </div>
                            </div>                   
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder"> {t("Floor Plan")} </label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder="3"  value=""/>
                                    <button className='btn-outline-primary fw-bold px-10 py-3 mt-n12 float-end' id="update_bt">
                                        {t("Add")} <i className="bi bi-plus-circle-fill font-15 ms-2 text-primary"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder"> {t("Documents")} </label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder="3"  value=""/>
                                    <button className='btn-outline-primary fw-bold px-10 py-3 mt-n12 float-end' id="update_bt">
                                        {t("Add")} <i className="bi bi-plus-circle-fill font-15 ms-2 text-primary"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="row ms-1">
                                    <div className="col-6 form-check">
                                        <input className="form-check-input me-4" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label fw-bolder text-primary" >
                                            {t("View Floor Plan")}
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <Link to={'/'} className='text-primary'>
                                            {t("View")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </>     
  )
}

export {Step1}
