export function Unit({chooseMessage}:any) {
    return (
        <>
        <div className="card mb-6">
            <div className="card-body">
            <div className="row">
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Bedrooms</label>
                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                        <option selected> Please Select </option>
                        <option> 1</option>    
                        <option> 2</option>    
                        <option> 3 </option>    
                        <option> 4</option>   
                        <option> 6+</option>          
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Bathrooms</label>
                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                        <option selected> Please Select </option>
                        <option> 1</option>    
                        <option> 2</option>    
                        <option> 3 </option>    
                        <option> 4</option>   
                        <option> 6+</option>          
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Build Up Area</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Plot Area</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Furnicing Status</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Bathrooms</label>
                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                        <option selected> Please Select </option>
                        <option> 0</option> 
                        <option> 1</option>     
                        <option> 2</option>    
                        <option> 3 </option>    
                        <option> 4</option>   
                        <option> 6+</option>          
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Parking Area</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>

        </div>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className='col-12'>
                            <label className='font-14 fw-bold mb-3 text-uppercase'>Description</label>
                            <textarea
                                className='form-control'
                                data-kt-autosize='true'
                                rows={3}
                                placeholder={'Description'}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h3 className="my-4"> Add Some Details</h3>
        <div className="card mb-7">
            <div className="card-body">
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item mb-4">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed accodion-tab mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Community Amenities
                    </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <div className="row">
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="115" id="cammenity115"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity115">Guest parking</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="118" id="cammenity118"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity118">Gym</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="123" id="cammenity123"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity123">Power Backup</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="125" id="cammenity125"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity125">Air Conditioner</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="127" id="cammenity127"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity127">Hospital</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="129" id="cammenity129"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity129">Electrical Point (Car Charging)</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="137" id="cammenity137"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity137">Badminton Court</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="138" id="cammenity138"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity138">Swimming Pool</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="141" id="cammenity141"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity141">Library</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="143" id="cammenity143"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity143">Golf Course</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="144" id="cammenity144"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity144">Bicycle Track</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="145" id="cammenity145"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity145">Gated Community</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="146" id="cammenity146"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity146">Jacuzzi</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="148" id="cammenity148"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity148">Barbeque</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="149" id="cammenity149"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity149">Bar</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="189" id="cammenity189"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity189">Sustainable Community</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="201" id="cammenity201"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity201">Built in wardrobes</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="204" id="cammenity204"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity204">Covered parking</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="205" id="cammenity205"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity205">Maid's room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="207" id="cammenity207"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity207">Private garage</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="208" id="cammenity208"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity208">Private garden</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="212" id="cammenity212"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity212">24 hours Maintenance</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="213" id="cammenity213"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity213">Bank/ATM Facility</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="214" id="cammenity214"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity214">Basketball Court</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="217" id="cammenity217"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity217">Business Center</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="219" id="cammenity219"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity219">Children's play area</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="220" id="cammenity220"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity220">Clubhouse</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="222" id="cammenity222"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity222">Concierge service</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="223" id="cammenity223"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity223">Cycling tracks</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="225" id="cammenity225"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity225">Laundry Service</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="232" id="cammenity232"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity232">Tennis courts</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="271" id="cammenity271"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity271">Squash courts</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="314" id="cammenity314"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity314">Basement parking</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="370" id="cammenity370"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity370">Private swimming pool</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="382" id="cammenity382"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity382">Walking Trails</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="387" id="cammenity387"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity387">Fitness Center</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="403" id="cammenity403"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity403">Balcony</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="405" id="cammenity405"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity405">Driver's Room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="477" id="cammenity477"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity477">Carpets</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1746" id="cammenity1746"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity1746">Dining in building</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1808" id="cammenity1808"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity1808">Private gym</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1902" id="cammenity1902"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity1902">Conference room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="1923" id="cammenity1923"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity1923">Prayer room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2538" id="cammenity2538"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity2538">Walk-in Closet</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2539" id="cammenity2539"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity2539">Built in Kitchen Appliances</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2545" id="cammenity2545"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity2545">Pets Allowed</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2550" id="cammenity2550"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity2550">Landscaped Garden</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2551" id="cammenity2551"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity2551">Laundry/washing room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input comm_amenities" name="comm_amenities[]" value="2552" id="cammenity2552"/>
                                    <label className="form-check-label font-13" htmlFor="cammenity2552">Storage room</label>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item mb-4">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed accodion-tab mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Additional Amenities
                    </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <div className="row">
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="115" id="ammenity115"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity115">Guest parking</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="118" id="ammenity118"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity118">Gym</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="123" id="ammenity123"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity123">Power Backup</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="125" id="ammenity125"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity125">Air Conditioner</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="127" id="ammenity127"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity127">Hospital</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="129" id="ammenity129"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity129">Electrical Point (Car Charging)</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="137" id="ammenity137"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity137">Badminton Court</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="138" id="ammenity138"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity138">Swimming Pool</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="141" id="ammenity141"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity141">Library</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="143" id="ammenity143"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity143">Golf Course</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="144" id="ammenity144"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity144">Bicycle Track</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="145" id="ammenity145"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity145">Gated Community</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="146" id="ammenity146"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity146">Jacuzzi</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="148" id="ammenity148"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity148">Barbeque</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="149" id="ammenity149"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity149">Bar</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="189" id="ammenity189"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity189">Sustainable Community</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="201" id="ammenity201"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity201">Built in wardrobes</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="204" id="ammenity204"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity204">Covered parking</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="205" id="ammenity205"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity205">Maid's room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="207" id="ammenity207"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity207">Private garage</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="208" id="ammenity208"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity208">Private garden</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="212" id="ammenity212"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity212">24 hours Maintenance</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="213" id="ammenity213"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity213">Bank/ATM Facility</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="214" id="ammenity214"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity214">Basketball Court</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="217" id="ammenity217"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity217">Business Center</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="219" id="ammenity219"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity219">Children's play area</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="220" id="ammenity220"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity220">Clubhouse</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="222" id="ammenity222"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity222">Concierge service</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="223" id="ammenity223"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity223">Cycling tracks</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="225" id="ammenity225"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity225">Laundry Service</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="232" id="ammenity232"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity232">Tennis courts</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="271" id="ammenity271"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity271">Squash courts</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="314" id="ammenity314"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity314">Basement parking</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="370" id="ammenity370"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity370">Private swimming pool</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="382" id="ammenity382"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity382">Walking Trails</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="387" id="ammenity387"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity387">Fitness Center</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="403" id="ammenity403"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity403">Balcony</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="405" id="ammenity405"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity405">Driver's Room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="477" id="ammenity477"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity477">Carpets</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="1746" id="ammenity1746"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity1746">Dining in building</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="1808" id="ammenity1808"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity1808">Private gym</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="1902" id="ammenity1902"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity1902">Conference room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="1923" id="ammenity1923"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity1923">Prayer room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="2538" id="ammenity2538"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity2538">Walk-in Closet</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="2539" id="ammenity2539"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity2539">Built in Kitchen Appliances</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="2545" id="ammenity2545"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity2545">Pets Allowed</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="2550" id="ammenity2550"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity2550">Landscaped Garden</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="2551" id="ammenity2551"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity2551">Laundry/washing room</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-check mb-6">
                                    <input type="checkbox" className="form-check-input" name="other_amenities[]" value="2552" id="ammenity2552"/>
                                    <label className="form-check-label font-13" htmlFor="ammenity2552">Storage room</label>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed accodion-tab mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        Near By
                    </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="form-check mb-6">
                                        <input type="checkbox" className="form-check-input" name="nearby[]" value="9" id="nearby9"/>
                                        <label className="form-check-label font-13" htmlFor="nearby9">School</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="form-check mb-6">
                                        <input type="checkbox" className="form-check-input" name="nearby[]" value="10" id="nearby10"/>
                                        <label className="form-check-label font-13" htmlFor="nearby10">Hospital</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="form-check mb-6">
                                        <input type="checkbox" className="form-check-input" name="nearby[]" value="11" id="nearby11"/>
                                        <label className="form-check-label font-13" htmlFor="nearby11">City Center</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="form-check mb-6">
                                        <input type="checkbox" className="form-check-input" name="nearby[]" value="12" id="nearby12"/>
                                        <label className="form-check-label font-13" htmlFor="nearby12">Beach</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="form-check mb-6">
                                        <input type="checkbox" className="form-check-input" name="nearby[]" value="13" id="nearby13"/>
                                        <label className="form-check-label font-13" htmlFor="nearby13">Airport</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="form-check mb-6">
                                        <input type="checkbox" className="form-check-input" name="nearby[]" value="14" id="nearby14"/>
                                        <label className="form-check-label font-13" htmlFor="nearby14">College</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 col-lg-4 col-sm-6 mb-3 mb-sm-0">
                        <div className="form-group mb-0">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Available for Virtual Visit * </label>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <div className="form-check mb-6">
                                        <input type="radio" id="yes-virtual" name="is_virtual_visit" className="form-check-input" value="1"/>
                                        <label className="form-check-label font-13" htmlFor="yes-virtual"> Yes </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check mb-6">
                                        <input type="radio" id="no_virtual" name="is_virtual_visit" className="form-check-input" value="0"/>
                                        <label className="form-check-label font-13" htmlFor="no_virtual"> No </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-sm-6">
                        <div className="form-group mb-0">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Available for On-Site Visit * </label>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <div className="form-check mb-6">
                                        <input type="radio" id="yes-onsite" name="is_onsite_visit" className="form-check-input" value="1"/>
                                        <label className="form-check-label font-13" htmlFor="yes-onsite"> Yes </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check mb-6">
                                        <input type="radio" id="no_onsite" name="is_onsite_visit" value="0" className="form-check-input"/>
                                        <label className="form-check-label font-13" htmlFor="no_onsite"> No </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-end my-6">
            <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('1')}>Previous</button>
            <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('3')}>Next</button>
        </div>
        </>
    )
}