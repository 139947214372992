import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {TDetails} from'../../Components/TDetails'
import { useTranslation } from 'react-i18next'

export function StepOne() {
    const { t } = useTranslation();

    return (
        <div className="">
            <div className='col-12'>
                <div className='mb-5'>
                    <h5 className='className="modal-title fw-bold font-18 cpt-headding'>
                        {t("Enter Tenant Details")}
                    </h5>
                </div>
            </div>
            <TDetails />
        </div>
    );
}