import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'
import { PopupListing } from './Modal'
import { propertiesBuildingList ,getMasterData } from '../../../constants/axios/apis'
import { CustomLoader2 } from '../../../constants/generics/customActios'
import { Select } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'

// const buildingData = [{
//     "refrence": "REF-BLDG-1615462991",
//     "buildingName": "Wilton Park Residencies Stores",
//     "buildingType": "Vertical",
//     "score": "10",
//     "communityName": "Wilton Park Residencies",
//     "totalUnits": "0"
// },
// {
//     "refrence": "REF-BLDG-1615462992",
//     "buildingName": "Wilton Park Residencies Shops",
//     "buildingType": "Vertical",
//     "score": "10",
//     "communityName": "Wilton Park Residencies",
//     "totalUnits": "0"
// },
// {
//     "refrence": "REF-BLDG-1615462993",
//     "buildingName": "Wilton Park Residencies Parking",
//     "buildingType": "Vertical",
//     "score": "10",
//     "communityName": "Wilton Park Residencies",
//     "totalUnits": "1"
// },
// {
//     "refrence": "REF-BLDG-1615462994",
//     "buildingName": "Wilton Park Residencies 5",
//     "buildingType": "Vertical",
//     "score": "10",
//     "communityName": "Wilton Park Residencies",
//     "totalUnits": "0"
// },
// {
//     "refrence": "REF-BLDG-1615462995",
//     "buildingName": "Belgravia Stores",
//     "buildingType": "Vertical",
//     "score": "10",
//     "communityName": "Belgravia Stores",
//     "totalUnits": "0"
// },
// {
//     "refrence": "REF-BLDG-1615462996",
//     "buildingName": "Belgravia Square Shops",
//     "buildingType": "Vertical",
//     "score": "10",
//     "communityName": "Belgravia Square",
//     "totalUnits": "0"
// },
// ];

export function BuildingList() {
   
    const {t} = useTranslation();

    // const pageSize = 25;
    const[pageSize, setPageSize] = useState<any>(10)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [buildingList, setBuildingList] = useState<any>()
    const [loading, setLoading] = useState<any>(true)
    const [totalBuildingList, setTotalBuildingList] = useState<any>(0)
    const [buildingTypeList, setBuildingTypeList] = useState<any>()
    const { Option } = Select

    const initialValues = {
        reference_id: '',
        building_name:'',
        building_type:'',
        status:''
    }

    const validationSchema = Yup.object({
        reference_id: Yup.string().required(t('Select a reference_id!')),
        building_name: Yup.string().required(t('Select a building_name!')),
        building_type: Yup.string().required(t('Select a building_type!')),
        status: Yup.string().required(t('Select a status!')),

    
      })

      const onSubmit = (values:any)=>{
        const formData = new FormData()
        formData.append('reference_id', values.reference_id)
        formData.append('building_name', values.building_name)
        console.log("bello")
            setLoading(true)
            const data = 
            '?reference_id=' +
            formik.values.reference_id +
            '&building_name=' +
            formik.values.building_name +
            // '&building_type=' +
            // formik.values.building_type +
            '&status=' +
            formik.values.status
            propertiesBuildingList(data).then((response: any) => {
                if (Array.isArray(response?.data) && response?.data?.length > 0) {
                    setBuildingList(response?.data)
                    setTotalBuildingList(response?.dataCount)
                } else {
                    setBuildingList([])
                    setTotalBuildingList(0)
                }
                setLoading(false)
            }).catch(() => { setLoading(false); setBuildingList([]); setTotalBuildingList(0) })
      }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,   
         onSubmit,
      })


    useEffect(() => {
        getMasterData().then(({ data }) => {
            const buildingTypeList = data.find((el: any) => el.category_name === 'building_type')
            setBuildingTypeList(buildingTypeList?.master_data)
        })
        setLoading(true)
        let page = currentPage
        const newPayload =
            '?currentPage=' +
            page +
            '&pageSize=' +
            pageSize +
            '&id_sort=1'
        propertiesBuildingList(newPayload).then((response: any) => {
            if (Array.isArray(response?.data) && response?.data?.length > 0) {
                setBuildingList(response?.data)
                setTotalBuildingList(response?.dataCount)
            } else {
                setBuildingList([])
                setTotalBuildingList(0)
            }
            setLoading(false)
        }).catch(() => { setLoading(false); setBuildingList([]); setTotalBuildingList(0) })
    }, [currentPage, pageSize])


    const buildingData = buildingList?.map((data: any, index: any) => (
        <tr key={data?.id}>
            {/* <td>{(currentPage - 1) * pageSize + index + 1}</td> */}
            <td className="text-warning">{data?.reference_id ? data.reference_id : '-'}</td>
            <td>{data?.building_name ? data.building_name : '-'}</td>
            <td>{data?.building_type ? data.building_type : '-'}</td>
            <td>{data.score ? data.score : '-'}</td>
            <td>{data?.community_name ? data.community_name : '-'}</td>
            <td>{data?.total_unit ? data.total_unit : '-'}</td>
            <td>{data?.unit_booked ? data.unit_booked : '-'}</td>
            <td>{data?.available_unit ? data.available_unit : '-'}</td>
            <td className={data?.status === 'InActive' ? 'text-danger' : 'text-success'}>{data?.status ? data.status : '-'}</td>
            <td className="action-button text-end">
                <div className="dropdown">
                    <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                        <i className="fa fa-ellipsis-v text-black"></i>
                    </button>
                    <div className="dropdown-menu">
                        <ul className="d-flex list-unstyled m-0 justify-content-center">
                            <li>
                                <Link to={'create-unit'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                    <i className="fa fa-home"></i>
                                    <p className="m-0">Create Unit</p>
                                </Link>
                            </li>
                            <li>
                                <Link to={{'pathname':'edit',
                                state: {'buildings':data}}} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                    <i className="fa fa-edit"></i>
                                    <p className="m-0">Edit</p>
                                </Link>
                            </li>
                            <li>
                            <Link to={{'pathname':'preview',
                                state: {'buildings':data}}} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                    <i className="fa fa-eye"></i>
                                    <p className="m-0">Preview</p>
                                </Link>
                            </li>
                            <li>
                                <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                    <i className="fa fa-share"></i>
                                    <p className="m-0">Share</p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        </tr>
    ))


    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-6">
                    <h3 className=''>Building List</h3>
                </div>
                <div className="col-6 text-end">
                    <Link to="/building/add">
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                            Add Building
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <form action=''
            // method='POST'
            // name='add_building'
            // id='add_building'
            onSubmit={formik.handleSubmit}
            >
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Reference Id </label>
                                <input className="form-control fw-bolder form-control-solid" placeholder="Reference Id"  
                                 {...formik.getFieldProps('reference_id')}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Building Name</label>
                                <input className="form-control fw-bolder form-control-solid" placeholder="Building Name"  {...formik.getFieldProps('building_name')} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Building Type</label>
                                <Select
                                    className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                                    showSearch
                                    placeholder='Please Select'
                                    optionFilterProp='children'
                                    //   value={
                                    //     formik.values.visit_purpose !== '' ? formik.values.visit_purpose : null
                                    //   }
                                    //   onChange={(val) => {
                                    //     formik.setFieldValue('visit_purpose', val)
                                    //   }}
                                    //   onBlur={formik.handleBlur}
                                    filterOption={(input, option) =>
                                        ((option!.children as unknown) as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    {buildingTypeList?.map((el: any, index: number) => (
                                        <Option key={index} value={el.value}>
                                            {el.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Community Type</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Community Type" {...formik.getFieldProps('building_type')}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Status</label>
                                <select className="form-control form-select fw-bolder form-control-solid" {...formik.getFieldProps('status')}
                                >
                                    <option>Please Select </option>
                                    <option value="active">Active</option>
                                    <option value="InActive">In Active</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' onClick={onSubmit} className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select" value={pageSize} onChange={(event)=>setPageSize(event.target.value)}>
                        <option value="10">10 </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='table-crm1'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th> Reference ID</th>
                                                                    <th> Building Name </th>
                                                                    <th> Building Type </th>
                                                                    <th> Score</th>
                                                                    <th> Community Name </th>
                                                                    <th> Total Units </th>
                                                                    <th> Units Booked </th>
                                                                    <th> Available Units </th>
                                                                    <th> Status </th>
                                                                    <th> Action </th>
                                                                </tr>
                                                            </thead>
                                                            {/* <tbody>
                                                                {totalBuildingList.length > 0 ? buildingList.map((item:any, i:any) => (
                                                                    <tr key={i}>
                                                                        <td className="text-warning">{item.refrence}</td>
                                                                        <td>{item.building}</td>
                                                                        <td>{item.buildingType}</td>
                                                                        <td>{item.score}
                                                                        <i data-bs-toggle="modal" data-bs-target="#Listing-popup" className="fa fa-eye text-warning ms-2 c-pointer"></i>
                                                                        </td>
                                                                        <td>{item.communityName}</td>
                                                                        <td>{item.totalUnits}</td>
                                                                        <td className="action-button text-end">
                                                                            <div className="dropdown">
                                                                                <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v text-black"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                                        <li>
                                                                                            <Link to={'create-unit'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-home"></i>
                                                                                                <p className="m-0">Create Unit</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to={'edit'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-edit"></i>
                                                                                                <p className="m-0">Edit</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to={'preview'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-eye"></i>
                                                                                                <p className="m-0">Preview</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-share"></i>
                                                                                                <p className="m-0">Share</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                }
                                                            </tbody> */}

                                                            {loading ? (
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={10}>
                                                                            <CustomLoader2 />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ) : (
                                                                <tbody>{buildingList ? buildingData : ''}</tbody>
                                                            )}

                                                        </table>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={parseInt(totalBuildingList)}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            < PopupListing />
        </>
    )
}
