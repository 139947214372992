import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function EditUser() {
    const [file, setFile] = useState('https://pm-crm.realcube.estate/img/avatar-con.jpeg');

    function handleChange(e:any) {
        let img = URL.createObjectURL(e.target.files[0])
        setFile(img);
    }
    
    return (      
        <>
           <div className="row align-items-center mb-5 community">
                <div className="col-md-5">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>Edit User
                    </h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3 ag-prof">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">Profile Picture</label>
                                <div className="mt-2">
                                    <div className="text-center">
                                        <img className="user-pic img-fluid mx-auto" src={file} alt="Image"/>
                                    </div>
                                    <div className="p-image theme-color text-center mt-3">
                                        <p className="border rounded font-13 py-3 px-3 mb-0 position-relative">
                                            <span>Upload Photo</span>
                                            <input onChange={handleChange} className="opacity-0 position-absolute top-0 start-0 bottom-0 end-0" type="file" accept="image/*" />
                                        </p>   
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase"> User Name </label>
                                        <input type="text" className="form-control fw-bolder" value={'Site Admin'} placeholder="User Name" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase"> Mobile </label>
                                        <input type="text" className="form-control fw-bolder" value={'987654320'} placeholder="Enter Mobile Number" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase"> Email </label>
                                        <input type="text" className="form-control fw-bolder" value={'admin@myvilla.com'} placeholder="Enter Email Id" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase">Alternate Mobile</label>
                                        <input type="number" className="form-control fw-bolder" value={'9876543210'} placeholder="Alternate Mobile" name="alternate_phone" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase">Gender</label>
                                        <select className="form-control form-select fw-bolder">
                                            <option>Male </option>
                                            <option>Female</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">State</label>
                                <select className="form-control form-select fw-bolder "
                                >
                                    <option >Please Select </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">City</label>
                                <select className="form-control form-select fw-bolder "
                                >
                                    <option >Please Select </option>
                                    <option selected>Dubai </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Pin Code </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Pin Code" value="7812549"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Adress Line </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Adress Line" value="test35"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase">Attached Cirtificates</label>
                                <input className="form-control" type="file" id="formFile" />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="form-label text-uppercase fw-bolder">Date of Birth</label>
                                    <input type="date" className="form-control fw-bolder" placeholder="Date of Birth" id="dob" name="date_of_birth" value="22-11-2022" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder">User Role</label>
                                <select className="form-control form-select fw-bolder "
                                >
                                    <option >Please Select </option>
                                    <option selected>Admin</option>
                                    <option>Agent</option>
                                    <option>Broker</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13">About profile</label>
                                    <textarea className="form-control fw-bolder" placeholder="Enter profile here..." rows={2} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Linked Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Linked Profile" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Facebook Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Facebook Profile" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Twitter Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Twitter Profile" />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Video Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Video Profile" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder">Status</label>
                                <select className="form-control form-select fw-bolder ">
                                    <option >Please Select </option>
                                    <option selected>Active</option>
                                    <option>In Active</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Update
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>  
    )
}
