import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/
export const GET_TENANTS = `${API_URL}/pm/tenantSearch/list`
export const GET_PROPRTYLIST = `${API_URL}/pm/getPropertyList/`
export const GET_PROPRTYDETAILS = `${API_URL}/pm/propertyDetails/`

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
// TenantSearchPayload tenantSearchPayload
export const actionTypes = {
  ContractsApprovalData: '[ContractsApprovalData] Action',
  Tenants: '[Tenants] Load data',
  TenantsLoaded: '[Load Tenants] Load data',
  ProprtyList: '[Proprty List] Load data',
  ProprtyListLoaded: '[Load Proprty List] Load data',
  ProprtyDetails: '[Proprty Details] Load data',
  ProprtyDetailsLoaded: '[Load Proprty Details] Load data',
  RefundData: '[RefundData] Action',
}
// ** Define initialpropertyManager **
const initialpropertyManagerState: IpropertyManagerState = {
  contractsApprovalData: undefined,
  tenants: undefined,
  proprtylist: undefined,
  proprtydetails: {
    step1: '',
    step2: '',
    step3: '',
    step4: '',
    step5: '',
    step6: '',
  },
  refundData: undefined,
}
export interface IpropertyManagerState {
  contractsApprovalData?: ''
  tenants?: ''
  proprtylist?: ''
  proprtydetails?: {
    step1: ''
    step2: ''
    step3: ''
    step4: ''
    step5: ''
    step6: ''
  }
  refundData?: ''
}
export const reducer = persistReducer(
  {storage, key: 'PM', blacklist: ['tenants', 'proprtylist', 'refundData']},

  (
    state: IpropertyManagerState = initialpropertyManagerState,
    action: ActionWithPayload<IpropertyManagerState>
  ) => {
    switch (action.type) {
      case actionTypes.ContractsApprovalData: {
        const contractsApprovalData = action.payload?.contractsApprovalData
        return {...state, contractsApprovalData: contractsApprovalData}
      }
      case actionTypes.Tenants: {
        const tenants = action.payload?.tenants
        return {...state, tenants}
      }
      case actionTypes.TenantsLoaded: {
        const tenants = action.payload?.tenants
        return {...state, tenants}
      }
      case actionTypes.ProprtyList: {
        const proprtylist = action.payload?.proprtylist
        return {...state, proprtylist}
      }
      case actionTypes.ProprtyListLoaded: {
        const proprtylist = action.payload?.proprtylist
        return {...state, proprtylist}
      }
      case actionTypes.ProprtyDetails: {
        const proprtydetails = action.payload?.proprtydetails
        return {...state, proprtydetails}
      }
      case actionTypes.ProprtyDetailsLoaded: {
        const proprtydetails = action.payload?.proprtydetails
        return {...state, proprtydetails}
      }
      case actionTypes.RefundData: {
        const refundData = action.payload?.refundData
        return {...state, refundData: refundData}
      }
      default:
        return state
    }
  }
)
// export function getTenants(payload: any) {
//   return axios.post(GET_TENANTS, payload)
// }

export function getTenants(payload: any) {
  return axios.get(GET_TENANTS + payload)
}
export async function getProprtyList(payload: any) {
  const data = await axios.get(GET_PROPRTYLIST + payload)
  return data
}
export function getPropertyDetails(payload: any) {
  return axios.get(GET_PROPRTYDETAILS + payload)
}
export const actions = {
  contractsApprovalData: (contractsApprovalData: any) => ({
    type: actionTypes.ContractsApprovalData,
    payload: {contractsApprovalData},
  }),
  tenants: (data: any) => ({type: actionTypes.Tenants, payload: {data}}),
  fulfillMyTenants: (tenants: '') => ({
    type: actionTypes.TenantsLoaded,
    payload: {tenants},
  }),
  proprtylist: (data: any) => ({type: actionTypes.ProprtyList, payload: {data}}),
  fulfillMyProprtyList: (proprtylist: '') => ({
    type: actionTypes.ProprtyListLoaded,
    payload: {proprtylist},
  }),
  proprtydetails: (data: any) => ({type: actionTypes.ProprtyDetails, payload: {data}}),
  fulfillMyProprtyDetails: (proprtydetails: '') => ({
    type: actionTypes.ProprtyDetailsLoaded,
    payload: {proprtydetails},
  }),
  refundData: (refundData: any) => ({
    type: actionTypes.RefundData,
    payload: {refundData},
  }),
}

export function* PMSaga() {
  yield takeLatest(actionTypes.Tenants, function* userRequested(datas: any) {
    const {data: tenants} = yield getTenants(datas.payload.data)
    yield put(actions.fulfillMyTenants(tenants))
  })
  yield takeLatest(actionTypes.ProprtyList, function* userRequested(datas: any) {
    // const {data: proprtylist} = yield getProprtyList(datas.payload.data)
    // yield put(actions.fulfillMyProprtyList(proprtylist))
    try {
      const {data: proprtylist} = yield getProprtyList(datas.payload.data)

      if (proprtylist.code >= 200 && proprtylist.code < 300) {
        yield put(actions.fulfillMyProprtyList(proprtylist))
      } else {
        throw proprtylist.code
      }
    } catch (error) {
      yield put({type: actions.proprtylist, error})
    }
  })
  yield takeLatest(actionTypes.ProprtyDetails, function* userRequested(datas: any) {
    const {data: proprtydetails} = yield getPropertyDetails(datas.payload.data)
    yield put(actions.fulfillMyProprtyDetails(proprtydetails))
  })
}
