import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

const MTAApprovalsTabs: React.FC = () => {

    const location = useLocation();
    const {t} = useTranslation()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="card-title mb-5 font-18 text-black"> {t("Approvals")} </h4>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body px-8 py-2">
                            <div className="row access-cd-tabs">
                                <div className="col-md-6 col-sm-12 col-lg-12">
                                   <div className='d-flex'>
                                       <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                           <li className='nav-item'>
                                               <Link
                                                   className={`nav-link position-relative text-active-primary ` +
                                                   (location.pathname === '/mta-approvals/list/pending' && 'active')}
                                                   to='/mta-approvals/list/pending'>
                                                   {t("Pending")}
                                               </Link>
                                           </li>
                                           <li className='nav-item'>
                                               <Link
                                                   className={`nav-link position-relative text-active-primary ` +
                                                   (location.pathname === '/mta-approvals/list/approved' && 'active')}
                                                   to='/mta-approvals/list/approved'>
                                                   {t("Approved")}
                                               </Link>
                                           </li>
                                           <li className='nav-item'>
                                               <Link
                                                   className={`nav-link position-relative text-active-primary ` +
                                                   (location.pathname === '/mta-approvals/list/rejected' && 'active')}
                                                   to='/mta-approvals/list/rejected'>
                                                   {t("Rejected")}
                                               </Link>
                                           </li>
                                       </ul>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {MTAApprovalsTabs}
