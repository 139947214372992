import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function CreateTaskModal() {
    const [show, setShow] = useState(true);
    const [show2, setShow2] = useState(true);
    return ( 
        <> 
           <div className="modal fade prop-enq-modal" id="create_task_modal">
                <div className="modal-dialog overflow-hidden modal-lg">
                    <div className="modal-content overflow-hidden border-0">
                        <div className="modal-header bg-primary text-white">
                            <h5 className="modal-title font-16 text-white font-weight-semibold">Add New Task</h5>
                            <button type="button" className="close text-warning bg-white rounded-circle" data-bs-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form className="d-block">
                                <div className='row'>
                                    <div className="col-lg-4 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Task Title</label>
                                        <input type="text" className="form-control font-13" name="" placeholder=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Status</label>
                                        <div className="tag-ip">
                                            <select className="form-control font-13">
                                                <option>To Do</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Assigned To</label>
                                        <div className="tag-ip">
                                            <select className="form-control font-13">
                                                <option>Sam Mathew (Agent)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Type Of Action</label>
                                        <div className="tag-ip">
                                            <select className="form-control font-13">
                                                <option>Move In Inspection Required</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Due Date</label>
                                        <input type="date" className="form-control font-13" placeholder="Mar 12, 2020" name=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 mb-7 d-flex align-items-center">
                                        <div className="form-check form-switch mt-4">
                                            <input className="form-check-input" type="checkbox" id="calender" onClick={() => setShow(!show)} checked={show}/>
                                            <label className="form-check-label" htmlFor="calender">Add To Calendar</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-6 mb-7">
                                        <div className="form-check form-switch d-flex ps-0">
                                            <label className="form-check-label pe-10 me-10" htmlFor="specific_time">Specific Time</label>
                                            <input className="form-check-input" type="checkbox" id="specific_time" onClick={() => setShow2(!show2)} checked={show2}/>
                                        </div>
                                        <div className="tag-ip mt-3">
                                            <div className="row">
                                                <div className="d-inline-block col-md-4">
                                                    <select className="form-control font-13">
                                                        <option>00</option>
                                                        <option>01</option>
                                                        <option>02</option>
                                                        <option>03</option>
                                                        <option>04</option>
                                                        <option>05</option>
                                                        <option>06</option>
                                                        <option>07</option>
                                                        <option>08</option>
                                                        <option>09</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                    </select>
                                                </div>
                                                <div className="d-inline-block col-md-4">
                                                    <select className="form-control font-13">
                                                        <option>00</option>
                                                        <option>10</option>
                                                        <option>20</option>
                                                        <option>30</option>
                                                        <option>40</option>
                                                        <option>50</option>
                                                    </select>
                                                </div>
                                                <div className="d-inline-block col-md-4">
                                                    <select className="form-control font-13">
                                                        <option>AM</option>
                                                        <option>PM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Priority</label>
                                        <div className="tag-ip">
                                            <select className="form-control font-13">
                                                <option>High</option>
                                            </select>
                                            <div className="custom-input custom-input-inline checkbox mt-4">
                                                <input type="checkbox" id="send-reminder" className="form-check-input check-input me-3" value="" name="send-reminder"/>
                                                <label htmlFor="send-reminder" className="font-13"> Send Reminder</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-sm-6 mb-7">
                                        <label className="d-block text-uppercase mb-0 font-13">Description</label>
                                        <div className="">
                                            <textarea className="form-control font-13" rows={4}></textarea>
                                        </div>
                                    </div>  		
                                    <div className="col-12 text-end mt-4">
                                        <button className="btn btn-outline-primary me-3 font-12">Cancel</button>
                                        <button className="btn btn-primary font-12">Create &amp; Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
	                
        </>
    )
}