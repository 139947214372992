import React, {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import {Profile} from './Profile'
import {ProfileDetails} from './ProfileDetails'
import {Documents} from './Documents'
import {Buildings} from './Buildings'
import {Handbook} from './Handbook'
import { SubMembers } from './SubMembers'
import { useTranslation } from 'react-i18next';

export function UserDetailsWrapper() {
  const { t } = useTranslation();
  let params: any = {}
  params = useLocation()
  const {user} = params.state

  const backLink = user?.designation === 'Staff' ? '/directory/staff' : '/directory/residents'

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={backLink} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              <span className='text-black'>{t("Details")}</span>
            </Link>
          </h4>
        </div>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body px-5 py-5'>
              <div className='row'>
                <div className='col-lg-3 col-12 mb-lg-0 mb-5'>
                  <Profile userData={user} />
                </div>
                <div className='col-lg-9 prof-det col-12'>
                  <ProfileDetails userData={user} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 mt-5'>
          <div className='card'>
            <div className='card-body px-5 pt-5 pb-0'>
              <div className='row'>
                {user.staff_type === 'Tenant' ? (
                  <Documents
                    documents={user.document}
                    building_id={user.building_id}
                    property_id={user.property_id}
                  />
                ) : (
                  <Buildings community={user.community} userType={user.staff_type} />
                )}
              </div>
            </div>
          </div>
        </div>
        
        <div className='col-12 mt-5'>
          <div className='card'>
            <div className='card-body px-5 pt-5 pb-0'>
              <div className='row'>
                <Handbook />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
