import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom'
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import uploadImg from "../../../assets/images/file-upload.svg";

import useRequestGet from '../../../hooks/useRequestGet'
// import useRequestPost from '../../../hooks/useRequestPost'
// import Loader from '../../../components/Loader';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Select } from 'antd';

import { useHistory } from "react-router-dom";
// import { CustomRequestSuccessfulPopup } from '../../SuccessfulPopup/CustomRequestSuccessfulPopup'
// import IntlTelInput from 'react-intl-tel-input';
// import PhoneInput from 'react-phone-input-2';
import { 
    createActivity,
    getFacilityBuildingContractList,
    getFacilityBuildingList,
    // getFmCommunityUser
} from '../../../constants/axios/apis';
import { useSelector } from 'react-redux';
import { RootState } from '../../../constants';
import { getUserRole } from '../../../constants/generics/userCheck';
import { LoadingErrorAlert, LoadingSubmitAlert } from '../../../constants/generics/customAlerts';
import { CustomLoaderCompact } from '../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';
import { validateAndCompressImage } from '../../../constants/generics/fileUploadHandler';

export function AddActivities({setPopupSuccessMsg}:any) {
    const { t } = useTranslation();
    const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    const history = useHistory();
    const successRef: any = useRef(null);
    const { Option } = Select;

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    // const { requestState: checklistQuestionsPost, setRequestParams } = useRequestPost("/pm/activity/create", true, successPopup);

    const { requestState: masterData, refreshRequest: refreshMasterData } = useRequestGet(user_role === 'FCS' ? "/getMasterData?userRole=FCS" : "/getMasterData");
    const { requestState: requestSubTypes, refreshRequest: refreshRequestSubTypes } = useRequestGet("/getActivityRequestTypes");
    const {requestState:userList, refreshRequest: refreshUserList} = useRequestGet("/user/list?user_role=TENANT");
    // const { requestState: userList, refreshRequest: refreshUserList } = useRequestGet("/user/list?type=residents");

    const [buildingUnitList, setBuildingUnitList] = useState<any>([])
    const [unitListOptions, setUnitListOptions] = useState<any>([]);
    const [requestTypeOptions, setRequestTypeOptions] = useState<any>([]);
    const [requestSubTypeList, setRequestSubTypeList] = useState<any>([]);
    const [outcomeOptions, setOutcomeOptions] = useState<any>([]);
    const [tenantListOptions, setTenantListOptions] = useState<any>('');    

    const [isLoading, setIsLoading] = useState<any>({
        building: false, // coming from RootState
        unit: true,
        type: true,
        category: true,
        // outcome: true,
        // tenant: true
    })
    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        isSubmitted: false,
        message: '',
    })

    const initialValues = {
        building_id: currentBuilding ? currentBuilding : "",
        unitNo: "",
        tenantName: "",
        contactNumber: "",
        requestType: "",
        requestSubType: "",
        outcome: "",
        description: "",
        mediaUpload: ""
    }

    const onSubmit = (values: any) => {
        const formData = new FormData();
        formData.append("unit_no", values.unitNo);
        formData.append("building_id", values.building_id);
        if(user_role!=='FCS' && tenantListOptions){
            formData.append("tenant_id", values.tenantName);
            formData.append("contact_number", values.contactNumber);
        }
        formData.append("description", values.description);
        formData.append("request_type", values.requestType);
        formData.append("activity_category_id", values.requestSubType);
        formData.append("outcome", values.outcome);
        formData.append("media", values.mediaUpload);

        setSubmitStatus((oStatus:any)=>({...oStatus, loading: true, error: false}))

        setTimeout(() => {
            createActivity(formData)
                .then((response: any) => {
                    if (response.errorCode === 1)
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
                    else if (response.errorCode === 0) {
                        setPopupSuccessMsg(response.description)
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.description, isSubmitted: true}))
                        successRef.current.click()
                        setTimeout(() => {
                            formik.resetForm()
                            history.push("/activities/list/open")
                        }, 1000)
                    } else
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                })
                .catch((e) => {
                    setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                })
        }, 1000)
    }

    const validationSchema = Yup.object({
        building_id: Yup.string().required(t("Select a building!")),
        unitNo: Yup.string().required(t("Select a unit!")),
        // tenantName: Yup.string().required("Required!"),
        // contactNumber: Yup.string()
        //     .min(4, 'Mobile No. should be greater than 4 digits!')
        //     .max(16, 'Mobile No should be smaller then 16 digits!')
        //     .matches(/^\+?[0-9]+$/, 'Invalid format, only + and numbers allowed!')
        //     .required('Mobile No is required!'),
        requestType: Yup.string().required(t("Select a request type!")),
        requestSubType: Yup.string().required(t("Select a request category!")),
        // outcome: Yup.string().required("Select an outcome!"),
        description: Yup.string()
            .max(150,t("Max length is 150 characters!"))
            .matches(/^[^\s].*/, t('Description cannot start with a blankspace!'))
            .required(t("Description is Required!")),
        // mediaUpload: Yup.mixed()
        //     .test('type', t('Only the following formats are acceptable: .jpeg, .jpg, .png'), (value) =>
        //         value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true )
        //     .test('fileSize', t('The file is too large!'), (value) => value ? value?.size <= 2000000 : true ),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState(false)

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleDisplayFileDetails = (e: any) => {
        setUploading(true)
        formik.setFieldError('mediaUpload', undefined)
        try {
            validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
                if (compressResponse?.success) {
                    inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
                    formik.setFieldTouched('mediaUpload')
                    formik.setFieldValue('mediaUpload', compressResponse.file)
                } else {
                    setUploadedFileName(null)
                    formik.setFieldTouched('mediaUpload', true, false)
                    formik.setFieldValue('mediaUpload', '', false)
                    formik.setFieldError('mediaUpload', t(compressResponse?.message || 'File compression error!'))
                }
                setUploading(false)
            })
        } catch (error) {
            setUploadedFileName(null)
            formik.setFieldTouched('mediaUpload')
            formik.setFieldValue('mediaUpload', '', false)
            formik.setFieldError('mediaUpload', t('File upload error!'))
            setUploading(false)
        }
        // inputRef.current?.files &&
        //     setUploadedFileName(inputRef.current.files[0].name);

        // formik.setFieldTouched('mediaUpload')
        // formik.setFieldValue('mediaUpload', e.currentTarget.files[0])
    };

    
    useEffect(() => {
        getFacilityBuildingList().then(({ data }) => {
            setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({ buildingId: item.id, unitList: item.property })) : [])
            setIsLoading((oState: any) => ({ ...oState, unit: false }))
        }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, unit: false })) })
    
        /* Fetch units having contract only */
        // getFacilityBuildingContractList().then(({data}) => {
        //     setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({buildingId: `${item.id}`, unitList: item.property})) : [])
        //     setIsLoading((oState: any) => ({...oState, unit: false}))
        // }).catch((e) => {setIsLoading((oState: any) => ({...oState, unit: false}))})
    }, [])

    useEffect(() => {
        setIsLoading((oState: any) => ({...oState, type: masterData?.loading}))
    }, [masterData])
    useEffect(() => {
        setIsLoading((oState: any) => ({...oState, category: requestSubTypes?.loading}))
    }, [requestSubTypes])
    useEffect(() => {
        setIsLoading((oState: any) => ({...oState, tenant: userList?.loading}))
    }, [userList])

    useEffect(() => {        
        const selectedBuildingId = formik.values.building_id
        const selectedBuildingUnits = (selectedBuildingId && selectedBuildingId !== "") ? buildingUnitList?.find((el: any) => el.buildingId === selectedBuildingId) : []
        setUnitListOptions(selectedBuildingUnits?.unitList?.length ? selectedBuildingUnits.unitList : [])
        formik.setFieldValue("unitNo", "");
    }, [formik.values.building_id, buildingUnitList])

    // useEffect(()=>{
    //     if(Array.isArray(buildingIdList.response)){
    //         if(buildingIdList.response.length > 0){
    //             setBuildingListOptions(buildingIdList.response);
    //         }
    //     }
    //     if(Array.isArray(propertyList.response) && Array.isArray(buildingIdList.response)){
    //         if(propertyList.response.length > 0 && buildingIdList.response.length > 0){
    //             if(formik.values.building_id !== ""){
    //                 const building_id = buildingIdList.response.find((el:any) => el.building_id == formik.values.building_id);
    //                 const propertyListVal = propertyList.response.filter((el:any) => el.buildingType === building_id.building_name);
    //                 setUnitListOptions(propertyListVal);
    //                 formik.setFieldValue("unitNo", "");
    //             }else{
    //                 setUnitListOptions([]);
    //                 formik.setFieldValue("unitNo", "");
    //             }
    //         }
    //     }        
    // },[propertyList, buildingIdList, formik.values.building_id]);

    useEffect(() => {
        if (Array.isArray(masterData.response)) {
            if (masterData.response.length > 0) {
                const requestTypeVal = masterData.response.find((el: any) => el.id === 23);
                const outcomeVal = masterData.response.find((el: any) => el.id === 24);
                setRequestTypeOptions(Array.isArray(requestTypeVal.master_data) ? requestTypeVal.master_data : [] );
                setOutcomeOptions(Array.isArray(outcomeVal.master_data) ? outcomeVal.master_data : [] );
                const defaultTypeVal = requestTypeVal?.master_data?.find((el:any)=>el.name === 'Maintenance')
                formik.setFieldValue("requestType", defaultTypeVal.value);
            }
        }
    }, [masterData]);
    useEffect(() => {
        if (Array.isArray(requestSubTypes.response)) {
            if (requestSubTypes.response.length > 0) {
                let filteredRequestSubType = requestSubTypes.response.find((el: any) => el.id == formik.values.requestType)
                // console.log(filteredRequestSubType?.activity_request_type, 'filteredRequestSubType');
                const filteredList = filteredRequestSubType?.activity_request_type?.filter((el: any) => el.name !== 'Fire Alarm')
                // setRequestSubTypeList(filteredRequestSubType?.activity_request_type ? filteredRequestSubType.activity_request_type : []);
                setRequestSubTypeList(filteredList ? filteredList : []);
            }
        }
    }, [requestSubTypes, formik.values.requestType]);

    useEffect(() => {
        // setUserError('')
        const selectedUnit = formik.values.unitNo
        if (userList?.response && unitListOptions && selectedUnit) {
            const selectedProperty = unitListOptions.find((el: any) => el.id == selectedUnit);
            const selectedUser = userList?.response?.find((el: any) => el.property_code === selectedProperty?.property_code);
            if (selectedUser) {
                setTenantListOptions(selectedUser);
                formik.setFieldValue("tenantName", selectedUser.id)
                formik.setFieldValue("contactNumber", selectedUser.mobile)
            } else {
                setTenantListOptions(undefined);
                formik.setFieldValue("tenantName", "");
                formik.setFieldValue("contactNumber", "")
                // setUserError('No tenant exist for selected unit!')
            }
        } else {
            setTenantListOptions(undefined);
            formik.setFieldValue("tenantName", "");
            formik.setFieldValue("contactNumber", "")
        }
    }, [formik.values.building_id, formik.values.unitNo])

    // function successPopup() {
        // formik.resetForm();
        // var ev = new Event('click', { bubbles: true });
        // successRef.current.dispatchEvent(ev);
        /* setTimeout(()=>{
            window.location.replace("/fc-workorder/open/corrective");
        },2000) */
    // }

    // const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
    //     // console.log(country, phoneNumber);

    //     formik.setFieldValue('contactNumber', phoneNumber)
    //     formik.setFieldTouched('contactNumber')
    // }

    const onValueChange = (phoneNumber:any) => {
        // console.log(phoneNumber);
        if(phoneNumber[0] && phoneNumber[0] !== '+')
            phoneNumber = '+' + phoneNumber
        formik.setFieldValue('contactNumber', phoneNumber)
    	formik.setFieldTouched('contactNumber')
    };

    const handleBuildingSelect = (val: any) => {
        // formik.handleChange(e)
        formik.setFieldValue('building_id', val)
        formik.setFieldValue('unitNo', '')
        formik.setFieldTouched('unitNo', false)
      }

      const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
        // if(phoneNumber[0] && phoneNumber[0] !== '+')
        // phoneNumber = '+' + phoneNumber
        formik.setFieldValue('contactNumber', phoneNumber)
        formik.setFieldTouched('contactNumber')
      }

    const loading = isLoading.unit || isLoading.type ||isLoading.category ||isLoading.tenant
    const loadingError = buildingUnitList.length === 0 ? t('No unit data found!')
        : requestTypeOptions.length === 0 ? t('No request type data found!')
        : requestSubTypes?.response?.length === 0 ? t('No request category data found!')
        // : requestSubTypeList?.length === 0 ? 'No request category data found'
        : outcomeOptions.length === 0 ? t('No outcome data found!')
        // : userList?.response?.length === 0 ? 'No tenant data found!'
        // : formik.values.unitNo !=='' && tenantListOptions === undefined ? 'No tenant data found for selected unit!'
        : undefined

    const showTenantInfo = user_role ? ['FMT', 'FMSM'].includes(user_role) : false;

    return (
        <div className="com-card-section myunit-page">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        {/* <Link to={'/activities/list/open'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </Link> */}
                        <span className='c-pointer' onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        <span className='text-black'>{t("Add Activity")}</span>
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            {loading?
                                <CustomLoaderCompact />
                                : loadingError ?
                                        <div className='alert alert-danger w-100 text-center' role='alert'>
                                            {loadingError}
                                        </div>
                                    :''
                            }
                            {/* <LoadingErrorAlert loading={loading} loadinError={loadingError} /> */}
                            <div className="row">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row form-add-design">
                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">{t("Building Name")}
                                                    <span className="text-danger"> * </span>
                                                </label>
                                                <Select
                                                    className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                                                    showSearch
                                                    placeholder={t("Select a Building")}
                                                    optionFilterProp="children"
                                                    defaultValue={formik.values.building_id!==''?formik.values.building_id :null}
                                                    onChange={handleBuildingSelect}
                                                    onBlur={formik.handleBlur}
                                                    filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                                    }
                                                >
                                                    {listBuilding?.map((el: any, index: number) => 
                                                    <Option key={index} value={el.id}>{el.name}</Option>
                                                    )}
                                                </Select>
                                                {/* <select
                                                    {...formik.getFieldProps('building_id')}
                                                    className="form-control form-select fw-bolder form-control-solid">
                                                    <option value="">Select</option>
                                                    {listBuilding?.map((el: any, index: number) => {
                                                        return (
                                                            <option key={index} value={el.id}>{el.name}</option>
                                                        )
                                                    })}
                                                </select> */}
                                                {formik.touched.building_id && formik.errors.building_id ? (
                                                    <small className="text-danger">
                                                        {formik.errors.building_id}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">{t("Unit")}
                                                    <span className="text-danger"> * </span>
                                                </label>
                                                <Select
                                                    className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                                                    showSearch
                                                    placeholder={t("Select a Unit")}
                                                    optionFilterProp="children"
                                                    value={formik.values.unitNo!==''?formik.values.unitNo :null}
                                                    onChange={(val)=> {formik.setFieldValue("unitNo", val)}}
                                                    onBlur={formik.handleBlur}
                                                    filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                                    }
                                                >
                                                    {unitListOptions?.map((el: any, index: number) => 
                                                    <Option key={index} value={el.id}>{el.property_code}</Option>
                                                    )}
                                                </Select>
                                                {/* <select
                                                    {...formik.getFieldProps('unitNo')}
                                                    className="form-control form-select fw-bolder form-control-solid">
                                                    <option value="">Select</option>
                                                    {unitListOptions?.map((el: any, index: number) => {
                                                        return (
                                                            <option key={index} value={el.id}>{el.property_code}</option>
                                                        )
                                                    })}
                                                </select> */}
                                                {formik.touched.unitNo && formik.errors.unitNo ? (
                                                    <small className="text-danger">
                                                        {formik.errors.unitNo}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        { showTenantInfo &&
                                            <>
                                            
                                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                    <div className="mb-7">
                                                        <label className="form-label fw-bolder">{t("Tenant Name")}
                                                            {/* <span className="text-danger"> * </span> */}
                                                        </label>
                                                        <input
                                                            value={tenantListOptions ? tenantListOptions.first_name + ' ' + tenantListOptions.last_name :''}
                                                            // {...formik.getFieldProps('tenantName') }
                                                            type="text" className="form-control fw-bolder form-control-solid bg-light" placeholder=""
                                                            readOnly
                                                            />
                                                        {/* <select
                                                            {...formik.getFieldProps('tenantName')}
                                                            className="form-control form-select fw-bolder form-control-solid">
                                                            <option value="">Select</option>
                                                            {tenantListOptions &&
                                                                <option key={tenantListOptions.id} value={tenantListOptions.id}>{tenantListOptions.first_name + ' ' + tenantListOptions.last_name}</option>
                                                            }
                                                        </select> */}
                                                        {formik.touched.tenantName && formik.errors.tenantName ? (
                                                            <small className="text-danger">
                                                                {formik.errors.tenantName}
                                                            </small>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                    <div className='mb-7'>
                                                        <label className='form-label fw-bolder d-block'>{t("Contact Number")}
                                                            {/* <span className="text-danger"> * </span> */}
                                                        </label>
                                                        <input
                                                            value={formik.values.contactNumber}
                                                            // {...formik.getFieldProps('contactNumber') }
                                                            type="text" className="form-control fw-bolder form-control-solid bg-light" placeholder=""
                                                            readOnly
                                                            />
                                                        {/* <PhoneInput
                                                            country={'ae'}
                                                            preferredCountries={['ae']}
                                                            value={formik.values.contactNumber}
                                                            onChange={onValueChange}
                                                            enableSearch={true}
                                                            enableAreaCodeStretch={true}
                                                            inputClass={"text-black"}
                                                        /> */}
                                                        {/* <PhoneInput
                                                                    country={'ae'}
                                                                    preferredCountries={['ae', 'in', 'us']}
                                                                    inputClass='form-control fw-bolder form-control-solid'
                                                                    // inputStyle={{height: '43px!important'}}
                                                                    value={formik.values.contactNumber}
                                                                    onChange={onValueChange}
                                                                    /> */}

                                                            {/* <IntlTelInput
                                                            preferredCountries={['ae', 'in', 'us']}
                                                            containerClassName='intl-tel-input w-100'
                                                            separateDialCode={true}
                                                            fieldName='contactNumber'
                                                            value={formik.values.contactNumber}
                                                            inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                                            onPhoneNumberChange={handlePhoneNumber}
                                                            placeholder="Contact Number"
                                                        /> */}
                                                        
                                                        
                                                        {/* <IntlTelInput
                                                            preferredCountries={['ae', 'in', 'us']}
                                                            containerClassName='intl-tel-input w-100'
                                                            separateDialCode={true}
                                                            fieldName='contactNumber'
                                                            value={formik.values.contactNumber}
                                                            inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                                            onPhoneNumberChange={handlePhoneNumber}
                                                            placeholder=""
                                                        /> */}
                                                        {formik.touched.contactNumber && formik.errors.contactNumber ? (
                                                            <small className="text-danger d-block">
                                                                {formik.errors.contactNumber}
                                                            </small>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Request Type")} <span className="text-danger"> * </span> </label>
                                                <select
                                                    {...formik.getFieldProps('requestType')}
                                                    className="form-control form-select fw-bolder form-control-solid">
                                                    <option value="">{t("Select")}</option>
                                                    {requestTypeOptions?.map((el: any, index: number) => {
                                                        return (
                                                            <option key={index} value={el.value}>{el.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {formik.touched.requestType && formik.errors.requestType ? (
                                                    <small className="text-danger">
                                                        {formik.errors.requestType}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Request Category")}<span className="text-danger"> * </span> </label>
                                                <select
                                                    {...formik.getFieldProps('requestSubType')}
                                                    className="form-control form-select fw-bolder form-control-solid">
                                                    <option value="">{t("Select")}</option>
                                                    {requestSubTypeList?.map((el: any, index: number) => {
                                                        return (
                                                            <option key={index} value={el.id}>{el.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {formik.touched.requestSubType && formik.errors.requestSubType ? (
                                                    <small className="text-danger">
                                                        {formik.errors.requestSubType}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Outcome")} </label>
                                                <select
                                                    {...formik.getFieldProps('outcome')}
                                                    className="form-control form-select fw-bolder form-control-solid">
                                                    <option value="">{t("Select")}</option>
                                                    {outcomeOptions?.map((el: any, index: number) => {
                                                        return (
                                                            <option key={index} value={el.value}>{el.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {formik.touched.outcome && formik.errors.outcome ? (
                                                    <small className="text-danger">
                                                        {formik.errors.outcome}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">{t("Description")} <span className="text-danger"> * </span> </label>
                                                <textarea
                                                    {...formik.getFieldProps('description')}
                                                    className='form-control fw-bolder form-control-solid'
                                                    data-kt-autosize='true'
                                                    rows={2}
                                                    placeholder={t('Type here...')}
                                                />
                                                {formik.touched.description && formik.errors.description ? (
                                                    <small className="text-danger">
                                                        {formik.errors.description}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                            <div className="mb-7 upload-input">
                                                <label className="form-label fw-bolder d-block">{t("Media Upload")}
                                                    {/* <span className="text-danger"> * </span> */}
                                                </label>
                                                <input
                                                    ref={inputRef}
                                                    className="d-none"
                                                    type="file"
                                                    accept=".png,.jpg,.jpeg"
                                                    onChange={(e) => handleDisplayFileDetails(e)}
                                                    // onBlur={formik.handleBlur}
                                                     />
                                                <button type='button' onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`} disabled={uploading}>
                                                    <span>
                                                        {uploading ?
                                                            <span className='indicator-progress d-block'>
                                                                {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                            : uploadedFileName ? uploadedFileName : <span className="txt">
                                                        <img
                                                                alt="File"
                                                                src={uploadImg}
                                                        className="position-relative"
                                                        loading="lazy"/>
                                                        {t("Upload")}</span>}
                                                    </span>
                                                </button>
                                                {formik.touched.mediaUpload && formik.errors.mediaUpload ? (
                                                    <div className='d-flex flex-column text-danger'>
                                                        <small className="text-danger">{formik.errors.mediaUpload}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <div className="row justify-content-end">
                                                <div className="col-lg-2 col-md-3 col-6">
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-primary fw-bold px-10 py-3 btn-block bg-white'
                                                        disabled={submitStatus.loading}
                                                        onClick={() => {
                                                            formik.resetForm()
                                                            history.goBack()
                                                        }}
                                                    >
                                                        {t("Cancel")}
                                                    </button>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-6">
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary fw-bold px-10 py-3 btn-block'
                                                        disabled={submitStatus.isSubmitted || submitStatus.loading || loading || uploading}
                                                    >
                                                        {submitStatus.loading ? 
                                                            <span className='indicator-progress d-block'>
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                                                            {t("Submit")}
                                                            </span>
                                                            :
                                                            <span className='indicator-label'>{t("Submit")}</span>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <LoadingSubmitAlert submitStatus={submitStatus}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='d-none'
              ></span>
        </div>
    );
}