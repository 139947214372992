export function AdditionalInfo({ chooseMessage }: any) {
    return (
        <>
            <div className="row">
                <div className="card mb-6">
                    <div className="card-body">
                        <div className="row">

                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13"> STR # * </label>
                                    <input type="text" className="form-control font-13 form-height" value="" id="" name="str_number" placeholder="STR" />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13"> Ready to Occupancy </label>
                                    <input type="date" className="form-control font-13 form-height" value="" id="" name="next_available" placeholder="Date" />
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13"> POA </label>
                                    <input type="text" className="form-control font-13 form-height" value="" id="" name="is_poa" placeholder="POA" />

                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Property Status</label>
                                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                        <option selected> Please Select </option>
                                        <option > Active</option>
                                        <option> Inactive</option>
                                        <option> Draft </option>
                                        <option> Sold</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Offer</label>
                                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                        <option selected> Please Select </option>
                                        <option > Yes</option>
                                        <option> No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13"> Offer Valid Date </label>
                                    <input type="date" className="form-control font-13 form-height" value="" id="" name="offer_valid_date" placeholder="Date" />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Premium</label>
                                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                        <option selected> Please Select </option>
                                        <option > Yes</option>
                                        <option> No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card priview-community">
                    <div className="card-body">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed accodion-tab" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                         Property Portal
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Guest Parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Gym
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Air Conditioner
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                    <label className="form-check-label">
                                                        Power Backup
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Hospital
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Electrical Point (Car Charging)
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Badminton Court
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                    <label className="form-check-label">
                                                        Swimming Pool
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Library
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                    <label className="form-check-label">
                                                        Golf Course
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Bicycle Track
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Gated Community
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Jacuzzi
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Barbeque
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                    <label className="form-check-label">
                                                        Bar
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Sustainable Community
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Built in wardrobes
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Covered parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Maid's room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private garage
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private garden
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        24 hours Maintenance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Bank/ATM Facility
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Basketball Court
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Business Center
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Children's play area
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Clubhouse
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Clubhouse
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Concierge service
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Cycling tracks
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Laundry Service
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Tennis courts
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                    <label className="form-check-label">
                                                        Squash courts
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Basement parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private swimming pool
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Walking Trails
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Fitness Center
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Balcony
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Driver's Room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Carpets
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Dining in building
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private gym
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Conference room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Prayer room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Walk-in Closet
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Built in Kitchen Appliances
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Pets Allowed
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Landscaped Garden
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Laundry/washing room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Storage room
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed accodion-tab" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Auto Lead Creation
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Guest Parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Gym
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Air Conditioner
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Power Backup
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Hospital
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Electrical Point (Car Charging)
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Badminton Court
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Swimming Pool
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Library
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Golf Course
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Bicycle Track
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Gated Community
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Jacuzzi
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Barbeque
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Bar
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Sustainable Community
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Built in wardrobes
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Covered parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Maid's room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private garage
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private garden
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        24 hours Maintenance
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Bank/ATM Facility
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Basketball Court
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Business Center
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Children's play area
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Clubhouse
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Clubhouse
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Concierge service
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Cycling tracks
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Laundry Service
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Tennis courts
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Squash courts
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Basement parking
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private swimming pool
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Walking Trails
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Fitness Center
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Balcony
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Driver's Room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Carpets
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Dining in building
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Private gym
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Conference room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Prayer room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Walk-in Closet
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Built in Kitchen Appliances
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Pets Allowed
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Landscaped Garden
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Laundry/washing room
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">
                                                        Storage room
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-end my-6">
                <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('4')}>Previous</button>
                <button type="submit" className="btn btn-primary fw-bold px-10 py-3">Finish</button>
            </div>
        </>
    )
}