import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function SelectListingPopup() {
    return ( 
        <> 
           <div className="modal fade prop-enq-modal" id="prop-enq-modal">
                <div className="modal-dialog overflow-hidden modal-xl">
                    <div className="modal-content overflow-hidden border-0">
                        <div className="modal-header bg-primary text-white">
                            <h5 className="modal-title font-16 text-white font-weight-semibold">Select Listing</h5>
                            <button type="button" className="close text-warning bg-white rounded-circle" data-bs-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body p-4">
                            <form >
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <p className="font-12">
                                            Use this table to search and select the appropriate listing.
                                        </p>
                                    </div>
                                    <div className="col-md-6 text-end mb-4">
                                        <button type="reset" className="btn btn-outline-primary px-4 py-2 font-weight-semibold">Reset</button>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="prop-detail-tab">
                                        
                                            <div className="">
                                                <div id="default-count-list" className="default-count-list">
                                                    <div className="row">
                                                        <div className="col-md-12 mb-4 ox-auto table-res-contain remove-abs">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered mb-0 font-12 text-nowrap" id="unit-listing-table">
                                                                    <thead className="text-uppercase bg-projects-list font-weight-normal">
                                                                    <tr>
                                                                        <th>  </th>
                                                                        <th className="">Ref</th>
                                                                        <th className="">Unit</th>
                                                                        <th className="">Category</th>
                                                                        <th className="">Unit Type</th>
                                                                        <th className="">Community</th>
                                                                        <th className="">Building</th>
                                                                        <th className="">Bedroom</th>
                                                                        <th className="">Bathroom</th>
                                                                        <th className="">Plot Area</th>
                                                                        <th className="">Listing Agent</th>
                                                                        <th style={{"position" : "unset"}} className="bg-transparent font-ab asc-align">
                                                                            Price (AED)
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th><input type="text" className="form-control font-10 py-2"  placeholder="Min 3 chars"/></th>
                                                                        <th><input type="text" className="form-control font-10 py-2" placeholder="Min 3 chars"/></th>
                                                                        <th>
                                                                            <select className="form-control font-12 py-2">
                                                                                
                                                                            </select>
                                                                        </th>
                                                                        <th>
                                                                            <select className="form-control font-10 py-2" data-title="Select">
                                                                                <option value="1"> Sale </option>
                                                                                <option value="2"> Lease </option>
                                                                            </select>
                                                                        </th>
                                                                        <th>
                                                                            <input type="text" className="form-control font-10 py-2" placeholder="Min 3 chars"/>
                                                                        </th>
                                                                        <th>
                                                                            <input type="text" className="form-control font-10 py-2" placeholder="Min 3 chars"/>
                                                                        </th>
                                                                        <th>
                                                                            <select className="form-control font-10 py-2">
                                                                                
                                                                            </select>
                                                                        </th>
                                                                        <th>
                                                                            <select className="form-control font-10 py-2" >
                                                                                
                                                                            </select>
                                                                        </th>
                                                                        <th>
                                                                            <input type="text" className="form-control font-10 py-2" placeholder="Min 3 chars"/>
                                                                        </th>
                                                                        <th></th>
                                                                        <th style={{position: "unset"}} className="bg-transparent border-transparent"><input type="text" className="form-control font-10 py-2" placeholder="Min 3 chars"/></th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody className="bg-white">
                                                                        <tr role="row" className="odd">
                                                                            <td>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="sorting_1">REF-UN-1689574310</td>
                                                                            <td className="sorting_2">test</td>
                                                                            <td>Bunglaow</td>
                                                                            <td>Sale</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td>Studio</td>
                                                                            <td>Studio</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td className=" contact-last-column">0.00</td>
                                                                        </tr>
                                                                        <tr role="row" className="odd">
                                                                            <td>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="sorting_1">REF-UN-1689311269</td>
                                                                            <td className="sorting_2">test</td>
                                                                            <td>Apartment</td>
                                                                            <td>Sale</td>
                                                                            <td>Belgravia Square</td>
                                                                            <td>Belgravia Square 3</td>
                                                                            <td>Studio</td>
                                                                            <td>Studio</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td className=" contact-last-column">60,000.00</td>
                                                                        </tr>
                                                                        <tr role="row" className="odd">
                                                                            <td>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="sorting_1">REF-UN-1689310884	</td>
                                                                            <td className="sorting_2">test</td>
                                                                            <td>Villa</td>
                                                                            <td>Sale</td>
                                                                            <td>WIlton Park Residencies</td>
                                                                            <td>-</td>
                                                                            <td>Studio</td>
                                                                            <td>Studio</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td className=" contact-last-column">0.00</td>
                                                                        </tr>
                                                                        <tr role="row" className="odd">
                                                                            <td>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="sorting_1">REF-UN-1689574310</td>
                                                                            <td className="sorting_2">test</td>
                                                                            <td>Bunglaow</td>
                                                                            <td>Sale</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td>Studio</td>
                                                                            <td>Studio</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td className=" contact-last-column">0.00</td>
                                                                        </tr>
                                                                        <tr role="row" className="odd">
                                                                            <td>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="sorting_1">REF-UN-1689574310</td>
                                                                            <td className="sorting_2">test</td>
                                                                            <td>Bunglaow</td>
                                                                            <td>Sale</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td>Studio</td>
                                                                            <td>Studio</td>
                                                                            <td>-</td>
                                                                            <td>-</td>
                                                                            <td className=" contact-last-column">0.00</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button type="button" className="btn btn-primary px-4 font-12 font-weight-semibold radius-10" id="button-apply-property">Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
	                
        </>
    )
}