import React from 'react'
import {useIntl} from 'react-intl'
import { useTranslation } from 'react-i18next'

export function SearchDetails() {
    const intl = useIntl()
    const { t } = useTranslation();

    return (
        <>
        <div className='row'>
            <div className='col-lg-12 my-auto'>
                <div className="modal fade add-broker-modal" aria-hidden='true' id="SearchDetails">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header px-7 py-7 pop_model_c">
                                <div className="form-group has-search">
                                    <span className="fa fa-search pt-2 form-control-feedback"></span>
                                    <input type="text" className="form-control ps-10" placeholder={t("Search")}/>
                                </div>
                            </div>
                            <div className="modal-body px-10 pt-0">
                                <div className="row">
                                    <div className="table-responsive fc-su-ser-tabel px-0">
                                        <table className="table align-middle table-striped gy-4 gx-4 mb-4">
                                            <thead>
                                            <tr>
                                                <th>{t("No")}</th>
                                                <th>{t("Name")}</th>
                                                <th>{t("Mobile Number")}</th>
                                                <th>{t("Action")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Muhhamed</td>
                                                <td>98987 87878</td>
                                                <td><input className="form-check-input fc-wo-checkbox" type="checkbox" id="flexCheckDefault"/></td>
                                            </tr>
                                            <tr>
                                                <td>01</td>
                                                <td>Muhhamed</td>
                                                <td>98987 87878</td>
                                                <td><input className="form-check-input fc-wo-checkbox" type="checkbox" id="flexCheckDefault"/></td>
                                            </tr>
                                            <tr>
                                                <td>01</td>
                                                <td>Muhhamed</td>
                                                <td>98987 87878</td>
                                                <td><input className="form-check-input fc-wo-checkbox" type="checkbox" id="flexCheckDefault"/></td>
                                            </tr>
                                            <tr>
                                                <td>01</td>
                                                <td>Muhhamed</td>
                                                <td>98987 87878</td>
                                                <td><input className="form-check-input fc-wo-checkbox" type="checkbox" id="flexCheckDefault"/></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
