import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import { CompletedTableData } from '../FacilityWorkorderTables/CompletedTableData/CompletedTableData';
import { OnHoldTableData } from '../FacilityWorkorderTables/OnHoldTableData/OnHoldTableData';
import { OpenTableData } from '../FacilityWorkorderTables/OpenTableData/OpenTableData';
import { PreventiveTabs } from './PreventiveTabs';




export const PreventiveWorkorderWrapper:React.FC<any> = ({fcsWorkOrderList, workOrder, loading}) => {

    const location = useLocation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3 movein-moveout ">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <Switch>
                                        <Route path='/facility-workorder/list/preventive/open'>
                                            <PreventiveTabs />
                                            <OpenTableData
                                                fcsWorkOrderList={fcsWorkOrderList}
                                                workOrder={workOrder}
                                                loading={loading}
                                            />
                                        </Route>
                                        <Route path='/facility-workorder/list/preventive/onhold'>
                                            <PreventiveTabs />
                                            <OnHoldTableData
                                                fcsWorkOrderList={fcsWorkOrderList}
                                                workOrder={workOrder}
                                                loading={loading}
                                            />
                                        </Route>
                                        <Route path='/facility-workorder/list/preventive/completed'>
                                            <PreventiveTabs />
                                            <CompletedTableData
                                                fcsWorkOrderList={fcsWorkOrderList}
                                                workOrder={workOrder}
                                                loading={loading}
                                            />
                                        </Route>
                                        <Redirect to='/error/403' />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}