import React, {useState, useEffect, useRef} from 'react'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import VisitorAvatar from '../../../../assets/images/SquareAvatar.png'
import {getUserRole} from '../../../../constants/generics/userCheck'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {PopupModel} from './PopupModel'
import {
  formatDate,
  formatTimeMeridiem,
} from '../../../../constants/generics/dateTimeFormater'
import moment from 'moment'
import {format} from 'path'
import {useSelector} from 'react-redux'
import { ImageViewerWithPopup } from '../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup'
import { useTranslation } from 'react-i18next'
import { scheduleVisitList } from '../../../../constants/axios/apis'
import { CustomLoader2 } from '../../../../constants/generics/customActios'
import NoImage from '../../../../assets/images/home.png'

export function ScheduledView() {
  let params: any = {}
  params = useLocation()

  let scheduledId = ''
  if (params.state.scheduledData) {
    scheduledId = params.state.scheduledData.id
  }

  const [scheduledData, setScheduledData] = useState<any>({})
  const [loading, setLoading]=useState(true)
  const history = useHistory()
  const [newDate, setNewDate] = useState<any>()
  const successRef = useRef<any>()
  // const status2= scheduledData?.status===
  const { t } = useTranslation();
  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  const handlePopup = () => {
    setNewDate(new Date())
    successRef.current.click()
  }

  useEffect(() => {    
    setLoading(true)
    setScheduledData({})
      scheduleVisitList(`?id=${scheduledId}`).then((response: any) => {
      if (response?.data && Array.isArray(response.data) && response.data.length > 0) 
        setScheduledData(response.data[0])
      setLoading(false)
    })
  }, [scheduledId])
  const building_img = scheduledData?.communityLogo && scheduledData?.communityLogo.length ? scheduledData?.communityLogo : NoImage

  return (
    <div className='com-card-section'>
      {
          loading?
          <CustomLoader2 /> :
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <span className='c-pointer' onClick={() => { history.goBack() }} >
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </span>
            <span className='text-black fw-bolder mb-5'>
              {t("Scheduled Visit Details")}
              {/* Status 1 - In 2 Out 4 not visited 0 upcoming  */}
              {/* {scheduledData?.status === 2
                ? 'Checked Out Details'
                : scheduledData?.status === 0
                ? 'Upcoming Details'
                : scheduledData?.status === 4
                ? 'Not Visted Details'
                : scheduledData?.status === 1
                ? 'Checked In Details'
                : scheduledData?.status} */}
            </span>
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-6 py-6'>
              <div className='row'>
                <div className='col-md-4 col-lg-3 col-12'>
                  <div className='approval-move mb-5'>
                    <img src={building_img} className="img-fluid rounded-5" />
                  </div>
                </div>

                <div className='col-md-8 col-lg-9 col-12'>
                  <div className='row'>
                    <div className='col-12 mb-5 text-end'>
                      <span
                        className={
                          scheduledData?.eventStatus === 2
                            ? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                            : scheduledData?.eventStatus === 4
                            ? 'bg-info bg-opacity-10 text-info px-5 py-3 rounded fw-bolder'
                            : scheduledData?.eventStatus === 5
                            ? 'bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                            : scheduledData?.eventStatus === 1
                            ? 'bg-success bg-opacity-10 text-primary px-5 py-3 rounded fw-bolder'
                            : scheduledData?.eventStatus === 3
                            ? 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                            : scheduledData?.eventStatus === 6
                            ? 'bg-warning bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                            : 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                        }
                      >
                        {' '}
                        {scheduledData?.eventStatus === 2
                          ? (t('Completed'))
                          : scheduledData?.eventStatus === 4
                          ? (t('Re-Scheduled'))
                          : scheduledData?.eventStatus === 5
                          ? (t('Expired'))
                          : scheduledData?.eventStatus === 1
                          ? (t('Upcoming'))
                          : scheduledData?.eventStatus === 3
                          ? (t('Ongoing'))
                          : scheduledData?.eventStatus === 6
                          ? (t('Cancelled'))
                          : scheduledData?.eventStatus}
                      </span>
                    </div>
                    <div className='col-md-4 col-6 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Name")} </h6>
                      <span className='text-black fw-bold font-15'>
                        {' '}
                        {scheduledData?.visitor ? scheduledData?.visitor : '--'}{' '}
                      </span>
                    </div>
                    <div className='col-md-4 col-6 mb-5'>
                      <div className=''>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Mobile Number")} </h6>
                        <span className='text-black fw-bold font-15'>
                          {' '}
                          {scheduledData?.mobile ? scheduledData?.mobile : '--'}{' '}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-6 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Email ID")} </h6>
                      <span className='text-black fw-bold font-15'>
                        {' '}
                        {scheduledData.emailID ? scheduledData.emailID : '--'}{' '}
                      </span>
                    </div>
                    <div className='col-md-4 col-6'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Emirates ID")} </h6>
                      <span className='text-black fw-bold font-15'>
                        {' '}
                        {scheduledData.emiratesID ? scheduledData.emiratesID : '--'}{' '}
                      </span>
                    </div>
                    <div className='col-md-4 col-6'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Building")} </h6>
                      <span className='text-black fw-bold font-15'>
                        {' '}
                        {scheduledData?.building ? scheduledData?.building : '--'}
                      </span>
                    </div>
                    <div className='col-md-4 col-6'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Unit")} </h6>
                      <span className='text-black fw-bold font-15'>
                        {' '}
                        {scheduledData?.propertyCode ? scheduledData?.propertyCode : '--'}
                      </span>
                    </div>
                    <div className='col-md-8 col-6'>
                      <div className='mt-4'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Property Name")} </h6>
                        <span className='text-black fw-bold font-15'>
                          {scheduledData?.propertyName ? scheduledData?.propertyName : '--'}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-6'>
                      <div className='mt-4'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Address")} </h6>
                        <span className='text-black fw-bold font-15'>
                          {scheduledData?.address ? scheduledData?.address : '--'}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-6'>
                      <div className='mt-4'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Visit Date")} </h6>
                        <span className='text-black fw-bold font-15'>
                          {formatDate(scheduledData?.visit_date)}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-6'>
                      <div className='mt-4'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Time")} </h6>
                        <span className='text-black fw-bold font-15'>
                          {formatTimeMeridiem(scheduledData?.Time ? scheduledData?.Time : '--')}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-6'>
                      <div className='mt-4'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("QR Code")} </h6>
                        {/* <div className='fcs-qr-code'> */}
                          <ImageViewerWithPopup
                            imageFile={scheduledData?.qrCode}
                            noImage={!scheduledData?.qrCode ? true: false }
                            // className='img-fluid rounded'
                            // alt='QR Code'
                          />
                        {/* </div> */}
                      </div>
                    </div>
                    {/* {scheduledData.status !== 0 && (
                      <div className='col-md-4 col-6'>
                        <div className='mt-4'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            CheckedIn Date/Time{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {scheduledData.check_in
                              ? formatDateTimeMeridiem(scheduledData.check_in)
                              : '--'}
                          </span>
                        </div>
                      </div>
                    )}
                    {scheduledData.status === 2 && (
                      <div className='col-md-4 col-6'>
                        <div className='mt-4'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            CheckedOut Date/Time
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {scheduledData.check_out
                              ? formatDateTimeMeridiem(scheduledData.check_out)
                              : '--'}
                          </span>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {user_role === 'FCSS' && (scheduledData?.status === 0 || scheduledData?.status === 4) && (
          <div className='col-12 text-end mt-5'>
            <button
              type='submit'
              className='btn btn-primary fw-bold px-6 py-3'
              data-bs-toggle='modal'
              data-bs-target='#PopupModel'
            >
              Check-In
            </button>
          </div>
        )}
        {user_role === 'FCSS' && scheduledData?.status === 1 && (
          <div className='col-12 text-end mt-5'>
            <button
              type='submit'
              className='btn btn-primary fw-bold px-6 py-3'
              data-bs-toggle='modal'
              data-bs-target='#PopupModel'
            >
              Check-Out
            </button>
          </div>
        )} */}
      </div>
      }
      {/* <PopupModel scheduledData={scheduledData} today={newDate} /> */}
      </div>
  )
}
