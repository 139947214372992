import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
// import NoImage from "../../../../../assets/images/no_image.png";
import NoImage from "../../../../../assets/images/House.png";
import { formatTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { ImageViewerWithPopup } from '../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';
import { CorrectiveWorkorderActions } from './CorrectiveWorkorderActions';
import { StartAndOnHoldWO } from './StartAndOnHoldWO';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

export function CorrectiveWorkorderDetails({workOrder, setPopupSuccessMsg}:any) {
    const { t } = useTranslation();

    const history = useHistory();

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    const userToken = state?.auth?.accessToken;

    // const [workOrderImage, setWorkOrderImage] = useState<any>(NoImage);
    const [woFilterData, setWoFilterData] = useState<any>({
        date: '',
        time: '',
        desc: '',
        image: NoImage,
        building_img: NoImage,
    });
    useEffect(() => {
        // (workOrder.issueCategory !== 'Clearance' && workOrder.typeText !== 'Move Out')
        if (workOrder.issueCategory === 'Clearance') {
            // const image = workOrder.buildings && workOrder.buildings[0] ? workOrder.buildings[0].cover_image : NoImage
            const building_img = workOrder.buildings && workOrder.buildings[0] ? workOrder.buildings[0].communityLogo : NoImage
            setWoFilterData({
                date: moment(workOrder.objectDetails?.referenceDetails?.date).format("DD-MM-YYYY"),
                time: workOrder.objectDetails?.referenceDetails?.time_slot,
                desc: workOrder.objectDetails?.referenceDetails?.comments,
                // image: (image && image.length) ? image : NoImage,
                image: '',
                building_img: (building_img && building_img.length) ? building_img : NoImage
            })
        } else {
            const building_img = workOrder.buildings && workOrder.buildings[0] ? workOrder.buildings[0].communityLogo : NoImage
            const image = workOrder?.objectDetails?.attachments_json?.photo &&
                workOrder?.objectDetails?.attachments_json?.photo[0] ? workOrder?.objectDetails?.attachments_json?.photo[0] : NoImage
            setWoFilterData({
                date: moment(workOrder.date).format("DD-MM-YYYY"),
                time: formatTimeMeridiem(workOrder.time),
                desc: workOrder.description,
                image: (image && image.length) ? image : NoImage,
                building_img: (building_img && building_img.length) ? building_img : NoImage
            })
        }
        // if(Array.isArray(workOrder?.objectDetails?.attachments_json?.photo)){
        //     if(workOrder?.objectDetails?.attachments_json?.photo.length > 0){
        //         setWorkOrderImage(workOrder?.objectDetails?.attachments_json?.photo[0]);
        //     }
        // }
    }, []);

    // const redirectLink  = workOrder.issueCategory === 'Corrective' ? '/workorders/clearance/open' : '/workorders/corrective/open';
    const isOnHold = workOrder.status === 'On hold'
    const isPending =  workOrder.is_FMapproved === 0 || (user_role === 'FC' && workOrder.is_FCSreassigned === 0)
    const statusText = workOrder.is_FMapproved === 0 ? t('FM Approval Pending')
        : user_role === 'FCS' ?
            t('FM Approved')
        :workOrder.is_FCSreassigned === 0 ? t('FCS Re-Assign Pending')
            : t('Approved & Re-Assigned')
    // : workOrder.is_FCSreassigned === 0 ? 'FCS Re-Assign Pending'
    //     // : 'FM Approved'
    //     : 'FM Approved & Re-Assigned'

    // const allowAssign = workOrder.is_FMapproved === 1
    const isAssignedUser = state?.auth?.user?.data.id === workOrder.assignedToUserId

    return (
        <div className="com-card-section myunit-page">
            <div className="row">
                <div className="col-12">
                    <div className="row flex-stack announcement-sec mb-6 align-items-center">
                        <div className="col-sm-8 col-12">
                            <h4 className="card-title font-18 text-black m-0">
                                {/* <Link to={'/workorders/open/corrective'} className=''> */}
                                <span onClick={history.goBack} className='c-pointer'>
                                    <img src={LeftArrow} alt='image' className="img-fluid me-5 left-arrow-back" />
                                </span>
                                <span className="text-black">
                                    {workOrder.typeText === "Move Out" ? 'Move Out Inspection' : workOrder.typeText}
                                </span>
                                {/* </Link> */}
                            </h4>
                        </div>
                        { (user_role === 'FCS' || user_role === 'FC') && isAssignedUser ?
                            <StartAndOnHoldWO workOrder={workOrder} setPopupSuccessMsg={setPopupSuccessMsg}/>
                            : ''
                        }
                    </div>
                </div>
                <div className="col-12">
                    <div className="card mb-7 overflow-hidden">
                        <div className="card-body p-7">
                            <div className="row border-bottom pb-10 mb-10">
                                { isOnHold &&
                                    <div className="card-status mb-5 mb-md-0">
                                        <h4 className={isPending ? 'pending' : 'approved'}>{statusText}</h4>
                                    </div>
                                }   
                                <div className="col-md-4 col-lg-3">
                                    <div className='approval-move'>
                                        <img src={woFilterData.building_img} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white rounded shadow-none">
                                        <div className="card-body px-0 py-0">
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <span className="text-black fw-bolder font-15 d-block mb-4">
                                                        {t("Work Order Number")} : {workOrder.WorkorderNo}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Unit Number")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrder.property_code} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Issue Type")} </h6>
                                                        <span className="text-black fw-bold font-15"> {workOrder.typeText === "Move Out" ? 'Move Out Inspection' : workOrder.issue_type} </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Appointment Date")} </h6>
                                                    <span className="text-black fw-bold font-15"> {woFilterData.date} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Time")} </h6>
                                                    <span className="text-black fw-bold font-15"> {woFilterData.time} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Priority")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrder.Priority} </span>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14">
                                                        {workOrder.issueCategory === 'Clearance' ? t('Service Request No.') : t('Ticket No.')}
                                                    </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrder.objectDetails?.ticketNo} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h5 className="mb-5">{t("Issue Details")}</h5>
                                <div className="row form-add-design">
                                    {workOrder.issueCategory !== "Clearance" &&
                                        <>
                                            {/* <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> Category <span className="text-danger"> * </span> </label>
                                                    <div className="form-control fw-bolder form-control-solid">
                                                        <h6 className="font-14 fw-bold d-block mb-0 py-1 work-order-input">
                                                            {workOrder.category}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Issue Category")} <span className="text-danger"> * </span> </label>
                                                    <div className="form-control fw-bolder form-control-solid">
                                                        <h6 className="font-14 fw-bold d-block mb-0 py-1 work-order-input">
                                                            {workOrder.subCategory}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Issue Type")} <span className="text-danger"> * </span> </label>
                                                    <div className="form-control fw-bolder form-control-solid">
                                                        <h6 className="font-14 fw-bold d-block mb-0 py-1 work-order-input">
                                                            {workOrder.issue_type}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Precise Location")} <span className="text-danger"> * </span> </label>
                                                    <div className="form-control fw-bolder form-control-solid">
                                                        <h6 className="font-14 fw-bold d-block mb-0 py-1 work-order-input">
                                                            <img src={workOrder?.objectDetails?.referenceDetails?.precise_location_icon} className="img-fluid me-5" style={{ height: '14px' }} />
                                                            {workOrder?.objectDetails?.referenceDetails?.precise_location_name}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Description")} </label>
                                            <textarea
                                                className='form-control fw-bolder form-control-solid'
                                                data-kt-autosize='true'
                                                rows={4}
                                                placeholder={woFilterData.desc}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    {(workOrder.issueCategory !== 'Clearance' && workOrder.typeText !== 'Move Out') &&
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7 upload-input">
                                                <label className="form-label fw-bolder d-block">{t("Attached Image")}</label>
                                                {/* 
                                                {visible &&
                                                <div className="position-relative">
                                                    <div className="upload-image">
                                                        <img src={woFilterData.image} alt={alt} className="form-img__img-preview"/>
                                                    </div>
                                                </div>
                                                }
                                                */}
                                                <ImageViewerWithPopup imageFile={woFilterData.image} noImage={woFilterData.image === NoImage} />
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                            <CorrectiveWorkorderActions workOrder={workOrder} setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}