import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'
import { CreateAudienceModal } from './CreateAudienceModal'

const manageData = [{
    "campaign": "Casa - Mudon - Bellevue - Landing...",
    "account_name": "Alex Samuel",
    "type": "Website traffic",
    "impressions": "10",
    "clicks": "20",
    "total_contacts": "10",
    "cost_per_contacts": "N/A",
    "amount_spent": "0.0%",
    "ctr": "0",
    "cpc": "N/A",
    "status": "Paused"
},
{
    "campaign": "Instagram Post: Mudon Views Offers..",
    "account_name": "Mathew Abraham",
    "type": "Post Engagement",
    "impressions": "12",
    "clicks": "20",
    "total_contacts": "10",
    "cost_per_contacts": "N/A",
    "amount_spent": "0.0%",
    "ctr": "0",
    "cpc": "N/A",
    "status": "Active"
},
{
    "campaign": "Post: 'Bellevue Towers In Downtown...'",
    "account_name": "John Fernadus",
    "type": "Post Engagement",
    "impressions": "0",
    "clicks": "0",
    "total_contacts": "10",
    "cost_per_contacts": "N/A",
    "amount_spent": "0.0%",
    "ctr": "0",
    "cpc": "N/A",
    "status": "Completed"
},
{
    "campaign": "Post: 'La Rosa 2 At Villanova Is The O...'",
    "account_name": "Michael Rustin",
    "type": "Post Engagement",
    "impressions": "10",
    "clicks": "20",
    "total_contacts": "10",
    "cost_per_contacts": "N/A",
    "amount_spent": "0.0%",
    "ctr": "0",
    "cpc": "N/A",
    "status": "Completed"
}
];

export function ManageList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-sm">
                    <h3 className=''>Manage</h3>
                </div>
                <div className="col-sm-auto">
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Create Campaign
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link className="dropdown-item" to={'/email/list'}>Email Campaign</Link></li>
                            <li><Link className="dropdown-item" to={'/social-media/list'}>Social Media Campaign</Link></li>
                            <li><Link className="dropdown-item" to={'/website/list'}>Website Campaign</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-auto text-end">
                    <Link to className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#import-popup"> Create Audience </Link>
                </div>
            </div>
            <div className='card'>
                <div className='card-body p-3'>
                    <div className='com-card-section accounts'>
                        <div className='table-crm2'>
                            <div className='row align-items-center mb-5 table-drop'>
                                <div className="col-10"></div>
                                <div className="col-2 text-end d-flex align-items-center">
                                    <span className="me-2">Show</span>
                                    <select className="form-control form-select">
                                        <option>10 </option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </select>
                                    <span className="ms-2">entries</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='table-responsive'>
                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                            <thead className=''>
                                                <tr>
                                                    <th>Ad Campaign</th>
                                                    <th>Account Name</th>
                                                    <th>Type</th>
                                                    <th>Impressions</th>
                                                    <th>Clicks</th>
                                                    <th>Total Contacts</th>
                                                    <th>Cost per contacts</th>
                                                    <th>Amount Spent</th>
                                                    <th>CTR</th>
                                                    <th>CPC</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {manageData.length > 0 ? manageData.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <div>
                                                                    <img src="https://pm-crm.realcube.estate/img/fb-market-ico.png"/>
                                                                </div>
                                                                <div className="ms-3">
                                                                    <div className='m-0 text-truncate'>{item.campaign}</div>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className={`dot me-2 ${item.status == 'Paused' ? 'pending' : 'complited'}`}></span><span className='font-11'>{item.status}</span>
                                                                        <div className="form-check form-switch ms-3">
                                                                            <input className="form-check-input" type="checkbox"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{item.account_name}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.impressions}</td>
                                                        <td>{item.clicks}</td>
                                                        <td>{item.total_contacts}</td>
                                                        <td>{item.cost_per_contacts}</td>
                                                        <td>{item.amount_spent}</td>
                                                        <td>{item.ctr}</td>
                                                        <td>{item.cpc}</td>
                                                        <td className="action-button text-end border-top border-bottom">
                                                            <div className="dropdown">
                                                                <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                    <i className="fa fa-ellipsis-v text-black"></i>
                                                                </button>
                                                                <div className="dropdown-menu">
                                                                    <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                        <li>
                                                                            <Link to={'edit'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                <i className="fa fa-edit"></i>
                                                                                <p className="m-0">Edit</p>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                <i className="fa fa-eye"></i>
                                                                                <p className="m-0">Preview</p>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='w-100 d-flex justify-content-end'>
                                        <CompactPagination
                                            pageSize={pageSize}
                                            totalCount={manageData.length}
                                            defaultPage={currentPage}
                                            onPageChange={setCurrentPage}
                                            loading={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            < CreateAudienceModal/>

        </>
    )
}
