import React, { useState, useEffect, useRef } from 'react';
import NoImage from "../../../../../../assets/images/House.png";
import { ImageViewerWithPopup } from '../../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';
import { useTranslation } from 'react-i18next';

export function IssueDetails({workOrderDetails, loading}:any) {
    let workOrderImage = NoImage
    if (workOrderDetails?.objectDetails?.attachments_json?.photo && workOrderDetails.objectDetails.attachments_json.photo[0])
        workOrderImage = workOrderDetails.objectDetails.attachments_json.photo[0]
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            {/* <h5 className="mb-5">Issue Details</h5> */}

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row form-add-design ">

                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Issue Category")}</label>
                                                    <div className='data-contain px-5 py-4 rounded-10'>
                                                        <p className='font-14 fw-bold d-block mb-0'>{workOrderDetails?.subCategory}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Issue Type")}</label>
                                                    <div className='data-contain px-5 py-4 rounded-10'>
                                                        <p className='font-14 fw-bold d-block mb-0'>{workOrderDetails?.issue_type}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder"> {t("Precise Location")}</label>
                                                        <div className='data-contain px-5 py-4 rounded-10'>
                                                            <p className='font-14 fw-bold d-block mb-0'>
                                                            <img src={workOrderDetails?.objectDetails?.referenceDetails?.precise_location_icon} className="img-fluid me-5" style={{ height: '14px' }} />
                                                                {workOrderDetails?.objectDetails?.referenceDetails?.precise_location_name}
                                                            </p>
                                                        </div>
                                                    {/* <div className="form-control fw-bolder form-control-solid">
                                                        <h6 className="font-14 fw-bold d-block mb-0 py-1 work-order-input">
                                                            <img src={workOrderDetails?.objectDetails?.referenceDetails?.precise_location_icon} className="img-fluid me-5" style={{ height: '14px' }} />
                                                            {workOrderDetails?.objectDetails?.referenceDetails?.precise_location_name}
                                                        </h6>
                                                    </div> */}
                                                    {/* <input type="text" className="form-control fw-bolder form-control-solid" placeholder=""  value="Bedroom" disabled/> */}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Description")}</label>
                                                    <div className="data-contain h-auto 75 px-5 py-4 rounded-10 mb-2">
                                                        <p className="font-14 fw-bold d-block mb-0">
                                                            {workOrderDetails?.description}
                                                        </p>
                                                    </div>
                                                    {/* <textarea
                                                        className='form-control fw-bolder form-control-solid'
                                                        data-kt-autosize='true'
                                                        rows={4}
                                                        placeholder={workOrderDetails?.description}
                                                        value={workOrderDetails?.description}
                                                        disabled
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder d-block mb-3">{t("Attached Image")}</label>
                                                    <ImageViewerWithPopup imageFile={workOrderImage} noImage={workOrderImage === NoImage} />
                                                </div>
                                            </div>


                                        
                                        {/* <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Contractor Name</h6>
                                            <span className="text-black fw-bold font-14"> Ali </span>
                                        </div> */}
                                        
                                    </div>


                                    {/* <div className="row form-add-design mb-8">
                                        <div className="col-12">
                                            <h4 className="card-title mb-7 font-14 text-primary">Permit to Work Details</h4>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Company Name </h6>
                                            <span className="text-black fw-bold font-14"> Nation Tower </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Applicant's Name </h6>
                                            <span className="text-black fw-bold font-14">NTA03 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Applicant Designation </h6>
                                            <span className="text-black fw-bold font-14"> Mohammed </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Applicant's Contact Number </h6>
                                            <span className="text-black fw-bold font-14"> +971 0123456789 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Location of Activity </h6>
                                            <span className="text-black fw-bold font-14"> Ali </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">No. of Workers</h6>
                                            <span className="text-black fw-bold font-14"> Ali </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Permit Issue Date</h6>
                                            <span className="text-black fw-bold font-14"> +971 0123456789 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work Start Date</h6>
                                            <span className="text-black fw-bold font-14"> 11-Feb-2022 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work End Date</h6>
                                            <span className="text-black fw-bold font-14"> 12-Feb-2022 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work Start Time</h6>
                                            <span className="text-black fw-bold font-14"> 10:00 AM </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work End Time</h6>
                                            <span className="text-black fw-bold font-14"> 05:00 PM </span>
                                        </div>
                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Comments </h6>
                                            <textarea
                                                className='form-control fw-bolder form-control-solid w-100'
                                                data-kt-autosize='true'
                                                rows={5}
                                                disabled
                                                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                             printer took a galley of type and scrambled it to make a type specimen book. It has survived
                                             not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                             It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
                                            />
                                        </div>
                                    </div> */}




                                </div>
                                {/* <div className="col-12 fm-team-approvals">
                                    <div className="accordion approval-accordion" id="accordionExample">
                                        <RequestDetails />
                                        <ApprovalHistory />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}