import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SelectContactModal } from './SelectContactModal'

export function Document() {
    const [documentsCount, setDocumentsCount] = useState<any>(1);
    return (
        <>
        <div className='com-card-section'>
            <div className='row align-items-center mb-5'>
                <div className="col-6">
                    <h3 className=''>Documents</h3>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="border p-7 rounded">
                        <div className="row">
                            <div className="col-md">
                                <label className="mb-0 d-inline font-12 font-weight-semibold text-theme-black"><span className="contact_ref text-warning"></span>  </label>
                                <Link to="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#select_contact" className="text-decoration-none text-warning font-weight-semibold">
                                Select contact to upload documents
                                </Link>
                            </div>
                            <div className="col-md-auto">
                                <Link to="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#select_contact" className="text-decoration-none text-warning font-weight-semibold text-end">
                                    <i className="fas fa-arrows-alt-h font-10 mr-1"></i> Change
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row documents-data mt-4 d-none">
                        <div className="col-md-2 my-3 docid-7">
                            <div className="border bg-light py-4 rounded doc-remove">
                                <div className="row">
                                    <div className="col-12 text-right"><span className="del-icon" data-did="7"><i className="fas fa-times"></i></span></div>
                                    <div className="col-12 text-center"><span className="file-icon font-32 theme-color"><i className="far fa-file-alt"></i></span></div>
                                    <div className="col-12 text-center"><input type="hidden" className="document_id" name="document_id" value="7"/><span className="font-13 theme-color">Driving Licence</span> </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { Array(documentsCount).fill(1).map((data, index) =>{
                    return(
                        <div className="row upload-filename mt-6">
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Document Upload</label>
                                    <input type="file" className="form-control font-13 form-height" multiple name="document1"/>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Document Type</label>
                                    <select className="form-control custom-select font-13 form-height" data-live-search="true" title="Please Select" name="document_type1">
                                        <option value="2640">Driving Licence</option>
                                        <option value="2641">Emirates ID</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Expiry Date</label>
                                    <input type="date" className="form-control font-13 form-height" placeholder="Expiry Date" name="expiry_date1" value="" required/>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-3 col-sm-6 d-flex align-items-center">
                                <div className=" text-end">
                                { documentsCount === (index+1) &&
                                    <button type="button" className="btn btn-primary me-3" onClick={()=>{setDocumentsCount((oState:any)=>oState+1)}}><span className="fas fa-plus"></span></button>
                                }
                                    <button type="button" className="btn btn-primary" onClick={()=>{setDocumentsCount((oState:any)=>oState-1)}}><span className="fas fa-minus"></span></button>
                                </div>
                            </div>
                        </div>
                    ) } ) }

                </div>
            </div>
        </div>
        <SelectContactModal/>
        </>
    )
}
