import { Link } from "react-router-dom";
import { useState } from 'react';
import { AudianceModal } from "../Modal-audiance";
import Nouislider from "nouislider-react";

export function Content({ chooseMessage }: any) {
    const [documentsCount, setDocumentsCount] = useState<any>(1);
    const [instagramCount, setInstagramCount] = useState<any>(1);
    const [twitterCount, setTwitterCount] = useState<any>(1);
    const [linkedCount, setLinkedCount] = useState<any>(1);
    const [showRange, setShowRange] = useState<any>(false);
    const [minValues, setMinValues] = useState<any>();
    const [maxValues, setMaxValues] = useState<any>();
    const [minBudgetValue, setMinBudgetValue] = useState<any>(0);
    const [maxBudgetValue, setMaxBudgetValue] = useState<any>(5000);
    function onSlides(value: any) {
        setMinBudgetValue(Math.round(value[0]));
        setMaxBudgetValue(Math.round(value[1]));
    }
    function onChanges(value: any) {
        setMinBudgetValue(Math.round(value[0]));
    }
    return (
        <>
            <div className="card mb-6 social-media">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase mb-0 font-13">CHANNEL</label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                    <option > Search </option>
                                    <option selected> Facebook</option>
                                    <option>Twtter</option>
                                    <option> User 3 </option>
                                    <option> User 4 </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase mb-0 font-13">TARGET AUDIENCE</label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                    <option > Search </option>
                                    <option selected > All Contacts</option>
                                    <option>leads</option>
                                    <option> Hot Leads</option>
                                    <option> Cold Leads </option>
                                    <option> Custom </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <Link to data-bs-toggle="modal" data-bs-target="#Audiance-popup" className="btn btn-primary radius-10  px-4 font-12 mt-6"> Create Audience </Link>
                        </div>
                    </div>
                    <div className="row form-add-design mb-3">
                        <div className='col-12'>
                            <label className='d-block text-uppercase mb-0 font-13'>Privew Text/Description</label>
                            <textarea
                                className='form-control'
                                data-kt-autosize='true'
                                rows={4}
                                placeholder={''}
                            />
                        </div>
                    </div>
                    <div className="row form-add-design mb-3">
                        <div className="col-4">
                            {Array(documentsCount).fill(1).map((data, index) => {
                                return (
                                    <div className="row upload-filename mt-6 form-add-design">
                                        <div className="col-md-9">
                                            <div className="mb-7">
                                                <label className="form-label text-uppercase">UPLOAD FACEBOOK IMAGE *<span>*</span></label>
                                                <div className="border-upload">
                                                    <i className="fa fa-upload"></i>
                                                    <span className='ms-2'>Upload</span>
                                                </div>
                                                <input className="form-control input-2" type="file" id="formFile" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 d-flex align-items-center">
                                            <div className=" text-end">
                                                {documentsCount === (index + 1) &&
                                                    <button type="button" className="content-btn me-3" onClick={() => { setDocumentsCount((oState: any) => oState + 1) }}><span className="fas fa-plus"></span></button>
                                                }
                                                <button type="button" className="content-btn" onClick={() => { setDocumentsCount((oState: any) => oState - 1) }}><span className="fas fa-minus"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-4">
                            {Array(instagramCount).fill(1).map((data, index) => {
                                return (
                                    <div className="row upload-filename mt-6 form-add-design">
                                        <div className="col-md-9">
                                            <div className="mb-7">
                                                <label className="form-label text-uppercase">UPLOAD INSTAGRAM IMAGE *<span>*</span></label>
                                                <div className="border-upload">
                                                    <i className="fa fa-upload"></i>
                                                    <span className='ms-2'>Upload</span>
                                                </div>
                                                <input className="form-control input-2" type="file" id="formFile" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 d-flex align-items-center">
                                            <div className=" text-end">
                                                {instagramCount === (index + 1) &&
                                                    <button type="button" className="content-btn me-3" onClick={() => { setInstagramCount((oState: any) => oState + 1) }}><span className="fas fa-plus"></span></button>
                                                }
                                                <button type="button" className="content-btn" onClick={() => { setInstagramCount((oState: any) => oState - 1) }}><span className="fas fa-minus"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="row form-add-design">
                        <div className="col-4">
                            {Array(twitterCount).fill(1).map((data, index) => {
                                return (
                                    <div className="row upload-filename mt-6 form-add-design">
                                        <div className="col-md-9">
                                            <div className="mb-7">
                                                <label className="form-label text-uppercase">UPLOAD TWITTER IMAGE *<span>*</span></label>
                                                <div className="border-upload">
                                                    <i className="fa fa-upload"></i>
                                                    <span className='ms-2'>Upload</span>
                                                </div>
                                                <input className="form-control input-3" type="file" id="formFile" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 d-flex align-items-center">
                                            <div className=" text-end">
                                                {twitterCount === (index + 1) &&
                                                    <button type="button" className="content-btn me-3" onClick={() => { setTwitterCount((oState: any) => oState + 1) }}><span className="fas fa-plus"></span></button>
                                                }
                                                <button type="button" className="content-btn" onClick={() => { setTwitterCount((oState: any) => oState - 1) }}><span className="fas fa-minus"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-4">
                            {Array(linkedCount).fill(1).map((data, index) => {
                                return (
                                    <div className="row upload-filename mt-6 form-add-design">
                                        <div className="col-md-9">
                                            <div className="mb-7">
                                                <label className="form-label text-uppercase">UPLOAD LINKEDIN IMAGE *<span>*</span></label>
                                                <div className="border-upload">
                                                    <i className="fa fa-upload"></i>
                                                    <span className='ms-2'>Upload</span>
                                                </div>
                                                <input className="form-control input-3" type="file" id="formFile" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 d-flex align-items-center">
                                            <div className=" text-end">
                                                {linkedCount === (index + 1) &&
                                                    <button type="button" className="content-btn me-3" onClick={() => { setLinkedCount((oState: any) => oState + 1) }}><span className="fas fa-plus"></span></button>
                                                }
                                                <button type="button" className="content-btn" onClick={() => { setLinkedCount((oState: any) => oState - 1) }}><span className="fas fa-minus"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="row form-add-design mb-4">
                        {/* <div className="col-4">
                            <div className="mb-7">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                        <label className="form-check-label">Default switch checkbox input</label>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label text-uppercase mb-0 font-13 d-block"> Paid Promotion </label>
                                <div className="mt-2 d-inline-flex align-items-center">
                                    <span className="pe-2">No</span>
                                    <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" onChange={() => setShowRange(!showRange)} />
                                    </div>
                                    <span className="ps-14">Yes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showRange ?
                        <>
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <label className="d-inline-block text-uppercase mb-0 mr-auto font-13">Budget (AED)</label>
                                </div>
                                <div className="col-3 text-end">
                                    <div className="d-flex justify-content-between">
                                    
                                        <div className="form-check form-switch">
                                            <span>Yearly</span>
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                        </div>
                                        <select className="border-none-select ms-2">
                                            <option selected >En </option>
                                            <option>Arab</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3 " >
                                <div className="col-8 content">
                                    <Nouislider
                                        range={{ min: 0, max: 10000 }}
                                        start={[parseInt(minValues?.length > 0 ? minValues : 0), parseInt(maxValues?.length > 0 ? maxValues : 5000)]}
                                        connect
                                        onSlide={onSlides} />
                                </div>
                            </div>
                            <div className="row pe-0">
                                <div className="col-4 d-flex budget_min_max">
                                    <label htmlFor="inputtext" className="col-form-label pe-3">Min</label>
                                    <input type="text" id="price-min" name="minBudgetValue" value={parseInt(minBudgetValue)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} className="form-control" onKeyDown={(event) => { event.preventDefault(); }} />
                                </div>
                                <div className="col-4 d-flex budget_min_max pe-4">
                                    <input type="text" id="price-max" name="maxBudgetValue" value={parseInt(maxBudgetValue)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} className="form-control" onKeyDown={(event) => { event.preventDefault(); }} />
                                    <label htmlFor="inputtext" className="col-form-label ps-3">Max</label>
                                </div>
                            </div>
                        </>

                        : ''}
                    
                </div>
            </div>
            <div className="text-end my-6">
                <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('2')}>Previous</button>
                <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('3')}>Next</button>
            </div>
            <AudianceModal/>
        </>
    )
}