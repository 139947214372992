import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {ImageViewerWithPopup} from '../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup'
import Home from '../../../../assets/images/my-unit.png'
import {currencyFormater} from '../../../../constants/generics/customActios'

export function OtherChargesDispute({serviceDetail}: any) {
  const {t} = useTranslation()
  const chequeImg = serviceDetail?.details?.attachments ? serviceDetail.details.attachments : Home

  let totalAmount = 0
  serviceDetail?.disputeDetails?.map((oc: any, index: number) => {
    const total = parseInt(oc.quantity) * parseFloat(oc.amount)
    totalAmount += total
  })

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-12'>
          <div className='card mb-7 serv-data'>
            <div className='card-body px-8 py-6'>
              <div className='row mb-7'>
                <div className='col-sm-12 card-title font-16 text-black'>
                  <span className='text-black'>{t('Clearance Charges Details')}</span>
                </div>
              </div>
              <div className='bg-light-gray rounded-5 p-4'>
                {serviceDetail?.disputeDetails?.map((oc: any, index: number) => {
                  const total = parseInt(oc.quantity) * parseFloat(oc.amount)

                  return (
                    <div className='row'>
                      <div className='col-md-8 col-8'>
                        <span className='text-black font-12 d-block mb-5 fw-bold'>
                          {oc?.categoryType ? oc.categoryType : '-'} *{' '}
                          <small>{oc?.quantity ? oc.quantity : '-'}</small>
                          <div className='text-theme-gray font-10'>
                            ({oc?.preciseLocation ? oc.preciseLocation : '-'})
                          </div>
                        </span>
                      </div>
                      <div className='col-md-4 text-end col-4'>
                        <span className='text-black font-12 d-block mb-5 fw-bold'>
                          {t('AED')} {currencyFormater(total)}
                        </span>
                      </div>
                    </div>
                  )
                })}
                <div className='bg-white rounded-5 p-2'>
                  <div className='row'>
                    <div className='col-md-8 col-8'>
                      <span className='text-black font-12 d-block fw-bold'>{'Total'}</span>
                    </div>
                    <div className='col-md-4 text-end col-4'>
                      <span className='text-black font-12 d-block fw-bold'>
                        {'AED'} {currencyFormater(totalAmount)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-12'>
          <div className='card mb-7 serv-data'>
            <div className='card-body px-8 py-6'>
              <div className='row mb-7'>
                <div className='col-sm-12 card-title font-16 text-black'>
                  <span className='text-black'>{t('Dispute Details')}</span>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-6 col-12 mb-10'>
                  <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t('Type')}</h6>
                  <span className='text-black fw-bold font-15'>{'Other Charges'}</span>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 col-12 mb-10'>
                  <h6 className='text-theme-gray mb-5 fw-normal font-13'>
                    {t('Uploaded Document')}
                  </h6>
                  <ImageViewerWithPopup
                    imageFile={chequeImg}
                    imgID={'cheque'}
                    noImage={chequeImg === Home}
                  />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12 col-12'>
                  <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t('Comments')}</h6>
                  <span className='text-black fw-bold font-15'>
                    {serviceDetail?.details?.comments ? serviceDetail?.details?.comments : '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
