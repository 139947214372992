import React, { useState, useEffect, useRef } from 'react';

export function OutOfOfficePopUp() {
    return (
        <div className="modal fade add-broker-modal" aria-hidden='true' id="out-of-offer-popUp">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content px-8 pt-6 py-3">
                    <div className="modal-header p-0">
                        <h5 className="modal-title">Out of Office</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-0 pb-0 pt-5">
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Details</label>
                                        <input type="text" className="form-control fw-bolder" placeholder="Vacation" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">From Date</label>
                                        <input type="date" className="form-control fw-bolder" placeholder="29-Apr-2021" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">To Date</label>
                                        <input type="date" className="form-control fw-bolder" placeholder="29-Apr-2021" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-7">
                                        <label className="text-black fw-bolder">Emergency Contact</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Contact person Name </label>
                                        <input type="text" className="form-control fw-bolder" placeholder="John Doe" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Mobile Number</label>
                                        <input type="text" className="form-control fw-bolder" placeholder="+97 09878 87887" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Email ID</label>
                                        <input type="email" className="form-control fw-bolder" placeholder="Johndoe@gmail.com" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-0">
                                        <label className="form-label fw-bolder">Note</label>
                                        <textarea
                                            className='form-control fw-bolder h-100'
                                            data-kt-autosize='true'
                                            rows={5}
                                            placeholder='Lorem Ipsum is simply dummy text of the
                                                printing and typesetting industry. Lorem Ipsum
                                                has been the industrys standard dummy text ever since the 1500s'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}