import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { useTranslation } from 'react-i18next';

export function Signature({sigCanvas, sigCanvasEmpty, sigError, handleSign}:any) {
    const clear = () => sigCanvas.current.clear(); 
    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
            handleSign(blob)
        }, 'image/png')
    }
    const { t } = useTranslation();

    return (
        <div className="mt-4">
            <h4 className="text-black font-18 fw-boldest mt-4 mb-6">{t("Customer Signature")}</h4>
            <div className="row form-add-design">
                <div className="col-md-12 col-12">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="mb-0">
                                <div className="w-100 form-control">
                                    <SignatureCanvas
                                        ref={sigCanvas}
                                        penColor='black'
                                        canvasProps={{ height: 60, width: 320, className: 'sigCanvas' }}
                                        onEnd={handleSignature} />
                                </div>
                                <div className="d-none">
                                    <SignatureCanvas    //Only to compare and check empty 
                                        ref={sigCanvasEmpty}
                                        penColor='black'
                                        canvasProps={{height: 60, width: 320, className: 'sigCanvas'}}
                                    />
                                </div>
                            </div>
                        </div>
                        { sigError &&
                            <div className="col-12">
                                <div className='d-flex flex-column text-danger'>
                                    <small className="text-danger">{t("Signature is required!")}</small>
                                </div>
                            </div>
                        }
                        <div className="col-md-12 text-end col-12">
                            <div className="mt-4">
                                <button className='btn btn-light fw-bold px-10 py-2' onClick={clear}>
                                    {t("Clear")}
                                </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}