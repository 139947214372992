
export const Ads = () => {
    return (
        <>
            <div className="card shadow-2 rounded mb-4 ads">
                <div className="card-body">
                    <div className="border-bottom pb-5">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h6 className="font-weight-bold text-dark mb-0"> Likes Details </h6>
                            </div>
                            {/* <div className="col-6 text-end">
                                <a href="#" className="btn text-uppercase py-1 btn-outline-secondary btn-20 font-weight-bold font-13 lh-normal"> Back </a>
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="row">
                            <div className="col-md-3 mb-6">
                                <div> <b> Lead Name </b> </div>
                                <div> <span>Nagmani's Fully Furnished Spacious Villa </span></div>
                            </div>
                            <div className="col-md-3 mb-6">
                                <div> <b> Name </b> </div>
                                <div> <span>Nagmani Bhushan </span></div>
                            </div>
                            <div className="col-md-3 mb-6">
                                <div> <b> Email ID </b> </div>
                                <div> <span>nag@gmail.com</span> </div>
                            </div>
                            <div className="col-md-3 mb-6">
                                <div> <b> Phone Number </b> </div>
                                <div><span> 8211123123</span> </div>
                            </div>
                            <div className="col-md-3 mb-6">
                                <div> <b> Property Title </b> </div>
                                <div> <span> Fully Furnished Spacious Villa</span> </div>
                            </div>
                            <div className="col-md-3 mb-6">
                                <div> <b> Property ID </b> </div>
                                <div> <span>199932</span> </div>
                            </div>
                            <div className="col-md-3 mb-6">
                                <div> <b> Agent Name </b> </div>
                                <div>  </div>
                            </div>
                            <div className="col-md-3 mb-6">
                                <div> <b> Like Date </b> </div>
                                <div> <span>2020-07-12</span> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}