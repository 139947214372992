var APIs = {
  URL: process.env.REACT_APP_API_URL,
  getAllContract: {
    endPoint: 'getAllContract',
    headers: { method: 'get', redirect: 'follow' },
  },
  getUserNotifications: {
    endPoint: 'userNotification',
    headers: { method: 'get', redirect: 'follow' },
  },
  postReadNotifications: {
    endPoint: 'readNotification',
    headers: { method: 'post', redirect: 'follow' },
  },
  // * Same API is also used for PM Module
  getApprovals: {
    endPoint: 'approval/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getPaymentHistory: {
    endPoint: 'payment/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  generateStatementRequest: {
    endPoint: 'tenant/generateStatement',
    headers: { method: 'post', redirect: 'follow' },
  },
  getAllMarketPlace: {
    endPoint: 'getAllMarketPlace',
    headers: { method: 'post', redirect: 'follow' },
  },
  getMarketPlace: {
    endPoint: 'marketing/marketplace/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getMarketPlaceDetails: {
    endPoint: 'marketing/getMPDeatils',
    headers: { method: 'get', redirect: 'follow' },
  },
  getPreciseLocations: {
    endPoint: 'getPreciseLocations',
    headers: { method: 'get', redirect: 'follow' },
  },
  getPreciseLocationsCommon: {
    endPoint: 'PL/commonArea',
    headers: { method: 'get', redirect: 'follow' },
  },
  getInspectionIssues: {
    endPoint: 'tenant/serviceRequest/lists',
    headers: { method: 'get', redirect: 'follow' },
  },
  getServiceRequestDetails: {
    endPoint: 'tenant/serviceRequest/lists',
    headers: { method: 'get', redirect: 'follow' },
  },
  deleteInspectionIssue: {
    endPoint: 'tenant/moveIn/inspections/delete',
    headers: { method: 'post', redirect: 'follow' },
  },
  getNationalities: {
    endPoint: 'nationality/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  saveAccessCard: {
    endPoint: 'facility/accessCards/save',
    headers: { method: 'post', redirect: 'follow' },
  },
  saveApprovalsData: {
    endPoint: 'facility/accessCards/save',
    headers: { method: 'post', redirect: 'follow' },
  },
  saveAccessData: {
    endPoint: 'facility/accessCards/save',
    headers: { method: 'post', redirect: 'follow' },
  },
  submitMoveInApproval: {
    endPoint: 'facility/mi/status/update',
    headers: { method: 'post', redirect: 'follow' },
  },
  submitFmsmHandoverToFcss: {
    endPoint: 'facility/accessCards/handOver',
    headers: { method: 'post', redirect: 'follow' },
  },
  submitAccessCardHandle: {
    endPoint: 'facility/mi/status/update',
    headers: { method: 'post', redirect: 'follow' },
  },
  submitDocumentStatus: {
    endPoint: 'facility/documents/sendDocument',
    headers: { method: 'post', redirect: 'follow' },
  },
  submitMoveOutApproval: {
    endPoint: 'facility/mo/status/update',
    headers: { method: 'post', redirect: 'follow' },
  },
  moveInInspectionRequest: {
    endPoint: 'tenant/submitMoveInInspection',
    headers: { method: 'post', redirect: 'follow' },
  },
  disputeChargesRequest: {
    endPoint: 'facility/work/order/dispute',
    headers: { method: 'post', redirect: 'follow' },
  },
  scheduleMeetingRequest: {
    endPoint: 'scheduleVisit',
    headers: { method: 'post', redirect: 'follow' },
  },
  sendPaymentRequest: {
    endPoint: 'payment/pay',
    headers: { method: 'post', redirect: 'follow' },
  },
  sendBankTransferRequest: {
    endPoint: 'bankTransfer',
    headers: { method: 'post', redirect: 'follow' },
  },
  sendChequePaymentRequest: {
    endPoint: 'payByCheque',
    headers: { method: 'post', redirect: 'follow' },
  },
  createAmenityBooking: {
    endPoint: 'tenant/amenityBooking',
    headers: { method: 'post', redirect: 'follow' },
  },
  createChequeReplacementRequest: {
    endPoint: 'facility/chequeReplaceRequests',
    headers: { method: 'post', redirect: 'follow' },
  },
  createChequeDelayRequest: {
    endPoint: 'facility/chequeDelay',
    headers: { method: 'post', redirect: 'follow' },
  },
  getMaintenanceRequestsCategories: {
    endPoint: 'services/mr/categories/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getIncidentList: {
    endPoint: 'facility/incident/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getMyUnitDocuments: {
    endPoint: 'myUnitDocuments',
    headers: { method: 'get', redirect: 'follow' },
  },
  getAvailableTimings: {
    endPoint: 'tenant/availableTimings',
    headers: { method: 'get', redirect: 'follow' },
  },
  getServiceRequestsCategories: {
    endPoint: 'services/sr/categories/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getAnnouncements: {
    endPoint: 'announcements/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  updateIsRedeemed: {
    endPoint: 'updateMarketPlace',
    headers: { method: 'post', redirect: 'follow' },
  },
  redeemMarketPlaceOffer: {
    endPoint: 'marketing/redeemOffer',
    headers: { method: 'post', redirect: 'follow' },
  },
  marketPlaceDetails: {
    endPoint: 'marketPlaceDetails',
    headers: { method: 'get', redirect: 'follow' },
  },
  getMaintenanceIssues: {
    endPoint: 'tenant/issues/mr',
    headers: { method: 'get', redirect: 'follow' },
  },
  getMaintenanceIssueDetails: {
    endPoint: 'tenant/issue/mr',
    headers: { method: 'get', redirect: 'follow' },
  },
  getServicesIssues: {
    endPoint: 'tenant/issues/sr',
    headers: { method: 'get', redirect: 'follow' },
  },
  facilityIncidentReslovedStatusUpdate: {
    endPoint: 'facility/incident/update',
    headers: { method: 'post', redirect: 'follow' },
  },
  facilityIncidentReportStatusUpdate: {
    endPoint: 'facility/incident/report',
    headers: { method: 'post', redirect: 'follow' },
  },
  financeSendAlertNotification: {
    endPoint: 'payment/alert/notification/send',
    headers: { method: 'post', redirect: 'follow' },
  },
  financeSendInnovice: {
    endPoint: 'finance/email/invoice/send',
    headers: { method: 'post', redirect: 'follow' },
  },
  financeSendReceipt: {
    endPoint: 'payment/email/receipt/send',
    headers: { method: 'post', redirect: 'follow' },
  },
  getServiceRequestByCategoryId: {
    endPoint: 'tenant/serviceRequest/lists',
    headers: { method: 'get', redirect: 'follow' },
  },
  getStepsStatus: {
    endPoint: 'tenant/stepsStatus',
    headers: { method: 'get', redirect: 'follow' },
  },
  getRenewalNotice: {
    endPoint: 'tenant/serviceRequest/lists',
    headers: { method: 'get', redirect: 'follow' },
  },
  redeemOfferrequest: {
    endPoint: 'updateMarketPlace',
    headers: { method: 'post', redirect: 'follow' },
  },
  renewContract: {
    endPoint: 'contract/renew/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  contractActivity: {
    endPoint: 'contract/activity',
    headers: { method: 'get', redirect: 'follow' },
  },
  updateContractActivity: {
    endPoint: 'contract/activity/update',
    headers: { method: 'post', redirect: 'follow' },
  },
  accessCardRequest: {
    endPoint: 'tenant/accessCard',
    headers: { method: 'post', redirect: 'follow' },
  },
  parkingIssueRequest: {
    endPoint: 'tenant/parkingIssue',
    headers: { method: 'post', redirect: 'follow' },
  },
  earlyTerminationRequest: {
    endPoint: 'tenant/leaseTermination',
    headers: { method: 'post', redirect: 'follow' },
  },
  undertakingLetterRequest: {
    endPoint: 'tenant/undertakingLetter',
    headers: { method: 'post', redirect: 'follow' },
  },
  renewalNoteRequest: {
    endPoint: 'tenant/renewalNotice',
    headers: { method: 'post', redirect: 'follow' },
  },
  vehicleRegistrationRequest: {
    endPoint: 'tenant/vehicleRegistration',
    headers: { method: 'post', redirect: 'follow' },
  },
  healthClubRegistrationRequest: {
    endPoint: 'tenant/healthClubRegistration',
    headers: { method: 'post', redirect: 'follow' },
  },
  extendContractRequest: {
    endPoint: 'contract/extend/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  vacateRequest: {
    endPoint: 'property/vacate',
    headers: { method: 'post', redirect: 'follow' },
  },
  rentReductionRequest: {
    endPoint: 'contract/reduce/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  generalPTWRequest: {
    endPoint: 'tenant/generalPTW',
    headers: { method: 'post', redirect: 'follow' },
  },
  generalPTWSafetyPrecautionsRequest: {
    endPoint: 'tenant/generalPTW/safetyPrecautions',
    headers: { method: 'post', redirect: 'follow' },
  },
  getMasterData: {
    endPoint: 'getMasterData',
    headers: { method: 'get', redirect: 'follow' },
  },
  getConfig: {
    endPoint: 'getConfig',
    headers: { method: 'get', redirect: 'follow' },
  },
  getAllotedParkingSlots: {
    endPoint: 'getAllotedParkingSlots',
    headers: { method: 'get', redirect: 'follow' },
  },
  getAllotedAccessCards: {
    endPoint: 'getAllotedAccessCards',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityParkingSlots: {
    endPoint: 'facility/parking/slots',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityUserList: {
    endPoint: 'user/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityBuildingList: {
    endPoint: 'facility/building/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getPMBuildingList: {
    endPoint: 'facility/building/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityBuildingContractList: {
    endPoint: 'facility/building/contract/properties',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityDocumentList: {
    endPoint: 'getDocumentRequestType',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityVisitorsTypeList: {
    endPoint: 'getAllVisitorType',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityUserType: {
    endPoint: 'user/types',
    headers: { method: 'get', redirect: 'follow' },
  },
  getIncidentLists: {
    endPoint: 'getIncidentLists',
    headers: { method: 'get', redirect: 'follow' },
  },
  getCommonArea: {
    endPoint: 'PL/commonArea',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityDocuments: {
    endPoint: 'facility/documents/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityAccessCards: {
    endPoint: 'facility/accessCards',
    headers: { method: 'get', redirect: 'follow' },
  },
  getAllotedAccessId: {
    endPoint: 'facility/accessCards',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityVisitors: {
    endPoint: 'facility/visitor/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityScheduledVisits: {
    endPoint: 'visits/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityMaintenanceRequests: {
    endPoint: 'facility/mr/issues',
    headers: { method: 'get', redirect: 'follow' },
  },
  getFacilityWorkOrders: {
    endPoint: 'facility/work/orders',
    headers: { method: 'get', redirect: 'follow' },
  },
  createFacilityDocument: {
    endPoint: 'facility/documents/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  addFacilityVisitor: {
    endPoint: 'facility/visitor/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  createVisitorInOutTime: {
    endPoint: 'facility/visitor/inOut',
    headers: { method: 'post', redirect: 'follow' },
  },
  createFacilityAnnouncement: {
    endPoint: 'announcements/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  createReportRequest: {
    endPoint: 'facility/issue/report',
    headers: { method: 'post', redirect: 'follow' },
  },
  getFacilityCompanyData: {
    endPoint: 'facility/user/company',
    headers: { method: 'get', redirect: 'follow' },
  },
  getAccessCardsView: {
    endPoint: 'facility/accessCards/view',
    headers: { method: 'get', redirect: 'follow' },
  },
  createMaintenanceRequest: {
    endPoint: 'tenant/mr/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  rescheduleMaintenanceRequest: {
    endPoint: 'tenant/mr/reschedule',
    headers: { method: 'post', redirect: 'follow' },
  },
  createMoveInAccessCardsRequest: {
    endPoint: 'tenant/accessCardRequest',
    headers: { method: 'post', redirect: 'follow' },
  },
  createMoveInInspectionIssue: {
    endPoint: 'tenant/moveInInspection',
    headers: { method: 'post', redirect: 'follow' },
  },
  updateMoveInInspectionIssue: {
    endPoint: 'tenant/updateMoveInInspection',
    headers: { method: 'post', redirect: 'follow' },
  },
  moveOutInspectionRequest: {
    endPoint: 'tenant/moveOutInspection',
    headers: { method: 'post', redirect: 'follow' },
  },
  moveOutApprovalRequest: {
    endPoint: 'tenant/moveOutApproval',
    headers: { method: 'post', redirect: 'follow' },
  },
  refundRequest: {
    endPoint: 'tenant/refundRequest',
    headers: { method: 'post', redirect: 'follow' },
  },
  uploadADDCLetterRequest: {
    endPoint: 'tenant/addcWelcomeLetter',
    headers: { method: 'post', redirect: 'follow' },
  },
  extendOfferRequest: {
    endPoint: 'extendOffer',
    headers: { method: 'post', redirect: 'follow' },
  },
  getOfferDetails: {
    endPoint: 'offerDetail',
    headers: { method: 'get', redirect: 'follow' },
  },
  bankTransfer: {
    endPoint: 'bankTransfer',
    headers: { method: 'post', redirect: 'follow' },
  },
  payByCheque: {
    endPoint: 'payByCheque',
    headers: { method: 'post', redirect: 'follow' },
  },
  createContract: {
    endPoint: 'contract/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  profileUpdate: {
    endPoint: 'updateProfile',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** FC - Submit Clearance Charges
  createClearanceCharge: {
    endPoint: 'tenant/mo/clearance/charge/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  updateClearanceCharge: {
    endPoint: 'tenant/mo/clearance/charge/update',
    headers: { method: 'post', redirect: 'follow' },
  },
  // Finance - Service Request List
  getFinanceServiceRequestList: {
    endPoint: 'finance/services',
    headers: { method: 'get', redirect: 'follow' },
  },
  // Finance - Services Refunds Add Termination
  createTerminationCharges: {
    endPoint: 'finance/termination/charges/add',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FCS - Activity list
  getActivitiesList: {
    endPoint: 'pm/activity',
    headers: { method: 'get', redirect: 'follow' },
  },
  // FCS - Activity Create
  createActivity: {
    endPoint: 'pm/activity/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FCS - My Team list
  getMyTeamUserList: {
    endPoint: 'user/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  // FCS - My Team chart
  getMyTeamChartData: {
    endPoint: 'myTeam',
    headers: { method: 'get', redirect: 'follow' },
  },
  // FCS - WorkOrder assign 
  postWorkOrderAssign: {
    endPoint: 'facility/work/assign',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FCS - WorkOrder Checklist View
  getWorkOrderChecklist: {
    endPoint: 'facility/work/order/checklist/damage/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  // FCS - WorkOrder Comments List
  getWorkOrderComments: {
    endPoint: 'facility/work/orders/comments/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  // FCS - WorkOrder Comments Add 
  postWorkOrderComments: {
    endPoint: 'facility/work/orders/comments',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FC - WorkOrder Re-Schedule 
  postWorkOrderReschedule: {
    endPoint: 'facility/appointment/reschedule',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FC - WorkOrder Complete Corrective/Submit Checklist
  postWorkOrderChecklist: {
    endPoint: 'facility/work/order/checklist/damage/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FM/FCS - edit team member
  updateTeamMember: {
    endPoint: 'user/updateUser/',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FC/FCS/PM - update account status (activate/deactivate) 
  updateAccountStatus: {
    endPoint: 'user/updateStatus',
    headers: { method: 'post', redirect: 'follow' },
  },
  // FM Team - Get Company List
  getCompanyList: {
    endPoint: 'myteam/companies',
    headers: { method: 'get', redirect: 'follow' },
  },
  // FM Team - Get User Company & Building List
  getFmCommunityUser: {
    endPoint: 'getFmCommunityUser',
    headers: { method: 'get', redirect: 'follow' },
  },
  // FM Team - Approve/Reject/RMI for PTW, Fitout, OnHold WO (Can be used anywhere for approvals)
  postApprovalSubmit: {
    endPoint: 'approvals',
    headers: { method: 'post', redirect: 'follow' },
  },

  //  Get community list
  getCommunities: {
    endPoint: 'pm/communities/list',
    headers: { method: 'get', redirect: 'follow' },
  },

  // MTA - Approval comment list
  getApprovalCommentList: {
    endPoint: 'marketing/approval/comments',
    headers: { method: 'get', redirect: 'follow' },
  },
  // MTA - Get Team Member Details
  getTeamMemberDetails: {
    endPoint: 'marketing/myTeam/memberDetails',
    headers: { method: 'get', redirect: 'follow' },
  },
  // ** MTA - Get All Properties **
  getMarketingTeamProperties: {
    endPoint: 'marketing/properties',
    headers: { method: 'get', redirect: 'follow' },
  },
  // ** MTA - Get Promotions **
  getMarketingTeamPromotions: {
    endPoint: 'marketing/promotions/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  // ** Commercial Tenant - Submit Key Type Details **
  keyTypeDetails: {
    endPoint: 'tenant/keyType',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** Commercial Tenant - Submit Fit-out NOC **
  fitOutDesignRequest: {
    endPoint: 'commercial/services/fitOutDesignNoc',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** Commercial Tenant - Submit Fit-out Commencement Approval **
  fitOutCommencementApprovalRequest: {
    endPoint: 'commercial/services/fitOutCommencementNoc',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** Commercial Tenant - Submit Move-in Authority Approval **
  moveInAuthorityApproval: {
    endPoint: 'commercial/services/authorityApproval',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** Commercial Tenant - Permit To Work - General PTW **
  generalPermitToWork: {
    endPoint: 'commercial/services/permitToWork',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** Commercial Tenant - Permit To Work - Fire Protection Impairment **
  fireProtectionImpairmentPermit: {
    endPoint: 'commercial/services/fireProtectionImpairment',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** Commercial Tenant - Permit To Work - Helipad & Rooftop Access **
  helipadRooftopAccessPermit: {
    endPoint: 'commercial/services/helipadRoofTopAccess',
    headers: { method: 'post', redirect: 'follow' },
  },
  // ** Commercial Tenant - Get Final Completion Certificate Questions **
  finalCompletionCertificateQuestions: {
    endPoint: 'marketing/certificate/questions/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  // ** Commercial Tenant - Final Completion Certificate **
  finalCompletionCertificate: {
    endPoint: 'commercial/services/finalCompletionCertificate',
    headers: { method: 'post', redirect: 'follow' },
  },
  getPropertyFilterData: {
    //* getPropertyFilterData to create Alert
    endPoint: 'property/getPropertyFilterData',
    headers: { method: 'get', redirect: 'follow' },
  },
  addAlerts: {
    //*  create Alert
    endPoint: 'addAlerts',
    headers: { method: 'post', redirect: 'follow' },
  },
  getAlerts: {
    //*  get Alerts
    endPoint: 'getAlerts',
    headers: { method: 'get', redirect: 'follow' },
  },
  removeAlerts: {
    //*  remove Alerts
    endPoint: 'myAlertStatus/remove',
    headers: { method: 'post', redirect: 'follow' },
  },
  unSubscribeAlerts: {
    //*  unsubscribe Alerts
    endPoint: 'myAlertStatus/unsubscribe',
    headers: { method: 'post', redirect: 'follow' },
  },
  subscribeAlerts: {
    //*  Subscribe Alerts
    endPoint: 'myAlertStatus/subscribe',
    headers: { method: 'post', redirect: 'follow' },
  },
  getMyDocs: {
    //*  Get all uploaded documents
    endPoint: 'getMyDoc',
    headers: { method: 'get', redirect: 'follow' },
  },
  uploadMyDocs: {
    //*  Upload documents
    endPoint: 'addMyDoc',
    headers: { method: 'post', redirect: 'follow' },
  },
  deleteMyDocs: {
    //*  Upload documents
    endPoint: 'deleteMyDoc',
    headers: { method: 'post', redirect: 'follow' },
  },
  myLikes: {
    //*  Like and unlike property
    endPoint: 'myLikes',
    headers: { method: 'post', redirect: 'follow' },
  },
  rateUs: {
    //*  property visit review
    endPoint: 'rateUs',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* Upload Post data cheques
  uploadPDCs: {
    endPoint: "tenant/postDatedCheque/create",
    headers: { method: "post", redirect: "follow" },
  },
  //* Check emirates already exists
  checkEmirates: {
    endPoint: "user/emirates/check",
    headers: { method: "post", redirect: "follow" },
  },
  //* Get All Schedule List for selected date and property id
  getVisitScheduleList: {
    endPoint: "visitScheduleList",
    headers: { method: "post", redirect: "follow" },
  },
  //* Post Reschedule List for selected date and property id
  postRescheduleVisit: {
    endPoint: "visit/reschedule",
    headers: { method: "post", redirect: "follow" },
  },
  //* Cancel schedule by id
  cancelScheduleVisit: {
    endPoint: "visits/cancel",
    headers: { method: "post", redirect: "follow" },
  },
  // ** Finance APIs **
  //*  Reports
  geFinanceReports: {
    endPoint: 'finance/reports',
    headers: { method: 'get', redirect: 'follow' },
  },
  //* Get All Receipts
  geFinanceReceipts: {
    endPoint: 'finance/receipts',
    headers: { method: 'get', redirect: 'follow' },
  },
  //* Finance module - get lease list
  getLeaseList: {
    endPoint: "finance/lease/list",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get lease details
  getLeaseDetail: {
    endPoint: "finance/leaseDetails",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get receipt list
  getReceiptList: {
    endPoint: "finance/receipt",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get receipt details
  getReceiptDetail: {
    endPoint: "finance/receipt/details",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get refunds list/details
  getRefundData: {
    endPoint: "finance/refunds",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get moveout list
  getMoveoutList: {
    endPoint: "finance/moveOutRequests",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get moveout detail (main & lease)
  getMoveoutDetail: {
    endPoint: "finance/moveOutRequestDetails",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get moveout detail (payments)
  getMoveoutPayments: {
    endPoint: "finance/moveOutRequestPayments",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get moveout detail (documents)
  getMoveoutDocuments: {
    endPoint: "finance/moveOutRequestDocuments",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get payment list
  getPaymentList: {
    endPoint: "finance/payments",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get payment detail
  getPaymentDetails: {
    endPoint: "finance/payment/details",
    headers: { method: "get", redirect: "follow" },
  },
  //* Finance module - get payment report type
  getPaymentType: {
    endPoint: "getPaymentType",
    headers: { method: "get", redirect: "follow" },
  },

  //* Tenant module - get refunds charges list
  getRefundChargesData: {
    endPoint: "tenant/mo/clearance/charge/List",
    headers: { method: "get", redirect: "follow" },
  },
  //* Facility module - activity details
  getActivityDetails: {
    endPoint: "pm/activityDetails",
    headers: { method: "get", redirect: "follow" },
  },
  //* Facility module - activity update
  closeActivity: {
    endPoint: "pm/activity/update/status",
    headers: { method: "post", redirect: "follow" },
  },
  //* FCSS module - add incident detail
  createIncident: {
    endPoint: 'facility/incident/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* FCSS module - get category and sub-category list (maintainance)
  // getMaintenanceRequestsCategories: {
  //   endPoint: 'services/mr/categories/list',
  //   headers: { method: 'get', redirect: 'follow' },
  // },



  // ** Property Managers(PM) APIs **
  //* Property module - get approvals list
  getPMapprovalList: {
    endPoint: "approval/list",
    headers: { method: "get", redirect: "follow" },
  },
  //* Property module - get myTeam and chart data
  getPMmyTeam: {
    endPoint: "myTeam",
    headers: { method: "get", redirect: "follow" },
  },
  //* PM module - create team member
  createTeamMember: {
    endPoint: 'user/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  //*Get One Contract
  getMyContract: {
    endPoint: 'contract/view',
    headers: { method: 'post', redirect: 'follow' },
  },
  //*signature
  addSignature: {
    endPoint: 'contract/signature/add',
    headers: { method: 'post', redirect: 'follow' },
  },
  //*Approve Contract, Service Request, Maintenance Request etc
  approveTheRequests: {
    endPoint: 'approvals/approve',
    headers: { method: 'post', redirect: 'follow' },
  },
  //*reject Contract, Service Request, Maintenance Request etc
  rejectTheRequests: {
    endPoint: 'approvals/reject',
    headers: { method: 'post', redirect: 'follow' },
  },
  //*Request for info Contract, Service Request, Maintenance Request etc
  rmiTheRequests: {
    endPoint: 'approvals/rmi',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* PM Offers and Renewals
  getOffersAndRenewals: {
    endPoint: 'pm',
    headers: { method: 'get', redirect: 'follow' },
  },
  //*Offers and Renewals Details
  getOffersAndRenewalsDetails: {
    endPoint: 'pm/offerDetail',
    headers: { method: 'get', redirect: 'follow' },
  },
  //*Refunds List
  getRefundsList: {
    endPoint: 'pm/refunds',
    headers: { method: 'get', redirect: 'follow' },
  },
  //*Refunds Details
  getRefundsDetails: {
    endPoint: 'pm/refunds',
    headers: { method: 'get', redirect: 'follow' },
  },
  //*Tenant Search
  tenantSearch: {
    endPoint: 'pm/tenantSearch/list',
    headers: { method: 'post', redirect: 'follow' },
  },
  //*Create new user(PT)
  createTenant: {
    endPoint: 'pm/createTenant',
    headers: { method: 'post', redirect: 'follow' },
  },
  //*Get Add Ons
  getAddOns: {
    endPoint: 'getAddOns',
    headers: { method: 'get', redirect: 'follow' },
  },
  //*Make an offer
  makeAnoffer: {
    endPoint: 'pm/makeOffer',
    headers: { method: 'post', redirect: 'follow' },
  }, //* PM - get my team details
  getMyTeamDeatils: {
    endPoint: "pm/myTeamDetails",
    headers: { method: "get", redirect: "follow" },
  }, //* PM module - Assign Property to team member
  assignMember: {
    endPoint: "pm/myTeam/assignMember",
    headers: { method: "post", redirect: "follow" },
  },//* PM module - All Schedule List 
  allScheduleList: {
    endPoint: "pm/visits/list",
    headers: { method: "get", redirect: "follow" },
  },//* PM module - Dashboard Chart Data
  myDashboard: {
    endPoint: "pm/myDashboard",
    headers: { method: "get", redirect: "follow" },
  },
  //* PM module - Property Edit 
  pmPropertyEdit: {
    endPoint: 'pm/propertyEdit',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* PM module - Property Edit Delete Images pm/property/media/{image/video/floor}/{id}
  pmPropertyEditDeleteMedia: {
    endPoint: 'pm/property/media/delete',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* PM module - Property Edit Add Images pm/property/media/{image/video/floor}/{id}
  pmPropertyEditAddMedia: {
    endPoint: 'pm/property/images/add',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* PM module - Property Edit Add Videos pm/property/media/{image/video/floor}/{id}
  pmPropertyEditAddMediaVideo: {
    endPoint: 'pm/property/videos/add',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* PM module - Property Edit Add Images pm/property/media/{image/video/floor}/{id}
  pmPropertyEditAddMediaFloor: {
    endPoint: 'pm/property/images/floor/add',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* PM module - unrenewedLease
  pmUnRenewedLease: {
    endPoint: 'pm/unrenewedLease/list',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* PM module - Leads and details
  pmGetLeadsList: {
    endPoint: 'pm/leads/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  //* PM module - Leads qualify or reject
  leadsRejectQualify: {
    endPoint: 'pm/leads/update',
    headers: { method: 'put', redirect: 'follow' },
  },
  //* PM module - Reports
  gePmReports: {
    endPoint: 'pm/reports',
    headers: { method: 'get', redirect: 'follow' },
  },
  //* PM module - PropertyDetails
  gePmPropertyDetails: {
    endPoint: 'pm/propertyDetails',
    headers: { method: 'get', redirect: 'follow' },
  },
  //*Make an offer - Lead
  makeLeadoffer: {
    endPoint: 'leadMakeOffer',
    headers: { method: 'post', redirect: 'follow' },
  },
  //* Chat: Get all chats based on the current user
  getMessageForUser: {
    endPoint: "getMessageForUser",
    headers: { method: "get", redirect: "follow" },
  },
  //* Chat: Get all chats
  getChatList: {
    endPoint: "getChatList",
    headers: { method: "get", redirect: "follow" },
  },
  //*  Chat: Get all chats sent from a user
  getMessageFromUser: {
    endPoint: "getMessageForExecutive",
    headers: { method: "get", redirect: "follow" },
  },
  //* Chat: Send a message to the user[PE]
  sendMessages: {
    endPoint: 'sendMessage',
    headers: { method: 'post', redirect: 'follow' },
  },

  /**
   * MT Admin APIs
   */
  marketPlaceList: {
    endPoint: 'marketing/offer/list',
    headers: { method: 'get', redirect: 'follow' },
  },
  updateMarketingProperty: {
    endPoint: 'marketing/property',
    headers: { method: 'post', redirect: 'follow' },
  },
  createMarketingVoucher: {
    endPoint: 'marketing/createVoucher',
    headers: { method: 'post', redirect: 'follow' },
  },
  createMarketingOffer: {
    endPoint: 'marketing/offer/create',
    headers: { method: 'post', redirect: 'follow' },
  },
  createMarketingEvent: {
    endPoint: 'marketing/createEvent',
    headers: { method: 'post', redirect: 'follow' },
  },
  postSendOffer: {
    endPoint: 'marketing/Offer/Send',
    headers: { method: 'post', redirect: 'follow' },
  },
  getMerchantList: {
    endPoint: 'marketing/listMerchant',
    headers: { method: 'get', redirect: 'follow' },
  },

  // * Properties Building List
  propertiesBuildingList: {
    endPoint: "pm/building/list",
    headers: { method: "get", redirect: "follow" },
  },
  // * Properties Account List
  propertiesAccountList: {
    endPoint: "pm/user/list",
    headers: { method: "get", redirect: "follow" },
  },
   // * Properties Account UserRole List
   getPropertiesUserRoleList: {
    endPoint: "pm/user/roles",
    headers: { method: "get", redirect: "follow" },
  },
  // * Properties Building Community List
  getPropertiesCommunityList: {
    endPoint: "pm/building/community",
    headers: { method: "get", redirect: "follow" },
  },
  // * Properties Building Category List
  getPropertiesCategoryList: {
    endPoint: "pm/building/category",
    headers: { method: "get", redirect: "follow" },
  },

  UpdateBuilding: {
    endPoint: "pm/updateBuilding/",
    headers: {method: "post", redirect: "follow"}
  },
  // PM UpdateBuilding
  addBuilding: {
    endPoint: "pm/createBuilding",
    headers: {method: "post", redirect: "follow"}
  },

  // PM detail Building
  detailBuilding: {
    endPoint: "pm/building/detail",
    headers: {method: "get", redirect: "follow"}
  },

  //* Get faq
  getFaq: {
    endPoint: "faq",
    headers: { method: "get", redirect: "follow" },
  },
  //* Get user menu/permissions API
  getMenu: {
    endPoint: "getMenu",
    headers: { method: "get", redirect: "follow" },
  },
  // POST method for add Building

}


module.exports = { APIs }
