import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ServiceRequestTabs: React.FC = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-12">
                                    <div className='d-flex py-2'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                        (location.pathname === '/pm-service-request/request-details' && 'active')}
                                                    to={{
                                                        pathname: '/pm-service-request/request-details',
                                                        state: location.state,
                                                    }}>
                                                    {t("Request Details")}
                                                </Link>
                                            </li>
                                            {/* <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                        (location.pathname === '/pm-service-request/payment' && 'active')}
                                                    to={{
                                                        pathname: '/pm-service-request/payment',
                                                        state: location.state,
                                                    }}>
                                                    {t("Payment")}
                                                </Link>
                                            </li> */}
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                        (location.pathname === '/pm-service-request/documents' && 'active')}
                                                    to={{
                                                        pathname: '/pm-service-request/documents',
                                                        state: location.state,
                                                    }}>
                                                    {t("Documents")}
                                                </Link>
                                            </li>
                                            {/* <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                        (location.pathname === '/pm-service-request/history' && 'active')}
                                                    to={{
                                                        pathname: '/pm-service-request/history',
                                                        state: location.state,
                                                    }}>
                                                    {t("ApprovedProformas")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                        (location.pathname === '/pm-service-request/approval-history' && 'active')}
                                                    to={{
                                                        pathname: '/pm-service-request/approval-history',
                                                        state: location.state,
                                                    }}>
                                                    {t("Approval History")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                        (location.pathname === '/pm-service-request/notes' && 'active')}
                                                    to={{
                                                        pathname: '/pm-service-request/notes',
                                                        state: location.state,
                                                    }}>
                                                    {t("Notes")}
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ServiceRequestTabs }
