import React, {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {UnrenewedLeases} from './components/UnrenewedLeases/UnrenewedLeases';
import LeftArrow from "../../assets/images/Left_Arrow.png";
import {PMButtons} from './components/PMButtons/PMButtons';
import {CheckBoxPopup} from './components/CheckBoxPopup/CheckBoxPopup';
import {AddedSuccessfulPopup} from '../SuccessfulPopup/AddedSuccessfulPopup'
import {RaiseProformaWrapper} from './components/RaiseProforma/RaiseProformaWrapper';
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const PMUnrenewedLeasesPage: FC = () => (

    <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-xxl-12 mt-0'>
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-6">
                        <div className="col-lg-3 col-sm-12">
                            <h4 className="card-title font-18 text-black mb-0">
                                {/* <Link to={'/'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link> */}
                                {t("Unrenewed Leases")}
                            </h4>
                        </div>
                        <div className="col-lg-9 col-sm-12 ps-0">
                            <PMButtons />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <UnrenewedLeases />
                    <CheckBoxPopup />
                    <AddedSuccessfulPopup />
                </div>
            </div>
            <Switch>
                <Route path='/pm-raise-proforma/open-cheque-details'>
                    <RaiseProformaWrapper />
                </Route>
            </Switch>
        </div>
    </div>
    </>
);

const PMUnrenewedLeasesWrapper: FC = () => {
    const intl = useIntl();
    const { t } = useTranslation();

    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <PMUnrenewedLeasesPage />
        </>
    )
};

export {PMUnrenewedLeasesWrapper}
