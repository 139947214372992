import React, { FC, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
// import {RequestSuccessfulPopup} from '../../../SuccessfulPopup/RequestSuccessfulPopup';
import { CustomRequestSuccessfulPopup } from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import PhoneInput from 'react-phone-input-2';

import { useSelector } from 'react-redux';
import { addNewTechnician, getUserBuildingCommunityList } from '../../../../utils/makeRequest';
import { IState } from '../../../../utils/interface';
import Loader from '../../../../components/Loader';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRequestPost from '../../../../hooks/useRequestPost'

import {
    createContractorUser,
    // getFacilityBuildingList,
    getFacilityCompanyData,
    getFacilityUserType,
    // getFmCommunityUser
} from '../../../../constants/axios/apis'
import { MultiSelect } from 'react-multi-select-component';
import IntlTelInput from 'react-intl-tel-input'
import { useTranslation } from 'react-i18next';

export function AddTechnicianPage() {
    const { t } = useTranslation();


    const validationSchemaFC = Yup.object({
        firstNameField: Yup.string()
            .required(t('First Name is required!'))
            .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        lastNameField: Yup.string()
            .required(t('Last Name is required!'))
            .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        emirateIDField: Yup.string()
            .required(t('Emirates ID is required!'))
            .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
            .matches( /^(\d+-?)+\d+$/, t("Emirates Id contains only numbers and hypen!") )
            .min(15, t('Minimum length is 15!'))
            .max(25, t('Maximum 25 characters!')),
        // mobileNumberField: Yup.string().required('Mobile Number is required').matches(
        //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     "Mobile Number is not valid"
        // ),
        mobileNumberField: Yup.string()
            .min(4, t('Mobile No should be greater then 4 digits!'))
            .max(16, t('Mobile No should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
            .required(t('Mobile No is required!')),
        emailIDField: Yup.string()
            .required(t('Email ID is required!'))
            .email(t('Enter a valid email!'))
            .matches(/^[^\s].*/, t('Email Id cannot start with a blankspace!')),
        company_id: Yup.string()
            .required(t('Company Name is required!')),
            // .matches(/^[^\s].*/, 'Company Name cannot start with a blankspace'),
            // .matches(/^[a-z0-9]+$/i, "Special characters are not allowed for Company Name"),
        property_id: Yup.array().min(1, t('Property is required!')),
        building_id: Yup.array().min(1, t('Building is required!')),
        user_type: Yup.string().required(t('User Type is required!')),
        // .matches(/^[a-z0-9]+$/i, "Special characters are not allowed for Building"),
        // fmContractNumberField: Yup.string().required('FM Contract Number is required(Default)'),
        // .matches(
        //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     "FM Contract Number is not valid" ),
        designationField: Yup.string()
            .required(t('Designation is required!'))
            .matches(/^[^\s].*/, t('Designation cannot start with a blankspace!'))
            .matches(/^[a-z0-9]+$/i, t("Special characters and blankspace are not allowed for Designation!"))
            .min(4, t('Minimum length is 4!'))
            .max(25, t('Maximum 25 characters!')),
    });
    const validationSchemaFCSS = Yup.object({
        firstNameField: Yup.string()
            .required(t('First Name is required!'))
            .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
            // .test('filled', 'First Name cannot contain only blankspaces', (value) => {
            //     if (value)
            //          return ( value.trim().length !== 0 )
            //     return true
            // })
        lastNameField: Yup.string()
            .required('Last Name is required')            
            // .test('filled', 'Last Name cannot contain only blankspaces', (value) => {
                //     if (value)
                //          return ( value.trim().length !== 0 )
                //     return true
                // })
            .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        emirateIDField: Yup.string()
            .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
            .required(t('Emirates ID is required!'))
            .min(15, t('Minimum length is 15!'))
            .max(25, t('Maximum 25 characters!'))
            .matches( /^(\d+-?)+\d+$/, t("Emirates Id contains only numbers and hypen!") ),
            // .test('len', 'Emirates ID is required.', (value) => {
            //     return value ? (value?.trim()).length > 0 : false
            // }),
        mobileNumberField: Yup.string()
            .min(4, t('Mobile No should be greater then 4 digits!'))
            .max(16, t('Mobile No should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
            .required(t('Mobile No is required!')),
        emailIDField: Yup.string()
            .required(t('Email ID is required!'))
            .email(t('Enter a valid email!'))
            .matches(/^[^\s].*/, t('Email Id cannot start with a blankspace!')),
        company_id: Yup.string()
            .required(t('Company Name is required!')),
            // .matches(/^[^\s].*/, 'Company Name cannot start with a blankspace'),
        property_id: Yup.array().min(1, t('Property is required!')),
        building_id: Yup.array().min(1, t('Building is required!')),
        designationField: Yup.string()
            .required(t('Designation is required!'))
            .matches(/^[^\s].*/, t('Designation cannot start with a blankspace!'))
            .matches(/^[a-z0-9]+$/i, t("Special characters and blankspace are not allowed for Designation!"))
            .min(4, 'Minimum length is 4!')
            .max(25, t('Maximum 25 characters!')),
        // .matches(/^[^\s].*/, 'Designation cannot start with a blankspace'),
    });

    
    const history = useHistory()
    let params: any = {};
    params = useLocation();
    const { addRole } = params.state;   //Adding from FCS(10) - values are : FC(8) or FCSS(9)
    // console.log(addRole,"addRole");

    const buttonEl: any = useRef(null);
    const state: any = useSelector(state => state);
    const userId = state?.auth?.user?.data?.id;
    // const userToken = state?.auth?.accessToken;
    // const id_role = state?.auth?.user?.data?.id_role;

    // const [buildingList, setBuildingList] = useState<any>([])
    const [selectedMobileCode, setMelectedMobileCode] = useState<any>('')
    const [companyList, setCompanyList] = useState<any>([])
    const [propertyList, setPropertyList] = useState<any>([])
    const [buildingList, setBuildingList] = useState<any>([])
    const [filteredBuildingList, setFilteredBuildingList] = useState<any>([])
    const [userTypeList, setUserTypeList] = useState<any>([])
    // const [userCompanyData, setUserCompanyData] = useState<any>()
    // const [userCompanyLoading, setUserCompanyLoading] = useState<any>(true)
    // const [userCompanyError, setUserCompanyError] = useState<any>(false)
    // const [userType, setUserType] = useState<any>([])
    // const [selectedBuilding, setSelectedBuilding] = useState<any>()

    // const [buildingOptions, setBuildingOptions] = useState<any>([])
    const [isLoading, setIsLoading] = useState<any>({
        company: true,
        property: true,
        building: true,
        userType: true,
    })
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [selectedBuildings, setSelectedBuildings] = useState([]);

    const [isSubmitting, setSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState('')
    // if (userToken === "undefined" || userId === "undefined") {
    //     // token undefined && // user id
    // }

    const { requestState: addNewTechnicianState, setRequestParams } = useRequestPost("/user/create", false, successNewBroker);

    const {
        loading,
        response: newTechnician,
        error
    } = addNewTechnicianState;

    // console.log('addNewTechnicianState', addNewTechnicianState);


    useEffect(() => {
        getFacilityCompanyData(userId).then(({ data }) => {
            setCompanyList((data && data.companies && Array.isArray(data.companies)) ? data.companies.map((item: any) => ({ id: `${item.company_id}`, name: item.company })) : [])
            setIsLoading((oState: any) => ({ ...oState, company: false }))
        }).catch((e) => { setIsLoading({ ...isLoading, company: false }) })

        getUserBuildingCommunityList(userId).then(({buildingListData, communityListData}) => {
            setPropertyList(communityListData)
            setBuildingList(buildingListData)
            setIsLoading((oState: any) => ({ ...oState, building: false, property: false }));
        }).catch((e)=>{setIsLoading({...isLoading, building:false, property:false })})

        // getFmCommunityUser('?user_id=' + userId).then(({ data }) => {
        //     if (data && Array.isArray(data)) {

        //         let buildingListData = new Array()
        //         let communityListData = new Array()
        //         data.forEach((community: any) => {
        //             communityListData.push({ id: `${community.community_id}`, name: community.community })
        //             community?.buildings?.forEach((building: any) => {
        //                 if (building.id && building.building_name) {
        //                     buildingListData.push({
        //                     id: `${building.id}`,
        //                     name: building.building_name,
        //                     communityId: `${community.community_id}`,
        //                     })
        //                 }
        //             })
        //         })
        //         setPropertyList(communityListData)
        //         setBuildingList(buildingListData)

        //         // setBuildingList(data.map((item: any) => ({ id: `${item.building_id}`, name: item.building_name, communityId: `${item.community_id}` })))

        //         // const communityList = data.map((item: any) => ({ id: `${item.community_id}`, name: item.community }))
        //         // const filteredCommulityList = communityList?.filter((value, index, self) => index === self.findIndex((el) => (el.id === value.id)))
        //         // setPropertyList(filteredCommulityList)
        //     }
        //     setIsLoading((oState: any) => ({ ...oState, building: false, property: false }));
        // }).catch((e) => { setIsLoading({ ...isLoading, building: false, property: false }) })

        getFacilityUserType().then(({ data }) => {
            setUserTypeList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: `${item.id}`, name: item.name })) : [])
            setIsLoading((oState: any) => ({ ...oState, userType: false }))
        }).catch((e) => { setIsLoading({ ...isLoading, userType: false }) })
    }, [])

    // useEffect(() => {
    //     // formik.setFieldValue('fmContractNumberField', userCompanyData?.fm_contact_number ? userCompanyData.fm_contact_number : '')
    //     // formik.setFieldValue('buildingField', userCompanyData?.buildings && userCompanyData.buildings[0]?.building_name ? userCompanyData.buildings[0]?.building_name : '')
    //     // formik.setFieldValue('companyNameField', userCompanyData?.companies && userCompanyData.companies[0]?.company ? userCompanyData.companies[0]?.company : '')
    // }, [userCompanyData])

    // console.log(userCompanyData);


    // const handleSelect = (e: any) => {
    // 	formik.handleChange(e)
    // 	setSelectedBuilding(buildingList.find((item: any) => item.id == e.target.value))
    // }

    // const buildingOptions = buildingList?.map((building: any) => (
    //     <option key={building.building_id} value={building.building_id}>
    //         {building.building_name}
    //     </option>
    // ))

    // const companyOptions = companyList?.map((company: any) => (
    //     <option key={company.company_id} value={company.company_id}>
    //         {company.company}
    //     </option>
    // ))


    // const userTypeOptions = userType?.map((user: any) => (
    //     <option key={user.id} value={user.id}>
    //         {user.name}
    //     </option>
    // ))

    const initialValues = {
        firstNameField: "",
        lastNameField: "",
        emirateIDField: "",
        mobileNumberField: "",
        emailIDField: "",
        company_id: '',
        property_id: [],
        building_id: [],
        designationField: "",
        user_type: "",
        // companyNameField: "",
        // buildingField: "",
        // building_id: '',
        // fmContractNumberField: "",
    }
    const onSubmit = (values: any) => {
        // const newTechnician = {
        //     first_name: values.firstNameField,
        //     last_name: values.lastNameField,
        //     email: values.emailIDField,
        //     emirates_id: values.emirateIDField,
        //     phone: values.mobileNumberField,
        //     designation: values.designationField,
        //     building_id: values.building_id,
        //     company_id: values.company_id,
        //     id_role: addRole,
        //     user_type:values.user_type,      
        //     created_by: userId
        // }
        // console.log(newTechnician, 'newTechnician');
        // setRequestParams(newTechnician);
        // console.log('Form: ',values);        

        setSubmitting(true)
        setSubmitError('')

        const formData = new FormData()
        formData.append("first_name", values.firstNameField.trim());
        formData.append("last_name", values.lastNameField.trim());
        formData.append("email", values.emailIDField);
        formData.append("emirates_id", values.emirateIDField.trim());
        formData.append('phone', selectedMobileCode + values.mobileNumberField)
        formData.append("designation", values.designationField.trim());
        formData.append("company_id", values.company_id);
        formData.append("user_type", values.user_type);
        formData.append("id_role", addRole);
        // formData.append("created_by", userId);
        values?.building_id?.forEach((element: any, index: any) => {
            formData.append(`buildingId[${index}]`, element);
        });

        setTimeout(() => {
            createContractorUser(formData)
                .then((response: any) => {
                    setSubmitting(false)
                    if (response.errorCode === 1)
                        setSubmitError(response.errorDescription)
                    else if (response.errorCode === 0) {
                        formik.resetForm();
                        setSubmitting(false)
                        var ev = new Event('click', { bubbles: true });
                        buttonEl.current.dispatchEvent(ev);
                    } else
                        setSubmitError(t("Some error occured. Please try again !"))
                })
                .catch((e) => {
                    // console.log(e, 'Error');
                    setSubmitting(false)
                    setSubmitError(t('Some error occured. Please try again !'))
                })
        }, 1000)
    }

    const validationSchema = (addRole === 'FCSS') ? validationSchemaFCSS : validationSchemaFC;

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    function successNewBroker() {
        var ev = new Event('click', { bubbles: true });
        buttonEl.current.dispatchEvent(ev);
        formik.resetForm();
    }

    // const onValueChange = (phoneNumber:any) => {
    //     // console.log(phoneNumber);
    // 	formik.setFieldValue('mobileNumberField', '+' + phoneNumber)
    // 	formik.setFieldTouched('mobileNumberField')
    // };

    const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
        setMelectedMobileCode(country?.dialCode && !phoneNumber.includes('+')? `+${country?.dialCode}` : '')
        formik.setFieldValue('mobileNumberField', phoneNumber)
        formik.setFieldTouched('mobileNumberField')
      }

    useEffect(() => {

        // let selectedPropertIDList = Array.isArray(formik.values.property_id) ? formik.values.property_id: [formik.values.property_id] ;
        // let selectedBuildingIDList = Array.isArray(formik.values.building_id) ? formik.values.building_id: [formik.values.building_id] ;
        let selectedPropertIDList = Array.isArray(selectedProperties) ? selectedProperties.map((item: any) => item.value) : [];
        let selectedBuildingIDList = Array.isArray(selectedBuildings) ? selectedBuildings.map((item: any) => item.value) : [];

        // console.log(selectedPropertIDList, selectedBuildingIDList);


        if (selectedPropertIDList) {
            formik.setFieldValue('property_id', selectedPropertIDList)
            // formik.setFieldTouched('property_id', true)
        } else {
            formik.setFieldValue('property_id', [])
            // formik.setFieldTouched('property_id', false)
        }

        const filteredBuildingList = buildingList?.filter((item: any) => selectedPropertIDList.includes(item.communityId))
        setFilteredBuildingList(filteredBuildingList)
        // console.log(filteredBuildingList, 'filteredBuildingList');


        const updatedSelectedBuildingList = filteredBuildingList?.filter((item: any) => selectedBuildingIDList.includes(item.id))
        const updatedSelectedBuildingIDList = filteredBuildingList?.map((item: any) => item.id)
        if (updatedSelectedBuildingIDList) {
            formik.setFieldValue('building_id', updatedSelectedBuildingList)
            // formik.setFieldTouched('building_id', true)
        } else {
            formik.setFieldValue('building_id', [])
            // formik.setFieldTouched('building_id', false)
        }

        const formatedSelectedBuildingList = updatedSelectedBuildingList?.map((item: any) => ({ label: item.name, value: item.id }))
        setSelectedBuildings(formatedSelectedBuildingList)
        // }, [formik.values.property_id])
    }, [selectedProperties])

    useEffect(() => {
        let selectedBuildingIDList = Array.isArray(selectedBuildings) ? selectedBuildings.map((item: any) => item.value) : [];
        if (selectedBuildingIDList) {
            formik.setFieldValue('building_id', selectedBuildingIDList)
            // formik.setFieldTouched('building_id', true)
        } else {
            formik.setFieldValue('building_id', [])
            // formik.setFieldTouched('building_id', false)
        }
        // console.log(selectedBuildingIDList, 'selectedBuildingIDList');
    }, [selectedBuildings])

    const isError = companyList?.length === 0 ? (t('No company data found!'))
        : propertyList?.length === 0 ? (t('No property data!'))
            : buildingList?.length === 0 ? (t('No building data found!'))
                : userTypeList?.length === 0 ? (t('No user-type data found!'))
                    : '';
    const isLoadingData = (isLoading.company || isLoading.property || isLoading.building || isLoading.userType)
    const propertyOptions = propertyList.map((item: any) => ({ label: item.name, value: item.id }))
    const buildingOptions = filteredBuildingList.map((item: any) => ({ label: item.name, value: item.id }))

    return (
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="com-card-section">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="card-title font-18 text-black mb-6">
                                <Link to={'/fc-su-myteam'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {addRole === 'FCSS' ? t('Add FCSS') : t('Add Technician(FC)')}
                                {/* Add Technician */}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body px-8 py-6">
                                    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                                        {isLoadingData ? (
                                            <div className="col-12 d-flex flex-column justify-content-center mt-5">
                                                <Loader />
                                            </div>
                                        ) : (isError !== '' && (
                                            <div className="col-12 mt-5">
                                                <div className="alert alert-danger mt-2 text-center w-100" role="alert">
                                                    {isError}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row form-add-design">
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("First Name")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('firstNameField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("First Name")}
                                                    />
                                                    {formik.touched.firstNameField && formik.errors.firstNameField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.firstNameField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Last Name")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('lastNameField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("Last Name")}
                                                    />
                                                    {formik.touched.lastNameField && formik.errors.lastNameField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.lastNameField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Emirates ID")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('emirateIDField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("Emirates ID")}
                                                    />
                                                    {formik.touched.emirateIDField && formik.errors.emirateIDField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.emirateIDField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Mobile Number")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <IntlTelInput
                                                        preferredCountries={['ae', 'in', 'us']}
                                                        containerClassName='intl-tel-input w-100'
                                                        separateDialCode={true}
                                                        fieldName='mobile_no'
                                                        value={formik.values.mobileNumberField}
                                                        inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                                        onPhoneNumberChange={handlePhoneNumber}
                                                        onPhoneNumberBlur={handlePhoneNumber}
                                                        placeholder={t('Mobile No.')}
                                                    />
                                                    {/* <PhoneInput
                                                        country={'ae'}
                                                        preferredCountries={['ae']}
                                                        // onlyCountries={['ae']}
                                                        // priority={{ ae: 0, in: 1, us: 2 }}
                                                        value={formik.values.mobileNumberField}
                                                        onChange={onValueChange}
                                                        enableSearch={true}
                                                        enableAreaCodeStretch={true}
                                                        inputClass={"text-black"}
                                                        placeholder={t('Mobile Number')}
                                                    /> */}
                                                    {/* <input
                                                        {...formik.getFieldProps('mobileNumberField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder=""
                                                    /> */}
                                                    {formik.touched.mobileNumberField && formik.errors.mobileNumberField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.mobileNumberField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Email ID")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('emailIDField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("Email ID")}
                                                    />
                                                    {formik.touched.emailIDField && formik.errors.emailIDField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.emailIDField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-5 mb-md-7'>
                                                    <label className='form-label fw-bolder'> {t("Company Name")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        className='form-control form-select fw-bolder form-control-solid'
                                                        data-control='select2'
                                                        data-allow-clear='true'
                                                        name='company_id'
                                                        // onChange={handleSelect}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.company_id}
                                                        onBlur={formik.handleBlur}
                                                    >
                                                        <option value='' disabled>
                                                            {t("Please Select")}
                                                        </option>
                                                        {companyList?.map((item: any, index: number) =>
                                                        (<option key={index} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                        )
                                                        )}
                                                        {/* {companyList ? companyOptions : ''} */}
                                                    </select>
                                                    {formik.touched.company_id && formik.errors.company_id && (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.company_id}</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">Company Name</label>
                                                    <input
                                                        {...formik.getFieldProps('companyNameField') }
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder=""
                                                        disabled
                                                    />
                                                    {formik.touched.companyNameField && formik.errors.companyNameField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.companyNameField}</small>
                                                        </div>
                                                    ): null }
                                                </div>
                                            </div> */}

                                            {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-5 mb-md-7'>
                                                    <label className='form-label fw-bolder'> Building
                                                    <span className="text-danger"> * </span>
                                                    </label>
                                                    <select
                                                        className='form-control form-select fw-bolder form-control-solid'
                                                        data-control='select2'
                                                        data-allow-clear='true'
                                                        name='building_id'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.building_id}
                                                        onBlur={formik.handleBlur}
                                                    >
                                                        <option value='' disabled>
                                                            Please Select
                                                        </option>
                                                        {buildingList ? buildingOptions : ''}
                                                    </select>
                                                    {formik.touched.building_id && formik.errors.building_id && (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.building_id}</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div> */}

                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-5 mb-md-7">
                                                    <label className='form-label fw-bolder'> {t("Property")} <span className="text-danger"> * </span> </label>
                                                    <MultiSelect
                                                        options={propertyOptions}
                                                        value={selectedProperties}
                                                        onChange={setSelectedProperties}
                                                        labelledBy={t("Select-property")}
                                                        hasSelectAll={false}
                                                    />
                                                    {formik.touched.property_id && formik.errors.property_id && (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.property_id}</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-2">
                                                    <label className='form-label fw-bolder'> {t("Building")} <span className="text-danger"> * </span> </label>
                                                    <MultiSelect
                                                        options={buildingOptions}
                                                        value={selectedBuildings}
                                                        onChange={setSelectedBuildings}
                                                        labelledBy={t("Select-Building")}
                                                        hasSelectAll={false}
                                                    />
                                                    {formik.touched.building_id && formik.errors.building_id && (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.building_id}</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>



                                            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder">FM Contract Number</label>
                                                    <input
                                                        {...formik.getFieldProps('fmContractNumberField') }
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder=""
                                                        disabled
                                                    />
                                                    {formik.touched.fmContractNumberField && formik.errors.fmContractNumberField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.fmContractNumberField}</small>
                                                        </div>
                                                    ): null }
                                                </div>
                                            </div> */}
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder">{t("Designation")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('designationField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("Designation")}
                                                    />
                                                    {formik.touched.designationField && formik.errors.designationField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.designationField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            {
                                                addRole !== "FCSS" &&

                                                <div className="col-md-4 col-12">
                                                    <div className="mb-7">
                                                        <label className="form-label fw-bolder">{t("User Type")}
                                                            <span className="text-danger"> * </span>
                                                        </label>
                                                        <select
                                                            className="form-control form-select fw-bolder form-control-solid"
                                                            data-control="select2"
                                                            data-allow-clear="true"
                                                            onChange={formik.handleChange}
                                                            name='user_type'
                                                            value={formik.values.user_type}
                                                            onBlur={formik.handleBlur}
                                                        >
                                                            <option value="">{t("Please Select")}</option>
                                                            {userTypeList?.map((item: any, index: number) =>
                                                            (<option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                            )
                                                            )}
                                                            {/* {userType ? userTypeOptions : ''} */}
                                                        </select>
                                                        {formik.touched.user_type && formik.errors.user_type ? (
                                                            <small className="text-danger">
                                                                {formik.errors.user_type}
                                                            </small>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-end d-flex flex-column align-items-end justify-content-end mt-5">
                            {isSubmitting && (
                                <Loader />
                            )}
                            {!isSubmitting && (
                                <div>
                                    <button
                                        onClick={() => { history.goBack() }}
                                        type='button' className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'>
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        onClick={formik.submitForm}
                                        type='submit'
                                        className='btn btn-primary fw-bold px-10 py-3'>
                                        {t("Submit")}
                                    </button>
                                </div>
                            )}
                            {submitError && (
                                // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
                                <div className="alert alert-danger w-100 text-center mt-5" role="alert">
                                    {submitError}
                                </div>
                            )}
                            <button ref={buttonEl} data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='button' className='invisible btn btn-primary fw-bold px-10 py-3'>
                                Submit
                            </button>
                            {/* <RequestSuccessfulPopup /> */}
                            <CustomRequestSuccessfulPopup
                                successMessage={(addRole === 'FCSS') ? "FCSS ADDED SUCCESSFULLY" : "TECHNICIAN ADDED SUCCESSFULLY"}
                                href={'/fc-su-myteam'} //For FCS module
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}