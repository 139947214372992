import React from 'react';
// import {Link} from 'react-router-dom'
// import Propertie from '../../../../../assets/images/latest-one.png';
// import Place from "../../../../../assets/images/place.svg";
// import pdficon from "../../../../../assets/images/pdf-file.png";
import { useTranslation } from 'react-i18next';

export function Buildings({community, userType}:any) {
    const { t } = useTranslation();
    let buildingList = new Array()
    if(userType === 'FM Security Manager' || userType === 'FM Team'){
        community?.forEach((item:any)=>{
            if(Array.isArray(item.buildings)){
                item?.buildings?.forEach((buildingData:any)=>{
                    buildingList.push({
                        community: item.community,
                        building: buildingData.building_name,
                    })
                })
            }else{
                buildingList.push({
                    community: item.community,
                    building: item.buildings?.building_name,
                })
            }
        })
    }else{
        community?.forEach((item:any)=>{
            buildingList.push({
                community: item.community,
                building: item.buildings?.building_name,
            })
        })
    }

    return (
        <div className="">
            <div className="col-12">
                <div className="row align-items-center">
                    <div className="col-9 text-start">
                        <h4 className="card-title font-18 text-black mb-0">
                            {t("Buildings Assigned")} ({buildingList.length})
                        </h4>
                    </div>
                    
                </div>
            </div>
            <div className="row mt-7">
                {buildingList?.map((building:any, index:number)=>{

                    return (
                        <div key={index} className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                            <div className="border rounded p-4">
                                            <div className="offer_main_details">
                                                <div className="col-12">
                                                    <p className="text-black fw-bold font-14 text-short mb-0">{building.building}</p>
                                                </div>
                                                <div className="col-12 offer_main_details_div_3">
                                                    <p className="mb-0 pt-2">
                                                        {/* <img src={Place} className="img-fluid"/> */}
                                                        ({building.community})
                                                    </p>
                                                </div>
                                            </div>
                                {/* <div className="row align-items-center">
                                    <div className="col-12">
                                        <span className="docx-title">Community: {building.community}</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="docx-title">Building: {building.buildings?.building_name}</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )
                })}
                
            </div>
        </div>
    );
}