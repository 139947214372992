import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useRequestGet from '../../../../../hooks/useRequestGet';
import { OnHoldBasicDetails } from './OnHoldBasicDetails/OnHoldBasicDetails';
import { OnHoldAdvancedDetailsAndTabs } from './OnHoldAdvancedDetailsAndTabs/OnHoldAdvancedDetailsAndTabs';
import { getApprovals } from '../../../../../constants/axios/apis';

const OnHoldDetailWrapper = ({approval}:any) => {
    let params: any = {};
    params = useLocation();
    // const { approval } = params.state || undefined;
    const workOrderID = approval?.object_id
    // console.log(params.state, 'FMTeamViewDetailsWrapper');

    // const [workOrderID, setWorkOrderID] = useState<any>('');
    // const [approval, setApproval] = useState<any>({});
    // // const { approval } = params.state || undefined;
    // const approvalID = params?.state?.approval?.id ? params.state.approval.id : undefined
    // useEffect(() => {
    //     getApprovals(`?id=${approvalID}`).then((response: any) => {
    //         if (response.data && Array.isArray(response.data) && response.data[0]) {
    //             setApproval(response.data[0])
    //             setWorkOrderID(response.data[0].object_id)
    //         } else {
    //             setApproval({})
    //         }
    //     })
    // }, [approvalID]);

    const [workOrderDetails, setWorkOrderDetails] = useState<any>({});
    const [loading, setLoading] = useState<any>(true);
    const { requestState: workOrderDetailState, refreshRequest: refreshRequestDetails } = useRequestGet(`/facility/work/orders/?id=${workOrderID}`);
    useEffect(() => {
        if (Array.isArray(workOrderDetailState.response)) {
            if (workOrderDetailState.response.length > 0) {
                setWorkOrderDetails(workOrderDetailState.response[0])
                setTimeout(()=>{setLoading(false)}, 500)
            }
        }
    }, [workOrderDetailState]);

    return (
        <>
            <OnHoldBasicDetails workOrderDetails={workOrderDetails} loading={loading} approval={approval}/>
            <OnHoldAdvancedDetailsAndTabs workOrderDetails={workOrderDetails} loading={loading}/>
        </>
    );
}

export { OnHoldDetailWrapper }

