import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
// import ProfileImage from '../../../../assets/images/profile-av-lg.jpg'
import Avatar from '../../../../assets/images/DefaultUser.png';
import { UpdateAccountStatus } from '../../../fc-soft-services-services/directory/components/UserDetailsWrapper/UpdateAccountStatus';
import { CustomRequestSuccessfulPopup } from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup';

export function Profile({teamMember}: any) {
  const { t } = useTranslation();
  const [accountStatusMsg, setAccountStatusMsg] = useState<any>('')

  return (
    <>
    <div className='profile-img-card rounded-5 w-100 py-9 px-13'>
      <div className='img border w-100 text-center'>
        <img src={teamMember?.profile_img ? teamMember?.profile_img : Avatar} className='img-fluid rounded-circle shadow' />
      </div>
      <div className='mx-3'>
        <h5 className='text-black font-18 fw-bolder mt-3 text-center'>{teamMember?.userName}</h5>
        {/* <span className='font-10 text-center d-block fw-bolder mb-3'>{teamMember?.assigned_roperties?.[0]}</span>
        <p className='text-center mb-3 text-muted fw-bold font-14'>Properties</p>
        <p className='text-center mb-3 fw-bold font-14'>{teamMember?.assigned_properties_count}</p>
        <p className='text-center mb-3 text-muted fw-bold font-14'>Community</p>
        <p className='text-black font-16 fw-bolder mt-3 mb-0 text-center'>--TODO--</p> */}
        
        <p className='text-center mb-3 text-muted fw-bold font-14'>{t("Account Status")}</p>
        <UpdateAccountStatus userData={teamMember} setAccountStatusMsg={setAccountStatusMsg}/>
      </div>
    </div>
    <CustomRequestSuccessfulPopup
          successMessage={accountStatusMsg}
          customID={'-account'}
          showOkBtn={true}
      />
    </>
  )
}
