import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'

const rolesData = [{
        "id": "1",
        "user_role": "Admin",
    },
    {
        "id": "2",
        "user_role": "Agent",
    },
    {
        "id": "3",
        "user_role": "Broker",
    },
    {
        "id": "4",
        "user_role": "Sales Administrator",
    },
    {
        "id": "5",
        "user_role": "Sales Manager	",
    },
];

export function UserRolesList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)
    return (
        <>
        <div className='com-card-section'>
            <div className='row align-items-center mb-5'>
                <div className="col-6">
                    <h3 className=''>User Roles</h3>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className='row align-items-center'>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> User Role </label>
                                <select className="form-control form-select fw-bolder">
                                    <option>Please Select </option>
                                    <option>Admin</option>
                                    <option>Agent</option>
                                    <option>Broker</option>
                                    <option>Sales Administrator</option>
                                    <option>Sales Manager</option>
                                </select>
                            </div>
                        </div>
                        <div className="col text-end">
                            <button type='reset' className='btn btn-outline-primary me-3'>
                                Cancel
                            </button>
                            <button type='submit' className='btn btn-primary px-5'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='table-crm'>
                <div className='table-responsive'>
                    <table className='table gy-4 gx-4 mb-0 no-sticky table-bordered'>
                        <thead className='text-uppercase bg-projects-list font-weight-normal'>
                            <tr>
                                <th>ID</th>
                                <th>User Role</th>
                                <th className="text-end"> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rolesData.length > 0 ? rolesData.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.id}</td>
                                    <td>{item.user_role}</td>
                                    <td className="action-button text-end">
                                        <div className="dropdown">
                                            <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                <i className="fa fa-ellipsis-v text-black"></i>
                                            </button>
                                            <div className="dropdown-menu mw-auto">
                                                <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                    <li>
                                                        <Link to={'edit'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                            <i className="fa fa-edit"></i>
                                                            <p className="m-0">Edit</p>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )) : <tr><td colSpan={3}>No data..</td></tr>
                            }
                        </tbody>
                    </table>
                    <div className='w-100 d-flex justify-content-end'>
                        <CompactPagination
                            pageSize={pageSize}
                            totalCount={rolesData.length}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={false}
                        />
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}
