import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import agentAvtar from '../../../assets/images/agent-avatar2.png'
import { AddMessagesPopup } from './NewMessagesModal'
import attachment from '../../../assets/images/attachment-ico.png'
import chatAvatr from '../../../assets/images/chat-avatar.png'

export function MessagesPage() {

    return (
        <>
            <div className="row ">
                <div className="col-md-10">
                    <h6 className="text-warning font-weight-semibold">
                        Messages
                    </h6>
                </div>

                <div className="col-2 text-end">
                    <select className="border-none-select">
                        <option selected >En </option>
                        <option>Arab</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <a href="#" className="text-decoration-none text-black font-weight-semibold font-12 me-2">
                                All
                            </a>
                            <a href="#" className="text-decoration-none text-black font-12">
                                Unread
                            </a>
                        </div>
                        <div className="col-md-6 text-end">
                            <a href="#" className="text-decoration-none text-black font-10">
                                Mark all as read
                            </a>
                        </div>
                        <div className="col-md-12 my-3 message">
                            <div className="card mx-height">
                                <div className="card-body">
                                    <ul className="p-0 users">
                                        <li className="list-unstyled border-bottom mb-4">
                                            <a href="javascript:void(0);" className="text-decoration-none">
                                                <div className="row">
                                                    <div className="col-md-2 pe-md-0">
                                                        <img src={agentAvtar} className="img-fluid" />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span className="font-14 text-black font-weight-semibold">
                                                            Sam mathew
                                                        </span>
                                                        <span className="agent-1 ms-3">
                                                            Agent
                                                        </span>
                                                        <p className="para-message">
                                                            Check the file to get an overview
                                                            of the up-coming project.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="list-unstyled border-bottom mb-4">
                                            <a href="javascript:void(0);" className="text-decoration-none">
                                                <div className="row">
                                                    <div className="col-md-2 pe-md-0">
                                                        <img src={agentAvtar} className="img-fluid" />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span className="font-14 text-black font-weight-semibold">
                                                            Soham Howard
                                                        </span>
                                                        <span className="agent-1 ms-3">
                                                            Agent
                                                        </span>
                                                        <p className="para-message">
                                                            Check the file to get an overview
                                                            of the up-coming project.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="list-unstyled border-bottom mb-4">
                                            <a href="javascript:void(0);" className="text-decoration-none">
                                                <div className="row">
                                                    <div className="col-md-2 pe-md-0">
                                                        <img src={agentAvtar} className="img-fluid" />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span className="font-14 text-black font-weight-semibold">
                                                            Bessie Black
                                                        </span>
                                                        <p className="para-message">
                                                            Check the file to get an overview
                                                            of the up-coming project.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="list-unstyled border-bottom mb-4">
                                            <a href="javascript:void(0);" className="text-decoration-none">
                                                <div className="row">
                                                    <div className="col-md-2 pe-md-0">
                                                        <img src={agentAvtar} className="img-fluid" />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span className="font-14 text-black font-weight-semibold">
                                                            Victoria Mccoy
                                                        </span>
                                                        <span className="agent-1 ms-3">
                                                            Landlord
                                                        </span>
                                                        <p className="para-message">
                                                            Check the file to get an overview
                                                            of the up-coming project.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>

                                        </li>
                                        <li className="list-unstyled border-bottom mb-4">
                                            <a href="javascript:void(0);" className="text-decoration-none">
                                                <div className="row">
                                                    <div className="col-md-2 pe-md-0">
                                                        <img src={agentAvtar} className="img-fluid" />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <span className="font-14 text-black font-weight-semibold">
                                                            Devon Edwards
                                                        </span>
                                                        <span className="agent-1 ms-3">
                                                            Agent
                                                        </span>
                                                        <p className="para-message">
                                                            Check the file to get an overview
                                                            of the up-coming project.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="my-2 text-center">
                                        <a data-bs-toggle="modal" data-bs-target="#addnew-messages" className="text-decoration-none bg-white rounded text-center py-2 font-14 text-warning font-weight-semibold">
                                            <i className="fa fa-plus text-warning me-3"></i>
                                            New Message
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-7">
                    <div className="col-md-12 mb-3 d-flex align-items-center">
                        <img src={agentAvtar} className="img-fluid me-3" width="30px" />
                        <h6 className="font-14 theme-color me-3 mb-0"> Sam mathew </h6>
                        <a href="#" className="text-warning font-weight-bold font-10 text-decoration-none">
                            View profile
                        </a>
                    </div>
                    <div className="card mx-height">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="chating">
                                        <div className="border-bottom py-5">
                                            <div className="">
                                                <div className="mt-4">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <i className="fas fa-check-double pe-1 font-10"></i>
                                                        <div className="msg-1">
                                                            Anything about this property
                                                        </div>
                                                        <img src={chatAvatr} className="img-fluid ms-1" width="30px" />
                                                    </div>
                                                    <div className="col-12 text-end">
                                                        <span className='date'>10:04 AM, Jan 23, 2020</span>
                                                    </div>
                                                </div>
                                                <div className="mb-5">
                                                    <div className="d-flex align-items-center">
                                                        <img src={chatAvatr} className="img-fluid me-2" width="30px" />
                                                        <span className="font-14 text-black font-weight-semibold">
                                                            Sam mathew
                                                        </span>
                                                        <span className="agent-1 ms-3">
                                                            Agent
                                                        </span>
                                                    
                                                    </div>
                                                    <div className="msg-2">
                                                            Check the file to get an overview of the up-coming
                                                            project.
                                                    </div>
                                                    <div>
                                                          <span className='date-1'>10:04 AM, Jan 23, 2020</span>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="d-flex align-items-center">
                                                        <img src={chatAvatr} className="img-fluid me-2" width="30px" />
                                                        <span className="font-14 text-black font-weight-semibold">
                                                            Sam mathew
                                                        </span>
                                                        <span className="agent-1 ms-3">
                                                            Agent
                                                        </span>
                                                    
                                                    </div>
                                                    <div className="msg-3">
                                                        <a href="#" className="text-warning font-weight-bold text-decoration-none">
                                                            <i className="far fa-file-pdf mr-1 font-10"></i> project-name.pdf
                                                        </a>
                                                    </div>
                                                    <div className="">
                                                       <span className='date-1'>10:04 AM, Jan 23, 2020</span> 
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <i className="fas fa-check-double pe-1 font-10"></i>
                                                        <div className="msg-1">
                                                            Thank You..
                                                        </div>
                                                        <img src={chatAvatr} className="img-fluid ms-1" width="30px" />
                                                    </div>
                                                    <div className="col-12 text-end">
                                                        <span className='date'>10:04 AM, Jan 23, 2020</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <form >
                                                <div className="row mt-5">
                                                    <div className="col-md-1 px-2 my-auto">
                                                        <div className="">
                                                              <img src={attachment} className="img-fluid pr-1 attach" width="" />
                                                            <input className="inputs" type="file" name="myfile"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 pl-0">
                                                        <div className="">
                                                            <input className="p-3 font-10 input-type" placeholder="Type Something" name="message" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 d-flex flex-wrap align-items-center">
                                                        <div className="text-end d-block w-100">
                                                            <button className="btn btn-primary font-weight-500 font-10 radius-15 py-2" type="submit">
                                                            <i className="fas fa-paper-plane"></i>
                                                                Send
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <AddMessagesPopup />

        </>
    )
}
