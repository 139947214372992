import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import avtaricon from '../../../assets/images/pm/avatar-icon.jpeg'
import avatar from "../../../assets/images/pm/avatar-icon.jpeg"
import { Offers } from './Tabs/offers';
import { Reminders } from './Tabs/Reminder';
import { Notes } from './Tabs/Notes';
import { Doccuments } from './Tabs/Doccuments';
import { Activities } from './Tabs/Activities';


export function OfferPreview() {
    const [tabPosition, setTabPosition] = useState("1");
    const OfferTab = (i: any) => {
        setTabPosition(i)
    }


    return (
        <>
            <div className="row align-items-center mb-5 community opportunity">
                <div className="col-md">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'/opportunites/offerList'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link> Offer Details
                    </h5>
                </div>
                <div className="col-auto">

                    <Link to={"/opportunites/offerList"} type='submit' className='btn btn-primary fw-bold px-10 py-3 text-uppercase'>
                        Offer List
                    </Link>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <div className="row align-items-center border-bottom py-4">
                        <div className="col-md-1 col-2">
                            <div className="lead-prof-img position-relative border rounded">
                                <img src={avtaricon} className="img-fluid rounded" alt="Image" />
                            </div>
                        </div>
                        <div className="col-md-9 col-10">
                            <div className="card-body py-0 px-2">
                                <h6 className="card-title font-weight-600 text-black mb-1 font-14">
                                    Radha Krishna
                                </h6>
                                <ul className="p-0 m-0 list-group list-group-horizontal d-inline-flex">
                                    <li className="list-unstyled">
                                        <a href="" target="_blank" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a href="" target="_blank" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a href="" target="_blank" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a href="javascript:void(0);" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                            <i className="fab fa-whatsapp"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 text-end">
                            <span className="bg-danger text-white rounded d-inline-block font-10 font-weight-600 px-2 py-1">Hot Lead</span>
                        </div>
                    </div>
                    <div className="row no- mt-4">
                        <div className="col-md-12 lead-det-prof">
                            <div className="d-block">
                                <div className="row">
                                    <div className="d-inline-block col-12">
                                        <h6 className="font-14 text-black font-weight-600 mb-3"> Lead Information </h6>
                                    </div>
                                    <input type="hidden" name="id_contact" className="id_contact" value="80" />
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Lead Ref</span></h6>
                                        <p className="font-13 mb-0 lh-normal mt-1"><a href="#" className="text-decoration-none text-warning font-12">REF-L-1627017165</a>
                                        </p>
                                        <p></p>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Lead Type</span></h6>
                                        <p className="font-12 text-muted">Buyer</p>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Lead Status</span></h6>
                                        <p className="font-12 text-muted">Active</p>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Lead Substatus</span></h6>
                                        <p className="font-12 text-muted">Offer Made</p>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Enquiry Date</span></h6>
                                        <p className="font-12 text-muted">23/07/2021</p>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Finance Type</span></h6>
                                        <p className="font-12 text-muted">Cash</p>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Actual Date</span> </h6>
                                        <p className="font-12 text-muted">18/07/2023</p>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Valid Till</span> </h6>
                                        <p className="font-12 text-muted">28/07/2023</p>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Buyer Type</span> </h6>
                                        <p className="font-12 text-muted">End User</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <div className="d-inline-block w-100 border-bottom py-4">
                                <h6 className="font-14 text-black m-0 font-weight-600">Unit Information </h6>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12 lead-det-prof pr-2 pr-2">
                                    <div className="card radius-15 bg-light mb-3">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 border-bottom pb-2">
                                                    gcf </h6>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                            <div className="">
                                                                <h6 className="font-12 text-black m-0">
                                                                    <span className="text-uppercase">Property Ref No</span></h6>                        
                                                                <p className="font-12">#REF-UN-1689307333</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                            <div className="">
                                                                <h6 className="font-12 text-black m-0">
                                                                    <span className="text-uppercase">Community</span></h6>
                                                                <p className="font-12 ellipsis"></p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                            <div className="">
                                                                <h6 className="font-12 text-black m-0">
                                                                    <span className="text-uppercase">Building</span></h6>
                                                                <p className="font-12 ellipsis"></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                            <div className="">
                                                                <h6 className="font-12 text-black m-0">
                                                                    <span className="text-uppercase">Price</span></h6>
                                                                <p className="font-12">AED 3</p>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                            <div className="">
                                                                <h6 className="font-12 text-black m-0">
                                                                    <span className="text-uppercase">Created at</span></h6>
                                                                <p className="font-12 mb-0">18/07/2323</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                                            <div className="">
                                                                <h6 className="font-12 text-black m-0">
                                                                    <span className="text-uppercase">Recent Action</span></h6>
                                                                <p className="font-12 mb-0">13/07/2323</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card '>
                <div className="card-body">
                    <div className='d-flex overflow-auto border-bottom '>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-4 flex-nowrap'>
                            <li className='nav-item'>
                                <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => OfferTab(1)}>
                                    Offers
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => OfferTab(2)}>
                                    Reminder
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => OfferTab(3)}>
                                    Note
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => OfferTab(4)}>
                                    Doccuments
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to className={`${tabPosition == '5' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => OfferTab(5)}>
                                    Activities
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {tabPosition == '1' ? <Offers /> :
                        tabPosition == '2' ? <Reminders /> :
                            tabPosition == '3' ? <Notes /> :
                                tabPosition == '4' ? <Doccuments /> :
                                    tabPosition == '5' ? <Activities /> : ''
                    }

                </div>
            </div>

        </>
    )
}
