import React, { useState, useEffect, useRef, FC } from 'react'
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../components/layout/core';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';
import { FinanceRefundDetailsScreen } from './FinanceRefundsDeatilsScreen/FinanceRefundDetailScreen';
import { FinanceRefundListWrapper } from './FinanceRefundsList/FinanceRefundListWrapper';

const FinanceServiceRequestRefundsRoutes: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');
    
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/finance-services-request-refunds/list'>
                            <FinanceRefundListWrapper />
                        </Route>
                        <Route path='/finance-services-request-refunds/view'>
                            <FinanceRefundDetailsScreen setPopupSuccessMsg={setPopupSuccessMsg} />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
            <CustomRequestSuccessfulPopup
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    );
}
const FinanceServiceRequestRefundsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <FinanceServiceRequestRefundsRoutes />
        </>
    )
};

export { FinanceServiceRequestRefundsWrapper }
