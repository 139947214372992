import React, {useState, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import { getFinanceServiceRequestList} from '../../../constants/axios/apis'
import { CustomLoader2} from '../../../constants/generics/customActios'
import { ChequeDelay } from './components/ChequeDelay'
import { BasicDetails } from './components/BasicDetails'
import { Actions } from './components/Actions'
import { ChequeReplace } from './components/ChequeReplace'
import { ClearanceChargesDispute } from './components/ClearanceChargesDispute'
import { OtherChargesDispute } from './components/OtherChargesDispute'
import { BrokerInvoiceDetails } from './components/BrokerInvoiceDetails'

export function FinanceDetailsScreen({setPopupSuccessMsg}:any) {

  let params: any = {}
  params = useLocation()
  let serviceID = ''
  if (params?.state?.serviceDetail?.id)
    serviceID = params.state.serviceDetail.id

  const [loading, setLoading] = useState<any>(true)
  const [serviceDetail, setServiceDetail] = useState<any>()

  useEffect(() => {
    setLoading(true)
    setServiceDetail({})
    getFinanceServiceRequestList(`?id=${serviceID}`)
      .then((response: any) => {
        if (response.data && Array.isArray(response.data) && response.data?.[0])
          setServiceDetail(response.data[0])
        setLoading(false)
      })
      .catch((e) => {})
  }, [serviceID])

  return (
    <>
      <div className='com-card-section myunit-page'>
        {loading ? (
          <CustomLoader2 />
        ) : (
          <>
            <BasicDetails serviceDetail={serviceDetail}/>
            
            { serviceDetail?.categoryCode === 'CQD' && <ChequeDelay serviceDetail={serviceDetail}/> }
            { serviceDetail?.categoryCode === 'CQR' && <ChequeReplace serviceDetail={serviceDetail}/> }
            { serviceDetail?.categoryCode === 'CCD' && <ClearanceChargesDispute serviceDetail={serviceDetail}/> }
            { serviceDetail?.categoryCode === 'OCD' && <OtherChargesDispute serviceDetail={serviceDetail}/> }
            { serviceDetail?.categoryCode === 'BKI' && <BrokerInvoiceDetails serviceDetail={serviceDetail}/> }
          </>
        )}
      </div>

      {!loading && <Actions serviceDetail={serviceDetail}  setPopupSuccessMsg={setPopupSuccessMsg} /> }
    </>
  )
}