import React, { useState, useEffect, useRef, FC } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {ApprovalsTabs} from './components/AvailabilityTabs/AvailabilityTabs';
import {Upcoming} from './components/Upcoming/Upcoming';
import {Available} from './components/Available/Available';
import {PropertyDetails} from './components/PropertyDetails/PropertyDetails';
import {EditPropertyDetails} from './components/EditPropertyDetails/EditPropertyDetails';
import {ReschedulePopup} from './components/ReschedulePopup/ReschedulePopup';
import {ScheduleVisitPopup} from './components/ScheduleVisitPopup/ScheduleVisitPopup';
import {RequestSuccessfulPopup} from '../SuccessfulPopup/RequestSuccessfulPopup';
import {MakeonOfferPopup} from './components/MakeonOfferPopup/MakeonOfferPopup';
import {MakeAnOfferPage} from './components/MakeAnOfferPage/MakeAnOfferPage';
import {MakeOfferPopup} from './components/MakeOfferPopup/MakeOfferPopup';
import {CardView} from './components/Available/CardView';
import {ListView} from './components/Available/ListView';
import {PMAvailabilityHeader} from './components/PMAvailabilityHeader/PMAvailabilityHeader';

const PMAvailabilityWrapper: FC = () => {
    const intl = useIntl();
    const [isCard, setCard] = useState(false);
    const onCard = () => {
        setCard(true);
    };
    const onList = () => {
        setCard(false);
    };
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <div className='row gy-5 g-xl-8 dashboard-page'>
            <div className="col-12 mt-0">
                <PMAvailabilityHeader isCard={isCard} onCard={onCard} onList={onList} />
            </div>
            <div className='col-xxl-12 mt-0'>
                <Switch>
                    <Route path='/pm-availability/available'>
                        <ApprovalsTabs />
                        {!isCard ?
                            <CardView />
                            :
                            <ListView />
                        }
                    </Route>
                    <Route path='/pm-availability/upcoming'>
                        <ApprovalsTabs />
                        {!isCard ?
                            <CardView />
                            :
                            <ListView />
                        }
                    </Route>
                    <Route path='/pm-availability/property-details'>
                        <PropertyDetails />
                        <ReschedulePopup />
                        <ScheduleVisitPopup />
                        <RequestSuccessfulPopup />
                        <MakeonOfferPopup />
                    </Route>
                    <Route path='/pm-availability/edit-details'>
                        <EditPropertyDetails />
                        <RequestSuccessfulPopup />
                    </Route>
                    <Route path='/pm-availability/make-an-offer'>
                        <MakeAnOfferPage />
                        <MakeOfferPopup />
                        <RequestSuccessfulPopup />
                    </Route>
                </Switch>
            </div>
        </div>
        </>
    )
};

export {PMAvailabilityWrapper}
