import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export function Table({inspectionDataList, serviceCategoryData, precise_locations, handleDelete, handleEdit}:any) {

    const inspectionDataFull = [...inspectionDataList]?.map((inspection: any) =>{
        const catData = serviceCategoryData?.find((el:any) => el.id == inspection.category_type_id)
        const roomData = precise_locations?.find((el:any) => el.id == inspection.room_id)
        return {...inspection, category_name:catData?.name, room_name: roomData?.name }
    })
    // console.log(inspectionDataFull, 'inspectionDataFull');
    // const handleRowDel = (row_id: any) =>{
    //     console.log(row_id, 'row_id');
        
    // }
    const { t } = useTranslation();
    return (
        <div className="col-12 pt-5">
            <div className="rec-payment-act-sec">
                <div className="row">
                    <div className="col-12">
                        <div className='table-responsive'>
                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                <thead>
                                    <tr>
                                        <th style={{width:'100px'}}> {t("No")} </th>
                                        <th style={{width:'200px'}}> {t("Category")} </th>
                                        <th style={{width:'100px'}}> {t("Room")} </th>
                                        <th style={{width:'100px'}}> {t("Req. Qty")} </th>
                                        <th style={{width:'350px'}}> {t("Description")} </th>
                                        <th style={{width:'150px'}}> {t("Action")} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inspectionDataFull?.map((inspection: any, index: number) =>{
                                        return (
                                            <tr key={inspection.no}>
                                                <td>{index + 1}</td>
                                                <td>{inspection.category_name}</td>
                                                <td>{inspection.room_name}</td>
                                                <td>{inspection.qty}</td>
                                                <td>{inspection.description}</td>
                                                <td >
                                                    <button className="text-primary bg-white border-0 me-5"
                                                        onClick={()=>handleEdit(inspection.no)} >
                                                        <i className="bi bi-pencil-square text-primary font-18"></i>
                                                    </button>
                                                    <button className="text-primary bg-white border-0"
                                                        onClick={()=>handleDelete(inspection.no)} >
                                                        <i className="bi bi-trash text-danger font-18"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {/* <tr>
                                        <td>
                                            01
                                        </td>
                                        <td>
                                            Broken kitchen
                                        </td>
                                        <td>
                                            Bedroom
                                        </td>
                                        <td>
                                           02
                                        </td>
                                        <td>
                                            Lorem Ipsum is simply dummy text of the
                                            printing and typesetting industry
                                        </td>
                                        <td >
                                            <button className="text-primary bg-white border-0 font-12">
                                                Edit
                                            </button>
                                            <button className="text-primary bg-white border-0 font-12">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            02
                                        </td>
                                        <td>
                                            Broken kitchen
                                        </td>
                                        <td>
                                            Bedroom
                                        </td>
                                        <td>
                                            02
                                        </td>
                                        <td>
                                            Lorem Ipsum is simply dummy text of the
                                            printing and typesetting industry
                                        </td>
                                        <td >
                                            <button className="text-primary bg-white border-0 font-12">
                                                Edit
                                            </button>
                                            <button className="text-primary bg-white border-0 font-12">
                                                Delete
                                            </button>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}