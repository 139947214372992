import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    plugins: {
        cutout:80,
        legend: {
            display: true,
            position: 'right' as const,
            labels: {
                color: 'rgb(0, 44, 106)',
                lineDash: [12,12],
                boxWidth: 19,
                boxHeight: 18,
                padding: 30,
                usePointStyle: false,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                }
            }
        },
    },
    aspectRatio: 1.9,
};

type chartProps = {
    chartData: {
        active: any,
        inactive: any,
        leave: any,
        total: any,
    }
}

export function MyTeam(props: chartProps) {

    const { t } = useTranslation();
    
        //Init chart labels and data
        const activeMembers = props?.chartData?.active !== undefined ? props.chartData.active : '';
        const inactiveMembers = props?.chartData?.inactive !== undefined ? props.chartData.inactive : '';
            
    
const data = {
    labels: [`${activeMembers} ${t("Active")}`, `${inactiveMembers} ${t("Inactive")}`],
    datasets: [
        {
            datalabels: {
                color: "#ffffff",
                font: {
                    size: 16,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
            data: [props.chartData.active , props.chartData.inactive ],
            backgroundColor: [
                '#FAA77B',
                '#969DD9',
            ],
            borderColor: [
                '#FAA77B',
                '#969DD9',
            ],
            borderWidth: 1,
        },
    ]
};
const newData = {...data}
newData.labels = [`${activeMembers} ${t("Active")}`, `${inactiveMembers} ${t("Inactive")}`];
newData.datasets[0].data = [activeMembers, inactiveMembers]
    return (
        <div className="pm_my_team_cart">
            <div className="row">
                <div className="col-12">
                    <div className="pm-chart pm-myteam-chart Doughnut-Chart text-center position-relative">
                        <Chart type='doughnut' data={newData} options={options} />
                        <div className="inner-text">
                            <h5 className="font-12 text-primary fw-boldest m-n3">
                                {props?.chartData?.total}<br/>
                                {t("Total")}<br/>
                                {t("Members")}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}