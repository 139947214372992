import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../components/partials/content/CompactPagination/CompactPagination'


const propertiesData = [{
    "sl": "1",
    "Reference": "REF-BLDG-1615462991",
    "Title": "test",
    "Community":"Wilton Park Residencies",
    "Building": "Belgravia Square 2",
    "Status": "Active",
    "Agent": "-",
    "Bedrooms": "-",
    "Bathrooms": "-",
    "Parking": "-",
    "Source": "-",
},
{
    "sl": "2",
    "Reference": "REF-BLDG-1615462991",
    "Title": "test",
    "Community":"Wilton Park Residencies",
    "Building": "Belgravia Square 2",
    "Status": "Active",
    "Agent": "-",
    "Bedrooms": "-",
    "Bathrooms": "-",
    "Parking": "-",
    "Source": "-",
},
{
    "sl": "3",
    "Reference": "REF-BLDG-1615462991",
    "Title": "test",
    "Community":"Wilton Park Residencies",
    "Building": "Belgravia Square 2",
    "Status": "Active",
    "Agent": "-",
    "Bedrooms": "-",
    "Bathrooms": "-",
    "Parking": "-",
    "Source": "-",
},
{
    "sl": "4",
    "Reference": "REF-BLDG-1615462991",
    "Title": "test",
    "Community":"Wilton Park Residencies",
    "Building": "Belgravia Square 2",
    "Status": "Active",
    "Agent": "-",
    "Bedrooms": "-",
    "Bathrooms": "-",
    "Parking": "-",
    "Source": "-",
},
{
    "sl": "5",
    "Reference": "REF-BLDG-1615462991",
    "Title": "test",
    "Community":"Wilton Park Residencies",
    "Building": "Belgravia Square 2",
    "Status": "Active",
    "Agent": "-",
    "Bedrooms": "-",
    "Bathrooms": "-",
    "Parking": "-",
    "Source": "-",
},
{
    "sl": "6",
    "Reference": "REF-BLDG-1615462991",
    "Title": "test",
    "Community":"Wilton Park Residencies",
    "Building": "Belgravia Square 2",
    "Status": "Active",
    "Agent": "-",
    "Bedrooms": "-",
    "Bathrooms": "-",
    "Parking": "-",
    "Source": "-",
},
];

export function PropertiesList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-6">
                    <h3 className=''>Properties Report</h3>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Reference  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Reference" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Ttile  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Ttile" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Community  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Community" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Building</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Building" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Status</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option >Please Select </option>
                                    <option>Active </option>
                                    <option>Inactive</option>
                                    <option>Draft</option>
                                    <option>Sold</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Bedrooms</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Bedrooms" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Bathrooms</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Bathrooms" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Parking</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Parking" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Source</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option >Please Select </option>
                                    <option>Facebook </option>
                                    <option>Instagram</option>
                                    <option>Google</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                    <div className="col"> </div>
                    <div className="col-auto">
                        <button className="btn btn-primary radius-10 font-weight-semibold px-4 font-10 text-uppercase property-download-btn btn-sm">Download</button>
                    </div>
                </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-2 d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='table-crm1'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th> Reference</th>
                                                                    <th> Title </th>
                                                                    <th> Community </th>
                                                                    <th> Building</th>
                                                                    <th> Status </th>
                                                                    <th> Agent </th>
                                                                    <th> Bedrooms </th>
                                                                    <th> Bathrooms </th>
                                                                    <th> Parking </th>
                                                                    <th> Source </th>
                                                                    <th> Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {propertiesData.length > 0 ? propertiesData.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className="text-warning">{item.sl}</td>
                                                                        <td>{item.Reference}</td>
                                                                        <td>{item.Title}</td>
                                                                        <td>{item.Community}</td>
                                                                        <td>{item.Building}</td>
                                                                        <td>{item.Status}</td>
                                                                        <td>{item.Agent}</td>
                                                                        <td>{item.Bedrooms}</td>
                                                                        <td>{item.Bathrooms}</td>
                                                                        <td>{item.Parking}</td>
                                                                        <td>{item.Source}</td>
                                                                        <td className="action-button text-end">
                                                                            <div className="dropdown">
                                                                                <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v text-black"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                                      
                                                                                        <li>
                                                                                            <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-eye"></i>
                                                                                                <p className="m-0">Preview</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={propertiesData.length}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
