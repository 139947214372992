import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {OpenDocumentTabs} from './OpenDocumentTabs/OpenDocumentTabs'
import {Cheques} from './Cheques/Cheques'
import {RenewalNotices} from './RenewalNotices/RenewalNotices'
import {Contracts} from './Contracts/Contracts'


export function OpenDocumentsList({searchItem, setSearchItem,documents}: any) {
  const location = useLocation()
  

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3 movein-moveout'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <Switch>
                    <Route path='/fc-ss-documents/open-documents/cheques'>
                      <OpenDocumentTabs />
                      <Cheques searchItem={searchItem} setSearchItem={setSearchItem} />
                        {/* documents={documents.filter(
                          (document: any) => document.requestType == 'Cheque'
                        )}
                      /> */}
                    </Route>
                    <Route path='/fc-ss-documents/open-documents/renewal-notices'>
                      <OpenDocumentTabs />
                      <RenewalNotices searchItem={searchItem} setSearchItem={setSearchItem} />
                        {/* documents={documents.filter(
                          (document: any) => document.requestType == 'Renewal Notices'
                        )}
                      /> */}
                    </Route>
                    <Route path='/fc-ss-documents/open-documents/contracts'>
                      <OpenDocumentTabs />
                      <Contracts searchItem={searchItem} setSearchItem={setSearchItem} />
                        {/* documents={documents.filter(
                          (document: any) => document.requestType == 'Contracts'
                        )}
                      /> */}
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
