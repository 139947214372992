import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Home from "../../../../../assets/images/nation-towers-new.png";
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';
import { currencyFormater } from '../../../../../constants/generics/customActios';

export function MainOfferCard({ main }: any) {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="row align-items-center h-100">
                                <div className="col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-4">
                                    <div className='img-bg'>
                                        <img src={main?.property_details?.coverImage?.length > 0 ? main?.property_details?.coverImage : Home} className="img-fluid" />
                                        <div className='property-overlay'>
                                            <div className="col-sm-12 col-12">
                                                <h6 className="mb-0 fw-bold text-white font-14">{t("Offer Number")} : {main?.offer_details?.offerNumber ? main?.offer_details?.offerNumber : '-'} </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white shadow-none rounded-0">
                                        <div className="card-body p-5">
                                            <div className="row">
                                                <div className="col-sm-12 col-12 mb-4">
                                                    <h6 className="text-black mb-0 fw-bold font-15"> {main?.property_details?.propertyName ? main?.property_details?.propertyName : '-'} </h6>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease Start Date")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {main?.offer_details?.startDate ? formatDate(main?.offer_details?.startDate) : '-'} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease End Date")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {main?.offer_details?.endDate ? formatDate(main?.offer_details?.endDate) : '-'}  </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Security Deposit")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {t("AED")} {main?.offer_details?.securityDeposit ? currencyFormater(main?.offer_details?.securityDeposit) : '-'} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Annual Rent")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {t("AED")} {main?.offer_details?.annualRent ? currencyFormater(main?.offer_details?.annualRent) : '-'} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13">{t("No. of Installments")}</h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {main?.offer_details?.noOfInstallments}  </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Target Rent")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {t("AED")} {main?.offer_details?.annualRent ? currencyFormater(main?.offer_details?.annualRent) : "-"} </span>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Budget Rent </h6>
                                                    <span className="text-black fw-bold font-14 ms-5"> AED {main?.offer_details?.annualRent} </span>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Rent Assessment </h6>
                                                    <span className="text-black fw-bold font-14 ms-5"> AED {main?.offer_details?.annualRent} </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}