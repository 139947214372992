import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom'


import MarketPalace1 from '../../../../assets/images/market_1.svg';
import MarketPalace2 from '../../../../assets/images/market_2.svg';
import MarketPalace3 from '../../../../assets/images/market_3.svg';
// import MarketPalace4 from '../../../../assets/images/market_4.svg';

export function PromotionCreatePopup() {
    const { t } = useTranslation();
    const history = useHistory();

    const actionList = [
        {
            title: t('Event'),
            icon: MarketPalace1,
            link: '/promotions/add/event'
        },
        {
            title: t('Offer'),
            icon: MarketPalace2,
            link: '/promotions/add/offer'
        },
        {
            title: t('Voucher'),
            icon: MarketPalace3,
            link: '/promotions/add/voucher'
        }
    ]

    return (
        <>
            <button className="btn btn-primary btn-sm font-16"
                data-bs-toggle='modal'
                data-bs-target='#create-market-place'
            >
                <i className="bi bi-plus-circle-fill align-middle me-1"></i>
                {t("Create")}
            </button>
            <div className="modal fade custom-modal create-campaign-popup" id="create-market-place">
                <div className="modal-dialog modal-dialog-scrollable modal-md modal-dialog-centered">
                    <div className="modal-content rounded-10">
                        <div className="modal-header p-0 justify-content-start bg-white border-0">
                            <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                        <div className="modal-body p-8">
                            <div className="quick-link-sec">
                                <div className="slick-slider text-center">
                                    <div className="row">
                                        {actionList.map((item: any, index: number) => {
                                            return (
                                                <div className="col-4" key={index}>
                                                    <div className="c-pointer" data-bs-dismiss="modal" onClick={(e) => { history.push(item.link) }}>
                                                        <div className="card m-0">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-12">
                                                                        <img src={item.icon} alt="market" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <h6 className="fw-bold mt-3 mb-0">{item.title}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}