import React, {useState, useEffect} from 'react'
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid'
import {getMyTeamUserList} from '../../../../constants/axios/apis'
import {Link, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../constants'
import {CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'
import {CompactPagination} from '../../../../components/partials/content/CompactPagination/CompactPagination'
import { useTranslation } from 'react-i18next';

// const columns = [
//   {
//     field: 'no',
//     headerName: 'No',
//     sortable: false,
//     disableColumnMenu: true,
//     width: 100,
//   },
//   {
//     field: 'first_name',
//     headerName: 'First Name',
//     width: 200,
//   },
//   {
//     field: 'last_name',
//     headerName: 'Last Name',
//     width: 200,
//   },
//   {
//     field: 'mobile',
//     headerName: 'Mobile Numbe',
//     width: 200,
//   },
//   {
//     field: 'company',
//     headerName: 'Company Name',
//     width: 250,
//   },
//   {
//     field: 'user_data',
//     headerName: 'Action',
//     sortable: false,
//     disableColumnMenu: true,
//     width: 100,
//     renderCell: (val: any) => {
//       return (
//         <Link
//           to={{
//             pathname: '/fc-su-myteam-details',
//             state: {user: val.row.user_data, prevPath: val.row.prev_path},
//           }}
//         >
//           <span className='view-option text-theme fw-bolder'>View</span>
//         </Link>
//       )
//     },
//   },
// ]

export function Supervisor({filterRole, searchItem, setSearchItem}: any) {
  const { t } = useTranslation();
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const pageSize = 10
  const location = useLocation()
  const [loading, setLoading] = useState<any>(true)
  const [teamList, setTeamList] = useState<any>()
  const [totalTeamList, setTotalTeamList] = useState<any>(0)
  const [currentPage, setCurrentPage] = useState<any>(1)

  const state: any = useSelector((state) => state)
  const userID = state?.auth?.user?.data.id

  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding])

  useEffect(() => {
    setLoading(true)
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&id_sort=1' +
      '&created_by=' +
      userID +
      '&search=' +
      searchItem +
      '&user_role=FCSS' +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    // '?user_role=FCSS' + '&building_id=' + currentBuilding
    getMyTeamUserList(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setTeamList(response.data)
        setTotalTeamList(response.dataCount)
      } else {
        setTeamList([])
        setTotalTeamList(0)
      }
      setLoading(false)
    }).catch(()=>{setLoading(false); setTeamList([]); setTotalTeamList(0)})
    //   if (response.errorCode === 0) {
    //     setMyTeamUsers(response?.data)
    //   } else if (response.errorCode === 1) {
    //     setMyTeamUsers([])
    //     setError(response.errorDescription)
    //   } else if (response === 400) {
    //     setMyTeamUsers([])
    //   } else {
    //     setMyTeamUsers([])
    //     setError('Some error occured')
    //   }
    //   setLoading(false)
    // })
    // .catch((e: any) => {
    //   setLoading(false)
    //   setMyTeamUsers([])
    //   setError('Some error occured')
    // })
  }, [filterRole, currentPage, currentBuilding, searchItem])

  // useEffect(() => {
  //   if (Array.isArray(myTeamUsers)) {
  //     const teamRow = myTeamUsers?.reverse().map((member: any, index: number) => {
  //       return {
  //         no: index + 1,
  //         first_name: member.first_name,
  //         last_name:member.last_name,
  //         mobile: member.mobile,
  //         company: member.companyName,
  //         user_data: member,
  //         prev_path: location.pathname,
  //       }
  //     })
  //     setTeamRow(teamRow)
  //   } else setTeamRow([])
  //   setLoading(false)
  // }, [myTeamUsers])

  if (!loading && (teamList === undefined || teamList?.length === 0)) {
    return <NoDataFound1 />
  }

  const teamData = teamList?.map((data: any, index: any) => (
    <tr key={data.id}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td>{data.first_name ? data.first_name : '--'}</td>
      <td>{data.last_name ? data.last_name : '--'}</td>
      <td>{data.mobile ? data.mobile : '--'}</td>
      <td>{data.companyName ? data.companyName : '--'}</td>
      <td className={data?.status === 0 ? 'text-danger' : 'text-success'}>
        {data?.status === 0 ? t('Inactive') : t('Active')}
      </td>
      <td>
        <Link
          to={{
            pathname: '/fc-su-myteam-details',
            state: {user: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t("View")}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body p-0'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                        <thead>
                          <tr>
                            <th className=''> {t("No.")} </th>
                            <th className=''> {t("First Name")}</th>
                            <th className=''> {t("Last Name")} </th>
                            <th className=''> {t("Mobile Number")} </th>
                            <th className=''> {t("Company Name")} </th>
                            <th className=''> {t("Account Status")} </th>
                            <th className=''> {t("Action")} </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={7}>
                                <CustomLoader2 />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>{teamList ? teamData : ''}</tbody>
                        )}
                      </table>
                      <CompactPagination
                        pageSize={pageSize}
                        totalCount={parseInt(totalTeamList)}
                        defaultPage={currentPage}
                        onPageChange={setCurrentPage}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
