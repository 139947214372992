// import { DataGrid } from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
// import { getApprovals1 } from '../../../../../../constants/axios/apis'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import moment from 'moment'
import {CustomLoaderCompact, NoDataFound1} from '../../../../../../constants/generics/customActios'
import {CompactPagination} from '../../../../../../components/partials/content/CompactPagination/CompactPagination'

import {useTranslation} from 'react-i18next'

export function MoveIn({
  approvals,
  totalApprovals,
  handleSubType,
  loading,
  currentPage, setCurrentPage
}: any) {

  const {t} = useTranslation()
  handleSubType(5)
  const pageSize = 10


  const approvalsList = approvals?.map((approval: any, index: any) => {
    const dataNumber = (currentPage - 1) * pageSize + index + 1

    return (
      <tr key={dataNumber}>
        <td>{dataNumber}</td>
        {/* <td>{approval?.details?.leaseNumber ? approval?.details?.leaseNumber : "--"}</td> */}
        <td>{approval.cutomerName ? approval.cutomerName : '--'}</td>
        <td>{approval.propertyCode ? approval.propertyCode : '--'}</td>
        <td>{moment(approval?.details?.leaseStartDate).format('DD-MM-YYYY')}</td>
        <td>{moment(approval?.details?.leaseEndDate).format('DD-MM-YYYY')}</td>
        {/* <td>{moment(approval?.details?.moveInDate).format("DD-MM-YYYY")}</td> */}
        <td>
          <Link to={{pathname: '/fcss-moveinDetails/building-access-card', state: {approval}}}>
            <span className='view-option text-theme fw-bolder'>{t('View')}</span>
          </Link>
        </td>
      </tr>
    )
  })

  return (
    <div className='row'>
      <div className='col-12 pt-5'>
        <div className='rec-payment-act-sec'>
          <div className='row'>
            <div className='col-12'>
              {!loading && (approvals === undefined || approvals?.length === 0) ? <NoDataFound1 /> :
                <>
                  <div className='table-responsive'>
                    <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                      <thead>
                        <tr>
                          <th className=''> {t('No.')} </th>
                          {/* <th className=''> Lease Number </th> */}
                          <th className=''> {t('Name')} </th>
                          <th className=''> {t('Unit')} </th>
                          <th className=''> {t('Lease Start Date')} </th>
                          <th className=''> {t('Lease End Date')} </th>
                          {/* <th className=''> Move-in Date </th> */}
                          <th className=''> {t('Action')} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                           <tr><td colSpan={7}><CustomLoaderCompact /></td></tr>
                          ) : approvalsList?.length ? approvalsList :''
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className='w-100 d-flex justify-content-end pt-5'>
                    <CompactPagination
                      pageSize={pageSize}
                      totalCount={parseInt(totalApprovals)}
                      defaultPage={currentPage}
                      onPageChange={setCurrentPage}
                      loading={loading}
                    />
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
