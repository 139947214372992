import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MultipleImageUploadComponent from './imageUploader'

export function EditCommunities() {
    return (
    <>
        <div className="row align-items-center mb-5 community">
            <div className="col-md-5">
                <h5 className="theme-color font-22 d-inline-block float-left">
                    <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                        <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                    </Link>Update Community
                </h5>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-md-12">
                <form action="" method="POST" name="add_building" id="add_building">
                    <div className="card form-add-design">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase">Community Code</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid active" disabled value="REF-CM-1689164354" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Community Name</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                            <option> Please Select </option>
                                            <option selected> WIlton Park Residencies</option>    
                                            <option > Belgravia Square</option>    
                                            <option> Emirates Hills </option>    
                                            <option> The Sloan by Belgravia Heights</option>           
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Building/Precinct Name</label>
                                        <input type="text" className="form-control font-13 form-height" name="name" placeholder="Building/Precinct Name" value="" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Building/Precinct Type</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" id="building-type" name="type" data-live-search="true" title="Select Type" required>
                                            <option value="2">Vertical</option>
                                            <option value="1">Horizontal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Floors</label>
                                        <input type="text" className="form-control font-13 form-height convertThis" name="Floors" placeholder="Floors" value="8" required />
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Category</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" id="" name="category" data-live-search="true" title="Select Category" required>
                                            <option value="1">Category 1</option>
                                            <option value="2" selected>Category 2</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Total Sq.ft</label>
                                        <input type="text" className="form-control font-13 form-height convertThis" name="total_sq_ft" placeholder="Total Sq.ft" value="5,000" required />
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Total Leasable Area</label>
                                        <input type="text" className="form-control font-13 form-height convertThis" name="total_area" placeholder="Total Leasable Area" value="6,000" required />
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Start Date</label>
                                        <input type="date" className="form-control font-13 form-height" name="start_date" placeholder="Start Date" value="2023-07-13" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">End Date</label>
                                        <input type="date" className="form-control font-13 form-height" name="end_date" placeholder="End Date" value="2023-07-23" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Status</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" id="" name="status" data-live-search="true" title="Select Status" required>
                                            <option value={1}>Active</option>
                                            <option value={2}>Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                
                                    <div className="col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="d-block text-uppercase mb-0 font-13"> Building Image </label>
                                            </div>
                                        </div>
                                        <MultipleImageUploadComponent/>
                                    </div>

                                <div className="col-12">
                                    <div className="text-end mt-5">
                                        <button type="reset" className="btn btn-outline-primary fw-bold px-10 py-3 me-3">Reset</button>
                                        <button type="submit" className="btn btn-primary fw-bold px-10 py-3">Update Building</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>  
    )
}