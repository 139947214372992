import React, { useState, useEffect } from 'react'
import { Link, useLocation} from 'react-router-dom'
import {PropertyDetails} from './building-details/PropertyDetails'
import { Notes } from './building-details/Notes'
import { ActivityLogs } from './building-details/ActivityLogs'
import {NearBy} from './building-details/NearBy'
import {Offers} from './building-details/Offers'
import { detailBuilding, singleBuilding } from '../../../constants/axios/apis'

export function PreviewBuilding() {
    const [tabPosition, setTabPosition] = useState("1");
    const[buildingList, setBuildingList] = useState<any>([]);
    const buildingTab = (i: any) => {
        setTabPosition(i)
    }

    let params:any = {}
    params = useLocation();
    let referenceId = ''
    if(params?.state?.buildings?.reference_id)
    referenceId = params.state.buildings?.reference_id;
    const ID = params?.state?.buildings?.id;

    useEffect(()=>{
        detailBuilding(`/${ID}`).then((response:any)=>{
            setBuildingList(response.data);
    }).catch((e)=>{
  
    })
    }, [])



    return (      
        <>
        <div className="row align-items-center mb-5 community">
            <div className="col-md-5">
                <h5 className="theme-color font-22 d-inline-block float-left">
                    <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                        <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                    </Link>{buildingList?.community_name}
                </h5>
            </div>
        </div>

        <div className="row my-4">
            <div className="clo-md-12 col-lg-8 col-12 text-center">
                <img src={buildingList?.cover_image} alt="building image" className="img-fluid"/>
            </div>
            <div className="col-md-12 col-lg-4 col-12">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4 radius-15 h-100">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Building Code : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">{buildingList?.reference_id}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Building / Precinct Type : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">{buildingList?.building_type} </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Community Name : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">{buildingList?.community_name}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Total Units : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">---</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Units Booked : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">---</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Available Units : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">---</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Floors : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">8</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Total SQ.FT : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">5,000 sq ft </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Total Leasable Area : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500"> 6,000 sq ft</span>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                                <div className="pub-status d-flex align-items-center justify-content-center mt-3 text-center">
                                    <i className="fas fa-check-circle text-warning font-16 mr-1"></i>
                                    <span className="text-white font-14 text-uppercase font-weight-semibold ms-3">Status </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='d-flex overflow-auto'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-4 flex-nowrap'>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(1)}>
                        Building Details
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(2)}>
                        Near by
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(3)}>
                        Offers
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(4)}>
                        Notes
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '5' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(5)}>
                        Activity Logs
                    </Link>
                </li>
            </ul>
        </div>
        
        {   tabPosition == '1' ? <PropertyDetails/> : 
            tabPosition == '2' ? <NearBy/> :
            tabPosition == '3' ? <Offers/> :
            tabPosition == '4' ? <Notes/> :
            tabPosition == '5' ? <ActivityLogs/> : ''
        }
        
    </>
    )
}

