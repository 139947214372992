import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

const RaiseProformaTabs: React.FC = () => {

    const location = useLocation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                    (location.pathname === '/pm-raise-proforma/open-cheque-details' && 'active')}
                                                    to='/pm-raise-proforma/open-cheque-details'>
                                                    Open Cheque Details
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/pm-raise-proforma/new-cheque-details' && 'active')}
                                                    to='/pm-raise-proforma/new-cheque-details'>
                                                    New Cheque Details
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {RaiseProformaTabs}
