import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Search} from './Search/Search';
import { useTranslation } from 'react-i18next'

// const TeamMembersTabs: React.FC = (props: myTeamDataProps) => {
export function TeamMembersTabs(myTeamData:any) {

    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="col-12 mt-4">
            <div className="row access-cd-tabs align-items-center">
                <div className="col-sm-4">
                    <h4 className="card-title font-18 text-black">{t("Team Members")}</h4>
                </div>
                <div className="col-sm-6">
                    <div className='d-flex overflow-auto h-55px float-end me-6 me-sm-10'>
                        {/* <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                            <li className='nav-item'>
                                <Link
                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                    (location.pathname === '/pm-myteam/all' && 'active')}
                                    to='/pm-myteam/all'>
                                    All
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                    (location.pathname === '/pm-myteam/week' && 'active')}
                                    to='/pm-myteam/week'>
                                    Week
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                    (location.pathname === '/pm-myteam/month' && 'active')}
                                    to='/pm-myteam/month'>
                                    Month
                                </Link>
                            </li>
                        </ul> */}
                    </div>
                </div>
                <div className="col-sm-2">
                    <Search />
                </div> 
            </div>
        </div>
    );
};

