/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const ChatTabs: FC = () => {
    const location = useLocation()
    const {t} = useTranslation()

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-md-8">
                        <div className='d-flex overflow-auto'>
                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent mb-4 flex-nowrap'>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 pt-0 position-relative me-8 ` +
                                        (location.pathname === '/chat/all' && 'active')}
                                        to='/chat/all'>
                                        {t("All")}
                                    </Link>
                                </li>
                                {/*<li className='nav-item'>*/}
                                    {/*<Link*/}
                                        {/*className={`nav-link pb-1 pt-0 position-relative ` +*/}
                                        {/*(location.pathname === '/chat/unread' && 'active')}*/}
                                        {/*to='/chat/unread'>*/}
                                        {/*Unread*/}
                                    {/*</Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 text-end">
                        {/*<span className="mark-read c-pointer font-11 fw-bold">*/}
                            {/*Mark all as read*/}
                        {/*</span>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export {ChatTabs}
