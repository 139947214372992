import { Chart } from 'react-chartjs-2';

export function OverviewChart({ chartData }: any) {

    const data = {
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },

                data: [40, 60],
                backgroundColor: ['#F3B521', '#000000' ],
                borderColor: ['#F3B521', '#000000'],
                borderWidth: 1,
            },
        ]
    };

    const options = {
        plugins: {
            cutout: 70,
            legend: {
                display: false,
                position: 'right' as const,
                labels: {
                    color: '#000000',
                    lineDash: [12, 12],
                    boxWidth: 16,
                    boxHeight: 16,
                    padding: 15,
                    usePointStyle: true,

                    font: {
                        size: 12,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    }
                }
            },
        },
        maintainAspectRatio: true,
    };

    return (
        <div className="com-card-section">
            <div className="px-7">
                <div className="row">
                    <div className="col-12">
                        <div className="Doughnut-Chart text-center position-relative top-0" id="unitsumary">
                            <div style={{ width: '80%', height: '80%' }}>
                                <Chart type='pie' data={data} options={options} />
                            </div>
                        </div>
                    </div>
                    <h4 className='mt-3 text-center text-dark fw-bold'>1M</h4>
                </div>
            </div>
        </div>
    );

}