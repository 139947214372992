import React, { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination'

const leadData = [{
  "refrence": "REF-L-1627017165",
  "stats": "New",
  "subStatus": "Not yet Contacted",
  "leadType": "Buyer",
  "firstName": "Abdul",
  "lastName": "Kadhar",
  "email": "abdul@gamil.com",
  "mobile": "	7892876246",
  "source": "Google",
  "enquiryDate": "	23/07/2021",
  "recentAction": "23/07/2021"
},
{   
  "refrence": "REF-L-1615803878",
  "stats": "Active",
  "subStatus": "Offer Made",
  "leadType": "Buyer",
  "firstName": "Brian",
  "lastName": "Ross",
  "email": "brian.ross@yahoo.com",
  "mobile": "	7892876246",
  "source": "Myvilla",
  "enquiryDate": "31/12/2020",
  "recentAction": "23/03/2021"
},
{
  "refrence": "REF-L-1616475236",
  "stats": "Active",
  "subStatus": "Offer Made",
  "leadType": "Buyer",
  "firstName": "Demo",
  "lastName": "Test",
  "email": "test@gamil.com",
  "mobile": "	7892876246",
  "source": "Facebook",
  "enquiryDate": "03/02/2020",
  "recentAction": "22/04/2121"
},
{
  "refrence": "REF-L-1616132103",
  "stats": "New",
  "subStatus": "Not yet Contacted",
  "leadType": "Buyer",
  "firstName": "muhammadu",
  "lastName": "-",
  "email": "muhammadu@gmail.com",
  "mobile": "	-",
  "source": "Google",
  "enquiryDate": "19/03/2121",
  "recentAction": "19/03/2121"
},
{
  "refrence": "REF-L-1626945778",
  "stats": "Wom",
  "subStatus": "Contact scheduled",
  "leadType": "Lease",
  "firstName": "Rafic",
  "lastName": "hamad",
  "email": "rafic@gamil.com",
  "mobile": "	7892876246",
  "source": "Google",
  "enquiryDate": "	23/07/2121",
  "recentAction": "23/07/2121"
}
];

export function LeadList() {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState<any>(1)
  return (
<>
<div className='row align-items-center mb-5'>
        <div className="col">
            <h3 className=''>Leads</h3>
        </div>
        <div className="col-auto text-end">
            <Link to='add' className='btn btn-primary fw-bold px-10 py-3'>
                Add Lead
            </Link>
        </div>
      </div>

      <div className="row mb-4">
          <div className="col-md-2 mb-3">
              <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                  <div className="bg-white border-0 text-center my-auto">
                      <h3 className="d-inline-block theme-color font-weight-bold mb-0">15</h3>
                      <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Open Leads</a>
                  </div>
              </div>
          </div>
          <div className="col-md-2 mb-3">
              <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                  <div className="bg-white border-0 text-center my-auto">
                      <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                      <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Qualified Leads</a>
                  </div>
              </div>
          </div>
          <div className="col-md-2 mb-3">
              <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                  <div className="bg-white border-0 text-center my-auto">
                      <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                      <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Viewing Arranged</a>
                  </div>
              </div>
          </div>
          <div className="col-md-2 mb-3">
              <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                  <div className="bg-white border-0 text-center my-auto">
                      <h3 className="d-inline-block theme-color font-weight-bold mb-0">4</h3>
                      <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Offer Made</a>
                  </div>
              </div>
          </div>
          <div className="col-md-2 mb-3">
              <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                  <div className="bg-white border-0 text-center my-auto">
                      <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                      <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">On Hold</a>
                  </div>
              </div>
          </div>
          <div className="col-md-2 mb-3">
              <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                  <div className="bg-white border-0 text-center my-auto">
                      <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                      <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Disqualified</a>
                  </div>
              </div>
          </div>
      </div>

      <div className='row align-items-center mb-5 table-drop'>
          <div className="col-10"></div>
          <div className="col-2 text-end d-flex align-items-center">
              <span className="me-2">Show</span>
              <select className="form-control form-select">
                  <option>10 </option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
              </select>
              <span className="ms-2">entries</span>
          </div>
      </div>

      <div className='com-card-section'>
          <div className='table-crm'>
              <div className='row'>
                  <div className='col-12'>
                      <div className='table-responsive'>
                          <table className='table mb-0 gx-4 text-nowrap table-bordered'>
                              <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                  <tr>
                                      <th> Ref.</th>
                                      <th> Status </th>
                                      <th> Sub Status </th>
                                      <th> Lead Type</th>
                                      <th> First Name</th>
                                      <th> Last Name </th>
                                      <th> Email </th>
                                      <th> Mobile No </th>
                                      <th> Source </th>
                                      <th> Enquiry Date </th>
                                      <th> Recent Action On </th>
                                      <th> Action </th>
                                  </tr>
                                  <tr>
                                      <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/></th>
                                      <th> <select className="rounded border px-3 py-1">
                                              <option>Select</option>
                                              <option>New</option>
                                              <option>Active</option>
                                              <option>Constraint</option>
                                              <option>Lost</option>
                                              <option>Won</option>
                                          </select>
                                      </th>
                                      <th></th>
                                      <th><select className="rounded border px-3 py-1">
                                              <option>Select</option>
                                              <option>Buyer</option>
                                              <option>Lease</option>
                                          </select> </th>
                                      <th> <input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/></th>
                                      <th> <input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/></th>
                                      <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/> </th>
                                      <th><input type="text" className="rounded border px-3 py-1" placeholder='Mobile no'/> </th>
                                      <th> <select className="rounded border px-3 py-1">
                                              <option>Select</option>
                                              <option>Social Media</option>
                                              <option>Facebook</option>
                                              <option>Instagram</option>
                                              <option>Google</option>
                                          </select> </th>
                                      <th></th>
                                      <th></th>
                                      <th className="border-0" style={{background:'#dedede'}}><button className="btn btn-secondary font-12 refereshResults">Reset</button>  </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {leadData.length > 0 ? leadData.map((item, i) => (
                                      <tr key={i}>
                                          <td className="text-warning">{item.refrence}</td>
                                          <td>{item.stats}</td>
                                          <td>{item.subStatus}</td>
                                          <td>{item.leadType}</td>
                                          <td>{item.firstName}</td>
                                          <td>{item.lastName}</td>
                                          <td>{item.email}</td>
                                          <td>{item.mobile}</td>
                                          <td>{item.source}</td>
                                          <td>{item.enquiryDate}</td>
                                          <td>{item.recentAction}</td>
                                          <td className="action-button text-end">
                                              <div className="dropdown">
                                                  <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                      <i className="fa fa-ellipsis-v text-black"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                      <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                          <li>
                                                              <Link to="edit" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                  <i className="fa fa-edit"></i>
                                                                  <p className="m-0">Edit</p>
                                                              </Link>
                                                          </li>
                                                          <li>
                                                              <Link to="edit" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                  <i className="fa fa-paper-plane"></i>
                                                                  <p className="m-0">Qualify</p>
                                                              </Link>
                                                          </li>
                                                          <li>
                                                              <Link to="view" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                  <i className="fa fa-eye"></i>
                                                                  <p className="m-0">Preview</p>
                                                              </Link>
                                                          </li>
                                                          <li>
                                                              <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                  <i className="fa fa-share-alt"></i>
                                                                  <p className="m-0">Share</p>
                                                              </Link>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                          </td>
                                      </tr>
                                  )) : <tr><td colSpan={3}>No data..</td></tr>
                                  }
                              </tbody>
                          </table>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <CompactPagination
                                pageSize={pageSize}
                                totalCount={leadData.length}
                                defaultPage={currentPage}
                                onPageChange={setCurrentPage}
                                loading={false}
                            />
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </>
  )
}
