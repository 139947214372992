import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PropertyDetails } from './building-details/PropertyDetails';
import { NearBy } from './building-details/NearBy';
import { Offers } from './building-details/Offers';
import { Notes } from './building-details/Notes';
import { ActivityLogs } from './building-details/ActivityLogs';
import Carousel from './SlickSilder';


export function CommunitiesPreview() {
    const [tabPosition, setTabPosition] = useState("1");

    const images = [
        'https://exalogic-store.s3.us-east-2.amazonaws.com/bl-crm/images/wilton-1.jpg',
        'https://exalogic-store.s3.us-east-2.amazonaws.com/bl-crm/images/wilton-2.jpg',
        'https://exalogic-store.s3.us-east-2.amazonaws.com/bl-crm/images/wilton-3.jpg',
        'https://exalogic-store.s3.us-east-2.amazonaws.com/bl-crm/images/wilton-4.jpg'
        // Add more image URLs here
    ];

    const buildingTab = (i: any) => {
        setTabPosition(i)
    }
    return (
        <>
        <div className="row align-items-center mb-5 community">
            <div className="col-md-12">
                <h5 className="theme-color font-22 d-inline-block float-left">
                    <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                        <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                    </Link>Wilton Park Residencies Stores
                </h5>
            </div>
        </div>

        <div className="row my-4">
            <div className="col-lg-8 col-12 text-center">
                <Carousel images={images} />
                {/* <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/bl-crm/images/wilton-1.jpg" alt="building image" className="img-build"/> */}
            </div>
            <div className="col-lg-4 col-12">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4 radius-15 h-100">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-12 text-theme-black font-weight-500 mb-0 col-sm-6"> Community Code : </label>
                                            <div className="col-sm-6">
                                                <span className="font-12 text-theme-black font-weight-500"> REF-CM-1607931313 </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-12 text-theme-black font-weight-500 mb-0 col-sm-6"> Community Type : </label>
                                            <div className="col-sm-6">
                                                <span className="font-12 text-theme-black font-weight-500"> horizontal </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-12 text-theme-black font-weight-500 mb-0 col-sm-6"> Total Buildings : </label>
                                            <div className="col-sm-6">
                                                <span className="font-12 text-theme-black font-weight-500"> 8 </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-12 text-theme-black font-weight-500 mb-0 col-sm-6"> Total Units : </label>
                                            <div className="col-sm-6">
                                                <span className="font-12 text-theme-black font-weight-500"> 23 </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-12 text-theme-black font-weight-500 mb-0 col-sm-6"> Units Booked : </label>
                                            <div className="col-sm-6">
                                                <span className="font-12 text-theme-black font-weight-500"> --- </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-12 text-theme-black font-weight-500 mb-0 col-sm-6"> Available Units : </label>
                                            <div className="col-sm-6">
                                                <span className="font-12 text-theme-black font-weight-500"> 23 </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-12 text-theme-black font-weight-500 mb-0 col-sm-6">Property Status : </label>
                                            <div className="col-sm-6">
                                                <span className="active-bg d-inline px-3 font-weight-semibold">Completed</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="pub-status d-flex align-items-center justify-content-center mt-3 text-center">
                                    <i className="fas fa-check-circle text-warning font-16 mr-1"></i>
                                    <span className="text-white font-14 text-uppercase font-weight-semibold ms-3"> Active </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='d-flex overflow-auto mt-5'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-4 flex-nowrap'>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(1)}>
                      Community Details
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(2)}>
                        Near by
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(3)}>
                        Offers
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(4)}>
                        Notes
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '5' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(5)}>
                        Activity Logs
                    </Link>
                </li>
            </ul>
        </div>
        
        {   tabPosition == '1' ? <PropertyDetails/> : 
            tabPosition == '2' ? <NearBy/> :
            tabPosition == '3' ? <Offers/> :
            tabPosition == '4' ? <Notes/> :
            tabPosition == '5' ? <ActivityLogs/> : ''
        }
        
    </>
    )
}