import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { saveAccessCard } from '../../../../../../../../constants/axios/apis';
import { RequestFailedPopup } from '../../../../../../../SuccessfulPopup/RequestFailedPopup'

const saveAccessCardRequest = Yup.object().shape({
  card_number: Yup.array().min(2, 'Please fill out all card numbers'),
  signature: Yup.string().required('Please add your signature'),
})

export function ParkingAccessCard({ property_id, user_id, pac_id, count, approval_id }: any) {
  const [message, setMessage] = useState<any>()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const initialValues = {
    property_id: property_id,
    // user_id: user_id,
    allotted_access_id: pac_id,
    card_number: [''],
    signature: '',
    approval_id: approval_id,
    flag: 2
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: saveAccessCardRequest,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        saveAccessCard(
          values.property_id,
          // values.user_id,
          values.allotted_access_id,
          values.card_number,
          values.signature,
          values.flag,
          values.approval_id
        )
          .then((data) => {
            setLoading(false)
            if (data.errorCode === 0) {
              clearForm()
              successRef.current.click()
            } else {
              setMessage(data.errorDescription)
              failRef.current.click()
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Invalid Data Provided')
          })
      }, 1000)
    },
  })

  const sigCanvas = useRef<any>()

  function clearSignature() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }

  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  const clearForm = () => {
    formik.resetForm()
    formik.values.card_number?.map((card: any, index: any) =>
      formik.setFieldValue(`card_number[${index}]`, '')
    )
    formik.setFieldValue('card_number', [])
    clearSignature()
  }

  const inputs = new Array(count).fill(0).map((item, index) => (
    <div key={index} className='col-md-4 col-12'>
      <div className='mb-7'>
        <label className='form-label fw-bolder'> Card Number {index + 1} </label>
        <input
          type='text'
          className='form-control fw-bolder form-control-solid'
          name={`card_number[${index}]`}
          onChange={formik.handleChange}
          value={formik.values.card_number?.[index]}
          autoComplete='off'
          maxLength={25}
          required
        />
      </div>
    </div>
  ))

  const [counts, setCounts] = useState(1)
  const [number, setNumber] = useState(0);
  const setNumberOfInput = (counts: any) => {
    setNumber(counts);
  };
  const fields = [];
  for (let i = 1; i <= number; i++) {
    fields.push(" " + (i + 2));
  }

  const handleAdd = () => {
    setCounts(counts + 1)
    setNumberOfInput(counts)
  }

  const handleRemove = () => {
    setCounts(counts - 1)
    setNumberOfInput(counts - 1)
  }

  return (
    <div>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='accordion-item mb-7'>
              <h2 className='accordion-header' id='headingThree'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  Parking Access Card
                </button>
              </h2>
              <div
                id='collapseThree'
                className='accordion-collapse collapse'
                aria-labelledby='headingThree'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='accessCardRequestForm'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='row form-add-design'>
                      {formik.touched.card_number && formik.errors.card_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.card_number}
                            </span>
                          </div>
                        </div>
                      )}
                      {count ? inputs : ''}

                      {fields.map((str) => {
                        return (
                          <>
                            <div className="col-md-4 col-12 label_close">
                              <div className="mb-10">
                                <label className="form-label fw-bolder">Card Number {str}</label>
                                <div className="d-flex fdrr-rtl">
                                  <input type="text" name="" className="form-control expected-rent-input" value="" />
                                  <button type='submit' className='bg-white cancel-bts px-7'
                                    onClick={handleRemove}
                                  >
                                    <i className="bi bi-x-circle font-18 text-black"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}

                      {/* <div className="col-sm-6 col-md-4 col-12">
                        <div className="mb-10">
                          <button className='btn btn-primary fw-bold px-10 py-3 mt-8'
                            onClick={handleAdd}
                          >
                            <span className='indicator-label'>Add More</span>
                          </button>
                        </div>
                      </div> */}

                      <div className='col-md-12 col-12'>
                        <div className='row'>
                          <div className='col-md-4 col-12'>
                            <div className='mb-7'>
                              <label className='form-label fw-bolder'> Customer Signature </label>
                              <div className='w-100 form-control'>
                                <SignatureCanvas
                                  ref={sigCanvas}
                                  penColor='black'
                                  canvasProps={{ height: 80, width: 320, className: 'sigCanvas' }}
                                  onEnd={handleSignature}
                                />
                              </div>
                              {formik.touched.signature && formik.errors.signature && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.signature}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-4 my-auto col-12'>
                            <div className='mt-3'>
                              <button
                                type='button'
                                className='btn btn-light fw-bold px-10 py-2'
                                onClick={clearSignature}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 text-end'>
                        <button className='btn btn-outline-primary fw-bold px-10 py-3 me-5'>
                          Cancel
                        </button>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                          {!loading && <span className='indicator-label'>Save</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                        {/* <button
                        // data-bs-target='#req-submitted-popup'
                        data-bs-target='#added-success-modal'
                        data-bs-toggle='modal'
                        data-bs-dismiss='modal'
                        type='submit'
                        className='btn btn-primary fw-bold px-10 py-3'
                      >
                        Save
                      </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        ref={successRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <RequestFailedPopup message={message} />
    </div>
  )
}
