import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function EditRole() {
    
    return (      
        <>
           <div className="row align-items-center mb-5 community">
                <div className="col-md-5">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>Edit Role
                    </h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form method="POST" name="edit_agency">
                        <div className="row">
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Role Name *</label>
                                    <input type="text" placeholder="Role Name" className="form-control font-13 form-height" name="role" value="Admin" required/>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13"> Status </label>
                                    <select className="form-control font-13 form-height selectpicker" name="status">
                                        <option value="">Select</option>
                                        <option value="0">Inactive</option>
                                        <option value="1" selected>Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-7">
                            <div className="col-12">
                                <h5 className="theme-color font-22 mb-4">Assign Menus</h5>
                            </div>
                            <div className="col-12 role-menus">
                                <div className="row">
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Dashboard</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_1" name="chkrole[0][]" value="1" />
                                                    <label className="form-check-label" htmlFor="chkrole_1">Dashboard</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Properties</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_32" name="chkrole[6][]" value="32"/>
                                                    <label className="form-check-label" htmlFor="chkrole_32">Communities</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_55" name="chkrole[6][]" value="55"/>
                                                    <label className="form-check-label" htmlFor="chkrole_55">Buildings</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_56" name="chkrole[6][]" value="56"/>
                                                    <label className="form-check-label" htmlFor="chkrole_56">Units</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_34" name="chkrole[6][]" value="34"/>
                                                    <label className="form-check-label" htmlFor="chkrole_34">Accounts</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_54" name="chkrole[6][]" value="54"/>
                                                    <label className="form-check-label" htmlFor="chkrole_54">Community</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Contacts</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_36" name="chkrole[7][]" value="36"/>
                                                    <label className="form-check-label" htmlFor="chkrole_36">Leads</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_37" name="chkrole[7][]" value="37"/>
                                                    <label className="form-check-label" htmlFor="chkrole_37">Contacts</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_39" name="chkrole[7][]" value="39"/>
                                                    <label className="form-check-label" htmlFor="chkrole_39">Opportunities</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_38" name="chkrole[7][]" value="38"/>
                                                    <label className="form-check-label" htmlFor="chkrole_38">Tasks</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_79" name="chkrole[7][]" value="79"/>
                                                    <label className="form-check-label" htmlFor="chkrole_79">Assign Lead</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>My Calender</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_27" name="chkrole[0][]" value="27"/>
                                                    <label className="form-check-label" htmlFor="chkrole_27">My Calender</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Documents</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_28" name="chkrole[0][]" value="28"/>
                                                    <label className="form-check-label" htmlFor="chkrole_28">Documents</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Reports</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_61" name="chkrole[29][]" value="61"/>
                                                    <label className="form-check-label" htmlFor="chkrole_61">Properties</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_62" name="chkrole[29][]" value="62"/>
                                                    <label className="form-check-label" htmlFor="chkrole_62">Payment History</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_63" name="chkrole[29][]" value="63"/>
                                                    <label className="form-check-label" htmlFor="chkrole_63">Agents</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Communication</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_58" name="chkrole[30][]" value="58"/>
                                                    <label className="form-check-label" htmlFor="chkrole_58">Email Template</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_50" name="chkrole[30][]" value="50"/>
                                                    <label className="form-check-label" htmlFor="chkrole_50">Messages</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Brokers</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_84" name="chkrole[73][]" value="84"/>
                                                    <label className="form-check-label" htmlFor="chkrole_84">Broker List</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_85" name="chkrole[73][]" value="85"/>
                                                    <label className="form-check-label" htmlFor="chkrole_85">Approval List</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Health Matrics</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_74" name="chkrole[0][]" value="74"/>
                                                    <label className="form-check-label" htmlFor="chkrole_74">Health Matrics</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Users</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_75" name="chkrole[76][]" value="75"/>
                                                    <label className="form-check-label" htmlFor="chkrole_75">User Mapping</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_77" name="chkrole[76][]" value="77"/>
                                                    <label className="form-check-label" htmlFor="chkrole_77">User List</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Lead Rule</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_78" name="chkrole[0][]" value="78"/>
                                                    <label className="form-check-label" htmlFor="chkrole_78">Lead Rule</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Settings</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_18" name="chkrole[17][]" value="18"/>
                                                    <label className="form-check-label" htmlFor="chkrole_18">Admin Users</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_19" name="chkrole[17][]" value="19"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_19">User Roles</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_20" name="chkrole[17][]" value="20"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_20">Menus</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_52" name="chkrole[17][]" value="52"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_52">Settings</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_53" name="chkrole[17][]" value="53"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_53">Portal</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_59" name="chkrole[17][]" value="59"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_59">Lead Rules</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_72" name="chkrole[17][]" value="72"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_72">Location Mapping</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Documents</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_80" name="chkrole[81][]" value="80"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_80">Verification</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_82" name="chkrole[81][]" value="82"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_82">Uploads</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Performance Monitoring</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_83" name="chkrole[0][]" value="83"/>
                                                    <label className="form-check-label" htmlFor="chkrole_83">Performance Monitoring</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                    <fieldset className="mb-4">
                                        <legend>Marketing</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_64" name="chkrole[31][]" value="64"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_64">Overview</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_65" name="chkrole[31][]" value="65"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_65">Ads</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_67" name="chkrole[31][]" value="67"/> 
                                                    <label className="form-check-label" htmlFor="chkrole_67">Social Media</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_69" name="chkrole[31][]" value="69"/>
                                                    <label className="form-check-label" htmlFor="chkrole_69">Chat</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-check mb-4">
                                                    <input type="checkbox" className="form-check-input" id="chkrole_70" name="chkrole[31][]" value="70"/>
                                                    <label className="form-check-label" htmlFor="chkrole_70">Settings</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-12 text-end">
                                    <Link to className="btn btn-outline-primary me-3">Cancel</Link>
                                    <button type="button" value="" name="submit" className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>  
    )
}
