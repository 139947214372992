import React, { useState, useEffect } from 'react'

export function ActivityLogs() {
    return (
        <>
            <div id="activity-log" className="activity-log">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card border-0 mb-3 radius-15 p-4">
                            <div className="card-body px-3 pt-3 pb-0 dash-com-scroll">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="">
                                            <div className="activity-feed form-group">
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            13/07/2023 05:54                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> RadhaKrishna -  Lead Updated </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            13/07/2023 05:52                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> RadhaKrishna -  Qualify </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            10/07/2023 04:43                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14">  -  Lead Updated </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            30/03/2023 08:39                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14">  -  Qualify </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            16/01/2023 07:44                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Samsmith -  Qualify </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            03/06/2022 04:14                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14">  -  Reminder Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            29/07/2021 00:56                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> SanjeevB -  Qualify </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            29/07/2021 00:55                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> SanjeevB -  Lead Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            27/07/2021 00:44                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14">  -  Lead Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            23/07/2021 05:31                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Samsmith -  Reminder Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            23/07/2021 05:26                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Samsmith -  Notes Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            23/07/2021 05:19                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Samsmith -  Notes Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            23/07/2021 01:59                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Samsmith -  Lead Updated </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            23/07/2021 00:56                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Samsmith -  Lead Updated </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            23/07/2021 00:55                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Samsmith -  Lead Updated </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            19/07/2021 01:16                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> UserDemo -  Offer Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            19/07/2021 01:15                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> UserDemo -  Lead Updated </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            19/07/2021 01:14                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> UserDemo -  Qualify </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            19/07/2021 01:14                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> UserDemo -  Lead Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            22/04/2021 02:01                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Vignesh -  offer Rejected </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            22/04/2021 02:00                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Vignesh -  offer Rejected </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            22/04/2021 01:59                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Vignesh -  offer Accepted </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            22/04/2021 01:51                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> Vignesh -  Offer Created </span>
                                                    </div>
                                                </div>
                                                <div className="feed-item p-3">
                                                    <div className="date">
                                                        <span className="text-has-action font-weight-normal font-12">
                                                            02/04/2021 00:57                                                                                </span>
                                                    </div>
                                                    <div className="text">
                                                        <span className="font-12 font-weight-semibold font-14"> shivak -  Qualify </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

