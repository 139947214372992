import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { RootState } from '../../../../../constants';

export function TerminationCharges() {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  let refundDetails: any
  refundDetails = useSelector<RootState>(({ PM }) => PM.refundData)
  return (
    <>
      {userEmail === 'pm@rc.test' ? (
        <div className=''>
          <div className='row'>
            <div className='col-sm-6 col-md-7 col-12'>
              <h4 className='card-title font-17 text-black fw-boldest mt-2'>Termination Charges</h4>
            </div>
            <div className='col-sm-6 col-md-5 text-end mb-8'>
              <button
                className='btn btn-primary btn-sm font-16'
                data-bs-toggle='modal'
                data-bs-target='#add-charges'
              >
                <i className='bi bi-plus-circle-fill align-middle'></i> Add
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='term-charges'>
                <div className='row'>
                  <div className='col-md-5 col-lg-5 col-4'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>Clearance Charges</h6>
                  </div>
                  <div className='col-md-4 col-lg-4 col-4'>
                    <button
                      className='view-option text-theme bg-white fw-bolder border-0 text-decoration-underline'
                      data-bs-toggle='modal'
                      data-bs-target='#apply-for-waiver-popup'
                    >
                      Apply for waiver
                    </button>
                  </div>
                  <div className='col-md-3 col-lg-3 col-4 text-end'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 100</h6>
                  </div>
                  <div className='col-md-5 col-lg-5 col-4'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>Lease Over Stay</h6>
                  </div>
                  <div className='col-md-4 col-lg-4 col-4'>
                    <button
                      className='view-option text-theme bg-white fw-bolder border-0 text-decoration-underline'
                      data-bs-toggle='modal'
                      data-bs-target='#apply-for-waiver-popup'
                    >
                      Apply for waiver
                    </button>
                  </div>
                  <div className='col-md-3 col-lg-3 col-4 text-end'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 100</h6>
                  </div>
                  <div className='col-md-5 col-lg-5 col-4'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>Administrative Fee</h6>
                  </div>
                  <div className='col-md-4 col-lg-4 col-4'>
                    <button
                      className='view-option text-theme bg-white fw-bolder border-0 text-decoration-underline'
                      data-bs-toggle='modal'
                      data-bs-target='#apply-for-waiver-popup'
                    >
                      Apply for waiver
                    </button>
                  </div>
                  <div className='col-md-3 col-lg-3 col-4 text-end'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 150</h6>
                  </div>
                  <div className='col-md-5 col-lg-5 col-4'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>VAT</h6>
                  </div>
                  <div className='col-md-4 col-lg-4 col-4'>
                    <button
                      className='view-option text-theme bg-white fw-bolder border-0 text-decoration-underline'
                      data-bs-toggle='modal'
                      data-bs-target='#apply-for-waiver-popup'
                    >
                      Apply for waiver
                    </button>
                  </div>
                  <div className='col-md-3 col-lg-3 col-4 text-end'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 7.5</h6>
                  </div>
                  <div className='col-12'>
                    <div className='bg-light-gray rounded-10 py-5 mb-8'>
                      <div className='row'>
                        <div className='col-md-9 col-lg-9 col-6'>
                          <h6 className='text-theme font-14 fw-bold m-0 ps-5'>Total</h6>
                        </div>
                        <div className='col-md-3 col-lg-3 col-6 text-end'>
                          <h6 className='text-theme font-14 fw-boldest m-0'>AED 257.5</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-9 col-lg-9 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>
                      Available Security Desposit
                    </h6>
                  </div>
                  <div className='col-md-3 col-lg-3 col-6 text-end'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 4000</h6>
                  </div>
                  <div className='col-12'>
                    <div className='bg-light-gray rounded-10 py-5 mb-8'>
                      <div className='row'>
                        <div className='col-md-9 col-lg-9 col-6'>
                          <h6 className='text-theme font-14 fw-bold m-0 ps-5'>Refund</h6>
                        </div>
                        <div className='col-md-3 col-lg-3 col-6 text-end'>
                          <h6 className='text-theme font-14 fw-boldest m-0'>AED 4150</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 text-end'>
                    <Link to={'/'} className='btn btn-outline-primary fw-bold px-6 py-3 me-5'>
                      Refund to Others
                    </Link>

                    <Link to={'/'} className='btn btn-primary fw-bold px-6 py-3'>
                      Refund to Tenant
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=''>
          <div className='row'>
            <div className='col-sm-6 col-md-7 col-12'>
              <h4 className='card-title font-17 text-black fw-boldest mt-2 mb-3 mb-sm-0'>Termination Charges</h4>
            </div>
            <div className='col-sm-6 col-md-5 text-end mb-8'>
              <button
                className='btn btn-primary btn-sm font-16'
                data-bs-toggle='modal'
                data-bs-target='#add-charges'
              >
                <i className='bi bi-plus-circle-fill align-middle'></i> Add
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='term-charges'>
                <div className='row'>
                  <div className='col-md-9 col-lg-10 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>Clearance Charges</h6>
                  </div>
                  <div className='col-md-3 col-lg-2 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 100</h6>
                  </div>
                  <div className='col-md-9 col-lg-10 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>Lease Over Stay</h6>
                  </div>
                  <div className='col-md-3 col-lg-2 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 100</h6>
                  </div>
                  <div className='col-md-9 col-lg-10 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>Administrative Fee</h6>
                  </div>
                  <div className='col-md-3 col-lg-2 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 150</h6>
                  </div>
                  <div className='col-md-9 col-lg-10 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>VAT</h6>
                  </div>
                  <div className='col-md-3 col-lg-2 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 7.5</h6>
                  </div>
                  <div className='col-12'>
                    <div className='bg-light-gray rounded-10 py-5 mb-8'>
                      <div className='row'>
                        <div className='col-md-9 col-lg-10 col-6'>
                          <h6 className='text-theme font-14 fw-bold m-0 ps-5'>Total</h6>
                        </div>
                        <div className='col-md-3 col-lg-2 col-6'>
                          <h6 className='text-theme font-14 fw-boldest m-0'>AED 257.5</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-9 col-lg-10 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8 ps-5'>
                      Available Security Desposit
                    </h6>
                  </div>
                  <div className='col-md-3 col-lg-2 col-6'>
                    <h6 className='text-black font-14 fw-bold mb-8'>AED 4000</h6>
                  </div>
                  <div className='col-12'>
                    <div className='bg-light-gray rounded-10 py-5 mb-8'>
                      <div className='row'>
                        <div className='col-md-9 col-lg-10 col-6'>
                          <h6 className='text-theme font-14 fw-bold m-0 ps-5'>Refund</h6>
                        </div>
                        <div className='col-md-3 col-lg-2 col-6'>
                          <h6 className='text-theme font-14 fw-boldest m-0'>AED 4150</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 text-end'>
                    <Link
                      to={'/approvals/moveout/details'}
                      className='btn btn-primary fw-bold px-6 py-3'
                    >
                      View Move-out Approval
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
