import React, {FC, useEffect, useRef, useState} from 'react'
import {CalenderCard} from '../CalenderCard/CalenderCard'
import {CalenderDetails} from '../CalenderDetails/CalenderDetails'
import {Link} from 'react-router-dom'

import {EventInput} from '@fullcalendar/react'
import {RootState} from '../../../../constants'
import {useSelector} from 'react-redux'
import {getFcssScheduledVisits, getFcssApprovals} from '../../../../utils/makeRequest'
import {IState} from '../../../../utils/interface'
import Loader from '../../../../components/Loader'
import {allScheduleList, getApprovals, getFacilityVisitors, scheduleVisitList} from '../../../../constants/axios/apis'
import {
  formatDate,
  formatDateTimeMeridiem,
  formatTimeMeridiem,
} from '../../../../constants/generics/dateTimeFormater'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function CalenderAll() {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const { t } = useTranslation();
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const id_role = state?.auth?.user?.data?.id_role

  const [loading, setLoading] = useState<any>({
    visitor:true,
    movein:true,
    moveout:true
  })
  const [moveInList, setMoveInList] = useState<any>([])
  const [moveOutList, setMoveOutList] = useState<any>([])
  const [visitorList, setVisitiorsList] = useState<any>([])
  const [parentSelectedCategory, setParentSelectedCategory] = useState<any>('visit')
  const [counts, setCounts] = useState<any>({visit: 0, moveIn: 0, moveOut: 0})

  if (userToken === 'undefined') {
    // token undefined
  }

  // const [scheduledVistList, setScheduledVistList] = useState<IState['APIState']>({
  //   loading: false,
  //   response: [],
  //   error: '',
  // })

  const [selectedDate, setSelectedDate] = useState<any>(new Date())

  useEffect(() => {
    setLoading({
      visitor:true,
      movein:true,
      moveout:true
    })
    // const newPayload = currentBuilding
    const newPayload = currentBuilding ? '?building_id=' + currentBuilding : '?building_id=0' 
    scheduleVisitList(newPayload).then((response: any) => {
      if (response.errorCode === 0 && response.data) {
        setVisitiorsList(response.data)
      } else {
        setVisitiorsList([])
      }
      setLoading((oState:any)=>({...oState, visitor: false}))
    }).catch((e)=>setLoading((oState:any)=>({...oState, visitor: false})))
    getApprovals(
      '?sub_type=6' + (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    ).then((response: any) => {
      if (response.errorCode === 0 && response.data) {
        setMoveOutList(response.data)
      } else {
        setMoveOutList([])
      }
      setLoading((oState:any)=>({...oState, moveout: false}))
    }).catch((e)=>setLoading((oState:any)=>({...oState, moveout: false})))
    getApprovals(
      '?sub_type=5' + (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    ).then((response: any) => {
      if (response?.code === 200 && response.errorCode != 1) {
        setMoveInList(response.data)
      } else {
        setMoveInList([])
      }
      setLoading((oState:any)=>({...oState, movein: false}))
    }).catch((e)=>setLoading((oState:any)=>({...oState, movein: false})))
  }, [currentBuilding])

  const [eventState, setEventState] = useState<EventInput[]>([])

  const sameDateCheck = (date1: any, date2: any) => {
    var d1 = new Date(date1)
    var d2 = new Date(date2)

    d1.setHours(0, 0, 0, 0)
    d2.setHours(0, 0, 0, 0)

    return d1.getTime() === d2.getTime()
  }

  const [scheduledVists, setscheduledVists] = useState<any>([])

  useEffect(() => {
    let indexCount = 1
    let eventList = new Array()
    let eventList2 = new Array()
    let eventList3 = new Array()
    let filteredScheduledVistList = new Array()
    let filteredMoveInList = new Array()
    let filteredMoveOutList = new Array()
    if (Array.isArray(visitorList) && visitorList.length > 0) {
      eventList = visitorList.map((event: any, index: number) => {
        return {
          id: String(indexCount++),
          title: event.propertyName,
          date: event.visit_date,
        }
      })
      filteredScheduledVistList = visitorList.filter((event: any, index: number) =>
        sameDateCheck(event.visit_date, selectedDate)
      )
    }
    if (Array.isArray(moveInList) && moveInList.length > 0) {
      eventList2 = moveInList.map((event: any, index: number) => {
        return {
          id: String(indexCount++),
          title: event.building,
          date: event.created_at,
        }
      })

      filteredMoveInList = moveInList?.filter((event: any, index: number) =>
        sameDateCheck(event.created_at, selectedDate)
      )
    }
    if (Array.isArray(moveOutList) && moveOutList.length > 0) {
      eventList3 = moveOutList.map((event: any, index: number) => {
        return {
          id: String(indexCount++),
          title: event.building,
          date: event.created_at,
        }
      })

      filteredMoveOutList = moveOutList?.filter((event: any, index: number) =>
        sameDateCheck(event.created_at, selectedDate)
      )
    }

    setEventState([...eventList, ...eventList2, ...eventList3])

    setCounts({
      visit: filteredScheduledVistList.length,
      moveIn: filteredMoveInList.length,
      moveOut: filteredMoveOutList.length,
    })

    // setCounts({
    //   visit: filteredScheduledVistList && Array.isArray(filteredScheduledVistList) ? filteredScheduledVistList.length : 0,
    //   moveIn: filteredMoveInList && Array.isArray(filteredMoveInList) ? filteredMoveInList.length : 0,
    //   moveOut: filteredMoveOutList && Array.isArray(filteredMoveOutList) ? filteredMoveOutList.length : 0,
    // })

    let todaysEventList = new Array()

    if (parentSelectedCategory === 'visit') {
      todaysEventList = filteredScheduledVistList?.map((event: any) => {
        return {
          title: event?.propertyName,
          subTitle: event?.propertyCode,
          date: moment(event?.visit_date).format('DD-MM-YYYY'),
          time: formatTimeMeridiem(event.Time),
          status:
            event?.eventStatus === 3
              ? (t('Ongoing'))
              : event?.eventStatus === 2
              ? (t('Visited'))
              : event?.eventStatus === 1
              ? (t('Scheduled'))
              : event?.eventStatus === 5
              ? (t('Expired'))
              : event?.eventStatus === 4
              ? (t('Re-Scheduled'))
              : event?.eventStatus,
          user: event?.visitor,
          code: event?.emiratesID,
          stateData: event,
        }
      })
    } else if (parentSelectedCategory === 'movein') {
      todaysEventList = filteredMoveInList?.map((event: any) => {
        return {
          title: event?.building,
          subTitle: '',
          date: formatDate(event?.created_at),
          // time: formatTimeMeridiem(event.created_at),
          status: event?.status,
          user: event?.cutomerName,
          code: event?.propertyCode,
          stateData: event,
        }
      })
    } else if (parentSelectedCategory === 'moveout') {
      todaysEventList = filteredMoveOutList.map((event: any) => {
        return {
          title: event?.building,
          subTitle: '',
          date: formatDate(event?.created_at),
          // time: formatTimeMeridiem(event.created_at),
          status: event?.status,
          user: event?.cutomerName,
          code: event?.propertyCode,
          stateData: event,
        }
      })
    }
    setscheduledVists(todaysEventList)

    // }
  }, [visitorList, moveInList, moveOutList, selectedDate, parentSelectedCategory])

  return (
    <div className='com-card-section'>
      <div className='row flex-stack announcement-sec mb-6'>
        <div className='col-md-6'>
          <h4 className='card-title font-18 su-ca-add-attence text-black'> {t("Calendar")}</h4>
        </div>
        {id_role !== 8 ||
          (id_role !== 9 && (
            <div className='col-md-6 text-end'>
              <Link
                to={'/fc-su-calendar/attendance/total'}
                className='btn btn-primary btn-sm su-ca-add-attence font-16'
              >
                View Attendance
              </Link>
            </div>
          ))}
      </div>
      <div className='row'>
        <div className='col-md-7'>
          <CalenderCard eventState={eventState} setSelectedDate={setSelectedDate} />
        </div>
        <div className='col-md-5'>
          <CalenderDetails
            scheduledVists={scheduledVists}
            loading={(loading.visitor || loading.movein || loading.moveout)}
            selectedDate={selectedDate}
            counts={counts}
            setParentSelectedCategory={setParentSelectedCategory}
          />
        </div>
      </div>
    </div>
  )
}
