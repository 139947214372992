import React from 'react';
import CheckGreen from '../../../../../../../../assets/images/Check_green_lg.svg';

export function StepSuccessful(props: any) {

    return (
        <div className="row pb-20 pt-15 step-one">
            <div className="col-md-12 custom-modal">
                <div className="text-center mx-md-12">
                    <div className="redeem-offer-QR">
                        <img src={CheckGreen} alt="tick-img" className="img-fluid mb-5" />
                        <h1 className="success-active">Successful</h1>
                        <p className="mb-4 font-12 fw-normal text-short mx-20 px-15 ">
                            RealCube Team Will get back to you shortly.
                        </p>
                        <button className="btn btn-primary btn-lg fw-normal font-15 px-20 py-3 w-50" data-bs-dismiss="modal" aria-label="Close">
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}