import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { currencyFormater, CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios'
import { getPaymentList } from '../../../../constants/axios/apis'
import { RootState } from '../../../../constants'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination'

export function FinancePaymentTable({ payload, searchItem }: any) {
    const { t } = useTranslation()

    const pageSize = 10
    const [loading, setLoading] = useState<any>(true)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [totalCount, setTotalCount] = useState<any>(0)
    const [rowList, setRowList] = useState<any>([])
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)

    useEffect(() => {
        setCurrentPage(1)
    }, [searchItem, currentBuilding])

    useEffect(() => {
        setLoading(true)
        if (payload !== '') {
            let page = currentPage
            let newPayload =
                'currentPage=' +
                page +
                '&pageSize=' +
                pageSize +
                '&search=' +
                searchItem
            newPayload = currentBuilding
                ? newPayload + '&building_id=' + currentBuilding
                : newPayload + '&building_id=0'
            getPaymentList(payload + newPayload).then((response: any) => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setRowList(response.data)
                    setTotalCount(response.dataCount.dataCount)
                } else {
                    setRowList([])
                    setTotalCount(0)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            })
        }
    }, [payload, currentPage, currentBuilding, searchItem])

    if (!loading && (rowList === undefined || rowList?.length === 0)) {
        return <div className='col-12 pt-5'><NoDataFound1 /></div>
    }

    const rowData = rowList?.map((data: any, index: any) => (
        <tr key={data.id}>
            <td>{(currentPage - 1) * pageSize + index + 1}</td>
            <td>{data?.tenantName ? data.tenantName : '-'}</td>
            <td>{data?.leaseNumber ? data.leaseNumber : '-'}</td>
            <td>{data?.propertyCode ? data.propertyCode : '-'}</td>
            <td>{data?.title ? data.title : '-'}</td>
            <td>
                {t('AED')} {currencyFormater(data?.amount)}
            </td>
            <td className={
                data.statusText === 'Cleared'
                    ? 'text-success' : 'text-warning'
            }>{data?.statusText ? data.statusText : '-'}</td>
            <td>
                <Link
                    to={{
                        pathname: '/finance-payments/view',
                        state: { paymentDetails: data },
                    }}
                    className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
                >
                    <span className='view-option text-theme fw-bolder'>{t('View')}</span>
                </Link>
            </td>
        </tr>
    ))

    return (
        <div className='col-12 pt-5'>
            <div className='rec-payment-act-sec'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='table-responsive'>
                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                <thead>
                                    <tr>
                                        <th className=''> {t('No')} </th>
                                        <th className=''> {t('Tenant Name')} </th>
                                        <th className=''> {t('Lease Number')} </th>
                                        <th className=''> {t('Unit')} </th>
                                        <th className=''> {t('Payment Type')} </th>
                                        <th className=''> {t('Amount')} </th>
                                        <th className=''> {t('Status')} </th>
                                        <th className=''> {t('Action')} </th>
                                    </tr>
                                </thead>
                                {loading ? (
                                    <tbody>
                                        <tr>
                                            <td colSpan={8} className='text-center pt-10 pb-0'>
                                                <CustomLoader2 />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>{rowList ? rowData : ''}</tbody>
                                )}
                            </table>
                        </div>
                        <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalCount)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}
