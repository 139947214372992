import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../../constants'
import * as profile from '../../constants/redux/Sagas/ProfileSaga'
import { useTranslation } from 'react-i18next'

const ProfileDetails = (props: any) => {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  let profileData: any = []
  profileData = useSelector<RootState>(({ Profile }) => Profile.profile)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(profile.actions.profile())
  }, [])
  const { t } = useTranslation();

  return (
    <>
    
      <div className='row profile-new-details'>
         {/* <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("First Name")}</label>
            <span className=''>{profileData?.data?.first_name}</span>
          </div>
        </div>
         <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Last Name")}</label>
            <span className=''>{profileData?.data?.last_name}</span>
          </div>
        </div> */}
        <div className='col-12'><h3 className="general-title">General Details</h3></div>
         <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Emirates Id")}</label>
            <span className=''>{profileData?.data?.emirates_id}</span>
          </div>
        </div>
         {/* <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Mobile Number")}</label>
            <span className=''>{profileData?.data?.phone}</span>
          </div>
        </div> */}
         {/* <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Email Address")}</label>
            <span className=''>{userEmail}</span>
          </div>
        </div> */}

         <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Alternate Contact No.")}</label>
            <span className=''>
              {profileData?.data?.alternate_no ? profileData?.data?.alternate_no : "-"}
            </span>
          </div>
        </div>
         <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Preferred Language")}</label>
            <span className=''>{profileData?.data?.preferred_language === 1 ? 'English' : 'العربية'}</span>
          </div>
        </div>
        <div className='col-12 mt-5'><h3 className="general-title">Emergency Details</h3></div>
         <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Emergency Contact Name")}</label>
            <span className=''>
              {profileData?.data?.emergency_contact_name ? profileData.data.emergency_contact_name :'-'}
            </span>
          </div>
        </div>
         <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Emergency Contact Number")}</label>
            <span className=''>{profileData?.data?.emergency_contact_no ?profileData?.data?.emergency_contact_no :"-"}</span>
          </div>
        </div>
        <div className='col-12 mt-5'><h3 className="general-title">Subscription Details</h3></div>
         <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
            <label className=''>{t("Subscription for Offers")}</label>
            {/* <span className=''>{t("SMS")}</span>, */}
            <span className='text-black font-15 fw-bold ms-2'>{profileData?.data?.source ? profileData?.data?.source :'-' }</span>
          </div>
        </div>
        {/* <div className='col-lg-4 col-12'>
          <div className='border rounded-5 p-4 h-100'>
            <label className=''>Present Address</label>
            <span className=''>
              {profileData?.data?.emergency_contact_name}
            </span>
          </div>
        </div>
        <div className='col-lg-4 col-12'>
          <div className='border rounded-5 p-4 h-100'>
            <label className=''>Permanent Address</label>
            <span className=''>Po Box 30118, Sharjah, 50871 - UAE</span>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-12">
          {props.successMsg !== '' && (
            <div className='alert alert-success w-100 mt-2 text-center' role='alert'>
              {props.successMsg}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { ProfileDetails }
