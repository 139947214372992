import React, { useState, useEffect } from 'react'

export function Offers() {
    return (
        <>
       
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row no-gutters border-bottom py-3">
                                <div className="col-sm-9">
                                    <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                    <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                        REF-OF-1689599669                                                                                                        </h6>
                                </div>
                                <div className="col-sm-3 pl-0 text-end">
                                    <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-secondary">Open</h6>
                                </div>
                            </div>
                            <div className='my-2'></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="">
                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">Property Ref</span></h6>
                                <p className="font-12 ellipsis">REF-UN-1689310884</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="">
                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">Property</span></h6>
                                <p className="font-12 mb-2 ellipsis">hhh</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="">
                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                <p className="font-12 ellipsis">AED 250,000</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="">
                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                <p className="font-12">---</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="">
                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                <p className="font-12">17/07/2023</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="">
                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                <p className="font-12">27/07/2023</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-end">
                            <a href="" className="text-decoration-none">
                                <button type="button" className="btn btn-primary btn-sm font-12 py-1"> View More </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
 
        </>
    )
}

