import React, { useState, useEffect, useRef } from 'react';
import PlusIcon from "../../../../../../../../assets/images/plus-circle.svg";
import { Alert, Spinner } from 'react-bootstrap-v5'
import * as PTOffer from '../../../../../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { RootState } from '../../../../../../../../constants';
import { useSelector } from 'react-redux';

export function StepThree(props: any) {
    const { uploadDocuments } = props
    let offerList: any
    offerList = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [show, setShow] = useState(false)
    const [error, setError] = useState('')
    const [comments, setComments] = useState('')
    const [variants, setVriants] = useState('')

    const inputRefEmiratesIdFront = useRef<any>(0)
    const [emiratesIdFront, setEmiratesIdFront] = useState<any>()
    const inputRefEmiratesIdBack = useRef<any>(0)
    const [emiratesIdBack, setEmiratesIdBack] = useState<any>()
    const inputRefPassport = useRef<any>(0)
    const [passport, setPassport] = useState<any>()
    const inputRefVisa = useRef<any>(0)
    const [visa, setVisa] = useState<any>()
    const inputRefLetter = useRef<any>(0)
    const [letter, setLetter] = useState<any>()
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleUploadEmiratesIdFront = () => {
        inputRefEmiratesIdFront.current?.click()
    }
    const handleDisplayFileDetailsEmiratesIdFront = () => {
        const saveFile = checkFileDetails(inputRefEmiratesIdFront)
        setEmiratesIdFront(saveFile)
    }
    //*
    const handleUploadEmiratesIdBack = () => {
        inputRefEmiratesIdBack.current?.click()
    }
    const handleDisplayFileDetailsEmiratesIdBack = () => {
        const saveFile = checkFileDetails(inputRefEmiratesIdBack)
        setEmiratesIdBack(saveFile)
    }
    //*
    const handleUploadPassport = () => {
        inputRefPassport.current?.click()
    }
    const handleDisplayFileDetailsPassport = () => {
        const saveFile = checkFileDetails(inputRefPassport)
        setPassport(saveFile)
    }
    //*
    const handleUploadVisa = () => {
        inputRefVisa.current?.click()
    }
    const handleDisplayFileDetailsVisa = () => {
        const saveFile = checkFileDetails(inputRefVisa)
        setVisa(saveFile)
    }
    //*
    const handleUploadLetter = () => {
        inputRefLetter.current?.click()
    }
    const handleDisplayFileDetailsLetter = () => {
        const saveFile = checkFileDetails(inputRefLetter)
        setLetter(saveFile)
    }
    const checkFileDetails = (checkFile: any) => {
        let fileSize: any = checkFile?.current?.files[0]?.size <= 2101546
        let isAllowedType: any =
            checkFile?.current?.files[0]?.type === 'image/png' ||
            checkFile?.current?.files[0]?.type === 'image/jpeg' ||
            checkFile?.current?.files[0]?.type === 'image/jpg' ||
            checkFile?.current?.files[0]?.type === 'application/pdf'
        if (!isAllowedType) {
            setShow(true)
            setError('File is not a png/jpeg/jpg/PDF  file')
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
        if (!fileSize) {
            setShow(true)
            setError('File size should be less than 2MB')
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
        if (isAllowedType && fileSize) {
            // checkFile.current?.files && setUploadedFileInvoice(checkFile.current.files[0])
            return checkFile.current.files[0]
        }
    }
    uploadDocuments(
        {
            emiratesIdFront: emiratesIdFront?.name ? emiratesIdFront : null,
            emiratesIdBack: emiratesIdBack?.name ? emiratesIdBack : null,
            passport: passport?.name ? passport : null,
            visa: visa?.name ? visa : null,
            letter: letter?.name ? letter : null,
        }
    )
    return (
        <div className="row">
            <div className="col-md-12">
                {/*<div className="col-12">*/}
                {/*<h3 className="upload-title">Documents to Upload</h3>*/}
                {/*</div>*/}
                {show ? (
                    <Alert className='font-14 fw-bold mb-8' variant={variants}>
                        {error}!
                    </Alert>
                ) : null}
                <div className="row em-row py-6">
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> Emirates ID (front ) <span className="text-danger">*</span> </label>
                            <input ref={inputRefEmiratesIdFront} onChange={handleDisplayFileDetailsEmiratesIdFront} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                            <button onClick={handleUploadEmiratesIdFront} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${emiratesIdFront?.name ? "primary active" : "primary"}`}>
                                <span className="text-black"> {emiratesIdFront?.name ? emiratesIdFront?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> Emirates ID ( Back ) <span className="text-danger">*</span> </label>
                            <input ref={inputRefEmiratesIdBack} onChange={handleDisplayFileDetailsEmiratesIdBack} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                            <button onClick={handleUploadEmiratesIdBack} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${emiratesIdBack?.name ? "primary active" : "primary"}`}>
                                <span className="text-black"> {emiratesIdBack?.name ? emiratesIdBack?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> Passport <span className="text-danger">*</span> </label>
                            <input ref={inputRefPassport} onChange={handleDisplayFileDetailsPassport} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                            <button onClick={handleUploadPassport} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${passport?.name ? "primary active" : "primary"}`}>
                                <span className="text-black"> {passport?.name ? passport?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> Visa <span className="text-danger">*</span>  </label>
                            <input ref={inputRefVisa} onChange={handleDisplayFileDetailsVisa} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                            <button onClick={handleUploadVisa} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${visa?.name ? "primary active" : "primary"}`}>
                                <span className="text-black"> {visa?.name ? visa?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    {offerList?.modeOfPayment==="PAY_BY_EMP" ? (
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> Letter of Intent  </label>
                            <input ref={inputRefLetter} onChange={handleDisplayFileDetailsLetter} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                            <button onClick={handleUploadLetter} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${letter?.name ? "primary active" : "primary"}`}>
                                <span className="text-black"> {letter?.name ? letter?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>) : null}
                </div>
            </div>
        </div>
    );
}