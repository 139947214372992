import React, { useState, useEffect, useRef } from 'react';

export function DateDetails() {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="accordion check-date-details" id="accordion-step-five">
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#date-details">
                                    Date Details
                                </button>
                            </h2>
                            <div id="date-details" className="accordion-collapse collapse show" data-bs-parent="#accordion-step-five">
                                <div className="accordion-body pb-5 pt-0">
                                    <div className="row form-add-design">
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> Key Handover Date </label>
                                                <input type="date" className="form-control bg-white fw-bolder form-control-solid" placeholder="" value="2021-06-01"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">Notice Date</label>
                                                <input type="date" className="form-control bg-white fw-bolder form-control-solid" placeholder="" value="2021-06-01"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">ADDC Clearance Date</label>
                                                <input type="date" className="form-control bg-white fw-bolder form-control-solid" placeholder=""  value="2021-06-01"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#vacating-reason">
                                    Vacating Reason
                                </button>
                            </h2>
                            <div id="vacating-reason" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                                <div className="accordion-body pb-5 pt-0">
                                    <div className="row form-add-design">
                                        No Data Found 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#upload-documents">
                                    Upload Documents
                                </button>
                            </h2>
                            <div id="upload-documents" className="accordion-collapse collapse show" data-bs-parent="#accordion-step-five">
                                <div className="accordion-body pb-5 pt-0">
                                    <div className="row form-add-design">
                                        <div className="col-md-6 form-label fw-bolder d-block mb-0">Mandatory Documents to Upload</div>
                                        <div className="col-md-6 form-label fw-bolder d-block mb-0">Other Documents to Upload</div>
                                        <div className="col-md-3 col-12">
                                            <div className="mb-7 upload-input">
                                                <label className="form-label fw-bolder d-block">&nbsp;</label>
                                                <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                    <span>{uploadedFileName ? uploadedFileName : <span>Upload  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <div className="mb-7 upload-input">
                                                <label className="form-label fw-bolder d-block">&nbsp;</label>
                                                <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                    <span>{uploadedFileName ? uploadedFileName : <span>Upload  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <div className="mb-7 upload-input">
                                                <label className="form-label fw-bolder d-block">&nbsp;</label>
                                                <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                    <span>{uploadedFileName ? uploadedFileName : <span>Upload  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <div className="mb-7 upload-input">
                                                <label className="form-label fw-bolder d-block">&nbsp;</label>
                                                <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                    <span>{uploadedFileName ? uploadedFileName : <span>Upload  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}