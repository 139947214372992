import React from 'react';
import { useTranslation } from 'react-i18next'
import MarketPalace1 from '../../../../../assets/images/market_1.svg';
import MarketPalace2 from '../../../../../assets/images/market_2.svg';
import MarketPalace3 from '../../../../../assets/images/market_3.svg';
import MarketPalace4 from '../../../../../assets/images/market_4.svg';
import { Link } from 'react-router-dom'
import { MarketPlaceCreatePopup } from '../../MarketPlaceCreate/MarketPlaceCreatePopup';
import Loader from '../../../../../components/Loader';

const MarketPlaceStats = ({ dataCount }: any) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            {/* {dataCount.loading ?
                                <Loader />
                                : */}
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 col-xl-3 col-lg-4 col-12 mt-3 mt-sm-0">
                                        <div className="market-place-card">
                                            <div className="card">
                                                <div className="card-body py-5 px-5">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-9">
                                                            <h6 className="card-title mb-0">{dataCount.event}</h6>
                                                            <p className="mb-0">{t("Event")}</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="cardimg">
                                                                <img src={MarketPalace1} alt="Work Orders" className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 col-lg-4 col-12 mt-3 mt-sm-0">
                                        <div className="market-place-card">
                                            <div className="card">
                                                <div className="card-body py-5 px-5">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-9">
                                                            <h6 className="card-title mb-0">{dataCount.offer}</h6>
                                                            <p className="mb-0">{t("Offers")}</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="cardimg">
                                                                <img src={MarketPalace2} alt="Work Orders" className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 col-lg-4 col-12 mt-3 mt-md-0">
                                        <div className="market-place-card">
                                            <div className="card">
                                                <div className="card-body py-5 px-5">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-9">
                                                            <h6 className="card-title mb-0">{dataCount.voucher}</h6>
                                                            <p className="mb-0">{t("Vouchers")}</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="cardimg">
                                                                <img src={MarketPalace3} alt="Work Orders" className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-xl-3 col-lg-4 col-12 mt-3 mt-xl-0">
                                        <div className="market-place-card">
                                            <div className="card">
                                                <div className="card-body py-5 px-5">
                                                    <div className="row g-0 align-items-center">
                                                        <div className="col-9">
                                                            <h6 className="card-title mb-0">{dataCount.redeemed}</h6>
                                                            <p className="mb-0">{t("Redeemed")}</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="cardimg">
                                                                <img src={MarketPalace4} alt="Work Orders" className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* } */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export { MarketPlaceStats };