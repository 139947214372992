import React, { useState, useEffect, useRef } from 'react';
import Propertie from '../../../../assets/images/latest-one.png';
import bed from "../../../../assets/images/bed.svg";
import Bath from "../../../../assets/images/bath.svg";
import Place from "../../../../assets/images/place.svg";
import SQR from "../../../../assets/images/sqft.svg";
import Yes from "../../../../assets/images/yes.svg";
import { Spinner } from 'react-bootstrap-v5'
import { RootState } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { makeAnoffer } from '../../../../constants/axios/apis';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

export function MakeOfferPopup() {
    const history = useHistory()
    const dispatch = useDispatch()
    const ref = useRef<any>(0)
    const ref1 = useRef<any>(0)
    let customerDetails: any
    customerDetails = useSelector<RootState>(({ General }) => General.pmMakeAnOfferPopUpData)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<any>()
    const { t } = useTranslation();

    const handleSubmit = () => {
        // var FormData = require('form-data');
        var offerData;
        if (customerDetails?.mode_payment === 'PAY_BY_EMP') {
            offerData = {
                'user_id': customerDetails?.customerData?.userId,
                'customer_name': customerDetails?.customerData?.name,
                'start_date': customerDetails?.start_date,
                'end_date': customerDetails?.end_date,
                'installment': customerDetails?.installment,
                'mode_payment': customerDetails?.mode_payment,
                'offer_request_price': customerDetails?.offer_request_price,
                'property_id': customerDetails?.customerData?.proprtyDetails?.data?.id,
                'employer_name': customerDetails?.employer_name,
                'housing_allowance': customerDetails?.housing_allowance,
            }
            // 'add_on': newD.toString(),
        } else {
            offerData = {
                'user_id': customerDetails?.customerData?.userId,
                'customer_name': customerDetails?.customerData?.name,
                'start_date': customerDetails?.start_date,
                'end_date': customerDetails?.end_date,
                'installment': customerDetails?.installment,
                'mode_payment': customerDetails?.mode_payment,
                'offer_request_price': customerDetails?.offer_request_price,
                'property_id': customerDetails?.customerData?.proprtyDetails?.data?.id
            }
        }
        makeAnoffer(offerData).then((response) => {
            if (response.code === 200 && response.errorCode != 1) {
                setMessage({
                    error: 0,
                    message: response?.data?.msg,
                })
                ref.current.click();
                setTimeout(() => {
                    ref1.current.click();
                    history.push('/pm-property-data/available')
                }, 2000)
            } else {
                setMessage({
                    error: 1,
                    message: response?.errorDescription,
                })
                ref.current.click();
            }
        })
    }
    
    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])
    return (
        <div className="modal fade add-broker-modal" aria-hidden='true' id="make-offer-popUp">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-8">
                    <div className="modal-header p-0">
                        <h5 className="modal-title">{t("Make an Offer")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-0 pb-0 pt-5">
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="properties_list_view mb-5">
                                        <div className="card px-2 py-2">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3">
                                                    <div className="offer_main_img2">
                                                        <img src={customerDetails?.customerData?.proprtyDetails?.data?.coverImage?.length > 0 ? customerDetails?.customerData?.proprtyDetails?.data?.coverImage : Propertie} alt="Work Orders" className="img-fluid" />
                                                        <div className="property_card_headding py-3 px-3 text-center">
                                                            {/* <p className="fw-bolder mb-0 text-white">Merchant Name</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 px-0">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <p className="text-black fw-bolder fs-16">{customerDetails?.customerData?.proprtyDetails?.data?.propretyName}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="offer_main_details_div_2">
                                                                <h5 className="text-black fw-bolder me-3 font-13">
                                                                    <span className="fw-boldest">{customerDetails?.customerData?.proprtyDetails?.data?.price} / {t("Year")} <small>{t("AED")}</small> </span>
                                                                </h5>
                                                                <p className="fs-7">
                                                                    <img src={bed} className="img-fluid" />
                                                                    {customerDetails?.customerData?.proprtyDetails?.data?.bedrooms}
                                                                </p>
                                                                <p className="fs-7">
                                                                    <img src={Bath} className="img-fluid" />
                                                                    {customerDetails?.customerData?.proprtyDetails?.data?.bathrooms}
                                                                </p>
                                                                <p className="sqft fs-7">
                                                                    <img src={SQR} className="img-fluid" />
                                                                    {customerDetails?.customerData?.proprtyDetails?.data?.builtInArea} {t("Sqmt")}
                                                                </p>
                                                                <p className="fs-7">
                                                                    <img src={Yes} className="img-fluid" />
                                                                    {customerDetails?.customerData?.proprtyDetails?.data?.isParkingAvailable === 'Y' ? t("Yes") : t("No")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 offer_main_details_div_3">
                                                            <p className="mb-0 pt-2">
                                                                <img src={Place} className="img-fluid" />
                                                                {customerDetails?.customerData?.proprtyDetails?.data?.propertyCode?.substring(customerDetails?.customerData?.proprtyDetails?.data?.propertyCode?.lastIndexOf("-") + 1, customerDetails?.customerData?.proprtyDetails?.data?.propertyCode?.length)} | {customerDetails?.customerData?.proprtyDetails?.data?.buildingType} | {customerDetails?.customerData?.proprtyDetails?.data?.Address} | {customerDetails?.customerData?.proprtyDetails?.data?.location}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 position-relative">
                                        <label className="form-label font-12">{t("Customer Name")}</label>
                                        <input type="text" className="form-control font-12" placeholder={customerDetails?.customerData?.name} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("LEASE START DATE")}</label>
                                                <p className="form-label text-black font-14 mb-0">{moment(customerDetails?.start_date).format('DD-MM-YYYY')}</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("NO OF PAYMENTS")}</label>
                                                <p className="form-label text-black font-14 mb-0">{customerDetails?.installment} {t("Payments")}</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("MY OFFER")}</label>
                                                <p className="form-label text-black font-14 mb-0"> {customerDetails?.offer_request_price.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("AED")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-0 form-check">
                                        <input className="form-check-input fc-wo-checkbox" type="checkbox" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            {t("Accept Terms and Conditions")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer px-0 py-0">
                        {/* <button data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal"
                                type='submit' className='btn btn-primary fw-bold px-10 py-3 w-100'>
                            Submit
                        </button> */}

                        <button type='submit'
                            className='btn btn-primary fw-bold px-10 py-3 w-100'
                            onClick={handleSubmit}
                        >

                            {loading && <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true" />}
                            {loading ? t("Loading...") : t("Submit")}
                        </button>
                        <p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                            {/*  */}
                        </p>
                        <p ref={ref1} data-bs-target="#dynamic-popup" data-bs-toggle="modal" >
                            {/*  */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}