import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { CommunitiesList } from './Communities/CommunitiesList'
import { CommunitiesCreateBuilding } from './Communities/CommunitiesCreateBuilding'
import { CommunitiesPreview } from './Communities/CommunitiesPreview'
import { EditCommunities } from './Communities/EditCommunities'
import { AddComunities } from './Communities/AddCommunities'
import { CreateUnit } from './Communities/CreateUnit/CreateUnitWrapper'

const PMCommunitiesPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                    <Route path='/communities/add'>
                            <AddComunities />
                        </Route>
                        <Route path='/communities/list'>
                            <CommunitiesList />
                        </Route>
                        <Route path='/communities/create-building'>
                            <CommunitiesCreateBuilding />
                        </Route>
                        <Route path='/communities/edit'>
                            <EditCommunities />
                        </Route>
                        <Route path='/communities/preview'>
                            <CommunitiesPreview />
                        </Route>
                        <Route path='/communities/create-unit'>
                            <CreateUnit/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMCommunitiesWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMCommunitiesPage />
        </>
    )
};

export { PMCommunitiesWrapper }
