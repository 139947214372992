import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UaeFlagIcon from '../../../assets/images/uae-flag.png'
import UsaFlagIcon from '../../../assets/images/en-flag.png'
import i18n from "i18next";

export function LangSwitch() {

	const [languageType, setLanguageType] = useState<any>('en');
	const { t } = useTranslation();

	const onChangeLanguage = (value: string) => {
		setLanguageType(value);
		i18n.changeLanguage(value);
	}

	const languages = [
		{
			code: 'en',
			name: 'English',
			country_code: 'gb',
		},
		{
			code: 'ar',
			name: 'العربية',
			dir: 'rtl',	
			country_code: 'sa',
		},
	];

	const currentLanguageCode: any = languageType === 'en' ? 'en' : 'ar';
	const currentLanguage: any = languages.find((l) => l.code === currentLanguageCode);

	useEffect(() => {
        // console.log('Setting page stuff')
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title')
    }, [currentLanguage, t]);

	return (
		<div className="d-flex align-items-center lang-sec">
			<div className="switch" data-bs-toggle="tooltip" data-bs-placement="top" title="Tip: You can switch to arabic">
				<label className="switch-light">
					<input 
						type="checkbox" 
						value={languageType}
					/>
					<span className="inner">
						<span onClick={() => onChangeLanguage('ar')} className={`font-14 ${languageType == 'ar' ? "btn btn-toggle-white" : ''}`}>
							<img width="18" height="16" src={UaeFlagIcon} alt="UAE Flag Icon" className='img-fluid me-2' />
							<span className="color-hide">عربی</span>
						</span>
						<span onClick={() => onChangeLanguage('en')} className={`font-14 ${languageType == 'en' ? "btn btn-toggle-white" : ''}`}>
							<img width="18" height="16" src={UsaFlagIcon} alt="USA Flag Icon" className='img-fluid me-2' />
							<span className="color-hide">English</span>
						</span>
					</span>
				</label>
			</div>
		</div>
	)
}
