import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { RootState } from '../../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function Addons() {
    let offerList: any = []
    offerList = useSelector<RootState>(({ General }) => General.offersRenewlaData)
    const { t } = useTranslation();

    // const addOnData = offerList?.add_on?.length > 0 ? offerList?.add_on : []
    const addOnData = new Array()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">

                            {addOnData.length > 0
                                ? addOnData.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-12 mb-6 col-md-4">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Customer Name")}
                                                    </label>
                                                    <div className="data-contain px-5 py-4 rounded-10">
                                                        <h6 className="font-14 fw-bold d-block mb-0">
                                                            {item?.customerName?.length > 0 ? item?.customerName : t("NA")}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-6 col-md-4">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Method of Payment")}
                                                    </label>
                                                    <div className="data-contain px-5 py-4 rounded-10">
                                                        <h6 className="font-14 fw-bold d-block mb-0">
                                                            {''}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-6 col-md-4">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Employer Name")}
                                                    </label>
                                                    <div className="data-contain px-5 py-4 rounded-10">
                                                        <h6 className="font-14 fw-bold d-block mb-0">
                                                            {item?.employerName?.length > 0 ? item?.employerName : t("NA")}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-6 col-md-4">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Housing Allowance")}
                                                    </label>
                                                    <div className="data-contain px-5 py-4 rounded-10">
                                                        <h6 className="font-14 fw-bold d-block mb-0">
                                                            {item?.housingAllowance?.length > 0 ? item?.housingAllowance : t("NA")}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row gx-0">
                                                <div className="col-12 addons-check">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="add-ons" id="addons1" checked disabled/>
                                                        <label className="form-check-label" htmlFor="addons1">
                                                            {item?.addOnName?.length > 0 ? item?.addOnName : t("NA")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 addons-check">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="add-ons" id="addons2" disabled/>
                                                        <label className="form-check-label" htmlFor="addons2">
                                                            {t("5% Municipality Charge")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 addons-check">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="add-ons" id="addons3" disabled/>
                                                            <label className="form-check-label" htmlFor="addons3">
                                                                {t("Furniture Vouchers")} <small className="text-normal ms-3">(2500 AED)</small>
                                                            </label>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <span><i className="bi bi-plus-circle font-14 text-black me-3 fw-bold"></i></span>
                                                            <span className="me-3 fw-bold font-16">1</span>
                                                            <span><i className="bi bi-dash-circle font-14 text-black fw-bold"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 addons-check">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="add-ons" id="addons4" disabled/>
                                                        <label className="form-check-label" htmlFor="addons4">
                                                            {t("Security Deposit")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 addons-check">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="add-ons" id="addons5" disabled/>
                                                        <label className="form-check-label" htmlFor="addons5">
                                                            {t("Etisalat")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 addons-check">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="add-ons" id="addons6" disabled/>
                                                        <label className="form-check-label" htmlFor="addons6">
                                                            {t("Nation Rivera Beach Club")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 addons-check">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="add-ons" id="addons7" disabled/>
                                                            <label className="form-check-label" htmlFor="addons7">
                                                                {t("White Goods")} <small className="text-normal ms-3">(2500 AED)</small>
                                                            </label>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <span><i className="bi bi-plus-circle font-14 text-black me-3 fw-bold"></i></span>
                                                            <span className="me-3 fw-bold font-16">1</span>
                                                            <span><i className="bi bi-dash-circle font-14 text-black fw-bold"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                : t("No Data Found")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}