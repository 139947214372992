import React, { useState, useEffect } from 'react'

export function Activities() {
    return (
        <>
            <div className="row activity mt-4">
                <div className="lead-det-prof pr-2">
                    <div className="card mb-4 bg-light">
                        <div className="card-body p-3">
                            <h6 className="font-weight-600 text-black d-flex justify-content-between align-items-center">Offer Created <span className="font-12 font-weight-normal">17/07/2323</span></h6>
                            <p className="font-13 mb-0">createOffer</p>
                        </div>
                    </div>
                    <div className="card mb-4 bg-light">
                        <div className="card-body p-3">
                            <h6 className="font-weight-600 text-black d-flex justify-content-between align-items-center">Lead Updated <span className="font-12 font-weight-normal">17/07/2323</span></h6>
                            <p className="font-13 mb-0">add_lead</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

