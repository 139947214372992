import React, { useState, useEffect } from 'react';
import Home from "../../../../../../assets/images/nation-towers-new.png";
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { currencyFormater } from '../../../../../../constants/generics/customActios';
import { ImageViewerWithPopup } from '../../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';

export function Details({ approval }: any) {
    const { t } = useTranslation();

    // return (
    //     <div className="com-card-section">
    //         <div className="row">
    //             <div className="col-12">
    //                 <div className="card">
    //                     <div className="card-body p-7">
    //                         <div className="row align-items-center">
    //                             <div className="col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-4">
    //                                 <img src={Home} className="img-fluid" />
    //                             </div>
    //                             <div className="col-md-8 col-lg-9">
    //                                 <div className="card lease-card bg-white shadow-none rounded-0">
    //                                     <div className="card-body p-0">
    //                                         <div className="row">
    //                                             <div className="col-md-6 col-12 col-sm-6 mb-7">
    //                                                 <h6 className="text-black mb-0 fw-bold font-15"> Nation Towers - N21 </h6>
    //                                             </div>
    //                                             <div className="col-md-6 col-12 mb-7 col-sm-6 text-end">
    //                                                 <h6 className="mb-0 fw-bold text-theme font-14 text-decoration-underline"> Offer Number : 25895615651 </h6>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13"> Lease Start Date </h6>
    //                                                 <span className="text-black fw-bold font-14"> 21-Feb-2021 </span>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13"> Lease End Date </h6>
    //                                                 <span className="text-black fw-bold font-14"> 20-Jan-2022 </span>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13"> Security Deposit </h6>
    //                                                 <span className="text-black fw-bold font-14"> AED 20,000 </span>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13"> Annual Rent </h6>
    //                                                 <span className="text-black fw-bold font-14"> AED 120,000 </span>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13"> No. Of Payments </h6>
    //                                                 <span className="text-black fw-bold font-14"> 2 </span>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13"> Target Rent </h6>
    //                                                 <span className="text-black fw-bold font-14"> AED 70,000 </span>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13"> Budget Rent </h6>
    //                                                 <span className="text-black fw-bold font-14"> AED 70,000 </span>
    //                                             </div>
    //                                             <div className="col-lg-3 col-sm-4 col-6 mb-7">
    //                                                 <h6 className="text-theme-gray mb-3 fw-normal font-13">Rent Assessment</h6>
    //                                                 <span className="text-black fw-bold font-14"> AED 70,000 </span>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (

        <div className='row'>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body p-7 pb-6 pt-5'>
                        <div className='row'>
                            <div className='mb-5'>
                                <div className='row align-items-baseline'>
                                </div>
                            </div>
                            <div className='col-md-4 col-lg-3 mb-4'>
                                <div className='approval-move'>
                                    <img src={approval?.communityLogo != null ? approval?.communityLogo : approval?.buildingIcon} alt={Home} className='img-fluid rounded' />
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-9'>
                                {/* <div className='card lease-card bg-white rounded'> */}
                                {/* <div className='card-body px-0 pt-0 pb-2'> */}
                                <div className='row'>
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                                        <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                            {' '}
                                            {t("Lease Number")}
                                        </h6>
                                        <span className='text-black fw-bold font-14'>
                                            {' '}
                                            {approval?.lease_number}{' '}
                                        </span>
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                                        <div className=''>
                                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                {' '}
                                                {t("Lease Start Date")}
                                            </h6>
                                            <span className='text-black fw-bold font-14'>
                                                {' '}
                                                {moment(approval?.start_date).format('DD-MM-YYYY')}{' '}
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                                                    <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                        {' '}
                                                        {t("Lease End Date")}
                                                    </h6>
                                                    <span className='text-black fw-bold font-14'>
                                                        {moment(approval?.details?.leaseEndDate).format('DD-MM-YYYY')}
                                                    </span>
                                                </div> */}
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                                        <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Status")} </h6>
                                        <span className='text-black fw-bold font-14'> {approval?.contract_status} </span>
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                                        <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                            {' '}
                                            {t("Annual Rent")}
                                        </h6>
                                        <span className='text-black fw-bold font-14'>
                                            {' '}
                                            {t("AED")} {approval?.annualRent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
                                        </span>
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                                        <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                            {' '}
                                            {t("Security Deposit")}
                                        </h6>
                                        <span className='text-black fw-bold font-14'> {t("AED")} {approval?.security_deposit?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                                        <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("No. of Installments")} </h6>
                                        <span className='text-black fw-bold font-14'> {approval?.NoOfPayments} </span>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-5">
                <div className="card mb-5">
                    <div className="card-body px-8 py-6">
                        {/* <div className="col-12 col-md-4">
                            {Object.entries(approval?.requestDetails)
                                .map(([key, values]) => (
                                    <div className="row">
                                        <div className="col-12 mb-6">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                {key}:
                                            </label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">
                                                    {values}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div> */}
                        {approval?.requestDetails && <div className="row">
                            <div className="col-12">
                                <h4 className="font-14 text-theme mb-6">
                                    {t("Request Details")}
                                </h4>
                            </div>
                            <div className="col-md-12 col-12 mb-5">
                                <div className="card shadow-sm payment-card-sec">
                                    <div className="card-body px-8 py-6">
                                        <div className="row">
                                            {Object.entries(approval?.requestDetails)
                                                .map(([key, values]) => (
                                                    key.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); }) != 'Id' && <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-3">
                                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                            {key.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); })}
                                                        </label>
                                                        <h3 className="mb-0 payment-txt">
                                                            {key?.includes('Date') && values != null && values != undefined ? moment(values as any).format('DD-MM-YYYY') 
                                                                : key?.includes('Amount') && values != null && values != undefined ? <>{t('AED')} {currencyFormater(values)}</> : values}
                                                            </h3>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {[27, 28, 29, 30 ].includes(approval?.subReference) && approval?.details_json && <div className="row">
                            <div className="col-12">
                                <h4 className="font-14 text-theme mb-6">
                                    {t("Request Details")}
                                    {approval?.subReference === 29 ? ' - ' + 
                                        (approval?.category_type_id === 52 ? t("Helipad and Rooftop Access (PTW)") :
                                            approval?.category_type_id === 53 ? t("Fire Protection Impairment (PTW)") :
                                                t("General PTW"))
                                        : ''}
                                </h4>
                            </div>
                            <div className="col-md-12 col-12 mb-5">
                                <div className="card shadow-sm payment-card-sec">
                                    <div className="card-body px-8 py-6">
                                        <div className="row">
                                            {Object.entries(approval?.details_json)
                                                .map(([key, values]) => {
                                                    let labelVal = key.replace(/_/g, ' ');
                                                    labelVal = labelVal.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); })

                                                    let dataVal = Array.isArray(values) ? '-TODO-' : (key?.includes('Date') && values != null && values != undefined ? moment(values as any).format('DD-MM-YYYY')
                                                    : key?.includes('Amount') && values != null && values != undefined ? <>{t('AED')} {currencyFormater(values)}</> : values)
                                                    return (
                                                        labelVal != 'Id' && <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {labelVal}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">
                                                                {dataVal}
                                                            </h3>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                        <div className="row">
                                            {approval?.attachments_json && Object.entries(approval?.attachments_json)
                                                .map(([key, values], index) => {
                                                    let labelVal = key.replace(/_/g, ' ');
                                                    labelVal = labelVal.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); })
                                                    
                                                    // let dataVal = Array.isArray(values) ? '-TODO-' : (key?.includes('Date') && values != null && values != undefined ? moment(values as any).format('DD-MM-YYYY')
                                                    // : key?.includes('Amount') && values != null && values != undefined ? <>{t('AED')} {currencyFormater(values)}</> : values)
                                                    return (
                                                        labelVal != 'Id' && <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">{labelVal}</label>
                                                            {(values && (typeof values === 'string' )&& values.includes('.pdf')) ?
                                                                <a href={values} className='d-block' target='_blank'>View PDF</a>
                                                                :
                                                                    <ImageViewerWithPopup imageFile={values} imgID={`${index}`}/>
                                                            }
                                                            {/* <h3 className="mb-0 payment-txt">
                                                                {dataVal}
                                                            </h3> */}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div className="row">

                            <div className="col-12 col-md-4">
                                <div className="row">
                                    <div className="col-12 mb-6">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Submitted by")} :
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                {approval?.cutomerName}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-6">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Date Submitted by")} :
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                {moment(approval?.created_at).format('DD-MM-YYYY')}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-8">
                                {approval?.comment && <div className="col-12 mb-6">
                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                        {t("Comments")}
                                    </label>
                                    <div className="data-contain h-75 px-5 py-4 rounded-10">
                                        <h6 className="font-14 fw-bold d-block mb-0">
                                            {approval?.comment}
                                        </h6>
                                    </div>
                                </div>}
                                {approval?.total_cost_impact && <div className="col-12 mb-6">
                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                        {t("Total Cost Impact")}
                                    </label>
                                    <div className="data-contain px-5 py-4 rounded-10">
                                        <h6 className="font-14 fw-bold d-block mb-0">
                                            {approval?.total_cost_impact}
                                        </h6>
                                    </div>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    )

}