import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { getPaymentDetails } from '../../../constants/axios/apis'
import { CustomLoader2 } from '../../../constants/generics/customActios'
import { BasicDetails } from './components/BasicDetails'
import { PaymentDetails } from './components/PaymentDetails'


export function FinancePaymentViewWrapper() {
	let params: any = {}
	params = useLocation()
	let paymentID = ''
	if (params?.state?.paymentDetails?.id)
		paymentID = params.state.paymentDetails.id

	const [loading, setLoading] = useState<any>(true)
	const [paymentDetail, setPaymentDetail] = useState<any>()

	useEffect(() => {
		setLoading(true)
		setPaymentDetail({})
		getPaymentDetails(paymentID)
			.then((response: any) => {
				if (response?.errorCode === 0 && response?.data?.[0]) {
					setPaymentDetail(response.data[0])
					setLoading(false)
				}
			})
			.catch((e) => { })
	}, [paymentID])

	return (
		<>
			<div className='com-card-section myunit-page'>
				{loading ? (
					<CustomLoader2 />
				) : (
					<>
						<BasicDetails receiptDetail={paymentDetail} />
						<PaymentDetails receiptDetail={paymentDetail} />
					</>
				)}
			</div>
		</>
	)
}