import moment from 'moment'
import {useSelector} from 'react-redux'

//created using ref from user_roles_mst
const userType = [
  {
    id_role: 4,
    role_short_name: 'PT',
    role_name: 'Prospective Tenant',
  },
  {
    id_role: 5,
    role_short_name: 'BR',
    role_name: 'Broker',
  },
  {
    id_role: 6,
    role_short_name: 'BA',
    role_name: 'Broker Admin',
  },
  {
    id_role: 7,
    role_short_name: 'T',
    role_name: 'Tenant',
  },
  {
    id_role: 8,
    role_short_name: 'FC',
    role_name: 'Facility Contractor',
  },
  {
    id_role: 9,
    role_short_name: 'FCSS',
    role_name: 'Facility Contractor Soft Services',
  },
  {
    id_role: 10,
    role_short_name: 'FCS',
    role_name: 'Facility Contractor Supervisor',
  },
  {
    id_role: 11,
    role_short_name: 'FT',
    role_name: 'Finance',
  },
  {
    id_role: 12,
    role_short_name: 'FMT',
    role_name: 'FM Team',
  },
  {
    id_role: 13,
    role_short_name: 'FMSM',
    role_name: 'FM Security Manager',
  },
  {
    id_role: 14,
    role_short_name: 'CPT',
    role_name: 'Commercial Prospective Tenant',
  },
  {
    id_role: 15,
    role_short_name: 'CT',
    role_name: 'Commercial Tenant',
  },
  {
    id_role: 16,
    role_short_name: 'MT',
    role_name: 'Marketing Team',
  },
  {
    id_role: 17,
    role_short_name: 'PE',
    role_name: 'Property Executive',
  },
  {
    id_role: 18,
    role_short_name: 'PM',
    role_name: 'Property Manager',
  },
  {
    id_role: 20,
    role_short_name: 'MA',
    role_name: 'Marking Admin',
  },
]

/**
 * Validate if existing user is same as given role code
 * @param user_data (json)
 * @param role_code (Ex-'FC', 'FCS', 'T')
 * @returns true/false
 */
export const validateUserType = (user_data: any, role_code: any) => {
  // export const ValidateUserType = (role_code:any) => {
  // const state: any = useSelector(state => state);
  // const user_data = state?.auth?.user?.data;

  if (user_data && user_data.id_role && role_code) {
    const selectedUser = userType.find((el: any) => user_data.id_role == el.id_role)
    return selectedUser?.role_short_name === role_code
  } else {
    return false
  }
}

/**
 * get the role code of given user
 * @param user_data (json from: state?.auth?.user?.data)
 * @returns role_code (Ex-'FC', 'FCS')
 */
export const getUserRole = (user_data: any) => {
  // export const GetUserCode = () => {
  // const state: any = useSelector(state => state);
  // const user_role = getUserRole(state?.auth?.user?.data);
  // const user_data = state?.auth?.user?.data;

  if (user_data && user_data.id_role) {
    const selectedUser = userType.find((el: any) => user_data.id_role == el.id_role)

    return selectedUser?.role_short_name
  }
  return null
}
