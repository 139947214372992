import React, { useState, useEffect } from 'react';
import Loader from '../../../../components/Loader';
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const UserRecentWorkOrders: React.FC<any> = ({ fcsWorkOrderList }) => {
    const { t } = useTranslation();
    const { loading, response: workOrders, error } = fcsWorkOrderList;

    const columns = [
        {
            field: 'no',
            headerName: t('No'),
            sortable: false,
            disableColumnMenu: true,
            width: 80,
        },
        {
            field: 'WorkOrderNo',
            headerName: t('Work order No'),
            minWidth: 180,
            flex: 1
        },
        {
            field: 'tenantName',
            headerName: t('Tenant Name'),
            minWidth: 200,
            flex: 1
        },
        {
            field: 'issueType',
            headerName: t('Issue Type'),
            minWidth: 200,
            flex: 1
        },
        {
            field: 'building',
            headerName: t('Building'),
            minWidth: 180,
            flex: 1
        },
        {
            field: 'unit',
            headerName: t('Unit'),
            minWidth: 180,
            flex: 1
        },
        {
            field: 'workOrderState',
            headerName: t('Action'),
            className: '',
            disableColumnMenu: true,
            minWidth: 80,
            flex: 1,
            renderCell: (val:any) => {
                // const viewLink = val.value.issueCategory === "Clearance" ?
                //     ( val.value.status === 'Completed' ? '/fc-workorder/moveoutinspection/completed'
                //     : val.value.status === 'Confirmed' ? '/fc-workorder/moveoutinspection/confirmed'
                //     : val.value.status === 'Inprogress' ? '/workorder/details'
                //     : val.value.status === 'Open' ? '/workorder/details'
                //     : '/'
                //     ): '/workorder/details'
                return(
                        <Link to={{'pathname': 'facility-workorder/view', state: {'workOrder': val.value}}} className="text-primary fw-bolder">
                            {t("View")}
                        </Link>
                )
            }
            ,
        }
    ];
    const [workOrderRow, setWorkOrderRow] = useState<any>([]);
    useEffect(() => {
        if (Array.isArray(workOrders)) {
            if (workOrders.length > 0) {
                const workOrderRow = workOrders.map((workOrder: any, index: number) => {
                    return {
                        no: index + 1,
                        WorkOrderNo: workOrder.WorkorderNo,
                        tenantName: workOrder.tenantName,
                        // issueType: workOrder.typeText === 'Move Out' ? 'Move Out Inspection' : workOrder.typeText,
                        issueType: workOrder.issueCategory === 'Clearance' ? (workOrder.typeText === 'Move Out' ? 'Move Out Inspection' : workOrder.typeText): workOrder.issue_type,
                        building: workOrder.buildings[0]?.building_name,
                        unit: workOrder.property_code,
                        // assignedTo: workOrder.assignedTo,
                        workOrderState :workOrder
                    }
                });
                setWorkOrderRow(workOrderRow);
            }
        }
    }, [workOrders]);

    return (
        <div className="card mb-3">
            <div className="card-body px-6 pt-5 pb-0">
                <h4 className="card-title font-18 text-black"> {t("Recent Work Orders")} </h4>
                <div className="rec-payment-act-sec pb-5">
                    <div className="row">
                        <div className="col-12 pt-5">
                            <div className="rec-payment-act-sec">
                                <div className="row">
                                    <div className="col-12">
                                    {!error && (
                                    <div className="data_table">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className='table-responsive'>
                                                    <div style={{ height: 650 }}>
                                                        <DataGrid
                                                            rows={workOrderRow}
                                                            getRowId={(row) => row.no}
                                                            columns={columns}
                                                            pageSize={10}
                                                            rowsPerPageOptions={[10]}
                                                            disableSelectionOnClick
                                                            loading={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    {error && (
                                        <div className="alert alert-danger w-100 mt-2" role="alert">
                                            {error}
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}