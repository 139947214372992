import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { ReportCard } from './components/ReportCard/ReportCard'
// import { ReportsList } from './components/ReportsList/ReportsList'
// import { MonthRent } from './components/Rent/MonthRent'
// import { YearRent } from './components/Rent/YearRent'
// import { Route, Switch } from 'react-router-dom'
import { ReportGraph } from './components/ReportGraph/ReportGraph'
import { geFinanceReports } from '../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'

const ReportsPage: FC = () => {
    const { t } = useTranslation();

    const [reportType, setReportType] = useState<any>('');
    const [reportState, setReportState] = useState<any>({
        loading: false,
        response: {},
        error: false
    });

    const genReport = (params: any) => {
        setReportState({ loading: true, response: {}, error: false })
        geFinanceReports(params)
            .then((response: any) => {
                if (response?.errorCode === 0 && response?.data)
                    setReportState({ loading: false, response: response.data, error: false })
                else if (response?.errorCode === 1)
                    setReportState({ loading: false, response: response?.errorDescription, error: false })
                else
                    setReportState({ loading: false, response: t("Some error occured!"), error: false })
            })
            .catch((e) => {
                setReportState({ loading: false, response: t("Some error occured!"), error: false })
            })
    }

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <ReportCard genReport={genReport} setReportType={setReportType} />
                    {reportState.loading ?
                        <Loader />
                        :
                        reportState.error ?
                            <div className="row pt-5">
                                <div className='col-12 text-center'>
                                    <div className='alert alert-danger w-100 mt-5 mb-0' role='alert'>
                                        {reportState.response}
                                    </div>
                                </div>
                            </div>
                            :
                            <ReportGraph reportState={reportState} setReportState={setReportState} reportType={reportType} />

                    }

                    {/* <Switch>
                        <Route path='/finance-reports'>
                            <ReportsList />
                            <MonthRent />

                        </Route>
                        <Route path='/finance-reports/year'>
                            <ReportCard />
                            <ReportsList />
                            <YearRent />
                        </Route>
                    </Switch> */}
                </div>
            </div>
        </>
    )
};

const FinanceReportsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}> Community Post </PageTitle>
            <ReportsPage />
        </>
    )
};

export { FinanceReportsWrapper }
