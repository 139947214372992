import React, { useState, useEffect } from 'react';
import Home from "../../../../assets/images/nation-towers-new.png";
import { useTranslation } from 'react-i18next'

export function Details() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-7">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-4">
                                    <img src={Home} className="img-fluid" />
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white shadow-none rounded-0">
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-sm-6 col-12 mb-7">
                                                    <h6 className="text-black mb-0 fw-bold font-15"> Nation Towers - N21 </h6>
                                                </div>
                                                <div className="col-sm-6 col-12 mb-7 text-end">
                                                    <h6 className="mb-0 fw-bold text-theme font-14"> {t("Lease Number")} : 25895615651 </h6>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease Start Date")} </h6>
                                                    <span className="text-black fw-bold font-14"> 21-Feb-2021 </span>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease End Date")} </h6>
                                                        <span className="text-black fw-bold font-14"> 20-Jan-2022 </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Annual Rent")} </h6>
                                                    <span className="text-black fw-bold font-14"> AED 12350 </span>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Security Deposit")} </h6>
                                                    <span className="text-black fw-bold font-14"> AED 70,000 </span>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Security Deposit")} </h6>
                                                    <span className="text-black fw-bold font-14"> AED 70,000 </span>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Total Cost Impact")} </h6>
                                                    <span className="text-black fw-bold font-14"> AED 70,000 </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}