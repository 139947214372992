import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

export function AddContactModal() {

    return (
        <div className="modal fade" id="addContact">
            <div className="modal-dialog modal-xl modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Add Contact</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                    <div className="row form-add-design">
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> FIRST NAME * </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="First Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  Last Name* </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Last Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  Phone No* </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Phone No" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  Email* </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 my-2">
                                    <h6 className="font-14 text-theme-black"> Add Address Details </h6>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  Po Box </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Po Box" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  Address Line 1 </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Address Line 1" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  Address Line 2 </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Address Line 2" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  City </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="City" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  Zip </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Zip" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">  State </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="State" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase font-13">Country</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select" required>
                                            <option selected> Please Select </option>
                                            <option>United Arab Emirites</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="text-end">
                                <button type="button" className="btn btn-primary px-7 font-weight-semibold" id="button-apply-property">Save</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}