import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import avtaricon from '../../../assets/images/avatar-con.jpeg'
import avtar from '../../../assets/images/avatar-small.jpeg'
import { getPropertiesUserRoleList } from '../../../constants/axios/apis'
import { Select } from 'antd'



export function AddAccounts() {
    const { Option } = Select
    const [userRoleList, setUserRoleList] = useState<any>([])
    useEffect(() => {
    getPropertiesUserRoleList().then(({ data }) => {
        setUserRoleList(data?.data)
    })
   },)
    return (
        <>
            <div className="row align-items-center mb-5 community">
                <div className="col-md-5">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>Add User
                    </h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row form-add-design">
                        {/* <div className="col-3"> */}
                        <div className="col-md-4 col-lg-3  ag-prof">
                            <div className="form-group">
                                <label className="d-block w-100 text-uppercase font-13">Profile Picture</label>
                                <div className="my-2 prof-update d-flex flex-wrap align-items-end">
                                    <div className="circle mx-auto radius-15 col-md-3 px-0">
                                        <img className="profile-pic img-fluid mx-auto overflow-hidden" src={avtaricon} alt="Image" />
                                    </div>
                                    <div className="p-image theme-color text-center mt-3 col-md-9">
                                        <p className="upload-button border rounded font-13 py-2 form-height px-3 ms-3 mb-0 ">
                                                <i className="fa fa-upload"></i> <span>Upload Photo</span>
                                        </p>
                                        <input className="file-upload111" type="file" accept="image/*" />
                                        <input type="hidden" id="photo" name="photo" value="" />
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> User Name </label>
                                <input type="text" className="form-control fw-bolder" placeholder="User Name" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Mobile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Enter Mobile Number" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Email </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Enter Email Id" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Alternate Mobile</label>
                                <input type="number" className="form-control fw-bolder" placeholder="Alternate Mobile" name="alternate_phone" value="" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Gender</label>
                                <select className="form-control form-select fw-bolder "
                                >
                                    <option >Please Select </option>
                                    <option>Male </option>
                                    <option>Female</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">State</label>
                                <select className="form-control form-select fw-bolder "
                                >
                                    <option >Please Select </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">City</label>
                                <select className="form-control form-select fw-bolder "
                                >
                                    <option >Please Select </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Pin Code </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Pin Code" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Adress Line </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Adress Line" />
                            </div>
                        </div>
                        <div className="col-md-3">
                        <div className="mb-7">
                                <label className="form-label text-uppercase">Attached Cirtificates</label>                               
                                <div className="border-upload">
                                    <img className='img-fluid' src={avtar} width={'20px'}/>
                                    <span className='ms-2'>Upload</span>
                                </div>
                                <input className="form-control input-1" type="file" id="formFile" />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="form-label text-uppercase fw-bolder">Date of Birth</label>
                                    <input type="date" className="form-control fw-bolder" placeholder="Date of Birth" id="dob" name="date_of_birth" value="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder">User Role</label>
                                <Select
                                    className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                                    showSearch
                                    placeholder='Please Select'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        ((option!.children as unknown) as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    {userRoleList?.map((el: any, index: number) => (
                                        <Option key={index} value={el.id}>
                                            {el.user_role_name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13">About profile</label>
                                    <textarea className="form-control fw-bolder" placeholder="Enter profile here..." rows={2} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Linked Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Linked Profile" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Facebook Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Facebook Profile" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Twitter Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Twitter Profile" />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder"> Video Profile </label>
                                <input type="text" className="form-control fw-bolder" placeholder="Video Profile" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label text-uppercase fw-bolder">Status</label>
                                <select className="form-control form-select fw-bolder ">
                                    <option >Please Select </option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Save
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
