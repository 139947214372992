
import { Summary } from "./Summary";
import { Viewing } from "./Viewing";
import { Offer } from "./Offer";
import { Leads } from "./Leads";
import { myDashboard } from "../../../../constants/axios/apis";
import { useEffect, useState } from "react";
import { getInvoiceList } from "../../../../utils/makeRequest";
import { IState } from "../../../../utils/interface";
import { useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";

export function GraphWrapper() {
    const state: any = useSelector(state => state);
    const [chartData, setChartData] = useState<any>([])
    const [loading, setLoading] = useState<any>(true)
    const userToken = state?.auth?.accessToken;

    useEffect(() => {
        myDashboard()
            .then((response: any) => {
                setLoading(false)
                if (response?.status === 200 && response?.data?.errorCode != 1) {
                    setChartData(response)
                } else {
                    setChartData(response)
                }
            })
            .catch((e) => {
            })
    }, [])

    if (userToken === "undefined") {
        // token undefined
    }

    const [invoiceList, setInvoiceList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    const { response: invoices, error } = invoiceList;
    useEffect(() => {
        getInvoiceList(setInvoiceList, userToken);
    }, []);
    function yearMonths() {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    function getInvoiceMonths(invoices: any[],status: string){
        const months = yearMonths();

        let invoiceMonth = new Set(
            invoices.filter(invoice=>invoice.status === status).map(invoice => {
                let date = new Date(invoice.invoiceDate);
                return date.getMonth()
            }).sort().map(d=>{
                return months[d];
            })
        );
        let invoiceMonths: any[] = [];

        invoiceMonth.forEach(function(month: any) {
            invoiceMonths.push(month);
        });

        return invoiceMonths;
    }

    function getTotalInvoiceByMonth(invoices: any[], status: string, invoiceMonths: any[]){
        const months = yearMonths();
        const returnArray: any[] = [];

        let invoice = invoices.filter(invoice=>invoice.status === status)

        invoiceMonths.forEach (function(month: any) {
            let totalInvoice = invoice.filter(inv=>{
                let d = new Date(inv.invoiceDate);

                return months[d.getMonth()] === month;
                //let invoiceMonth = d.getMonth();
            });
            returnArray.push(totalInvoice.length);
        })
        return returnArray;
    }

    const [chartDataPending, setChartDataPending] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'In-Progress',
                data: [1,2,3,4],
                backgroundColor: '#002C6A',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });

    useEffect(() => {
        if (Array.isArray(invoices)) {
            if (invoices.length > 0) {
                let pendingInvoiceMonth = getInvoiceMonths(invoices, "Pending");
                setChartData(setChartDataPending);

            }
        }
    }, [invoices]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-12">
                                     <div className="col-12 cpt-tenant-det2">
                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item tab_nav_li" role="presentation">
                                                    <button className="nav-link pb-4 position-relative active" id="pills-home-tab" data-bs-toggle="pill"
                                                        data-bs-target="#summer" type="button" role="tab" aria-controls="pills-home"
                                                        aria-selected="false">
                                                        Summary
                                                    </button>
                                                </li>
                                                <li className="nav-item tab_nav_li" role="presentation">
                                                    <button className="nav-link pb-4 position-relative" id="pills-profile-tab" data-bs-toggle="pill"
                                                        data-bs-target="#Leads" type="button" role="tab" aria-controls="pills-profile"
                                                        aria-selected="true">
                                                        Leads
                                                    </button>
                                                </li>
                                                <li className="nav-item tab_nav_li" role="presentation">
                                                    <button className="nav-link pb-4 position-relative" id="pills-profile-tab" data-bs-toggle="pill"
                                                        data-bs-target="#Viewing" type="button" role="tab" aria-controls="pills-profile"
                                                        aria-selected="true">
                                                        Viewing
                                                    </button>
                                                </li>
                                                <li className="nav-item tab_nav_li" role="presentation">
                                                    <button className="nav-link pb-4 position-relative" id="pills-profile-tab" data-bs-toggle="pill"
                                                        data-bs-target="#offer" type="button" role="tab" aria-controls="pills-profile"
                                                        aria-selected="true">
                                                        Offer Sent
                                                    </button>
                                                </li>
                                                <li className="nav-item tab_nav_li flex-fill text-end" role="presentation">
                                                    <DateRangePicker size="sm" format="dd/MM/yyyy" className="mb-3 font-12" placeholder="Select Date Range" />
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="summer" role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <Summary data={chartDataPending} />
                                                </div>
                                                <div className="tab-pane fade" id="Leads" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <Leads chartData={chartData} />
                                                </div>
                                                <div className="tab-pane fade" id="Viewing" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <Viewing chartData={chartData} />
                                                </div>
                                                <div className="tab-pane fade" id="offer" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <Offer chartData={chartData} />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}