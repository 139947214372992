import React, {useState, useEffect} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FacilityMaintenanceCards } from './FacilityMaintenanceCards/FacilityMaintenanceCards'
import { FacilityMaintenanceTableOpen } from './FacilityMaintenanceTable/FacilityMaintenanceTableOpen'
import { FacilityMaintenanceTableResolved } from './FacilityMaintenanceTable/FacilityMaintenanceTableResolved'
import { RequestTabs } from './RequestTabs/RequestTabs'

export function FacilityMaintenanceListWrapper() {
  const [searchItem, setSearchItem] = useState<any>('');

  return(
    <>
      <FacilityMaintenanceCards />
      <RequestTabs searchItem={searchItem} setSearchItem={setSearchItem} />
      <Switch>
        <Route path='/facility-maintenance/list/open'>
          <FacilityMaintenanceTableOpen searchItem={searchItem} setSearchItem={setSearchItem} />
        </Route>
        <Route path='/facility-maintenance/list/resolved'>
          <FacilityMaintenanceTableResolved searchItem={searchItem} setSearchItem={setSearchItem} />
        </Route>
        <Redirect to='/error/403' />
      </Switch>
      {/* <FacilityMaintenanceTable /> */}
    </>
  )
}
