import React, { useState, useRef, useEffect } from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom';
// import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
// import LeftArrow from "../../../../../../assets/images/Left_Arrow.png";
import NoImage from "../../../../../../assets/images/House.png";
import { CustomLoaderCompact } from '../../../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

const OnHoldBasicDetails = ({workOrderDetails, loading, approval}:any) => {
    const { t } = useTranslation();

    // let workOrderImage = NoImage
    // if (workOrderDetails?.objectDetails?.attachments_json?.photo && workOrderDetails.objectDetails.attachments_json.photo[0])
    //     workOrderImage = workOrderDetails.objectDetails.attachments_json.photo[0]
    
    const building_img = workOrderDetails?.buildings && workOrderDetails?.buildings[0] && workOrderDetails.buildings[0].communityLogo ? workOrderDetails.buildings[0].communityLogo : NoImage

    return (
        <div className="row">
                <div className="col-12">
                    <div className="card mb-7 overflow-hidden">
                        <div className="card-body p-7">
                            { loading && <CustomLoaderCompact /> }
                            <div className="row">
                                <div className="card-status">
                                    {
                                        approval?.status === 'Pending' ? <h4 className='pending'>{t("Pending")}</h4>
                                        :approval?.status === 'Approved' ? <h4 className='approved'>{t("Approved")}</h4>
                                        :approval?.status === 'Rejected' ? <h4 className='rejected'>{t("Rejected")}</h4>
                                        :''
                                    }
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className='approval-move'>
                                        <img src={building_img} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white rounded shadow-none">
                                        <div className="card-body px-0 py-0">
                                                
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <span className="text-black fw-bolder font-15 d-block mb-4">
                                                        {t("Work Order Number")} : {workOrderDetails?.WorkorderNo}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 col-6 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Unit Number")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrderDetails?.property_code} </span>
                                                </div>
                                                <div className="col-md-4 col-6 mb-5">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Issue Type")} </h6>
                                                        <span className="text-black fw-bold font-15"> {workOrderDetails?.typeText === "Move Out" ? 'Move Out Inspection' : workOrderDetails?.issue_type} </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-6 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Appointment Date")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrderDetails?.date} </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Time")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrderDetails?.time} </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Priority")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrderDetails?.Priority} </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Work Order Number")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrderDetails?.WorkorderNo} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export { OnHoldBasicDetails }

