import React, { useState, useEffect } from 'react'

export function PropertyDetails() {
    return (      
        <>
        <div id="property-details" className="property-details mt-2 active">
            <div className="row">
                <div className="col-md-12 mb-3 col-12">
                    <div className="card radius-15">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                        <label className="font-12 font-weight-semibold mb-0 col-sm-6 col-auto"> Start Date : </label> 
                                        <div className="col-auto">
                                            <span className="font-weight-semibold text-black font-12">13/07/2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-group row circl-sm mb-2 align-items-center">
                                        <label className="font-12 font-weight-semibold mb-0 col-sm-6 col-auto"> End Date : </label>
                                        
                                        <div className="col-auto ">
                                            <span className="font-weight-semibold text-black font-12">23/07/2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="font-16 mb-0 font-weight-semibold text-black"> Amenities </h5>
                                    <hr className=""/>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Power Backup </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Swimming Pool </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Golf Course </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Bar </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <div className="py-2">
                                                <i className="far font-14 fa-check-square"></i>
                                                <span className="font-12 pl-1 font-weight-semibold"> Squash courts </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

