import React, { useState, useEffect } from 'react'
import OtherServiceIcon from '../../../../assets/images/others_service.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'
import { getMaintenanceRequestsCategories } from '../../../../constants/axios/apis'
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios'
import { useTranslation } from 'react-i18next';

const settings = {
	dots: false,
	arrows: true,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				dots: false,
				arrows: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				dots: false,
				arrows: true,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
				arrows: true,
			},
		},
	],
}

//FCS - Rendering only Outdoor Category Services(temp)
// let userDetail: any = localStorage.getItem('userDetail')
// const isFCS = JSON.parse(userDetail).id_role === 10 ? true : false;

export function FacilityMaintenanceCards() {
	const { t } = useTranslation();
	const [categories, setCategories] = useState<any>()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		getMaintenanceRequestsCategories().then(({ data: categoriesData }) => {
			// if(isFCS)//Ignore Indoor services based on user
			// categoriesData = categoriesData?.filter((category: any) => category?.categoryName === 'Outdoor')
			setCategories(categoriesData)
			setLoading(false)
		}).catch((e)=>setLoading(false))
	}, [])

	let content = categories?.map((category: any) =>
		category?.service_category_types.map((subcategory: any) => (
			<div key={subcategory.id}>
				<Link
					to={{ pathname: '/facility-maintenance/add', state: { quickLinkCategory: subcategory } }}
					className='text-decoration-none'
				>
					<div className='card'>
						<div className='card-body py-7 px-5'>
							<div className='row g-0'>
								<div className='col-4'>
									<img src={subcategory.icon ? subcategory.icon : OtherServiceIcon} className='img-fluid' />
								</div>
								<div className='col-8'>
									<h6 className='card-title mb-0'>{subcategory.name}</h6>
								</div>
							</div>
						</div>
					</div>
				</Link>
			</div>
		))
	)
	return (
		<div className='com-card-section'>
			<div className='row mb-6 flex-stack'>
				<div className='col-md-6 col-6'>
					<h4 className='card-title font-18 text-black m-0'>{t("Maintenance")}</h4>
				</div>
				<div className='col-md-6 col-6 text-end'>
					<Link to={'/facility-maintenance/add'} className='btn btn-primary btn-sm font-16'>
						<i className='bi bi-plus-circle-fill align-middle'></i> {t("Add")}
					</Link>
				</div>
			</div>
			<div className='row'>
				<div className='col-12'>
					<div className='card mb-7'>
						<div className='card-body p-7'>
							<div className='row'>
								<div className='col-md-6 col-6'>
									<h4 className='card-title'> {t("New Request")} </h4>
								</div>
							</div>
							{loading && (
								<CustomLoader2 height={'80'}/>
							)}
							{!loading && (
								<div className='quick-link-sec'>
									<div>
										<div className='row'>
											<div className='col-12'>
												{content?.length ? 
													<Slider {...settings}>
														{categories ? content : ''}
													</Slider>
													:
													<NoDataFound1 height={'110'} />
												}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
