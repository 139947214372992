import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getUserRole } from '../../../../../../constants/generics/userCheck';
import { useTranslation } from 'react-i18next';

export function InActiveAccessCardDetails({ count, cardDetails, test }: any) {
  const defaultCount = count;
  const { t } = useTranslation();
  const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);

  const [cardInputs, setCardInputs] = useState<any>([])
  const [inputCount, setInputCount] = useState<any>(defaultCount)

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                {/* <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='accessCardRequestForm'
                //   onSubmit={formik.handleSubmit}
                > */}
                  <div className='row form-add-design'>
                    {new Array(defaultCount).fill(0).map((value: any, index: any) => {
                      return (
                        <div key={index} className='col-sm-6 col-md-4 col-12 label_close'>
                          <div className='mb-5'>
                            <label className='form-label fw-bolder'>
                              {t("Card Number")} {index + 1}
                            </label>
                            <div className='d-flex'>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid expected-rent-input'
                                value={cardDetails?.[index]}
                                autoComplete='off'
                                required
                                maxLength={25}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className='row form-add-design'>
                    <>
                      <div className='col-sm-6 col-md-4 col-12'>
                        <div className='mb-6'>
                          <label className='form-label fw-bolder d-block'> {t("Customer Signature")} </label>
                          <span className='sign-box1'>
                            <img src={cardDetails?.card_details?.building_access_card?.signature} className='img-fluid' />
                          </span>
                        </div>
                      </div>
                    </>
                    {user_role === 'FMSM' &&
                      <div className='col-sm-6 col-md-4 col-12'>
                        <div className='mb-6'>
                          <label className='form-label fw-bolder d-block'>{t("FCSS Signature")}</label>
                          <span className='sign-box1'>
                            <img src={cardDetails?.card_details?.building_access_card?.staff_signature} className='img-fluid' />
                          </span>
                        </div>
                      </div>
                    }
                  </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
