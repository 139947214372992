export const makeRequest = async (method = 'GET', URL: string, setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, requestParams: any = '', userToken: string = '', formData: boolean = false, successCallback: Function = () => {}, failCallback: Function = () => {}) => {

    try{
        setState({
            loading: true,
            response: [],
            error: ''
        });

        let headers:any = {}

        let body = requestParams;

        if (!formData) {
            headers = {
                'Content-Type': 'application/json',
            }
            body = JSON.stringify(requestParams);
        }

        if (userToken !== "undefined" && userToken !== "") {

            headers = {
                ...headers,
                'Authorization': `Bearer ${userToken}`
            }
        }

        let init = method == "POST" || method == "PUT" ? {
            method: method,
            headers: headers,
            body: body
        } : {
            method: method,
            headers: headers
        };

        const response = await fetch(URL, init);

        const responseObj = await response.json();
        if(responseObj.code === 200 && responseObj.errorCode == 0){
            setState({
                loading: false,
                response: responseObj.data,
                error: ''
            });
            successCallback();
        }else{
            if (responseObj.errorDescription !== null && responseObj.errorDescription !== '') {
                throw responseObj.errorDescription;
            } else {
                throw "Server error";
            }
        }
    }catch(err){
        let catchError = "";
        if (typeof err !== "string") {
            catchError = "Server error";
        } else {
            catchError = err;
        }
        //setExtendOfferState({loading: false, error: catchError});
        setState({
            loading: false,
            response: [],
            error: catchError
        });
        failCallback();
        console.log('error',err);
    }
}