import React, { FC, useEffect, useRef, useState } from 'react'
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import FullCalendar, { CalendarOptions, EventInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils'

export const CalendarCard: React.FC<any> = ({eventState}) => {
	const [selectedDate, setSelectedDate] = useState<any>() // Accept setSelectedDate from the parent as a props
	const handleDateClick = (arg:any) => {
		setSelectedDate(arg?.date)
	}

	return (
		<div className='com-card-section'>
			<div className='row'>
				<div className='col-12'>
					<div className='card mb-6 calender-card-sec'>
						<div className='card-body px-8 py-10'>
							<div className='row'>
								<div className='col-12'>
									<div className='row'>
										<div className='new-calender'>
											<FullCalendar
												plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
												headerToolbar={{
													left: 'prev,next,today',
													center: 'title',
													right: 'timeGridWeek,dayGridMonth,timeGridDay,',
												}}
												initialView='timeGridWeek'
												editable={true}
												selectable={true}
												selectMirror={true}
												dayMaxEvents={true}
												events={eventState} // alternatively, use the `events` setting to fetch from a feed
												dateClick={handleDateClick}
											/>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
