import React from 'react';
import { useTranslation } from 'react-i18next';

export function Details({ teamMember }: any) {
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("First Name")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                    {teamMember?.first_name}
                  </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Last Name")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                    {teamMember?.last_name}
                  </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Contact Number")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        {teamMember?.contact_no ? teamMember?.contact_no : '-'}
                    </span>
                </div>
            </div>
            
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Email ID")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        {teamMember?.email ? teamMember?.email : '-'}
                    </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Emirate ID")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                    {teamMember?.emirates_id}
                  </span>
                </div>
            </div>
            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Alternate Email ID")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        --TODO--
                    </span>
                </div>
            </div> */}
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Alternate Contact No.")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        {teamMember?.alternate_no ? teamMember?.alternate_no : '-'}
                    </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Emergency Contact Name")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        {teamMember?.emergency_contact_name ? teamMember?.emergency_contact_name : '-'}
                    </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Emergency Contact Number")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        {teamMember?.emergency_contact_no ? teamMember?.emergency_contact_no : '-'}
                    </span>
                </div>
            </div>
            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Present Address")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        --TODO--
                        Sample Data - Po Box 50584, Dubai, 50536 - UAE
                    </span>
                </div>
            </div> */}
            {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Permanent Address")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        --TODO--
                        Sample Data - Po Box 30118, Sharjah, 50871 - UAE
                    </span>
                </div>
            </div> */}
        </div>
    );
}