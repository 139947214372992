import React, { FC, useEffect, useRef, useState } from 'react'
import Dateicon from "../../../../../assets/images/Calendar.png";
import UploadIcon from "../../../../../assets/images/upload-ioc.png";
import { Link } from 'react-router-dom'
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { useFormik } from 'formik';
import { getMasterData } from '../../../../constants/axios/apis';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { PreventDatePickerInput } from '../../../../constants/generics/dateTimeFormater';
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';
import { getUserRole } from '../../../../constants/generics/userCheck';

// export const Report: FC<any> = ({ reportState, setReportState, genReport, buildingListState, setReportType }) => {
export const Report: FC<any> = ({ reportState, setReportState, genReport, setReportType }) => {
    const { t } = useTranslation();
    const today_data_object = new Date();
    const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    const [loading, setLoading] = useState<any>(false)
    const [reportList, setReportList] = useState<any>()
    
    const initialValues = {
        building_id: currentBuilding ? currentBuilding : "0",
        report_type: "0",
        from_date: "",
        to_date: "",
        send_email: "false",
        proper_date: ''
    };

    const onSubmit = (values: any) => {
        /* console.log('Form: ',values); */
        const sendMailVal = values.send_email === true ? "1" : "0";
        // const params = `?building_id=${values.building_id}&report_type=${values.report_type}&from_date=${values.from_date}&to_date=${values.to_date}&send_email=${sendMailVal}`;
        const params = `?building_id=${values.building_id}&report_type=${values.report_type}&from_date=${values.from_date}&to_date=${values.to_date}&send_email=${sendMailVal}`;
        genReport(params);

        const reportTypeVal = reportList?.find((el:any)=>values.report_type == el.value)?.name
        setReportType(reportTypeVal ? reportTypeVal : '')
    }

    const validate = (values: any) => {
        let errors: any = {}

        if(!values.building_id){
            errors.building_id = t('Required!');
        }

        // if(!values.report_type){
        //     errors.report_type = 'Required';
        // }

        if (!values.from_date) {
            errors.from_date = t('Required!');
        }

        if (!values.to_date) {
            errors.to_date = t('Required!');
        }

        var date1 = new Date(values.from_date);
        var date2 = new Date(values.to_date);

        if (values.from_date && values.to_date) {
            if (date1.getTime() > date2.getTime()) {
                errors.proper_date = t('\'To date\' must be greater then \'From date\' !');
            }
        }
        return errors;
    }
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
        // enableReinitialize: true
    });

    const [fromDateValue, setFromDateValue] = useState<any>();
    const [toDateValue, setToDateValue] = useState<any>();
    const handleFromDateChange = (e: any) => {
        setFromDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') :'';
        formik.setFieldValue("from_date", menDate);        
    }
    const handleToDateChange = (e: any) => {
        setToDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') :'';
        formik.setFieldValue("to_date", menDate);        
    }

    useEffect(() => {
        if(user_role === 'FMT'){
            setLoading(true)
            setReportList([])
            getMasterData().then(({ data }) => {
                const reportList = data.find((el: any) => el.category_name === "fc_report_type")
                setReportList(reportList?.master_data ? reportList?.master_data : [])
                setLoading(false)
            }).catch(e=>{
                setLoading(false)
            })
        }
    }, [])

    const reportOption = reportList?.map((reportItem: any) => (
        <option key={reportItem.value} value={reportItem.value} className="text-capitalize">
            {reportItem.name}
        </option>
    ))

    PreventDatePickerInput()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        {t("Report")}
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="card-body px-8 py-6">
                            {loading && (
                                <CustomLoader2 />
                            )}
                            {!loading && (
                                <>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row form-add-design">
                                                <div className="col-md-3 col-sm-6 col-12">
                                                    <div className="mb-10">
                                                        <label className="form-label fw-bolder">{t("Select Building")} <span className="text-danger"> * </span></label>
                                                        <select
                                                            name="building_id"
                                                            className="form-control form-select fw-bolder form-control-solid"
                                                            onChange={formik.handleChange}
                                                            defaultValue={currentBuilding ? currentBuilding : '0'}
                                                        // value={formik.values.building_id} 
                                                        >
                                                            <option value="0">{t("All")}</option>
                                                            {listBuilding?.map((building: any, index: number) => {
                                                                return (
                                                                    <option value={building.id} key={index}>{building.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {formik.errors.building_id ? (
                                                            <small className="text-danger">
                                                                {formik.errors.building_id}
                                                            </small>
                                                            ): null }
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12">
                                                    <div className="mb-10">
                                                        <label className="form-label fw-bolder">{t("Report Type")} <span className="text-danger"> * </span></label>
                                                        <select
                                                            name="report_type"
                                                            className="form-control form-select fw-bolder form-control-solid text-capitalize"
                                                            onChange={formik.handleChange}
                                                        // value={formik.values.report_type} 
                                                        >
                                                            <option value="0">{t("All")}</option>
                                                            {reportList ? reportOption : ''}
                                                        </select>
                                                        {/* {formik.errors.report_type ? (
                                                        <small className="text-danger">
                                                            {formik.errors.report_type}
                                                        </small>
                                                    ): null } */}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-12">
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 col-sm-6 col-12">
                                                            <div className="mb-2 date-picker">
                                                                <label className="form-label fw-bolder">{t("From Date")} <span className="text-danger"> * </span></label>
                                                                {/* <input
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.from_date}
                                                                    name="from_date" type="date" className="form-control fw-bolder form-control-solid" placeholder=""
                                                                    onBlur={formik.handleBlur} /> */}
                                                                <DatePicker
                                                                    value={fromDateValue}
                                                                    className='form-control fw-bolder form-control-solid'
                                                                    format="dd-MM-y"
                                                                    // maxDate={today_data_object}
                                                                    onChange={handleFromDateChange}
                                                                    dayPlaceholder="DD"
                                                                    monthPlaceholder="MM"
                                                                    yearPlaceholder="YYYY"
                                                                />
                                                                {formik.touched.from_date && formik.errors.from_date ? (
                                                                    <small className="text-danger">
                                                                        {formik.errors.from_date}
                                                                    </small>
                                                                ) : null}
                                                                {(formik.touched.from_date && formik.touched.to_date) && formik.errors.proper_date ? (
                                                                    <small className="text-danger">
                                                                        {formik.errors.proper_date}
                                                                    </small>
                                                                ) : null}                                                                
                                                                {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-6 col-12">
                                                            <div className="mb-2 date-picker">
                                                                <label className="form-label fw-bolder">{t("To Date")} <span className="text-danger"> * </span></label>
                                                                {/* <input
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.to_date}
                                                                    name="to_date" type="date" className="form-control fw-bolder form-control-solid" placeholder=""
                                                                    onBlur={formik.handleBlur} /> */}
                                                                <DatePicker
                                                                    value={toDateValue}
                                                                    className='form-control fw-bolder form-control-solid'
                                                                    format="dd-MM-y"
                                                                    // maxDate={today_data_object}
                                                                    onChange={handleToDateChange}
                                                                    dayPlaceholder="DD"
                                                                    monthPlaceholder="MM"
                                                                    yearPlaceholder="YYYY"
                                                                />
                                                                {formik.touched.to_date && formik.errors.to_date ? (
                                                                    <small className="text-danger">
                                                                        {formik.errors.to_date}
                                                                    </small>
                                                                ) : null}
                                                                {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div className="col-md-4 col-lg-4 col-xl-6 col-12">
                                                </div>
                                                <div className="col-md-4 col-lg-4 col-sm-6 col-xl-3 col-12 py-4">
                                                    <div className="form-check form-check-sm custom float-end">
                                                        <input
                                                            onChange={formik.handleChange}
                                                            value={formik.values.send_email}
                                                            name="send_email" className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                        <label className="form-check-label font-12 fw-normal" htmlFor="flexCheckDefault">
                                                            {t("Send A Copy Via Email")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-4 col-sm-6 col-xl-3 col-12">
                                                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3 d-block w-100 GenerateReport'>
                                                        {t("Generate Report")}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}