import React, {FC, useEffect, useRef, useState} from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Link} from 'react-router-dom'
import {StepOne} from './components/StepOne/StepOne'
import {StepTwo} from './components/StepTwo/StepTwo'
import {StepThree} from './components/StepThree/StepThree'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { useTranslation } from 'react-i18next'

const UpdateOffer: FC = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const { t } = useTranslation();

    const steps = [
        t("Enter Tenants Details"),
        t("Emergency Contact"),
        t("Upload Document")
    ];

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSubmit = () => {

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error(t("You can't skip a step that isn't optional."));
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className="com-card-section movein-page">
            <div className="row">
                <div className="col-md-12 col-12 mb-6">
                    <h4 className="card-title font-18 text-black mb-0">
                        <Link to={'/pm-offers-and-renewals/edit-offer'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </Link>
                        {t("Edit Property Details")}
                    </h4>
                </div>
            </div>
            <div className="col-12">
                <div className="card w-100">
                    <div className="card-body p-0">
                        <Stepper activeStep={activeStep} className="multiform-stepper">
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step className={activeStep == index ? "active" : ""} key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <React.Fragment>
                            <div>
                                {
                                    activeStep === 0 ?
                                    <StepOne /> :
                                    activeStep === 1 ?
                                    <StepTwo /> :
                                    <StepThree />
                                }
                            </div>
                            <div className='d-flex flex-stack bg-white action-btns px-7 stepper-actions'>
                                <div className='mr-2 col-2'>
                                    <button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className='btn btn-outline-secondary btn-lg px-10 py-3 w-100 btn-back'
                                    >
                                        {t("Back")}
                                    </button>
                                </div>
                                <div className="col-8">
                                    &nbsp;
                                </div>

                                <div className="col-2">
                                    <button
                                        onClick={activeStep === 3 ? handleSubmit : handleNext}
                                        className='btn btn-primary fw-bold px-10 py-3 w-100'
                                        data-bs-target={activeStep === 3 ? "#added-success-modal" : ""}
                                        data-bs-toggle={activeStep === 3 ? "modal" : ""}
                                    >
                                        {activeStep === 3 ? t("Submit") : t("Next")}
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        </div>
    )
};

export {UpdateOffer};
