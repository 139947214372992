import React, {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Link} from 'react-router-dom'
import {Step1} from './components/steps/Step1'
import {Step2} from './components/steps/Step2'
import {Step3} from './components/steps/Step3'
import {Step4} from './components/steps/Step4'
import {Step5} from './components/steps/Step5'
import {Step6} from './components/steps/Step6'
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import { useTranslation } from 'react-i18next'

const AddProperty: FC = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const { t } = useTranslation();

    const steps = [t("Property Details"),
    t("Locality Details"),
    t("Rent Details"),
    t("Amenities Details"),
    t("Property Image"),
    t("Property Video")];

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSubmit = () => {

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error(t("You can't skip a step that isn't optional."));
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const intl = useIntl()
  const location = useLocation()
  const [menu, setMenu] = useState('')

  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  useEffect(() => {
    if (window.location.pathname != menu) {
      setMenu(window.location.pathname)
      console.log(window.location.pathname)
    }
  })

    return (
        <>
            <div className="com-card-section movein-page">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h4 className="card-title font-18 text-black mb-6"> 
                            <Link to={'/pm-property-data/available'} className=''>
                                <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            </Link>
                            {t("Building Name")} 
                        </h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card w-100">
                        <div className="card-body p-0">
                            <Stepper activeStep={activeStep} className="multiform-stepper">
                                {steps.map((label, index) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    // if (isStepOptional(index)) {
                                    //     labelProps.optional = (
                                    //         <p>Optional</p>
                                    //     );
                                    // }
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step className={activeStep == index ? "active" : ""} key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <React.Fragment>
                                <div>
                                    {
                                        activeStep === 0 ?
                                        <Step1 /> :
                                        activeStep === 1 ?
                                        <Step2 /> :
                                        activeStep === 2 ?
                                        <Step3 /> :
                                        activeStep === 3 ?
                                        <Step4 /> :
                                        activeStep === 4 ?
                                        <Step5 /> :
                                        <Step6 />
                                    }
                                </div>
                                <div className='d-flex flex-stack bg-white action-btns px-7 stepper-actions'>
                                    <div className='mr-2 col-md-2 col-sm-3 col-5 '>
                                        <button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className='btn btn-outline-secondary btn-lg px-10 py-3 w-100 btn-back'
                                        >
                                            {t("Back")}
                                        </button>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                        <button
                                            onClick={activeStep === 5 ? handleSubmit : handleNext}
                                            className='btn btn-primary fw-bold px-10 py-3 w-100'
                                            data-bs-target={activeStep === 5 ? "#added-success-modal" : ""}
                                            data-bs-toggle={activeStep === 5 ? "modal" : ""}
                                        >
                                            {activeStep === 5 ? t("Submit") : t("Next")}
                                        </button>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </>       
    )
};

export {AddProperty};
