import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'
import { AddLeadRules } from './AddLeadRules';
import { UpdateLeadRules } from './UpdateModal';

const leadsRuleData = [{
    "slNo": "1",
    "ruleName": "Language",
    "type": "users",
    "users": "[112]",
    "created": "2021-07-29 09:44:34",
},
{
    "slNo": "2",
    "ruleName": "Language",
    "type": "users",
    "users": "[113]",
    "created": "2021-07-29 09:44:34",
},
];

export function ListingLeadRules() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-sm">
                    <h3 className=''>Lead Allocation Rules</h3>
                </div>
                <div className="col-sm-auto text-right">
                    {/* <Link to="/lead-rules/add" className="btn btn-primary ms-2"> Add Rule </Link> */}
                    <button data-bs-toggle="modal" data-bs-target="#addLeadPopup" type='button' className='btn btn-primary fw-bold px-10 py-3'>
                        Add Rule
                    </button>
                    {/* <i data-bs-toggle="modal" data-bs-target="#Listing-popup" className="fa fa-eye text-warning ms-2 c-pointer"></i> */}
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Rule Name </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Rule Name" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Type</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option>Please Select </option>
                                    <option>User</option>
                                    <option>Role</option>
                                    <option>Group</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Users</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Users" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Created</label>
                                <input type="date" className="form-control fw-bolder form-control-solid" placeholder="Created" />
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='bg-white'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th> Sl No</th>
                                                                    <th>Rule Name </th>
                                                                    <th> Type </th>
                                                                    <th> Users</th>
                                                                    <th className='text-center'> Created</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {leadsRuleData.length > 0 ? leadsRuleData.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className="text-warning">{item.slNo}</td>
                                                                        <td>
                                                                            <Link data-bs-toggle="modal" data-bs-target="#UpdateLeadPopup" to className='text-warning'>
                                                                                {item.ruleName}
                                                                            </Link>
                                                                        </td>
                                                                        <td>{item.type}</td>
                                                                        <td>{item.users}</td>
                                                                        <td className='text-center'>{item.created}</td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div className='w-100 d-flex justify-content-end'>
                                                            <CompactPagination
                                                                pageSize={pageSize}
                                                                totalCount={leadsRuleData.length}
                                                                defaultPage={currentPage}
                                                                onPageChange={setCurrentPage}
                                                                loading={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddLeadRules />
            <UpdateLeadRules/>
        </>
    )
}
