import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
export function SelectContactModal() {

    return (
        <div className="modal fade" id="select_contact">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Contacts List</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="col-md-12">
                            <div className="prop-detail-tab">
                                <div className="">
                                    <div id="default-count-list" className="default-count-list">
                                        <div className="row">
                                            <div className="col-md-12 mb-4 ox-auto table-res-contain remove-abs">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered gx-3 mb-0 font-12 text-nowrap" id="unit-listing-table">
                                                        <thead className="text-uppercase bg-projects-list font-weight-normal">
                                                            <tr>
                                                                <th> </th>
                                                                <th className="">Ref</th>
                                                                <th className="">FIRST NAME</th>
                                                                <th className="">LAST NAME</th>
                                                                <th className="">PHONE NO</th>
                                                                <th className="">EMAIL</th>
                                                                <th className="">DATE ADDED</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white">
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <input type="radio" name="select_redio" className="form-check-input" value="" />
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <input type="radio" name="select_redio" className="form-check-input" value="" />
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <input type="radio" name="select_redio" className="form-check-input" value="" />
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <input type="radio" name="select_redio" className="form-check-input" value="" />
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <input type="radio" name="select_redio" className="form-check-input" value="" />
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <input type="radio" name="select_redio" className="form-check-input" value="" />
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="text-end">
                                <button type="button" className="btn btn-primary px-7 font-weight-semibold" data-bs-dismiss="modal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}