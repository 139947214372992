import {FC} from 'react'
import { useTranslation } from 'react-i18next';

const Error403: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>{t("403 - Forbidden")}</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        {t("You don't have permission to access this resource.")}
      </div>
    </>
  )
}

export {Error403}
