import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function AddMenu() {
    const [subMenusData, setSubMenus] = useState(false);
    const [menuCount, setMenuCount] = useState<any>(1);
    const subMenuView = () => {
        console.log();
        setSubMenus(!subMenusData);
    }
    return (      
        <>
           <div className="row align-items-center mb-5 community">
                <div className="col-md-5">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>Add Menu
                    </h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form method="POST" name="edit_agency">
                        <div className="row">
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Menu Name *</label>
                                    <input type="text" placeholder="Menu Name" className="form-control font-13 form-height" name="menu_name" value="" required/>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Menu Icon *</label>
                                    <input type="text" placeholder="Menu Icon" className="form-control font-13 form-height" name="menu_icon" value="" required/>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Display Order</label>
                                    <input type="number" placeholder="Display Order" className="form-control font-13 form-height" name="display_order" value="" required/>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13"> Status </label>
                                    <select className="form-control form-select font-13 form-height" name="status" id="status">
                                        <option value="">Select</option>
                                        <option value="0">Inactive</option>
                                        <option value="1">Active</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6 d-flex flex-wrap align-items-center">
                                <div className="form-check mb-7">
                                    <input className="form-check-input" onChange={subMenuView} type="checkbox" name="submenu" id="submenu"/>
                                    <label className="form-check-label font-13" htmlFor="submenu">Have Submenu?</label>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 col-sm-6">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">Menu Link</label>
                                    <input type="text" placeholder="Menu Link" className="form-control font-13 form-height" name="menu_link" value=""/>
                                </div>
                            </div>
                        </div>
                        <div className={`row ${!subMenusData ? 'd-none' : ''}`}>
                            <div className="col-12">
                                <div className="row mb-4 align-items-center">
                                    <div className="col-12 col-sm-6">
                                        <h5 className="text-theme-black font-weight-semibold d-inline-block float-left">Add Sub Menus links</h5>
                                    </div>
                                    <div className="col-12 col-sm-6 text-end">
                                        <Link to id="addnewsubmenu" className="btn btn-Primary" onClick={()=>{setMenuCount((oState:any)=>oState+1)}}>Add New</Link>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12">
                                        <div className="table-responsive">
                                        <table className="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="mw-200"> Title </th>
                                                    <th className="mw-200"> Menu Links </th>
                                                    <th className="text-center"> Display in menu? </th>
                                                    <th className="text-center"> Display Order </th>
                                                    <th className="text-center"> Status </th>
                                                    <th className="text-center"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                Array(menuCount).fill(1).map((data:any, index:any) =>{
                                                    return(
                                                <tr>
                                                    <td>
                                                        <input type="text" className="form-control font-13" name=""/>
                                                    </td>
                                                    <td> 
                                                        <input type="text" className="form-control font-13" name=""/>
                                                    </td>
                                                    <td className="text-center"> 
                                                        <input type="checkbox" className="form-check-input" name=""/> 
                                                    </td>
                                                    <td> 
                                                        <input type="text" className="form-control font-13" name=""/> 
                                                    </td>
                                                    <td className="text-center"> 
                                                        <input type="checkbox" className="form-check-input" name=""/> 
                                                    </td>
                                                    <td className="action-button text-end">
                                                        <div className="dropdown">
                                                            <button type="button" className="btn border-0 rounded-0 p-0" data-bs-toggle="dropdown">
                                                                <i className="fa fa-ellipsis-v text-black"></i>
                                                            </button>
                                                            <div className="dropdown-menu mw-auto">
                                                                <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                    <li>
                                                                        <Link onClick={()=>{setMenuCount((oState:any)=>oState-1)}} to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                            <i className="fa fa-times"></i>
                                                                            <p className="m-0">Delect</p>
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                    )} )}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-end mt-7">
                            <button type="reset" className="btn btn-outline-primary me-3">Cancel</button>
                            <button type="submit" className="btn btn-primary px-7">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </>  
    )
}
