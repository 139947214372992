import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import userIcon from '../../../assets/images/user-ico.png'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'
import { ImportModal } from './ImportModal'

const userData = [{
    "Slno": "1",
    "Name": "Vignes",
    "JobTitle": "Sales Executive",
    "officeNo": "---",
    "CountryDailingCode": "971",
    "phone": "6077615467",
    "email": "vicky3074@yahoo.com",
    "userrole": "	Agent",
    "status": "	Active"
},
{
    "Slno": "2",
    "Name": "Thomas Selby",
    "JobTitle": "Sales Executive",
    "officeNo": "---",
    "CountryDailingCode": "971",
    "phone": "	8295894859",
    "email": "thomas@nomail.com",
    "userrole": "	Broker",
    "status": "	Active"
},
{
    "Slno": "3",
    "Name": "Test Ragul R",
    "JobTitle": "Sales Executive",
    "officeNo": "---",
    "CountryDailingCode": "971",
    "phone": "8765435645",
    "email": "	ragul.r@exalogic.co",
    "userrole": "	Broker",
    "status": "	Active"
},
{
    "Slno": "4",
    "Name": "Test Broker",
    "JobTitle": "Sales Executive",
    "officeNo": "---",
    "CountryDailingCode": "971",
    "phone": "	1234567890	",
    "email": "	testBroker@gmail.com",
    "userrole": "	Broker",
    "status": "	Active"
},
{
    "Slno": "5",
    "Name": "Test Agency",
    "JobTitle": "Sales Executive",
    "officeNo": "---",
    "CountryDailingCode": "971",
    "phone": "562541258",
    "email": "	testagency@test.com",
    "userrole": "	Broker",
    "status": "	Active"
},
{
    "Slno": "6",
    "Name": "Site Admin",
    "JobTitle": "Sales Executive",
    "officeNo": "---",
    "CountryDailingCode": "971",
    "phone": "	8971771897	",
    "email": "	testagency@test.com",
    "userrole": "	Broker",
    "status": "	Active"
},
];

export function UserList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-sm">
                    <h3 className=''>User</h3>
                </div>
                <div className="col-sm-auto text-right">
                    <Link to="/admin-users/add" className="btn btn-primary me-2"> Add User </Link>
                    <Link to className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#import-popup">Import
                    </Link>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Name </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Name" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Job Title </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Job Title" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Office No </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Office No" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Country Dialing Code </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Country Dialing Code" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Phone </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Phone" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Email </label>
                                    <input type="email" className="form-control form-height font-13" placeholder="Email" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">USER ROLE</label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                    <option selected> Please Select </option>
                                    <option> Admin</option>
                                    <option > Agent</option>
                                    <option> Broker </option>
                                    <option> Sale Adminstator</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">Status</label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                    <option selected> Please Select </option>
                                    <option > Active</option>
                                    <option > Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section accounts'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='table-crm2'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th> </th>
                                                                    <th> Name</th>
                                                                    <th> Job Title </th>
                                                                    <th> Office Number </th>
                                                                    <th> Country Dailing Code</th>
                                                                    <th> Phone </th>
                                                                    <th> Email </th>
                                                                    <th> User Role </th>
                                                                    <th> Status </th>
                                                                    <th> Action </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {userData.length > 0 ? userData.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td>
                                                                        <span className="d-inline-block ag-count font-weight-bold font-14 text-center">{item.Slno}</span>
                                                                            </td>
                                                                        <td>
                                                                            <div className="row no-gutters flex-nowrap">
                                                                                <div className="col-auto">
                                                                                    <img src={userIcon} className='ag-user-img border img-fluid rounded-circle' />
                                                                                </div>
                                                                                <div className="col-auto py-1">
                                                                                    <div className="d-block pl-2 py-2">
                                                                                        <h5 className="font-weight-600 mb-0 font-14 ellipsis">{item.Name}</h5>
                                                                                        <p className="mb-0">Male </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.JobTitle}</td>
                                                                        <td>{item.officeNo}
                                                                           
                                                                        </td>
                                                                       
                                                                        <td>{item.CountryDailingCode}</td>
                                                                        <td>{item.phone}</td>
                                                                        <td>{item.email}</td>
                                                                        <td>{item.userrole}</td>
                                                                        <td>{item.status}</td>
                                                                        <td className="action-button text-end">
                                                                            <div className="dropdown">
                                                                                <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v text-black"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                                        <li>
                                                                                            <Link to={'edit'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-edit"></i>
                                                                                                <p className="m-0">Edit</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-share"></i>
                                                                                                <p className="m-0">Share</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={userData.length}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            < ImportModal/>

        </>
    )
}
