import React, { useState, useEffect, useRef } from 'react';
import useRequestPut from '../../../../../hooks/useRequestPut';

import { useTranslation } from 'react-i18next';
import useRequestPost from '../../../../../hooks/useRequestPost';

export const StartWorkorder:React.FC<any> = ({workOrder, refreshRequest}) => {
    const { t } = useTranslation();

    // const { requestState: workOrderUpdateState, setRequestParams } = useRequestPut(`/facility/work/order/update/${workOrder.id}`);
    const { requestState: workOrderUpdateState, setRequestParams } = useRequestPost(`/facility/work/order/update`);
    const [loading, setLoading] = useState<any>(false);

    const startWork = () => {
        setLoading(true)
        // const formData = new FormData();
        // formData.append("status", "2");
        // setRequestParams(formData)
        setRequestParams({
            status: 2,
            work_order_id: workOrder.id
        });
        setTimeout(() => {
            window.location.reload();
        }, 2000)
    }

    return (
        <div className="col-sm-4 col-12 text-end">
            <button
                className="rounded-2 btn btn-primary btn-sm px-4 py-2 border-0 fw-bold font-15"
                type="button"
                disabled={loading}
                onClick={() => {
                    startWork()
                }}>
                { loading ? 
                    t("Please wait...")
                    : t("Responded")
                }
            </button>
        </div>
    );
}