import React from 'react';
import { ChecklistView } from '../CorrectiveWorkorderDetails/ChecklistView';
import { Comments } from './Comments';
import { WorkorderStatusView } from './WorkorderStatusView';

export function ComplitionDetails({workOrder}:any) {

    const showChecklist = (workOrder?.issueCategory === 'Corrective' && (workOrder?.status === 'Completed' || workOrder?.status === 'Under observation'))

    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className="card">
                    <div className="card-body p-7">
                        <WorkorderStatusView workOrder={workOrder}/>
                        { showChecklist && <ChecklistView workOrder={workOrder} /> }
                        <Comments workOrder={workOrder} />
                    </div>
                </div>
            </div>
        </div>
    );
}