import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import { ImageViewerWithPopup } from '../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup'
import { formatDate } from '../../../../constants/generics/dateTimeFormater'
import { currencyFormater } from '../../../../constants/generics/customActios'

export function ChequeReplace({serviceDetail}:any) {
    const {t} = useTranslation()
    const [activeButton, setActiveButton] = useState<any>('old')
    const [chequeDetails, setChequeDetails] = useState<any>(
        {   
            chq_no: '-',
            bank: '-',
            acc_no: '-',
            acc_holder: '-',
            amount: '-',
            img: '',
            date: ''
        }
    )
    useEffect(()=>{
        if(serviceDetail?.chequeDetails?.[0]){
            if(activeButton === 'old')
                setChequeDetails(
                    {   
                        chq_no: serviceDetail.chequeDetails[0].old_cheque_number,
                        bank: serviceDetail.chequeDetails[0].old_bank,
                        acc_no: serviceDetail.chequeDetails[0].old_acc_number,
                        acc_holder: serviceDetail.chequeDetails[0].old_acc_holder_name,
                        amount: serviceDetail.chequeDetails[0].old_amount,
                        img: serviceDetail.chequeDetails[0].old_cheque_img,
                        date: serviceDetail.chequeDetails[0].old_cheque_date
                    }
                )
            else{
                setChequeDetails(
                    {
                        chq_no: serviceDetail.chequeDetails[0].new_cheque_number,
                        bank: serviceDetail.chequeDetails[0].new_bank,
                        acc_no: serviceDetail.chequeDetails[0].new_acc_number,
                        acc_holder: serviceDetail.chequeDetails[0].new_acc_holder_name,
                        amount: serviceDetail.chequeDetails[0].new_amount,
                        img: serviceDetail.chequeDetails[0].new_cheque_img,
                        date: serviceDetail.chequeDetails[0].new_cheque_date
                    }
                )
            }
        }
    }, [activeButton])

    return (
        <>
            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card mb-7 serv-data'>
                            <div className='card-body px-8 py-6'>
                                <div className='row mb-7'>
                                    <div className='col-sm-6 card-title font-16 text-black'>
                                        <span className='text-black'>{t("Cheque Replace Reason")}</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Type of Reason")} </h6>
                                        <span className='text-black fw-bold font-15'>
                                            {serviceDetail?.details?.reasonText ? serviceDetail?.details?.reasonText : '-'}
                                        </span>
                                    </div>
                                    <div className='col-8 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Description")} </h6>
                                        <span className='text-black fw-bold font-15'>
                                            {serviceDetail?.chequeDetails?.[0]?.desc ? serviceDetail.chequeDetails[0].desc : '-'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='card mb-3'>
                            <div className='card-body px-8 py-0'>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='d-flex overflow-auto h-55px'>
                                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                                
                                                <li className='nav-item'>
                                                    <a
                                                        onClick={() => {
                                                            setActiveButton('old');
                                                        }}
                                                        className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                            (activeButton === 'old' && 'active')}>
                                                        {t("Old Cheque")}
                                                    </a>
                                                </li>
                                                <li className='nav-item'>
                                                    <a
                                                        onClick={() => {
                                                            setActiveButton('new');
                                                        }}
                                                        className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                            (activeButton === 'new' && 'active')}>
                                                        {t("New Cheque")}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='card mb-3 serv-data'>
                            <div className='card-body px-8 py-6'>
                                <div className='row mb-7'>
                                    <div className='col-sm-6 card-title font-16 text-black'>
                                        <span className='text-black'>{t("Cheque Details")}</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Cheque Number")}</h6>
                                        <span className='text-black fw-bold font-15'> {chequeDetails.chq_no} </span>
                                    </div>
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Account Number")} </h6>
                                        <span className='text-black fw-bold font-15'>{chequeDetails.chq_no} </span>
                                    </div>
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'>{t("Account Holder Name")}</h6>
                                        <span className='text-black fw-bold font-15'>{chequeDetails.acc_holder} </span>
                                    </div>
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'>{t("Bank")} </h6>
                                        <span className='text-black fw-bold font-15'> {chequeDetails.bank} </span>
                                    </div>
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Amount")}</h6>
                                        <span className='text-black fw-bold font-15'> {t("AED")} {currencyFormater(chequeDetails.amount)} </span>
                                    </div>
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Cheque Date")} </h6>
                                        <span className='text-black fw-bold font-15'> {chequeDetails.date && chequeDetails.date!== '' ? formatDate(chequeDetails.date) : '-'} </span>
                                    </div>
                                    <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                        <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t("Cheque Image")} </h6>
                                        <ImageViewerWithPopup imageFile={chequeDetails.img} noImage={chequeDetails.img === ''} imgID={'cheque'}/>
                                        {/* <img src={chequeDetails.img} className='form-img__img-preview' /> */}
                                    </div>
                                    {chequeDetails.img !== '' && 
                                        <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                            <a href={chequeDetails.img}
                                                target='_blank'
                                                className='btn btn-primary fw-bold px-12 py-3'
                                                download
                                            >
                                                {t('Download Cheque')}
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}