import React, {useState, useEffect, useRef} from 'react'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import {Link, useHistory, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  addFacilityVisitor,
  getFmCommunityUser,
  getFacilityBuildingList,
  getMasterData,
  getFacilityVisitorsTypeList,
  getFacilityBuildingContractList,
} from '../../../../../constants/axios/apis'
import {getUserBuildingCommunityList} from '../../../../../utils/makeRequest'
import {RequestFailedPopup} from '../../../../SuccessfulPopup/RequestFailedPopup'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import IntlTelInput from 'react-intl-tel-input'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../constants'
import {CustomRequestSuccessfulPopup} from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import {PreventDatePickerInput} from '../../../../../constants/generics/dateTimeFormater'
import {CustomLoaderCompact} from '../../../../../constants/generics/customActios'
import {Select} from 'antd'
import { useTranslation } from 'react-i18next';

export function AddVisitor(props: any) {
  const today = new Date()
  // today.setHours(0, 0, 0, 0)

  const listBuilding: any = useSelector<RootState>(({FCDashboard}) => FCDashboard.listBuilding)
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const { t } = useTranslation();
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  // const [buildingList, setBuildingList] = useState<any>([])
  const [visitorTypeList, setVisitorTypeList] = useState<any>([])
  // const [selectedBuilding, setSelectedBuilding] = useState<any>()
  const [propertyVistsList, setPropertyVistsList] = useState<any>()
  const [selectedPropertyVistsList, setSelectedPropertyVistsList] = useState<any>()
  const [submitError, setSubmitError] = useState('')
  const buttonEl: any = useRef(null)

  const state: any = useSelector((state) => state)
  const userId = state?.auth?.user?.data?.id

  const [buildingList, setBuildingList] = useState<any>([])
  // const [buildingList, setBuildingList] = useState<any>([])
  const [buildingUnitList, setBuildingUnitList] = useState<any>([])
  const [unitListOptions, setUnitListOptions] = useState<any>([''])
  const [isLoading, setIsLoading] = useState<any>({
    building: false, // coming from RootState
    unit: true,
    location: true,
    categories: true,
  })

  let params: any = {}
  params = useLocation()
  const location: any = useLocation()
  let buildingInit: any
  if (params.state && params.state.building) {
    const {building} = params.state
    buildingInit = building
  }

  const initialValues = {
    isStaff: true,
    // building_id: '',
    building_id: currentBuilding ? currentBuilding : '',
    property_id: '',
    first_name: '',
    last_name: '',
    visit_purpose: '',
    company: '',
    emirates_id: '',
    mobile_no: '',
    email: '',
    date: today,
    time: moment(today).format('HH:mm'),
    visitor_type: '',
  }

  const addVisitorSchema = Yup.object().shape({
    isStaff: Yup.boolean(),
    first_name: Yup.string()
      .required('First Name is required!')
      .matches(/^[^\s].*/, 'First Name cannot start with a blankspace!')
      .min(3, 'Minimum 3 characters!')
      .max(30, 'Maximum 30 characters!')
      .matches(/^[A-Za-z ]*$/, 'Only alphabets allowed!'),
    last_name: Yup.string()
      .required('Last Name is required!')
      .matches(/^[^\s].*/, 'Last Name cannot start with a blankspace!')
      .min(3, 'Minimum 3 characters!')
      .max(30, 'Maximum 30 characters!')
      .matches(/^[A-Za-z ]*$/, 'Only alphabets allowed!'),
    visitor_type: Yup.string().required('Please select a visitor type!'),
    building_id: Yup.string().required('Please select a building!'),
    property_id: Yup.string().required('Please select a unit!'),
    visit_purpose: Yup.string().required('Please specify the visit purpose!'),
    company: Yup.string()
      .when('isStaff', { is: true, 
        then: Yup.string().required('Company Name is required!'),
      })
      .min(3, 'Minimum 3 characters! ')
      .max(30, 'Maximum 30 characters!')
      .matches(/^[^\s].*/, 'Company Name cannot start with a blankspace!'),
    emirates_id: Yup.string()
      .when('isStaff', { is: true, 
        then: Yup.string().required('Emirates ID is required!'),
      })
      .matches(/^[^\s].*/, 'Emirates ID cannot start with a blankspace!')
      .matches(/^(\d+-?)+\d+$/, 'Emirates Id contains only numbers and hypen!')
      .min(15, 'Minimum length is 15!')
      .max(25, 'Maximum 25 characters!'),
    mobile_no: Yup.string()
      .when('isStaff', { is: true, 
        then: Yup.string().required('Mobile No. is required!'),
      })
      .min(3, 'Mobile No. should be greater then 3 digits!')
      .max(15, 'Mobile No. should be smaller then 15 digits!')
      .matches(/^\+?[0-9]+$/, 'Invalid format, only + and numbers allowed!')
      .matches(/^[^\s].*/, 'Mobile No. cannot start with a blankspace!'),
    email: Yup.string()
      .when('isStaff', { is: true, 
        then: Yup.string().required('Email is required!'),
      })
      .email('Please enter vaild email format!')
      .matches(/^[^\s].*/, 'Email Id cannot start with a blankspace!'),
    date: Yup.date().required('Please select a date!'),
      // .min(today, 'Selected date cannot be in the past')
    time: Yup.string().required('Please select the time!'),
  })
 
  useEffect(() => {
    setBuildingList(listBuilding)
    // getFmCommunityUser('?user_id=' + userId).then(({ data }) => {
    // 	if (data && Array.isArray(data)) {
    // 		setBuildingList(data.map((item: any) => ({ id: `${item.building_id}`, name: item.building_name, communityId: `${item.community_id}` })))

    // 		// const communityList =  data.map((item: any) => ({ id: `${item.community_id}`, name: item.community }))
    // 		// const filteredCommulityList = communityList?.filter((value, index, self) => index === self.findIndex((el) => ( el.id === value.id )))
    // 		// setPropertyList(filteredCommulityList)
    // 	}
    // 	setIsLoading((oState: any) => ({ ...oState, building: false }))
    // }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, building: false })); })

    getUserBuildingCommunityList(userId)
      .then(({buildingListData, communityListData}) => {
        setBuildingList(buildingListData)
        setIsLoading((oState: any) => ({...oState, building: false}))
      })
      .catch((e) => {
        setIsLoading({...isLoading, building: false})
      })

    getFacilityBuildingList()
    // .then(({data}) => {
    //   setBuildingUnitList(
    //     data && Array.isArray(data)
    //       ? data.map((item: any) => ({buildingId: item.id, unitList: item.property}))
    //       : []
    //   )
    //   setIsLoading((oState: any) => ({...oState, unit: false}))
    // })
    // .catch((e) => {
    //   setIsLoading((oState: any) => ({...oState, unit: false}))
    // })
    getFacilityBuildingList()
      .then(({data}) => {
        setBuildingUnitList(
          data && Array.isArray(data)
            ? data.map((item: any) => ({buildingId: `${item.id}`, unitList: item.property}))
            : []
        )
        setIsLoading((oState: any) => ({...oState, unit: false}))
      })
      .catch((e) => {
        setIsLoading((oState: any) => ({...oState, unit: false}))
      })
  }, [listBuilding])

  useEffect(() => {
    // getFacilityBuildingList().then(({ data }) => {
    // 	setBuildingList(data)
    // })
    getFacilityVisitorsTypeList().then(({data}) => {
      setVisitorTypeList(data)
    })
  }, [])

  useEffect(() => {
    getMasterData().then(({data}) => {
      const porposeList = data.find((el: any) => el.category_name === 'purpose_of_visit')
      setPropertyVistsList(porposeList?.master_data)
    })
  }, [])

  const handleSelect = (e: any) => {
    formik.handleChange(e)
    const purposeSelectedList = propertyVistsList.find(
      (item: any) => item.category_name === 'purpose_of_visit'
    )
    setSelectedPropertyVistsList(purposeSelectedList)
  }
  const handleBuildingSelect = (val: any) => {
    // formik.handleChange(e)
    formik.setFieldValue('building_id', val)
    formik.setFieldValue('property_id', '')
    formik.setFieldTouched('property_id', false)
  }

  const {Option} = Select

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: addVisitorSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setSubmitError('')
      const check_in = moment(values.date).format('YYYY-MM-DD') + ' ' + values.time
      setTimeout(() => {
        addFacilityVisitor(
          values.building_id,
          values.property_id,
          values.first_name,
          values.last_name,
          values.visit_purpose,
          values.company,
          values.emirates_id,
          values.mobile_no,
          values.email,
          values.visitor_type,
          check_in
        )
          .then((response: any) => {
            setLoading(false)

            if (response.errorCode === 1) setSubmitError(response.errorDescription)
            else if (response.errorCode === 0) {
              formik.resetForm()
              setLoading(false)
              var ev = new Event('click', {bubbles: true})
              buttonEl.current.dispatchEvent(ev)
            } else setSubmitError(t('Some error occured. Please try again !'))
          })
          // 	if (response.errorCode === 1) {
          // 		setMessage(response.errorDescription)
          // 		failRef.current.click()
          // 	} else {
          // 		resetForm()
          // 		successRef.current.click()
          // 		history.push('/visitors/all', {})
          // 	}
          // })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t('Invalid Data Provided'))
          })
      }, 1000)
    },
  })
  useEffect(()=>{
    formik.setFieldValue('isStaff', formik.values.visitor_type == '149' ? true : false)
  },[formik.values.visitor_type])

  useEffect(() => {
    const selectedBuildingId = formik.values.building_id
    const selectedBuildingUnits =
      selectedBuildingId && selectedBuildingId !== ''
        ? buildingUnitList?.find((el: any) => el.buildingId === selectedBuildingId)
        : []
    setUnitListOptions(
      selectedBuildingUnits?.unitList?.length ? selectedBuildingUnits.unitList : []
    )
    if (buildingInit && selectedBuildingId == buildingInit.building_id) {
      formik.setFieldValue('property_id', buildingInit.property_id)
    } else {
      formik.setFieldValue('property_id', '')
    }
  }, [formik.values.building_id, buildingUnitList])

  const isError = buildingList?.length === 0 ? (t('No building data found.')) : ''

  const isLoadingData = isLoading.unit

  const buildingOptions = buildingList?.map((building: any) => (
    <option key={building.id} value={building.id}>
      {building.name}
    </option>
  ))

  const visitorTypeOptions = visitorTypeList?.map((visitorTypeList: any) => (
    <option key={visitorTypeList.id} value={visitorTypeList.id}>
      {visitorTypeList.master_data_name}
    </option>
  ))

  // const propertyOptions = selectedBuilding?.property?.map((property: any) => (
  //   <option key={property.id} value={property.id}>
  //     {property.property_code}
  //   </option>
  // ))

  // useEffect(() => {
  //   getMasterData().then(({data}) => {
  //     const varl = data?.find((el: any) => el.category_name === 'purpose_of_visit')
  //     setPropertyVistsList(varl?.master_data)
  //   })
  // }, [])

  const propertyVistsOptions = propertyVistsList?.map((propertyVists: any) => (
    <option key={propertyVists.value} value={propertyVists.value}>
      {propertyVists.name}
    </option>
  ))

  const handleDate = (e: any) => {
    formik.setFieldValue('date', new Date(e))
  }

  const [selected, setSelected] = useState('')
  const [value, setValue] = useState<any>()
  const onchangeNo = (e: any) => {
    setValue(e.target.value)
  }

  const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
    formik.setFieldValue('mobile_no', phoneNumber)
    formik.setFieldTouched('mobile_no')
  }

  const [dateValue, setDateValue] = useState<any>()
  const handleDateChange = (e: any) => {
    setDateValue(e)
    var menDate = e ? moment(e).format('YYYY-MM-DD') : ''
    formik.setFieldValue('date', menDate)
  }

  PreventDatePickerInput()

  return (
    <div>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/visitors/all'} className=''>
                <img src={LeftArrow} className='img-fluid me-5' alt='Back' />
              </Link>
              <span className='text-black'>{t("Add Visitor")}</span>
            </h4>
          </div>
          <div className='col-12'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='visitorForm'
              onSubmit={formik.handleSubmit}
            >
              <div className='card mb-8'>
                <div className='card-body p-5 px-md-8 py-md-8'>
                  {isLoadingData ? (
                    <div className='col-12 d-flex flex-column justify-content-center mt-5'>
                      <CustomLoaderCompact />
                    </div>
                  ) : (
                    isError !== '' && (
                      <div className='col-12 mt-5'>
                        <div className='alert alert-danger mt-2 text-center w-100' role='alert'>
                          {isError}
                        </div>
                      </div>
                    )
                  )}
                  <div className='row form-add-design'>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>
                          {t("First Name")}
                          <small className='text-danger'>* </small>
                        </label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          {...formik.getFieldProps('first_name')}
                          placeholder={t('First Name')}
                        />
                        {formik.touched.first_name && formik.errors.first_name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.first_name}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>{t("Last Name")}<small className='text-danger'>* </small> </label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          {...formik.getFieldProps('last_name')}
                          placeholder={t('Last Name')}
                        />
                        {formik.touched.last_name && formik.errors.last_name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.last_name}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>
                          {t("Visitor Type")} <small className='text-danger'>* </small>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select Visitor Type')}
                          optionFilterProp='children'
                          value={
                            formik.values.visitor_type !== '' ? formik.values.visitor_type : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue('visitor_type', val)
                          }}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // className='form-control form-select fw-bolder form-control-solid'
                          // data-control='select2'
                          // data-allow-clear='true'
                          // name='visitor_type'
                          // onChange={handleSelect}
                          // value={formik.values.visitor_type}
                          // onBlur={formik.handleBlur}
                        >
                          {/* <option value='' disabled>
                            Please Select
                          </option>
                          {visitorTypeList ? visitorTypeOptions : ''} */}
                          {visitorTypeList?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.master_data_name}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.visitor_type && formik.errors.visitor_type && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.visitor_type}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>
                          {t("Building Name")} <small className='text-danger'>* </small>{' '}
                        </label>
                        <Select
                          // {...formik.getFieldProps('building_id')}
                          // className='form-control form-select fw-bolder form-control-solid'
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select a building')}
                          optionFilterProp='children'
                          defaultValue={
                            formik.values.building_id !== '' ? formik.values.building_id : null
                          }
                          onChange={handleBuildingSelect}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {/* <option value=''>Select</option>
                          {buildingList?.map((el: any, index: number) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            )
                          })} */}
                          {buildingList?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.building_id && formik.errors.building_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.building_id}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>
                          {t("Unit Number")} <small className='text-danger'>* </small>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select a Unit')}
                          optionFilterProp='children'
                          value={
                            formik.values.property_id !== '' ? formik.values.property_id : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue('property_id', val)
                          }}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // className='form-control form-select fw-bolder form-control-solid'
                          // data-control='select2'
                          // data-allow-clear='true'
                          // name='property_id'
                          // onChange={formik.handleChange}
                          // value={formik.values.property_id}
                          // onBlur={formik.handleBlur}
                        >
                          {/* <option value='' disabled>
                            Please Select
                          </option> */}
                          {unitListOptions?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.property_code}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.property_id && formik.errors.property_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.property_id}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>
                          {t("Purpose of Visit")} <small className='text-danger'>* </small>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select Purpose of Visit')}
                          optionFilterProp='children'
                          value={
                            formik.values.visit_purpose !== '' ? formik.values.visit_purpose : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue('visit_purpose', val)
                          }}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {propertyVistsList?.map((el: any, index: number) => (
                            <Option key={index} value={el.value}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.visit_purpose && formik.errors.visit_purpose && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.visit_purpose}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>
                          {t("Company Name")}
                          { formik.values.isStaff &&
                            <small className='text-danger'> * </small>
                          }
                        </label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          {...formik.getFieldProps('company')}
                          placeholder={t('Company Name')}
                        />
                        {formik.touched.company && formik.errors.company && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.company}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-7'>
                        <label className='form-label fw-bolder'>
                          {t("Emirates Id")}
                          { formik.values.isStaff &&
                            <small className='text-danger'> * </small>
                          }
                        </label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          {...formik.getFieldProps('emirates_id')}
                          placeholder={t('Emirates Id')}
                        />
                        {formik.touched.emirates_id && formik.errors.emirates_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.emirates_id}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-0'>
                        <label className='form-label fw-bolder'>
                          {t("Mobile No.")}
                          { formik.values.isStaff &&
                            <small className='text-danger'> * </small>
                          }
                        </label>
                        <IntlTelInput
                          preferredCountries={['ae', 'in', 'us']}
                          containerClassName='intl-tel-input w-100'
                          separateDialCode={true}
                          fieldName='mobile_no'
                          value={formik.values.mobile_no}
                          inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                          onPhoneNumberChange={handlePhoneNumber}
                          placeholder={t('Mobile No.')}
                        />
                        {formik.touched.mobile_no && formik.errors.mobile_no ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.mobile_no}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-5 mb-md-0'>
                        <label className='form-label fw-bolder'>
                          {t("Email Id")}
                          { formik.values.isStaff &&
                            <small className='text-danger'> * </small>
                          }
                        </label>
                        <input
                          type='email'
                          className='form-control fw-bolder form-control-solid'
                          {...formik.getFieldProps('email')}
                          placeholder={t('Email Id')}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.email}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='date-picker'>
                        <label className='form-label fw-bolder'>
                          {t("Check-In Date")} <small className='text-danger'>* </small>{' '}
                        </label>
                        <DatePicker
                          onChange={(e: any) => handleDate(e)}
                          value={formik.values.date}
                          className='form-control fw-bolder form-control-solid'
                          format='dd-MM-y'
                          clearIcon={null}
                          // maxDate={new Date(new Date().toLocaleDateString())}
                        />
                        {formik.touched.date && formik.errors.date ? (
                          <div className='d-flex flex-column text-danger'>
                            <small className='text-danger'>{formik.errors.date}</small>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className=''>
                        <label className='form-label fw-bolder'>
                          {t("Check-In Time")} <small className='text-danger'>* </small>
                        </label>
                        <input
                          className='form-control fw-bolder form-control-solid'
                          type='time'
                          // step={60}
                          // value={moment(formik.values.time,'HH:mm  A').format('HH:mm')}
                          {...formik.getFieldProps('time')}
                        />
                        {formik.touched.time && formik.errors.time ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.time}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='col-12 text-end'>
            <Link to={'/visitors/all'} className=''>
              <button className='btn btn-outline-primary bg-white fw-bold px-10 py-3 me-5'>
                {t("Cancel")}
              </button>
            </Link>
            <button
              type='submit'
              className='btn btn-primary fw-bold px-10 py-3'
              form='visitorForm'
              disabled={loading}
            >
              {!loading && <span className='indicator-label'>{t("Create")}</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {t("Please wait...")}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {submitError && (
            <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
              {submitError}
            </div>
          )}
          <p
            ref={buttonEl}
            data-bs-target='#req-submitted-popup'
            data-bs-toggle='modal'
            data-bs-dismiss='modal'
            className='invisible d-none btn btn-primary fw-bold px-10 py-3'
          />
          <CustomRequestSuccessfulPopup
            successMessage={t('Visitors Added Successfully')}
            href={'/visitors/all'}
          />
        </div>
      </div>
    </div>
  )
}
