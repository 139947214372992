import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import PlusIcon from '../../../../../assets/images/plus-circle-icon.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {getFacilityDocumentList} from '../../../../../constants/axios/apis'
import Cheque from '../../../../../assets/images/cheque_1.png'
import { CustomLoader2, NoDataFound1 } from '../../../../../constants/generics/customActios'
import { useTranslation } from 'react-i18next';

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
      },
    },
  ],
}

export function DocumentTabs({searchItem, setSearchItem}: any) {
  const location = useLocation()
  const [documentList, setDocumentList] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation();

  useEffect(() => {
    getFacilityDocumentList().then(({data}) => {
      setDocumentList(data)
      setLoading(false)
		}).catch((e)=>setLoading(false))
  }, [])

  let content = documentList?.map((documentTypeList: any) => (
    <div key={documentTypeList?.id}>
      <Link
        to={{pathname: '/fc-ss-documents/add', state: {quickLinkCategory: documentTypeList}}}
        className='text-decoration-none'
      >
        <div className='card'>
          <div className='card-body py-7 px-5'>
            <div className='row g-0 align-items-center'>
              <div className='col-4'>
                <img
                  src={documentTypeList?.icon ? documentTypeList?.icon : Cheque}
                  className='img-fluid'
                />
              </div>
              <div className='col-8'>
                <h6 className='card-title mb-0'>{documentTypeList?.master_data_name}</h6>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  ))
  // )

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <h4 className='card-title mb-4 font-18 text-black'> {t("Documents")} </h4>
            </div>
            <div className='col-md-6 text-end'>
              <Link to={'/fc-ss-documents/add'} className='btn btn-primary py-2'>
                <img src={PlusIcon} className='img-fluid me-2' alt='Plus' />
                {t("Add")}
              </Link>
            </div>
          </div>
          <div className='row'>
				{loading && (
					<CustomLoader2 height={'166'}/>
				)}
				{!loading && (
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body ps-6 pt-0 pb-3 pe-0'>
                <div className='row'>
                  {/* <div className='col-md-6 col-6'>
                    <h4 className='card-title'> New Request </h4>
                  </div> */}
                </div>
                <div className='quick-link-sec'>
                  <div>
                    <div className='row'>
                      <div className='col-12'>
                      {content?.length ? 
                        <Slider {...settings}>{documentList ? content : ''}</Slider>
                        :
                        <NoDataFound1 height={'110'} />
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
          <div className='card mb-3'>
            <div className='card-body px-4 py-2'>
              <div className='row'>
                <div className='col-md-6 col-12'>
                  <div className='d-flex'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/fc-ss-documents/open-documents/cheques'
                              ? 'active'
                              : location.pathname ===
                                '/fc-ss-documents/open-documents/renewal-notices'
                              ? 'active'
                              : location.pathname === '/fc-ss-documents/open-documents/contracts'
                              ? 'active'
                              : '')
                          }
                          to='/fc-ss-documents/open-documents/cheques'
                        >
                          {t("Open Documents")}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/fc-ss-documents/sent-documents/cheques'
                              ? 'active'
                              : location.pathname ===
                                '/fc-ss-documents/sent-documents/renewal-notices'
                              ? 'active'
                              : location.pathname === '/fc-ss-documents/sent-documents/contracts'
                              ? 'active'
                              : '')
                          }
                          to='/fc-ss-documents/sent-documents/cheques'
                        >
                          {t("Collected Documents")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                  <div className='form-group has-search'>
                    <span className='fa fa-search pt-2 form-control-feedback'></span>
                    <input
                      type='text'
                      className='form-control ps-10'
                      onChange={(e) => setSearchItem(e.target.value)}
                      placeholder={t('Search')}
                    />
                  </div>
                </div>
              </div>
                <div className='col-md-6 col-12 my-auto text-end'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
