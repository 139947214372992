import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ApprovalsTabs: React.FC = () => {

    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        {t("Approvals")}
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-7 py-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex h-55px overflow-x'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals/open-requests' && 'active')}
                                                    to='/pm-approvals/open-requests'>
                                                    {t("Open Requests")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals/resolved-requests' && 'active')}
                                                    to='/pm-approvals/resolved-requests'>
                                                    {t("Resolved Requests")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {ApprovalsTabs}
