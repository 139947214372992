import React from 'react';
import CheckGreen from '../../../../../../../../assets/images/prev-icon.svg';

export function StepSuccessful() {

    return (
        <div className="row pb-20 pt-15 step-one">
            <div className="col-md-12 custom-modal">
                <div className="text-center mx-md-12">
                    <div className="redeem-offer-QR">
                        <img src={CheckGreen} alt="tick-img" className="img-fluid mb-5" />
                        <h1 className="text-black font-26 mb-2 fw-normal">Done!</h1>
                        <p className="mb-4 font-12 fw-normal text-successful mx-20 px-15 ">
                            Request created successfully
                        </p>
                        <button data-bs-dismiss="modal" className="btn btn-primary btn-lg fw-normal font-15 px-20 py-3">
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}