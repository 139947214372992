import React, { useState, useEffect, useRef } from 'react';
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import {Link} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'

export function ParkingDetails() {

    const sigCanvas = useRef<any>();
    function clear() {
        sigCanvas.current.clear();
    }

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <Link to={'/parking/all'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            <span className="text-black">
                                Vehicle Details
                            </span>
                        </Link>
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row form-add-design">
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Brand </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option>Please Select</option>
                                            <option selected> Brand 1 </option>
                                            <option> Brand 2 </option>
                                            <option> Brand 3 </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Model </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option>Please Select</option>
                                            <option selected> Model 1 </option>
                                            <option> Model 2 </option>
                                            <option> Model 3 </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Color </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option>Please Select</option>
                                            <option selected> Black </option>
                                            <option> White </option>
                                            <option> Red </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Registered Plate Number </label>
                                        <input type="text" value="87395" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> Customer Signature </label>
                                                <div className="w-100 form-control">
                                                    <SignatureCanvas
                                                        ref={sigCanvas}
                                                        penColor='black'
                                                        canvasProps={{height: 60, width: 320, className: 'sigCanvas'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 my-auto col-12">
                                            <div className="mt-7">
                                                <button className='btn btn-light fw-bold px-10 py-2' onClick={clear}>
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12 my-auto">
                                    <div className="mb-1">
                                        <div className="form-check custom">
                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked />
                                            <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault">
                                                I Accept the terms and conditions
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-end mt-5">
                    <button className='btn btn-outline-primary bg-white fw-bold px-10 py-3 me-5'>
                        Cancel
                    </button>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}