import React from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom'
import LeftArrow from "../../../../../../assets/images/Left_Arrow.png";
import NoImage from "../../../../../../assets/images/House.png";
import { ImageViewerWithPopup } from '../../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';
import { useTranslation } from 'react-i18next';

export const ViewInspectionItem:React.FC<any> = ({}) => {
    const history = useHistory();
    let params: any = {};
    params = useLocation(); 
    const { clearanceDetails } = params.state;

    const handleCancel = () => {
        history.goBack();
    }
    const { t } = useTranslation();

    return (
        <>
            <div className="col-12">
                <div className="row mb-6 align-items-center">
                    <div className="col-sm-6 col-8">
                        <h4 className="card-title font-18 text-black mb-0">
                            <span className='c-pointer' onClick={handleCancel}>
                                <img src={LeftArrow} alt="Arrow Left" className="img-fluid me-5 left-arrow-back" />
                            </span>
                            {clearanceDetails.category}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card mb-7">
                    <div className="card-body p-7">
                            <div className="row form-add-design">
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className='form-label fw-bolder'>{t("Select Category")} </label>
                                        <input
                                            type="text"
                                            className="form-control fw-bolder form-control-solid" value={clearanceDetails.category} readOnly/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className='form-label fw-bolder'>{t("Select Room")} </label>
                                        <input
                                            type="text"
                                            className="form-control fw-bolder form-control-solid" value={clearanceDetails.room} readOnly/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder"> {t("Required Quantity")} </label>
                                        <input
                                            type="text"
                                            className="form-control fw-bolder form-control-solid" value={clearanceDetails.quantity} readOnly/>
                                    </div>
                                </div>
                                
                                <div className="col-md-4 col-12">
                                    <div className="mb-7 upload-input">
                                        <label className="form-label fw-bolder d-block mb-6">{t("Attached Image")}</label>
                                        <ImageViewerWithPopup imageFile={clearanceDetails.image} noImage={!clearanceDetails.image ? true: false }/>
                                        {/* <div className="position-relative">
                                            <div className="upload-image mt-0">
                                                { clearanceDetails.image ? 
                                                    <img src={clearanceDetails.image ? clearanceDetails.image : NoImage } alt="Preview" className="form-img__img-preview" />
                                                    : 'No image'
                                                }
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7 upload-input">
                                        <label className="form-label fw-bolder d-block mb-6">{t("Customer Signature")}</label>
                                        <div className="position-relative">
                                            <div className="upload-image mt-0">
                                                <img src={clearanceDetails.customer_signature} alt="Preview" className="form-img__img-preview w-100 border rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Description")} </label>
                                        <textarea
                                            className='form-control fw-bolder form-control-solid'
                                            data-kt-autosize='true'
                                            rows={3}
                                            value={clearanceDetails.description}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}