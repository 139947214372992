import FullCalendar from '@fullcalendar/react'
import React, {FC, useEffect, useRef, useState} from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import {useTranslation} from 'react-i18next'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export const CalenderCard: React.FC<any> = ({eventState, setSelectedDate}) => {
  const {t} = useTranslation()
  const handleDateClick = (arg: any) => {
    setSelectedDate(arg?.date)
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-6 calender-card-sec'>
            <div className='card-body px-8 py-10'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='full-Calendar'>
                      <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                          left: 'prev,next today',
                          center: 'title',
                          right: 'dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        initialView='dayGridMonth'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        events={eventState} // alternatively, use the `events` setting to fetch from a feed
                        dateClick={handleDateClick}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='row pb-13 pt-10'>
                    <div className='col-12'>
                      <div className='calendar_div_2 flex-wrap'>
                        <div className='corrective me-8'>
                          <div className='box red me-4'></div>
                          <p className='mb-0'> {t('Services')}</p>
                        </div>
                        <div className='corrective me-8'>
                          <div className='box blue me-4'></div>
                          <p className='mb-0'> {t('Refunds')}</p>
                        </div>
                        <div className='corrective me-8'>
                          <div className='box green me-4'></div>
                          <p className='mb-0'> {t('Move-out Requests')}</p>
                        </div>
                        <div className='corrective me-8'>
                          <div className='box purple me-4'></div>
                          <p className='mb-0'> {t('Invoices')}</p>
                        </div>
                        <div className='corrective me-8'>
                          <div className='box sky-blue me-4'></div>
                          <p className='mb-0'> {t('Lease Details')}</p>
                        </div>
                        <div className='corrective'>
                          <div className='box orange me-4'></div>
                          <p className='mb-0'> {t('Payments')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
