import React, {FC, useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import { PageTitle } from '../../components/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom';
import { FacilityMaintenanceView } from './FacilityMaintenanceView/FacilityMaintenanceView';
import { FacilityMaintenanceAdd } from './FacilityMaintenanceAdd/FacilityMaintenanceAdd';
import { FacilityMaintenanceListWrapper } from './FacilityMaintenanceList/FacilityMaintenanceListWrapper';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';


const FacilityMaintenanceRoutes: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/facility-maintenance/list'>
                            <FacilityMaintenanceListWrapper />
                        </Route>
                        <Route path='/facility-maintenance/add'>
                            <FacilityMaintenanceAdd setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/facility-maintenance/view'>
                            <FacilityMaintenanceView />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
            <CustomRequestSuccessfulPopup 
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    );
}
const FacilityMaintenanceWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FacilityMaintenanceRoutes />
        </>
    )
};

export {FacilityMaintenanceWrapper}
