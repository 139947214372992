import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PersonalInformation} from './/components/PersonalInformation/PersonalInformation';


const MyProfilePage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
            <PersonalInformation />
        </div>
    </div>
    </>
);

const MyProfileWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}> Personal Information </PageTitle>
        <MyProfilePage />
        </>
    )
};

export {MyProfileWrapper}
