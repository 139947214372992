import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

export function ScheduledVisitTabs({searchItem, setSearchItem}: any) {
  const location = useLocation()
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-md-12'>
              <h4 className='card-title mb-4 font-18 text-black'> {t("Scheduled Visit")}</h4>
            </div>
          </div>
          <div className='card mb-3'>
            <div className='card-body px-4 py-0'>
              <div className='row access-cd-tabs'>
                <div className='col-md-8 col-12'>
                  <div className='d-flex py-2'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/scheduled-visit/all' && 'active')
                          }
                          to='/scheduled-visit/all'
                        >
                          {t('All')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/scheduled-visit/upcoming' && 'active')
                          }
                          to='/scheduled-visit/upcoming'
                        >
                          {t("Upcoming")}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/scheduled-visit/ongoing' && 'active')
                          }
                          to='/scheduled-visit/ongoing'
                        >
                          {t("Ongoing")}
                        </Link>
                      </li>
                      {/* <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (location.pathname === '/scheduled-visit/rescheduled' && 'active')}
                                                    to='/scheduled-visit/rescheduled'>
                                                    Re-Scheduled
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (location.pathname === '/scheduled-visit/expired' && 'active')}
                                                    to='/scheduled-visit/expired'>
                                                    Expired
                                                </Link>
                                            </li> */}
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/scheduled-visit/completed' && 'active')
                          }
                          to='/scheduled-visit/completed'
                        >
                          {t("Completed")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-4 col-12 my-md-auto my-4'>
                  <div className='d-flex pm-topbar-buttons float-end'>
                    <div className='form-group has-search'>
                      <span className='fa fa-search pt-2 form-control-feedback'></span>
                      <input
                        type='text'
                        className='form-control ps-10'
                        onChange={(e) => setSearchItem(e.target.value)}
                        placeholder={t('Search')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
