import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { UserList } from './user-components/UserList'
import { AddUser } from './user-components/AddUser'
import { EditUser } from './user-components/EditUser'

const PMSettings: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/admin-users/list'>
                            <UserList />
                        </Route>
                        <Route path='/admin-users/add'>
                            <AddUser />
                        </Route>
                        <Route path='/admin-users/edit'>
                            <EditUser />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMUserWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMSettings />
        </>
    )
};

export { PMUserWrapper }

