import React from 'react';

export function StepOne() {
    return (
        <div className="row py-6 step-one">
            <div className="col-md-12">
                <div className="row form-add-design">
                    <div className="col-md-12 col-12">
                        <div className="mb-6">
                            <label className="form-label fw-bolder text-black mb-3"> Ad account </label>
                            <select className="form-control form-select fw-bolder form-control-solid">
                                <option selected disabled>Select</option>
                                <option>Facebook</option>
                                <option>Google Ads</option>
                                <option>Linked In</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="mb-6 position-relative">
                            <label className="form-label fw-bolder text-black mb-3"> Facebook page </label>
                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="mb-6">
                            <label className="form-label fw-bolder text-black d-block mb-3"> Campaign </label>
                            <div className="form-check form-check-inline custom-radio">
                                <input className="form-check-input" type="radio" name="campaign" id="inlineRadio1" />
                                <label className="form-check-label text-black fw-bolder font-12" htmlFor="inlineRadio1">
                                    Create new
                                </label>
                            </div>
                            <div className="form-check form-check-inline custom-radio mb-4">
                                <input className="form-check-input" type="radio" name="campaign" id="inlineRadio2" />
                                <label className="form-check-label text-black fw-bolder font-12" htmlFor="inlineRadio2">
                                    Select from existing
                                </label>
                            </div>
                            <input type="date" className="form-control fw-bolder form-control-solid" placeholder="" />
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="mb-4">
                            <label className="form-label fw-bolder text-black d-block mb-3"> Ad creative </label>
                            <div className="form-check form-check-inline custom-radio">
                                <input className="form-check-input" type="radio" name="creative" id="inlineRadio3" />
                                <label className="form-check-label text-black fw-bolder font-12" htmlFor="inlineRadio3">
                                    Use existing post
                                </label>
                            </div>
                            <div className="form-check form-check-inline custom-radio mb-4">
                                <input className="form-check-input" type="radio" name="creative" id="inlineRadio4" />
                                <label className="form-check-label text-black fw-bolder font-12" htmlFor="inlineRadio4">
                                    Create a new ad
                                </label>
                            </div>
                            <input type="date" className="form-control fw-bolder form-control-solid" placeholder="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}