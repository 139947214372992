import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import HomeSmall from '../../../../../assets/images/my-unit-sm.png'
import HomePayment from '../../../../../assets/images/home-white.png'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../../constants/generics/dateTimeFormater'
import { currencyFormater } from '../../../../../constants/generics/customActios'
import { financeSendAlertNotification } from '../../../../../constants/axios/apis'
import { CustomRequestSuccessfulPopup } from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'

export function SendAlert(props: any) {
    const { t } = useTranslation()
    const buttonEl: any = useRef(null)
    const [submitting, setSubmitting] = useState<any>({
        loading: false,
        error: false,
        message: '',
        isSubmitted: false
    })

    useEffect(() => {
        if (submitting.error)
            setTimeout(() => { setSubmitting((oState: any) => ({ ...oState, error: false })) }, 5000);
    }, [submitting.error])

    const sendAlertNotification = () => {
        const newPayload = {
            payment_id: props.id,
            user_id: props.userId,
        }
        setSubmitting({ loading: true, error: false, message: '', isSubmitted: false })

        financeSendAlertNotification(newPayload)
            .then((response: any) => {
                if (response.errorCode === 1) {
                    setSubmitting({ loading: false, error: true, message: response.errorDescription, isSubmitted: false })
                } else if (response.errorCode === 0) {
                    setSubmitting({ loading: false, error: false, message: response?.description, isSubmitted: true })

                    var ev = new Event('click', { bubbles: true })
                    buttonEl.current.dispatchEvent(ev)
                } else {
                    setSubmitting({ loading: false, error: true, message: t('Some error occured. Please try again !'), isSubmitted: false })
                }
            })
            .catch((e) => {
                setSubmitting({ loading: false, error: true, message: t('Some error occured. Please try again !'), isSubmitted: false })
            })
    }


    return (
        <>
            <div className='col-12 mb-6'>
                <div className='pay-alert-box rounded py-3 ps-3 pe-8'>
                    <div className='row align-items-center'>
                        <div className='col-sm-6 col-md-2 col-lg-2 col-xl-1 col-xxl-1'>
                            <div className='bg-gray py-4 px-2'>
                                <img src={HomeSmall} className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-3 col-lg-2 col-xl-2 col-xxl-2'>
                            <div className='ps-xxl-5 ps-xl-5'>
                                <h6 className='font-12 fw-bold text-black mb-4'>{t('Type of Payment')}</h6>
                                <h5 className='font-14 fw-boldest text-black mb-0'>
                                    {props?.type ? props.type : '-'}
                                </h5>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                            {props?.due_date &&
                                <div className='ps-xxl-5 ps-xl-5'>
                                    <h6 className='font-12 fw-bold text-black mb-4'>{t('Due Date')}</h6>
                                    <h5 className='font-14 fw-boldest text-black mb-0'>
                                        {formatDate(props?.due_date)}
                                    </h5>
                                </div>
                            }
                        </div>
                        <div className='col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                            <h6 className='font-12 fw-bold text-black mb-4'>{t('Amount')}</h6>
                            <h5 className='font-14 fw-b dest text-black mb-0'>
                                {t('AED')}{' '}
                                {props?.amount ? currencyFormater(props?.amount) : '-'}
                            </h5>
                        </div>
                        <div className='col-md-3 col-lg-4 col-xl-5 col-xxl-5 text-end'>
                            {submitting.loading ? (
                                <button type='button' className='btn btn-primary fw-bold px-10 py-3' disabled>
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        {t('Please wait...')}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </button>
                            ) : (
                                <>
                                    <button
                                        type='submit'
                                        onClick={() => sendAlertNotification()}
                                        disabled={submitting.isSubmitted}
                                        className='btn btn-primary font-14 fw-bold px-8 px-lg-15  py-3'
                                    >
                                        <span className='indicator-label'>{t('Send Alert')}</span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {submitting.error && (
                    <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                        {submitting.message}
                    </div>
                )}
                <p
                    ref={buttonEl}
                    data-bs-target='#req-submitted-popup-send-alert'
                    data-bs-toggle='modal'
                    data-bs-dismiss='modal'
                    className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                />
                <CustomRequestSuccessfulPopup successMessage={submitting.message} customID='-send-alert'/>
            </div>

        </>
    )
}
