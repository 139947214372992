import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import QuickLinkOne from "../../../../assets/images/my-services-quick-link.png";
import QuickLinkTwo from "../../../../assets/images/refund-quick-link.png";
import QuickLinkThree from "../../../../assets/images/move-out-req-quick-link.png";
import QuickLinkFour from "../../../../assets/images/receipt-quick-link.png";
import QuickLinkFive from "../../../../assets/images/lease-deatils-quick-link.png";
import QuickLinkSix from "../../../../assets/images/payments-quick-link.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CalanderIcon from "../../../../assets/images/calendar-quick-link.png";

export function QuickLinks() {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h4 className="card-title"> {t("Quick Links")} </h4>
                                </div>
                                {/*<div className="col-md-6 col-6 text-right">*/}
                                    {/*<Link to={''} className="text-decoration-none view-all"> */}
                                        {/*<span> View All </span>*/}
                                        {/*<img src={RightArrow} alt="Right Arrow" className="img-fluid" /> */}
                                    {/*</Link>*/}
                                {/*</div>*/}
                            </div>
                            <div className="quick-link-sec">
                                <div>
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <Slider {...settings}>
                                                <div>
                                                    <Link to={'/finance-services-request/list/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={QuickLinkOne} alt="Services" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Services")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/finance-services-request-refunds/list/open-requests-list'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={QuickLinkTwo} alt="Refunds" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Refunds")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/finance-moveout-requests/list/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={QuickLinkThree} alt="MoveOut Requests" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0">
                                                                            {t("Move-out Requests")}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/finance-services-receipt/list/day-list'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={QuickLinkFour} alt="Invoices" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Invoices")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/finance-services-lease/list/day-list'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={QuickLinkFive} alt="Lease Details" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Lease Details")}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/finance-payments/list/all/creditCard-payment'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={CalanderIcon} alt="Payments" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Payments")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/finance-reports/month'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={QuickLinkSix} alt="Report" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Report")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/finance-calendar'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body px-6 py-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={QuickLinkSix} alt="Calendar" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Calendar")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}