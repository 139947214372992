import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export function TeamTabs({searchItem, setSearchItem}: any) {
  const location = useLocation()
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='row  mb-7'>
            <div className='col-md-6 col-6'>
              <h4 className='card-title font-18 text-black mb-0'> {t("My Team")} </h4>
            </div>

            <div className='col-md-6 col-6 text-end'>
              {/* <Link
                                to={{ 'pathname': '/fmsm-my-team/add/fm/team', state: { 'addRole': 'FMT' } }}
                                className="btn btn-primary btn-sm font-16 me-5">
                                <i className="bi bi-plus-circle-fill align-middle"></i> Add Team
                            </Link> */}
              <div className='d-inline-flex pm-topbar-buttons mx-4'>
                <div className='form-group has-search'>
                  <span className='fa fa-search pt-2 form-control-feedback'></span>
                  <input
                    type='text'
                    className='form-control ps-10'
                    onChange={(e) => setSearchItem(e.target.value)}
                    placeholder={t('Search')}
                  />
                </div>
              </div>
              <Link
                to={{pathname: '/fmsm-my-team/add/fcs/supervisor', state: {addRole: 'FCSS'}}}
                className='btn btn-primary btn-sm font-16'
              >
                <i className='bi bi-plus-circle-fill align-middle'></i> {t("Add FCSS")}
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                    (location.pathname === '/fmsm-my-team/team' && 'active')}
                                                    to='/fmsm-my-team/team'>
                                                    Team(FM)
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/fmsm-my-team/supervisor' && 'active')}
                                                    to='/fmsm-my-team/supervisor'>
                                                    Soft Services (FCSS)
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  )
}
