import MultipleFloorPlan from "../create_unit/MultipleFloorPlan";
import MultipleImageUpload from "../create_unit/MultipleImageUpload";
import MultipleMediaUpload from "../create_unit/MultipleMediaUpload";

export function Media({chooseMessage}:any) {
    return (
        <>
        <h3>Sale Details</h3>
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> FLOOR PLAN </label>
                            </div>
                        </div>
                        <MultipleFloorPlan/>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> OTHER MEDIA </label>
                            </div>
                        </div>
                        <MultipleMediaUpload/>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> PHOTOS </label>
                            </div>
                        </div>
                        <MultipleImageUpload/>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> VIRTUAL VIDEO </label>
                                <textarea className="form-control" rows={4}></textarea>
                            </div>
                        </div>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
         <div className="text-end my-6">
            <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('3')}>Previous</button>
            <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('5')}>Next</button>
        </div>
        </>
    )
}