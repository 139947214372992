import React, { useState, useEffect, useRef } from 'react';
import LeftArrow from "../../../../../../assets/images/Left_Arrow.png";
import {Link} from 'react-router-dom'

export function FireAlarmRequest() {

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <Link to={'/incidents/open-requests'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            <span className="text-black">
                                Fire Alarm
                            </span>
                        </Link>
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row form-add-design">
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Area</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option>Air conditioning Failure</option>
                                            <option>Water Leakage Issue</option>
                                            <option>Exhaust Failure</option>
                                            <option>Washing Machine Issue</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Level</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> Level 1 </option>
                                            <option> Level 2 </option>
                                            <option> Level 3 </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Building Location</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> Level 1 </option>
                                            <option> Level 2 </option>
                                            <option> Level 3 </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Device</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Action Taken</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> Yes </option>
                                            <option> No </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">Root Cause</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-12">
                                    <div className="mb-0">
                                        <label className="form-label fw-bolder">Comments</label>
                                        <textarea
                                            className='form-control fw-bolder form-control-solid'
                                            data-kt-autosize='true'
                                            rows={3}
                                            placeholder='Type here...'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-end mt-5">
                    <button data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" className='btn btn-outline-primary bg-white fw-bold px-10 py-3 me-5'>
                        Report
                    </button>
                    <button data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                        Resolved
                    </button>
                </div>
            </div>
        </div>
    );
}