import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

export function Search() {
    const { t } = useTranslation();

    return (
        <div className="col-12">
            <div className="form-group has-search me-4">
                <span className="fa fa-search pt-2 form-control-feedback"></span>
                <input type="text" className="form-control ps-10"
                       data-bs-target="#SearchDetails"
                       data-bs-toggle="modal" data-bs-dismiss="modal"
                       placeholder={t("Search")}
                />
            </div>
        </div>
    );
}