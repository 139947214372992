import React, {useState, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {CustomLoader2} from '../../../constants/generics/customActios'
import {BasicDetails} from './components/BasicDetails'
import {Action} from './components/Action'
import {
  getConfig,
  getFCSSServiceRequestsCategories,
  getRefundChargesData,
  getRefundDetail,
} from '../../../constants/axios/apis'
import {BankDetails} from './components/BankDetails'
import {TerminationCharges} from './components/TerminationCharges'
import {AddTerminationCharges} from './components/AddTerminationCharges'

export function FinanceRefundDetailsScreen({setPopupSuccessMsg}: any) {
  let params: any = {}
  params = useLocation()
  let refundID = ''
  if (params?.state?.refundDetail?.id) refundID = params.state.refundDetail.id

  const [loading, setLoading] = useState<any>(true)
  const [reload, setReload] = useState<any>(true)
  const [loadingCharges, setLoadingCharges] = useState<any>(true)
  const [refundDetail, setRefundDetail] = useState<any>()
  const [chargeList, setChargeList] = useState<any>([])
  const [chargeTypes, setChargeTypes] = useState<any>([])
  const [vat, setVat] = useState<any>('')

  useEffect(() => {
    setLoading(true)
    setRefundDetail({})
    getRefundDetail(refundID)
      .then((response: any) => {
        if (response.data) {
          setRefundDetail(response.data)
          setLoading(false)
        }
      })
      .catch((e) => {})

    getFCSSServiceRequestsCategories().then((response) => {
      let charge_types = response?.data
        ?.filter((item: any) => item.categoryCode === 'OC')
        .flatMap((data: any) => data.service_category_types)
      setChargeTypes(charge_types)
    })

    getConfig().then(({data}: any) => {
      if (data && Array.isArray(data)) {
        const vat_per = data?.find((item: any) => item.key == 'vat_percentage').value
        setVat(vat_per ? vat_per : '')
      }
    })
  }, [refundID])

  useEffect(() => {
    if(reload){
      setLoadingCharges(true)
      setChargeList([])
      // console.log("🚀 ~ file: FinanceRefundDetailScreen.tsx ~ line 43 ~ useEffect ~ refundDetail?.work_order_id", refundDetail)
      if (refundDetail?.work_order_id) {
        const wo_id = refundDetail?.work_order_id //675 , 85, 268
        getRefundChargesData(`?work_order_id=${wo_id}`)
          .then((response: any) => {
            // console.log(response.data, 'getRefundChargesData');
  
            if (response.data && Array.isArray(response.data)) {
              const woData = response.data.find((el: any) => el.work_order_id == wo_id)
              setChargeList(woData)
            }
            setLoadingCharges(false)
            setReload(false)
          })
          .catch((e) => {
            setLoadingCharges(false)
            setReload(false)
          })
      } 
      // else {
      //   setLoadingCharges(false)
      // }
    }
  }, [refundID, refundDetail, reload])

  return (
    <>
      {loading ? (
        <CustomLoader2 />
      ) : (
        <>
          <div className='com-card-section myunit-page'>
            <BasicDetails refundDetail={refundDetail} />
            <div className='col-12'>
              <div className='row mb-6'>
                {loadingCharges ? (
                  <div className='col-12 col-sm-6'>
                    <CustomLoader2 />
                  </div>
                ) : (
                  <div className='col-12 col-sm-6'>
                    <TerminationCharges
                      refundDetail={refundDetail}
                      chargeList={chargeList}
                      vat={vat}
                    />
                    {refundDetail.status === 0 && (
                      <AddTerminationCharges
                        refundDetail={refundDetail}
                        chargeTypes={chargeTypes}
                        vat={vat}
                        setReload={setReload}
                      />
                    )}
                  </div>
                )}
                <div className='col-12 col-sm-6'>
                  <BankDetails refundDetail={refundDetail} />
                </div>
              </div>
            </div>
          </div>
          <Action refundDetail={refundDetail} setPopupSuccessMsg={setPopupSuccessMsg} />
        </>
      )}
    </>
  )
}
