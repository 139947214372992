import React, { useState, useEffect, useRef } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom'
import {  closeActivity } from '../../../constants/axios/apis'
import uploadImg from "../../../assets/images/file-upload.svg";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingSubmitAlert } from '../../../constants/generics/customAlerts';
import { useTranslation } from 'react-i18next';
import { validateAndCompressImage } from '../../../constants/generics/fileUploadHandler';

export function CloseActivity({activityID, closeReasonOption, loading, setPopupSuccessMsg}:any) {
    const { t } = useTranslation();
    const history = useHistory();
    const successRef:any = useRef(null);
    
    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        isSubmitted: false,
        message: '',
    })

    const initialValues = {
        reason: "",
        comment: "",
        mediaUpload: ""
    }

    const onSubmit = (values:any) => {
        setSubmitStatus((oStatus:any)=>({...oStatus, loading: true, error: false}))
        
        const formData = new FormData();
        formData.append("request_id", activityID);
        formData.append("reason", values.reason);
        formData.append("comments", values.comment.trim());
        formData.append("media[]", values.mediaUpload);
      

        setTimeout(() => {
            closeActivity(formData)
                .then((response: any) => {
                    if (response.errorCode === 1) {
                        // console.log(response);
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
                    } else if(response.errorCode === 0) {
                        setPopupSuccessMsg(response.description)
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.description, isSubmitted: true}))
                        successRef.current.click()

                        setTimeout(() => {
                            history.push("/activities/list/close")
                        }, 1000)
                    } else{
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                    }
                })
                .catch((e) => {
                    setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                })
        }, 1000)
    }

    const validationSchema = Yup.object({
        reason: Yup.string().required(t("Close reason is required!")),
        comment: Yup.string()
            .max(250,t("Max length is 250 characters!"))
            .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!'))
            .required(t("Comment is required!")),
        // mediaUpload: Yup.mixed()
        //     .test('type', t('Only the following formats are acceptable: .jpeg, .jpg, .png'), (value) =>
        //         value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true )
        //     .test('fileSize', t('The file is too large!'), (value) => value ? value?.size <= 1000000 : true ),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const [uploading, setUploading] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleDisplayFileDetails = async (e: any) => {
        setUploading(true)
        formik.setFieldError('mediaUpload', undefined)
        try {
            validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
                if (compressResponse?.success) {
                    inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
                    formik.setFieldTouched('mediaUpload')
                    formik.setFieldValue('mediaUpload', compressResponse.file)
                } else {
                    setUploadedFileName(null)
                    formik.setFieldTouched('mediaUpload', true, false)
                    formik.setFieldValue('mediaUpload', '', false)
                    formik.setFieldError('mediaUpload', t(compressResponse?.message || 'File compression error!'))
                }
                setUploading(false)
            })
        } catch (error) {
            setUploadedFileName(null)
            formik.setFieldTouched('mediaUpload')
            formik.setFieldValue('mediaUpload', '', false)
            formik.setFieldError('mediaUpload', t('File upload error!'))
            setUploading(false)
        }

        // inputRef.current?.files &&
        // setUploadedFileName(inputRef.current.files[0].name);

        // formik.setFieldTouched('mediaUpload')
        // formik.setFieldValue('mediaUpload', e.currentTarget.files[0])
    };

    // console.log(formik, 'formik');
    
    
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row form-add-design border-top pt-10 mt-10">
                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                        <div className="mb-7">
                            <label className="form-label fw-bolder"> {t("Close Reason")} <span className="text-danger"> * </span> </label>
                            <select 
                                {...formik.getFieldProps('reason') }
                                className="form-control form-select fw-bolder form-control-solid">
                                <option value="">{t("Select")}</option>
                                {closeReasonOption?.map((el:any, index: number)=>{
                                    return (
                                        <option key={index} value={el.value}>{el.name}</option>
                                    )
                                })}
                            </select>
                            {formik.touched.reason && formik.errors.reason ? (
                                <small className="text-danger">
                                    * {formik.errors.reason}
                                </small>
                            ): null }
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                        <div className="mb-7 upload-input">
                            <label className="form-label fw-bolder d-block">{t("Media Upload")}</label>
                            <input 
                                ref={inputRef} 
                                className="d-none" 
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={(e) => handleDisplayFileDetails(e)}
                                // onBlur={formik.handleBlur}
                                 />
                            <button type='button' onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`} disabled={uploading}>
                                <span>{ uploading ?
                                    <span className='indicator-progress d-block'>
                                        {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                : uploadedFileName ? uploadedFileName : <span className="txt">
                                        <img
                                                alt="File"
                                                src={uploadImg}
                                        className="position-relative"
                                        loading="lazy"/>{t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                            </button>
                            {formik.touched.mediaUpload && formik.errors.mediaUpload ? (
                                <div className='d-flex flex-column text-danger'>
                                    <small className="text-danger">{formik.errors.mediaUpload}</small>
                                </div>
                            ): null }
                        </div>
                    </div>
                    <div className="col-12 mb-8">
                        <div className="">
                            <label className="form-label fw-bolder"> {t("Comments")} <span className="text-danger"> * </span> </label>
                            <textarea
                                className='form-control fw-bolder form-control-solid'
                                data-kt-autosize='true'
                                rows={4}
                                placeholder={t('Type here...')}
                                {...formik.getFieldProps('comment') }
                            />
                        </div>
                        {formik.touched.comment && formik.errors.comment ? (
                                <small className="text-danger">
                                    * {formik.errors.comment}
                                </small>
                            ): null }
                    </div>
                    <div className="col-12 text-end">
                        <div className="row justify-content-end">
                            <div className="col-lg-2 col-md-3 col-6">
                                <button
                                    type='button'
                                    className='btn btn-outline-primary fw-bold px-10 py-3 btn-block'
                                    disabled={submitStatus.loading}
                                    onClick={() => {
                                        formik.resetForm()
                                        history.goBack()
                                    }}
                                >{t("Cancel")}</button>
                            </div>
                            <div className="col-lg-2 col-md-3 col-6">
                                <button
                                    type='submit'
                                    className='btn btn-primary fw-bold px-md-5 py-3 px-2 btn-block'
                                    disabled={submitStatus.isSubmitted || submitStatus.loading || loading || uploading}
                                    >
                                    {submitStatus.loading ? 
                                        <span className='indicator-progress d-block'>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                                        {t("Close Activity")}
                                        </span>
                                        :
                                        <span className='indicator-label'>{t("Close Activity")}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <span
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='d-none'
            ></span>
            <LoadingSubmitAlert submitStatus={submitStatus}/>
        </>            
    );
}