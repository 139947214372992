import React, {useState, useEffect} from 'react'
import {getFacilityDocuments} from '../../../../../../../constants/axios/apis'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import {Link} from 'react-router-dom'
import Loader from '../../../../../../../components/Loader'
import {RootState} from '../../../../../../../constants'
import {useSelector} from 'react-redux'
import {CustomLoader2, NoDataFound1} from '../../../../../../../constants/generics/customActios'
import {CompactPagination} from '../../../../../../../components/partials/content/CompactPagination/CompactPagination'
import {useTranslation} from 'react-i18next'

export function Cheques({searchItem, setSearchItem}: any) {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const {t} = useTranslation()
  const pageSize = 10
  const [documentList, setDocumentList] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totaldocumentList, setTotaldocumentList] = useState<any>(0)
  const [page, setPage] = React.useState(1)
  setTimeout(() => {
    setLoading(false)
  }, 1500)

  const handleClick = (newCurrentPage: any) => {
    setCurrentPage(newCurrentPage)
  }

  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding])
  
  useEffect(() => {
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&id_sort=1' +
      '&status=sent' +
      '&search=' +
      searchItem +
      '&request_type=cheque' +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getFacilityDocuments(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setDocumentList(response.data)
        // setCurrentPage(page);
        setTotaldocumentList(response.dataCount)
      } else {
        setDocumentList([])
        setTotaldocumentList(0)
      }
    })
  }, [currentPage, currentBuilding, searchItem])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleClick(value)
  }

  if (!loading && (documentList === undefined || documentList.length === 0)) {
    return <NoDataFound1 />
  }

  const documentData = documentList?.map((data: any, index: any) => (
    <tr key={data.id}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td>{data.requestType ? data.requestType : '--'}</td>
      {/* <td>{data?.tenant ? data?.tenant : '--'}</td> */}
      <td>{data?.uploaded_user ? data?.uploaded_user : '--'}</td>
      <td>
        <span className='fcss-open-td'>{data.building ? data.building : '--'}</span>
      </td>
      <td>{data.property_code ? data.property_code : '--'}</td>
      <td>
        <Link
          to={{
            pathname: '/fc-ss-documents/document-details',
            state: {documentDetail: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mt-5'>
            <div className='rec-payment-act-sec'>
              <div className='row'>
                <div className='col-12'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                      <thead>
                        <tr>
                          <th className=''> {t('No')} </th>
                          <th className=''> {t('Type of Req.')}</th>
                          {/* <th className=''> Tenant Name</th> */}
                          <th className=''> {t('Uploaded By')} </th>
                          <th className=''> {t('Building')} </th>
                          <th className=''> {t('Unit')} </th>
                          <th className=''> {t('Action')} </th>
                        </tr>
                      </thead>
                      {loading ? (
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <CustomLoader2 />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>{documentList ? documentData : ''}</tbody>
                      )}
                    </table>
                    <div className='w-100 d-flex justify-content-end pt-5'>
                      {/* <Stack spacing={2}>
                        <Pagination
                          count={
                            totaldocumentList % pageSize === 0
                              ? totaldocumentList / pageSize
                              : Math.ceil(totaldocumentList / pageSize)
                          }
                          onChange={handlePageChange}
                        />
                      </Stack> */}
                      <CompactPagination
                        pageSize={pageSize}
                        totalCount={parseInt(totaldocumentList)}
                        defaultPage={currentPage}
                        onPageChange={setCurrentPage}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
