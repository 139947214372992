import {useTranslation} from 'react-i18next'

const Loader = () => {
    const {t} = useTranslation()

    return (
        <div className="w-100 d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t("Loading...")}</span>
            </div>
        </div>
    )
}

export default Loader;

