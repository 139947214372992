import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {MyTeamList} from './components/MyTeamList/MyTeamList'

import {AddContractors} from './add/AddContractors/AddContractors'
// import { AddTechnicianPage } from '../fc-supervisor-myteam/components/Add/AddTechnician';

import {RequestSuccessfulPopup} from '../SuccessfulPopup/RequestSuccessfulPopup'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {TeamTabs} from './components/TeamTabs/TeamTabs'
import {Team} from './components/Team/Team'
import {Supervisor} from './components/Supervisor/Supervisor'
import {AddTeam} from './add/AddContractors/AddTeam'

// import { useSelector } from 'react-redux';
// import { getFcsMyTeamList, getFcsWorkOrders } from '../../utils/makeRequest';
// import { IState } from '../../utils/interface';
// import Loader from '../../components/Loader';
// import useRequestGet from '../../hooks/useRequestGet'

// const TitleandReportWrapper = () => {
//     return (
//         <div className="com-card-section">
//             <div className="row mb-6 flex-stack">
//                 <div className="col-7">
//                     <h4 className="card-title font-18 text-black mt-2">
//                         My Team
//                     </h4>
//                 </div>
//                 <div className="col-5 text-end">
//                     <Link
//                         to={{ 'pathname': '/fmsm-my-team/add', state: { 'addRole': 'FMT' } }}
//                         className="btn btn-primary btn-sm font-16 me-5">
//                         <i className="bi bi-plus-circle-fill align-middle"></i> Add Team
//                     </Link>
//                     <Link
//                         to={{ 'pathname': '/fmsm-my-team/add', state: { 'addRole': 'FCS' } }}
//                         className="btn btn-primary btn-sm font-16">
//                         <i className="bi bi-plus-circle-fill align-middle"></i> Add Supervisor
//                     </Link>
//                 </div>
//             </div>
//             {/* <Reports /> */}
//         </div>
//     )
// }

type Props = {
  searchItem?: any
  setSearchItem?: any
}

const MyTeamPage: FC<Props>=({
  searchItem,
  setSearchItem,
    // const state:any = useSelector(state => state);

  // const userToken = state?.auth?.accessToken;

  // if(userToken === "undefined"){
  //     // token undefined
  // }

  // const [fcsMyTeamList, setFcsMyTeamList] = useState<IState['APIState']>({
  //     loading: false,
  //     response: [],
  //     error: ''
  // });

  // useEffect(()=>{
  //     getFcsMyTeamList(setFcsMyTeamList,userToken);
  // },[]);
}) => {
  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/fmsm-my-team/add/fcs/supervisor'>
              <AddContractors />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/fmsm-my-team/add/fm/team'>
              <AddTeam />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/fmsm-my-team/team'>
              <TeamTabs />
              {/* <MyTeamList /> */}
              <Team />
            </Route>
            <Route path='/fmsm-my-team/supervisor'>
              <TeamTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <Supervisor searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Redirect to='/error/403' />
          </Switch>
        </div>
      </div>
    </>
  )
}

const MyTeamWrapper: FC = () => {
  const intl = useIntl()
  const [searchItem, setSearchItem] = useState<any>('');
  return (
    <>
      <PageTitle breadcrumbs={[]}> Community Post </PageTitle>
      <MyTeamPage
      setSearchItem={setSearchItem}
      searchItem={searchItem}
      />
    </>
  )
}

export {MyTeamWrapper}
