import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { ApprovalsTabs } from './components/ApprovalsTabs/ApprovalsTabs';
import { OpenRequests } from './components/OpenRequests/OpenRequests';
import { ResolvedRequests } from './components/ResolvedRequests/ResolvedRequests';
import { InsufficientNoticeWrapper } from './InsufficientNotice/InsufficientNoticeWrapper';
import { OpenRequestsNew } from "./components/OpenRequests/OpenRequestsNew";
import { ResolvedRequestsNew } from "./components/ResolvedRequests/ResolvedRequestsNew";
import { PMEarlyTerminationRequestWrapper } from "./PMEarlyTerminationRequest/PMEarlyTerminationRequestWrapper";
import { OfferDetailsWrapper } from './OfferDetails/OfferWrapper';
import { getPMapprovalList } from '../../constants/axios/apis';
import { useLocation } from 'react-router-dom'
import { ServiceRequestWrapper } from './ServiceRequests/serviceRequestWrapper';

type Props = {
    approvals?: any
    loading?: any
}
const PMApprovalsPage: FC<Props> = ({ approvals, loading }: any) => (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <Switch>
                    <Route path='/pm-approvals/open-requests'>
                        <ApprovalsTabs />
                        {/*<OpenRequests />*/}
                        <OpenRequestsNew />
                    </Route>
                    <Route path='/pm-approvals/resolved-requests'>
                        <ApprovalsTabs />
                        {/*<ResolvedRequests />*/}
                        <ResolvedRequestsNew />
                    </Route>
                    <Route path='/pm-insufficient-notice'>
                        <InsufficientNoticeWrapper />
                    </Route>
                    <Route path='/pm-service-request'>
                        <ServiceRequestWrapper />
                    </Route>
                    <Route path='/pm-early-termination-request'>
                        <PMEarlyTerminationRequestWrapper />
                    </Route>
                    <Route path='/offer-details'>
                        <OfferDetailsWrapper />
                    </Route>
                </Switch>
            </div>
        </div>
    </>
);

const PMApprovalsWrapper: FC = () => {
    const intl = useIntl();
    const [approvals, setApprovals] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const location = useLocation();
    // useEffect(() => {
    //     setLoading(true)
    //     const config = location.pathname === '/pm-approvals/open-requests' ? 'status=pending' : 'status=approved'
    //     getPMapprovalList(config)
    //         .then((response: any) => {
    //             setLoading(false)
    //             if (response?.errorCode !== 0 || response?.errorDescription === 'The token is expired or invalid') {
    //             } else {
    //                 setApprovals(response.data);
    //             }
    //         })
    // }, [location.pathname])
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMApprovalsPage approvals={approvals} loading={loading} />
        </>
    )
};

export { PMApprovalsWrapper }
