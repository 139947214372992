import {formatDate} from '@fullcalendar/react'
import moment from 'moment'
import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {CompactPagination} from '../../../../components/partials/content/CompactPagination/CompactPagination'
import {RootState} from '../../../../constants'
import {getLeaseList} from '../../../../constants/axios/apis'
import {CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'

export function FinanceLeaseWeekRequest({
  searchItem,
  setSearchItem,
  activeButton,
  setActiveButton,
}: any) {
  const location = useLocation()
  const {t} = useTranslation()

  const pageSize = 10
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalServiceLeaseCount, setTotalServiceLeaseCount] = useState<any>(0)
  const [serviceLeaseList, setServiceLeaseList] = useState<any>([])
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchItem, currentBuilding])

  useEffect(() => {
    setLoading(true)
    let page = currentPage
    let newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&filter=week' +
      '&search=' +
      searchItem +
      '&id_sort=1'
    newPayload = currentBuilding
      ? newPayload + '&building_id=' + currentBuilding
      : newPayload + '&building_id=0'
    getLeaseList(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setServiceLeaseList(response.data)
        // setCurrentPage(page)
        setTotalServiceLeaseCount(response.dataCount)
      } else {
        setServiceLeaseList([])
        // setCurrentPage(1)
        setTotalServiceLeaseCount(0)
        // setLoading(false);
      }
      setLoading(false)
    })
    // .catch((e)=>setLoading(false))
  }, [currentPage, currentBuilding, searchItem])

  if (!loading && (serviceLeaseList === undefined || serviceLeaseList?.length === 0)) {
    return <NoDataFound1 />
  }

  const rowData = serviceLeaseList?.map((data: any, index: any) => (
    <tr key={data.id}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td>{data.tenantName ? data.tenantName : '-'}</td>
      <td>{data.leaseNumber ? data.leaseNumber : '-'}</td>
      <td>{moment(data.leaseStartDate).format('DD-MM-YYYY')}</td>
      <td>{moment(data.leaseEndDate).format('DD-MM-YYYY')}</td>
      <td>{data.propertyCode ? data.propertyCode : '-'}</td>
      <td
        className={
          data.statusText === 'Pending'
            ? 'text-warning'
            : data.statusText === 'Lease Active'
            ? 'text-success'
            : data.statusText === 'Lease Terminated '
            ? 'text-danger'
            : 'text-info'
        }
      >
        {data.statusText ? data.statusText : '-'}
      </td>
      <td>
        <Link
          to={{
            pathname: '/finance-services-lease/view',
            state: {leaseDetail: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <div className='row'>
                  <div className='col-12 pt-5'>
                    <div className='rec-payment-act-sec'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='table-responsive'>
                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                              <thead>
                                <tr>
                                  <th className=''> {t('No')} </th>
                                  <th className=''> {t('Tenant Name')} </th>
                                  <th className=''> {t('Lease Number')} </th>
                                  <th className=''> {t('Start Date')} </th>
                                  <th className=''> {t('End Date')} </th>
                                  <th className=''> {t('Unit')} </th>
                                  <th className=''> {t('Status')} </th>
                                  <th className=''> {t('Action')} </th>
                                </tr>
                              </thead>
                              {loading ? (
                                <tbody>
                                  <tr>
                                    <td colSpan={8} className='text-center pt-10 pb-0'>
                                      <CustomLoader2 />
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>{serviceLeaseList ? rowData : ''}</tbody>
                                // <tbody>
                                //   {serviceLeaseList?.length > 0 ? (
                                //     rowData
                                //   ) : (
                                //     <tr>
                                //       <td colSpan={6} className='text-center pt-10 pb-0'>
                                //         <NoDataFound1 />
                                //       </td>
                                //     </tr>
                                //   )}
                                // </tbody>
                              )}
                            </table>
                          </div>
                          <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalServiceLeaseCount)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
