import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { InspectionDetails } from './InspectionDetails';
import { IssueDetails } from './IssueDetails';
import { useTranslation } from 'react-i18next';

const OnHoldAdvancedDetailsAndTabs = ({workOrderDetails, loading}:any) => {
    const [activeTab, setActiveTab] = useState<any>(1);
    // const [approvalData, setApprovalData] = useState<any>({});
    const { t } = useTranslation();

    return (
        <>
            <div className="col-md-12 col-12">
                <div className="card mb-3">
                    <div className="card-body px-8 py-0">
                        <div className="row access-cd-tabs">
                            <div className="col-md-12 col-12">
                                <div className='d-flex overflow-auto h-55px'>
                                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                        <li className='nav-item'>
                                            <a onClick={() => { setActiveTab(1); }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                (activeTab === 1 && 'active')}>
                                                {t("Issue Details")}
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a onClick={() => { setActiveTab(2); }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                (activeTab === 2 && 'active')}>
                                                {t("Inspection Details")}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                activeTab === 1 ?
                <IssueDetails workOrderDetails={workOrderDetails} loading={loading} />
                : activeTab === 2 ?
                <InspectionDetails workOrderDetails={workOrderDetails} loading={loading} />
                // : activeTab === 3 ?
                // <Documents approvalData={approvalData}/>
                // : activeTab === 4 ?
                // <Undertaking approvalData={approvalData}/>
                :''
            }
        </>
    );
}

export { OnHoldAdvancedDetailsAndTabs }

