import React, { useState, FC, useRef, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { Details } from '../ApprovalTabs/ViewDetails/Details/Details';
import { ViewDetailsFullWrapper } from '../ApprovalTabs/ViewDetails/ViewDetailsFullWrapper';
import { OnHoldDetailWrapper } from './OnHoldDetailWrapper/OnHoldDetailWrapper';
import { ApproveActions } from './ApproveActions/ApproveActions';
import { TechnicianDetailWrapper } from './TechnicianDetailWrapper/TechnicianDetailWrapper';
import { useTranslation } from 'react-i18next';
import { getApprovals } from '../../../../constants/axios/apis';
import { CustomLoader2 } from '../../../../constants/generics/customActios';

const FMTeamViewDetailsWrapper: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    let params: any = {};
    params = useLocation();
    const { approval } = params.state || undefined;
    // const approvalID = approval?.id
    const [loading, setLoading] = useState<any>(false);
    const [error, setError] = useState<any>('');
    // const [approvalData, setApprovalData] = useState<any>({});
    const [approvalData, setApprovalData] = useState<any>(approval);

    // console.log(approval, 'approval');
    // console.log(approvalData, 'approvalData');
    
    // useEffect(()=>{
    //     setLoading(true)
    //     if(approval && approval.subReference !== undefined){
    //         setApprovalData(approval)
    //         setLoading(false)
    //     }else{
    //         getApprovals(`?id=${approval?.id}`).then(({ data }) => {
    //             if(data && Array.isArray(data) && data.length)
    //                 setApprovalData(data?.[0])
    //             else
    //                 setError(t("Some error occured!"))
    //             setLoading(false)
    //         }).catch((e)=>{
    //             setError(t("Some error occured!"))
    //             setLoading(false)
    //         })
    //     }
    // }, [approval])
    
    // const backLink = approval.subReference === 21 ? '/fmteam-approvals/technician/upcoming' : '/fmteam-approvals/on-hold/upcoming'
    const backTitle = approvalData?.subReference ? 
        approvalData?.subReference === 21 ? t('Technician Account') :
        approvalData?.subReference === 27 ? t('NOC Details') :
        approvalData?.subReference === 30 ? t('NOC Details') :
        approvalData?.subReference === 29 ? t('PTW Details') :
         t('On-Hold Work Order') :''
    
    return (
        <>
            <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
                <div className='col-xl-12 mt-0'>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="card-title font-18 text-black mb-6">
                                {/* <Link to={backLink} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link> */}
                                <span className='c-pointer' onClick={() => { history.goBack() }} >
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </span>
                                <span className="text-black">{backTitle}</span>
                            </h4>
                        </div>
                    </div>
                    {
                        loading ? 
                        <CustomLoader2 />
                        :
                            error !== '' ?
                            <div className="text-center">
                                <div className="alert alert-danger w-100 mt-2" role="alert">
                                    {error}
                                </div>
                            </div>
                            :
                            <>
                            
                                <Switch>
                                    <Route path='/fmteam-viewDetails/onhold'>
                                        <OnHoldDetailWrapper approval={approvalData}/>
                                    </Route>
                                    <Route path='/fmteam-viewDetails/technician'>
                                        <TechnicianDetailWrapper approval={approvalData}/>
                                    </Route>
                                    <Route path='/fmteam-viewDetails/ptw'>
                                        <div className="col-12 mb-7">
                                            <Details approval={approvalData}/>
                                        </div>
                                        {/* <ViewDetailsFullWrapper /> */}
                                    </Route>
                                    <Route path='/fmteam-viewDetails/noc'>
                                        <div className="col-12 mb-7">
                                            <Details approval={approvalData}/>
                                        </div>
                                        {/* <ViewDetailsFullWrapper /> */}
                                    </Route>
                                </Switch>
                                <div className="row">
                                    <div className="col-12 mt-1">
                                        <ApproveActions approvalRefType={approvalData.subReference}/>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    );
}

export { FMTeamViewDetailsWrapper }

