import React, {FC, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {CustomLoader2} from '../../../../constants/generics/customActios'
import { VerticalGraph } from './VerticalGraph'

const initReportData = {
  Event: {
    open: {val: 0, rem: 0},
    close: {val: 0, rem: 0},
    total: 0,
  },
  Offers: {
    open: {val: 0, rem: 0},
    close: {val: 0, rem: 0},
    total: 0,
  },
  Vouchers: {
    open: {val: 0, rem: 0},
    close: {val: 0, rem: 0},
    total: 0,
  },
}

export const Rent: FC<any> = ({reportState, setReportState, reportType}) => {
  const {loading, response: reports, error} = reportState
  const [reportVal, setReportVal] = useState(initReportData)
  const { t } = useTranslation();
  // console.log(reportState, 'reportState');
  

  useEffect(() => {
    if (Object.keys(reportState?.response)?.length > 0) {
      let EventData = initReportData?.Event
      let OffersData = initReportData?.Offers
      let VouchersData = initReportData?.Vouchers
      if (reportState?.response?.event) {
        EventData = {
          open: {
            val: reportState?.response?.event?.upcoming,
            rem: reportState?.response?.event?.closed,
          },
          close: {
            val: reportState?.response?.event?.closed,
            rem: reportState?.response?.event?.upcoming,
          },
          total:
            reportState?.response?.event?.upcoming +
            reportState?.response?.event?.closed,
        }
      }
      if (reportState?.response?.offer) {
        OffersData = {
          open: {
            val: reportState?.response?.offer?.upcoming,
            rem: reportState?.response?.offer?.closed,
          },
          close: {
            val: reportState?.response?.offer?.closed,
            rem: reportState?.response?.offer?.upcoming,
          },
          total:
            reportState?.response?.offer?.upcoming +
            reportState?.response?.offer?.closed,
        }
      }
      if (reportState?.response?.voucher) {
        VouchersData = {
          open: {
            val: reportState?.response?.voucher?.upcoming,
            rem: reportState?.response?.voucher?.closed,
          },
          close: {
            val: reportState?.response?.voucher?.closed,
            rem: reportState?.response?.voucher?.upcoming,
          },
          total:
            reportState?.response?.voucher?.upcoming +
            reportState?.response?.voucher?.closed,
        }
      }
      setReportVal({
        Event: EventData,
        Offers: OffersData,
        Vouchers: VouchersData,
      })
    }
  }, [reportState])

  if (loading) {
    return <CustomLoader2 />
  } else if (!reportState || !reportState?.response || reportState?.response?.length === 0)
    return <></>
  else if (reportState?.error !== '')
    return (
      <>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='alert alert-danger w-100 mt-2' role='alert'>
              {reportState?.error}
            </div>
          </div>
        </div>
      </>
    )

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12'>
          <h4 className='card-title font-18 text-black mb-6'>{t("Overview")}</h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card rounded-10'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                {loading && <CustomLoader2 />}
                {!loading && (
                  <>
                    {(reportType === '' || reportType === 'event') && (
                      <div className='col-md-3 col-4 mx-auto'>
                        <div className='inspection-chart'>
                          <VerticalGraph
                            title={t('Event')}
                            openValue={reportVal?.Event?.open}
                            closeValue={reportVal?.Event?.close}
                            totalValue={reportVal?.Event?.total}
                          />
                        </div>
                      </div>
                    )}
                    {(reportType === '' || reportType === 'offers') && (
                      <div className='col-md-3 col-4 mx-auto'>
                        <div className='inspection-chart'>
                          <VerticalGraph
                            title={t('Offers')}
                            openValue={reportVal?.Offers?.open}
                            closeValue={reportVal?.Offers?.close}
                            totalValue={reportVal?.Offers?.total}
                          />
                        </div>
                      </div>
                    )}
                    {(reportType === '' || reportType === 'vouchers') && (
                      <div className='col-md-3 col-4 mx-auto'>
                        <div className='inspection-chart'>
                          <VerticalGraph
                            title={t('Vouchers')}
                            openValue={reportVal?.Vouchers?.open}
                            closeValue={reportVal?.Vouchers?.close}
                            totalValue={reportVal?.Vouchers?.total}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
