/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { getChatList } from '../../../../../constants/axios/apis'
import { DefaultAvatar } from '../../../../../constants/generics/customActios'
import { KTSVG, toAbsoluteUrl } from '../../../../helpers'
import {useTranslation} from 'react-i18next'

type Props = {
    setActiveChat?: any
}

const ChatAll: FC<Props> = ({ setActiveChat }) => {
    const [chatHeads, setChatHeads] = useState<any>([])
    const {t} = useTranslation()

    useEffect(() => {
        getChatList().then(response => {
            if (response?.data && response?.errorCode === 0) {
                setChatHeads(response?.data)
            }
        })
    }, [])

    return (
        <div className="">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className='card card-flush chat-card-left'>
                        <div className='card-header pt-7' id='kt_chat_contacts_header'>
                            <form className='w-100 position-relative' autoComplete='off'>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen021.svg'
                                    className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                                />

                                <input
                                    type='text'
                                    className='form-control form-control-solid px-15'
                                    name='search'
                                    placeholder={t("Search by username or email...")}
                                />
                            </form>
                        </div>

                        <div className='card-body p-3 ' id='kt_chat_contacts_body'>
                            <div
                                className='scroll-y me-n5 pe-5 h-500px'
                                data-kt-scroll='true'
                                data-kt-scroll-activate='{default: false, lg: true}'
                                data-kt-scroll-max-height='auto'
                                data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                                data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                                data-kt-scroll-offset='0px'
                            >
                                {chatHeads?.length && chatHeads?.map((chatHead: any) => (
                                    <>
                                        <div className='d-flex flex-stack chat-left active'
                                            onClick={() => setActiveChat(chatHead)}
                                            key={chatHead?.chatId}
                                        >
                                            <div className='d-flex align-items-top chat-left-in '>
                                                <div className='symbol symbol-45px symbol-circle'>
                                                    {chatHead?.profileImage ? <img alt='Pic' src={chatHead?.profileImage} /> :
                                                        <DefaultAvatar />}
                                                </div>

                                                <div className='ms-5 px-5 pt-0 pb-8'>
                                                    <div className="d-flex justify-content-between">
                                                        <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                            {chatHead?.fullName}
                                                        </a>
                                                    </div>
                                                    <div className='fw-normal chat-desc my-2'>
                                                        {chatHead?.message}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='separator separator-solid d-none'></div>
                                    </>
                                ))

                                }

                                {/* <div className='d-flex flex-stack chat-left active'>
                                    <div className='d-flex align-items-top chat-left-in '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                                M
                                            </span>
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>
                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Melody Macy
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Agent
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc my-2'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>5 hrs</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <img alt='Pic' src={toAbsoluteUrl('/media/avatars/150-2.jpg')} />
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>
                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Max Smith
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Agent
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <img alt='Pic' src={toAbsoluteUrl('/media/avatars/150-4.jpg')} />
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>
                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Sean Bean
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Agent
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                        <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <img alt='Pic' src={toAbsoluteUrl('/media/avatars/150-15.jpg')} />
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>
                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Brian Cox
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Agent
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <span className='symbol-label bg-light-warning text-warning fs-6 fw-bolder'>
                                                M
                                            </span>
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>
                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Mikaela Collins
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Agent
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>1 day</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <img alt='Pic' src={toAbsoluteUrl('/media/avatars/150-8.jpg')} />
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>
                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Francis Mitcham
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Landlord
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>5 hrs</span>
                                        <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                                O
                                            </span>
                                            <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2'></div>
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>

                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Olivia Wild
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Active
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>1 week</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <span className='symbol-label bg-light-primary text-primary fs-6 fw-bolder'>
                                                N
                                            </span>
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>

                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Neil Owen
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Active
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>20 hrs</span>
                                        <span className='badge badge-sm badge-circle badge-light-success'>6</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <img alt='Pic' src={toAbsoluteUrl('/media/avatars/150-6.jpg')} />
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>

                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Dan Wilson
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Active
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>2 weeks</span>
                                        <span className='badge badge-sm badge-circle badge-light-warning'>9</span>
                                    </div>
                                </div>

                                <div className='separator separator-solid d-none'></div>

                                <div className='d-flex flex-stack chat-left'>
                                    <div className='d-flex align-items-top '>
                                        <div className='symbol symbol-45px symbol-circle'>
                                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                                E
                                            </span>
                                        </div>

                                        <div className='ms-5 px-5 pt-0 pb-8'>

                                            <div className="d-flex justify-content-between">
                                                <a href='#' className='fw-bolder text-black text-hover-primary mb-2 font-12'>
                                                    Emma Bold
                                                </a>
                                                <span className="active-state border-0 px-3 py-1 font-10">
                                                    Active
                                                </span>
                                            </div>
                                            <div className='fw-normal chat-desc mb-3 mt-1'>Check the file to get an overview
                                                of the up-coming project.</div>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column align-items-end ms-2'>
                                        <span className='text-muted fs-7 mb-1'>1 day</span>
                                    </div>
                                </div> */}

                                {/* <div className="mt-8">
                                    <button className="btn new-msg-btn"><i className="fas fa-plus ps-5"></i>New Message</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ChatAll }
