import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import MyPic from '../../../../../assets/images/DefaultUser.png';
import { DefaultAvatar } from '../../../../../constants/generics/customActios';
import { NoDataFound } from '../../../../../constants/generics/noDataFound';
import { Hover } from '../Hover/Hover';
import { getPMmyTeam } from './../../../../../constants/axios/apis';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { CustomLoader1 } from './../../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next'
import { CompactPagination } from '../../../../../components/partials/content/CompactPagination/CompactPagination';

export function All({ props }: any) {
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(6)
    // console.log("🚀 ~ file: All.tsx:17 ~ All ~ pageSize", pageSize)
    const [totalTeamList, setTotalTeamList] = useState<any>([]);
    const [message, setMessage] = useState<any>()
    const [myTeamData, setMyTeamData] = useState<any>([])
    const [searchItem, setSearchItem] = useState<any>('')
    const [loading, setLoading] = useState<any>(true)
    const { t } = useTranslation();

    useEffect(() => {
        const roleType = 'PE'
        // console.log("🚀 ~ file: All.tsx:26 ~ useEffect ~ roleType", roleType)
        if (roleType != undefined)
            getPMmyTeam(roleType, currentPage, pageSize, searchItem)
                .then((response: any) => {
                    setLoading(false)
                    if (response?.errorCode === 0) {
                        setMyTeamData(response)
                        setTotalTeamList(response.dataCount);
                    } else {
                        setMyTeamData(response)
                    }
                })
                .catch((e) => {
                })
    }, [, currentPage, searchItem])
    const handleClick = (newCurrentPage: any) => {
        setCurrentPage(newCurrentPage)
    };
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handleClick(value);
    };
    if (loading) {
        return <CustomLoader1 height={20} />
    }
    return (
        <><div className="col-12 mt-4">
            <div className="row access-cd-tabs align-items-center">
                <div className="col-sm-4">
                    <h4 className="card-title font-18 text-black">{t("Team Members")}</h4>
                </div>
                <div className="col-sm-6">
                    <div className='d-flex overflow-auto h-55px float-end me-6 me-sm-10'>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className='row'>
                        <div className='col-lg-12 pe-3 my-auto'>
                            <div className="input-group custom-search-form">
                                <input type="text" className="form-control search-input" placeholder={t('Search')} onChange={(e) => setSearchItem(e.target.value)} />
                                <span className="input-group-btn">
                                    <button className="search_icon" type="button">
                                        <span className=""> </span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="col-12">
                <div className="mta_my_team_team_members mt-5">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {myTeamData?.errorCode != 1 && myTeamData?.data?.teamList?.length > 0 ? myTeamData?.data?.teamList?.map((item: any, index: number) => {
                                    const sl = (pageSize * (currentPage - 1)) + index + 1;
                                    return (
                                        <>
                                            <div className='col-12'>
                                                <div className='table-responsive'>
                                                    <table className="table table-borderless table-nowrap table-center w-100">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("Employee Picture")}</th>
                                                                <th>{t("Employee Name")}</th>
                                                                <th>{t("Contact No.")}</th>
                                                                <th>{t("Emirates Id")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td>
                                                                    <Link to={{
                                                                        pathname: '/pm-myteam-team-members-details',
                                                                        state: { id: item?.user_id }
                                                                    }}>
                                                                        <div className="cardimg position-relative">
                                                                            {item?.profileImage != null ? <img src={item?.profileImage} alt="Pic" className="me-3" /> :
                                                                                <img src={MyPic} className="me-3" height={30} alt='Pic' />}
                                                                            <span className={item?.status === 1 ? 'member-status-team active' : 'member-status-team inactive'}></span>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {item?.name ? item?.name : '-'}
                                                                </td>
                                                                <td>{item?.emiratesId ? item?.emiratesId : '-'}</td>
                                                                <td>{item?.mobile ? item?.mobile : '-'}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </>
                                    );
                                })
                                    : <NoDataFound />}
                            </div><div className="w-100 d-flex justify-content-end">
                                {/* <Stack spacing={2}>
                                    <Pagination count={totalTeamList % pageSize === 0 ? totalTeamList / pageSize : Math.ceil(totalTeamList / pageSize)} onChange={handlePageChange} />
                                </Stack> */}
                                <CompactPagination
                                    pageSize={pageSize}
                                    totalCount={parseInt(totalTeamList)}
                                    defaultPage={currentPage}
                                    onPageChange={setCurrentPage}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    );
}