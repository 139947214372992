import React, { useState, useEffect } from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import ArrowRightBlue from '../../../../assets/images/arrow-right-blue.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'
import { getAnnouncements } from '../../../../constants/axios/apis'
import moment from 'moment'

export function Announcements() {
  const [announcements, setAnnouncements] = useState<any>([])

  useEffect(() => {
    getAnnouncements().then((data: any) => {
      setAnnouncements(data)
    })
  }, [])

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  }

  let content = announcements?.data?.map((item: any) => (
    <div key={item.id}>
      <Link to={{ pathname: "/announcements-details", state: { item } }}>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-4 col-lg-3 my-auto col-12'>
                <img src={item.cover_image} alt="cover-img" className='img-fluid announce-img' />
              </div>
              <div className='col-md-6 col-lg-7 my-auto col-12'>
                <h4 className='card-title mb-2'> Community Post </h4>
                <h6 className='sch-date'>{moment(item.date).format('DD-MM-YYYY')}</h6>
                <h6 className='sch-time mb-0'>
                  {item.start_time} - {item.end_time}
                </h6>
              </div>
              <div className='col-md-2 col-lg-2 my-auto text-end col-12'>
                <img src={ArrowRightBlue} alt='go' className='img-fluid d-inline left-arrow-back' />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <h4 className='card-title'> Community Post </h4>
                </div>
                <div className="offset-sm-3 col-md-3 col-6 text-end">
                  <Link to={'/announcements'} className="text-decoration-none view-all">
                    <span> View All </span>
                    <img src={RightArrow} alt="view-all" className="img-fluid" />
                  </Link>
                </div>
              </div>
              <div className='announcement-sec'>
                <div className='row'>
                  <div className='col-md-12 col-12 col-lg-12'>
                    <div className='row'>
                      <div className='col-12'>
                        <Slider {...settings}>
                          {announcements ? content : 'Loading...'}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
