// import React from 'react'
// import {VerticalGraph} from '../../../../components/layout/components/VerticalGraph'
import React,{FC, useState, useEffect} from 'react'
import { VerticalGraph } from '../VerticalGraph/VerticalGraph';
import Loader from '../../../../components/Loader';

export const Rent:FC<any> = ({reportState, setReportState, reportType}) => {
    const { loading, response: reports, error } = reportState;

    const [reportVal, setReportVal] = useState({
        corrective: {
            val: 0,
            rem: 0 
        },
        clearance: {
            val: 0,
            rem: 0
        },
        preventive: {
            val: 0,
            rem: 0
        }
    });

    useEffect(()=>{
        if(Object.keys(reportState.response).length > 0){

            const max = Math.max.apply(Math, [
                reportState.response.corrective,
                reportState.response.clearance,
                reportState.response.preventive
            ]);
            let total = 0
            if(reportType ===  '')
                total = reportState.response.corrective + reportState.response.clearance + reportState.response.preventive
            else if(reportType === 'corrective')
                total = reportState.response.corrective
            else if(reportType === 'clearance')
                total = reportState.response.clearance
            else if(reportType === 'preventive')
                total = reportState.response.preventive

            setReportVal(
                {
                    corrective: {
                        val: reportState.response.corrective,
                        rem: total - reportState.response.corrective
                    },
                    clearance: {
                        val: reportState.response.clearance,
                        rem: total - reportState.response.clearance
                    },
                    preventive: {
                        val: reportState.response.preventive,
                        rem: total - reportState.response.preventive
                    }
                }
            );
        }
        
    },[reportState]);

    if(loading){
        return <Loader />
    } 
    else if(!reportState || !reportState.response || reportState.response.length === 0)
        return <></>
    else if(reportState.error !== "")
        return <>
            <div className="row">
                <div className="col-12 text-center">
                    <div className="alert alert-danger w-100 mt-2" role="alert">
                        {reportState.error} 123
                    </div>
                </div>
            </div>
        </>

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        Overview
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card rounded-10">
                        <div className="card-body px-8 py-6">
                            {/*<div className="col-md-12">*/}
                            {/*<h4 className="fw-boldest text-black mt-3 mb-6 font-16">*/}
                            {/*Total Inspection (80)*/}
                            {/*</h4>*/}
                            {/*</div>*/}
                            <div className="row">
                                {loading && (
                                    <Loader />
                                )}
                                {!loading && (
                                    <>  
                                        {(reportType ===  '' || reportType === 'corrective') &&
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Corrective"
                                                        reportVal={reportVal.corrective}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {(reportType === '' || reportType === 'clearance') && 
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Clearance"
                                                        reportVal={reportVal.clearance}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {(reportType === '' || reportType === 'preventive') && 
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Preventive"
                                                        reportVal={reportVal.preventive}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {/* <div className="col-md-3">

                                        </div> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}