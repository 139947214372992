import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dateicon from "../../../../../assets/images/Calendar.png";
import UploadIcon from "../../../../../assets/images/upload-ioc.png";
import { Link } from 'react-router-dom'
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { useFormik } from 'formik';
import { getMasterData } from '../../../../constants/axios/apis';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import { PreventDatePickerInput } from '../../../../constants/generics/dateTimeFormater';

export function Report({ reportState, setReportState, genReport, setReportType }: any) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<any>(true)
    const [categoryList, setCategoryList] = useState<any>([])
    const [reportList, setReportList] = useState<any>([])

    const initialValues = {
        category_type: '',
        report_type: '',
        from_date: "",
        to_date: "",
        send_email: "false",
        proper_date: ''
    };

    const onSubmit = (values: any) => {
        const sendMailVal = values?.send_email === true ? "1" : "0";
        const params = `?category=${values?.category_type}&report_type=${values?.report_type}&from_date=${values.from_date}&to_date=${values.to_date}&send_email=${sendMailVal}`;
        genReport(params);

        // const categoryTypeVal = categoryList?.find((el: any) => values?.category_type == el?.value)?.name
        // setCategoryList(categoryTypeVal ? categoryTypeVal : '')

        const reportTypeVal = reportList?.find((el: any) => values?.report_type == el?.value)?.name
        // setReportList(reportTypeVal ? reportTypeVal : '')
        setReportType(reportTypeVal ? reportTypeVal : '')
    }

    const validate = (values: any) => {
        let errors: any = {}
        // if (!values.category_type) {
        //     errors.category_type = 'Required!';
        // }
        // if (!values.report_type) {
        //     errors.report_type = 'Required!';
        // }
        if (!values.from_date) {
            errors.from_date = (t('Required!'));
        }
        if (!values.to_date) {
            errors.to_date = (t('Required!'));
        }
        var date1 = new Date(values.from_date);
        var date2 = new Date(values.to_date);

        if (values.from_date && values.to_date) {
            if (date1.getTime() > date2.getTime()) {
                errors.proper_date = (t('\'To date\' must be greater then \'From date\' !'));
            }
        }
        return errors;
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });

    const [fromDateValue, setFromDateValue] = useState<any>();
    const [toDateValue, setToDateValue] = useState<any>();

    const handleFromDateChange = (e: any) => {
        setFromDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') : '';
        formik.setFieldValue("from_date", menDate);
    }
    const handleToDateChange = (e: any) => {
        setToDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') : '';
        formik.setFieldValue("to_date", menDate);
    }

    useEffect(() => {
        setLoading(true)
        setCategoryList([])
        getMasterData().then(({ data }) => {
            const categoryList = data?.find((el: any) => el?.category_name === "market_place")
            setCategoryList(categoryList?.master_data ? categoryList?.master_data : [])
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        setReportList([])
        getMasterData().then(({ data }) => {
            const reportList = data?.find((el: any) => el?.category_name === "mt_report_type")
            setReportList(reportList?.master_data ? reportList?.master_data : [])
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
    }, [])

    const categoryOption = categoryList?.map((categoryItem: any) => (
        <option key={categoryItem?.value} value={categoryItem?.value} className="text-capitalize">
            {categoryItem?.name}
        </option>
    ))
    const reportOption = reportList?.map((reportItem: any) => (
        <option key={reportItem?.value} value={reportItem?.value} className="text-capitalize">
            {reportItem?.name}
        </option>
    ))
    
    PreventDatePickerInput()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        {t("Report")}
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="card-body px-8 py-6">
                            {loading && (
                                <CustomLoader2 />
                            )}
                            {!loading && (
                                <>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row form-add-design">
                                            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Category")}<span className="text-danger"> * </span></label>
                                                    <select
                                                        name='category_type'
                                                        className="form-control form-select fw-bolder form-control-solid"
                                                        onChange={formik.handleChange}
                                                    >
                                                        <option value="0">{t("All")}</option>
                                                        {categoryList ? categoryOption : ''}
                                                    </select>
                                                    {formik.errors.category_type ? (
                                                        <small className="text-danger">
                                                            {formik.errors.category_type}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Report Type")}<span className="text-danger"> * </span></label>
                                                    <select
                                                        name="report_type"
                                                        className="form-control form-select fw-bolder form-control-solid"
                                                        onChange={formik.handleChange}
                                                    >
                                                        <option value="0">{t("All")}</option>
                                                        {reportList ? reportOption : ''}
                                                    </select>
                                                    {formik.errors.report_type ? (
                                                        <small className="text-danger">
                                                            {formik.errors.report_type}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12">
                                                <div className="row mb-5">
                                                    <div className="col-md-6 col-sm-6 col-12">
                                                        <div className="mb-2 date-picker">
                                                            <label className="form-label fw-bolder">{t("From Date")} <span className="text-danger"> * </span></label>
                                                            <DatePicker
                                                                value={fromDateValue}
                                                                className='form-control fw-bolder form-control-solid'
                                                                format="dd-MM-y"
                                                                onChange={handleFromDateChange}
                                                                dayPlaceholder="DD"
                                                                monthPlaceholder="MM"
                                                                yearPlaceholder="YYYY"
                                                            />
                                                            {formik.touched.from_date && formik.errors.from_date ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.from_date}
                                                                </small>
                                                            ) : null}
                                                            {(formik.touched.from_date && formik.touched.to_date) && formik.errors.proper_date ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.proper_date}
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-12">
                                                        <div className="mb-2 date-picker">
                                                            <label className="form-label fw-bolder">{t("To Date")} <span className="text-danger"> * </span></label>
                                                            <DatePicker
                                                                value={toDateValue}
                                                                className='form-control fw-bolder form-control-solid'
                                                                format="dd-MM-y"
                                                                onChange={handleToDateChange}
                                                                dayPlaceholder="DD"
                                                                monthPlaceholder="MM"
                                                                yearPlaceholder="YYYY"
                                                            />
                                                            {formik.touched.to_date && formik.errors.to_date ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.to_date}
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-4 col-lg-4 col-xl-6 col-12">
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-6 col-xl-3 col-12 py-4">
                                                <div className="form-check form-check-sm custom float-end">
                                                    <input
                                                        onChange={formik.handleChange}
                                                        value={formik.values.send_email}
                                                        name="send_email" className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                    <label className="form-check-label font-12 fw-normal" htmlFor="flexCheckDefault">
                                                        {t("Send A Copy Via Email")}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
                                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3 d-block w-100 GenerateReport'>
                                                    {t("Generate Report")}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}