/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { getMessageFromUser, sendMessages } from '../../../constants/axios/apis'
import { CustomViewPopup, DefaultAvatar, fileUploader, validateRequired } from './../../../constants/generics/customActios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { CustomDdIcon2 } from './../../../constants/generics/customActios';
import {useTranslation} from 'react-i18next'
import { MdPictureAsPdf } from 'react-icons/md';

type Props = {
	isDrawer?: boolean
	activeChat?: any
}

const ChatInner: FC<Props> = ({ isDrawer = false, activeChat }) => {
	const [messagesWithUser, setMessagesWithUser] = useState<any>([])
	const [message, setMessage] = useState<string>('')

	const inputRefImgUpload = useRef<any>(null);
	const [uploadedFileNameImgUpload, setUploadedFileNameImgUpload] = useState<any>(null);
	const [img, setImg] = useState<any>(null);
	const [imgUploadFieldError, setImgUploadFieldError] = useState<string[]>(['']);
	const [imgUploadFieldError1, setImgUploadFieldError1] = useState<string[]>(['']);
	const [viewImage, setViewImage] = useState<any>()
    const {t} = useTranslation()

	const ref = useRef<any>(0)
	const ref3 = useRef<any>(0)

	let id: number = 0
	let userImg = ''
	let userDetail = localStorage.getItem('userDetail')
	if (userDetail) {
		id = JSON.parse(userDetail).id
		userImg = JSON.parse(userDetail).profile_img
	}

	const handleUploadImgUpload = () => {
		inputRefImgUpload.current?.click();
	};

	const handleDisplayFileDetailsImgUpload = () => {
		inputRefImgUpload.current?.files &&
			setUploadedFileNameImgUpload(inputRefImgUpload.current.files[0].name);


		if (inputRefImgUpload.current?.files) {
			setImg(inputRefImgUpload.current?.files[0])
			setImgUploadFieldError([]);
			fileUploader(inputRefImgUpload.current?.files[0], setImgUploadFieldError, "Image");
			fileUploader(inputRefImgUpload.current?.files[0], setImgUploadFieldError1, "Image");
		}
	};

	const sendMessage = () => {
		var FormData = require('form-data')
		var msgPayload = new FormData()
		msgPayload.append('chat_screen', id);
		msgPayload.append('to_user_id', activeChat?.fromUserId);
		msgPayload.append('message', message);

		// inputRefImgUpload.current?.files?.length > 0 && msgPayload.append('img[]', inputRefImgUpload.current?.files[0])
		img != null && msgPayload.append('img[]', img)

		if (message.length > 0 || inputRefImgUpload.current?.files?.length > 0 && imgUploadFieldError?.length === 0) {
			sendMessages(msgPayload)
				.then((response: any) => {
					setImg(null)
					setUploadedFileNameImgUpload(null)
					fetchMessages()
				})
		} else {
		}
		setMessage('')
	}

	const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault()
			sendMessage()
		}
	}

	useEffect(() => {
		if (activeChat) {
			fetchMessages()
		}
	}, [activeChat])

	const fetchMessages = () => {
		let payload = '?from_user_id=' + activeChat?.fromUserId
		getMessageFromUser(payload).then(response => {
			if (response.data) {
				setMessagesWithUser(response.data)
				ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		})
	}

	const handlePreviewViewDoc = (data: any) => {
		setViewImage(data)
		ref3.current.click();
	}

	return (
		<><div
			className='card-body'
			id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
		>
			<div
				className={clsx('scroll-y me-n5 pe-5', { 'h-410px': !isDrawer })}
				data-kt-element='messages'
				data-kt-scroll='true'
				data-kt-scroll-activate='{default: false, lg: true}'
				data-kt-scroll-max-height='auto'
				data-kt-scroll-dependencies={isDrawer
					? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
					: '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'}
				data-kt-scroll-wrappers={isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'}
				data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
			>

				{messagesWithUser?.length > 0 ? messagesWithUser?.map((message: any, index: any) => {
					const templateAttr = {};
					if (message.template) {
						Object.defineProperty(templateAttr, 'data-kt-element', {
							value: `template-${message.type}`,
						});
					}
					const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.type === 'In' ? 'start' : 'end'} mb-10 ${message.type === 'In' ? 'chat-reply' : 'chat-in'}`;

					return (
						<div
							key={`message${index}`}
							className={clsx('d-flex', contentClass, 'mb-6', { 'd-none': message.template })}
							{...templateAttr}
						>
							<div
								className={clsx(
									'd-flex flex-column align-items',
									`align-items-${message.type === 'In' ? 'start' : 'end'}`
								)}
							>
								{message?.message && <div className='d-flex align-items-center mb-2'>
									{message.type === 'In' ? (
										<>
											<div className='symbol  symbol-35px symbol-circle chat-inner-symbol'>
												{activeChat?.profileImage ? <img alt='Pic' src={activeChat?.profileImage} /> :
													<DefaultAvatar />}
											</div>

										</>
									) : (
										<>
											<div className='symbol  symbol-35px symbol-circle chat-inner-symbol'>
												{userImg ? <img alt='Pic' src={userImg} /> :
													<DefaultAvatar />}
											</div>
										</>
									)}

									<div
										className={clsx(
											'p-5 rounded',
											`bg-white`,
											'text-dark fw-bold mw-lg-400px mx-5 chat-text',
											`text-${message.type === 'In' ? 'start' : 'end'}`
										)}
										data-kt-element='message-text'
										dangerouslySetInnerHTML={{ __html: message.message }}
									></div>
								</div>}
								<div className='d-flex align-items-center mb-2'>
									<div className='symbol  symbol-85px'>&nbsp;</div>
									{message.type === 'In' ? (
										<>
											<div className='symbol  symbol-100px ' onClick={() => {
												handlePreviewViewDoc(message?.img[0]);
											}}>
												{message?.img[0]?.includes('pdf') ? <MdPictureAsPdf height="5em" width="5em" size={'5em'} />
													:
													message?.img[0] && <img alt='Pic' src={message?.img[0]} />
												}
											</div>

										</>
									) : (
										<>
											<div className='symbol  symbol-100px ' onClick={() => {
												handlePreviewViewDoc(message?.img[0]);
											}}>
												{message?.img[0]?.includes('pdf') ? <MdPictureAsPdf height="5em" width="5em" size={'5em'} />
													:
													message?.img[0] && <img alt='Pic' src={message?.img[0]} />
												}
											</div>
										</>
									)}
								</div>
								<div className='d-flex align-items-center mb-2'>
									<div className='symbol  symbol-35px symbol-circle chat-inner-symbol'>&nbsp;</div>
									<div ref={ref}
										className={clsx(
											`text-${message.type === 'In' ? 'chat-reply-time mx-5' : 'chat-in-time mx-5'}`
										)}
										data-kt-element='message-text'
									>
										{moment(message.created_at).format("MMM Do YYYY, h:mm: a")}</div>
								</div>
							</div>
						</div>
					);
				}) : t("Select a chat to start")}
			</div>

			<div
				className='card-footer pt-4 px-0 pb-0'
				id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
			>


				<div className='d-flex flex-stack'>
					<div className='d-flex align-items-center me-2'>
						<button
							className='btn btn-sm btn-icon btn-active-light-primary me-1 btn-attachment'
							type='button'
							data-bs-toggle='tooltip'
							onClick={handleUploadImgUpload}
						>
							<i className='bi bi-paperclip fs-3'></i>
						</button>
						<input ref={inputRefImgUpload} onChange={handleDisplayFileDetailsImgUpload} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />

					</div> <span className="slash">|</span>
					<textarea
						className='form-control form-control-flush mb-0 typing-area me-5 px-2'
						data-kt-element='input'
						rows={1}
						placeholder={t("Type Something")}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						onKeyDown={onEnterPress}
					></textarea>
					<button
						className='btn btn-primary send-msg d-flex align-items-center'
						type='button'
						data-kt-element='send'
						onClick={sendMessage}
					>
						<i className="fas fa-paper-plane me-1"></i> {t("Send")}
					</button>
				</div>
				{uploadedFileNameImgUpload ? uploadedFileNameImgUpload : ''}
				<div className='d-flex flex-column text-danger'>
					{imgUploadFieldError.map((error, index) => {
						if (index === 0)
							return (
								<small className="text-danger" key={index}>{error}</small>
							);
					})}
				</div>
			</div>
		</div><CustomViewPopup viewImage={viewImage} />
			<p ref={ref3} data-bs-target="#view-customPopUp" data-bs-toggle="modal" data-bs-dismiss="modal">
				{/*  */}
			</p></>
	)
}

export { ChatInner }
