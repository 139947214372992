import React, { useState, useEffect } from 'react';

// import { useSelector } from 'react-redux';
// import { getMaintenanceRequestsOpen } from '../../../../../utils/makeRequest';
// import { IState } from '../../../../../utils/interface';
import moment from 'moment'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { getFacilityMaintenanceRequests } from '../../../../constants/axios/apis';
import { formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import Loader from '../../../../components/Loader';
import { RequestTabs } from '../RequestTabs/RequestTabs';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

// const columns = [
//     {
//         field: 'no',
//         headerName: 'No',
//         sortable: false,
//         disableColumnMenu: true,
//         minWidth: 100,
//         flex: 1,
//     },
//     {
//         field: 'ticketNo',
//         headerName: 'Ticket No',
//         minWidth: 200,
//         flex: 1,
//     },
//     {
//         field: 'category',
//         headerName: 'Category',
//         minWidth: 150,
//         flex: 1,
//     },
//     {
//         field: 'unit',
//         headerName: 'Unit',
//         className: '',
//         disableColumnMenu: true,
//         minWidth: 150,
//         flex: 1,
//     },
//     {
//         field: 'time',
//         headerName: 'Time',
//         minWidth: 150,
//         flex: 1,
//     }
// ];

export function FacilityMaintenanceTableOpen({searchItem, setSearchItem}: any) {
    const { t } = useTranslation();
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    const pageSize = 10;
    const [maintenanceList, setMaintenanceList] = useState<any>([])
    const [loading, setLoading] = useState<any>(true);
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [totalMaintenanceCount, setTotalMaintenanceCount] = useState<any>(0);

    useEffect(()=>{ setCurrentPage(1) },[searchItem])
    useEffect(() => { setCurrentPage(1) }, [currentBuilding])

    useEffect(() => {
        
        setLoading(true)
        let page = currentPage;
        let newPayload = "?currentPage=" + page + "&pageSize=" + pageSize + "&status=open"+"&search=" + searchItem 
        // if(currentBuilding)
        newPayload = currentBuilding ? newPayload + "&building_id=" + currentBuilding : newPayload + "&building_id=0"
        getFacilityMaintenanceRequests(newPayload).then((response: any) => {
            setMaintenanceList(response.data);
            // console.log(response.data, "response.data");
            setCurrentPage(page);
            setTotalMaintenanceCount(response.dataCount);
            setLoading(false);
        }).catch((e)=>{
            setMaintenanceList([]);
            setCurrentPage(1);
            setTotalMaintenanceCount(0);
            setLoading(false);
        })
    }, [currentPage, currentBuilding,searchItem]);

    const rowData = maintenanceList?.map((data: any, index: any) => (
        <tr key={data.id}>
            <td>{((currentPage-1) * pageSize) + index + 1}</td>
            <td>{data.ticket}</td>
            <td>{data.categoryType}</td>
            <td>{data.property_code}</td>
            <td>{ moment(data.appointmentDate).format("DD-MM-YYYY") + ' ' + formatTimeMeridiem(data.time)}</td>
            <td>
                <Link to={{
                    'pathname': '/facility-maintenance/view',
                    state: { 'maintenanceDetail': data }
                }} className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'>
                    <span className='view-option text-theme fw-bolder'>{t("View")}</span>
                </Link>
            </td>
        </tr>
    ))

    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12 pt-5">
                                    <div className='rec-payment-act-sec'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='table-responsive'>
                                                    <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th className=''> {t("No.")} </th>
                                                                <th className=''> {t("Ticket")}</th>
                                                                <th className=''> {t("Category")} </th>
                                                                {/* <th className=''> {t("Building")} </th> */}
                                                                <th className=''> {t("Unit")} </th>
                                                                <th className=''> {t("Appointment Date & Time")} </th>
                                                                <th className=''> {t("Action")} </th>
                                                            </tr>
                                                        </thead>
                                                        { loading ? 
                                                            (<tbody><tr><td colSpan={6} className='text-center pt-10 pb-0'><CustomLoader2 /></td></tr></tbody>)
                                                            :
                                                            (<tbody>
                                                                {(maintenanceList?.length > 0) ? rowData : <tr><td colSpan={6} className='text-center pt-10 pb-0'><NoDataFound1 /></td></tr>}
                                                            </tbody>)
                                                        }
                                                    </table>
                                                </div>
                                                <CompactPagination
                                                    pageSize={pageSize}
                                                    totalCount={parseInt(totalMaintenanceCount)}
                                                    defaultPage={1}
                                                    onPageChange={setCurrentPage}
                                                    loading={loading}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>
            </div>
        </div>
    );


    // return (
    //     <div className="com-card-section">
    //         <div className="row">
    //             <div className="col-12">
    //                 <div className="card mb-3">
    //                     <div className="card-body px-8 py-6">
    //                         <div className="row">
    //                             <div className="col-12 pt-5">
    //                                 <div className="rec-payment-act-sec">
    //                                     <div className="row">
    //                                         <div className="col-12">
    //                                             {loading && (
    //                                                 <Loader />
    //                                             )}
    //                                             {!loading && (
    //                                                 <div className="data_table">
    //                                                     <div className="row">
    //                                                         <div className="col-12">
    //                                                             <div className='table-responsive'>
    //                                                                 <div style={{ height: 650 }}>
    //                                                                     <DataGrid
    //                                                                         rows={workOrderRow}
    //                                                                         getRowId={(row) => row.no}
    //                                                                         columns={columns}
    //                                                                         pageSize={10}
    //                                                                         rowsPerPageOptions={[10]}
    //                                                                     />
    //                                                                 </div>
    //                                                             </div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             )}
    //                                             {error && (
    //                                                 <div className="alert alert-danger w-100 mt-2" role="alert">
    //                                                     {error}
    //                                                 </div>
    //                                             )}
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
}