import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'

export function ViewPopup() {
    const { t } = useTranslation();

    return (
        <div className="modal fade add-broker-modal" aria-hidden='true' id="view-popup">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t("View Popup")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body"></div>
                      
                </div>
            </div>
        </div>
    );
}