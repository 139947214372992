import React, { useState, useEffect, useRef } from 'react';
import { MdOutlineClose, MdOutlineCheck } from "react-icons/md";
import { useTranslation } from 'react-i18next'
import { leadsRejectQualify } from '../../../../constants/axios/apis';
import { Link, useHistory } from 'react-router-dom';
import { ImageViewerWithPopup } from '../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';

export function Status({leadsData, setPopupSuccessMsg}: any) {
    // console.log(leadsData, 'leadsData');
    const { t } = useTranslation();
    const [rejectId, setRejectId] = useState<any>()
    const [qualifyId, setQualifyId] = useState<any>()
    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        isSubmitted: false,
        message: '',
    })
    const history = useHistory()
    const successRef: any = useRef(null);

    const handleReject = (id: any) => {
        setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: true, error: false }))

        // console.log("🚀 ~ file: Status.tsx:5 ~ handleReject ~ id", id)
        const formData = new FormData()
        formData.append("action", 'reject');
        formData.append("lead_id", id);

        leadsRejectQualify(formData)
            .then((response: any) => {
                if (response.errorCode === 1)
                    setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false, error: true, message: response.errorDescription }))
                else if (response.errorCode === 0) {
                    setPopupSuccessMsg(response.description)
                    setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.description, isSubmitted: true}))
                    successRef.current.click()
                    setTimeout(() => {
                        history.push("/pm-leads/rejected")
                    }, 1000)
                } else
                    setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false, message: t("Some error occured!"), error: true }))
            })
            .catch((e) => {
                setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false, message: t("Some error occured!"), error: true }))
            })
    }
    const handleQulify = (id: any) => {
        setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: true, error: false }))

        const formData = new FormData()
        formData.append("action", 'qualify');
        formData.append("lead_id", id);

        leadsRejectQualify(formData)
            .then((response: any) => {
                if (response.errorCode === 1)
                    setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false, error: true, message: response.errorDescription }))
                else if (response.errorCode === 0) {
                    setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false, message: response.description, isSubmitted: true }))
                    setPopupSuccessMsg(response.description)
                    successRef.current.click()
                    setTimeout(() => {
                        history.push("/pm-leads/qualified")
                    }, 1000)
                } else
                    setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false, message: t("Some error occured!"), error: true }))
            })
            .catch((e) => {
                setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false, message: t("Some error occured!"), error: true }))
            })
    }
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-7">
                            <div className="row">
                                {/* <div className="col-12">
                                    <div className="row align-items-center mb-6">
                                        <div className="col-lg-6 col-sm-12">
                                            <h4 className="card-title font-18 text-black mb-0">
                                                Status
                                            </h4>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 text-end">
                                            <i className="text-warning bi bi-plus-circle-fill font-17 me-3"></i> <span>New</span>
                                            <i className="text-success bi bi-plus-circle-fill font-17 me-3"></i> <span>Qualified</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <div className="row form-add-design">
                                        {/* <div className="col-md-4 col-12 mb-10">
                                            <div className="request-refund-bg p-5 rounded text-center">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14 text-uppercase"> Refund Available </h6>
                                                <span className="text-black fw-bolder font-16"> AED 4140 </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 mb-10">
                                            <div className="request-refund-bg p-5 rounded text-center">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14 text-uppercase"> NO OF PAYMENTS </h6>
                                                <span className="text-black fw-bolder font-16"> AED 4140 </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 mb-10">
                                            <div className="request-refund-bg p-5 rounded text-center">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14 text-uppercase"> MY OFFER </h6>
                                                <span className="text-black fw-bolder font-16"> 80000 </span>
                                            </div>
                                        </div> */}
                                        <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="form-label fw-bolder">Customer Name</label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0"> {leadsData?.customerName ? leadsData?.customerName : '-'} </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="form-label fw-bolder">Contact Name</label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0"> {leadsData?.contactName ? leadsData?.contactName : '-'} </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray"> Email ID </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0"> {leadsData?.email ? leadsData?.email : '-'}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray"> Mobile Number </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    {/* <h6 className="font-14 fw-bold d-block mb-0"> +97 98698 98989 </h6> */}
                                                    <h6 className="font-14 fw-bold d-block mb-0">{leadsData?.mobile ? leadsData?.mobile : '-'} </h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {leadsData?.description && <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="form-label fw-bolder">Description</label>
                                                <h6 className="font-14 fw-bold d-block mb-0"> {leadsData?.description ? leadsData?.description : '-'} </h6>
                                            </div>
                                        </div>} */}
                                        <div className="col-12">
                                            <div className="mb-5">
                                                <label className="form-label fw-bolder">Other Info</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="form-label fw-bolder">Concept Name</label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0"> {leadsData?.concept_name ? leadsData?.concept_name : '-'} </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="form-label fw-bolder">Concept Type</label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0"> {leadsData?.concept_type ? leadsData?.concept_type === 1 ? 'New' : leadsData?.concept_type === 2 ? 'Existing' : leadsData?.concept_type : '-'} </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-10">
                                                <label className="form-label fw-bolder">Website</label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0"> {leadsData?.website_link ? leadsData?.website_link : '-'} </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="">
                                                <label className="form-label fw-bolder"> {t("Proof of Concept")} </label>
                                                <ImageViewerWithPopup imageFile={leadsData?.attachments_json?.concept_proof} imgID={'poc'} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="">
                                                <label className="form-label fw-bolder"> {t("Trade License")} </label>
                                                <ImageViewerWithPopup imageFile={leadsData?.attachments_json?.trading_license} imgID={'tl'} />
                                            </div>
                                        </div>


                                        <div className="col-12 text-end">
                                            {leadsData?.statusText === 'qualified' ?
                                                leadsData?.offer_id ?
                                                    <button className='btn btn-primary fw-bold px-13 py-3 me-5 disabled' >
                                                        {t("Offer already made!")}
                                                    </button>
                                                    :
                                                    <Link
                                                        to={{
                                                            pathname: '/pm-leads/leads-offer',
                                                            state: { leadData: leadsData },
                                                        }}
                                                        className='btn btn-primary fw-bold px-13 py-3 me-5' >
                                                        {t("Make an offer")}
                                                    </Link>
                                                :
                                                leadsData?.statusText === 'new' && submitStatus.isSubmitted === false ?
                                                    submitStatus.loading ?
                                                        <button className='btn btn-primary fw-bold px-13 py-3 me-5' disabled >
                                                            {t("Please wait...")}
                                                        </button>
                                                        :
                                                        <>
                                                            {/* <button className="btn btn-outline-primary fw-bold px-13 py-3 me-5" onClick={() => handleReject(leadsData?.id)}>Reject</button> */}
                                                            <button className='btn btn-outline-primary fw-bold px-13 py-3 me-5'
                                                                data-bs-target='#reject-popup'
                                                                data-bs-toggle='modal'
                                                                data-bs-dismiss='modal'
                                                                onClick={() => setRejectId(leadsData?.id)}
                                                            // onClick={() => setCancelId( )}
                                                            >
                                                                {t("Reject")}
                                                            </button>
                                                            {/* <button className="btn btn-primary fw-bold px-13 py-3" onClick={() => handleQulify(leadsData?.id)}>Qualify</button> */}
                                                            <button className='btn btn-primary fw-bold px-13 py-3 me-5'
                                                                data-bs-target='#qualify-popup'
                                                                data-bs-toggle='modal'
                                                                data-bs-dismiss='modal'
                                                                onClick={() => setQualifyId(leadsData?.id)}
                                                            // onClick={() => setCancelId( )}
                                                            >
                                                                {t("Qualify")}
                                                            </button>
                                                        </>
                                                    : ''
                                            }
                                        </div>
                                        {
                                            // submitStatus.isSubmitted && submitStatus.error === false ?
                                            //     <div className="col-12 mt-5">
                                            //         <div className="alert alert-success mt-2 text-center w-100" role="alert">
                                            //             {submitStatus.message}
                                            //         </div>
                                            //     </div>
                                            //     :
                                                submitStatus.error ?
                                                    <div className="col-12 mt-5">
                                                        <div className="alert alert-danger mt-2 text-center w-100" role="alert">
                                                            {submitStatus.message}
                                                        </div>
                                                    </div>
                                                    : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal fade custom-modal' id='reject-popup'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content rounded-10'>
                        <div className='modal-header border-0 py-4'>
                            <h5 className='modal-title fw-bold'> {t("Reject")}</h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                            >&times;</button>
                        </div>
                        <div className='modal-body px-10 pb-0'>
                            <div className='row mt-6'>
                                <div className='col-12'>
                                    <div className='mb-6'>
                                        <div className="text-center flex-css justify-content-center">
                                            <MdOutlineClose color='red' fontSize="3.5em" />
                                        </div>
                                        <h3 className='text-dark fw-bolder text-center flex-css justify-content-center mt-4 font-18'>{t("Are you sure?")}</h3>
                                    </div>
                                    <div className="text-center flex-css justify-content-center">
                                        <p className='font-14 text-black fw-normal'> {t("Do you really want to Rejact this?")} <br /> {t("This process cannot be undone.")} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer border-0 flex-css justify-content-center'>
                            <button
                                type='button'
                                className='btn btn-primary fw-bold px-10 py-3 me-5'
                                data-bs-dismiss='modal'
                                onClick={() => handleReject(rejectId)}
                            // onClick={() => onCancel(cancelId)}
                            >
                                {t("Yes")}
                            </button>
                            <button
                                type='button'
                                className='btn btn-outline-primary fw-bold px-10 py-3'
                                data-bs-dismiss='modal'
                            >
                                {t("No")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal fade custom-modal' id='qualify-popup'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content rounded-10'>
                        <div className='modal-header border-0 py-4'>
                            <h5 className='modal-title fw-bold'> {t("Qualify")}</h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                            >&times;</button>
                        </div>
                        <div className='modal-body px-10 pb-0'>
                            <div className='row mt-6'>
                                <div className='col-12'>
                                    <div className='mb-6'>
                                        <div className="text-center flex-css justify-content-center">
                                            <MdOutlineCheck color='green' fontSize="3.5em" />
                                        </div>
                                        <h3 className='text-dark fw-bolder text-center flex-css justify-content-center mt-4 font-18'>{t("Are you sure?")}</h3>
                                    </div>
                                    <div className="text-center flex-css justify-content-center">
                                        <p className='font-14 text-black fw-normal'> {t("Do you really want to Qualify this?")} <br /> {t("This process cannot be undone.")} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer border-0 flex-css justify-content-center'>
                            <button
                                type='button'
                                className='btn btn-primary fw-bold px-10 py-3 me-5'
                                data-bs-dismiss='modal'
                                onClick={() => handleQulify(qualifyId)}
                            // onClick={() => onCancel(cancelId)}
                            >
                                {t("Yes")}
                            </button>
                            <button
                                type='button'
                                className='btn btn-outline-primary fw-bold px-10 py-3'
                                data-bs-dismiss='modal'
                            >
                                {t("No")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <span
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='d-none'
              ></span>
        </div>
    );
}