import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

export function FilterModal() {


    return (
        <div className="modal fade filter-modal" aria-hidden='true' id="Filter-popup">
            <div className="modal-dialog modal-md modal-dialog-centered radius-15 h-100">
                <div className="modal-content radius-15 overflow-hidden border-0 h-100">
                    <div className="modal-header align-items-center">
                        <h6 className="text-black font-weight-semibold font-14 mb-0">
                            Filter <i className="fas fa-filter"></i>
                        </h6>
                        <div className="filter-close" data-bs-dismiss="modal">
                            <i className="fas fa-angle-left font-16 text-black"></i>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder">Status</label>
                                    <select className="form-control form-select fw-bolder"
                                    >
                                        <option selected>Active </option>
                                        <option>Inactive </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder">Concluded</label>
                                    <select className="form-control form-select fw-bolder"
                                    >
                                        <option selected>yes </option>
                                        <option>No </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder">Paused</label>
                                    <select className="form-control form-select fw-bolder"
                                    >
                                        <option selected>yes </option>
                                        <option>No </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}