import React, { FC, useEffect, useRef, useState } from 'react'
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import FullCalendar, { CalendarOptions, EventInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils'

export const CalenderCard: React.FC<any> = ({eventState}) => {
	const [selectedDate, setSelectedDate] = useState<any>() // Accept setSelectedDate from the parent as a props
	const handleDateClick = (arg:any) => {
		setSelectedDate(arg?.date)
	}

	return (
		<div className='com-card-section'>
			<div className='row'>
				<div className='col-12'>
					<div className='card mb-6 calender-card-sec'>
						<div className='card-body px-8 py-10'>
							<div className="row mb-2">
								<div className="col-4"></div>
								<div className="col-4">
									<h4 className="flex-fill font-18 fw-boldest text-black mt-2 mb-0 text-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; My Calendar </h4>
								</div>
								<div className="col-4 form-add-design">
									<select className="form-control form-select fw-bolder form-control-solid w-auto ms-auto pt-2 pb-2"
									>
										<option selected disabled>Please Select </option>
										<option>Scheduled </option>
										<option>Cancelled</option>
										<option>Sucessfull</option>
										<option>Unsucessfull</option>
									</select>
								</div>
								{/* <div className="col-12 form-add-design">
									<div className="d-flex justify-content-around">
										<div className="">&nbsp;</div>
										<h4 className="flex-fill font-14 fw-boldest text-black mt-1 mb-2 text-center"> My Calendar </h4>
										<div className="">
											<select className="form-control form-select fw-bolder form-control-solid"
											>
												<option selected disabled>Please Select </option>
												<option>Scheduled </option>
												<option>Cancelled</option>
												<option>Sucessfull</option>
												<option>Unsucessfull</option>
											</select>
										</div>
									</div>
								</div> */}
							</div>
							{/* <div className="row">
								<div className="col-12">
									<div className="border-bottom pb-2">
										<div className="row">
											<div className="col-md-6">
												<h4 className="font-14 fw-boldest text-black mt-1 mb-2"> My Calendar </h4>
											</div>
											<div className="col-md-6 text-end">
												<select className="form-select" aria-label="Default select example">
													<option selected>Open this select menu</option>
													<option value="1">One</option>
													<option value="2">Two</option>
													<option value="3">Three</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div> */}
							<div className='row'>
								<div className='col-12'>
									<div className='row'>
										<div className='new-calender'>
											<FullCalendar
												plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
												headerToolbar={{
													left: 'prev,next,today',
													center: 'title',
													right: 'timeGridWeek,timeGridDay',
												}}
												initialView='timeGridDay'
												editable={true}
												selectable={true}
												selectMirror={true}
												dayMaxEvents={true}
												events={eventState} // alternatively, use the `events` setting to fetch from a feed
												dateClick={handleDateClick}
											/>
										</div>
									</div>
								</div>
								<div className='col-12'>
									<div className='row pb-13 pt-10'>
										<div className='col-12'>
											{/* <div className='calendar_div_2'>
												<div className='corrective me-10'>
													<div className='box red me-4'></div>
													<p className='mb-0'> Scheduled Visits</p>
												</div>
												<div className='corrective me-10'>
													<div className='box blue me-4'></div>
													<p className='mb-0'> Move-in</p>
												</div>
												<div className='corrective'>
													<div className='box green me-4'></div>
													<p className='mb-0'> Move-out</p>
												</div>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
