import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import avtaricon from '../../../assets/images/pm/avatar-icon.jpeg'
import { PropertyRequirementPopup } from '../../pm-contacts/Contacts/PropertyRequirementPopup'
import { SelectListingPopup } from '../../pm-contacts/Contacts/SelectListingPopup'
import { ContactModal } from '../ContactModel'
import { AddContactModal } from '../../pm-contact-leads/components/AddContactModal'
import { Selectlead } from '../Selectleadmodal'

export function OpportunitesAdd() {
    return (
        <>
            <div className="row align-items-center mb-5 community">
                <div className="col-md">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link> Create Opportunity
                    </h5>
                </div>
                <div className="col-auto">
                    <a href="/opportunites/list"  type='submit' className='btn btn-primary fw-bold px-10 py-3 text-uppercase'>
                        Opportunities List
                    </a>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <div className="row mb-3 pr-3">
                        <div className="col col-xl-3 col-md-6">
                            <div className="border p-3 rounded">
                                <div className="row ">
                                    <div className="col-sm-auto">
                                        <img src={avtaricon} className="img-fluid border rounded-circle" width="45px" />
                                    </div>
                                    <div className="col-sm align-items-center justify-content-between d-flex mt-2 mt-sm-0">
                                        <a  data-bs-toggle="modal" data-bs-target="#ContactPopup" className=" text-decoration-none text-warning font-10 font-weight-semibold add-or-select-contact">
                                            Add or Select Contact                                                                    </a>
                                        <a  data-bs-toggle="modal" data-bs-target="#ContactPopup" className="text-decoration-none text-warning font-10 font-weight-semibold text-right lh-1 d-none d-sm-block">
                                            <i className="fas fa-arrows-alt-h font-10 mr-1"></i> Change
                                        </a>
                                    </div>
                                    <div className="col-12">
                                        <div id="contact-empty" className="text-danger float-left w-100 small"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto grad d-inline-flex rounded">
                            <div className="text-right d-inline-block m-auto">
                                <span className="d-inline-block bg-warning2 rounded px-4 py-2 text-center font-12 text-white"><b>Lead Score<br />25</b></span>
                            </div>
                        </div>
                    </div>
                    <div className='row form-add-design'>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">REFERENCE</label>
                                <input type="text" className="form-control fw-bolder form-control-solid active" disabled value="REF-L-1673243180" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">CREATED BY</label>
                                <input type="text" className="form-control fw-bolder form-control-solid active" disabled value="Site Admin" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">ENQUIRY DATE</label>
                                <input type="date" className="form-control fw-bolder" disabled value="08-01-2023" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Souce</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option >Please Select </option>
                                    <option>Google</option>
                                    <option>Facebook</option>
                                    <option>Twitter</option>
                                    <option>Instagram</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Lead Type</label>
                                <select className="form-control form-select fw-bolder form-control-solid">
                                    <option >Please Select </option>
                                    <option>Lease</option>
                                    <option>Buyer</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-inline w-100 text-uppercase font-13"> Finance </label>
                                <label className="text-end font-10 mt-2">(Only for buyer option)</label>
                                <select className="form-control form-select fw-bolder form-control-solid">
                                    <option >Please Select </option>
                                    <option>Loan (Approved)</option>
                                    <option>Loan ( Not Approved)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-5">
                <div className="card-body">
                    <div className="row mb-3 pr-3">
                        <div className="col col-xl-3 col-md-6">
                            <div className="border p-3 rounded">
                                <div className="row ">
                                    <div className="col-sm-auto">
                                        <img src={avtaricon} className="img-fluid border rounded-circle" width="45px" />
                                    </div>
                                    <div className="col-sm align-items-center justify-content-between d-flex mt-2 mt-sm-0">
                                        <a data-bs-toggle="modal" data-bs-target="#ContactPopup" className=" text-decoration-none text-warning font-10 font-weight-semibold add-or-select-contact">
                                            Add or Select Contact                                                                    </a>
                                        <a data-bs-toggle="modal" data-bs-target="#ContactPopup" className="text-decoration-none text-warning font-10 font-weight-semibold text-right lh-1 d-none d-sm-block">
                                            <i className="fas fa-arrows-alt-h font-10 mr-1"></i> Change
                                        </a>
                                    </div>
                                    <div className="col-12">
                                        <div id="contact-empty" className="text-danger float-left w-100 small"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-auto grad d-inline-flex rounded">
                            <div className="text-right d-inline-block m-auto">
                                <span className="d-inline-block bg-warning2 rounded px-4 py-2 text-center font-12 text-white"><b>Lead Score<br />25</b></span>
                            </div>
                        </div> */}
                    </div>
                    <div className='row form-add-design'>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Status</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >

                                    <option selected>New</option>
                                    <option>Active</option>
                                    <option>Lost</option>
                                    <option>Won</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">SUB STATUS</label>
                                <select className="form-control form-select fw-bolder form-control-solid">

                                    <option selected>Not Yet Contacted</option>
                                    <option>Contacted</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-7">
                                <label className='font-14 fw-bold mb-3 text-uppercase'>STATUS REMARKS</label>
                                <textarea
                                    className='form-control'
                                    data-kt-autosize='true'
                                    rows={3}
                                    placeholder={'Remarks'}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">PRIORITY</label>
                                <select className="form-control form-select fw-bolder form-control-solid">

                                    <option>Urgent</option>
                                    <option>Low</option>
                                    <option>High</option>
                                    <option selected >Normal</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">LEAD STATUS</label>
                                <select className="form-control form-select fw-bolder form-control-solid">

                                    <option selected>cold</option>
                                    <option>Hot</option>
                                    <option>Warm</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="text-warning theme-color font-12 mb-4">Property Requirements
                                    <a href="javascript:void(0);" className="text-decoration-none text-gray-800" data-toggle="tooltip" data-placement="top" title="Multiple property requirements can be added against a lead. For example, when a buyer has given two different locations (e.g. Springs or Ranches) they would buy in, both areas can be recorded as seperate requirements.">
                                        <i className="fas fa-question-circle font-10 text-grey-700"></i>
                                    </a>
                                </h6>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 req-card-append lead-add-height h-auto">

                                        <div className="row no-gutters req-card rounded p-2 mb-1">
                                            <div className="col-sm">
                                                <button type="button" className="btn theme-color font-14 font-weight-semibold bg-transparent d-flex flex-wrap px-0 border-0 align-items-center">
                                                    <div className="req-collapse-icon" data-toggle="collapse" data-target="#req-item<?= $i ?>">
                                                        <i className="fa fa-angle-right me-2"></i> Requirement
                                                    </div>
                                                    <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-req-modal" className="text-decoration-none text-gray-800 font-12 rounded-circle ms-2">
                                                        <i className="fas fa-plus-square"></i>
                                                    </a>
                                                </button>
                                            </div>
                                            <div className="col-sm-auto">
                                                <button type="button" className="btn btn-primary"><span className="fas fa-plus"></span></button>
                                            </div>
                                            <div className="col-md-12">
                                                <div id="req-item1" className="collapse show bg-white p-3 mt-2 property1-text">
                                                    <div className="border p-2 selectBorder">
                                                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-enq-modal" className="text-decoration-none font-weight-semibold text-warning selectPropEnquiry">
                                                            Select the Property Enquired For
                                                        </a>
                                                    </div>
                                                    <div className="row prop-single-record">
                                                        <div className="col-md-12 my-2 text-end remove-button">
                                                            <Link to="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-enq-modal" className="text-decoration-none d-inline-flex align-items-center bg-primary text-white py-1 px-3 rounded check-property-select"> Property </Link>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="card radius-15 bg-light mb-1">
                                                                <div className="card-body p-3">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 pb-2">test</h6>
                                                                                </div>
                                                                                <div className="col-auto"><a href="#" className="text-decoration-none font-12 text-danger d-flex justify-content-end remove-prop-enq"><i className="fas fa-times"></i> </a></div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-4 col-lg-3 col-sm-6">
                                                                                    <div className="">
                                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Property Ref No</span></h6>
                                                                                        <p className="font-12">REF-UN-1689251187</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-lg-3 col-sm-6">
                                                                                    <div className="">
                                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Community</span></h6>
                                                                                        <p className="font-12 ellipsis">null</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-lg-3 col-sm-6">
                                                                                    <div className="">
                                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Building</span></h6>
                                                                                        <p className="font-12 ellipsis">null</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-lg-3 col-sm-6">
                                                                                    <div className="">
                                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Price</span></h6>
                                                                                        <p className="font-12">AED 22.00</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-lg-3 col-sm-6">
                                                                                    <div className="">
                                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Created at</span></h6>
                                                                                        <p className="font-12">13/07/2023</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 col-12">
                                                                                    <div className="">
                                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Recent Action</span></h6>
                                                                                        <p className="font-12">13/07/2023</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <PropertyRequirementPopup/>     
            <SelectListingPopup/>
            <ContactModal/>
            <Selectlead/>
            <AddContactModal/>

        </>
    )
}
