import React, { useState, useEffect, useRef } from 'react'
import { Link,useHistory,useLocation } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { saveAccessCard } from '../../../../../../../../constants/axios/apis'
import { RequestFailedPopup } from '../../../../../../../SuccessfulPopup/RequestFailedPopup'

const saveAccessCardRequest = Yup.object().shape({
  // card_number: Yup.array().min(2, 'Please fill out all card numbers'),
  // signature: Yup.string().required('Please add your signature'),
})

export function BuildingAccessCard({ count }: any) {

  let params: any = {};
  params = useLocation();
  const { approval } = params.state;
  const defaultCount = count
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const [cardInputs, setCardInputs] = useState<any>([])
  const [inputCount, setInputCount] = useState<any>(defaultCount)
  const allotedAccessId = (approval?.allotted_access_card?.find((el: any) => el.name === "Building Access Card"))?.card_type_id


  const initialValues = {
    property_id: approval.propertyID,
    user_id: approval.user_id,
    allotted_access_id: allotedAccessId,
    card_number: [],
    signature: '',
    flag: 1,
    approval_id: approval.id
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: saveAccessCardRequest,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        saveAccessCard(
          values.property_id,
          values.user_id,
          values.allotted_access_id,
          values.card_number,
          values.signature,
          values.flag,
          values.approval_id
        )
          .then((response: any) => {
            setLoading(false)
            if (response.errorCode === 0) {
              clearForm()
              successRef.current.click()             
            } else {
              setMessage(response.errorDescription)
              failRef.current.click()
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Invalid Data Provided')
          })
      }, 1000)
    },
  })

  const sigCanvas = useRef<any>()

  function clearSignature() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }

  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  const clearForm = () => {
    formik.resetForm()
    formik.values.card_number?.map((card: any, index: any) =>
      formik.setFieldValue(`card_number[${index}]`, '')
    )
    formik.setFieldValue('card_number', [])
    clearSignature()
  }

  useEffect(() => {
    const inputs = new Array(defaultCount).fill(0).map((item, index) => (
      <input
        type='text'
        className='form-control fw-bolder form-control-solid expected-rent-input'
        name={`card_number[${index}]`}
        onChange={formik.handleChange}
        value={formik.values.card_number?.[index]}
        autoComplete='off'
        required
        maxLength={25}
        disabled
      />
    ))
    setCardInputs(inputs)
  }, [])


  const handleAdd = () => {
    const newCount = inputCount
    const newInput = <input
      type='text'
      className='form-control fw-bolder form-control-solid'
      name={`card_number[${newCount}]`}
      onChange={formik.handleChange}
      value={formik.values.card_number?.[newCount]}
      autoComplete='off'
      required
      maxLength={25}
    />
    setCardInputs([...cardInputs, newInput]);
    setInputCount(newCount + 1);
  }

  const handleRemove = () => {
    const lastIndex = inputCount - 1;

    if (inputCount > defaultCount) {
      const updatedInputs = cardInputs
      updatedInputs.pop();
      setCardInputs(updatedInputs);
      setInputCount(inputCount - 1);

      let updatedValues = [...formik.values.card_number]
      updatedValues.pop()
      formik.setFieldValue('card_number', updatedValues)
    }
  }


  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='accordion-item mb-5'>
              <h2 className='accordion-header' id='headingTwo'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  Building Access Card 
                </button>
              </h2>
              <div
                id='collapseTwo'
                className='accordion-collapse collapse'
                aria-labelledby='headingTwo'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='accessCardRequestForm'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='row form-add-design'>
                      {formik.touched.card_number && formik.errors.card_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.card_number}
                            </span>
                          </div>
                        </div>
                      )}
                     {cardInputs?.map((cardInput: any, index: any) => {
                        return (
                          <div key={index} className='col-sm-6 col-md-4 col-12 label_close'>
                            <div className='mb-5'>
                              <label className='form-label fw-bolder'> Card Number {index + 1} </label>
                              <div className="d-flex">
                                {cardInput}

                                {
                                  (index === (inputCount - 1)) && (index > (defaultCount - 1)) &&
                                  <button type='button' className='bg-white cancel-bts px-7'
                                    onClick={handleRemove}
                                  >
                                    <i className="bi bi-x-circle font-18 text-black"></i>
                                  </button>
                                }

                              </div>

                            </div>
                          </div>
                        )
                      })}
                      {/* {
                        (count > inputCount) &&
                        <div className="col-sm-6 col-md-4 col-12">
                          <div className="mb-6">
                            <label className="form-label fw-bolder d-block">&nbsp;</label>
                            <button type="button" className='btn btn-primary fw-bold px-10 py-3'
                              onClick={handleAdd}
                            >
                              <span className='indicator-label'>Add More</span>
                            </button>
                          </div>
                        </div>
                      } */}
                      <div className='col-md-12 col-12'>
                        <div className='row'>
                          <div className='col-sm-8 col-md-4 col-12'>
                            <div className='mb-md-0 mb-4'>
                              <label className='form-label fw-bolder'> Customer Signature </label>
                              <div className='w-100 form-control'>
                                <SignatureCanvas
                                  ref={sigCanvas}
                                  penColor='black'
                                  canvasProps={{ height: 80, width: 320, className: 'sigCanvas' }}
                                  onEnd={handleSignature}
                                />
                              </div>
                              {formik.touched.signature && formik.errors.signature && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.signature}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-sm-4 col-md-4 my-auto col-12'>
                            <div className='mt-0 mt-md-3 mb-4 mb-md-0'>
                              <button
                                type='button'
                                className='btn btn-light fw-bold px-10 py-2'
                                onClick={clearSignature}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 text-end'>
                        <button
                          type='button'
                          className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                          onClick={() => clearForm()}
                        >
                          Cancel
                        </button>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                          {!loading && <span className='indicator-label'>Save</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        ref={successRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <RequestFailedPopup message={message} />
    </>
  )
}
