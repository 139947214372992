import { Link } from 'react-router-dom';
import avtaricon from '../../../../assets/images/pm/avatar-icon.jpeg'
import { Notes } from './Tabs/Notes'
import { Reminders } from './Tabs/Reminder'
import { Activities } from './Tabs/Activities'
import { Offers } from './Tabs/offers'
import { Doccuments } from './Tabs/Doccuments'
import avatar from "../../../../assets/images/pm/avatar-icon.jpeg"
import { useState } from 'react';
import {QualifyModal} from './QualifyModal'
export function LeadView() {

  const [tabPosition, setTabPosition] = useState("1");
  const opportunityTab = (i: any) => {
      setTabPosition(i)
  }

  return (
    <>
        <div className="row align-items-center mb-5 community opportunity">
            <div className="col-md">
                <h5 className="theme-color font-22 d-inline-block float-left">
                    <Link to={'/opportunites/list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                        <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                    </Link>Lead Details
                </h5>
            </div>
            <div className="col-auto">
                <Link to="edit" className='btn btn-outline-primary fw-bold px-10 py-3 text-uppercase me-3'> Edit </Link>
                <Link to className='btn btn-primary fw-bold px-10 py-3 text-uppercase' data-bs-toggle="modal" data-bs-target="#quality_modal">Quality </Link>
            </div>
        </div>
        <div className="card mb-5">
            <div className="card-body">
                <div className="row mb-3">
                    <div className="col-md-12 col-lg-4">
                        <div className="mb-3 mb-md-3 mb-lg-0 h-100">
                            <div className="">
                                <div className="row no-gutters">
                                    <div className="col-md-2 col-2">
                                        <div className="lead-prof-img position-relative border rounded">
                                            <img src={avtaricon} className="img-fluid rounded" alt="Image" />
                                        </div>
                                    </div>
                                    <div className="col-md-10 col-10">
                                        <div className="card-body py-0 px-2">
                                            <h6 className="card-title font-weight-600 text-black mb-3">Abdul</h6>
                                            <ul className="p-0 m-0 lead-media d-flex">
                                                <li className="list-unstyled">
                                                    <Link to target="_blank" className="text-decoration-none border rounded-circle d-block me-3 text-center">
                                                        <i className="fab fa-facebook-f text-black "></i>
                                                    </Link>
                                                </li>
                                                <li className="list-unstyled">
                                                    <Link to target="_blank" className="text-decoration-none border rounded-circle d-block me-3 text-center">
                                                        <i className="fab fa-linkedin-in text-black "></i>
                                                    </Link>
                                                </li>
                                                <li className="list-unstyled">
                                                    <Link to target="_blank" className="text-decoration-none border rounded-circle d-block me-3 text-center">
                                                        <i className="fab fa-twitter text-black "></i>
                                                    </Link>
                                                </li>
                                                <li className="list-unstyled">
                                                    <Link to="javascript:void(0);" className="text-decoration-none border rounded-circle d-block me-3 text-center">
                                                        <i className="fab fa-whatsapp text-black "></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row form-add-design">
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2">
                            <span className="text-uppercase text-black">Opportunity Status</span>
                        </h6>
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#lead-status-modal">
                            <p className="font-10 text-muted badge badge-blue border-0" id="updated_status"> Active </p>
                        </a>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Opportunity Sub Status</span> </h6>
                        <p className="text-muted mb-7" id="updated_sub_status">Offer Made</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Opportunity Type</span> </h6>
                        <p className="text-muted mb-7">Buyer</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">First Name</span> </h6>
                        <p className="text-muted mb-7">Radha</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Last Name</span> </h6>
                        <p className="text-muted mb-7">Krishna</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Designation</span> </h6>
                        <p className="text-muted mb-7">-</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Email</span> </h6>
                        <p className="text-muted mb-7">radha.k@exalogic.co</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Phone Number</span> </h6>
                        <p className="text-muted mb-7">-</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Alternate Phone</span> </h6>
                        <p className="text-muted mb-7">-</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Addresses</span></h6>
                        <p className="text-muted mb-7">139-A, 1st FloorJanapriya apartments saroornagar</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">City</span> </h6>
                        <p className="text-muted mb-7">Hyderabad</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">State</span> </h6>
                        <p className="text-muted mb-7">Telangana</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Country</span> </h6>
                        <p className="text-muted mb-7">United Arab Emirates</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Zip</span> </h6>
                        <p className="text-muted mb-7">300065</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Select Language</span> </h6>
                        <p className="text-muted mb-7">-</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercas text-black">Source</span> </h6>
                        <p className="text-muted mb-7">Google</p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <h6 className="text-black mt-0 mb-2"><span className="text-uppercase text-black">Assigned To</span> </h6>
                        <p className="text-muted mb-7 mt-2"><img src={avatar} alt="Image" className="mr-1 img-fluid rounded-circle" width="35px" /> -</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="card mb-5">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                            <h6 className="font-14 text-black m-0 font-weight-600">Interested Units </h6>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12 lead-det-prof pr-2">
                                <div className="row no-gutters req-card bg-ec rounded p-2 mb-3">
                                    <div className="col-md-12">
                                        <div className="p-3 theme-color font-14 font-weight-semibold bg-transparent d-flex flex-wrap align-items-center">
                                            <div className="req-collapse-icon" data-toggle="collapse" data-target="#req-item1">
                                                <i className="fa fa-angle-right mr-2"></i> Requirement 1
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div id="req-item1" className="collapse show bg-white p-3 mt-2 property1-text">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card radius-15 bg-light">
                                                        <div className="card-body p-3">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 border-bottom pb-2">
                                                                                Property Requirements
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">CATEGORY</span></h6>
                                                                                <p className="font-12 mb-0">---</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">MIN BEDS</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis">--- </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">MAX BEDS</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis">---</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">EMIRATE</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis">Abu Dhabi</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">MIN PRICE</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis">---</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">MAX PRICE</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis">---</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">COMMUNITY</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis">WIlton Park Residencies</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">MIN AREA</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis"></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">Max AREA</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis"></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">UNITS</span></h6>
                                                                                <p className="font-12 mb-0 ellipsis">---</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">LOCATION</span></h6>
                                                                                <p className="font-12 ellipsis mb-0">Akoya Oxygen</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                            <div className="">
                                                                                <h6 className="font-12 text-black m-0"><span className="text-uppercase">FURNISHED TYPE</span></h6>
                                                                                <p className="font-12 ellipsis mb-0">---</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='card '>
            <div className="card-body">
            <div className='d-flex overflow-auto border-bottom'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-4 flex-nowrap'>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(1)}>
                            Reminder
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(2)}>
                            Note
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(3)}>
                            Doccuments
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(4)}>
                            Activities
                        </Link>
                    </li>
                </ul>
            </div>

            {
            tabPosition == '1' ? <Reminders /> :
            tabPosition == '2' ? <Notes /> :
            tabPosition == '3' ? <Doccuments /> :
            tabPosition == '4' ? <Activities /> : ''
            }

          <QualifyModal/>

            </div>
        </div>

      </>
  )
}
