import React, { useState, FC, useRef, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../../../../assets/images/Left_Arrow.png'
import { MoveInDetailsTab } from './MoveInDetailsTab/MoveInDetailsTab'
import { Details } from './Details/Details'
import { MainDoorKeys } from './MainDoorKeys/MainDoorKeys'
import { BuildingAccessCard } from './BuildingAccessCard/BuildingAccessCard'
import { ParkingAccessCard } from './ParkingAccessCard/ParkingAccessCard'
import {
  submitMoveInApproval,
  getApprovals,
  submitFmsmHandoverToFcss,
} from '../../../../../../../constants/axios/apis'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../../../../../../constants/generics/userCheck'
import { CustomRequestSuccessfulPopup } from '../../../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { useTranslation } from 'react-i18next'
import { CustomLoader2 } from '../../../../../../../constants/generics/customActios'

const MoveInDetailsWrapper: React.FC = () => {
  const location = useLocation<any>()
  let approvalId = location?.state?.approval?.id ? location?.state?.approval?.id : {}

  const successRef = useRef<any>()
  const failRef = useRef<any>()

  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')

  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [approvals, setApprovals] = useState<any>([])
  const [reloadData, setReloadData] = useState<any>(true)
  const [approvalDataDetails, setApprovalDataDetails] = useState<any>({})
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState('')

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  const [submitError, setSubmitError] = useState('')
  const buttonEl: any = useRef(null)
  const { t } = useTranslation();

  // useEffect(() => {
  //     getAllotedAccessId().then(({ data }: any) => {
  //         setAccessCards(data.find((element: any) => element.propertyId === property_id))
  //     })
  // }, [property_id])

  useEffect(() => {
    if (reloadData) {
      // setLoading(true)
      // setApprovalDataDetails({})
      const newPayload = '?id=' + approvalId
      getApprovals(newPayload).then((response: any) => {
        if (response?.data && Array.isArray(response.data) && response.data.length > 0)
          setApprovalDataDetails(response.data[0])
        // setApprovalDataDetails(response.data)
        // setLoading(false)
        setReloadData(false)
      })
    }
  }, [approvalId, reloadData])

  const bacCount = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.no_of_cards

  const pacCount = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.no_of_cards
  const mdkCount = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Main Door Key'
  )?.no_of_cards
  let bacCardDetails
  let pacCardDetails

  if (approvalDataDetails?.card_details?.building_access_card) {
    bacCardDetails = approvalDataDetails?.card_details?.building_access_card?.card_number
    bacCardDetails = Object.values(bacCardDetails)
    bacCardDetails = bacCardDetails?.map((item: any) => item.number)
  }

  if (approvalDataDetails?.card_details?.parking_access_card) {
    pacCardDetails = approvalDataDetails?.card_details?.parking_access_card?.card_number
    pacCardDetails = Object.values(pacCardDetails)
    pacCardDetails = pacCardDetails.map((item: any) => item.number)
  }

  // FCSS Moved In
  const sendMoveInApproval = () => {
    setLoading(true)
    setSubmitError('')
    setTimeout(() => {
      submitMoveInApproval(approvalDataDetails.approval_id,approvalDataDetails.object_id, [
        approvalDataDetails?.card_details?.building_access_card?.allotted_access_id,
        approvalDataDetails?.card_details?.parking_access_card?.allotted_access_id,
      ])
        .then((response: any) => {
          setLoading(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          } else if (response.errorCode === 0) {
            // formik.resetForm()
            setSubmitSuccessMsg(response.description)
            // setLoading(false)
            var ev = new Event('click', { bubbles: true })
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/approvals/closed/movein')
            }, 4000)
          } else {
            setSubmitError(t("Some error occured. Please try again !"))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          }
          setLoading(false)
        })
        //   if (response.errorCode === 0) {
        //     history.push('/approvals/closed/movein', {})
        //     successRef.current.click()
        //   } else {
        //     setMessage(response.errorDescription)
        //     failRef.current.click()
        //   }
        // })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(t("Invalid Data Provided"))
          setTimeout(() => {
            setSubmitError('')
          }, 5000)
        })
    }, 1000)
  }

  // useEffect(() => {
  //   setLoading(true)
  //   const newPayload = '?id=' + approvalData?.id
  //   getApprovals(newPayload).then((response: any) => {
  //     setApprovals(response.data)
  //     setLoading(false)
  //   })
  // }, [])

  // FMSM Handover to FCSS
  const sendFMSMHandoverFCSS = () => {
    setLoading(true)
    setSubmitError('')
    setTimeout(() => {
      submitFmsmHandoverToFcss(
        approvalDataDetails?.access_card_slot_id,
        approvalDataDetails?.propertyID,
        approvalDataDetails?.user_id
      )
        .then((response: any) => {
          // setLoading(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          } else if (response.errorCode === 0) {
            // formik.resetForm()
            // setLoading(false)
            var ev = new Event('click', { bubbles: true })
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/approvals/closed/movein')
            }, 4000)
          } else {
            setSubmitError(t("Some error occured. Please try again !"))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          }
          setLoading(false)
        })
        //   if (response.errorCode === 0) {
        //     history.push('/approvals/closed/movein', {})
        //     successRef.current.click()
        //   } else {
        //     setMessage(response.errorDescription)
        //     failRef.current.click()
        //   }
        // })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(t("Invalid Data Provided"))
          setTimeout(() => {
            setSubmitError('')
          }, 5000)
        })
    }, 1000)
  }

  // FMSM
  const buildingAccessCardID = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.id
  const parkingAccessCardID = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.id

  const enabledMoveInButton =
    approvalDataDetails?.card_details?.building_access_card?.signature?.length &&
    approvalDataDetails?.card_details?.parking_access_card?.signature?.length // FCSS Moved In Button Enabled

  const enabledFMSMHandOverToFCSSButton =
    approvalDataDetails?.card_details?.building_access_card?.staff_signature?.length &&
    approvalDataDetails?.card_details?.parking_access_card?.staff_signature?.length // FMSM Hand Over To FCSS Button Enabled

  const buildingSaved =
    user_role === 'FCSS'
      ? approvalDataDetails?.card_details?.building_access_card?.signature?.length
        ? true
        : false
      : approvalDataDetails?.card_details?.building_access_card?.staff_signature?.length
        ? true
        : false
  const parkingSaved =
    user_role === 'FCSS'
      ? approvalDataDetails?.card_details?.parking_access_card?.signature?.length
        ? true
        : false
      : approvalDataDetails?.card_details?.parking_access_card?.staff_signature?.length
        ? true
        : false

  return (
    <>
      {/* <div className='com-card-section'>
        {
          loading ?
            <CustomLoader2 /> : */}
            <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
              <div className='col-xxl-12 mt-0'>
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                      <Link to={'/approvals/upcoming/movein'} className=''>
                        <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                        {/* <span className='text-black'>{t("Move In Approval")}</span> */}
                      </Link>
                      <span className="text-black">{t("Issue Access Cards")}</span>
                    </h4>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 mb-7'>
                    <Details approval={approvalDataDetails} />
                  </div>
                </div>

                <Switch>                 

                  <Route path='/fcss-moveinDetails/building-access-card'>
                    <MoveInDetailsTab buildingSaved={buildingSaved} parkingSaved={parkingSaved} />
                    <BuildingAccessCard
                      count={bacCount}
                      cardDetails={bacCardDetails}
                      approval={approvalDataDetails}
                      allotedAccessID={
                        user_role === 'FCSS'
                          ? approvalDataDetails?.card_details?.building_access_card?.allotted_access_id
                          : buildingAccessCardID
                      }
                      cardSignature={
                        user_role === 'FCSS'
                          ? approvalDataDetails?.card_details?.building_access_card?.signature
                          : approvalDataDetails?.card_details?.building_access_card?.staff_signature
                      }
                      cardSignature2={
                        user_role === 'FMSM'
                          ? approvalDataDetails?.card_details?.building_access_card?.signature
                          : ''
                      } //Only FMSM used for customer signature
                      setReloadData={setReloadData}
                    />
                  </Route>

                  <Route path='/fcss-moveinDetails/parking-access-card'>
                    <MoveInDetailsTab buildingSaved={buildingSaved} parkingSaved={parkingSaved} />
                    <ParkingAccessCard
                      count={pacCount}
                      cardDetails={pacCardDetails}
                      approval={approvalDataDetails}
                      allotedAccessID={
                        user_role === 'FCSS'
                          ? approvalDataDetails?.card_details?.parking_access_card?.allotted_access_id
                          : parkingAccessCardID
                      }
                      cardSignature={
                        user_role === 'FCSS'
                          ? approvalDataDetails?.card_details?.parking_access_card?.signature
                          : approvalDataDetails?.card_details?.parking_access_card?.staff_signature
                      }
                      cardSignature2={
                        user_role === 'FMSM'
                          ? approvalDataDetails?.card_details?.parking_access_card?.signature
                          : ''
                      } //Only FMSM used for customer signature
                      setReloadData={setReloadData}
                    />
                  </Route>

                  <Route path='/fcss-moveinDetails/main-door-keys'>
                    <MoveInDetailsTab buildingSaved={buildingSaved} parkingSaved={parkingSaved} />
                    <MainDoorKeys count={mdkCount} />
                  </Route>
                  
                </Switch>
                {user_role === 'FCSS' ? (
                  <div className='row'>
                    <div className='col-12 text-end mt-5'>
                      <button
                        onClick={() => sendMoveInApproval()}
                        disabled={!enabledMoveInButton}
                        type='submit'
                        className='btn btn-primary fw-bold px-10 py-3'
                      >
                        {!loading && <span className='indicator-label'>{t("Moved In")}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {t("Please wait...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {submitError && (
                      // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
                      <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                        {submitError}
                      </div>
                    )}
                    <button
                      ref={buttonEl}
                      data-bs-target='#req-submitted-popup'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      type='submit'
                      className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                    >
                      {t("Submit")}
                    </button>
                    <CustomRequestSuccessfulPopup
                      successMessage={'Moved In Successfully'}
                      // href={'/approvals/closed/movein'}
                      close={false}
                    />
                  </div>
                ) : (
                  <div className='row'>
                    <div className='col-12 text-end mt-5'>
                      <button
                        onClick={() => sendFMSMHandoverFCSS()}
                        disabled={!enabledFMSMHandOverToFCSSButton}
                        type='submit'
                        className='btn btn-primary fw-bold px-10 py-3'
                      >
                        {!loading && <span className='indicator-label'>{t("HandOver")}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {t("Please wait...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {submitError && (
                      // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
                      <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                        {submitError}
                      </div>
                    )}
                    <button
                      ref={buttonEl}
                      data-bs-target='#req-submitted-popup'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      type='submit'
                      className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                    >
                      {t("Submit")}
                    </button>
                    <CustomRequestSuccessfulPopup
                      successMessage={'Handover Successfully'}
                      // href={'/approvals/closed/movein'}
                      close={false}
                    />
                  </div>
                )}
              </div>
            </div>
        {/* }</div> */}
    </>
  )
}

export { MoveInDetailsWrapper }
