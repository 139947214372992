import React, { useState, useEffect } from 'react'

export function Activities() {
    return (
        <>

            <div className="row activity mt-4">
                <div className="lead-det-prof pr-2">
                    <div className="card radius-8 mb-2 bg-light">
                        <div className="card-body p-2">
                            <h6 className="font-13 font-weight-600 text-black">Offer Created <span className="font-10  float-right font-weight-normal">17/07/2323</span></h6>
                            <p className="font-10 mb-0">createOffer</p>
                        </div>
                    </div>
                    <div className="card radius-8 mb-2 bg-light">
                        <div className="card-body p-2">
                            <h6 className="font-13 font-weight-600 text-black">Lead Updated <span className="font-10  float-right font-weight-normal">17/07/2323</span></h6>
                            <p className="font-10 mb-0">add_lead</p>
                        </div>
                    </div>
                    <div className="card radius-8 mb-2 bg-light">
                        <div className="card-body p-2">
                            <h6 className="font-13 font-weight-600 text-black">Lead Created <span className="font-10  float-right font-weight-normal">17/07/2323</span></h6>
                            <p className="font-10 mb-0">add_lead</p>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

