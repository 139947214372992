import React, {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import {RefundsTab} from './components/RefundsTab/RefundsTab';
import {OpenRequests} from './components/OpenRequests/OpenRequests';
import {ClosedRequests} from './components/ClosedRequests/ClosedRequests';
import {CheckBoxPopup} from '../pm-unrenewed-leases/components/CheckBoxPopup/CheckBoxPopup';
import {AddedSuccessfulPopup} from '../SuccessfulPopup/AddedSuccessfulPopup'
import {ChequeDelayRequestDetails} from "./components/Details/ChequeDelayRequestDetails/ChequeDelayRequestDetails";
import {AddChargesPopup} from "./components/Details/AddChargesPopup/AddChargesPopup";
import {ApplyForWaiverPopup} from "./components/Details/ApplyForWaiverPopup/ApplyForWaiverPopup";

const PMRefundsPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-xxl-12 mt-0'>
            <Switch>
                <Route path='/pm-refunds/open-requests'>
                    <RefundsTab />
                    <OpenRequests />
                    <CheckBoxPopup />
                    <AddedSuccessfulPopup />
                </Route>
                <Route path='/pm-refunds/closed-requests'>
                    <RefundsTab />
                    <ClosedRequests />
                    <CheckBoxPopup />
                    <AddedSuccessfulPopup />
                </Route>
                <Route path='/pm-refunds/cheque-delay-request-details'>
                    <ChequeDelayRequestDetails />
                    <AddChargesPopup />
                    <AddedSuccessfulPopup />
                    <ApplyForWaiverPopup />
                </Route>
            </Switch>
        </div>
    </div>
    </>
);

const PMRefundsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <PMRefundsPage />
        </>
    )
};

export {PMRefundsWrapper}
