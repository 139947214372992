import React, { useState, useEffect, useRef } from 'react';
import { RequestDetails } from "./RequestDetails"
import { ApprovalHistory } from "./ApprovalHistory"

export function IssueDetails({ approvalData }: any) {
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row form-add-design mb-8">
                                        <div className="col-12">
                                            <h4 className="card-title mb-7 font-14 text-primary">Request Details</h4>
                                        </div>
                                        <div className="col-12">
                                            <h4 className="card-title mb-7 font-14">Are you using a contractor to carry out work? : Yes/No</h4>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Tenant Name </h6>
                                            <span className="text-black fw-bold font-14"> Mohammed </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Contractor Name</h6>
                                            <span className="text-black fw-bold font-14"> Ali </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Contractor's Company Name </h6>
                                            <span className="text-black fw-bold font-14"> Exalogic Tech </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Contractor Phone No. </h6>
                                            <span className="text-black fw-bold font-14"> +971 0123456789 </span>
                                        </div>
                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Comments </h6>
                                            <textarea
                                                className='form-control fw-bolder form-control-solid w-100'
                                                data-kt-autosize='true'
                                                rows={5}
                                                disabled
                                                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                             printer took a galley of type and scrambled it to make a type specimen book. It has survived
                                             not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                             It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
                                            />
                                        </div>
                                    </div>


                                    {/* <div className="row form-add-design mb-8">
                                        <div className="col-12">
                                            <h4 className="card-title mb-7 font-14 text-primary">Permit to Work Details</h4>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Company Name </h6>
                                            <span className="text-black fw-bold font-14"> Nation Tower </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Applicant's Name </h6>
                                            <span className="text-black fw-bold font-14">NTA03 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Applicant Designation </h6>
                                            <span className="text-black fw-bold font-14"> Mohammed </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Applicant's Contact Number </h6>
                                            <span className="text-black fw-bold font-14"> +971 0123456789 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Location of Activity </h6>
                                            <span className="text-black fw-bold font-14"> Ali </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">No. of Workers</h6>
                                            <span className="text-black fw-bold font-14"> Ali </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Permit Issue Date</h6>
                                            <span className="text-black fw-bold font-14"> +971 0123456789 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work Start Date</h6>
                                            <span className="text-black fw-bold font-14"> 11-Feb-2022 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work End Date</h6>
                                            <span className="text-black fw-bold font-14"> 12-Feb-2022 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work Start Time</h6>
                                            <span className="text-black fw-bold font-14"> 10:00 AM </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work End Time</h6>
                                            <span className="text-black fw-bold font-14"> 05:00 PM </span>
                                        </div>
                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Comments </h6>
                                            <textarea
                                                className='form-control fw-bolder form-control-solid w-100'
                                                data-kt-autosize='true'
                                                rows={5}
                                                disabled
                                                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                             printer took a galley of type and scrambled it to make a type specimen book. It has survived
                                             not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                             It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
                                            />
                                        </div>
                                    </div> */}




                                </div>
                                {/* <div className="col-12 fm-team-approvals">
                                    <div className="accordion approval-accordion" id="accordionExample">
                                        <RequestDetails />
                                        <ApprovalHistory />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}