import { Bar } from 'react-chartjs-2';

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Top Agents',
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };
  
const labels = ['Campaign 1', 'Campaign 2', 'Campaign 3', 'Campaign 4','Campaign 5','Campaign 6','Campaign 7','Campaign 8','Campaign 9','Campaign 10','Campaign 11'];

export const data = {
labels,
datasets: [
    {
        label: 'Total Leads',
        data: [600,700, 500, 800, 550,760, 200, 690, 800, 500,1100],
        backgroundColor: '#000',
        textColor: '#000'
    },
    {
        label: 'Fan',
        data: [1497, 1220, 1235, 1648, 1369,2209, 2131, 2359, 1871, 1834,1630],
        backgroundColor: '#ffa50f',
        textColor: '#ffa50f'
    }
],
};

export const Click = (props:any) => {

    return(
        <div className="com-card-section">
            <div className="row">
            </div>
            <div className="row">
                <div className="col-12">
                    <Bar options={options} data={data} width={100} height={30}/>
                </div>
            </div>
        </div>
    )
}
