import React, {useState, useEffect} from 'react'
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid'
import {getMyTeamUserList} from '../../../../constants/axios/apis'
import {Link, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../constants'

const columns = [
  {
    field: 'no',
    headerName: 'No',
    sortable: false,
    disableColumnMenu: true,
    width: 100,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'mobile',
    headerName: 'Mobile Number',
    width: 200,
  },
  {
    field: 'email',
    headerName: 'Email Id',
    width: 250,
  },
  {
    field: 'emirates_id',
    headerName: 'Emirates Id',
    width: 250,
  },
  {
    field: 'user_data',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 100,
    renderCell: (val: any) => {
      return (
        <Link
          to={{
            pathname: '/fc-su-myteam-details',
            state: {user: val.row.user_data, prevPath: val.row.prev_path},
          }}
        >
          <span className='view-option text-theme fw-bolder'>View</span>
        </Link>
      )
    },
  },
]

export const Team: React.FC<any> = ({filterRole}) => {
  const location = useLocation()
  const [teamRow, setTeamRow] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)
  const [error, setError] = useState<any | null>()
  const [myTeamUsers, setMyTeamUsers] = useState<any>([])

  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )

  useEffect(() => {
    // getFcsMyTeamList(setFcsMyTeamList, userToken);
    const newPayload = '?user_role=FMT' + '&building_id=' + currentBuilding
    setLoading(true)
    getMyTeamUserList(newPayload)
      .then((response: any) => {
        if (response.errorCode === 0) {
          setMyTeamUsers(response?.data)
        } else if (response.errorCode === 1) {
          setMyTeamUsers([])
          setError(response.errorDescription)
        } else if (response === 400) {
          setMyTeamUsers([]) //TODO: api should return 200 even if there is no data
          // setErrorFCSS('No data')
        } else {
          setMyTeamUsers([])
          setError('Some error occured')
        }
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setMyTeamUsers([])
        setError('Some error occured')
        // console.log(e, 'error in team list');
      })
  }, [filterRole, currentBuilding])

  useEffect(() => {
    if (Array.isArray(myTeamUsers)) {
      const teamRow = myTeamUsers?.reverse().map((member: any, index: number) => {
        return {
          no: index + 1,
          name: member.first_name + ' ' + member.last_name,
          mobile: member.mobile,
          email: member.email,
          emirates_id: member.emirates_id,
          user_data: member,
          prev_path: location.pathname,
        }
      })
      setTeamRow(teamRow)
    } else setTeamRow([])
    setLoading(false)
  }, [myTeamUsers])

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body p-0'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <div className='rec-payment-act-sec'>
                    <div className='row'>
                      <div className='col-12'>
                        {!error && (
                          <div className='data_table'>
                            <div className='row'>
                              <div className='col-12'>
                                <div className='table-responsive'>
                                  <div style={{height: 400, width: '100%'}}>
                                    <DataGrid
                                      rows={teamRow}
                                      getRowId={(row) => row.no}
                                      columns={columns}
                                      pageSize={5}
                                      rowsPerPageOptions={[5]}
                                      loading={loading}
                                      disableSelectionOnClick
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {error && (
                          <div className='alert alert-danger w-100 mt-2' role='alert'>
                            {error}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
