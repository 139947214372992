import React, { FC, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next'
import uploadImg from "../../../../../assets/images/file-upload.svg";

export function StepFour() {
    const [file, setFile] = useState([] as any);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const { t } = useTranslation();

    function uploadSingleFile(e: any) {
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        console.log("file", file);
    }

    function upload(e: any) {
        e.preventDefault();
        console.log(file);
    }

    function deleteFile(e: any) {
        const s = file.filter((item: any, index: any) => index !== e);
        setFile(s);
        console.log(s);
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
            setUploadedFileName(inputRef.current.files[0].name);
    };

    const [dateValue, setDateValue] = useState(new Date());

    return (
        <>
            <div className="row form-add-design">
                <div className='col-12'>
                    <div className='mb-5'>
                        <h5 className='className="modal-title fw-bold font-18 cpt-headding'>
                            {t("Payment Plan Details")}
                        </h5>
                    </div>
                </div>
                {/**/}
                <div className="col-12">
                    <h5 className='text-black fw-500 font-16'>
                        {t("Security Deposit")}
                    </h5>
                </div>
                <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Payment Method")} </label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='Cheque'
                        />
                    </div>
                </div>
                <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Receipt Number")}</label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='12231231'
                        />
                    </div>
                </div>
                <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                    <div className='mb-5 date-picker'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Receipt Date")} </label>
                        {/* <input type="date"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='2021-04-29'
                        /> */}
                        <DatePicker
                            onChange={setDateValue}
                            value={dateValue}
                            className='form-control fw-bolder form-control-solid'
                            format="y-MM-dd"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="mb-5 upload-input">
                        <label className="form-label fw-bolder d-block">{t("Media Upload")}</label>
                        <input ref={inputRef} onChange={uploadSingleFile} type="file" className="d-none" multiple />
                        <button onClick={handleUpload} className={`w-100 py-3 ellipsis cheque-upload btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                            <span>{uploadedFileName ? uploadedFileName : <span className="txt">
                                <img
                                        alt="File"
                                        src={uploadImg}
                                className="position-relative"
                                loading="lazy"/>
                                {t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                        </button>
                    </div>
                </div>
                <div className="col-md-12 col-12">
                    {file.length > 0 &&
                        <div className="my-5 position-relative">
                            <label className="form-label fw-bolder d-block">{t("Attach Images/Video")}</label>
                            {file.map((item: any, index: any) => {
                                return (
                                    <div className="upload-image" key={item}>
                                        <img src={item} alt="" />
                                        <div className="close-icon" onClick={() => deleteFile(index)}>
                                            &times;
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>                
                <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Total Amount")} </label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='4,693'
                        />
                    </div>
                </div>
                <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Amount")} </label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='4,693'
                        />
                    </div>
                </div>
                <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("VAT")} </label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='0'
                        />
                    </div>
                </div>
                <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                    <div className='mb-5 date-picker'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Cheque Date")} </label>
                        {/* <input type="date"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='2021-04-29'
                        /> */}
                        <DatePicker
                            onChange={setDateValue}
                            value={dateValue}
                            className='form-control fw-bolder form-control-solid'
                            format="y-MM-dd"
                        />
                    </div>
                </div>
                {/**/}
                <div className="col-12">
                    <h5 className='text-black fw-500 font-16'>
                        {t("Rent Installment")} 1
                    </h5>
                </div>
                <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                    <div className='mb-5 date-picker'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Receipt Date")} </label>
                        {/* <input type="date"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='2021-04-29'
                        /> */}
                        <DatePicker
                            onChange={setDateValue}
                            value={dateValue}
                            className='form-control fw-bolder form-control-solid'
                            format="y-MM-dd"
                        />
                    </div>
                </div>
                <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                    <div className='mb-5 date-picker'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Cheque Date")} </label>
                        {/* <input type="date"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='2021-04-29'
                        /> */}
                        <DatePicker
                            onChange={setDateValue}
                            value={dateValue}
                            className='form-control fw-bolder form-control-solid'
                            format="y-MM-dd"
                        />
                    </div>
                </div>
                <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Amount")} </label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='4,693'
                        />
                    </div>
                </div>
                <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("VAT")} </label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='0'
                        />
                    </div>
                </div>
                <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                    <div className='mb-5'>
                        <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Total Amount")} </label>
                        <input type="text"
                            className="form-control fw-bolder bg-secon"
                            placeholder=""
                            value='4,693'
                        />
                    </div>
                </div>
                <div className='col-12'>
                    <div className='accordion accordion-flush' id='accordionFlushExamplepayment-details'>
                        <div className='accordion-item offers_details_accordian_div1'>
                            <h2 className='accordion-header position-relative' id='flush-headingpayment-details'>
                                <button
                                    className='accordion-button collapsed accordian_bt py-3 font-14'
                                    type='button'
                                    data-bs-toggle='collapse'
                                    data-bs-target='#flush-collapsepayment-details'
                                    aria-expanded='false'
                                    aria-controls='flush-collapsepayment-details'
                                >
                                    {t("Rent Installment")} 2
                                </button>
                            </h2>

                            <div
                                id='flush-collapsepayment-details'
                                className='accordion-collapse collapse'
                                aria-labelledby='flush-headingpayment-details'
                                data-bs-parent='#accordionFlushExamplepayment-details'
                            >
                                <div className='accordion-body py-5'>
                                    <div className='row form-add-design'>
                                        <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                                            <div className='mb-5 date-picker'>
                                                <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Receipt Date")} </label>
                                                {/* <input type="date"
                                                    className="form-control fw-bolder bg-secon"
                                                    placeholder=""
                                                    value='2021-04-29'
                                                /> */}
                                                <DatePicker
                                                    onChange={setDateValue}
                                                    value={dateValue}
                                                    className='form-control fw-bolder form-control-solid'
                                                    format="y-MM-dd"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-sm-6 col-xl-6'>
                                            <div className='mb-5 date-picker'>
                                                <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Cheque Date")} </label>
                                                {/* <input type="date"
                                                    className="form-control fw-bolder bg-secon"
                                                    placeholder=""
                                                    value='2021-04-29'
                                                /> */}
                                                <DatePicker
                                                    onChange={setDateValue}
                                                    value={dateValue}
                                                    className='form-control fw-bolder form-control-solid font-13'
                                                    format="y-MM-dd"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                                            <div className='mb-5'>
                                                <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Amount")} </label>
                                                <input type="text"
                                                    className="form-control fw-bolder bg-secon"
                                                    placeholder=""
                                                    value='4,693'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                                            <div className='mb-5'>
                                                <label className="font-14 fw-bold mb-3 text-normal d-block">{t("VAT")} </label>
                                                <input type="text"
                                                    className="form-control fw-bolder bg-secon"
                                                    placeholder=""
                                                    value='0'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-4 col-sm-6 col-xl-4'>
                                            <div className='mb-5'>
                                                <label className="font-14 fw-bold mb-3 text-normal d-block">{t("Total Amount")} </label>
                                                <input type="text"
                                                    className="form-control fw-bolder bg-secon"
                                                    placeholder=""
                                                    value='4,693'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}