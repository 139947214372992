import React, { FC, useState, useRef, useEffect } from 'react'
import { bankTransfer } from '../../../../constants/axios/apis';
export function BankTransfer() {
    return (
            <div className="">
                <div className="row">
                    <div className="col-12">
                        <div className="row form-add-design">
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">Beneficiary Name</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value='RealCube L.L.C' maxLength={50}
                                        disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">Beneficiary Account Number</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value='5469859874548' maxLength={50}
                                        disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">IBAN Number</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value='Email ID' maxLength={50}
                                           disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">Beneficiary Bank Name</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value='Abu Dhabi Commercial Bank ' maxLength={50}
                                       disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">Swift Code</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value='ADCBAEAA' maxLength={50}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3 w-100'>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
