import React, { useState, useRef, FC } from 'react';
import {Link} from 'react-router-dom'
import PlusIcon from "../../../../../../../assets/images/plus-circle.svg";
import { useTranslation } from 'react-i18next'

export function StepTwo() {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="row form-add-design">
                  <div className='col-12'>
                        <div className='mb-5'>
                            <h5 className='className="modal-title fw-bold font-18 cpt-headding'>
                                {t("Emergency Contact")}
                            </h5>
                        </div>
                    </div> 
                    {/* <div className='col-12'>
                        <div className='row'>
                            <div className='col-6 ps-0'>
                                <div className='mb-6'>
                                    <input
                                        value="Muhammad"
                                        type='text'
                                        className='form-control fw-bolder bg-white'
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='col-6 pe-0'>
                                <div className='mb-6'>
                                    <input
                                        value="Ashif"
                                        type='text'
                                        className='form-control fw-bolder bg-white'
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">{t("Name")}</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">{t("Contact Number")}</label>
                      <input type="tel" 
                        className="form-control fw-bolder bg-secon mb-1" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">{t("Relation")}</label>
                      <input type="text"
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">{t("Nationality")}</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                </div>
            </div>
        </div>
    );
}