import React, { useState, useEffect, useRef } from 'react';

export function ApplyForWaiverPopup() {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };
    return (
        <div className="modal fade add-broker-modal" id="apply-for-waiver-popup">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header px-8 py-8">
                        <h5 className="modal-title text-black font-18 fw-bolder">Apply Waiver</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-8">
                        <form>
                            <div className="row form-add-design">
                                <div className="col-12">
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> Charge Type </label>
                                        <select className="form-control form-select fw-bolder bg-white form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> Clearance Charges </option>
                                            <option> Common Areas </option>
                                            <option> Elevators </option>
                                        </select>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> Comments </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                    {/*<div className=" ">*/}
                                        {/*<div className="mb-5 upload-input">*/}
                                            {/*<label className="form-label fw-bolder d-block">Upload Document</label>*/}
                                            {/*<input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>*/}
                                            {/*<button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>*/}
                                                {/*<span>{uploadedFileName ? uploadedFileName : <span>Upload  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>*/}
                                            {/*</button>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                    <div className="">
                                        <div className="mb-3">
                                            <label className="form-label">Upload Document</label>
                                            <div className="attachment-div">
                                                <label htmlFor="attachment">{uploadedFileName ? uploadedFileName : "Upload" }</label>
                                                <input type="file" id="attachment" className="form-control fw-bolder form-control-solid" ref={inputRef} onChange={handleDisplayFileDetails} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer px-8 pb-8">
                        <button type="button" className="btn btn-primary fw-bold px-14 py-3 w-100"
                                data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#added-success-modal">
                            Send Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}