import React from 'react';

export function StepThree() {
    return (
        <div className="row py-6 step-one">
            <div className="col-md-12">
                <div className="row form-add-design">
                    <div className="col-md-12 col-12">
                        <div className="mb-6">
                            <label className="form-label fw-bolder text-black mb-3"> Budget </label>
                            <div className="input-group mb-7">
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                <span className="input-group-text bg-transparent font-12 fw-bolder" id="basic-addon2">
                                    <select className="form-control form-select bg-transparent font-12 fw-bolder p-0 border-0 form-control-solid">
                                        <option selected>Total (AED)</option>
                                        <option>Total (Dollars)</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="mb-4">
                            <label className="form-label fw-bolder text-black d-block mb-3"> Schedule </label>
                            <input type="date" className="form-control mb-4 fw-bolder form-control-solid" placeholder="From Date" />
                            <input type="date" className="form-control fw-bolder form-control-solid" placeholder="To Date" />
                        </div>
                    </div>
                    <div className="col-12">
                        <h6 className="text-black font-14 fw-bold mb-3">
                            Your ad will run for <span className="fw-boldest"> 7 days </span> and will cost you no
                            more than <span className="fw-boldest"> AED 500.00 </span>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
}