import React, {useState, FC, useRef, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Link, useParams, useHistory, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../../../../assets/images/Left_Arrow.png'
import {MoveOutDetailsTab} from './MoveOutDetailsTab/MoveOutDetailsTab'
import {MainDoorKeys} from './MainDoorKeys/MainDoorKeys'
import {
  getAllotedAccessId,
  submitMoveOutApproval,
  getApprovals,
  submitFmsmHandoverToFcss,
} from '../../../../../../../constants/axios/apis'
import {BuildingAccessCard} from '../../MoveIn/MoveInDetailsWrapper/BuildingAccessCard/BuildingAccessCard'
import {ParkingAccessCard} from '../../MoveIn/MoveInDetailsWrapper/ParkingAccessCard/ParkingAccessCard'
import {Details} from '../../MoveIn/MoveInDetailsWrapper/Details/Details'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../../../../../../constants/generics/userCheck'
import {CustomRequestSuccessfulPopup} from '../../../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import {OtherAccessCard} from '../../MoveIn/MoveInDetailsWrapper/OtherAccessCard/OtherAccessCard'
import { useTranslation } from 'react-i18next'

const MoveOutDetailsWrapper: React.FC = () => {
  const location = useLocation<any>()
  const [accessCards, setAccessCards] = useState<any>()
  const { t } = useTranslation();

  const successRef = useRef<any>()
  const failRef = useRef<any>()

  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  // const [cardSignature, setCardSignature] = useState<any>('')
  let approvalId = location?.state?.approval?.id ? location?.state?.approval?.id : {}
  const property_id = approvalId
  // const user_id = location?.state?.approval?.user_id;
  const id = approvalId
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [approvalDataDetails, setApprovalDataDetails] = useState<any>({})
  const [reloadData, setReloadData] = useState<any>(true)
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState('')

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  const [submitError, setSubmitError] = useState('')
  const buttonEl: any = useRef(null)

  // useEffect(() => {
  //     getAllotedAccessId().then(({ data }: any) => {
  //         setAccessCards(data.find((element: any) => element.propertyId === property_id))
  //     })
  // }, [property_id])

  useEffect(() => {
    if (reloadData) {
      const newPayload = '?id=' + approvalId
      getApprovals(newPayload).then((response: any) => {
        if (response?.data && Array.isArray(response.data) && response.data.length > 0)
          setApprovalDataDetails(response.data[0])
        // setApprovalDataDetails(response.data)
        setReloadData(false)
      })
    }
  }, [approvalId, reloadData])

  const bacCount = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.no_of_cards
  const pacCount = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.no_of_cards
  const oacCardData = approvalDataDetails?.card_details?.other_access_cards?.card_number
  const oacCount = oacCardData ? Object.keys(oacCardData)?.length : 0
  let bacCardDetails
  let pacCardDetails
  let oacCardDetails

  if (approvalDataDetails?.card_details?.building_access_card) {
    bacCardDetails = approvalDataDetails?.card_details?.building_access_card?.card_number
    bacCardDetails = Object.values(bacCardDetails)
    bacCardDetails = bacCardDetails?.map((item: any) => item.number)
  }

  if (approvalDataDetails?.card_details?.parking_access_card) {
    pacCardDetails = approvalDataDetails?.card_details?.parking_access_card?.card_number
    pacCardDetails = Object.values(pacCardDetails)
    pacCardDetails = pacCardDetails.map((item: any) => item.number)
  }
  if (approvalDataDetails?.card_details?.other_access_cards) {
    oacCardDetails = approvalDataDetails?.card_details?.other_access_cards?.card_number
    oacCardDetails = Object.values(oacCardDetails)
    oacCardDetails = oacCardDetails.map((item: any) => item.number)
  }

  // FCSS Moved Out
  const sendMoveOutApproval = () => {
    setLoading(true)
    setSubmitError('')
    setTimeout(() => {
      submitMoveOutApproval(approvalDataDetails.approval_id,approvalDataDetails.object_id, [
        approvalDataDetails?.card_details?.building_access_card?.allotted_access_id,
        approvalDataDetails?.card_details?.parking_access_card?.allotted_access_id,
      ])
        .then((response: any) => {
          // setLoading(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          } else if (response.errorCode === 0) {
            // formik.resetForm()
            setSubmitSuccessMsg(response.description)
            // setLoading(false)
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/approvals/closed/moveout')
            }, 4000)
          } else {
            setSubmitError(t("Some error occured. Please try again !"))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          }
          setLoading(false)
        })
        //   if (response.errorCode === 0) {
        //     history.push('/approvals/closed/movein', {})
        //     successRef.current.click()
        //   } else {
        //     setMessage(response.errorDescription)
        //     failRef.current.click()
        //   }
        // })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(t("Invalid Data Provided"))
          setTimeout(() => {
            setSubmitError('')
          }, 5000)
        })
    }, 1000)
  }

  const buildingSaved =
    user_role === 'FCSS'
      ? approvalDataDetails?.card_details?.building_access_card?.signature?.length > 0
      : approvalDataDetails?.card_details?.building_access_card?.staff_signature?.length > 0
  const parkingSaved =
    user_role === 'FCSS'
      ? approvalDataDetails?.card_details?.parking_access_card?.signature?.length > 0
      : approvalDataDetails?.card_details?.parking_access_card?.staff_signature?.length > 0
  const otherSaved =
    oacCount === 0
      ? true
      : user_role === 'FCSS'
      ? approvalDataDetails?.card_details?.other_access_cards?.signature?.length > 0
      : approvalDataDetails?.card_details?.other_access_cards?.staff_signature?.length > 0

  const enableButton = buildingSaved && parkingSaved && otherSaved
  // approvalDataDetails?.card_details?.building_access_card?.signature?.length &&
  // approvalDataDetails?.card_details?.parking_access_card?.signature?.length &&
  // approvalDataDetails?.card_details?.other_access_cards?.signature?.length // FCSS Hand Over To FMSM Button Enabled

  console.log(approvalId,approvalDataDetails,"approvalId?.access_card_slot_id,");
  // FCSS Handover to FMSM
  const sendFMSMHandoverFCSS = () => {
    setLoading(true)
    setSubmitError('')
    setTimeout(() => {
      submitFmsmHandoverToFcss(
        
        approvalDataDetails?.access_card_slot_id,
        approvalDataDetails?.propertyID,
        approvalDataDetails?.user_id
      )
        .then((response: any) => {
          // setLoading(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          } else if (response.errorCode === 0) {
            // formik.resetForm()
            setSubmitSuccessMsg(response.description)
            // setLoading(false)
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/approvals/closed/moveout')
            }, 4000)
          } else {
            setSubmitError(t("Some error occured. Please try again !"))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          }
          setLoading(false)
        })
        //   if (response.errorCode === 0) {
        //     history.push('/approvals/closed/movein', {})
        //     successRef.current.click()
        //   } else {
        //     setMessage(response.errorDescription)
        //     failRef.current.click()
        //   }
        // })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(t("Invalid Data Provided"))
          setTimeout(() => {
            setSubmitError('')
          }, 5000)
        })
    }, 1000)
  }

  const buildingAccessCardID = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.id
  const parkingAccessCardID = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.id
  const otherAccessCardID = approvalDataDetails?.allotted_access_card?.find(
    (el: any) => el.name === 'Other Access Card'
  )?.id

  return (
    <>
      <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='card-title font-18 text-black mb-6'>
                <Link to={'/approvals/upcoming/moveout'} className=''>
                  <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                  {/* <span className='text-black'> {t("Move Out Approval")}</span> */}
                </Link>
                <span className="text-black">{t("Collect Access Cards")}</span>
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mb-7'>
              <Details approval={approvalDataDetails} />
            </div>
          </div>
          <Switch>
            
            <Route path='/fcss-moveoutDetails/building-access-card'>
              <MoveOutDetailsTab
                buildingSaved={buildingSaved}
                parkingSaved={parkingSaved}
                otherSaved={otherSaved}
                isOthers={oacCount !== 0}
              />
              <BuildingAccessCard
                count={bacCount}
                approval={approvalDataDetails}
                cardDetails={bacCardDetails}
                allotedAccessID={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.building_access_card?.allotted_access_id
                    : buildingAccessCardID
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.building_access_card?.signature
                    : approvalDataDetails?.card_details?.building_access_card?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.building_access_card?.signature
                    : ''
                } //Only FMSM used for customer signature
                setReloadData={setReloadData}
                type={approvalDataDetails?.subReference === 5 ? 'movein' : 'moveout'}
              />
            </Route>
            <Route path='/fcss-moveoutDetails/parking-access-card'>
              <MoveOutDetailsTab
                buildingSaved={buildingSaved}
                parkingSaved={parkingSaved}
                otherSaved={otherSaved}
                isOthers={oacCount !== 0}
              />
              <ParkingAccessCard
                count={pacCount}
                approval={approvalDataDetails}
                cardDetails={pacCardDetails}
                allotedAccessID={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.parking_access_card?.allotted_access_id
                    : parkingAccessCardID
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.parking_access_card?.signature
                    : approvalDataDetails?.card_details?.parking_access_card?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.parking_access_card?.signature
                    : ''
                } //Only FMSM used for customer signature setReloadData={setReloadData}
                setReloadData={setReloadData}
                type={approvalDataDetails?.subReference === 5 ? 'movein' : 'moveout'}
              />
            </Route>
            <Route path='/fcss-moveoutDetails/other-access-card'>
              <MoveOutDetailsTab
                buildingSaved={buildingSaved}
                parkingSaved={parkingSaved}
                otherSaved={otherSaved}
                isOthers={oacCount !== 0}
              />
              <OtherAccessCard
              approval={approvalDataDetails}
                count={oacCount}
                cardDetails={oacCardDetails}
                allotedAccessID={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.other_access_cards?.allotted_access_id
                    : approvalDataDetails?.card_details?.other_access_cards?.allotted_access_id
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.other_access_cards?.signature
                    : approvalDataDetails?.card_details?.other_access_cards?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.other_access_cards?.signature
                    : ''
                } //Only FMSM used for customer signature
                setReloadData={setReloadData}
                type={approvalDataDetails?.subReference === 5 ? 'movein' : 'moveout'}
              />
            </Route>
            <Route path='/fcss-moveoutDetails/main-door-keys'>
              <MoveOutDetailsTab
              />
              <MainDoorKeys />
            </Route>
          </Switch>
          {user_role === 'FMSM' ? (
            <div className='row'>
              <div className='col-12 text-end mt-5'>
                <button
                  onClick={() => sendMoveOutApproval()}
                  disabled={!enableButton}
                  type='submit'
                  className='btn btn-primary fw-bold px-10 py-3'
                >
                  {!loading && <span className='indicator-label'>Moved Out</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {submitError && (
                // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
                <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                  {submitError}
                </div>
              )}
              <button
                ref={buttonEl}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                type='submit'
                className='invisible d-none btn btn-primary fw-bold px-10 py-3'
              >
                Submit
              </button>
              <CustomRequestSuccessfulPopup
                successMessage={'Moved Out Successfully'}
                // href={'/approvals/closed/moveout'}
                close={false}
              />
            </div>
          ) : (
            <div className='row'>
              <div className='col-12 text-end mt-5'>
                <button
                  onClick={() => sendFMSMHandoverFCSS()}
                  disabled={!enableButton}
                  type='submit'
                  className='btn btn-primary fw-bold px-10 py-3'
                >
                  {!loading && <span className='indicator-label'>HandOver</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {submitError && (
                // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
                <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                  {submitError}
                </div>
              )}
              <button
                ref={buttonEl}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                type='submit'
                className='invisible d-none btn btn-primary fw-bold px-10 py-3'
              >
                Submit
              </button>
              <CustomRequestSuccessfulPopup
                successMessage={'Handover Successfully'}
                // href={'/approvals/closed/movein'}
                close={false}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {MoveOutDetailsWrapper}
