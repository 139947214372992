import React, { FC, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getMyTeamUserList } from '../../../constants/axios/apis';
import { CustomLoader2 } from '../../../constants/generics/customActios';

export function UserRedirectHandler() {

    const history = useHistory()
    let params: any = {};
    params = useLocation();
    const userID = params.state.userData.id

    useEffect(() => {
        getMyTeamUserList(`?user_ids[0]=${userID}`).then((response: any) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                history.push({
                    pathname: '/fc-su-myteam-details',
                    state: {user: response.data[0], prevPath: '/'}
                })
            }else{

            }
        })
    }, [userID])

    return <>
        <CustomLoader2 height={'166'}/>
    </>
}