import React from 'react'

export function Content(props: any) {
    const proprtyDetails = props.proprtyDetails
    return (
        <div className="">
            {/* <p className="font-14 pe-20">{proprtyDetails?.propertyDescription}</p> */}
            <p dangerouslySetInnerHTML={{ __html: proprtyDetails?.propertyDescription }}></p>
        </div>
    )
};