import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer';
import { approveTheRequests, rejectTheRequests, rmiTheRequests } from '../../../../constants/axios/apis';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../constants';
import { useTranslation } from 'react-i18next'

export function ApproveButtons() {
    const dispatch = useDispatch()
    const history = useHistory();
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    const ref = useRef<any>(0)
    const [message, setMessage] = useState<any>()
    const [approve, setApprove] = useState<any>([])
    const [comments, setComments] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingType, setLoadingType] = useState('')
    const { t } = useTranslation();
    const rmi = (e: any) => {
        if (comments.length > 0) {
            setLoading(true)
            setLoadingType('3')
            // setTimeout(() => {
            //     setLoading(false)
            //     setLoadingType('')
            // }, 2000);
            var data = new FormData();
            data.append('approval_id', contractApprovalData?.id);
            data.append('comment', comments);
            rmiTheRequests(data).then((response) => {
                setLoading(false)
                setLoadingType('')
                if (response?.code === 200 && response?.errorCode != 1) {
                    setMessage({
                        error: 0,
                        message: response?.description,
                    })
                    ref.current.click();
                    setTimeout(() => {
                        ref.current.click();
                        history.push("/pm-approvals/open-requests")
                    }, 3000);
                } else if (response === 500 || response === 401) {
                    setMessage({
                        error: 1,
                        message: t("Internal Server Error"),
                    })
                    ref.current.click();
                } else {
                    setMessage({
                        error: 1,
                        message: response?.errorDescription,
                    })
                    ref.current.click();
                }
            }).catch((err) => {
                setLoading(false)
                setLoadingType('')
                setMessage({
                    error: 1,
                    message: t("Server Error") + err,
                })
                ref.current.click();
            })
            setTimeout(() => {
                setLoading(false)
                setLoadingType('')
            }, 2000);
        } else {
            setErrorMsg(t("Please enter comments/reason for rejection"))
            setTimeout(() => {
                setErrorMsg('')
            }, 2000);
        }
    }
    const reject = (e: any) => {
        if (comments.length > 0) {
            setLoading(true)
            setLoadingType('2')
            // setTimeout(() => {
            //     setLoading(false)
            //     setLoadingType('')
            // }, 2000);
            var data = new FormData();
            data.append('approval_id', contractApprovalData?.id);
            data.append('comment', comments);
            rejectTheRequests(data).then((response) => {
                setLoading(false)
                setLoadingType('')
                if (response?.code === 200 && response?.errorCode != 1) {
                    setMessage({
                        error: 0,
                        message: response?.description,
                    })
                    ref.current.click();
                    setTimeout(() => {
                        ref.current.click();
                        history.push("/pm-approvals/open-requests")
                    }, 3000);
                } else if (response === 500 || response === 401) {
                    setMessage({
                        error: 1,
                        message: t("Internal Server Error"),
                    })
                    ref.current.click();
                } else {
                    setMessage({
                        error: 1,
                        message: response?.errorDescription,
                    })
                    ref.current.click();
                }
            }).catch((err) => {
                setLoading(false)
                setLoadingType('')
                setMessage({
                    error: 1,
                    message: t("Server Error") + err,
                })
                ref.current.click();
            })
            setTimeout(() => {
                setLoading(false)
                setLoadingType('')
            }, 2000);
        } else {
            setErrorMsg(t("Please enter comments/reason for rejection"))
            setTimeout(() => {
                setErrorMsg('')
            }, 2000);
        }
    }
    const approved = (e: any) => {
        setLoading(true)
        setLoadingType('1')

        var data = new FormData();
        data.append('approval_id', contractApprovalData?.id);
        data.append('comment', comments);
        approveTheRequests(data).then((response) => {
            setLoading(false)
            setLoadingType('')
            if (response?.code === 200 && response?.errorCode != 1) {
                setMessage({
                    error: 0,
                    message: response?.description,
                })
                ref.current.click();
                setTimeout(() => {
                    ref.current.click();
                    history.push("/pm-approvals/open-requests")
                }, 3000);
            } else if (response === 500 || response === 401) {
                setMessage({
                    error: 1,
                    message: t("Internal Server Error"),
                })
                ref.current.click();
            } else {
                setMessage({
                    error: 1,
                    message: response?.errorDescription,
                })
                ref.current.click();
            }
        }).catch((err) => {
            setLoading(false)
            setLoadingType('')
            setMessage({
                error: 1,
                message: t("Server Error") + err,
            })
            ref.current.click();
        })
        setTimeout(() => {
            setLoading(false)
            setLoadingType('')
        }, 2000);
    }
    useLayoutEffect(() => {
        if (loading) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
            document.body.style.pointerEvents = "none";
        }
        if (!loading) {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
            document.body.style.pointerEvents = "all";
        }
    }, [loading]);


    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])
    return (
        <><div className="com-card-section pm_approval_buttons">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body px-8 py-6">
                            {contractApprovalData?.status === "Approved" ?
                                ''
                                // <div className="row align-items-end">
                                //     <div className="col-12">
                                //             <label className="font-14 fw-bold mb-3 text-theme-gray">
                                //                 Approver Comments
                                //             </label>
                                //             <div className="data-contain h-75 px-5 py-4 rounded-10">
                                //                 <h6 className="font-14 fw-bold d-block mb-0">
                                //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vestibulum, turpis ac suscipit tempus, sem lacus scelerisque orci, nec semper metus ligula nec augue. In sed leo at ante pulvinar vestibulum.
                                //                 </h6>
                                //             </div>
                                //     </div>
                                // </div>
                                :
                                <div className="row align-items-end">
                                    <div className="col-lg-12 col-sm-12 mb-5">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="font-14 fw-bold mb-3">{t("Approver Comments")} </label>
                                                <textarea
                                                    onChange={(e) => setComments(e.target.value)}
                                                    className='form-control fw-bolder'
                                                    data-kt-autosize='true'
                                                    rows={4}
                                                    placeholder={t("Write Comments")} />
                                            </div>
                                            <p className="text-danger">{errorMsg}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-12 text-end">
                                        <div className="col-12 text-end">
                                        <button className='btn btn-lg request-more-info fw-bold px-10 py-3 me-5 disabled' onClick={rmi}>
                                                {loading && loadingType === '3' && <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" />}
                                                {loading && loadingType === '3' ? t("Loading...") : t("Request More Info")}
                                            </button>
                                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-5' onClick={reject}>
                                                {loading && loadingType === '2' && <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" />}
                                                {loading && loadingType === '2' ? t("Loading...") : t("Reject")}
                                            </button>

                                           

                                            <button className='btn btn-primary fw-bold px-10 py-3' onClick={approved}>
                                                {loading && loadingType === '1' && <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" />}
                                                {loading && loadingType === '1' ? t("Loading...") : t("Approve")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div><p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p></>
    );
}