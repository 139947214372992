import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const WorkOrderStatusTabs = ({ searchKey, setSearchKey }: any) => {

    const location = useLocation();
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-4">
                        <div className="col-lg-3 col-sm-12 col-12">
                            <h4 className="card-title font-18 text-black m-0">{t("Work Order")}</h4>
                        </div>
                        <div className="col-lg-9 col-sm-12 col-12">
                            <div className="d-flex pm-topbar-buttons float-end my-3 my-md-0">
                                <div className="form-group has-search">
                                    <span className="fa fa-search pt-2 form-control-feedback"></span>
                                    <input type="text" className="form-control ps-10"
                                        placeholder={t("Workorder/Request No.")}
                                        value={searchKey}
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <h4 className="card-title font-18 text-black mb-6"> Work Orders </h4>
                        </div> */}
                    </div>
                    <div className="card mb-3">
                        <div className="card-body px-4 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex py-2'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary px-5 ` +
                                                        (location.pathname === '/facility-workorder/list/corrective/open' ?
                                                            'active' :
                                                            location.pathname === '/facility-workorder/list/corrective/onhold' ?
                                                                'active' :
                                                                location.pathname === '/facility-workorder/list/corrective/completed' ?
                                                                    'active' :
                                                                    '')}
                                                    to='/facility-workorder/list/corrective/open'>
                                                    {t("Corrective")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary px-5 ` +
                                                        (location.pathname === '/facility-workorder/list/clearance/open' ?
                                                            'active' :
                                                            location.pathname === '/facility-workorder/list/clearance/confirmed' ?
                                                                'active' :
                                                                location.pathname === '/facility-workorder/list/clearance/completed' ?
                                                                    'active' :
                                                                    '')}
                                                    to='/facility-workorder/list/clearance/open'>
                                                    {t("Clearance")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary px-5 ` +
                                                        (location.pathname === '/facility-workorder/list/preventive/open' ?
                                                            'active' :
                                                            location.pathname === '/facility-workorder/list/preventive/onhold' ?
                                                                'active' :
                                                                location.pathname === '/facility-workorder/list/preventive/completed' ?
                                                                    'active' :
                                                                    '')}
                                                    to='/facility-workorder/list/preventive/open'>
                                                    {t("Preventive")}
                                                </Link>
                                            </li>
                                            {/* <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/facility-workorder/list/sla/open' ?
                                                        'active' :
                                                        location.pathname === '/facility-workorder/list/sla/onhold' ?
                                                        'active' :
                                                        location.pathname === '/facility-workorder/list/sla/completed' ?
                                                        'active' :
                                                        '')}
                                                    to='/facility-workorder/list/sla/open'>
                                                    {t("SLA")}
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { WorkOrderStatusTabs }
