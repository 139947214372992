import { Expenditure } from './Graphs/Expenditure'
import {OverviewChart} from './Graphs/OverviewChart'
import { RentingTrend } from './Graphs/RentingTrend'
import { Revenue } from './Graphs/Revenue'
import { ManageList } from './ManageList'

export const Overview = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-2">
                    <OverviewChart/>
                </div>
                <div className="col-md-10">
                    <div className="card radius-12">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md">
                                    <h6 className="font-weight-semibold text-theme-black font-12 mb-3"> Total Budget </h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="https://pm-crm.realcube.estate/img/total-bud-ico.png" className="img-fluid" width="45px"/>
                                        </div>
                                        <div className="col-md-6 px-md-2 text-end">
                                            <h6 className="font-12 font-weight-semibold text-uppercase mb-1"> AED </h6>
                                            <h5 className="font-weight-bold mb-0 font-22"> 1M </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto border-right px-0 mx-7'></div>
                                <div className="col-md">
                                    <h6 className="font-weight-semibold text-theme-black font-12 mb-3"> Online Budget </h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="https://pm-crm.realcube.estate/img/online-bud-ico.png" className="img-fluid" width="45px"/>
                                        </div>
                                        <div className="col-md-6 px-md-2 text-end">
                                            <h6 className="font-12 font-weight-semibold text-uppercase mb-1"> AED </h6>
                                            <h5 className="font-weight-bold mb-0 text-warning font-22"> 40K </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto border-right px-0 mx-7'></div>
                                <div className="col-md">
                                    <h6 className="font-weight-semibold text-theme-black font-12 mb-3"> Offline Budget </h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src="https://pm-crm.realcube.estate/img/offline-bud-ico.png" className="img-fluid" width="45px"/>
                                        </div>
                                        <div className="col-md-6 px-md-2 text-end">
                                            <h6 className="font-12 font-weight-semibold text-uppercase mb-1"> AED </h6>
                                            <h5 className="font-weight-bold mb-0 font-22"> 60K </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-5">
                    <div className="row">
                        <div className="col-md-3 mb-7">
                            <div className="card radius-12 h-100">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Emails Sent </h6>
                                        <div className="row">
                                        <div className="col-md-4 px-md-1">
                                            <img src="https://pm-crm.realcube.estate/img/emails-sent-ico.png" className="img-fluid" width="35px"/>
                                        </div>
                                        <div className="col-md-8 px-md-2 d-flex align-items-center justify-content-end">
                                            <h5 className="font-weight-bold mb-0 text-end font-22"> 5,000 </h5>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-7">
                            <div className="card radius-12 h-100">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Opened </h6>
                                        <div className="row">
                                        <div className="col-md-4 px-md-1">
                                            <img src="https://pm-crm.realcube.estate/img/opened-ico.png" className="img-fluid" width="35px"/>
                                        </div>
                                        <div className="col-md-8 px-md-2 d-flex align-items-center justify-content-end">
                                            <h5 className="font-weight-bold mb-0 text-end ml-auto font-22"> 2,000 </h5>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-7">
                            <div className="card radius-12 h-100">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Clicked </h6>
                                        <div className="row">
                                        <div className="col-md-4 px-md-1">
                                            <img src="https://pm-crm.realcube.estate/img/clicked-ico.png" className="img-fluid" width="35px"/>
                                        </div>
                                        <div className="col-md-8 px-md-2 d-flex align-items-center justify-content-end">
                                            <h5 className="font-weight-bold mb-0 text-end font-22"> 500 </h5>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-7">
                            <div className="card radius-12 h-100">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Contacted </h6>
                                        <div className="row">
                                        <div className="col-md-4 px-md-1">
                                            <img src="https://pm-crm.realcube.estate/img/contacted-ico.png" className="img-fluid" width="35px"/>
                                        </div>
                                        <div className="col-md-8 px-md-2 d-flex align-items-center justify-content-end">
                                            <h5 className="font-weight-bold mb-0 text-end font-22"> 75 </h5>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-4'>
                    <h6 className="font-16 font-weight-semibold">Renting Trend</h6>
                    <div className='card mb-7'>
                        <div className='card-body'>
                            <RentingTrend/>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <h6 className="font-16 font-weight-semibold">Revenue by Channel</h6>
                    <div className='card mb-7'>
                        <div className='card-body'>
                            <Revenue/>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <h6 className="font-16 font-weight-semibold">Add Expenditure</h6>
                    <div className='card mb-7'>
                        <div className='card-body'>
                            <Expenditure/>
                        </div>
                    </div>
                </div>
            </div>
            <ManageList/>
        </>
    )
}