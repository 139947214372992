import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../components/layout/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MTAApprovalListWrapper } from './MTAApprovalsList/MTAApprovalListWrapper';
import { MTAApprovalsDetails } from './MTAApprovalsView/Details/MTAApprovalsDetails';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';

const MTAApprovalsPage: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');
    return (
        <>
            <Switch>
                <Route path='/mta-approvals/list'>
                    <MTAApprovalListWrapper />
                </Route>
                <Route path='/mta-approvals/view'>
                    <MTAApprovalsDetails setPopupSuccessMsg={setPopupSuccessMsg}/>
                </Route>
                <Redirect to='/error/403' />
            </Switch>
            <CustomRequestSuccessfulPopup 
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    );
}

const MTAApprovalsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <MTAApprovalsPage />
        </>
    )
};

export { MTAApprovalsWrapper }
