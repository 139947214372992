import React, { useState, useEffect } from 'react';
import Credit from "../../../../assets/images/pm-credit-card.svg";
import Bank from "../../../../assets/images/pm-bank.svg";
import Cheque from "../../../../assets/images/pm-cheque.svg";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { useTranslation } from 'react-i18next'
import moment from 'moment';

export function Payment() {
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 pt-8 pb-3">
                            <div className="row">

                                {contractApprovalData?.payment_plan_details?.rent?.length > 0 ? contractApprovalData?.payment_plan_details?.rent.map((item: any, index: number) => {
                                    return (
                                        <div className="col-md-12 col-12 mb-5">
                                            <div className="card shadow-sm payment-card-sec">
                                                <div className="card-body px-8 py-6">
                                                    <div className="row">
                                                        <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Payment Type")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Rent")}</h3>
                                                        </div>
                                                        <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Payment Date")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{moment(item?.amountDeductionDate).format('DD-MM-YYYY')}</h3>
                                                        </div>
                                                        <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Amount")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{parseInt(item?.totalAmount)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("AED")}</h3>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Payment Type")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">
                                                                <img
                                                                    src={item?.modePayment === 'CREDIT_CARD' ? Credit : item?.modePayment === 'BANK_TRANSFER' ? Bank : Cheque}
                                                                    className="img-fluid me-3"
                                                                />
                                                                {item?.modePayment === 'CREDIT_CARD' ? t("Credit Card") : item?.modePayment === 'BANK_TRANSFER' ? t("Bank Transfer") : t("Cheque")}
                                                            </h3>
                                                        </div>
                                                        <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="me-3">
                                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                        {t("Status")}
                                                                    </label>
                                                                    <h3 className="mb-0 payment-txt">{item?.status === 1 ? t("Pending") : t("Cleared")}</h3>
                                                                </div>
                                                                {/* <div className="">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''}

                                {contractApprovalData?.service_charges?.length > 0 ? contractApprovalData?.service_charges.map((item: any, index: number) => {
                                    return (
                                        <div className="col-md-12 col-12 mb-5">
                                            <div className="card shadow-sm payment-card-sec">
                                                <div className="card-body px-8 py-6">
                                                    <div className="row">
                                                        <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Payment Type")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Service Charges")}</h3>
                                                        </div>
                                                        <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Payment Date")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">12-11-2021</h3>
                                                        </div>
                                                        <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Amount")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">45,000 {t("AED")}</h3>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Payment Type")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt"><img src={Bank} className="img-fluid me-3" /> {t("Credit Card")}</h3>
                                                        </div>
                                                        <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="me-3">
                                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                        {t("Status")}
                                                                    </label>
                                                                    <h3 className="mb-0 payment-txt">{t("Cleared")}</h3>
                                                                </div>
                                                                <div className="">
                                                                    <i className="bi bi-chevron-down text-black"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''}
                                {/* <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">Other Changes</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">12-11-2021</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">45,000 {t("AED")}</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt"><img src={Cheque} className="img-fluid me-3" /> {t("Credit Card")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Cleared")}</h3>
                                                        </div>
                                                        <div className="">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}