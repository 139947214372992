import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { PMAvailabilityHeader } from '../PMAvailabilityHeader/PMAvailabilityHeader';
import { useDispatch, useSelector } from 'react-redux';
import * as PM from '../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { RootState } from '../../../../constants';
import { useTranslation } from 'react-i18next'

const ApprovalsTabs: React.FC = () => {
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const dispatch = useDispatch()
    const intl = useIntl()
    const location = useLocation();
    const [menu, setMenu] = useState('');

    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(10)
    const [totalOfferList, setTotalOfferList] = useState<any>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
        }
    });
    const [isCard, setCard] = useState(false);
    const onCard = () => {
        setCard(true);
    };
    const onList = () => {
        setCard(false);
    };
    const proprtyList = (event: any) => {
        // dispatch(PM.actions.proprtylist(`${event}?currentPage=${currentPage}&pageSize=${pageSize}&building_id=${currentBuilding}`))
    }

    useEffect(() => {
        // dispatch(PM.actions.proprtylist(`available?currentPage=${currentPage}&pageSize=${pageSize}&building_id=${currentBuilding}`))
    }, [,currentBuilding])
    return (
        <div className="com-card-section">
            {menu == "/pm-property-data/available" || menu == "/pm-property-data/upcoming" ?
                <div className="row">
                    {/*<div className="col-12">*/}
                    {/*<PMAvailabilityHeader isCard={isCard} onCard={onCard} onList={onList} />*/}
                    {/*</div>*/}
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body px-8 py-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='d-flex h-55px overflow-x'>
                                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                                <li className='nav-item'>
                                                    <Link
                                                        className={`nav-link position-relative text-active-primary ` +
                                                            (location.pathname === '/pm-property-data/available' && 'active')}
                                                        to='/pm-property-data/available'
                                                        onClick={(e) => proprtyList('available')}
                                                    >
                                                        {t("Available")}
                                                    </Link>
                                                </li>
                                                <li className='nav-item'>
                                                    <Link
                                                        className={`nav-link position-relative text-active-primary ` +
                                                            (location.pathname === '/pm-property-data/upcoming' && 'active')}
                                                        to='/pm-property-data/upcoming'
                                                        onClick={(e) => proprtyList('upcoming')}
                                                    >
                                                        {t("Upcoming")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    {/*<div className="col-12">*/}
                    {/*<PMAvailabilityHeader isCard={isCard} onCard={onCard} onList={onList} />*/}
                    {/*</div>*/}
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body px-8 py-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='d-flex py-2 overflow-x'>
                                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                                <li className='nav-item'>
                                                    <Link
                                                        className={`nav-link nav-link position-relative text-active-primary ` +
                                                            (location.pathname === '/pm-availability/available' && 'active')}
                                                        to='/pm-availability/available'>
                                                        {t("Available")}
                                                    </Link>
                                                </li>
                                                <li className='nav-item'>
                                                    <Link
                                                        className={`nav-link nav-link position-relative text-active-primary ` +
                                                            (location.pathname === '/pm-availability/upcoming' && 'active')}
                                                        to='/pm-availability/upcoming'>
                                                        {t("Upcoming")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export { ApprovalsTabs }
