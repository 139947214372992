import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logout from '../../../../assets/images/logout.png'

export function LogoutPopup() {
    const history = useHistory()
    const onLogout = () => {
        window.localStorage.clear()
        history.push('/logout')
    }
    const { t } = useTranslation();

    return (
        <div className='modal fade custom-modal logout-popup' id='logout-modal'>
            <div className='modal-dialog'>
                <div className='modal-content rounded-10'>
                    <div className='modal-header border-0'>
                        {/* <h5 className="modal-title">{t("Logout!")}</h5>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'>
                            &times;
                        </button> */}
                         <h5 className="modal-title" >{t("Logout!")}</h5>
                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className='modal-body px-5 py-8'>
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={Logout} alt="view-all" className="img-fluid logout-img" />
                            </div>
                            <div className="col-md-12 text-center">
                                <h1 className="text-black fw-bold font-20 mb-4">
                                    {t("Are you sure you'd like to Log Out?")}
                                </h1>
                                <p className="mb-8 fw-normal font-14 text-black">
                                    {t("Any unsaved changes will be discarded.")}
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center py-1">
                                    <button data-bs-dismiss="modal" className='btn btn-outline-primary font-14 fw-bold px-10 py-3 mx-4'>
                                        {t("Cancel")}
                                    </button>
                                    <button onClick={() => onLogout()} className='btn btn-primary fw-bold px-10 py-3 font-14'>
                                        {t("Logout")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}