export function  CampaignDetails({chooseMessage}:any) {
    return (
        <>
        <div className="card">
            <div className="card-body">
                <div className="row mb-4">
                    <div className="col-md-12">
                        <form >
                            <div className="form-add-design">
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder text-uppercase mb-0">Campaign Name</label>
                                                <input type="text" className="form-control fw-bolder"  placeholder="Campaign Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">CAMPAIGN OWNER</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                                    <option selected> Search </option>
                                                    <option> User 1</option>    
                                                    <option> User 2</option>    
                                                    <option> User 3 </option>    
                                                    <option> User 4 </option>           
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder text-uppercase mb-0">Start Date</label>
                                                <input type="date" className="form-control fw-bolder"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder text-uppercase mb-0">End Date</label>
                                                <input type="date" className="form-control fw-bolder"  />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-end my-6">
                {/* <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3">Previous</button> */}
                <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('2')}>Next</button>
            </div>
        </>
    )
}