import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import * as _redux from './constants'
import store, { persistor } from './constants/redux/Store'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { App } from './App'
import { ICTi18nProvider } from './setup/i18n/ICTi18n'
import './styles/sass/style.scss'
import './styles/sass/style.react.scss'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { LayoutSplashScreen } from './components/layout/core'

i18n
	// .use(LanguageDetector)
	.use(Backend)
	.use(initReactI18next)
	// .use(HttpApi)
	.init({
		lng: "ar",
		fallbackLng: "en",
		// detection: {
		// 	order: ["path", "localStorage", "htmlTag", "cookie"],
		// 	caches: ["localStorage", "cookie"], // cache user language on
		// },
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json',
		},
	});

const { PUBLIC_URL } = process.env

_redux.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
	<ICTi18nProvider>
		<Provider store={store}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			<PersistGate persistor={persistor}>
				<Suspense fallback={<LayoutSplashScreen />}>
					<App basename={PUBLIC_URL} />
				</Suspense>
			</PersistGate>
		</Provider>
	</ICTi18nProvider>,
	document.getElementById('root')
)
