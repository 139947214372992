import React, {useState, useEffect} from 'react'
import {ActionMenu} from '../ActionMenu/ActionMenu'
import ActionDotsIoc from '../../../../assets/images/Action_dots.png'
import {pmUnRenewedLease} from '../../../../constants/axios/apis'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../constants'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {
  NoDataFoundWithBuildingName,
  CustomLoader,
  currencyFormater,
} from '../../../../constants/generics/customActios'
import {formatDate} from '../../../../constants/generics/dateTimeFormater'
import {CompactPagination} from '../../../../components/partials/content/CompactPagination/CompactPagination'

export function UnrenewedLeases() {
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)
  const allContracts: any = useSelector<RootState>(({General}) => General.buildingName)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [pageSize, setPageSize] = useState<any>(10)
  const [offerData, setOfferData] = useState<any>([])
  const [totalList, setTotalList] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const {t} = useTranslation()

  useEffect(() => {
    setLoading(true)
    const id_sort = '1'
    const building = currentBuilding && currentBuilding !== '' ? currentBuilding : '0'
    pmUnRenewedLease(currentPage, pageSize, building, id_sort)
      .then((response: any) => {
        setLoading(false)
        if (response?.status === 200) {
          setOfferData(response?.data)
          setTotalList(response?.data?.dataCount)
        } else {
          setOfferData(response)
        }
      })
      .catch((e) => {})
  }, [currentPage, currentBuilding])

  if (loading) {
    return <CustomLoader />
  }
  if (offerData?.dataCount <= 0) {
    return (
      <NoDataFoundWithBuildingName
        name={allContracts[0]?.buildingName != undefined && `For ${allContracts[0]?.buildingName}`}
      />
    )
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <div className='rec-payment-act-sec'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='table-responsive'>
                          <table className='table table-striped gy-4 gx-4 mb-0'>
                            <thead>
                              <tr>
                                <th>
                                  <input
                                    className='form-check-input fc-wo-checkbox'
                                    data-bs-target='#CheckBoxPopup'
                                    data-bs-toggle='modal'
                                    type='checkbox'
                                    id='flexCheckDefault'
                                  />
                                </th>
                                <th> No. </th>
                                <th> Tenant Name </th>
                                <th> Lease Number </th>
                                <th> Termination Date </th>
                                {/* <th> Building </th> */}
                                <th> Annual Rent </th>
                                <th> Days </th>
                                <th> Unit </th>
                                <th> Status </th>
                                <th> Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {offerData?.errorCode != 1 &&
                                offerData.length != 0 &&
                                offerData?.data?.map((item: any, index: number) => {
                                  const sl = pageSize * (currentPage - 1) + index + 1
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {' '}
                                        <input
                                          className='form-check-input fc-wo-checkbox'
                                          type='checkbox'
                                          id='flexCheckDefault'
                                        />
                                      </td>
                                      <td>{sl}</td>
                                      <td>{item?.tenantName ? item.tenantName : '-'}</td>
                                      <td> {item?.leaseNumber ? item.leaseNumber : '-'}</td>
                                      <td>
                                        {item?.contract_end ? formatDate(item.contract_end) : '-'}
                                      </td>
                                      {/* <td>{item?.buildingName}</td> */}
                                      <td>
                                        AED{' '}
                                        {item?.annualRent
                                          ? currencyFormater(item?.annualRent)
                                          : '-'}
                                      </td>
                                      <td>{item?.days ? item?.days : '-'}</td>
                                      <td>{item?.PropertyCode ? item?.PropertyCode : '-'}</td>
                                      <td
                                        className={
                                          item?.status === 'Active'
                                            ? `text-success`
                                            : item?.status === 'Pending'
                                            ? `text-warning`
                                            : item?.status === 'Terminated'
                                            ? `text-danger`
                                            : `text-danger`
                                        }
                                      >
                                        {item?.status === 'Active'
                                          ? `Active`
                                          : item?.status === 'Pending'
                                          ? `Pending`
                                          : item?.status === 'Terminated'
                                          ? `Terminated`
                                          : `Expired`}
                                      </td>
                                      <td>
                                        {' '}
                                        <span className='ms-lg-2' id='kt_header_user_menu_toggle'>
                                          <span
                                            className='cursor-pointer'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-attach='parent'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='bottom'
                                          >
                                            <img src={ActionDotsIoc} className='img-fluid' />
                                          </span>
                                          <ActionMenu data={item} />
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                          <div className='w-100 d-flex justify-content-end'>
                            <CompactPagination
                              pageSize={pageSize}
                              totalCount={parseInt(totalList)}
                              defaultPage={currentPage}
                              onPageChange={setCurrentPage}
                              loading={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
