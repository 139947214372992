import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';
import { currencyFormater } from '../../../../../constants/generics/customActios';

export function LeaseDetails(props: any) {
    const {t} = useTranslation()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-8">
                                        <h6 className="d-inline text-black fw-bold font-15"> {t("Lease Number")} : </h6>
                                        <span className="text-black fw-bold font-15"> {props.leaseData?.leaseNumber} </span>
                                    </div>
                                </div>
                                <div className=" col-sm-6 col-md-3 col-lg-2 col-12 mb-8">
                                    <h6 className="text-theme-gray mb-5 fw-normal font-13"> {t("Lease Start Date")} </h6>
                                    <span className="text-black fw-bold font-15"> {formatDate(props.leaseData?.leaseStartDate)} </span>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-2 col-12 mb-8">
                                    <h6 className="text-theme-gray mb-5 fw-normal font-13"> {t("Lease End Date")} </h6>
                                    <span className="text-black fw-bold font-15"> {formatDate(props.leaseData?.leaseEndDate)} </span>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-2 col-12 mb-8">
                                    <h6 className="text-theme-gray mb-5 fw-normal font-13"> {t("No. of Payments")} </h6>
                                    <span className="text-black fw-bold font-15"> {props.paymentCount} </span>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-2 col-12 mb-8">
                                    <h6 className="text-theme-gray mb-5 fw-normal font-13"> {t("Documents")} </h6>
                                    <span className="text-black fw-bold font-15"> {props.leaseData?.documentCount} </span>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-2 col-12 mb-8">
                                    <h6 className="text-theme-gray mb-5 fw-normal font-13"> {t("Annual Rent")} </h6>
                                    <span className="text-black fw-bold font-15"> {t("AED")} {currencyFormater(props.leaseData?.rent)} </span>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-2 col-12 mb-8">
                                    <h6 className="text-theme-gray mb-5 fw-normal font-13"> {t("Security Deposit")} </h6>
                                    <span className="text-black fw-bold font-15"> {t("AED")} {currencyFormater(props.leaseData?.securityDepost)} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}