import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../../constants/generics/customActios';
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';

export function SecurityDeposit({ payment }: any) {
    const { t } = useTranslation();
    
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className='accordion-item border-0'>
                                        {/* <h2 className='accordion-header offers_details_accordian_div1' id='flush-headingSeven'>
                                            <button
                                                className='accordion-button collapsed p-0'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#payment-plan-details'
                                            ><h4 className='fw-bolder'>{t("Security Deposit And Rent")}</h4>
                                            </button>
                                        </h2> */}
                                        <div
                                            id='payment-plan-details'
                                            className='accordion-collapse collapse show'
                                            data-bs-parent='#accordion-step-five'
                                        >  
                                            <div className='accordion-body pb-5 pt-0 px-0'>
                                                <div className='row form-add-design'>
                                                    <div className='col-12'>
                                                        <div className='mb-10'>
                                                            <h4 className='fw-bolder'>{t("Security Deposit")}</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-12'>
                                                        <div className='mb-10'>
                                                            <label className='form-label fw-bolder'>{t("Security Date")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                    {moment(payment?.sd?.securityDate).format('DD-MM-YYYY')}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-12'>
                                                        <div className='mb-10'>
                                                            <label className='form-label fw-bolder'>{t("Cheque Date")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                    {moment(payment?.sd?.checkDate).format('DD-MM-YYYY')}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-12'>
                                                        <div className='mb-10'>
                                                            <label className='form-label fw-bolder'>{t("Amount")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                    {t("AED")} {currencyFormater(payment?.sd?.sdAmount)}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-12'>
                                                        <div className='mb-10'>
                                                            <label className='form-label fw-bolder'>{t("VAT")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                    {t("AED")}	 {currencyFormater(payment?.sd?.vat)}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-12'>
                                                        <div className='mb-10'>
                                                            <label className='form-label fw-bolder'>{t("Total Amount")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                    {t("AED")}	 {currencyFormater(payment?.sd?.totalAmount)}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                    {payment?.rent?.length > 0 && payment?.rent?.map((rent: any, index: any) => (
                                                        <div className="row" key={index}>

                                                            <div className='col-12'>
                                                                <div className='mb-10'>
                                                                    <h4 className='fw-bolder font-18'>	{payment?.rent?.length > 1 ? `${t("Rent Installment")} ${index + 1}` : t("Rent Installment")}</h4>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='row'>
                                                                    <div className='col-md-4 col-12'>
                                                                        <div className='mb-10'>
                                                                            <label className='form-label fw-bolder'>{t("Security Date")}</label>
                                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                                {moment(rent?.securityDate).format('DD-MM-YYYY')}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-4 col-12'>
                                                                        <div className='mb-10'>
                                                                            <label className='form-label fw-bolder'>{t("Cheque Date")}</label>
                                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                                {moment(rent?.checkDate).format('DD-MM-YYYY')}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div><div className='col-md-4 col-12'>
                                                                        <div className='mb-10'>
                                                                            <label className='form-label fw-bolder'>{t("Amount")}</label>
                                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                                    {t("AED")}	{currencyFormater(rent?.rentAmount)}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div><div className='col-md-4 col-12'>
                                                                        <div className=''>
                                                                            <label className='form-label fw-bolder'>{t("VAT")}</label>
                                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                                    {t("AED")}	{currencyFormater(rent?.vat)}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-4 col-12'>
                                                                        <div className=''>
                                                                            <label className='form-label fw-bolder'>{t("Total Amount")}</label>
                                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                                <h6 className='font-14 fw-bold d-block mb-0'>
                                                                                    {t("AED")}	{currencyFormater(rent?.totalAmount)}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                { (index < payment?.rent?.length-1) &&
                                                                    <hr className='my-5' />
                                                                }
                                                            </div>
                                                            {/* {payment?.rent?.length > 1 ? (<><hr /></>) : ''} */}
                                                        </div>
                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}