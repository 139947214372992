import React, {FC, useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import { PageTitle } from '../../components/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom';
import { AddActivities } from './AddActivities/AddActivities';
import { ActivityDetails } from './ActivityDetails/ActivityDetails';
import { ActivitiesListWrapper } from './ActivitiesList/ActivitiesListWrapper';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';

const FCSupervisorServicesPage: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/activities/list'>
                            <ActivitiesListWrapper />
                        </Route>
                        <Route path='/activities/add'>
                            <AddActivities setPopupSuccessMsg={setPopupSuccessMsg} />
                        </Route>
                        <Route path='/activities/view'>
                            <ActivityDetails setPopupSuccessMsg={setPopupSuccessMsg} />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
            <CustomRequestSuccessfulPopup 
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    );
}
const FCSupervisorActivitiesWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FCSupervisorServicesPage  />
        </>
    )
};

export {FCSupervisorActivitiesWrapper}
