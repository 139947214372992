import React, {FC, useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import { PageTitle } from '../../components/layout/core'
import {Redirect, Route, Switch} from 'react-router-dom';
import { FacilityWorkorderListWrapper } from './FacilityWorkorderList/FacilityWorkorderListWrapper';
import { WorkOrderViewWrapper } from './FacilityWorkorderView/WorkOrderViewWrapper';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';
import { CheckList } from './FacilityWorkorderView/FacilityWorkorderDetails/CorrectiveWorkorderDetails/CheckList';
import { UpdateInspection } from './FacilityWorkorderView/FacilityWorkorderDetails/ClearanceWorkorderDetails/MoveOutinspectionUpdate/UpdateInspection';
import { ViewInspectionItem } from './FacilityWorkorderView/FacilityWorkorderDetails/ClearanceWorkorderDetails/MoveOutinspectionView/ViewInspectionItem';
import { MoveOutInspection } from './FacilityWorkorderView/FacilityWorkorderDetails/ClearanceWorkorderDetails/MoveOutInspectionStart/MoveOutInspection';

const FacilityWorkorderPage: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/facility-workorder/list'>
                            <FacilityWorkorderListWrapper />
                        </Route>
                        <Route path='/facility-workorder/view'>
                            <WorkOrderViewWrapper setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/facility-workorder/check-list'>
                            <CheckList setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/facility-workorder/inspection'>
                            <MoveOutInspection setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/facility-workorder/inspection-update'>
                            <UpdateInspection />
                        </Route>
                        <Route path='/facility-workorder/inspection-view'>
                            <ViewInspectionItem />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
            <CustomRequestSuccessfulPopup 
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    );
}
const FacilityWorkorderWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FacilityWorkorderPage />
        </>
    )
};

export {FacilityWorkorderWrapper}
