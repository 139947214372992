import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {OnHoldTabs} from './OnHoldTabs/OnHoldTabs'
import {Upcoming} from './Upcoming/Upcoming'
import {Closed} from './Closed/Closed'
import { getApprovals } from '../../../../../constants/axios/apis';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../constants';

export function OnHold() {
    // const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    // const [loading, setLoading] = useState(true);
    
    // const [approvalList, setApprovalList] = useState([]);
    
    // const [upomingApprovalList, setUpomingApprovalList] = useState<any>([]);
    // const [closedApprovalList, setClosedApprovalList] = useState<any>([]);

    // useEffect(()=>{
    //     setLoading(true)
    //     setUpomingApprovalList([])
    //     setClosedApprovalList([])
    //     let payload = '?sub_type=18'
    //     payload += currentBuilding ? payload + '&building_id=' + currentBuilding : ''
    //     getApprovals(payload).then(({ data }) => {
    //         if(Array.isArray(data)){
    //             // const filteredData = data.filter((el:any)=>el.subReference === 18)
    //             setUpomingApprovalList(data?.filter((el:any)=>el.status !== 'Approved'))
    //             setClosedApprovalList(data?.filter((el:any)=>el.status === 'Approved'))
    //             // setApprovalList(data)
    //         }
    //         setLoading(false)
    //         // setTimeout(() => {
    //         // }, 500)
    //     }).catch((e)=>{
    //         setLoading(false)
    //     })
    // }, [currentBuilding])

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3 movein-moveout">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <Switch>
                                        <Route path='/fmteam-approvals/on-hold/upcoming'>
                                            <OnHoldTabs />
                                            <Upcoming />
                                            {/* //  approvalList={upomingApprovalList} loading={loading}/> */}
                                        </Route>
                                        <Route path='/fmteam-approvals/on-hold/closed'>
                                            <OnHoldTabs />
                                            <Closed />
                                            {/* // approvalList={closedApprovalList} loading={loading}/> */}
                                        </Route>
                                        <Redirect to='/error/403' />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}