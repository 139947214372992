import React, { Suspense, lazy, useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../components/partials'
import { DashboardWrapper } from '../../pages/dashboard/DashboardWrapper'

import { MarketPlaceWrapper } from '../../pages/market-place/MarketPlaceWrapper'

import { ProfilePage } from '../../components/profile/ProfilePage'

import { FCSoftServicesDashboardWrapper } from '../../pages/fc-soft-services-dashboard/FCSoftServicesDashboardWrapper'

import { FCSupervisorDashboardWrapper } from '../../pages/fc-supervisor-dashboard/FCSupervisorDashboardWrapper'

import { FCDashboardWrapper } from '../../pages/fc-dashboard/FCDashboardWrapper'

import { FinanceDashboardWrapper } from '../../pages/finance-dashboard/FinanceDashboardWrapper'

import { MyProfileWrapper } from '../../pages/my-profile/MyProfileWrapper'
import { NotificationsWrapper } from '../../pages/notifications/NotificationsWrapper'
import { MoveOutApproval } from '../../pages/fc-supervisor-dashboard/components/MoveOutApproval/MoveOutApproval'
import { FMTeamDashboardWrapper } from '../../pages/fmteam-dashboard/FMTeamDashboardWrapper'
import { MTADashboardWrapper } from '../../pages/mta-dashboard/MTADashboardWrapper'
import { MTAPropertiesWrapper } from '../../pages/mta-properties/MTAPropertiesWrappers'
import { MTAPropertyDetails } from '../../pages/mta-properties/components/MTAPropertyDetails/MTAPropertyDetails'
import { EditPropertyDetails } from '../../pages/mta-properties/components/EditPropertyDetails/EditPropertyDetails'
import { CTMoveInWrapper } from '../../pages/ct-move-in/CTMoveInWrapper'
import { MTAMoveinProcessWrapper } from '../../pages/mta-properties/components/MTAMoveinProcess/MTAMoveinProcessWrapper'
import { PMMyTeamWrapper } from '../../pages/pm-myteam/PMMyTeamWrapper'
import { MTAMyteamWrapper } from '../../pages/mta-myteam/MTAMyteamWrapper'
import { MTAMyteamWrapperNew } from '../../pages/mta-myteam/MTAMyteamWrapperNew'
import { MTAMyTeamAddMember } from '../../pages/mta-myteam/components/AddMember/AddMember'
import { MTAMyteamActionRequired } from '../../pages/mta-myteam/components/ActionRequired/ActionRequired'
import { MTATeamMembersDetails } from '../../pages/mta-myteam/components/TeamMembersDetails/TeamMembersDetails'
import { PMTeamMembersDetails } from '../../pages/pm-myteam/components/TeamMembersDetails/TeamMembersDetails'
import { MTAApprovalsWrapper } from '../../pages/mta-approvals/MTAApprovalsWrapper'
// import { MTAApprovalsDetails } from '../../pages/mta-approvals/MTAApprovalsView/Details/MTAApprovalsDetails'
import { MTAReportWrapper } from '../../pages/mta-report/MTAReportWrapper'
import { MTACampaignWrapper } from '../../pages/mta-campaign/MTACampaignWrapper'
import { MTAAppSettingsWrapper } from '../../pages/mta-app-settings/MTAAppSettingsWrapper'
import { MTAMarketPlaceWrapper } from '../../pages/mta-market-place/MTAMarketPlaceWrapper'
import { MTAWebPagesWrapper } from '../../pages/mta-webpages/MTAWebPagesWrapper'
import { FMSMServicesWrapper } from '../../pages/fmsm-services/FMSMServicesWrapper'
import { PMApprovalsWrapper } from '../../pages/pm-approvals/PMApprovalsWrapper'
import { InsufficientNoticeWrapper } from '../../pages/pm-approvals/InsufficientNotice/InsufficientNoticeWrapper'
import { PMEarlyTerminationRequestWrapper } from '../../pages/pm-approvals/PMEarlyTerminationRequest/PMEarlyTerminationRequestWrapper'
import { OfferDetailsWrapper } from '../../pages/pm-approvals/OfferDetails/OfferWrapper'
import { PMUnrenewedLeasesWrapper } from '../../pages/pm-unrenewed-leases/PMUnrenewedLeasesWrapper'
import { RaiseProformaWrapper } from '../../pages/pm-unrenewed-leases/components/RaiseProforma/RaiseProformaWrapper'
import { PMAvailabilityWrapper } from '../../pages/pm-availability/PMAvailabilityWrapper'
import { PMTenantSearchWrapper } from '../../pages/pm-tenant-search/PMTenantSearchWrapper'
import { PMRefundsWrapper } from '../../pages/pm-refunds/PMRefundsWrapper'
import { PMOffersAndRenewalsWrapper } from '../../pages/pm-offers-and-renewals/PMOffersAndRenewalsWrapper'
import { PMTenantWrapper } from '../../pages/pm-tenant/PMTenantWrapper'
import { PMLeadsWrapper } from '../../pages/pm-leads/PMLeadsWrapper'
import { PMPropertyDataWrapper } from '../../pages/pm-property-data/PMPropertyDataWrapper'
import { PMCalenderWrapper } from '../../pages/pm-calender/PMCalenderWrapper'
import { PTDocumentsWrapper } from '../../pages/pt-documents/PTDocumentsWrapper'
import { PTFaq } from '../../pages/pt-faq/Faq'
import { PTSettings } from '../../pages/pt-settings/PTSettings'
import { PTPersonalInformationPage } from '../../pages/pt-personal-information/PTPersonalInformationPage'
import { BRStatementsWrapper } from '../../pages/br-statements/BRStatementsWrapper'
import { FCSSCalenderWrapper } from '../../pages/fc-ss-calender/FCSSCalenderWrapper'
import { SingleBrokerDetails } from '../../pages/SinglebrokersData/SingleBrokerDetails/SingleBrokerDetails'
import { ChangeAddress } from '../../pages/mta-properties/components/EditPropertyDetails/ChangeAddress'
import { ChooseOnMap } from '../../pages/mta-properties/components/EditPropertyDetails/ChooseOnMap'
import { PTMarketPlaceWrapper } from '../../pages/pt-market-place/PTMarketPlaceWrapper'
import { ContractRenewal } from '../../pages/pm-unrenewed-leases/components/ContractRenewal/ContractRenewal'
import { PMReportWrapper } from '../../pages/pm-report/PmReportWrapper'
import { PMMyteamActionRequired } from '../../pages/pm-myteam/components/ActionRequired/ActionRequired'
import { PMMyTeamAddMember } from '../../pages/pm-myteam/components/AddMember/AddMember'
import { ContractWrapper } from '../../pages/pm-approvals/Contract/ContractWrapper'
import { ApprovalsRenewalWrapper } from '../../pages/pm-offers-and-renewals/components/ApprovalRenewal/ApprovalsRenewalWrapper'
import { MoveInDetailsWrapper } from '../../pages/approvals/components/ApprovalTabs/UpcomingApprovals/MoveIn/MoveInDetailsWrapper/MoveInDetailsWrapper'
import { MoveOutDetailsWrapper } from '../../pages/approvals/components/ApprovalTabs/UpcomingApprovals/MoveOut/MoveOutDetailsWrapper/MoveOutDetailsWrapper'
import { MoveInDetailsWrapper1 } from '../../pages/approvals/components/ApprovalTabs/ClosedApprovals/MoveIn/MoveInDetailsWrapper/MoveInDetailsWrapper'
import { MoveOutDetailsWrapper1 } from '../../pages/approvals/components/ApprovalTabs/ClosedApprovals/MoveOut/MoveOutDetailsWrapper/MoveOutDetailsWrapper'
import { FCSSViewDetailsWrapper } from '../../pages/fc-soft-services-services/access-cards/components/ViewDetails/ViewDetailsWrapper/ViewDetailsWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../constants'
import { FMTeamViewDetailsWrapper } from '../../pages/fmteam-approvals/components/ViewDetailsWrapper/ViewDetailsWrapper'
import { EditMember } from '../../pages/fc-supervisor-myteam/components/Edit/EditMember'
import { InActiveDetailWrapper } from '../../pages/fc-soft-services-services/access-cards/components/ViewDetails/InActiveDetailWrapper/InActiveDetailWrapper'
import { FacilityMaintenanceWrapper } from '../../pages/facility-maintenance/FacilityMaintenanceWrapper'
import { FCSupervisorActivitiesWrapper } from '../../pages/facility-activities/FCSupervisorActivitiesWrapper'
import { FacilityWorkorderWrapper } from '../../pages/facility-workorders/FacilityWorkorderWrapper'
import { ParkingWrapper } from '../../pages/fc-ss-parking/ParkingWrapper'
import { FinanceServiceRequestRefundsWrapper } from '../../pages/finance-services-request-refunds/FinanceServiceRequestRefundsWrapper'
import { FinanceRefundDetailsScreen } from '../../pages/finance-services-request-refunds/FinanceRefundsDeatilsScreen/FinanceRefundDetailScreen'
import { FinanceServiceRequestLeaseWrapper } from '../../pages/finance-lease-details/FinanceServiceRequestLeaseWrapper'
import { FinanceLeaseDetailScreen } from '../../pages/finance-lease-details/FinanceLeaseDetailsScreen/FinanceLeaseDetailScreen'
import { getUserRole } from '../../constants/generics/userCheck'
import { getAllowedProfileRoutes, getAllowedRoutes } from '../../configs/roleUtils'
import PrivateRoutesConfig from '../../configs/PrivateRoutesConfig'
import { ErrorsPage } from '../../components/errors/ErrorsPage'
import { getMenu } from '../../constants/axios/apis'
import * as General from '../../constants/redux/Sagas/GeneralSaga'
import PrivateRoutesConfigProfileMenu from '../../configs/PrivateRoutesConfigProfileMenu'
import { CustomLoader2 } from '../../constants/generics/customActios'
import { PropertyManagerDashboardWrapper } from '../../pages/pm-dashboard/PropertyManagerDashboardWrapper'
import { UserRedirectHandler } from '../../pages/notifications/components/UserRedirectHandler'
import { FCSSApprovalHandler } from '../../pages/notifications/components/FCSSApprovalHandler'
import { RedirectHandler } from './RedirectHandler'

export function PrivateRoutesNew() {
    const dispatch = useDispatch()

    const [permissions, setPermissions] = useState<any>([])
    useEffect(() => {
        getMenu()
            .then((response: any) => {
                const customData = {
                    permissions: response?.data?.data,
                    errorCode: response?.data?.errorCode,
                    statusCode: response?.status,
                    apiStatusCode: response?.data?.code,
                    fullResponse: response
                }
                if (response?.status === 200 && response?.data?.errorCode != 1) {

                    dispatch(General.actions.permissions(customData))
                } else {
                    dispatch(General.actions.permissions(customData))
                }
            })
            .catch((e) => {
            })
    }, [])
    let userEmail = 'Mohammad'
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }
    // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
    const MyUnitWrapper = lazy(() => import('../../pages/my-unit/MyUnitWrapper'))
    const ThankYouServiceWrapper = lazy(() =>
        import('../../pages/thankyou-service/ThankYouServiceWrapper')
    )
    const MoveInWrapper = lazy(() => import('../../pages/move-in/MoveInWrapper'))
    // const MarketPlaceOfferDetails = lazy(() => import('../pages/market-place/MarketPlaceOfferDetails/MarketPlaceOfferDetails'))
    // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
    const ProfileEditPage = lazy(() => import('../../components/profile/ProfileEdit'))
    const WizardsPage = lazy(() => import('../../components/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../../components/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../../components/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../../components/apps/chat/ChatPage'))
    const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)

    const allPermissions: any = useSelector<RootState>(({ General }) => General.permissions)
    // console.log(allPermissions, 'allPermissions PRN');

    const state: any = useSelector(state => state);
    const userRoleCode = getUserRole(state?.auth?.user?.data);
    var allowedRoutes = allPermissions != undefined ? getAllowedRoutes(PrivateRoutesConfig, userRoleCode, allPermissions) : 0;
    var allowedProfileRoutes = allPermissions != undefined ? getAllowedProfileRoutes(PrivateRoutesConfigProfileMenu, userRoleCode, allPermissions) : 0;

    if (allPermissions === undefined) { return (<div><CustomLoader2 height={'200'} /></div>) }
    //Config Aside-Menu routes
    let allRoutes = new Array()
    allowedRoutes?.length > 0 && allowedRoutes?.forEach((route: any) => {
        if (route?.menu?.subMenu?.length > 0) {
            route?.menu?.subMenu.forEach((subRoute: any) => {
                allRoutes.push({
                    path: subRoute.path,
                    component: subRoute.component,
                    menuCode: subRoute.menuCode,
                })
                subRoute?.menu?.supportedComponents?.forEach((item: any) => {
                    if (item.path && item.component) {
                        allRoutes.push({
                            path: item.path,
                            component: item.component,
                            menuCode: subRoute.menuCode,
                        })
                    }
                })
            })
        } else {
            allRoutes.push({
                path: route.path,
                component: route.component,
                menuCode: route.menuCode,
            })
            route?.menu?.supportedComponents?.forEach((item: any) => {
                if (item.path && item.component) {
                    allRoutes.push({
                        path: item.path,
                        component: item.component,
                        menuCode: route.menuCode,
                    })
                }
            })
        }
    });

    //Config Profile-Menu routes
    let allProfileRoutes = new Array()
    allowedProfileRoutes?.length > 0 && allowedProfileRoutes?.forEach((route: any) => {
        allProfileRoutes.push({
            path: route.path,
            component: route.component,
            menuCode: route.menuCode,
        })
        route?.menu?.supportedComponents?.forEach((item: any) => {
            if (item.path && item.component) {
                allProfileRoutes.push({
                    path: item.path,
                    component: item.component,
                    menuCode: route.menuCode,
                })
            }
        })
    });

    const PMRoutes = [
        { path:'/pm-unrenewed-leases', component: PMUnrenewedLeasesWrapper  },
        { path:'/pm-refunds', component: PMRefundsWrapper  },
        { path:'/pm-tenant', component: PMTenantWrapper  },
        { path:'/pm-leads', component: PMLeadsWrapper  },
    ]

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route
                    // path={userRoleCode === 'MA' || userRoleCode === 'MT' ? '/marketing-admin-dashboard': '/dashboard'}
                    path='/dashboard'
                    component={
                        userRoleCode === 'MA' || userRoleCode === 'MT' ? MTADashboardWrapper :
                        userRoleCode === 'FC' ? FCDashboardWrapper :
                            userRoleCode === 'FCSS' ? FCSoftServicesDashboardWrapper :
                                userRoleCode === 'FCS' ? FCSupervisorDashboardWrapper :
                                    userRoleCode === 'FT' ? FinanceDashboardWrapper :
                                        userRoleCode === 'FMSM' ? FMTeamDashboardWrapper :
                                            userRoleCode === 'FMT' ? FMTeamDashboardWrapper :
                                                // userEmail === 'commercialtenant@exalogic.co' ? CommercialTenantDashboardWrapper :
                                                userRoleCode === 'PE' || userRoleCode === 'PM' ? PropertyManagerDashboardWrapper :
                                                // userRole === 4 || userRole === 5 || userRole === 14 ? PTDashboardWrapper :
                                                // userRole === 7 && currentContract?.property_search_type == 2 ? CommercialTenantDashboardWrapper :
                                                DashboardWrapper
                    }
                />


                {/* Deploy profile menu routes */}
                {allowedProfileRoutes.map((route: any, index: number) => {
                    const access = allPermissions?.permissions?.profile?.find((item: any) => (item?.name).toUpperCase() === route.menuCode.toUpperCase())
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            // component={route.component}
                            render={(props) => <route.component
                                menuCode={route.menuCode}
                                accessControl={access?.permission}
                                {...props} />}
                        />
                    )
                })}

                {/* Deploy aside menu routes */}
                {allRoutes.map((route: any, index: number) => {
                    const access = allPermissions?.permissions?.menu?.find((item: any) => (item?.name).toUpperCase() === route.menuCode.toUpperCase())
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            // component={route.component}
                            render={(props) => <route.component
                                menuCode={route.menuCode}
                                accessControl={access.permission}
                                {...props} />}
                        />
                    )
                })}

                {/* PM non menu based private routes */}
                { (userRoleCode === 'PE' || userRoleCode === 'PM') && PMRoutes.map((route: any, index: number) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                component={route.component}
                            />
                        )
                    })
                }
                {/*                 
                <Route
                    path='/contract-extension-request-accept'
                    component={ContractExtensionRequestAcceptWrapper}
                />
                <Route
                    path='/renewal-notices-request-accept'
                    component={RenewalNoticesRequestAcceptWrapper}
                />
                <Route
                    path='/contract-renewal-request-accept'
                    component={ContractRenewalRequestAcceptWrapper}
                /> */}
                {/* <Route path='/my-unit' component={MyUnitWrapper} /> */}
                <Route path='/market-place' component={MarketPlaceWrapper} /> {/* --- TODO: must check for popup issue!!!!--- */}
                {/* <Route path='/maintenance' component={MaintenancePage} /> */}
                {/* <Route path='/my-services' component={MyServicePage} /> */}
                {/* <Route path='/payments' component={PaymentsPage} /> */}
                {/* <Route path='/announcements' component={AnnouncementsPage} /> */}
                <Route path='/profile' component={ProfilePage} />
                {/* <Route path='/faq' component={Faq} /> */}
                <Route path='/edit' component={ProfileEditPage} />
                {/* <Route path='/documents' component={DocumentsPage} /> */}
                <Route path='/crafted/account' component={AccountPage} />
                <Route path='/chat' component={ChatPage} />
                {/* <Route path='/move-in' component={MoveInWrapper} /> */}
                {/* <Route path='/move-out' component={MoveOutWrapper} /> */}
                <Route path='/thankyou-service' component={ThankYouServiceWrapper} />
                {/* <Route path='/announcements-details' component={AnnouncementsDetails} /> */}
                {/* Start Broker Admin Screens */}
                {/* <Route path='/broker-admin-dashboard' component={BrokerAdminDashboardWrapper} />
                <Route path='/broker-admin-MyTeam' component={BrokerAdminMyTeamWrapper} />
                <Route path='/visits' component={TeamsActivityWrapper} />
                <Route path='/offers' component={TeamActivityOfferWrapper} />
                <Route path='/offers-details/:id' component={TeamActivityOfferWrapper} />
                <Route path='/reservedproperties' component={ReservedPropertiesWrapper} />
                <Route path='/contracts' component={ContractsWrapper} />
                <Route path='/property-details' component={ContractsWrapper} />
                <Route path='/statements' component={StatementsWrapper} />
                <Route path='/br-statements' component={BRStatementsWrapper} />
                <Route path='/brokers/in-progress' component={BrokersWrapper} />
                <Route path='/brokers/approved' component={BrokersWrapper} />
                <Route path='/brokers/rejected' component={BrokersWrapper} />
                <Route path='/SingleBroker/in-progress' component={SingleBrokersWrapper} />
                <Route path='/SingleBroker/approved' component={SingleBrokersWrapper} />
                <Route path='/SingleBroker/rejected' component={SingleBrokersWrapper} />
                <Route path='/SingleBroker/data/details' component={SingleBrokerDetails} /> */}
                {/* End Broker Admin Screens */}
                {/* Start Facility Contractor Soft Services Screens */}
                {/* <Route path='/approvals' component={ApprovalsListWrapper} />
                <Route path='/incidents' component={ServicesWrapper} />
                <Route path='/access-cards' component={ServicesWrapper} />
                <Route path='/visitors' component={ServicesWrapper} />
                <Route path='/fc-ss-documents' component={ServicesWrapper} />
                <Route path='/parking' component={ParkingWrapper} />
                <Route path='/directory' component={ServicesWrapper} />
                <Route path='/scheduled-visit' component={ScheduledVisitWrapper} />
                <Route path='/fc-ss-announcements' component={FCSoftServicesAnnouncementsWrapper} />
                <Route path='/fc-ss-maintenance' component={MaintenanceWrapper} />
                <Route path='/fcss-moveinDetails' component={MoveInDetailsWrapper} />
                <Route path='/fcss-moveoutDetails' component={MoveOutDetailsWrapper} />
                <Route path='/fcss-moveinDetails1' component={MoveInDetailsWrapper1} />
                <Route path='/fcss-moveoutDetails1' component={MoveOutDetailsWrapper1} />
                <Route path='/fcss-viewDetails' component={FCSSViewDetailsWrapper} />
                <Route path='/fcss-viewDetails-inactive' component={InActiveDetailWrapper} /> */}
                {/* End Facility Contractor Soft Services Screens */}
                {/* Start Facility Contractor  Screens */}
                {/* <Route path='/fc-moveout-inspection' component={MoveOutInspectionWrapper} />
                <Route path='/fc-reports' component={FCReportsWrapper} />
                <Route path='/fc-calendar' component={FCCalendarWrapper} />
                <Route path='/facility-workorder' component={FacilityWorkorderWrapper} /> */}
                {/*<Route path='/add-attendance/total' component={AddAttendance} />*/}
                {/*<Route path='/attendance/total' component={AttandanceListWrapper} />*/}
                {/* End Facility Contractor  Screens */}
                {/*Start FC Supervisor Screen*/}
                {/* <Route path='/scheduledvisitsdetails' component={ScheduledVisitsDetails} />
                <Route path='/moveoutapproval' component={MoveOutApproval} />
                <Route path='/activities' component={FCSupervisorActivitiesWrapper} />
                <Route path='/facility-maintenance' component={FacilityMaintenanceWrapper} />
                <Route path='/su-announcement' component={FCSupervisorAnnouncementWrapper} />
                <Route path='/su-create-announcements' component={SupervisorCreateAnnouncements} />
                <Route path='/fc-su-myteam' component={FCSupervisorMyTeamWrapper} />
                <Route path='/fc-su-myteam-details' component={FCSMyTeamDetailsWrapper} />
                <Route path='/addtechnician' component={AddTechnicianPage} />
                <Route path='/edit-member' component={EditMember} />
                <Route path='/fc-su-report' component={FCSupervisorReportsWrapper} />
                <Route path='/fc-su-calendar' component={FCSupervisorCalenderWrapper} />
                <Route path='/fc-ss-calendar' component={FCSSCalenderWrapper} /> */}
                {/*<Route path='/fc-su-calendar-add-attendance' component={AddAttendance} />*/}
                {/*END FC Supervisor Screen*/}
                {/*Start Finance Team Screen*/}
                {/* <Route path='/finance-services-request' component={FinanceServiceRequestWrapper} />
                <Route path='/finance-services-request/view' component={FinanceDetailsScreen} />
                <Route path='/finance-services-request-refunds/list' component={FinanceServiceRequestRefundsWrapper} />
                <Route path='/finance-services-request-refunds/view' component={FinanceRefundDetailsScreen} />
                <Route path='/finance-services-lease/list' component={FinanceServiceRequestLeaseWrapper} />
                <Route path='/finance-services-lease-details/view' component={FinanceLeaseDetailScreen} />
                <Route path='/finance-moveout-requests' component={FinanceMoveoutRequestsWrapper} />
                <Route path='/finance-receipts' component={FinanceReceiptsWrapper} />
                <Route path='/finance-payments' component={FinancePaymentWrapper} />
                <Route path='/finance-reports' component={FinanceReportsWrapper} />
                <Route path='/finance-calendar' component={FinanceCalendarWrapper} /> */}
                {/*End Finance Team Screen*/}
                {/*My Profile Dashboard Start*/}
                <Route path='/my-profile' component={MyProfileWrapper} />
                <Route path='/notifications' component={NotificationsWrapper} />
                <Route path='/user-redirect' component={UserRedirectHandler} />
                <Route path='/move-approval-redirect' component={FCSSApprovalHandler} />
                {/* <Route path='/pt-alerts' component={PTAlertsWrapper} /> */}
                {/*My Profile Dashboard End*/}
                {/*Start FM Team Screen*/}
                {/* <Route path='/fmteam-approvals' component={FMTeamApprovalsListWrapper} />
                <Route path='/fmteam-viewDetails' component={FMTeamViewDetailsWrapper} />
                <Route path='/fm-myteam' component={FMTeamMyTeamWrapper} />
                <Route path='/fm-report' component={FMTeamReportsWrapper} /> */}
                {/*End FM Team Screen*/}
                {/*Start FMSM Screen*/}
                {/* <Route path='/fmsm-access-cards' component={FMSMAccessCardsWrapper} />
                <Route path='/fmsm-my-team' component={MyTeamWrapper} />
                <Route path='/fmsm-services' component={FMSMServicesWrapper} /> */}
                {/*End FMSM Screen*/}
                {/*Start MTA Screen*/}
                {/* <Route path='/marketing-admin-dashboard' component={MTADashboardWrapper} />
                <Route path='/mta-Properties' component={MTAPropertiesWrapper} />
                <Route path='/mta-property-details' component={MTAPropertyDetails} />
                <Route path='/mta-edit-propertyDetails' component={EditPropertyDetails} />
                <Route path='/change-address' component={ChangeAddress} />
                <Route path='/choose-on-map' component={ChooseOnMap} />
                <Route path='/mta-movein-process' component={MTAMoveinProcessWrapper} />
                <Route path='/mta-myteam' component={MTAMyteamWrapper} />
                <Route path='/pm-myteam' component={PMMyTeamWrapper} />
                <Route path='/mta-myteam-new' component={MTAMyteamWrapperNew} />
                <Route path='/mta-myteam-add-member' component={MTAMyTeamAddMember} />
                <Route path='/pm-myteam-add-member' component={PMMyTeamAddMember} />
                <Route path='/mta-myteam-action-required' component={MTAMyteamActionRequired} />
                <Route path='/pm-myteam-action-required' component={PMMyteamActionRequired} />
                <Route path='/mta-myteam-team-members-details' component={MTATeamMembersDetails} />
                <Route path='/pm-myteam-team-members-details' component={PMTeamMembersDetails} />
                <Route path='/pm-calender' component={PMCalenderWrapper} />
                <Route path='/mta-approvals' component={MTAApprovalsWrapper} />
                <Route path='/mta-approvals-details' component={MTAApprovalsDetails} />
                <Route path='/mta-report' component={MTAReportWrapper} />
                <Route path='/mta-app-settings' component={MTAAppSettingsWrapper} />
                <Route path='/mta-campaign' component={MTACampaignWrapper} />
                <Route path='/mta-market-place' component={MTAMarketPlaceWrapper} />
                <Route path='/mta-web-pages' component={MTAWebPagesWrapper} /> */}
                {/*End MTA Screen*/}
                {/* Start Commercial Tenant Screens */}
                {/* <Route path='/ct-move-in' component={CTMoveInWrapper} /> */}
                {/* End Commercial Tenant Screens */}
                {/* Start Property Manager Screens */}
                {/* <Route path='/pm-approvals' component={PMApprovalsWrapper} />
                <Route path='/pm-insufficient-notice' component={InsufficientNoticeWrapper} />
                <Route path='/pm-early-termination-request' component={PMEarlyTerminationRequestWrapper} />
                <Route path='/offer-details' component={OfferDetailsWrapper} />
                <Route path='/contract' component={ContractWrapper} />
                <Route path='/pm-raise-proforma' component={RaiseProformaWrapper} />
                <Route path='/pm-availability' component={PMAvailabilityWrapper} />
                <Route path='/pm-tenant-search' component={PMTenantSearchWrapper} />
                <Route path='/pm-unrenewed-leases' component={PMUnrenewedLeasesWrapper} />
                <Route path='/pm-refunds' component={PMRefundsWrapper} />
                <Route path='/pm-tenant' component={PMTenantWrapper} />
                <Route path='/pm-leads' component={PMLeadsWrapper} />
                <Route path='/pm-offers-and-renewals' component={PMOffersAndRenewalsWrapper} />
                <Route path='/pm-property-data' component={PMPropertyDataWrapper} />
                <Route path='/pm-approvals-offer' component={ApprovalsOfferWrapper} />
                <Route path='/pm-approvals-renewals' component={ApprovalsRenewalWrapper} />
                <Route path='/pm-contract-renewal' component={ContractRenewal} />
                <Route path='/pm-report' component={PMReportWrapper} /> */}

                {/* End Property Manager Screens */}
                {/* Start Prospective Tenant Screens */}
                {/* <Route path='/pt-activity' component={PTActivityWrapper} />
                <Route path='/pt-alerts' component={PTAlertsWrapper} />
                <Route path='/pt-documents' component={PTDocumentsWrapper} />
                <Route path='/pt-faq' component={PTFaq} />
                <Route path='/pt-settings' component={PTSettings} />
                <Route path='/pt-personal-information' component={PTPersonalInformationPage} />
                <Route path='/marketPlace' component={PTMarketPlaceWrapper} /> */}
                {/* End Prospective Tenant Screens */}


                {/* <Route path='/crafted/pages/wizards' component={WizardsPage} />
                <Route path='/crafted/widgets' component={WidgetsPage} />
                <Route path='/menu-test' component={MenuTestPage} />
                <Route path='/payment/return/:status' component={Index} />
                <Redirect from='/auth' to='/dashboard' /> */}


                <Route path='/error' component={ErrorsPage} />
                {/* <Redirect
                    exact
                    from='/'
                    to={
                        // userRoleCode === 'MA' || userRoleCode === 'MT' ? '/marketing-admin-dashboard/all' : //TODO - ADD MTA
                        userRoleCode && ['FC', 'FCS', 'FMT', 'FCSS', 'FMSM', 'FT', 'PE', 'PM', 'MT', 'MA'].includes(userRoleCode) ? '/dashboard' :
                        '/auth/login'
                    }
                /> */}
                {/* <Redirect to='/error/403' /> */}
                <Route exact path='/' component={RedirectHandler} />
                <Route path='*' component={RedirectHandler} />
            </Switch>
        </Suspense>
    )
}
