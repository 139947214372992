import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import icondocument from '../../assets/images/ico-file-upload.png'

export function PopupListing() {


    return (
        <div className="modal fade" aria-hidden='true' id="import-popup">
            <div className="modal-dialog modal-lg modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Import User</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <div className="card card-box">
                                    <div className="card-body px-4 py-0">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <a className="btn btn-primary radius-10 px-4 font-14 font-weight-semibold" href="/Uploads/format/contact.csv">Download Sample</a>
                                            </div>
                                            <div className="col-md-12">
                                                <ul className="list-unstyled pl-0 mb-4">
                                                    <li className="font-12 text-theme-black">1. Your CSV data should be in the format below. The first line of your CSV file should be the column headers as in the table example. Also make sure that your file is <b>UTF-8</b> to avoid unnecessary <b>encoding problems</b>.</li>
                                                    <li className="font-12 text-theme-black">2. If the column <b>you are trying to import is date</b> make sure that is formatted in format Y-m-d (2020-09-09).</li>
                                                    <li className="font-12 text-danger">3. Duplicate email rows won't be imported.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="d-block w-100 text-uppercase mb-0 font-13">Choose CSV File</label>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="text-center radius-15 p-4 d-flex flex-wrap align-items-center border-box">
                                                    <div id="" className="mx-auto w-100 dropzone-csv">
                                                        <img src={icondocument} className="img-fluid doccument" alt="Image"/>
                                                        <p className="font-weight-600 theme-color font-12 my-3">Drop or upload your file here</p>
                                                        <div className="fileupload">
                                                            <input id="upload" className="form-control upload-csv form-controller" type="file"/>
                                                        </div>
                                                        <p className="mb-0 text-secondary font-12">(.csv, .xlsx formats supported)</p>
                                                    </div>
                                                    <div className="row hidden w-100" id="container">
                                                        <div className="col-12">
                                                            <div id="" className="result-csv mx-auto w-100">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="border prev-show-ck mt-2 radius-10 p-2 w-50 mx-auto">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-10">
                                                                <span className="font-13 filename"></span>
                                                            </div>
                                                            <div className="col-lg-2">
                                                                <a href="#" className="text-decoration-none">
                                                                    <i className="far fa-trash-alt font-14 text-black"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col-md-12 text-end">
                            <button type="button" className="btn btn-outline-secondary me-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}