import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Technicians} from '../components/Technicians/Technicians';
import {MyTeamGraphWrapper} from '../components/MyTeamGraph/MyTeamGraphWrapper';
import {
    getMyTeamChartData,
} from '../../../constants/axios/apis'
import { useTranslation } from 'react-i18next';

const MyTeamWrapper: React.FC<any> = ({fcsWorkOrderList}) => {
    const { t } = useTranslation();
    const [loadingFC, setLoadingFC] = useState<any>(true);
    // const [loadingFCSS, setLoadingFCSS] = useState<any>(true);
    const [errorFC, setErrorFC] = useState<any>('');
    // const [errorFCSS, setErrorFCSS] = useState<any>('');
    const [dataFC, setDataFC] = useState<any>();
    // const [dataFCSS, setDataFCSS] = useState<any>();

    useEffect(()=>{
        setLoadingFC(true)
        // setLoadingFCSS(true)
        getMyTeamChartData('?user_role=FC')
            .then((response:any) => {                
                if(response?.errorCode === 0 && response.data){
                    let active = 0
                    let inactive = 0
                    response?.data?.teamList?.forEach((member:any)=>{
                        if(member.isFmApproved === 1 && member.status === 1)
                            active ++
                        else
                            inactive ++
                    })
                    setDataFC({
                        active: active,
                        inactive: inactive,
                        total: response.data.total,
                    })
                    // setDataFC({
                    //     active: response.data.activeTechnician,
                    //     inactive: response.data.inactiveTechnician,
                    //     total: response.data.total,
                    // })
                }else if(response.errorCode === 1){
                    setDataFC({})
                    setErrorFC(response.errorDescription)
                }else{
                    setDataFC({})
                    setErrorFC(t("Some error occured!"))
                }
                setLoadingFC(false)
            }).catch((e:any)=>{
                setDataFC({})
                setErrorFC(t("Some error occured!"))
                setLoadingFC(false)
                // console.log(e, 'error in team list');
            })
        // getMyTeamChartData('?user_role=FCSS')
        //     .then((response:any) => {                
        //         if(response?.errorCode === 0 && response.data){
        //             setDataFCSS({
        //                 active: response.data.activeTechnician,
        //                 inactive: response.data.inactiveTechnician,
        //                 total: response.data.total,
        //             })
        //             // setDataFCSS({
        //             //     active: response.data.activeTechnicianFCSS,
        //             //     inactive: response.data.inactiveTechnicianFCSS,
        //             //     total: response.data.totalFCSS,
        //             // })
        //         }else if(response.errorCode === 1){
        //             setDataFCSS({})
        //             setErrorFCSS(response.errorDescription)
        //         }else{
        //             setDataFCSS({})
        //             setErrorFCSS(t("Some error occured!"))
        //         }
        //         setLoadingFCSS(false)
        //     }).catch((e:any)=>{
        //         setDataFCSS({})
        //         setErrorFCSS(t("Some error occured!"))
        //         setLoadingFCSS(false)
        //         // console.log(e, 'error in team list');
        //     })
    },[]);

    const fcChartData = { loading: loadingFC, response: dataFC, error: errorFC }
    // const fcssChartData = { loading: loadingFCSS, response: dataFCSS, error: errorFCSS }
    const fcssChartData = { loading: false, response: [], error: false }

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row mb-6 align-items-center">
                        <div className="col-md-6 col-5">
                            <h4 className="card-title font-18 text-black mb-0"> {t("My Team")} </h4>
                        </div>
                        <div className="col-md-6 col-7 text-end">
                            <Link 
                                to={{'pathname':'/addtechnician', state: {'addRole': 'FC'}}}
                                className="btn btn-primary btn-sm font-16">
                                <i className="bi bi-plus-circle-fill align-middle"></i> {t("Add Technician")}
                            </Link>
                            {/* <Link 
                                to={{'pathname':'/addtechnician', state: {'addRole': 'FCSS'}}}
                                className="btn btn-primary btn-sm font-16">
                                <i className="bi bi-plus-circle-fill align-middle"></i> {t("Add FCSS")}
                            </Link> */}
                        </div>
                    </div>                    
                    <div className="row mb-6 grid-match">
                        <div className="col-sm-5 col-12">
                            <div className="card technicians_card">
                                <div className="card-body px-8 py-2">
                                    <div className="">
                                        {/* <h4 className="card-title font-18 text-black pt-5"> Technicians </h4> */}
                                        <Technicians 
                                            fcChartData={fcChartData}
                                            fcssChartData={fcssChartData}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-7 col-12">
                            <div className="card myteamgraph_card ms-0 ms-lg-3">
                                <div className="card-body px-8 py-2">
                                    <div className="">
                                        <MyTeamGraphWrapper fcsWorkOrderList={fcsWorkOrderList}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {MyTeamWrapper}
