import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Search } from '../../TeamMembers/Search/Search';
import Propertie from '../../../../../assets/images/latest-one.png';
import Place from "../../../../../assets/images/place.svg";
import { NoDataFound } from './../../../../../constants/generics/noDataFound';
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../../constants/generics/customActios';

export function AssignedProperties({ teamDetailData }: any) {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="">
            <div className="col-12">
                <div className="row align-items-center">
                    <div className="col-9 text-start">
                        <h4 className="card-title font-18 text-black mb-0">
                            {t("Assigned Properties")} ({teamDetailData?.data?.assigned_properties?.length?teamDetailData?.data?.assigned_properties?.length:'0'})
                        </h4>
                    </div>
                    <div className="col-3">
                        {/* <Search /> */}
                    </div>
                </div>
            </div>
            <div className="col-12 mt-7">
                <div className="row">
                    {teamDetailData?.errorCode != 1 && Array.isArray(teamDetailData?.data) && teamDetailData?.data?.assigned_properties?.length > 0 ? teamDetailData?.data?.assigned_properties?.map((item: any, index: number) => {
                        return (
                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                <Link to={{
                                    pathname: '/pm-availability/property-details',
                                    state: { id: item?.propertyId }
                                    // to={{pathname: '/pm-myteam-action-required',state: { type: 'one',pid:item?.propertyId,id:teamDetailData?.data?.id, from: location.pathname}
                                }}>
                                    <div className="properties_list_view assigned_properties mb-5">
                                        <div className="card px-3 py-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3">
                                                    <div className="offer_main_img">
                                                        <img src={item?.coverImage != null ? item?.coverImage : Propertie} alt="Work Orders" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <p className="text-black fw-bold font-14 text-short">{item?.property_name?item?.property_name:'-'}</p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="offer_main_details_div_2">
                                                                <h4 className="text-black fw-bolder me-3">
                                                                    <small>{t("AED")}</small> <span className="fw-boldest font-18">{item?.price?currencyFormater(item?.price):'-'}</span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-12 offer_main_details_div_3">
                                                            <p className="mb-0 pt-2">
                                                                <img src={Place} className="img-fluid" />
                                                                {item?.propertyType}, {item?.address}, {item?.location}
                                                            </p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>)
                    })
                        : <NoDataFound />}
                </div>
            </div>
        </div>
    );
}