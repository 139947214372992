import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { createWorker } from "tesseract.js";
import { Alert, Spinner } from 'react-bootstrap-v5'
import { RootState } from '../../../../constants';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import * as dashboard from '../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import { payByCheque } from '../../../../constants/axios/apis';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import {SuccessfulPopup} from "./SuccessfulPopup";

export function PaybyCheque() {
    const history = useHistory();
    const dispatch = useDispatch()
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const ref = useRef<any>(0)
    let offerList: any
    offerList = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    const [chequeNumber, setChequeNumber] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [accountName, setAccountName] = useState('')
    const [bankName, setBankName] = useState('')
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [message, setMessage] = useState<any>()
    const inputRefCheque = useRef<any>(0)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [error, setError] = useState('')
    const [variants, setVriants] = useState('')
    const [uploadedFileCheque, setUploadedFileCheque] = useState<any>()

    const handleUploadCheque = () => {
        inputRefCheque.current?.click()
    }
    const handleDisplayFileDetailsCheque = () => {
        let fileSize: any = inputRefCheque?.current?.files[0]?.size <= 2101546
        let isAllowedType: any =
            inputRefCheque?.current?.files[0]?.type === 'image/png' ||
        inputRefCheque?.current?.files[0]?.type === 'image/jpeg' ||
        inputRefCheque?.current?.files[0]?.type === 'image/jpg' ||
        inputRefCheque?.current?.files[0]?.type === 'application/pdf'
        if (!isAllowedType) {
            setShow(true)
            setError('File is not a png/jpeg/jpg/PDF  file')
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
        if (!fileSize) {
            setShow(true)
            setError('File size should be less than 2MB')
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
        if (isAllowedType && fileSize) {
            inputRefCheque.current?.files && setUploadedFileCheque(inputRefCheque.current.files[0])
        }
    }
    // ** OCR**
    // const [ocr, setOcr] = useState("");
    // const [imageData, setImageData] = useState<any>();
    // const worker = createWorker({
    //     logger: (m) => {
    //         console.log(m);
    //     },
    // });
    // const convertImageToText = async () => {
    //     if (!imageData) return;
    //     await worker.load();
    //     await worker.loadLanguage("eng");
    //     await worker.initialize("eng");
    //     const {
    //         data: { text },
    //     } = await worker.recognize(imageData);
    //     setOcr(text);
    // };

    // useEffect(() => {
    //     convertImageToText();
    // }, [imageData]);

    // function handleImageChange(e: any) {
    //     const file = e.target.files[0];
    //     if (!file) return;
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         const imageDataUri: any = reader.result;
    //         console.log({ imageDataUri });
    //         setImageData(imageDataUri);
    //     };
    //     reader.readAsDataURL(file);
    // }
    const handleSubmit = () => {
        setLoading(true)
        var FormData = require('form-data')
        var payByChequeDetails = new FormData()
        payByChequeDetails.append('offer_id', offerList?.offerId);
        // payByChequeDetails.append('payment_category', '1');
        payByChequeDetails.append('payment_for', offerList?.tabMode);
        payByChequeDetails.append('property_id', offerList?.propertyId);
        payByChequeDetails.append('cheque_number', chequeNumber);
        payByChequeDetails.append('payment_date', offerList?.tabMode === 2 ? offerList?.sdChequeDate : offerList?.rentChequeDate);
        payByChequeDetails.append('acc_holder_name', accountName);
        payByChequeDetails.append('acc_number', accountNumber);
        payByChequeDetails.append('bank', bankName);
        payByChequeDetails.append('amount', offerList?.tabMode === 2 ? offerList?.securityDeposit : offerList?.amount);
        payByChequeDetails.append('cheque_img', uploadedFileCheque);
        payByCheque(payByChequeDetails).then((data) => {
            setLoading(false)
            if (data.code === 200 && data.errorCode != 1) {
                dispatch(dashboard.actions.offerDetails(offerList?.offerId))
                setMessage({
                    error: 0,
                    message: 'Payment Successful',
                })
                ref.current.click();
                setChequeNumber('')
                setAccountNumber('')
                setAccountName('')
                setBankName('')
                setPaymentDate(new Date())
                setUploadedFileCheque('')
            } else {
                setMessage({
                    error: 1,
                    message: data.errorDescription,
                })
                ref.current.click();
                setChequeNumber('')
                setAccountNumber('')
                setAccountName('')
                setBankName('')
                setPaymentDate(new Date())
                setUploadedFileCheque('')
            }
        })
    }
    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])
    return (
        <>
        <div className=" ">
            <div className="row">
                <div className="col-12">
                    <div className="row form-add-design">
                        <div className="col-12">
                            {/* <div>
        <p>Choose an Image</p>
        <input
            type="file"
            name=""
            id=""
            onChange={handleImageChange}
            accept="image/*"
        />
    </div>
    <div className="display-flex">
        <img src={imageData} alt="" />
        <p>{ocr}</p>
    </div> */}
                            {show ? (
                                <Alert className='font-14 fw-bold mb-8' variant={variants}>
                                    {error}!
                                </Alert>
                            ) : null}
                            <div className="mb-5 upload-input">
                                <label className="form-label fw-bolder d-block mb-3">Photo</label>
                                <input
                                    ref={inputRefCheque}
                                    onChange={handleDisplayFileDetailsCheque}
                                    className='d-none'
                                    type='file'
                                    accept='.png,.jpeg,.jpg,application/pdf' />
                                <button onClick={handleUploadCheque} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileCheque?.name ? "primary" : "primary"}`}>
                                    <span>{uploadedFileCheque?.name ? uploadedFileCheque?.name : <span>Upload  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                </button>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-5 position-relative">
                                <label className="font-14 fw-bold mb-3 d-block">Cheque Number</label>
                                <input type="text" className="form-control fw-bolder  bg-white" defaultValue='741852963' maxLength={50} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-5 position-relative">
                                <label className="font-14 fw-bold mb-3 d-block">Cheque Date</label>
                                <input type="date" className="form-control fw-bolder  bg-white" defaultValue='01/Jan/2020' placeholder="01/Jan/2020"
                                       disabled />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-5 position-relative">
                                <label className="font-14 fw-bold mb-3 d-block">Account Number</label>
                                <input type="text" className="form-control fw-bolder  bg-white" defaultValue='5469859874548' maxLength={50}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-5 position-relative">
                                <label className="font-14 fw-bold mb-3 d-block">Account Holder Name</label>
                                <input type="text" className="form-control fw-bolder  bg-white" defaultValue='5469859874548' maxLength={50}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-5 position-relative">
                                <label className="font-14 fw-bold mb-3 d-block">Bank Name</label>
                                <input type="text" className="form-control fw-bolder  bg-white" defaultValue='Abu Dhabi Commercial Bank ' maxLength={50}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <button
                                className='btn btn-primary fw-bold px-10 py-3 w-100'
                                data-bs-target='#create-contract-popup'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                            >
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        </>
    );
}
