import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
// import { PaymentTabs } from './components/PaymentTabs/PaymentTabs'
// import { All } from './components/PaymentTabs/All/All'
// import { Rent } from './components/PaymentTabs/Rent/Rent'
// import { SecurityDeposits } from './components/PaymentTabs/SecurityDeposits/SecurityDeposits'
// import { ServiceCharges } from './components/PaymentTabs/ServiceCharges/ServiceCharges'
// import { OthersList } from './components/PaymentTabs/OthersList/OthersList'
// import { PaymentDetails } from './components/PaymentDetails/PaymentDetails'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FinancePaymentListWrapper } from './FinancePaymentList/FinancePaymentListWrapper'
import { FinancePaymentViewWrapper } from './FinancePaymentView/FinancePaymentViewWrapper'


const PaymentListPage: FC = () => {

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/finance-payments/list'>
                            <FinancePaymentListWrapper />
                        </Route>
                        <Route path='/finance-payments/view'>
                            <FinancePaymentViewWrapper />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
        </>
    )
};

const FinancePaymentWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}> Payments </PageTitle>
            <PaymentListPage />
        </>
    )
};

export { FinancePaymentWrapper }
