import React, {FC} from 'react'
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../components/layout/core';
import { FinanceReceiptsListWrapper } from './FinanceReceiptList/FinanceReceiptListWrapper';
import { FinanceReceiptDetailScreen } from './FinanceReceiptsDetailsScreen/FinanceReceiptDetailScreen';

const FinanceServiceRequestReceiptsRoutes: FC = () => {

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/finance-services-receipt/list'>
                            <FinanceReceiptsListWrapper />
                        </Route>
                        <Route path='/finance-services-receipt/view'>
                            <FinanceReceiptDetailScreen />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
        </>
    );
}

const FinanceServiceRequestReceiptsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FinanceServiceRequestReceiptsRoutes />
        </>
    )
};

export {FinanceServiceRequestReceiptsWrapper}
