import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ApprovalsOfferTab: React.FC = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">                
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex py-2 overflow-x'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/offer-details' && 'active')}
                                                    to='/pm-approvals-offer/offer-details'>
                                                   {t("Offer Details")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/payment-plan-details' && 'active')}
                                                    to='/pm-approvals-offer/payment-plan-details'>
                                                    {t("Payment Plan Details")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/documents' && 'active')}
                                                    to='/pm-approvals-offer/documents'>
                                                    {t("Documents")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/approval-history' && 'active')}
                                                    to='/pm-approvals-offer/approval-history'>
                                                    {t("Approval History")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/address-book' && 'active')}
                                                    to='/pm-approvals-offer/address-book'>
                                                    {t("Address Book")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/addons' && 'active')}
                                                    to='/pm-approvals-offer/addons'>
                                                    {t("Addons")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/tenant-details' && 'active')}
                                                    to='/pm-approvals-offer/tenant-details'>
                                                    {t("Tenant Details")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-approvals-offer/notes' && 'active')}
                                                    to='/pm-approvals-offer/notes'>
                                                    {t("Notes")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {ApprovalsOfferTab}
