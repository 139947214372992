import React from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../constants/generics/customActios';

export function OfferAnnounce(props:any) {
    const proprtyDetails = props.proprtyDetails
    const { t } = useTranslation();

    return (
        <div className="">
            <div className="OfferAnnounce mb-5 p-5">
                <p className="font-22 text-black price-offer mb-0">{t("AED")} <span className="font-29 fw-bolder">{proprtyDetails?.price?currencyFormater(proprtyDetails?.price):'-'}</span> / {t("Year")}</p>
                {/* <div className="d-flex button-sections align-items-center">
                    <button data-bs-target="#make-on-offer-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit'
                            className="btn btn-outline-primary px-5 merchant-name me-4">
                        {t("Make an Offer")}
                    </button>
                    <button data-bs-target="#reschedule-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit'
                            className='btn btn-primary reschedule fw-bold px-4 py-3'>
                        {t("Schedule Visit")}
                    </button>
                </div> */}
            </div>
        </div>
    )
};