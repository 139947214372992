import React, {useState, useEffect, useRef} from 'react'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import {Link, useHistory, useLocation} from 'react-router-dom'
import NoImage from '../../../../../assets/images/home.png'
import {formatTimeMeridiem} from '../../../../../constants/generics/dateTimeFormater'
import {
  facilityIncidentReportStatusUpdate,
  facilityIncidentReslovedStatusUpdate,
  getIncidentList,
} from '../../../../../constants/axios/apis'
import {RequestFailedPopup} from '../../../../SuccessfulPopup/RequestFailedPopup'
import {CustomRequestSuccessfulPopup} from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'

import {useTranslation} from 'react-i18next'
import {ImageViewerWithPopup} from '../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup'
import Loader from '../../../../../components/Loader'
import { CustomLoader2, CustomLoaderCompact } from '../../../../../constants/generics/customActios'

export function IncidentView() {
  let params: any = {}
  params = useLocation()

  let incidentId = ''
  if (params?.state?.incidentData) {
    incidentId = params?.state?.incidentData?.id
  }

  const [incidentData, setIncidentData] = useState<any>({})
  // const {incidentData} = params.state

  const buttonEl: any = useRef(null)
  const history = useHistory()

  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading]=useState(true)
  const [submitError, setSubmitError] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    setLoading(true)
    setIncidentData({})
    getIncidentList(`?id=${incidentId}`).then((response: any) => {
      if (response?.data && Array.isArray(response?.data) && response?.data?.length > 0)
        setIncidentData(response?.data[0])
      setLoading(false)
    })
  }, [incidentId])

  const sendIncidentReport = () => {
    setTimeout(() => {
      setSubmitting(true)
      setSubmitError('')
      const formData = new FormData();
      formData.append("id", incidentData?.id);

      facilityIncidentReportStatusUpdate(formData)
        .then((response: any) => {
          setSubmitting(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
          } else if (response.errorCode === 0) {
            setSuccessMsg(response?.description)
            setIsSubmitted(true)
            setSubmitting(false)
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/incidents/open-requests')
            }, 4000)
          } else {
            setSubmitError(t('Some error occured. Please try again !'))
          }
        })
        .catch((e) => {
          setSubmitting(false)
          setSubmitError(t('Some error occured. Please try again !'))
        })
    }, 1000)
  }

  const sendIncidentStatus = () => {
    const newPayload = {
      status: 1,
      incident_id: incidentData?.id,
    }
    setTimeout(() => {
      setSubmitting(true)
      setSubmitError('')
      facilityIncidentReslovedStatusUpdate(newPayload)
        .then((response: any) => {
          setSubmitting(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
          } else if (response.errorCode === 0) {
            setSuccessMsg(response?.description)
            setIsSubmitted(true)
            setSubmitting(false)
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/incidents/resolved-requests')
            }, 4000)
          } else {
            setSubmitError(t('Some error occured. Please try again !'))
          }
        })
        .catch((e) => {
          setSubmitting(false)
          setSubmitError(t('Some error occured. Please try again !'))
        })
    }, 1000)
  }
  const incident_img = incidentData?.image?.image ? incidentData?.image?.image[0] : NoImage
  // const building_img = incidentData?.buildingIcon && incidentData?.buildingIcon.length ? incidentData?.buildingIcon : NoImage
  const building_img = incidentData?.communityLogo && incidentData?.communityLogo.length ? incidentData?.communityLogo : NoImage
  const {t} = useTranslation()
  return (
    <>
      <div className='com-card-section'>
        {
          loading?
          <CustomLoader2 /> :
        
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              {/* <Link to={'/incidents/open-requests'} className=''> */}
              <span className='c-pointer' onClick={() => { history.goBack() }} >
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                {/* </Link> */}
              </span>
              <span className='text-black'>{t('Incident Details')}</span>
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-6 py-6'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3 col-12'>
                    <div className='approval-move mb-5'>
                        <img src={building_img} className="img-fluid rounded-5" />
                    </div>
                    {/* <div className='visitor-avatar'>
                      <img
                        src={building_img}
                        className='img-fluid rounded'
                        alt='image'
                      />
                    </div> */}
                  </div>
                  <div className='col-md-8 col-lg-9 col-12'>
                    <div className='row'>
                      <div className='col-12 mb-5 text-end'>
                        <span
                          className={
                            incidentData?.status === 0
                              ? 'bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                              : incidentData?.status === 1
                              ? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                              : 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                          }
                        >
                          {incidentData?.status === 0
                            ? t('Open')
                            : incidentData?.status === 1
                            ? t('Resolved')
                            : incidentData?.status}
                        </span>
                      </div>

                      <div className='col-md-12 col-12'>
                        <span className='text-black fw-bolder font-15 d-block mb-4'>
                          {t('Area -')} {incidentData?.category ? incidentData?.category : '--'}
                        </span>
                      </div>

                      {/* <div className='col-md-12 col-12'>
                        <span className='text-black fw-bolder font-15 d-block mb-4'>
                          {t("Category -")}
                          {incidentData?.categoryParent ? incidentData?.categoryParent : '--'}
                        </span>
                      </div> */}
                      <div className='col-md-4 col-6 mb-5'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                          {t('Tickect No.')}
                        </h6>
                        <span className='text-black fw-bold font-15'>
                          {incidentData?.ticket_no ? incidentData?.ticket_no : '--'}
                        </span>
                      </div>
                      <div className='col-md-4 col-6'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                          {t('Building Name')}
                        </h6>
                        <span className='text-black fw-bold font-15'>
                          {incidentData?.buildingLocation ? incidentData?.buildingLocation : '--'}
                        </span>
                      </div>
                      <div className='col-md-4 col-6'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t('Level')} </h6>
                        <span className='text-black fw-bold font-15'>
                          {incidentData?.level ? incidentData?.level : '--'}
                        </span>
                      </div>
                      <div className='col-md-4 col-6'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                          {t('Action Taken')}
                        </h6>
                        <span className='text-black fw-bold font-15'>
                          {incidentData?.actionTaken ? incidentData?.actionTaken : '--'}
                        </span>
                      </div>
                      <div className='col-md-4 col-6'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t('Device')} </h6>
                        <span className='text-black fw-bold font-15'>
                          {incidentData?.device ? incidentData?.device : '--'}
                        </span>
                      </div>
                      <div className='col-md-4 col-6 mb-5'>
                        <div className=''>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t('Time')} </h6>
                          <span className='text-black fw-bold font-15'>
                            {formatTimeMeridiem(incidentData?.time ? incidentData?.time : '--')}
                          </span>
                        </div>
                      </div>
                      <div className='col-md-4 col-6 mb-5'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-14'>{t('Root Cause')}</h6>
                        <span className='text-black fw-bold font-15'>
                          {incidentData?.rootCause ? incidentData?.rootCause : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-lg-3">
                    <div className="mb-5 upload-input">
                        <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Attached Image")}</h6>
                        <ImageViewerWithPopup imageFile={incident_img} noImage={incident_img === NoImage} imgID='incident' />
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-9 col-12">
                    <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Description")} </h6>
                    <div className='data-contain h-auto 75 px-5 py-4 rounded-10 mb-2'>
                      <p className='font-14 fw-bold d-block mb-0'>
                        {incidentData?.comments ? incidentData?.comments : '--'}
                      </p>
                    </div>
                  </div>
                </div>

                {incidentData?.categoryParent === 'Fire Alarm' && incidentData?.status !== 0 && (
                  <div className='row'>
                    <div className='col-12'>
                      <div className='border-top pt-10 mt-10'></div>
                    </div>
                    <div className='col-md-12 col-12'>
                      <span className='text-black fw-bolder font-15 d-block mb-4'>
                        {t('Activity Details')}
                      </span>
                    </div>
                    <div className='col-md-4 col-6'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                        {t('Closed Reason')}
                      </h6>
                      <span className='text-black fw-bold font-15'>
                        {incidentData?.details?.reason ? incidentData?.details?.reason : '-'}
                      </span>
                    </div>
                    <div className='col-md-4 col-6'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'>{t('Comments')}</h6>
                      <span className='text-black fw-bold font-15'>
                        {incidentData?.details?.closed_description
                          ? incidentData?.details?.closed_description
                          : '-'}
                      </span>
                    </div>
                    <div className='col-md-4 col-6'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-14'>{t('Media')}</h6>
                      <ImageViewerWithPopup imageFile={incidentData?.details?.media} />
                    </div>
                  </div>
                )}

                {incidentData?.status !== 1 && (
                  <div className='col-12 text-end mt-5'>
                    <div className='col-12 text-end'>                      
                      {incidentData?.reportFlag === 1 ? 
                        <button
                          type='button'
                          className='btn btn-primary fw-bold px-10 py-3 me-5'
                          disabled={true}
                        >
                          <span className='indicator-label'>{t('Already Reported')}</span>
                        </button>
                        :
                        <>
                          { submitting ?
                            <div className="text-center">
                              <Loader />
                            </div>
                            :
                            <>
                              {incidentData?.categoryParent === 'Fire Alarm' && (
                                <button
                                  type='submit'
                                  onClick={() => sendIncidentReport()}
                                  className='btn btn-primary fw-bold px-10 py-3 me-5'
                                  disabled={isSubmitted}
                                >
                                  <span className='indicator-label'>{t('Report')}</span>
                                </button>
                              )}
                              <button
                                type='submit'
                                onClick={() => sendIncidentStatus()}
                                className='btn btn-primary fw-bold px-10 py-3'
                                disabled={isSubmitted}
                              >
                                <span className='indicator-label'>{t('Resolved')}</span>
                              </button>
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                )}
                {submitError && (
                  <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                    {submitError}
                  </div>
                )}
                <p
                  ref={buttonEl}
                  data-bs-target='#req-submitted-popup'
                  data-bs-toggle='modal'
                  data-bs-dismiss='modal'
                  className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                />
                <CustomRequestSuccessfulPopup
                  successMessage={successMsg}
                  close={false}
                  // href={'/incidents/open-requests'}
                />
              </div>
            </div>
          </div>
        </div>
}
      </div>
    </>
  )
}
