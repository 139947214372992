import React from 'react'
import ProfileImg from '../../../../assets/images/profile_pic.png';

export function PersonalInformation() {
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        Personal information
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card rounded-10">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="profile_wrapper">
                                    <div className="profile_div">
                                        <div className="profile_imp me-4">
                                            <img src={ProfileImg} className="img-fluid me-2" alt="profile img"/>
                                        </div>
                                    </div>
                                    <div className="profile_div profile_div_headdeing">
                                        <h2>Mohhamed</h2>
                                        <p>Not yet verified</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-7">
                <div className="col-12">
                    <div className="card rounded-10">
                        <div className="card-body px-8 py-6">
                            <div className="row personalinfor_form">
                                <div className="col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> First Name </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Last Name </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Email ID </label>
                                        <input type="email" className="form-control fw-bolder form-control-solid" />
                                        <button className="Notyetverified">Not yet verified</button>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Mobile Number </label>
                                        <input type="number" className="form-control fw-bolder form-control-solid" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="mb-0">
                                        <label className="form-label fw-bolder">Password </label>
                                        <input type="password" className="form-control fw-bolder form-control-solid" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}