import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PropertyRequirementPopup } from './PropertyRequirementPopup'
import { SelectListingPopup } from './SelectListingPopup'

export function EditContacts() {
    return (
        <>
        <div className="row align-items-center mb-5 community">
            <div className="col-md">
                <h5 className="theme-color font-22 d-inline-block float-left">
                    <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                        <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                    </Link>Edit Contact
                </h5>
            </div>
            <div className="col-auto">
                <span className="d-inline-block bg-warning2 rounded px-4 py-2 text-center font-12 text-white"><b>Property Score<br/>25</b></span>
            </div>
        </div>

        <div className="card mb-7">
            <div className="card-body">
                <h3 className="theme-color font-22 mb-4">Basic Details </h3>
                <div className="row">
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> First Name </label>
                            <input type="text" className="form-control form-height font-13" name="full_name" id="full_name" placeholder="Enter First Name" value="" required/>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Last Name </label>
                            <input type="text" className="form-control form-height font-13" name="last_name" placeholder="Enter Last Name" value=""/></div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Gender </label>
                            <select className="selectpicker form-control form-height w-100" title="Select" id="gender" name="gender">
                                
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Nationalities </label>
                            <select className="selectpicker form-control form-height w-100" title="Select" name="nationalities">
                                
                                </select>

                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Mobile </label>
                            <input type="number" className="form-control form-height font-13" name="phone" placeholder="Enter Phone Number" value=""/>
                            
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Alternate Mobile </label>
                            <input type="number" className="form-control form-height font-13" placeholder="Enter Phone Number"/>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Email </label>
                            <input type="email" className="form-control form-height font-13" name="email" placeholder="Enter Email ID" value="" required/>
                            
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Street Address </label>
                            <input type="text" className="form-control form-height font-13" name="address1" placeholder="Enter Street Address" value=""/>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> City </label>
                            <input type="text" className="form-control form-height font-13" name="city" placeholder="Enter City Name" value=""/>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> State </label>
                            <input type="text" className="form-control form-height font-13" name="state" placeholder="Enter State Name" value=""/>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Country </label>
                            <select className="form-control form-height w-100" title="Select" id="country_select" name="id_country">
                                <option value="">Please Select</option>
                                
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13"> Zip code </label>
                            <input type="text" className="form-control form-height font-13" name="zip" placeholder="Enter Zip Code" value=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="card mb-7">
            <div className="card-body">
                <h3 className="theme-color font-22 mb-4"> Requirement </h3>
                <div className="row">
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Customer Type </label>
                        <select className="form-control form-height w-100" title="Select" name="nationalities">
                            <option value="">Select</option>
                            <option value="">Looking to buy</option>
                            <option value="">Looking to Leas</option>
                            <option value="">Not Interested</option>
                        </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Unit Type </label>
                        <select className="form-control form-height w-100" title="Select" name="nationalities">
                            <option value="">Select</option>
                            <option value="">Villa</option>
                            <option value="">Apartment</option>
                            <option value="">Bungalow</option>
                        </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Preferable Size </label>
                        <select className="form-control form-height w-100" title="Select" name="nationalities">
                            <option value="">Select</option>
                            <option value="">Studio</option>
                            <option value="">1 BHK</option>
                            <option value="">2 BHK</option>
                            <option value="">3 BHK</option>
                        </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Budget </label>
                        <select className="form-control form-height w-100" title="Select" name="nationalities">
                            <option value="">Select</option>
                            <option value="">50K</option>
                            <option value="">50K</option>
                        </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13">  Payment Method </label>
                        <select className="form-control form-height w-100" title="Select" name="nationalities">
                            <option value="">Cash</option>
                            <option value="">Cheque</option>
                            <option value="">Credit/Debit</option>
                            <option value="">Bank Loan</option>
                        </select>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="theme-color font-22 mb-4">Lead Scoring Questionnaire
                                <a href="javascript:void(0);" className="text-decoration-none text-gray-800" data-toggle="tooltip" data-placement="top" title="Multiple property requirements can be added against a lead. For example, when a buyer has given two different locations (e.g. Springs or Ranches) they would buy in, both areas can be recorded as seperate requirements.">
                                    <i className="fas fa-question-circle font-10 text-grey-700"></i>
                                </a>
                            </h3>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 req-card-append lead-add-height h-auto">
                                    
                                    <div className="row no-gutters req-card rounded p-2 mb-1">
                                        <div className="col-sm">
                                            <button type="button" className="btn theme-color font-14 font-weight-semibold bg-transparent d-flex flex-wrap px-0 border-0 align-items-center">
                                                <div className="req-collapse-icon" data-toggle="collapse" data-target="#req-item<?= $i ?>">
                                                    <i className="fa fa-angle-right me-2"></i> Requirement 
                                                </div>
                                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-req-modal" className="text-decoration-none text-gray-800 font-12 rounded-circle ms-2">
                                                    <i  className="fas fa-plus-square"></i>
                                                </a>
                                            </button>
                                        </div>
                                        <div className="col-sm-auto">
                                            <button type="button" className="btn btn-primary"><span className="fas fa-plus"></span></button>
                                        </div>
                                        <div className="col-md-12">
                                            <div id="req-item1" className="collapse show bg-white p-3 mt-2 property1-text">
                                                 <div className="border p-2 selectBorder">
                                                    <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-enq-modal" className="text-decoration-none font-weight-semibold text-warning selectPropEnquiry">
                                                        Select the Property Enquired For
                                                    </a>
                                                </div>
                                                <div className="row prop-single-record">
                                                    <div className="col-md-12 my-2 text-end remove-button">
                                                        <Link to="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-enq-modal" className="text-decoration-none d-inline-flex align-items-center bg-primary text-white py-1 px-3 rounded check-property-select"> Property </Link>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="card radius-15 bg-light mb-1">
                                                            <div className="card-body p-3">
                                                                <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 pb-2">test</h6>
                                                                        </div>
                                                                        <div className="col-auto"><a href="#" className="text-decoration-none font-12 text-danger d-flex justify-content-end remove-prop-enq"><i className="fas fa-times"></i> </a></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                                                            <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Property Ref No</span></h6>
                                                                            <p className="font-12">REF-UN-1689251187</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                                                            <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Community</span></h6>
                                                                            <p className="font-12 ellipsis">null</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                                                            <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Building</span></h6>
                                                                            <p className="font-12 ellipsis">null</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                                                            <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Price</span></h6>
                                                                            <p className="font-12">AED 22.00</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                                                            <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Created at</span></h6>
                                                                            <p className="font-12">13/07/2023</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-12">
                                                                            <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Recent Action</span></h6>
                                                                            <p className="font-12">13/07/2023</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div className="card mb-7">
            <div className="card-body">
                <h3 className="theme-color font-22 mb-4">Additional Information </h3>
                <div className="row">
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Source </label>
                        <select className="selectpicker form-control form-height w-100" data-live-search="true" title="Select" name="source">
                            <option value="">Select</option>
                        </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13">Date of Enquiry</label>
                        <input type="date" id="enquiry-date" className="form-control form-height font-13" name="enquiry_date" placeholder="" value=""/>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Priority </label>
                        <select className="form-control form-height w-100" title="Select" name="priority">
                        
                        </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Lead Type </label>
                        <select className="form-control form-height w-100" title="Select" name="nationalities">
                            <option value="">Buyer</option>
                            <option value="">Lease</option>
                        </select>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Notes </label>
                        <textarea className="form-control font-13" rows={5} name="notes" placeholder="Notes"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PropertyRequirementPopup/>     
        <SelectListingPopup/>    
        </>
        )
}