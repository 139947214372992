import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  createTerminationCharges,
  getServiceRequestsCategories,
} from '../../../../constants/axios/apis'
import {LoadingSubmitAlert} from '../../../../constants/generics/customAlerts'
import {CustomRequestSuccessfulPopup} from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'

export function AddTerminationCharges({refundDetail, chargeTypes, vat, setReload}: any) {
  const {t} = useTranslation()
  const [totalAmount, setTotalAmount] = useState<any>('')
  const [vatAmount, setVatAmount] = useState<any>('')
  const [serviceCategoryID, setServiceCategoryID] = useState<any>()
  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    isSubmitted: false,
    message: '',
  })
  const buttonEl: any = useRef(null)

  useEffect(() => {
    getServiceRequestsCategories().then(({data: categoriesData}) => {
      categoriesData = categoriesData?.find((category: any) => category?.categoryCode === 'OC')
      const serviceCategoryData = categoriesData?.service_category_types
      setServiceCategoryID(categoriesData.id)
    })
  }, [])

  const initialValues = {
    category_id: '',
    amount: '',
    comment: '',
  }
  const validationSchema = Yup.object().shape({
    category_id: Yup.string().required(t('Category is required!')),
    amount: Yup.string().required(t('Amount is required!'))
      .matches(/^[^\s].*/, 'Amount cannot start with a blankspace!')
      .matches(/^\d*[\.{1}\d*]\d*$/, 'Wrong amount format!')
      .test('positive', t('Please provide a valid Amount!'), (value) => {
        return (value && Number(value) > 0) ? true : false
      }),
      // .matches(/^[1-9][0-9]*$/, 'Amount cannot start with 0!'),
      // .matches(/^\d*\.{1}\d*$/, 'Wrong amount format!'),
      // .matches(/^\d+(?:\.\d{0,2})$/, 'Wrong amount format!'),
    comment: Yup.string()
      .required(t('Comment is required!'))
      .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!'))
      .max(250, t('Max length is 250!'))
      .test('len', t('Comment is required!'), (value) => {
        return value ? (value?.trim()).length > 0 : false
      }),
  })

  

  const onSubmit = (values: any) => {
    // console.log(values, 'values');

    // setLoading(true)
    // setSubmitError('')
    const formData = new FormData()
    formData.append('work_order_id', refundDetail?.work_order_id)
    formData.append('ocharge[0][category_type_id]', values.category_id)
    formData.append('ocharge[0][amount]', values.amount)
    formData.append('ocharge[0][desc]', values.comment)
    formData.append('ocharge[0][category]', serviceCategoryID)
    setSubmitStatus((oStatus: any) => ({...oStatus, loading: true, error: false}))

    setTimeout(() => {
      createTerminationCharges(formData)
        .then((response: any) => {
          if (response.errorCode === 1)
            setSubmitStatus((oStatus: any) => ({
              ...oStatus,
              loading: false,
              error: true,
              message: response.errorDescription,
            }))
          else if (response.errorCode === 0) {
            setSubmitStatus((oStatus: any) => ({
              ...oStatus,
              loading: false,
              message: response.description,
              isSubmitted: true,
            }))
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
            formik.resetForm()
            setReload(true)
          } else
            setSubmitStatus((oStatus: any) => ({
              ...oStatus,
              loading: false,
              message: t('Some error occured!'),
              error: true,
            }))
        })
        .catch((e) => {
          setSubmitStatus((oStatus: any) => ({
            ...oStatus,
            loading: false,
            message: t('Some error occured!'),
            error: true,
          }))
        })
    }, 1000)
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  useEffect(() => {
    if (formik.values.amount !== '') {
      const vatVal = parseFloat(formik.values.amount) * 0.05
      setVatAmount(vatVal)
      setTotalAmount(parseFloat(formik.values.amount) + vatVal)
    } else {
      setVatAmount('')
      setTotalAmount('')
    }
  }, [formik.values.amount])

  return (
    <>
      <div className='modal fade add-broker-modal' id='add-charges'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header px-8 py-8'>
              <h5 className='modal-title text-black font-18 fw-bolder'>{t('Add Charges')}</h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body px-8'>
              <form onSubmit={formik.handleSubmit}>
                <div className='row form-add-design'>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label htmlFor='f-name' className='form-label fw-bolder mb-3'>
                        {t('Charge Type')}
                        <span className='text-danger'> * </span>
                      </label>
                      <select
                        {...formik.getFieldProps('category_id')}
                        className='form-control form-select fw-bolder bg-white form-control-solid'
                        data-control='select2'
                        data-allow-clear='true'
                      >
                        <option value=''>{t('Please Select')}</option>
                        {chargeTypes?.map((item: any, index: number) => (
                          <option key={index} value={item.id}>
                            {' '}
                            {item.name}{' '}
                          </option>
                        ))}

                        {/* <option> {t('Cleaning')} </option>
                      <option> {t('Common Areas')} </option>
                      <option> {t('Elevators')} </option> */}
                      </select>
                      {formik.touched.category_id && formik.errors.category_id ? (
                        <small className='text-danger'>{formik.errors.category_id}</small>
                      ) : null}
                    </div>
                    <div className='mb-5'>
                      <label htmlFor='f-name' className='form-label fw-bolder mb-3'>
                        {t('Amount')}
                        <span className='text-danger'> * </span>
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('amount')}
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Amount')}
                      />
                      {formik.touched.amount && formik.errors.amount ? (
                        <small className='text-danger'>{formik.errors.amount}</small>
                      ) : null}
                    </div>
                    <div className='mb-5'>
                      <label htmlFor='f-name' className='form-label fw-bolder mb-3'>
                        {t('VAT')}
                        <span className='text-danger'> * </span>
                      </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid bg-light'
                        placeholder={t('VAT (5%)')}
                        value={vatAmount}
                        readOnly
                      />
                    </div>
                    <div className='mb-5'>
                      <label htmlFor='f-name' className='form-label fw-bolder mb-3'>
                        {t('Total')}
                        <span className='text-danger'> * </span>
                      </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid bg-light'
                        placeholder={t('Total')}
                        value={totalAmount}
                        readOnly
                      />
                    </div>
                    <div className='mb-5'>
                      <label htmlFor='f-name' className='form-label fw-bolder mb-3'>
                        {t('Comments')}
                        <span className='text-danger'> * </span>
                      </label>
                      <textarea
                        {...formik.getFieldProps('comment')}
                        className='form-control'
                        data-kt-autosize='true'
                        rows={3}
                        placeholder={t('Type here...')}
                        //   {...formik.getFieldProps('comment')}
                      />
                      {formik.touched.comment && formik.errors.comment ? (
                        <small className='text-danger'>{formik.errors.comment}</small>
                      ) : null}
                      {/* {formik.touched.comment && formik.errors.comment ? (
                            <small className='text-danger'>{formik.errors.comment}</small>
                          ) : null} */}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 pt-1 pb-5 text-end'>
                    <button
                      type='submit'
                      className='btn btn-primary fw-bold px-10 py-3'
                      disabled={submitStatus.loading}
                    >
                      {submitStatus.loading ? (
                        <span className='indicator-progress d-block'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                          {t('Submit')}
                        </span>
                      ) : (
                        <span className='indicator-label'>{t('Submit')}</span>
                      )}
                    </button>
                  </div>
                  <div className='col-12'>
                    <LoadingSubmitAlert submitStatus={submitStatus} />
                  </div>
                  <p
                    ref={buttonEl}
                    data-bs-target='#req-submitted-popup-charges'
                    data-bs-toggle='modal'
                    data-bs-dismiss='modal'
                    className='invisible d-none'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomRequestSuccessfulPopup
        successMessage={submitStatus.message}
        close={true}
        customID={'-charges'}
      />
    </>
  )
}
