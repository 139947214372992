import {useIntl} from 'react-intl'
import React, { useState, useEffect, useRef } from 'react';
import Filter from "../../../../assets/images/filter_active.png"
import { useTranslation } from 'react-i18next'

export function PMFilter() {
    const { t } = useTranslation();

    return (
        <>
            <div className='row'>
                <div className='col-lg-12 my-auto'>
                    <button className='btn btn-primary btn-sm text-start pe-2 font-16'
                            data-bs-toggle="modal" data-bs-target="#add-filter-details"
                    >
                        {t("Filter")} <img src={Filter} className="img-fluid shadow ms-5 filter_tenant" />
                    </button>
                </div>
            </div>
        </>
    );
}
