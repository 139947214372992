import React from 'react'
import {Link} from 'react-router-dom'
import Propertie from '../../../../../assets/images/latest-one.png'
import Place from '../../../../../assets/images/place.svg'
import pdficon from '../../../../../assets/images/pdf-file.png'
import docicon from '../../../../../assets/images/doc-ioc.png'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../../../../constants/generics/userCheck'
import { useTranslation } from 'react-i18next';

export function Documents({documents, building_id, property_id}: any) {
  const { t } = useTranslation();
  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  return (
    <div className=''>
      <div className='col-12'>
        <div className='row align-items-center'>
          <div className='col-md-9 text-start'>
            <h4 className='card-title font-18 text-black mb-0'>{t("Documents")} ({documents.length})</h4>
          </div>
          {user_role === 'FMSM' ? (
            <div className='col-md-3  text-end'>
              <Link
                to={{'pathname':'/fc-ss-documents/add', state: {'building': {building_id: building_id, property_id: property_id } }}} >
                <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                  <span className='indicator-label'>{t("Add Document")}</span>
                </button>
              </Link>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='row mt-7'>
        {documents?.map((document: any, index: number) => {
          if (document.attachments[0]) {
            return (
              <div key={index} className='col-md-6 col-12 mb-7'>
                <div className='border rounded p-4'>
                  <div className='row align-items-center'>
                    <div className='col-sm-9 col-12'>
                      {/* TODO: icon */}
                      <span>
                        <img src={docicon} alt='docx-icon' className='docx-icon me-3' />
                      </span>
                      {/* <span className="docx-title">{document.requestType ? document.requestType : '-'}</span> */}
                      <span className='text-black fw-bold font-14 text-short mb-0'>
                        {document.requestType ? document.requestType : document.comments}
                      </span>
                      {/* <span className="docx-title">{document.comments}</span> */}
                    </div>
                    <div className='col-sm-3 col-12'>
                      <a
                        className='btn btn-primary w-100 font-12 px-3 py-3'
                        href={document.attachments[0]}
                        target='_blank'
                      >
                        {t("Download")}
                      </a>
                    </div>
                  </div>
                  {/* <div className='row'>
                                        <div className="col-12">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-14">Comment</h6>
                                            <div className="data-contain h-auto 75 px-5 py-4 rounded-10 mb-2">
                                                <p className="font-14 fw-bold d-block mb-0">{document.comments ? document.comments : '-'}</p>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            )
          } else return <></>
        })}
      </div>
    </div>
  )
}
