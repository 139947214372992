import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export function PreventiveTabs() {

    const location = useLocation();
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className='d-flex subtabs'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link position-relative text-active-primary ` +
                                (location.pathname === '/facility-workorder/list/preventive/open' && 'actives')}
                                to='/facility-workorder/list/preventive/open'>
                                {t("Open")}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link position-relative text-active-primary ` +
                                (location.pathname === '/facility-workorder/list/preventive/onhold' && 'actives')}
                                to='/facility-workorder/list/preventive/onhold'>
                                {t("On-Hold")}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link position-relative text-active-primary ` +
                                (location.pathname === '/facility-workorder/list/preventive/completed' && 'actives')}
                                to='/facility-workorder/list/preventive/completed'>
                                {t("Completed")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}