import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Propertie from '../../../../assets/images/latest-one.png';

export function Cash() {
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12 col-lg-6">
                                    <Link to={'/my-unit/payments'}>
                                        <div className="properties_list_view mb-5">
                                            <div className="card px-3 py-3">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-3">
                                                        <div className="offer_main_img">
                                                            <img src={Propertie} alt="Work Orders" className="img-fluid" />
                                                            <div className="property_card_headding py-3 px-3 text-center">
                                                                <p className="fw-bolder mb-0 text-white">Merchant Name</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9">
                                                        <div className="offer_main_details">
                                                            <div className="col-12 mb-5">
                                                                <h4 className="text-black fw-bolder font-16">Lease Number : 564574345</h4>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="pm-tenant d-flex">
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Tenant Name</p>
                                                                        <p className="mb-0 text-black font-14">Muhammad</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Unit</p>
                                                                        <p className="mb-0 text-black font-14">A052</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Annual Rent</p>
                                                                        <p className="mb-0 text-black font-14">AED 4000</p>
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-muted mb-2">Payment Type</p>
                                                                        <p className="mb-0 text-black font-14">Cash</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-xs-12 col-lg-6">
                                    <Link to={'/my-unit/payments'}>
                                        <div className="properties_list_view mb-5">
                                            <div className="card px-3 py-3">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-3">
                                                        <div className="offer_main_img">
                                                            <img src={Propertie} alt="Work Orders" className="img-fluid" />
                                                            <div className="property_card_headding py-3 px-3 text-center">
                                                                <p className="fw-bolder mb-0 text-white">Merchant Name</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9">
                                                        <div className="offer_main_details">
                                                            <div className="col-12 mb-5">
                                                                <h4 className="text-black fw-bolder font-16">Lease Number : 564574345</h4>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="pm-tenant d-flex">
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Tenant Name</p>
                                                                        <p className="mb-0 text-black font-14">Muhammad</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Unit</p>
                                                                        <p className="mb-0 text-black font-14">A052</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Annual Rent</p>
                                                                        <p className="mb-0 text-black font-14">AED 4000</p>
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-muted mb-2">Payment Type</p>
                                                                        <p className="mb-0 text-black font-14">Cash</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-xs-12 col-lg-6">
                                    <Link to={'/my-unit/payments'}>
                                        <div className="properties_list_view mb-5">
                                            <div className="card px-3 py-3">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-3">
                                                        <div className="offer_main_img">
                                                            <img src={Propertie} alt="Work Orders" className="img-fluid" />
                                                            <div className="property_card_headding py-3 px-3 text-center">
                                                                <p className="fw-bolder mb-0 text-white">Merchant Name</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9">
                                                        <div className="offer_main_details">
                                                            <div className="col-12 mb-5">
                                                                <h4 className="text-black fw-bolder font-16">Lease Number : 564574345</h4>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="pm-tenant d-flex">
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Tenant Name</p>
                                                                        <p className="mb-0 text-black font-14">Muhammad</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Unit</p>
                                                                        <p className="mb-0 text-black font-14">A052</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Annual Rent</p>
                                                                        <p className="mb-0 text-black font-14">AED 4000</p>
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-muted mb-2">Payment Type</p>
                                                                        <p className="mb-0 text-black font-14">Cash</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-xs-12 col-lg-6">
                                    <Link to={'/my-unit/payments'}>
                                        <div className="properties_list_view mb-5">
                                            <div className="card px-3 py-3">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-3">
                                                        <div className="offer_main_img">
                                                            <img src={Propertie} alt="Work Orders" className="img-fluid" />
                                                            <div className="property_card_headding py-3 px-3 text-center">
                                                                <p className="fw-bolder mb-0 text-white">Merchant Name</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9">
                                                        <div className="offer_main_details">
                                                            <div className="col-12 mb-5">
                                                                <h4 className="text-black fw-bolder font-16">Lease Number : 564574345</h4>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="pm-tenant d-flex">
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Tenant Name</p>
                                                                        <p className="mb-0 text-black font-14">Muhammad</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Unit</p>
                                                                        <p className="mb-0 text-black font-14">A052</p>
                                                                    </div>
                                                                    <div className="me-6">
                                                                        <p className="text-muted mb-2">Annual Rent</p>
                                                                        <p className="mb-0 text-black font-14">AED 4000</p>
                                                                    </div>
                                                                    <div className="">
                                                                        <p className="text-muted mb-2">Payment Type</p>
                                                                        <p className="mb-0 text-black font-14">Cash</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}