import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Home from '../../../../../assets/images/nation-towers-new.png'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import { TerminationCharges } from "../TerminationCharges/TerminationCharges";
import { BankDetails } from "../BankDetails/BankDetails";
import { DateDetails } from "../DateDetails/DateDetails";
import { getRefundsDetails } from '../../../../../constants/axios/apis';
import { useDispatch, useSelector } from 'react-redux';
import * as PM from '../../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { RootState } from '../../../../../constants';
import { currencyFormater, CustomLoader1 } from '../../../../../constants/generics/customActios';
import moment from 'moment';
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';


export function ChequeDelayRequestDetails() {

  const location = useLocation<any>()
  const dispatch = useDispatch()
  const history = useHistory()
  const id = location?.state?.id
  let refundDetails: any
  refundDetails = useSelector<RootState>(({ PM }) => PM.refundData)
  // console.log("🚀 ~ file: ChequeDelayRequestDetails.tsx:22 ~ ChequeDelayRequestDetails ~ refundDetails", refundDetails)
  useEffect(() => {
    dispatch(PM.actions.refundData(''))
    getRefundsDetails(id)
      .then((response: any) => {

        dispatch(PM.actions.refundData({
          fullData: response,
          requiredData: response?.data?.data,
          httpStatus: response?.status,
          apiStaus: response?.data?.code,
          apiErrorCode: response?.data?.errorCode,
          apiDesc: response?.data?.description
        }))
        if (response?.errorCode === 0) {

        }
      })
      .catch((e) => { })
  }, [])
  if (refundDetails === undefined || refundDetails === '') { return <CustomLoader1 height={100} /> }
  return (
    <div className='com-card-section myunit-page'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <span
              className='c-pointer'
              onClick={() => { history.goBack() }}
            >
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </span>
            <span className='text-black'>Refund Request Details</span>
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row align-items-center'>
                <div className='col-md-4 col-lg-3 text-xxl-center'>
                  <img src={refundDetails?.requiredData?.buildingIcon ? refundDetails?.requiredData?.buildingIcon : Home} alt='property-image' className='img-fluid' />
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white shadow-none rounded-0'>
                    <div className='card-body p-0'>
                      <div className='row'>
                        <div className='col-md-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Building Name{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> {refundDetails?.requiredData?.building ? refundDetails?.requiredData?.building : '-'} </span>
                        </div>
                        <div className='col-md-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Service Category{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> {refundDetails?.requiredData?.serviceCategory ? refundDetails?.requiredData?.serviceCategory : '-'} </span>
                        </div>
                        <div className='col-md-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Service Type{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> {refundDetails?.requiredData?.serviceType ? refundDetails?.requiredData?.serviceType : '-'} </span>
                        </div>
                        <div className='col-md-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Tenant Name{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> {refundDetails?.requiredData?.tenantName ? refundDetails?.requiredData?.tenantName : '-'} </span>
                        </div>
                        <div className='col-md-4 col-12 mb-7'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              Lease Number{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'> {refundDetails?.requiredData?.leaseNumber ? refundDetails?.requiredData?.leaseNumber : '-'} </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Lease Start Date{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> {refundDetails?.requiredData?.leaseStartDate ? formatDate(refundDetails.requiredData.leaseStartDate) : '-'} </span>
                        </div>
                        <div className='col-md-4 col-12'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Lease End Date{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> {refundDetails?.requiredData?.leaseEndDate ? formatDate(refundDetails.requiredData.leaseEndDate) : '-'} </span>
                        </div>
                        <div className='col-md-4 col-12'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Annual Rent{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> AED {refundDetails?.requiredData?.rent ? currencyFormater(refundDetails?.requiredData?.rent) : '-'} </span>
                        </div>
                        <div className='col-md-4 col-12'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            Security Deposit{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> AED {refundDetails?.requiredData?.securityDepost ? currencyFormater(refundDetails?.requiredData?.securityDepost) : '-'} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-7 col-lg-7 mb-7'>
          <div className='card h-100'>
            <div className='card-body px-10 py-8'>
              <TerminationCharges />
            </div>
          </div>
        </div>
        <div className='col-12 col-md-5 col-lg-5 mb-7'>
          <div className='card h-100'>
            <div className='card-body px-8 py-8'>
              <BankDetails />
            </div>
          </div>
        </div>
        <div className='col-12 mb-7'>
          <DateDetails />
        </div>
      </div>
    </div>
  )
}
