import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMyTeamChartData } from '../../../../constants/axios/apis';
import { TeamChartWrapper } from '../TeamChart/TeamChartWrapper';
import { TeamMember } from '../TeamMember/TeamMember';

export function TeamDashboard() {
    const { t } = useTranslation()

    const [chartData, setChartData] = useState<any>({ active: 0, inactive: 0, total: 0 })
    const [teamRow, setTeamRow] = useState<any>([]);
    const [totalTeamList, setTotalTeamList] = useState<any>(0);
    const [loading, setLoading] = useState<any>(true)
    const [error, setError] = useState<any>('')

    useEffect(() => {
        // getPMmyTeam()
        getMyTeamChartData('?user_role=MT&id_sort=1&currentPage=1&pageSize=3')
        // getMyTeamChartData('')
            .then((response: any) => {
                if (response?.errorCode === 0) {
                    setChartData({
                        active: response?.data?.activeMembers,
                        inactive: response?.data?.inactiveMembers,
                        //   leave: response?.data?.leaves,
                        total: response?.data?.totalMembers,
                    });
                    if (response?.data?.teamList?.length) {
                        setTotalTeamList(response?.dataCount)
                        setTeamRow(response?.data?.teamList.slice(0, 3))
                    }
                } else {
                    setError(t('Some error occured!'))
                }
                setLoading(false)
            })
            .catch((e) => {
                setError(t('Some error occured!'))
                setLoading(false)
            })
    }, [])
    
    return (
        <div className="com-card-section">
            <div className="row">
                {/* <div className="col-md-12 col-12">
                    <div className="row mb-6">
                        <div className="col-12">
                            <h4 className="card-title font-18 text-black mb-0"> {t("My Team")}</h4>
                        </div>
                    </div>
                </div> */}
                <TeamChartWrapper loading={loading} error={error} chartData={chartData}/>
                <TeamMember teamRow={teamRow} totalTeamList={totalTeamList} loading={loading} error={error}/>
            </div>
        </div>
    );
}