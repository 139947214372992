import React, { useEffect, useState } from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import { getFacilityBuildingList } from '../../../../constants/axios/apis'
import { Select } from 'antd'
import 'antd/dist/antd.css';
const { Option } = Select;

export function PMDropDown() {
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userRole = JSON.parse(userDetail).id_role
    }

    const intl = useIntl()
    const dispatch = useDispatch()

    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const [buildingUnitList, setBuildingUnitList] = useState<any>([])
    const [isLoading, setIsLoading] = useState<any>({
        building: true,
    })
    // currentBuilding === undefined ? '1' : currentBuilding
    const [selectedBuilding, setSelectedBuilding] = useState<any>(currentBuilding)

    const onChange = (value: string) => {
        setSelectedBuilding(value)
        // const openWorkOrderVal =  buildingUnitList.filter((buildingId: any, index: number)=>{
        //     return buildingId.id === selectedBuilding;
        // });
    };

    const onSearch = (value: string) => {
    };

    useEffect(() => {
        getFacilityBuildingList().then(({ data }) => {
            setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({ buildingId: item.id, buildingName: item.building })) : [])
            dispatch(General.actions.building(selectedBuilding)) // Recent change todo to check
            setIsLoading((oState: any) => ({ ...oState, building: false }))
        }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, building: false })) })
    }, [])


    useEffect(() => {
        if (currentBuilding === undefined && buildingUnitList && buildingUnitList[0]) {
            // const defaultValue = buildingUnitList?.map((building: any, index: any) => {
            //     if (index === 0) {
            //         setSelectedBuilding(userRole === 11 ? '' : building.buildingId)
            //     }
            // })
            setSelectedBuilding('')
        }
    }, [currentBuilding, buildingUnitList])

    useEffect(() => {
        dispatch(General.actions.building(selectedBuilding))
        dispatch(General.actions.buildingName((
            buildingUnitList.filter((buildingId: any, index: number) => {
                return buildingId.buildingId === selectedBuilding
            })
        )))
    }, [selectedBuilding, currentBuilding, buildingUnitList])

    const contracts = buildingUnitList?.map((building: any) => (
        <Option key={building.buildingId} value={building.buildingId}>
            {building.buildingName}
        </Option>
    ))

    const renderSelect = () => {
        if (!isLoading?.building) {
            return (
                <>
                    <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select'
                        showSearch
                        placeholder="Select Building"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                        // defaultValue={currentBuilding}
                        defaultValue={currentBuilding ? currentBuilding : selectedBuilding} // Recent changes todo check
                    >
                        <Option value="">All Buildings</Option>
                        {!isLoading?.building ? contracts : ''}
                    </Select></>

            )
        } else { }
    }
    return (
        <>
            <div className='row'>
                <div className='col-lg-12 my-auto'>
                    {renderSelect()}
                </div>
            </div>
        </>
    )
}
