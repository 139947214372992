import clsx from 'clsx'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials/layout/header-menus/HeaderUserMenu'
import { useLayout } from '../../core'
import { Link } from 'react-router-dom'
import avatarImg from '../../../../assets/images/avatar.jpg'
import { RootState } from '../../../../constants'
import * as profile from '../../../../constants/redux/Sagas/ProfileSaga'
import { MenuComponent } from '../../../../setup/ts/components'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { config } = useLayout()
	const { t } = useTranslation();

  let userName = 'Mohammad'
  let userImage = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    const lastName = JSON.parse(userDetail).last_name != null ? JSON.parse(userDetail).last_name : ''
    userName = JSON.parse(userDetail).first_name + ' ' + lastName
    userImage = JSON.parse(userDetail).profile_img
    userRole = JSON.parse(userDetail).id_role
  }

  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(profile.actions.profile())
  // }, [])
  let profileData: any = []
  profileData = useSelector<RootState>(({ Profile }) => Profile.profile)

  let notifCount: any
    notifCount = useSelector<RootState>(({ General }) => General.notif)
    notifCount = Number(notifCount)


  const languages = [
		{
			code: 'en',
			name: 'English',
			country_code: 'gb',
		},
		{
			code: 'ar',
			name: 'العربية',
			dir: 'rtl',	
			country_code: 'sa',
		},
	];

  useEffect(() => {
    MenuComponent.reinitialization() // import from _metronic/assets/ts/components
  }, [])  

	const currentLanguageCode: any = profileData?.data?.preferred_language === 1 ? 'en' : 'ar';
	const currentLanguage: any = languages.find((l) => l.code === currentLanguageCode);

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* CHAT */}
      {/* {userRole != 4 && userRole != 5 ? (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <Link
            to={'/chat/all'}
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
          >
            <KTSVG path='' className='email-ioc' />
            <span className='bullet bullet-dot bg-warning h-6px w-6px position-absolute translate-middle top-5 start-70 animation-blink'></span>
          </Link>
        </div>
      ) : null} */}
      {/* Activities */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Drawer toggle */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-bs-toggle='modal'
          data-bs-target='#exampleModal'
        >
          <KTSVG path='' className='notif-ioc' />
          {notifCount !== undefined && notifCount !==''  && notifCount > 0 ?
            <span className='notify-icon'>
            <span className={'badge badge-light p-1'}>{notifCount > 999 ? '999+' : notifCount}</span>
          </span> : <span className='notify-icon'>
            <span className={'badge badge-light p-1'}>0</span>
          </span>
          }
          {/* <span className='bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-5 start-70 animation-blink'></span> */}
        </div>
        {/* end::Drawer toggle */}
      </div>
      {/* Quick links */}
      {/*  <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
      </div>*/}

      {/* NOTIFICATIONS */}
      {/*   <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <HeaderNotificationsMenu />
      </div>*/}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center ms-lg-7', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol position-relative d-contents', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className='font-14 name'>  Welcome, </span>
          <span className='font-14 fw-bold user-name'> {userName}</span>
          <div className='topbar-polygon'>
            <img src={profileData?.data?.profile_img ? profileData?.data?.profile_img : avatarImg} alt={profileData?.data?.first_name} className='rounded-circle' />
          </div>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
