

export function CreateAudienceModal() {

    return (
        <div className="modal fade" aria-hidden='true' id="import-popup">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Create Audience</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="row py-4">
                            <div className="col-md-12">
                                <div className="form-group mb-10 row">
                                    <label className="control-label theme-color font-14 fw-bold col-md-8"> Create website traffic audience </label>
                                    <div className="col-md-4 text-end">
                                        <a href="#" className="text-decoration-none">
                                        <img src="https://pm-crm.realcube.estate/img/fb-market-ico.png" className="img-fluid me-3"/>
                                        </a>
                                        <a href="#" className="text-decoration-none">
                                        <img src="https://pm-crm.realcube.estate/img/linkedin-ico.png" className="img-fluid me-3"/>
                                        </a>
                                        <a href="#" className="text-decoration-none">
                                        <img src="https://pm-crm.realcube.estate/img/google-ppc-ico.png" className="img-fluid"/>
                                        </a>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="border p-4 rounded">
                                        <p className="mb-0 font-12 font-weight-semibold">
                                            Nurture the people who've been to your site. Create an audience from your visitors and re-engage with them wherever they are online.
                                        </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-10 row">
                                    <label className="control-label theme-color font-14 fw-bold col-md-12"> Contact List <img src="https://pm-crm.realcube.estate/img/lock-ico.svg" className="img-fluid ms-3"/> </label>
                                    <div className="col-md-12 mt-3">
                                        <div className="border p-4 rounded">
                                            <p className="mb-0 font-12 font-weight-semibold">
                                                Make the most of your contacts. Create an audience from your contact list in Realcube CRM to re-engage with them
                                                wherever they are online.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-10 row">
                                    <label className="control-label theme-color font-14 fw-bold col-md-8"> Lookalike </label>
                                    <div className="col-md-4 text-end">
                                        <a href="#" className="text-decoration-none">
                                        <img src="https://pm-crm.realcube.estate/img/fb-market-ico.png" className="img-fluid me-3"/>
                                        </a>
                                        <a href="#" className="text-decoration-none">
                                        <img src="https://pm-crm.realcube.estate/img/linkedin-ico.png" className="img-fluid"/>
                                        </a>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="border p-4 rounded">
                                            <p className="mb-0 font-12 font-weight-semibold">
                                                Make the most of your contacts. Create an audience from your contact list in Realcube CRM to re-engage with them
                                                wherever they are online.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}