import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {PMFilter} from '../PMFilter/PMFilter';
import Excel from "../../../../assets/images/xls.svg";
import PDF from "../../../../assets/images/pdf.svg";
import Links from "../../../../assets/images/External_Link.svg";
import {PropertyType} from '../../../mta-properties/components/PropertyType/PropertyType';
import { useTranslation } from 'react-i18next'

export function PMAvailabilityHeader(props: any) {
    const intl = useIntl()
    const location = useLocation();
    const[menu, setMenu] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (window.location.pathname != menu){
            setMenu(window.location.pathname)
        }
    });
    return (
        <div className="">
            {menu == "/pm-property-data/available" || menu == "/pm-property-data/upcoming" ?
                <div className="col-12 mb-5">
                    <div className="row align-items-center">
                        <div className="col-xl-2 col-lg-12 col-6">
                            <h4 className="card-title font-18 text-black mb-3">{t("Property Data")}</h4>
                        </div>
                        <div className="col-xl-10 col-lg-12 col-6">
                            <div className="properties_buttons float-end">
                                {/* <div className="d-flex pm-topbar-buttons mb-4 mb-lg-0 flex-wrap">
                                    <div className="me-3 mb-3">
                                        <button className='btn btn-primary fw-bold px-4 py-3'>
                                            <img src={Excel} className="img-fluid"/> Share as Excel
                                        </button>
                                    </div>
                                    <div className="me-3 mb-3">
                                        <button className='btn btn-primary fw-bold px-4 py-3'>
                                            <img src={PDF} className="img-fluid"/> Share as PDF
                                        </button>
                                    </div>
                                    <div className="me-3">
                                        <button className='btn btn-primary fw-bold px-4 py-3'>
                                            <img src={Links} className="img-fluid"/> Share as Link
                                        </button>
                                    </div>
                                </div> */}
                                <div className="card_list me-3">
                                    {!props.isCard ?
                                        <div className="card_list_view text-end">
                                            <button onClick={props.onList} className='btn card_view active btn-sm text-start px-4 py-3'>
                                                <i className="bi bi-card-image"></i>
                                            </button>
                                            <button onClick={props.onCard} className='btn list_view btn-sm text-start px-4 py-3'>
                                                <i className="bi bi-list-ul"></i>
                                            </button>
                                        </div>
                                        :
                                        <div className="card_list_view text-end" >
                                            <button onClick={props.onList} className='btn card_view btn-sm text-start px-4 py-3'>
                                                <i className="bi bi-card-image"></i>
                                            </button>
                                            <button onClick={props.onCard} className='btn list_view active btn-sm text-start px-4 py-3'>
                                                <i className="bi bi-list-ul"></i>
                                            </button>
                                        </div>
                                    }
                                </div>
                                {/* <div className="filter me-3">
                                    <div className="properties_filter text-end">
                                        <PMFilter />
                                    </div>
                                </div>
                                <div className="add_pro">
                                    <div className="properties_add_bt text-end">
                                        <button type='submit' className='btn btn-primary fw-bold px-4 py-3 text-start'
                                                data-bs-toggle="modal"
                                                data-bs-target="#added-success-modal" >
                                            <i className="bi bi-plus-circle-fill align-middle font-18"></i> Add
                                        </button>
                                        <PropertyType />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                : null
                // <>
                //     <div className="col-12 mb-5">
                //         <div className="row align-items-center">
                //             <div className="col-sm-4">
                //                 <h4 className="card-title font-18 text-black mb-0">Availability</h4>
                //             </div>
                //             <div className="col-sm-8">
                //                 <div className="properties_buttons float-end">
                //                     <div className="card_list me-10">
                //                         {!props.isCard ?
                //                             <div className="card_list_view text-end">
                //                                 <button onClick={props.onList} className='btn card_view active btn-sm text-start py-3'>
                //                                     <i className="bi bi-card-image"></i>
                //                                 </button>
                //                                 <button onClick={props.onCard} className='btn list_view btn-sm text-start py-3'>
                //                                     <i className="bi bi-list-ul"></i>
                //                                 </button>
                //                             </div>
                //                             :
                //                             <div className="card_list_view text-end" >
                //                                 <button onClick={props.onList} className='btn card_view btn-sm text-start py-3'>
                //                                     <i className="bi bi-card-image"></i>
                //                                 </button>
                //                                 <button onClick={props.onCard} className='btn list_view active btn-sm text-start py-3'>
                //                                     <i className="bi bi-list-ul"></i>
                //                                 </button>
                //                             </div>
                //                         }
                //                     </div>
                //                     <div className="filter me-10">
                //                         <div className="properties_filter text-end">
                //                             <PMFilter />
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </>                
            }
        </div>
    )
};