import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'
import { AddComunities } from './AddCommunities'
import { PopupListing } from './Modal'
import { getMasterData, getCommunities } from '../../../constants/axios/apis'
import { Select } from 'antd'
import { CustomLoader2 } from '../../../constants/generics/customActios'

export function CommunitiesList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [buildingTypeList, setBuildingTypeList] = useState<any>()
    const { Option } = Select
    const [loading, setLoading] = useState<any>(true)
    const [communitiList, setCommunitiList] = useState<any>()
    const [totalcommunitiList, setTotalcommunitiList] = useState<any>(0)

    useEffect(() => {
        getMasterData().then(({ data }) => {
            const buildingTypeList = data.find((el: any) => el.category_name === 'building_type')
            setBuildingTypeList(buildingTypeList?.master_data)
        })
        
    }, [])

    useEffect(() => {
        setLoading(true)
        let page = currentPage
        const newPayload =
            '?currentPage=' +
            page +
            '&pageSize=' +
            pageSize +
            '&id_sort=1'
            getCommunities(newPayload).then((response: any) => {
            if (Array.isArray(response?.data) && response?.data?.length > 0) {
                setCommunitiList(response?.data)
                setTotalcommunitiList(response?.dataCount)
            } else {
                setCommunitiList([])
                setTotalcommunitiList(0)
            }
            setLoading(false)
        }).catch(() => { setLoading(false); setCommunitiList([]); setTotalcommunitiList(0) })
    }, [currentPage])

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-6">
                    <h3 className=''>Community List</h3>
                </div>
                <div className="col-6 text-end">
                    <Link to="/communities/add">
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                        Add Community
                    </button>
                    </Link>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase"> Reference Id </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Reference Id" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Community Name</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Community Name" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Community Type</label>
                                <Select
                                    className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                                    showSearch
                                    placeholder='Please Select'
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        ((option!.children as unknown) as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    {buildingTypeList?.map((el: any, index: number) => (
                                        <Option key={index} value={el.value}>
                                            {el.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Status</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option>Please Select </option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>
            
            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='table-crm'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th> Reference ID</th>
                                                                    <th> Community Name </th>
                                                                    <th> Community Type </th>
                                                                    <th> Score</th>
                                                                    <th> Total Buildings </th>
                                                                    <th> Total Units </th>
                                                                    <th> Total Booked </th>
                                                                    <th> Available Units </th>
                                                                    <th> Status </th>
                                                                    <th> Action </th>
                                                                </tr>
                                                            </thead>
                                                            {loading ? (
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={10}>
                                                                            <CustomLoader2 />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ) : (
                                                                <tbody>{communitiList ? 
                                                                <>
                                                                {communitiList.length > 0 ? communitiList.map((item:any, i:number) => (
                                                                    <tr key={i}>
                                                                        <td className="text-warning">{item.reference_id}</td>
                                                                        <td>{item.community}</td>
                                                                        <td>{item.community_type}</td>
                                                                        <td>{item.score ? item.score : '0'}    
                                                                            <i data-bs-toggle="modal" data-bs-target="#Listing-popup" className="fa fa-eye text-warning ms-2 c-pointer"></i>
                                                                        </td>
                                                                        <td>{item.total_building}</td>
                                                                        <td>{item.total_unit}</td>
                                                                        <td>{item.unit_booked}</td>
                                                                        <td>{item.available_unit}</td>
                                                                        <td className={item?.status === 'InActive' ? 'text-danger' : 'text-success'}>{item?.status == 1 ? 'Active' : 'InActive'}</td>
                                                                        <td className="action-button text-end">
                                                                            <div className="dropdown">
                                                                                <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v text-black"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                                        <li>
                                                                                            <Link to="/communities/create-building" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-building"></i>
                                                                                                <p className="m-0">Create Building</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to="/communities/create-unit" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-home"></i>
                                                                                                <p className="m-0">Create Unit</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to="/communities/edit" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-edit"></i>
                                                                                                <p className="m-0">Edit</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to="/communities/preview" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-eye"></i>
                                                                                                <p className="m-0">Preview</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-share"></i>
                                                                                                <p className="m-0">Share</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={10}>No data..</td></tr>
                                                                }
                                                                </>
                                                                : ''}</tbody>
                                                                )}
                                                        </table>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={parseInt(totalcommunitiList)}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           < PopupListing/>
        </>
        )
}
