import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import Approvals from "../../../../assets/images/aproval-quick-link.svg";
import UnrenewedLeases from "../../../../assets/images/unrenewed-lease-quick-link.svg";
import Availability from "../../../../assets/images/availability-quick-link.svg";
import Refunds from "../../../../assets/images/refund-quick-link.svg";
import Offers from "../../../../assets/images/offer-renewals-quick-link.svg";
import MyTeam from "../../../../assets/images/my-team-quick-link.svg";
import Reports from "../../../../assets/images/reports-quick-link.png";
import Tenants from "../../../../assets/images/tenants-quick-link.svg";
import Leads from "../../../../assets/images/leads-quick-link.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function QuickLinks() {
    const { t } = useTranslation();

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow:3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
           
            
        ]
    };
    return (
        <div className="row">
            <div className="col-12">
                <div className="card mb-6">
                    <div className="card-body p-7">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="font-14 fw-boldest text-black border-bottom pb-4"> Quick Buttons </h4>
                            </div>
                        </div>
                        <div className="quick-link-sec mt-5">
                            <div className='quick-card'>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mb-5">
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-approvals/open-requests'} className="text-decoration-none ">
                                                        <div className="card quick-1">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Approvals} alt="Renew Contract" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("Approvals")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-leads/new-lead'} className="text-decoration-none">
                                                        <div className="card quick-2">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Leads} alt="Community Wall" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("Leads")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-unrenewed-leases'} className="text-decoration-none">
                                                        <div className="card quick-3">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={UnrenewedLeases} alt="Access Card" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("Unrenewed Leases")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-property-data/available'} className="text-decoration-none">
                                                        <div className="card quick-4">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Availability} alt="Community Wall" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("Availability")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-refunds/open-requests'} className="text-decoration-none">
                                                        <div className="card quick-5">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Refunds} alt="Community Wall" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("Refunds")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-refunds/open-requests'} className="text-decoration-none">
                                                        <div className="card quick-6">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Refunds} alt="Community Wall" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("Refunds")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>   
                                        <div className="row mb-5">
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-offers-and-renewals/offer'} className="text-decoration-none">
                                                        <div className="card quick-7">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Offers} alt="Community Wall" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("Offers and Renewals")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-myteam/all'} className="text-decoration-none">
                                                        <div className="card quick-8">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={MyTeam} alt="Community Wall" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12"> {t("My Team")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <Link to={'/pm-tenant/all'} className="text-decoration-none">
                                                        <div className="card quick-9">
                                                            <div className="card-body py-7 px-4">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={Tenants} alt="Community Wall" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0 font-12">{t("Tenants")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    );
}