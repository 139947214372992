import React, { useState, useEffect } from 'react';
import Credit from "../../../../assets/images/pm-credit-card.svg";
import Bank from "../../../../assets/images/pm-bank.svg";
import Cheque from "../../../../assets/images/pm-cheque.svg";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { useTranslation } from 'react-i18next'
import moment from 'moment';

export function Payment() {
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row mb-3">

                {contractApprovalData?.payment_plan_details?.rent?.length > 0 ? contractApprovalData?.payment_plan_details?.rent.map((item: any, index: number) => {
                    return (
                        <div className="col-md-12 col-12 mb-5">
                            <div className="card shadow-sm payment-card-sec">
                                <div className="card-body px-8 py-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                                {t("Payment Type")}
                                            </label>
                                            <h3 className="mb-0 payment-txt col">{t("Rent")}</h3>
                                        </div>
                                        <div className="row">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                                {t("Payment Date")}
                                            </label>
                                            <h3 className="mb-0 payment-txt col">{moment(item?.amountDeductionDate).format('DD-MM-YYYY')}</h3>
                                        </div>
                                        <div className="row">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                                {t("Amount")}
                                            </label>
                                            <h3 className="mb-0 payment-txt col">{item?.totalAmount?.toLocaleString(undefined, { maximumFractionDigits: 2 })} {t("AED")}</h3>
                                        </div>
                                        <div className="row">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                                {t("Payment Type")}
                                            </label>
                                            <h3 className="mb-0 payment-txt col">
                                                <img
                                                    src={item?.modePayment === 'CREDIT_CARD' ? Credit : item?.modePayment === 'BANK_TRANSFER' ? Bank : Cheque}
                                                    className="img-fluid me-3"
                                                />
                                                {item?.modePayment === 'CREDIT_CARD' ? t("Credit Card") : item?.modePayment === 'BANK_TRANSFER' ? t("Bank Transfer") : t("Cheque")}
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row align-items-center">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                                        {t("Status")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt col">
                                                        {item?.status === 1 ? t("Pending") : t("Cleared")}
                                                    </h3>
                                                </div>
                                                {/* <div className="">
                            <i className="bi bi-chevron-down text-black"></i>
                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) :
                    <div className="col-12 mb-3">
                        <div className="card  payment-card-sec">
                            <div className="card-body px-8 py-6">
                                {t("No Data Found")}
                            </div>
                        </div>
                    </div>
                }

                {contractApprovalData?.service_charges?.length > 0 ? contractApprovalData?.service_charges.map((item: any, index: number) => {
                    return (
                        <div className="col-md-12 col-12 mb-5">
                            <div className="card shadow-sm payment-card-sec">
                                <div className="card-body px-8 py-6">
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Payment Type")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">{t("Service Charges")}</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Payment Date")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">12-11-2021</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Amount")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">45,000 {t("AED")}</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Payment Type")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col"><img src={Bank} className="img-fluid me-3" /> {t("Credit Card")}</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row align-items-center">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                                    {t("Status")}
                                                </label>
                                                <h3 className="mb-0 payment-txt col">{t("Cleared")}</h3>
                                            </div>
                                            <div className="">
                                                <i className="bi bi-chevron-down text-black"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) :
                    <div className="col-12">
                        <div className="card  payment-card-sec">
                            <div className="card-body px-8 py-6">
                                {t("No Data Found")}
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>

    );
}