import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import fb from '../../../assets/images/fb-market-ico.png'
import linked from '../../../assets/images/linkedin-ico.png'
import goggle from '../../../assets/images/google-ppc-ico.png'
import lock from '../../../assets/images/lock-ico.svg'

export function AudianceModal() {


    return (
        <div className="modal fade" aria-hidden='true' id="Audiance-popup">
            <div className="modal-dialog modal-md modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-theme">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Create audience</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="row py-4">
                            <div className="col-md-12">
                                <div className="form-group my-15 row">
                                    <label className="mb-4 control-label theme-color font-16 font-weight-semibold col-md-8"> Create website traffic audience </label>
                                    <div className="col-md-4 text-end">
                                        <a href="#" className="text-decoration-none">
                                            <img src={fb} className="img-fluid me-3"/>
                                        </a>
                                        <a href="#" className="text-decoration-none">
                                            <img src={linked} className="img-fluid me-3"/>
                                        </a>
                                        <a href="#" className="text-decoration-none">
                                            <img src={goggle} className="img-fluid"/>
                                        </a>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="border px-5 py-8 rounded">
                                            <p className="mb-0 font-12 font-weight-semibold">
                                                Nurture the people who've been to your site. Create an audience from your visitors and re-engage with them wherever they are online.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group my-15 row">
                                    <label className="mb-4 control-label theme-color font-16 font-weight-semibold col-md-12"> Contact List <img src={lock} className="img-fluid ms-1"/> </label>
                                    <div className="col-md-12">
                                        <div className="border px-5 py-8 rounded">
                                            <p className="mb-0 font-12 font-weight-semibold">
                                                Make the most of your contacts. Create an audience from your contact list in Realcube CRM to re-engage with them
                                                wherever they are online.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group my-15 row">
                                    <label className="mb-4 control-label theme-color font-16 font-weight-semibold col-md-8"> Lookalike </label>
                                    <div className="col-md-4 text-end">
                                        <a href="#" className="text-decoration-none">
                                            <img src={fb} className="img-fluid me-3"/>
                                        </a>
                                        <a href="#" className="text-decoration-none">
                                          <img src={linked} className="img-fluid me-3"/>
                                        </a>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="border px-5 py-8 rounded">
                                            <p className="mb-0 font-12 font-weight-semibold">
                                                Make the most of your contacts. Create an audience from your contact list in Realcube CRM to re-engage with them
                                                wherever they are online.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}