import React, {FC, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import {CustomRequestSuccessfulPopup} from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import Loader from '../../../../components/Loader'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import IntlTelInput from 'react-intl-tel-input'
import {useTranslation} from 'react-i18next'
import {createTeamMember} from '../../../../constants/axios/apis'

export function PMMyTeamAddMember() {
  const {t} = useTranslation()
  const history = useHistory()
  const buttonEl: any = useRef(null)

  const [selectedMobileCode, setMelectedMobileCode] = useState<any>('')

  const [isSubmitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [message, setMessage] = useState('')

  const initialValues = {
    firstNameField: '',
    lastNameField: '',
    emirateIDField: '',
    mobileNumberField: '',
    emailIDField: '',
    designationField: '',
  }

  const onSubmit = (values: any) => {
    setSubmitting(true)
    setSubmitError('')

    const formData = new FormData()
    formData.append('first_name', values.firstNameField)
    formData.append('last_name', values.lastNameField)
    formData.append('email', values.emailIDField)
    formData.append('emirates_id', values.emirateIDField)
    formData.append('phone', selectedMobileCode + values.mobileNumberField)
    formData.append('designation', values.designationField)
    formData.append('id_role', 'PE')

    setTimeout(() => {
      createTeamMember(formData)
        .then((response: any) => {
          setSubmitting(false)
          if (response.errorCode === 1) setSubmitError(response.errorDescription)
          else if (response.errorCode === 0) {
            formik.resetForm()
            setMessage(response.data.msg)
            setSubmitting(false)
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
          } else setSubmitError(t('Some error occured. Please try again !'))
        })
        .catch((e) => {
          setSubmitting(false)
          setSubmitError(t('Some error occured. Please try again !'))
        })
    }, 1000)
  }

  const validationSchema = Yup.object({
    firstNameField: Yup.string()
      .required(t('First Name is required!'))
      .min(3, t('Minimum 3 characters!'))
      .max(30, t('Maximum 30 characters!'))
      .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
      .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!')),
    lastNameField: Yup.string()
      .required('Last Name is required')
      .min(3, t('Minimum 3 characters!'))
      .max(30, t('Maximum 30 characters!'))
      .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
      .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!')),
    emirateIDField: Yup.string()
      .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
      .required(t('Emirates ID is required!'))
      .matches(/^(\d+-?)+\d+$/, t('Emirates ID is not valid!'))
      .min(15, t('Min length is 15!'))
      .max(25, t('Maximum 25 characters!')),

    mobileNumberField: Yup.string()
      .min(3, t('Mobile No. should be greater then 3 digits!'))
      .max(15, t('Mobile No. should be smaller then 15 digits!'))
      .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
      .matches(/^[^\s].*/, t('Mobile No. cannot start with a blankspace!'))
      .required(t('Mobile No. is required!')),
    emailIDField: Yup.string()
      .email(t('Please enter vaild email format!'))
      .required(t('Email is required!'))
      .matches(/^[^\s].*/, t('Email Id cannot start with a blankspace!')),
  })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
    setMelectedMobileCode(
      country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : ''
    )
    formik.setFieldValue('mobileNumberField', phoneNumber)
    formik.setFieldTouched('mobileNumberField')
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/pm-myteam/all'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              <span className='text-black'>{t('Add Team Member')}</span>
            </Link>
          </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t('First Name')} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('firstNameField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('First Name')}
                      />
                      {formik.touched.firstNameField && formik.errors.firstNameField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.firstNameField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t('Last Name')} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('lastNameField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Last Name')}
                      />
                      {formik.touched.lastNameField && formik.errors.lastNameField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.lastNameField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t('Emirates ID')} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('emirateIDField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Emirates Id')}
                      />
                      {formik.touched.emirateIDField && formik.errors.emirateIDField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.emirateIDField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t('Mobile Number')} <span className='text-danger'> * </span>
                      </label>
                      <IntlTelInput
                        preferredCountries={['ae', 'in', 'us']}
                        containerClassName='intl-tel-input w-100'
                        separateDialCode={true}
                        fieldName='mobile_no'
                        value={formik.values.mobileNumberField}
                        inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                        onPhoneNumberChange={handlePhoneNumber}
                        onPhoneNumberBlur={handlePhoneNumber}
                        placeholder={t('Mobile No.')}
                      />
                      {formik.touched.mobileNumberField && formik.errors.mobileNumberField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.mobileNumberField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t('Email ID')} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('emailIDField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Email Id')}
                      />
                      {formik.touched.emailIDField && formik.errors.emailIDField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.emailIDField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-2'>
                      <label className='form-label fw-bolder'>
                        {t('Designation')} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('designationField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Designation')}
                      />
                      {formik.touched.designationField && formik.errors.designationField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.designationField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='col-12 text-end d-flex flex-column align-items-end justify-content-end mt-5'>
          {isSubmitting && <Loader />}
          {!isSubmitting && (
            <div>
              <button
                onClick={() => {
                  history.goBack()
                }}
                type='button'
                className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'
              >
                {t('Cancel')}
              </button>
              <button
                onClick={formik.submitForm}
                type='submit'
                className='btn btn-primary fw-bold px-10 py-3'
              >
                {t('Submit')}
              </button>
            </div>
          )}
          {submitError && (
            <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
              {submitError}
            </div>
          )}
          <p
            ref={buttonEl}
            data-bs-target='#req-submitted-popup'
            data-bs-toggle='modal'
            data-bs-dismiss='modal'
            className='invisible d-none btn btn-primary fw-bold px-10 py-3'
          />
          <CustomRequestSuccessfulPopup successMessage={message} href={'/pm-myteam/all'} />
        </div>
      </div>
    </div>
  )
}
