import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next'

export function History() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 pt-8 pb-3">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="font-14 text-theme mb-6">
                                        {t("Total Approved Amount")} : 1800 {t("AED")}
                                    </h4>
                                </div>
                                <div className="col-12">
                                    <div className="card shadow-sm mb-7">
                                        <div className="card-body px-8 py-6">
                                            <div className="history_main_div d-flex">
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Type")}
                                                    </label>
                                                    <h3 className="mb-0">{t("Early Termination")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0">Muhammed</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Total Cost Impact")}
                                                    </label>
                                                    <h3 className="mb-0">12,000 {t("AED")}</h3>
                                                </div>
                                                {/* <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        Amount
                                                    </label>
                                                    <h3 className="mb-0">50,000 {t("AED")}</h3>
                                                </div> */}
                                                <div className="">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0">{t("Approved")}</h3>
                                                        </div>
                                                        {/* <div className="">
                                                            <i className="bi bi-chevron-down text-black" ></i>
                                                        </div> */}
                                                        <Dropdown >
                                                            <Dropdown.Toggle variant="white"  id="dropdown-basic" className='border-0 p-1'>
                                                                <i className="bi bi-chevron-down text-black" ></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className='approved-popup'>
                                                                <div >
                                                                    <div>
                                                                        <div className='font-14 fw-bold mb-2'>{t("Approval History")}</div>
                                                                             <table className="table m-0">
                                                                                <thead>
                                                                                    <tr className='heading'>
                                                                                    <th scope="col">{t("Status")}</th>
                                                                                    <th scope="col">{t("Approved By")}</th>
                                                                                    <th scope="col">{t("Approved Date")}</th>
                                                                                    <th scope="col">{t("Comments")}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>{t("Approved")}</td>
                                                                                        <td>Mohammed</td>
                                                                                        <td>20-jun-2022</td>
                                                                                        <td>{t("Comments")}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>{t("In Process")}</td>
                                                                                        <td>John</td>
                                                                                        <td>22-jun-2022</td>
                                                                                        <td>{t("Comments")}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                    </div>
                                                                </div>
                                                                {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card shadow-sm mb-7">
                                        <div className="card-body px-8 py-6">
                                            <div className="history_main_div d-flex">
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Type")}
                                                    </label>
                                                    <h3 className="mb-0">{t("Contract Renewal")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0">Muhammed</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Total Cost Impact")}
                                                    </label>
                                                    <h3 className="mb-0">12,000 {t("AED")}</h3>
                                                </div>
                                                {/* <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        Amount
                                                    </label>
                                                    <h3 className="mb-0">50,000 {t("AED")}</h3>
                                                </div> */}
                                                 <div className="">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0">{t("Approved")}</h3>
                                                        </div>
                                                        {/* <div className="">
                                                            <i className="bi bi-chevron-down text-black" ></i>
                                                        </div> */}
                                                        <Dropdown >
                                                            <Dropdown.Toggle variant="white"  id="dropdown-basic" className='border-0 p-1'>
                                                                <i className="bi bi-chevron-down text-black" ></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className='approved-popup'>
                                                                <div >
                                                                    <div>
                                                                        <div className='font-14 fw-bold mb-2'>{t("Approval History")}</div>
                                                                             <table className="table m-0">
                                                                                <thead>
                                                                                    <tr className='heading'>
                                                                                    <th scope="col">{t("Status")}</th>
                                                                                    <th scope="col">{t("Approved By")}</th>
                                                                                    <th scope="col">{t("Approved Date")}</th>
                                                                                    <th scope="col">{t("Comments")}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>{t("Approved")}</td>
                                                                                        <td>Mohammed</td>
                                                                                        <td>20-jun-2022</td>
                                                                                        <td>{t("Comments")}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>{t("In Process")}</td>
                                                                                        <td>John</td>
                                                                                        <td>22-jun-2022</td>
                                                                                        <td>{t("Comments")}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                    </div>
                                                                </div>
                                                                {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-7">
                                    <div className="card shadow-sm">
                                        <div className="card-body px-8 py-6">
                                            <div className="history_main_div d-flex">
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Type")}
                                                    </label>
                                                    <h3 className="mb-0">{t("Insufficient Notices")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0">Muhammed</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Total Cost Impact")}
                                                    </label>
                                                    <h3 className="mb-0">12,000 {t("AED")}</h3>
                                                </div>
                                                {/* <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        Amount
                                                    </label>
                                                    <h3 className="mb-0">50,000 {t("AED")}</h3>
                                                </div> */}
                                                <div className="">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0">{t("Approved")}</h3>
                                                        </div>
                                                        {/* <div className="">
                                                            <i className="bi bi-chevron-down text-black" ></i>
                                                        </div> */}
                                                         <Dropdown >
                                                            <Dropdown.Toggle variant="white"  id="dropdown-basic" className='border-0 p-1'>
                                                                <i className="bi bi-chevron-down text-black" ></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className='approved-popup'>
                                                                <div >
                                                                    <div>
                                                                        <div className='font-14 fw-bold mb-2'>{t("Approval History")}</div>
                                                                             <table className="table m-0">
                                                                                <thead>
                                                                                    <tr className='heading'>
                                                                                    <th scope="col">{t("Status")}</th>
                                                                                    <th scope="col">{t("Approved By")}</th>
                                                                                    <th scope="col">{t("Approved Date")}</th>
                                                                                    <th scope="col">{t("Comments")}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>{t("Approved")}</td>
                                                                                        <td>Mohammed</td>
                                                                                        <td>20-jun-2022</td>
                                                                                        <td>{t("Comments")}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>{t("In Process")}</td>
                                                                                        <td>John</td>
                                                                                        <td>22-jun-2022</td>
                                                                                        <td>{t("Comments")}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                    </div>
                                                                </div>
                                                                {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}