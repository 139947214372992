import React, { useState, useEffect, useRef } from 'react';
import Propertie from '../../../../../../assets/images/latest-one.png';
import bed from "../../../../../../assets/images/bed.svg";
import Bath from "../../../../../../assets/images/bath.svg";
import Place from "../../../../../../assets/images/place.svg";
import SQR from "../../../../../../assets/images/sqft.svg";
import Yes from "../../../../../../assets/images/yes.svg";
import { useTranslation } from 'react-i18next'

export function MakeAnOfferPopUp() {
    const { t } = useTranslation();

    return (
        <div className="modal fade add-broker-modal" aria-hidden='true' id="make-anOffer-popUp">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-8">
                    <div className="modal-header p-0">
                        <h5 className="modal-title">{t("Make an Offer")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-0 pb-0 pt-5">
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="properties_list_view mb-5">
                                        <div className="card px-2 py-2">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3">
                                                    <div className="offer_main_img2">
                                                        <img src={Propertie} alt="Work Orders" className="img-fluid" />
                                                        <div className="property_card_headding py-3 px-3 text-center">
                                                            <p className="fw-bolder mb-0 text-white">{t("Merchant Name")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 px-0">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <p className="text-black fw-bolder fs-16">{t("Fully furnished Spacious Villa")}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="offer_main_details_div_2">
                                                                <h5 className="text-black fw-bolder me-3 font-13">
                                                                    <small>{t("AED")}</small> <span className="fw-boldest">90,000</span>
                                                                </h5>
                                                                <p className="fs-7">
                                                                    <img src={bed} className="img-fluid"/>
                                                                    3
                                                                </p>
                                                                <p className="fs-7">
                                                                    <img src={Bath} className="img-fluid"/>
                                                                    3
                                                                </p>
                                                                <p className="sqft fs-7">
                                                                    <img src={SQR} className="img-fluid"/>
                                                                    1400 {t("Sqmt")}
                                                                </p>
                                                                <p className="fs-7">
                                                                    <img src={Yes} className="img-fluid"/>
                                                                    {t("Yes")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 offer_main_details_div_3">
                                                            <p className="mb-0 pt-2">
                                                                <img src={Place} className="img-fluid"/>
                                                                Type 3 | Ground Floor,  Mira Oasis 3
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 position-relative">
                                        <label className="form-label font-12">{t("Customer Name")}</label>
                                        <input type="text" className="form-control font-12" placeholder="John Doe" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("LEASE START DATE")}</label>
                                                <p className="form-label text-black font-14 mb-0">June 10,2021</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("NO OF PAYMENTS")}</label>
                                                <p className="form-label text-black font-14 mb-0">2 Payments</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("MY OFFER")}</label>
                                                <p className="form-label text-black font-14 mb-0">80000</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 position-relative">
                                        <label className="form-label font-12">{t("Extension Reason")}</label>
                                        <select className="form-control form-select font-12" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer px-0 py-0">
                        <button data-bs-target="#added-success-modal" data-bs-toggle="modal" data-bs-dismiss="modal"
                            type='submit'
                            className='btn btn-primary fw-bold px-10 py-3 w-100'
                        >
                            {t("Submit")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}