import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { OpportunitiesListing } from './Opportunites/OpportunitesListing'
import { OpportunitesAdd } from './Opportunites/OpportunitesAdd'
import { OpportunitesSendOffer } from './Opportunites/OpportunitesSendOffer'
import { OpportunitesEdit } from './Opportunites/OpportunitesEdit'
import { OpportunitesPreview } from './Opportunites/OpportunitesPreview'
import { OfferListing } from './Offer/OfferList'
import { OfferPreview } from './Offer/OfferPreview'

const PMOpportunitesPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                    <Route path='/opportunites/list'>
                            <OpportunitiesListing />
                        </Route>
                        <Route path='/opportunites/add'>
                            <OpportunitesAdd />
                        </Route>
                        <Route path='/opportunites/send/offer'>
                            <OpportunitesSendOffer />
                        </Route>
                        <Route path='/opportunites/edit'>
                            <OpportunitesEdit />
                        </Route>
                        <Route path='/opportunites/preview'>
                            <OpportunitesPreview />
                        </Route>
                        <Route path='/opportunites/offerList'>
                            <OfferListing />
                        </Route>
                        <Route path='/opportunites/offerPreview'>
                            <OfferPreview />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMOpportunitesWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMOpportunitesPage />
        </>
    )
};

export { PMOpportunitesWrapper }
