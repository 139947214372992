import { FC } from 'react'
import { Link } from 'react-router-dom'
import SendReminder from "../../../../assets/images/reminders.svg";
import Renew from "../../../../assets/images/renewal.svg";
import RaiseProforma from "../../../../assets/images/bill.svg";

const ActionMenu = (props: any) => {
    // console.log("🚀 ~ file: ActionMenu.tsx:8 ~ ActionMenu ~ props", props)
    return (
        <div className='menu menu-sub menu-sub-dropdown menu-column rounded-12 menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-3 w-145px'
            data-kt-menu='true'>
            <div className='menu-item px-2'>
                <Link to={'/finance-services/receipt-details/:id'} className='menu-link text-black fs-7 ps-2 pe-0 py-2'>
                    <img src={SendReminder} className="img-fluid me-3" />
                    Send Reminder
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to={'/pm-contract-renewal'} className='menu-link text-black fs-7 ps-2 pe-0 py-2'>
                    <img src={Renew} className="img-fluid me-3" />
                    Renew
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to={'/pm-raise-proforma/open-cheque-details'} className='menu-link text-black fs-7 ps-2 pe-0 py-2'>
                    <img src={RaiseProforma} className="img-fluid me-3" />
                    Raise Proforma
                </Link>
            </div>
        </div>
    )
};

export { ActionMenu }
