import React, {useState, useEffect, useRef} from 'react'
import DatePicker from 'react-date-picker'
import {createVisitorInOutTime} from '../../../../../constants/axios/apis'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import moment from 'moment'
import {RequestFailedPopup} from '../../../../SuccessfulPopup/RequestFailedPopup'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {PreventDatePickerInput} from '../../../../../constants/generics/dateTimeFormater'
import {CustomRequestSuccessfulPopup} from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { useTranslation } from 'react-i18next'

const today = new Date()

export function PopupModel({visitorsData, status, today = new Date()}: any) {
  // const today = new Date()
  const [loading, setLoading] = useState(false)
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const history = useHistory()
  const buttonEl: any = useRef(null)
  const {t} = useTranslation()
  const ref = useRef<any>(0)
  const ref2 = useRef<any>(0)

  const [submitError, setSubmitError] = useState('')

  const initialValues = {
    date: today,
    time: moment(today).format('HH:mm'),
  }

  const vistiorRequestSchema = Yup.object().shape({
    date: Yup.date()
      // .max(today, 'Selected date cannot be in the future')
      .required(t('Please select a date')),
    time: Yup.string().required(t('Please select the time')),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vistiorRequestSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setSubmitError('')
      const date_time = moment(values.date).format('YYYY-MM-DD') + ' ' + values.time

      setTimeout(() => {
        createVisitorInOutTime(
          // status,
          visitorsData?.status === 0 || visitorsData?.status === 4
            ? 'In'
            : visitorsData?.status === 1
            ? 'Out'
            : '', // for not visited upcoming ongoing
          visitorsData?.id,
          date_time
        )
          .then((response: any) => {
            setLoading(false)
            if (response.errorCode === 1) {
              setSubmitError(response.errorDescription)
              setTimeout(() => {
                setSubmitError('')
              }, 5000)
            } else if (response.errorCode === 0) {
              setLoading(false)
              var ev = new Event('click', {bubbles: true})
              buttonEl.current.dispatchEvent(ev)
              setTimeout(() => {
                window.location.replace(
                  visitorsData?.status === 0 || visitorsData?.status === 4
                    ? '/visitors/check-in'
                    : '/visitors/check-out'
                )
              }, 4000)
            } else {
              setSubmitError(t('Some error occured. Please try again !'))
              setTimeout(() => {
                setSubmitError('')
              }, 5000)
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t('Invalid Data Provided'))
            setSubmitError(t('Some error occured. Please try again !'))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          })
      }, 1000)
    },
  })

  const handleDate = (e: any) => {
    formik.setFieldValue('date', new Date(e))
  }

  // const [dateValue, setDateValue] = useState<any>()
  // const handleDateChange = (e: any) => {
  //   setDateValue(e)
  //   var menDate = e ? moment(e).format('YYYY-MM-DD') : ''
  //   formik.setFieldValue('date', menDate)
  // }

  // PreventDatePickerInput()

  return (
    <>
      <div className='modal fade custom-modal' aria-hidden='true' id='PopupModel'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content rounded-10'>
            <div className='modal-header py-3 border-0'>
              {visitorsData?.status === 0 || visitorsData?.status === 4 ? (
                <h5 className='modal-title'>{t("Checking In Details")}</h5>
              ) : (
                <h5 className='modal-title'>{t("Checking Out Details")}</h5>
              )}
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <form
                className={
                  'form w-100 fv-plugins-bootstrap5 fv-plugins-framework tab-pane form-add-design'
                }
                noValidate
                onSubmit={formik.handleSubmit}
                id='vistior-slots'
              >
                <div className='row'>
                  <div className='col-md-6 col-12 mb-5'>
                    <div className='date-picker'>
                      <label className='form-label fw-bolder'>
                        {t("Date")}
                        <span className='text-danger'> * </span>
                      </label>
                      <DatePicker
                        onChange={(e: any) => handleDate(e)}
                        value={formik.values.date}
                        className='form-control fw-bolder form-control-solid'
                        format='dd-MM-y'
                        clearIcon={null}
                        // maxDate={new Date(new Date().toLocaleDateString())}
                      />
                      {formik.touched.date && formik.errors.date ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.date}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-6 col-12 mb-5'>
                    <div className=''>
                      <label className='form-label fw-bolder'>
                        {t("Time")}
                        <span className='text-danger'> * </span>
                      </label>
                      <input
                        className='form-control form-control-solid'
                        type='time'
                        // step="60"
                        {...formik.getFieldProps('time')}
                      />
                      {formik.touched.time && formik.errors.time ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.time}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 text-end'>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                      {/* <i className="bi bi-arrow-right-square-fill font-19 text-primary"></i> */}
                      {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {t("Please wait...")}{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {submitError && (
                    <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                      {submitError}
                    </div>
                  )}
                  <p
                    ref={buttonEl}
                    data-bs-target='#req-submitted-popup'
                    data-bs-toggle='modal'
                    data-bs-dismiss='modal'
                    className='invisible d-none'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomRequestSuccessfulPopup successMessage={t('Updated Successfully')} close={false} />
    </>
  )
}
