import React from 'react';
import { StartWorkorder } from './StartWorkorder';
import { PutOnHoldWorkOrder } from './PutOnHoldWorkOrder';

export const StartAndOnHoldWO:React.FC<any> = ({workOrder, setPopupSuccessMsg}) => {
    return (
        <>
            {workOrder.status == "Open" ? (
                <StartWorkorder workOrder={workOrder} />
            ) : workOrder.status == "Inprogress" ? (
                <PutOnHoldWorkOrder workOrder={workOrder} setPopupSuccessMsg={setPopupSuccessMsg} />
            ) : null}
        </>
    );
}