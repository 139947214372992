import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../components/layout/core'
import {IncidentList} from './incidents/components/IncidentList/IncidentList'
import {RequestTabs} from './incidents/components/RequestTabs/RequestTabs'
import {OpenRequests} from './incidents/components/OpenRequests/OpenRequests'
import {ResolvedRequests} from './incidents/components/ResolvedRequests/ResolvedRequests'
import {FireAlarmRequest} from './incidents/components/NewRequest/FireAlarmRequest/FireAlarmRequest'
import {VehicleAccidentRequest} from './incidents/components/NewRequest/VehicleAccidentRequest/VehicleAccidentRequest'
import {RequestSuccessfulPopup} from '../SuccessfulPopup/RequestSuccessfulPopup'
import {AccessCardTabs} from './access-cards/components/AccessCardTabs/AccessCardTabs'
import {OpenList} from './access-cards/components/OpenList/OpenList'
import {ActiveList} from './access-cards/components/ActiveList/ActiveList'
import {InActiveList} from './access-cards/components/InActiveList/InActiveList'
import {VisitorTabs} from './visitors/components/VisitorTabs/VisitorTabs'
import {AllList} from './visitors/components/AllList/AllList'
import {CheckInList} from './visitors/components/CheckInList/CheckInList'
import {CheckOutList} from './visitors/components/CheckOutList/CheckOutList'
import {AddVisitor} from './visitors/components/AddVisitor/AddVisitor'
import {VisitorDetails} from './visitors/components/VisitorDetails/VisitorDetails'
import {DocumentTabs} from './documents/components/DocumentTabs/DocumentTabs'
import {OpenDocumentsList} from './documents/components/DocumentTabs/OpenDocumentsList/OpenDocumentsList'
import {SentDocumentsList} from './documents/components/DocumentTabs/SentDocumentsList/SentDocumentsList'
import {AddDocument} from './documents/components/AddDocument/AddDocument'
import {ParkingInfo} from './parking/components/ParkingInfo/ParkingInfo'
import {ParkingSlots} from './parking/components/ParkingSlots/ParkingSlots'
import {AddParking} from './parking/components/AddParking/AddParking'
import {SignatureModal} from './parking/components/SignatureModal/SignatureModal'
import {ParkingSuccessModal} from './parking/components/ParkingSuccessModal/ParkingSuccessModal'
import {ParkingDetails} from './parking/components/ParkingDetails/ParkingDetails'
import {DirectoryTabs} from './directory/components/DirectoryTabs/DirectoryTabs'
import {ResidentsList} from './directory/components/ResidentsList/ResidentsList'
import {StaffList} from './directory/components/StaffList/StaffList'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {ParkedSlotPopup} from './parking/components/ParkedSlotPopup/ParkedSlotPopup'
import {Add} from './Add/Add'
import {
  // getFacilityAccessCards,
  // getFacilityDocuments,
  getFacilityParkingSlots,
  // getFacilityUserList,
  // getDirectoryUserList,
  // getFacilityVisitors,
  getFacilityUserData,
  // getApprovals,
  // getIncidentList,
  // scheduleVisitList,
} from '../../constants/axios/apis'

import {NewIncident} from './incidents/components/NewRequest/NewIncident/NewIncident'
import {ReportIssues} from './parking/components/ReportIssues/ReportIssues'
import {ContactPopup} from './parking/components/Contact/Contact'
// import {PopupModel} from './visitors/components/VisitorDetails/PopupModel'
import {IncidentView} from './incidents/components/IncidentView/IncidentView'
import {DocumentDetails} from './documents/components/DocumentDetails/DocumentDetails'
// import {ViewDetails} from "./access-cards/components/ViewDetails/ViewDetails";
// import {ResidentsViewDetails} from './directory/components/ResidentsList/ResidentsViewDetails/ResidentsViewDetails'
// import {StaffViewDetails} from './directory/components/StaffList/StaffViewDetails/StaffViewDetails'
import {UserDetailsWrapper} from './directory/components/UserDetailsWrapper/UserDetailsWrapper'
import {useSelector} from 'react-redux'
import {RootState} from '../../constants'
import {getUserRole} from '../../constants/generics/userCheck'
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup'

type Props = {
  accessCards?: any
  visitors?: any
  searchItem?: any
  setSearchItem?: any
  documents?: any
  parkingSlots?: any
  // userList?: any
  residentUserCount?: any
  staffUserCount?: any
  selectedParkingSlot?: any
  selectSlot?: any
  handleBuilding?: any
  parkingSearchItem?: any
  setParkingSearchItem?: any
  totalInactiveAccessList?: any
  setTotalInactiveAccessList?: any
  setInactiveAccessList?: any
  inactiveAccessList?: any
  setSelectedBuilding?: any
  selectedBuilding?: any
}

const ServicesPage: FC<Props> = ({
  // accessCards,
  // visitors,
  searchItem,
  setSearchItem,
  documents,
  parkingSlots,
  residentUserCount,
  staffUserCount,
  selectedParkingSlot,
  selectSlot,
  handleBuilding,
  parkingSearchItem,
  setParkingSearchItem,
  // totalInactiveAccessList,
  // setTotalInactiveAccessList,
  // setInactiveAccessList,
  // inactiveAccessList,
  setSelectedBuilding,
  selectedBuilding
}) => {
  // const [residentList, setResidentList] = useState<any>([]);
  // const [staffList, setStaffList] = useState<any>([]);
  const [parkingSuccessMsg, setParkingSuccessMsg] = useState<any>('');

  // useEffect(() => {
  //   if (Array.isArray(userList)) {
  //     if (userList.length > 0) {
  //       const residentListData = userList.filter((user: any) => {
  //         return user.user_role === 7;
  //       });
  //       const staffListData = userList.filter((user: any) => {
  //         return user.user_role === 8;
  //       });
  //       setResidentList(residentListData);
  //       setStaffList(staffListData);
  //     }
  //   }
  // }, [userList]);

  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/incidents/open-requests'>
              <IncidentList />
              <RequestTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <OpenRequests searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/incidents/resolved-requests'>
              <IncidentList />
              <RequestTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <ResolvedRequests searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/incidents/details'>
              <IncidentView />
            </Route>
            <Route path='/incidents/new-incidents'>
              <NewIncident />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/incidents/fire-alarm'>
              <FireAlarmRequest />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/incidents/vehicle-accident'>
              <VehicleAccidentRequest />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/access-cards/open'>
              <AccessCardTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <OpenList searchItem={searchItem} setSearchItem={setSearchItem} />
              {/* accessCards={accessCards} /> */}
            </Route>
            <Route path='/access-cards/active'>
              <AccessCardTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <ActiveList searchItem={searchItem} setSearchItem={setSearchItem} />
              {/* Form web design this is inactive tabs */}
            </Route>
            <Route path='/access-cards/closed'>
              <AccessCardTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <InActiveList searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/access-cards/details'>{/* <ViewDetails /> */}</Route>
            <Route path='/visitors/all'>
              <VisitorTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <AllList searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/visitors/check-in'>
              <VisitorTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <CheckInList searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/visitors/check-out'>
              <VisitorTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <CheckOutList searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/visitors/add'>
              <AddVisitor />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/visitors/details'>
              <VisitorDetails />
              {/* <PopupModel /> */}
            </Route>
            <Route path='/fc-ss-documents/open-documents'>
              <DocumentTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <OpenDocumentsList searchItem={searchItem} setSearchItem={setSearchItem}
                documents={documents?.filter((document: any) => document.status == 'Open')}
              />
            </Route>
            <Route path='/fc-ss-documents/sent-documents'>
              <DocumentTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <SentDocumentsList searchItem={searchItem} setSearchItem={setSearchItem}
                documents={documents?.filter((document: any) => document.status == 'Sent')}
              />
            </Route>
            <Route path='/fc-ss-documents/add'>
              <AddDocument />
            </Route>
            <Route path='/fc-ss-documents/document-details'>
              <DocumentDetails />
            </Route>
            <Route path='/parking/all'>
              <ParkingInfo count={parkingSlots?.count?.[0]} />
              <ParkingSlots
                selectSlot={selectSlot}
                parkingSlots={parkingSlots}
                handleBuilding={handleBuilding}
                parkingSearchItem={parkingSearchItem}
                setParkingSearchItem={setParkingSearchItem}
                setSelectedBuilding={setSelectedBuilding}
                selectedBuilding={selectedBuilding}
                // setParkingSearchNumber={setParkingSearchNumber}
                // parkingSearchNumber={parkingSearchNumber}
              />
              <ParkedSlotPopup selectedSlot={selectedParkingSlot} />
              <ContactPopup selectedSlot={selectedParkingSlot} />
            </Route>
            <Route path='/parking/add-parking'>
              <AddParking setParkingSuccessMsg={setParkingSuccessMsg}/>
              {/* <SignatureModal /> */}
              {/* <ParkingSuccessModal /> */}
            </Route>
            <Route path='/parking/vehicle-details'>
              <ParkingDetails />
            </Route>
            <Route path='/parking/report-issues'>
              <ReportIssues />
            </Route>
            <Route path='/directory/residents'>
              <DirectoryTabs searchItem={searchItem} setSearchItem={setSearchItem} residentsCount={residentUserCount} staffCount={staffUserCount} />
              <ResidentsList searchItem={searchItem} setSearchItem={setSearchItem} />
              {/* residents={residentList} /> */}
            </Route>
            <Route path='/directory/residents-deatils'>
              <UserDetailsWrapper />
              {/* <ResidentsViewDetails /> */}
            </Route>
            <Route path='/directory/staff'>
              <DirectoryTabs searchItem={searchItem} setSearchItem={setSearchItem} residentsCount={residentUserCount} staffCount={staffUserCount} />
              <StaffList searchItem={searchItem} setSearchItem={setSearchItem} />
              {/* staff={staffList} /> */}
            </Route>
            <Route path='/directory/staff-deatils'>
              <UserDetailsWrapper />
              {/* <StaffViewDetails /> */}
            </Route>
            <Route path='/directory/add'>
              <Add />
              <RequestSuccessfulPopup />
            </Route>
            <Redirect to='/error/403' />
          </Switch>
        </div>
        <CustomRequestSuccessfulPopup
          customID='-parking'
          successMessage={parkingSuccessMsg}
          // showOkBtn={true}
        />
      </div>
    </>
  )
}

const ServicesWrapper: FC = () => {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )

  const location = useLocation()
  const [parkingSlots, setParkingSlots] = useState<any>([])
  const [selectedParkingSlot, setSelectedParkingSlot] = useState<any>()
  // const [userList, setUserList] = useState<any>([])
  const [residentUserCount, setResidentUserCount] = useState<any>([])
  const [staffUserCount, setStaffUserCount] = useState<any>([])
  // const [documents, setDocuments] = useState<any>([])
  // const [accessCards, setAccessCards] = useState<any>([])
  // const [visitors, setVisitors] = useState<any>([])
  const [buildingId, setBuildingId] = useState<any>([])
  const [parkingSearchItem, setParkingSearchItem] = useState<any>('')
  const [searchItem, setSearchItem] = useState<any>('')
  const [selectedBuilding, setSelectedBuilding] = useState<any>(null)
  // const pageSize = 10
  // const [currentPage, setCurrentPage] = useState<any>(1)
  // const [totalaccessList, setTotalaccessList] = useState<any>([])
  // const [accessList, setAccessList] = useState<any>([])
  // const [totalInactiveAccessList, setTotalInactiveAccessList] = useState<any>([])
  // const [inactiveAccessList, setInactiveAccessList] = useState<any>([])
  // const [incidentList, setIncidentList] = useState<any>()
  // const [totalIncidentList, setTotalIncidentList] = useState<any>([])
  // const [totalVisitorList, setTotalVisitorList] = useState<any>([])
  // const [visitorList, setVisitorList] = useState<any>()
  // const [totalScheduledVisitorList, setTotalScheduledVisitorList] = useState<any>([])
  // const [scheduledVisitorList, setScheduledVisitorList] = useState<any>()
  
  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  // console.log('selectedBuilding', selectedBuilding)

  // let temp_building_id = 1

  let fetchType = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    fetchType = JSON.parse(userDetail).id_role == 13 ? 'FMSM' : 'FC'
  }

  const selectSlot = (slot: any) => {
    setSelectedParkingSlot(slot)
  }

  const handleBuilding = (buildingId: any) => {
    console.log('handle', buildingId)
    setBuildingId(buildingId)
    // setBuildingId(1)
  }

  useEffect(() => {
    if(user_role !== 'FMT'){
      if (buildingId) {
        getFacilityParkingSlots(buildingId, parkingSearchItem).then(({data}) => {setParkingSlots(data)})
      } else {
        setParkingSlots([])
      }
    }
  }, [buildingId, parkingSearchItem, location?.key, selectedBuilding])

  useEffect(() => {
    setResidentUserCount(0)
    setStaffUserCount(0)
      let params = currentBuilding ? '?building_id=' + currentBuilding : '?building_id=0'
      params += '&type=residents' //For FMT
      if(user_role === 'FCSS')
        params += '&user_search_type=commercial_user'
      // currentBuilding ? '?type=residents&user_search_type=commercial_user&building_id=' + currentBuilding : '?type=residents&user_search_type=commercial_user'
    getFacilityUserData(params).then(({data}) => {
      setResidentUserCount(data?.dataCount ? data.dataCount : 0)
    })
    getFacilityUserData(
      currentBuilding ? '?type=staff&building_id=' + currentBuilding : '?type=staff'
    ).then(({data}) => {
      setStaffUserCount(data?.dataCount ? data.dataCount : 0)
    })
  }, [currentBuilding])

  

  useEffect(() => {
   
  }, [location.key])

  return (
    <>
      <PageTitle breadcrumbs={[]}> Services </PageTitle>
      <ServicesPage
        parkingSearchItem={parkingSearchItem}
        setParkingSearchItem={setParkingSearchItem}
        searchItem={searchItem}
        setSearchItem={setSearchItem}
        parkingSlots={parkingSlots}
        residentUserCount={residentUserCount}
        staffUserCount={staffUserCount}
        selectedParkingSlot={selectedParkingSlot}
        selectSlot={selectSlot}
        handleBuilding={handleBuilding}
        setSelectedBuilding={setSelectedBuilding}
        selectedBuilding={selectedBuilding}
      />
    </>
  )
}

export {ServicesWrapper}
