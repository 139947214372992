import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {RescheduleTime} from './RescheduleTime';
import { useTranslation } from 'react-i18next'

export function ReschedulePopup() {
    const { t } = useTranslation();

    return (
        <div className="modal fade add-broker-modal" aria-hidden='true' id="reschedule-popup">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t("Schedule Visit")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-5 team-activity-visit-calendar">
                            <Calendar />
                        </div>
                        <div className="mb-5">
                            <h5 className="modal-title mb-5">{t("Select Time")}</h5>
                            <RescheduleTime />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary border-0 w-40 text-uppercase me-4" data-bs-dismiss="modal">{t("Cancel")}</button>
                        <button data-bs-target="#schedule-visit-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit'
                                className='btn btn-primary fw-bold px-10 py-3'>
                            {t("Continue")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}