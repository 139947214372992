import React, { useState, useEffect, useRef } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom'
import NoImage from "../../../../../assets/images/House.png";
import { getPreciseLocations } from '../../../../../constants/axios/apis'
import { currencyFormater } from '../../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

export const CompletedMoveOut = ({workOrder}:any) => {
    const history = useHistory();

    // let params: any = {};
    // params = useLocation();
    // const { workOrder } = params.state;
    const [chargesList, setChargesList] = useState<any>(workOrder?.clearanceChargeDetails)

    useEffect(() => {
        getPreciseLocations(workOrder?.propertyId).then(({ data: preciseLocation }) => {
            const preciseLocationList = preciseLocation?.preciseLocationList
            if(preciseLocationList){
                const updatedChargesList = chargesList.map((el:any)=>{
                    const seletedRoom = preciseLocationList.find((item:any)=> item.id == el.precise_location)
                    return { ...el, room: seletedRoom?.name }
                })
                setChargesList(updatedChargesList)
            }
        })
    }, [])

    const { t } = useTranslation();

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="moveout-inspection">
                        <div className="row">
                            {chargesList.length > 0 && chargesList.map((charge: any, index: number) => {
                                return (
                                    <div className="col-12" key={index}>
                                        <div className="card mb-5">
                                            <div className="card-body p-5">
                                                <div key={index} className="d-flex moveout-inspection-main-div align-items-center">
                                                    <div className="moveout-inspection-div divimg">
                                                        <img src={charge.image ? charge.image : NoImage} alt="" className="img-fliud"/>
                                                    </div>
                                                    <div className="moveout-inspection-div">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14"> {charge.category} </h6>
                                                        <span className="text-black fw-bold font-15"> {t("Req. Quantity")} : {charge.quantity} </span>
                                                        {/* <p className="font-10 mb-1">{charge.room}</p> */}
                                                    </div>
                                                    <div className="moveout-inspection-div">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Rate")} </h6>
                                                        <span className="text-black fw-bold font-15"> {t("AED")} {currencyFormater(parseFloat(charge.amount))} </span>
                                                    </div>
                                                    <div className="moveout-inspection-div">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Total Amount")} </h6>
                                                        <span className="text-black fw-bold font-15"> {t("AED")} {currencyFormater(parseInt(charge.quantity) * parseFloat(charge.amount))} </span>
                                                    </div>
                                                    <div className="moveout-inspection-div text-end">
                                                        <div className="row justify-content-end">
                                                            <div className="col-lg-8 col-md-3 col-6">
                                                                <Link to={{'pathname':'/facility-workorder/inspection-view', state: {clearanceDetails: charge }}} className='btn btn-primary fw-bold btn-block py-3'>
                                                                    {t("View")}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {(!chargesList || chargesList?.length === 0) && 
                                <div className="col-12 mb-5">
                                    <div className="card p-5">
                                        {t("No inspection data available.")}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}