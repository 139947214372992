import React, { useState } from 'react'
import ProfileImage from '../../../../../assets/images/profile-av-lg.jpg'
import { DefaultAvatar } from '../../../../../constants/generics/customActios'
import { OutOfOfficePopUp } from '../OutOfOfficePopUp/OutOfOfficePopUp'
import ProfileAvatar from '../../../../../assets/images/DefaultUser.png'
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../../../constants/generics/userCheck'
import { useSelector } from 'react-redux'
import { UpdateAccountStatus } from './UpdateAccountStatus'
import { CustomRequestSuccessfulPopup } from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'

export function Profile({ teamDetailData }: any) {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  const [accountStatusMsg, setAccountStatusMsg] = useState<any>('')
  const state: any = useSelector((state) => state)
  const user_role = getUserRole(state?.auth?.user?.data)
  const allowStatusChange = user_role === 'PM'

  const { t } = useTranslation()
  return (
    <>
      <div className='profile-img-card rounded-5 w-100 py-9 px-5'>
        <div className='img border w-100 text-center'>
          {teamDetailData?.data?.profile_img != null ? (
            <img
              src={teamDetailData?.data?.profile_img}
              alt='Work Orders'
              className='img-fluid me-3'
            />
          ) : (
            <img
              className={"img-fluid rounded-circle shadow'"}
              src={ProfileAvatar}
              height={150}
              alt='Pic'
            />
          )}
        </div>
        {/* {userEmail === 'pm@rc.test' ? (
        <button
          data-bs-target='#out-of-offer-popUp'
          data-bs-toggle='modal'
          data-bs-dismiss='modal'
          type='submit'
          className='btn btn-primary fw-bold px-6 py-2 out-of-office'
        >
          Out of Office
        </button>
      ) : null} */}
        <OutOfOfficePopUp />
        <div className='mx-3 text-center'>
          <h5 className='text-black font-18 fw-bolder mt-3 text-center'>
            {teamDetailData?.data?.first_name ? teamDetailData?.data?.first_name : '-'}{' '}
            {teamDetailData?.data?.last_name ? teamDetailData?.data?.last_name : '-'}
          </h5>
          {/* <span className='font-10 text-center d-block fw-bolder mb-3'></span>
        {teamDetailData?.data?.status === 1 ? (
          <p className='bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'>
            {t('Active')}
          </p>
        ) : teamDetailData?.data?.status === 0 ? (
          <p className='bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'>
            {t('Inactive')}
          </p>
        ) : (
          <p className='bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'>
            {t('Approval Pending')}
          </p>
        )} */}
        </div>
        <>
          <p className='text-center mb-3 text-muted fw-bold font-14'>{t('Account Status')}</p>
          {allowStatusChange ? (
            <UpdateAccountStatus
              teamDetailData={teamDetailData}
              setAccountStatusMsg={setAccountStatusMsg}
            />
          ) : (
            <>
              {teamDetailData?.data.user_role === 8 ? (
                <p className='font-16 mt-3 mb-0 text-center bg-white text-warning px-5 py-3 rounded fw-bolder'>
                  {t('Approval Pending')}
                </p>
              ) : teamDetailData?.data?.status === 0 ? (
                <p className='font-16 mt-3 mb-0 text-center bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'>
                  {t('Inactive')}
                </p>
              ) : (
                <p className='font-16 mt-3 mb-0 text-center bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'>
                  {t('Active')}
                </p>
              )}
            </>
          )}
        </>

      </div>
      <CustomRequestSuccessfulPopup
        successMessage={accountStatusMsg}
        customID={'-account'}
        showOkBtn={true}
      />
    </>
  )
}
