import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../components/partials/content/CompactPagination/CompactPagination'

const contactData = [{
    "refrence": "REF-L-1627017165",
    "firstName": "Abdul",
    "lastName": "Kadhar",
    "email": "abdul@gamil.com",
    "mobile": "	7892876246",
    "addedDate": "23/07/2021",
  },
  {   
    "refrence": "REF-L-1615803878",
    "firstName": "Brian",
    "lastName": "Ross",
    "email": "brian.ross@yahoo.com",
    "mobile": "	7892876246",
    "addedDate": "31/12/2020",
  },
  {
    "refrence": "REF-L-1616475236",
    "firstName": "Demo",
    "lastName": "Test",
    "email": "test@gamil.com",
    "mobile": "	7892876246",
    "addedDate": "03/02/2020",
  },
  {
    "refrence": "REF-L-1616132103",
    "firstName": "Muhammadu",
    "lastName": "Buhari",
    "email": "muhammadu@gmail.com",
    "mobile": "6548754872",
    "addedDate": "19/03/2121",
  },
  {
    "refrence": "REF-L-1626945778",
    "firstName": "Rafic",
    "lastName": "hamad",
    "email": "rafic@gamil.com",
    "mobile": "	7892876246",
    "addedDate": "23/07/2121",
  }
  ];

export function ContactModal() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)
    return (
        <>
        <div className="modal fade" id="ContactPopup">
            <div className="modal-dialog modal-xl modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Contacts</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="col-sm-auto text-end mb-4">
                            <Link className="btn btn-outline-primary" to>Reset</Link>
                            <Link className="btn btn-primary ms-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#addContact" to> Add New Contact </Link>
                        </div>
                        <div className='table-responsive'>
                          <table className='table mb-0 text-nowrap table-bordered'>
                              <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                  <tr>
                                      <th></th>
                                      <th> Ref.</th>
                                      <th> First Name</th>
                                      <th> Last Name </th>
                                      <th> Email </th>
                                      <th> Mobile No </th>
                                      <th> Date Added </th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/></th>
                                    <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/></th>
                                    <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/> </th>
                                    <th><input type="text" className="rounded border px-3 py-1" placeholder='Mobile no'/> </th>
                                    <th></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {contactData.length > 0 ? contactData.map((item, i) => (
                                      <tr key={i}>
                                          <td><input type="radio" className="form-check-input" value="" /></td>
                                          <td className="text-warning">{item.refrence}</td>
                                          <td>{item.firstName}</td>
                                          <td>{item.lastName}</td>
                                          <td>{item.email}</td>
                                          <td>{item.mobile}</td>
                                          <td>{item.addedDate}</td>
                                      </tr>
                                  )) : <tr><td colSpan={3}>No data..</td></tr>
                                  }
                              </tbody>
                          </table>
                        </div>
                        <div className='w-100 d-flex justify-content-end'>
                            <CompactPagination
                                pageSize={pageSize}
                                totalCount={contactData.length}
                                defaultPage={currentPage}
                                onPageChange={setCurrentPage}
                                loading={false}
                            />
                        </div> 
                        <div className='text-end'>
                            <Link className="btn btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#SelectLeadPopup" to>Apply </Link>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}