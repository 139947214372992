import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {TechnicianTabs} from './TechnicianTabs/TechnicianTabs'
import {Upcoming} from './Upcoming/Upcoming'
import {Closed} from './Closed/Closed'
import { getApprovals } from '../../../../../constants/axios/apis';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../constants';

export function Technician() {
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    const [loading, setLoading] = useState(true);
    
    // const [approvalList, setApprovalList] = useState([]);
    const [upomingApprovalList, setUpomingApprovalList] = useState([]);
    const [closedApprovalList, setClosedApprovalList] = useState([]);

    useEffect(()=>{
        setLoading(true)
        setUpomingApprovalList([])
        setClosedApprovalList([])
        let payload = '?sub_type=21'
        payload += currentBuilding ? payload + '&building_id=' + currentBuilding : '&building_id=0'
        getApprovals(payload).then(({ data }) => {
            // const filteredData = data.filter((el:any)=>el.subReference === 21)
            setUpomingApprovalList(data?.filter((el:any)=>el.status !== 'Approved'))
            setClosedApprovalList(data?.filter((el:any)=>el.status === 'Approved'))
            // setApprovalList(data)
            setLoading(false)
        }).catch((e)=>{
            setLoading(false)
        })
    // }, [])
    }, [currentBuilding])

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3 movein-moveout">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <Switch>
                                        <Route path='/fmteam-approvals/technician/upcoming'>
                                            <TechnicianTabs />
                                            <Upcoming approvalList={upomingApprovalList} loading={loading}/>
                                        </Route>
                                        <Route path='/fmteam-approvals/technician/closed'>
                                            <TechnicianTabs />
                                            <Closed approvalList={closedApprovalList} loading={loading}/>
                                        </Route>
                                        <Redirect to='/error/403' />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}