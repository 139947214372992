import React, { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../components/layout/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PromotionListWrapper } from './components/PromotionList/PromotionListWrapper';
import { PromotionViewWrapper } from './components/PromotionView/PromotionViewWrapper';
import { PromotionCreateWrapper } from './components/PromotionCreate/PromotionCreateWrapper';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';

type Props  = {
    // categoryList:any
}

const PromotionsWrapper: FC<Props> = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return(
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/promotions/list'>
                            <PromotionListWrapper />
                        </Route>
                        <Route path='/promotions/view'>
                            <PromotionViewWrapper setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/promotions/add'>
                            <PromotionCreateWrapper setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div> 
            </div>
            <CustomRequestSuccessfulPopup 
                    successMessage={popupSuccessMsg}
                    showOkBtn={true}
                    customID={'-redirect'}
                />
        </>
    )
};


const MTAPromotionsWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Market Place</PageTitle>
            <PromotionsWrapper />
        </>
    )
};

export { MTAPromotionsWrapper }
