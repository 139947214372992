import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['40 Active', '10 Inactive'],
    datasets: [
        {
            datalabels: {
                color: "#ffffff",
                font: {
                    size: 16,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
            data: [40, 10],
            backgroundColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderWidth: 1,
        },
    ]
};

export const options = {
    plugins: {
        legend: {
            display: true,
            position: 'right' as const,
            labels: {
                color: 'rgb(0, 44, 106)',
                lineDash: [12,12],
                boxWidth: 19,
                boxHeight: 18,
                padding: 30,
                usePointStyle: true,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                }
            }
        },
        
    },
    cutout: '80%',
    maintainAspectRatio: true,
};

type chartProps = {
    chartData: {
        active: any,
        inactive: any,
        leave: any,
        total: any,
    }
}

export function MyTeamChart(props: chartProps) {
    const {t} = useTranslation()

    const chartData = {
        labels: [` ${t('Active')} (${props?.chartData?.active}) `, ` ${t('Inactive')} (${props?.chartData?.inactive}) `],
        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                data: [props?.chartData?.active, props?.chartData?.inactive],
                backgroundColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderWidth: 1,
            },
        ]
    };
    // Init chart labels and data
    // const activeMembers = props?.chartData?.active !== undefined ? props.chartData.active : '';
    // const inactiveMembers = props?.chartData?.inactive !== undefined ? props.chartData.inactive : '';
    // const newData = {...data}
    // newData.labels = [`${activeMembers} Active`, `${inactiveMembers} Inactive`];
    // newData.datasets[0].data = [activeMembers, inactiveMembers]

    return (
        <div className="mta_my_team_cart">
            <div className="row">
                <div className="col-12">
                    <div className="mta-chart Doughnut-Chart text-center position-relative position-absolute top-0" id="mateam-chart">
                        <Chart type='doughnut'  data={chartData} options={options} width={100} height={100}/>
                        <div className="inner-text" id="ma-team-chart">
                            <h4 className="font-12 text-primary fw-boldest">
                                {props?.chartData?.total}<br/>
                               {t("Total")}<br/>
                                {t("Members")}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}