import React, { useState, useEffect } from 'react';
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import PluseIcon from '../../../assets/images/pluse_icon.png'
import { CloseActivities } from './CloseActivities/CloseActivities';
import { OpenActivities } from './OpenActivities/OpenActivities';
import { useTranslation } from 'react-i18next';

export function ActivitiesListWrapper(){
    const location = useLocation();
    const [searchItem, setSearchItem] = useState<any>('');

    const { t } = useTranslation();
    return (
        <>
            <div className="com-card-section">
                <div className="row">
                    <div className="col-12">
                        <div className="row align-items-center mb-6">
                            <div className="col-7">
                                <h4 className="card-title m-0 font-18 text-black"> {t("Activities")} </h4>
                            </div>
                            <div className="col-5 text-end">
                                <Link to={'/activities/add'} className='btn btn-primary btn-sm font-16'>
                                    <i className="bi bi-plus-circle-fill align-middle pe-0"></i> {t("Add")}
                                </Link>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body px-4 py-0">
                                <div className="row access-cd-tabs">
                                    <div className="col-md-6 col-12">
                                        <div className='d-flex py-2'>
                                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                                <li className='nav-item'>
                                                    <Link
                                                        className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/activities/list/open' && 'active')}
                                                        to='/activities/list/open'>
                                                        {t("Open")}
                                                    </Link>
                                                </li>
                                                <li className='nav-item'>
                                                    <Link
                                                        className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/activities/list/close' && 'active')}
                                                        to='/activities/list/close'>
                                                        {t("Closed")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                                        <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                                            <div className='form-group has-search'>
                                                <span className='fa fa-search pt-2 form-control-feedback'></span>
                                                    <input
                                                        type='text'
                                                        className='form-control ps-10'
                                                        value={searchItem}
                                                        onChange={(e) => setSearchItem(e.target.value)}
                                                        placeholder={t('Search')}
                                                    />  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Switch>
                <Route path='/activities/list/open'>
                    <OpenActivities searchItem={searchItem} />
                </Route>
                <Route path='/activities/list/close'>
                    <CloseActivities searchItem={searchItem} />
                </Route>
                <Redirect to='/error/403' />
            </Switch>
        </>
    );
};