import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const InActiveDetailTabs = ({ activeButton, setActiveButton, isOthers }: any) => {
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-0'>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item'>
                        <a onClick={() => { setActiveButton('building') }}
                          className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (activeButton === 'building' && 'active')}> {t("Building Access Card")} </a>
                      </li>
                      <li className='nav-item'>
                        <a onClick={() => { setActiveButton('parking') }}
                          className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (activeButton === 'parking' && 'active')}> {t("Parking Access Card")} </a>
                      </li>
                      {isOthers &&
                        <li className='nav-item'>
                          <a onClick={() => { setActiveButton('others') }}
                            className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                              (activeButton === 'others' && 'active')}> {t("Other Access Card")} </a>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { InActiveDetailTabs }
