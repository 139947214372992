import React, {useRef, useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);
const labels = [''];

export function VerticalGraph(props: any) {
    const data = {
        labels,
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: '',
                data: [props.reportVal.val],
                backgroundColor: '#002C6A',
                barThickness: 55,
                maxBarThickness: 55,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            },
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 0,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: '',
                data: [props.reportVal.rem],
                backgroundColor: '#EAEFF5',
                barThickness: 55,
                maxBarThickness: 55,
                borderRadius: {
                    bottomRight: 0,
                    bottomLeft: 0,
                    topRight: 5,
                    topLeft: 5,
                }
            },
        ]
    };

    const options = {
        showTooltips: false,
        // hover: {mode: null},
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: props.title + '(' + props.reportVal.val + ')',
                padding: {
                    top: 10,
                    bottom: 30
                },
                color: '#A6A6A6',
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
        },
    
        layout: {
                padding: {
                    left: 0
                }
            },
            responsive: true,
    
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false,
                        drawBorder: false,
                        zeroLineColor: 'transparent'
                    },
                    ticks: {
                        color: 'rgb(0, 0, 0)',
                        font: {
                            size: 14,
                            family: "'Poppins', sans-serif",
                            lineHeight: 0.5,
                            weight: '500',
                        }
                    },
                },
                y: {
                    stacked: true,
    
                    display: false,
                    grid: {
                        display: false,
                        drawBorder: false,
                        ticks: {
                            display: false
                        },
                    },
                },
            },
        };
  return (
      <>
        <Bar options={options} data={data} height={400} />
      </>
  )
}
