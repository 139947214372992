import React, { useState, useEffect } from 'react'
import {NoteModal} from './NoteModal'

export function Notes() {
    return (      
        <>
         <div className="row activity mt-4">
            <div className="lead-det-prof pr-2">
                <div className="card mb-4 bg-light">
                    <div className="card-body p-3">
                        <h6 className="font-weight-600 text-black d-flex justify-content-between align-items-center">Site Admin <span className="font-12 font-weight-normal">17/07/2323</span></h6>
                        <p className="font-12 mb-0">Testing Note </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-4"> 
            <div className="col-md-12">
                <div className="border mb-4 rounded text-center py-4">
                    <i className="fas fa-file-alt text-gray-400 font-44"></i>
                    <h3 className="text-gray-400 mt-4 mb-0"> No Notes found </h3>
                </div>
            </div>
            <div className="col-12">
                <div className='py-3 text-center border'>
                    <button data-bs-toggle="modal" data-bs-target="#note_modal" className='btn-primary border-radius-round px-2 pt-1'>
                        <i className="fa fa-plus text-white"></i>
                    </button>
                </div>
            </div>
        </div>   
        <NoteModal/>     
    </>
    )
}

