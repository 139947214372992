import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {PMButtons} from '../../../pm-unrenewed-leases/components/PMButtons/PMButtons';
import LeftArrow from "../../../../assets/images/Left_Arrow.png";


const TenantTabs: React.FC = () => {
    const location = useLocation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-6">
                        <div className="col-lg-3 col-sm-12">
                            <h4 className="card-title font-18 text-black mb-0">
                                <Link to={'/'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                Tenant
                            </h4>
                        </div>
                        <div className="col-lg-9 col-sm-12">
                            <PMButtons />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                                    (location.pathname === '/pm-tenant/all' && 'active')}
                                                    to='/pm-tenant/all'>
                                                    All
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/pm-tenant/card' && 'active')}
                                                    to='/pm-tenant/card'>
                                                    Card
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/pm-tenant/cash' && 'active')}
                                                    to='/pm-tenant/cash'>
                                                    Cash
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/pm-tenant/payment-by-emp' && 'active')}
                                                    to='/pm-tenant/payment-by-emp'>
                                                    Payment by EMP
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {TenantTabs}
