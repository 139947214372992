import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'

export function MainDoorKeys() {
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row form-add-design">
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Card Number 1 </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Card Number 2 </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> Customer Signature </label>
                                                <div className="w-100 form-control">
                                                    <SignatureCanvas
                                                        penColor='black'
                                                        canvasProps={{height: 80, width: 320, className: 'sigCanvas'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 my-auto col-12">
                                            <div className="mt-3">
                                                <button className='btn btn-light fw-bold px-10 py-2'>
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <button className='btn btn-outline-primary fw-bold px-10 py-3 me-5'>
                                        Cancel
                                    </button>
                                    <button data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}