import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/
// export const GET_MYBUILDING = `${API_URL}/getFmCommunityUser`

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
export const actionTypes = {
    SelectedBuilding: '[SelectedBuilding] Action',
    ListBuilding: '[ListBuilding] Action',
}

// ** Define initialDashboardState **
const initialFCDashboardState: IFCDashboardState = {
    selectedBuilding: undefined,
    listBuilding: undefined,
}
export interface IFCDashboardState {
    selectedBuilding?: '',
    listBuilding?: ''
}

// ** Add Reducers **
// ** Once done make sure to import and add to rootReducer in RootReducer.ts file
export const reducer = persistReducer(
    {
        storage,
        key: 'FCDashboard',
        whitelist: ['selectedBuilding'],
    },
    (
        state: IFCDashboardState = initialFCDashboardState,
        action: ActionWithPayload<IFCDashboardState>
    ) => {
        switch (action.type) {
            
            case actionTypes.SelectedBuilding: {
                const building = action.payload?.selectedBuilding
                return { ...state, selectedBuilding: building }
            }
            case actionTypes.ListBuilding: {
                const buildingList = action.payload?.listBuilding
                return { ...state, listBuilding: buildingList }
            }
            default:
                return state
        }
    }
)


export const actions = {

    selectedBuilding: (selectedBuilding: any) => ({
        type: actionTypes.SelectedBuilding,
        payload: { selectedBuilding },
    }),
    listBuilding: (listBuilding: any) => ({
        type: actionTypes.ListBuilding,
        payload: { listBuilding },
    }),
}
// ** Define Saga [Make sure to maintain unique names]
// ** Once done make sure to import and add to rootSaga in RootReducer.ts file
export function* FacilityContractorSaga() {
    // ** Get My AllBuilding Dispatch**
}
