import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import { useTranslation } from 'react-i18next'
import { LangSwitch } from './LangSwitch'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const { t } = useTranslation();

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Wrong email format"))
      .min(3, t("Minimum 3 symbols"))
      .max(50, t("Maximum 50 symbols"))
      .required(t("Email is required")),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch((e) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            // setStatus('The login detail is incorrect')
            setStatus(e)
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className="mb-10 d-flex mt-0 justify-content-center">
          <LangSwitch />
        </div>

        <div className='text-left mb-5'>
          {/* begin::Title */}
          <h1 className='text-theme fw-bold mb-2 font-16'>{t("Forgot Password")}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-600 fw-bold font-12'>
            {t("Enter your email id to send password reset link")}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {t("Sorry, looks like there are some errors detected, please try again.")}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              {t("If this email address exists, you will receive an email to reset your password")}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <input
            type='email'
            placeholder={t("Enter Email ID")}
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' className='btn btn-lg btn-primary w-100 mb-7' disabled={loading}>
            {!loading && <span className='indicator-label'>{t("Submit")}</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {t("Please wait...")}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link
            to='/auth/login'
            id='kt_login_password_reset_form_cancel_button'
            className='fw-bold text-theme font-14'
          >
            {t("Back to Login")}
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
