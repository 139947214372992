import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/
export const GET_PAYMENTS = `${API_URL}/finance/payments?`

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
// ReportsPayload reportspayload
export const actionTypes = {
  ReportsPayload: '[RP] Action',
  Tabs: '[Tabs] Action',
  Payments: '[Payments] Load data',
  PaymentsLoaded: '[Load Payments] Load data',
}
// ** Define initialFianance **
const initialFiananceState: IFiananceState = {
  reportspayload: undefined,
  tabs: undefined,
  payments: undefined,
}
export interface IFiananceState {
  reportspayload?: ''
  tabs?: ''
  payments?: ''
}
export const reducer = persistReducer(
  {storage, key: 'offer', blacklist: ['reportspayload']},

  (state: IFiananceState = initialFiananceState, action: ActionWithPayload<IFiananceState>) => {
    switch (action.type) {
      case actionTypes.ReportsPayload: {
        const reportspayload = action.payload?.reportspayload
        return {...state, reportspayload: reportspayload}
      }
      case actionTypes.Tabs: {
        const tabs = action.payload?.tabs
        return {...state, tabs: tabs}
      }
      case actionTypes.Payments: {
        const payments = action.payload?.payments
        return {...state, payments}
      }
      case actionTypes.PaymentsLoaded: {
        const payments = action.payload?.payments
        return {...state, payments}
      }
      default:
        return state
    }
  }
)
export function getPayments(payload: any) {
  return axios.get(GET_PAYMENTS + payload)
}
export const actions = {
  reportspayload: (reportspayload: any) => ({
    type: actionTypes.ReportsPayload,
    payload: {reportspayload},
  }),
  tabs: (tabs: any) => ({
    type: actionTypes.Tabs,
    payload: {tabs},
  }),
  payments: (data: any) => ({type: actionTypes.Payments, payload: {data}}),
  fulfillMyPayments: (payments: '') => ({
    type: actionTypes.PaymentsLoaded,
    payload: {payments},
  }),
}

export function* PTFinancessaga() {
  yield takeLatest(actionTypes.Payments, function* userRequested(datas: any) {
    const {data: payments} = yield getPayments(datas.payload.data)
    yield put(actions.fulfillMyPayments(payments))
  })
}
