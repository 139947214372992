import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { AddressDetails } from '../../../pm-offers-and-renewals/PMCreateContract/Components/AddressDetails'
import { VisitedRequest } from '../VisitRequest/VisitedRequest'
import { Visit } from '../Visit/Visit'


export function RecentActivity() {
  return (
   <>
    
    <div className="row">
        <div className="col-12">
            <div className="card mb-6">
                <div className="card-body p-7">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="font-14 fw-boldest text-black border-bottom pb-4"> Recent Activity </h4>
                        </div>
                    </div>
                    <div className="sales-section mt-5 border-bottom pb-5">
                        <div className='row'>
                            <div className='col-12'>
                                <a href="javascript:void(0);" className="d-block text-decoration-none">
                                    <h6 className="font-12 theme-color ellipsis mb-0">
                                        <span className="text-warning font-weight-semibold">REF-L-1616655604</span>
                                           <span className="ms-2"> Qualify </span>
                                         <span className="badge badge-primary font-weight-normal font-10 px-2 d-inline-block py-1 ms-2">
                                           Active
                                         </span>
                                    </h6>
                                    <span className="font-weight-normal font-10 text-muted">10/07/2023 04:51</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="sales-section mt-5 border-bottom pb-5">
                        <div className='row'>
                            <div className='col-12'>
                                <a href="javascript:void(0);" className="d-block text-decoration-none">
                                    <h6 className="font-12 theme-color ellipsis mb-0">
                                        <span className="text-warning font-weight-semibold">REF-L-1616655604</span>
                                           <span className="ms-2"> Qualify </span>
                                         <span className="badge badge-primary font-weight-normal font-10 px-2 d-inline-block py-1 ms-2">
                                           Active
                                         </span>
                                    </h6>
                                    <span className="font-weight-normal font-10 text-muted">10/07/2023 04:51</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="sales-section mt-5 border-bottom pb-5">
                        <div className='row'>
                            <div className='col-12'>
                                <a href="javascript:void(0);" className="d-block text-decoration-none">
                                    <h6 className="font-12 theme-color ellipsis mb-0">
                                        <span className="text-warning font-weight-semibold">REF-L-1616655604</span>
                                           <span className="ms-2">  Lead Updated  </span>
                                         <span className="badge badge-primary font-weight-normal font-10 px-2 d-inline-block py-1 ms-2">
                                           Active
                                         </span>
                                    </h6>
                                    <span className="font-weight-normal font-10 text-muted">10/07/2023 04:51</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="sales-section mt-5 border-bottom pb-5">
                        <div className='row'>
                            <div className='col-12'>
                                <a href="javascript:void(0);" className="d-block text-decoration-none">
                                    <h6 className="font-12 theme-color ellipsis mb-0">
                                        <span className="text-warning font-weight-semibold">
                                        </span> Unit Updated 
                                    </h6>
                                    <span className="font-weight-normal font-10 text-muted">03/04/2023 05:29</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="sales-section mt-5 border-bottom pb-5">
                        <div className='row'>
                            <div className='col-12'>
                                <a href="javascript:void(0);" className="d-block text-decoration-none">
                                    <h6 className="font-12 theme-color ellipsis mb-0">
                                        <span className="text-warning font-weight-semibold">
                                        </span> Unit Updated 
                                    </h6>
                                    <span className="font-weight-normal font-10 text-muted">03/04/2023 05:29</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row align-items-center mb-4">
                        <div className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                            <div className="d-flex d-block">
                                <h4 className="font-14 fw-boldest text-black "> Recent Property visits </h4>
                            </div>
                            <a className="text-warning font-12 font-weight-bold">
                                <i className="fa fa-eye text-warning"></i>
                               <span className='ms-2'>14 views</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 cpt-tenant-det1">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item tab_nav_li" role="presentation">
                                <button className="nav-link pb-1 position-relative me-12 false" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#card-payment" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">                                
                                    VisitedRequest
                                </button>
                            </li>
                            <li className="nav-item tab_nav_li" role="presentation">
                                <button className="nav-link pb-1 position-relative me-6 active" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#bank-transfer" type="button" role="tab" aria-controls="pills-profile"
                                    aria-selected="false">
                                    Visit
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="card-payment" role="tabpanel" aria-labelledby="pills-home-tab">
                            <VisitedRequest />
                            </div>
                            <div className="tab-pane fade" id="bank-transfer" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <Visit />
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-12 text-end'>
                        <a href="javascript:void(0);" className="text-warning font-weight-bold font-10">View More <i className="fa fa-angle-right text-warning"></i></a>
                    </div> */}
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row align-items-center mb-4">
                        <div className="d-flex justify-content-between border-bottom pb-2 align-items-center">
                            <div className="d-flex d-block">
                                <h4 className="font-14 fw-boldest text-black "> Today: 11 July 2023 </h4>
                            </div>
                            <Link to className="text-warning font-12 font-weight-bold">
                                <i className="fa fa-angle-left text-warning me-1"></i>
                                <i className="fa fa-angle-right text-warning"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card border-0 shadow-none rounded-0 no-data-found-cd">
                            <div className="card-body border-0">
                                <div className="row justify-content-center">
                                   
                                    <div className="col-12 text-center">
                                        <span className='d-block text-center font-13 link-theme'> No Note Found. </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 text-end'>
                        <a  className="text-warning font-12 font-weight-bold">
                            <span className='me-2'>View More</span> 
                            <i className="fa fa-angle-right text-warning"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
                
   </>
  )
}
