import template from "../../../../assets/images/default-template.png"


export function CampaignDetails({ chooseMessage }: any) {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row form-add-design mb-3">
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <div className="d-block">
                                    <div className="row">
                                        <div className="col-md-3 mb-4 template-inner-set">
                                            <div className="template-inner cursor-pointer">
                                                <div className="template-image bg-white rounded-15 overflow-hidden  p-7 text-center">
                                                    <img src={template} className="img-fluid object-cover rounded-15 overflow-hidden" height="100" alt="Image" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-4 template-inner-set">
                                            <div className="template-inner cursor-pointer">
                                                <div className="template-image bg-white rounded-15 overflow-hidden  p-7 text-center">
                                                    <img src={template} className="img-fluid object-cover rounded-15 overflow-hidden" height="100" alt="Image" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-4 template-inner-set">
                                            <div className="template-inner cursor-pointer">
                                                <div className="template-image bg-white rounded-15 overflow-hidden  p-7 text-center">
                                                    <img src={template} className="img-fluid object-cover rounded-15 overflow-hidden" height="100" alt="Image" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-4 template-inner-set">
                                            <div className="template-inner cursor-pointer selected">
                                                <div className="template-image bg-white rounded-15 overflow-hidden  p-7 text-center">
                                                    <img src={template} className="img-fluid object-cover rounded-15 overflow-hidden" height="100" alt="Image" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-end my-6">
                {/* <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3">Previous</button> */}
                <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('2')}>Next</button>
            </div>
        </>
    )
}