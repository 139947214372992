import React from 'react'
import { useTranslation } from 'react-i18next'
import { SendAlert } from '../../../finance-lease-details/FinanceLeaseDetailsScreen/components/PaymentInfo/SendAlert'
import { PaymentInfo } from '../../../finance-lease-details/FinanceLeaseDetailsScreen/components/PaymentInfo/PaymentInfo'

export function PaymentDetails({ receiptDetail }: any) {
  const { t } = useTranslation()

  return (
    <>
      <div className='card px-7 py-7 mb-7'>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <h4 className='font-16 text-black fw-bolder mb-8'>{t('Payment Status')}</h4>
          </div>
        </div>
        <div className='row payment-st-body'>
          {receiptDetail?.status === 'Upcoming' &&
            <SendAlert
              id={receiptDetail?.id}
              user_id={receiptDetail?.userId}
              type={receiptDetail?.subCategory}
              due_date={receiptDetail?.dueDate}
              amount={receiptDetail?.amount}
            />
          }
          <PaymentInfo
            id={receiptDetail?.id}
            due_date={receiptDetail?.dueDate}
            paid_date={receiptDetail?.paymentReceived}
            method={receiptDetail?.paymentType}
            type={receiptDetail?.subCategory}
            amount={receiptDetail?.amount}
            email={receiptDetail?.tenantEmail}
            invoice={receiptDetail?.invoice_file}
            receipt={receiptDetail?.receipt}
            ref_id={receiptDetail?.referenceId}
            payment_status={receiptDetail?.status === 'Upcoming' ? 0 : 1}
          />
        </div>
      </div>
    </>
  )
}
