import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

export function BankDetails({refundDetail}: any) {
  const {t} = useTranslation()
  const history = useHistory()

  return (
    <div className='card'>
      <div className='card-body px-6 py-6'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-17 text-black fw-boldest mt-2 mb-8'>
              {t('Bank Details')}
            </h4>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='bank-details-finance'>
              <div className='row'>
                <div className='col-12 mb-6'>
                  <label className='font-14 fw-bold mb-3 text-black d-block'>
                    {t("Beneficiary's Name")}
                  </label>
                  <div className='data-contain px-5 py-4 rounded-10'>
                    <h6 className='font-14 fw-bold d-block mb-0'>{refundDetail.details?.name}</h6>
                  </div>
                </div>
                <div className='col-12 mb-6'>
                  <label className='font-14 fw-bold mb-3 text-black d-block'>
                    {t("Beneficiary's Account Number")}
                  </label>
                  <div className='data-contain px-5 py-4 rounded-10'>
                    <h6 className='font-14 fw-bold d-block mb-0'>
                      {refundDetail.details?.account_number}
                    </h6>
                  </div>
                </div>
                <div className='col-12 mb-6'>
                  <label className='font-14 fw-bold mb-3 text-black d-block'>
                    {t('IBAN Number')}
                  </label>
                  <div className='data-contain px-5 py-4 rounded-10'>
                    <h6 className='font-14 fw-bold d-block mb-0'>
                      {refundDetail.details?.iban_number}
                    </h6>
                  </div>
                </div>
                <div className='col-12 mb-6'>
                  <label className='font-14 fw-bold mb-3 text-black d-block'>
                    {t("Beneficiary's Bank Name")}
                  </label>
                  <div className='data-contain px-5 py-4 rounded-10'>
                    <h6 className='font-14 fw-bold d-block mb-0'>{refundDetail.details?.bank}</h6>
                  </div>
                </div>
                <div className='col-12 mb-0'>
                  <label className='font-14 fw-bold mb-3 text-black d-block'>
                    {t('Swift Code')}
                  </label>
                  <div className='data-contain px-5 py-4 rounded-10'>
                    <h6 className='font-14 fw-bold d-block mb-0'>
                      {refundDetail.details?.swift_code}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
