import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { RootState } from '../../../../../constants';

export function BankDetails(props: any) {

    let refundDetails: any
    refundDetails = useSelector<RootState>(({ PM }) => PM.refundData)
    return (
        <div className="">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-17 text-black fw-boldest mt-2 mb-8">
                        Bank Details
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="bank-details-finance">
                        <div className="row">
                            <div className="col-12 mb-6">
                                <label className="font-14 fw-bold mb-3 text-black d-block">
                                    Beneficiary's Name
                                </label>
                                <div className="data-contain px-5 py-4 rounded-10">
                                    <h6 className="font-14 fw-bold d-block mb-0">
                                        {refundDetails?.requiredData?.details?.name ? refundDetails?.requiredData?.details?.name : '-'}
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 mb-6">
                                <label className="font-14 fw-bold mb-3 text-black d-block">
                                    Beneficiary's Account Number
                                </label>
                                <div className="data-contain px-5 py-4 rounded-10">
                                    <h6 className="font-14 fw-bold d-block mb-0">
                                        {refundDetails?.requiredData?.details?.account_number ? refundDetails?.requiredData?.details?.account_number : '-'}
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 mb-6">
                                <label className="font-14 fw-bold mb-3 text-black d-block">
                                    IBAN Number
                                </label>
                                <div className="data-contain px-5 py-4 rounded-10">
                                    <h6 className="font-14 fw-bold d-block mb-0">
                                        {refundDetails?.requiredData?.details?.iban_number ? refundDetails?.requiredData?.details?.iban_number : '-'}
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 mb-6">
                                <label className="font-14 fw-bold mb-3 text-black d-block">
                                    Beneficiary's Bank Name
                                </label>
                                <div className="data-contain px-5 py-4 rounded-10">
                                    <h6 className="font-14 fw-bold d-block mb-0">
                                        {refundDetails?.requiredData?.details?.bank ? refundDetails?.requiredData?.details?.bank : '-'}
                                    </h6>
                                </div>
                            </div>
                            <div className="col-12 mb-0">
                                <label className="font-14 fw-bold mb-3 text-black d-block">
                                    Swift Code
                                </label>
                                <div className="data-contain px-5 py-4 rounded-10">
                                    <h6 className="font-14 fw-bold d-block mb-0">
                                        {refundDetails?.requiredData?.details?.swift_code ? refundDetails?.requiredData?.details?.swift_code : '-'}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}