import React, { FC, useEffect, useRef, useState, Component } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import Home from "../../../../../assets/images/my-unit.png";
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../constants';
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import moment from 'moment';

const Step3: FC = () => {
    const dispatch = useDispatch()
    let proprtyDetails: any
    proprtyDetails = useSelector<RootState>(({ PM }) => PM.proprtydetails)
    let propertyEditData: any
    propertyEditData = useSelector<RootState>(({ General }) => General.propertyEditData)

    const [dateValue, setDateValue] = useState(new Date());
    const [negotiable, setNegotiable] = useState(propertyEditData?.step3?.negotiable != null ? propertyEditData?.step3?.negotiable : (proprtyDetails?.data?.is_negotiable == null || proprtyDetails?.data?.is_negotiable == 0 || proprtyDetails?.data?.is_negotiable == 0 ? false : true))
    const [expectedRent, setExpectedRent] = useState(propertyEditData?.step3?.expectedRent != null ? propertyEditData?.step3?.expectedRent : parseFloat(proprtyDetails?.data?.price > 0 ? proprtyDetails?.data?.price : 0))
    const [minRent, setMinRent] = useState(propertyEditData?.step3?.minRent != null ? propertyEditData?.step3?.minRent : parseFloat(proprtyDetails?.data?.min_rent > 0 ? proprtyDetails?.data?.min_rent : 0))
    const [noOfPayments, setNoOfPayments] = useState(propertyEditData?.step3?.noOfPayments != null ? propertyEditData?.step3?.noOfPayments : parseFloat(proprtyDetails?.data?.no_of_payments) == null ? 1 : parseFloat(proprtyDetails?.data?.no_of_payments))
    const [serviceCharge, setServiceCharge] = useState(propertyEditData?.step3?.serviceCharge != null ? propertyEditData?.step3?.serviceCharge : (proprtyDetails?.data?.service_charge) == null ? 10 : parseFloat(proprtyDetails?.data?.service_charge))
    const [securityDeposit, setSecurityDeposit] = useState(propertyEditData?.step3?.securityDeposit != null ? propertyEditData?.step3?.securityDeposit : (proprtyDetails?.data?.security_deposit) == null ? '' : parseFloat(proprtyDetails?.data?.security_deposit))
    const [rentDate, setRentDate] = useState(propertyEditData?.step3?.rentDate != null ? propertyEditData?.step3?.rentDate : (proprtyDetails?.data?.availableFrom))


    const intl = useIntl()
    const location = useLocation()
    const [menu, setMenu] = useState('')
    const { t } = useTranslation();

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }
    const onChange = (checkedValues: any) => {
        setNegotiable(checkedValues.target.value)
    };
    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
        }
    })
    useEffect(() => {
        const step3Data = {
            negotiable: negotiable,
            expectedRent: expectedRent,
            minRent: minRent,
            noOfPayments: noOfPayments,
            serviceCharge: serviceCharge,
            securityDeposit: securityDeposit,
            rentDate: rentDate,
        }
        dispatch(General.actions.propertyEditData({
            step1: propertyEditData.step1,
            step2: propertyEditData.step2,
            step3: step3Data
        }));
    }, [expectedRent, minRent, noOfPayments, serviceCharge, securityDeposit, rentDate, negotiable])

    return (
        <>
            <div className="com-card-section w-100 p-7">
                <div className="row">
                    <div className="col-12">
                        <div className="card shadow-none">
                            <div className="card-body p-0">
                                <div className="row form-add-design">
                                    <div className="col-sm-6 col-md-4 col-12">
                                        <div className="mb-10">
                                            <div className="form-group">
                                                <label className="form-label fw-bolder w-100">
                                                    <span>{t("Target Rent")}</span>
                                                    <div className="form-check form-check-sm custom float-end">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={negotiable} onClick={(e) => setNegotiable(!negotiable)} defaultValue={negotiable} />
                                                        <label className="form-check-label font-12 text-black fw-normal" htmlFor="flexCheckDefault">
                                                            {t("Negotiable")}
                                                        </label>
                                                    </div>
                                                </label>
                                                <div className="d-flex fdrr-rtl">
                                                    <input type="text" name="" className="form-control expected-rent-input" placeholder={t("Enter")} value={expectedRent} disabled />
                                                    <select className="bg-lightgray expected-rent-selectbox font-14 ps-7" data-control="select2" data-allow-clear="true" >
                                                        <option value="" selected>{t("AED")}</option>
                                                        {/* <option value="">{t("Dollar")}</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-12">
                                        <div className="mb-10">
                                            <div className="form-group">
                                                <label className="form-label fw-bolder w-100">
                                                    {t("Minimum Rent")} %
                                                </label>
                                                <div className="d-flex fdrr-rtl">
                                                    <input type="text" name="" className="form-control expected-rent-input" placeholder={t("Enter")} value={minRent} onChange={(e) => setMinRent(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-12">
                                        <div className="mb-10 position-relative">
                                            <label className="form-label fw-bolder">{t("Max no of Payment")}</label>
                                            <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true" value={noOfPayments} onChange={(e) => setNoOfPayments(e.target.value)}>
                                                <option selected disabled>{t("Please Select")}</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Service Charge (VAT)")}</label>
                                            <input type="text" name="" className="form-control expected-rent-input" value={serviceCharge} disabled />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-12">
                                        <div className="mb-10 position-relative">
                                            <label className="form-label fw-bolder">{t("Security Deposit")}</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Security Deposit")} value={securityDeposit} disabled />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-12">
                                        <div className="mb-10 position-relative date-picker">
                                            <label className="form-label fw-bolder">{t("Available From Rent Date")}</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" value={rentDate != null ? moment(rentDate).format('DD-MM-YYYY') : ''} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Step3 }
