import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { ApprovalHistory } from "../IssueDetails/ApprovalHistory"

export function WorkInformation({ approvalData }: any) {
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row form-add-design mb-8">
                                        <div className="col-12">
                                            <h4 className="card-title mb-7 font-14 text-primary">Work Information</h4>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Work start date </h6>
                                            <span className="text-black fw-bold font-14"> 01 Feb 2022 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Work start time </h6>
                                            <span className="text-black fw-bold font-14"> 09:00 AM </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Work End date </h6>
                                            <span className="text-black fw-bold font-14"> 24 Fen 2022 </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Work end time </h6>
                                            <span className="text-black fw-bold font-14"> 09:00 AM </span>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12 mb-7">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Work Type </h6>
                                            <span className="text-black fw-bold font-14"> Fit out works </span>
                                        </div>
                                        <div className="col-lg-12 col-sm-12 col-12 my-10">
                                            <h6 className="text-black mb-5 fw-bolder"> Safety and Security Precautions </h6>
                                            <div className="d-flex">
                                                <div className="form-check custom me-5">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault1" />
                                                    <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault1">
                                                        PPEs revenant to the activity
                                                    </label>
                                                </div>
                                                <div className="form-check custom me-5">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault2" />
                                                    <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault2">
                                                        Fire Extinguisher
                                                    </label>
                                                </div>
                                                <div className="form-check custom">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault3" />
                                                    <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault3">
                                                        Safety Helmets
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="row form-add-design mb-8">
                                        <div className="col-12">
                                            <h4 className="card-title mb-7 font-14 text-primary">Work Information</h4>
                                        </div>
                                        <div className="col-lg-12 col-sm-12 col-12 mb-10 mt-0">
                                            <h6 className="text-black mb-5 fw-bolder">Permit Requirements</h6>
                                            <div className="row">
                                                <div className="col-sm-4 col-12">
                                                    <div className="form-check custom mb-7">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault1" />
                                                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault1">
                                                            Work with Lifting Appliance
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-12">
                                                    <div className="form-check custom mb-7">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault2" />
                                                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault2">
                                                            Scaffolding
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-12">
                                                    <div className="form-check custom mb-7">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault3" />
                                                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault3">
                                                            Hot Work
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-12">
                                                    <div className="form-check custom mb-7">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault4" />
                                                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault4">
                                                            Work at Height
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-12">
                                                    <div className="form-check custom mb-7">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault5" />
                                                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault5">
                                                            Helicopter landing / Take off
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13"> Comments </h6>
                                            <textarea
                                                className='form-control fw-bolder form-control-solid w-100'
                                                data-kt-autosize='true'
                                                rows={5}
                                                disabled
                                                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                             printer took a galley of type and scrambled it to make a type specimen book. It has survived
                                             not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                             It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
                                            />
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="col-12 fm-team-approvals">
                                    <div className="accordion approval-accordion" id="accordionExample">
                                        <RequestDetails />
                                        <ApprovalHistory />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}