import { Chart } from 'react-chartjs-2';

export function Analytics({ chartData }: any) {

    const data = {
        labels: [`Facebook 500 (33%)`,
            ` Twitter 350 (22%)`,
            ` Instagram 750 (45%)`],

        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 14,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },

                data: [chartData?.data?.data?.unit?.leased, chartData?.data?.data?.unit?.vacant, chartData?.data?.data?.unit?.underOffer],
                backgroundColor: [
                    '#ffa50f',
                    '#bdc3c7',
                    '#dbdfe2'
                ],
                borderColor: [
                    '#ffa50f',
                    '#bdc3c7',
                    '#dbdfe2'
                ],
                borderWidth: 1,
            },
        ]
    };

    const options = {
        plugins: {
            cutout: 70,
            legend: {
                display: true,
                position: 'right' as const,
                labels: {
                    color: '#000000',
                    lineDash: [12, 12],
                    boxWidth: 16,
                    boxHeight: 16,
                    padding: 15,
                    usePointStyle: true,

                    font: {
                        size: 10,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    }
                }
            },
        },
        maintainAspectRatio: true,
    };

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="unit-summary-sec mb-4">
                        <div className="">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12">
                                    <h4 className="font-14 fw-boldest text-black"> Analytics </h4>
                                </div>
                                 <div className="col-6 text-end">
                                    <select className="form-control form-select fw-bolder"
                                    >
                                        <option selected>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Quaterly</option>
                                        <option>Daily</option>
                                    </select>  
                                 </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="pm_unit_summary_cart">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="Doughnut-Chart text-center position-relative top-0" id="unitsumary">
                                                    <div >
                                                        <Chart type='doughnut' data={data} options={options} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}