import React, { FC, useEffect, useRef, useState, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Spinner } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { Step1 } from './components/steps/Step1'
import { Step2 } from './components/steps/Step2'
import { Step3 } from './components/steps/Step3'
import { Step4 } from './components/steps/Step4'
import { Step5 } from './components/steps/Step5'
import { Step6 } from './components/steps/Step6'
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../constants';
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { pmPropertyEdit } from '../../../constants/axios/apis';
import * as PTOffer from '../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { someThingWentWrong } from '../../../constants/generics/customActios';
import * as PM from '../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { useHistory } from 'react-router-dom'

const EditDetails: FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const refPropertyEdit = useRef<any>(0)
    let proprtyDetails: any
    proprtyDetails = useSelector<RootState>(({ PM }) => PM.proprtydetails)
    let propertyEditData: any
    propertyEditData = useSelector<RootState>(({ General }) => General.propertyEditData)

    const somethingWrg = someThingWentWrong()
    const [activeStep, setActiveStep] = React.useState(0);
    const [message, setMessage] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const { t } = useTranslation();


    const steps = [t("Property Details"),
    t("Locality Details"),
    t("Rent Details"),
    t("Amenities Details"),
    t("Property Image"),
    t("Property Video")];

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSubmit = () => {
        setLoading(true)
        var FormData = require('form-data')
        var propertyEditDetails = new FormData()
        propertyEditDetails.append('id_property', proprtyDetails?.data?.id);
        propertyEditDetails.append('title', propertyEditData?.step1?.title != null ? propertyEditData?.step1?.title : proprtyDetails?.data?.propretyName);
        propertyEditDetails.append('property_description', propertyEditData?.step1?.desc != null ? propertyEditData?.step1?.desc : proprtyDetails?.data?.propertyDescription);
        propertyEditDetails.append('longitude', parseFloat(propertyEditData?.step2?.long != null ? propertyEditData?.step2?.long : proprtyDetails?.data?.longitude));
        propertyEditDetails.append('latitude', propertyEditData?.step2?.lat != null ? propertyEditData?.step2?.lat : parseFloat(proprtyDetails?.data?.latitude));
        propertyEditDetails.append('is_negotiable', propertyEditData?.step3?.negotiable == 0 ? 0 : 1);
        propertyEditDetails.append('min_rent', propertyEditData?.step3?.minRent);
        propertyEditDetails.append('no_of_payments', propertyEditData?.step3?.noOfPayments);
        propertyEditData?.step1?.document != null && propertyEditData?.step1?.document != false && propertyEditDetails.append('cover_image', propertyEditData?.step1?.document);
        // propertyEditDetails.append('available_for', propertyEditData?.step1?.available != null ? propertyEditData?.step1?.available : proprtyDetails?.data?.isPropertyAvailable);
        // propertyEditDetails.append('property_type', propertyEditData?.step1?.propertyType != null ? propertyEditData?.step1?.propertyType : proprtyDetails?.data?.propertySearchType);
        // propertyEditDetails.append('bhk_type', propertyEditData?.step1?.bedRooms != null ? propertyEditData?.step1?.bedRooms : proprtyDetails?.data?.bedrooms);
        // propertyEditDetails.append('no_of_bedrooms', propertyEditData?.step1?.bedRooms != null ? propertyEditData?.step1?.bedRooms : proprtyDetails?.data?.bedrooms);
        // propertyEditDetails.append('no_of_bathrooms', propertyEditData?.step1?.bathRooms != null ? propertyEditData?.step1?.bathRooms : proprtyDetails?.data?.bathrooms);
        // propertyEditDetails.append('no_of_parking', '2');
        // propertyEditDetails.append('parking', propertyEditData?.step1?.parking != null ? propertyEditData?.step1?.parking : proprtyDetails?.data?.isParkingAvailable);
        // propertyEditDetails.append('property_size', propertyEditData?.step1?.size != null ? propertyEditData?.step1?.size : proprtyDetails?.data?.builtInArea);
        // propertyEditDetails.append('furnishing', propertyEditData?.step1?.furnish != null ? propertyEditData?.step1?.furnish : proprtyDetails?.data?.furnish);
        // propertyEditDetails.append('facing', propertyEditData?.step1?.facing != null ? propertyEditData?.step1?.facing : proprtyDetails?.data?.facing);
        // propertyEditDetails.append('property_age', propertyEditData?.step1?.age != null ? propertyEditData?.step1?.age : proprtyDetails?.data?.property_age);
        // propertyEditDetails.append('building_id', propertyEditData?.step2?.selectedBuilding);
        // propertyEditDetails.append('community_id', propertyEditData?.step2?.communityId);
        // propertyEditDetails.append('area', propertyEditData?.step2?.area != null ? propertyEditData?.step2?.area : proprtyDetails?.data?.area);
        // propertyEditDetails.append('location', propertyEditData?.step2?.locations != null ? propertyEditData?.step2?.locations : proprtyDetails?.data?.location);
        // propertyEditDetails.append('floor', propertyEditData?.step2?.floors != null ? propertyEditData?.step2?.floors : proprtyDetails?.data?.no_of_floors);
        // propertyEditDetails.append('expected_rent', propertyEditData?.step3?.expectedRent);
        // propertyEditDetails.append('service_charge', propertyEditData?.step3?.serviceCharge);
        // propertyEditDetails.append('security_deposit', propertyEditData?.step3?.securityDeposit);
        // propertyEditDetails.append('available_from', propertyEditData?.step3?.rentDate != null && moment(propertyEditData?.step3?.rentDate).format('YYYY-MM-DD'));
        // propertyEditDetails.append('amenities', propertyEditData?.step4?.amenities?.toString());
        // propertyEditDetails.append('media_upload[]', propertyEditData?.step5?.filesSelected != null && propertyEditData?.step5?.filesSelected);
        // propertyEditDetails.append('property_video[]', propertyEditData?.step6?.filesSelected != null && propertyEditData?.step6?.filesSelected);
        // propertyEditDetails.append('floor_plan', propertyEditData?.step1?.floorPlan != null && propertyEditData?.step1?.floorPlan);
        // propertyEditDetails.append('offered_price', '80000');
        pmPropertyEdit(propertyEditDetails).then((data) => {
            setLoading(false)
            if (data?.status === 200 && data?.data?.errorCode === 0) {
                dispatch(PM.actions.proprtydetails(proprtyDetails?.data?.id))
                setMessage({
                    error: 0,
                    message: 'Successful',
                })
                //  pathname: '/pm-availability/property-details',
                // state: { id: item?.id }
                history.push({
                    pathname: '/pm-availability/property-details',
                    state: { id: proprtyDetails?.data?.id }
                })
            } else if (data?.status > 200) {
                console.log('if else ')
                setMessage({
                    error: 1,
                    message: somethingWrg,
                })
            } else if (data?.data?.errorCode === 1) {
                setMessage({
                    error: 1,
                    message: data?.data?.errorDescription,
                })
            } else {
                setMessage({
                    error: 1,
                    message: somethingWrg,
                })
            }
        })
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const intl = useIntl()
    const location = useLocation()
    const [menu, setMenu] = useState('')

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }

    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
            console.log(window.location.pathname)
        }
    })

    useLayoutEffect(() => {
        if (loading) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
            document.body.style.pointerEvents = "none";
        }
        if (!loading) {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
            document.body.style.pointerEvents = "all";
        }
    }, [loading]);
    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
            refPropertyEdit.current.click();
        }
    }, [message])
    return (
        <><>
            <div className="com-card-section movein-page">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h4 className="card-title font-18 text-black mb-6">
                            <Link to={'/pm-availability/property-details'} className=''>
                                <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            </Link>
                            {proprtyDetails ? proprtyDetails?.data?.propretyName : t("Building Name")}
                        </h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card w-100">
                        <div className="card-body p-0">
                            <Stepper activeStep={activeStep} className="multiform-stepper">
                                {steps.map((label, index) => {
                                    const stepProps: { completed?: boolean; } = {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    // if (isStepOptional(index)) {
                                    //     labelProps.optional = (
                                    //         <p>Optional</p>
                                    //     );
                                    // }
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step className={activeStep == index ? "active" : ""} key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <React.Fragment>
                                <div>
                                    {activeStep === 0 ?
                                        <Step1 /> :
                                        activeStep === 1 ?
                                            <Step2 /> :
                                            activeStep === 2 ?
                                                <Step3 /> :
                                                activeStep === 3 ?
                                                    <Step4 /> :
                                                    activeStep === 4 ?
                                                        <Step5 /> :
                                                        <Step6 />}
                                </div>
                                <div className='d-flex flex-stack bg-white action-btns px-7 stepper-actions'>
                                    <div className='mr-2 col-md-2 col-sm-3 col-5 '>
                                        <button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className='btn btn-outline-secondary btn-lg px-10 py-3 w-100 btn-back'
                                        >
                                            {t("Back")}
                                        </button>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                        <button
                                            onClick={activeStep === 5 ? handleSubmit : handleNext}
                                            className='btn btn-primary fw-bold px-10 py-3 w-100'
                                            id="next-edit"
                                        > {loading && <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true" />}
                                            {activeStep === 5 ? t("Submit") : t("Next")}
                                        </button>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </><p ref={refPropertyEdit} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p></>

    )
};

export { EditDetails };
