import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { ApprovalHistory } from "../IssueDetails/ApprovalHistory";
import Sign from "../../../../../../assets/images/signature.png";

export function Undertaking({approvalData}:any) {
  return (
    <div className="com-card-section">
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body px-8 py-6">
              <div className="row form-add-design">
                <div className="col-12">
                  <div className="row form-add-design mb-8">
                    <div className="col-12">
                      <h4 className="card-title mb-7 font-14 text-primary">Request Details</h4>
                    </div>
                    <div className='fmteam-approval-udertaking m-4 mb-8 p-9 mt-0'>
                      <div className='col-12'>
                        <div className='row'>
                          <div className="col-12">
                            <h4 className="card-title mb-7 font-14">Application Under Taking</h4>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12 mb-7">
                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Tenant Name </h6>
                            <span className="text-black fw-bold font-14"> Mohammed </span>
                          </div>
                          <div className='col-12'>
                            <div className='row align-items-end'>
                              <div className="col-lg-4 col-sm-4 col-12">
                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> Signature </h6>
                                <span className="sign-box">
                                  <img src={Sign} className='img-fluid me-5' />
                                </span>
                              </div>
                              {/* <div className="col-lg-8 col-sm-8 col-12 text-end">
                                <button className='fw-bold text-primary border-0 bg-transparent'>
                                  Download <i className="bi bi-download ms-2"></i>
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 fm-team-approvals">
                  <div className="accordion approval-accordion" id="accordionExample">
                    <RequestDetails />
                    <ApprovalHistory />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}