import React, {useState, useEffect, useRef} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {
  getMasterData,
  getPropertiesCategoryList,
  getPropertiesCommunityList,
  updateBuilding, singleBuilding, detailBuilding
} from '../../../constants/axios/apis'
import {Select} from 'antd'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import MultipleImageUploadComponent from './imageUploader'
import {useHistory} from 'react-router-dom'
import {validateAndCompressImage} from '../../../constants/generics/fileUploadHandler'
import uploadImg from "../../../assets/images/file-upload.svg";
import { LoadingSubmitAlert } from '../../../constants/generics/customAlerts'
import { CustomLoader, CustomLoader1, CustomLoaderCompact } from '../../../constants/generics/customActios'
import { CustomRequestSuccessfulPopup } from '../../SuccessfulPopup/CustomRequestSuccessfulPopup'

export function EditBuilding() {
  const {t} = useTranslation()
  const history = useHistory()

      let params:any = {}
    params = useLocation();
    let referenceId = ''
    if(params?.state?.buildings?.reference_id)
    referenceId = params.state.buildings?.reference_id;
    const ID = params?.state?.buildings?.id;

    const buttonEl: any = useRef(null)

  const [buildingTypeList, setBuildingTypeList] = useState<any>()
  const [communityList, setCommunityList] = useState<any>([])
  const [categoryList, setCategoryList] = useState<any>([])
  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    isSubmitted: false,
    message: '',
  })
  const[loader, setLoader] = useState(false)
  const[error, setError] = useState('')
  const [uploading, setUploading] = useState(false)
  const {Option} = Select
  const inputRef = useRef<HTMLInputElement>(null)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const[buildingList, setBuildingList] = useState<any>()

  useEffect(() => {
    setLoader(true)
    getPropertiesCommunityList().then(({data}) => {
      setCommunityList(data?.data)
    })
    getMasterData().then(({data}) => {
      const buildingTypeList = data.find((el: any) => el.category_name === 'building_type')
      setBuildingTypeList(buildingTypeList?.master_data)
    })
    getPropertiesCategoryList().then(({data}) => {
      setCategoryList(data)
    })
    detailBuilding(`/${ID}`).then((response: any) => {
          setBuildingList(response.data);
  }).catch((e)=>{

  })
  setLoader(false)
  }, [])

  console.log(buildingList)


  const initialValues = {
    referenceId: buildingList?.reference_id,
    community_name: buildingList?.community_name,
    building_code: '',
    building_name: buildingList?.building_name,
    type: buildingList?.building_type,
    assignable_area_size_sqmt: '',
    status: buildingList?.status,
    cover_image: '',
    lease_area: '',
    start_date: '',
    end_date: '',
  }

  const onSubmit = (values: any) => {
    const formData = new FormData()
    formData.append('community_name', values.community_name)
    formData.append('building_code', values.building_code)
    formData.append('building_name', values.building_name)
    formData.append('type', values.type)
    formData.append('assignable_area_size_sqmt', values.assignable_area_size_sqmt)
    formData.append('status', values.status)
    formData.append('cover_image[]', values.cover_image)
    formData.append('lease_area', values.lease_area)
    formData.append('start_date', values.start_date)
    formData.append('end_date', values.end_date)

    setSubmitStatus((oStatus:any)=>({...oStatus, loading:true, error:false}))

    setTimeout(()=>{
      updateBuilding(formData, ID)
      .then((response: any) => {
        if (response.errorCode === 1) {
          setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
      }
        else if (response.errorCode === 0) {
          setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.data, isSubmitted: true}))
          setTimeout(() => {
            formik.resetForm()
            // history.push("/building/list")
          }, 1000)
          
          var ev = new Event('click', {bubbles: true})
          buttonEl.current.dispatchEvent(ev)
        } else
          setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
      })
      .catch((e) => {
        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
      })
    })
  }

  const resetSubmit = ()=>{
      formik.resetForm()
  }

  const validationSchema = Yup.object({
    community_name: Yup.string().required(t('Select a community_name!')),
    building_name: Yup.string().required(t('Select a building_name!')),
    building_code: Yup.string().required(t('Select a building_code')),
    type: Yup.string().required(t('Select category!')),
    assignable_area_size_sqmt: Yup.string().required(t('Select total_sqft!')),
    lease_area: Yup.string().required(t('Select  leasable_area')),
    start_date: Yup.string().required(t('Select start_date!')),
    end_date: Yup.string().required(t('Select end_date!')),
    status: Yup.string().required(t('Select Status')),
    cover_image: Yup.string().required(t('Select image')),

  })


  const handleUpload = () => {
    inputRef.current?.click()
    formik.setFieldValue('cover_image', '')
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('cover_image', undefined)
    try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
            if (compressResponse?.success) {
                inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
                formik.setFieldTouched('cover_image')
                formik.setFieldValue('cover_image', compressResponse.file)
            } else {
                setUploadedFileName(null)
                formik.setFieldTouched('cover_image', true, false)
                formik.setFieldValue('cover_image', '', false)
                formik.setFieldError('cover_image', t(compressResponse?.message || 'File compression error!'))
            }
            setUploading(false)
        })
    } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('cover_image')
        formik.setFieldValue('cover_image', '', false)
        formik.setFieldError('cover_image', t('File upload error!'))
        setUploading(false)
    }
    // inputRef.current?.files &&
    //     setUploadedFileName(inputRef.current.files[0].name);

    // formik.setFieldTouched('cover_image')
    // formik.setFieldValue('cover_image', e.currentTarget.files[0])
};


  // const handleSelect = (val: any) => {
  //   // formik.handleChange(e)
  //   formik.setFieldValue('community_id', val)

  //   // setSelectedSubCategory(subCategories.find((item: any) => item.id == val))
  //   // formik.setFieldValue(
  //   //   'category_id',
  //   //   subCategories.find((item: any) => item.id == val).category_id
  //   // )
  //   formik.setFieldValue('building_name', '')
  //   formik.setFieldTouched('building_name', false)
  // }


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,   
     onSubmit,
  })



  return (
    <>
    {loader ? (
      <>
            <div className='row form-add-design'>
						<div className='col-lg-6 col-12 w-100 h-20'>
							<div
								className='mb-10 h-20 d-flex justify-content-center align-items-center'
								style={{ height: '500px' }}
							>
								<CustomLoaderCompact/>
							</div>
						</div>
					</div>
      </>
    )
  : (
    <>
      <div className='row align-items-center mb-5 community'>
        <div className='col-md-5'>
          <h5 className='theme-color font-22 d-inline-block float-left'>
            <Link
              to={'list'}
              className='back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block'
            >
              <i className='fa fa-angle-left font-20 font-weight-bold'></i>
            </Link>
            Update Building
          </h5>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-md-12'>
          <form
            action=''
            method='POST'
            name='add_building'
            id='add_building'
            onSubmit={formik.handleSubmit}
          >
            <div className='card form-add-design'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>Code</label>
                      <input
                        type='text'
                        className='form-control font-13 form-height'
                        name='code'
                        placeholder='Code'
                        value={formik.values.referenceId}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>
                        Select Community
                        <span className="text-danger"> * </span>
                      </label>
                      <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder={t('Select a community!')}
                        optionFilterProp='children'
                        //   defaultValue={
                        //     formik.values.id !== '' ? formik.values.id : null
                        //   }
                        //   onChange={(val) => formik.setFieldValue('id', val)}
                        //   onBlur={formik.handleBlur}
                        value={
                          formik.values.community_name !== '' ? formik.values.community_name : null
                        }
                        // onChange={handleSelect}
                        onChange={(val) => {
                          formik.setFieldValue('community_name', val)
                        }}
                            filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {communityList?.map((el: any, index: number) => (
                          <Option key={index} value={el.id}>
                            {el.community}
                          </Option>
                        ))}

                      </Select>
                      {formik.touched.community_name && formik.errors.community_name ? (
                                                    <small className="text-danger">
                                                        {formik.errors.community_name}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>
                        Building/Precinct Name
                        <span className="text-danger"> * </span>
                      </label>
                      <input
                        type='text'
                        className='form-control font-13 form-height'
                        {...formik.getFieldProps('building_name')}
                        placeholder='Building/Precinct Name'
                      />
                      {formik.touched.building_name && formik.errors.building_name ? (
                                                    <small className="text-danger">
                                                        {formik.errors.building_name}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>
                        Building/Precinct Type
                        <span className="text-danger"> * </span>
                      </label>
                      <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder='Please Select'
                        optionFilterProp='children'
                        //   value={
                        //     formik.values.visit_purpose !== '' ? formik.values.visit_purpose : null
                        //   }
                        //   onChange={(val) => {
                        //     formik.setFieldValue('visit_purpose', val)
                        //   }}
                        //   onBlur={formik.handleBlur}
                        value={
                          formik.values.building_code !== '' ? formik.values.building_code : null
                        }
                        onChange={(val) => {
                          formik.setFieldValue('building_code', val)
                        }}
                        onBlur={formik.handleBlur}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {buildingTypeList?.map((el: any, index: number) => (
                          <Option key={index} value={el.value}>
                            {el.name}
                          </Option>
                        ))}
                        
                      </Select>
                      {formik.touched.building_code && formik.errors.building_code ? (
                                                    <small className="text-danger">
                                                        {formik.errors.building_code}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>Category
                      <span className="text-danger"> * </span></label>
                      <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder='Please Select'
                        optionFilterProp='children'
                        //   defaultValue={
                        //     formik.values.id !== '' ? formik.values.id : null
                        //   }
                        //   onChange={(val) => formik.setFieldValue('id', val)}
                        //   onBlur={formik.handleBlur}
                        value={formik.values.type !== '' ? formik.values.type : null}
                        onChange={(val) => {
                          formik.setFieldValue('type', val)
                        }}
                        onBlur={formik.handleBlur}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {categoryList?.map((el: any, index: number) => (
                          <Option key={index} value={el.master_data_name}>
                            {el.master_data_name}
                          </Option>
                        ))}
                      </Select>
                      {formik.touched.type && formik.errors.type ? (
                                                    <small className="text-danger">
                                                        {formik.errors.type}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>
                        Total Sq.ft
                        <span className="text-danger"> * </span>
                      </label>
                      <input
                        type='text'
                        className='form-control font-13 form-height convertThis'
                        placeholder='Total Sq.ft'
                        {...formik.getFieldProps('assignable_area_size_sqmt')}
      
                      />
                      {formik.touched.assignable_area_size_sqmt && formik.errors.assignable_area_size_sqmt ? (
                                                    <small className="text-danger">
                                                        {formik.errors.assignable_area_size_sqmt}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>
                        Total Leasable Area
                        <span className="text-danger"> * </span>
                      </label>
                      <input
                        type='number'
                        className='form-control font-13 form-height convertThis'
                        placeholder='Total Leasable Area'
                        {...formik.getFieldProps('lease_area')}
                      />
                      {formik.touched.lease_area && formik.errors.lease_area ? (
                                                    <small className="text-danger">
                                                        {formik.errors.lease_area}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>
                        Start Date
                        <span className="text-danger"> * </span>
                      </label>
                      <input
                        type='date'
                        className='form-control font-13 form-height'
                        {...formik.getFieldProps('start_date')}
                        placeholder='Start Date'
                      />
                      {formik.touched.start_date && formik.errors.start_date ? (
                                                    <small className="text-danger">
                                                        {formik.errors.start_date}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>End Date
                      <span className="text-danger"> * </span></label>
                      <input
                        type='date'
                        className='form-control font-13 form-height'
                        {...formik.getFieldProps('end_date')}
                        placeholder='End Date'
                      />
                      {formik.touched.end_date && formik.errors.end_date ? (
                                                    <small className="text-danger">
                                                        {formik.errors.end_date}
                                                    </small>
                                                ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3 col-sm-6'>
                    <div className='mb-7'>
                      <label className='d-block w-100 text-uppercase mb-0 font-13'>Status
                      <span className="text-danger"> * </span></label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        {...formik.getFieldProps('status')}
                        id=''
                        data-live-search='true'
                        title='Select Status'
                      >
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </select>
                      {formik.touched.status && formik.errors.status ? (
                                                    <small className="text-danger">
                                                        {formik.errors.status}
                                                    </small>
                       ) : null}
                    </div>
                  </div>

                  <div className='col-md-12 col-12'>
                    <div className='row'>
                      <div className='col-12'>
                        <label className='d-block text-uppercase mb-0 font-13'>
                          {' '}
                          Building Image{' '}
                        </label>
                      </div>
                    </div>
                    {/* <MultipleImageUploadComponent /> */}
                    <div className='mb-10 upload-input d-inline-block'>

                    <input
                        ref={inputRef}
                        onChange={(e) => handleDisplayFileDetails(e)}
                        className='d-none'
                        type='file'
                        accept=".png,.jpg,.jpeg"
                        multiple
                        // onBlur={formik.handleBlur}
                      />
                      <button
                        type='button'
                        onClick={handleUpload}
                        disabled={uploading}
                        className={`w-100 py-3 ellipsis btn btn-outline-${
                          uploadedFileName ? 'primary' : 'primary'
                        }`}
                      >
                        <span>
                          {
                            uploading ?
                              <span className='indicator-progress d-block'>
                                {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                              :
                              uploadedFileName ? (
                                uploadedFileName
                              ) : (
                          <span className="txt">
                            <img
                                    alt="File"
                                    src={uploadImg}
                            className="position-relative"
                            loading="lazy"/>
                                  {t("Upload")}{' '}
                                  <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                </span>
                              )}{' '}
                        </span>
                      </button>
                    </div>
                    
                  </div>
                  {formik.touched.cover_image && formik.errors.cover_image ? (
                                                    <small className="text-danger">
                                                        {formik.errors.cover_image}
                                                    </small>
                       ) : null}
                  <div className='col-12'>
                    <div className='text-end mt-5'>

                      <button
                        type='reset'
                        className='btn btn-outline-primary fw-bold px-10 py-3 me-3'
                        onClick={resetSubmit}
                      >
                        Reset
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary fw-bold px-10 py-3'
                      disabled={submitStatus.isSubmitted || submitStatus.loading || uploading}
                      >
                        Update Building
                      </button>
                      <p
            ref={buttonEl}
            data-bs-target='#req-submitted-popup'
            data-bs-toggle='modal'
            data-bs-dismiss='modal'
            className='invisible d-none btn btn-primary fw-bold px-10 py-3'
          />
                      <CustomRequestSuccessfulPopup
                          successMessage={submitStatus.message}
                          href={'/building/list'}
                        />
                    </div>
                  </div>
                </div>
                <LoadingSubmitAlert submitStatus={submitStatus}/>
              </div>
            </div>
          </form>
        </div>
      </div>
      </>
      )}
    </>
  )
}
