import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { Signature } from './Signature'

import {
    // getMaintenanceRequestsCategories,
    getServiceRequestsCategories,
    // getMasterData,
    getPreciseLocations,
    createClearanceCharge,
} from '../../../../../../constants/axios/apis'
import { AddInspectionForm } from './AddInspectionForm';
import { Table } from './Table';
import { ClearanceWorkorderBasicDetails } from '../ClearanceWorkorderBasicDetails';
import { useTranslation } from 'react-i18next';

export function MoveOutInspection({setPopupSuccessMsg}:any) {
    const { t } = useTranslation();

    const history = useHistory();
    // const intl = useIntl()
    // const location = useLocation<any>()
    let params: any = {};
    params = useLocation();
    const { workOrder } = params.state;

    const [menu, setMenu] = useState('')
    const [jobStarted, setjobStarted] = useState(true)
    const [addingInspection, setAddingInspection] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [loading, setLoading] = useState(false)
    const sigCanvas = useRef<any>();
    const sigCanvasEmpty = useRef<any>();
    const [sigError, setSigError] = useState(false)
    const [termError, setTermError] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const [submitSuccessMsg, setSubmitSuccessMsg] = useState('')
    // const [imageURL, setImageURL] = useState('')
    const [fieldValue, setFieldValue] = useState<any>()

    const [serviceCategoryData, setServiceCategories] = useState<any>()
    const [serviceCategoryID, setServiceCategoryID] = useState<any>()
    const [selectedInspectionData, setSelectedInspection] = useState<any>()
    const [precise_locations, setPreciseLocations] = useState<any>()
    const [inspectionDataList, setInspectionData] = useState<any>([])
    // const PROPERTY_ID = workOrder?.propertyId
    const successRef:any = useRef(null);

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }
    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
            // console.log(window.location.pathname)
        }
        // getMaintenanceRequestsCategories().then(({ data: categoriesData }) => {
        //     categoriesData = categoriesData?.filter((category: any) => category?.categoryName === 'Indoor')
        //     const serviceCategoryData = categoriesData?.map((cat: any) => cat?.service_category_types).flat()
        //     setServiceCategories(serviceCategoryData)
        // })
        getServiceRequestsCategories().then(({ data: categoriesData }) => {
            categoriesData = categoriesData?.find((category: any) => category?.categoryCode === 'CC')
            // console.log(categoriesData, 'categoriesData');
            // const serviceCategoryData = categoriesData?.map((cat: any) => cat?.service_category_types).flat()
            const serviceCategoryData = categoriesData?.service_category_types;
            setServiceCategories(serviceCategoryData)
            setServiceCategoryID(categoriesData.id)
            // console.log(serviceCategoryData, 'serviceCategoryData');
            
        })
        getPreciseLocations(workOrder?.propertyId).then(({ data: preciseLocationList }) => {
            setPreciseLocations(preciseLocationList['preciseLocationList'])
        })
    }, [workOrder])
    useEffect(() => {
        if (inspectionDataList?.length === 0)
            setAddingInspection(true)
    }, [inspectionDataList])
    // useEffect(() => {
    //     if (inspectionDataList?.length === 0 && addingInspection === false)
    //         history.goBack()
    // }, [inspectionDataList, addingInspection])
    
    useEffect(() => {
        if (termError)
            setTermError(false)
    }, [acceptTerms])

    // const handleStart = () => {
    //     setjobStarted(true)
    //     setAddingInspection(true)
    // }
    const handleAddInspection = () => {
        setSelectedInspection(null)
        setAddingInspection(true)
    }
    const handleCancel = () => {
        setAddingInspection(false) //TODO
        setSelectedInspection(null)
        if (inspectionDataList?.length === 0){
            history.goBack()
        }
    }
    const handleDelete = (inspection_no: any) => {
        let updatedInspectionData = [...inspectionDataList].filter((inspection: any) => inspection.no !== inspection_no)
        setInspectionData(updatedInspectionData)
    }
    const handleEdit = (inspection_no: any) => {
        let selectedInspection = inspectionDataList.find((inspection: any) => inspection.no === inspection_no)
        // console.log(selectedInspection, 'selectedInspection');
        
        setSelectedInspection(selectedInspection)
        setIsEdit(true)
        setAddingInspection(true)
    }
    const handleFormSave = (formData: any) => {
        if (isEdit) {
            const updatedInspectionData = [...inspectionDataList].map((inspection: any) => {
                if (inspection.no === selectedInspectionData.no) {
                    return {
                        no: selectedInspectionData.no,
                        category_type_id: formData?.category,
                        room_id: formData?.precise_location,
                        qty: formData?.quantity,
                        description: formData?.description,
                        image: formData?.mediaUpload,
                    }
                } else
                    return inspection
            })
            setInspectionData(updatedInspectionData)
            setAddingInspection(false)
            setIsEdit(false)
            setSelectedInspection(null)
        } else {
            const updatedInspectionData = [...inspectionDataList, {
                no: inspectionDataList ? inspectionDataList.length + 1 : 1,
                category_type_id: formData?.category,
                room_id: formData?.precise_location,
                qty: formData?.quantity,
                description: formData?.description,
                image: formData?.mediaUpload,
            }]
            setInspectionData(updatedInspectionData)
            setAddingInspection(false)
            setSelectedInspection(null)
        }

    }
    const handleSign = (blob: any) => { 
        setFieldValue(blob)
    }
    function submitInspectionData(){
            setLoading(true)
            setSubmitError('')
            const formData = new FormData()
            // formData.append('id', '') //Not needed, required use only for edititing a clearance charge data
            formData.append('work_order_id', workOrder.id)
            formData.append('customer_signature', fieldValue, 'signature.png');
            // console.log(inspectionDataList.length, 'inspectionDataList length');
            
            inspectionDataList.forEach((inspection:any, index: number) => {
                // console.log(inspection.image, 'inspection.image', index);
                
                formData.append('ccharge['+index+'][image]', inspection.image);
                formData.append('ccharge['+index+'][desc]', inspection.description);
                formData.append('ccharge['+index+'][precise_location]', inspection.room_id);
                formData.append('ccharge['+index+'][quantity]', inspection.qty);
                formData.append('ccharge['+index+'][category]', serviceCategoryID);
                formData.append('ccharge['+index+'][category_type_id]', inspection.category_type_id);
            });

            setTimeout(() => {
                createClearanceCharge(formData)
                    .then((response: any) => {
                        setLoading(false)
                        if (response.errorCode === 1) {
                            setSubmitError(response.errorDescription)
                            setTimeout(() => {
                                setSubmitError('')
                            }, 5000)
                        } else if(response.errorCode === 0) {
                            // console.log('Submit response', response)
                            setPopupSuccessMsg(response.description)
                            setSubmitSuccessMsg(response.description)
                            successRef.current.click();
                            setTimeout(() => {
                                history.push("/facility-workorder/list/clearance/confirmed")
                                // window.location.replace("/fc-workorder/clearance/confirmed");
                            }, 500)
                        } else{
                            setSubmitError(t("Some error occured!"))
                            setTimeout(() => {
                                setSubmitError('')
                            }, 5000)
                        }
                    })
                    .catch((e) => {
                        console.log(e, 'Error');
                        setLoading(false)
                        setSubmitError(t('Some error occured!'))
                        setTimeout(() => {
                            setSubmitError('')
                        }, 5000)
                        // setSubmitting(false)
                        // setStatus('Invalid Data Provided')
                    })
            }, 1000)
    }

    const handleSubmit = () => {
        const signURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        const emptySign = sigCanvasEmpty.current.getTrimmedCanvas().toDataURL("image/png")

        let isError = false
        if (signURL === emptySign) {
            isError = true
            setSigError(true)
        }
        else {
            setSigError(false)
        }
        if (!acceptTerms) {
            isError = true
            setTermError(true)
        }
        if (!isError && inspectionDataList?.length)
            submitInspectionData()

    }

    // console.log(jobStarted, addingInspection, acceptTerms);

    return (
        <>
            <ClearanceWorkorderBasicDetails workOrder={workOrder} setPopupSuccessMsg={setPopupSuccessMsg} compactView={true}/>
            {/* {!jobStarted && workOrder &&
                <div className="col-12 text-end">
                    <button className='btn btn-primary fw-bold px-10 py-3'
                        onClick={handleStart} >
                        Start Job
                    </button>
                </div>
            } */}
            {jobStarted && addingInspection &&
                // Add Inspection Form
                <AddInspectionForm selectedInspectionData={selectedInspectionData}
                    serviceCategoryData={serviceCategoryData}
                    precise_locations={precise_locations}
                    handleFormSave={handleFormSave}
                    handleCancel={handleCancel}
                    addedDataList={inspectionDataList} />
            }
            {jobStarted && !addingInspection &&
                // Inspection Details
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row align-items-center mb-5">
                                <div className="col-6">
                                    <h4 className="card-title font-16 text-black mb-0">
                                        {t("Inspection Details")}
                                    </h4>
                                </div>
                                <div className="col-6 text-end">
                                    {/* <button 
                                        data-bs-toggle="modal" data-bs-target="#AddPricingModal"
                                        className="btn btn-gray-btn fw-bold px-5 py-3 border-0">
                                        <i className="bi bi-plus-circle me-2 font-17"></i> ADD MORE
                                    </button> */}

                                    <button
                                        onClick={handleAddInspection}
                                        type='button' className='btn btn-outline-primary fw-bold px-10 py-3 bg-white'>
                                        <i className="bi bi-plus-circle me-2 font-17" style={{ color: "inherit" }}></i> {t("ADD MORE")}
                                    </button>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <Table inspectionDataList={inspectionDataList}
                                    serviceCategoryData={serviceCategoryData}
                                    precise_locations={precise_locations}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit} />
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <Signature sigCanvas={sigCanvas} sigCanvasEmpty={sigCanvasEmpty} sigError={sigError}
                                        handleSign={handleSign} />
                                </div>
                                <div className="col-md-8 col-12">
                                    <div className="d-flex align-items-center float-end mt-20">
                                        <div className="form-check form-check-sm custom me-5">
                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={acceptTerms} onChange={() => { setAcceptTerms(old => !old) }} />
                                            <label className="form-check-label font-12 fw-normal" htmlFor="flexCheckDefault">
                                                {t("I Accept Terms and Conditions")}
                                            </label>
                                            {termError &&
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{t("Please accept the terms and conditions.")}</small>
                                                </div>
                                            }
                                        </div>
                                        {loading ?
                                            <button
                                                type='submit' className='btn btn-primary fw-bold px-15 py-3' disabled>
                                                <span className='indicator-progress' style={{display: 'block'}}>
                                                {t("Please wait...")}{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            </button>
                                        :   
                                            <>
                                                { submitSuccessMsg !== '' ?
                                                    <button type="button" className="btn btn-primary fw-bold px-15 py-3" disabled >
                                                        {t("Submit")}
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-primary fw-bold px-15 py-3"
                                                        onClick={handleSubmit}
                                                    >
                                                        {t("Submit")}
                                                    </button>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <p
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='invisible d-none'
                />
        </>
    );
}