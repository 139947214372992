import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { useTranslation } from 'react-i18next'

const EditOffer: React.FC = () => {
    const location = useLocation();
    const intl = useIntl();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-6">
                        <div className="col-12">
                            <h4 className="card-title font-18 text-black mb-0">
                                <Link to={'/pm-offers-and-renewals/details-page'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {t("Edit Property Details")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body px-8 py-8">
                            <div className="row form-add-design">
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Start Date")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="29-Apr-2020" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Number of Payments")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="02" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Unit Number")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="U-534" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Building")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Abu dhabi - 08" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Unit Area")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="71.1sqm" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Security Deposit")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="4,853" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Annual Rent")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="55,000" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("No. of Payment")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("No. of Parking Allowed")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Classification")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="CHM" />
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <button className='btn btn-outline-primary fw-bold px-10 py-3 me-4'>
                                        {t("Cancel")}
                                    </button>
                                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3'
                                        onClick={(e) => history.push('/pm-offers-and-renewals/update-offer')}
                                    >
                                        {t("Update Offer")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export {EditOffer}
