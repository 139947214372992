import React, { useState, useEffect, useRef } from 'react'
import Propertie from '../../../../assets/images/latest-one.png'

export function ViewPopup(props: any) {
    return (
        <div className='modal fade add-broker-modal' aria-hidden='true' id='view-popUpreschedule'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content p-5'>
                    <div className='modal-header p-0 mb-5'>
                        <h4 className="modal-title">{props?.original_name}</h4>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        ></button>
                    </div>
                    <div className='modal-body px-0 pb-0 pt-0'>
                        <div className=''>
                            <div className='row'>
                                {/* {props?.viewImage.includes('pdf') ? <iframe src={props?.viewImage} style={{ width: '600px', height: '500px' }} ></iframe> :
                  <img src={props?.viewImage} className='img-fluid va-baseline' />} */}
                                {props?.viewImage?.includes('pdf') ? <iframe src={props?.viewImage} style={{ width: '600px', height: '500px' }} ></iframe> :
                                    <img src={props?.viewImage} style={{ width: '100%', height: '20%' }} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
