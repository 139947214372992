import React, {useState, useEffect} from 'react'
import Loader from '../../../../../components/Loader'
import {getFacilityUserList} from '../../../../../constants/axios/apis'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import Avatar from '../../../../../assets/images/DefaultUser.png'
// import { DirectoryPopup } from '../DirectoryPopup/DirectoryPopup'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../constants'
import {CustomLoader2, NoDataFound1} from '../../../../../constants/generics/customActios'
import {CompactPagination} from '../../../../../components/partials/content/CompactPagination/CompactPagination'
import { getUserRole } from '../../../../../constants/generics/userCheck'
import { useTranslation } from 'react-i18next';

export function ResidentsList({searchItem, setSearchItem}: any) {
  const { t } = useTranslation();
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )

  const state: any = useSelector((state) => state)
  const user_role = getUserRole(state?.auth?.user?.data)

  const pageSize = 10
  const [residentsList, setResidentsList] = useState<any>([])
  // const [selectedUser, setSelectedUser] = useState<any>()
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalresidentsList, setTotalresidentsList] = useState<any>(0)

  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding])

  useEffect(() => {
    setLoading(true)
    const newPayload = '?currentPage=' + currentPage + '&pageSize=' + pageSize + '&id_sort=1' +'&search=' + searchItem +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0') + 
      '&type=residents' +
      (user_role === 'FCSS' ? '&user_search_type=commercial_user' : '')
    getFacilityUserList(newPayload)
      .then((response: any) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setResidentsList(response.data)
          setTotalresidentsList(response.dataCount)
          setLoading(false)
        } else {
          setResidentsList([])
          setTotalresidentsList(0)
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [currentPage, currentBuilding,searchItem])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
  }

  const residentsData = residentsList?.map((data: any, index: any) => (
    // <tr key={data.id}>
    <tr
      key={data.id}
      // style={{ cursor: 'pointer' }}
      // data-bs-toggle='modal'
      // data-bs-target='#parking-popup-modal'
      // onClick={() => setSelectedUser(data)}
    >
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td className='ellipsis'>
        <div className=''>
          <img
            src={data.profile_img ? data.profile_img : Avatar}
            width='32'
            height='32'
            className='img-fluid me-2 rounded-circle'
          />
        </div>
      </td>
      <td>{data.first_name ? data.first_name + ' ' + data.last_name : '--'}</td>
      {/* <td>{data.resident_type ? data.resident_type : '--'}</td> */}
      <td>{data.property_type ? data.property_type : '--'}</td>
      <td>{data.building_name ? data.building_name : '--'}</td>
      <td>{data.property_code ? data.property_code : '--'}</td>
      <td>{data.mobile ? data.mobile : '--'}</td>
      {/* <td>{data.email ? data.email : "--"}</td> */}
      <td>
        <Link
          to={{pathname: '/directory/residents-deatils', state: {user: data}}}
          className='text-primary'
        >
          {t("View")}
        </Link>
      </td>
    </tr>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body p-0'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    {loading ? (
                      <div className='text-center pb-7'>
                        <CustomLoader2 />
                      </div>
                    ) : residentsList && residentsList.length > 0 ? (
                      <div className='table-responsive'>
                        <table className='table align-middle table-striped gy-3 gx-3 mb-0 fcss-table'>
                          <thead>
                            <tr>
                              <th className=''> {t("No.")} </th>
                              <th className=''> {t("Image")} </th>
                              <th className=''> {t("Name")}</th>
                              <th className=''> {t("Resident Type")}</th>
                              <th className=''> {t("Building")} </th>
                              <th className=''> {t("Unit")} </th>
                              <th className=''> {t("Mobile Number")} </th>
                              {/* <th className=''> Email </th> */}
                              <th className=''> {t("Action")} </th>
                            </tr>
                          </thead>
                          <tbody>{residentsList ? residentsData : ''}</tbody>
                        </table>
                        <div className='w-100 d-flex justify-content-end my-5'>
                          {/* <Stack spacing={2}>
                            <Pagination
                              defaultPage={currentPage}
                              count={
                                totalresidentsList % pageSize === 0
                                  ? totalresidentsList / pageSize
                                  : Math.ceil(totalresidentsList / pageSize)
                              }
                              onChange={handlePageChange}
                            />
                          </Stack> */}
                          <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalresidentsList)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                          />
                        </div>
                      </div>
                    ) : (
                      <NoDataFound1 />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DirectoryPopup user={selectedUser} /> */}
    </div>
  )
}
