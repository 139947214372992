import React from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { AssignWorkOrderPopup } from '../AssignWorkOrderPopup';
import { useTranslation } from 'react-i18next';
import { WorkorderReschedulePopup } from './WorkorderReschedulePopup/WorkorderReschedulePopup';

export function CorrectiveWorkorderActions({workOrder, setPopupSuccessMsg}:any) {
    const { t } = useTranslation();

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
 
    const isOnHold = workOrder.status === 'On hold'
    const allowAssign = workOrder.is_FMapproved === 1
    const isAssignedUser = state?.auth?.user?.data.id === workOrder.assignedToUserId
    const isPending = workOrder.is_FMapproved === 0 || workOrder.is_FCSreassigned === 0

    return (
        <div className="row form-add-design">
            <div className="col-12 text-end">
                <div className="row justify-content-end">
                    { isAssignedUser &&
                        <>
                            { workOrder.status === 'Open' &&
                                <>
                                    <div className="col-lg-3 col-md-4 col-12">
                                        <button
                                            data-bs-target="#ReschedulePopup"
                                            className="btn btn-outline-primary fw-bold px-10 py-3 mb-4 mb-md-0 btn-block"
                                            data-bs-toggle="modal"
                                            type="button"
                                        >
                                            {t("Tenant Not Availabile")}
                                        </button>
                                    </div>
                                    <WorkorderReschedulePopup
                                        workOrder={workOrder}
                                        isOnHold={false}
                                        title={t('Tenant Not Available - Reschedule')}
                                        setPopupSuccessMsg={setPopupSuccessMsg}
                                    />
                                </>
                            }
                            { workOrder.status === 'Inprogress' &&
                                <div className="col-lg-2 col-md-3 col-12">
                                    <Link to={{ 'pathname': '/facility-workorder/check-list', state: { 'workOrder': workOrder } }}>
                                        <button
                                            className='btn btn-primary fw-bold px-10 py-3 mb-4 mb-md-0 btn-block'>
                                            {t("Start Job")}
                                        </button>
                                    </Link>
                                </div>
                            }
                            { workOrder.status === 'On hold' &&
                                <>
                                    <div className="col-lg-4 col-md-5 col-12">
                                        <button
                                            data-bs-target="#ReschedulePopup"
                                            className="btn btn-primary fw-bold px-md-10 py-3 mb-4 mb-md-0 btn-block"
                                            data-bs-toggle="modal"
                                            type="button"
                                            disabled={isPending}
                                        >
                                            {t("Reschedule / Book Appointment")}
                                        </button>
                                    </div>
                                    <WorkorderReschedulePopup
                                        workOrder={workOrder}
                                        isOnHold={true}
                                        title={t('Reschedule / Book Appointment')}
                                        setPopupSuccessMsg={setPopupSuccessMsg}
                                        />
                                </>
                            }
                        </>
                    }
                    {(user_role === 'FCS' && (workOrder.status === "Open" || workOrder.status === "On hold")) ?
                        <>
                            <div className="col-lg-2 col-md-3 col-12">
                                <button data-bs-target="#assign-popup"
                                    data-bs-toggle="modal" data-bs-dismiss="modal"
                                    type='button'
                                    disabled={isOnHold && !allowAssign}
                                    // disabled={!isValidBuilding || (isOnHold && !allowAssign)}
                                    className={isAssignedUser ?
                                        'px-10 py-3 corrective_table_popup_bt btn btn-primary btn-block'
                                        : 'px-10 py-3 corrective_table_popup_bt btn btn-primary btn-block' }
                                    >
                                    {(workOrder?.assignedTo?.length > 0)? t('Re-Assign') : t('Assign')}
                                </button>
                            </div>
                            <AssignWorkOrderPopup
                                workOrder={workOrder}
                                setPopupSuccessMsg={setPopupSuccessMsg}
                            />
                        </>
                        :''
                    }
                </div>
            </div>
        </div>
    );
}