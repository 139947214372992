import React, { FC, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DynamicPopup } from '../../../../pages/SuccessfulPopup/DynamicPopup';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { rateUs } from '../../../../constants/axios/apis'

export function FeedbackDrawer() {
    const ref = useRef<any>(0)
    const dispatch = useDispatch()
    const [message, setMessage] = useState<any>()
    const [show, setShow] = useState<any>(false)
    const [workmanship, setWorkmanship] = useState<any>()
    const [punctuality, setPunctuality] = useState<any>()
    const [qualityofwork, setQualityofwork] = useState<any>()
    const [comment, setComment] = useState<any>()

    const handleSubmit = () => {
        setShow(true)
        var Reviewdata = ({
            "workmanship": workmanship,
            "punctuality": punctuality,
            "quality_of_work": qualityofwork,
            "additional_feedback": comment
        });
        rateUs(Reviewdata).then((data) => {
            if (data.code === 200 && data?.errorCode != 1) {
                setMessage({
                    error: 0,
                    message: data?.description,
                })
                ref.current.click();
            } else {
                setShow(false)
                setMessage({
                    error: 1,
                    message: data?.errorDescription ? data?.errorDescription : 'Server Error',
                })
                ref.current.click();
            }
        })
    }

    
    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])
    return (
        <><>
            <div className="modal fade feed-design" id="feedback-popup">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-10">
                        <div className="modal-header border-0">
                            <h5 className="modal-title fw-bold">Feedback</h5>
                            <button type="button" className="btn-close text-black" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body px-10 pb-0">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="fw-bolder title mb-5">
                                        High five! What makes you satisfied?
                                    </h4>
                                    <div className="satis-wdw">
                                        <label className="fw-normal text-black font-12 mb-1"> Workmanship </label>
                                        <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                                            <input type="radio" className="btn-check" value={workmanship} name="rating-sec" id="verysatis" autoComplete="off" onChange={(e) => setWorkmanship(1)} />
                                            <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis">
                                                <span className="very-satis-ioc comm-icon me-2"></span>
                                                Very Satisfied
                                            </label>

                                            <input type="radio" className="btn-check" value={workmanship} name="rating-sec" id="satis" autoComplete="off" onChange={(e) => setWorkmanship(2)} />
                                            <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis">
                                                <span className="satis-ioc comm-icon me-2"></span>
                                                Satisfied
                                            </label>

                                            <input type="radio" className="btn-check" value={workmanship} name="rating-sec" id="notsatis" autoComplete="off" onChange={(e) => setWorkmanship(3)} />
                                            <label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis">
                                                <span className="not-satis-ioc comm-icon me-2"></span>
                                                Not Satisfied
                                            </label>
                                        </div>
                                        <label className="fw-normal text-black font-12 mb-1"> Punctuality </label>
                                        <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                                            <input type="radio" className="btn-check" value={punctuality} name="rating-sec-one" id="verysatis-one" autoComplete="off" onChange={(e) => setPunctuality(1)} />
                                            <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis-one">
                                                <span className="very-satis-ioc comm-icon me-2"></span>
                                                Very Satisfied
                                            </label>

                                            <input type="radio" className="btn-check" value={punctuality} name="rating-sec-one" id="satis-one" autoComplete="off" onChange={(e) => setPunctuality(2)} />
                                            <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis-one">
                                                <span className="satis-ioc comm-icon me-2"></span>
                                                Satisfied
                                            </label>

                                            <input type="radio" className="btn-check" value={punctuality} name="rating-sec-one" id="notsatis-one" autoComplete="off" onChange={(e) => setPunctuality(3)} />
                                            <label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis-one">
                                                <span className="not-satis-ioc comm-icon me-2"></span>
                                                Not Satisfied
                                            </label>
                                        </div>
                                        <label className="fw-normal text-black font-12 mb-1"> Quality of work </label>
                                        <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                                            <input type="radio" className="btn-check" value={qualityofwork} name="rating-sec-two" id="verysatis-two" autoComplete="off" onChange={(e) => setQualityofwork(1)} />
                                            <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis-two">
                                                <span className="very-satis-ioc comm-icon me-2"></span>
                                                Very Satisfied
                                            </label>

                                            <input type="radio" className="btn-check" value={qualityofwork} name="rating-sec-two" id="satis-two" autoComplete="off" onChange={(e) => setQualityofwork(2)} />
                                            <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis-two">
                                                <span className="satis-ioc comm-icon me-2"></span>
                                                Satisfied
                                            </label>

                                            <input type="radio" className="btn-check" value={qualityofwork} name="rating-sec-two" id="notsatis-three" autoComplete="off" onChange={(e) => setQualityofwork(3)} />
                                            <label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis-three">
                                                <span className="not-satis-ioc comm-icon me-2"></span>
                                                Not Satisfied
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-6">
                                        <textarea
                                            className='form-control fw-bold font-12 form-control-solid'
                                            data-kt-autosize='true'
                                            rows={3}
                                            placeholder='Comment here....'
                                            value={comment}
                                            onChange={(e) => setComment(e?.target?.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 pt-0 px-10">
                            <button data-bs-dismiss="modal" className='btn btn-outline-primary fw-bold px-10 py-3 me-5'>
                                Cancel
                            </button>
                            {/* <button data-bs-target="#feedback-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Submit
                            </button> */}
                            <button type="button" className="btn btn-primary fw-bold px-10 py-3" onClick={handleSubmit}
                                disabled={
                                    workmanship > 0 &&
                                        punctuality > 0 &&
                                        qualityofwork > 0 &&
                                        show === false
                                        ? false : true
                                }
                            >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </> <p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p><DynamicPopup /></>
    );
}