import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Credit from "../../../../../assets/images/pm-credit-card.svg";
import Bank from "../../../../../assets/images/pm-bank.svg";
import Check from "../../../../../assets/images/pm-cheque.svg";
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';
import { currencyFormater } from '../../../../../constants/generics/customActios';

export function Payment({ payment }: any) {
    const { t } = useTranslation();
    const paymentData = payment?.paymentList?.length ? payment?.paymentList : []

    return (
        <div className="com-card-section">
            <div className="row mb-3">
                {paymentData.length ?
                    paymentData.map((item: any, index: any) => (
                        <div className="col-md-12 col-12 mb-5" key={index}>
                            <div className="card shadow-sm payment-card-sec">
                                <div className="card-body px-8 py-6">
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Payment Type")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">{item.subCategory}</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Payment Date")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">{item.status === 'Paid' ? formatDate(item.paidDate) : formatDate(item.dueDate)}</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Amount")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">{t("AED")} {currencyFormater(item.amount)}</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Payment Type")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">
                                            {
                                                item.paymentType === 'Credit Card' ? <> <img src={Credit} className="img-fluid me-3" /> {t('Credit Card')}</>
                                                    : item.paymentType === 'BANK_TRANSFER' ? <> <img src={Bank} className="img-fluid me-3" /> {t('Bank Transfer')}</>
                                                        : item.paymentType === 'CHEQUE' ? <> <img src={Check} className="img-fluid me-3" /> {t('Cheque')}</>
                                                            : <>{item.paymentType}</>

                                            }
                                        </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row align-items-center">
                                                <label className="font-14 fw-bold mb-0 text-theme-gray col">
                                                    {t("Status")}
                                                </label>
                                                {item.status === 'Paid' ?
                                                    <h3 className="mb-0 payment-txt text-success col">{t("Cleared")}</h3>
                                                    :
                                                    <h3 className="mb-0 payment-txt test-warning col">{t('Pending')}</h3>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    : <div className="col-12">
                        <div className="card  payment-card-sec">
                                            <div className="card-body px-8 py-6">
                            { t("No Data Found") }
                    </div>
                    </div>
                    </div>
                }


                {/* <div className="row">
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                        {payment?.payment_plan_details?.service_charges?.map((item:any, index:number)=>{
                                           return ( <div className="row">
                                                <div className="row">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{t("Service Charges")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{item?.paymentDate}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{item?.amount} {t("AED")}</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt"><img src={Bank} className="img-fluid me-3" />{item?.modePayment}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{item?.status}</h3>
                                                        </div>
                                                        <div className="">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
        </div>
    );
}