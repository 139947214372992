import React, {useState, useEffect} from 'react'
import ChequeDelayIcon from '../../../../assets/images/cheque-delay.png'
import ChequeReplaceIcon from '../../../../assets/images/payment-icon-cheque.png'
import InvoiceIcon from '../../../../assets/images/invoice.png'
// import ChequeReplaceIcon from '../../../../assets/images/fc-ql-two.png'
import ClearanceChargesDisputeIcon from '../../../../assets/images/Renew_contract.png'
import OtherChargesDisputeIcon from '../../../../assets/images/lease-details.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {RootState} from '../../../../constants'
import {useSelector} from 'react-redux'
import {getFinanceServiceRequestList} from '../../../../constants/axios/apis'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import {CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'

export function RecentRequests() {
  const [serviceRequestList, setServiceRequestList] = useState<any>([])
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    let newPayload = '?status=open' + '&id_sort=1'
    newPayload = currentBuilding
      ? newPayload + '&building_id=' + currentBuilding
      : newPayload + '&building_id=0'
    getFinanceServiceRequestList(newPayload)
      .then((response: any) => {
        const filterServices = response?.data?.filter((services: any) => services.status === 'Open')
        setServiceRequestList(
          Array.isArray(filterServices) && filterServices?.length > 0
            ? filterServices.slice(0, 15)
            : []
        )
        setLoading(false)
      })
      .catch((e) => {
        setServiceRequestList([])
        setLoading(false)
      })
  }, [currentBuilding])

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  const {t} = useTranslation()
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-5 pt-4 p-xl-7 pt-xl-5'>
              <div className='row'>
                <div className='col-md-6 col-9'>
                  <h4 className='card-title'> {t('Recent Requests')} </h4>
                </div>
                {serviceRequestList?.length > 2 ? (
                  <div className='col-md-6 col-6 d-flex justify-content-end align-items-center pe-20'>
                    <Link
                      to={'/finance-services-request/list/open'}
                      className='text-decoration-none view-all'
                    >
                      <span> {t('View All')} </span>
                      <img src={RightArrow} className='img-fluid' />
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className='rec-request-sec'>
                <div>
                  <div className='row'>
                    {loading && <CustomLoader2 height={'166'} />}
                    {!loading && (
                      <div className='col-12 p-0'>
                        {serviceRequestList?.length ? (
                          <Slider {...settings}>
                            {serviceRequestList?.map((serviceDetail: any, index: number) => {
                              const viewLink = '/finance-services-request/view'
                              const dynamicChequeImage =
                                serviceDetail.categoryCode === 'CQR'
                                  ? ChequeReplaceIcon
                                  : serviceDetail.categoryCode === 'CQD'
                                  ? ChequeDelayIcon
                                  : serviceDetail.categoryCode === 'CCD'
                                  ? ClearanceChargesDisputeIcon
                                  : serviceDetail.categoryCode === 'OCD'
                                  ? OtherChargesDisputeIcon
                                  : InvoiceIcon
                              return (
                                <div key={index}>
                                  <Link
                                    to={{pathname: viewLink, state: {serviceDetail}}}
                                    className='text-decoration-none'
                                  >
                                    <div className='card mt-4'>
                                      <div className='card-body px-5 py-5 px-md-8 py-md-7'>
                                        <div className='row'>
                                          <div className='col-lg-8'>
                                            <div className='mb-7'>
                                              <img
                                                src={dynamicChequeImage}
                                                className='img-fluid me-5 d-inline'
                                                style={{minHeight: '36px'}}
                                              />
                                              <span className='card-title'>
                                                {serviceDetail?.category
                                                  ? serviceDetail.category
                                                  : '-'}
                                              </span>
                                            </div>
                                            {/* <h6 className='font-14 fw-normal text-black'>
                                              {t('Action Required')}
                                            </h6> */}
                                            <div className="row">
                                              <div className="col-sm-6 pe-0">
                                                  <p className="mb-0 text-muted">{t("Unit Number")}</p>
                                                  <p className="mb-0 text-dark font-12 fcs-unit-number">{serviceDetail.propertyCode}</p>
                                              </div>
                                              <div className="col-sm-6 pe-0">
                                                  <p className="mb-0 text-muted">{t("Tenant")}</p>
                                                  <p className="mb-0 text-dark font-12 fcs-unit-number">{serviceDetail.tenantName ? serviceDetail.tenantName : '-'}</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-lg-4 text-end mt-auto'>
                                            <button className='btn btn-primary rounded w-100 py-2 mt-13'>
                                              {t('View')}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              )
                            })}
                          </Slider>
                        ) : (
                          <NoDataFound1 height={'110'} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
