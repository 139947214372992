import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import {redeemMarketPlaceOffer, redeemOfferrequest} from '../../../../../constants/axios/apis'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'



export function RedeemMarketPlacePopup({ redeemableOffer = '', backLink = '/market-place/all' }: any) {
  const { t } = useTranslation();

  // const history = useHistory()
  const closeRef = useRef<any>()
  // const [error, setError] = useState<any>('')
  // const [loading, setLoading] = useState<any>(false)

  const initialValues = {
    id: redeemableOffer?.id,
    terms: false,
  }
  const redeemRequest = Yup.object().shape({
    terms: Yup.bool().oneOf([true], t('Terms & Conditions must be accepted')),
  })
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: redeemRequest,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      formik.setFieldValue('terms', false)
      formik.setFieldTouched('terms', false)
      closeRef.current.click()
    },
    // onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
    //   setLoading(true)
    //   setError('')
    //   setTimeout(() => {
    //     const payload = {
    //       id: values.id,
    //       is_redeemed: 1,
    //     }
    //     redeemMarketPlaceOffer(values.id, payload)
    //       .then((response: any) => {
    //         if(response.errorCode === 0){
    //           resetForm()
    //           formik.setFieldValue('terms', false)
    //           closeRef.current.click()
    //           history.push(backLink)
    //         }else if(response.errorCode === 1){
    //           setStatus(response.errorDescription)
    //           setError(response.errorDescription)
    //         }else{
    //           setSubmitting(false)
    //           setError('Some error occured. Please try again later')
    //           setStatus('Some error occured. Please try again later')
    //         }            
    //         setLoading(false)
    //       })
    //       .catch((e) => {
    //         setLoading(false)
    //         setSubmitting(false)
    //         setError('Invalid Data Provided')
    //         setStatus('Invalid Data Provided')
    //       })
    //   }, 1000)
    // },
  })

  return (
    <div className='modal fade custom-modal redeem-offer' id='redeem-offer'>
      <div className='modal-dialog modal-md modal-dialog-centered'>
        <div className='modal-content rounded-10'>
          <div className='modal-header'>
            <h5 className='modal-title'>{t("Redeem your offer Now")}</h5>
            <button
              type='button'
              className='btn-close shadow'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              &times;
            </button>
          </div>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='modal-body'>
              <div className='row g-5'>
                <div className='col-md-6'>
                  <div className='text-center'>
                    <div className='redeem-offer-percent'>
                      <img
                        src={redeemableOffer?.logo}
                        alt='redeem-offer-img'
                        className='img-fluid'
                      />
                      <h1 className='my-5 fw-bold text-black border rounded p-3'>{redeemableOffer?.offer} % Off</h1>
                      <h6 className='font-13 fw-bold text-black mb-4 mx-md-8'>
                        {' '}
                        {redeemableOffer?.details}{' '}
                      </h6>

                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='redeem-offer-percent'>
                    <p className='font-12 fw-normal'>{redeemableOffer?.description}</p>
                  </div>
                </div>
                {/* <div className='col-md-6'>
                  <div className='redeem-offer-percent'>
                    <p className='font-12 fw-normal'>{redeemableOffer?.description}</p>
                    <div className='d-flex justify-content-end'>
                      <button
                        className='btn btn-primary btn-lg d-block py-2 px-lg-14 fw-normal'
                        disabled={formik.values.terms == false || loading}
                      >
                        {loading ? 'Please Wait...' : 'Redeem'}
                      </button>
                    </div>
                  </div>
                </div>
                {error && error !== '' ?
                <div className="col-12 text-center"><span className="text-danger">{error}</span></div>
                  :''
                } */}
              </div>
              <div className='row g-5'>
                <div className='col-md-6'>
                  <div className='form-check form-check-sm'>
                    <input
                      className='form-check-input me-3 float-none'
                      type='checkbox'
                      id='flexCheckDefault'
                      {...formik.getFieldProps('terms')}
                      checked={formik.values.terms}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className='form-check-label font-12 fw-normal '
                      htmlFor='flexCheckDefault'
                    >
                      {t("Terms & Condition apply")}
                    </label>
                  </div>
                  {formik.touched.terms && formik.errors.terms && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.terms}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6'>
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-primary btn-lg d-block py-2 px-lg-14 fw-normal w-100'
                    >
                      {t("Redeem")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#redeem-QR'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
