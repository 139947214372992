import React from 'react'
import { PendingPayment } from './PendingPayment';
import { PaidPayment } from './PaidPayment';
import { formatDate, formatDateTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater';
import { useTranslation } from 'react-i18next';

/**
 * Props Params = id, due_date, paid_date, method, type, amount, email, invoice, receipt, ref_id, payment_status 
 * Payment status: pending = 0, paid = 2, failed = 2
 */
export function PaymentInfo( props:any) {
  const {t} = useTranslation()

  const payment_status_text = props.payment_status === 2 ? 'Payment Failed' : props.payment_status === 1 ? 'Payment Received' : 'Payment Due'
  const payment_date = props.payment_status === 0 ? formatDate(props.due_date) : formatDateTimeMeridiem(props.paid_date)
  const file = props.payment_status === 0 ? props.invoice : props.receipt
  const isPrimaryPayment = ['Rent', 'Security Deposit'].includes(props?.type)

  const title_date = isPrimaryPayment ?
  props.payment_status === 0 ? formatDate(props?.due_date) : formatDate(props?.paid_date)
  : t("Pending")
  
  const method_text = props.payment_status === 0 ? 
    isPrimaryPayment ? (props?.method ? props.method : ' - ') : t("Pending")
    : (props?.method ? props.method : ' - ')

  return (
    <>
      {props.payment_status === 0 ?
        <PendingPayment
          id={props?.id} 
          title_date={title_date}

          method_text={method_text}
          payment_type={props.type ? props.type : ' - '}
          amount={props.amount}

          payment_status_text={payment_status_text}
          payment_date={payment_date}
          file={file}
          email={props.email}
          // ref_id={props?.ref_id}
        />
        :
        <PaidPayment 
          id={props?.id} 
          title_date={title_date}

          method_text={method_text}
          payment_type={props.type ? props.type : ' - '}
          amount={props.amount}

          payment_status_text={payment_status_text}
          payment_date={payment_date}
          file={file}
          email={props.email}
          ref_id={props?.ref_id}
        />
      }
    </>
  )
}
