import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { BounceRates } from './marketing-website/BounceRateList'
import { CostPerClick } from './marketing-website/CostPerClickList'
import { ClickThroughCampaign } from './marketing-website/ClickThroughList'
import { ImpressionCampaign } from './marketing-website/ImpressionList'
import { WebsiteListing } from './marketing-website/WebsitelListing'
import { AddWebsite } from './marketing-website/create-website-campaign/addWebsite'
import { EditWebsite } from './marketing-website/edit-website-campaign/EditWebsite'
const PMWebsite: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/website/list'>
                            <WebsiteListing/>
                        </Route>
                        <Route path='/website/add'>
                            <AddWebsite/>
                        </Route>  
                        <Route path='/website/edit'>
                            <EditWebsite/>
                        </Route>
                        <Route path='/website/BounceRates'>
                            <BounceRates/>
                        </Route>
                        <Route path='/website/cost-per-click'>
                            <CostPerClick/>
                        </Route>
                        <Route path='/website/click-through-rate'>
                            <ClickThroughCampaign/>
                        </Route>
                        <Route path='/website/impressions'>
                            <ImpressionCampaign/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMWebsiteWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMWebsite />
        </>
    )
};

export { PMWebsiteWrapper }

