import React, {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {TenantTabs} from './components/TenantTabs/TenantTabs';
import {All} from './components/All/All';
import {Card} from './components/Card/Card';
import {Cash} from './components/Cash/Cash';
import {PaymentByEMP} from './components/PaymentByEMP/PaymentByEMP';

const PMTenantPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-xxl-12 mt-0'>
            <Switch>
                <Route path='/pm-tenant/all'>
                    <TenantTabs />
                    <All />
                </Route>
                <Route path='/pm-tenant/card'>
                    <TenantTabs />
                    <Card />
                </Route>
                <Route path='/pm-tenant/cash'>
                    <TenantTabs />
                    <Cash />
                </Route>
                <Route path='/pm-tenant/payment-by-emp'>
                    <TenantTabs />
                    <PaymentByEMP />
                </Route>
            </Switch>
        </div>
    </div>
    </>
);

const PMTenantWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <PMTenantPage />
        </>
    )
};

export {PMTenantWrapper}
