import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import DatePicker from 'react-date-picker'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import moment from 'moment'
import { getAvailableTimings, postWorkOrderReschedule } from '../../../../../../constants/axios/apis'
import { formatTimeMeridiem, PreventDatePickerInput } from '../../../../../../constants/generics/dateTimeFormater'
import { useTranslation } from 'react-i18next';

export function WorkorderReschedulePopup({workOrder, title, setPopupSuccessMsg}:any) {
  const { t } = useTranslation();
  
  const history = useHistory();
  const today_data_object = new Date();
  let minDate = today_data_object
  if(workOrder?.date?.length && workOrder?.time?.length){
    const old_date = moment(workOrder?.date).format('YYYY-MM-DD')
    if(moment().diff(old_date, 'days') < 0)
      minDate = new Date(workOrder?.date)
  }

  const [timeSlots, setTimeSlots] = useState<any>([])
  const [timeSlotsError, setTimeSlotsError] = useState<any>((t('Please select a date for available time slots')))
  const successRef: any = useRef(null)
  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    message: ''
  })

  const initialValues = {
    date: "",
    time: "",
    // comments: ''
  }
  const validationSchema = Yup.object().shape({
    date: Yup.string().required(t("Date is required!")),
    time: Yup.string().required(t("Time is required!")),
    // comments: Yup.string()
    // .required(t("Comment is required!"))
    //   .max(250, t("Max length is 250 characters!"))
    //   .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!')),
  })
  const validate = (values: any) => {
    let errors: any = {}
    
    var selectedDate = moment(values.date).startOf('day')
    var selectedDateFull = moment(values.date + (values.time ? ' ' +values.time + ':00' : ''))
    var todayDate = moment().startOf('day')
    var todayFull = moment()

    if (values.time && (selectedDate.diff(todayDate, 'days') === 0) && (selectedDateFull.diff(todayFull) < 0)) {
        errors.time = (t('Please select the vaild time!'));
    }
    return errors;
  }
  const onSubmit = (values: any) => {
    setSubmitStatus((oStatus:any)=>({...oStatus, loading: true}))
    const params = {
        issue_id: workOrder.id,
        date: values.date,
        time: values.time
    }
    setTimeout(() => {
      postWorkOrderReschedule(params)
        .then((response: any) => {
          if (response.errorCode === 1) {
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.errorDescription, error: true}))
          } else if(response.errorCode === 0) {
            setPopupSuccessMsg(response.description)
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.description}))
            // var ev = new Event('click', { bubbles: true});
            successRef.current.click();
            setTimeout(() => {
              history.push("/facility-workorder/list/corrective/open")
                // window.location.replace("/fc-workorder/corrective/open");
            }, 500)
            // setTimeout(() => {
            //   window.location.reload();
            //   refreshRequest()
            // }, 3000)
          } else{
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
          }
          setSubmitStatus((oStatus:any)=>({...oStatus, loading: false}))
        })
        .catch((e) => {
            // console.log(e, 'Error');
          setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
        })
    }, 1000)
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validate
  });

  const [dateValue, setDateValue] = useState<any>();
  const handleDateChange = (e: any) => {
      // console.log(e, 'handleDateChange');
      setDateValue(e);
      // var menDate = new Date(e).toISOString().slice(0, 10);
      var menDate = e ? moment(e).format('YYYY-MM-DD') : ''
      formik.setFieldValue("date", menDate);
      // formik.setFieldTouched("date", true);
      
      formik.setFieldValue("time", '');
      formik.setFieldTouched("time", false);
  }

  useEffect(() => {
    // console.log(formik.values.date, 'formik.values.date');
    
    if (formik.values.date && formik.values.date !== '') {
      getAvailableTimings(
        0,  //Property ID - to fetch all time slots
        moment(formik.values.date).format('YYYY-MM-DD'),
        0
      ).then(({ data }) => {
        //Validate/Filter and display only future date & time slots.
        function validateTime(inputTime: any) {
          const dt = moment(moment(formik.values.date).format('YYYY-MM-DD') + ' ' + inputTime + ':00')
          if (moment().diff(dt, 'minutes') > 0)
            return false

          if(workOrder?.date?.length && workOrder?.time?.length){
            const old_date = moment(moment(workOrder?.date).format('YYYY-MM-DD') + ' ' + workOrder?.time)
            const old_time = moment(moment(formik.values.date).format('YYYY-MM-DD') + ' ' + workOrder?.time)
            if ((old_date.diff(old_time, 'days')===0) && (old_time.diff(dt, 'minutes') >= 0))
              return false
          }
          return true
        }
        let validTimes = data.filter((element: any) => validateTime(element));
        setTimeSlots(validTimes)
        setTimeSlotsError(validTimes.length ? '' : t('No time slots available for this day, try a different day'))
      })
    } else {
      setTimeSlots([])
      setTimeSlotsError(t('Please select a date for available time slots'))
    }
  }, [formik.values.date])

  PreventDatePickerInput()

  return (
    <>
      <div className='modal fade custom-modal text-start' aria-hidden='true' id='ReschedulePopup'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content rounded-10'>
            <div className='modal-header py-3 border-0'>
              <h5 className='modal-title'>{title}</h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <form
                className={
                  'form w-100 fv-plugins-bootstrap5 fv-plugins-framework tab-pane form-add-design'
                }
                noValidate
                onSubmit={formik.handleSubmit}
                id='vistior-slots'
              >
                <div className='row'>
                  <div className='col-md-6 col-12 mb-5'>
                    <div className='date-picker'>
                      <label className='form-label fw-bolder'>
                        {t("Date")} <span className='text-danger'> * </span>
                      </label>
                      <DatePicker
                        value={dateValue}
                        className='form-control fw-bolder form-control-solid'
                        format="dd-MM-y"
                        minDate={minDate}
                        onChange={handleDateChange}
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                      />
                      {formik.touched.date && formik.errors.date ? (
                        <small className="text-danger">
                            {formik.errors.date}
                        </small>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mb-5">
                    <div className="">
                      <label className="form-label fw-bolder">
                          {t("Time Slot")} <small className="text-danger">* </small></label>
                      <select
                          className="form-control form-select fw-bolder form-control-solid"
                          data-control="select2" data-allow-clear="true"
                          {...formik.getFieldProps('time')}
                      >
                          <option value="">{t("Please Select")}</option>
                          {timeSlots.map((timeSlot: any, index: number) => {
                              return (
                                  <option key={index} value={timeSlot}>{formatTimeMeridiem(timeSlot)}</option>
                              )
                          })}
                      </select>
                      {formik.touched.time && formik.errors.time ? (
                          <div className='d-flex flex-column text-danger'>
                              <small className="text-danger">{formik.errors.time}</small>
                          </div>
                      ) : null}
                    </div>
                  </div>
                  {timeSlotsError !== '' ? (
                    <div className='col-12 mb-5'>
                      <small className="text-muted">{timeSlotsError}</small>
                    </div>
                  ) : null}

                  {/* <div className="col-md-12 col-12 mb-5">
                    <div className="mb-0">
                      <label className="form-label fw-bolder">
                        {t("Comment")} <small className="text-danger">* </small>
                      </label>
                      <textarea {...formik.getFieldProps('comments')} 
                        className="form-control fw-bolder form-control-solid"
                        placeholder={t("Type here...")}
                        rows={3}></textarea>
                      {formik.touched.comments && formik.errors.comments ? (
                        <div className='d-flex flex-column text-danger'>
                            <small className="text-danger">{formik.errors.comments}</small>
                        </div>
                      ): null }
                    </div>
                  </div> */}
                  {/* <div className='col-md-6 col-12 mb-5'>
                    <div className=''>
                      <label className='form-label fw-bolder'>
                        Time
                        <span className='text-danger'> * </span>
                      </label>
                      <input
                        type="time"
                        className="form-control fw-bolder form-control-solid"
                        placeholder=""
                        {...formik.getFieldProps('time')}
                      />
                      {formik.touched.time && formik.errors.time ? (
                        <small className="text-danger">
                            {formik.errors.time}
                        </small>
                      ) : null}
                    </div>
                  </div> */}
                </div>
                <div className='row'>
                  {(!submitStatus.loading && submitStatus.error) && (
                    <div className="col-12 text-center">
                        <div className="alert alert-danger w-100 mt-2" role="alert">
                            {submitStatus.message}
                        </div>
                    </div>
                  )}
                  <div className='col-12 text-end'>
                    { submitStatus.loading ?
                      <button type='button' className='btn btn-primary fw-bold px-10 py-3' disabled>
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {t("Please wait...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                      </button>
                    :
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                      <span className='indicator-label'>{t("Submit")}</span>
                    </button>
                    }
                    <p
                      ref={successRef}
                      data-bs-target='#req-submitted-popup-redirect'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      className='invisible d-none'
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
      {/* <CustomRequestSuccessfulPopup
        successMessage={submitStatus.message}
        close={false}
        customID={'-schedule'}
      /> */}
    </>
  )
}
