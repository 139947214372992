import React from 'react'
import {useIntl} from 'react-intl'
import SearchIcon from '../../../../assets/images/search-icon.png'
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export function MenuInnerSearch() {

    const intl = useIntl();

    const items = [
        {
            id: 0,
            name: 'Services'
        },
        {
            id: 1,
            name: 'Maintenance'
        },
        {
            id: 2,
            name: 'Invoice'
        },
        {
            id: 3,
            name: 'Properties'
        },
        {
            id: 4,
            name: 'Reports'
        },
        {
            id: 5,
            name: 'Calendar'
        },
        {
            id: 6,
            name: 'Tenant Dashboard'
        },
        {
            id: 7,
            name: 'Broker Admin Dashboard'
        },
        {
            id: 8,
            name: 'Facility Contractor Dashboard'
        },
        {
            id: 9,
            name: 'Facility Contractor Soft Services Dashboard'
        },
        {
            id: 10,
            name: 'Facility Contractor Supervisor Dashboard'
        },
        {
            id: 11,
            name: 'Facility Contractor Soft Services'
        },
        {
            id: 12,
            name: 'Profile'
        },
        {
            id: 13,
            name: 'Settings'
        },
        {
            id: 14,
            name: 'FAQ'
        },
        {
            id: 15,
            name: 'Chat with us'
        },
        {
            id: 16,
            name: 'My Documents'
        },
        {
            id: 17,
            name: 'My Unit'
        },
        {
            id: 18,
            name: 'Market Place'
        },
        {
            id: 19,
            name: 'Payments'
        },
        {
            id: 20,
            name: 'Announcements'
        }
    ];

    // const handleOnSearch = (string, results) => {
    //     // onSearch will have as the first callback parameter
    //     // the string searched and for the second the results.
    //     console.log(string, results)
    // };
    //
    // const handleOnHover = (result) => {
    //     // the item hovered
    //     console.log(result)
    // };
    //
    // const handleOnSelect = (item) => {
    //     // the item selected
    //     console.log(item)
    // };
    //
    // const handleOnFocus = () => {
    //     console.log('Focused')
    // };
    //
    // const formatResult = (item) => {
    //     return item
    //     // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    // };

    return (
        <>
        <div className='row'>
            <div className='col-lg-12 my-auto'>
                <div className="input-group top-search-wrapper">
                    {/*<input type="text" className="form-control" placeholder="Search..." />*/}
                    {/*<span className="input-group-text bg-transparent px-5" id="basic-addon2">*/}
                        {/*<img src={SearchIcon} className="img-fluid" />*/}
                    {/*</span>*/}
                    {/*<ReactSearchAutocomplete*/}
                    {/*items={items}*/}
                    {/*onSearch={handleOnSearch}*/}
                    {/*onHover={handleOnHover}*/}
                    {/*onSelect={handleOnSelect}*/}
                    {/*onFocus={handleOnFocus}*/}
                    {/*autoFocus*/}
                    {/*formatResult={formatResult}*/}
                    {/*placeholder='Search...'*/}
                    {/*/>*/}
                </div>
            </div>
        </div>
        </>
    )
}
