import React, { useEffect, useState } from 'react'
import UserIcon from '../../../../assets/images/plumber_img.png'
import RevertIcon from '../../../../assets/images/revet_icon.png'
import MsgIcon from '../../../../assets/images/msg_icon.png'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import moment from 'moment';
import Loader from '../../../../components/Loader'
import { formatDate, formatDateTimeMeridiem, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'
import { useSelector } from 'react-redux'
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios'

import { useTranslation } from 'react-i18next';

Chart.register(ChartDataLabels)

const labels = ['Open', 'Close']
export const data = {
	labels,

	datasets: [
		{
			datalabels: {
				color: '#EAEFF5',
				font: {
					size: 16,
					family: "'Poppins', sans-serif",
					lineHeight: 0.5,
				},
			},
			label: 'Corrective',
			data: [140, 100],
			backgroundColor: '#002C6A',
			barThickness: 55,
			maxBarThickness: 55,
			borderRadius: {
				bottomRight: 5,
				bottomLeft: 5,
				topRight: 5,
				topLeft: 5,
			},
		},
		{
			datalabels: {
				color: '#EAEFF5',
				font: {
					size: 16,
					family: "'Poppins', sans-serif",
					lineHeight: 0.5,
				},
			},
			label: 'Corrective',
			data: [160, 200],
			backgroundColor: '#EAEFF5',
			barThickness: 55,
			maxBarThickness: 55,
			borderRadius: {
				bottomRight: 0,
				bottomLeft: 0,
				topRight: 5,
				topLeft: 5,
			},
		},
	],
}
export const options = {
	showTooltips: false,
	// hover: {mode: null},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: true,
			text: 'Corrective(20)',
			padding: {
				top: 10,
				bottom: 30,
			},
			color: '#A6A6A6',
			font: {
				size: 14,
				family: "'Poppins', sans-serif",
				lineHeight: 0.5,
			},
		},
	},

	layout: {
		padding: {
			left: 50,
		},
	},
	responsive: true,

	scales: {
		x: {
			stacked: true,
			grid: {
				display: false,
				drawBorder: false,
				zeroLineColor: 'transparent',
			},
			ticks: {
				color: 'rgb(0, 0, 0)',
				font: {
					size: 14,
					family: "'Poppins', sans-serif",
					lineHeight: 0.5,
					weight: '500',
				},
			},
		},
		y: {
			stacked: true,

			display: false,
			grid: {
				display: false,
				drawBorder: false,
				ticks: {
					display: false,
				},
			},
		},
	},
}

export const CalenderDetails: React.FC<any> = ({ selectedWorkOrders, loading, selectedDate }) => {
	// const todayDate = moment().format("YYYY-MM-DD");
	const [selectedCategory, setSelectedCategory] = useState<any>('Corrective');
	const [filteredWorkOrders, setFilteredWorkOrders] = useState<any>([]);

	const [workOrderCategoryCount, setWorkOrderCategoryCount] = useState({
		corrective: 0,
		clearance: 0,
		preventive: 0
	});

	const state: any = useSelector(state => state);
	let isFCUser = (state?.auth?.user?.data?.id_role && state.auth.user.data.id_role == 8) ? true : false;

	let correctiveCount = 0;
	let clearanceCount = 0;
	let preventiveCount = 0;

	selectedWorkOrders.map((wo: any, index: number) => {
		if (wo.issueCategory === "Corrective") {
			//setWorkOrderCategoryCount({...workOrderCategoryCount, corrective: workOrderCategoryCount.corrective++});
			correctiveCount++;
		}
		if (wo.issueCategory === "Clearance") {
			//setWorkOrderCategoryCount({...workOrderCategoryCount, clearance: workOrderCategoryCount.clearance++});
			clearanceCount++;
		}
		if (wo.issueCategory === "Preventive") {
			//setWorkOrderCategoryCount({...workOrderCategoryCount, preventive: workOrderCategoryCount.preventive++});
			preventiveCount++;
		}
	});

	useEffect(() => {
		setWorkOrderCategoryCount({
			corrective: correctiveCount,
			clearance: clearanceCount,
			preventive: preventiveCount
		});
	}, [selectedWorkOrders]);

	// useEffect(()=>{
	// 	setWorkOrderCategoryCount({
	// 		corrective: correctiveCount,
	// 		clearance: clearanceCount,
	// 		preventive: preventiveCount
	// 	});
	// },[selectedCategory]);

	useEffect(() => {
		const temp = selectedWorkOrders.filter((wo: any) => wo.issueCategory === selectedCategory)
		setFilteredWorkOrders(temp)
	}, [selectedCategory, selectedWorkOrders]);

	/* console.log("corrective", workOrderCategoryCount.corrective);
	console.log("clearance", workOrderCategoryCount.clearance);
	console.log("preventive", workOrderCategoryCount.preventive); */
	// console.log(filteredWorkOrders, 'filteredWorkOrders')
	const { t } = useTranslation();
	return (
		<div className='com-card-section'>
			<div className='row'>
				<div className='col-12'>
					<div className='card rounded-10 calendar-details-sec calender-card-sec'>
						<div className='card-body px-5 py-3'>
							{/* <div className="row">
								<div className='col-12'>
									<div className='row'>
										<div className='col-6'>
											<h4 className='fw-bolder font-14'>{t("Today")}</h4>
										</div>
										<div className='col-6 text-end'>
											<h4 className='fw-bolder font-14'>{formatDate(selectedDate)}</h4>
										</div>
									</div>
								</div>
							</div> */}
							<div className='row border-btm'>
								<div className="col-12">
									<div className='event-request mb-3'>
										<div
											className={'event-sec cursor-pointer ' + (selectedCategory === 'Corrective' && 'border bg-gray')}
											onClick={(e) => setSelectedCategory('Corrective')}>
											
											<p>{t("Corrective")}</p>
											<h4>{(workOrderCategoryCount.corrective)}</h4>
										</div>
										<div
											className={'event-sec cursor-pointer ' + (selectedCategory === 'Clearance' && 'border bg-gray')}
											onClick={(e) => setSelectedCategory('Clearance')}>
											
											<p>{t("Clearance")}</p>
											<h4>{workOrderCategoryCount.clearance}</h4>
										</div>
										<div
											className={'event-sec cursor-pointer ' + (selectedCategory === 'Preventive' && 'border bg-gray')}
											onClick={(e) => setSelectedCategory('Preventive')}>

											<p>{t("Preventive")}</p>
											<h4>{workOrderCategoryCount.preventive}</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className='invoice-card'>
										{loading &&
											<div className="mt-5">
												<CustomLoader2 height={'166'} />
											</div>
										}
										{!loading && (!filteredWorkOrders || filteredWorkOrders.length === 0) &&
											<NoDataFound1 height={'110'} />
											// <div className="mt-5 text-center">
											// 	No workorder exist for current selection.
											// </div>
										}
										{filteredWorkOrders.map((workOrder: any, index: number) => {
											let viewLink = '/facility-workorder/view'
											// if(isFCUser){		//FC
											// 	viewLink = workOrder.issueCategory === "Clearance" ?
											// 	( workOrder.status === 'Completed' ? '/fc-workorder/moveoutinspection/completed'
											// 	: workOrder.status === 'Confirmed' ? '/fc-workorder/moveoutinspection/confirmed'
											// 	: workOrder.status === 'Inprogress' ? '/fc-workorder/moveoutinspection/open'
											// 	: workOrder.status === 'Open' ? '/fc-workorder/moveoutinspection/open'
											// 	: '/'
											// 	): workOrder.status === 'On hold' ? '/fc-workorder/book-appointment': '/fc-workorder/work-order-details'
											// }else{ 				//FCS
											// 	viewLink = workOrder.issueCategory === "Clearance" ?
											// 	( workOrder.status === 'Completed' ? '/fc-workorder/moveoutinspection/completed'
											// 	: workOrder.status === 'Confirmed' ? '/fc-workorder/moveoutinspection/confirmed'
											// 	: workOrder.status === 'Inprogress' ? '/workorder/details'
											// 	: workOrder.status === 'Open' ? '/workorder/details'
											// 	: '/'
											// 	): '/workorder/details'
											// }

											return (
												<Link to={{ 'pathname': viewLink, state: { 'workOrder': workOrder } }} key={index + 1}>

													<div className='invoice-box calender_card px-4 mb-5' >
														<div className='row'>
															<div className='col-12 mb-5'>
																<div className='calender_card_right_div1'>
																	<h5 className='font-17 fw-normal'>{workOrder.issueCategory === 'Clearance' ? workOrder.typeText === "Move Out" ? "Move Out Inspection" : workOrder.typeText : workOrder.typeText}</h5>
																	<p className='mb-0 font-14 fw-normal'>
																		{workOrder.subCategory}
																	</p>
																</div>
															</div>
															<div className='col-12 mb-5'>
																<div className='calender_card_right_div2'>
																	<div className='row'>
																		<div className='col-sm-8'>
																			<h5 className='font-14 fw-normal text-color'>{t("WO Number")}</h5>
																			<p className='mb-0 font-12 fw-normal text-color'>{workOrder.WorkorderNo}</p>
																		</div>
																		<div className='col-sm-4'>
																			<p className='in-progress mb-0 py-1 px-3'>{workOrder.status}</p>
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12 mb-5'>
																<div className='calender_card_right_div2'>
																	<div className='row'>
																		<div className='col-sm-6'>
																			<h5 className='font-14 fw-normal text-color'>{t("Appointment Date")}</h5>
																			<p className='mb-0 font-12 fw-normal text-color'>{formatDate(workOrder.date)}</p>
																		</div>
																		<div className='col-sm-6 text-end'>
																			<h5 className='font-14 fw-normal text-color'>{t("Time")}</h5>
																			<p className='mb-0 font-12 fw-normal text-red'>
																				{workOrder.issueCategory === "Clearance" ? workOrder?.objectDetails?.referenceDetails?.time_slot : formatTimeMeridiem(workOrder.time)}
																				{/* {formatTimeMeridiem(workOrder.time)} */}
																			</p>
																		</div>
																		{/* <div className='col-sm-6'>
																	<h5 className='font-14 fw-normal text-color'>Estimated Time</h5>
																	<p className='mb-0 font-12 fw-normal text-color'>{formatTimeMeridiem(workOrder.time)}</p>
																</div>
																<div className='col-sm-6 text-end'>
																	<h5 className='font-14 fw-normal text-color'>Actual Time</h5>
																	<p className='mb-0 font-12 fw-normal text-red'>{formatTimeMeridiem(workOrder.time)}</p>
																</div> */}
																	</div>
																</div>
															</div>
															<hr className='px-4' />
															<div className='col-12'>
																<div className='calender_card_right_div3'>
																	<div className='row'>
																		<div className='col-sm-6'>
																			{workOrder.assignedTo ?
																				<div className='calender-section'>
																					<div className='calender_card_right_div3_img'>
																						<img src={UserIcon} className='img-fluid' />
																					</div>
																					<div className='calender_card_right_div3_text'>
																						<h5 className='font-14 fw-normal text-color'>{workOrder.assignedTo}</h5>
																						{/* <p className='mb-0 font-12 fw-normal text-red'>PRV00023</p> */}
																					</div>
																				</div>
																				:
																				<div className='calender-section'>
																					<div className='calender_card_right_div3_text'>
																						<div className="pt-4">
																							{t("Not assigned")}
																						</div>
																					</div>
																				</div>
																			}

																		</div>
																		<div className='col-sm-6 text-end'>
																			<div className='icons_msg float-end'>
																				<div className='icon_1'>
																					{workOrder.commentsCount} <img src={MsgIcon} className='img-fluid ps-1' />
																				</div>
																				{/* <div className='icon_1 icon_2 ms-2'>
																			<img src={RevertIcon} className='img-fluid' />
																		</div> */}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</Link>
											)
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
