import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import WorkOrdersImg from "../../../../../assets/images/ac-repair.png";
import NoImage from "../../../../../assets/images/House.png";
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { ClearanceWorkorderActions } from './ClearanceWorkorderActions';
import { useTranslation } from 'react-i18next';

export function ClearanceWorkorderBasicDetails({workOrder, setPopupSuccessMsg , compactView=false}:any) {

    const history = useHistory();

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    const userToken = state?.auth?.accessToken;
    // if(userToken === "undefined"){
    //     // token undefined
    // }


    // const [workOrderImage, setWorkOrderImage] = useState<any>(NoImage);
    const [woFilterData, setWoFilterData] = useState<any>({
        date: '',
        time: '',
        desc: '',
        // image: NoImage,
        building_img: NoImage,
    });
    useEffect(() => {
        // const image = workOrder.buildings && workOrder.buildings[0] ? workOrder.buildings[0].cover_image : NoImage
        const building_img = workOrder.buildings && workOrder.buildings[0] ? workOrder.buildings[0].communityLogo : NoImage
        setWoFilterData({
            date: moment(workOrder.objectDetails?.referenceDetails?.date).format("DD-MM-YYYY"),
            time: workOrder.objectDetails?.referenceDetails?.time_slot,
            desc: workOrder.objectDetails?.referenceDetails?.comments,
            // image: (image && image.length) ? image : NoImage,
            building_img: (building_img && building_img.length) ? building_img : NoImage
        })
    }, []);

    const isAssignedUser = state?.auth?.user?.data.id === workOrder.assignedToUserId

    const { t } = useTranslation();
    return (
        <div className="com-card-section myunit-page">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <span onClick={history.goBack} className='c-pointer'>
                            <img src={LeftArrow} alt='image' className="img-fluid me-5 left-arrow-back" />
                        </span>
                        <span className="text-black">
                            {workOrder.typeText === "Move Out" ? 'Move Out Inspection' : workOrder.typeText}
                        </span>
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-7 overflow-hidden">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-4 col-lg-3">
                                    <div className='approval-move'>
                                        <img src={woFilterData.building_img} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white rounded shadow-none">
                                        <div className="card-body px-0 py-0">
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <span className="text-black fw-bolder font-15 d-block mb-4">
                                                        {t("Work Order Number")} : {workOrder.WorkorderNo}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Unit Number")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrder.property_code} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Issue Type")} </h6>
                                                        <span className="text-black fw-bold font-15"> {workOrder.typeText === "Move Out" ? 'Move Out Inspection' : workOrder.issue_type} </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Appointment Date")} </h6>
                                                    <span className="text-black fw-bold font-15"> {woFilterData.date} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Time")} </h6>
                                                    <span className="text-black fw-bold font-15"> {woFilterData.time} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-5 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Priority")} </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrder.Priority} </span>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14">
                                                        {workOrder.issueCategory === 'Clearance' ? (t('Service Request No.')) : (t('Ticket No.'))}
                                                    </h6>
                                                    <span className="text-black fw-bold font-15"> {workOrder.objectDetails?.ticketNo} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { (!compactView && workOrder.status === "Open" && user_role !== 'FC') &&
                                <>
                                    <div className="row border-top pt-10 mt-10">
                                        <h5 className="mb-5">{t("Issue Details")}</h5>
                                        <div className="col-12">
                                            <div className="row form-add-design">
                                                <div className="col-12">
                                                    <div className="mb-7">
                                                        <label className="form-label fw-bolder">{t("Description")} </label>
                                                        <div className="data-contain h-auto 75 px-5 py-4 rounded-10 mb-2">
                                                            <p className="font-14 fw-bold d-block mb-0">
                                                                {woFilterData.desc ? woFilterData.desc : '-'}
                                                            </p>
                                                        </div>
                                                        {/* <p>
                                                            {woFilterData.desc}
                                                        </p> */}
                                                        {/* <textarea
                                                            className='form-control fw-bolder form-control-solid bg-light'
                                                            data-kt-autosize='true'
                                                            rows={3}
                                                            placeholder={woFilterData.desc}
                                                            disabled
                                                        /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ClearanceWorkorderActions workOrder={workOrder} setPopupSuccessMsg={setPopupSuccessMsg}/>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            { (!compactView && isAssignedUser && workOrder.status === 'Open' && user_role === 'FC' ) &&
                <div className="row form-add-design mb-6">
                    <div className="col-12 text-end">
                        <Link to={{ 'pathname': '/facility-workorder/inspection', state: { 'workOrder': workOrder } }}>
                            <button
                                className='btn btn-primary fw-bold px-10 py-3'>
                                {t("Start Job")}
                            </button>
                        </Link>
                    </div>
                </div>
            }
        </div>
    );
}