import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../../constants'
import {useDispatch, useSelector} from 'react-redux'
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {formatDate} from '../../../../../constants/generics/dateTimeFormater'
import {currencyFormater} from '../../../../../constants/generics/customActios'

export function OfferDetails() {
  let offerList: any = []
  offerList = useSelector<RootState>(({General}) => General.offersRenewlaData)
  const {t} = useTranslation()

  return (
    <div className="com-card-section">
      <div className="row">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body px-8 py-6">
            <div className='row form-add-design'>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder'>{t('Tenant Name')}</label>
                    <div className='data-contain px-5 py-4 rounded-10'>
                      <h6 className='font-14 fw-bold d-block mb-0'>
                        {' '}
                        {offerList?.offer_details?.tenant_name
                          ? offerList?.offer_details?.tenant_name
                          : '-'}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder'>{t('Area(in sqmt)')}</label>
                    <div className='data-contain px-5 py-4 rounded-10'>
                      <h6 className='font-14 fw-bold d-block mb-0'>
                        {offerList?.property_details?.area
                          ? offerList?.property_details?.area
                          : '-'}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder'>{t('Lease Start Date')}</label>
                    <div className='data-contain px-5 py-4 rounded-10'>
                      <h6 className='font-14 fw-bold d-block mb-0'>
                        {offerList?.offer_details?.startDate
                          ? formatDate(offerList?.offer_details?.startDate)
                          : '-'}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder'>{t('Lease End Date')}</label>
                    <div className='data-contain px-5 py-4 rounded-10'>
                      <h6 className='font-14 fw-bold d-block mb-0'>
                        {offerList?.offer_details?.endDate
                          ? formatDate(offerList?.offer_details?.endDate)
                          : '-'}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder'>{t('Annual Rent')} </label>
                    <div className='data-contain px-5 py-4 rounded-10'>
                      <h6 className='font-14 fw-bold d-block mb-0'>
                        {t('AED')}{' '}
                        {offerList?.offer_details?.offerPrice
                          ? currencyFormater(offerList?.offer_details?.offerPrice)
                          : '-'}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder'>{t('Security Deposit')}</label>
                    <div className='data-contain px-5 py-4 rounded-10'>
                      <h6 className='font-14 fw-bold d-block mb-0'>
                        {t('AED')}{' '}
                        {offerList?.offer_details?.securityDeposit
                          ? currencyFormater(offerList?.offer_details?.securityDeposit)
                          : '-'}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  // return (
  //   <div className='row'>
  //     <div className='col-12'>
  //       <div className='accordion-item'>
  //         <h2 className='accordion-header' id='flush-headingSeven'>
  //           <button
  //             className='accordion-button collapsed'
  //             type='button'
  //             data-bs-toggle='collapse'
  //             data-bs-target='#offer-details'
  //           >
  //             {t('Offer Details')}
  //           </button>
  //         </h2>
  //         <div
  //           id='offer-details'
  //           className='accordion-collapse collapse show'
  //           data-bs-parent='#accordion-step-five'
  //         >
  //           <div className='accordion-body pb-5 pt-0'>
  //             {' '}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}
