import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {SearchOutlined} from '@ant-design/icons'
import {Menu, Button} from 'antd'
import {Popover} from 'antd'
import {Input} from 'antd'
import {getFacilityAccessCards} from '../../../../../constants/axios/apis'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../../../../constants/generics/userCheck'
import {RootState} from '../../../../../constants'
import {useTranslation} from 'react-i18next'

export function AccessCardTabs({searchItem, setSearchItem}: any) {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const location = useLocation()
  const {t} = useTranslation()

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='row align-items-center mb-4'>
              <div className='col-lg-3 col-sm-12 col-12'>
                <h4 className='card-title mb-4 font-18 text-black'> {t('Access Cards')}</h4>
              </div>
            </div>
            <div className='card mb-3'>
              <div className='card-body px-4 py-0'>
                <div className='row access-cd-tabs'>
                  <div className='col-md-8 col-12'>
                    <div className='d-flex py-2'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link position-relative text-active-primary ` +
                              (location.pathname === '/access-cards/open' && 'active')
                            }
                            to='/access-cards/open'
                          >
                            {t('Open')}
                          </Link>
                        </li>
                        {/* /access-cards/closed -> Active url and /access-cards/active -> In-active       
                        for inactive file name is ActiveList.tsx and active file name is InActive.tst         */}
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link position-relative text-active-primary ` +
                              (location.pathname === '/access-cards/closed' && 'active')
                            }
                            to='/access-cards/closed'
                          >
                            {t('Active')}
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link position-relative text-active-primary  ` +
                              (location.pathname === '/access-cards/active' && 'active')
                            }
                            to='/access-cards/active'
                          >
                            {t('In-Active')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-12 my-md-auto my-4'>
                    <div className='d-flex pm-topbar-buttons float-end'>
                      <div className='form-group has-search'>
                        <span className='fa fa-search pt-2 form-control-feedback'></span>
                        <input
                          type='text'
                          className='form-control ps-10'
                          onChange={(e) => setSearchItem(e.target.value)}
                          placeholder={t('Search')}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-md-4 col-12'>
                  <div className='fcss-search text-end'>
                    <Popover placement="left" overlayClassName='header-drop' content={searchBox} trigger="click" className='fcss-popover'>
                      <Button htmlType="submit" className="header-search-btn py-0 px-3 rounded">
                        <SearchOutlined />
                      </Button>
                    </Popover>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
