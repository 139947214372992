import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import PlusIcon from '../../../../../assets/images/plus-circle-icon.png'
import TotalImg1 from '../../../../../assets/images/total-icon-fill1.svg'
import TotalImg2 from '../../../../../assets/images/total-icon-fill2.svg'
import TotalImg3 from '../../../../../assets/images/total-icon-fill3.svg'

export function ParkingInfo({count}: any) {
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-6 col-6'>
          <h4 className='card-title font-18 text-black mb-4'> Parking </h4>
        </div>
        {/* <div className='col-md-6 col-6 text-end mb-4'>
          <Link to={'/parking/add-parking'} className='btn btn-primary py-2'>
            <img src={PlusIcon} className='img-fluid me-2' alt='Plus' />
            Add
          </Link>
        </div> */}
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='total-parking-info'>
                <div className='row gx-10'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-5'>
                    <div className='card'>
                      <div className='card-body py-6 px-6'>
                        <div className='row align-items-center'>
                          <div className='col-3'>
                            <div className='icon-sh px-0 py-3 rounded-5 text-center'>
                               <img src={TotalImg1} className='img-fluid' alt='total-parking' />
                            </div>
                          </div>
                          <div className='col-9 text-center'>
                            <h6 className='font-34 text-black fw-bolder mb-0'>
                              {count?.total} <span className='font-14 fw-normal'> Total </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-5'>
                    <div className='card'>
                      <div className='card-body py-6 px-6'>
                        <div className='row align-items-center'>
                          <div className='col-3'>
                            <div className='icon-sh px-0 py-3 rounded-5 text-center'>
                              <img src={TotalImg2} className='img-fluid' alt='total-parking' />
                            </div>
                          </div>
                          <div className='col-9 text-center'>
                            <h6 className='font-34 text-black fw-bolder mb-0'>
                              {count?.available}{' '}
                              <span className='font-14 fw-normal'> Available </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-0'>
                    <div className='card'>
                      <div className='card-body py-6 px-6'>
                        <div className='row align-items-center'>
                          <div className='col-3'>
                            <div className='icon-sh px-0 py-3 rounded-5 text-center'>
                              <img src={TotalImg3} className='img-fluid' alt='total-parking' />
                            </div>
                          </div>
                          <div className='col-9 text-center'>
                            <h6 className='font-34 text-black fw-bolder mb-0'>
                              {count?.booked} <span className='font-14 fw-normal'> Booked </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
