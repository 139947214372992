export function Unit({chooseMessage}:any) {
    return (
        <>
        <div className="card mb-6">
            <div className="card-body">
            <div className="row">
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Bedrooms</label>
                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                        <option selected> Please Select </option>
                        <option> 1</option>    
                        <option> 2</option>    
                        <option> 3 </option>    
                        <option> 4</option>   
                        <option> 6+</option>          
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Bathrooms</label>
                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                        <option selected> Please Select </option>
                        <option> 1</option>    
                        <option> 2</option>    
                        <option> 3 </option>    
                        <option> 4</option>   
                        <option> 6+</option>          
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Build Up Area</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Plot Area</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Furnicing Status</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Bathrooms</label>
                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select Community" required>
                        <option selected> Please Select </option>
                        <option> 0</option> 
                        <option> 1</option>     
                        <option> 2</option>    
                        <option> 3 </option>    
                        <option> 4</option>   
                        <option> 6+</option>          
                    </select>
                </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6">
                <div className="mb-7">
                    <label className="d-block w-100 text-uppercase mb-0 font-13">Parking Area</label>
                    <input type="text" className="form-control font-13 form-height" name="name" placeholder="Area" value="" required/>
                </div>
            </div>

        </div>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className='col-12'>
                            <label className='font-14 fw-bold mb-3 text-uppercase'>Description</label>
                            <textarea
                                className='form-control'
                                data-kt-autosize='true'
                                rows={3}
                                placeholder={'Description'}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h3 className="my-4"> Add Some Details</h3>
        <div className="card">
            <div className="card-body">
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed accodion-tab" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Community Amenities
                    </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Guest Parking
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                        <label className="form-check-label">
                                            Gym
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed accodion-tab" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Additional Amenities
                    </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed accodion-tab" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        Near By
                    </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="text-end my-6">
            <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('1')}>Previous</button>
            <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('3')}>Next</button>
        </div>
        </>
    )
}