import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {DocumentTabs} from './components/DocumentTabs/DocumentTabs'
import {EmiratesID} from './components/EmiratesID/EmiratesID'
import {Passport} from './components/Passport/Passport'
import {DrivingLicense} from './components/DrivingLicense/DrivingLicense'
import {Route, Switch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../constants'
import * as documents from '../../constants/redux/Sagas/Tenant/TenantDocumentsSaga'
import { useTranslation } from 'react-i18next';

const Documents: FC = () => {
  const { t } = useTranslation();

  return(
    <>
      <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <h4 className='card-title font-18 text-black mb-6 fw-bolder'> {t("My Documents")} </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <Switch>
                <Route path='/documents/emirates-id'>
                  <DocumentTabs />
                  <EmiratesID />
                </Route>
                <Route path='/documents/passport'>
                  <DocumentTabs />
                  <Passport />
                </Route>
                <Route path='/documents/driving-license'>
                  <DocumentTabs />
                  <DrivingLicense />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}


const DocumentsPage: FC = () => {
  const intl = useIntl()
  let myDocs: any = []
  myDocs = useSelector<RootState>(({Tenantdocuments}) => Tenantdocuments.mydocsuploaded)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(documents.actions.mydocsuploaded())
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}> My Documents </PageTitle>
      <Documents />
    </>
  )
}

export {DocumentsPage}
