import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { getUserRole } from '../../../../constants/generics/userCheck';

import { useTranslation } from 'react-i18next';

export function ClearanceTabs() {

    const location = useLocation();
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className='d-flex subtabs'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link position-relative text-active-primary ` +
                                (location.pathname === '/facility-workorder/list/clearance/open' && 'actives')}
                                to='/facility-workorder/list/clearance/open'>
                                {t("Open")}
                            </Link>
                        </li>
                        {user_role !== 'FM' &&
                            <li className='nav-item'>
                                <Link
                                    className={`nav-link position-relative text-active-primary ` +
                                    (location.pathname === '/facility-workorder/list/clearance/confirmed' && 'actives')}
                                    to='/facility-workorder/list/clearance/confirmed'>
                                    {t("Confirmed")}
                                </Link>
                            </li>
                        }
                        <li className='nav-item'>
                            <Link
                                className={`nav-link position-relative text-active-primary ` +
                                (location.pathname === '/facility-workorder/list/clearance/completed' && 'actives')}
                                to='/facility-workorder/list/clearance/completed'>
                                {t("Completed")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}