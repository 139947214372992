import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../assets/images/Left_Arrow.png'
import { Buildings } from '../../fc-soft-services-services/directory/components/UserDetailsWrapper/Buildings';
import { Profile } from '../../fc-soft-services-services/directory/components/UserDetailsWrapper/Profile';
import { ProfileDetails } from '../../fc-soft-services-services/directory/components/UserDetailsWrapper/ProfileDetails';

import { getFcsWorkOrders } from '../../../utils/makeRequest';
import { IState } from '../../../utils/interface';
import { useSelector } from 'react-redux';
import { UserDetailsChart } from './UserDetailsChart/UserDetailsChart';
import { MyTeamGraphWrapper } from '../components/MyTeamGraph/MyTeamGraphWrapper';
import { UserRecentWorkOrders } from './UserRecentWorkOrders/UserRecentWorkOrders';
import { SubMembers } from '../../fc-soft-services-services/directory/components/UserDetailsWrapper/SubMembers';
import { getUserRole } from '../../../constants/generics/userCheck';
import { useTranslation } from 'react-i18next';

export function FCSMyTeamDetailsWrapper() {
    const { t } = useTranslation();
    const history = useHistory()
    let params: any = {};
    params = useLocation();
    const { user, prevPath='/' } = params.state || {}

    const state:any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    const [fcsWorkOrderList, setFcsWorkOrderList] = useState<IState['APIState']>({
        loading: true,
        response: [],
        error: ''
    });
    const [filteredWorkOrdersList, setFilteredWorkOrderList] = useState<IState['APIState']>({
        loading: true,
        response: [],
        error: ''
    });
    useEffect(()=>{
        getFcsWorkOrders(setFcsWorkOrderList,userToken);
    },[]);
    useEffect(()=>{
        const filteredWorkOrders = fcsWorkOrderList?.response?.filter((item:any)=> (item.assignedToUserId && item.assignedToUserId === user.id))
        // console.log(filteredWorkOrders, 'filteredWorkOrders');
        
        setFilteredWorkOrderList({
            loading: fcsWorkOrderList.loading,
            response: filteredWorkOrders,
            error: fcsWorkOrderList.error
        });
    },[fcsWorkOrderList]);

    // console.log(fcsWorkOrderList, 'fcsWorkOrderList');
    // console.log(filteredWorkOrdersList, 'filteredWorkOrdersList');
    
    // const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    return (
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="com-card-section">
                    <div className="row mb-6 align-items-center">
                        <div className='col-md-6 col-6'>
                            <h4 className='card-title font-18 text-black m-0'>
                                {/* <Link to={prevPath} className=''>
                                </Link> */}
                                <span onClick={()=>history.goBack()} style={{cursor:'pointer'}}>
                                    <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                                </span>
                                <span className="text-black">{t("Details")}</span>
                            </h4>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                            <Link 
                                to={{'pathname':'/edit-member', state: {'user': user} }}
                                className="btn btn-primary btn-sm font-16 me-5">
                                <i className="bi bi-pencil me-2"></i> {t("Edit")}
                            </Link>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body px-5 py-5">
                                    <div className="row">
                                        <div className="col-lg-3 col-12 mb-lg-0 mb-5">
                                            <Profile userData={user} />
                                        </div>
                                        <div className="col-lg-9 prof-det col-12">
                                            <ProfileDetails userData={user} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-5">
                            <div className="card">
                                <div className="card-body px-5 pt-5 pb-0">
                                    <div className="row">
                                        <Buildings community={user?.community} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (user_role === 'FMT' && user.user_role === 10) &&
                            <SubMembers userID={user.id} filterRole={'FC'}/>
                        }

                        {user.staff_type === 'Facility Contractor' && 
                            <>
                                <div className='col-12 mt-5'>
                                    <div className="row mb-6">
                                        <div className="col-sm-5 col-12">
                                            <div className="card mb-3 technicians_card">
                                                <div className="card-body px-6 py-5 pb-0">
                                                    <h4 className="card-title font-18 text-black"> {t("Work Orders")} </h4>
                                                    <UserDetailsChart workOrderList={filteredWorkOrdersList} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 col-12">
                                            <div className="card myteamgraph_card ms-0 ms-lg-3">
                                                <div className="card-body px-6 pt-0 pb-13">
                                                    <MyTeamGraphWrapper fcsWorkOrderList={filteredWorkOrdersList} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-5 mb-6">
                                    <UserRecentWorkOrders fcsWorkOrderList={filteredWorkOrdersList} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}