import React, { useState, useEffect } from 'react';
import ban01 from "../../../assets/images/login-img.png";
// import LoginContain from "./Login.style";

export function LoginLeftBanner() {
    return (
        <div>
            <img alt='Logo' src={ban01} className='img-fluid w-100' />
        </div>
    );
}