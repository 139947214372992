import { intersection } from 'lodash';

// export function isArrayWithLength(arr:any) {
//     return (Array.isArray(arr) && arr.length)
// }

// const apiMenuResFC = {
//     mainMenu: [
//         {   
//             menu_Id: 'workorder',
//             indexPos: 1,
//             subMenu: []
//         },
//         {
//             menu_Id: 'announcement',
//             indexPos: 2,
//             subMenu: []
//         },
//         {
//             menu_Id: 'report',
//             indexPos: 3,
//             subMenu: []
//         },
//         {
//             menu_Id: 'calendar',
//             indexPos: 4,
//             subMenu: []
//         }
//     ]
// }

export function getAllowedRoutes(routes: any, userRoleCode: any, allPermissions: any) {
    // console.log("allPermissions", allPermissions?.permissions?.menu?.length, allPermissions)

    // allPermissions?.errorCode != 1 && allPermissions?.permissions?.menu?.length > 0 &&
    //             allPermissions?.permissions?.menu?.map((access: any, index: any) => {
    //               if (access?.name === "Dashboard" && access?.permission?.read == 0) {

    //                 return (<div className='dash-icon-menu'>
    //                   <AsideMenuItem
    //                     to='/dashboard'
    //                     icon='/media/icons/Dashboard_InActive.png'
    //                     title={t("Dashboard")}
    //                   />
    //                 </div>)
    //               }
    //             })








    // const roles = JSON.parse(localStorage.getItem('roles')); //TODO
    // const roles = ['FC']

    // const apiMenuRes = userRoleCode === 'FC' ? apiMenuResFC.mainMenu:
    //     userRoleCode === 'FCS' ? apiMenuResFCS.mainMenu : null

    const apiMenuRes = allPermissions?.permissions?.menu

    // console.log(apiMenuRes, 'permissions?.menu');


    const allowedRoutes = routes.filter(({ permission, menuCode, menu }: any) => {
        let isAllowed = false
        if ((permission && permission.includes(userRoleCode))) {
            if (apiMenuRes && Array.isArray(apiMenuRes)) {
                apiMenuRes.forEach((item: any) => {
                    if ((item?.name).toUpperCase() === menuCode?.toUpperCase() && item?.permission?.read === 1)
                        isAllowed = true
                });
            }
        }
        return isAllowed
    })

    return allowedRoutes

    // return routes.filter(({ permission }:any) => {
    //     if(!permission) return true;
    //     else if(!isArrayWithLength(permission)) return true;
    //     else return intersection(permission, roles).length;
    // });
}

export function getAllowedProfileRoutes(routes: any, userRoleCode: any, allPermissions: any) {
    const apiMenuRes = allPermissions?.permissions?.profile

    // console.log(apiMenuRes, 'permissions?.menu');


    const allowedRoutes = routes.filter(({ permission, menuCode, menu }: any) => {
        let isAllowed = false
        if ((permission && permission.includes(userRoleCode))) {
            if (apiMenuRes && Array.isArray(apiMenuRes)) {
                apiMenuRes.forEach((item: any) => {
                    if ((item?.name).toUpperCase() === menuCode?.toUpperCase() && item?.permission?.read === 1)
                        isAllowed = true
                });
            }
        }
        return isAllowed
    })

    return allowedRoutes

    // return routes.filter(({ permission }:any) => {
    //     if(!permission) return true;
    //     else if(!isArrayWithLength(permission)) return true;
    //     else return intersection(permission, roles).length;
    // });
}