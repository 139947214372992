import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { EmailTempalteList } from './EmailTemplate/EmailTemplateList'
import { AddEmailTemplate } from './EmailTemplate/AddEmailTemplate'
import { EditEmailTemplate } from './EmailTemplate/EditEmailTemplate'
import { EditTemplatePreview } from './EmailTemplate/EmailTemplatePreview'
import { MessagesPage } from './Messages/Messages'

const PMEmailTemplateWrapper: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/communication/email-templates'>
                            <EmailTempalteList />
                        </Route>
                        <Route path='/communication/addemail-templates'>
                            <AddEmailTemplate />
                        </Route>
                        <Route path='/communication/editemail-templates'>
                            <EditEmailTemplate />
                        </Route>
                        <Route path='/communication/email-templates-preview'>
                            <EditTemplatePreview/>
                        </Route>
                        <Route path='/communication/messages'>
                            <MessagesPage/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMEmailTemplate: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMEmailTemplate />

        </>
    )
};

export { PMEmailTemplateWrapper }

