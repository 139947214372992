import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/
export const GET_MYDOCS = `${API_URL}/getMyDoc`
export const POST_MYDOCS = `${API_URL}/addMyDoc`
export const DELETE_MYDOCS = `${API_URL}/deleteMyDoc`

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  MyDocsUploaded: '[MyDocsUploaded] Action',
  DocsUpload: '[DocsUpload] Action',
  DeleteDocs: '[DeleteDocs] Action',
  ResetState: '[ResetState] Action',

  MyDocsLoaded: '[Load MyDocs] Load data',
  DocsUploadLoaded: '[Load DocsUpload Loaded] Load data',
  DeleteDocsLoaded: '[Load DeleteDocs Loaded] Load data',
}
const initialTenantDocumentsState: ITenantDocumentsState = {
  mydocsuploaded: undefined,
  docsupload: undefined,
  deletedocs: undefined,
}
export interface ITenantDocumentsState {
  mydocsuploaded?: ''
  docsupload?: ''
  deletedocs?: ''
}

export const reducer = persistReducer(
  {
    storage,
    key: 'Tenantdocuments',
    blacklist: ['mydocsuploaded'],
  },
  (
    state: ITenantDocumentsState = initialTenantDocumentsState,
    action: ActionWithPayload<ITenantDocumentsState>
  ) => {
    switch (action.type) {
      case actionTypes.MyDocsUploaded: {
        const mydocsuploaded = action.payload?.mydocsuploaded
        return {...state, mydocsuploaded}
      }
      case actionTypes.MyDocsLoaded: {
        const mydocsuploaded = action.payload?.mydocsuploaded
        return {...state, mydocsuploaded}
      }
      case actionTypes.DocsUpload: {
        const docsupload = action.payload?.docsupload
        return {...state, docsupload}
      }
      case actionTypes.DocsUploadLoaded: {
        const docsupload = action.payload?.docsupload
        return {...state, docsupload}
      }
      case actionTypes.DeleteDocs: {
        const deletedocs = action.payload?.deletedocs
        return {...state, deletedocs}
      }
      case actionTypes.DeleteDocsLoaded: {
        const deletedocs = action.payload?.deletedocs
        return {...state, deletedocs}
      }
      case actionTypes.ResetState: {
        const docsupload = action.payload?.docsupload
        return {docsupload}
      }
      default:
        return state
    }
  }
)
export function getMyDocs() {
  return axios.get(GET_MYDOCS)
}
export function postMyDocs(data: any) {
  return axios.post(POST_MYDOCS, data)
}
export function deelteMyDocs(id: any) {
  return axios.delete(DELETE_MYDOCS + '/' + id)
}
export const actions = {
  mydocsuploaded: () => ({type: actionTypes.MyDocsUploaded}),
  fulfillmydocsuploaded: (mydocsuploaded: '') => ({
    type: actionTypes.MyDocsLoaded,
    payload: {mydocsuploaded},
  }),

  docsupload: (data: any) => ({type: actionTypes.DocsUpload, payload: {data}}),
  fulfillMyDocsUpload: (docsupload: '') => ({
    type: actionTypes.DocsUploadLoaded,
    payload: {docsupload},
  }),
  deletedocs: (id: any) => ({type: actionTypes.DeleteDocs, payload: {id}}),
  fulfillDeleteDocs: (deletedocs: '') => ({
    type: actionTypes.DeleteDocsLoaded,
    payload: {deletedocs},
  }),
  resetState: () => ({type: actionTypes.ResetState}),
}
export function* TenantDoumentssaga() {
  yield takeLatest(actionTypes.MyDocsUploaded, function* userRequested() {
    const {data: myDocs} = yield getMyDocs()
    yield put(actions.fulfillmydocsuploaded(myDocs))
  })
  yield takeLatest(actionTypes.DocsUpload, function* userRequested(datas: any) {
    const {data: docsupload} = yield postMyDocs(datas.payload.data)
    yield put(actions.fulfillMyDocsUpload(docsupload))
  })
  yield takeLatest(actionTypes.DeleteDocs, function* userRequested(id: any) {
    const {data: deletedocs} = yield deelteMyDocs(id.payload.id)
    yield put(actions.fulfillDeleteDocs(deletedocs))
  })
}
