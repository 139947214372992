import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import PlusIcon from '../../../../../assets/images/plus-circle-icon.png'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../../../../constants/generics/userCheck'
import { useTranslation } from 'react-i18next';

export function VisitorTabs({searchItem, setSearchItem}: any) {
  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)
  const { t } = useTranslation();
  const location = useLocation()

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='row mb-4'>
            <div className='col-md-6 col'>
              <h4 className='card-title mb-4 font-18 text-black'> {t("Visitors")} </h4>
            </div>
            {user_role === 'FCSS' && (
              <div className='col-md-6 col-auto text-end'>
                <Link to={'/visitors/add'} className='btn btn-primary py-2'>
                  <img src={PlusIcon} className='img-fluid me-2' alt='Plus' />
                  {t("Add")}
                </Link>
              </div>
            )}
          </div>
          <div className='card mb-3'>
            <div className='card-body px-4 py-0'>
              <div className='row access-cd-tabs'>
                <div className='col-md-6 col-12'>
                  <div className='d-flex py-2'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/visitors/all' && 'active')
                          }
                          to='/visitors/all'
                        >
                          {t("All")}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/visitors/check-in' && 'active')
                          }
                          to='/visitors/check-in'
                        >
                          {t("Checked In List")}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/visitors/check-out' && 'active')
                          }
                          to='/visitors/check-out'
                        >
                          {t("Checked Out List")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                  <div className='form-group has-search'>
                    <span className='fa fa-search pt-2 form-control-feedback'></span>
                    <input
                      type='text'
                      className='form-control ps-10'
                      onChange={(e) => setSearchItem(e.target.value)}
                      placeholder={t('Search')}
                    />
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

