import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import Home from '../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import {currencyFormater} from '../../../../constants/generics/customActios'
import {formatDate} from '../../../../constants/generics/dateTimeFormater'

export function BasicDetails({receiptDetail}: any) {
  const {t} = useTranslation()
  const history = useHistory()
  const building_img = receiptDetail?.communityLogo ? receiptDetail?.communityLogo : Home

  return (
    <>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <span
              className='c-pointer'
              onClick={() => { history.goBack() }}
            >
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </span>
            <span className='text-black'>{t('Invoice Details')}</span>
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row align-items-center'>
                <div className='col-md-4 col-lg-3 text-xxl-center mb-6 mb-sm-0'>
                  <div className="invoice-details-img">
                    <img src={building_img} className='img-fluid' />
                  </div>
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white shadow-none rounded-0'>
                    <div className='card-body p-0'>
                      <div className='row'>
                        <div className='col-sm-12 col-md-12 col-12 mb-7'>
                          {/* <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                      
                                                  </h6> */}
                          <span className='mb-3 d-block text-black fw-bold font-14'>
                            {t('Lease Number')} :{' '}
                            {receiptDetail?.leaseNumber ? receiptDetail?.leaseNumber : '-'}
                          </span>
                        </div>
                        <div className='col-sm-6 col-md-4 col-6 mb-7'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {t('Tenant Name')}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {receiptDetail?.tenantName ? receiptDetail?.tenantName : '-'}
                            </span>
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-6 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>{t('Unit')}</h6>
                          <span className='text-black fw-bold font-14'>
                            {receiptDetail?.propertyCode ? receiptDetail?.propertyCode : '-'}
                          </span>
                        </div>
                        <div className='col-sm-6 col-md-4 col-6 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Lease Start Date')}
                          </h6>
                          <span className='text-black fw-bold font-14'>
                            {receiptDetail?.leaseStartDate
                              ? formatDate(receiptDetail?.leaseStartDate)
                              : '-'}
                          </span>
                        </div>
                        <div className='col-sm-6 col-md-4 col-6 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Lease End Date')}
                          </h6>
                          <span className='text-black fw-bold font-14'>
                            {receiptDetail?.leaseEndDate
                              ? formatDate(receiptDetail?.leaseEndDate)
                              : '-'}
                          </span>
                        </div>
                        <div className='col-sm-6 col-md-4 col-6 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Annual Rent')}
                          </h6>
                          <span className='text-black fw-bold font-14'>
                            {t('AED')}{' '}
                            {receiptDetail?.annualRent ? currencyFormater(receiptDetail?.annualRent) : '-'}
                          </span>
                        </div>
                        <div className='col-sm-6 col-md-4 col-6 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Security Deposit')}
                          </h6>
                          <span className='text-black fw-bold font-14'>
                            {t('AED')}{' '}
                            {receiptDetail?.security_deposit
                              ? currencyFormater(receiptDetail?.security_deposit)
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
