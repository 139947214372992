import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import Home from "../../../../assets/images/nation-towers-new.png";
import { pmGetLeadsList } from '../../../../constants/axios/apis';
import { currencyFormater, CustomLoader, NoDataFound } from '../../../../constants/generics/customActios';
import { formatDate } from '../../../../constants/generics/dateTimeFormater';
import { Status } from './Status';
import {useHistory} from 'react-router-dom'

type LocationState = {
    id?: any
}

export function LeadsDetails({setPopupSuccessMsg}: any) {
    const location = useLocation<LocationState>();
    const history = useHistory()
    const { id } = location.state || {};
    const [leadsData, setLeadsData] = useState<any>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (id) {
            const qry = `id=${id} `;//building_id=${currentBuilding}
            pmGetLeadsList(qry)
                .then((response: any) => {
                    setLoading(false)
                    if (response?.status === 200) {
                        setLeadsData(response?.data)
                    } else {
                        setLeadsData(response)
                    }
                })
                .catch((e) => {
                })
        } else {
            // console.log("no id found");
        }
    }, [id])
    if (leadsData.length === 0 || loading) {
        return <CustomLoader />
    }
    if (leadsData?.errorDescription === 'No data found.') {
        return <NoDataFound />
    }
    return (
        <><div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-6">
                        <div className="col-lg-4 col-sm-12">
                            <h4 className="card-title font-18 text-black mb-0">
                                {/* <Link to={'/pm-leads/new-lead'} className=''> */}
                                <span
                                    className='c-pointer'
                                    onClick={() => { history.goBack() }}
                                >
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                    {/* </Link> */}
                                </span>
                                Leads Details
                            </h4>
                        </div>
                        <div className="col-lg-8 col-sm-12">

                        </div>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-3 text-xxl-center">
                                    <img src={leadsData?.data[0]?.property_cover_img ? leadsData?.data[0]?.property_cover_img : Home} className="img-fluid leads-details-img" />
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white shadow-none rounded-0">
                                        <div className="card-body p-5">
                                            <div className="col-md-8 col-12 mb-7">
                                                <h6 className="text-black mb-0 fw-bold font-15"> {leadsData?.data[0]?.property_name ? leadsData?.data[0]?.property_name : '-'} </h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 col-12 mb-7">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> Created At </h6>
                                                        <span className="text-black fw-bold font-14"> {leadsData?.data[0]?.createdAt ? formatDate(leadsData?.data[0]?.createdAt) : '-'}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6 col-12 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Annual Rent </h6>
                                                    <span className="text-black fw-bold font-14"> AED {leadsData?.data[0]?.price ? currencyFormater(leadsData?.data[0]?.price) : '-'} </span>
                                                </div>
                                                <div className="col-md-4 col-sm-6 col-12">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Property Type</h6>
                                                    <span className="text-black fw-bold font-14"> {leadsData?.data[0]?.propertyType ? leadsData?.data[0]?.propertyType : '-'} </span>
                                                </div>
                                                <div className="col-md-12 col-12">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Location</h6>
                                                    <span className="text-black fw-bold font-14">
                                                        {/* {leadsData?.data[0]?.Address ? leadsData?.data[0]?.Address + ', ' : " "}  {leadsData?.data[0]?.location}  */}
                                                        {leadsData?.data[0]?.propertyCode?.substring(leadsData?.data[0]?.propertyCode?.lastIndexOf("-") + 1, leadsData?.data[0]?.propertyCode?.length) + ' | '} {leadsData?.data[0]?.building_name && leadsData?.data[0]?.building_name + ' | '}  {leadsData?.data[0]?.Address && leadsData?.data[0]?.Address + ' | '}  {leadsData?.data[0]?.location && leadsData?.data[0]?.location}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><Status leadsData={leadsData?.data[0]} setPopupSuccessMsg={setPopupSuccessMsg}/></>
    );
}