import React, { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../components/layout/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MerchantList } from './components/MerchantList/MerchantList';
import { MerchantView } from './components/MerchantView/MerchantView';

type Props  = {
    // categoryList:any
}

const MerchantWrapper: FC<Props> = () => (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <Switch>
                    <Route path='/merchant/list'>
                        <MerchantList />
                    </Route>
                    <Route path='/merchant/view'>
                        <MerchantView />
                    </Route>
                    <Redirect to='/error/403' />
                </Switch>
            </div> 
        </div>
    </>
);


const MTAMerchantsWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Market Place</PageTitle>
            <MerchantWrapper />
        </>
    )
};

export { MTAMerchantsWrapper }
