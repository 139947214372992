import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import Propertie from '../../../../assets/images/latest-one.png'
import bed from '../../../../assets/images/icon-bed.svg'
import Bath from '../../../../assets/images/icon-bath.svg'
import Place from '../../../../assets/images/icon_place.svg'
import SQR from '../../../../assets/images/icon_sqr.svg'
import Yes from '../../../../assets/images/icon-parking.svg'
import {SelectTeamMemberPopup} from '../ActionRequired/SelectTeamMemberPopup'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../constants'
import * as PM from '../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import {
  currencyFormater,
  CustomLoader,
  NoDataFound,
} from './../../../../constants/generics/customActios'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import {useTranslation} from 'react-i18next'
import {CompactPagination} from '../../../../components/partials/content/CompactPagination/CompactPagination'

const PMMyteamActionRequired: React.FC = () => {
  const location = useLocation()
  const {state} = useLocation()
  const searchType: any = state
  const {t} = useTranslation()
  const [loading, setLoading] = useState<any>(true)
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)
  const dispatch = useDispatch()
  let proprtyList: any
  proprtyList = useSelector<RootState>(({PM}) => PM.proprtylist)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [pageSize, setPageSize] = useState<any>(10)
  useEffect(() => {}, [currentBuilding])
  useEffect(() => {
    dispatch(
      PM.actions.proprtylist(
        `available?currentPage=${currentPage}&pageSize=${pageSize}&building_id=${currentBuilding}&isAssigned=0`
      )
    )
  }, [, currentBuilding, currentPage])
  const handleClick = (newCurrentPage: any) => {
    setCurrentPage(newCurrentPage)
  }
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleClick(value)
  }
  const handleAssign = (property_id: any) => {
    dispatch(General.actions.pid(property_id))
  }
  if (proprtyList === undefined) {
    return <CustomLoader />
  }
  return (
    <div className='col-12 mt-n6'>
      <div className='row access-cd-tabs align-items-center'>
        <div className='col-12'>
          <h4 className='card-title font-18 text-black mb-0'>
            <Link to={{pathname: searchType?.from, state: {id: searchType?.id}}} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              <span className='text-black'>{t('Action Required')}</span>
            </Link>
          </h4>
        </div>
        <div className='col-12 mt-7'>
          <div className='card'>
            <div className='card-body px-5 pt-5 pb-0'>
              <div className='row'>
                <div className=''>
                  {proprtyList?.code === 200 && proprtyList?.errorCode != 1 && Array.isArray(proprtyList?.data) && proprtyList?.data?.length > 0 ? (
                    proprtyList?.data?.map((item: any, index: number) => {
                      if (item?.is_assigned != 1)
                        return (
                          <div className='col-12'>
                            <div className='myteam_actionRequired mb-5'>
                              <div className='card px-3 py-3'>
                                <div className='row align-items-center'>
                                  <div className='col-md-2 col-sm-12 col-12'>
                                    <div className='offer_main_img'>
                                      <img
                                        src={
                                          item?.coverImage?.length > 0
                                            ? item?.coverImage
                                            : Propertie
                                        }
                                        alt='Work Orders'
                                        className='img-fluid'
                                      />
                                    </div>
                                  </div>
                                  <div className='col-md-7 col-sm-12 col-12'>
                                    <div className='actionRequired_details'>
                                      <div className='col-12'>
                                        <p className='text-black fw-bolder text-black font-18 villa-text'>
                                          {item?.property_name ? item?.property_name : '-'}
                                        </p>
                                      </div>
                                      <div className='col-12'>
                                        <div className='offer_main_details_div_2'>
                                          <h4 className='text-black fw-bolder me-5'>
                                            <small>{t('AED')}</small>{' '}
                                            <span className='fw-boldest font-20 villa-text'>
                                              {item?.price ? currencyFormater(item.price) : '-'}
                                            </span>
                                          </h4>
                                          <p>
                                            <img src={bed} className='img-fluid me-3' />
                                            {item?.bedrooms ? item?.bedrooms : '-'}
                                          </p>
                                          <p>
                                            <img src={Bath} className='img-fluid me-3' />
                                            {item?.bathrooms ? item?.bathrooms : '-'}
                                          </p>
                                          <p className='sqft'>
                                            <img src={SQR} className='img-fluid me-3' />
                                            {item?.builtInArea ? item?.builtInArea : '-'}{' '}
                                            {t('Sqmt')}
                                          </p>
                                          <p>
                                            <img src={Yes} className='img-fluid me-3' />
                                            {item?.is_parking_available === 'Y'
                                              ? t('Yes')
                                              : t('No')}
                                          </p>
                                        </div>
                                      </div>
                                      <div className='col-12 offer_main_details_div_3'>
                                        <p className='mb-0 pt-2'>
                                          <img
                                            src={Place}
                                            width='12'
                                            height='17'
                                            className='img-fluid me-3'
                                          />
                                          {item?.propertyType ? item?.propertyType : '-'} |{' '}
                                          {item?.address ? item?.address : '-'} |{' '}
                                          {item?.location ? item?.location : '-'}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className='col-md-3 col-sm-12 col-12 text-end'
                                    onClick={() => handleAssign(item?.id)}
                                  >
                                    <SelectTeamMemberPopup />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    })
                  ) : (
                    <div className='row'>
                      <NoDataFound />
                    </div>
                  )}
                  <div className='w-100 d-flex justify-content-end'>
                    {/* {
                      proprtyList?.data?.length > 0 && (
                        <Stack spacing={2}>
                          <Pagination
                            page={currentPage}
                            count={
                              proprtyList?.dataCount % pageSize === 0
                                ? proprtyList?.dataCount / pageSize
                                : Math.ceil(proprtyList?.dataCount / pageSize)
                            }
                            onChange={handlePageChange}
                          />
                        </Stack>
                      )
                      } */}
                       <CompactPagination
                          pageSize={pageSize}
                          totalCount={parseInt(proprtyList?.dataCount)}
                          defaultPage={currentPage}
                          onPageChange={setCurrentPage}
                          loading={false}
                      />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PMMyteamActionRequired}
