import React,{FC, useState, useEffect} from 'react'
import Loader from '../../../../components/Loader';
import { VerticalGraph } from '../../../fc-supervisor-report/VerticalGraph';
import { Bar } from 'react-chartjs-2';

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Top Agents',
      },
      datalabels: {
        display: false
      },
    },
  };
  
  const labels = ['Matthew', 'Troy', 'Davis', 'Carolyn', 'Wolfe', 'Caitlin', 'Harris', 'Steven'];
  
  export const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: [92, 60, 72, 54, 40, 28, 20, 15],
        backgroundColor: '#070707',
        textColor: '#070707'
      },
    ],
  };

export const Summary = (props:any) => {

    return(
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h5 className="card-title font-weight-bold text-black">
                        Top Agents
                    </h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Bar options={options} data={data} />
                </div>
            </div>
        </div>
    )
}

export const Summary2:FC<any> = () => {
    const reportState = {
        loading : false,
        response: {},
        error :''
    };
    const reportType = '';
    const { loading, response: reports, error } = reportState;

    const [reportVal, setReportVal] = useState({
        corrective: {
            val: 10,
            rem: 30 
        },
        clearance: {
            val: 20,
            rem: 20
        },
        preventive: {
            val: 30,
            rem: 10
        },
        tempval1: {
            val: 40,
            rem: 0
        },
        tempval2: {
            val: 25,
            rem: 0
        },
        tempval3: {
            val: 15,
            rem: 0
        }        
    });

    // useEffect(()=>{
    //     if(Object.keys(reportState.response).length > 0){

    //         const max = Math.max.apply(Math, [
    //             reportState.response.corrective,
    //             reportState.response.clearance,
    //             reportState.response.preventive
    //         ]);
    //         let total = 0
    //         if(reportType ===  '')
    //             total = reportState.response.corrective + reportState.response.clearance + reportState.response.preventive
    //         else if(reportType === 'corrective')
    //             total = reportState.response.corrective
    //         else if(reportType === 'clearance')
    //             total = reportState.response.clearance
    //         else if(reportType === 'preventive')
    //             total = reportState.response.preventive

    //         setReportVal(
    //             {
    //                 corrective: {
    //                     val: reportState.response.corrective,
    //                     rem: total - reportState.response.corrective
    //                 },
    //                 clearance: {
    //                     val: reportState.response.clearance,
    //                     rem: total - reportState.response.clearance
    //                 },
    //                 preventive: {
    //                     val: reportState.response.preventive,
    //                     rem: total - reportState.response.preventive
    //                 }
    //             }
    //         );
    //     }
        
    // },[reportState]);

    if(loading){
        return <Loader />
    } 
    // else if(!reportState || !reportState.response || reportState?.response?.length === 0)
    //     return <></>
    else if(reportState.error !== "")
        return <>
            <div className="row">
                <div className="col-12 text-center">
                    <div className="alert alert-danger w-100 mt-2" role="alert">
                        {reportState.error}
                    </div>
                </div>
            </div>
        </>

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h5 className="card-title font-weight-bold text-black">
                        Top Agents
                    </h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                        <div className="px-8 py-6">
                            <div className="row">
                                {loading && (
                                    <Loader />
                                )}
                                {!loading && (
                                    <>  
                                        {(reportType ===  '' || reportType === 'corrective') &&
                                            <div className="col-2 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Mohit"
                                                        reportVal={reportVal.corrective}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {(reportType ===  '' || reportType === 'corrective') &&
                                            <div className="col-2 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Agent1"
                                                        reportVal={reportVal.tempval1}
                                                    />
                                                </div>
                                            </div>
                                        }
                                         {(reportType ===  '' || reportType === 'corrective') &&
                                            <div className="col-2 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Agent2"
                                                        reportVal={reportVal.tempval2}
                                                    />
                                                </div>
                                            </div>
                                        }
                                         {(reportType ===  '' || reportType === 'corrective') &&
                                            <div className="col-2 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Agent3"
                                                        reportVal={reportVal.tempval3}
                                                    />
                                                </div>
                                            </div>
                                        }                                       
                                         
                                        {(reportType === '' || reportType === 'clearance') && 
                                            <div className="col-2 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Ram"
                                                        reportVal={reportVal.clearance}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {(reportType === '' || reportType === 'preventive') && 
                                            <div className="col-2 mx-auto">
                                                <div className="inspection-chart">
                                                    <VerticalGraph
                                                        title="Vignesh"
                                                        reportVal={reportVal.preventive}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )

}