import React, {FC} from 'react'
import {DocumentsUpload} from './DocumentsUpload/DocumentsUpload'

const StepThree: FC = () => {

    return (
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-md-12 mb-10">
                    <DocumentsUpload />
                </div>
            </div>
        </div>
    )
};

export {StepThree}
