import React, {useState, useEffect, useRef} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getConfig } from '../../../constants/axios/apis';
import { FinanceRefundsClosedRequest } from './FinanceRefundsTable/FinanceRefundsClosedRequest';
import { FinanceRefundsOpenRequest } from './FinanceRefundsTable/FinanceRefundsOpenRequest';
import { FinanceServiceRefundsTabs } from './FinanceRequestTabs/FinanceServiceRefundsTabs'

export function FinanceRefundListWrapper() {
  const [searchItem, setSearchItem] = useState<any>('');
  const [vat, setVat] = useState<any>('');
  useEffect(() => {
      getConfig().then(({ data }: any) => {
          if(data && Array.isArray(data)){
              const vat_per = data?.find((item: any) => item.key == 'vat_percentage').value
              setVat( vat_per ? vat_per : '' )
          }
      })
  }, [])

  return (
    <>
      <FinanceServiceRefundsTabs searchItem={searchItem} setSearchItem={setSearchItem} />
      <Switch>
        <Route path='/finance-services-request-refunds/list/open-requests-list'>
            <FinanceRefundsOpenRequest searchItem={searchItem} vat={vat}/>          
        </Route>
        <Route path='/finance-services-request-refunds/list/closed-requests-list'>
          <FinanceRefundsClosedRequest searchItem={searchItem} vat={vat}/>
        </Route>
        <Redirect to='/error/403' />
      </Switch>
    </>
  )
}