import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { RootState } from '../../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function TenantDetails() {
    let offerList: any = []
    offerList = useSelector<RootState>(({ General }) => General.offersRenewlaData)
    const { t } = useTranslation();

    const tenantData = offerList?.tenant_deatils ? offerList.tenant_deatils : undefined

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            { tenantData ? 
                                <div className="row">
                                    <div className="col-12 mb-6 col-md-4">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Emirates ID Number")}
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                            {tenantData?.emiratesIdNo?.length > 0 ? tenantData?.emiratesIdNo : t("NA")}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-6 col-md-4">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Name")}
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                            {tenantData?.tenantName?.length > 0 ? tenantData?.tenantName : t("NA")}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-6 col-md-4">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Date of Birth")}
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                            {tenantData?.tenantDob?.length > 0 ? moment(tenantData?.tenantDob).format('DD-MM-YYYY')  : t("NA")}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-6 col-md-4">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Emirates ID Expiry Date")}
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                            {tenantData?.emirates_id_exp_date?.length > 0 ? moment(tenantData?.emirates_id_exp_date).format('DD-MM-YYYY') : t("NA")}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-6 col-md-4">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Passport Number")}
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                            {tenantData?.passport_no?.length > 0 ? tenantData?.passport_no : t("NA")}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-6 col-md-4">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Passport Expiry Date")}
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                            {tenantData?.passport_expiry_date?.length > 0 ? moment(tenantData?.passport_expiry_date).format('DD-MM-YYYY') : t("NA")}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                : t("No Data Found")}
                        </div>
                        {/* <div className="card-body px-8 py-6">
                            {offerList?.data?.address_book?.length > 0
                                ? offerList?.data?.address_book?.map((item: any, index: number) => {
                                    return (
                                        <div className="row">
                                            <div className="col-12 mb-6 col-md-4">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                    {t("Emirates ID Number")}
                                                </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0">
                                                    {item?.emiratesIdNo?.length > 0 ? item?.emiratesIdNo : t("NA")}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-6 col-md-4">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                    {t("Name")}
                                                </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0">
                                                    {item?.tenantName?.length > 0 ? item?.tenantName : t("NA")}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-6 col-md-4">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                    {t("Date of Birth")}
                                                </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0">
                                                    {item?.tenantDob?.length > 0 ? moment(item?.tenantDob).format('DD-MM-YYYY')  : t("NA")}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-6 col-md-4">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                    {t("Emirates ID Expiry Date")}
                                                </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0">
                                                    {item?.emirates_id_exp_date?.length > 0 ? moment(item?.emirates_id_exp_date).format('DD-MM-YYYY') : t("NA")}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-6 col-md-4">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                    {t("Passport Number")}
                                                </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0">
                                                    {item?.passport_no?.length > 0 ? item?.passport_no : t("NA")}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-6 col-md-4">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                    {t("Passport Expiry Date")}
                                                </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0">
                                                    {item?.passport_expiry_date?.length > 0 ? moment(item?.passport_expiry_date).format('DD-MM-YYYY') : t("NA")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : t("No Data Found")}
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}