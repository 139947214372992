import {FC} from 'react'
import {useHistory} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../components/auth/models/UserModel'
import { RootState } from '../../../../constants'
// import {Languages} from './Languages'
import {KTSVG} from '../../../../components/helpers'
import {Redirect} from "react-router";
import { useTranslation } from 'react-i18next'

const DeportmentBt: FC = () => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div
            className='Depaertment-menu-dropdown menu menu-sub menu-sub-dropdown menu-column rounded-12 menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-0 fs-6 w-250px'
            data-kt-menu='true'
        >
            <div className='menu-item px-2'>
                <Link to={'#'} className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className="item-icon user-ioc"
                    />
                    {t("Request for Clarification")}
                </Link>
            </div>
            <hr className="m-0"/>
            <div className='menu-item px-2'>
                <Link to='/' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=" "
                    />
                    {t("Property Manager")}
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to='/' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=" "
                    />
                    {t("Finance Manager")}
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to='/' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=" "
                    />
                    {t("Security Manager")}
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to='' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=" "
                    />
                    {t("IT Manager")}
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to={'/'} className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=""
                    />
                    {t("Facility Manager")}
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to='#' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=" "
                    />
                    {t("Tenant")}
                </Link>
            </div>
            <hr className="m-0"/>
            <div className='menu-item px-2'>
                <Link to='/' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className="item-icon user-ioc"
                    />
                    {t("Reassign the Request")}
                </Link>
            </div>
            <hr className="m-0"/>
            <div className='menu-item px-2'>
                <Link to='/' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=" "
                    />
                    {t("Line Manager")}
                </Link>
            </div>
            <div className='menu-item px-2'>
                <Link to='/' className='menu-link px-3'>
                    <KTSVG
                        path=''
                        className=" "
                    />
                    {t("Property Executives")}
                </Link>
            </div>
        </div>
    )
}

export {DeportmentBt}
