import React, { FC, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';
import { LeadList } from './components/LeadList/LeadList';
import { LeadView } from './components/LeadView/LeadView';
import { LeadEdit } from './components/LeadEdit/LeadEdit';
import { LeadAdd } from './components/LeadAdd/LeadAdd';

const PMLeadsPage: FC = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return(
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/lead/list'>
                            <LeadList />  
                        </Route>
                        <Route path='/lead/view'>
                            <LeadView />  
                        </Route>
                        <Route path='/lead/edit'>
                            <LeadEdit setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/lead/add'>
                            <LeadAdd setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                    </Switch>
                </div>
            </div>
            <CustomRequestSuccessfulPopup 
                successMessage={popupSuccessMsg}
                showOkBtn={true}
                customID={'-redirect'}
            />
        </>
    );
}

const PmContactLeadsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMLeadsPage />
        </>
    )
};

export { PmContactLeadsWrapper }
