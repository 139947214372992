import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { Reminders } from '../Tabs/Reminder'
import { CreateTaskModal } from '../CreateTaskModal';
import { EmailDistributeModal } from '../EmailDistributeModal';

export function TaskList() {
  const { t } = useTranslation();
  const [tabPosition, setTabPosition] = useState("1");
  const opportunityTab = (i: any) => {
      setTabPosition(i)
  }
  return (
    <>
    <div className='com-card-section'>
      <div className='row flex-stack announcement-sec mb-6'>
        <div className='col-md'>
          <h4 className='card-title font-18 su-ca-add-attence text-black'> {t("Tasks")}</h4>
        </div>
        <div className="col-auto text-end">
          <button type='button' data-bs-toggle="modal" data-bs-target="#create_task_modal" className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>Create Task</button>
          <button type='button' className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>Download</button>
          <button type='button' data-bs-toggle="modal" data-bs-target="#email_dist_modal" className='btn btn-outline-primary fw-bold px-10 py-3'>Email/Distribute</button>
        </div>
      </div>
      <div className='card mb-3'>
          <div className="card-body pb-2">
            <div className='d-flex overflow-auto'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-3 flex-nowrap'>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(1)}>
                          Open Tasks
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(2)}>
                        All Upcoming
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(3)}>
                        Current
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(4)}>
                        Completed
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '5' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(5)}>
                        Due today
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '6' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(6)}>
                        Due this Week
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '7' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(7)}>
                        Overdue
                        </Link>
                    </li>

                </ul>
            </div>
          </div>
      </div>

        {
          tabPosition == '1' ? <Reminders /> :
          tabPosition == '2' ? <Reminders /> :
          tabPosition == '3' ? <Reminders /> :
          tabPosition == '4' ? <Reminders /> :
          tabPosition == '5' ? <Reminders /> :
          tabPosition == '6' ? <Reminders /> : 
          tabPosition == '7' ? <Reminders /> : ''
        }
    </div>
    <CreateTaskModal/>
    <EmailDistributeModal/>
    </>
  )
}
