import React, { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IState } from '../../../../utils/interface'
import { getApprovals } from '../../../../constants/axios/apis'
import {
  formatDate,
  formatTimeMeridiem,
} from '../../../../constants/generics/dateTimeFormater'
import { allScheduleList } from '../../../../constants/axios/apis'
import { RootState } from '../../../../constants'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CalendarCard } from '../CalendarCard/CalendarCard'
import { EventInput } from '@fullcalendar/react'
import { CalenderModal } from './Modal'

export function CalendarAll() {
  const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const id_role = state?.auth?.user?.data?.id_role
  const [moveInList, setMoveInList] = useState<any>([])
  const [moveOutList, setMoveOutList] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)
  const [parentSelectedCategory, setParentSelectedCategory] = useState<any>('visit')
  const [counts, setCounts] = useState<any>({ visit: 0, moveIn: 0, moveOut: 0 })
  const { t } = useTranslation();

  const [scheduledVistList, setScheduledVistList] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  useEffect(() => {
    setLoading(false)
    // allScheduleList(currentBuilding).then((response: any) => {
    //   setLoading(false)
    //   if (response?.code === 200 && response.errorCode != 1) {
    //     setScheduledVistList({
    //       loading: false,
    //       response: response.data,
    //       error: '',
    //     })
    //   } else {
    //     setScheduledVistList({
    //       loading: false,
    //       response: [],
    //       error: '',
    //     })
    //   }
    // })
    // getApprovals('?sub_type=6' + (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')).then((response: any) => {
    //   if (response.errorCode === 0 && response.data) {
    //     setMoveOutList(response.data)
    //   } else {
    //     setMoveOutList([])
    //   }
    // })
    // getApprovals('?sub_type=5' + (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')).then((response: any) => {
    //   if (response?.code === 200 && response.errorCode != 1) {
    //     setMoveInList(response.data)
    //   } else {
    //     setMoveInList([])
    //   }
    // })
  }, [currentBuilding])

  const [eventState, setEventState] = useState<EventInput[]>([])

  const sameDateCheck = (date1: any, date2: any) => {
    var d1 = new Date(date1)
    var d2 = new Date(date2)

    d1.setHours(0, 0, 0, 0)
    d2.setHours(0, 0, 0, 0)

    return d1.getTime() === d2.getTime()
  }

  const [scheduledVists, setscheduledVists] = useState<any>([])

  // useEffect(() => {
  //   let indexCount = 1
  //   let eventList = new Array()
  //   let eventList2 = new Array()
  //   let eventList3 = new Array()
  //   let filteredScheduledVistList = new Array()
  //   let filteredMoveInList = new Array()
  //   let filteredMoveOutList = new Array()
  //   if (Array.isArray(scheduledVistList.response) && scheduledVistList.response.length > 0) {
  //     eventList = scheduledVistList.response.map((event: any, index: number) => {
  //       return {
  //         id: String(indexCount++),
  //         title: event.propertyName,
  //         date: event.visit_date,
  //       }
  //     })
  //     filteredScheduledVistList = scheduledVistList.response.filter((event: any, index: number) =>
  //       sameDateCheck(event.visit_date, selectedDate)
  //     )
  //   }
  //   if (Array.isArray(moveInList) && moveInList.length > 0) {
  //     eventList2 = moveInList.map((event: any, index: number) => {
  //       return {
  //         id: String(indexCount++),
  //         title: event.building,
  //         date: event.created_at,
  //       }
  //     })

  //     filteredMoveInList = moveInList?.filter((event: any, index: number) =>
  //       sameDateCheck(event.created_at, selectedDate)
  //     )
  //   }
  //   if (Array.isArray(moveOutList) && moveOutList.length > 0) {
  //     eventList3 = moveOutList.map((event: any, index: number) => {
  //       return {
  //         id: String(indexCount++),
  //         title: event.building,
  //         date: event.created_at,
  //       }
  //     })

  //     filteredMoveOutList = moveOutList?.filter((event: any, index: number) =>
  //       sameDateCheck(event.created_at, selectedDate)
  //     )
  //   }

  //   setEventState([...eventList, ...eventList2, ...eventList3])

  //   setCounts({
  //     visit: filteredScheduledVistList.length,
  //     moveIn: filteredMoveInList.length,
  //     moveOut: filteredMoveOutList.length,
  //   })

  //   let todaysEventList = new Array()

  //   if (parentSelectedCategory === 'visit') {
  //     todaysEventList = filteredScheduledVistList?.map((event: any) => {
  //       return {
  //         title: event?.propertyName,
  //         subTitle: event?.propertyCode,
  //         date: moment(event?.visit_date).format('DD-MM-YYYY'),
  //         time: formatTimeMeridiem(event.Time),
  //         status:
  //           event?.eventStatus === 0
  //             ? 'Upcoming'
  //             : event?.eventStatus === 2
  //               ? 'Completed'
  //               : event?.eventStatus === 1
  //                 ? 'Ongoing'
  //                 : event?.eventStatus,
  //         user: event?.visitor,
  //         code: event?.emiratesID,
  //         stateData: event,
  //       }
  //     })
  //   } else if (parentSelectedCategory === 'movein') {
  //     todaysEventList = filteredMoveInList?.map((event: any) => {
  //       return {
  //         title: event?.building,
  //         subTitle: '',
  //         date: formatDate(event?.created_at),
  //         status: event?.status,
  //         user: event?.customerName,
  //         code: event?.propertyCode,
  //         stateData: event,
  //       }
  //     })
  //   } else if (parentSelectedCategory === 'moveout') {
  //     todaysEventList = filteredMoveOutList.map((event: any) => {
  //       return {
  //         title: event?.building,
  //         subTitle: '',
  //         date: formatDate(event?.created_at),
  //         status: event?.status,
  //         user: event?.customerName,
  //         code: event?.propertyCode,
  //         stateData: event,
  //       }
  //     })
  //   }
  //   setscheduledVists(todaysEventList)
  // }, [scheduledVistList, moveInList, moveOutList, selectedDate, parentSelectedCategory])

	const handleDateClick = (arg:any) => {
		setSelectedDate(arg?.date)
	}

  return (
    <div className='com-card-section'>
      <div className='row flex-stack announcement-sec mb-6'>
        <div className='col-md-6'>
          <h4 className='card-title font-18 su-ca-add-attence text-black'> {t("My Calendar")}</h4>
        </div>
        <div className="col-6 text-end">
          <button data-bs-toggle="modal" data-bs-target="#calenderPopup" type='button' className='btn btn-primary fw-bold px-10 py-3'>
              Add Appointment
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          {/* <CalenderCard eventState={eventState} setSelectedDate={setSelectedDate} /> */}
          <CalendarCard eventState={eventState} />
        </div>
      </div>
      <CalenderModal/>
    </div>
  )
}
