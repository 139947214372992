import { Bar } from 'react-chartjs-2';

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Top Agents',
      },
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };
  
const labels = ['Q1/2020', 'Q2/2020', 'Q3/2020', 'Q4/2020'];

export const data = {
labels,
datasets: [
    {
        label: 'Social',
        data: [92, 60, 72, 54, 40],
        backgroundColor: '#F3B521',
        textColor: '#F3B521'
    },
    {
        label: 'Paid Ads',
        data: [92, 60, 72, 54, 40],
        backgroundColor: '#e64434',
        textColor: '#e64434'
    },
    {
        label: 'Owned Content',
        data: [92, 60, 72, 54, 40],
        backgroundColor: '#ec943f',
        textColor: '#ec943f'
    },
    {
        label: 'Offline Ads',
        data: [92, 60, 72, 54, 40],
        backgroundColor: '#5db35c',
        textColor: '#5db35c'
    },
    {
        label: 'Email',
        data: [92, 60, 72, 54, 40],
        backgroundColor: '#000000',
        textColor: '#000000'
    },
],
};

export const Revenue = (props:any) => {

    return(
        <div className="com-card-section">
            <div className="row">
            </div>
            <div className="row">
                <div className="col-12">
                    <Bar options={options} data={data} width={100} height={100}/>
                </div>
            </div>
        </div>
    )
}
