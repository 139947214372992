import React, { FC, useEffect, useRef, useState, Component } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../constants'
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import { useTranslation } from 'react-i18next'
import { getCommunities, getFacilityBuildingList, getPMBuildingList } from '../../../../../constants/axios/apis';
import { CustomLoader1 } from '../../../../../constants/generics/customActios';

const Step2: FC = () => {
    const dispatch = useDispatch()
    let proprtyDetails: any
    proprtyDetails = useSelector<RootState>(({ PM }) => PM.proprtydetails)
    let propertyEditData: any
    propertyEditData = useSelector<RootState>(({ General }) => General.propertyEditData)
    const intl = useIntl()
    const location = useLocation()
    const [menu, setMenu] = useState('')
    const { t } = useTranslation();

    const [lat, setLat] = useState(propertyEditData?.step2?.lat != null ? propertyEditData?.step2?.lat : proprtyDetails?.data?.latitude == null || proprtyDetails?.data?.latitude == "NaN" || proprtyDetails?.data?.latitude == null ? 0 : parseFloat(proprtyDetails?.data?.latitude))
    const [long, setLong] = useState(parseFloat(propertyEditData?.step2?.long != null ? propertyEditData?.step2?.long : proprtyDetails?.data?.longitude == null || proprtyDetails?.data?.longitude == "NaN" || proprtyDetails?.data?.longitude == null ? 0 : proprtyDetails?.data?.longitude))
    const [propertyCode, setPropertyCode] = useState<any>(propertyEditData?.step2?.propertyCode != null ? propertyEditData?.step2?.propertyCode : proprtyDetails?.data?.propertyCode)
    const [area, setArea] = useState<any>(propertyEditData?.step2?.area != null ? propertyEditData?.step2?.area : proprtyDetails?.data?.area)
    const [locations, setLocations] = useState<any>(propertyEditData?.step2?.locations != null ? propertyEditData?.step2?.locations : proprtyDetails?.data?.location)
    const [floors, setFloors] = useState<any>(propertyEditData?.step2?.floors != null ? propertyEditData?.step2?.floors : proprtyDetails?.data?.no_of_floors)
    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }
    const [communityList, setCommunityList] = useState<any>([])
    const [initialIds, setInitialIds] = useState<any>()
    const [buildingUnitList, setBuildingUnitList] = useState<any>([])
    const [communityId, setCommunityId] = useState<any>(propertyEditData?.step2?.communityId != null ? propertyEditData?.step2?.communityId : proprtyDetails?.data?.communityName)
    const [selectedBuilding, setSelectedBuilding] = useState<any>(propertyEditData?.step2?.selectedBuilding != null ? propertyEditData?.step2?.selectedBuilding : proprtyDetails?.data?.buildingName)

    // useEffect(() => {
    //     if (initialIds != undefined) {
    //         setCommunityId(propertyEditData?.step2?.communityId != null ? propertyEditData?.step2?.communityId : initialIds.communityId)
    //         setSelectedBuilding(propertyEditData?.step2?.selectedBuilding != null ? propertyEditData?.step2?.selectedBuilding : initialIds.pId)
    //     }
    // }, [initialIds])
    // useEffect(() => {
    //     getFacilityBuildingList().then(({ data }) => {
    //         const list = (data && Array.isArray(data)) ? data.flat()?.find((items: any) => (
    //             items?.property.find((item: any) => {
    //                 if (item?.property_code === propertyCode)
    //                     setInitialIds({
    //                         communityId: items.communityId,
    //                         pId: items.id
    //                     })
    //             }
    //             )
    //         )
    //         ) : []
    //     }).catch((e) => { })
    // }, [propertyCode])

    // useEffect(() => {
    //     getCommunities().then(({ data }) => {
    //         setCommunityList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: item.id, name: item.community })) : [])
    //     }).catch((e) => {

    //     })
    //     getPMBuildingList(communityId === 0 ? initialIds?.communityId : communityId).then(({ data }) => {
    //         setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({ buildingId: item.id, building: item.building })) : [])

    //     }).catch((e) => { })
    // }, [, communityId, initialIds])
    // useEffect(() => {
    //     if (communityId == 0 && initialIds != undefined)
    //         getPMBuildingList(communityId === 0 ? initialIds?.communityId : communityId).then(({ data }) => {
    //             setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({ buildingId: item.id, building: item.building })) : [])

    //         }).catch((e) => { })
    // }, [communityId, initialIds])

    // const handleSelectedCommunity = (id: any) => {
    //     setCommunityId(id)
    //     getPMBuildingList(id).then(({ data }) => {
    //         setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({ buildingId: item.id, building: item.building })) : [])

    //     }).catch((e) => { })
    // }
    // const communityListOptions = communityList?.map((community: any) => (
    //     <option key={community.id} value={community.id} >
    //         {community.name}
    //     </option>
    // ))

    // const buildingList = buildingUnitList?.map((building: any) => (
    //     <option key={building.buildingId} value={building.buildingId} onSelect={handleSelectedCommunity} >
    //         {building.building}
    //     </option>
    // ))

    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
        }
    })
    const containerStyle = {
        width: '100%',
        height: '450px',
        borderRadius: '10px'
    };
    const center = {
        lat: lat,
        lng: long
    };
    const onMarkerDragEnd = (event: any) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        setLat(newLat)
        setLong(newLng)

    };

    const onMapClick = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        function success(pos: any) {
            var crd = pos.coords;

            console.log(`Latitude : ${crd.latitude}`);
            console.log(`Longitude: ${crd.longitude}`);
        }

        function errors(err: any) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (result.state === "granted") {
                        console.log(result.state);
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(success);
                    } else if (result.state === "prompt") {
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                    }
                    result.onchange = function () {
                        console.log(result.state);
                    };
                });
        } else {
            alert(t("Sorry Not available!"));
        }
    };

    useEffect(() => {
        const step2Data = {
            communityId: communityId,
            selectedBuilding: selectedBuilding,
            propertyCode: propertyCode,
            locations: locations,
            floors: floors,
            area: area,
            lat: lat,
            long: long,
        }
        dispatch(General.actions.propertyEditData({
            step1: propertyEditData.step1, step2: step2Data
        }));
    }, [lat, long, communityId, selectedBuilding, propertyCode, area, locations, floors])
    // if (communityList?.length <= 0) { return <CustomLoader1 height={50} /> }
    return (
        <>
            <div className="com-card-section w-100 p-7">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row form-add-design">
                            <div className="col-md-12 col-12">
                                <div className='row'>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Select Community")}*</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" value={communityId} disabled />
                                            {/* <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true" disabled
                                                onChange={(e) => handleSelectedCommunity(e.target.value)}
                                                value={communityId == 0 && initialIds != undefined ? initialIds.communityId : communityId}>
                                                <option selected disabled>{t("Please Select")}</option>
                                                {communityList ? communityListOptions : ''}
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Select Building")}*</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" value={selectedBuilding} disabled />
                                            {/* <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true" disabled
                                                onChange={(e) => setSelectedBuilding(e.target.value)}
                                                value={selectedBuilding}>
                                                {buildingUnitList ? buildingList : ''}
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Property Code")}</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" value={propertyCode} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Area")}</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" value={area} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Location")}</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" value={locations} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Latitude")}</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" value={lat} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Longitude")}</label>
                                            <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" value={long} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Floor")}</label>
                                            <input type="number" className="form-control fw-bolder form-control-solid" placeholder="" value={floors} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-12">
                                <LoadScript
                                    googleMapsApiKey='https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCXzH7HgZsLoL-QATfrmc5Ng5-u0rdguAI&libraries=geometry,drawing,places'
                                >
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={11}
                                        onClick={onMapClick}
                                    >
                                        <Marker
                                            draggable={true}
                                            position={center}
                                            onDragEnd={onMarkerDragEnd}
                                        />
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Step2 }
