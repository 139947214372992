import React, { useState, useRef, FC } from 'react';
import PlusIcon from "../../../../../../../assets/images/plus-circle.svg";

export function StepThree() {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="row form-add-design">
                    <div className='col-12'>
                        <div className='mb-5'>
                            <h5 className='className="modal-title fw-bold font-18 cpt-headding'>
                                Documents to Upload
                            </h5>
                        </div>
                    </div>                    
                    <div className="col-sm-6 col-12">
                        <div className="mb-5 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Emirates ID (front )</label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-5 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Emirates ID ( Back ) </label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-5 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Passport </label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-10 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Visa </label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-5 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Letter of Intent </label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-5 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Signature </label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}