import React, { useState, useEffect } from 'react'
import {ReminderModal} from './ReminderModal'

export function Reminders() {
    return (
        <>
         <div className="row activity mt-4">
            <div className="lead-det-prof pr-2">
                <div className="card mb-4 bg-light">
                    <div className="card-body p-3">
                        <h6 className="font-weight-600 text-black d-flex justify-content-between align-items-center">Driving Licence <span className="badge bg-dark">Scheduled</span></h6>
                        <p className="font-12 mb-0"> Start Date: 18/07/2023 </p>
                        <p className="font-12 mb-0"> End Date: 18/07/2023 </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-4">
            <div className="col-md-12 mb-4">
                <div className="border rounded text-center py-4">
                    <i className="fas fa-file-alt text-gray-400 font-44"></i>
                    <h3 className="text-gray-400 mt-4 mb-0"> No Reminders found </h3>
                </div>
            </div>
            <div className="col-12">
                <div className='border rounded py-3 text-center'>
                    <button data-bs-toggle="modal" data-bs-target="#reminder_modal" className='btn-primary border-radius-round px-2 pt-1'>
                        <i className="fa fa-plus text-white"></i>
                    </button>
                </div>
            </div>
        </div>
        <ReminderModal/>
        </>  
    )
}

