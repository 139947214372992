import React from 'react';
import {Link} from 'react-router-dom'
import MyPic from '../../../../../assets/images/my_team_pic.png';
import { useTranslation } from 'react-i18next'

export function Week() {
    const { t } = useTranslation();

    return (
        <div className="col-12">
            <div className="mta_my_team_team_members mt-5">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                 <Link to={'/pm-myteam-team-members-details'}>
                                    <div className="mta_team_members_card mb-5">
                                        <div className="card px-3 py-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3 col-3">
                                                    <div className="offer_main_img">
                                                        <div className="num">
                                                            <p className="p-1 mb-0">56</p>
                                                        </div>
                                                        <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 col-9">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <p className="text-black fw-bolder font-14 mb-2">Mohammad </p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Member ID")} :</p>
                                                                    <p className="mb-0 text-black">546454</p>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Contact No.")} : </p>
                                                                    <p className="mb-0 text-black">+971 6655855555</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                 <Link to={'/pm-myteam-team-members-details'}>
                                    <div className="mta_team_members_card mb-5">
                                        <div className="card px-3 py-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3 col-3">
                                                    <div className="offer_main_img">
                                                        <div className="num">
                                                            <p className="p-1 mb-0">56</p>
                                                        </div>
                                                        <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 col-9">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <p className="text-black fw-bolder font-14 mb-2">Mohammad </p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Member ID")} :</p>
                                                                    <p className="mb-0 text-black">546454</p>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Contact No.")} : </p>
                                                                    <p className="mb-0 text-black">+971 6655855555</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                 <Link to={'/pm-myteam-team-members-details'}>
                                    <div className="mta_team_members_card mb-5">
                                        <div className="card px-3 py-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3 col-3">
                                                    <div className="offer_main_img">
                                                        <div className="num">
                                                            <p className="p-1 mb-0">56</p>
                                                        </div>
                                                        <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 col-9">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <p className="text-black fw-bolder font-14 mb-2">Mohammad </p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Member ID")} :</p>
                                                                    <p className="mb-0 text-black">546454</p>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Contact No.")} : </p>
                                                                    <p className="mb-0 text-black">+971 6655855555</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                 <Link to={'/pm-myteam-team-members-details'}>
                                    <div className="mta_team_members_card mb-5">
                                        <div className="card px-3 py-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3 col-3">
                                                    <div className="offer_main_img">
                                                        <div className="num">
                                                            <p className="p-1 mb-0">56</p>
                                                        </div>
                                                        <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 col-9">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <p className="text-black fw-bolder font-14 mb-2">Mohammad </p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Member ID")} :</p>
                                                                    <p className="mb-0 text-black">546454</p>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Contact No.")} : </p>
                                                                    <p className="mb-0 text-black">+971 6655855555</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                 <Link to={'/pm-myteam-team-members-details'}>
                                    <div className="mta_team_members_card mb-5">
                                        <div className="card px-3 py-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3 col-3">
                                                    <div className="offer_main_img">
                                                        <div className="num">
                                                            <p className="p-1 mb-0">56</p>
                                                        </div>
                                                        <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 col-9">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <p className="text-black fw-bolder font-14 mb-2">Mohammad </p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Member ID")} :</p>
                                                                    <p className="mb-0 text-black">546454</p>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Contact No.")} : </p>
                                                                    <p className="mb-0 text-black">+971 6655855555</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                 <Link to={'/pm-myteam-team-members-details'}>
                                    <div className="mta_team_members_card mb-5">
                                        <div className="card px-3 py-3">
                                            <div className="row align-items-center">
                                                <div className="col-sm-3 col-3">
                                                    <div className="offer_main_img">
                                                        <div className="num">
                                                            <p className="p-1 mb-0">56</p>
                                                        </div>
                                                        <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-9 col-9">
                                                    <div className="offer_main_details">
                                                        <div className="col-12">
                                                            <p className="text-black fw-bolder font-14 mb-2">Mohammad </p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Member ID")} :</p>
                                                                    <p className="mb-0 text-black">546454</p>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <p className="mb-0 text-black">{t("Contact No.")} : </p>
                                                                    <p className="mb-0 text-black">+971 6655855555</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}