/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { ChatWindow } from './ChatWindow/ChatWindow'
import { ChatTabs } from './ChatTabs/ChatTabs'
import { ChatAll } from './ChatAll/ChatAll'
import { ChatUnread } from './ChatUnread/ChatUnread'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ChatLeftSidebar } from "./ChatLeftSidebar/ChatLeftSidebar";
import {useTranslation} from 'react-i18next'

const ChatMainPage: FC = () => {
    const [activeChat, setActiveChat] = useState<any>(null)
    const {t} = useTranslation()

    return (
        <>
            <div className="com-card-section">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h4 className="card-title font-18 text-black mb-8">{t("Chat with us")}</h4>
                    </div>
                </div>
            </div>
            <div className="chat-main-page">
                <div className="row gx-8">
                    <div className="col-lg-4 col-lg-4 mb-lg-0 mb-5">
                        <div className="chat-contacts">
                            {/*<ChatLeftSidebar />*/}
                            <Switch>
                                <Route path='/chat/all'>
                                    <ChatTabs />
                                    <ChatAll setActiveChat={setActiveChat} />
                                </Route>
                                <Route path='/chat/unread'>
                                    <ChatTabs />
                                    <ChatUnread />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="chat-body">
                            <ChatWindow activeChat={activeChat} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ChatMainPage }
