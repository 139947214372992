import React, {useState, useEffect} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ClosedRequests } from './MoveOutRequestTables/ClosedRequests'
import { OpenRequest } from './MoveOutRequestTables/OpenRequest'
import { MoveOutRequestTabs } from './MoveOutRequestTabs/MoveOutRequestTabs'

export function MoveoutRequestListWrapper() {
  const [searchItem, setSearchItem] = useState<any>('');

  return(
    <>
        <MoveOutRequestTabs searchItem={searchItem} setSearchItem={setSearchItem} />
        <Switch>
            <Route path='/finance-moveout-requests/list/open'>
                <OpenRequest searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/finance-moveout-requests/list/closed'>
                <ClosedRequests searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Redirect to='/error/403' />
        </Switch>
    </>
  )
}