import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination';

const emailtemplatedata = [{
    "id": "1",
    "template": "send_offer",
    "subject": "You have received an offer for",
    "dynamic": "null",
    "attachments": "",
    "created": "26/11/2020",
    "modified": "-"
},
{
    "id": "2",
    "template": "	reset_password",
    "subject": "New Reset Password",
    "dynamic": "$person_name,$url_reset_password",
    "attachments": "",
    "created": "07/08/2020",
    "modified": "-"
},
{
    "id": "3",
    "template": "	password_change_success",
    "subject": "Password Change successfullyr for",
    "dynamic": "$person_name",
    "attachments": "",
    "created": "26/11/2020",
    "modified": "-"
},
{
    "id": "4",
    "template": "Contact",
    "subject": "You have received an offer for",
    "dynamic": "null",
    "attachments": "",
    "created": "26/11/2020",
    "modified": "-"
},
{
    "id": "5",
    "template": "send_offer",
    "subject": "You have received an offer for",
    "dynamic": "null",
    "attachments": "",
    "created": "26/11/2020",
    "modified": "-"
},
{
    "id": "6",
    "template": "send_offer",
    "subject": "You have received an offer for",
    "dynamic": "null",
    "attachments": "",
    "created": "26/11/2020",
    "modified": "-"
},
];

export function EmailTempalteList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-6">
                    <h3 className=''>Email Templatet</h3>
                </div>
                <div className="col-6 text-end">
                    <Link to={'/communication/addemail-templates'} className='btn btn-primary fw-bold px-10 py-3'>
                        Add New Email Template
                    </Link>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Template Name  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Template Name" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Subject  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Subject" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Dynamic Value  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Dynamic Value" />
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className='col-10'></div>
                <div className="col-2 d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='table-crm1'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th> Template Name</th>
                                                                    <th style={{ width: "200px" }}> Subject </th>
                                                                    <th> Dynamic Value</th>
                                                                    <th> Attachments </th>
                                                                    <th> Created </th>
                                                                    <th> Modified </th>
                                                                    <th> Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {emailtemplatedata.length > 0 ? emailtemplatedata.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className="text-warning">{item.id}</td>
                                                                        <td>{item.template}</td>
                                                                        <td>{item.subject}</td>
                                                                        <td>{item.dynamic}</td>
                                                                        <td>{item.attachments}</td>
                                                                        <td>{item.created}</td>
                                                                        <td>{item.modified}</td>
                                                                        <td className="action-button text-end">
                                                                            <div className="dropdown">
                                                                                <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v text-black"></i>
                                                                                </button>
                                                                                <div className="dropdown-menu">
                                                                                    <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                                        <li>
                                                                                            <Link to={'/communication/editemail-templates'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-edit"></i>
                                                                                                <p className="m-0">Edit</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Link to={'/communication/email-templates-preview'} className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                                                <i className="fa fa-eye"></i>
                                                                                                <p className="m-0">View Details</p>
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={emailtemplatedata.length}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
