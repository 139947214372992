import React, {FC, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import {CustomRequestSuccessfulPopup} from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import {useSelector} from 'react-redux'
// import { addNewTechnician } from '../../../../utils/makeRequest';
// import { IState } from '../../../../utils/interface';
import Loader from '../../../../components/Loader'

import {useFormik} from 'formik'
import * as Yup from 'yup'

import {
  getCompanyList,
  // getFmCommunityUser,
  // getCommunities,
  // getFacilityBuildingList,
  // getFacilityCompanyData,
  // getFacilityUserType,
  createContractorUser,
} from '../../../../constants/axios/apis'
import {getUserBuildingCommunityList} from '../../../../utils/makeRequest'

import {MultiSelect} from 'react-multi-select-component'
import IntlTelInput from 'react-intl-tel-input'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next';

export function AddContractors() {
  const { t } = useTranslation();
  const history = useHistory()
  let params: any = {}
  params = useLocation()
  const {addRole} = params.state //Adding from FMSM - User: (FCS => id_role: 10) (FM_Team => id_role: 12)

  const buttonEl: any = useRef(null)
  const state: any = useSelector((state) => state)
  const userId = state?.auth?.user?.data?.id

  const [selectedMobileCode, setMelectedMobileCode] = useState<any>('')
  const [companyList, setCompanyList] = useState<any>([])
  const [propertyList, setPropertyList] = useState<any>([])
  const [buildingList, setBuildingList] = useState<any>([])
  const [filteredBuildingList, setFilteredBuildingList] = useState<any>([])
  // const [buildingOptions, setBuildingOptions] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>({
    company: true,
    property: true,
    building: true,
  })

  const [selectedProperties, setSelectedProperties] = useState([])
  const [selectedBuildings, setSelectedBuildings] = useState([])

  const [isSubmitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const {Option} = Select

  // if(userToken === "undefined" || userId === "undefined"){
  //     // token undefined && // user id
  // }

  useEffect(() => {
    getCompanyList()
      .then(({data}) => {
        setCompanyList(
          data && Array.isArray(data)
            ? data.map((item: any) => ({id: item.id, name: item.company}))
            : []
        )
        setIsLoading((oState: any) => ({...oState, company: false}))
      })
      .catch((e) => {
        setIsLoading({...isLoading, company: false})
      })
    // getCommunities().then(({ data }) => {
    //     setPropertyList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: item.id, name: item.community })) : [])
    //     setIsLoading((oState:any)=>({...oState, property:false}))
    // }).catch((e)=>{setIsLoading((oState:any)=>({...oState, property:false}))})
    // getFacilityBuildingList().then(({ data }) => {
    //     setBuildingList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: item.id, name: item.building, communityId: `${item.communityId}` })) : [])
    //     setIsLoading((oState:any)=>({...oState, building:false}))
    // }).catch((e)=>{setIsLoading((oState:any)=>({...oState, building:false}))})

    getUserBuildingCommunityList(userId)
      .then(({buildingListData, communityListData}) => {
        setPropertyList(communityListData)
        setBuildingList(buildingListData)
        setIsLoading((oState: any) => ({...oState, building: false, property: false}))
      })
      .catch((e) => {
        setIsLoading({...isLoading, building: false, property: false})
      })

    // getFmCommunityUser('?user_id=' + userId).then(({ data }) => {
    //     if (data && Array.isArray(data)) {
    //         setBuildingList(data.map((item: any) => ({ id: `${item.building_id}`, name: item.building_name, communityId: `${item.community_id}` })))

    //         const communityList = data.map((item: any) => ({ id: `${item.community_id}`, name: item.community }))
    //         const filteredCommulityList = communityList?.filter((value, index, self) => index === self.findIndex((el) => (el.id === value.id)))
    //         setPropertyList(filteredCommulityList)
    //     }
    //     setIsLoading((oState: any) => ({ ...oState, building: false, property: false }));
    // }).catch((e) => { setIsLoading({ ...isLoading, building: false, property: false }) })
  }, [])

  const initialValues = {
    firstNameField: '',
    lastNameField: '',
    emirateIDField: '',
    mobileNumberField: '',
    emailIDField: '',
    company_id: '',
    property_id: [],
    building_id: [],
    designationField: '',
    // fmContractNumberField: '',
  }
  const onSubmit = (values: any) => {
    // const newUser = {
    //     first_name: values.firstNameField,
    //     last_name: values.lastNameField,
    //     email: values.emailIDField,
    //     emirates_id: values.emirateIDField,
    //     phone: values.mobileNumberField,
    //     designation: values.designationField,
    //     building_id: values.building_id,
    //     company_id: values.company_id,
    //     id_role: addRole,   //FCS
    //     created_by: userId
    // }
    // setRequestParams(newUser);

    setSubmitting(true)
    setSubmitError('')

    const formData = new FormData()
    formData.append('first_name', values.firstNameField)
    formData.append('last_name', values.lastNameField)
    formData.append('email', values.emailIDField)
    formData.append('emirates_id', values.emirateIDField)
    formData.append('phone', selectedMobileCode + values.mobileNumberField)
    formData.append('designation', values.designationField)
    formData.append('company_id', values.company_id)
    formData.append('id_role', addRole)
    // formData.append('fm_contract_number', values.fmContractNumberField)
    // formData.append("created_by", userId);
    values?.building_id?.forEach((element: any, index: any) => {
      formData.append(`buildingId[${index}]`, element)
    })

    setTimeout(() => {
      createContractorUser(formData)
        .then((response: any) => {
          setSubmitting(false)
          if (response.errorCode === 1) setSubmitError(response.errorDescription)
          else if (response.errorCode === 0) {
            formik.resetForm()
            setSubmitting(false)
            var ev = new Event('click', {bubbles: true})
            buttonEl.current.dispatchEvent(ev)
          } else setSubmitError(t('Some error occured. Please try again !'))
        })
        .catch((e) => {
          // console.log(e, 'Error')
          setSubmitting(false)
          setSubmitError(t('Some error occured. Please try again !'))
        })
    }, 1000)
  }
  const validationSchema = Yup.object({
    firstNameField: Yup.string()
      .required(t('First Name is required!'))
      .min(3, t('Minimum 3 characters!'))
      .max(30, t('Maximum 30 characters!'))
      // .test('filled', (t('First Name cannot contain only blankspaces!')), (value) => {
      //   if (value) {
      //     return value.trim().length !== 0
      //   }
      //   return true
      // })
      .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
      .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!')),
    lastNameField: Yup.string()
      .required('Last Name is required')
      .min(3, t('Minimum 3 characters!'))
      .max(30, t('Maximum 30 characters!'))
      // .test('filled', t('Last Name cannot contain only blankspaces!'), (value) => {
      //   if (value) {
      //     return value.trim().length !== 0
      //   }
      //   return true
      // })
      .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
      .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!')),
    emirateIDField: Yup.string()
      .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
      .required(t('Emirates ID is required!'))
      .matches( /^(\d+-?)+\d+$/, t("Emirates ID is not valid!") )
      .min(15, t('Min length is 15!'))
      .max(25, t('Maximum 25 characters!')),
      // .test('len', 'Emirates ID is required.', (value) => {
      //   return value ? (value?.trim()).length > 0 : false
      // }),

    mobileNumberField: Yup.string()
      .min(3, t('Mobile No. should be greater then 3 digits!'))
      .max(15, t('Mobile No. should be smaller then 15 digits!'))
      .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
      .matches(/^[^\s].*/, t('Mobile No. cannot start with a blankspace!'))
      .required(t('Mobile No. is required!')),
    emailIDField: Yup.string()
      .email(t('Please enter vaild email format!'))
      .required(t('Email is required!'))
      .matches(/^[^\s].*/, t('Email Id cannot start with a blankspace!')),
    company_id: Yup.string().required(t('Company Name is required!')),
    // .matches(/^[a-z0-9]+$/i, "Special characters are not allowed for Company Name"),
    property_id: Yup.array().min(1, t('Property is required!')),
    building_id: Yup.array().min(1, t('Building is required!')),
    // property_id: Yup.string().required('Property is required'),
    // building_id: Yup.string().required('Building is required'),
    // fmContractNumberField: Yup.string()
    // .required('FM Contract Number is required')
    // .max(25,"Max ength is 25"),
    designationField: Yup.string()
      .required(t('Designation is required!'))
      .max(50, t('Max length is 50!'))
      .matches(/^[a-z0-9]+$/i, t('Special characters are not allowed for Designation!'))
      .matches(/^[^\s].*/, t('Designation cannot start with a blankspace!')),
  })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  useEffect(() => {
    // let selectedPropertIDList = Array.isArray(formik.values.property_id) ? formik.values.property_id: [formik.values.property_id] ;
    // let selectedBuildingIDList = Array.isArray(formik.values.building_id) ? formik.values.building_id: [formik.values.building_id] ;
    let selectedPropertIDList = Array.isArray(selectedProperties)
      ? selectedProperties.map((item: any) => item.value)
      : []
    let selectedBuildingIDList = Array.isArray(selectedBuildings)
      ? selectedBuildings.map((item: any) => item.value)
      : []

    // console.log(selectedPropertIDList, selectedBuildingIDList);

    if (selectedPropertIDList) {
      formik.setFieldValue('property_id', selectedPropertIDList)
      // formik.setFieldTouched('property_id', true)
    } else {
      formik.setFieldValue('property_id', [])
      // formik.setFieldTouched('property_id', false)
    }

    const filteredBuildingList = buildingList?.filter((item: any) =>
      selectedPropertIDList.includes(item.communityId)
    )
    setFilteredBuildingList(filteredBuildingList)
    // console.log(filteredBuildingList, 'filteredBuildingList');

    const updatedSelectedBuildingList = filteredBuildingList?.filter((item: any) =>
      selectedBuildingIDList.includes(item.id)
    )
    const updatedSelectedBuildingIDList = filteredBuildingList?.map((item: any) => item.id)
    if (updatedSelectedBuildingIDList) {
      formik.setFieldValue('building_id', updatedSelectedBuildingList)
      // formik.setFieldTouched('building_id', true)
    } else {
      formik.setFieldValue('building_id', [])
      // formik.setFieldTouched('building_id', false)
    }

    const formatedSelectedBuildingList = updatedSelectedBuildingList?.map((item: any) => ({
      label: item.name,
      value: item.id,
    }))
    setSelectedBuildings(formatedSelectedBuildingList)
    // }, [formik.values.property_id])
  }, [selectedProperties])

  useEffect(() => {
    let selectedBuildingIDList = Array.isArray(selectedBuildings)
      ? selectedBuildings.map((item: any) => item.value)
      : []
    if (selectedBuildingIDList) {
      formik.setFieldValue('building_id', selectedBuildingIDList)
      // formik.setFieldTouched('building_id', true)
    } else {
      formik.setFieldValue('building_id', [])
      // formik.setFieldTouched('building_id', false)
    }
    // console.log(selectedBuildingIDList, 'selectedBuildingIDList');
  }, [selectedBuildings])

  function successNewUser() {
    var ev = new Event('click', {bubbles: true})
    buttonEl.current.dispatchEvent(ev)
    formik.resetForm()
  }
  const isError =
    companyList?.length === 0
      ? t('No company data found!')
      : propertyList?.length === 0
      ? t('No property data!')
      : buildingList?.length === 0
      ? t('No building data found!')
      : ''

  const isLoadingData = isLoading.company || isLoading.property || isLoading.building
  // const noBuilding = (!isLoadingData && buildingOptions?.length === 0)
  // console.log(companyList, propertyList, buildingList);
  // console.log(isLoading, 'isLoading');

  // console.log(selectedProperties, 'MultiSelect');

  const propertyOptions = propertyList.map((item: any) => ({label: item.name, value: item.id}))
  const buildingOptions = filteredBuildingList.map((item: any) => ({
    label: item.name,
    value: item.id,
  }))

  const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
    setMelectedMobileCode(country?.dialCode && !phoneNumber.includes('+')? `+${country?.dialCode}` : '')
    formik.setFieldValue('mobileNumberField', phoneNumber)
    formik.setFieldTouched('mobileNumberField')
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/fmsm-my-team/supervisor'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              <span className='text-black'>
                {/* {addRole === 'FCS' ? 'Add Supervisor(FC)' : 'Add Team(FM)'} */}
                {t("Add FCSS")}
              </span>
            </Link>
          </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
                {isLoadingData ? (
                  <div className='col-12 d-flex flex-column justify-content-center mt-5'>
                    <Loader />
                  </div>
                ) : (
                  isError !== '' && (
                    <div className='col-12 mt-5'>
                      <div className='alert alert-danger mt-2 text-center w-100' role='alert'>
                        {isError}
                      </div>
                    </div>
                  )
                )}
                <div className='row form-add-design'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("First Name")} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('firstNameField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('First Name')}
                      />
                      {formik.touched.firstNameField && formik.errors.firstNameField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.firstNameField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Last Name")} <span className='text-danger'> * </span></label>
                      <input
                        {...formik.getFieldProps('lastNameField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Last Name')}
                      />
                      {formik.touched.lastNameField && formik.errors.lastNameField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.lastNameField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Emirates ID")} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('emirateIDField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Emirates Id')}
                      />
                      {formik.touched.emirateIDField && formik.errors.emirateIDField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.emirateIDField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Mobile Number")} <span className='text-danger'> * </span>
                      </label>
                      <IntlTelInput
                        preferredCountries={['ae', 'in', 'us']}
                        containerClassName='intl-tel-input w-100'
                        separateDialCode={true}
                        fieldName='mobile_no'
                        value={formik.values.mobileNumberField}
                        inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                        onPhoneNumberChange={handlePhoneNumber}
                        onPhoneNumberBlur={handlePhoneNumber}
                        placeholder={t('Mobile No.')}
                      />
                      {/* <input
                        {...formik.getFieldProps('mobileNumberField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder='Mobile Number'
                      /> */}
                      {formik.touched.mobileNumberField && formik.errors.mobileNumberField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.mobileNumberField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Email ID")} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('emailIDField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Email Id')}
                      />
                      {formik.touched.emailIDField && formik.errors.emailIDField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.emailIDField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-5 mb-md-7'>
                      <label className='form-label fw-bolder'>
                        {' '}
                        {t("Company Name")} <span className='text-danger'> * </span>{' '}
                      </label>
                      {/* <select
                        className='form-control form-select fw-bolder form-control-solid'
                        data-control='select2'
                        data-allow-clear='true'
                        name='company_id'
                        onChange={formik.handleChange}
                        value={formik.values.company_id}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          Please Select
                        </option>
                        {companyList?.map((item: any, index: number) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select> */}
                      <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select Company Name')}
                          optionFilterProp='children'
                          value={
                            formik.values.company_id !== '' ? formik.values.company_id : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue('company_id', val)
                          }}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {companyList?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                      {formik.touched.company_id && formik.errors.company_id && (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.company_id}</small>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-5 mb-md-7'>
                      <label className='form-label fw-bolder'>
                        {' '}
                        {t("Property")} <span className='text-danger'> * </span>{' '}
                      </label>
                      <MultiSelect
                        options={propertyOptions}
                        value={selectedProperties}
                        onChange={setSelectedProperties}
                        labelledBy='Select-property'
                        hasSelectAll={false}
                      />
                      {formik.touched.property_id && formik.errors.property_id && (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.property_id}</small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-2'>
                      <label className='form-label fw-bolder'>
                        {' '}
                        {t("Building")} <span className='text-danger'> * </span>{' '}
                      </label>
                      <MultiSelect
                        options={buildingOptions}
                        value={selectedBuildings}
                        onChange={setSelectedBuildings}
                        labelledBy='Select-Building'
                        hasSelectAll={false}
                      />
                      {formik.touched.building_id && formik.errors.building_id && (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.building_id}</small>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-2'>
                      <label className='form-label fw-bolder'>
                        FM Contract Number <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('fmContractNumberField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder='FM Contract Number'
                      />
                      {formik.touched.fmContractNumberField &&
                      formik.errors.fmContractNumberField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>
                            {formik.errors.fmContractNumberField}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </div> */}

                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-2'>
                      <label className='form-label fw-bolder'>
                        {t("Designation")} <span className='text-danger'> * </span>
                      </label>
                      <input
                        {...formik.getFieldProps('designationField')}
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t('Designation')}
                      />
                      {formik.touched.designationField && formik.errors.designationField ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className='text-danger'>{formik.errors.designationField}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='col-12 text-end d-flex flex-column align-items-end justify-content-end mt-5'>
          {isSubmitting && <Loader />}
          {!isSubmitting && (
            <div>
              <button
                onClick={() => {
                  history.goBack()
                }}
                type='button'
                className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'
              >
                {t("Cancel")}
              </button>
              <button
                onClick={formik.submitForm}
                type='submit'
                className='btn btn-primary fw-bold px-10 py-3'
              >
                {t("Submit")}
              </button>
            </div>
          )}
          {submitError && (
            <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
              {submitError}
            </div>
          )}
          <p
            ref={buttonEl}
            data-bs-target='#req-submitted-popup'
            data-bs-toggle='modal'
            data-bs-dismiss='modal'
            className='invisible d-none btn btn-primary fw-bold px-10 py-3'
          />
          <CustomRequestSuccessfulPopup
            successMessage={'Soft Services Added Successfully'}
            href={'/fmsm-my-team/supervisor'}
          />
        </div>
      </div>
    </div>
  )
}
