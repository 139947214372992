import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import EventPic from "../../../../assets/images/event_pic.png";
import { RejectPopup } from './RejectPopup';
import { RequestMoreInformationPopup } from './RequestMoreInformationPopup';
import { useTranslation } from 'react-i18next';
import { getApprovals, getMarketPlaceDetails, marketPlaceList } from '../../../../constants/axios/apis';
import { CustomLoaderCompact, NoDataFound } from '../../../../constants/generics/customActios';
import { formatDate, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import { ApprovalActionWrapper } from '../ApprovalAction/ApprovalActionWrapper';

export function MTAApprovalsDetails({setPopupSuccessMsg}:any){
    const { t } = useTranslation()
    const history = useHistory();
    let params: any = {};
    params = useLocation();
    const { approvalData } = params.state || undefined;

    let userID: any = ''
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userID = JSON.parse(userDetail).id
    }

    const [approval, setApproval] = useState<any>();
    const [marketPlace, setMarketPlace] = useState<any>();
    const [loading, setLoading] = useState<any>({
        approval: true,
        market: true,
        error: false
    })

    useEffect(() => {
        if(approvalData?.id){
            setLoading((oState: any) => ({ ...oState, approval: true }))
            getApprovals(`?id=${approvalData.id}`).then((response: any) => {
                if (response?.errorCode === 0 && Array.isArray(response.data) && response.data.length > 0) {
                    setApproval(response.data[0])
                    setLoading((oState: any) => ({ ...oState, approval: false , error: false}))
                }else{
                    setLoading((oState: any) => ({ ...oState, approval: false , error: true}))
                }
            }).catch((e) => setLoading((oState: any) => ({ ...oState, approval: false , error: true})))
        }
    }, [approvalData])

    useEffect(() => {
        if (approval?.object_id) {
            setLoading((oState: any) => ({ ...oState, market: true }))
            marketPlaceList(`?id=${approval.object_id}`).then(({ data }: any) => {
                if (data && data[0]) {
                    setMarketPlace(data[0])
                }
                setLoading((oState: any) => ({ ...oState, market: false }))
            }).catch((e) => setLoading((oState: any) => ({ ...oState, market: false })))

            // getMarketPlaceDetails(approvalData.object_id).then((response: any) => {
            //     if (response.data.length > 0) {
            //         setMarketPlace(response.data[0])
            //     }
            //     setLoading((oState: any) => ({ ...oState, market: false }))
            // }).catch((e) => setLoading((oState: any) => ({ ...oState, market: false })))
        }
    }, [approval])

    return (
        <div className="com-card-section mt-n7">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <span className='c-pointer' onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        {t("Details")}
                    </h4>
                </div>
                {(loading.approval || (loading.market && !loading.error)) ? <CustomLoaderCompact />
                    : loading.error ? <div className="rounded"><NoDataFound /></div>
                        :
                        <>
                            <div className="col-12">
                                <div className="card mb-7 overflow-hidden">
                                    <div className="card-body p-7 mt-md-0 mt-8">
                                        <div className="row align-items-center ">
                                            <div className="col-md-3 col-lg-3 col-xl-3 mta_details_event_pic mb-3 mb-md-0">
                                                <img src={marketPlace?.image?.[0]} className="img-fluid" />
                                            </div>
                                            <div className="col-md-9 col-lg-9 col-xl-9">
                                                <div className="row">
                                                    <div className="col-md-12 col-12">
                                                        <span className="text-black fw-bolder font-16 d-block mb-4">
                                                            {marketPlace?.name}
                                                        </span>
                                                    </div>
                                                    {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-5">
                                                                <h6 className="text-theme-gray mb-3 fw-normal font-14">Merchant Name</h6>
                                                                <span className="text-black fw-bold font-16"> 2103 </span>
                                                            </div> */}
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-5">
                                                        <div className="">
                                                            <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Event Start Date")}</h6>
                                                            <span className="text-black fw-bold font-16"> {formatDate(marketPlace?.start_date)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-5">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Event End Date")}</h6>
                                                        <span className="text-black fw-bold font-16">{formatDate(marketPlace?.end_date)}</span>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-5">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Event Start Time")}</h6>
                                                        <span className="text-black fw-bold font-16">{formatTimeMeridiem(marketPlace?.start_time)}</span>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-5">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Event End Time")}</h6>
                                                        <span className="text-black fw-bold font-16">{formatTimeMeridiem(marketPlace?.end_time)}</span>
                                                    </div>
                                                    {marketPlace?.type && 
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-5">
                                                            <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Type")}</h6>
                                                            <span className="text-black fw-bold font-16">{marketPlace?.type}</span>
                                                        </div>
                                                    }
                                                    {approval.createdBy === userID && 
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-5">
                                                            <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Created By")}</h6>
                                                            <span className="text-black fw-bolder font-16">{t("Self")}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="card-status">
                                                {approval?.status === 'Approved' ? <h4 className="approved">{t("Approved")}</h4>
                                                    : approval?.status === 'Pending' ? <h4 className="pending">{t("Pending")}</h4>
                                                        : approval?.status === 'Rejected' ? <h4 className="rejected">{t("Rejected")}</h4>
                                                            : ''

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="card mb-7">
                                    <div className="card-body p-7">
                                        <div className="row align-items-center ">
                                            <div className="col-12">
                                                <div className="row form-add-design">
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Building Name")} </label>
                                                            <input type="text" value={marketPlace?.building_name} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    {(marketPlace?.type !== 'Event') &&
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                            <div className="mb-7">
                                                                <label className="form-label fw-bolder"> {t("% OFF")} </label>
                                                                <input type="text" value={marketPlace?.offer} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Category")} </label>
                                                            <input type="text" value={marketPlace?.category_name} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="N/A" disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Contact")} </label>
                                                            <input type="text" value={marketPlace?.contact} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="N/A" disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Web")} </label>
                                                            <input type="text" value={marketPlace?.web_link} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    {(marketPlace?.type === 'Event') ?
                                                    <>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Address Line 1")} </label>
                                                            <input type="text" value={marketPlace?.address1} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Address Line 2")} </label>
                                                            <input type="text" value={marketPlace?.address2} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Location")} </label>
                                                            <input type="text" value={marketPlace?.location} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    </>:
                                                    <>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Location")} </label>
                                                            <input type="text" value={marketPlace?.location} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Redeem Limit")} </label>
                                                            <input type="text" value={marketPlace?.redeemLimit} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Redeemed Count")} </label>
                                                            <input type="text" value={marketPlace?.redeemCount} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    </>
                                                    }
                                                    
                                                    <div className="col-md-12 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder"> {t("Description")} </label>
                                                            <input type="text" value={marketPlace?.description} className="form-control fw-bolder form-control-solid bg-light border-0" placeholder="" disabled />
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        approval?.status === 'Pending' ?
                                                            <>
                                                                <div className="col-12 text-md-end">
                                                                    <button data-bs-target="#requestmore-information-popup-modal" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit'
                                                                        className='btn btn-primary fw-bold px-10 py-3 me-5 mb-3 mb-sm-0 '>
                                                                        {t("Request more Information")}
                                                                    </button>
                                                                    <button data-bs-target="#teject-popup-modal" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit'
                                                                        className="btn btn-outline-primary fw-bold px-10 py-3 me-5 mb-3 mb-sm-0 bg-white">
                                                                        {t("Reject")}
                                                                    </button>
                                                                    <button data-bs-dismiss="modal" type='submit'
                                                                        className='btn btn-primary fw-bold px-10 py-3 mb-3 mb-sm-0'>
                                                                        {t("Approve")}
                                                                    </button>
                                                                </div>
                                                                <RejectPopup />
                                                                <RequestMoreInformationPopup />
                                                            </>
                                                            : <></>
                                                    } */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ApprovalActionWrapper approvalDetail={approval} setPopupSuccessMsg={setPopupSuccessMsg}/>
                            {/* {approval.createdBy !== userID && 
                                <Actions approvalDetail={approval} setPopupSuccessMsg={setPopupSuccessMsg} />
                            } */}
                        </>
                    
                }
            </div>
        </div>
    );
}