import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux';
import {useIntl} from 'react-intl'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import anounceImg from '../../../../assets/images/bg_1.jpg'
import { formatDate, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import { getUserRole } from '../../../../constants/generics/userCheck';
import { getFCSAnnouncements } from '../../../../constants/axios/apis';
import { CustomLoaderCompact } from '../../../../constants/generics/customActios';
import AnnounceBackupImg from '../../../../assets/images/announcement-img.png'

import { useTranslation } from 'react-i18next';
import moment from 'moment';

export function AnnouncementsDetails() {
    const intl = useIntl()
    const location = useLocation<any>()
    const [menu, setMenu] = useState('')
    const [announcement, setAnnouncement] = useState<any>()
    const [loading, setLoading] = useState(true);

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    let announcementID = ''
    if(location?.state?.item?.id)
        announcementID = location?.state?.item?.id

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }

    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
        }
    }, [])

    useEffect(() => {
        
        setLoading(true)
        setAnnouncement({})
        getFCSAnnouncements(`?id=${announcementID}`).then((response: any) => {
            if(response?.errorCode === 0 && response?.data && Array.isArray(response.data) && response.data?.[0]){
                setAnnouncement(response.data[0])
            }
            setLoading(false)
        })

    }, [announcementID]);

    const backLink = (user_role === 'T') ? '/announcements'
        : (user_role === 'FC' || user_role ==='FCS' || user_role === 'FMT' || user_role === 'FMSM') ? '/su-announcement'
        : (user_role === 'FCSS') ? '/fc-ss-announcements/list' : '/';

    // const banner_img = announcement?.cover_image ? announcement?.cover_image : announcement?.categoryIcon
    //categoryIcon is wrong, using local image directly
    const banner_img = announcement?.cover_image && (typeof announcement?.cover_image === 'string') ? announcement?.cover_image : AnnounceBackupImg
    const { t } = useTranslation();
    return (
        <div className="col-12">
            <div className="row mt-n5">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <h4 className="card-title font-18 text-black mb-7">
                                <Link to={backLink} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                <span className="text-black"> {t("Community Post")} </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body px-8 py-8">
                            {
                                loading ? <CustomLoaderCompact />:
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="announcement-main-images mb-10">
                                                <img src={banner_img} className="" alt='announcement-img'/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <h4 className="card-title font-20 text-black mb-7">
                                                        {announcement?.title}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="text-theme-gray mb-4 fw-normal font-14"> {t("Building")} : </label>
                                                    <span className="text-black font-15"> {announcement?.building_name} </span>
                                                </div>
                                                <div className="col-12">
                                                    <label className="text-theme-gray mb-4 fw-normal font-14"> {t("Date")} : </label>
                                                    {announcement?.endDate && (moment(announcement?.date).diff(moment(announcement?.endDate), 'days') !== 0)? 
                                                        <span className="text-black font-15"> {t("From")} <span className="fw-bolder">{formatDate(announcement?.date)}</span> {t("To")} <span className="fw-bolder">{formatDate(announcement?.endDate)}</span> </span>
                                                        :
                                                        <span className="text-black font-15"> {t("From")} <span className="fw-bolder">{formatDate(announcement?.date)}</span> {t("Onward")}</span>
                                                    }
                                                    {/* <span className="text-black fw-bold font-15"> {formatDate(announcement?.date)}</span> */}
                                                </div>
                                                <div className="col-12">
                                                    <label className="text-theme-gray mb-4 fw-normal font-14"> {t("Time")} : </label>
                                                    <span className="text-black font-15"> {t("From")} <span className="fw-bolder">{formatTimeMeridiem(announcement?.start_time)}</span> {t("To")} <span className="fw-bolder">{formatTimeMeridiem(announcement?.end_time)}</span> </span>
                                                </div>
                                                <div className="col-12">
                                                    <label className="text-theme-gray mb-0 fw-normal font-14"> {t("Description")} : </label>
                                                    <span className="text-black font-15"> {announcement?.announcement}</span>
                                                    {/* <span className="text-black font-15 ps-3" dangerouslySetInnerHTML={{__html: announcement?.announcement}} /> */}
                                                </div>

                                                {/* <div className="col-sm-6 col-md-3 col-12">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-4 fw-normal font-14"> {t("Category")} </h6>
                                                        <span className="text-black fw-bold font-15"> {announcement?.category_value} </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-3 col-12">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-4 fw-normal font-14"> {t("Building")} </h6>
                                                        <span className="text-black fw-bold font-15"> {announcement?.building_name} </span>
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-sm-4 col-12">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-4 fw-normal font-14"> Attachments </h6>
                                                        <div className='d-flex'>
                                                            <div className='bg-light rounded-pill me-3 text-center py-1 px-5'>
                                                                <p className='mb-0'>
                                                                    <i className='bi bi-image fonts-1 me-3'></i>
                                                                    IMG13145
                                                                </p>
                                                            </div>
                                                            <div className='bg-light rounded-pill me-3 text-center py-1 px-5'>
                                                                <p className='mb-0'>
                                                                    <i className='bi bi-image fonts-1 me-3'></i>
                                                                    IMG13145
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}