import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import WorkOrdersIcon from "../../../../assets/images/work-order-quick-link.svg";
import ActivitiesIcon from "../../../../assets/images/activities-quick-link.svg";
import MaintenanceIcon from "../../../../assets/images/maintenance-quick-link.svg";
import MyTeamIcon from "../../../../assets/images/my-team-quick-link.svg";
import ReportIcon from "../../../../assets/images/reports-quick-link.png";
import CalanderIcon from "../../../../assets/images/calendar-quick-link.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'

import { useTranslation } from 'react-i18next';


export function SupervisorQuickLinks() {
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow:4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow:3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h4 className="card-title"> {t("Quick Links")} </h4>
                                </div>
                                {/*<div className="col-md-6 col-6 text-right">*/}
                                {/*<Link to={''} className="text-decoration-none view-all"> */}
                                {/*<span> View All </span>*/}
                                {/*<img src={RightArrow} className="img-fluid" /> */}
                                {/*</Link>*/}
                                {/*</div>*/}
                            </div>
                            <div className="quick-link-sec">
                                <div>
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <Slider {...settings}>
                                                <div>
                                                    <Link to={'/facility-workorder/list/corrective/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-6 px-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={WorkOrdersIcon} alt="Work Orders" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Work Orders")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/activities/list/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-6 px-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={ActivitiesIcon} alt="Activities" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Activities")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/facility-maintenance/list/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-6 px-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={MaintenanceIcon} alt="Maintenance" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Maintenance")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/fc-su-myteam'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-6 px-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={MyTeamIcon} alt="My Team" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("My Team")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* <div>
                                                    <Link to={'/fc-su-report'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={ReportIcon} alt="Maintenance" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0">Reports</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div> */}
                                                <div>
                                                    <Link to={'/fc-su-calendar/all'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-6 px-6">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={CalanderIcon} alt="Calendar" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0">{t("Calendar")}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>                                                
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}