import React, {useState, useEffect} from 'react'
import Home from '../../../../../../../../assets/images/nation-towers-new.png'
import {Link, useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../../../../../constants/generics/userCheck';
import NoImage from "../../../../../../../../assets/images/House.png";

export function Details({approval}:any) {
  const { t } = useTranslation();
  let params: any = {}
  params = useLocation()
  // const {approval} = params.state
  const bac = approval?.allotted_access_card?.find((el: any) => el.name === 'Building Access Card')
    ?.no_of_cards
  const mdk = approval?.allotted_access_card?.find((el: any) => el.name === 'Main Door Keys')
    ?.no_of_cards
  const pac = approval?.allotted_access_card?.find((el: any) => el.name === 'Parking Access Card')
    ?.no_of_cards
  const oacCardData = approval?.card_details?.other_access_cards?.card_number
  const oacCount = oacCardData ? Object.keys(oacCardData)?.length : 0

  const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);
  

  const completedStatus = 
    ((user_role === 'FMSM' && approval?.subReference===5) || (user_role === 'FCSS' && approval?.subReference===6))
    && approval?.status === 'Pending' && approval?.handoverStatus === 'Closed' ?
    approval?.subReference===5 ? t("Movein Pending") : t("Moveout Pending") : ''

  // const approvalStatus = 
  //   (user_role === 'FMSM' && approval?.subReference===5 && approval?.handoverStatus === 'Closed' ) || (user_role === 'FMSM' && approval?.status === 'Approved') ?
  //   t("Approved") : t("Pending")
  const approvalStatus = 
    completedStatus.length ? t("Approved")
    : approval?.status === "Approved" ? t("Closed") :t("Pending")

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-0'>
                  <div className='approval-move mb-5'>
                    <img src={approval?.communityLogo && approval?.communityLogo.length ? approval?.communityLogo : NoImage} className="img-fluid rounded-5" />
                  </div>
                  {/* <div className='approval-move'>
                    <img src={approval.buildingIcon && approval.buildingIcon.length ? approval.buildingIcon : NoImage} className='img-fluid' />
                  </div> */}
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white shadow-none rounded-0'>
                    <div className='card-body p-0'>
                      <div className='row'>
                        <div className='col-12 mb-5 text-end'>
                          {completedStatus !== '' &&
                            <span className={ 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder me-3' } >
                              {completedStatus}
                            </span>
                          }
                          <span
                            className={
                              approvalStatus === 'Pending'
                                ? 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                                : 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                            }
                            // className={
                            //   approval?.status === 'Pending'
                            //     ? 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                            //     : approval?.status === 'Approved'
                            //     ? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                            //     : 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                            // }
                          >
                            {approvalStatus}
                            {/* {approval?.status === 'Pending'
                              ? t("Pending")
                              : approval?.status === 'Approved'
                              ? t("Approved")
                              : approval?.status} */}
                          </span>
                        </div>

                        <div className='col-sm-12 col-12 mb-7'>
                          <h6 className='text-black mb-0 fw-bold font-15'>
                            {' '}
                            {t("Tenant Name")} : {approval?.cutomerName
                              ? approval?.cutomerName
                              : '--'}{' '}
                          </h6>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t("Building Name")}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {approval?.building ? approval?.building : '--'}{' '}
                          </span>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Unit Number")} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {approval?.propertyCode ? approval?.propertyCode : '--'}{' '}
                          </span>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7">
                          <h6 className="text-theme-gray mb-3 fw-normal font-14"> Main Door Keys </h6>
                          <span className="text-black fw-bold font-15"> </span>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t("Building Access Cards")}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'> {bac ? bac : 0}</span>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t("Parking Access Cards")}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'> {pac ? pac : 0}</span>
                        </div>
                      </div>
                      {approval?.subReference === 6 && (
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-0'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t("Other Access Cards")}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {oacCount ? oacCount : 0}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
