import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const FinancePaymentMainTab = ({ searchItem, setSearchItem, activeButton, setActiveButton }: any) => {

    const { t } = useTranslation()

    const location = useLocation();
    let s = location.pathname
    let link = s.substring(s.lastIndexOf("/") + 1, s.length)

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row flex-stack announcement-sec mb-4">
                        <div className="col-md-12">
                            <h4 className="card-title font-18 text-black"> {t("Payment")} </h4>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-md-9 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/finance-payments/list/all/creditCard-payment' ?
                                                            'active' :
                                                            location.pathname === '/finance-payments/list/all/bank-transfer' ?
                                                                'active' :
                                                                location.pathname === '/finance-payments/list/all/cheque' ?
                                                                    'active' :
                                                                    '')}
                                                    to={`/finance-payments/list/all/${link}`}>
                                                    {t("All")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/finance-payments/list/rent/creditCard-payment' ?
                                                            'active' :
                                                            location.pathname === '/finance-payments/list/rent/bank-transfer' ?
                                                                'active' :
                                                                location.pathname === '/finance-payments/list/rent/cheque' ?
                                                                    'active' :
                                                                    '')}
                                                    to={`/finance-payments/list/rent/${link}`}>
                                                    {t("Rent")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/finance-payments/list/security-deposits/creditCard-payment' ?
                                                            'active' :
                                                            location.pathname === '/finance-payments/list/security-deposits/bank-transfer' ?
                                                                'active' :
                                                                location.pathname === '/finance-payments/list/security-deposits/cheque' ?
                                                                    'active' :
                                                                    '')}
                                                    to={`/finance-payments/list/security-deposits/${link}`}>
                                                    {t("Security Deposits")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/finance-payments/list/service-charges/creditCard-payment' ?
                                                            'active' :
                                                            location.pathname === '/finance-payments/list/service-charges/bank-transfer' ?
                                                                'active' :
                                                                location.pathname === '/finance-payments/list/service-charges/cheque' ?
                                                                    'active' :
                                                                    '')}
                                                    to={`/finance-payments/list/service-charges/${link}`}>
                                                    {t("Service Charges")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/finance-payments/list/other-charges/creditCard-payment' ?
                                                            'active' :
                                                            location.pathname === '/finance-payments/list/other-charges/bank-transfer' ?
                                                                'active' :
                                                                location.pathname === '/finance-payments/list/other-charges/cheque' ?
                                                                    'active' :
                                                                    '')}
                                                    to={`/finance-payments/list/other-charges/${link}`}>
                                                    {t("Other Charges")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-3 col-12 text-end my-md-auto my-4'>
                                    <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                                        <div className='form-group has-search'>
                                            <span className='fa fa-search pt-2 form-control-feedback'></span>
                                            <input
                                                type='text'
                                                className='form-control ps-10'
                                                onChange={(e) => setSearchItem(e.target.value)}
                                                placeholder={t('Search')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { FinancePaymentMainTab }