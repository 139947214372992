import React from 'react'
import {Link} from 'react-router-dom'
import docicon from '../../../../../assets/images/doc-ioc.png'
import Place from '../../../../../assets/images/relax-area-resort.png'
import { useTranslation } from 'react-i18next';

export function Handbook() {
  const { t } = useTranslation();
  return (
    <div className=''>
      <div className='col-12'>
        <div className='row align-items-center'>
          <div className='col-md-9 text-start'>
            <h4 className='card-title font-18 text-black mb-0'>{t("Handbook")} </h4>
          </div>
        </div>
      </div>
      <div className='row mt-7'>
        <div className='col-md-6 col-12 mb-7'>
          <div className='border rounded p-4'>
            <div className='row align-items-center'>
              <div className='col-sm-9 col-12'>
                <span>
                  <img src={docicon} alt='docx-icon' className='docx-icon me-3' />
                </span>
                <span className='text-black fw-bold font-14 text-short mb-0'>{t("Handbook")}</span>
              </div>
              <div className='col-sm-3 col-12'>
                <a
                  className='btn btn-primary w-100 font-12 px-3 py-3'
                  href={Place} // nedd to change with real data
                  target='_blank'
                >
                  {t("Download")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
