import React,{FC, useState, useEffect} from 'react'
// import { VerticalGraph } from '../../VerticalGraph';
import Loader from '../../../../components/Loader';
import {VerticalGraph} from '../../../../components/layout/components/VerticalGraph'
import { CustomLoader2 } from '../../../../constants/generics/customActios';

const initReportData = {
    Corrective: {
        open:{ val: 0, rem: 0 },
        close:{ val: 0, rem: 0 },
        total: 0
    },
    Clearance: {
        open:{ val: 0, rem: 0 },
        close:{ val: 0, rem: 0 },
        total: 0
    },
    Preventive: {
        open:{ val: 0, rem: 0 },
        close:{ val: 0, rem: 0 },
        total: 0
    },
}
export const Rent:FC<any> = ({reportState, setReportState, reportType}) => {
    const { loading, response: reports, error } = reportState;
    const [reportVal, setReportVal] = useState(initReportData);

    // console.log(reportState.Clearance, 'response');
    // console.log(reportState.Preventive, 'response');
    

    useEffect(()=>{
        if(Object.keys(reportState.response).length > 0){

            // const max = Math.max.apply(Math, [
            //     reportState.response.Corrective.open,
            //     reportState.response.Corrective.closed,
            //     reportState.response.Clearance.open,
            //     reportState.response.Clearance.closed,
            //     reportState.response.Preventive.open,
            //     reportState.response.Preventive.closed,
            // ]);
            let CorrectiveData = initReportData.Corrective
            let ClearanceData = initReportData.Clearance
            let PreventiveData = initReportData.Preventive
            if(reportState.response.Corrective){
                CorrectiveData = {
                    open:{
                        val: reportState.response.Corrective.open + reportState.response.Corrective.Inprogress + reportState.response.Corrective.Onhold,
                        rem: reportState.response.Corrective.Completed
                    },
                    close:{
                        val: reportState.response.Corrective.Completed ,
                        rem: reportState.response.Corrective.open + reportState.response.Corrective.Inprogress + reportState.response.Corrective.Onhold
                    },
                    total:reportState.response.Corrective.open + reportState.response.Corrective.Inprogress + reportState.response.Corrective.Onhold + reportState.response.Corrective.Completed ,
                }
            }
            if(reportState.response.Clearances){
                ClearanceData = {
                    open:{
                        val: reportState.response.Clearances.open + reportState.response.Clearances.Inprogress + reportState.response.Clearances.Onhold,
                        rem: reportState.response.Clearances.Completed
                    },
                    close:{
                        val: reportState.response.Clearances.Completed ,
                        rem: reportState.response.Clearances.open + reportState.response.Clearances.Inprogress + reportState.response.Clearances.Onhold
                    },
                    total:reportState.response.Clearances.open + reportState.response.Clearances.Inprogress + reportState.response.Clearances.Onhold + reportState.response.Clearances.Completed ,
                }
            }
            if(reportState.response.Preventive){
                PreventiveData = {
                    open:{
                        val: reportState.response.Preventive.open + reportState.response.Preventive.Inprogress + reportState.response.Preventive.Onhold,
                        rem: reportState.response.Preventive.Completed
                    },
                    close:{
                        val: reportState.response.Preventive.Completed ,
                        rem: reportState.response.Preventive.open + reportState.response.Preventive.Inprogress + reportState.response.Preventive.Onhold
                    },
                    total:reportState.response.Preventive.open + reportState.response.Preventive.Inprogress + reportState.response.Preventive.Onhold + reportState.response.Preventive.Completed ,
                }
            }
            setReportVal(
                {
                    Corrective: CorrectiveData,
                    Clearance: ClearanceData,
                    Preventive: PreventiveData,
                }
            );

            console.log(CorrectiveData);
            console.log(ClearanceData);
            
        }
        
    },[reportState]);

    if(loading){
        return <CustomLoader2 />
    } 
    else if(!reportState || !reportState.response || reportState.response.length === 0)
        return <></>
    else if(reportState.error !== "")
        return <>
            <div className="row">
                <div className="col-12 text-center">
                    <div className="alert alert-danger w-100 mt-2" role="alert">
                        {reportState.error}
                    </div>
                </div>
            </div>
        </>

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        Overview
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card rounded-10">
                        <div className="card-body px-8 py-6">
                            {/*<div className="col-md-12">*/}
                            {/*<h4 className="fw-boldest text-black mt-3 mb-6 font-16">*/}
                            {/*Total Inspection (80)*/}
                            {/*</h4>*/}
                            {/*</div>*/}
                            <div className="row">
                                {loading && (
                                    <CustomLoader2 />
                                )}
                                {!loading && (
                                    <>  
                                        {(reportType ===  '' || reportType === 'corrective') &&
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    {/* <VerticalGraph
                                                        title="WorkOrder"
                                                        reportVal={reportVal.corrective}
                                                    /> */}
                                                    <VerticalGraph 
                                                        title="Corrective"
                                                        openValue={reportVal.Corrective.open}
                                                        closeValue={reportVal.Corrective.close}
                                                        totalValue={reportVal.Corrective.total}
                                                        />
                                                </div>
                                            </div>
                                        }
                                        {(reportType === '' || reportType === 'clearance') && 
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    {/* <VerticalGraph
                                                        title="Clearance"
                                                        reportVal={reportVal.clearance}
                                                    /> */}
                                                    <VerticalGraph 
                                                        title="Clearance"
                                                        openValue={reportVal.Clearance.open}
                                                        closeValue={reportVal.Clearance.close}
                                                        totalValue={reportVal.Clearance.total}
                                                        />
                                                </div>
                                            </div>
                                        }
                                        {(reportType === '' || reportType === 'preventive') && 
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    {/* <VerticalGraph
                                                        title="Preventive"
                                                        reportVal={reportVal.preventive}
                                                    /> */}
                                                    <VerticalGraph 
                                                        title="Preventive"
                                                        openValue={reportVal.Preventive.open}
                                                        closeValue={reportVal.Preventive.close}
                                                        totalValue={reportVal.Preventive.total}
                                                        />
                                                </div>
                                            </div>
                                        }
                                        {/* <div className="col-md-3">

                                        </div> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}