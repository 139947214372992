import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import Place from "../../../../assets/images/place-icons.png";
import bed from '../../../../assets/images/icon-bed.svg';
import Bath from '../../../../assets/images/icon-bath.svg';
import SQR from '../../../../assets/images/icon-sqft.svg';
import Yes from '../../../../assets/images/icon-parking.svg';
import { Content } from './ReadMore';
import { Amenities } from './Amenities';
import { OfferAnnounce } from './OfferAnnounce';
import { Google } from './Google';
import { ImageGallery } from './ImageGallery';
import { RootState } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import * as PM from '../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../../constants/generics/userCheck';
import { CustomLoader2 } from '../../../../constants/generics/customActios';

type LocationState = {
    id?: any
}

const PropertyDetails: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const location1 = useLocation<LocationState>();
    let proprtyDetails: any
    proprtyDetails = useSelector<RootState>(({ PM }) => PM.proprtydetails)
    const { id } = location1.state || proprtyDetails?.data?.id;
    const { t } = useTranslation();

    const state: any = useSelector((state) => state)
    const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

    useEffect(() => {
        if (id) {
            dispatch(PM.actions.proprtydetails(id))
        } else {
            // console.log("no id found");
        }
    }, [id])
    if (proprtyDetails === undefined) { return (<CustomLoader2 />) }
    return (
        <div className="col-md-12 col-12">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-6">
                        <div className="col-sm-4 col-8">
                            <h4 className="card-title font-18 text-black mb-0">
                                <Link to={'/pm-property-data/available'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                    <span className='text-black'> {t("Property Details")} </span>
                                </Link>
                            </h4>
                        </div>
                        {user_role !== 'PE' && (
                        <div className="col-sm-8 col-4">
                            <div className="properties_buttons float-end">
                                <div className="add_pro">
                                    <div className="properties_add_bt text-end">
                                        <Link to={'/pm-property-data/edit-details'} className="btn btn-primary btn-sm font-16">
                                            <i className="bi bi-pencil align-middle"></i> {t("Edit")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                         )}
                    </div>
                </div>
            </div>
            <div className="row mx-1">
                <div className="card">
                    <div className="card-body px-5 py-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <ImageGallery proprtyDetails={proprtyDetails?.data} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-8 col-12">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="prop_details_headding">
                                                    <h4 className="mb-0">{proprtyDetails?.data?.propretyName?proprtyDetails?.data?.propretyName:'-'}
                                                        {/* <span className="mx-10">
                                                            <i className="bi bi-heart-fill font-17 text-danger"></i>
                                                        </span> */}
                                                    </h4>
                                                </div>
                                                <div className="prop_details_headding">
                                                    <p className="mb-0 pt-5">
                                                        <img src={Place} className="img-fluid me-2" />
                                                        {proprtyDetails?.data?.propertyCode?.substring(proprtyDetails?.data?.propertyCode?.lastIndexOf("-") + 1, proprtyDetails?.data?.propertyCode?.length) + ' | '} {proprtyDetails?.data?.buildingType && proprtyDetails?.data?.buildingType + ' | '}  {proprtyDetails?.data?.Address && proprtyDetails?.data?.Address + ' | '}  {proprtyDetails?.data?.location && proprtyDetails?.data?.location}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <div className="row">
                                                <div className="prop_details_facilities">
                                                    <div className="offer_main_details_div_2">
                                                        {proprtyDetails?.data?.bedrooms && proprtyDetails?.data?.bedrooms !== '' && proprtyDetails?.data?.bedrooms !== '0' &&
                                                            <p>
                                                                <img src={bed} className="img-fluid" />
                                                                {proprtyDetails?.data?.bedrooms}
                                                            </p>
                                                        }
                                                        {proprtyDetails?.data?.bathrooms && proprtyDetails?.data?.bathrooms !== '' && proprtyDetails?.data?.bathrooms !== '0' &&
                                                            <p>
                                                                <img src={Bath} className="img-fluid" />
                                                                {proprtyDetails?.data?.bathrooms}
                                                            </p>
                                                        }

                                                        <p className="sqft">
                                                            <img src={SQR} className="img-fluid" />
                                                            {proprtyDetails?.data?.builtInArea?proprtyDetails?.data?.builtInArea:'-'} {t("Sqmt")}
                                                        </p>
                                                        <p>
                                                            <img src={Yes} className="img-fluid" />
                                                            {proprtyDetails?.data?.isParkingAvailable?proprtyDetails?.data?.isParkingAvailable:'-'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <h4 className="fw-bolder mb-3">{t("About the Property")}</h4>
                                                <Content proprtyDetails={proprtyDetails?.data} />
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <h4 className="fw-bolder mb-5">{t("Amenities")}</h4>
                                                <Amenities proprtyDetails={proprtyDetails?.data} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-12">
                                        <div className="col-12">
                                            <div className="row">
                                                <OfferAnnounce proprtyDetails={proprtyDetails?.data} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row mt-5">
                                                <div className="prop_details_headding">
                                                    <h4 className="fw-bolder mb-5">{t("Property Location")}</h4>
                                                    <p className="mb-0">
                                                        <img src={Place} className="img-fluid me-2" />
                                                        {proprtyDetails?.data?.propertyCode?.substring(proprtyDetails?.data?.propertyCode?.lastIndexOf("-") + 1, proprtyDetails?.data?.propertyCode?.length) + ' | '} {proprtyDetails?.data?.buildingType && proprtyDetails?.data?.buildingType + ' | '}  {proprtyDetails?.data?.Address && proprtyDetails?.data?.Address + ' | '}  {proprtyDetails?.data?.location && proprtyDetails?.data?.location}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <Google proprtyDetails={proprtyDetails?.data} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { PropertyDetails }
