import React, { useState, FC, useRef, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../../../assets/images/Left_Arrow.png'
import { ViewDetailsTab } from './ViewDetailsTab/ViewDetailsTab'
import { Details } from './Details/Details'
import { BuildingAccessCard } from './BuildingAccessCard/BuildingAccessCard'
import { ParkingAccessCard } from './ParkingAccessCard/ParkingAccessCard'
import {
  getFacilityAccessCards,
  submitAccessCardHandle,
} from '../../../../../../constants/axios/apis'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../../../../../constants/generics/userCheck'
import { CustomRequestSuccessfulPopup } from '../../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { CustomLoader2 } from '../../../../../../constants/generics/customActios'
import { useTranslation } from 'react-i18next';

const FCSSViewDetailsWrapper: React.FC = () => {
  const location = useLocation<any>()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  let accessCards = location?.state?.accessData ? location?.state?.accessData : {}
  const [accessCardData, setAccessCards] = useState<any>(accessCards)
  // const property_id = accessCardData
  // const id = accessCardData
  const [loading, setLoading] = useState(false)
  const [reloadData, setReloadData] = useState(true)
  const history = useHistory()

  const buttonEl: any = useRef(null)
  const [submitError, setSubmitError] = useState('')

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  const { t } = useTranslation();

  // useEffect(() => {
  //     getAllotedAccessId().then(({ data }: any) => {
  //         setAccessCards(data.find((element: any) => element.propertyId === property_id))
  //     })
  // }, [property_id])

  const statusChangePayload = accessCards?.status === 2 ? 'inactive' : 'open' // For Inactive and Open Tabs

  useEffect(() => {
    if (reloadData) {
      // getAllotedAccessId().then(({ data }: any) => {
      //     setAccessCards(data.find((element: any) => element.propertyId === property_id))
      //     setReloadData(false)
      // })
      const newPayload = '?id=' + accessCards.id + '&status=' + statusChangePayload
      getFacilityAccessCards(newPayload).then((response: any) => {
        setAccessCards(response.data[0])
        setReloadData(false)
      })
    }
  }, [accessCards, reloadData])

  const enabledHandOverButton =
    accessCardData?.card_details?.building_access_card?.signature?.length ||
    accessCardData?.card_details?.parking_access_card?.signature?.length
  let isParking =
    accessCardData.allotted_access_card &&
    accessCardData.allotted_access_card[0] &&
    accessCardData.allotted_access_card[0].name === 'Parking Access Card'
  let cardDetails
  let cardCount
  let lableName
  let cardTitle
  let cardAllotedAccessId
  let cardSignature
  let cardSignature2 //Only FMSM used for customer signature

  if (isParking) {
    if (accessCardData?.card_details?.parking_access_card) {
      cardDetails = accessCardData?.card_details?.parking_access_card?.card_number
      cardDetails = Object.values(cardDetails)
      cardDetails = cardDetails.map((item: any) => item.number)
    }
    cardCount = accessCardData?.allotted_access_card?.find(
      (el: any) => el.name === 'Parking Access Card'
    )?.no_of_cards
    lableName = 'Parking Access Card'
    cardTitle = 'Parking Access Card'
    cardAllotedAccessId =
      user_role === 'FCSS'
        ? accessCardData?.card_details?.parking_access_card?.allotted_access_id
        : accessCardData?.allotted_access_card?.find((el: any) => el.name === 'Parking Access Card')
          ?.id
    cardSignature =
      user_role === 'FCSS'
        ? accessCardData?.card_details?.parking_access_card?.signature
        : accessCardData?.card_details?.parking_access_card?.staff_signature
    cardSignature2 =
      user_role === 'FMSM' ? accessCardData?.card_details?.parking_access_card?.signature : ''
  } else {
    if (accessCardData?.card_details?.building_access_card) {
      cardDetails = accessCardData?.card_details?.building_access_card?.card_number
      cardDetails = Object.values(cardDetails)
      cardDetails = cardDetails?.map((item: any) => item.number)
    }
    lableName = 'Building Access Card'
    cardCount = accessCardData?.allotted_access_card?.find(
      (el: any) => el.name === 'Building Access Card'
    )?.no_of_cards
    cardTitle = 'Building Access Card'
    cardAllotedAccessId =
      user_role === 'FCSS'
        ? accessCardData?.card_details?.building_access_card?.allotted_access_id
        : accessCardData?.allotted_access_card?.find(
          (el: any) => el.name === 'Building Access Card'
        )?.id
    cardSignature =
      user_role === 'FCSS'
        ? accessCardData?.card_details?.building_access_card?.signature
        : accessCardData?.card_details?.building_access_card?.staff_signature
    cardSignature2 =
      user_role === 'FMSM' ? accessCardData?.card_details?.building_access_card?.signature : ''
  }

  // FCSS Handover Access Card
  const sendHandover = () => {
    const alloted_card_id =
      accessCardData?.card_details?.building_access_card?.allotted_access_id ||
      accessCardData?.card_details?.parking_access_card?.allotted_access_id
    setLoading(true)
    setSubmitError('')
    setTimeout(() => {
      submitAccessCardHandle(accessCardData.id, [alloted_card_id], accessCardData.sr_request_id)
        .then((response: any) => {
          setLoading(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          } else if (response.errorCode === 0) {
            // formik.resetForm()
            setLoading(false)
            var ev = new Event('click', { bubbles: true })
            buttonEl.current.dispatchEvent(ev)
            setTimeout(() => {
              window.location.replace('/access-cards/closed')
            }, 4000)
          } else {
            setSubmitError(t('Some error occured. Please try again !'))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          }
        })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Invalid Data Provided')
          setTimeout(() => {
            setSubmitError('')
          }, 5000)
        })
    }, 1000)
  }

  return (
    <>
      <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
        <div className='col-xl-12 mt-0'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='card-title font-18 text-black mb-6'>
                {/* <Link to={'/access-cards/open'} className=''> */}
                <div
                  className='me-5 c-pointer'
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                  <span className='text-black'>{t("Access Cards")}</span>
                  {/* / </Link> */}
                </div>
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mb-7'>
              <Details
                accessCardData={accessCardData}
                cardCount={cardCount}
                lableName={lableName}
              />
            </div>
          </div>
          {reloadData ? (
            <CustomLoader2 />
          ) : (
            <BuildingAccessCard
              cardTitle={cardTitle}
              count={cardCount}
              cardDetails={cardDetails}
              allotedAccessID={cardAllotedAccessId}
              cardSignature={cardSignature}
              cardSignature2={cardSignature2}
              setReloadData={setReloadData}
            />
          )}
          {/* <Switch>
                        <Route path='/fcss-viewDetails/building-access-card'>
                            <ViewDetailsTab />
                            <BuildingAccessCard
                                count={bacCount} 
                                cardDetails={cardDetails}
                                allotedAccessID={''}
                                cardSignature={''}
                                setReloadData={setReloadData} />
                        </Route>
                        <Route path='/fcss-viewDetails/parking-access-card'>
                            <ViewDetailsTab />
                            <ParkingAccessCard />
                        </Route>
                    </Switch> */}
          {accessCardData?.status === 0 && user_role === 'FCSS' && (
            <div className='row'>
              <div className='col-12 text-end mt-5'>
                <button
                  type='submit'
                  className='btn btn-primary fw-bold px-10 py-3'
                  onClick={() => sendHandover()}
                  disabled={!enabledHandOverButton}
                >
                  {t("Handover")}
                </button>
              </div>
            </div>
          )}
          {submitError && (
            // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
            <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
              {submitError}
            </div>
          )}
          <p
            ref={buttonEl}
            data-bs-target='#req-submitted-popup'
            data-bs-toggle='modal'
            className='invisible d-none btn btn-primary fw-bold px-10 py-3'
          />
          <CustomRequestSuccessfulPopup
            successMessage={t('Access Card Handed Over Successfully!')}
          // href={'/access-cards/closed'}
          />
        </div>
      </div>
    </>
  )
}

export { FCSSViewDetailsWrapper }
