import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getApprovalCommentList, postApprovalSubmit } from '../../../../constants/axios/apis'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Loader from '../../../../components/Loader'
import { formatDateTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'

export function ApprovalComments({ commentList, loading, error }: any) {
    const { t } = useTranslation()

    return (
        <div className='row form-add-design'>
            {loading ?
                <Loader />
                :
                <>
                    { (commentList.length > 0 || error !== '') ?
                            <div className="col-12">
                                <label className="form-label fw-bolder"> {t("Comments")} </label>
                            </div>
                            : ''
                    }
                    {error !== '' &&
                        <div className="col-12 mb-5">
                            <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2 mb-2" role="alert">
                                {error}
                            </div>
                        </div>
                    }
                    {commentList?.map((commentItem: any, index: number) => {
                        return (
                            <div className="col-12 mb-5" key={index}>
                                <div className="data-contain h-auto 75 px-5 py-4 rounded-10 mb-2">
                                    <p className="font-14 fw-bold d-block mb-0">
                                        {commentItem.comments}
                                    </p>
                                </div>
                                <label className="font-12 fw-bold mb-3 text-theme-gray ps-5">
                                    {commentItem.action_by_name ? commentItem.action_by_name : '-'} ({formatDateTimeMeridiem(commentItem.action_at)})
                                </label>
                            </div>
                        )
                    })}

                </>

            }
        </div>
    )
}
