import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function AddressBook() {
    let offerList: any = []
    offerList = useSelector<RootState>(({ General }) => General.offersRenewlaData)
    const { t } = useTranslation();
    
    const addressBookData = offerList?.address_book?.length > 0 ? offerList?.address_book : []

    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body px-8 py-6'>
                            {addressBookData.length
                                ? addressBookData?.map((item: any, index: number) => {
                                    return (
                                        <div className='row'>
                                            <div className='col-12 mb-6 col-md-4'>
                                                <label className='font-14 fw-bold mb-3 text-theme-gray'>{t("Name")}</label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <h6 className='font-14 fw-bold d-block mb-0'>{item?.name?.length > 0 ? item?.name : t("NA")}</h6>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-6 col-md-4'>
                                                <label className='font-14 fw-bold mb-3 text-theme-gray'>
                                                    {t("Contact Number")}
                                                </label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <h6 className='font-14 fw-bold d-block mb-0'>{item?.phone?.length > 0 ? item?.phone : t("NA")}</h6>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-6 col-md-4'>
                                                <label className='font-14 fw-bold mb-3 text-theme-gray'>{t("Relation")}</label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <h6 className='font-14 fw-bold d-block mb-0'>{item?.relation?.length > 0 ? item?.relation : t("NA")}</h6>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-6 col-md-4'>
                                                <label className='font-14 fw-bold mb-3 text-theme-gray'>
                                                    {t("Nationality")}
                                                </label>
                                                <div className='data-contain px-5 py-4 rounded-10'>
                                                    <h6 className='font-14 fw-bold d-block mb-0'>{item?.nationality?.length > 0 ? item?.nationality : t("NA")}</h6>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 mb-6 col-md-4 pt-9">
                                    <button className="btn btn-primary fw-bold px-10 py-3">Add</button>
                                </div> */}
                                        </div>
                                    )
                                })
                                : t("No Data Found")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
