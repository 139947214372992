import React, { useState, FC , useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom';
import {Link, useParams, useLocation} from 'react-router-dom'
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import {EarlyTerminationTabs} from "./EarlyTerminationTabs/EarlyTerminationTabs";
import {RequestDetails} from "./RequestDetails/RequestDetails";
import {Details} from "./Details/Details";
import {ApproveButtons} from "./ApproveButtons/ApproveButtons";
import {Payment} from "./Payment/Payment";
import {Documents} from "./Documents/Documents";
import {ApprovalHistory} from "./ApprovalHistory/ApprovalHistory";
import {Notes} from "./Notes/Notes";
import {History} from "./History/History";
import { useTranslation } from 'react-i18next'

const PMEarlyTerminationRequestWrapper: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
        <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="row">
                    <div className="col-12">
                        <h4 className="card-title font-18 text-black mb-6">
                            <Link to={'/pm-approvals/open-requests'} className=''>
                                <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            </Link>
                            {t("Early Termination Request")}
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-7">
                        <Details />
                    </div>
                </div>
                <Switch>
                    <Route path='/pm-early-termination-request/request-details'>
                        <EarlyTerminationTabs />
                        <RequestDetails />
                    </Route>
                    <Route path='/pm-early-termination-request/payment'>
                        <EarlyTerminationTabs />
                        <Payment />
                    </Route>
                    <Route path='/pm-early-termination-request/documents'>
                        <EarlyTerminationTabs />
                        <Documents />
                    </Route>
                    <Route path='/pm-early-termination-request/history'>
                        <EarlyTerminationTabs />
                        <History />
                    </Route>
                    <Route path='/pm-early-termination-request/approval-history'>
                        <EarlyTerminationTabs />
                        <ApprovalHistory />
                    </Route>
                    <Route path='/pm-early-termination-request/notes'>
                        <EarlyTerminationTabs />
                        <Notes />
                    </Route>
                </Switch>
                <div className="row">
                    <div className="col-12 mt-1">
                        <ApproveButtons />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export {PMEarlyTerminationRequestWrapper}

