import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { getTeamMemberDetails } from '../../../../constants/axios/apis';
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { Profile } from './Profile';
import { Details } from './Details';
import { useTranslation } from 'react-i18next';
import { MerchantApprovals } from '../../../mta-merchants/components/MerchantView/MerchantApprovals';

const MembersDetails: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory();
    const { state }: any = useLocation();
    // console.log("🚀 ~ file: MembersDetails.tsx:14 ~ state", state)
    const [teamMember, setTeamMember] = useState<any>()
    const [loading, setLoading] = useState<any>(true)

    useEffect(() => {
        setLoading(true)
        if (state?.member_id) {
            getTeamMemberDetails(state.member_id).then((response) => {
                if (response?.errorCode === 0 && response?.data) {
                    setTeamMember(response?.data)
                    setLoading(false)
                }
            })
        }
    }, [state])

    return (
        <div className="col-12">
            <div className="row access-cd-tabs align-items-center">
                <div className="col-12">
                    <h4 className="card-title font-18 text-black mb-0">
                        <span className='c-pointer' onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        {t("Details")}
                    </h4>
                </div>
                {loading ?
                    <div className="col-12 mt-7">
                        <CustomLoader2 height={'200'} />
                    </div>
                    :
                        <div className="col-12 mt-7 mb-5">
                            <div className="card">
                                <div className="card-body px-5 py-5">
                                    <div className="row">
                                        <div className="col-lg-3 col-12 mb-lg-0 mb-5">
                                            <Profile teamMember={teamMember} />
                                        </div>
                                        <div className="col-lg-9 prof-det col-12">
                                            <Details teamMember={teamMember} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <MerchantApprovals userID={state?.member_id} parentLoading={loading}/>
            </div>
        </div>
    );
};

export { MembersDetails }
