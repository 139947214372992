import React, {useState, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {Link} from 'react-router-dom'
import { FinanceLeaseAllRequest } from './FinanceLeaseTable/FinanceLeaseAllRequest';
import { FinanceLeaseDayRequest } from './FinanceLeaseTable/FinanceLeaseDayRequest';
import { FinanceLeaseMonthRequest } from './FinanceLeaseTable/FinanceLeaseMonthRequest';
import { FinanceLeaseWeekRequest } from './FinanceLeaseTable/FinanceLeaseWeekRequest';
import { FinanceServiceLeaseTabs } from './FinanceLeaseTabs/FinanceServiceLeaseTabs';

export function FinanceLeaseListWrapper() {
  const [searchItem, setSearchItem] = useState<any>('');

  return (
    <>
     <FinanceServiceLeaseTabs searchItem={searchItem} setSearchItem={setSearchItem} />
      <Switch>
      <Route path='/finance-services-lease/list/all-list'>
            <FinanceLeaseAllRequest searchItem={searchItem} setSearchItem={setSearchItem} />          
        </Route>
        <Route path='/finance-services-lease/list/day-list'>
            <FinanceLeaseDayRequest searchItem={searchItem} setSearchItem={setSearchItem} />          
        </Route>
        <Route path='/finance-services-lease/list/week-list'>
          <FinanceLeaseWeekRequest searchItem={searchItem} setSearchItem={setSearchItem} />
        </Route>
        <Route path='/finance-services-lease/list/month-list'>
          <FinanceLeaseMonthRequest searchItem={searchItem} setSearchItem={setSearchItem} />
        </Route>
        <Redirect to='/error/403' />        
      </Switch>
    </>
    );
}