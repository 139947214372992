import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import avtar from '../../../assets/images/pm/avatar-icon.jpeg'
import { PriviewModal } from '../CreateOfferPriviewpop'

export function OpportunitesSendOffer() {
    return (
        <>
            <div className="row align-items-center mb-5 community">
                <div className="col-md">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'/opportunites/offerList'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>   Create New Offer
                    </h5>
                </div>
                <div className="col-auto">
                    <a href="/opportunites/offerList" type='submit' className='btn btn-primary fw-bold px-10 py-3 text-uppercase'>
                        Offer List
                    </a>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label className="form-label fw-bolder text-uppercase">REFERENCE</label>
                            <input type="text" className="form-control fw-bolder form-control-solid active" disabled value="REF-OF-1689609348" />
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="form-group">
                                <div className="border p-6 rounded mt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="mb-0 d-inline font-12 font-weight-semibold text-theme-black"> Lead Ref:
                                                <span className="lead_ref text-warning">REF-L-1626962311
                                                </span>  </label>
                                            <input type="hidden" id="lead_id" name="lead_id" value="36" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="form-group">
                                <div className="border p-6 rounded mt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="mb-0 d-inline font-12 font-weight-semibold text-theme-black"> Unit Ref: <span className="unit_ref text-warning">REF-UN-1689589116</span></label>
                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#prop-enq-modal" className="text-decoration-none text-warning font-10 font-weight-semibold d-block text-end lh-1">
                                                <i className="fas fa-arrows-alt-h font-10 mr-1"></i> change
                                            </a>
                                            <input type="hidden" className="unit_id" name="unit_id" id="unit_id" value="140" />
                                            <input type="hidden" className="unit_ref_mail" name="unit_ref_mail" id="unit_ref_mail" value="REF-UN-1689589116  - test" />
                                        </div>
                                    </div>
                                </div>
                                <div id="unit-empty" className="text-danger float-left w-100 py-2 small">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="border rounded p-2 mb-3">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <img src={avtar} className="img-fluid border rounded-circle" width="45px" />
                                            </div>
                                            <div className="col my-auto">
                                                <a href="javascript:void(0);" className="text-decoration-none text-warning font-12 font-weight-semibold contact_name ml-2">
                                                    Vignesh                                                                                                </a>
                                                <input type="hidden" name="contact_id" value="56" />
                                                <input type="hidden" name="to_email" value="vicky3074@yahoo.com" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">Status</label>
                                <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                    <option selected> Please Select </option>
                                    <option >Open</option>
                                    <option>Close</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h6 className="font-weight-500 text-warning font-14 mb-3"> Offer Closure </h6>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase mb-0 font-13">Actual Date</label>
                                <input type="date" className="form-control font-13 form-height" name="start_date" placeholder="Start Date" value="" required />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase mb-0 font-13">Valid To</label>
                                <input type="date" className="form-control font-13 form-height" name="start_date" placeholder="Start Date" value="" required />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">BUYER TYPE</label>
                                <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                    <option selected> End User </option>
                                    <option >Investor</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">FINANCE TYPE</label>
                                <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                    <option selected> Cash </option>
                                    <option >Mortgage</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>  
            </div>
            <div className='card mb-4'>
                <div className="card-body">
                    <div className="">
                        <h6 className="theme-color font-22 mb-4"> Transaction Process </h6>
                    </div>
                    <div className='row'>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="form-group">
                                <label className="d-block w-100 text-uppercase mb-0 font-13"> Deposit </label>
                                <div className="form-control input-group p-0">
                                    <input type="text" className="form-control border-0 form-height convertThis font-13" placeholder="Area" name="built_up_area" value="Deposit" />
                                    <div className="input-group-append"><span className="font-12 input-group-text border-0"> AED </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase mb-0 font-13">Add Installments</label>
                                <input type="date" className="form-control font-13 form-height" name="start_date" placeholder="Add Installment" value="" required />
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <div className="form-group lease-column req-card-append">
                                <div className="row req-card bg-white">
                                    <div className="col-lg-8">
                                        <div className="form-group mb-0">
                                            <label className="d-block w-100 text-uppercase mb-0 font-13">Contract Terms</label>
                                            <input type="text" className="form-control font-13 form-height" name="contract_terms[]" placeholder="Add Contract" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 my-auto">
                                        <div className="addButton d-inline">
                                            <button type="button" className="btn btn-primary font-12 radius-10 font-weight-semibold d-inline-flex flex-wrap align-items-center py-6 mt-6">
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="mb-7">
                                <div className="form-group mb-0">
                                    <label className="d-block w-100 text-uppercase mb-0 font-13">MOVE IN DATE</label>
                                    <input type="date" className="form-control font-13 form-height" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="col-auto cpt-tenant-det1">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item tab_nav_li" role="presentation">
                                <button className="nav-link pb-1 position-relative active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#card-payment" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">
                                    Required Documents
                                </button>
                            </li>
                            {/* <li className="nav-item tab_nav_li" role="presentation">
                                <button className="nav-link pb-1 position-relative me-6 active" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#bank-transfer" type="button" role="tab" aria-controls="pills-profile"
                                    aria-selected="false">
                                    Visit
                                </button>
                            </li> */}
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="card-payment" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row priview-community">
                                    <div className="col-md-3 col-6 mb-5">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="flexCheckChecked" value="" checked/>
                                            <label className="form-check-label">Driving Licence</label></div>
                                    </div>
                                    <div className="col-md-3 col-6 mb-5">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="flexCheckChecked" value="" checked/>
                                            <label className="form-check-label">Emirates</label></div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tab-pane fade" id="bank-transfer" role="tabpanel" aria-labelledby="pills-profile-tab">
                                ssss
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 text-end mt-4">
                <button data-bs-toggle="modal" data-bs-target="#PriviewPopup" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                    Send Offer
                </button>
            </div>
            <PriviewModal/>
        </>
    )
}
