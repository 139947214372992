import React, { useState, useRef, FC } from 'react';
import {ApprovalHistory} from "../IssueDetails/ApprovalHistory"

export function Documents({approvalData}:any) {
    
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row form-add-design mb-8">
                                        <div className="col-12">
                                            <h4 className="card-title mb-7 font-14 text-primary">Documents</h4>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="mb-7 upload-input">
                                                <label className="form-label text-black font-14 fw-bold d-block mb-5"> All Docs</label>
                                                <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file" disabled/>
                                                <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                                    <span> {uploadedFileName ? uploadedFileName : <i className='bi bi-download text-muted ms-3 fw-bold font-17 uploadbt'></i>} </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12 fm-team-approvals">
                                    <div className="accordion approval-accordion" id="accordionExample">
                                        <RequestDetails />
                                        <ApprovalHistory />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}