import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

export function CalenderModal() {


    return (
        <>
            <div className="modal fade" aria-hidden='true' id="calenderPopup">
                <div className="modal-dialog modal-lg modal-dialog-centered radius-15">
                    <div className="modal-content radius-15 overflow-hidden border-0">
                        <div className="modal-header badge-dark">
                            <h5 className="modal-title font-16 font-weight-semibold text-white">Add Event</h5>
                            <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="row form-add-design">
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> TITLE </label>
                                            <input type="text" className="form-control form-height font-13" placeholder="Title" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13">Description</label>
                                            <textarea className="form-control fw-bolder" placeholder="Description" rows={3} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> Start Date </label>
                                            <input type="Date" className="form-control form-height font-13" placeholder="Start Date" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> Start Time </label>
                                            <input type="time" className="form-control form-height font-13" placeholder="Start Time" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> End Date </label>
                                            <input type="Date" className="form-control form-height font-13" placeholder="End Date" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <div className="form-group">
                                            <label className="d-block w-100 text-uppercase font-13"> End Time </label>
                                            <input type="time" className="form-control form-height font-13" placeholder="End Time" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                <div className="mb-7">
                                    <label className="d-block w-100 text-uppercase font-13">Category</label>
                                    <select className="form-control form-select fw-bolder" id="" name="id_community" data-live-search="true" title="Select" required>
                                        <option selected> Please Select </option>
                                        <option >Property visit</option>
                                        <option>Shedule a call</option>
                                        <option>Contract send</option>
                                    </select>
                                </div>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <div className="col-12 text-end">
                                <button data-bs-dismiss="modal" className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                    Close
                                </button>
                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
}