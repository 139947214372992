import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../components/layout/core'
import {ApprovalTabs} from './components/ApprovalTabs/ApprovalTabs'
import {UpcomingApprovals} from './components/ApprovalTabs/UpcomingApprovals/UpcomingApprovals'
import {ClosedApprovals} from './components/ApprovalTabs/ClosedApprovals/ClosedApprovals'
import {MoveInDetails} from './components/ApprovalTabs/UpcomingApprovals/MoveIn/MoveInDetails/MoveInDetails'
import {MoveOutDetails} from './components/ApprovalTabs/UpcomingApprovals/MoveOut/MoveOutDetails/MoveOutDetails'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RequestSuccessfulPopup} from '../SuccessfulPopup/RequestSuccessfulPopup'
import {getApprovals} from '../../constants/axios/apis'
import {RootState} from '../../constants'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../constants/generics/userCheck'

import {useTranslation} from 'react-i18next'

type Props = {
  approvals?: any
  totalApprovals?: any
  handleStatus?: any
  handleSubType?: any
  loading?: any
  searchItem?: any
  setSearchItem?: any
  currentPage?: any
  setCurrentPage?: any
}

const ApprovalsListPage: FC<Props> = ({
  approvals,
  totalApprovals,
  handleStatus,
  handleSubType,
  loading,
  searchItem, setSearchItem,
  currentPage, setCurrentPage
}) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
      <div className='col-xxl-12 mt-0'>
        <Switch>
          <Route path='/approvals/upcoming'>
            <ApprovalTabs searchItem={searchItem} setSearchItem={setSearchItem} />
            <UpcomingApprovals
              approvals={approvals}
              totalApprovals={totalApprovals}
              handleStatus={handleStatus}
              handleSubType={handleSubType}
              loading={loading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Route>
          <Route path='/approvals/closed'>
            <ApprovalTabs searchItem={searchItem} setSearchItem={setSearchItem} />
            <ClosedApprovals
              approvals={approvals}
              totalApprovals={totalApprovals}
              handleStatus={handleStatus}
              handleSubType={handleSubType}
              loading={loading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Route>
          <Route path='/approvals/movein/details'>
            <MoveInDetails />
            <RequestSuccessfulPopup />
          </Route>
          <Route path='/approvals/moveout/details'>
            <MoveOutDetails />
            <RequestSuccessfulPopup />
          </Route>
          <Redirect to='/error/403' />
        </Switch>
      </div>
    </div>
  </>
)

const ApprovalsListWrapper: FC = () => {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const [searchItem, setSearchItem] = useState<any>('')

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)
  const [approvals, setApprovals] = useState<any>([])
  const [totalApprovals, setTotalApprovals] = useState<any>(0)

  const [statusType, setStatusType] = useState<any>('')
  const [oldStatusType, setOldStatusType] = useState<any>('')
  const [subType, setSubType] = useState<any>(0)
  const [oldSubType, setOldSubType] = useState<any>(0)
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const pageSize = 10

  const handleStatus = (newStatusType: any) => {
    setStatusType(newStatusType)
  }

  const handleSubType = (newSubType: any) => {
    setSubType(newSubType)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [currentBuilding])

  //subtype:6=moveout, 5=movein
  useEffect(() => {
    if(statusType !== '' && subType !== 0){

      setLoading(true)
      let page = currentPage
      if (oldStatusType !== statusType || oldSubType !== subType) {
        page = 1
      }
      let newPayload =
        '?currentPage=' + page +
        '&pageSize=' + pageSize +
        '&id_sort=1&sub_type=' + subType +
        ((user_role === 'FMSM' && subType === 5 && statusType === 'approve') ? '' : '&status=' + statusType) +
        (searchItem !== '' ? '&search=' + searchItem : '') +
        (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0') +
        ((user_role === 'FCSS' && subType === 5) ||
        (user_role === 'FMSM' && (subType === 6 || statusType === 'approve'))
          ? '&is_card_data_added=1'
          : user_role === 'FMSM' && (subType === 5 || statusType === 'pending')
          ? '&is_card_data_added=open'
          : '')
  
      //  const newPayload='?sub_type=5&status =pending&id_sort=1&currentPage=1'
      getApprovals(newPayload).then((response: any) => {
        if(response.data && Array.isArray(response.data)){
          setApprovals(response.data)
          setCurrentPage(page)
          setTotalApprovals(response.dataCount)

        }else{
          setApprovals([])
          setCurrentPage(1)
          setTotalApprovals(0)
        }
        setOldStatusType(statusType)
        setOldSubType(subType)
        setLoading(false)
        // setTimeout(()=>{
        // }, 500)
      })
    }
  }, [statusType, currentPage, subType, currentBuilding, searchItem])
  
  const {t} = useTranslation()
  return (
    <>
      <PageTitle breadcrumbs={[]}> {t('Approvals')} </PageTitle>
      <ApprovalsListPage
        approvals={approvals}
        totalApprovals={totalApprovals}
        handleStatus={handleStatus}
        handleSubType={handleSubType}
        loading={loading}
        searchItem={searchItem}
        setSearchItem={setSearchItem}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  )
}

export {ApprovalsListWrapper}
