import React, { useState, useEffect } from 'react'

export function Owner() {
    return (      
        <>
         <div id="offers-details" className="offers-details">
            <div className="card">
                <div className="card-body">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="row align-items-center">
                                <div className="col-md-12 text-center py-4">
                                    <i className="fas fa-file-alt text-gray-400 font-44"></i>
                                    <h3 className="text-gray-400 mt-4 mb-0"> No Owner found </h3>
                                </div>
                            </div>
                        </div>

                    </div>        
                </div>
            </div>
        </div>
    </>
    )
}

