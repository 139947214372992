import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeRequest } from "./hooksHelper";

interface IState {
    APIState: {
        loading: boolean,
        response: any,
        error: string,
    }
};

const API_URL = process.env.REACT_APP_API_URL || 'api';

const useRequestPost = (URL: string, formData = false, successCallback: Function = () => {}, failCallback: Function = () => {}) => {
    const [state, setState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    const appState:any = useSelector(state => state);
    const userToken = appState?.auth?.accessToken;

    const url = `${API_URL}${URL}`

    if(userToken === "undefined"){
        // token undefined
    }

    const [requestParams, setRequestParams] = useState<any>('');


    useEffect(()=>{
        if(requestParams !== ''){
            makeRequest('POST', url, setState, requestParams, userToken, formData, successCallback, failCallback);
        }
    },[requestParams]);

    return {
        requestState: state,
        setRequestParams
    }
}



export default useRequestPost;