import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getMoveoutDetail, getMoveoutDocuments, getMoveoutPayments } from '../../../../constants/axios/apis'
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { BasicDetails } from './components/BasicDetails';
import { MoveOutRequestTabs } from './components/MoveOutRequestTabs';
import { LeaseDetails } from './components/LeaseDetails';
import { Payments } from './components/Payments';
import { Documents } from './components/Documents';
import { Actions } from './components/Actions';

export function MoveoutRequestViewWrapper({setPopupSuccessMsg}:any) {
  const {t} = useTranslation()
  // const { id } = useParams<any>()

  let params:any = useLocation()

  let serviceID = ''
  if (params?.state?.requestDetail?.approvalId)
    serviceID = params.state.requestDetail.approvalId
  
  const [loading, setLoading] = useState<any>({
    moveout: true,
    payment: true,
    document: true,
  })

  const [activeButton, setActiveButton] = useState<any>('lease')
  const [moveoutData, setMoveoutData] = useState<any>()
  const [paymentData, setPaymentData] = useState<any>()
  const [documentData, setDocumentData] = useState<any>()

    useEffect(() => {
        getMoveoutDetail(serviceID).then((response) => {
            // console.log("Moveout & Lease:", response);
            if (response?.errorCode === 0 && response?.data) {
                setMoveoutData(response.data);
            }
            setLoading((oState:any)=>({...oState, moveout: false}))
        })
        getMoveoutPayments(serviceID).then((response) => {
            // console.log("Payment", response);
            if (response?.errorCode === 0 && response?.data) {
                setPaymentData(response.data);
            }
            setLoading((oState:any)=>({...oState, payment: false}))
        })
        getMoveoutDocuments(serviceID).then((response) => {
            // console.log("Documents", response);
            if (response?.errorCode === 0 && response?.data) {
                setDocumentData(response.data);
            }
            setLoading((oState:any)=>({...oState, document: false}))
        })
    }, [serviceID])

    let paymentCount = 0
    if (paymentData?.Rent?.payments && Array.isArray(paymentData?.Rent?.payments))
      paymentCount += paymentData?.Rent?.payments?.length
    if (paymentData?.Other_Charges?.payments && Array.isArray(paymentData?.Other_Charges?.payments))
      paymentCount += paymentData?.Other_Charges?.payments?.length
    if (paymentData?.Service_Charges?.payments && Array.isArray(paymentData?.Service_Charges?.payments))
      paymentCount += paymentData?.Service_Charges?.payments?.length
    
    const allLoading = loading.moveout || loading.payment || loading.document

    return (
      <>
        <div className='com-card-section myunit-page'>
          {allLoading ? (
            <CustomLoader2 />
          ) : (
            <>
              <BasicDetails moveoutData={moveoutData} paymentCount={paymentCount}/>
              <MoveOutRequestTabs activeButton={activeButton} setActiveButton={setActiveButton}/>
              { activeButton === 'lease' && <LeaseDetails leaseData={moveoutData} paymentCount={paymentCount}/> }
              { activeButton === 'payment' && <Payments paymentData={paymentData} /> }
              { activeButton === 'doc' && <Documents documentData={documentData} /> }
            </>
          )}
        </div>
        {!allLoading && <Actions moveoutData={moveoutData} setPopupSuccessMsg={setPopupSuccessMsg} /> }
      </>
    )
}