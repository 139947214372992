import React from 'react'
import RenewContract from '../../../../assets/images/Renew_contract_blue.png'
import AccessCard from '../../../../assets/images/Access_card_blue.png'
import ParkingIssue from '../../../../assets/images/Parking_issue_blue.png'
import Disturbance from '../../../../assets/images/Disturbance_blue.png'
import RentDeduction from '../../../../assets/images/rent-deduction.png'
import ClearanceCharges from '../../../../assets/images/clearance-charges.png'
import EarlyTerminations from '../../../../assets/images/early-termination.png'
import ExtendContract from '../../../../assets/images/extend-contract.png'
import UndertakingLetter from '../../../../assets/images/undertaking-letter.png'
import PermitWork from '../../../../assets/images/permit-work.png'
import NOC from '../../../../assets/images/noc.png'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export function MyServicesList({ services }: any) {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  // let updatedServicesList = services?.filter(
  //   (el: any) => el.id != 21 && el.id != 22 && el.id != 23 && el.id != 24 && el.id != 5 && el.id != 6 && el.id != 7 && el.id != 8 && el.id != 9 && el.id != 42 && el.id != 43
  // )

  let content = services?.map((service: any) =>
    service.id == 26 ? (
      <div key={service.id}>
        <Link
          to={'#schedule-meeting'}
          className='text-decoration-none'
          data-bs-toggle='modal'
        >
          <div className='card'>
            <div className='card-body py-7 px-5'>
              <div className='row g-0'>
                <div className='col-4'>
                  <img src={service.icon} className='img-fluid' />
                </div>
                <div className='col-8'>
                  <h6 className='card-title mb-0'>
                    {' '}
                    Meeting <br /> Request{' '}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    ) : (
      <div key={service.id}>
        <Link to={{ pathname: '/my-services/' + service.id }}>
          <div className='card'>
            <div className='card-body py-7 px-3'>
              <div className='row g-0 align-items-center'>
                <div className='col-4'>
                  <img src={service.icon} className='img-fluid' />
                </div>
                <div className='col-8'>
                  <h6 className='card-title mb-0'>{service.categoryName}</h6>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  )

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <h4 className='card-title'> {window.location.pathname == '/dashboard' ? 'Quick Links' : 'My Services'} </h4>
                </div>
                {/*<div className="col-md-6 col-6 text-right">*/}
                {/*<a href="#" className="text-decoration-none view-all">*/}
                {/*<span> View All </span>*/}
                {/*<img src={RightArrow} className="img-fluid" />*/}
                {/*</a>*/}
                {/*</div>*/}
              </div>
              <div className='quick-link-sec'>
                <div>
                  <div className='row'>
                    <div className='col-12'>
                      {userEmail === 'commercialtenant@rc.test' ? (
                        <>
                          <Slider {...settings}>
                            <div>
                              <Link to={'/ct-move-in'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={NOC} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>NOC</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/ct-move-in'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={PermitWork} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Permit to Work</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/contract-renewal'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={RenewContract} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Renew Contract</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/1'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={AccessCard} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Access Card</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/parking-issue'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ParkingIssue} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Parking Issue</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/openissues'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={Disturbance} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Disturbance</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/early-termination'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={EarlyTerminations} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Early Termination</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/extend-contract'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ExtendContract} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Extend Contract</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/undertaking-letter'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={UndertakingLetter} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Undertaking Letter</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/renewal-notice'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ClearanceCharges} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Clearance Charges</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/rent-reduction'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={RentDeduction} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Rent Reduction</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/amenity-booking'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={RentDeduction} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Amenity Booking</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </Slider>
                        </>
                      ) : (
                        <>
                          <Slider {...settings}>
                            {services ? content : ''}
                            {/* <div>
                              <Link to={'/my-services/extend-contract'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={RenewContract} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Renew Contract</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/access-card'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={AccessCard} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Access Card</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/parking-issue'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ParkingIssue} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Parking Issue</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/openissues'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={Disturbance} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Disturbance</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/early-termination'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={EarlyTerminations} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Early Termination</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/extend-contract'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ExtendContract} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Extend Contract</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/undertaking-letter'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={UndertakingLetter} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Undertaking Letter</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/renewal-notice'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ClearanceCharges} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Clearance Charges</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/rent-deduction'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={RentDeduction} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>Rent Reduction</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/general-ptw'}>
                                <div className='card'>
                                  <div className='card-body py-7 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={GeneralPTW} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>General PTW</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div> */}
                          </Slider>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
