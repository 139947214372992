import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { formatDate } from '../../../../constants/generics/dateTimeFormater';
import { useTranslation } from 'react-i18next'

export function RequestDetails() {
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-5">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 mb-6">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                {t("Submitted by")} :
                                            </label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">
                                                    {contractApprovalData?.cutomerName ? contractApprovalData.cutomerName : '-'}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-6">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                {t("Date Submitted by")} :
                                            </label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">
                                                    {contractApprovalData?.created_at ? formatDate(contractApprovalData?.created_at) : '-'}

                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="col-12 mb-6">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Comments")}
                                        </label>
                                        <div className="data-contain h-75 px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                {contractApprovalData?.comment ? contractApprovalData?.comment : '-'}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}