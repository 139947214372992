import React, {useState, useEffect} from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import ShVisit01 from '../../../../assets/images/sch-visit-banner.png'
import ShVisit02 from '../../../../assets/images/Image_4.png'
import ShVisit03 from '../../../../assets/images/Image_9.png'
import ShVisit04 from '../../../../assets/images/Image_10.png'
import ConfirmedIcon from '../../../../assets/images/visit-confirmed.png'
import LocationIcon from '../../../../assets/images/loc-mark-icon.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'
import {allScheduleList, getFacilityVisitors, scheduleVisitList} from '../../../../constants/axios/apis'
import moment from 'moment'
import VisitorAvatar from '../../../../assets/images/SquareAvatar.png'
import {RootState} from '../../../../constants'
import {useSelector} from 'react-redux'
import {CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'

import { useTranslation } from 'react-i18next';

export function ScheduledVisits() {
  const { t } = useTranslation();
  const [visits, setVisits] = useState<any>()
  const [loading, setLoading] = useState(true)
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )

  // useEffect(() => {
  //   const newPayload = "completed"
  //   getFacilityVisitors(newPayload).then(({data}) => {
  //     console.log(data,"dash");

  //     data
  //       .sort((a: any, b: any) => {
  //         return new Date(a.visit_date).getTime() - new Date(b.visit_date).getTime()
  //       })
  //       .sort((a: any, b: any) => {
  //         return a.Time - b.Time
  //       })
  //       .reverse()
  //     setVisits(data.slice(-4))
  //   })
  // }, [])

  useEffect(() => {
    // getAnnouncementsLists(setAnnouncementsLists,userToken);
    setLoading(true)
    setVisits([])    
    const newPayload =
      '?status=upcoming' + (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
      scheduleVisitList(newPayload)
      .then((response: any) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setVisits(response.data)
          setLoading(false)
        } else {
          setVisits([])
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [currentBuilding])

  const upcomingVisits = visits?.map((visit: any) => (
    <div key={visit?.id}>
      {/* <Link to={{pathname:'/scheduled-visit/upcoming', state: { item: visit } }}> */}
      <div className='card mt-4 me-5'>
        <div className='card-body p-sm-4'>
          <div className='row'>
            <div className='col-md-4 col-sm-12 col-xl-3 my-auto col-12 pe-1 pe-xl-3 pb-2 pb-md-0'>
              <div className='img-bg'>
                <img
                  src={visit?.propertyCoverImage ? visit?.propertyCoverImage : VisitorAvatar}
                  className='img-fluid position-relative'
                  alt='propertyImage'
                />
              </div>
            </div>
            <div className='col-md-8 col-sm-12 col-xl-9 col-12'>
              <div className='d-flex flex-nowrap justify-content-between'>
                <h4 className='card-title mb-2 me-1'>
                  {' '}
                  {visit?.building ? visit?.building : '--'}{' '}
                </h4>
                <span className='count fcss-propert-code'>
                  {' '}
                  {visit?.propertyCode ? visit?.propertyCode : '--'}{' '}
                </span>
              </div>
              <h6 className='sub-title'>{visit?.visitor ? visit?.visitor : '--'}</h6>

              <div className='d-flex flex-nowrap justify-content-between align-items-center'>
                <span className='sch-status text-primary'>{visit?.eventStatus === 1 ? (t('Upcoming')) : (t('Re-Scheduled'))}</span>
                <Link
                  to={{
                    pathname: '/scheduled-visit/view',
                    state: {scheduledData: visit},
                  }}
                  className='count text-decoration-none text-black'
                >
                  {' '}
                  {t("View Detail")}{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  ))

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }
  // const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-5 pt-4 p-xl-7 pt-xl-5'>
              <div className='row'>
                <div className='col-md-6 col-12'>
                  <h4 className='card-title'> {t("Scheduled Visits")}</h4>
                </div>
                {visits?.length > 2 ? (
                  <div
                    className='col-md-6 col-12 d-flex justify-content-end align-items-center pe-md-20'
                  >
                    <Link
                      to={'/scheduled-visit/upcoming'}
                      className='text-decoration-none view-all'
                    >
                      <span> {t("View All")} </span>
                      <img src={RightArrow} className='img-fluid' />
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className='visits-place-sec'>
                <div className='row'>
                  <div className='col-md-12 col-12 col-lg-12'>
                    <div className='row'>
                      {loading && <CustomLoader2 height={'166'} />}
                      {!loading && (
                        <div className='col-12 p-0'>
                          <Slider {...settings}>{visits ? upcomingVisits : ''}</Slider>
                          {!visits?.length && (
                            <NoDataFound1 height={'110'} />
                            // <div className='text-center'>No Upcoming Scheduled Visit</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
