import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../constants/generics/customActios';
import { ImageViewerWithPopup } from '../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';

export function RequestDetails() {
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    contractApprovalData = contractApprovalData[0]
    // console.log("🚀 ~ file: RequestDetails.tsx:11 ~ RequestDetails ~ contractApprovalData", contractApprovalData)
    const { t } = useTranslation();
    // const test = Object.entries(contractApprovalData?.requestDetails).forEach(([key, value]) => {
    //     // console.log(`****** ${key} ${value}`);
    //     const data = {
    //         key: key,
    //         value: value

    //     }
    //     return key;
    // });
    // // console.log("🚀 ~ file: RequestDetails.tsx:17 ~ test ~ test", test)
    // {
    //     Object.entries(contractApprovalData?.requestDetails)
    //         .map(([key, values]) => (
    //             console.log(`****** ${key} ${values}`)
    //         ))
    // }

    // console.log(contractApprovalData, 'contractApprovalData');
    

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-5">
                        <div className="card-body px-8 py-6">
                            {/* <div className="col-12 col-md-4">
                                {Object.entries(contractApprovalData?.requestDetails)
                                    .map(([key, values]) => (
                                        <div className="row">
                                            <div className="col-12 mb-6">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                    {key}:
                                                </label>
                                                <div className="data-contain px-5 py-4 rounded-10">
                                                    <h6 className="font-14 fw-bold d-block mb-0">
                                                        {values}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div> */}
                            {contractApprovalData?.requestDetails && <div className="row">
                                <div className="col-12">
                                    <h4 className="font-14 text-theme mb-6">
                                        {t("Request Details")}
                                    </h4>
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                {Object.entries(contractApprovalData?.requestDetails)
                                                    .map(([key, values]) => (
                                                        key.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); }) != 'Id' && <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {key.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); })}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">
                                                                {key?.includes('Date') && values != null && values != undefined ? moment(values as any).format('DD-MM-YYYY') 
                                                                    : key?.includes('Amount') && values != null && values != undefined ? <>{t('AED')} {currencyFormater(values)}</> : values}
                                                                </h3>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {[27, 28, 29, 30 ].includes(contractApprovalData?.subReference) && contractApprovalData?.details_json && <div className="row">
                                <div className="col-12">
                                    <h4 className="font-14 text-theme mb-6">
                                        {t("Request Details")}
                                        {contractApprovalData?.subReference === 29 ? ' - ' + 
                                            (contractApprovalData?.category_type_id === 52 ? t("Helipad and Rooftop Access (PTW)") :
                                                contractApprovalData?.category_type_id === 53 ? t("Fire Protection Impairment (PTW)") :
                                                    t("General PTW"))
                                            : ''}
                                    </h4>
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                {Object.entries(contractApprovalData?.details_json)
                                                    .map(([key, values]) => {
                                                        let labelVal = key.replace(/_/g, ' ');
                                                        labelVal = labelVal.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); })

                                                        let dataVal = Array.isArray(values) ? '-TODO-' : (key?.includes('Date') && values != null && values != undefined ? moment(values as any).format('DD-MM-YYYY')
                                                        : key?.includes('Amount') && values != null && values != undefined ? <>{t('AED')} {currencyFormater(values)}</> : values)
                                                        return (
                                                            labelVal != 'Id' && <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-3">
                                                                <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                    {labelVal}
                                                                </label>
                                                                <h3 className="mb-0 payment-txt">
                                                                    {dataVal}
                                                                </h3>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                            <div className="row">
                                                {contractApprovalData?.attachments_json && Object.entries(contractApprovalData?.attachments_json)
                                                    .map(([key, values], index) => {
                                                        let labelVal = key.replace(/_/g, ' ');
                                                        labelVal = labelVal.replace(/([A-Z])/g, ' $1').replace(/^./, function (key) { return key.toUpperCase(); })
                                                        
                                                        // let dataVal = Array.isArray(values) ? '-TODO-' : (key?.includes('Date') && values != null && values != undefined ? moment(values as any).format('DD-MM-YYYY')
                                                        // : key?.includes('Amount') && values != null && values != undefined ? <>{t('AED')} {currencyFormater(values)}</> : values)
                                                        return (
                                                            labelVal != 'Id' && <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-3">
                                                                <label className="font-14 fw-bold mb-3 text-theme-gray">{labelVal}</label>
                                                                {(values && (typeof values === 'string' )&& values.includes('.pdf')) ?
                                                                    <a href={values} className='d-block' target='_blank'>View PDF</a>
                                                                    :
                                                                        <ImageViewerWithPopup imageFile={values} imgID={`${index}`}/>
                                                                }
                                                                {/* <h3 className="mb-0 payment-txt">
                                                                    {dataVal}
                                                                </h3> */}
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <div className="row">

                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 mb-6">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                {t("Submitted by")} :
                                            </label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">
                                                    {contractApprovalData?.cutomerName}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-6">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                {t("Date Submitted by")} :
                                            </label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">
                                                    {moment(contractApprovalData?.created_at).format('DD-MM-YYYY')}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    {contractApprovalData?.comment && <div className="col-12 mb-6">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Comments")}
                                        </label>
                                        <div className="data-contain h-75 px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                {contractApprovalData?.comment}
                                            </h6>
                                        </div>
                                    </div>}
                                    {contractApprovalData?.total_cost_impact && <div className="col-12 mb-6">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Total Cost Impact")}
                                        </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                {contractApprovalData?.total_cost_impact}
                                            </h6>
                                        </div>
                                    </div>}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}