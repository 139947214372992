import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { getUserRole } from '../../../../../constants/generics/userCheck';

export function MarketPlaceTabSecondary() {
    const { t } = useTranslation();
    // const location = useLocation();
    // let s = location.pathname
    // let link = s.substring(s.lastIndexOf("/list/") + 6, s.lastIndexOf("/"))
    const state: any = useSelector(state => state)
    const user_role = getUserRole(state?.auth?.user?.data)

    const location = useLocation();
    let s = location.pathname
    let secondaryLink = s.substring(s.lastIndexOf("/") + 1, s.length)
    let primaryLink = s.substring(s.lastIndexOf("/list/") + 6, s.lastIndexOf("/"))

    const tabsList = [
        {
            title: t('All'),
            value: 'all'
        },
        {
            title: t('Active'),
            value: 'active'
        },
        {
            title: t('Inactive'),
            value: 'inactive'
        },
        {
            title: t('Expired'),
            value: 'expired'
        },
    ]
    const tabsListAdmin = [
        {
            title: t('All'),
            value: 'all'
        },
        // {
        //     title: t('Dining'),
        //     value: 'dining'
        // },
        // {
        //     title: t('Lifestyle'),
        //     value: 'lifestyle'
        // },
        // {
        //     title: t('Entertainment'),
        //     value: 'entertainment'
        // },
        {
            title: t('Active'),
            value: 'active'
        },
        {
            title: t('Inactive'),
            value: 'inactive'
        },
        {
            title: t('Expired'),
            value: 'expired'
        },
    ]


    const subTabList = user_role && ['MA', 'MT'].includes(user_role) ? tabsListAdmin : tabsList

    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className='d-flex subtabs'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        {subTabList.map((item: any, index: number) => {
                            return (
                                <li className='nav-item' key={index}>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-14 ${secondaryLink === item.value && 'actives'}`}
                                        to={`/market-place/list/${primaryLink}/${item.value}`}>
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                        {/* <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/market-place/list/${link}/all` && 'actives')}
                                to={`/market-place/list/${link}/all`}>
                                All
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/market-place/list/${link}/dining` && 'actives')}
                                to={`/market-place/list/${link}/dining`}>
                                Dining
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/market-place/list/${link}/lifestyle` && 'actives')}
                                to={`/market-place/list/${link}/lifestyle`}>
                                Lifestyle
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/market-place/list/${link}/entertainment` && 'actives')}
                                to={`/market-place/list/${link}/entertainment`}>
                                Entertainment
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/market-place/list/${link}/active` && 'actives')}
                                to={`/market-place/list/${link}/active`}>
                                Active
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/market-place/list/${link}/inactive` && 'actives')}
                                to={`/market-place/list/${link}/inactive`}>
                                Inactive
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/market-place/list/${link}/expired` && 'actives')}
                                to={`/market-place/list/${link}/expired`}>
                                Expired
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
}