
import React, { FC, useState } from 'react';
import { Content } from './Content';
import { Link } from 'react-router-dom';
import { CampaignDetails } from './Campaigndetails';
import { Execute } from './Execute';

export function AddEmail() {
    const [tabPosition, setTabPosition] = useState("1");
    const chooseMessage = (message:any) => {
        setTabPosition(message);
    };
    const buildingTab = (i: any) => {
        setTabPosition(i)
    }
    return (
        <>   
         <div className="row align-items-center mb-5 community">
                <div className="col-md-5">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>  Create New Email Campaign
                    </h5>
                </div>
            </div>         
            <div className="row">
                <div className="col-md-12">
                    <div className="col-12">
                        <div className='d-flex step_tab overflow-auto'>
                            <ul className='nav justify-content-between mb-4 w-100 flex-nowrap border bg-white rounded-3'>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(1)}>
                                        Campaign Details 
                                    </Link>
                                </li>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(2)}>
                                        Content
                                    </Link>
                                </li>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(3)}>
                                        Execute
                                    </Link>
                                </li>
                               
                            </ul>
                        </div>

                        {   tabPosition == '1' ? <CampaignDetails chooseMessage={chooseMessage}/> : 
                            tabPosition == '2' ? <Content chooseMessage={chooseMessage}/> :
                            tabPosition == '3' ? <Execute chooseMessage={chooseMessage}/> : ''
                        }

                    </div>
                </div>
            </div>         
                        
        </>
    )
}