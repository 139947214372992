import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { getMerchantList, getTeamMemberDetails } from '../../../../constants/axios/apis';
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import Avatar from '../../../../assets/images/DefaultUser.png';
import { useTranslation } from 'react-i18next';
import { MerchantApprovals } from './MerchantApprovals';

export function MerchantView() {
    const { t } = useTranslation()
    const history = useHistory();
    const [teamMember, setTeamMember] = useState<any>()
    const [loading, setLoading] = useState<any>(true)

    let params: any = {};
    params = useLocation();
    const { merchantData } = params.state;

    useEffect(() => {
        const newPayload = `?id=${merchantData?.id}`
        getMerchantList(newPayload).then((response: any) => {
            if (response.errorCode === 0 && response?.data) {
                setTeamMember(response.data)
            }
            setLoading(false)
        }).catch((e) => setLoading(false))
    }, [merchantData])

    return (
        <div className="col-12">
            <div className="row access-cd-tabs align-items-center">
                <div className="col-12">
                    <h4 className="card-title font-18 text-black mb-0">
                        <span className='c-pointer' onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        {t("Details")}
                    </h4>
                </div>
                {loading ?
                    <div className="col-12 mt-7">
                        <CustomLoader2 height={'200'} />
                    </div>
                    :
                    <>
                        <div className="col-12 mt-7 mb-5">
                            <div className="card">
                                <div className="card-body px-5 py-5">
                                    <div className="row">
                                        <div className="col-lg-3 col-12 mb-lg-0 mb-5">
                                            <div className='profile-img-card rounded-5 w-100 py-9 px-13 h-100'>
                                                <div className='img border w-100 text-center'>
                                                    <img src={teamMember?.profile_img ? teamMember?.profile_img : Avatar} className='img-fluid rounded-circle shadow' />
                                                </div>
                                                <div className='mx-3'>
                                                    <h5 className='text-black font-18 fw-bolder mt-3 text-center'>{teamMember?.userName}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 prof-det col-12">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                                                    <div className="border rounded-5 p-4 h-100">
                                                        <label className="font-14 fw-bold d-block mb-2">
                                                            {t("Merchant Name")}
                                                        </label>
                                                        <span className="text-black font-15 fw-bold">
                                                            {teamMember?.userName}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                                                    <div className="border rounded-5 p-4 h-100">
                                                        <label className="font-14 fw-bold d-block mb-2">
                                                            {t("Email")}
                                                        </label>
                                                        <span className="text-black font-15 fw-bold">
                                                            {teamMember?.email}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                                                    <div className="border rounded-5 p-4 h-100">
                                                        <label className="font-14 fw-bold d-block mb-2">
                                                            {t("Mobile")}
                                                        </label>
                                                        <span className="text-black font-15 fw-bold">
                                                            {teamMember?.phone}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-4 col-12 mt-3 mt-sm-0">
                                                    <div className="market-place-card">
                                                        <div className="card">
                                                            <div className="card-body py-5 px-5">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-9">
                                                                        <h6 className="card-title mb-0">{teamMember?.approvalCount?.pending}</h6>
                                                                        <p className="mb-0">{t("Pending")}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="cardimg">
                                                                            <i className="bi bi-exclamation-circle font-18"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-12 mt-3 mt-sm-0">
                                                    <div className="market-place-card">
                                                        <div className="card">
                                                            <div className="card-body py-5 px-5">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-9">
                                                                        <h6 className="card-title mb-0">{teamMember?.approvalCount?.approved}</h6>
                                                                        <p className="mb-0">{t("Approved")}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="cardimg">
                                                                            <i className="bi bi-check-circle font-18"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 col-12 mt-3 mt-md-0">
                                                    <div className="market-place-card">
                                                        <div className="card">
                                                            <div className="card-body py-5 px-5">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-9">
                                                                        <h6 className="card-title mb-0">{teamMember?.approvalCount?.rejected}</h6>
                                                                        <p className="mb-0">{t("Rejected")}</p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="cardimg">
                                                                            <i className="bi bi-x-circle font-18"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <MerchantApprovals userID={merchantData?.id} parentLoading={loading}/>
            </div>
        </div>
    );
};