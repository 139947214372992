import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { EmailList } from './marketing-email/EmailListing'
import { AddEmail } from './marketing-email/create-email/addEmail'
import { AddEmailTemplate } from './marketing-email/create-email-template/addEmailTemplate'


const PMEmail: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/email/list'>
                            <EmailList/>
                        </Route>
                        <Route path='/email/add'>
                            <AddEmail/>
                        </Route>
                        <Route path='/email/template'>
                            <AddEmailTemplate/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMEmailWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMEmail />
        </>
    )
};

export { PMEmailWrapper }

