import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

export function RequestDetails() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-5">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 mb-6">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                {t("Submitted by")} :
                                            </label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">
                                                    John Doe
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-6">
                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                {t("Date Submitted by")} :
                                            </label>
                                            <div className="data-contain px-5 py-4 rounded-10">
                                                <h6 className="font-14 fw-bold d-block mb-0">
                                                    20-Jan-2022
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="col-12 mb-6">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray">
                                            {t("Comments")}
                                        </label>
                                        <div className="data-contain h-75 px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                                is simptextly  dummy text of the printing and typesetting industry. Lorem Ipsum is simply
                                                dummy  of the printing and typesetting industry. Lorem Ipsum is simply  dummy text
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}