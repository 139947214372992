import { Link } from "react-router-dom";
import { SetStateAction, useState } from 'react';
import Nouislider from "nouislider-react";
import { AudianceModal } from "../../marketing-social-media/Modal-audiance";
import template from "../../../../assets/images/default-template.png"
export function Content({ chooseMessage }: any) {
    const [documentsCount, setDocumentsCount] = useState<any>(0);
    const [instagramCount, setInstagramCount] = useState<any>(0);
    const [twitterCount, setTwitterCount] = useState<any>(0);
    const [linkedCount, setLinkedCount] = useState<any>(0);
    const [showRange, setShowRange] = useState<any>(false);
    const [minValues, setMinValues] = useState<any>();
    const [maxValues, setMaxValues] = useState<any>();
    const [minBudgetValue, setMinBudgetValue] = useState<any>(0);
    const [maxBudgetValue, setMaxBudgetValue] = useState<any>(5000);
    function onSlides(value: any) {
        setMinBudgetValue(Math.round(value[0]));
        setMaxBudgetValue(Math.round(value[1]));
    }
    function onChanges(value: any) {
        setMinBudgetValue(Math.round(value[0]));
    }

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-4 col-12 mb-7">
                                    <div className="form-group">
                                        <label className="control-label text-uppercase mb-0 font-13"> From Name </label>
                                        <input type="text" className="add-box-in form-control font-13 form-height w-100" name="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12 mb-7">
                                    <div className="form-group">
                                        <label className="control-label text-uppercase mb-0 font-13"> From Address </label>
                                        <input type="text" className="add-box-in form-control font-13 form-height w-100" name="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-12 mb-7">
                                    <div className="form-group">
                                        <label className="control-label text-uppercase mb-0 font-13"> Subject </label>
                                        <input type="text" className="add-box-in form-control font-13 form-height w-100" name="" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 mb-7">
                                    <div className="form-group">
                                        <label className="control-label text-uppercase mb-0 font-13"> Preview Text/Description </label>
                                        <textarea rows={5} className="form-control font-13"></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12 mb-7">
                                    <div className="form-group">
                                        <label className="control-label text-uppercase mb-0 font-13"> Template Name </label>
                                        <input type="text" className="add-box-in form-control font-13 form-height w-100" name="" />
                                    </div>
                                </div>
                          
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase mb-0">Subscription Type</label>
                                        <select className="form-control form-select fw-bolder form-control-solid"
                                        >
                                            <option >Please Select </option>
                                            <option>Marketing Information</option>
                                            <option>Customer Service Information</option>
                                            <option>One to One</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-uppercase mb-0">Campaign Name</label>
                                        <select className="form-control form-select fw-bolder form-control-solid"
                                        >
                                            <option >Please Select </option>
                                            <option>Marketing Information</option>
                                            <option>Customer Service Information</option>
                                            <option>One to One</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="text-end my-6">
                <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('2')}>Previous</button>
                <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('3')}>Next</button>
            </div>
        </div>




    )
}