import {DataGrid} from '@mui/x-data-grid'
// import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
// import Loader from '../../../../../../components/Loader';
// import { CustomLoader2, NoDataFound1 } from '../../../../../../constants/generics/customActios';
import {formatDate, formatTimeMeridiem} from '../../../../../../constants/generics/dateTimeFormater'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {RootState} from '../../../../../../constants'
import {CompactPagination} from '../../../../../../components/partials/content/CompactPagination/CompactPagination'
import {CustomLoader2, NoDataFound1} from '../../../../../../constants/generics/customActios'
import {getApprovals} from '../../../../../../constants/axios/apis'

export function Upcoming() {
  const {t} = useTranslation()
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const state: any = useSelector((state) => state)
  const pageSize = 10
  const [upcomingList, setUpcomingList] = useState<any>()
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalUpcomingList, setTotalUpcomingList] = useState<any>(0)
  const [page, setPage] = React.useState(1)

  // const columns = [
  //     {
  //         field: 'no',
  //         headerName: t('No.'),
  //         // sortable: false,
  //         disableColumnMenu: true,
  //         width: 80
  //     },
  //     {
  //         field: 'workorderno',
  //         headerName: t('Work Order No'),
  //         minWidth:180,
  //         flex:1
  //     },
  //     {
  //         field: 'issueType',
  //         headerName: t('Issue Type'),
  //         minWidth:200,
  //         flex:1
  //     },
  //     {
  //         field: 'unit',
  //         headerName: t('Unit'),
  //         minWidth:180,
  //         flex:1
  //     },
  //     {
  //         field: 'appointmentDate',
  //         headerName: t('Appointment Date'),
  //         minWidth:180,
  //         flex:1
  //     },
  //     {
  //         field: 'time',
  //         headerName: t('Time'),
  //         minWidth:150,
  //         flex:1
  //     },
  //     {
  //         field: 'itemState',
  //         headerName: t('Action'),
  //         sortable: false,
  //         disableColumnMenu: true,
  //         width: 150,
  //         renderCell: (val:any) => {
  //             return <Link to={{
  //                         'pathname': '/fmteam-viewDetails/onhold',
  //                         'state': {'approval': val.value},
  //                     }} className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'>
  //                         <span className='view-option text-theme fw-bolder'>{t("View")}</span>
  //                     </Link>
  //         },
  //     },
  // ];

  // const dataRow = approvalList?.map((item: any, index: number) => {
  //     return {
  //         no: index+1,
  //         workorderno: item.workOrderNumber,
  //         issueType: item.workOrderData?.[0]?.issue_type,
  //         unit: item.propertyCode,
  //         appointmentDate: formatDate(item.workOrderData?.[0]?.date),
  //         time: formatTimeMeridiem(item.workOrderData?.[0]?.time),
  //         itemState :item
  //     }
  // });

  useEffect(() => {
    setLoading(true)
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&id_sort=1' +
      '&status=pending'+
      '&sub_type=18'+
      //   '&search=' + searchItem +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getApprovals(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setUpcomingList(response.data)
        setTotalUpcomingList(response.dataCount)
      } else {
        setUpcomingList([])
        setTotalUpcomingList(0)
      }
      setLoading(false)
    }).catch((e)=>setLoading(false))
  }, [currentPage, currentBuilding])

  if (!loading && (upcomingList === undefined || upcomingList?.length === 0)) {
    return <NoDataFound1 />
  }

  const upcomingData = upcomingList?.map((data: any, index: any) => (
    <tr key={`${data.id}${index}`}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td>{data.workOrderNumber ? data.workOrderNumber : '-'}</td>
      <td>{data.workOrderData?.[0]?.issue_type ? data.workOrderData?.[0]?.issue_type : '-'}</td>
      <td>{data.propertyCode ? data.propertyCode : '-'}</td>
      <td>{data.workOrderData?.[0]?.date ? formatDate(data.workOrderData?.[0]?.date) :"-"}</td>
      <td>{data.workOrderData?.[0]?.time ? formatTimeMeridiem(data.workOrderData?.[0]?.time) :"-"}</td>
      <td>
        <Link
          to={{
            pathname: '/fmteam-viewDetails/onhold',
            state: {approval: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='mb-3'>
            <div className='py-4'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                        <thead>
                          <tr>
                            <th className=''> {t('No.')} </th>
                            <th className=''> {t('Work Order No')}</th>
                            <th className=''> {t('Issue Type')} </th>
                            <th className=''> {t('Unit')} </th>
                            <th className=''> {t('Appointment Date')}</th>
                            <th className=''> {t('Time')}</th>
                            <th className=''> {t('Action')} </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={7}>
                                <CustomLoader2 />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>{upcomingList ? upcomingData : ''}</tbody>
                        )}
                      </table>
                      <CompactPagination
                        pageSize={pageSize}
                        totalCount={parseInt(totalUpcomingList)}
                        defaultPage={currentPage}
                        onPageChange={setCurrentPage}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
