import React, { useState, useRef, FC, useEffect } from 'react';
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import {Link, useHistory} from 'react-router-dom'
import DatePicker from 'react-date-picker';
import { createMarketingEvent, getCommunities, getFacilityBuildingList, getFacilityUserList, getMasterData } from '../../../../../constants/axios/apis';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';
import Loader from '../../../../../components/Loader';
import { CustomRequestSuccessfulPopup } from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup';
import { useTranslation } from 'react-i18next'
import { LoadingSubmitAlert } from '../../../../../constants/generics/customAlerts';
import { PreventDatePickerInput } from '../../../../../constants/generics/dateTimeFormater';
import { validateAndCompressImage } from '../../../../../constants/generics/fileUploadHandler';
import { Select } from 'antd';
import IntlTelInput from 'react-intl-tel-input';

export function CreateEvent({setPopupSuccessMsg}:any) {
    const history = useHistory()
    const { Option } = Select
    let userID: any = ''
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userID = JSON.parse(userDetail).id
    }


    const [communityList, setCommunityList] = useState<any>([])
    const [buildingList, setBuildingList] = useState<any>([])
    const [categoryList, setCategoryList] = useState<any>([])
    const [selectedMobileCode, setMelectedMobileCode] = useState<any>('')

    // const [audienceList, setaudienceList] = useState<any>([])
    const { t } = useTranslation(); 
    
    const [isLoading, setIsLoading] = useState<any>({
        // community: true,
        building: true,
        category: true,
        // audience: true,
    })
    
    // const [selectedAudience, setSelectedAudience] = useState<any>([])
    // const [selectedCommunity, setSelectedCommunity] = useState([]);
    
    const successRef:any = useRef(null);
    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        isSubmitted: false,
        message: '',
    })

    useEffect(() => {
        // getCommunities().then(({ data }) => {
        //     setCommunityList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: item.id, name: item.community })) : [])
        //     setIsLoading((oState:any)=>({...oState, community:false}))
        // }).catch((e)=>{setIsLoading((oState:any)=>({...oState, community:false}))})

        getMasterData().then(({data}) => {
            const dataList = data?.find((el: any) => el.category_name === 'market_place')
            setCategoryList(dataList?.master_data ? dataList?.master_data : [])
            setIsLoading((oState:any)=>({...oState, category:false}))
        }).catch((e)=>{setIsLoading((oState:any)=>({...oState, category:false}))})

        getFacilityBuildingList().then(({ data }) => {
            setBuildingList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: item.id, name: item.building })) : [])
            setIsLoading((oState: any) => ({ ...oState, building: false }))
        }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, building: false })) })
            
        // getFacilityUserList('?type=residents').then(({data}) => {
        //     setaudienceList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: item.id, name: item.first_name + ' ' + item.last_name})) : [])
        //     setIsLoading((oState:any)=>({...oState, audience:false}))
        // }).catch((e)=>{setIsLoading((oState:any)=>({...oState, audience:false}))})
    }, [])

    const initialValues = {
        title: '',
        banner: '',
        logo: '',
        // community_id: [],
        buildingLocation: [],
        // code: '',
        // audience: [],
        category: '',
        address1: '',
        address2: '',
        location: '',
        contact: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        web: '',
        // ticket_no: '',
        // offer: '',
        // type_of_offer: '',
        desc: '',
        proper_date: '',
    }

    const onSubmit = (values: any) => {
        setSubmitStatus((oStatus:any)=>({...oStatus, loading: true, error: false}))

        const formData = new FormData()
        formData.append("name", values.title.trim());
        formData.append("image[]", values.banner);
        formData.append("logo", values.logo);
        // formData.append("coupon_code", values.code);
        // formData.append("offer", values.offer);
        formData.append("category", values.category);
        // formData.append("latitude", values.firstNameField);  //TODO
        // formData.append("longitude", values.firstNameField);  //TODO
        formData.append("address1", values.address1.trim());
        formData.append("address2", values.address2.trim());
        formData.append("location", values.location.trim());
        formData.append("contact", selectedMobileCode + values.contact);
        formData.append("start_date", values.startDate);
        formData.append("end_date", values.endDate);
        formData.append("start_time", values.startTime);
        formData.append("end_time", values.endTime);
        formData.append("web_link", values.web);
        // formData.append("ticket_no", values.ticket_no);
        // formData.append("offer_type", values.type_of_offer);  //TODO
        formData.append("description", values.desc.trim());
        formData.append("flag", '3');
        formData.append("merchant_id", userID);         //TODO
        formData.append("building_id", values?.buildingLocation && Array.isArray(values?.buildingLocation) ? (values?.buildingLocation).join() : '');
        // formData.append("community_id", values?.community_id && Array.isArray(values?.community_id) ? (values?.community_id).join() : '');
        // values?.audience?.forEach((element:any, index:any) => {
        //     formData.append("target_audiance[]", element);
        // });
        
        setTimeout(() => {
            createMarketingEvent(formData)
                .then((response: any) => {                    
                    if (response.errorCode === 1) 
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
                    else if(response.errorCode === 0) {
                        formik.resetForm();
                        setPopupSuccessMsg(response.description)
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.description, isSubmitted: true}))
                        successRef.current.click()
                        setTimeout(() => {
                            history.push("/promotions/list/upcoming/events")
                        }, 1000)
                    } else
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                })
                .catch((e) => {
                    setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                })
        }, 1000)
    }
    const validationSchema = Yup.object({
        title: Yup.string().required(t("Title is required!"))
            .matches(/^[^\s].*/, t('Title cannot start with a blankspace!'))
            .min(4, t('Min length is 4!'))
            .max(100, t('Max length is 100!'))
            .test('len', t('Title is required!'), (value) => {
                return value ? (value?.trim()).length > 0 : false
            }),
        // code: Yup.string().required('Code is required!'),
        buildingLocation: Yup.array().min(1,t("Building is required!")),
        category: Yup.string().required(t("Category is required!")),
        address1: Yup.string()
            .required(t("Address 1 is required!"))
            .matches(/^[^\s].*/, t('Address cannot start with a blankspace!'))
            .min(4, t('Min length is 4!'))
            .max(100, t('Max length is 100!')),
        address2: Yup.string()
            // .required(t("Address 2 is required!"))
            .matches(/^[^\s].*/, t('Address cannot start with a blankspace!'))
            .min(4, t('Min length is 4!'))
            .max(100, t('Max length is 100!')),
        location: Yup.string().required(t("Location is required!"))
            .required(t("Location is required!"))
            .matches(/^[^\s].*/, t('Location cannot start with a blankspace!'))
            .min(4, t('Min length is 4!'))
            .max(100, t('Max length is 100!')),
        contact: Yup.string().required(t("Contact is required!"))
            .min(4, t('Contact should be greater then 4 digits!'))
            .max(16, t('Contact should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!')),
        startDate: Yup.string().required(t("Start Date is required!")),
        endDate: Yup.string().required(t("End Date is required!")),
        startTime: Yup.string().required(t("Start Time is required!")),
        endTime: Yup.string().required(t("End Time is required!")),
        web: Yup.string().url(t("Please provide a valid URL!")),
        // Yup.string().required(t("Web is required!")),
        // ticket_no: Yup.string().required(t("No of Ticket is required!")),
        // offer: Yup.string().required('Offer % is required!'),
        desc: Yup.string()
            .required(t('Description is required!'))
            .matches(/^[^\s].*/, t('Description cannot start with a blankspace!'))
            .max(1000, t('Max length is 1000!'))
            .test('len', t('Description is required!'), (value) => {
                return value ? (value?.trim()).length > 0 : false
            }),
        banner: Yup.mixed().required(t("Banner Image is required!")),
            // .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png"), (value) =>
            //     value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true )
            // .test('fileSize', t("The file is too large!"), (value) => value ? value?.size <= 2000000 : true ),
        logo: Yup.mixed().required(t("Logo Image is required!")),
            // .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png"), (value) =>
            //     value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true )
            // .test('fileSize', t("The file is too large!"), (value) => value ? value?.size <= 2000000 : true ),
    });

    const validate = (values: any) => {
        let errors: any = {}
        // if(!values.building_id){
        //     errors.building_id = 'Required!';
        // }
        // if (!values.startDate) {
        //     errors.startDate = t("Required!");
        // }
        // if (!values.endDate) {
        //     errors.endDate = t("Required!");
        // }
        // var date1 = new Date(values.startDate);
        // var date2 = new Date(values.endDate);
        // if (values.startDate && values.endDate) {
        //     if (date1.getTime() > date2.getTime()) {
        //         errors.proper_date = '\'Start date\' must be greater then \'End date\' !';
        //     }
        // }
        // return errors;

        var startDate = new Date(values.startDate);
        var endDate = new Date(values.endDate);


        var date1 = new Date("01/01/2011 " + values.startTime);
        var date2 = new Date("01/01/2011 " + values.endTime);

        var selectedDate = moment(values.startDate).startOf('day')
        var selectedDateFull = moment(values.startDate + (values.startTime ? ' ' + values.startTime + ':00' : ''))
        var todayDate = moment().startOf('day')
        var todayFull = moment()

        if (values.startDate && values.endDate) {
            if (startDate.getTime() > endDate.getTime()) {
                errors.startDate = "Start date must be less then End date!";
            }
            else if (startDate.getTime() === endDate.getTime()) {
                if (values.startTime && (selectedDate.diff(todayDate, 'days') === 0) && (selectedDateFull.diff(todayFull) < 0)) {
                    errors.startTime = t('Please select the vaild time!');
                }
                if (values.startTime && values.endTime) {
                    if (date1.getTime() >= date2.getTime()) {
                        errors.endTime = t("'End Time' must be greater then 'Start Time'!");
                    }
                }
            } else {
                if (values.startTime && (selectedDate.diff(todayDate, 'days') === 0) && (selectedDateFull.diff(todayFull) < 0)) {
                    errors.startTime = t('Please select the vaild time!');
                }
            }
        }

        return errors;
    }

    // const validationSchema = Yup.object({
    //     title: Yup.string()
    //         .required('Title is required!')
    //         .matches(/^[^\s].*/, 'Title cannot start with a blankspace!')
    //         .min(4, 'Minimum length is 4!')
    //         .max(25, 'Maximum 25 characters!'),
    // });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        validate,
    });

    // useEffect(() => {
    //     formik.setFieldValue('audience', Array.isArray(selectedAudience) ? selectedAudience.map((item:any)=>item.value): [])
    //     // formik.setFieldTouched('audience', true)
    // }, [selectedAudience])

    // useEffect(() => {
    //     formik.setFieldValue('community_id', Array.isArray(selectedCommunity) ? selectedCommunity.map((item:any)=>item.value): [])
    //     // formik.setFieldTouched('audience', true)
    // }, [selectedCommunity])

    // useEffect(() => {
    //     setSelectedBuilding(buildingList.find((item: any) => item.id == formik.values.buildingLocation))
    //   }, [buildingList, formik.values.buildingLocation])
    

      const handleSelect = (val: any) => {
        formik.setFieldValue('buildingLocation', [val])
      }

    // const onValueChange = (phoneNumber:any, country:any, e:any, formattedValue:any) => {
    //     // console.log(phoneNumber, country, e, formattedValue);
    // 	formik.setFieldValue('mobileNumberField', '+' + phoneNumber)
    // 	formik.setFieldTouched('mobileNumberField')
    // };

    
    
    // const communityOptions = communityList.map((item:any)=>({label:item.name, value:item.id}))
    // const audienceOptions = audienceList.map((item:any)=>({label:item.name, value:item.id}))

    const [uploading1, setUploading1] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const handleUpload = () => inputRef.current?.click();
    const handleDisplayFileDetails = () => {
        setUploading1(true)
        formik.setFieldError('banner', undefined)
        if(inputRef.current?.files && inputRef.current?.files[0]){
            try {
                validateAndCompressImage(inputRef.current.files[0]).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setUploadedFileName(compressResponse.file.name);
                        formik.setFieldTouched('banner')
                        formik.setFieldValue('banner', compressResponse.file)
                    } else {
                        setUploadedFileName(null)
                        formik.setFieldTouched('banner', true, false)
                        formik.setFieldValue('banner', '', false)
                        formik.setFieldError('banner', t(compressResponse?.message || (t('File compression error!'))))
                    }
                    setUploading1(false)
                })
            } catch (error) {
                setUploadedFileName(null)
                formik.setFieldTouched('banner')
                formik.setFieldValue('banner', '', false)
                formik.setFieldError('banner', t('File upload error!'))
                setUploading1(false)
            }
        }

        // inputRef.current?.files &&
        // setUploadedFileName(inputRef.current.files[0].name);
        // formik.setFieldValue("banner", inputRef.current?.files && inputRef.current.files[0] ? inputRef.current.files[0] : '')
    };
    
    const [uploading2, setUploading2] = useState(false)
    const inputRef2 = useRef<HTMLInputElement>(null);
    const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);
    const handleUpload2 = () => inputRef2.current?.click();
    const handleDisplayFileDetails2 = () => {
        setUploading2(true)
        formik.setFieldError('logo', undefined)
        if(inputRef2.current?.files && inputRef2.current?.files[0]){
            try {
                validateAndCompressImage(inputRef2.current.files[0]).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setUploadedFileName2(compressResponse.file.name);
                        formik.setFieldTouched('logo')
                        formik.setFieldValue('logo', compressResponse.file)
                    } else {
                        setUploadedFileName2(null)
                        formik.setFieldTouched('logo', true, false)
                        formik.setFieldValue('logo', '', false)
                        formik.setFieldError('logo', t(compressResponse?.message || (t('File compression error!'))))
                    }
                    setUploading2(false)
                })
            } catch (error) {
                setUploadedFileName2(null)
                formik.setFieldTouched('logo')
                formik.setFieldValue('logo', '', false)
                formik.setFieldError('logo', t('File upload error!'))
                setUploading2(false)
            }
        }

        // inputRef2.current?.files &&
        // setUploadedFileName2(inputRef2.current.files[0].name);
        // formik.setFieldValue("logo", inputRef2.current?.files && inputRef2.current.files[0] ? inputRef2.current.files[0] : '')
    };

    const today_data_object = new Date();
    const [fromDateValue, setFromDateValue] = useState<any>();
    const [toDateValue, setToDateValue] = useState<any>();
    const handleFromDateChange = (e: any) => {
        setFromDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') :'';
        formik.setFieldValue("startDate", menDate);        
    }
    const handleToDateChange = (e: any) => {
        setToDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') :'';
        formik.setFieldValue("endDate", menDate);        
    }

    const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
        setMelectedMobileCode(country?.dialCode && !phoneNumber.includes('+')? `+${country?.dialCode}` : '')
        formik.setFieldValue('contact', phoneNumber)
        formik.setFieldTouched('contact')
    }

    // const isError = communityList?.length === 0 ? 'No community data found.'
    //     : categoryList?.length === 0 ? t("No category data.")
    //     : '' ;
    const isError = categoryList?.length === 0 ? t("No category data.") 
        : buildingList?.length === 0 ? t("No building data.") 
        : '' ;
    const isLoadingData = (isLoading.category || isLoading.building)
    PreventDatePickerInput()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <span className='c-pointer'  onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        <span className="text-black">
                            {t("Create New Event")}
                        </span>
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-8">
                            { isLoadingData ? (
                                <div className="col-12 d-flex flex-column justify-content-center mt-5">
                                    <Loader />
                                </div>
                            ): (isError !== '' && (
                                <div className="col-12 mt-5">
                                    <div className="alert alert-danger mt-2 text-center w-100" role="alert">
                                        { isError }
                                    </div>
                                </div>
                            ))}
                            <div className="row form-add-design">
                                <div className="col-lg-12 col-sm-12 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-black"> {t("Event Details")} </label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Title")} <span className="text-danger"> * </span></label>
                                        <input 
                                            {...formik.getFieldProps('title')}
                                            type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Title")}
                                        />
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.title}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>                                
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7 upload-input">
                                        <label className="form-label text-black font-14 fw-bold d-block"> {t("Banner Image")} <span className="text-danger"> * </span></label>
                                        <input
                                            ref={inputRef}
                                            onChange={handleDisplayFileDetails}
                                            className="d-none"
                                            type="file"
                                            accept=".png,.jpg,.jpeg"
                                            // onBlur={formik.handleBlur}
                                        />
                                        <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary active" : "primary"}`} disabled={uploading1}>
                                        {/* <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file pe-2 ellipsis pe-4 rounded border-0 btn-outline-${uploadedFileName ? "primary active" : "primary"}`} disabled={uploading1}> */}
                                            <span> {uploading1 ?
                                                <span className='indicator-progress d-block'>
                                                    {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                                : uploadedFileName ? uploadedFileName : <>{t("Upload")} <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i></>} </span>
                                        </button>
                                        {formik.touched.banner && formik.errors.banner ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.banner}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7 upload-input">
                                        <label className="form-label text-black font-14 fw-bold d-block"> {t("Banner Logo")} <span className="text-danger"> * </span></label>
                                        <input
                                            ref={inputRef2}
                                            onChange={handleDisplayFileDetails2}
                                            className="d-none"
                                            type="file"
                                            accept=".png,.jpg,.jpeg"
                                            // onBlur={formik.handleBlur}
                                        />
                                        <button onClick={handleUpload2} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary active" : "primary"}`} disabled={uploading2}>
                                            <span> {uploading2 ?
                                                <span className='indicator-progress d-block'>
                                                    {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                                : uploadedFileName2 ? uploadedFileName2 : <>{t("Upload")} <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i></>} </span>
                                        </button>
                                        {formik.touched.logo && formik.errors.logo ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.logo}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Voucher Value </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div> */}
                                <div className="col-lg-12 col-sm-12 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder text-black"> {t("Other Details")} </label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Building Name")} <span className="text-danger"> * </span></label>
                                        {/* <MultiSelect
                                            options={communityOptions}
                                            value={selectedCommunity}
                                            onChange={setSelectedCommunity}
                                            labelledBy="Select-property"
                                            hasSelectAll={false}
                                        /> */}
                                        <Select
                                            className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                                            showSearch
                                            placeholder={t('Select a building')}
                                            optionFilterProp='children'
                                            onChange={handleSelect}
                                            onBlur={formik.handleBlur}
                                            filterOption={(input, option) =>
                                                ((option!.children as unknown) as string)
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                        >
                                            {buildingList?.map((el: any, index: number) => (
                                                <Option key={index} value={el.id}>
                                                    {el.name}
                                                </Option>
                                            ))}
                                        </Select>
                                        {formik.touched.buildingLocation && formik.errors.buildingLocation && (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.buildingLocation}</small>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Category")} <span className="text-danger"> * </span></label>
                                        <select
                                            name="category"
                                            className="form-control form-select fw-bolder form-control-solid"
                                            data-control='select2'
                                            data-allow-clear='true'
                                            onChange={formik.handleChange}
                                            value={formik.values.category}
                                            onBlur={formik.handleBlur}
                                            // defaultValue={''}
                                        >
                                            <option value="">{t("Please Select")}</option>
                                            {categoryList?.map((item: any, index: number) => {
                                                return (
                                                    <option value={item.value} key={index}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                        {formik.touched.category && formik.errors.category ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.category}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Address Line 1")} <span className="text-danger"> * </span></label>
                                        <input 
                                            {...formik.getFieldProps('address1')}
                                            type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Address Line 1")} />
                                        {formik.touched.address1 && formik.errors.address1 ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.address1}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Address Line 2")} 
                                        {/* <span className="text-danger"> * </span> */}
                                        </label>
                                        <input 
                                            {...formik.getFieldProps('address2')}
                                            type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Address Line 2")} />
                                        {formik.touched.address2 && formik.errors.address2 ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.address2}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div> 
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Place")} <span className="text-danger"> * </span></label>
                                        <input 
                                            {...formik.getFieldProps('location')}
                                            type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Place")} />
                                        {formik.touched.location && formik.errors.location ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.location}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div> 
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Contact")} <span className="text-danger"> * </span></label>
                                        {/* <input 
                                            {...formik.getFieldProps('contact')}
                                            type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Contact")} /> */}
                                        <IntlTelInput
                                            preferredCountries={['ae', 'in', 'us']}
                                            containerClassName='intl-tel-input w-100'
                                            separateDialCode={true}
                                            fieldName='mobile_no'
                                            value={formik.values.contact}
                                            inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                            onPhoneNumberChange={handlePhoneNumber}
                                            onPhoneNumberBlur={handlePhoneNumber}
                                            placeholder={t('Mobile No.')}
                                        />
                                        {formik.touched.contact && formik.errors.contact ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.contact}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div> 
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7 date-picker">
                                        <label className="form-label fw-bolder"> {t("Start Date")} <span className="text-danger"> * </span> </label>
                                        <DatePicker
                                            value={fromDateValue}
                                            className='form-control fw-bolder form-control-solid'
                                            format="dd-MM-y"
                                            minDate={today_data_object}
                                            onChange={handleFromDateChange}
                                            dayPlaceholder="DD"
                                            monthPlaceholder="MM"
                                            yearPlaceholder="YYYY"
                                        />
                                        {formik.touched.startDate && formik.errors.startDate ? (
                                            <small className="text-danger">
                                                {formik.errors.startDate}
                                            </small>
                                        ) : null}
                                        {(formik.touched.startDate && formik.touched.endDate) && formik.errors.proper_date ? (
                                            <small className="text-danger">
                                                {formik.errors.proper_date}
                                            </small>
                                        ) : null}
                                    </div>
                                </div> 
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("Start Time")} <span className="text-danger"> * </span></label>
                                        <input 
                                            {...formik.getFieldProps('startTime')}
                                            type="time" className="form-control fw-bolder form-control-solid" placeholder="" />
                                        {formik.touched.startTime && formik.errors.startTime ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.startTime}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div> 
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7 date-picker">
                                        <label className="form-label fw-bolder"> {t("End Date")} <span className="text-danger"> * </span></label>
                                        <DatePicker
                                            value={toDateValue}
                                            className='form-control fw-bolder form-control-solid'
                                            format="dd-MM-y"
                                            minDate={today_data_object}
                                            onChange={handleToDateChange}
                                            dayPlaceholder="DD"
                                            monthPlaceholder="MM"
                                            yearPlaceholder="YYYY"
                                        />
                                        {formik.touched.endDate && formik.errors.endDate ? (
                                            <small className="text-danger">
                                                {formik.errors.endDate}
                                            </small>
                                        ) : null}
                                    </div>
                                </div> 
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> {t("End Time")} <span className="text-danger"> * </span></label>
                                        <input 
                                            {...formik.getFieldProps('endTime')}
                                            type="time" className="form-control fw-bolder form-control-solid" placeholder="" />
                                        {formik.touched.endTime && formik.errors.endTime ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.endTime}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div> 
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder d-block"> {t("Web")} 
                                        {/* <span className="text-danger"> * </span> */}
                                        </label>
                                        <div className="input-group mb-0">
                                            <input
                                                {...formik.getFieldProps('web')}
                                                type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Web Link")} />
                                            <span className="input-group-text bg-transparent font-14 fw-normal" id="basic-addon2">
                                                <i className="bi bi-link-45deg font-20 fw-boldest text-gray-600"></i>
                                            </span>
                                        </div>
                                        {formik.touched.web && formik.errors.web ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.web}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                
                                <div className="col-md-12 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder">
                                            {t("Description")} <span className="text-danger"> * </span>
                                        </label>
                                        <textarea
                                            className='form-control fw-bolder form-control-solid'
                                            name='desc'
                                            value={formik.values.desc}
                                            onChange={formik.handleChange}
                                            data-kt-autosize='true'
                                            rows={3}
                                            placeholder={t("Type here...")}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.desc && formik.errors.desc ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.desc}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>


                                <div className="col-12 text-end">
                                    <button
                                        type='button'
                                        className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                                        disabled={submitStatus.loading}
                                        onClick={() => { history.goBack() }}
                                    >{t("Cancel")}</button>
                                
                                    <button
                                        onClick={formik.submitForm}
                                        type='submit'
                                        className='btn btn-primary fw-bold px-10 py-3'
                                        disabled={submitStatus.isSubmitted || submitStatus.loading || uploading1 || uploading2 || isLoadingData}
                                        >
                                        {submitStatus.loading ? 
                                            <span className='indicator-progress d-block'>
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                                            {t("Submit")}
                                            </span>
                                            :
                                            <span className='indicator-label'>{t("Submit")}</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <span
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='d-none'
            ></span>
            <LoadingSubmitAlert submitStatus={submitStatus}/>
        </div>
        
    );
}