import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import Propertie from '../../../../assets/images/latest-one.png';
import { RootState } from '../../../../constants';
import * as PM from '../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { currencyFormater, CustomLoader1, NoDataFound1 } from './../../../../constants/generics/customActios';
import { SelectTeamMemberPopup } from './../ActionRequired/SelectTeamMemberPopup';
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import { useTranslation } from 'react-i18next'

export function NewProperty() {
    const { t } = useTranslation();

    const location = useLocation()
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const dispatch = useDispatch()
    let proprtyList: any
    proprtyList = useSelector<RootState>(({ PM }) => PM.proprtylist)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(4)
    useEffect(() => {
    }, [currentBuilding])
    useEffect(() => {
        dispatch(PM.actions.proprtylist(`available?currentPage=${currentPage}&pageSize=${pageSize}&building_id=${currentBuilding}&isAssigned=0`))
    }, [, currentBuilding])

    const handleAssign = (property_id: any) => {
        dispatch(General.actions.pid(property_id))
    }
    if (proprtyList === undefined) {
        return <CustomLoader1 height={20} />
    }
    return (
        <div className="mta_my_team_upcoming_tasks">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        {proprtyList?.errorCode != 1 && Array.isArray(proprtyList?.data) && proprtyList?.data?.length > 0 ? proprtyList?.data?.map((item: any, index: number) => {
                            if (item?.is_assigned != 1)
                                return (
                                    <div className=" col-sm-6 col-12" key={index}>
                                        {/* <Link to={{pathname:'/pm-myteam-action-required', state:{ type: 'one', pid: item?.id, id: item?.id, from: location.pathname}}}> */}
                                        <div className="properties_list_view mb-5">
                                            <div className="card px-3 py-3">
                                                <div className="row align-items-center">
                                                    <div className="col-md-12 col-xl-4 col-4">
                                                        <div className="offer_main_img">
                                                            <img src={item?.coverImage?.length > 0 ? item?.coverImage : Propertie} alt="Work Orders" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-8 col-md-12 col-8 ps-md-0">
                                                        <div className="offer_main_details">
                                                            <div className="col-12">
                                                                <p className="text-black fw-bold fs-12 mb-2">{item?.property_name ? item?.property_name : '-'}</p>
                                                            </div>
                                                            <div className="col-12 offer_main_details_div_3 d-flex align-items-center">
                                                                <h4 className="text-black fw-bolder mb-2">
                                                                    <small>{t("AED")}</small> <span className="fw-boldest">{item?.price ? currencyFormater(item.price) : '-'}</span>
                                                                </h4>
                                                                {/* <Link  to={{pathname:'/pm-myteam-action-required', state:{ type: 'one', pid: item?.id, id: item?.id, from: location.pathname}}} className="count text-decoration-none text-black"> Assign </Link> */}
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="" onClick={() => handleAssign(item?.id)}> <SelectTeamMemberPopup /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </div>)
                        }) : <div className='row'><NoDataFound1 height={150} /></div>}
                    </div>
                </div>
            </div>
        </div>
    );
}