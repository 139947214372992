import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom'

export function ContactPopup({ selectedSlot }: any) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div className='modal fade custom-modal' id='parked-contact-popup-modal'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-header bg-white border-0'>
                        <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'>
                        </button>
                    </div>
                    <div className='modal-body py-5'>
                        <div className='row mb-5'>
                            <div className='col-sm-6 col-12'>
                                <h5 className='modal-title font-16' id='exampleModalLabel'>
                                    {t("Contact Details")}
                                </h5>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='table-responsive fc-su-ser-tabel px-0'>
                                <table className='table align-middle table-striped gy-4 gx-4'>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>{t("Contact Number")} :- {selectedSlot?.tenantNumber ? selectedSlot?.tenantNumber : (t('Not Available'))}</strong>
                                            </th>
                                        </tr>
                                    </thead>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
