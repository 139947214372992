import React, { useState, useEffect } from 'react';
import GoogleAds from '../../../../../../../../assets/images/google-ads-icon.svg';
import ReactFlagsSelect from "react-flags-select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getNationalities, getMasterData } from '../../../../../../../../constants/axios/apis'

export function StepTwo(props: any) {
    const { contactInformation } = props
    const { contact } = props
    const Tdata = contact?.length > 0 ? JSON.parse(contact) : ''
    const { formErrors } = props
    const [cName, setCName] = useState(Tdata?.cName?.length > 0 ? Tdata?.cName : '')
    const [contactNumber, setContactNumber] = useState(Tdata?.contactNumber?.length > 0 ? Tdata?.contactNumber : '')
    const [relation, setRelation] = useState(Tdata?.relation?.length > 0 ? Tdata?.relation : '')
    const [nationality, setNationality] = useState(Tdata?.nationality?.length > 0 ? Tdata?.nationality : '')
    const [nationalities, setNationalities] = useState<any>()
    const [relations, setRelations] = useState<any>()
    useEffect(() => {
        getNationalities().then(({ data }) => {
            setNationalities(data)
        })
    }, [])
    useEffect(() => {
        getMasterData().then(({ data: masterData }) => {
            let categories = masterData
                .filter((item: any) => item.id == 19)
                .flatMap((data: any) => data.master_data)
            setRelations(categories)
        })
    }, [])
    const nationalityList = nationalities?.map((nationality: any) => (
        <option key={nationality.id_nationality} value={nationality.id_nationality}>
            {nationality.country}
        </option>
    ))
    const relationsList = relations?.map((relation: any) => (
        <option key={relation.value} value={relation.name}>
            {relation.name}
        </option>
    ))
    contactInformation(
        JSON.stringify({
            cName,
            contactNumber,
            relation,
            nationality
        }))
    const [selected, setSelected] = useState("");
    const [value, setValue] = useState<any>();
    // const onchangeNo =(e:any)=>{
    //     setValue(e.target.value);
    // }

    return (
        <div className="tenant-form py-6 step-one">
            <div className="mb-5">
                <label className="form-label text-black"> Name <span className="text-danger">*</span> </label>
                <input type="text" name="username" className="form-control text-black" placeholder="Name"
                    maxLength={20}
                    onChange={(e) => setCName(e?.target?.value)}
                    value={cName}
                    onKeyPress={(event) => {
                        if (!/[a-zA-Z ]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    autoComplete="off"
                    onPaste={(e) => {
                        e.preventDefault()
                        return false;
                    }} onCopy={(e) => {
                        e.preventDefault()
                        return false;
                    }}
                // style={{ border: cName?.length > 0 ? '1px solid green' : '1px solid red' }}
                />
                <p style={{ color: "red" }}>
                    {formErrors?.cName}
                </p>
            </div>
            <div className="mb-5">
                <label className="form-label text-black"> Contact Number <span className="text-danger">*</span> </label>
                <PhoneInput
                    country={'ae'}
                    preferredCountries={['in', 'ae']}
                    // onlyCountries={['in', 'ae']}
                    priority={{ ae: 0, in: 1 }}
                    value={contactNumber}
                    onChange={setContactNumber}
                    enableSearch={true}
                    enableAreaCodeStretch={true}
                    inputClass={"text-black"}
                />
                <p style={{ color: "red" }}>
                    {formErrors?.contactNumber}
                </p>
            </div>
            <div className="mb-5">
                <label className="form-label text-black"> Relation <span className="text-danger">*</span> </label>
                <select className="form-control form-select text-black" onChange={(e) => setRelation(e?.target?.value)} value={relation}>
                    <option selected> Select Relation </option>
                    {relations ? relationsList : ''}
                </select>
                <p style={{ color: "red" }}>
                    {formErrors?.relation}
                </p>
            </div>
            <div className="mb-5">
                <label className="form-label text-black"> Nationality <span className="text-danger">*</span> </label>
                {/* <ReactFlagsSelect
                    selected={nationality}
                    onSelect={(code) => setNationality(code)}
                    searchPlaceholder="Search countries"
                    searchable
                    className="form-control p-0"
                /> */}
                <select className="form-control form-select text-black" value={nationality} onChange={(e) => setNationality(e?.target?.value)}>
                    <option selected >
                        Select Nationality
                    </option>
                    {nationalities ? nationalityList : ''}
                </select>
                <p style={{ color: "red" }}>
                    {formErrors?.nationality}
                </p>
            </div>
        </div>
    );
}