import React, {useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../../../assets/images/Left_Arrow.png'
import {InActiveDetailTabs} from './InActiveDetailTabs'
import {InActiveAccessCardDetails} from './InActiveAccessCardDetails'
import { useTranslation } from 'react-i18next';

const InActiveDetailWrapper: React.FC = () => {
  const location = useLocation<any>()
  const history = useHistory<any>()
  let accessCards = location?.state?.accessData ? location?.state?.accessData : {}
  const [activeButton, setActiveButton] = useState<any>('building')
  const { t } = useTranslation();
  // console.log(accessCards);

  const bacCount = accessCards?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.no_of_cards
  const pacCount = accessCards?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.no_of_cards
  const mdkCount = accessCards?.allotted_access_card?.find((el: any) => el.name === 'Main Door Key')
    ?.no_of_cards
  const oacCardData = accessCards?.card_details?.other_access_cards?.card_number
  const oacCount = oacCardData ? Object.keys(oacCardData)?.length : 0
  let bacCardDetails
  let pacCardDetails
  let oacCardDetails

  if (accessCards?.card_details?.building_access_card) {
    bacCardDetails = accessCards?.card_details?.building_access_card?.card_number
    bacCardDetails = Object.values(bacCardDetails)
    bacCardDetails = bacCardDetails?.map((item: any) => item.number)
  }

  if (accessCards?.card_details?.parking_access_card) {
    pacCardDetails = accessCards?.card_details?.parking_access_card?.card_number
    pacCardDetails = Object.values(pacCardDetails)
    pacCardDetails = pacCardDetails.map((item: any) => item.number)
  }

  if (accessCards?.card_details?.other_access_cards) {
    oacCardDetails = accessCards?.card_details?.other_access_cards?.card_number
    oacCardDetails = Object.values(oacCardDetails)
    oacCardDetails = oacCardDetails.map((item: any) => item.number)
  }

  return (
    <>
      <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='card-title font-18 text-black mb-6'>
                <Link to={'/access-cards/active'} className=''>
                  {/* <div onClick={()=>history.goBack()} style={{cursor:'pointer'}}> */}
                  <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                  <span className='text-black'>{t("Access Cards")}</span>
                  {/* </div> */}
                </Link>
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mb-7'>
              <div className='com-card-section'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='card'>
                      <div className='card-body p-7'>
                        <div className='row'>
                          <div className='col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-0'>
                            <div className='approval-move'>
                              <img src={accessCards?.image} className='img-fluid' />
                            </div>
                          </div>
                          <div className='col-md-8 col-lg-9'>
                            <div className='card lease-card bg-white shadow-none rounded-0'>
                              <div className='card-body p-0'>
                                <div className='row'>
                                  <div className='col-12 mb-5 text-end'>
                                    <span
                                      className={
                                        'bg-info bg-opacity-10 text-info px-5 py-3 rounded fw-bolder'
                                      }
                                    >
                                      {t("In-Active")}
                                    </span>
                                  </div>
                                  <div className='col-sm-12 col-12 mb-7'>
                                    <h6 className='text-black mb-0 fw-bold font-15'>
                                      {t("Tenant Name")} :{' '}
                                      {accessCards?.cutomerName ? accessCards?.cutomerName : '--'}
                                    </h6>
                                  </div>
                                  <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                                    <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                                     {t("Building Name")}
                                    </h6>
                                    <span className='text-black fw-bold font-15'>
                                      {accessCards?.building ? accessCards?.building : '--'}
                                    </span>
                                  </div>
                                  <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                                    <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                                      {' '}
                                      {t("Unit Number")}{' '}
                                    </h6>
                                    <span className='text-black fw-bold font-15'>
                                      {accessCards?.propertyCode ? accessCards?.propertyCode : '--'}
                                    </span>
                                  </div>
                                  <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                                    <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                                      {t("Building Access Cards")}
                                    </h6>
                                    <span className='text-black fw-bold font-15'>
                                      {' '}
                                      {bacCount ? bacCount : 0}
                                    </span>
                                  </div>
                                  <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7'>
                                    <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                                      {t("Parking Access Cards")}
                                    </h6>
                                    <span className='text-black fw-bold font-15'>
                                      {' '}
                                      {pacCount ? pacCount : 0}
                                    </span>
                                  </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-0'>
                                  <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                                    {t("Other Access Cards")}
                                  </h6>
                                  <span className='text-black fw-bold font-15'>
                                    {oacCount ? oacCount : 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <InActiveDetailTabs
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            isOthers={oacCount !== 0}
          />
          {activeButton === 'building' ? (
            <InActiveAccessCardDetails count={bacCount} cardDetails={bacCardDetails} />
          ) : activeButton === 'parking' ? (
            <InActiveAccessCardDetails count={pacCount} cardDetails={pacCardDetails} />
          ) : activeButton === 'others' ? (
            <InActiveAccessCardDetails count={oacCount} cardDetails={oacCardDetails} />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

export {InActiveDetailWrapper}
