import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import MarketPlace01 from "../../../../assets/images/Vox_logo.png";
import MarketPlace02 from "../../../../assets/images/Mado_logo.png";
import MarketPlace03 from "../../../../assets/images/be_fit_logo-sm.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'
import {getMarketPlaceList} from '../../../../constants/axios/apis'
import Loader from '../../../../components/Loader';
import { useTranslation } from 'react-i18next'
import { NoDataFoundMarketPlace } from '../../../../constants/generics/customActios';

export function MarketPlace() {
    const { t } = useTranslation();
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };


    const [marketPlaceItems, setMarketPlaceItems] = useState<any>([])
    const [loadingMarket, setLoadingMarket] = useState(true)

    useEffect(() => {
        getMarketPlaceList().then((response: any) => {
            // console.log(response);
            if (response?.errorCode === 0 && response?.data) {                
                setMarketPlaceItems(response.data)
                //TODO - replace category code with text            
                setLoadingMarket(false);
            }else{
                setLoadingMarket(false);
            }
        })
    }, [])

    //Get only 4 items from last
    let content = marketPlaceItems?.slice(-4).map((item: any) => (
        <div key={item.id}>
            <Link
                to={{
                pathname: '/market-place/marketplaceofferdetails',
                state: item,
                }}
            >
                <div className='card mt-4'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-4 col-lg-3 my-auto col-12'>
                                <div className='img-bg'>
                                <img src={item.banner} className='img-fluid position-relative' />
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-9 col-12'>
                                <div className='d-flex flex-nowrap justify-content-between fdrr-rtl'>
                                    <h4 className='card-title mb-3'> {item.name} </h4>
                                    <span className='count'> 2 For 1 </span>
                                </div>
                                <h6 className='sub-title'>{item.location}</h6>
                                <h6 className='category mb-1'>{item.category}</h6>
                                <div className='d-flex flex-nowrap justify-content-between fdrr-rtl align-items-center'>
                                    <span className='sch-time'>
                                        <span className='bullet bullet-dot bg-success h-10px w-10px translate-middle'></span>
                                        {item.open_hours}
                                    </span>
                                    <a href='#' className='count text-decoration-none'> {t("View Detail")} </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    ))

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-7">
                                    <h4 className="card-title"> {t("Market Place")} </h4>
                                </div>
                            </div>
                            <div className="row">
                                <NoDataFoundMarketPlace height={'130'} />
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    )

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <h4 className="card-title"> {t("Market Place")} </h4>
                                </div>
                                {/*<div className="col-md-6 col-6 text-right">*/}
                                    {/*<a href="#" className="text-decoration-none view-all"> */}
                                        {/*<span> View All </span>*/}
                                        {/*<img src={RightArrow} className="img-fluid" /> */}
                                    {/*</a>*/}
                                {/*</div>*/}
                            </div>
                            <div className="market-place-sec">
                                <div className="row">
                                    <div className="col-md-12 col-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-12 p-0">
                                            {loadingMarket ?
                                                <Loader /> :
                                                marketPlaceItems.length ? 
                                                    <Slider {...settings}>{content}</Slider>
                                                    :<div className="text-center">{t("No Data Found")}</div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}