import { Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import loader from '../../assets/images/loader.gif';

export const CustomLoader = () => {
    const { t } = useTranslation();

    return <>
        <div className="card mb-7" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
          <img src={loader} className="h-44px" width='100' height="100"/>
{/*             <Spin tip={t("Loading...")} size="large" style={{color:'#1C447B'}}/> */}
        </div>
    </>;
};
export const CustomLoader1 = (props:any) => {
    const { t } = useTranslation();
    return <>
        <div className="card mb-7" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: `${props.height}vh`,
        }}>
         <img src={loader} className="h-44px" width='100' height="100"/>
{/*             <Spin tip={t("Loading...")} size="large" style={{color:'#1C447B'}}/> */}
        </div>
    </>;
};