import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeRequest } from "./hooksHelper";

interface IState {
    APIState: {
        loading: boolean,
        response: any,
        error: string,
    }
};

const API_URL = process.env.REACT_APP_API_URL || 'api';

const useRequestGet = (URL: string) => {
    const [state, setState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    const appState:any = useSelector(state => state);
    const userToken = appState?.auth?.accessToken;

    const url = `${API_URL}${URL}`

    if(userToken === "undefined"){
        // token undefined
    }
    
    const [refresh, setRefresh] = useState(true);

    function refreshFuc (){
        setRefresh(preSt => !preSt)
    }

    useEffect(()=>{
        makeRequest('GET', url, setState, '', userToken);
    },[refresh]);

    return {
        requestState: state,
        refreshRequest: refreshFuc
    }
}



export default useRequestGet;