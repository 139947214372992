import React, { useState, useEffect } from 'react'

export function Offers() {
    return (
        <>

            <div className="row mt-4">
                <div className="font-14 font-weight-bold text-muted ml-0">
                    <span className="text-warning text-center rounded d-inline-block mb-1">Agent</span>
                </div>
                <div className="chat-message-content font-10 px-5 py-5 border shadow-none rounded-10 w-100">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500">Offer Ref</span>
                            <p className="font-12 ellipsis"> REF-OF-1689664421 </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500"> Property Title </span>
                            <p className="font-12 "> Invest Now/ Good Income/3BR TH w Terrace </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500"> Community </span>
                            <p className="font-12 ellipsis"> Bloom Gradens </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500"> Contact Name </span>
                            <p className="font-12 ellipsis"> kota tejesh </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500"> Listing Price </span>
                            <p className="font-12 ellipsis"> AED 3 </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500"> Offer Price </span>
                            <p className="font-12 ellipsis"> AED 60,000 </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500"> Offer Date </span>
                            <p className="font-112 ellipsis"> 18/07/2023 </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <span className="d-block font-12 text-black text-uppercase font-weight-500"> Deposit </span>
                            <p className="font-12 ellipsis"> 10.00 %</p>
                        </div>
                        <div className="col-12 text-end">

                        </div>
                    </div>
                </div>
                <div className="chat-message-date d-block">
                    <div className="row">
                        <div className="col-12 text-end">
                            <span className="font-10 d-block mb-0 mr-0 ml-0 float-right"> 18/07/2023 02:14 </span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

