import React, {FC, useEffect, useRef, useState, Component} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../components/helpers'
import {Field, ErrorMessage} from 'formik'
import { useTranslation } from 'react-i18next'

const Step4: FC = () => {
    const intl = useIntl()
  const location = useLocation()
  const [menu, setMenu] = useState('')
  const { t } = useTranslation();

  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  useEffect(() => {
    if (window.location.pathname != menu) {
      setMenu(window.location.pathname)
      console.log(window.location.pathname)
    }
  })

  return (
    <>
        { userRole === 16 ? (
            <div className="com-card-section w-100 p-7">
            <div className="row form-add-design">
                <div className="col-12">
                    <div className="card shadow-none">
                        <div className="card-body p-0">
                            <div className="row form-add-design">
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder d-block mb-5">{t("Gated Security")}</label>
                                        <div className="btn-group" id="property_available">
                                            <input className="form-check-input me-5" type="radio" name="btnradio1" id="btnradio5" value="yes" checked/>
                                            <label className="form-check-label me-5 text-black" htmlFor="btnradio5"> {t("Yes")} </label>

                                            <input className="form-check-input me-5" type="radio" name="btnradio1" id="btnradio6" value="no" />
                                            <label className="form-check-label text-black" htmlFor="btnradio6"> {t("No")} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder d-block mb-5">{t("Non-Veg allowed?")}</label>
                                        <div className="btn-group" id="property_available">
                                            <input className="form-check-input me-5" type="radio" name="btnradio2" id="btnradio7" value="yes" checked/>
                                            <label className="form-check-label me-5 text-black" htmlFor="btnradio7"> {t("Yes")} </label>

                                            <input className="form-check-input me-5" type="radio" name="btnradio2" id="btnradio8" value="no"/>
                                            <label className="form-check-label text-black" htmlFor="btnradio8"> {t("No")} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder d-block mb-5">{t("Gym?")}</label>
                                        <div className="btn-group" id="property_available">
                                            <input className="form-check-input me-5" type="radio" name="btnradio3" id="btnradio9" value="yes" checked/>
                                            <label className="form-check-label me-5 text-black" htmlFor="btnradio9"> {t("Yes")} </label>

                                            <input className="form-check-input me-5" type="radio" name="btnradio3" id="btnradio11" value="no"/>
                                            <label className="form-check-label text-black" htmlFor="btnradio11"> {t("No")} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Bathroom(s)")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Water Supply")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Other Amenities")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        ): userRole === 18 ? ( 
            <div className="com-card-section w-100 p-7">
            <div className="row form-add-design">
                <div className="col-12">
                    <div className="card shadow-none">
                        <div className="card-body p-0">
                            <div className="row form-add-design">
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder d-block mb-5">{t("Gated Security")}</label>
                                        <div className="btn-group" id="property_available">
                                            <input className="form-check-input me-5" type="radio" name="btnradio1" id="btnradio5" value="yes" checked/>
                                            <label className="form-check-label me-5 text-black" htmlFor="btnradio5"> {t("Yes")} </label>

                                            <input className="form-check-input me-5" type="radio" name="btnradio1" id="btnradio6" value="no" />
                                            <label className="form-check-label text-black" htmlFor="btnradio6"> {t("No")} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder d-block mb-5">{t("Non-Veg allowed?")}</label>
                                        <div className="btn-group" id="property_available">
                                            <input className="form-check-input me-5" type="radio" name="btnradio2" id="btnradio7" value="yes" checked/>
                                            <label className="form-check-label me-5 text-black" htmlFor="btnradio7"> {t("Yes")} </label>

                                            <input className="form-check-input me-5" type="radio" name="btnradio2" id="btnradio8" value="no"/>
                                            <label className="form-check-label text-black" htmlFor="btnradio8"> {t("No")} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder d-block mb-5">{t("Gym?")}</label>
                                        <div className="btn-group" id="property_available">
                                            <input className="form-check-input me-5" type="radio" name="btnradio3" id="btnradio9" value="yes" checked/>
                                            <label className="form-check-label me-5 text-black" htmlFor="btnradio9"> {t("Yes")} </label>

                                            <input className="form-check-input me-5" type="radio" name="btnradio3" id="btnradio11" value="no"/>
                                            <label className="form-check-label text-black" htmlFor="btnradio11"> {t("No")} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Bathroom(s)")}</label>
                                        <input type="text" className="form-control fw-bolder form-control-solid"/>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Water Supply")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Other Amenities")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        ):null}
    </>
  )
}

export {Step4}
