import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { AddBuilding } from './Buildings/AddBuilding'
import { BuildingList } from './Buildings/BuildingList'
import { EditBuilding } from './Buildings/EditBuilding'
import { CreateUnit } from './Buildings/CreateUnit'
import { PreviewBuilding } from './Buildings/PreviewBuilding'

const PMBuildingSWrapper: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                    <Route path='/building/add'>
                            <AddBuilding />
                        </Route>
                        <Route path='/building/list'>
                            <BuildingList />
                        </Route>
                        <Route path='/building/create-unit'>
                            <CreateUnit />
                        </Route>
                        <Route path='/building/edit'>
                            <EditBuilding />
                        </Route>
                        <Route path='/building/preview'>
                            <PreviewBuilding />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMBuildingWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMBuildingSWrapper />
        </>
    )
};

export { PMBuildingWrapper }

