import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import CheckMark from "../../../assets/images/Check_green_lg.png";
import ErrorMark from "../../../assets/images/error.png";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import PhoneInput from 'react-phone-input-2';
import { createTeamMember } from '../../../constants/axios/apis';
import { useTranslation } from 'react-i18next';
import IntlTelInput from 'react-intl-tel-input';
import { LoadingSubmitAlert } from '../../../constants/generics/customAlerts';

export function AddTeamMember({setPopupSuccessMsg}:any) {
    // const [loading, setLoading] = useState(false)
    const history = useHistory()
    const successRef: any = useRef(null);
    // const [uploadedEmiratesIdName, setUploadedEmiratesIdName] = useState<any>(null)
    // const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    // const inputRef = useRef<HTMLInputElement>(null);
    // const emiratesIdInputRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const [mobileCode, setMobileCode] = useState<any>('')
    const [altContactCode, setAltContactCode] = useState<any>('')
    const [emgContactCode, setEmgContactCode] = useState<any>('')

    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        isSubmitted: false,
        message: '',
    })

    // const handleEmiratesIdUpload = () => {
    //     emiratesIdInputRef.current?.click()
    // }

    // const handleUpload = () => {
    //     inputRef.current?.click()
    // }

    const initialValues = {
        firstNameField: "",
        lastNameField: "",
        emirateIDField: "",
        mobileNumberField: "",
        emailIDField: "",
        // alternate_email: '',
        alternate_no: '',
        emergency_contact_name: '',
        emergency_contact_no: '',
        // present_address: '',
        // permanent_address: '',
        // photo: '',
    }

    const teamMemberSchema = Yup.object().shape({
        firstNameField: Yup.string()
            .required(t('First Name is required!'))
            .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        lastNameField: Yup.string()
            .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .max(25, t('Maximum 25 characters!')),
        emirateIDField: Yup.string()
            .required(t('Emirates ID is required!'))
            .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
            .matches(/^(\d+-?)+\d+$/, t("Emirates Id contains only numbers and hypen!"))
            .min(15, t('Minimum length is 15!'))
            .max(25, t('Maximum 25 characters!')),
        // mobileNumberField: Yup.string().required('Mobile Number is required').matches(
        //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     "Mobile Number is not valid"
        // ),
        mobileNumberField: Yup.string()
            .min(4, t('Mobile No should be greater then 4 digits!'))
            .max(16, t('Mobile No should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
            .required(t('Mobile No is required!')),
        emailIDField: Yup.string()
            .required(t('Email ID is required!'))
            .email(t('Enter a valid email!'))
            .matches(/^[^\s].*/, t('Email Id cannot start with a blankspace!')),
        // first_name: Yup.string()
        //     .min(3, (t('Minimum 3 characters')))
        //     .max(30, (t('Maximum 30 characters')))
        //     .required(t('First Name is required'))
        //     .test('filled', (t('First name cannot contain only blankspaces')), (value) => {
        //         if (value) {
        //             return (
        //                 value.trim().length !== 0
        //             )
        //         }
        //         return true
        //     })
        //     .matches(/^[^\s].*/, (t('First name cannot start with a blankspace')))
        //     .matches(/^[A-Za-z ]+$/, (t('Only alphabets allowed'))),
        // last_name: Yup.string()
        //     .min(3, (t('Minimum 3 characters')))
        //     .max(30, (t('Maximum 30 characters')))
        //     .required(t('Last name is required'))
        //     .test('filled', (t('Last name cannot contain only blankspaces')), (value) => {
        //         if (value) {
        //             return (
        //                 value.trim().length !== 0
        //             )
        //         }
        //         return true
        //     })
        //     .matches(/^[^\s].*/, (t('Last name cannot start with a blankspace')))
        //     .matches(/^[A-Za-z ]+$/, (t('Only alphabets allowed'))),
        // emirates_id: Yup.mixed()
        //     .required(t('Emirates ID image is required'))
        //     .test('type', (t('Only the following formats are acceptable: .jpeg, .jpg .pdf')), (value) => {
        //         if (value === 0) return true
        //         return (
        //             value &&
        //             (value.type === 'image/jpeg' ||
        //                 value.type === 'image/jpg' ||
        //                 value.type === 'application/pdf')
        //         )
        //     })
        //     .test('fileSize', (t('The file is too large, must be less than 2 mb')), (value) => {
        //         if (!value) return true
        //         return value?.size <= 2000000
        //     }),
        // email: Yup.string()
        //     .email(t('Wrong email format'))
        //     .min(3, (t('Minimum 3 symbols')))
        //     .max(50, (t('Maximum 50 symbols')))
        //     .required(t('Email is required')),
        // alternate_email: Yup.string()
        //     .email(t('Wrong email format'))
        //     .min(3, (t('Minimum 3 symbols')))
        //     .max(50, (t('Maximum 50 symbols'))),
        emergency_contact_name: Yup.string()
            .matches(/^[^\s].*/, t('Contact Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t('Minimum 3 characters'))
            .max(25, t('Maximum 25 characters!')),
        // .required(t('Emergency Contact name is required'))
        // .test('filled', (t('Emergency Contact name cannot contain only blankspaces')), (value) => {
        //     if (value) {
        //         return (
        //             value.trim().length !== 0
        //         )
        //     }
        //     return true
        // })
        // .matches(/^[^\s].*/, (t('Emergency Contact name cannot start with a blankspace')))
        // .matches(/^[A-Za-z ]+$/, (t('Only alphabets allowed'))),
        emergency_contact_no: Yup.string()
            .min(4, t('Emergency Contact Number should be greater then 4 digits!'))
            .max(16, t('Emergency Contact Number should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!')),
        alternate_no: Yup.string()
            .min(4, t('Alternate Contact Number should be greater then 4 digits!'))
            .max(16, t('Alternate Contact Number should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!')),
        // .required(t('Emergency Contact Number is required!')),
        //  Yup.string().required(t('Emergency Contact Number is required')),
        // present_address: Yup.string()
        //     .min(3, (t('Minimum 3 characters')))
        //     .max(100, (t('Maximum 100 characters')))
        //     .required(t('Present Address is required'))
        //     .test('filled', (t('Present Address cannot contain only blankspaces')), (value) => {
        //         if (value) {
        //             return (
        //                 value.trim().length !== 0
        //             )
        //         }
        //         return true
        //     })
        //     .matches(/^[^\s].*/, (t('Present Address cannot start with a blankspace')))
        //     .matches(/^[A-Za-z ]+$/, (t('Only alphabets allowed'))),
        // permanent_address: Yup.string()
        //     .min(3, (t('Minimum 3 characters')))
        //     .max(100, (t('Maximum 100 characters')))
        //     .required(t('Permanent Address is required'))
        //     .test('filled', (t('Permanent Address cannot contain only blankspaces')), (value) => {
        //         if (value) {
        //             return (
        //                 value.trim().length !== 0
        //             )
        //         }
        //         return true
        //     })
        //     .matches(/^[^\s].*/, (t('Permanent Address cannot start with a blankspace')))
        //     .matches(/^[A-Za-z ]+$/, (t('Only alphabets allowed'))),
        // photo: Yup.mixed()
        //     .required(t('Personal photo is required'))
        //     .test('type', (t('Only the following formats are acceptable: .jpeg, .jpg, .pdf')), (value) => {
        //         if (!value) return true
        //         return (
        //             value &&
        //             (value.type === 'image/jpeg' ||
        //                 value.type === 'image/jpg' ||
        //                 value.type === 'application/pdf')
        //         )
        //     })
        //     .test('fileSize', (t('The file is too large!')), (value) => {
        //         if (!value) return true
        //         return value?.size <= 2000000
        //     }),
    })

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: teamMemberSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            // setLoading(true)
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: true, error: false}))

            setTimeout(() => {
                const formData = new FormData()
                formData.append("first_name", values.firstNameField.trim());
                formData.append("last_name", values.lastNameField.trim());
                formData.append("email", values.emailIDField);
                formData.append("emirates_id", values.emirateIDField.trim());
                formData.append('phone', mobileCode + values.mobileNumberField)
                // formData.append('alternate_email', values.alternate_email)
                formData.append('alternate_no', values.alternate_no !== '' ? altContactCode + values.alternate_no : '')
                formData.append('emergency_contact_name', values.emergency_contact_name)
                formData.append('emergency_contact_no', values.emergency_contact_no !== '' ? emgContactCode + values.emergency_contact_no : '')
                // formData.append('present_address', values.present_address)
                // formData.append('permanent_address', values.permanent_address)
                // formData.append('photo', values.photo)
                formData.append('id_role', 'MT')

                createTeamMember(formData)
                    .then((response: any) => {
                        if (response.errorCode === 1)
                            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
                        else if (response.errorCode === 0) {
                            setPopupSuccessMsg(response.data?.msg)
                            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.data?.msg, isSubmitted: true}))
                            successRef.current.click()
                            setTimeout(() => {
                                formik.resetForm()
                                history.push('/mta-myteam/list/pending')
                            }, 1000)
                        } else
                            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                    })
                    .catch((e) => {
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                    })
            }, 1000)
        },
    })

    // const handleDisplayEmiratesIdDetails = (e: any) => {
    //     formik.setFieldTouched('emirates_id')
    //     emiratesIdInputRef.current?.files &&
    //         setUploadedEmiratesIdName(emiratesIdInputRef.current.files[0].name)
    //     formik.setFieldValue('emirates_id', e.currentTarget.files[0])
    // }

    // const handleDisplayFileDetails = (e: any) => {
    //     inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
    //     formik.setFieldTouched('photo')
    //     formik.setFieldValue('photo', e.currentTarget.files[0])
    // }

    const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
        setMobileCode(country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : '')
        formik.setFieldValue('mobileNumberField', phoneNumber)
        formik.setFieldTouched('mobileNumberField')
    }
    const handleEmergencyContactNumber = (status: any, phoneNumber: any, country: any) => {
        setEmgContactCode(country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : '')
        formik.setFieldValue('emergency_contact_no', phoneNumber)
        formik.setFieldTouched('emergency_contact_no')
    }
    const handleAlternateNumber = (status: any, phoneNumber: any, country: any) => {
        setAltContactCode(country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : '')
        formik.setFieldValue('alternate_no', phoneNumber)
        formik.setFieldTouched('alternate_no')
    }

    // const handlePhoneNumber = (phoneNumber: any) => {
    //     formik.setFieldValue('mobile', phoneNumber)
    //     formik.setFieldTouched('mobile')
    // }

    // const handleEmergencyContactNumber = (emergenceContactNo: any) => {
    //     formik.setFieldValue('emergency_contact_no', emergenceContactNo)
    //     formik.setFieldTouched('emergency_contact_no')
    // }

    // const handleAlternateNumber = (alternateNo: any) => {
    //     formik.setFieldValue('alternate_no', alternateNo)
    //     formik.setFieldTouched('alternate_no')
    // }

    return (
        <div className="row access-cd-tabs align-items-center">
            <div className="col-12">
                <h4 className="card-title font-18 text-black mb-0">
                    <span className='c-pointer' onClick={() => { history.goBack() }} >
                        <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                    </span>
                    {/* <Link to={'/mta-myteam/list/pending'} className=''>
                        <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                    </Link> */}
                    {t("Add Team Member")}
                </h4>
            </div>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            >
                <div className="col-12 mt-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="col-12">
                                <div className="row form-add-design">
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("First Name")}
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <input
                                                {...formik.getFieldProps('firstNameField')}
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("First Name")}
                                            />
                                            {formik.touched.firstNameField && formik.errors.firstNameField ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.firstNameField}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Last Name")}
                                                {/* <span className="text-danger"> * </span> */}
                                            </label>
                                            <input
                                                {...formik.getFieldProps('lastNameField')}
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("Last Name")}
                                            />
                                            {formik.touched.lastNameField && formik.errors.lastNameField ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.lastNameField}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Emirates ID")}
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <input
                                                {...formik.getFieldProps('emirateIDField')}
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("Emirates ID")}
                                            />
                                            {formik.touched.emirateIDField && formik.errors.emirateIDField ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.emirateIDField}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10 upload-input">
                                            <label className="form-label fw-bolder"> {t("Emirates ID")}<span className='text-danger'>*</span></label>
                                            <div className="attachment-div">
                                                <input
                                                    type='file'
                                                    ref={emiratesIdInputRef}
                                                    className="form-control fw-bolder form-control-solid"
                                                    onChange={(e) => handleDisplayEmiratesIdDetails(e)}
                                                    accept='.jpeg,.jpg,application/pdf'
                                                />
                                                <button
                                                    type='button'
                                                    onClick={handleEmiratesIdUpload}
                                                    className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdName ? 'primary' : 'primary'
                                                        }`}
                                                >
                                                    <span>
                                                        {uploadedEmiratesIdName ? (
                                                            uploadedEmiratesIdName
                                                        ) : (
                                                            <span>
                                                                {t("Upload")}{' '}
                                                                <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                                            </span>
                                                        )}{' '}
                                                    </span>
                                                </button>
                                            </div>
                                            {formik.touched.emirates_id && formik.errors.emirates_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.emirates_id}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Mobile Number")}
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <IntlTelInput
                                                preferredCountries={['ae', 'in', 'us']}
                                                containerClassName='intl-tel-input w-100'
                                                separateDialCode={true}
                                                fieldName='mobile_no'
                                                value={formik.values.mobileNumberField}
                                                inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                                onPhoneNumberChange={handlePhoneNumber}
                                                onPhoneNumberBlur={handlePhoneNumber}
                                                placeholder={t('Mobile No.')}
                                            />
                                            {/* <PhoneInput
                                                        country={'ae'}
                                                        preferredCountries={['ae']}
                                                        // onlyCountries={['ae']}
                                                        // priority={{ ae: 0, in: 1, us: 2 }}
                                                        value={formik.values.mobileNumberField}
                                                        onChange={onValueChange}
                                                        enableSearch={true}
                                                        enableAreaCodeStretch={true}
                                                        inputClass={"text-black"}
                                                        placeholder={t('Mobile Number')}
                                                    /> */}
                                            {/* <input
                                                        {...formik.getFieldProps('mobileNumberField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder=""
                                                    /> */}
                                            {formik.touched.mobileNumberField && formik.errors.mobileNumberField ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.mobileNumberField}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Email ID")}
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <input
                                                {...formik.getFieldProps('emailIDField')}
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("Email ID")}
                                            />
                                            {formik.touched.emailIDField && formik.errors.emailIDField ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.emailIDField}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/*                                     
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Alternate Email ID")}</label>
                                            <input
                                                type="email"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("Alternate Email ID")}
                                                {...formik.getFieldProps('email')}
                                            />
                                            {formik.touched.alternate_email && formik.errors.alternate_email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.alternate_email}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Mobile Number")}<span className='text-danger'>*</span></label>
                                            <PhoneInput
                                                country={'ae'}
                                                preferredCountries={['ae', 'in', 'us']}
                                                onlyCountries={['ae', 'in', 'us']}
                                                priority={{ ae: 0, in: 1, us: 2 }}
                                                value={formik.values.mobile}
                                                onChange={handlePhoneNumber}
                                                enableSearch={true}
                                                enableAreaCodeStretch={true}
                                                inputClass={"text-black"}
                                            />
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.mobile}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Alternate Contact No.")}</label>
                                            <IntlTelInput
                                                preferredCountries={['ae', 'in', 'us']}
                                                containerClassName='intl-tel-input w-100'
                                                separateDialCode={true}
                                                fieldName='alternate_no'
                                                value={formik.values.alternate_no}
                                                inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                                onPhoneNumberChange={handleAlternateNumber}
                                                onPhoneNumberBlur={handleAlternateNumber}
                                                placeholder={t('Alternate Contact No.')}
                                            />
                                            {/* <PhoneInput
                                                country={'ae'}
                                                preferredCountries={['ae', 'in', 'us']}
                                                onlyCountries={['ae', 'in', 'us']}
                                                priority={{ ae: 0, in: 1, us: 2 }}
                                                value={formik.values.alternate_no}
                                                onChange={handleAlternateNumber}
                                                enableSearch={true}
                                                enableAreaCodeStretch={true}
                                                inputClass={"text-black"}
                                            /> */}
                                            {formik.touched.alternate_no && formik.errors.alternate_no && (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.alternate_no}</small>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Emergency Contact Name")}</label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("Emergency Contact Name")}
                                                {...formik.getFieldProps('emergency_contact_name')}
                                            />
                                            {formik.touched.emergency_contact_name && formik.errors.emergency_contact_name && (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.emergency_contact_name}</small>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Emergency Contact Number")}</label>
                                            <IntlTelInput
                                                preferredCountries={['ae', 'in', 'us']}
                                                containerClassName='intl-tel-input w-100'
                                                separateDialCode={true}
                                                fieldName='emergency_contact_no'
                                                value={formik.values.emergency_contact_no}
                                                inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                                onPhoneNumberChange={handleEmergencyContactNumber}
                                                onPhoneNumberBlur={handleEmergencyContactNumber}
                                                placeholder={t('Emergency Contact Number')}
                                            />
                                            {/* <PhoneInput
                                                country={'ae'}
                                                preferredCountries={['ae', 'in', 'us']}
                                                onlyCountries={['ae', 'in', 'us']}
                                                priority={{ ae: 0, in: 1, us: 2 }}
                                                value={formik.values.emergency_contact_no}
                                                onChange={handleEmergencyContactNumber}
                                                enableSearch={true}
                                                enableAreaCodeStretch={true}
                                                inputClass={"text-black"}
                                            /> */}
                                            {formik.touched.emergency_contact_no && formik.errors.emergency_contact_no && (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.emergency_contact_no}</small>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Present Address")}<span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("Present Address")}
                                                {...formik.getFieldProps('present_address')}
                                            />
                                            {formik.touched.present_address && formik.errors.present_address && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.present_address}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Permanent Address")}<span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder={t("Permanent Address")}
                                                {...formik.getFieldProps('permanent_address')}
                                            />
                                            {formik.touched.permanent_address && formik.errors.permanent_address && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.permanent_address}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                        <div className="mb-10 upload-input">
                                            <label className="form-label fw-bolder"> {t("Upload Photo")}<span className='text-danger'>*</span></label>
                                            <div className="attachment-div">
                                                <input
                                                    ref={inputRef}
                                                    onChange={(e) => handleDisplayFileDetails(e)}
                                                    className='d-none'
                                                    type='file'
                                                    onBlur={formik.handleBlur}
                                                />
                                                <button
                                                    type='button'
                                                    onClick={handleUpload}
                                                    className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                                                        }`}
                                                >
                                                    <span>
                                                        {uploadedFileName ? (
                                                            uploadedFileName
                                                        ) : (
                                                            <span>
                                                                {t("Upload")}{' '}
                                                                <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                                            </span>
                                                        )}{' '}
                                                    </span>
                                                </button>
                                            </div>
                                            {formik.touched.photo && formik.errors.photo && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.photo}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="col-12 text-end mt-5">
                                        {/* <Link to={'/mta-myteam/list/pending'} className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'>
                                            {t("Cancel")}
                                        </Link> */}
                                        <button
                                            type='button'
                                            className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'
                                            disabled={submitStatus.loading}
                                            onClick={() => {
                                                formik.resetForm()
                                                history.goBack()
                                            }} >{t("Cancel")}</button>

                                        <button
                                            type='submit'
                                            className='btn btn-primary fw-bold px-10 py-3'
                                            disabled={submitStatus.isSubmitted || submitStatus.loading }
                                        >
                                            {submitStatus.loading ? 
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    {t("Please wait...")}{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                                :
                                                <span className='indicator-label'>{t("Add")}</span>
                                            }
                                        </button>
                                    </div>

                                    {/* <div className="modal fade verification-modal" aria-hidden='true' id="verificationModal">
                                        <div className="modal-dialog modal-sm modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <div className="text-center verification-box">
                                                        <img src={ErrorMark} alt="error" />
                                                        <h3 className="text-error mb-3">{t("Error")}</h3>
                                                        <p className="font-12 mb-8"></p>
                                                        <button type="button" className="btn btn-primary text-uppercase w-100" data-bs-dismiss="modal">{t("OK")}</button>
                                                    </div>
                                                    <div className="text-center verification-box">
                                                        <img src={CheckMark} alt="check-mark" />
                                                        <h3 className="text-primary mb-3">{t("Done")}</h3>
                                                        <p className="font-12 mb-8">{t("Your Profile is under verification.")}
                                                        </p>
                                                        <p className="font-12 mb-8">{t("We are currently validating your profile. You will receive an Email once its approved.")}</p>
                                                        <button type="button" className="btn btn-primary text-uppercase w-100" data-bs-dismiss="modal">{t("Continue")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <LoadingSubmitAlert submitStatus={submitStatus}/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {/* <p
                ref={successRef}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
            >
            </p> */}
            <span
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='d-none'
              ></span>
        </div>
    );
}