import React, {useState, useEffect} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import InactiveSlot from '../../../../assets/images/parking-inactive.png'
import ActiveSlot from '../../../../assets/images/parking-active.svg'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { ParkingSearchPopup } from '../ParkingSearch/ParkingSearchPopup'
// import { getUserBuildingCommunityList } from '../../../../utils/makeRequest'
import { CustomLoaderCompact, NoDataFound1 } from '../../../../constants/generics/customActios'
import { RootState } from '../../../../constants'


export function ParkingSlots({
  setSelectedParkingSlot,
  parkingSlots,
  selectedBuilding, setSelectedBuilding,
  selectedBlock, setSelectedBlock,
  loading,
}: any) {
  const { t } = useTranslation();
  const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)

  const history = useHistory()
  const [visualParkingSlots, setVisualParkingSlots] = useState<any>([])

  useEffect(()=>{
    const initBuilding = selectedBuilding && selectedBuilding !== '' ? selectedBuilding
      : listBuilding?.[0]?.id ? listBuilding[0].id : '';
    setSelectedBuilding(initBuilding)
    formik.setFieldValue('buildingLocation', initBuilding)
  },[])

  const initialValues = {
    parking_slot: selectedBlock? selectedBlock :'',
    parking_number: '',
    buildingLocation: selectedBuilding ? selectedBuilding : '',
  }
  const bookParkingSchema = Yup.object().shape({
    parking_slot: Yup.string().required(t('Please select a Parking Block!')),
    parking_number: Yup.string().required(t('Parking Number is required!')),
    buildingLocation: Yup.string().required(t('Select a building location!')),
  })

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: bookParkingSchema,
    onSubmit: (values) => {
      history.push('/parking/add-parking', {
        parking_number: values.parking_number,
      })
    },
  })

  useEffect(() => {
    setVisualParkingSlots(
      parkingSlots?.parking_slot?.filter((slot: any) => slot.blockId === selectedBlock)
    )
  }, [selectedBlock, parkingSlots])

  useEffect(() => {
    const availableIds = parkingSlots?.block_name?.map((el:any)=>el.id);
    if (!(selectedBlock !== '' && availableIds?.includes(selectedBlock)) && parkingSlots?.block_name?.length) {
      setSelectedBlock(parkingSlots?.block_name[0]?.id)
      formik.setFieldValue('parking_slot', parkingSlots?.block_name[0]?.id)
    }
  }, [parkingSlots])

  const handlParkingBlock = (val: any) => {
    const selectedData = parkingSlots?.block_name?.find((block: any) => block.id == val)
    setSelectedBlock( selectedData?.id )
    formik.setFieldValue('parking_slot', val)
  }

  const handleBuildingSelect = (val: any) => {
    setSelectedBuilding(val)
    setSelectedBlock('')
    formik.setFieldValue('buildingLocation', val)
    formik.setFieldValue('property_id', '')
    formik.setFieldTouched('property_id', false)
  }

  const VisualParkingSlotsList = visualParkingSlots?.map((slot: any, selectedSlot: any) => (
    <div className='col text-center parking-slot-sec' key={slot.id}>
      {slot.status === 0 ? (
        <Link
          to={{
            pathname: '/parking/add-parking',
            state: {parking_number: slot, selectedSlot: selectedSlot},
          }}
        >
          <img src={InactiveSlot} className='img-fluid mb-4' alt='Slot Not Booked' />
          <h6 className='text-black fw-bold font-12 mb-4 ms-0'>{slot.slotNumber}</h6>
        </Link>
      ) : (
        <button
          data-bs-target='#parked-slot-popup-modal'
          data-bs-toggle='modal'
          className='btn border-0 text-center pt-0'
          type='button'
          onClick={() => setSelectedParkingSlot(slot)}
        >
          {/* // <> */}
          <img src={ActiveSlot} className='img-fluid mb-4 active' alt='Slot Booked' />
          <h6 className='text-black fw-bold font-12 mb-4 ms-0'>{slot.slotNumber}</h6>
          {/* </> */}
        </button>
        // <Link
        //   data-bs-toggle='modal'
        //   to={{pathname: '#parked-slot-popup-modal', state: {parking_number: slot}}}
        //   role='button'
        // >
        //   <img src={ActiveSlot} className='img-fluid mb-4' alt='Slot Booked' />
        //   <h6 className='text-black fw-bold font-12 mb-4 ms-0'>{slot.slotNumber}</h6>
        // </Link>
      )}
    </div>
  ))
  
  const {Option} = Select  
  
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-4'>
            <div className='card-body px-9 pt-9 pb-5'>
              <div className='row'>
                <div className='col-12'>
                  <ParkingSearchPopup parkingSlots={parkingSlots}/>
                </div>
              </div>
              <div className=''>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='accessCardRequestForm'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design'>
                    <div className='col-md-4 col-lg-4 col-sm-6 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t("Building Location")}
                          <span className='text-danger'> * </span>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select a Building')}
                          optionFilterProp='children'
                          value={
                            formik.values.buildingLocation !== '' ? formik.values.buildingLocation : null
                          }
                          onChange={handleBuildingSelect}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {listBuilding?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.buildingLocation && formik.errors.buildingLocation && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.buildingLocation}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-md-4 col-lg-4 col-sm-6 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t("Parking Block")} <span className='text-danger'> * </span>{' '}
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select a Parking')}
                          optionFilterProp='children'
                          value={ formik.values.parking_slot !== '' ? formik.values.parking_slot : null }
                          onChange={handlParkingBlock}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {parkingSlots?.block_name?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.parking_slot && formik.errors.parking_slot && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.parking_slot}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-6 col-12'>
                      <div className='mb-7'>
                        <div className='d-flex float-end mt-8'>
                          <button
                            type='button'
                            className='btn btn-primary fw-bold px-5 py-3'
                            data-bs-toggle='modal'
                            data-bs-target='#parkingsearchPopupmodal'
                            disabled={!parkingSlots?.parking_slot?.length}
                          >
                            <i className='fas fa-search'></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='row mt-5'>
                  <div className='col-md-12'>
                    <div className='text-end mb-10 justify-content-end'>
                      <span className='avail-parking me-10'>{t("Available")}</span>
                      <span className='booked-parking'>{t("Booked")}</span>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 '>
                    { loading ? <CustomLoaderCompact /> :
                      <div className='row'>{visualParkingSlots?.length ? VisualParkingSlotsList : <NoDataFound1 />}</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
