import React, {useState, useEffect} from 'react'
import TeamIcon from '../../../../assets/images/mta_myteam_active.png'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export function RecentTechnitianApprovalCard({approval}:any) {
    const { t } = useTranslation();

    return (
        <div>
            <div className='card mt-4'>
                <div className='card-body px-5 py-5 px-md-8 py-md-7'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='mb-7'>
                                <img src={ TeamIcon } className='img-fluid me-5 d-inline' />
                                <span className='card-title'>{t("Technician Approval")}</span>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-2">
                                    <p className="mb-0 text-muted">{t("Name")}</p>
                                    <p className="mb-0 text-dark fcs-dashboard-request fcs-unit-number">{approval?.details?.firstName + ' ' + approval?.details?.lastName}</p>
                                </div> 
                            </div>
                            <div className="row">
                                <div className="col-sm-12 pe-0">
                                    <p className="mb-0 text-muted">{''}</p>
                                    <p className="mb-0 text-dark font-12 fcs-unit-number">{''}</p>
                                </div>
                                {/* <div className="col-sm-6 pe-0">
                                    <p className="mb-0 text-muted">Date/Time</p>
                                    <p className="mb-0 text-dark font-12 fcs-unit-number">
                                        {moment(recent.date).format("DD-MM-YYYY")} {' '}
                                        {recent.issueCategory === "Clearance" ? recent?.objectDetails?.referenceDetails?.time_slot : formatTimeMeridiem(recent.time)}

                                    </p>
                                </div> */}
                            </div>
                            {/* <h6 className='font-14 fw-normal text-black'>
                                Action Required
                            </h6> */}
                        </div>
                        <div className='col-lg-4 text-end mt-auto'>
                            <Link
                                to={{
                                    'pathname': '/fmteam-viewDetails/technician',
                                    'state': {'approval': approval},
                                }}
                                className='btn btn-primary rounded w-100 py-2 mt-13'
                            >
                            {t("View")}
                            </Link>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    )
}