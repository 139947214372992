import React, { useState, useEffect, useRef } from 'react';
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import NoImage from "../../../assets/images/House.png";
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { formatDate, formatDateTimeMeridiem, formatTimeMeridiem } from '../../../constants/generics/dateTimeFormater';
import { useTranslation } from 'react-i18next';
import { getFacilityMaintenanceRequests } from '../../../constants/axios/apis';
import { CustomLoaderCompact } from '../../../constants/generics/customActios';
import { ImageViewerWithPopup } from '../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';

export function FacilityMaintenanceView() {
    const { t } = useTranslation();
    const history = useHistory();

    let params: any = {};
    params = useLocation();
    let maintenanceID = ''
    if(params?.state?.maintenanceDetail?.id)
        maintenanceID = params.state.maintenanceDetail.id
    // const { maintenanceDetail } = params.state;
    // console.log(params.state, "params.state");

    const [loading, setLoading] = useState<any>(true);
    const [maintenanceDetail, setMaintenanceDetail] = useState<any>()
    useEffect(() => {
        setLoading(true)
        setMaintenanceDetail({})
        getFacilityMaintenanceRequests(`?id=${maintenanceID}`).then((response: any) => {
            if(response.data && Array.isArray(response.data) && response.data?.[0])
                setMaintenanceDetail(response.data[0]);
            setLoading(false);
        }).catch((e)=>{

        })
    }, [maintenanceID]);

    const image = maintenanceDetail?.attachments_json?.photo ? maintenanceDetail?.attachments_json.photo : NoImage;
    // const building_img = maintenanceDetail?.buildingIcon && maintenanceDetail?.buildingIcon.length  ? maintenanceDetail?.buildingIcon : NoImage;
    const building_img = maintenanceDetail?.communityLogo && maintenanceDetail?.communityLogo.length  ? maintenanceDetail?.communityLogo : NoImage;

    return (
        <div className="com-card-section myunit-page">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        {/* <Link to={'/facility-maintenance/list/' + (maintenanceDetail?.status === 0 ? 'open' : 'resolved')} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </Link> */}
                        <span className='c-pointer' onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        <span className="text-black"> {t("Maintenance Details")} </span>
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body p-7">
                        {
                            loading ? <CustomLoaderCompact />:
                            <>
                                <div className="row">
                                    <div className="col-md-4 col-lg-3">
                                        <div className='approval-move mb-5'>
                                            <img src={building_img} className="img-fluid rounded-5" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-lg-9 col-12">
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <span className="text-black fw-bolder font-15 d-block mb-4">
                                                    {t("Ticket")} - {maintenanceDetail?.ticket ? maintenanceDetail.ticket : "-"}
                                                </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Category Type")}</h6>
                                                <span className="text-black fw-bold font-15"> {maintenanceDetail?.category ? maintenanceDetail.category : "-"} </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Issue Category")} </h6>
                                                <span className="text-black fw-bold font-15"> {maintenanceDetail?.categoryType ? maintenanceDetail.categoryType : "-"} </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <div className="">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Issue Type")} </h6>
                                                    <span className="text-black fw-bold font-15"> {maintenanceDetail?.issue ? maintenanceDetail.issue : "-"} </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Building")} </h6>
                                                <span className="text-black fw-bold font-15"> {maintenanceDetail?.building ? maintenanceDetail.building : "-"} </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Unit")} </h6>
                                                <span className="text-black fw-bold font-15"> {maintenanceDetail?.property_code ? maintenanceDetail.property_code : "-"} </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Precise Location")} </h6>
                                                <span className="text-black fw-bold font-15"> {maintenanceDetail?.preciseLocationName ? maintenanceDetail.preciseLocationName : "-"} </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Appointment Date & Time")} </h6>
                                                <span className="text-black fw-bold font-15"> {(maintenanceDetail?.appointmentDate ? formatDate(maintenanceDetail?.appointmentDate) : '-') + ' ' + (maintenanceDetail?.time ? formatTimeMeridiem(maintenanceDetail?.time) : '')} </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Created At")} </h6>
                                                <span className="text-black fw-bold font-15"> {formatDateTimeMeridiem(maintenanceDetail?.created)} </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-5">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Status")} </h6>
                                                <span className="text-black fw-bold font-15"> {maintenanceDetail?.status === 0 ? 'Open' : 'Resolved'} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 col-lg-3">
                                        <div className="mb-5 upload-input">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Attached Image")}</h6>
                                            <ImageViewerWithPopup imageFile={image} noImage={image === NoImage} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-lg-9 col-12">
                                        <h6 className="text-theme-gray mb-3 fw-normal font-14">{t("Description")} </h6>
                                        <span className="text-black fw-bold font-15"> {maintenanceDetail?.description ? maintenanceDetail.description : "-"} </span>
                                    </div>
                                </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}