import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../components/partials/content/CompactPagination/CompactPagination'


const paymentData = [{
    "sl": "1",
    "ContactName": "Radha Krishna",
    "Email": "radha.k@exalogic.co	",
    "Contact":"-",
    "Ref": "REF-UN-1689663797	",
    "title": "Commercial Plot 2",
    "type": "Apartment",
    "community": "The Sloan by Belgravia Heights",
    "Building": "Ajman One Tower 1",
    "transaction": "040030558098",
    "paymentmode": "	Online Payment",
    "price": "	2000.00",
    "installments": "-",
    "address": "139-A, 1st Floor Janapriya apartments saroornagar Hyderabad Telangana Dubai",
    "status": "Success",
    "createdDate": "09/07/2021"


},
{
    "sl": "1",
    "ContactName": "Radha Krishna",
    "Email": "radha.k@exalogic.co	",
    "Contact":"-",
    "Ref": "REF-UN-1689663797	",
    "title": "Commercial Plot 2",
    "type": "Apartment",
    "community": "The Sloan by Belgravia Heights",
    "Building": "Ajman One Tower 1",
    "transaction": "040030558098",
    "paymentmode": "	Online Payment",
    "price": "	2000.00",
    "installments": "-",
    "address": "139-A, 1st Floor Janapriya apartments saroornagar Hyderabad Telangana Dubai",
    "status": "Success",
    "createdDate": "09/07/2021"
},
{
    "sl": "1",
    "ContactName": "Radha Krishna",
    "Email": "radha.k@exalogic.co	",
    "Contact":"-",
    "Ref": "REF-UN-1689663797	",
    "title": "Commercial Plot 2",
    "type": "Apartment",
    "community": "The Sloan by Belgravia Heights",
    "Building": "Ajman One Tower 1",
    "transaction": "040030558098",
    "paymentmode": "	Online Payment",
    "price": "	2000.00",
    "installments": "-",
    "address": "139-A, 1st Floor Janapriya apartments saroornagar Hyderabad Telangana Dubai",
    "status": "Success",
    "createdDate": "09/07/2021"
},
{
    "sl": "1",
    "ContactName": "Radha Krishna",
    "Email": "radha.k@exalogic.co	",
    "Contact":"-",
    "Ref": "REF-UN-1689663797	",
    "title": "Commercial Plot 2",
    "type": "Apartment",
    "community": "The Sloan by Belgravia Heights",
    "Building": "Ajman One Tower 1",
    "transaction": "040030558098",
    "paymentmode": "	Online Payment",
    "price": "	2000.00",
    "installments": "-",
    "address": "139-A, 1st Floor Janapriya apartments saroornagar Hyderabad Telangana Dubai",
    "status": "Success",
    "createdDate": "09/07/2021"
},
{
    "sl": "1",
    "ContactName": "Radha Krishna",
    "Email": "radha.k@exalogic.co	",
    "Contact":"-",
    "Ref": "REF-UN-1689663797	",
    "title": "Commercial Plot 2",
    "type": "Apartment",
    "community": "The Sloan by Belgravia Heights",
    "Building": "Ajman One Tower 1",
    "transaction": "040030558098",
    "paymentmode": "	Online Payment",
    "price": "	2000.00",
    "installments": "-",
    "address": "139-A, 1st Floor Janapriya apartments saroornagar Hyderabad Telangana Dubai",
    "status": "Success",
    "createdDate": "09/07/2021"
},
{
    "sl": "1",
    "ContactName": "Radha Krishna",
    "Email": "radha.k@exalogic.co	",
    "Contact":"-",
    "Ref": "REF-UN-1689663797	",
    "title": "Commercial Plot 2",
    "type": "Apartment",
    "community": "The Sloan by Belgravia Heights",
    "Building": "Ajman One Tower 1",
    "transaction": "040030558098",
    "paymentmode": "	Online Payment",
    "price": "	2000.00",
    "installments": "-",
    "address": "139-A, 1st Floor Janapriya apartments saroornagar Hyderabad Telangana Dubai",
    "status": "Success",
    "createdDate": "09/07/2021"
},
];

export function PaymentHistoryList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col-6">
                    <h3 className=''>Payment History</h3>
                </div>
                <div className="col-6 text-end">
                <button className='btn btn-primary fw-bold px-10 py-3 me-3'>
                    Reset
                </button>
                </div>
            </div>
            <div className="card mb-6">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Contact Name  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Contact Name" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Contact Email  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Contact Email" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Contact Number  </label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Contact Number" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Property Ref</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Property Ref" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">Property Title</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Property Title" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">PROPERTY TYPE</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option >Please Select </option>
                                    <option>Villa</option>
                                    <option>Townhouse</option>
                                    <option>Bunglsow</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Community</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Community" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Building</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Building" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Transaction Number</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Transaction Number" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder"> Price(AED)</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Price(AED)" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">installment</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option >Please Select </option>
                                    <option>2 payment</option>
                                    <option>8 payment</option>
                                    <option>1 Payment</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                    <div className="col"> </div>
                    <div className="col-auto">
                        <button className="btn btn-primary radius-10 font-weight-semibold px-4 font-10 text-uppercase property-download-btn btn-sm">Download</button>
                    </div>
                </div>
            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-2 d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='row'>
                    <div className='col-12'>
                        <div className=''>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='table-crm-payment'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='table-responsive'>
                                                        <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                            <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                <tr>
                                                                    <th>Sl. No.</th>
                                                                    <th> Contact Name</th>
                                                                    <th> Contact Email </th>
                                                                    <th> Contact Number </th>
                                                                    <th> Property Ref</th>
                                                                    <th> Property Title </th>
                                                                    <th> Property Type </th>
                                                                    <th> Community </th>
                                                                    <th> Building </th>
                                                                    <th> Transaction Number </th>
                                                                    <th> payment Mode </th>
                                                                    <th> Price (AED)</th>
                                                                    <th> Installments</th>
                                                                    <th> Building Adrdess</th>
                                                                    <th> Status</th>
                                                                    <th> Created Date</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paymentData.length > 0 ? paymentData.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td className="text-warning">{item.sl}</td>
                                                                        <td>{item.ContactName}</td>
                                                                        <td>{item.Email}</td>
                                                                        <td>{item.Contact}</td>
                                                                        <td>{item.Ref}</td>
                                                                        <td>{item.title}</td>
                                                                        <td>{item.type}</td>
                                                                        <td>{item.community}</td>
                                                                        <td>{item.Building}</td>
                                                                        <td>{item.transaction}</td>
                                                                        <td>{item.paymentmode}</td>
                                                                        <td>{item.price}</td>
                                                                        <td>{item.installments}</td>
                                                                        <td>{item.address}</td>
                                                                        <td>{item.status}</td>
                                                                        <td>{item.createdDate}</td>
                                                                        
                                                                    </tr>
                                                                )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={paymentData.length}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
