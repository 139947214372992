import { Line } from 'react-chartjs-2';

export function RentingTrend({ chartData }: any) {

    const data = {
        labels: ['Q1/2019', 'Q2/2020', 'Q3/2021','Q4/2022'],
        datasets: [
            {
              label: 'Converted',
              data: [0,20,30,40,50],
              borderColor: '#F3B521',
              backgroundColor: '#F3B521',
            },
            {
              label: 'Web Traffic',
              data: [0,30,40,50,60],
              borderColor: '#000000',
              backgroundColor: '#000000',
            },
          ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
        },

        maintainAspectRatio: true,
    };
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="Doughnut-Chart text-center position-relative top-0" id="unitsumary">
                    <div>                                                 
                        <Line data={data} options={options} width={30} height={30} />
                        </div>
                    </div>                                                
                </div>
            </div>
        </div>
    );
}