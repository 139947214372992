/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../helpers'
import { Dropdown1, ChatInner } from '../../../../partials'
import { Link } from 'react-router-dom';
import { DefaultAvatar } from './../../../../../constants/generics/customActios';

const ChatWindow = ({ activeChat }: any) => {
    let userEmail = ''
    let userRole: number = 0
    let userName = ''
    let userImg = ''
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
        userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
        userImg = JSON.parse(userDetail).profile_img
    }
    return (
        <div className="">
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex mb-4">
                        <span className="chat-window-pic">
                            {/* <img alt='Pic' src={toAbsoluteUrl('/media/avatars/150-15.jpg')} /> */}
                            {userImg?.length > 0 ?
                                (<img src={userImg} alt="user-profile" />)
                                : (<DefaultAvatar classname={"profile-chat"} />)}
                        </span>
                        <span className="chat-window-name font-14 ps-5">
                            {userName}
                        </span>
                        {/* <span className="chat-window-det ps-5">
                            <Link to={'/profile'} className="text-primary fw-bold">View Profile</Link>
                        </span> */}
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className='card' id='kt_chat_messenger'>
                            {/*<div className='card-header' id='kt_chat_messenger_header'>*/}
                            {/*<div className='card-title'>*/}
                            {/*<div className='symbol-group symbol-hover'></div>*/}
                            {/*<div className='d-flex justify-content-center flex-column me-3'>*/}
                            {/*<a*/}
                            {/*href='#'*/}
                            {/*className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'*/}
                            {/*>*/}
                            {/*Brian Cox*/}
                            {/*</a>*/}

                            {/*<div className='mb-0 lh-1'>*/}
                            {/*<span className='badge badge-success badge-circle w-10px h-10px me-1'></span>*/}
                            {/*<span className='fs-7 fw-bold text-gray-400'>Active</span>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}

                            {/*<div className='card-toolbar'>*/}
                            {/*<div className='me-n3'>*/}
                            {/*<button*/}
                            {/*className='btn btn-sm btn-icon btn-active-light-primary'*/}
                            {/*data-kt-menu-trigger='click'*/}
                            {/*data-kt-menu-placement='bottom-end'*/}
                            {/*data-kt-menu-flip='top-end'*/}
                            {/*>*/}
                            {/*<i className='bi bi-three-dots fs-2'></i>*/}
                            {/*</button>*/}
                            {/*<Dropdown1 />*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            <ChatInner activeChat={activeChat} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export { ChatWindow }
