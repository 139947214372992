import React, { FC, useState, useEffect } from 'react'
// import { VerticalGraph } from '../../VerticalGraph';
// import Loader from '../../../../components/Loader';
// import { VerticalGraph } from '../../../../components/layout/components/VerticalGraph'
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
// import { RootState } from '../../../../constants'
// import { useSelector } from 'react-redux'
// import { geFinanceReports } from '../../../../constants/axios/apis'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(ChartDataLabels);

export const options = {
    showTooltips: false,
    // hover: {mode: null},
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: '',
            padding: {
                top: 10,
                bottom: 30
            },
            color: '#A6A6A6',
            font: {
                size: 14,
                family: "'Poppins', sans-serif",
                lineHeight: 0.5,
            },
        },
    },

    layout: {
        padding: {
            left: 0
        }
    },
    responsive: true,

    scales: {
        x: {
            stacked: true,
            grid: {
                display: false,
                drawBorder: false,
                zeroLineColor: 'transparent'
            },
            ticks: {
                color: 'rgb(0, 0, 0)',
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                    weight: '500',
                },

            },
        },
        y: {
            stacked: true,
            grid: {
                display: false,
                drawBorder: false,
                zeroLineColor: 'transparent'
            },
            ticks: {
                color: 'rgb(0, 0, 0)',
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                    weight: '500',
                },

            },
        },
    },
};
export const ReportGraph: FC<any> = ({ reportState, setReportState, reportType }) => {
    const { t } = useTranslation();

    const { loading, response: reports, error } = reportState;

    

    if (!reportState || !reportState.response || Object.keys(reportState.response).length === 0)
        return <></>

    const reportValues = Object.values(reports)
    let maxVal = 10
    reportValues?.forEach((el:any)=>{
        if(el >maxVal)
        maxVal = el
    })
    const data = {
        labels: Object?.keys(reports).map(string => string.slice(0, 3)),
        blue: {
            dataSet: reportValues
        },
        white: {
            dataSet: Object.values(reports).map(function (value: any) {
                return maxVal - value;
            })
        }
    };

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        {t("Revenue")}
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card rounded-10">
                        <div className="card-body px-8 py-6">
                            <div className="inspection-chart">
                                <Bar
                                    options={options}
                                    data={{
                                        labels: data.labels,
                                        datasets: [
                                            {
                                                datalabels: {
                                                    color: "#EAEFF5",
                                                    font: {
                                                        size: 16,
                                                        family: "'Poppins', sans-serif",
                                                        lineHeight: 0.5,
                                                    },
                                                },
                                                label: "",
                                                backgroundColor: '#002C6A',
                                                barThickness: 55,
                                                maxBarThickness: 55,
                                                borderRadius: {
                                                    bottomRight: 5,
                                                    bottomLeft: 5,
                                                    topRight: 5,
                                                    topLeft: 5,
                                                },
                                                data: data.blue.dataSet //From API
                                            },
                                            {
                                                datalabels: {
                                                    color: "#EAEFF5",
                                                    font: {
                                                        size: 16,
                                                        family: "'Poppins', sans-serif",
                                                        lineHeight: 0.5,
                                                    },
                                                },
                                                label: "",
                                                backgroundColor: '#EAEFF5',
                                                barThickness: 55,
                                                maxBarThickness: 55,
                                                borderRadius: {
                                                    bottomRight: 0,
                                                    bottomLeft: 0,
                                                    topRight: 5,
                                                    topLeft: 5,
                                                },
                                                data: data.white.dataSet //From API
                                            }
                                        ]
                                    }}
                                    height={100} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}