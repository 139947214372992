import React, { useState, useEffect, useRef } from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom'
import LeftArrow from "../../../../../../assets/images/Left_Arrow.png";

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    getServiceRequestsCategories,
    getPreciseLocations,
    createClearanceCharge,
} from '../../../../../../constants/axios/apis'
import { Signature } from '../MoveOutInspectionStart/Signature';
import { useTranslation } from 'react-i18next';
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler';


export const UpdateInspection:React.FC<any> = ({}) => {
    const { t } = useTranslation();

    const history = useHistory();
    let params: any = {};
    params = useLocation(); 
    const { clearanceChargeId, workOrder, addedDataList } = params.state;

    const [serviceCategoryData, setServiceCategories] = useState<any>()
    const [serviceCategoryID, setServiceCategoryID] = useState<any>()
    const [precise_locations, setPreciseLocations] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [sigError, setSigError] = useState(false)
    const [termError, setTermError] = useState(false)
    const [inspectionError, setInspectionError] = useState('')
    const [submitError, setSubmitError] = useState('')
    const [acceptTerms, setAcceptTerms] = useState(false)
    
    const [selectedInspectionData, setSelectedInspection] = useState<any>()
    
    const sigCanvas = useRef<any>();
    const sigCanvasEmpty = useRef<any>();
    
    const [fieldValue, setFieldValue] = useState<any>() //sign field
    const [imgPreview, setImgPreview] = useState('')
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleSign = (blob: any) => { 
        setFieldValue(blob)
    }

    useEffect(() => {        
        if(clearanceChargeId){
            const inspectionData = workOrder?.clearanceChargeDetails?.find((el:any)=>el.clearanceChargeId === clearanceChargeId)
            if(inspectionData){
                setSelectedInspection(inspectionData)
                setImgPreview(inspectionData.image)  //TODO - uncomment after preserve image issue is fixed
            }
        }
    }, [])

    useEffect(() => {
        getServiceRequestsCategories().then(({ data: categoriesData }) => {
            categoriesData = categoriesData?.find((category: any) => category?.categoryCode === 'CC')
            const serviceCategoryData = categoriesData?.service_category_types;
            setServiceCategories(serviceCategoryData)
            setServiceCategoryID(categoriesData.id)

        })
        getPreciseLocations(workOrder?.propertyId).then(({ data: preciseLocationList }) => {
            setPreciseLocations(preciseLocationList['preciseLocationList'])
        })
    }, [workOrder])

    useEffect(() => {
        if (termError)
            setTermError(false)
    }, [acceptTerms])

    const initialValues = {
        category: clearanceChargeId && selectedInspectionData?.categoryId ? selectedInspectionData.categoryId : "",
        precise_location: clearanceChargeId && selectedInspectionData?.precise_location ? selectedInspectionData.precise_location : "",
        quantity: clearanceChargeId && selectedInspectionData?.quantity ? selectedInspectionData.quantity : "",
        description: clearanceChargeId && selectedInspectionData?.description ? selectedInspectionData.description : "",
        mediaUpload: "",
    }

    const validateSubmit = () => {
        const signURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        const emptySign = sigCanvasEmpty.current.getTrimmedCanvas().toDataURL("image/png")

        // console.log(inspectionError, 'inspectionError');
        let isError = false
        if (signURL === emptySign) {
            isError = true
            setSigError(true)
        }
        else {
            setSigError(false)
        }
        if (!acceptTerms) {
            isError = true
            setTermError(true)
        }
        if(inspectionError !== '')
            isError = true
        
        return !isError
    }

    const onSubmit = async (values: any) => {
        // console.log(values, 'formik values');

        // console.log(imgPreview, 'imgPreview');
        // console.log(values.mediaUpload, 'values.mediaUpload');

        // let oldImage:any
        // if(values.mediaUpload === '' && imgPreview){
        //     oldImage = await fetch(imgPreview)
        //         .then(response => {return response.blob()})
        //     console.log(oldImage);
        // }

        if(validateSubmit()){   //Validate : inspection duplicate category, sign and terms
            setLoading(true)
            setSubmitError('')
            const formData = new FormData()
            const newIndex = 0;
            formData.append("work_order_id", workOrder.id);
            formData.append('customer_signature', fieldValue, 'signature.png');
            if(clearanceChargeId)
                formData.append(`ccharge[${newIndex}][id]`, clearanceChargeId) //Use only for edit
                // formData.append('id', clearanceChargeId) //Use only for edit

            if(values.mediaUpload !== ''){
                // console.log('new image added');
                formData.append(`ccharge[${newIndex}][image]`, values.mediaUpload); // same new image
            }
            else if(imgPreview === ''){
                formData.append(`ccharge[${newIndex}][image]`, ''); // remove existing image - save null
            }
            // do nothing, it will preserve old image
            // else if(oldImage){
            //     formData.append(`ccharge[${newIndex}][image]`, ''); // preserve old image link
            // }
            formData.append(`ccharge[${newIndex}][desc]`, values.description);
            formData.append(`ccharge[${newIndex}][precise_location]`, values.precise_location);
            formData.append(`ccharge[${newIndex}][quantity]`, values.quantity);
            formData.append(`ccharge[${newIndex}][category]`, serviceCategoryID);
            formData.append(`ccharge[${newIndex}][category_type_id]`, values.category);
                
            setTimeout(() => {
                createClearanceCharge(formData)
                    .then((response: any) => {
                        setLoading(false)
                        if (response.errorCode === 1) {
                            setSubmitError(response.errorDescription)
                            setTimeout(() => {
                                setSubmitError('')
                            }, 5000)
                        } else if(response.errorCode === 0) {
                            history.goBack();
                        } else{
                            setSubmitError(t("Some error occured!"))
                            // console.log(response, 'response');
                            setTimeout(() => {
                                setSubmitError('')
                            }, 5000)
                        }
                    })
                    .catch((e) => {
                        // console.log(e, 'Error');
                        setLoading(false)
                        setSubmitError(t('Some error occured!'))
                        setTimeout(() => {
                            setSubmitError('')
                        }, 5000)
                    })
            }, 1000)
        }
    }

    const validationSchema = Yup.object({
        category: Yup.string().required('Category is required!'),
        precise_location: Yup.string().required('Room is required!'),
        quantity: Yup.string()
            .matches(/^[0-9]*$/, 'Only numbers allowed!')
            .required('Quantity is required!')
            .test(
                'Is positive?', 
                'Quantity must be greater than 0!', 
                (value) => Number(value) > 0
              ),
        description: Yup.string()
            .max(250,"Max length is 250 characters!")
            .matches(/^[^\s].*/, 'Description cannot start with a blankspace!')
            .required("Description is required!"),
        // mediaUpload: Yup.mixed()
        //     .test('type', 'Only the following formats are acceptable: .jpeg, .jpg, .png', (value) =>
        //         value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true )
        //     .test('fileSize', 'The file is too large!', (value) => value ? value?.size <= 2000000 : true ),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        enableReinitialize:true,
        validationSchema,
    });

    useEffect(() => {
        let isAlreadyAdded = false
        if(addedDataList && !selectedInspectionData || !(selectedInspectionData?.categoryId == formik.values.category && selectedInspectionData?.precise_location === formik.values.precise_location)){            
            for (const item of addedDataList) {
                if(item.categoryId == formik.values.category && item.precise_location === formik.values.precise_location){
                    isAlreadyAdded = true;
                    break
                }
            }
            if(isAlreadyAdded){
                setInspectionError('Inspection for selected Category & Room already added! Please edit existing or select other Category/Room.')
            }else{
                setInspectionError('')
            }
        }else{
            setInspectionError('')
        }     
    }, [formik.values.category, formik.values.precise_location])

    const [uploading, setUploading] = useState(false)
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = (e: any) => {
        setUploading(true)
        formik.setFieldError('mediaUpload', undefined)
        try {
            validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
                if (compressResponse?.success) {
                    inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
                    formik.setFieldTouched('mediaUpload')
                    formik.setFieldValue('mediaUpload', compressResponse.file)
                    setImgPreview(URL.createObjectURL(compressResponse?.file))
                } else {
                    setUploadedFileName(null)
                    formik.setFieldTouched('mediaUpload', true, false)
                    formik.setFieldValue('mediaUpload', '', false)
                    formik.setFieldError('mediaUpload', t(compressResponse?.message || 'File compression error!'))
                }
                setUploading(false)
            })
        } catch (error) {
            setUploadedFileName(null)
            formik.setFieldTouched('mediaUpload')
            formik.setFieldValue('mediaUpload', '', false)
            formik.setFieldError('mediaUpload', t('File upload error!'))
            setUploading(false)
        }
        // inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name);
        // setImgPreview(URL.createObjectURL(e.currentTarget.files[0]))
        // formik.setFieldTouched('mediaUpload')
        // formik.setFieldValue('mediaUpload', e.currentTarget.files[0])
    };
    const handleClearImage = () => {
        setImgPreview('')
        formik.setFieldValue('mediaUpload', '')
    }
    const handleCancel = () => {
        formik.resetForm();
        history.goBack();
    }

    const subCategoryOptions = serviceCategoryData?.map((subCategory: any) => (
        <option key={subCategory.id} value={subCategory.id}>
            {subCategory.name}
        </option>
    ))
    const roomOptions = precise_locations?.map((location: any) => (
        <option key={location.id} value={location.id}>
            {location.name}
        </option>
    ))

    return (
        <>
            <div className="col-12">
                <div className="row mb-6 align-items-center">
                    <div className="col-sm-12 col-12">
                        <h4 className="card-title font-18 text-black mb-0">
                            <span className='' onClick={handleCancel} style={{cursor: 'pointer'}}>
                                <img src={LeftArrow} alt="Arrow Left" className="img-fluid me-5" />
                            </span>
                            {t("Moveout Inspection")} ({clearanceChargeId ? (t('Edit')) : (t('Add'))})
                        </h4>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card mb-7">
                    <div className="card-body p-7">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row form-add-design border-bottom mb-5">
                                <div className="col-md-4 col-12">
                                    <div className="mb-7">
                                        <label className='form-label fw-bolder'>{t("Select Category")} <span className="text-danger"> * </span> </label>
                                        <select
                                            className='form-control form-select fw-bolder form-control-solid'
                                            data-control="select2" data-allow-clear="true"
                                            {...formik.getFieldProps('category')}
                                        >
                                            <option value="">{t("Please Select")}</option>
                                            {subCategoryOptions ? subCategoryOptions : ''}
                                        </select>
                                        {formik.touched.category && formik.errors.category && (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.category}</small>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className='form-label fw-bolder'>{t("Select Room")} <span className="text-danger"> * </span> </label>
                                        <select
                                            className='form-control form-select fw-bolder form-control-solid'
                                            data-control="select2" data-allow-clear="true"
                                            {...formik.getFieldProps('precise_location')}
                                        >
                                            <option value="">{t("Please Select")}</option>
                                            {roomOptions ? roomOptions : ''}
                                        </select>
                                        {formik.touched.precise_location && formik.errors.precise_location && (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.precise_location}</small>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder"> {t("Required Quantity")} <span className="text-danger"> * </span> </label>
                                        <input
                                            type="text"
                                            {...formik.getFieldProps('quantity')}
                                            className="form-control fw-bolder form-control-solid" placeholder="" />

                                        {formik.touched.quantity && formik.errors.quantity ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.quantity}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <div>
                                    {imgPreview !== '' &&  <img src={imgPreview} alt='img' /> }
                                </div> */}
                                {imgPreview === '' ?
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder d-block mb-6">{t("Attach Image")} ({t("Optional")})</label>
                                            <input
                                                ref={inputRef}
                                                className="d-none"
                                                type="file"
                                                onChange={(e) => handleDisplayFileDetails(e)}
                                                // onBlur={formik.handleBlur}
                                                accept=".png,.jpg,.jpeg"
                                                />
                                            <button onClick={handleUpload} type='button' className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`} disabled={uploading}>
                                                <span>{uploading ?
                                                    <span className='indicator-progress d-block'>
                                                        {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                    : uploadedFileName ? uploadedFileName : <span>{t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                            </button>
                                            {formik.touched.mediaUpload && formik.errors.mediaUpload ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.mediaUpload}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    :
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder d-block mb-6">{t("Attach Image")} ({t("Optional")})</label>
                                            <div className="position-relative">
                                                <div className="upload-image mt-0">
                                                    <img src={imgPreview} alt="Preview" className="form-img__img-preview" />
                                                    <div className="close-icon" onClick={handleClearImage}>&times;</div>
                                                </div>
                                            </div>
                                            {formik.touched.mediaUpload && formik.errors.mediaUpload ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.mediaUpload}</small>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                }
                                <div className="col-md-8 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Description")} <span className="text-danger"> * </span> </label>
                                        <textarea
                                            className='form-control fw-bolder form-control-solid'
                                            data-kt-autosize='true'
                                            rows={2}
                                            placeholder='Type here...'
                                            {...formik.getFieldProps('description')}
                                        />
                                        {formik.touched.description && formik.errors.description ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.description}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <Signature sigCanvas={sigCanvas} sigCanvasEmpty={sigCanvasEmpty} sigError={sigError}
                                        handleSign={handleSign} />
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="row justify-content-end">
                                        <div className="col-lg-3 col-md-4 col-12 my-auto">
                                            <div className="form-check form-check-sm custom float-end mt-5">
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={acceptTerms} onChange={() => { setAcceptTerms(old => !old) }} />
                                                <label className="form-check-label font-12 fw-normal" htmlFor="flexCheckDefault">
                                                    {t("I Accept Terms and Conditions")}
                                                </label>
                                                {termError &&
                                                    <div className='d-flex flex-column text-danger'>
                                                        <small className="text-danger">{t("Please accept terms and condition")}</small>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {loading ?
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <button
                                                    type='button' className='btn btn-primary fw-bold px-15 py-3 mt-5 btn-block' disabled>
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    {t("Please wait...")}{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                </button>
                                            </div>
                                            :
                                            <>
                                                <div className="col-lg-2 col-md-3 col-6">
                                                    <button type='button' className='btn btn-outline-primary fw-bold px-10 btn-block py-3 mt-5'
                                                        onClick={handleCancel}>
                                                        {t("Cancel")}
                                                    </button>
                                                </div>
                                                <div className="col-lg-2 col-md-3 col-6">
                                                    <button
                                                        // data-bs-target="#SuccessModal" data-bs-toggle="modal" data-bs-dismiss="modal"
                                                        type="submit" className="btn btn-primary fw-bold px-10 btn-block py-3 mt-5"
                                                        // onClick={handleSubmit}>
                                                        onClick={formik.submitForm}
                                                        disabled={uploading}
                                                    >
                                                        {t("Submit")}
                                                    </button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                        {inspectionError !== '' && (
                            <div className="col-12 text-center">
                                <div className="alert alert-danger w-100 mt-3" role="alert">
                                    {inspectionError}
                                </div>
                            </div>
                        )}
                        {submitError !== '' && (
                            <div className="col-12 text-center">
                                <div className="alert alert-danger w-100 mt-3" role="alert">
                                    {submitError}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            
        </>
    )
}