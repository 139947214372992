import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../components/auth'
import * as Dashboard from './Sagas/ProspectiveTenant/PTDashboardSaga'
import * as Profile from './Sagas/ProfileSaga'
import * as PTDocuments from './Sagas/ProspectiveTenant/PTDocumentsSaga'
import * as TenantDocuments from './Sagas/Tenant/TenantDocumentsSaga'
import * as TDashboard from './Sagas/Tenant/TenantDashboardSaga'
import * as PTOffer from './Sagas/ProspectiveTenant/PTOffer'
import * as PTFinance from './Sagas/Finance/financeSaga'
import * as PM from './Sagas/PropertyManager/propertyManagerSaga'
import * as General from './Sagas/GeneralSaga'
import * as FCDashboard from './Sagas/FacilityContractor/FacilityContractorSaga'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  PTdashboard: Dashboard.reducer,
  Profile: Profile.reducer,
  PTdocuments: PTDocuments.reducer,
  Tenantdocuments: TenantDocuments.reducer,
  Tdashboard: TDashboard.reducer,
  PTOffer: PTOffer.reducer,
  PTFinance: PTFinance.reducer,
  PM: PM.reducer,
  General: General.reducer,
  FCDashboard: FCDashboard.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    Dashboard.PTDashboardsaga(),
    Profile.Profilesaga(),
    PTDocuments.PTDoumentssaga(),
    TenantDocuments.TenantDoumentssaga(),
    TDashboard.TenantDashboardsaga(),
    PTFinance.PTFinancessaga(),
    PM.PMSaga(),
    General.GeneralSaga(),
    FCDashboard.FacilityContractorSaga(),
  ])
}
