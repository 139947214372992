
import NoDataFoundImage from '../../assets/images/no-data-found.svg';
import loadderIcon from '../../assets/images/loader.gif'
import { useTranslation } from 'react-i18next'

export const NoDataFound = () => {
    const { t } = useTranslation();

    return <>
        <div className="col-12 com-card-section">
            <div className="card border-0 shadow-none no-data-found-cd">
                <div className="card-body border-0">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <img src={loadderIcon} alt="No Data Found" className="img-fluid" />
                        </div>
                        <div className="col-12 text-center">
                            <span className='d-block text-center font-18 fw-bolder text-black'> {t("No Data Found")} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;

}
