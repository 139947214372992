import React, { useState, useEffect, useRef } from 'react';
import NoImage from "../../../../../../assets/images/House.png";
// import { ImageViewerWithPopup } from '../../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';
import { useTranslation } from 'react-i18next';

export function InspectionDetails({workOrderDetails, loading}:any) {
    let workOrderImage = NoImage
    if (workOrderDetails?.objectDetails?.attachments_json?.photo && workOrderDetails.objectDetails.attachments_json.photo[0])
        workOrderImage = workOrderDetails.objectDetails.attachments_json.photo[0]
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row mb-1">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            {/* <h5 className="mb-5">Inspection Details</h5> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row form-add-design mb-8">
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <label className="form-label fw-bolder">{t("Assigned Technician")}</label>
                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                <p className='font-14 fw-bold d-block mb-0'>{workOrderDetails?.assignedTo}</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='border-top py-10 mt-5'>
                                {/* <h5 className="mb-5">On Hold Details</h5> */}
                                <div className="row form-add-design">
                                    <div className="col-md-4 col-12 mb-5">
                                        <label className="form-label fw-bolder">{t("On-Hold Reason")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <p className='font-14 fw-bold d-block mb-0'>{workOrderDetails?.onHoldReason}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-12">
                                        <label className="form-label fw-bolder">{t("On-Hold Comment")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <p className='font-14 fw-bold d-block mb-0'>{workOrderDetails?.onHoldComment}</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <h5 className="mb-5">On Hold Details</h5>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row form-add-design mb-8">
                                        <div className="col-sm-6 col-md-4 col-12">
                                            <label className="form-label fw-bolder">Reason</label>
                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                <p className='font-14 fw-bold d-block mb-0'>{workOrderDetails?.onHoldReason}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-8 col-12">
                                            <label className="form-label fw-bolder">Comment</label>
                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                <p className='font-14 fw-bold d-block mb-0'>{workOrderDetails?.onHoldComment}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}