import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getPMapprovalList } from './../../../../constants/axios/apis';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import NoDataFoundImage from '../../../../assets/images/no-data-found.svg';
import { CustomLoader } from './../../../../constants/generics/customLoader';
import { RootState } from '../../../../constants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';

export function ResolvedRequestsNew() {
	const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
	const location = useLocation();
	const [approvalsData, setApprovalsData] = useState<any>([])
	const [loading, setLoading] = useState(true)

	const [type, setType] = useState<any>('0')
	const [currentPage, setCurrentPage] = useState<any>(1)
	const [pageSize, setPageSize] = useState<any>(10)
	const [totalApprovalsList, setTotalApprovalsList] = useState<any>(0);
	const { t } = useTranslation();

	useEffect(() => {
		setLoading(true)
		const building = '&building_id=' + (currentBuilding && currentBuilding !== '' ? currentBuilding : '0')
		const config = `status=approve&id_sort=1&currentPage=${currentPage}${building}&type=${type}`
		getPMapprovalList(config)
			.then((response: any) => {
				setLoading(false)
				if (response?.errorCode !== 0 || response?.errorDescription === t("The token is expired or invalid")) {
					setApprovalsData(response);
				} else {
					setApprovalsData(response);
					setTotalApprovalsList(response.dataCount);
				}
			})
	}, [currentPage, currentBuilding, type])

	const handleClick = (newCurrentPage: any) => {
		setCurrentPage(newCurrentPage)
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		handleClick(value);
	};

	const handleType = (type: any) => {
		setType(type.target.value)
	}
	if (approvalsData.length === 0) {
		return <CustomLoader />
	}
	return (
		<>
			<div className="com-card-section">
				<div className="row">
					<div className="col-12">
						<div className="card mb-3">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-md-12 col-12">
										<div className="rec-payment-act-sec">
											<div className="row">
												{approvalsData?.errorCode === 0 ?
													<div className="col-12">
														<div className='table-responsive'>
															<table className='table table-striped gy-4 gx-4 mb-0'>
																<thead>
																	<tr>
																		<th> {t("Request No.")} </th>
																		<th>
																			<select className="fw-bolder bg-transparent border-0 font-14" data-control="select2" data-allow-clear="true" value={type} onChange={handleType}>
																				<option disabled>{t("Type of Request")} </option>
																				<option value="0">{t("All Type of Request")}</option>
																				<option value="1">{t("Offer Request")}</option>
																				<option value="2">{t("Contract Request")}</option>
																				<option value="3">{t("Service Request")}</option>
																			</select>
																		</th>
																		<th> {t("Issue/Request")} </th>
																		<th> {t("Tenant Name")} </th>
																		<th> {t("Unit No.")} </th>
																		<th> {t("Action")} </th>
																	</tr>
																</thead>
																<tbody>
																	{approvalsData?.errorCode != 1 && approvalsData?.data?.map((item: any, index: number) => {
																		const sl = (pageSize * (currentPage - 1)) + index + 1
																		return (
																		<tr key={index}>
																			{/* <td>{item?.requestNo != undefined && item?.requestNo != null ? item?.requestNo : sl}</td> */}
																			<td>
																			<Link
																					to={{
																						pathname: item?.reference === 3 ? '/pm-service-request/request-details' : item?.reference === 1 ? '/offer-details/request-details' : '/contract/request-details',
																						// pathname: item?.reference === 3 ? '/pm-insufficient-notice/request-details' : item?.reference === 1 ? '/offer-details/request-details' : '/contract/request-details',
																						state: { id: item?.id , status: 'approve'},
																					}}>
																					<span className='view-option text-theme fw-bolder'> {item?.requestNo != undefined && item?.requestNo != null ? item?.requestNo : sl}</span>
																				</Link>
																			</td>
																			<td>{item?.reference === 1 ? t("Offer") : item?.reference === 2 ? t("Contract") : t("Service Request")}</td>
																			<td>
																				{/* {item?.reference === 1 ? t("Offer") : item?.reference === 2 ? t("Contract") : t("Service Request")} */}
																				{item?.subReference === 1 ? t("New Contract") :
																					item?.subReference === 2 ? t("Renew Contract") :
																						item?.subReference === 3 ? t("Extend Contract") :
																							item?.subReference === 4 ? t("Rent Reduction") :
																								item?.subReference === 5 ? t("Move In") :
																									item?.subReference === 6 ? t("Move Out Access Card") : // was Moveout previously - no 25 new added
																										item?.subReference === 7 ? t("Submit Interest") :
																											item?.subReference === 8 ? t("Cheque Delay") :
																												item?.subReference === 9 ? t("Cheque Replace") :
																													item?.subReference === 10 ? t("Event") :
																														item?.subReference === 11 ? t("Offer") :
																															item?.subReference === 12 ? t("Voucher") :
																																item?.subReference === 13 ? t("Early Termination Request") :
																																	item?.subReference === 14 ? t("Renew Contract Documents") :
																																		item?.subReference === 15 ? t("Extend Contract Documents") :
																																			item?.subReference === 16 ? t("Residential GPTW") :
																																				item?.subReference === 17 ? t("Commercial  GPTW") :
																																					item?.subReference === 20 ? 'Broker Login' :
																																						item?.subReference === 21 ? 'New User' :
																																							item?.subReference === 22 ? 'Access Cards' :
																																							item?.subReference === 23 ? t("Dispute Charges") :
																																							item?.subReference === 24 ? t("Refund Request") :
																																								item?.subReference === 25 ? t("Move Out") :
																																									item?.subReference === 27 ? t("Fit-out Design NOC") :
																																									item?.subReference === 28 ? t("Fit-out Commencement") :
																																									item?.subReference === 29 ? 
																																										item?.category_type_id === 52 ? t("PTW - Helipad") :
																																										item?.category_type_id === 53 ? t("PTW - Fire Protection") :
																																										t("PTW - General") :
																																									item?.subReference === 30 ? t("Authorities NOC") :
																																									t("New request (optional)")
																																									// t("new onhold-workorder, etc (optional)")
																				}
																			</td>
																			<td>{item?.cutomerName ? item?.cutomerName : '-'}</td>
																			<td>{item?.propertyCode ? item?.propertyCode : '-'}</td><td>
																				<Link
																					to={{
																						pathname: item?.reference === 3 ? '/pm-service-request/request-details' : item?.reference === 1 ? '/offer-details/request-details' : '/contract/request-details',
																						// pathname: item?.reference === 3 ? '/pm-insufficient-notice/request-details' : item?.reference === 1 ? '/offer-details/request-details' : '/contract/request-details',
																						state: { id: item?.id , status: 'approve'},
																					}}>
																					<span className='view-option text-theme fw-bolder'>{t("View")}</span>
																				</Link>
																			</td>
																		</tr>)
																	})}
																</tbody>
															</table>
															<div className="w-100 d-flex justify-content-end">
																{/* <Stack spacing={2}>
																	<Pagination count={totalApprovalsList % pageSize === 0 ? totalApprovalsList / pageSize : Math.ceil(totalApprovalsList / pageSize)} onChange={handlePageChange} />
																</Stack> */}
																<CompactPagination
																	pageSize={pageSize}
																	totalCount={parseInt(totalApprovalsList)}
																	defaultPage={currentPage}
																	onPageChange={setCurrentPage}
																	loading={false}
																/>
															</div>
														</div>
													</div>
													: <div className="col-12">
														<select className="form-control form-select fw-bolder form-control-solid w-50" data-control="select2" data-allow-clear="true" value={type} onChange={handleType}>
															<option disabled>{t("Type of Request")} </option>
															<option value="0" >{t("All Type of Request")}</option>
															<option value="1">{t("Offer Request")}</option>
															<option value="2">{t("Contract Request")}</option>
															<option value="3">{t("Service Request")}</option>
														</select>
														<div className="card border-0 shadow-none no-data-found-cd">
															<div className="card-body border-0">
																<div className="row justify-content-center">
																	<div className="col-12 text-center">
																		<img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" />
																	</div>
																	<div className="col-12 text-center">
																		<span className='d-block text-center font-18 fw-bolder text-black'> {t("No Data Found")} </span>
																	</div>
																</div>
															</div>
														</div>
													</div>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div></>
	)
}