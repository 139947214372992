import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {TenantOverview} from'./Components/TenantOverview';
import {TenantStepper} from './TenantStepper/TenantStepper'
import {StepFour} from './TenantStepper/StepFour/StepFour'

export function CreateContractPopup() {
    const [isTenantStepper, setTenantStepper] = useState(true);
    const [isTenantOverview, setTenantOverview] = useState(true);
    const [isStepFour, setStepFour] = useState(true);

    const onTenantStepper = () => {
        setTenantStepper(true);
        setTenantOverview(false);
        setStepFour(false)
    };

    const onTenantOverview = () => {
        setTenantOverview(true);
        setTenantStepper(false);
        setStepFour(false)
    };
    const onStepFour = () => {
        setStepFour(true)
        setTenantOverview(false);
        setTenantStepper(false);
    };

    return (
        <div className="">            
              <div className="modal fade custom-modal create-campaign-popup tenant-info-popup" id="create-contract-popup">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content rounded pt-5 py-3">
                        <div className="modal-header p-0 justify-content-start rounded-0 mb-2">
                            <h5 className="modal-title fw-bold font-18">
                                {isTenantOverview ? 
                                    <span
                                        onClick={() => setTenantOverview(false)}
                                    >
                                        Enter Tenant's Details
                                    </span>
                                    : isTenantStepper ?
                                    <span
                                        onClick={() => setTenantOverview(false)}
                                    >                                        
                                    </span>
                                    : isStepFour ?
                                    <span
                                        onClick={() => setTenantOverview(false)}
                                    >                                        
                                    </span>
                                :null}                                
                            </h5>
                            <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                        <div className="modal-body">                           
                            {isTenantOverview ?
                                <TenantOverview
                                    isTenantStepper={isTenantStepper}
                                    onTenantStepper={onTenantStepper}
                                />
                                : isTenantStepper ?
                                    <TenantStepper />
                                :
                                <StepFour
                                    isStepFour={isStepFour}
                                    onStepFour={onStepFour}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}