import React, { useState, useEffect, useRef } from 'react';
// import useRequestGet from '../../../../hooks/useRequestGet'
import useRequestPost from '../../../../../hooks/useRequestPost'
import CheckGreen from "../../../../../assets/images/Check_green_lg.png";
import OnHold from "../../../../../assets/images/on-hold.png";
import Loader from '../../../../../components/Loader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { RequestSuccessfulPopup } from '../../../SuccessfulPopup/RequestSuccessfulPopup';
import { getMasterData } from '../../../../../constants/axios/apis';
// import Item from 'antd/lib/list/Item';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const PutOnHoldWorkOrder:React.FC<any> = ({workOrder, setPopupSuccessMsg}) => {
    const { t } = useTranslation();

    const history = useHistory();
    // const buttonEl:any = useRef(null);
    // function successPopup(){
    //     var ev = new Event('click', { bubbles: true});
    //     buttonEl.current.dispatchEvent(ev);
    // }
    const successRef: any = useRef(null)
    const [isSubmitted, setIsSubmitted] = useState<any>(false);
    const [reasonList, setReasonList] = useState<any>([]);

    const {requestState: makeOnHoldState, setRequestParams} = useRequestPost("/facility/work/order/onHold", false, ()=>{
        // console.log(makeOnHoldState, 'makeOnHoldState');
        setIsSubmitted(true)
        setPopupSuccessMsg(t("On Hold updated Successfully!"))
        successRef.current.click()
        setTimeout(() => {
            history.push("/facility-workorder/list/corrective/onhold")
        }, 500)

    });

    useEffect(()=>{
        getMasterData().then(({ data }) => {
			const reasonData = data?.find((el: any) =>
				el.category_name === "work_order_on_hold_reasons"
			)
            setReasonList(reasonData?.master_data)
		})        
    },[])
    const initialValues = {
        reason: '',
        comments: ''
    }
    const onSubmit = (values: any) => {
        setRequestParams({
            reason: values.reason,
            comment: values.comments,
            status: "3",
            work_order_id: workOrder?.id
        })
        setTimeout(()=>{
            console.log('redirect');
            // window.location.replace("/fc-workorder/corrective/on-hold");
        },2000)
    }
    const validationSchema = Yup.object({
        reason: Yup.string().required(t('Reason is required!')),
        // comments: Yup.string().required('Comments is required')
        comments: Yup.string()
            .max(250, t("Max length is 250 characters!"))
            .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!'))
            .required(t("Comment is required!")),
    });
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    return (
        <>
            <div className="col-sm-4 col-12 text-end">
                <button
                    className="rounded-2 bg-white text-primary btn btn-sm px-4 py-2 border-0 fw-bold font-16 on-hold-btn"
                    data-bs-toggle="modal" data-bs-target="#onHoldPopUp" type="button">
                    <img src={OnHold} alt="Hold" className="img-fluid me-2" />{t("ON HOLD")}
                </button>
            </div>

            <div className="modal onHoldPopUp custom-modal" id="onHoldPopUp">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content rounded-10">
                        
                        <form onSubmit={formik.handleSubmit} style={{width: "100%"}}>
                            <div className="modal-header border-0">
                                <h5 className="modal-title">{t("On Hold")}</h5>
                                {/* <button ref={buttonEl} type="button" className="btn-close invisible" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body pt-8">
                                <div className="row form-add-design">
                                    <div className="col-md-12 col-12">
                                        <div className="mb-5">
                                            <label className="form-label fw-bolder"> {t("Reason")}</label>
                                            <select 
                                                {...formik.getFieldProps('reason') }
                                                /* onChange={(e:any)=>{
                                                    setReason(e.target.value)
                                                }}  */
                                                className="form-control form-select fw-bolder form-control-solid">
                                                <option value="">{t("Please Select")}</option>
                                                {reasonList?.map((el:any, index:number)=>
                                                    <option key={index} value={el.value}>{el.name}</option>
                                                )}
                                                {/* <option value="132">Out Of Region</option> */}
                                                {/* <option value="133">Sick</option> */}
                                            </select>
                                            {formik.touched.reason && formik.errors.reason ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.reason}</small>
                                                </div>
                                            ): null }
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <div className="mb-0">
                                            <label className="form-label fw-bolder">{t("Comment")}</label>
                                            <textarea {...formik.getFieldProps('comments') } className="form-control fw-bolder form-control-solid" placeholder={t("Type here...")} rows={4}></textarea>
                                            {formik.touched.comments && formik.errors.comments ? (
                                                <div className='d-flex flex-column text-danger'>
                                                    <small className="text-danger">{formik.errors.comments}</small>
                                                </div>
                                            ): null }
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <div className="modal-footer border-0 pt-0">
                                {makeOnHoldState.loading && (
                                    <Loader />
                                )}
                                {!makeOnHoldState.loading && (
                                    <button 
                                        /* onClick={handleSub} */ 
                                        className="btn btn-primary fw-bold px-8 py-3 font-16 fw-normal">{t("Submit")}</button>
                                )}
                            </div>
                            <p
                                ref={successRef}
                                data-bs-target='#req-submitted-popup-redirect'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                className='invisible d-none' />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}