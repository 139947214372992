import React, {useState, useEffect, useRef} from 'react'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import {Link, useHistory, useLocation} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {vehicleRegistrationRequest} from '../../../../../constants/axios/apis'
import {RequestFailedPopup} from '../../../../SuccessfulPopup/RequestFailedPopup'
import {CustomRequestSuccessfulPopup} from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { useTranslation } from 'react-i18next'

export function AddParking({setParkingSuccessMsg}:any) {
  const history = useHistory()
  const location = useLocation<any>()
  const { t } = useTranslation();
  const [parkingSlot, setParkingSlot] = useState<any>()
  const [propertyParkingSlot, setPropertyParkingSlot] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [formloading, setFormloading] = useState<any>(false)

  const [submitError, setSubmitError] = useState('')

  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')

  const buttonEl: any = useRef(null)

  let temporary_property_id = 1

  useEffect(() => {
    setParkingSlot(location?.state?.parking_number)
  }, [location])

  const vehicleRegistrationRequestSchema = Yup.object().shape({
    brand: Yup.string()
      .required(t('Please enter the brand!'))
      .matches(/^[^\s].*/, (t('Brand Name cannot start with a blankspace!')))
      .max(20, (t('Brand should be smaller then 20 characters!'))),
    model: Yup.string()
      .required(t('Please enter the model!'))
      .matches(/^[^\s].*/, (t('Model Name cannot start with a blankspace!')))
      .max(20, 'Model should be smaller then 20 characters!'),
    color: Yup.string()
      .required(t('Please enter the color!'))
      .max(20, (t('Color should be smaller then 20 characters!')))
      .matches(/^[^\s].*/, (t('Color Name cannot start with a blankspace!')))
      .matches(/^[A-Za-z ]+$/, (t('Only alphabets allowed!'))),
    regno: Yup.string()
      .required(t('Please enter the Registered Plate Number!'))
      .matches(/^[^\s].*/, (t('Registration number cannot start with a blankspace!')))
      .max(20, (t('Registration number should be smaller then 20 digits!'))),
    status: Yup.bool().oneOf([true], (t('Terms & Conditions must be accepted!'))),
    signature: Yup.string().required(t('Please add signature!')),
  })

  const initialValues = {
    category_id: 9,
    property_id: parkingSlot?.property_id,
    parking_slot: parkingSlot?.id,
    user_id: parkingSlot?.user_id,
    brand: '',
    model: '',
    color: '',
    regno: '',
    status: 0,
    signature: '',
  }

  const sigCanvas = useRef<any>()
  function clear() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }
  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  const handleTerms = (e: any) => {
    if (e.target.checked) {
      return formik.setFieldValue('status', 1)
    }
    return formik.setFieldValue('status', 0)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleRegistrationRequestSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setSubmitError('')
      setTimeout(() => {
        vehicleRegistrationRequest(
          values.property_id,
          values.category_id,
          values.parking_slot,
          values.brand,
          values.model,
          values.color,
          values.regno,
          values.signature,
          values.user_id
        )
          .then((response: any) => {
            setLoading(false)

            if (response.errorCode === 1) {
              setSubmitError(response.errorDescription)
              setTimeout(() => {
                setSubmitError('')
              }, 5000)
            } else if (response.errorCode === 0) {
              setParkingSuccessMsg(response.description)
              // formik.resetForm()
              setLoading(false)
              var ev = new Event('click', {bubbles: true})
              buttonEl.current.dispatchEvent(ev)
              // history.push('/parking/all')
              setTimeout(() => {
                // window.location.replace('/parking/all')
                history.push('/parking/all')
                // window.location.reload();
              }, 1000)
            } else {
              setSubmitError(t('Some error occured. Please try again !'))
              setTimeout(() => {
                setSubmitError('')
              }, 5000)
            }
          })

          // if (response.errorCode != 1 && response.errorCode === 200) {
          //   successRef.current.click()
          //   sigCanvas.current.clear()
          //   history.replace('/parking/all')
          //   // Automatically Reload the Page (Because of status value changed for the cars)
          //   window.location.reload();
          // } else {
          //   setMessage(response.errorDescription)
          //   failRef.current.click()
          // }
          // })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t('Invalid Data Provided'))
            setTimeout(() => {
              setSubmitError('')
            }, 5000)
          })
      }, 1000)
    },
  })

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              {/* <Link to={'/parking/all'} className=''> */}
              <div
                className='me-5 c-pointer'
                onClick={() => {
                  history.goBack()
                }}
              >
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                <span className='text-black'>{t("Vehicle Registration")}</span>
                {/* </Link> */}
              </div>
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-4'>
              <div className='card-body px-8 py-6'>
                <h4 className='font-18 text-black fw-normal mb-0'>
                  {t("Parking Slot Number")}{' '}
                  <span className='fw-bolder ml-5'>
                    {parkingSlot ? parkingSlot.slotNumber : (t('No Data'))}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <form
                  className={'form w-100 fv-plugins-bootstrap5 fv-plugins-framework tab-pane'}
                  noValidate
                  onSubmit={formik.handleSubmit}
                  id='parking-slot'
                >
                  <div className='row form-add-design'>
                    <div className='col-md-12 col-12'>
                      <h5 className='font-16 text-black mb-6 fw-bolder'>{t("Vehicle Details")}</h5>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {' '}
                          {t("Brand")}
                          <span className='text-danger'> * </span>
                        </label>
                        <input
                          type='text'
                          {...formik.getFieldProps('brand')}
                          className='form-control fw-bolder form-control-solid'
                          placeholder={t('Brand')}
                          autoComplete='off'
                        />
                        {formik.touched.brand && formik.errors.brand && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.brand}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {' '}
                          {t("Model")}
                          <span className='text-danger'> * </span>
                        </label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          {...formik.getFieldProps('model')}
                          placeholder={t('Model')}
                          autoComplete='off'
                        />
                        {formik.touched.model && formik.errors.model && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.model}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {' '}
                          {t("Color")}
                          <span className='text-danger'> * </span>
                        </label>
                        <input
                          type='text'
                          {...formik.getFieldProps('color')}
                          className='form-control fw-bolder form-control-solid'
                          placeholder={t('Color')}
                          autoComplete='off'
                        />
                        {formik.touched.color && formik.errors.color && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.color}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {' '}
                          {t("Registered Plate Number")}
                          <span className='text-danger'> * </span>
                        </label>
                        <input
                          type='text'
                          {...formik.getFieldProps('regno')}
                          className='form-control fw-bolder form-control-solid'
                          placeholder={t('Registered Plate Number')}
                          autoComplete='off'
                        />
                        {formik.touched.regno && formik.errors.regno && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.regno}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-12 col-12 mb-5 mb-md-0'>
                      <div className='row'>
                        <div className='col-md-4 col-sm-8 col-12'>
                          <div className='mb-4'>
                            <label className='form-label fw-bolder'>
                              {' '}
                              {t("Customer Signature")}
                              <span className='text-danger'> * </span>
                            </label>
                            <div className='w-100 form-control'>
                              <SignatureCanvas
                                ref={sigCanvas}
                                penColor='black'
                                canvasProps={{height: 60, width: 320, className: 'sigCanvas'}}
                                onEnd={handleSignature}
                              />
                            </div>
                            {formik.touched.signature && formik.errors.signature && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.signature}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-md-4 my-auto col-sm-4 col-12'>
                          <div className='mt-7'>
                            <button
                              type='button'
                              className='btn btn-light fw-bold px-10 py-2'
                              onClick={clear}
                            >
                              {t("Clear")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='row'>
                        <div className='col-md-12 col-sm-12 col-12'>
                          <div className='row align-items-center justify-content-end'>
                            <div className='mb-0 col-md'>
                              <div className='form-check form-check-sm custom float-end'>
                                <input
                                  className='form-check-input'
                                  checked={formik.values.status ? true : false}
                                  {...formik.getFieldProps('status')}
                                  onChange={(e) => handleTerms(e)}
                                  type='checkbox'
                                  id='flexCheckDefault'
                                  onBlur={formik.handleBlur}
                                />
                                <label
                                  className='form-check-label font-12 fw-normal text-start'
                                  htmlFor='flexCheckDefault'
                                  style={{textAlign: 'left'}}
                                >
                                  {/* Need to change the url */}
                                  <a href='/parking/add-parking'>
                                    {t("Accept Terms &#38; Condition apply")}
                                  </a>
                                </label>
                              </div>
                              {formik.touched.status && formik.errors.status && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.status}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='col-md-auto text-end'>
                              <div className="row justify-content-end mt-5 mt-md-0">
                                <div className="col-lg-6 col-md-6 col-6">
                                  <button
                                    type='button'
                                    className='btn btn-outline-primary fw-bold px-md-15 py-3 btn-block'
                                    onClick={() => {
                                      formik.resetForm()
                                      history.push('/parking/all')
                                    }}
                                  >
                                    {t("Cancel")}
                                  </button>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                  <button type='submit' className='btn btn-primary fw-bold px-md-15 btn-block py-3'disabled={loading}>
                                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                    {loading && (
                                      <span className='indicator-progress' style={{display: 'block'}}>
                                        {t("Please wait...")}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                         {submitError && (                         
                          <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                            {submitError}
                          </div>
                        )}
                        <p
                          ref={buttonEl}
                          data-bs-target='#req-submitted-popup-parking'
                          data-bs-toggle='modal'
                          data-bs-dismiss='modal'
                          className='invisible d-none btn btn-primary fw-bold px-15 py-3'
                        /> 
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}
