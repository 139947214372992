import React, {useState, useRef, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import NoImage from "../../../../../../../assets/images/House.png";

export function Details({accessCardData, cardCount, lableName}: any) {
  const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-4'>
                  <div className='approval-move'>
                    <img src={accessCardData?.communityLogo ? accessCardData?.communityLogo : NoImage} className='img-fluid' />
                    {/* <img src={accessCardData?.buildingIcon} className='img-fluid' /> */}
                    {/* <img src={accessCardData?.propertyCoverImage} className='img-fluid' /> */}
                  </div>
                </div>                
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white shadow-none rounded-0'>
                    <div className='card-body p-0'>
                      <div className='row'>
                      <div className='col-12 mb-5 text-end'>
                  <span
                    className={
                      accessCardData?.status === 0
                        ? 'bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                        : accessCardData?.status === 1
                        ? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                        : 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                    }
                  >
                    {' '}
                    {accessCardData?.status === 0
                      ? (t('Open'))
                      : accessCardData?.status === 2
                      ? (t('Active'))
                      : accessCardData?.status}
                  </span>
                </div>
                        <div className='col-md-12 col-12'>
                          <span className='text-black fw-bolder font-15 d-block mb-7'>
                            {t("Tenant Name")} :{' '}
                            {accessCardData?.tenantName ? accessCardData?.tenantName : '--'}
                          </span>
                        </div>
                        
                        <div className='col-lg-4 col-md-4 col-sm-6 col-xl-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t("Building Name")}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {accessCardData?.building_name
                              ? accessCardData?.building_name
                              : '--'}{' '}
                          </span>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-xl-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Unit Number")} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {accessCardData?.propertyCode
                              ? accessCardData?.propertyCode
                              : '--'}{' '}
                          </span>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-xl-4 col-12 mb-7'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t(lableName)} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {cardCount ? cardCount : 0}{' '}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
