import React,{FC, useState, useEffect} from 'react'
// import { VerticalGraph } from '../../VerticalGraph';
import Loader from '../../../../components/Loader';
import {VerticalGraph} from '../../../../components/layout/components/VerticalGraph'
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

const initReportData = {
    movein: {
        open:{ val: 0, rem: 0 },
        close:{ val: 0, rem: 0 },
        total: 0
    },
    moveout: {
        open:{ val: 0, rem: 0 },
        close:{ val: 0, rem: 0 },
        total: 0
    },
    schedule: {
        open:{ val: 0, rem: 0 },
        close:{ val: 0, rem: 0 },
        total: 0
    },
}
export const RentFMSM:FC<any> = ({reportState, setReportState, reportType}) => {
    const { t } = useTranslation();
    const { loading, response: reports, error } = reportState;
    const [reportVal, setReportVal] = useState(initReportData);

    // console.log(reportState.Clearance, 'response');
    // console.log(reportState.Preventive, 'response');
    

    useEffect(()=>{
        if(Object.keys(reportState.response).length > 0){

            // const max = Math.max.apply(Math, [
            //     reportState.response.Corrective.open,
            //     reportState.response.Corrective.closed,
            //     reportState.response.Clearance.open,
            //     reportState.response.Clearance.closed,
            //     reportState.response.Preventive.open,
            //     reportState.response.Preventive.closed,
            // ]);
            let MoveIn = initReportData.movein
            let MoveOut = initReportData.moveout
            let scheduleEvents = initReportData.schedule
            if(reportState.response.MoveIn){
                MoveIn = {
                    open:{
                        val: reportState.response.MoveIn.Open,
                        rem: reportState.response.MoveIn.Closed
                    },
                    close:{
                        val: reportState.response.MoveIn.Closed ,
                        rem: reportState.response.MoveIn.Open
                    },
                    total:reportState.response.MoveIn.Open + reportState.response.MoveIn.Closed,
                }
            }
            if(reportState.response.MoveOut){
                MoveOut = {
                    open:{
                        val: reportState.response.MoveOut.Open,
                        rem: reportState.response.MoveOut.Closed
                    },
                    close:{
                        val: reportState.response.MoveOut.Closed ,
                        rem: reportState.response.MoveOut.Open
                    },
                    total:reportState.response.MoveOut.Open + reportState.response.MoveOut.Closed,
                }
            }
            if(reportState.response.scheduleEvents){
                scheduleEvents = {
                    open:{
                        val: reportState.response.scheduleEvents.Open,
                        rem: reportState.response.scheduleEvents.Closed
                    },
                    close:{
                        val: reportState.response.scheduleEvents.Closed ,
                        rem: reportState.response.scheduleEvents.Open
                    },
                    total:reportState.response.scheduleEvents.Open + reportState.response.scheduleEvents.Closed,
                }
            }
            
            setReportVal(
                {
                    movein: MoveIn,
                    moveout: MoveOut,
                    schedule: scheduleEvents,
                }
            );
        }
        
    },[reportState]);

    if(loading){
        return <CustomLoader2 />
    } 
    else if(!reportState || !reportState.response || reportState.response.length === 0)
        return <></>
    else if(reportState.error !== "")
        return <>
            <div className="row">
                <div className="col-12 text-center">
                    <div className="alert alert-danger w-100 mt-2" role="alert">
                        {reportState.error}
                    </div>
                </div>
            </div>
        </>

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        {t("Overview")}
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card rounded-10">
                        <div className="card-body px-8 py-6">
                            {/*<div className="col-md-12">*/}
                            {/*<h4 className="fw-boldest text-black mt-3 mb-6 font-16">*/}
                            {/*Total Inspection (80)*/}
                            {/*</h4>*/}
                            {/*</div>*/}
                            <div className="row">
                                {loading && (
                                    <CustomLoader2 />
                                )}
                                {!loading && (
                                    <>  
                                        {(reportType ===  '' || reportType === 'corrective') &&
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    {/* <VerticalGraph
                                                        title="WorkOrder"
                                                        reportVal={reportVal.corrective}
                                                    /> */}
                                                    <VerticalGraph 
                                                        title="Move In"
                                                        openValue={reportVal.movein.open}
                                                        closeValue={reportVal.movein.close}
                                                        totalValue={reportVal.movein.total}
                                                        />
                                                </div>
                                            </div>
                                        }
                                        {(reportType === '' || reportType === 'clearance') && 
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    {/* <VerticalGraph
                                                        title="Clearance"
                                                        reportVal={reportVal.clearance}
                                                    /> */}
                                                    <VerticalGraph 
                                                        title="Move Out"
                                                        openValue={reportVal.moveout.open}
                                                        closeValue={reportVal.moveout.close}
                                                        totalValue={reportVal.moveout.total}
                                                        />
                                                </div>
                                            </div>
                                        }
                                        {(reportType === '' || reportType === 'preventive') && 
                                            <div className="col-md-3 col-4 mx-auto">
                                                <div className="inspection-chart">
                                                    {/* <VerticalGraph
                                                        title="Preventive"
                                                        reportVal={reportVal.preventive}
                                                    /> */}
                                                    <VerticalGraph 
                                                        title="Schedule Visits"
                                                        openValue={reportVal.schedule.open}
                                                        closeValue={reportVal.schedule.close}
                                                        totalValue={reportVal.schedule.total}
                                                        />
                                                </div>
                                            </div>
                                        }
                                        {/* <div className="col-md-3">

                                        </div> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}