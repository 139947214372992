import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { TechnicianBasicDetails } from './TechnicianBasicDetails/TechnicianBasicDetails';
import { TechnicianAdvancedDetailsAndTabs } from './TechnicianAdvancedDetailsAndTabs/TechnicianAdvancedDetailsAndTabs';
import { getMyTeamUserList } from '../../../../../constants/axios/apis';

const TechnicianDetailWrapper = ({approval}:any) => {
    let params: any = {};
    params = useLocation();
    // const { approval } = params.state || undefined;
    const userID = approval?.object_id

    const [details, setDetails] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    useEffect(() => {
        setLoading(true)
        let payload = '?user_ids[0]=' + userID
        getMyTeamUserList(payload)
            .then((response:any) => {
                if(response.errorCode === 0){
                    setDetails(response?.data?.[0] ? response?.data?.[0] : undefined)
                }
                setTimeout(()=>{setLoading(false)}, 500)
                
            }).catch((e:any)=>{

            })
    }, [userID]);

    return (
        <>
            <TechnicianBasicDetails userDetails={details} loading={loading} approval={approval}/>
            <TechnicianAdvancedDetailsAndTabs userDetails={details} loading={loading}/>
        </>
    );
}

export { TechnicianDetailWrapper }

