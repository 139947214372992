import React,{ useEffect, useState } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {MyTeamGraphTabs} from '../MyTeamGraph/MyTeamGraphTab';
import {MyTeamBarChart} from '../MyTeamGraph/MyTeamBarChart';

import { useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import { CustomLoader2 } from '../../../../constants/generics/customActios';

export const MyTeamGraphWrapper:React.FC<any> = ({fcsWorkOrderList}) => {
    const state:any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    if(userToken === "undefined"){
        // token undefined
    }
    
    // const [fcsWorkOrderList, setFcsWorkOrderList] = useState<IState['APIState']>({
    //     loading: false,
    //     response: [],
    //     error: ''
    // });

    const {loading, response: workOrders, error} = fcsWorkOrderList;

    //  useEffect(()=>{
    //     getFcsWorkOrders(setFcsWorkOrderList,userToken);
    // },[]);

    
    function yearMonths(){
        return ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    }
    function getWorkOrderMonths(invoices: any[],status: string){
        const months = yearMonths();

        let invoiceMonth = new Set(
            invoices.filter(invoice=>invoice.status === status).map(invoice => {
                let date = new Date(invoice.date);
                return date.getMonth()
            }).sort().map(d=>{
                return months[d];
            })
        );
        let invoiceMonths: any[] = [];
        
        invoiceMonth.forEach(function(month: any) {
            invoiceMonths.push(month);
        });

        return invoiceMonths;
    }

    function getTotalWorkOrderByMonth(invoices: any[], status: string, invoiceMonths: any[]){
        const months = yearMonths();
        const returnArray: any[] = [];

        let invoice = invoices.filter(invoice=>invoice.status === status)

        invoiceMonths.forEach (function(month: any) {
            let totalInvoice = invoice.filter(inv=>{
                let d = new Date(inv.date);

                return months[d.getMonth()] === month;
            });
            returnArray.push(totalInvoice.length);
        })
        return returnArray;
    }

    const [chartDataOpen, setChartDataOpen] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'Open',
                data: [],
                backgroundColor: '#FAA77B',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });
    const [chartDataOnHold, setChartDataOnHold] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'On Hold',
                data: [],
                backgroundColor: '#FAA77B',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });
    const [chartDataCompleted, setChartDataCompleted] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'Completed',
                data: [],
                backgroundColor: '#FAA77B',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });

    function setChartData(setState: any,label: any,data: any): void{
        setState({
            labels: label,
            datasets: [
                {
                    datalabels: {
                        color: "#EAEFF5",
                        font: {
                            size: 16,
                            family: "'Poppins', sans-serif",
                            lineHeight: 0.5,
                        },
                    },
                    label: label,
                    data: data,
                    backgroundColor: '#FAA77B',
                    barThickness: 45,
                    maxBarThickness: 45,
                    borderRadius: {
                        bottomRight: 5,
                        bottomLeft: 5,
                        topRight: 5,
                        topLeft: 5,
                    }
                }
            ],
        })
    }
    

    useEffect(()=>{
        if(Array.isArray(workOrders)){
            if(workOrders.length>0){
                let pendingInvoiceMonth = getWorkOrderMonths(workOrders, "Open");
                let totalPendingInvoiceByMonth = getTotalWorkOrderByMonth(workOrders, "Open", pendingInvoiceMonth);
                setChartData(setChartDataOpen, pendingInvoiceMonth, totalPendingInvoiceByMonth);
                
                let approvedInvoiceMonth = getWorkOrderMonths(workOrders, "On hold");
                let totalApprovedInvoiceByMonth = getTotalWorkOrderByMonth(workOrders, "On hold", approvedInvoiceMonth);
                setChartData(setChartDataOnHold, approvedInvoiceMonth, totalApprovedInvoiceByMonth);

                let rejectedInvoiceMonth = getWorkOrderMonths(workOrders, "Completed");
                let totalRejectedInvoiceByMonth = getTotalWorkOrderByMonth(workOrders, "Completed", rejectedInvoiceMonth);
                setChartData(setChartDataCompleted, rejectedInvoiceMonth, totalRejectedInvoiceByMonth);
            }
        }
    },[workOrders]);

    const [activeOption, setActiveOption] = useState<any>('open');

    return (
        <div className="row">
            <div className="col-12">
                <MyTeamGraphTabs activeOption={activeOption} setActiveOption={setActiveOption}/>
                {loading ?
                    <div className="row">
                        <div className="col-12">
                            {/* <Loader /> */}
                            <CustomLoader2 height={'166'}/>
                        </div>
                    </div>
                    :
                    <MyTeamBarChart 
                        data={
                            activeOption === 'open' ? chartDataOpen
                            : activeOption === 'onhold' ? chartDataOnHold
                            : activeOption === 'completed' ? chartDataCompleted
                            : {}
                        }
                    />
                }
            </div>
        </div>
    )
    
    // return (
    //     <div className="">
    //         <div className="col-12">
    //             <Switch>
    //                 <Route path='/fc-su-myteam/open'>
    //                     <MyTeamGraphTabs />
    //                     <MyTeamBarChart data={chartDataOpen} />
    //                 </Route>
    //                 <Route path='/fc-su-myteam/onhold'>
    //                     <MyTeamGraphTabs />
    //                     <MyTeamBarChart data={chartDataOnHold} />
    //                 </Route>
    //                 <Route path='/fc-su-myteam/completed'>
    //                     <MyTeamGraphTabs />
    //                     <MyTeamBarChart data={chartDataCompleted} />
    //                 </Route>
    //             </Switch>
    //         </div>
    //     </div>
    // );
}