import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {Editor} from './Editor';


export function EditEmailTemplate() {
    return (
        <>
            <div className="row align-items-center mb-5 community">
                <div className="col-md-5">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'email-templates'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>
                        Update Email
                    </h5>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-12">
                    <form >
                        <div className="card form-add-design">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <div className="mb-7">
                                            <label className="d-block w-100 text-uppercase mb-0 font-13">Template Name</label>
                                            <input type="text" className="form-control font-13 form-height" name="Template Name" placeholder="Template Name" value="send_offer" readOnly />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <div className="mb-7">
                                            <label className="d-block w-100 text-uppercase mb-0 font-13">Subject</label>
                                            <input type="text" className="form-control font-13 form-height" name="Subject" placeholder="Subject" value="You have received an offer for" readOnly />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <div className="mb-7">
                                            <label className="form-label d-block text-uppercase mb-0 font-13" >File upload</label>
                                            <input className="form-control font-13" type="file" id="formFile" />
                                            {/* <div className="form-control image-email">
                                                <img src={uploader} className="img-fluid pe-1" width="20px" />
                                                <span className="font-13 font-weight-semibold"> Upload </span>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <div className="form-group mb-0">
                                            <label className="d-block w-100 text-uppercase mb-0 font-13"> STATUS  </label>
                                            <div className="row mt-2">
                                                <div className="col-md-6">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <label className="form-check-label" >
                                                            Active
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <label className="form-check-label" >
                                                            In Active
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Dynamic Value </label>
                                        <div className="row priview-community mt-5">
                                            <div className="col-md-2 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked"/>
                                                    <label className="form-check-label">agent_name</label>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked"/>
                                                    <label className="form-check-label">agency_name</label>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked"/>
                                                    <label className="form-check-label">person_name</label>
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-6 mb-5">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckChecked"/>
                                                    <label className="form-check-label">to</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">DESCRIPTION</label>
                                            <Editor />
                                        </div>
                                    </div>
                                    <div className="col-12 text-end">
                                        <button  className='btn btn-primary fw-bold px-6 py-3'>
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
