import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
export function RuleModal() {


    return (
        <div className="modal fade" aria-hidden='true' id="Rule-popup">
            <div className="modal-dialog modal-lg modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Add Rule</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className='row'>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">Rule Name</label>
                                    <input type="text" className="form-control fw-bolder" placeholder="Enter Rule Name" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">AGENT TYPE</label>
                                    <select className="form-control form-select fw-bolder"
                                    > 
                                        <option selected>Please Select </option>
                                        <option >User </option>
                                        <option>Role</option>
                                        <option>Group</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">SOURCE</label>
                                    <select className="form-control form-select fw-bolder"
                                    > 
                                        <option selected>Please Select </option>
                                        <option > </option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">Lease Type</label>
                                    <select className="form-control form-select fw-bolder"
                                    > 
                                        <option selected>Please Select </option>
                                        <option > Buyer</option>
                                        <option>Lease</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">PRIORITY</label>
                                    <select className="form-control form-select fw-bolder"
                                    > 
                                        <option selected>Please Select </option>
                                        <option >Urgent</option>
                                        <option>Low</option>
                                        <option>High</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">NATIONALITY</label>
                                    <select className="form-control form-select fw-bolder"
                                    > 
                                        <option selected>Please Select </option>
                                        <option >UAE</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">LANGUAGE</label>
                                    <select className="form-control form-select fw-bolder"
                                    > 
                                        <option selected>Please Select </option>
                                        <option >Arabic</option>
                                        <option >Africans</option>
                                        <option >Albanians</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">USER</label>
                                    <select className="form-control form-select fw-bolder"> 
                                        <option selected>Nothing Selected </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="form-group mb-0 text-end mt-2">
                                    <button type="button" className="btn btn-outline-primary px-4 radius-10 font-12 mb-2 mb-md-0" data-bs-dismiss="modal">Close</button>
                                    <input type="submit" className="btn btn-primary mx-2 font-12 radius-10 text-uppercase d-inline-flex flex-wrap align-items-center px-4 float-right" value="Save"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}