import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function TenantOverview(props: any) {
    const { t } = useTranslation();

    return (
        <div className="row g-0">
            <div className="col-md-12 ">
                <div
                    className="card campaign-card rounded-5 mb-6 c-pointer"
                    onClick={props.onTenantStepper}
                >
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-lg-7 col-xl-7">
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="text-black font-16 fw-bold mb-0">
                                            {t("Resident's Details")}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-5 text-end my-auto">
                                <span className="c-pointer">
                                    <i className="bi bi-chevron-right font-15 fw-boldest"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="card campaign-card rounded-5 mb-6 c-pointer"
                    onClick={props.onTenantStepper}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-7 col-xl-7">
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="text-black font-16 fw-bold mb-0">
                                            {t("Contact information")}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-5 text-end my-auto">
                                <span className="c-pointer">
                                    <i className="bi bi-chevron-right font-15 fw-boldest"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="card campaign-card rounded-5 mb-6 c-pointer"
                    onClick={props.onTenantStepper}
                >
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-lg-7 col-xl-7">
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="text-black font-16 fw-bold mb-0">
                                            {t("Payment Plan Details")}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-5 text-end my-auto">
                                <span className="c-pointer">
                                    <i className="bi bi-chevron-right font-15 fw-boldest"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="card campaign-card rounded-5 mb-6 c-pointer"
                    onClick={props.onTenantStepper}
                >
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-lg-7 col-xl-7">
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="text-black font-16 fw-bold mb-0">
                                            {t("Upload Documents")}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-5 text-end my-auto">
                                <span className="c-pointer">
                                    <i className="bi bi-chevron-right font-15 fw-boldest"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="btn btn-primary fw-bold px-12 py-3" disabled>{t("Done")}</button>
                </div>
            </div>
        </div>
    );
}