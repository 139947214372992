import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

export function ApprovalHistory() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 pt-8 pb-3">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="font-14 text-theme mb-6">
                                        {t("Approval History")}
                                    </h4>
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approval ID")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">1</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Status")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{t("Approved")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">Mohammed</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">20-Jun-2022</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Recommendation")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Comments")}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approval ID")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">1</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Status")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{t("Pending")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">Mohammed</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">20-Jun-2022</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Recommendation")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Comments")}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approval ID")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">1</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Status")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{t("In Process")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">Mohammed</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">20-Jun-2022</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Recommendation")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Comments")}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}