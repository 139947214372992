import React, { useState, useEffect } from 'react'
export function Offers() {
return (     
<div className="row">
    <div className="col-md-12">
        <div className="card border-0 mb-3 radius-15 p-4">
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-md-12">
                    <div className="dash-com-scroll">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689245721                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">shivak</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">shiva0909@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">34,567</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689245673                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-yellow">Closed</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">RadhaKrishna</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">radha.k@exalogic.co</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">53,653</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234406                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234406                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234406                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234406                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234406                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234406                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234406                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689234340                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">2 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689231869                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">6 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689231869                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">6 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1689231869                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">KeerthanaP</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">8884350557</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">keerthana1542@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">50,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">6 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">13/07/2023</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">23/07/2023</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1627911622                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">RadhaKrishna</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">radha.k@exalogic.co</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">1,000,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">02/08/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">12/08/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1627624506                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">RadhaKrishna</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">radha.k@exalogic.co</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">387,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">1 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">30/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">09/08/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1627555527                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">SanjeevB</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">7411502041</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">sanjeev.b@exalogic.co</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">3,694,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">25%</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">29/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">08/08/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1627538558                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">SanjeevB</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">7411502041</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">sanjeev.b@exalogic.co</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">3,694,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">29/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">08/08/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1627538198                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">SanjeevB</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">7411502041</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">sanjeev.b@exalogic.co</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">3,694,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">29/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">08/08/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1627285696                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">demoapp@nomail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">3,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">2 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">26/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">05/08/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1627208971                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">demoapp@nomail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">45,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">2 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">25/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">04/08/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1626675345                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">UserDemo</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">demo@user.in</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">3,694,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">12 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">19/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">29/07/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1626422324                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">RadhaKrishna</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">7892876246</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">radha.k@exalogic.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">25,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">2 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">16/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">26/07/2021</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="card radius-15 bg-light">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="row no-gutters">
                                                    <div className="col-sm-9">
                                                        <h6 className="font-12 text-black m-0 d-inline"><span className="text-uppercase">Offer Ref: </span></h6>
                                                        <h6 className="font-weight-bold ellipsis mb-2 font-13 text-warning d-inline">
                                                        REF-OF-1625814182                                                                                                        
                                                        </h6>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-info">Open</h6>
                                                    </div>
                                                </div>
                                                <hr className="my-2"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Name</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Phone No</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">-</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Contact Email</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">demoapp@nomail.com</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Price</span></h6>
                                                    <p className="font-12 mb-2 ellipsis">77,000</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">No. of Installments</span></h6>
                                                    <p className="font-12 mb-0">2 Payment</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Date</span></h6>
                                                    <p className="font-12 mb-2">09/07/2021</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                                <div className="">
                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Offer Valid Date</span></h6>
                                                    <p className="font-12 mb-2">-</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-12 text-end">
                                                <a href="#" className="text-decoration-none">
                                                <button type="button" className="btn btn-primary font-12 btn-sm py-1"> View More </button>
                                                </a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-12 mt-3 text-right">
                    <a href="#" className="btn btn-primary radius-10 text-uppercase px-4 font-12"> View More Offers </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}