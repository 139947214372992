import React, {useState, useEffect, useRef} from 'react'
import {Link, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import uploadImg from "../../../../assets/images/file-upload.svg";
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useHistory} from 'react-router-dom'
import {
  createReportRequest,
  getFCSSServiceRequestsCategories,
} from '../../../../constants/axios/apis'
import useRequestGet from '../../../../hooks/useRequestGet'
import {CustomRequestSuccessfulPopup} from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import IntlTelInput from 'react-intl-tel-input'
import { useTranslation } from 'react-i18next'

export function ReportIssues({setParkingSuccessMsg}:any) {
  const { t } = useTranslation();
  let params: any = {}
  params = useLocation()
  const {selectedSlot} = params.state
  
  const history = useHistory()
  const successRef: any = useRef(null)
  
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [parkingTypes, setParkingTypes] = useState<any>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const {requestState: masterData, refreshRequest: refreshMasterData} = useRequestGet(
    '/getMasterData'
  )
  const [outcomeOptions, setOutcomeOptions] = useState<any>([])
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [issueId, setIssueId] = useState<any>([])

  const validationSchema = Yup.object().shape({
    // buildingName: Yup.string().required("Select a building"),
    // unitNo: Yup.string().required("Select a unit no."),
    // tenantName: Yup.string().required("Required!"),
    // contactNumber: Yup.string().required("Required!").matches(
    //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //     "contact number is not valid"
    // ).max(15, "contact number is not valid"),
    issue_type: Yup.string().required(t('Select a issue type!')),
    outcome: Yup.string().required(t('Select an outcome!')),
    description: Yup.string()
      .required(t('Description is Required!'))
      .matches(/^[^\s].*/, (t('Description cannot start with a blankspace!')))
      .max(250, (t('Description should be 250 characters!'))),
    image: Yup.mixed()
      .test('type', (t('Only the following formats are acceptable: .jpeg, .jpg, .png')), (value) => {
        if (value) {
          return (
            value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png'
          )
        }
        return true
      })
      .test('fileSize', (t('The file is too large!')), (value) => {
        if (value) {
          return value?.size <= 2000000
        }
        return true
      }),
  })

  const initialValues = {
    property_id: selectedSlot.property_id,
    slot_id: selectedSlot.id,
    issue_type: '',
    outcome: '',
    description: '',
    image: '',
  }

  const handleUpload = () => {
    inputRef.current?.click()
  }
  const handleDisplayFileDetails = (e: any) => {
    inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
    formik.setFieldTouched('image')
    formik.setFieldValue('image', e.currentTarget.files[0])
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setSubmitError('')
      setTimeout(() => {
        createReportRequest(
          values.property_id,
          values.slot_id,
          values.issue_type,
          values.outcome,
          values.description,
          values.image
        )
          .then((response: any) => {
            setLoading(false)

            if (response.errorCode === 1)
             setSubmitError(response.errorDescription)
            else if (response.errorCode === 0) {
              setParkingSuccessMsg(response.description)
              formik.resetForm()
              setLoading(false)
              var ev = new Event('click', {bubbles: true})
              successRef.current.dispatchEvent(ev)
              setTimeout(() => {
                history.push('/parking/all')
              }, 500)
            } else
              setSubmitError(t('Some error occured. Please try again !'))
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t('Invalid Data Provided'))
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (Array.isArray(masterData.response)) {
      if (masterData.response.length > 0) {
        const outcomeVal = masterData.response.find((el: any) => el.id === 24)
        setOutcomeOptions(outcomeVal.master_data)
      }
    }
  }, [masterData])

  useEffect(() => {
    getFCSSServiceRequestsCategories().then((response) => {
        let parking_types = response?.data?.filter((item: any) => item.categoryName === "Parking Issue")
            .flatMap((data: any) => data.service_category_types)
        setParkingTypes(parking_types)
    })
  }, [])

  return (
    <>
      <div className='com-card-section myunit-page'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/parking/all'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </Link>
              {t("Report a Issue")}
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <form onSubmit={formik.handleSubmit}>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-7'>
                          <label className='form-label fw-bolder'>{t("Building Name")}</label>
                          <input
                           type='text'
                            className='form-control fw-bolder form-control-solid bg-light'
                            disabled
                            value={selectedSlot.building_name}
                            placeholder=''
                          />
                         </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-7'>
                          <label className='form-label fw-bolder'>{t("Unit")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid bg-light'
                            disabled
                            value={selectedSlot.property_code}
                            placeholder=''
                          />
                          </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-7'>
                          <label className='form-label fw-bolder'>{t("Tenant Name")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid bg-light'
                            disabled
                            value={
                              selectedSlot.tenantName ? selectedSlot.tenantName : (t('Not Available'))
                            }
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-7'>
                          <label className='form-label fw-bolder'> {t("Contact Number")} </label>

                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid bg-light'
                            disabled
                            value={
                              selectedSlot.tenantNumber
                                ? selectedSlot.tenantNumber
                                : 'Not Available'
                            }
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-7'>
                          <label className='form-label fw-bolder'>
                            {' '}
                            {t("Issue Type")} <span className='text-danger'> * </span>{' '}
                          </label>
                          <select
                            {...formik.getFieldProps('issue_type')}
                            className='form-control form-select fw-bolder form-control-solid'
                          >
                            <option value=''>{t("Select")}</option>
                            {/* {issueId ? serviceRequestOptions : ""} */}
                            {/* {services.map((el: any, index: number) => {
                                                        return (
                                                            <option key={index} value={el.id}>{el.issue}</option>
                                                        )
                                                    })} */}
                            {parkingTypes.map((el: any, index: number) => 
                              <option key={index} value={el.id}>{el.name}</option>
                            )}
                            {/* <option value='4'>Parking Issue</option> */}
                          </select>
                          {formik.touched.issue_type && formik.errors.issue_type ? (
                            <small className='text-danger'>{formik.errors.issue_type}</small>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-7'>
                          <label className='form-label fw-bolder'>
                            {' '}
                            {t("Outcome")} <span className='text-danger'> * </span>{' '}
                          </label>
                          <select
                            {...formik.getFieldProps('outcome')}
                            className='form-control form-select fw-bolder form-control-solid'
                          >
                            <option value=''>
                              {t("Select")}
                            </option>
                            {outcomeOptions.map((el: any, index: number) => {
                              return (
                                <option key={index} value={el.value}>
                                  {el.name}
                                </option>
                              )
                            })}
                          </select>
                          {formik.touched.outcome && formik.errors.outcome ? (
                            <small className='text-danger'>{formik.errors.outcome}</small>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-md-8 col-12'>
                        <div className='mb-7'>
                          <label className='form-label fw-bolder'>
                            {t("Description")} <span className='text-danger'> * </span>{' '}
                          </label>
                          <textarea
                            {...formik.getFieldProps('description')}
                            className='form-control fw-bolder form-control-solid'
                            data-kt-autosize='true'
                            rows={2}
                            placeholder={t('Type here...')}
                          />
                          {formik.touched.description && formik.errors.description ? (
                            <small className='text-danger'>{formik.errors.description}</small>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-0 upload-input'>
                          <label className='form-label fw-bolder d-block'>{t("Upload Image")}</label>
                          <input
                            ref={inputRef}
                            onChange={(e) => handleDisplayFileDetails(e)}
                            className='d-none'
                            type='file'
                            onBlur={formik.handleBlur}
                          />
                          <button
                            type='button'
                            onClick={handleUpload}
                            className={`w-100 py-3 ellipsis btn btn-outline-${
                              uploadedFileName ? 'primary' : 'primary'
                            }`}
                          >
                            <span>
                              {uploadedFileName ? (
                                uploadedFileName
                              ) : (
                              <span className="txt">
                                <img
                                        alt="File"
                                        src={uploadImg}
                                className="position-relative"
                                loading="lazy"/>
                                  {t("Upload")}{' '}
                                  <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                </span>
                              )}{' '}
                            </span>
                          </button>
                          {formik.touched.image && formik.errors.image && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.image}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-12 text-end'>
                        <button
                          onClick={() => { history.goBack() }}
                          type='button'
                          className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary fw-bold px-10 py-3'
                          disabled={loading}
                        >
                          {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                        {submitError && (
                          <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                            {submitError}
                          </div>
                        )}
                        {/* <p
                          ref={successRef}
                          data-bs-target='#req-submitted-popup'
                          data-bs-toggle='modal'
                          data-bs-dismiss='modal'
                          className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                        />
                        <CustomRequestSuccessfulPopup
                          successMessage={t('Issue Reported Successfully')}
                          href={'/parking/all'}
                        /> */}
                        <p
                          ref={successRef}
                          data-bs-target='#req-submitted-popup-parking'
                          data-bs-toggle='modal'
                          data-bs-dismiss='modal'
                          className='invisible d-none btn btn-primary fw-bold px-15 py-3'
                        /> 
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
