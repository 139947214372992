import React, {FC, useEffect, useRef, useState, Component} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import Home from "../../../../../assets/images/my-unit.png";
import { useTranslation } from 'react-i18next'

const Step3: FC = () => {
    const intl = useIntl()
  const location = useLocation()
  const [menu, setMenu] = useState('')
  const { t } = useTranslation();

  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  useEffect(() => {
    if (window.location.pathname != menu) {
      setMenu(window.location.pathname)
      console.log(window.location.pathname)
    }
  })

  return (
      <>
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-12">
                    <div className="card shadow-none">
                        <div className="card-body p-0">
                            <div className="row form-add-design">
                                <div className="col-md-12 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder d-block mb-5">{t("Property Available for")}</label>
                                        <div className="btn-group" id="property_available">
                                            <input className="form-check-input me-5" type="radio" name="btnradio" id="btnradio3" checked/>
                                            <label className="form-check-label me-5 text-black" htmlFor="btnradio3"> {t("Rent")} </label>

                                            <input className="form-check-input me-5" type="radio" name="btnradio" id="btnradio4" />
                                            <label className="form-check-label text-black" htmlFor="btnradio4"> {t("Not Available")} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <div className="form-group">
                                            <label className="form-label fw-bolder w-100">
                                                <span>{t("Expected Rent")}</span>
                                                <div className="form-check form-check-sm custom float-end">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                    <label className="form-check-label font-12 text-black fw-normal" htmlFor="flexCheckDefault">
                                                        {t("Negotiable")}
                                                    </label>
                                                </div>
                                            </label>
                                            <div className="d-flex fdrr-rtl">
                                                <input type="text" name="" className="form-control expected-rent-input" value="" placeholder={t("Enter")} />
                                                <select className="bg-lightgray expected-rent-selectbox font-14 ps-7" data-control="select2" data-allow-clear="true" >
                                                    <option value="" selected>{t("AED")}</option>
                                                    <option value="">{t("Dollar")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Service Charges Applicable")} </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>5</option>
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10 position-relative">
                                        <label className="form-label fw-bolder">{t("Available From")}</label>
                                        <input type="date" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Furnishing")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Parking")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>Ground</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Preferred Tenants")}</label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>{t("Please Select")}</option>
                                            <option>Active</option>
                                            <option>Open</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10 position-relative">
                                        <label className="form-label fw-bolder">{t("Payments")} </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="3" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Property Description")}</label>
                                        <textarea
                                            className='form-control fw-bolder form-control-solid'
                                            data-kt-autosize='true'
                                            rows={3}
                                            placeholder={t("Type here...")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
  )
}

export {Step3}
