import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {PropertyDetails} from './building-details/PropertyDetails'
import { Notes } from './building-details/Notes'
import { ActivityLogs } from './building-details/ActivityLogs'
import {NearBy} from './building-details/NearBy'
import {Offers} from './building-details/Offers'
import {Owner} from './building-details/Owner'

export function PreviewUnit() {
    const [tabPosition, setTabPosition] = useState("1");
    const buildingTab = (i: any) => {
        setTabPosition(i)
    }

    return (      
        <>
        <div className="row align-items-center mb-5 community">
            <div className="col-md">
                <h5 className="theme-color font-22 d-inline-block float-left">
                    <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                        <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                    </Link>Wilton Park Residencies Stores
                </h5>
            </div>
            <div className="col-md-auto">
                <span className="d-inline-block radius-10 bg-warning2 rounded px-4 py-2 text-center font-12 text-white">
                    <b>Property Score<br/>25</b>
                </span>
            </div>
        </div>

        <div className="row my-4">
            <div className="clo-md-12 col-lg-8 col-12 text-center">
                <img src="https://pm-crm.realcube.estate/img/no-product-image.png" alt="building image"/>
            </div>
            <div className="col-md-12 col-lg-4 col-12">
                <h3 className="text-black font-weight-bold font-2rem"> 82,000 AED </h3>
                <div className='row mb-4'>
                    <div className="col-lg-6 col-md-3 col-sm-4 col-6">
                        <span className="text-warning font-14 font-weight-bold"> Studio </span> 
                        <span className="text-theme-black font-14"> Beds </span>
                    </div>
                    <div className="col-lg-6 col-md-3 col-sm-4 col-6">
                        <span className="text-warning font-14 font-weight-bold"> Studio </span> 
                        <span className="text-theme-black font-14"> Baths </span>
                    </div>
                    <div className="col-lg-6 col-md-3 col-sm-4 col-6">
                        <span className="text-warning font-14 font-weight-bold"> Studio </span> 
                        <span className="text-theme-black font-14"> Parking </span>
                    </div>
                    <div className="col-lg-6 col-md-3 col-sm-4 col-6">
                        <span className="text-warning font-14 font-weight-bold"> 275 </span> 
                        <span className="text-theme-black font-14"> sqft </span>
                    </div>
                </div>
                <p className="mb-3 font-14">Studio</p>
                <p className="mb-3 font-14"><i className="fas fa-map-marker-alt"></i> Jumeriah, Dubai </p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-4 radius-15 h-100">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> RERA Number : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">---</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Permit Number : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">--- </span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Reference ID : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">REF-UN-1605671718</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Property Type : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">Apartment</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Builtup Area : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">2964 sq ft</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Plot Area : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">275 sq ft</span>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 align-items-center">
                                            <label className="font-14 text-theme-black font-weight-500 mb-0 col-sm-6"> Completion : </label>
                                            <div className="col-sm-6">
                                                <span className="font-14 text-theme-black font-weight-500">---</span>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="pub-status d-flex align-items-center justify-content-center mt-3 text-center">
                                    <i className="fas fa-check-circle text-warning font-16 mr-1"></i>
                                    <span className="text-white font-14 text-uppercase font-weight-semibold ms-3"> Active </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='d-flex overflow-auto'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-4 flex-nowrap'>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(1)}>
                        Property Details
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(2)}>
                        Near by
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(3)}>
                        Owner
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(4)}>
                        Offers
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '5' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(5)}>
                        Notes
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to className={`${tabPosition == '6' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => buildingTab(6)}>
                        Activity Logs
                    </Link>
                </li>
            </ul>
        </div>
        
        {   tabPosition == '1' ? <PropertyDetails/> : 
            tabPosition == '2' ? <NearBy/> :
            tabPosition == '3' ? <Owner/> :
            tabPosition == '4' ? <Offers/> :
            tabPosition == '5' ? <Notes/> :
            tabPosition == '6' ? <ActivityLogs/> : ''
        }
        
    </>
    )
}

