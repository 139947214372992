import React, { useState, useEffect, useRef } from 'react';
import BedRoom from "../../../../assets/images/bedroom_icon.png";

import { getWorkOrderChecklist } from '../../../../../constants/axios/apis';
import { ImageViewerWithPopup } from '../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';

import { useTranslation } from 'react-i18next';

export function ChecklistView({workOrder}:any) {
    const { t } = useTranslation();
    const [checklistData, setChecklistData] = useState<any>([]);
    const [checklistError, setChecklistError] = useState('');
    const [taskData, setTaskDetails] = useState<any>({
        note: '',
        photo: '',
        signature: ''
    });
    useEffect(()=>{
        getWorkOrderChecklist('?work_order_id=' + workOrder?.id)
            .then((response:any) => {
                // console.log(response, 'getWorkOrderChecklist');
                
                if(response.errorCode === 0){
                    if(Array.isArray(response?.data) && response?.data.length >0){
                        // console.log('array data');
                        setChecklistData([...response?.data])
                        setTaskDetails({
                            note: response?.data[0].note,
                            photo: response?.data[0].photo?.damage_images,
                            signature: response?.data[0].customer_signature
                        })
                    }else if(response?.data){
                        // console.log('single data');
                        const temp = []
                        temp.push(response?.data)
                        setChecklistData(temp)
                        setTaskDetails({
                                    note: response?.data.note,
                                    photo: response?.data.photo?.damage_images,
                                    signature: response?.data.customer_signature
                                })
                    }
                }else if(response.errorCode === 1){
                    setChecklistData([])
                    setChecklistError(response.errorDescription)
                }else{
                    setChecklistData([])
                    setChecklistError(t("Some error occured!"))
                }
            }).catch((e:any)=>{
                setChecklistData([])
                setChecklistError(t("Some error occured!"))
                console.log(e, 'error in checklist');
            })
    },[]);
    
    // console.log(checklistData, 'checklistData');
    
    return (
        <div className="row border-top py-10 mt-5">
            <h5 className="mb-5">{t("Task Completion Details")}</h5>
            <div className="row form-add-design">
                <div className="col-md-6 col-12">
                    <div className="mb-7">
                        <label className="form-label fw-bolder"> {t("Checklist")} </label>
                        <div className="col-md-12">
                            {
                                checklistData.map((checkList: any, index: number)=>{
                                    return (
                                        <div className="checklist-box active shadow-none bg-light" key={index}>
                                            <h4 className="question-checklist text-dark">{index+1}. {checkList.checklist}</h4>
                                            <div className='row'>
                                                <div className='col-6 col-lg-4'>
                                                    <div className="btn-group mb-0" role="group" aria-label="Basic radio toggle button group" style={{pointerEvents:'none'}}>
                                                        { checkList.answer === 0 ?
                                                            <>
                                                                <input 
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    id={`btnradio${index*3+2}`}
                                                                    name={`btnradio-${index}`}
                                                                    defaultChecked={true}
                                                                />
                                                                <label 
                                                                    className="btn btn-outline-primary btn-fail"
                                                                    htmlFor={`btnradio${index*3+2}`}>Fail</label>
                                                            </>
                                                        : checkList.answer === 1 ?
                                                            <>
                                                                <input 
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    id={`btnradio${index*3+1}`}
                                                                    name={`btnradio-${index}`}
                                                                    defaultChecked={true}
                                                                />
                                                                <label 
                                                                    className="btn btn-outline-primary btn-pass" 
                                                                    htmlFor={`btnradio${index*3+1}`}>Pass</label>
                                                            </>
                                                            
                                                        // : checkList.answer === 2 ?
                                                        : 
                                                            <>
                                                                <input 
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    id={`btnradio${index*3+3}`}
                                                                    name={`btnradio-${index}`}
                                                                    defaultChecked={true}
                                                                />
                                                                <label 
                                                                    className="btn btn-outline-primary btn-na" 
                                                                    htmlFor={`btnradio${index*3+3}`}>NA</label>
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            
                                            {/* <div className="checklist-action-items">
                                                <span className="me-5 c-pointer"><img src={NoteIcon} alt="Note" className="me-2 img-fluid" />Note</span>
                                                <span className="me-5 c-pointer"><img src={CameraIcon} alt="Camera" className="me-2 img-fluid" />Photo</span>
                                                <span className="me-5 c-pointer"><img src={ActionIcon} alt="Action" className="me-2 img-fluid" />Action</span>
                                            </div> */}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <div className="table-responsive">
                            <table className="table table-striped corrective_table">
                                <tbody>
                                    {checklistData?.map((checklist:any, index:any)=>{
                                        return (
                                            <tr className='p-4' key={index}>
                                                <td className="ps-4">{index+1}. </td>
                                                <td className="ps-3">{checklist.checklist}</td>
                                                <td className="pe-4">{checklist.answer === 0 ? 'Fail': checklist.answer === 1 ? 'Pass' : checklist.answer === 2 ? 'N/A' : checklist.answer}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-7">
                                <label className="form-label fw-bolder">{t("Note")} </label>
                                <textarea
                                    className='form-control fw-bolder form-control-solid bg-light border-0'
                                    data-kt-autosize='true'
                                    rows={3}
                                    disabled
                                    value={taskData.note}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-7 upload-input">
                                <label className="form-label fw-bolder d-block">{t("Customer Signature")}</label>
                                    <div className="position-relative">
                                        <div className="upload-image mt-0 w-100">
                                            <img src={taskData.signature} alt='image' className="form-img__img-preview w-100 border rounded"/>
                                            {/* <div className="close-icon" onClick={removeImg}>&times;</div> */}
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-7 upload-input">
                                <label className="form-label fw-bolder d-block">{t("Photo")}</label>
                                {(Array.isArray(taskData.photo) && taskData.photo.length > 0) ?
                                    <div className="d-flex flex-wrap">
                                        {taskData.photo.map((el:any, index:number)=>
                                            <div className="mb-3" key={index}>
                                                <ImageViewerWithPopup imageFile={el} noImage={el ? false : true} imgID={'checklist-img'+index}/>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    <ImageViewerWithPopup imageFile={taskData.photo} noImage={taskData.photo ? false : true} imgID={'checklist-img'}/>
                                }
                                    {/* <div className="position-relative">
                                        <div className="upload-image">
                                            <img src={taskData.photo} alt='image' className="form-img__img-preview"/>
                                            <div className="close-icon" onClick={removeImg}>&times;</div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    )
}