import React, { useState, useEffect, useRef } from 'react'
import HomePayment from '../../../../../assets/images/home-white.png'
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../../constants/generics/customActios'
import { financeSendInnovice } from '../../../../../constants/axios/apis'
import { CustomRequestSuccessfulPopup } from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'

export function PendingPayment(props: any) {
    const { t } = useTranslation()
    const buttonEl: any = useRef(null)
    const [isShow, setShow] = useState<any>(false)
    const [submitting, setSubmitting] = useState<any>({
        loading: false,
        error: false,
        message: '',
        isSubmitted: false
    })

    useEffect(() => {
        if (submitting.error)
            setTimeout(() => { setSubmitting((oState: any) => ({ ...oState, error: false })) }, 5000);
    }, [submitting.error])

    const sendInnovice = () => {
        const newPayload = {
            invoiceId: props.id,
            email: props.email,
        }
        setSubmitting({ loading: true, error: false, message: '', isSubmitted: false })
        financeSendInnovice(newPayload)
            .then((response: any) => {
                if (response.errorCode === 1) {
                    setSubmitting({ loading: false, error: true, message: response.errorDescription, isSubmitted: false })
                } else if (response.errorCode === 0) {
                    setSubmitting({ loading: false, error: false, message: response?.description, isSubmitted: true })
                    var ev = new Event('click', { bubbles: true })
                    buttonEl.current.dispatchEvent(ev)
                } else {
                    setSubmitting({ loading: false, error: true, message: t('Some error occured. Please try again !'), isSubmitted: false })
                }
            })
            .catch((e) => {
                setSubmitting({ loading: false, error: true, message: t('Some error occured. Please try again !'), isSubmitted: false })
            })
    }

    return (
        <div className='col-12 mb-6'>
            <div className='bg-dark-grey mb-5 invoice-no rounded-10 px-8 py-5'>
                <div className='row'>
                    <div className='col-md-6 mb-md-0 mb-3'>
                        <h6 className='font-14 fw-normal text-black mb-0'>
                            {props?.title_date}
                        </h6>
                    </div>

                    <div className='col-md-6 text-md-end text-start'>
                        <h6 className='font-14 fw-normal text-black mb-0'>
                            {t('Invoice Number')} - {' '}{`0000${props?.id}`}
                        </h6>
                    </div>
                </div>
            </div>
            <div className='row align-items-center'>
                <div className='col-sm-6 col-md-2 col-lg-2 col-xl-1 col-xxl-1 mb-md-0 mb-5'>
                    <div className='border-img rounded px-5 py-5 d-inline-block'>
                        <img src={HomePayment} className='img-fluid' />
                    </div>
                </div>
                <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-0 mb-5'>
                    <div className='ps-xxl-5 ps-xl-5'>
                        <h4 className='font-15 fw-bolder mb-3 text-black'> {''} </h4>
                        <h6 className='font-13 text-black mb-3 fw-normal'>
                            {t('Payment Method')} : {props?.method_text}
                        </h6>
                        <span
                            onClick={() => setShow(!isShow)}
                            className='text-theme d-block font-11 fw-bold c-pointer'
                        >
                            {isShow ? t('Hide Details') : t('View Details')}
                        </span>
                    </div>
                </div>
                <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-0 mb-5'>
                    <div className='ps-xxl-5 ps-xl-5'>
                        <h6 className='font-13 text-black mb-0 fw-normal'>
                            {t('Type of Payment')} : {props?.payment_type}
                        </h6>
                    </div>
                </div>
                <div className='col-sm-6 col-md-4 col-lg-4 col-xl-5 col-xxl-5 mb-md-0 mb-5 text-md-end text-start'>
                    <div className='pe-5'>
                        <h5 className='fw-bolder font-14 mb-0 text-gray-500'>
                            {t('AED')}{' '}
                            {props?.amount ? currencyFormater(props?.amount) : '-'}
                        </h5>
                    </div>
                </div>
            </div>
            {isShow ?
                <div className='row align-items-center mt-8'>
                    <div className='offset-lg-2 offset-xl-1 offset-xxl-1 col-lg-3 col-xl-3 col-xxl-3'>
                        <div className='ps-xxl-5 ps-xl-5'>
                            <div className='payment-progress'>
                                <div className='step'>
                                    <div>
                                        <div className='circle light'>
                                            <i className='bi bi-exclamation-circle-fill text-warning'></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='pay-status'> {t('Payment Due')} </div>
                                        <div className='time-info'>{props?.payment_date}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 col-xl-8 col-xxl-8'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='bg-generate rounded p-7'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-4'>
                                            <h5 className='text-theme fw-bolder font-14'>
                                                {props?.payment_type} {t('Invoice')}
                                            </h5>
                                            {props?.file &&
                                                <h6 className='text-black fw-normal font-11'>
                                                    {t('Invoice has been generated')}
                                                </h6>
                                            }
                                        </div>
                                        <div className='col-lg-4'>
                                            <a
                                                href={props?.file}
                                                className={'btn btn-light font-14 fw-bold w-100 py-3' + (props?.file ? '' : ' disabled')}
                                                target='_blank'
                                            >
                                                {t('View')}
                                            </a>
                                        </div>

                                        <div className='col-lg-4'>
                                            {submitting.loading ? (
                                                <button
                                                    type='button'
                                                    className='btn btn-primary fw-bold px-10 py-3 w-100'
                                                    disabled
                                                >
                                                    <span
                                                        className='indicator-progress'
                                                        style={{ display: 'block' }}
                                                    >
                                                        {t('Please wait...')}{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        type='submit'
                                                        onClick={() => sendInnovice()}
                                                        disabled={submitting.isSubmitted}
                                                        className='btn btn-primary font-14 fw-bold w-100 py-3'
                                                    >
                                                        <span className='indicator-label'>
                                                            {t('Send Invoice')}
                                                        </span>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {submitting.error && (
                            <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                                {submitting.message}
                            </div>
                        )}
                    </div>
                </div>
                : <></>
            }
            <p
                ref={buttonEl}
                data-bs-target={`#req-submitted-popup-send-invoice-${props.id}`}
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='invisible d-none btn btn-primary fw-bold px-10 py-3'
            />
            <CustomRequestSuccessfulPopup successMessage={submitting.message} customID={`-send-invoice-${props.id}`}/>
        </div>
    )
}
