import React, { useState, FC, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { Link, useParams, useLocation } from 'react-router-dom'
import { InsufficientNoticeTabs } from './InsufficientNoticeTabs/InsufficientNoticeTabs';
import { RequestDetails } from './RequestDetails/RequestDetails';
import { Documents } from './Documents/Documents';
import { ApprovalHistory } from './ApprovalHistory/ApprovalHistory';
import { Notes } from './Notes/Notes';
import { History } from './History/History';
import { Payment } from './Payment/Payment';
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import { Details } from './Details/Details';
import { ApproveButtons } from './ApproveButtons/ApproveButtons';
import { getPMapprovalList } from '../../../constants/axios/apis'
import * as PM from '../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../constants';
import { ViewDetailsFullWrapper } from '../../../pages/fmteam-approvals/components/ApprovalTabs/ViewDetails/ViewDetailsFullWrapper';
import { useTranslation } from 'react-i18next'

type LocationState = {
    id?: any
}

const InsufficientNoticeWrapper: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation<LocationState>();
    const { id } = location.state || {};// const [detailData, setDetailData] = useState<any>([])
    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            const config = "status=pending&id=" + id + "&status=pending"
            getPMapprovalList(config)
                .then((response: any) => {
                    if (response?.errorCode === 0) {
                        // setDetailData(''); //TODO after api ready
                        dispatch(PM.actions.contractsApprovalData(response?.data))
                    }
                })
                .catch((e) => {
                })
        } else {
            console.log("no id found");
        }
    }, [id])
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    contractApprovalData = contractApprovalData[0]

    return (
        <>
            <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="card-title font-18 text-black mb-6">
                                <Link to={'/pm-approvals/open-requests'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {t("Insufficient Notice")}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-7">
                            <Details />
                        </div>
                    </div>
                    {contractApprovalData?.subReference === 16 ? <ViewDetailsFullWrapper /> :
                        <Switch>
                            <Route path='/pm-insufficient-notice/request-details'>
                                <InsufficientNoticeTabs />
                                <RequestDetails />
                            </Route>
                            <Route path='/pm-insufficient-notice/payment'>
                                <InsufficientNoticeTabs />
                                <Payment />
                            </Route>
                            <Route path='/pm-insufficient-notice/documents'>
                                <InsufficientNoticeTabs />
                                <Documents />
                            </Route>
                            <Route path='/pm-insufficient-notice/history'>
                                <InsufficientNoticeTabs />
                                <History />
                            </Route>
                            <Route path='/pm-insufficient-notice/approval-history'>
                                <InsufficientNoticeTabs />
                                <ApprovalHistory />
                            </Route>
                            <Route path='/pm-insufficient-notice/notes'>
                                <InsufficientNoticeTabs />
                                <Notes />
                            </Route>
                        </Switch>}
                    <div className="row">
                        <div className="col-12 mt-1">
                            <ApproveButtons />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { InsufficientNoticeWrapper }

