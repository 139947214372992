import React, { useState, useEffect } from 'react'
import NearByLocation from './NearByLocation/NearByLocation'
import { Col, Row } from 'antd'
import MultipleImageUploadComponent from './imageUploader'

export function AddComunities() {
return (
<>
<div className='row align-items-center mb-5 community'>
    <div className="col-md-5">
        <h5 className="theme-color font-22 d-inline-block float-left">
            <a href="javascript:history.back()" className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                <i className="fa fa-angle-left font-20 font-weight-bold"></i>
            </a>
            Add Community 
        </h5>
    </div>
</div>
<div className="card">
    <div className="card-body">
        <div className='row form-add-design'>
            <div className="col-md-3">
                <label className="form-label fw-bolder text-uppercase">Community Code</label>
                <input type="text" className="form-control fw-bolder form-control-solid active" disabled value="REF-CM-1689164354" />
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Community Name</label>
                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder="Community Name" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Community Type</label>
                    <select className="form-control form-select fw-bolder form-control-solid"
                        >
                        <option >Please Select </option>
                        <option>Vertical </option>
                        <option>Horizontal</option>
                    </select>
                </div>
            </div>
        </div>
        <h6 className="mb-4">Aminities</h6>
        <div className='row aminities mb-5'>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="guestParking"/>
                    <label htmlFor='guestParking' className="btn btn-outline-primary font-12 d-block">Guest Parking</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="Gym"/>
                    <label htmlFor='Gym' className="btn btn-outline-primary font-12 d-block">Gym</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="powerBackup"/>
                    <label htmlFor='powerBackup' className="btn btn-outline-primary font-12 d-block">Power Backup</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="airConditioner"/>
                    <label htmlFor='airConditioner' className="btn btn-outline-primary font-12 d-block">Air Conditioner</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="hospital"/>
                    <label htmlFor='hospital' className="btn btn-outline-primary font-12 d-block">Hospital</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="electricalPoint"/>
                    <label htmlFor='electricalPoint' className="btn btn-outline-primary font-12 d-block">Electrical Point(Card Charging)</label>
                </div>
            </div>
       
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="badmintonCourt"/>
                    <label htmlFor='badmintonCourt' className="btn btn-outline-primary font-12 d-block">Badminton Court</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="swimmingPool"/>
                    <label htmlFor='swimmingPool' className="btn btn-outline-primary font-12 d-block">Swimming Pool</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="library"/>
                    <label htmlFor='library' className="btn btn-outline-primary font-12 d-block">Library</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="golfCourse"/>
                    <label htmlFor='golfCourse' className="btn btn-outline-primary font-12 d-block">Golf Course</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="bicycleTrack"/>
                    <label htmlFor='bicycleTrack' className="btn btn-outline-primary font-12 d-block">Bicycle Track</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="gatedCommunity"/>
                    <label htmlFor='gatedCommunity' className="btn btn-outline-primary font-12 d-block">Gated Community</label>
                </div>
            </div>
       
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="jacuzzi"/>
                    <label htmlFor='jacuzzi' className="btn btn-outline-primary font-12 d-block">Jacuzzi</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="barbeque"/>
                    <label htmlFor='barbeque' className="btn btn-outline-primary font-12 d-block">Barbeque</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="bar"/>
                    <label htmlFor='bar' className="btn btn-outline-primary font-12 d-block">Bar</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="sustainableCommunity "/>
                    <label htmlFor='sustainableCommunity' className="btn btn-outline-primary font-12 d-block">Sustainable Community </label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="builtInWardrobes"/>
                    <label htmlFor='builtInWardrobes' className="btn btn-outline-primary font-12 d-block">Built In Wardrobes</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="coveredParking"/>
                    <label htmlFor='coveredParking' className="btn btn-outline-primary font-12 d-block">Covered Parking</label>
                </div>
            </div>
        
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="maidRoom"/>
                    <label htmlFor='maidRoom' className="btn btn-outline-primary font-12 d-block">Maid's Room </label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="privateGarage"/>
                    <label htmlFor='privateGarage' className="btn btn-outline-primary font-12 d-block">Private Garage</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="maintenance24"/>
                    <label htmlFor='maintenance24' className="btn btn-outline-primary font-12 d-block">24 Hours Maintenance</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="facility"/>
                    <label htmlFor='facility' className="btn btn-outline-primary font-12 d-block">Bank/ATM Facility</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="basketballCourt"/>
                    <label htmlFor='basketballCourt' className="btn btn-outline-primary font-12 d-block">Basketball Court</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="businessCenter"/>
                    <label htmlFor='businessCenter' className="btn btn-outline-primary font-12 d-block"> Business Center</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="childrenPlay"/>
                    <label htmlFor='childrenPlay' className="btn btn-outline-primary font-12 d-block">Children's Play Area</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="clubHouse"/>
                    <label htmlFor='clubHouse' className="btn btn-outline-primary font-12 d-block">Club House</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="conciergeService"/>
                    <label htmlFor='conciergeService' className="btn btn-outline-primary font-12 d-block">Concierge Service</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="cyclingTrack"/>
                    <label htmlFor='cyclingTrack' className="btn btn-outline-primary font-12 d-block"> Cycling Track</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="lundryService"/>
                    <label htmlFor='lundryService' className="btn btn-outline-primary font-12 d-block">Lundry Service</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="tenisCourt"/>
                    <label htmlFor='tenisCourt' className="btn btn-outline-primary font-12 d-block">Tenis Court</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="sqashCourt"/>
                    <label htmlFor='sqashCourt' className="btn btn-outline-primary font-12 d-block">Sqash Court</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="basementParking"/>
                    <label htmlFor='basementParking' className="btn btn-outline-primary font-12 d-block">Basement Parking</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="pswimmingPool"/>
                    <label htmlFor='pswimmingPool' className="btn btn-outline-primary font-12 d-block">Private Swimming Pool</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="walkingTrails"/>
                    <label htmlFor='walkingTrails' className="btn btn-outline-primary font-12 d-block">Walking Trails</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="fitnessCenter"/>
                    <label htmlFor='fitnessCenter' className="btn btn-outline-primary font-12 d-block">Fitness Center</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="balcony"/>
                    <label htmlFor='balcony' className="btn btn-outline-primary font-12 d-block">Balcony</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="driverRoom"/>
                    <label htmlFor='driverRoom' className="btn btn-outline-primary font-12 d-block">Driver's Room</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="carpet"/>
                    <label htmlFor='carpet' className="btn btn-outline-primary font-12 d-block">Carpet</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="diningInBuilding"/>
                    <label htmlFor='diningInBuilding' className="btn btn-outline-primary font-12 d-block">Dining In Building</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="conferenceRoom"/>
                    <label htmlFor='conferenceRoom' className="btn btn-outline-primary font-12 d-block">Conference Room</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="prayerRoom"/>
                    <label htmlFor='prayerRoom' className="btn btn-outline-primary font-12 d-block">Prayer Room</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="walkIncloset"/>
                    <label htmlFor='walkIncloset' className="btn btn-outline-primary font-12 d-block">Walk In closet</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="kitchenAppliance"/>
                    <label htmlFor='kitchenAppliance' className="btn btn-outline-primary font-12 d-block">Build In Kitchen Appliance</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="petsAllow"/>
                    <label htmlFor='petsAllow' className="btn btn-outline-primary font-12 d-block">Pets Allow</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="landscapedGarden"/>
                    <label htmlFor='landscapedGarden' className="btn btn-outline-primary font-12 d-block">Landscaped Garden</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="laundry"/>
                    <label htmlFor='laundry' className="btn btn-outline-primary font-12 d-block">Laundry</label>
                </div>
            </div>
        
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="amenities[]" id="storageRoom"/>
                    <label htmlFor='storageRoom' className="btn btn-outline-primary font-12 d-block">Storage Room</label>
                </div>
            </div>
        </div>
        <div className="row mb-4">
            <div className='col-12'>
                <label className='font-14 fw-bold mb-3 text-uppercase'>Description</label>
                <textarea
                    className='form-control'
                    data-kt-autosize='true'
                    rows={3}
                    placeholder={'Description'}
                    />
            </div>
        </div>
        <h6 className="mb-4">Address</h6>
        <div className="row mb-4 form-add-design">
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Lattitude</label>
                    <input type="text" className="form-control fw-bolder" placeholder="Lattitude" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Longitude</label>
                    <input type="text" className="form-control fw-bolder" placeholder="Longitude" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Address</label>
                    <input type="text" className="form-control fw-bolder" placeholder="Address" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Address 2</label>
                    <input type="text" className="form-control fw-bolder" placeholder="Address 2" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">City</label>
                    <input type="text" className="form-control fw-bolder" placeholder="City" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">State</label>
                    <input type="text" className="form-control fw-bolder" placeholder="State" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Country</label>
                    <input type="text" className="form-control fw-bolder" placeholder="Country" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Zipcode</label>
                    <input type="text" className="form-control fw-bolder" placeholder="Zipcode" />
                </div>
            </div>
        </div>
        <Row className='mb-5'>
            <Col lg={24} md={24} xs={24}>
                <div className="near-by-loaction mb-30px">
                    <NearByLocation />
                </div>
            </Col>
        </Row>
        <div className="row">
            <div className="col-12">
                <label className="d-block text-uppercase mb-0 font-13"> Community Image </label>
            </div>
        </div>
        {/* <div className="col-md-12 col-12">
            <div className="row">
                <div className="col-12">
                    <label className="d-block text-uppercase mb-0 font-13"> Community Image </label>
                </div>
            </div>
            <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                <div className="row">
                    <div className="col-md-12">
                        <div className="w-100">
                            <div className="upload-block d-inline-block">
                                <div className="upload-btn-wrapper bg-white px-2 py-1">
                                    <span className="material-icons font-44">+</span>
                                    <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <MultipleImageUploadComponent/>
        <h6 className="mb-4">Near By</h6>
        <div className='row aminities mb-10'>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="near_by[]" id="school"/>
                    <label htmlFor='school' className="btn btn-outline-primary font-12 d-block">School</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="near_by[]" id="nearbyHospital"/>
                    <label htmlFor='nearbyHospital' className="btn btn-outline-primary font-12 d-block">Hospital</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="near_by[]" id="cityCenter"/>
                    <label htmlFor='cityCenter' className="btn btn-outline-primary font-12 d-block">City Center</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="near_by[]" id="beach"/>
                    <label htmlFor='beach' className="btn btn-outline-primary font-12 d-block">Beach</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="near_by[]" id="airpot"/>
                    <label htmlFor='airpot' className="btn btn-outline-primary font-12 d-block">Airpot</label>
                </div>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-4 col-6 mb-4'>
                <div className='aminit-box'>
                    <input type="checkbox" className="d-none" name="near_by[]" id="college"/>
                    <label htmlFor='college' className="btn btn-outline-primary font-12 d-block">College</label>
                </div>
            </div>
        </div>
        <h6 className="mb-4">Community Status</h6>
        <div className='row form-add-design'>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Satrt Date</label>
                    <input type="date" className="form-control fw-bolder form-control-solid" placeholder="Community Name" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">End Date</label>
                    <input type="date" className="form-control fw-bolder form-control-solid" placeholder="Community Name" />
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">PROPERTY STAGE</label>
                    <select className="form-control form-select fw-bolder form-control-solid"
                        >
                        <option >Property Satge </option>
                        <option>Stage 1 </option>
                        <option>Satge 2</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Property Status</label>
                    <select className="form-control form-select fw-bolder form-control-solid"
                        >
                        <option >Property Status </option>
                        <option>Work in progress </option>
                        <option>Completed</option>
                    </select>
                </div>
            </div>
            <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Satus</label>
                    <select className="form-control form-select fw-bolder form-control-solid"
                        >
                        <option > Status </option>
                        <option>Active </option>
                        <option>Inactive</option>
                    </select>
                </div>
            </div>
            <div className="col-12 text-end">
                <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                    Reset
                </button>
                <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                    Create Community
                </button>
            </div>
        </div>
    </div>
</div>
</>
)
}