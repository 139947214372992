import React, { useState, useEffect } from 'react'
import Slider from "react-slick";

interface CarouselProps {
    images: string[];
}
const Carousel: React.FC<CarouselProps> = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      focusOnSelect: true,
      afterChange: (index: number) => setCurrentSlide(index),
    };
  
    const handleThumbnailClick = (index: number) => {
      setCurrentSlide(index);
    };
  
    return (
      <div className="carousel-container">
        <div className="carousel">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="carousel-slide">
                <img src={image} alt={`Slide ${index}`} height={350} className='w-100' style={{'objectFit': 'cover'}}/>
              </div>
            ))}
          </Slider>
        </div>
        <div className="carousel-thumbnails d-flex justify-content-center">
          {images.map((image, index) => (
            <CarouselThumbnail
              key={index}
              image={image}
              isActive={index === currentSlide}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>
    );
  };
  
export default Carousel;

interface CarouselThumbnailProps {
    image: string;
    isActive: boolean;
    onClick: () => void;
}
  
const CarouselThumbnail: React.FC<CarouselThumbnailProps> = ({ image, isActive, onClick }) => {
    return (
      <div
        className={`carousel-thumbnail mx-2 ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        <img src={image} alt="Thumbnail" className='' width={100}/>
      </div>
    );
  };
  

// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// interface CarouselProps {
//     images: string[];
// }
// const Carousel: React.FC<CarouselProps> = ({images}:any) => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: false,
//     centerMode: true,
//     focusOnSelect: true,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           centerMode: false,
//         },
//       },
//     ],
//   };

//   const thumbnailSettings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     centerMode: true,
//     focusOnSelect: true,
//   };

//   return (
//     <div className="carousel-container">
//       <Slider {...settings}>
//        {images.map((image:any, i:number) => (
//         <div>
//           <img src={image} alt="Slide 1" className='w-100' height={350} style={{'objectFit': 'cover'}}/>
//         </div>
//         ))}
//       </Slider>

//       <Slider {...thumbnailSettings}>
//         {images.map((image:any, i:number) => (
//         <div className='carousel-thumbnail'>
//             <img src={image} alt="Slide 1" width={100}/>
//         </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default Carousel;
