import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
export function PopupListing() {


    return (
        <div className="modal fade" aria-hidden='true' id="Listing-popup">
            <div className="modal-dialog modal-lg modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Properties Health Matrics</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="row">
                            <div className="col-md-12 mb-0 ox-auto table-res-contain remove-abs">
                                <div className="table-responsive table-crm">
                                    <table className="table table-bordered mb-0 font-12 gy-4 gx-4 w-100 remove-sort-ioc no-sticky">
                                        <thead className="text-uppercase bg-projects-list font-weight-normal">
                                            <tr>
                                                <th className="">Property Column</th>
                                                <th className="">Condition</th>
                                                <th className="">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            <tr>
                                                <td>
                                                    Title
                                                </td>
                                                <td>
                                                    Length
                                                </td>
                                                <td>
                                                    10
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Description
                                                </td>
                                                <td>
                                                    Length
                                                </td>
                                                <td>
                                                    10
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Amenities
                                                </td>
                                                <td>
                                                    Count
                                                </td>
                                                <td>
                                                    0
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Images
                                                </td>
                                                <td>
                                                    Count
                                                </td>
                                                <td>
                                                    0
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}