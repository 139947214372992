import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { CreateUnit } from './Units/create_unit/CreateUnit'
import { PreviewUnit } from './Units/PreviewUnit' 
import { UnitListing } from './Units/UnitListing'
import { EditUnit } from './Units/edit_unit/EditUnit' 

const PMUnitsWrapper: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                <Switch>
                        <Route path='/units/list'>
                            <UnitListing />
                        </Route>
                        <Route path='/units/edit'>
                            <EditUnit />
                        </Route>
                        <Route path='/units/preview'>
                            <PreviewUnit />
                        </Route>
                        <Route path='/units/create-unit'>
                            <CreateUnit/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMUnitWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMUnitsWrapper />
        </>
    )
};

export { PMUnitWrapper }

