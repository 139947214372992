import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { Payment } from './Payment';
import { SecurityDeposit } from './SecurityDeposit';

export function PaymentPlanDetails({payment}:any) {
    return (
        <div className="">
            <Payment payment={payment}/>
            <SecurityDeposit  payment={payment?.payment_plan_details}/>
        </div>
    );
}