import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'

const unitData = [{
    "refrence": "REF-UN-1605604130",
    "unitName": "Wilton Park Residencies -3 Floor - Apartment",
    "category": "Apartment",
    "unitType": "Sale",
    "communityName": "Wilton Park Residencies",
    "building": "Wilton Park Residencies 2",
    "price": "3,694,000",
    "stats": "Active"
},
{   
    "refrence": "REF-UN-1605671718",
    "unitName": "You’ll Want To Live Here! Best Home & Community",
    "category": "Apartment",
    "unitType": "Sale",
    "communityName": "Belgravia Square",
    "building": "Belgravia Square 3",
    "price": "82,000",
    "stats": "Active"
},
{
    "refrence": "REF-UN-1606102703",
    "unitName": "Corner Bungalow, Upgraded Kitchen, Close to Pool",
    "category": "Apartment",
    "unitType": "Lease",
    "communityName": "Emirates Hills",
    "building": "--",
    "price": "38,500",
    "stats": "Active"
},
{
    "refrence": "REF-UN-1606119822",
    "unitName": "The Sloan - 4th Floor - Apartment",
    "category": "Apartment",
    "unitType": "Lease",
    "communityName": "The Sloan by Belgravia Heights",
    "building": "Wilton Park Residencies 3",
    "price": "47,000",
    "stats": "Active"
},
{
    "refrence": "REF-UN-1606137807",
    "unitName": "Single Row! Spacious 5BR TH with Private Garden",
    "category": "Townhouse",
    "unitType": "Lease",
    "communityName": "WIlton Park Residencies",
    "building": "Wilton Park Residencies 2",
    "price": "3,900,000",
    "stats": "Active"
},
{
    "refrence": "REF-UN-1606137885",
    "unitName": "Ideal Investment- Elegant Townhouse With Maid room",
    "category": "Townhouse",
    "unitType": "Lease",
    "communityName": "The Sloan by Belgravia Heights",
    "building": "Ajman One Tower 2",
    "price": "75,000",
    "stats": "Active"
},
];

export function UnitListing() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col">
                    <h3 className=''>Unit List</h3>
                </div>
                <div className="col-auto text-end">
                    <Link to='create-unit' className='btn btn-primary fw-bold px-10 py-3'>
                        Add Property
                    </Link>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">40</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Active Units</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">75</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Inactive units</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Draft Units</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Units Booked</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row align-items-center mb-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='table-crm'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='table-responsive'>
                                <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                    <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                        <tr>
                                            <th> Unit Ref.</th>
                                            <th> Unit Name </th>
                                            <th> Category </th>
                                            <th> Unit Type</th>
                                            {/* <th> Property Score </th> */}
                                            <th> Community </th>
                                            <th> Building </th>
                                            <th> Price(AED) </th>
                                            <th> Status </th>
                                            <th> Action </th>
                                        </tr>
                                        <tr>
                                            <th> </th>
                                            <th> <input type="text" placeholder='Min 3 Char' className="rounded border px-3 py-1"/> </th>
                                            <th> <select className="rounded border px-3 py-1">
                                                    <option>Select</option>
                                                    <option>Sale</option>
                                                    <option>Lease</option>
                                                </select>
                                            </th>
                                            <th><select className="rounded border px-3 py-1">
                                                    <option>Villa</option>
                                                    <option>Townhouse</option>
                                                    <option>Bunglaow</option>
                                                    <option>Apartment</option>
                                                </select> </th>
                                            <th> <input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/></th>
                                            <th> <input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/></th>
                                            <th><input type="text" className="rounded border px-3 py-1" placeholder='Min 3 Char'/> </th>
                                            <th> <select className="rounded border px-3 py-1">
                                                    <option>Active</option>
                                                    <option>Inactive</option>
                                                    <option>Draft</option>
                                                    <option>Sold</option>
                                                </select> </th>
                                            <th className="border-0" style={{background:'#dedede'}}><button className="btn btn-secondary font-12 refereshResults">Reset</button>  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unitData.length > 0 ? unitData.map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-warning">{item.refrence}</td>
                                                <td>{item.unitName}</td>
                                                <td>{item.category}</td>
                                                <td>{item.unitType}</td>
                                                <td>{item.communityName}</td>
                                                <td>{item.building}</td>
                                                <td>{item.price}</td>
                                                <td>{item.stats}</td>
                                                <td className="action-button text-end">
                                                    <div className="dropdown">
                                                        <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                            <i className="fa fa-ellipsis-v text-black"></i>
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                
                                                                <li>
                                                                    <Link to="edit" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-edit"></i>
                                                                        <p className="m-0">Edit</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="preview" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-eye"></i>
                                                                        <p className="m-0">Preview</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-share"></i>
                                                                        <p className="m-0">Share</p>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr><td colSpan={3}>No data..</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='w-100 d-flex justify-content-end'>
                                <CompactPagination
                                    pageSize={pageSize}
                                    totalCount={unitData.length}
                                    defaultPage={currentPage}
                                    onPageChange={setCurrentPage}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
