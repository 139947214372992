import React, {useState, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {Link} from 'react-router-dom'
import { FinanceReceiptAllRequest } from './FinanceReceiptTable/FinanceReceiptAllRequest';
import { FinanceReceiptDayRequest } from './FinanceReceiptTable/FinanceReceiptDayRequest';
import { FinanceReceiptMonthRequest } from './FinanceReceiptTable/FinanceReceiptMonthRequest';
import { FinanceReceiptWeekRequest } from './FinanceReceiptTable/FinanceReceiptWeekRequest';
import { FinanceServiceReceiptsTabs } from './FinanceReceiptTabs/FinanceServiceReceiptsTabs';


export function FinanceReceiptsListWrapper() {
  const [searchItem, setSearchItem] = useState<any>('');

  return (
    <>
     <FinanceServiceReceiptsTabs searchItem={searchItem} setSearchItem={setSearchItem} />
      <Switch>
      <Route path='/finance-services-receipt/list/all-list'>
            <FinanceReceiptAllRequest searchItem={searchItem} setSearchItem={setSearchItem} />          
        </Route>
        <Route path='/finance-services-receipt/list/day-list'>
            <FinanceReceiptDayRequest searchItem={searchItem} setSearchItem={setSearchItem} />          
        </Route>
        <Route path='/finance-services-receipt/list/week-list'>
          <FinanceReceiptWeekRequest searchItem={searchItem} setSearchItem={setSearchItem} />
        </Route>
        <Route path='/finance-services-receipt/list/month-list'>
          <FinanceReceiptMonthRequest searchItem={searchItem} setSearchItem={setSearchItem} />
        </Route>
        <Redirect to='/error/403' />        
      </Switch>
    </>
    );
}