import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'

export function ScheduleVisitPopup() {
    const { t } = useTranslation();

    return (
        <div className="modal fade add-broker-modal" aria-hidden='true' id="schedule-visit-popup">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-10">
                    <div className="modal-header p-0">
                        <h5 className="modal-title">{t("Schedule Visit")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <hr className="pt-0" />
                    <div className="modal-body px-0 pb-5 pt-0">
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-10 position-relative">
                                        <label className="form-label text-primary font-12">{t("Customer Type")}</label>
                                        <select className="form-control form-select font-12" data-control="select2" data-allow-clear="true">
                                            <option selected disabled value="">{t("Please Select")}</option>
                                            <option value={1}>Person</option>
                                            <option value={2}>Organization</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-10 position-relative">
                                        <label className="form-label text-primary font-12">{t("Name")}</label>
                                        <input type="text" className="form-control font-12" placeholder={t("Enter Name")} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-10 position-relative">
                                        <label className="form-label text-primary font-12">{t("Email ID")}</label>
                                        <input type="text" className="form-control font-12" placeholder={t("Email ID")} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="position-relative">
                                        <label className="form-label font-12">{t("Comments(Optional)")}</label>
                                        <textarea
                                            className='form-control font-12 h-25'
                                            data-kt-autosize='true'
                                            rows={4}
                                            placeholder={t("Type here")}
                                            value=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer px-0 py-0">
                        <button type="button" className="btn btn-secondary border-0 w-40 text-uppercase me-4"
                            data-bs-dismiss="modal">{t("Cancel")}</button>
                        <button data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit'
                            className='btn btn-primary fw-bold px-10 py-3 disabled'>
                            {t("Submit")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}