import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { OffersAndRenewalsTab } from './components/OffersAndRenewalsTab/OffersAndRenewalsTab'
import { Offer } from './components/Offer/Offer'
import { Renewals } from './components/Renewals/Renewals'
import { CheckBoxPopup } from '../pm-unrenewed-leases/components/CheckBoxPopup/CheckBoxPopup'
import { AddedSuccessfulPopup } from '../SuccessfulPopup/AddedSuccessfulPopup'
import { Details } from './components/Details/Details'
import { EditOffer } from './components/EditOffer/EditOffer'
import { UpdateOffer } from './components/EditOffer/UpdateOffer'
import { PropertyCreatedSuccessful } from '../SuccessfulPopup/PropertyCreatedSuccessful'
import { MakeAnOfferPopUp } from './components/EditOffer/components/MakeAnOfferPopUp/MakeAnOfferPopUp'
import {CreateContractPopup} from "./PMCreateContract/CreateContractPopup";

const PMOffersAndRenewalsPage: FC = () => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        <Switch>
          <Route path='/pm-offers-and-renewals/offer'>
            <OffersAndRenewalsTab />
            <Offer />
            <CheckBoxPopup /> 
            <AddedSuccessfulPopup /> 
          </Route>
          <Route path='/pm-offers-and-renewals/renewals'>
            <OffersAndRenewalsTab />
            <Renewals />
            <CheckBoxPopup /> 
            <AddedSuccessfulPopup /> 
          </Route>
          <Route path='/pm-offers-and-renewals/edit-offer'>
            <EditOffer />
          </Route>
          <Route path='/pm-offers-and-renewals/update-offer'>
            <UpdateOffer />
            <PropertyCreatedSuccessful />
            <MakeAnOfferPopUp />
          </Route>
        </Switch>
      </div>
    </div>
  </>
)

const PMOffersAndRenewalsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <PMOffersAndRenewalsPage />
    </>
  )
}

export { PMOffersAndRenewalsWrapper }
