import React, { useEffect, useState } from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import * as dashboard from '../../../../constants/redux/Sagas/Tenant/TenantDashboardSaga'

export function MenuInner() {
  const intl = useIntl()
  const dispatch = useDispatch()

  const contractList: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.allContract)
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)

  const [selectedContract, setSelectedContract] = useState<any>()

  useEffect(() => {
    dispatch(dashboard.actions.allContract())
  }, [])

  useEffect(() => {
    if (!currentContract) {
      setSelectedContract(contractList?.data[0])
    }
  }, [contractList, currentContract])

  useEffect(() => {
    dispatch(dashboard.actions.selectedContract(selectedContract))
  }, [selectedContract, dispatch])

  const contracts = contractList?.data?.map((contract: any) => (
    <option key={contract.id} value={contract.id}>
      {contract.buildingName + ' - ' + contract.UnitNumber}
    </option>
  ))

  return (
    <>
      <div className='row'>
        <div className='col-lg-12 my-auto'>
          <select
            className='form-select form-select-lg form-select-solid'
            data-control='select2'
            data-placeholder='Select Community'
            onChange={(e) =>
              setSelectedContract(
                contractList?.data?.find((contract: any) => contract.id == e.target.value)
              )
            }
            defaultValue={currentContract?.id}
          >
            {contractList ? contracts : ''}
          </select>
        </div>
      </div>
    </>
  )
}
