import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {Route, Switch} from 'react-router-dom'
import {PermitToWorkTabs} from './PermitToWorkTabs/PermitToWorkTabs'
import {Upcoming} from './Upcoming/Upcoming'
import {Closed} from './Closed/Closed'
import {getFacilityWorkOrders} from '../../../../../constants/axios/apis'

export function PermitToWork() {
  const [permits, setPermits] = useState<any>()

  // useEffect(() => {
  //   getFacilityWorkOrders().then(({data}: any) => {
  //     data
  //       .sort((a: any, b: any) => {
  //         return a.id - b.id
  //       })
  //       .reverse()
  //     setPermits(data)
  //   })
  // }, [])

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3 movein-moveout'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <Switch>
                    <Route path='/fmteam-approvals/permit-work/upcoming'>
                      <PermitToWorkTabs />
                      <Upcoming
                        // permits={permits?.filter((permit: any) => permit.status == 'Open')}
                      />
                    </Route>
                    <Route path='/fmteam-approvals/permit-work/closed'>
                      <PermitToWorkTabs />
                      <Closed
                        // permits={permits?.filter((permit: any) => permit.status == 'Completed')}
                      />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
