import React, { useState, useEffect, useRef } from 'react';
import CheckGreen from "../../assets/images/Check_green_lg.png";
import { useTranslation } from 'react-i18next'

export function PropertyCreatedSuccessful() {
    let userEmail = "";
    let userDetail = localStorage.getItem("userDetail");
    if(userDetail){
        userEmail = JSON.parse(userDetail).email;
    };
    const { t } = useTranslation();

    return (
        <div className="modal fade custom-modal" id="added-success-modal">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header bg-white border-0">
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                    </div>
                    <div className="modal-body py-10">
                        <div className="row g-5">
                            <div className="col-md-12">
                                <div className="text-center mx-md-12">
                                    <div className="redeem-offer-QR">
                                        <img src={CheckGreen} alt="tick-img" className="img-fluid mb-5" />
                                        <h1 className="text-theme font-24 mb-2 fw-bold">{t("Done")}</h1>
                                        <p className="mb-3 font-12 fw-bolder">
                                            {t("Property Created Successful")}
                                        </p>
                                        {userEmail === 'pm@rc.test' ?
                                            <button data-bs-target="#make-anOffer-popUp" data-bs-toggle="modal" data-bs-dismiss="modal" className="btn btn-primary btn-lg fw-bolder font-12 w-100">
                                                {t("Continue")}
                                            </button>
                                            :
                                            <button data-bs-dismiss="modal" className="btn btn-primary btn-lg fw-bolder font-12 w-100">
                                                {t("Continue")}
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}