import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../components/layout/core'
import {SupervisorRecentRequests} from './components/RecentRequests/RecentRequests'
import {SupervisorQuickLinks} from './components/QuickLinks/QuickLinks'
import {UpcomingWorkOrders} from './components/UpcomingWorkOrders/UpcomingWorkOrders'
/* import {UpcomingWorkOrders} from '../fc-dashboard/components/UpcomingWorkOrders/UpcomingWorkOrders' */
import {SupervisorAnnouncements} from './/components/Announcements/Announcements'

import { useSelector } from 'react-redux';
import { getFcsWorkOrders } from '../../utils/makeRequest';
import { IState } from '../../utils/interface';
import Loader from '../../components/Loader';
import { RootState } from '../../constants'
import { getWorkOrdersList } from '../../constants/axios/apis'


const FCSupervisorDashboardPage: FC = () => {
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)
    
    const [fcsWorkOrderList, setFcsWorkOrderList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    
    const [noncompletedWorkOrders,setNoncompletedWorkOrders] = useState<any>([]);
    const [openWorkOrders,setOpenWorkOrders] = useState<any>([]);

    useEffect(()=>{
        setFcsWorkOrderList({
            loading: true,
            response: [],
            error: ''
        })

        const payload = currentBuilding ? "?building_id=" + currentBuilding : "?building_id=0"
        getWorkOrdersList(payload).then(({ data }:any) => {
            setFcsWorkOrderList({
                loading: false,
                response: (data && Array.isArray(data)) ? data : [],
                error: ''
            })
        }).catch((e)=>{setFcsWorkOrderList({
            loading: false,
            response: [],
            error: 'Some error occured'
        })})
    }, [currentBuilding])

    useEffect(()=>{
        if(Array.isArray(fcsWorkOrderList.response) && fcsWorkOrderList.response.length > 0){
            const noncompletedWorkOrderVal =  fcsWorkOrderList.response.filter((workOrder: any, index: number)=>{
                // return workOrder.status !== "Completed" && workOrder.status !== "Under observation";
                return (
                    workOrder.status === "Open" && (!workOrder.assignedTo || workOrder.assignedTo === '' ) ||
                    workOrder.status === "Confirmed" || workOrder.status === "On hold"
                )
            }).slice(0,15);
            const openWorkOrderVal =  fcsWorkOrderList.response.filter((workOrder: any, index: number)=>{
                return workOrder.status === "Open";
            }).slice(0,15);
            
            setNoncompletedWorkOrders(noncompletedWorkOrderVal);
            setOpenWorkOrders(openWorkOrderVal);
        }else{
            setNoncompletedWorkOrders([])
            setOpenWorkOrders([])
        }
        // setFcsWorkOrderList((oState:any)=>({...oState, loading:false}))
    },[fcsWorkOrderList]);
    
    return (
        <>
        <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <SupervisorRecentRequests 
                    fcsWorkOrderList={fcsWorkOrderList} 
                    noncompletedWorkOrders={noncompletedWorkOrders}
                />
                <SupervisorQuickLinks />
                {/* <UpcomingWorkOrders /> */}
                <UpcomingWorkOrders 
                    fcsWorkOrderList={fcsWorkOrderList} 
                    noncompletedWorkOrders={openWorkOrders}
                />
                <SupervisorAnnouncements />
            </div>
        </div>
        </>
    );
}

const FCSupervisorDashboardWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FCSupervisorDashboardPage />
        </>
    )
};

export {FCSupervisorDashboardWrapper}
