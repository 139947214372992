import React from 'react'
import { Link } from 'react-router-dom'
import { DateRangePicker } from 'rsuite'

export function OffersPipeline() {

    return (
        <>

            <div className="row">
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="card-body p-7">
                            <div className="row">
                                {/* <div className="col-md-12">
                                    <h4 className="font-14 fw-boldest text-black border-bottom pb-4"> Offers Pipeline </h4>
                                </div> */}
                                <div className="col-12">
                                    <div className="border-bottom pb-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className="font-14 fw-boldest text-black mt-1 mb-2"> Offers Pipeline </h4>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <DateRangePicker size="sm" format="dd/MM/yyyy" placeholder="Select Date Range" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sales-section mt-5">
                                <div id="myKanban">
                                    <div className="kanban-container">
                                        <div data-id="_todo" className="kanban-board">
                                            <header className="kanban-board-header info">
                                                <div className="kanban-title-board">Recent Offers</div>
                                            </header>
                                            <main className="kanban-drag">
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12" >REF-OF-1619771907</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 15,500</p>
                                                    <span className="font-10 text-muted d-block">30/04/2021 03:38</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1619781137</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 230,000</p>
                                                    <span className="font-10 text-muted d-block">30/04/2021 06:12</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625814182</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 77,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 02:03</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625820349</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 7,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 03:45</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625825944</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 7,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:19</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625826241</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 230,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:24</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625826262</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 230,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:24</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625826331</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 84,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:25</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1626422324</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 25,000</p>
                                                    <span className="font-10 text-muted d-block">16/07/2021 02:59</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1626675345</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 3,694,000</p>
                                                    <span className="font-10 text-muted d-block">19/07/2021 01:16</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627208971</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 45,000</p>
                                                    <span className="font-10 text-muted d-block">25/07/2021 05:29</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627285696</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 3,000</p>
                                                    <span className="font-10 text-muted d-block">26/07/2021 02:48</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627387964</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 3,000</p>
                                                    <span className="font-10 text-muted d-block">27/07/2021 07:13</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627538198</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 3,694,000</p>
                                                    <span className="font-10 text-muted d-block">29/07/2021 00:57</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627538558</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 3,694,000</p>
                                                    <span className="font-10 text-muted d-block">29/07/2021 01:03</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627555527</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 3,694,000</p>
                                                    <span className="font-10 text-muted d-block">29/07/2021 05:47</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627564800</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 3,000</p>
                                                    <span className="font-10 text-muted d-block">29/07/2021 08:20</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627624506</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 387,000</p>
                                                    <span className="font-10 text-muted d-block">30/07/2021 00:55</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627627556</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 450,000</p>
                                                    <span className="font-10 text-muted d-block">30/07/2021 01:46</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627627612</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 450,000</p>
                                                    <span className="font-10 text-muted d-block">30/07/2021 01:47</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1627911622</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 1,000,000</p>
                                                    <span className="font-10 text-muted d-block">02/08/2021 08:40</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1654157240</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 8,999,999</p>
                                                    <span className="font-10 text-muted d-block">02/06/2022 03:07</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1673876675</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 100,000,000</p>
                                                    <span className="font-10 text-muted d-block">16/01/2023 07:45</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689231869</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:05</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689231869</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:28</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689231869</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:29</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689233426</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 47,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:30</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234340</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 0</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:46</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234406</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:48</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234406</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:51</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234406</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 02:52</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234406</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 03:01</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234406</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 03:02</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234406</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 03:07</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689234406</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 50,000</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 03:13</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689245673</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 53,653</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 05:55</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1689245721</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 34,567</p>
                                                    <span className="font-10 text-muted d-block">13/07/2023 05:57</span>
                                                </div>
                                            </main>
                                            <footer></footer>
                                        </div>
                                        <div data-id="_working" className="kanban-board">
                                            <header className="kanban-board-header success">
                                                <div className="kanban-title-board">Accepted Offers</div>
                                            </header>
                                            <main className="kanban-drag">
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625825944</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 7,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:19</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625826331</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 84,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:25</span>
                                                </div>
                                            </main>
                                            <footer></footer>
                                        </div>
                                        <div data-id="_done" className="kanban-board">
                                            <header className="kanban-board-header danger">
                                                <div className="kanban-title-board">Rejected Offers</div>
                                            </header>
                                            <main className="kanban-drag"></main>
                                            <footer></footer>
                                        </div>
                                        <div data-id="_done" className="kanban-board">
                                            <header className="kanban-board-header warning">
                                                <div className="kanban-title-board">Negotiated Offers</div>
                                            </header>
                                            <main className="kanban-drag">
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625826262</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 230,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:24</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625826331</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 84,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 05:25</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1625814182</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 77,000</p>
                                                    <span className="font-10 text-muted d-block">09/07/2021 02:03</span>
                                                </div>
                                                <div className="kanban-item" data-eid="undefined">
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0">Offer Reference</h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2"><a className="text-decoration-none text-warning font-12">REF-OF-1619781137</a></p>
                                                    <h6 className="font-13 font-weight-bold ellipsis text-capitalize mb-0"></h6>
                                                    <p className="font-13 mb-0 d-block ellipsis mb-2">AED 230,000</p>
                                                    <span className="font-10 text-muted d-block">30/04/2021 06:12</span>
                                                </div>
                                            </main>
                                            <footer></footer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
