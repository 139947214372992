import React, { useState, useEffect } from 'react'

export function Notes() {
    return (      
        <>
     
                    <div className="row mt-4">
                       
                    <div className="col-md-12">
                            <div className="row align-items-center border mb-4">
                                <div className="col-md-12 text-center py-4">
                                    <i className="fas fa-file-alt text-gray-400 font-44"></i>
                                    <h3 className="text-gray-400 mt-4 mb-0"> No Notes found </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 py-3 text-center border">
                            <button className=' btn-primary border-radius-round'>
                              <i className="fa fa-plus"></i>
                            </button>
                        </div>

                    </div>        
     
    </>
    )
}

