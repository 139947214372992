import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'

import { CorrectiveWorkorderDetails } from './FacilityWorkorderDetails/CorrectiveWorkorderDetails/CorrectiveWorkorderDetails';
import useRequestGet from '../../../hooks/useRequestGet';
import { CustomLoaderCompact } from '../../../constants/generics/customActios';
import { ClearanceWorkorderDetailsWrapper } from './FacilityWorkorderDetails/ClearanceWorkorderDetails/ClearanceWorkorderDetailsWrapper';
import { ComplitionDetails } from './FacilityWorkorderDetails/WorkorderComplitionDetails/ComplitionDetails';
// import { Feedback } from '../../fc-supervisor-services/WorkOrders/components/Feedback/Feedback';
import { FeedbackDetails } from './FacilityWorkorderDetails/CorrectiveWorkorderDetails/FeedbackDetails';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../constants/generics/userCheck';

export function WorkOrderViewWrapper({setPopupSuccessMsg}:any) {

    // const history = useHistory();
    let params: any = {};
    params = useLocation();
    const { workOrder } = params.state;

    // const state: any = useSelector(state => state);
    // const user_role = getUserRole(state?.auth?.user?.data);
    // const userToken = state?.auth?.accessToken;

    const [workOrderDetails, setWorkOrderDetails] = useState<any>();
    const [loading, setLoading] = useState(true);
    const { requestState: workOrderDetailState, refreshRequest: refreshRequestDetails } = useRequestGet(`/facility/work/orders/?id=${workOrder.id}`);
    useEffect(() => {
        refreshRequestDetails()
    }, [workOrder]);
    useEffect(() => {
        setLoading(workOrderDetailState.loading)
        if (Array.isArray(workOrderDetailState.response)) {
            if (workOrderDetailState.response.length > 0) {
                setWorkOrderDetails(workOrderDetailState.response[0])
            }
        }
    }, [workOrderDetailState]);

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    return (
        <>
            <div className="com-card-section myunit-page">
                {loading ? 
                    <CustomLoaderCompact />
                :
                <>
                    { workOrderDetails?.issueCategory === 'Corrective' ?
                        <>
                            <CorrectiveWorkorderDetails workOrder={workOrderDetails} setPopupSuccessMsg={setPopupSuccessMsg}/>
                            <ComplitionDetails workOrder={workOrderDetails}/>
                            { (workOrderDetails?.status === 'Completed' || workOrderDetails?.status === 'Under observation') && user_role === 'FMT' ?
                                <FeedbackDetails workOrder={workOrderDetails} />
                                :''

                            }
                        </>
                    : workOrderDetails?.issueCategory === 'Clearance' ?
                        <>
                            <ClearanceWorkorderDetailsWrapper workOrder={workOrderDetails} setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </>
                        
                    :''
                    }
                
                </>    
            }
            </div>
        </>
    );
}