import React, {FC, useEffect, useState} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { CreateEvent } from './CreateEvent/CreateEvent';
import { CreateOffer } from './CreateOffer/CreateOffer';
import { CreateVoucher } from './CreateVoucher/CreateVoucher';

export function PromotionCreateWrapper({setPopupSuccessMsg}:any){   
    return (
        <>
            <Switch>
                <Route path='/promotions/add/event'>
                    <CreateEvent setPopupSuccessMsg={setPopupSuccessMsg}/>
                </Route>
                <Route path='/promotions/add/offer'>
                    <CreateOffer setPopupSuccessMsg={setPopupSuccessMsg}/>
                </Route>
                <Route path='/promotions/add/voucher'>
                    <CreateVoucher setPopupSuccessMsg={setPopupSuccessMsg}/>
                </Route>
                <Redirect to='/error/403' />
            </Switch>
        </>
    )
}

