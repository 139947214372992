import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import Loader from '../../../../components/Loader';
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

/* export const data = {
    labels: ['40 Electricians', '10 Inactive'],
    datasets: [
        {
            datalabels: {
                color: "#ffffff",
                font: {
                    size: 16,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
            data: [40, 10],
            backgroundColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderWidth: 1,
        },
    ]
}; */

export const options = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                color: 'rgb(0, 44, 106)',
                lineDash: [12,12],
                boxWidth: 19,
                boxHeight: 18,
                padding: 30,
                usePointStyle: true,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                }
            }
        },
    },
    cutout:'80%',
    maintainAspectRatio: false,
};

export const Technicians:React.FC<any> = ({fcChartData, fcssChartData}) => {
    const [data,setData] = useState({
        labels: ['0 Active', '0 Inactive'],
        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                data: [0, 0],
                backgroundColor: [
                    '#FAA77B',
                    '#969DD9',
                ],
                borderColor: [
                    '#FAA77B',
                    '#969DD9',
                ],
                borderWidth: 1,
            },
        ]
    });

    const { t } = useTranslation();

    // const {loading, response: technicians, error} = fcsMyTeamList;
    const [activeButton, setActiveButton] = useState<any>("FC");
    const [loading, setLoading] = useState<any>(true);
    const [error, setError] = useState<any>('');
    const [userChartData, setUserChartData] = useState<any>();

    useEffect(()=>{
        const activeUserData = activeButton === 'FC' ? fcChartData : fcssChartData;        
        setLoading(activeUserData.loading)
        setError(activeUserData.error)
        setUserChartData(activeUserData.response)
    }, [fcChartData, fcssChartData, activeButton])

    useEffect(()=>{
        if(userChartData && Object.keys(userChartData).length !== 0){
            setData({
                labels: [` ${t('Active')} (${userChartData.active}) `, ` ${t('Inactive')} (${userChartData.inactive}) `],
                datasets: [
                    {
                        datalabels: {
                            color: "#ffffff",
                            font: {
                                size: 16,
                                family: "'Poppins', sans-serif",
                                lineHeight: 0.5,
                            },
                        },
                        data: [userChartData.active, userChartData.inactive],
                        backgroundColor: [
                            '#FAA77B',
                            '#969DD9',
                        ],
                        borderColor: [
                            '#FAA77B',
                            '#969DD9',
                        ],
                        borderWidth: 1,
                    },
                ]
            })
        } 
    },[userChartData]);

    // console.log(userChartData, 'userChartData');
    
    return (
        <div className="">
            <div className="row access-cd-tabs">
                <div className="col-12">
                    <div className='d-flex py-2'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                            <li className='nav-item'>
                                <a 
                                    onClick={()=>{
                                        setActiveButton('FC');
                                    }}
                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                    (activeButton === 'FC' && 'active')}>
                                    {t("Technicians")}
                                </a>
                            </li>
                            {/* <li className='nav-item'>
                                <a
                                    onClick={()=>{
                                        setActiveButton('FCSS');
                                    }}
                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                    (activeButton === 'FCSS' && 'active')}>
                                    FCSS
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                {loading ?
                    <div className="col-12">
                        {/* <Loader /> */}
                        <CustomLoader2 height={'166'}/>
                    </div>
                : error !== '' ?
                        <div className="col-12">{error}</div>
                    :
                    <div className="col-12 mt-lg-10 mt-md-5">
                        <div className="Doughnut-Chart text-center position-relative">
                            <Chart type='doughnut' data={data} options={options} height={"300%"} width={"90%"}/>
                            <div className="">
                                <div className="inner-text">
                                    {userChartData && userChartData.total ? (
                                        <h4 className="font-12 text-primary fw-boldest">
                                            {userChartData.total}<br/>
                                            {t("Total")}
                                        </h4>
                                        ):(
                                        <h4 className="font-12 text-primary fw-boldest">
                                            0 <br/>
                                            {t("Total")}
                                        </h4>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}