import React, {useState, useEffect, useRef} from 'react'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import {Link, useHistory, useLocation} from 'react-router-dom'
import UploadIcon from '../../../../../assets/images/upload-ioc.png'
import uploadImg from "../../../../../assets/images/file-upload.svg";
import {
  createFacilityDocument,
  getFmCommunityUser,
  getFacilityDocumentList,
  getFacilityBuildingList,
  getFacilityBuildingContractList,
} from '../../../../../constants/axios/apis'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {RequestFailedPopup} from '../../../../SuccessfulPopup/RequestFailedPopup'
import {useSelector} from 'react-redux'
import Loader from '../../../../../components/Loader'
import {RootState} from '../../../../../constants'
import {CustomRequestSuccessfulPopup} from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import {getUserRole} from '../../../../../constants/generics/userCheck'
import {CustomLoader2, CustomLoaderCompact} from '../../../../../constants/generics/customActios'
import {Select} from 'antd'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../constants/generics/fileUploadHandler'


export function AddDocument() {
  const listBuilding: any = useSelector<RootState>(({FCDashboard}) => FCDashboard.listBuilding)
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const { t } = useTranslation();
  let params: any = {}
  params = useLocation()
  const location: any = useLocation()
  const quickLinkCategory = location?.state?.quickLinkCategory || undefined
  let buildingInit: any
  if (params.state && params.state.building) {
    const {building} = params.state
    buildingInit = building
  }
  // const [buildingList, setBuildingList] = useState<any>([])
  const [documentList, setDocumentList] = useState<any>([])
  const [selectedBuilding, setSelectedBuilding] = useState<any>()
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  const [submitError, setSubmitError] = useState('')
  const buttonEl: any = useRef(null)
  const {Option} = Select

  const userId = state?.auth?.user?.data?.id
  const [buildingList, setBuildingList] = useState<any>([])
  const [buildingUnitList, setBuildingUnitList] = useState<any>([])
  const [selectedCategoryType, setSelectedCategoryType] = useState<any>(quickLinkCategory?.id)
  const [unitListOptions, setUnitListOptions] = useState<any>([''])
  const [isLoading, setIsLoading] = useState<any>({
    building: false, // coming from RootState
    unit: true,
    location: true,
    categories: true,
  })


  const createDocumentSchema = Yup.object().shape({
    request_type: Yup.string().required(t('Please select request type!')),
    building_id: Yup.string().required(t('Please select a Building!')),
    property_id: Yup.string().required(t('Please select a Unit!')),
    comments: Yup.string()
      .required(t('Please enter Description!'))
      .matches(/^[^\s].*/, (t('Description cannot start with a blankspace!')))
      .max(250, (t('Description should be 250 characters!'))),
    photo: Yup.mixed()
      .test('fileRequried', (t('Please select a file!')), (value) => {
        return value?.size ? true : false
      })
      .test('type', (t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!')), (value) => {
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'image/png' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', (t('The file is too large! Max allowed size is 5MB.')), (value) => {
        return value?.size <= 5242880
      }),
  })

  const initialValues = {
    request_type: quickLinkCategory ? quickLinkCategory.id : '',
    // building_id: '',
    building_id: buildingInit
      ? `${buildingInit.building_id}`
      : currentBuilding
      ? currentBuilding
      : '',
    property_id: buildingInit ? buildingInit.property_id : '',
    comments: '',
    photo: '',
  }

  useEffect(() => {
    setBuildingList(listBuilding)
    // getFmCommunityUser('?user_id=' + userId).then(({ data }) => {
    // 	if (data && Array.isArray(data)) {
    // 		setBuildingList(data.map((item: any) => ({ id: `${item.building_id}`, name: item.building_name, communityId: `${item.community_id}` })))

    // 		// const communityList =  data.map((item: any) => ({ id: `${item.community_id}`, name: item.community }))
    // 		// const filteredCommulityList = communityList?.filter((value, index, self) => index === self.findIndex((el) => ( el.id === value.id )))
    // 		// setPropertyList(filteredCommulityList)
    // 	}
    // 	setIsLoading((oState: any) => ({ ...oState, building: false }))
    // }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, building: false })); })

    // getFacilityBuildingList()
    //   .then(({data}) => {
    //     setBuildingUnitList(
    //       data && Array.isArray(data)
    //         ? data.map((item: any) => ({buildingId: `${item.id}`, unitList: item.property}))
    //         : []
    //     )
    //     setIsLoading((oState: any) => ({...oState, unit: false}))
    //   })
    //   .catch((e) => {
    //     setIsLoading((oState: any) => ({...oState, unit: false}))
    //   })

    // For Unit in Document we have to using getFacilityBuildingContractList() bcoz that api is having contract
    getFacilityBuildingContractList()
      .then(({data}) => {
        setBuildingUnitList(
          data && Array.isArray(data)
            ? data.map((item: any) => ({buildingId: `${item.id}`, unitList: item.property}))
            : []
        )
        setIsLoading((oState: any) => ({...oState, unit: false}))
      })
      .catch((e) => {
        setIsLoading((oState: any) => ({...oState, unit: false}))
      })
  }, [listBuilding])

  useEffect(() => {
    // Todo need to check
    // getFacilityBuildingContractList().then(({ data }) => {
    // 	setBuildingList(data)
    // })
    getFacilityDocumentList().then(({data}) => {
      setDocumentList(data)
    })
  }, [])

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {    
    if(e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf'){      
      inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)        
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', e.currentTarget.files[0])
    }else{
      setUploading(true)
      formik.setFieldError('photo', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', compressResponse.file, false)
          } else {
            setUploadedFileName(null)
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', '', false)
            formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('photo')
        formik.setFieldValue('photo', '', false)
        formik.setFieldError('photo', t('File upload error!'))
        setUploading(false)
      }
    }
    // inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
    // formik.setFieldValue('photo', e.currentTarget.files[0])
  }

  const handleSelect = (e: any) => {
    formik.setFieldValue('request_type', e)
    // setSelectedBuilding(buildingList.find((item: any) => item.id == e.target.value))
  }

  const handleBuildingSelect = (val: any) => {
    // formik.handleChange(e)
    formik.setFieldValue('building_id', val)
    formik.setFieldValue('property_id', '')
    formik.setFieldTouched('property_id', false)
  }

  const handleArrow = () => {}

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: createDocumentSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setSubmitError('')
      setTimeout(() => {
        createFacilityDocument(
          values.request_type,
          values.building_id,
          values.property_id,
          values.comments,
          values.photo
        )
          .then((response: any) => {
            setLoading(false)

            if (response.errorCode === 1) setSubmitError(response.errorDescription)
            else if (response.errorCode === 0) {
              formik.resetForm()
              setLoading(false)
              var ev = new Event('click', {bubbles: true})
              buttonEl.current.dispatchEvent(ev)
            } else setSubmitError(t('Some error occured. Please try again !'))
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t('Invalid Data Provided'))
          })
      }, 1000)
    },
  })

  useEffect(() => {
    const selectedBuildingId = formik.values.building_id
    const selectedBuildingUnits =
      selectedBuildingId && selectedBuildingId !== ''
        ? buildingUnitList?.find((el: any) => el.buildingId === selectedBuildingId)
        : []
    setUnitListOptions(
      selectedBuildingUnits?.unitList?.length ? selectedBuildingUnits.unitList : []
    )
    if (buildingInit && selectedBuildingId == buildingInit.building_id) {
      formik.setFieldValue('property_id', buildingInit.property_id)
    } else {
      formik.setFieldValue('property_id', '')
    }
  }, [formik.values.building_id, buildingUnitList])

  const isError = buildingList?.length === 0 ? (t('No building data found.')) : ''

  const isLoadingData = isLoading.unit

  // const buildingOptions = buildingList?.map((building: any) => (
  // 	<option key={building.id} value={building.id}>
  // 		{building.building}
  // 	</option>
  // ))

  const documentOptions = documentList?.map((documentTypeList: any) => (
    <option key={documentTypeList.id} value={documentTypeList.id}>
      {documentTypeList.master_data_name}
    </option>
  ))

  // const propertyOptions = selectedBuilding?.property?.map((property: any) => (
  // 	<option key={property.id} value={property.id}>
  // 		{property.property_code}
  // 	</option>
  // ))

  const onSubmitLinkRedirection =
    user_role === 'FCSS' ? '/fc-ss-documents/open-documents/cheques' : '/directory/residents'

  return (
    <div>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              {/* <Link to={'/fc-ss-documents/open-documents/cheques'} className=''>
								<img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
								<span className='text-black'>Add Document</span>
							</Link> */}
              <span
                className='me-5 c-pointer'
                onClick={() => {
                  history.goBack()
                }}
              >
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </span>
              <span className='text-black'>{t("Add Document")}</span>
            </h4>
          </div>
          <div className='col-12'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='documentForm'
              onSubmit={formik.handleSubmit}
            >
              <div className='card mb-3'>
                <div className='card-body px-8 py-6'>
                  {isLoadingData ? (
                    <div className='col-12 d-flex flex-column justify-content-center mt-5'>
                      <CustomLoaderCompact />
                    </div>
                  ) : (
                    isError !== '' && (
                      <div className='col-12 mt-5'>
                        <div className='alert alert-danger mt-2 text-center w-100' role='alert'>
                          {isError}
                        </div>
                      </div>
                    )
                  )}
                  <div className='row form-add-design'>
                    <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t("Type of Request")}
                          <span className='text-danger'> * </span>
                        </label>
                        <Select
                        className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                        showSearch
                        placeholder={t("Select Request Type")}
                        optionFilterProp="children"
                        defaultValue={formik.values.request_type !=='' ? formik.values.request_type : null}
                        onChange={handleSelect}
                        onBlur={formik.handleBlur}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                      >
                          {/* <option value='' disabled>
                            Please Select
                          </option> */}
                          {/* {documentList ? documentOptions : ''} */}
                          {documentList?.map((el: any, index: number) => 
                           <Option key={index} value={el?.id}>{el?.master_data_name}</Option>
                        )}
                        </Select>
                        {formik.touched.request_type && formik.errors.request_type && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.request_type}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t("Building Name")}
                          <span className='text-danger'> * </span>
                        </label>
                        <Select
                          // {...formik.getFieldProps('building_id')}
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select a Building')}
                          optionFilterProp='children'
                          defaultValue={
                            formik.values.building_id !== '' ? formik.values.building_id : null
                          }
                          onChange={handleBuildingSelect}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {/* <option value=''>Please Select</option> */}
                          {buildingList?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                        {/* //   return (
                          //     <option key={index} value={el.id}>
                          //       {el.name}
                          //     </option>
                          //   )
                          // })} */}
                        {formik.touched.building_id && formik.errors.building_id ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.building_id}
                              </span>
                            </div>
                          </div>
                        ) : // )}
                        null}
                      </div>
                    </div>
                    <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t("Unit Number")}
                          <span className='text-danger'> * </span>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select a Unit')}
                          optionFilterProp='children'
                          value={
                            formik.values.property_id !== '' ? formik.values.property_id : null
                          }
                          onChange={(val) => {
                            formik.setFieldValue('property_id', val)
                          }}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {unitListOptions?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.property_code}
                            </Option>
                          ))}
                        </Select>
                        {/* <option value=''>Please Select</option>
                          {unitListOptions?.map((el: any, index: number) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.property_code}
                              </option>
                            )
                          })}
                        </select> */}
                        {formik.touched.property_id && formik.errors.property_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.property_id}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-12'>
                      <div className='mb-7 upload-input'>
                        <label className='form-label fw-bolder'>
                          {t("Upload Document")}
                          <span className='text-danger'> * </span>
                        </label>
                        <input
                          ref={inputRef}
                          onChange={(e) => handleDisplayFileDetails(e)}
                          className='d-none'
                          type='file'
                          // onBlur={formik.handleBlur}
                        />
                        <button
                          type='button'
                          onClick={handleUpload}
                          disabled={uploading}
                          className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                            }`}
                        >
                          {uploading ?
                            <span className='indicator-progress d-block'>
                              {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            : uploadedFileName ? (
                              uploadedFileName
                            ) : (
                        <span className="txt">
                          <img
                                  alt="File"
                                  src={uploadImg}
                          className="position-relative"
                          loading="lazy"/>
                                {t("Upload")}{' '}
                                <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                              </span>
                            )}{' '}
                        </button>
                        {formik.touched.photo && formik.errors.photo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.photo}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-8 col-12'>
                      <div className='mb-0'>
                        <label className='form-label fw-bolder'>{t("Description")}</label>
                        <span className='text-danger'> * </span>
                        <textarea
                          className='form-control fw-bolder form-control-solid'
                          data-kt-autosize='true'
                          rows={3}
                          placeholder={t('Type here...')}
                          {...formik.getFieldProps('comments')}
                        />
                        {formik.touched.comments && formik.errors.comments ? (
                          <small className='text-danger'>{formik.errors.comments}</small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className='col-12 text-end mt-5'>
              {/* <Link to={'/fc-ss-documents/open-documents/cheques'} className=''> */}
              <button
                className='btn btn-outline-primary bg-white fw-bold px-10 py-3 me-5'
                onClick={() => {
                  history.goBack()
                }}
              >
                {t("Cancel")}
              </button>
              {/* </Link> */}
              <button
                type='submit'
                onClick={formik.submitForm}
                className='btn btn-primary fw-bold px-10 py-3'
                form='visitorForm'
                disabled={loading || uploading}
              >
                {!loading && <span className='indicator-label'>{t("Create")}</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {t("Please wait...")}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* <button ref={buttonEl}
								data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='button' className='d-none btn btn-primary fw-bold px-10 py-3'></button> */}
              {submitError && (
                <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                  {submitError}
                </div>
              )}
              <span
                ref={buttonEl}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='invisible d-none btn btn-primary fw-bold px-10 py-3'
              />
              <CustomRequestSuccessfulPopup
                successMessage={t('Document Added Successfully')}
                href={onSubmitLinkRedirection}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
