import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import {ContactModal} from '../ContactModal';
import {AddContactModal} from '../AddContactModal'
import {SelectLeadModal} from '../SelectLeadModal'
import { PropertyRequirementPopup } from '../../../pm-contacts/Contacts/PropertyRequirementPopup'
import { SelectListingPopup } from '../../../pm-contacts/Contacts/SelectListingPopup'

export function LeadEdit(props:any) {
  const { t } = useTranslation();
  const [requirementsCount, setRequirementsCount] = useState<any>(1);
  return (
    <>
    <div className="row align-items-center mb-5 community">
        <div className="col-md-12">
            <h5 className="theme-color font-22 d-inline-block float-left">
                <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                    <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                </Link>Update Lead
            </h5>
        </div>
    </div>

    <div className="card mb-7">
      <div className="card-body">
          <div className="row mb-3 pr-3">
              <div className="col col-xl-auto col-md-6">
                  <div className="border p-3 rounded">
                  <div className="row ">
                    <div className="col-sm-auto">
                        <img src="https://pm-crm.realcube.estate/img/avatar-con.jpeg" className="img-fluid border rounded-circle" width="45px"/>
                    </div>
                    <div className="col-sm align-items-center justify-content-between d-flex mt-2 mt-sm-0">
                        <Link to className=" text-decoration-none text-primary font-weight-semibold add-or-select-contact">
                        Add or Select Contact</Link>
                        <Link to="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#leadContact" className="text-decoration-none text-warning font-weight-semibold text-right lh-1 d-none d-sm-block">
                          <i className="fas fa-arrows-alt-h mr-1"></i> Change
                        </Link>                                                              
                    </div>
                    <div className="col-12">
                        <div id="contact-empty" className="text-danger float-left w-100 small"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <span className="d-inline-block bg-warning2 rounded px-4 py-2 text-center font-14 text-white"><b>Lead Score<br/>0%</b></span>
              </div>
          </div>        
          <div className="">
              <div className="row">
                      
                  <div className="col-md-4 col-lg-3 col-sm-6">
                      <div className="mb-7">
                          <label className="d-block w-100 text-uppercase mb-0 font-13"> Reference </label>
                          <input type="text" className="form-control form-height font-13" value="" name="reference_id" placeholder="" readOnly />
                      </div>
                  </div>
                  <div className="col-md-4 col-lg-3 col-sm-6">
                      <div className="mb-7">
                          <label className="d-block w-100 text-uppercase mb-0 font-13"> Created By </label>
                          <input type="text" className="form-control form-height font-13" value="" name="" placeholder="" readOnly/>
                      </div>
                  </div>
                  <div className="col-md-4 col-lg-3 col-sm-6">
                      <div className="mb-7">
                          <label className="d-block w-100 text-uppercase mb-0 font-13"> Enquiry Date </label>
                          <input type="date" id="enquiry-date" className="form-control form-height font-13" name="enquiry_date" placeholder="" value=""/>
                      </div>
                  </div>
                  <div className="col-md-4 col-lg-3 col-sm-6">
                      <div className="mb-7">
                          <label className="d-block w-100 text-uppercase mb-0 font-13"> Source </label>

                          <select className="selectpicker form-control form-height w-100" name="source">
                              <option value="">Select</option>
                            
                          </select>
                      </div>
                  </div>
                  <div className="col-md-4 col-lg-3 col-sm-6">
                      <div className="mb-7">
                          <label className="d-block w-100 text-uppercase mb-0 font-13"> Lead Type </label>
                          <select className="selectpicker form-control form-height w-100" id="lead_type" name="lead_type">

                          </select>
                      </div>
                  </div>
                  <div className="col-md-4 col-lg-3 col-sm-6">
                      <div className="mb-7">
                          <label className="d-inline w-100 text-uppercase mb-0 font-13"> Finance </label>
                          <label className="float-right mb-0 font-10 mt-2">(Only for buyer option)</label>
                          <select className="selectpicker form-control form-height w-100" title="Select" name="finance">
                            
                          </select>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>        
    <div className="card mb-7">
      <div className="card-body">
            <div className="row mb-3 pr-3">
              <div className="col col-xl-auto col-md-6">
                  <div className="border p-3 rounded">
                  <div className="row ">
                    <div className="col-sm-auto">
                        <img src="https://pm-crm.realcube.estate/img/avatar-con.jpeg" className="img-fluid border rounded-circle" width="45px"/>
                    </div>
                    <div className="col-sm align-items-center justify-content-between d-flex mt-2 mt-sm-0">
                        <Link to className=" text-decoration-none text-primary font-weight-semibold add-or-select-contact">
                        Add or Select Agent</Link>
                        <Link to="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#leadContact" className="text-decoration-none text-warning font-weight-semibold text-right lh-1 d-none d-sm-block">
                          <i className="fas fa-arrows-alt-h mr-1"></i> Change
                        </Link>                                                              
                    </div>
                    <div className="col-12">
                        <div id="contact-empty" className="text-danger float-left w-100 small"></div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div> 
            <div className="row">
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Status </label>
                        <input type="hidden" id="hdnPreviousValue" />
                        <select className="selectpicker form-control form-height w-100" name="status" id="sel_status">
                            <option>Select</option>
                            <option>New</option>
                            <option>Active</option>
                            <option>Lost</option>
                            <option>Won</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="mb-7 sub_status_field">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Sub Status </label>
                        <select className="form-control form-height w-100 font-13" name="sub_status" title="Select" id="sub_status">
                            <option>Select</option>
                            <option>Not Yet Contacted</option>
                            <option>Contacted</option>
                        </select>
                    </div>
                </div>
                
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Priority </label>
                        <select className="selectpicker form-control form-height w-100" name="priority">
                            <option>Select</option>
                            <option>Urgent</option>
                            <option>Low</option>
                            <option>Normal</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Lead Status </label>
                        <select className="selectpicker form-control form-height w-100" name="category">
                            <option>Select</option>
                            <option>Hot</option>
                            <option>Warm</option>
                            <option>Cold</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4 col-lg-6 ">
                    <div className="mb-7">
                        <label className="d-block w-100 text-uppercase mb-0 font-13"> Status Remarks </label>
                        <textarea className="form-control font-13 status_remarks" rows={3} name="status_remarks" placeholder="Remarks"></textarea>
                    </div>
                </div>
            </div> 
      </div>
    </div>
    <div className="card mb-7">
      <div className="card-body">
          <div className="">
              <div className="row">
                    <div className="col-md-12">
                        <h6 className="font-weight-500 text-warning font-14"> Property Requirements
                            <a href="javascript:void(0);" className="text-decoration-none text-gray-800" data-toggle="tooltip" data-placement="top" title="Multiple property requirements can be added against a lead. For example, when a buyer has given two different locations (e.g. Springs or Ranches) they would buy in, both areas can be recorded as seperate requirements."><i className="fas fa-question-circle font-10 text-grey-700"></i></a>
                        </h6>
                    </div>
                    
                  {
                    Array(requirementsCount).fill(1).map((data, index) =>{
                        
                        return(
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 req-card-append lead-add-height h-auto">
                                        
                                        <div className="row no-gutters req-card rounded p-2 mb-1">
                                            <div className="col-sm">
                                                <button type="button" className="btn theme-color font-14 font-weight-semibold bg-transparent d-flex flex-wrap px-0 border-0 align-items-center">
                                                    <div className="req-collapse-icon" data-toggle="collapse" data-target={`#req-item${index}`}>
                                                        <i className="fa fa-angle-right me-2"></i> Requirement {index+1}
                                                    </div>
                                                    <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-req-modal" className="text-decoration-none text-gray-800 font-12 rounded-circle ms-2">
                                                        <i  className="fas fa-plus-square"></i>
                                                    </a>
                                                </button>
                                            </div>
                                            { requirementsCount === (index+1) &&
                                                <div className="col-sm-auto">
                                                    <button type="button" className="btn btn-primary" onClick={()=>{setRequirementsCount((oState:any)=>oState+1)}}><span className="fas fa-plus"></span></button>
                                                </div>
                                            }
                                            { index > 0 &&
                                                <div className="col-sm-auto">
                                                    <button type="button" className="btn btn-primary" onClick={()=>{setRequirementsCount((oState:any)=>oState-1)}}><span className="fas fa-minus"></span></button>
                                                </div>
                                            }
                                            <div className="col-md-12">
                                                <div id={`req-item${index}`} className="collapse show bg-white p-3 mt-2 property1-text">
                                                    <div className="border p-2 selectBorder">
                                                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#prop-enq-modal" className="text-decoration-none font-weight-semibold text-warning selectPropEnquiry">
                                                            Select the Property Enquired For
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        )
                    })  
                }
              </div>
              <div className="row">
                  <div className="col-md-12">
                      <div className="prop-detail-tab">
                          
                          <div className="tab-content mt-3">
                              <div id="notes-list" className="notes-list tab-pane fade show">
                                  <div className="row">
                                      <div className="col-md-12 show-update-notes">
                                      
                                          <div className="mb-7">
                                              <textarea className="form-control font-13 form-height click-add" id="notes_description" placeholder="Click to add your note"></textarea>
                                          </div>
                                          <div className="show-sub-note">
                                              <button type="button" className="btn btn-outline-warning px-4 font-12 font-weight-semibold radius-10 cancel-add">Cancel</button>
                                              <button type="button" className="btn btn-warning px-4 font-12 font-weight-semibold radius-10" id="add_notes">Submit</button>
                                          </div>
                                      </div>
                                      <div className="col-md-12 notes-doc-list">
                                          <div className="activities-sect">
                                              <div className="card rounded mb-2 text-left activity-list">
                                                  <div className="card-body p-2 ">
                                                      <div className="notes_doc_lists">
                                                        
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div id="contact-hist-list" className="contact-hist-list tab-pane fade">
                                  <div className="row align-items-center">
                                      <div className="col-md-12 text-center py-5">
                                          <div className="">
                                              <i className="fas fa-history text-gray-400 font-29"></i>
                                              <h6 className="text-gray-400 my-2"> No Contact History </h6>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div id="viewings-list" className="viewings-list tab-pane fade">
                                  <div className="row align-items-center">
                                      <div className="col-md-12 text-center py-5">
                                          <div className="">
                                              <i className="fas fa-briefcase text-gray-400 font-29"></i>
                                              <h6 className="text-gray-400 my-2"> No Viewings Found </h6>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div id="offers-list" className="offers-list tab-pane fade">
                                  <div className="row align-items-center">
                                      <div className="col-md-12 text-center">
                                          <div className="notes_doc_list">
                                              
                                              <div className="clearfix"></div>
                                            
                                                  <div className="card rounded mb-2  text-left">
                                                      <div className="card-body p-2">
                                                          <h6 className="font-14 font-weight-bold "><a className="text-warning" href=""></a>
                                                              <span className="float-right badge font-8 font-weight-bold text-white py-2 ml-2"></span>
                                                              <span className="float-right py-1 ml-2"><a href=""><i className="fas fa-edit"></i></a></span>
                                                          </h6>
                                                          <p className="font-10 mb-0">
                                                              Offer Date :  
                                                          </p>
                                                          <p className="font-10 mb-0">
                                                              Offer Price :  
                                                          </p>
                                                          <p className="font-10 mb-0">
                                                              Deposit :
                                                          </p>
                                                      </div>
                                                  </div>
                                                
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div id="activities-list" className="activities-list tab-pane fade">
                                  <div className="row align-items-center">
                                      <div className="col-md-12 text-center py-3">

                                          <label className="d-block text-left font-12 activity-title">Activities</label>
                                          <select id="activities-select" className="selectpicker form-control form-height w-100" data-size="7" data-live-search="true"  data-title="Select" data-width="100%">
                                            
                                          </select>
                                      </div>
                                      <div className="col-md-12">
                                          <div className="activities-sect">
                                              <div className="card rounded mb-2 text-left activity-list">
                                                
                                                        
                                                        
                                              </div>
                                          </div>
                                        
                                      </div>
                                  </div>
                              </div>
                              <div id="matching-list" className="matching-list tab-pane fade">
                                  <div className="row">
                                      <div className="col-md-12">
                                          <h6 className="font-12 text-theme-black font-weight-semibold"> Property Enquired For </h6>
                                          <div className="lead-add-height">
                                              <div className="row no-gutters mb-3">
                                                  <div className="col-md-12 mb-2">
                                                      <a href="javascript:void(0);" data-toggle="modal" data-target="#prop-send-email" className="text-decoration-none font-12 text-warning">
                                                          <i className="fa fa-envelope"></i>  Email
                                                      </a>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <img src="" className="img-fluid"/>
                                                  </div>
                                                  <div className="col-md-9 px-2">
                                                      <div className="row">
                                                          <div className="col-md-12">
                                                              <a href="#" className="text-decoration-none">
                                                                  <h6 className="font-weight-semibold text-theme-black matching-title font-12">
                                                                      Large Layout | Will Be Vacant Soon | Reserve Now!!, Al Reem
                                                                  </h6>
                                                                  <p className="mb-1 font-12 text-theme-black"> RC-L-4836 </p>
                                                              </a>
                                                              <span className="text-theme-black font-10"> AED </span> <span className="text-warning font-10 font-weight-semibold mr-2"> 59583 </span>
                                                              <span className="text-warning font-10 font-weight-semibold"> 2,077 </span> <span className="text-theme-black font-10"> sqft </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="row no-gutters mb-3">
                                                  <div className="col-md-12 mb-2">
                                                      <a href="javascript:void(0);" data-toggle="modal" data-target="#prop-send-email" className="text-decoration-none font-12 text-warning">
                                                          <i className="fa fa-envelope"></i>  Email
                                                      </a>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <img src="" className="img-fluid"/>
                                                  </div>
                                                  <div className="col-md-9 px-2">
                                                      <div className="row">
                                                          <div className="col-md-12">
                                                              <a href="#" className="text-decoration-none">
                                                                  <h6 className="font-weight-semibold text-theme-black matching-title font-12">
                                                                      Large Layout | Will Be Vacant Soon | Reserve Now!!, Al Reem
                                                                  </h6>
                                                                  <p className="mb-1 font-12 text-theme-black"> RC-L-4836 </p>
                                                              </a>
                                                              <span className="text-theme-black font-10"> AED </span> <span className="text-warning font-10 font-weight-semibold mr-2"> 59583 </span>
                                                              <span className="text-warning font-10 font-weight-semibold"> 2,077 </span> <span className="text-theme-black font-10"> sqft </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="row no-gutters mb-3">
                                                  <div className="col-md-12 mb-2">
                                                      <a href="javascript:void(0);" data-toggle="modal" data-target="#prop-send-email" className="text-decoration-none font-12 text-warning">
                                                          <i className="fa fa-envelope"></i>  Email
                                                      </a>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <img src="" className="img-fluid"/>
                                                  </div>
                                                  <div className="col-md-9 px-2">
                                                      <div className="row">
                                                          <div className="col-md-12">
                                                              <a href="#" className="text-decoration-none">
                                                                  <h6 className="font-weight-semibold text-theme-black matching-title font-12">
                                                                      Large Layout | Will Be Vacant Soon | Reserve Now!!, Al Reem
                                                                  </h6>
                                                                  <p className="mb-1 font-12 text-theme-black"> RC-L-4836 </p>
                                                              </a>
                                                              <span className="text-theme-black font-10"> AED </span> <span className="text-warning font-10 font-weight-semibold mr-2"> 59583 </span>
                                                              <span className="text-warning font-10 font-weight-semibold"> 2,077 </span> <span className="text-theme-black font-10"> sqft </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="row no-gutters mb-3">
                                                  <div className="col-md-12 mb-2">
                                                      <a href="javascript:void(0);" data-toggle="modal" data-target="#prop-send-email" className="text-decoration-none font-12 text-warning">
                                                          <i className="fa fa-envelope"></i>  Email
                                                      </a>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <img src="" className="img-fluid"/>
                                                  </div>
                                                  <div className="col-md-9 px-2">
                                                      <div className="row">
                                                          <div className="col-md-12">
                                                              <a href="#" className="text-decoration-none">
                                                                  <h6 className="font-weight-semibold text-theme-black matching-title font-12">
                                                                      Large Layout | Will Be Vacant Soon | Reserve Now!!, Al Reem
                                                                  </h6>
                                                                  <p className="mb-1 font-12 text-theme-black"> RC-L-4836 </p>
                                                              </a>
                                                              <span className="text-theme-black font-10"> AED </span> <span className="text-warning font-10 font-weight-semibold mr-2"> 59583 </span>
                                                              <span className="text-warning font-10 font-weight-semibold"> 2,077 </span> <span className="text-theme-black font-10"> sqft </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div className="d-block text-end mt-3">
      <button className="btn btn-primary radius font-weight-semibold px-5" id="form-create-lead-submit">Save</button>
    </div>

    <ContactModal />
    <AddContactModal/>
    <SelectLeadModal/>
    <PropertyRequirementPopup/>     
    <SelectListingPopup/> 
    </>
  )
}
