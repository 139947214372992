import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getMyTeamChartData } from '../../../../constants/axios/apis';
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { MyTeamChart } from './MyTeamChart';
import { useTranslation } from 'react-i18next';

export function TeamChartWrapper({loading, error, chartData}:any) {
    const { t } = useTranslation()
    
    return (
        <div className='col-xl-5 col-sm-12 col-12'>
            <div className='card mb-3 min-height-card1' style={{ minHeight: '350px' }}>
                <div className='card-body px-8 py-2'>
                    <div className='row'>
                        <div className='col-12 z-index-3'>
                            <div className='row mt-5 align-items-center'>
                                <div className='col-xl-5 col-sm-5 col-12'>
                                    {/* <h4 className='card-title font-18 text-black'> {t("Attendance")} </h4> */}
                                    <h4 className='card-title font-18 text-black'> {t("My Team")} </h4>
                                </div>
                                <div className='col-xl-7 col-sm-7 col-12 text-end'>
                                    <Link
                                        to={'/mta-myteam/add'}
                                        className='btn btn-primary btn-sm font-12'
                                    >
                                        <i className='bi bi-plus-circle-fill align-middle'></i> {t("Add Member")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {loading ?
                            <div className="col-12">
                                {/* <Loader /> */}
                                <CustomLoader2 height={'166'} />
                            </div>
                            : error !== '' ?

                                <div className="col-12">
                                    <div className='alert alert-danger w-100 text-center mt-5' role='alert'>{error}</div>
                                </div>
                                :
                                <div className='col-12 overflow-hidden'>
                                    <MyTeamChart chartData={chartData} />
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}