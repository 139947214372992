import React, { FC, useEffect, useRef, useState } from 'react'
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import FullCalendar, { CalendarOptions, EventInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils'

import { useTranslation } from 'react-i18next';

export const CalenderCard: React.FC<any> = ({eventState, setSelectedDate}) => {
	// console.log('eventState: ',eventState);

// 	select: function(start, end, jsEvent, view) {
// 		// start contains the date you have selected
// 		// end contains the end date. 
// 		// Caution: the end date is exclusive (new since v2).
// 		var allDay = !start.hasTime() && !end.hasTime();
// 		alert(["Event Start date: " + moment(start).format(),
// 			   "Event End date: " + moment(end).format(),
// 			   "AllDay: " + allDay].join("\n"));
//    }

//    dayClick: function(date, jsEvent, view) { 
// 	alert('Clicked on: ' + date.getDate()+"/"+date.getMonth()+"/"+date.getFullYear());  
// },

// dayClick: function(date, jsEvent, view) { 
// 	alert("Selected Date:"+date._d)
//  }

	const handleDateClick = (arg:any) => {
		setSelectedDate(arg?.date)
	}

	const { t } = useTranslation();									
	return (
		<div className='com-card-section'>
			<div className='row'>
				<div className='col-12'>
					<div className='card mb-6 calender-card-sec'>
						<div className='card-body px-8 py-10'>
							<div className='row'>
								<div className='col-12'>
									<div className='row'>
										<div className='new-calender'>
											<FullCalendar
												plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
												headerToolbar={{
													left: 'prev,title,next',
													// center: 'title',
													right: 'timeGridDay,timeGridWeek,dayGridMonth',
												}}
												initialView='dayGridMonth'
												editable={true}
												selectable={true}
												selectMirror={true}
												dayMaxEvents={true}
												events={eventState} // alternatively, use the `events` setting to fetch from a feed
												dateClick={handleDateClick}
											/>
										</div>
									</div>
								</div>
								<div className='col-12'>
									<div className='row pb-13 pt-10'>
										<div className='col-12'>
											<div className='calendar_div_2'>
												<div className='corrective me-10'>
													<div className='box red me-4'></div>
													<p className='mb-0'> {t("Corrective")}</p>
												</div>
												<div className='corrective me-10'>
													<div className='box blue me-4'></div>
													<p className='mb-0'> {t("Clearance")}</p>
												</div>
												<div className='corrective'>
													<div className='box green me-4'></div>
													<p className='mb-0'> {t("Preventive")}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
