import React, { useState, useEffect, useRef } from 'react';
import Loader from '../../../../../components/Loader';
import { getWorkOrderComments, postWorkOrderComments } from '../../../../../constants/axios/apis';
import { formatDateTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater';

import { useTranslation } from 'react-i18next';

export function Comments({workOrder}:any) {
    const { t } = useTranslation();
    const [commentList, setCommentList] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const [commentError, setCommentError] = useState('');
    const [submitError, setSubmitError] = useState('');
    const [commentInput, setCommentInput] = useState('');
    const [reload, setReload] = useState(true);
    
    
    useEffect(()=>{
        if(reload){
            setLoading(true)
            setError('')
            getWorkOrderComments('?work_order_id=' + workOrder?.id)
                .then((response:any) => {
                    if(response.errorCode === 0){
                        setCommentList(response?.data)
                    }else if(response.errorCode === 1){
                        setCommentList([])
                        if(response.errorDescription !== 'No data found.')
                            setError(response.errorDescription)
                    }else{
                        setCommentList([])
                        setError(t("Some error occured!"))
                    }
                    setLoading(false)
                }).catch((e:any)=>{
                    setLoading(false)
                    setCommentList([])
                    setError(t("Some error occured"))
                    console.log(e, 'error in team list');
                })
            setReload(false)
        }
    },[reload]);

    const handleSubmit = () => {
        // const specialRegex = /[^A-Z a-z0-9]/
        const commentData = commentInput.trim()     
        if( commentData === ''){
            setCommentError(t('Please type a comment!'))
        // }else if(specialRegex.test(commentInput)){
        //     setCommentError('Symbols are not allowed in comment')
        }else if(/^\s/.test(commentInput)){
            setCommentError(t('Comment cannot start with space!'))
        }else{
            const formData = JSON.stringify({
                "work_order_id": workOrder?.id,
                "comment": commentData, 
            });
            setSubmitting(true)
            setSubmitError('')
            setCommentError('')
            // console.log(formData, 'formData');
            
            postWorkOrderComments(formData)
                .then((data: any) => {
                    if (data.code === 200 && data.errorCode === 0) {
                        setReload(true)
                        setCommentInput('')
                        // ref.current.click();//Reload comments
                    } else {
                        setSubmitError(t('Some error occured!'))
                    }
                    setSubmitting(false)
                })
        } 
        
        
    };
    
    // console.log(submitError, 'submitError');    
    
    return (
        <div className="row border-top pt-10 pb-0 mt-5">
            <div className="col-12">
                <div className="row form-add-design">
                    <div className="col-12">
                        <label className="form-label fw-bolder mb-0"> {t("Comments")}</label>
                    </div>
                    {loading &&
                        <Loader />
                    }
                    {!loading && error !== '' && (
                        <div className="col-12 mb-5">
                            <div style={{width: 'fit-content'}} className="alert alert-danger mt-2 mb-2" role="alert">
                                {error}
                            </div>
                        </div>
                    )}
                    {!loading && 
                        commentList?.map((commentItem:any, index:number)=>{
                            return (
                                <div className="col-12 mb-5" key={index}>
                                    <div className="data-contain h-auto 75 px-5 py-4 rounded-10 mb-2">
                                        <p className="font-14 fw-bold d-block mb-0">
                                            {commentItem.comment}
                                        </p>
                                    </div>
                                    <label className="font-12 fw-bold mb-3 text-theme-gray ps-5">
                                        {commentItem.userName} ({formatDateTimeMeridiem(commentItem.created_at)})
                                    </label>
                                </div>
                            )
                        }
                        
                    )}

                    {
                        (workOrder.status !== 'Completed' && workOrder.status !=='Under observation') ?
                        <>
                            <div className="col-12 mt-5">
                                <textarea
                                    className='form-control fw-bolder'
                                    data-kt-autosize='true'
                                    rows={3}
                                    value={commentInput}
                                    onChange={(e) => setCommentInput(e?.target?.value)}
                                    placeholder={t('Write Comment')} />
                                {  commentError !=='' &&
                                    <div className='text-danger'>
                                        {commentError}
                                    </div>
                                }
                                
                            </div>
                            <div className="col-12 text-end pt-5">
                                {isSubmitting ? 
                                    <button type='button' className='btn btn-primary fw-bold px-10 py-3' >
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        {t("Please wait...")}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    </button>
                                :
                                    <button type='button' className='btn btn-primary fw-bold px-10 py-3' onClick={handleSubmit} >
                                        <span className='indicator-label'>{t("Submit")}</span>
                                    </button>
                                }
                            </div>
                        </>: commentList.length === 0 ? <i>{t("No comments available!")}</i> : ''
                    }
                    
                    {submitError !== '' && (
                        <div className="col-12 pt-5">
                            <div style={{width: 'fit-content'}} className="alert alert-danger mt-2 mb-2" role="alert">
                                {submitError}
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
        </div>
    )
}