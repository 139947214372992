import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentInfo } from '../../../finance-lease-details/FinanceLeaseDetailsScreen/components/PaymentInfo/PaymentInfo'

export function PaymentDetails({ receiptDetail }: any) {
  const { t } = useTranslation()

  return (
    <>
      <div className='card px-7 py-7 mb-7'>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <h4 className='font-16 text-black fw-bolder mb-8'>{t('Payment Status')}</h4>
          </div>
        </div>
        <div className='row payment-st-body'>
          <PaymentInfo
            id={receiptDetail?.paymentDetails?.id}
            due_date={receiptDetail?.paymentDetails?.dueDate}
            paid_date={receiptDetail?.paymentDetails?.paymentReceived}
            method={receiptDetail?.paymentDetails?.paymentType}
            type={receiptDetail?.paymentDetails?.subCategory}
            amount={receiptDetail?.paymentDetails?.amount}
            email={receiptDetail?.email}
            invoice={receiptDetail?.paymentDetails?.invoice_file}
            receipt={receiptDetail?.paymentDetails?.receipt}
            ref_id={receiptDetail?.paymentDetails?.referenceId}
            payment_status={receiptDetail?.paymentDetails?.status === 'paid' ? 1 : 0}
          />
        </div>
      </div>
    </>
  )
}
