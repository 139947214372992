import React from 'react'
import Img1 from "../../../../assets/images/login-img.png";
import Img2 from "../../../../assets/images/Image_10.png";
import Img3 from "../../../../assets/images/Image_9.png";

export function ImageGallery(props:any) {
const proprtyDetails = props.proprtyDetails
    return (
        <div className="row mb-10 mt-4">
            <div className="col-12 col-sm-8">
                <div className="galery_img_main">
                    <img src={proprtyDetails?.coverImage?.length > 0 ? proprtyDetails?.coverImage:Img1} className="img-fluid me-3"/>
                </div>
            </div>
            <div className="col-12 col-sm-4">
                <div className="galery_img">
                    <div className="row">
                        {proprtyDetails?.property_images?.length > 0 ? proprtyDetails?.property_images.map((item:any, index:number) => {
                       return( <div className="col-6 mb-6" key={index}>
                            <img src={item?.propertyImage} className="img-fluid me-3"/>
                        </div>)
                        }):<div className="col-6 mb-6">
                        <img src={Img2} className="img-fluid me-3"/>
                    </div>}
                    </div>
                </div>
            </div>
        </div>

    )
};