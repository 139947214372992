import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { MenusList } from './menu-components/MenusList'
import { AddMenu } from './menu-components/AddMenu'
import { EditMenu } from './menu-components/EditMenu'


const PMMenus: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/menus/list'>
                            <MenusList/>
                        </Route>
                        <Route path='/menus/add'>
                            <AddMenu/>
                        </Route>
                        <Route path='/menus/edit'>
                            <EditMenu/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMMenusWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMMenus />
        </>
    )
};

export { PMMenusWrapper }

