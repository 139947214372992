import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Home from '../../../../assets/images/nation-towers-new.png'
import { RootState } from '../../../../constants'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export function Details() {
  let contractApprovalData: any = []
  contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
  const { t } = useTranslation()

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body p-0'>
              <div className='row align-items-center'>
                <div className='col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-4'>
                  <div className='img-bg'>
                    <img src={contractApprovalData?.image} className='img-fluid' />
                    <div className='property-overlay'>
                      <div className="col-sm-12 col-12">
                        <h6 className="mb-0 fw-bold text-white font-14">{t('Offer Number')} : {contractApprovalData?.details?.ticket}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white shadow-none rounded-0'>
                    <div className='card-body p-0'>
                      <div className='row'>
                        <div className='col-sm-12 col-12 mb-4'>
                          <h6 className='text-black mb-0 fw-bold font-15'>
                            {contractApprovalData?.building}
                          </h6>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7'>
                        <div className="d-flex align-items-baseline justify-content-start">
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Lease Start Date')}
                          </h6>
                          <span className='text-black fw-bold font-14 ms-5'>
                            {moment(contractApprovalData?.details?.leaseStartDate).format('DD-MM-YYYY')}
                          </span>
                        </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7'>
                        <div className="d-flex align-items-baseline justify-content-start">
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {t('Lease End Date')}
                            </h6>
                            <span className='text-black fw-bold font-14 ms-5'>
                              {moment(contractApprovalData?.details?.leaseEndDate).format('DD-MM-YYYY')}
                            </span>
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7 mb-md-0'>
                        <div className="d-flex align-items-baseline justify-content-start">
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Security Deposit')}
                          </h6>
                          {/* <span className="text-black fw-bold font-14 ms-5"> AED 70,000 </span> */}
                          <span className='text-black fw-bold font-14 ms-5'>
                            {t('AED')} {(contractApprovalData?.security_deposit?.toLocaleString(undefined, { maximumFractionDigits: 2 }))}
                          </span>
                        </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7'>
                        <div className="d-flex align-items-baseline justify-content-start">
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Annual Rent')}
                          </h6>
                          {/* <span className="text-black fw-bold font-14 ms-5"> {t("AED")} 12350 </span> */}
                          <span className='text-black fw-bold font-14 ms-5'>
                            {t('AED')} {contractApprovalData?.annualRent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </span>
                        </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7 mb-md-0'>
                        <div className="d-flex align-items-baseline justify-content-start">
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('No Of Payment')}
                          </h6>
                          {/* <span className="text-black fw-bold font-14 ms-5"> 4 </span> */}
                          <span className='text-black fw-bold font-14 ms-5'>
                            {contractApprovalData?.NoOfPayments}
                          </span>
                        </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7 mb-md-0'>
                        <div className="d-flex align-items-baseline justify-content-start">
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {t('Target Rent')}
                          </h6>
                          <span className='text-black fw-bold font-14 ms-5'>
                            {t('AED')} {contractApprovalData?.annualRent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </span>
                        </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Budget  Rent</h6>
                                                    <span className="text-black fw-bold font-14 ms-5"> AED - </span>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6  col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13">Rent Assessment</h6>
                                                    <span className="text-black fw-bold font-14 ms-5"> AED - </span>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
