import React, {useState, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import LocationIoc from "../../../../../assets/images/moveout-loc.png";
import BedIoc from "../../../../../assets/images/moveout-bedrooms.png";
import PayIoc from "../../../../../assets/images/moveout-pay.png";
import DocIoc from "../../../../../assets/images/moveout-doc.png";

export function BasicDetails({moveoutData, paymentCount}:any) {
    const {t} = useTranslation()
    const history = useHistory();

    return (
        <div className="com-card-section myunit-page">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <span className='c-pointer' onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        {t("Ticket No.")}: {moveoutData?.ticketNo}
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="font-15 fw-bold text-black mb-6">
                                        {t("Moveout Request")}
                                    </h4>
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-sm-6 col-md-4 col-lg-3 text-xxl-center mb-xs-7">
                                    <div className='approval-move'>
                                        <img src={moveoutData?.communityLogo} alt='property-image' className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white shadow-none rounded-0">
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-md-12 col-12 mb-7">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-12 mb-7">
                                                            <h6 className="text-black mb-2 fw-normal font-15"> {moveoutData?.property_name} </h6>
                                                            <span className="text-black fw-normal font-14">
                                                                <img src={LocationIoc} className="img-fluid me-1" />
                                                                {moveoutData?.community}, {moveoutData?.country_name}
                                                            </span>
                                                        </div>
                                                        <div className="col-12">
                                                          <div className="d-flex justify-content-start">
                                                            <div className="bg-light-grey rounded px-3 py-2 d-inline">
                                                                <img src={BedIoc} className="img-fluid me-2" />
                                                                <span className="fw-normal font-12 text-black">
                                                                    {moveoutData?.bedrooms} {t("Bedrooms")}
                                                                </span>
                                                            </div>
                                                            <div className="bg-light-grey rounded px-3 py-2 d-inline mx-5">
                                                                <img src={PayIoc} className="img-fluid me-2" />
                                                                <span className="fw-normal font-12 text-black">
                                                                    {paymentCount} {t("Payments")}
                                                                </span>
                                                            </div>
                                                            <div className="bg-light-grey rounded px-3 py-2 d-inline">
                                                                <img src={DocIoc} className="img-fluid me-2" />
                                                                <span className="fw-normal font-12 text-black">
                                                                    {moveoutData?.documentCount} {t("Documents")}
                                                                </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-8 mb-md-0">
                                                            <div className="bg-light-grey rounded px-3 py-2 d-inline">
                                                                <img src={BedIoc} className="img-fluid me-2" />
                                                                <span className="fw-normal font-12 text-black">
                                                                    {moveoutData?.bedrooms} {t("Bedrooms")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-8 mb-md-0">
                                                            <div className="bg-light-grey rounded px-3 py-2 d-inline">
                                                                <img src={PayIoc} className="img-fluid me-2" />
                                                                <span className="fw-normal font-12 text-black">
                                                                    {moveoutData?.no_payments} {t("Payments")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-8 mb-md-0">
                                                            <div className="bg-light-grey rounded px-3 py-2 d-inline">
                                                                <img src={DocIoc} className="img-fluid me-2" />
                                                                <span className="fw-normal font-12 text-black">
                                                                    {moveoutData?.documentCount} {t("Documents")}
                                                                </span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Tenant Name")} </h6>
                                                    <span className="text-black fw-bold font-14"> {moveoutData?.tenantName} </span>
                                                </div>
                                                <div className="col-md-4 col-12 mb-7">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease Number")} </h6>
                                                        <span className="text-black fw-bold font-14"> {moveoutData?.leaseNumber} </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Unit Number")} </h6>
                                                    <span className="text-black fw-bold font-14"> {moveoutData?.propertyCode} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}