import React, { useState, useEffect } from 'react';
import ban02 from "../../../assets/images/forgot-img.png";
// import LoginContain from "./Login.style";

export function ResetLeftBanner() {
    return (
        <div>
            <img alt='Logo' src={ban02} className='img-fluid w-100' />
        </div>
    );
}