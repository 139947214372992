import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'

export function PMFilterPopup() {
    const { t } = useTranslation();

    return (
        <div className="modal fade add-broker-modal" id="add-filter-details">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header px-8 py-8">
                        <h5 className="modal-title text-black font-18 fw-bolder">{t("Filter")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-8">
                        <form>
                            <div className="row form-add-design">
                                <div className="col-12">
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> {t("Range")} </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Enter")} />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> {t("Unit Number")} </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> {t("Building")} </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> {t("Payment Type")} </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer px-8 pb-8">
                        <button type="button" className="btn btn-outline-primary fw-bold px-14 py-3 me-5">
                            {t("Cancel")}
                        </button>
                        <button type="button" className="btn btn-primary fw-bold px-14 py-3">
                            {t("Apply")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}