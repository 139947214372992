import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import {Link} from 'react-router-dom'

export function StepTwo() {

    const [selected, setSelected] = useState([]);

    const options = [
        { label: "Housing", value: "Housing" },
        { label: "Food", value: "Food" },
        { label: "Marketing", value: "Marketing" },
    ];

    const optionsLocation = [
        { label: "Abu dhabi, United arab emirates", value: "Abu dhabi, United arab emirates" },
        { label: "Bangalore, India", value: "Bangalore, India" },
        { label: "US", value: "US" },
    ];

    const optionsAgeRange = [
        { label: "10-15", value: "10-15" },
        { label: "15-30", value: "15-30" },
        { label: "30-70", value: "30-70" },
    ];

    return (
        <div className="row py-6 step-one">
            <div className="col-md-12">
                <div className="row form-add-design">
                    <div className="col-md-12 col-12">
                        <div className="mb-7">
                            <label className="form-label fw-bolder text-black mb-3"> Special ad category </label>
                            <MultiSelect
                                options={options}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                                disableSearch={false}
                            />
                            <span className="add-on-text d-block text-black font-12 fw-normal mt-3">
                                Is your ad related to housing or credit?
                                <Link to={'/mta-campaign/open'} className="font-12 fw-normal ms-1">
                                    Learn more
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div className="col-12">
                        <h5 className="text-black font-16 fw-normal mb-6">
                            Audience
                        </h5>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="mb-7">
                            <label className="form-label fw-bolder text-black d-block mb-3"> Audience </label>
                            <div className="form-check form-check-inline custom-radio">
                                <input className="form-check-input" type="radio" name="campaign" id="inlineRadio1" />
                                <label className="form-check-label text-black fw-bolder font-12" htmlFor="inlineRadio1">
                                    New Audience
                                </label>
                            </div>
                            <div className="form-check form-check-inline custom-radio mb-4">
                                <input className="form-check-input" type="radio" name="campaign" id="inlineRadio2" />
                                <label className="form-check-label text-black fw-bolder font-12" htmlFor="inlineRadio2">
                                    Select Saved audience
                                </label>
                            </div>
                            <MultiSelect
                                options={options}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                                disableSearch={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="mb-7">
                            <label className="form-label fw-bolder text-black d-block mb-3"> Location </label>
                            <h6 className="text-black font-12 fw-bold mb-3">
                                People living in any of the following
                            </h6>
                            <MultiSelect
                                options={optionsLocation}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                                disableSearch={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="mb-5">
                            <label className="form-label fw-bolder text-black d-block mb-3"> Age range </label>
                            <h6 className="text-black font-12 fw-bold mb-3">
                                Select target age people
                            </h6>
                            <MultiSelect
                                options={optionsAgeRange}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                                disableSearch={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}