import React, {useState, useEffect, useRef} from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import Announcement01 from '../../../../assets/images/fc-announce-banner.png'
import {RootState} from '../../../../constants'
import ArrowRightBlue from '../../../../assets/images/arrow-right-blue.png'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'

import {useSelector} from 'react-redux'
import {getFCSAnnouncements} from '../../../../constants/axios/apis'
// import { getAnnouncementsLists } from '../../../../utils/makeRequest';
import {IState} from '../../../../utils/interface'
import Loader from '../../../../components/Loader'
import {formatDate, formatTimeMeridiem} from '../../../../constants/generics/dateTimeFormater'
import moment from 'moment'
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios'

import { useTranslation } from 'react-i18next';

interface IProps {
  brokersList: IState['APIState']
}

export function Announcements() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  const [loading, setLoading] = useState(true)
  const [announcementsLists, setAnnouncementsLists] = useState<any>([])
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
 
  // const state:any = useSelector(state => state);
  // const userToken = state?.auth?.accessToken;
  // if(userToken === "undefined"){
  //     // token undefined
  // }
  // const [announcementsLists, setAnnouncementsLists] = useState<IState['APIState']>({
  //     loading: false,
  //     response: [],
  //     error: ''
  // });
  // const {loading, response: announcementsList, error} = announcementsLists;
  useEffect(() => {
    setLoading(true)
    setAnnouncementsLists([])
    const newPayload = '?status=open&id_sort=1&date_sort=1' +(currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getFCSAnnouncements(newPayload)
      .then((response: any) => {
        let announcementsData = response.data
        announcementsData = announcementsData?.filter((announcement: any) => {
          const annpuncementEndTime = new Date(
            announcement.endDate + ' ' + announcement.end_time
          ).getTime()
          let currentTime = new Date().getTime()
          return currentTime <= annpuncementEndTime
        })
        setAnnouncementsLists(announcementsData)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [currentBuilding])

  const [topAnouncement, setTopAnouncement] = useState<any>([])

  useEffect(() => {
    if (Array.isArray(announcementsLists)) {
      if (announcementsLists.length > 0) {
        const topAnouncementVal = announcementsLists.slice(0, 15)

        setTopAnouncement(topAnouncementVal)
      }
    }
  }, [announcementsLists])

  const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row align-items-center'>
                <div className='col-md-6 col-12'>
                  <h4 className='card-title'> {t("Community Post")} </h4>
                </div>
                {topAnouncement?.length > 2 ? (
                      <div
                        className='col-md-6 col-12 d-flex justify-content-end align-items-center pe-md-20'
                      >
                        <Link to={'/fc-ss-announcements/list'} className='text-decoration-none view-all'>
                          <span> {t("View All")} </span>
                          <img src={RightArrow} className='img-fluid' />
                        </Link>
                      </div>
                    )
                    : (
                        <></>
                    )
                }
              </div>
              <div className='announcement-sec'>
                <div className='row'>
                  <div className='col-md-12 col-12 col-lg-12'>
                    <div className='row'>
                      {loading && <CustomLoader2 height={'166'} />}
                      {!loading && (
                        <div className='col-12'>
                          {topAnouncement?.length ? (
                            <Slider {...settings}>
                              {topAnouncement.map((announcement: any, index: number) => {
                                return (
                                  <div key={index}>
                                    <Link
                                      to={{
                                        pathname: '/announcements-details',
                                        state: {item: announcement},
                                      }}
                                    >
                                      <div className='card announcement mt-4'>
                                        <div className='card-body'>
                                          <div className='row'>
                                            <div className='col-md-4 col-lg-3 my-auto col-12 mb-3 mb-md-0'>
                                              <img
                                                src={announcement.cover_image}
                                                className='img-fluid announce-img'
                                              />
                                            </div>
                                            <div className='col-md-6 col-lg-7 my-auto col-12'>
                                              <h4 className='card-title mb-2'>
                                                {announcement.title}
                                              </h4>
                                              <h6 className="sch-date">
                                                {announcement?.building_name}
                                              </h6>
                                              <h6 className="sch-time mb-0">
                                                <span className='text-black me-3'>{formatDate(announcement.date)}</span>
                                                {formatTimeMeridiem(announcement.start_time)} - {formatTimeMeridiem(announcement.end_time)}
                                              </h6>
                                            </div>
                                            <div className='col-md-2 col-lg-2 my-auto text-end col-12'>
                                              <img
                                                src={ArrowRightBlue}
                                                className='img-fluid d-inline left-arrow-back'
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                )
                              })}
                            </Slider>
                          ) : (
                            <NoDataFound1  height={'110'}  />
                            // <div className='text-center'>No Open Announcments.</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
