/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {LoginLeftBanner} from './components/LoginLeftBanner'
import {ResetLeftBanner} from './components/ResetLeftBanner'
import {toAbsoluteUrl} from '../helpers'
import logo from "../../assets/images/logo-alt.svg";

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='container-fluid px-0 overflow-hidden py-0 mx-0 px-full'>

      <div className='row justify-content-center'>
        <div className='col-md-12 login'>
          <div className='card border-0'>
            <div className='card-body '>
              <div className='row'>
                <div className='col-md-6 login-left'>
                  <div className="">
                    <Route path='/auth/login' component={LoginLeftBanner} />
                    <Route path='/auth/forgot-password' component={ResetLeftBanner} />
                  </div>
                </div>
                <div className='col-md-6 login-right px-0 overflow-hidden pt-2 d-flex flex-wrap align-items-center'>
                  <div className='row justify-content-center w-100'>
                    <div className="col-lg-7 col-md-8 col-sm-9 col-10">
                      <div className="d-flex flex-wrap align-items-center h-100 mx-auto">
                        <div className="px-4 px-sm-5 pb-5 mb-5 mx-0 mx-lg-5 w-100">
                          <div className="text-center mb-4 mt-5 mt-md-0">
                            <a href='#' className='pt-5'>
                              <img alt='Logo' src={logo} className='h-35' />
                            </a>
                          </div>
                        </div>
                        <div className='w-100'>
                          <Switch>
                            <Route path='/auth/login' component={Login} />
                            <Route path='/auth/registration' component={Registration} />
                            <Route path='/auth/forgot-password' component={ForgotPassword} />
                            <Redirect from='/auth' exact={true} to='/auth/login' />
                            <Redirect to='/auth/login' />
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* begin::Footer */}
      {/*<div className='d-flex flex-center flex-column-auto p-10'>*/}
        {/*<div className='d-flex align-items-center fw-bold fs-6'>*/}
          {/*<a href='#' className='text-muted text-hover-primary px-2'>*/}
            {/*About*/}
          {/*</a>*/}

          {/*<a href='#' className='text-muted text-hover-primary px-2'>*/}
            {/*Contact*/}
          {/*</a>*/}

          {/*<a href='#' className='text-muted text-hover-primary px-2'>*/}
            {/*Contact Us*/}
          {/*</a>*/}
        {/*</div>*/}
      {/*</div>*/}
      {/* end::Footer */}
    </div>
  )
}
