import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { Settings } from './marketing-settings/Settings'


const PMSettings: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/settings-marketing'>
                            <Settings/>
                        </Route> 
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMSettingsMaketingWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMSettings />
        </>
    )
};

export { PMSettingsMaketingWrapper }

