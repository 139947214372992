import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../components/layout/core'
import {ScheduledVisitTabs} from './components/ScheduledVisitTabs/ScheduledVisitTabs'
import {UpcomingList} from './components/UpcomingList/UpcomingList'
import {OngoingList} from './components/OngoingList/OngoingList'
import {CompletedList} from './components/CompletedList/CompletedList'
import {Redirect, Route, Switch} from 'react-router-dom'
import {getFacilityScheduledVisits} from '../../constants/axios/apis'
import {ScheduledView} from './components/ScheduledView/ScheduledView'
import {AllList} from './components/AllList/AllList'
import {ReScheduled} from './components/ReScheduled/ReScheduled'
import {Expired} from './components/Expired/Expired'

type Props = {
  visits?: any
  searchItem?: any
  setSearchItem?: any
}

const ScheduledVisitsPage: FC<Props> = ({visits,searchItem,setSearchItem}: any) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
      <div className='col-xxl-12 mt-0'>
        <Switch>
          <Route path='/scheduled-visit/all'>
            <ScheduledVisitTabs searchItem={searchItem} setSearchItem={setSearchItem} />
            <AllList searchItem={searchItem} setSearchItem={setSearchItem} />
            {/* <UpcomingList visits={visits?.filter((visit: any) => visit.eventStatus === 1)} /> */}
          </Route>
          <Route path='/scheduled-visit/upcoming'>
            <ScheduledVisitTabs searchItem={searchItem} setSearchItem={setSearchItem} />
            <UpcomingList searchItem={searchItem} setSearchItem={setSearchItem} />
            {/* <UpcomingList visits={visits?.filter((visit: any) => visit.eventStatus === 1)} /> */}
          </Route>
          <Route path='/scheduled-visit/ongoing'>
            <ScheduledVisitTabs searchItem={searchItem} setSearchItem={setSearchItem} />
            <OngoingList searchItem={searchItem} setSearchItem={setSearchItem} />
            {/* <OngoingList visits={visits?.filter((visit: any) => visit.eventStatus === 2)} /> */}
          </Route>
          <Route path='/scheduled-visit/completed'>
            <ScheduledVisitTabs searchItem={searchItem} setSearchItem={setSearchItem} />
            <CompletedList searchItem={searchItem} setSearchItem={setSearchItem} />
            {/* <CompletedList visits={visits?.filter((visit: any) => visit.eventStatus === 3)} /> */}
          </Route>
          {/* <Route path='/scheduled-visit/rescheduled'>
            <ScheduledVisitTabs />
            <ReScheduled />
          </Route>
          <Route path='/scheduled-visit/expired'>
            <ScheduledVisitTabs />
            <Expired />
          </Route> */}
          <Route path='/scheduled-visit/view'>
            <ScheduledView />
          </Route>
          <Redirect to='/error/403' />
        </Switch>
      </div>
    </div>
  </>
)

const ScheduledVisitWrapper: FC = () => {
  const [visits, setVisits] = useState<any>()
  const [searchItem, setSearchItem] = useState<any>('')

  return (
    <>
      <PageTitle breadcrumbs={[]}> Scheduled Visits </PageTitle>
      <ScheduledVisitsPage visits={visits} searchItem={searchItem} setSearchItem={setSearchItem} />
    </>
  )
}

export {ScheduledVisitWrapper}
