import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

export function History() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 pt-8 pb-3">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="font-14 text-theme mb-6">
                                        {t("Total Approved Amount")} : 1800 {t("AED")}
                                    </h4>
                                </div>
                                <div className="col-12">
                                    <div className="card shadow-sm mb-7">
                                        <div className="card-body px-8 py-6">
                                            <div className="history_main_div d-flex">
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Type")}
                                                    </label>
                                                    <h3 className="mb-0">{t("Early Termination")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0">Muhammed</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Total Cost Impact")}
                                                    </label>
                                                    <h3 className="mb-0">12,000 {t("AED")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0">50,000 {t("AED")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0">{t("Approved")}</h3>
                                                        </div>
                                                        <div className="">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card shadow-sm mb-7">
                                        <div className="card-body px-8 py-6">
                                            <div className="history_main_div d-flex">
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Type")}
                                                    </label>
                                                    <h3 className="mb-0">{t("Contract Renewal")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0">Muhammed</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Total Cost Impact")}
                                                    </label>
                                                    <h3 className="mb-0">12,000 {t("AED")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0">50,000 {t("AED")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <div className="row align-items-center">
                                                        <div className="col-10">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0">{t("Approved")}</h3>
                                                        </div>
                                                        <div className="col-2">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-7">
                                    <div className="card shadow-sm">
                                        <div className="card-body px-8 py-6">
                                            <div className="history_main_div d-flex">
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Type")}
                                                    </label>
                                                    <h3 className="mb-0">{t("Insufficient Notices")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Request Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved Date")}
                                                    </label>
                                                    <h3 className="mb-0">20-11-2021</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Approved By")}
                                                    </label>
                                                    <h3 className="mb-0">Muhammed</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Total Cost Impact")}
                                                    </label>
                                                    <h3 className="mb-0">12,000 {t("AED")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0">50,000 {t("AED")}</h3>
                                                </div>
                                                <div className="history_div">
                                                    <div className="row align-items-center">
                                                        <div className="col-10">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0">{t("Approved")}</h3>
                                                        </div>
                                                        <div className="col-2">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}