import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { currencyFormater, CustomLoaderCompact } from '../../../../constants/generics/customActios'

export function TerminationCharges({ refundDetail, chargeList, vat }: any) {
  const { t } = useTranslation()
  const history = useHistory()

  const [ccAmount, setCcAmount] = useState<any>(0)
  const [subTotal, setSubTotal] = useState<any>(0)
  const [vatAmount, setVatAmount] = useState<any>(0)
  const [totalAmount, setTotalAmount] = useState<any>(0)
  const [refundAmount, setRefundAmount] = useState<any>(0)

  useEffect(() => {
    let ccTotal = 0
    chargeList?.cCharge?.forEach((charge: any) => {
      ccTotal += (parseFloat(charge.amount) * parseInt(charge.quantity))
      // ccTotal += (parseFloat(charge.amount))  //TODO
    })
    let ocTotal = 0
    chargeList?.oCharge?.forEach((charge: any) => {
      ocTotal += parseFloat(charge.amount)
    })
    const tempSubTotal = refundDetail?.is_clearance_paid === 0 ? ccTotal + ocTotal : ocTotal
    const tempVat = vat !== '' ? (parseFloat(vat) * tempSubTotal) / 100 : 0
    const tempTotal = tempSubTotal + tempVat
    const tempRefund = parseFloat(refundDetail?.securityDepost) - tempTotal
    setCcAmount(ccTotal)
    setSubTotal(tempSubTotal)
    setVatAmount(tempVat)
    setTotalAmount(tempTotal)
    setRefundAmount(tempRefund)
  }, [vat, refundDetail, chargeList])

  // console.log(vat, vatAmount, totalAmount, refundAmount);

  return (
    <div className='card'>
      <div className='card-body px-6 py-6'>
        <div className='row mb-8 align-items-center'>
          <div className='col-sm-6 col-md-7 col-8'>
            <h4 className='card-title font-17 text-black fw-boldest mb-0'>
              {t('Termination Charges')}
            </h4>
          </div>
          {refundDetail.status === 0 &&
            <div className='col-sm-6 col-md-5 text-end col-4'>
              <button
                className='btn btn-primary btn-sm font-16'
                data-bs-toggle='modal'
                data-bs-target='#add-charges'
              >
                <i className='bi bi-plus-circle-fill align-middle'></i> {t('Add')}
              </button>
            </div>
          }
        </div>
        <div className='row'>
          {vat === '' &&
            <div className="col-12">
              <CustomLoaderCompact />
            </div>
          }
          <div className='col-12'>
            <div className='term-charges'>
              <div className='row'>
                <div className='col-md-9 col-lg-10 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8 ps-5'>{t('Clearance Charges')}</h6>
                </div>
                <div className='col-md-3 col-lg-2 col-6'>
                  <h6 className={'text-black font-14 fw-bold mb-8'+(refundDetail?.is_clearance_paid === 0 ? '' : ' text-decoration-line-through')}>{t('AED')} {currencyFormater(ccAmount)}</h6>
                </div>
                {
                  chargeList?.oCharge?.map((charge: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <div className='col-md-9 col-lg-10 col-6'>
                          <h6 className='text-black font-14 fw-bold mb-8 ps-5'>{charge.category}</h6>
                        </div>
                        <div className='col-md-3 col-lg-2 col-6'>
                          <h6 className='text-black font-14 fw-bold mb-8'>{t('AED')} {currencyFormater(charge.amount)}</h6>
                        </div>
                      </Fragment>
                    )
                  })
                }
                {/* <div className='col-md-9 col-lg-10 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8 ps-5'>{t('Lease Over Stay')}</h6>
                </div>
                <div className='col-md-3 col-lg-2 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8'>{t('AED')} 100</h6>
                </div> */}
                {/* <div className='col-md-9 col-lg-10 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8 ps-5'>
                    {t('Administrative Fee')}
                  </h6>
                </div>
                <div className='col-md-3 col-lg-2 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8'>{t('AED')} {'-'}</h6>
                </div> */}
                <div className='col-md-9 col-lg-10 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8 ps-5'>{t('VAT')}</h6>
                </div>
                <div className='col-md-3 col-lg-2 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8'>{t('AED')} {currencyFormater(vatAmount)}</h6>
                </div>
                <div className='col-12'>
                  <div className='bg-light-gray rounded-10 py-5 mb-8'>
                    <div className='row'>
                      <div className='col-md-9 col-lg-10 col-6'>
                        <h6 className='text-theme font-14 fw-bold m-0 ps-5'>{t('Total')}</h6>
                      </div>
                      <div className='col-md-3 col-lg-2 col-6'>
                        <h6 className='text-theme font-14 fw-boldest m-0'>{t('AED')} {currencyFormater(totalAmount)}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-9 col-lg-10 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8 ps-5'>
                    {t('Available Security Deposit')}
                  </h6>
                </div>
                <div className='col-md-3 col-lg-2 col-6'>
                  <h6 className='text-black font-14 fw-bold mb-8'>{t('AED')} {currencyFormater(refundDetail?.securityDepost)}</h6>
                </div>
                <div className='col-12'>
                  <div className='bg-light-gray rounded-10 py-5 mb-8'>
                    <div className='row'>
                      <div className='col-md-9 col-lg-10 col-6'>
                        <h6 className='text-theme font-14 fw-bold m-0 ps-5'>{t('Refund')}</h6>
                      </div>
                      <div className='col-md-3 col-lg-2 col-6'>
                        <h6 className='text-theme font-14 fw-boldest m-0'>{t('AED')} {currencyFormater(refundAmount)}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 text-end mb-5'>
                  <Link
                    to={{
                      pathname: '/finance-moveout-requests/view',
                      state: { requestDetail: { approvalId: refundDetail.moveOutApprovalId } },
                    }}
                    className='btn btn-primary fw-bold px-6 py-3'
                  >
                    {t('View Moveout Approval')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
