import React, {FC, useEffect, useRef, useState} from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {StepOne} from './StepOne/StepOne'
import {StepTwo} from './StepTwo/StepTwo'
import {StepThree} from './StepThree/StepThree'
import {StepFour} from './StepFour/StepFour'
import {StepSuccessful} from './StepSuccessful/StepSuccessful'

const steps = [
    'Tenant Details',
    'Contact information',
    'Upload Documents'
    ];

const TenantStepper: FC = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [ visible, setVisible] = React.useState(false);

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSubmit = () => {
        setVisible(true)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className="com-card-section movein-page">            
            <div className="col-12">
                <div className="w-100 cpt-tenant-detail">
                    <div className="p-0">
                    {!visible && (
                        <>
                        <Stepper activeStep={activeStep} className="multiform-stepper">
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                // if (isStepOptional(index)) {
                                //     labelProps.optional = (
                                //         <p>Optional</p>
                                //     );
                                // }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step className={activeStep == index ? "active" : ""} key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        
                        <React.Fragment>
                            <div>
                                {
                                    activeStep === 0 ?
                                    <StepOne /> :
                                    activeStep === 1 ?
                                    <StepTwo /> :
                                    activeStep === 2 ?
                                    <StepThree /> :
                                    <StepFour />
                                }                                
                            </div>                            
                            {/*<div>*/}
                                {/*{isStepOptional(activeStep) && (*/}
                                    {/*<button onClick={handleSkip}>*/}
                                        {/*Skip*/}
                                    {/*</button>*/}
                                {/*)}*/}
                            {/*</div>*/}
                            <div className='d-flex flex-stack action-btns stepper-actions'>
                                <div className='mr-4 col-5'>
                                    <button
                                        disabled={activeStep === 0 || activeStep === 3}
                                        onClick={handleBack}
                                        className='btn btn-outline-secondary btn-lg px-10 py-3 w-100 btn-back'
                                    >
                                        Back
                                    </button>
                                </div>
                                <div className="col-2">
                                    &nbsp;
                                </div>
                                <div className="col-5">
                                    <button
                                        onClick={activeStep === 3 ? handleSubmit : handleNext}
                                        className='btn btn-primary fw-bold px-10 py-3 w-100'
                                    >
                                        {activeStep === 3 ? 'Done' : 'Next'}
                                    </button>                                    
                                </div>                                
                            </div>
                        </React.Fragment>
                        </>
                        )}
                        {visible && (
                            <StepSuccessful />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export {TenantStepper};
