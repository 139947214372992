import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getOffersAndRenewals } from '../../../../constants/axios/apis'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import NoDataFoundImage from '../../../../assets/images/no-data-found.svg'
import { CustomLoader } from './../../../../constants/generics/customLoader'
import { useTranslation } from 'react-i18next'
import {
    currencyFormater,
    CustomLoader2,
    NoDataFound1,
} from '../../../../constants/generics/customActios'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination'

export function Renewals() {
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(10)
    const [offerData, setOfferData] = useState<any>([])
    const [totalRenewalList, setTotalRenewalList] = useState<any>([])
    const { t } = useTranslation()
    const [loading, setLoading] = useState<any>(true)

    useEffect(() => {
        const type = 'renewal'
        setLoading(true)
        const building = currentBuilding && currentBuilding !== '' ? currentBuilding : '0'
        getOffersAndRenewals(type, currentPage, pageSize, building)
            .then((response: any) => {
                if (response?.errorCode === 0) {
                    setOfferData(response)
                    setTotalRenewalList(response.dataCount)
                } else {
                    setOfferData(response)
                    setTotalRenewalList(0)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                setOfferData('')
                setTotalRenewalList(0)
            })
    }, [currentPage, currentBuilding])

    // const handleClick = (newCurrentPage: any) => {
    //     setCurrentPage(newCurrentPage)
    // };
    // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    //     handleClick(value);
    // };

    if (!loading && (offerData === undefined || (offerData.length === 0 && offerData.errorCode != 1))) {
        return <NoDataFound1 />
    }
    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body p-0'>
                            <div className='row'>
                                {!loading ? (
                                    <div className='col-md-12 col-12'>
                                        <div className='rec-payment-act-sec'>
                                            <div className='row'>
                                                {offerData?.errorCode === 0 ? (
                                                    <>
                                                        {' '}
                                                        <div className='col-12'>
                                                            <div className='table-responsive'>
                                                                <table className='table table-striped gy-4 gx-4 mb-0'>
                                                                    <thead>
                                                                        <tr>
                                                                            {/* <th>
                                                                                <input
                                                                                    className='form-check-input fc-wo-checkbox'
                                                                                    data-bs-target='#CheckBoxPopup'
                                                                                    data-bs-toggle='modal'
                                                                                    type='checkbox'
                                                                                    id='flexCheckDefault'
                                                                                />
                                                                            </th> */}
                                                                            <th> {t('No.')} </th>
                                                                            <th> {t('Ticket No.')} </th>
                                                                            <th> {t('Property Name')} </th>
                                                                            <th> {t('Unit')} </th>
                                                                            <th> {t('Amount')} </th>
                                                                            <th> {t('Status')} </th>
                                                                            <th> {t('Action')} </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {offerData?.errorCode != 1 && offerData?.data?.length > 0
                                                                            ? offerData?.data?.map((item: any, index: number) => {
                                                                                const sl = pageSize * (currentPage - 1) + index + 1
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        {/* <td>
                                                                                            <input
                                                                                                className='form-check-input fc-wo-checkbox'
                                                                                                type='checkbox'
                                                                                                id='flexCheckDefault'
                                                                                            />
                                                                                        </td> */}
                                                                                        <td>{sl}</td>
                                                                                        <td>{item?.ticketNo ? item.ticketNo : '-'}</td>
                                                                                        <td>
                                                                                            {item?.property_name ? item.property_name : '-'}
                                                                                        </td>
                                                                                        <td>{item?.unit ? item.unit : '-'}</td>
                                                                                        <td>
                                                                                            {t('AED')}{' '}
                                                                                            {item?.amount
                                                                                                ? currencyFormater(item?.amount)
                                                                                                : '-'}
                                                                                        </td>

                                                                                        <td
                                                                                            className={
                                                                                                item?.status === t('Pending')
                                                                                                    ? 'text-warning'
                                                                                                    : item?.status === t('Rejected')
                                                                                                        ? 'text-danger'
                                                                                                        : item?.status === t('Accepted')
                                                                                                            ? 'text-success'
                                                                                                            : item?.status === t('Extension')
                                                                                                                ? 'text-primary'
                                                                                                                : 'text-info'
                                                                                            }
                                                                                        >
                                                                                            {item?.status}
                                                                                        </td>
                                                                                        <td>
                                                                                            <Link
                                                                                                // to={'/pm-approvals-offer/offer-details'}
                                                                                                to={{
                                                                                                    pathname: '/pm-approvals-offer/offer-details',
                                                                                                    state: { id: item?.id },
                                                                                                }}
                                                                                            >
                                                                                                <span className='view-option text-theme fw-bolder'>
                                                                                                    {t('View')}
                                                                                                </span>
                                                                                            </Link>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            : ''}
                                                                    </tbody>
                                                                </table>
                                                                <div className='w-100 d-flex justify-content-end'>
                                                                    {/* <Stack spacing={2}>
                                    <Pagination
                                      count={
                                        totalRenewalList % pageSize === 0
                                          ? totalRenewalList / pageSize
                                          : Math.ceil(totalRenewalList / pageSize)
                                      }
                                      onChange={handlePageChange}
                                    />
                                  </Stack> */}
                                                                    <CompactPagination
                                                                        pageSize={pageSize}
                                                                        totalCount={parseInt(totalRenewalList)}
                                                                        defaultPage={currentPage}
                                                                        onPageChange={setCurrentPage}
                                                                        loading={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className='col-12 com-card-section'>
                                                        <div className='card border-0 shadow-none no-data-found-cd'>
                                                            <div className='card-body border-0'>
                                                                <div className='row justify-content-center'>
                                                                    <div className='col-12 text-center'>
                                                                        <img
                                                                            src={NoDataFoundImage}
                                                                            alt='No Data Found'
                                                                            className='img-fluid'
                                                                        />
                                                                    </div>
                                                                    <div className='col-12 text-center'>
                                                                        <span className='d-block text-center font-18 fw-bolder text-black'>
                                                                            {' '}
                                                                            {t('No Data Found')}{' '}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <CustomLoader2 />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
