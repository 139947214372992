import React, { useState, useRef, FC } from 'react';
import PlusIcon from "../../../../../../../assets/images/plus-circle.svg";
import { useTranslation } from 'react-i18next'

const DocumentsUpload: FC = () => {

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="com-card-section">
            <div className="row documents-card form-add-design pt-6">
                <div className="col-12">
                    <div className="row mb-6">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-6">
                                    <div className="mb-10">
                                        <h4 className="form-label fw-bolder">{t("Emirate ID (Front and Back)")}</h4>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-10">
                                        <h4 className="form-label fw-bolder">{t("Passport & Visa")}</h4>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <div className="mb-5 upload-input">
                                        <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                        <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                            <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <div className="mb-5 upload-input">
                                        <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                        <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                            <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <div className="mb-5 upload-input">
                                        <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                        <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                            <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <div className="mb-5 upload-input">
                                        <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                        <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                            <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <div className="mb-3 upload-input">
                                        <label className="form-label text-black text-uppercase font-14 fw-bold d-block mb-5"> {t("Letter of Intent")} </label>
                                        <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                        <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                            <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <div className="mb-3 upload-input">
                                        <label className="form-label text-black text-uppercase font-14 fw-bold d-block mb-5"> {t("Signature")} </label>
                                        <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                        <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                            <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {DocumentsUpload}
