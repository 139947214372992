import React from 'react'
import Logo from '../../../../assets/images/logo-alt.svg';

export function CustomSplashLoader() {
    return (
        <div id="splash-screen" className="splash-screen">
            <img src={Logo} alt="RealCube logo" className="h-30px" />
            <span>Loading ...</span>
        </div>
    )
}
