import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Home from "../../../../assets/images/home.png";
import { RootState } from '../../../../constants';
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { formatDate } from '../../../../constants/generics/dateTimeFormater';
import { currencyFormater } from '../../../../constants/generics/customActios';

export function Details() {
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-4">
                                    <div className='img-bg'>
                                        <img src={contractApprovalData?.communityLogo ? contractApprovalData.communityLogo : Home} className="img-fluid" />
                                        <div className='property-overlay'>
                                            <div className="col-sm-12 col-12">
                                                <h6 className="mb-0 fw-bold text-white font-14">{t("Offer Number")} : {contractApprovalData?.requestNo ? contractApprovalData.requestNo : '-'} </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white shadow-none rounded-0">
                                        <div className="card-body p-5">
                                            <div className="row">
                                                <div className="col-sm-12 col-12 mb-4">
                                                    <h6 className="text-black mb-0 fw-bold font-15"> {contractApprovalData?.building ? contractApprovalData.building : '-'} </h6>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease Start Date")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {contractApprovalData?.start_date ? formatDate(contractApprovalData?.start_date) : '-'} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease End Date")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {contractApprovalData?.endDate ? formatDate(contractApprovalData?.endDate) : '-'} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Security Deposit")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {t("AED")} {contractApprovalData?.security_deposit ? currencyFormater(contractApprovalData.security_deposit) : '-'} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Annual Rent")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {t("AED")} {contractApprovalData?.annualRent ? currencyFormater(contractApprovalData.annualRent) : '-'} </span>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("No Of Payment")} </h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {contractApprovalData?.NoOfPayments ? contractApprovalData?.NoOfPayments : '-'} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-12 mb-7 mb-md-0">
                                                    <div className="d-flex align-items-baseline justify-content-start">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Target Rent")}</h6>
                                                        <span className="text-black fw-bold font-14 ms-5"> {t("AED")} {contractApprovalData?.targetRent ? currencyFormater(contractApprovalData?.targetRent) : '-'}  </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}