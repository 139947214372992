import React, { useState, useEffect } from 'react';

export function AddMessagesPopup() {


    return (
        <div className="modal fade" aria-hidden='true' id="addnew-messages">
            <div className="modal-dialog modal-md modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Add Message</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-7">
                                    <label className="form-label fw-bolder text-uppercase">AGENT</label>
                                    <select className="form-control form-select fw-bolder form-control-solid">
                                        <option selected >Select Agent</option>
                                        <option value="94">Demo Agent</option>
                                        <option value="109">RMS Demo Agency </option>
                                        <option value="110">rms demo agent ag</option>
                                        <option value="111">rms demo ag2 ag</option>
                                        <option value="112">Vignesh </option>
                                        <option value="113">Brian Smith</option>
                                        <option value="114">Brial smith</option>
                                        <option value="115">Sachin ten</option>
                                        <option value="116">Ragul </option>
                                        <option value="117">Test agency monday </option>
                                        <option value="118">Test Ragul R</option>
                                        <option value="119">Maverick </option>
                                        <option value="120">Charlie Smith</option>
                                        <option value="121">Ragul </option>
                                        <option value="122">Blinders Estate </option>
                                        <option value="124">Mickey Mike</option>
                                        <option value="125">Thomas Shelby</option>
                                        <option value="126">My Agency </option>
                                        <option value="127">Bishal Joshi</option>
                                        <option value="128">ABC </option>
                                        <option value="129">agrear uy</option>
                                        <option value="132">Test Broker </option>
                                        <option value="133">Broker </option>
                                        <option value="134">BLOOM ESTATES </option>
                                        <option value="137">Rakshit </option>
                                        <option value="138">Rakshit As </option>
                                        <option value="139">Agent </option>
                                        <option value="144">Sales Admin </option>
                                        <option value="145">Sales Manager </option>
                                        <option value="147">test </option>
                                        <option value="148">subodh.s </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="mb-3">
                                    <label className="form-label fw-bolder">Messages</label>
                                    <textarea
                                        className='form-control fw-bolder form-control-solid'
                                        data-kt-autosize='true'
                                        rows={3}
                                        placeholder='Enter Messages'
                                    />
                                </div>
                            </div>
                            <div className='col-12 text-end'>
                                <button className='btn btn-primary px-4'>
                                    Send
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}