import React, { FC, useEffect, useRef, useState, Component } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../components/helpers'
import { Field, ErrorMessage } from 'formik'
import { Checkbox, Col, Row } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../constants'
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'

const Step4: FC = () => {
  const dispatch = useDispatch()
  let proprtyDetails: any
  proprtyDetails = useSelector<RootState>(({ PM }) => PM.proprtydetails)
  let propertyEditData: any
  propertyEditData = useSelector<RootState>(({ General }) => General.propertyEditData)
  const intl = useIntl()
  const location = useLocation()
  const [menu, setMenu] = useState('')
  const { t } = useTranslation();

  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  useEffect(() => {
    if (window.location.pathname != menu) {
      setMenu(window.location.pathname)
    }
  })

  let list = new Array()
  const [amenities, setAmenities] = useState(proprtyDetails?.data?.amenities)
  const [amenitiesChecked, setAmenitiesChecked] = useState<any>()
  const [amenitiesList, setAmenitiesList] = useState(proprtyDetails?.data?.amenities)
  const onChange = (checkedValues: CheckboxValueType[]) => {
    setAmenitiesChecked(checkedValues)
  };
  useEffect(() => {
    if (propertyEditData?.step4?.amenities != null) {
      propertyEditData?.step4?.amenities?.forEach((item: any) => {
        list.push(parseInt(item))
      })
      setAmenitiesChecked(list)
    }
    else {
      amenities?.forEach((item: any) => {
        if (item?.isAvailable === 'Y')
          list.push(parseInt(item.id))
      })
      setAmenitiesChecked(list)
    }
  }, [amenities])
  useEffect(() => {
    const step4Data = {
      amenities: amenitiesChecked,
    }
    dispatch(General.actions.propertyEditData({
      step1: propertyEditData.step1,
      step2: propertyEditData.step2,
      step3: propertyEditData.step3,
      step4: step4Data
    }));
  }, [amenitiesChecked])
  return (
    <>
      {userRole === 16 ? (
        <div className="com-card-section w-100 p-7">
          <div className="row form-add-design">
            <div className="col-12">
              <div className="card shadow-none">
                <div className="card-body p-0">
                  <div className="row form-add-design">
                    <div className="col-12">
                      <h4 className="card-title mb-7 font-14 text-primary">{t("Amenities")}</h4>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault1" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault1">
                          {t("Gym and Swimming Pool")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault2" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault2">
                          {t("Concierge")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault3" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault3">
                          {t("Private Parking")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault4" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault4">
                          {t("Pets Allowed")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault5" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault5">
                          {t("Close proximity to retail and restaurants")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault6" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault6">
                          {t("Central AC")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault7" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault7">
                          {t("Built in Wardrobes")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4 col-12">
                      <div className="form-check custom mb-7">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault8" />
                        <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault8">
                          {t("Maids room")} <small>{t("(only for 3 and 4 bedrooms)")}</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : userRole === 18 ? (
        <div className="com-card-section w-100 p-7">
          <div className="row form-add-design">
            <div className="col-12">
              <div className="card shadow-none">
                <div className="card-body p-0">
                  <div className="row form-add-design">
                    <div className="col-12">
                      <h4 className="card-title mb-7 font-14 text-primary">{t("Amenities")}</h4>
                    </div>
                    <div className='col-12'>
                      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={list} disabled>
                        <div className='row'>
                          {amenitiesList?.map((item: any) => <div className='col-sm-3 col-12 mb-7'>
                            {/* <Checkbox value="A">{t("Gym and Swimming Pool")}</Checkbox> */}
                            <Checkbox value={parseInt(item.id)} >{item.amenity}</Checkbox>
                          </div>)}
                        </div>
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export { Step4 }
