import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {RecentRequests} from './components/RecentRequests/RecentRequests'
import {QuickLinks} from './components/QuickLinks/QuickLinks'
import {ScheduledVisits} from './components/ScheduledVisits/ScheduledVisits'
import {Announcements} from './components/Announcements/Announcements'

const FCDashboardPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
        <div className='col-xxl-12 mt-0'>
            <RecentRequests />
            <QuickLinks />
            <ScheduledVisits />
            <Announcements />
        </div>
    </div>
    </>
);

const FCSoftServicesDashboardWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FCDashboardPage />
        </>
    )
};

export {FCSoftServicesDashboardWrapper}
