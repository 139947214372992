import React, { useState, useEffect } from 'react'

export function ReminderModal() {
    return (
        <>
            <div className="modal fade" id="reminder_modal">
                <div className="modal-dialog overflow-hidden modal-lg">
                    <div className="modal-content radius-15 overflow-hidden">
                        <div className="modal-header bg-primary text-white">
                            <h6 className="modal-title text-white font-16 font-weight-semibold">Reminder</h6>
                            <button type="button" className="close text-warning bg-white rounded-circle" data-bs-dismiss="modal">×</button>
                        </div>
                        <div className="modal-body">
                            <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Reminder Type</label>
                                        <select id="activities-select" className="form-control form-height w-100" >
                                            <option className="bs-title-option" value="">Select</option>
                                            <option value="87">Call</option>
                                            <option value="88">Reminders for Call back</option>
                                            <option value="89">Meeting at Office</option>
                                            <option value="90">Meeting Outside</option>
                                            <option value="91">Appointment Requested</option>
                                            <option value="92">Appointment Confirmed</option>
                                            <option value="93">Project Viewing (atsite)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Status</label>
                                        <select className="form-control form-height w-100">
                                            <option className="bs-title-option" value="">Select</option>
                                            <option value="">Select</option>
                                            <option value="Scheduled">Scheduled</option>
                                            <option value="Cancelled">Cancelled</option>
                                            <option value="Successful">Successful</option>
                                            <option value="Unsuccessful">Unsuccessful</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Start Date</label>
                                        <input type="date" className="form-control form-height font-13" id="activity-st-dt" value=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7 position-relative">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Start Time</label>
                                        <input type="time" className="form-control form-height font-13" id="activity-st-time" value=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">End Date</label>
                                        <input type="date" className="form-control form-height font-13" id="activity-end-dt" value=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7 position-relative">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">End Time</label>
                                        <input type="time" className="form-control form-height font-13" id="activity-end-time" value="" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Interested Units</label>
                                        <select id="intrested_unit" className="form-control form-height w-100">
                                            <option className="bs-title-option" value="">Select</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Description</label>
                                        <textarea rows={5} className="form-control font-13 click-add" id="activity-description" placeholder="Click to add description"></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12 text-end">
                                    <button type="button" className="btn btn-primary font-weight-semibold px-4">Submit</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
