import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { postApprovalSubmit } from '../../../../constants/axios/apis'
import * as Yup from 'yup'
import { useFormik } from 'formik'

export function ApprovalActions({ approvalDetail, setPopupSuccessMsg }: any) {
  const { t } = useTranslation()
  const approvalID = approvalDetail?.id && approvalDetail?.id !== '' ? approvalDetail?.id : ''
  const history = useHistory()
  const successRef: any = useRef(null)
  const [approvalType, setApprovalType] = useState()
  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    message: '',
  })

  const initialValues = {
    comment: '',
  }
  const validationSchema = Yup.object().shape({
    comment: Yup.string()
      .required(t('Comment is required!'))
      .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!'))
      .max(250, t('Max length is 250!'))
      .test('len', t('Comment is required!'), (value) => {
        return value ? (value?.trim()).length > 0 : false
      }),
  })

  useEffect(() => {
    if (approvalID === '')
      setSubmitStatus((oStatus: any) => ({
        ...oStatus,
        loading: false,
        message: t('Incomplete approval data!'),
        error: true,
      }))
  }, [])

  const onSubmit = (values: any) => {
    if (approvalID !== '') {
      setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: true }))

      var formData = new FormData()
      formData.append('approval_id', approvalID)
      formData.append('comment', values.comment)

      setTimeout(() => {
        postApprovalSubmit(formData, approvalType)
          .then((response: any) => {
            if (response.errorCode === 1) {
              setSubmitStatus((oStatus: any) => ({
                ...oStatus,
                loading: false,
                message: response.errorDescription,
                error: true,
              }))
            } else if (response.errorCode === 0) {
              setPopupSuccessMsg(response.description)
              setSubmitStatus((oStatus: any) => ({
                ...oStatus,
                loading: false,
                message: response.description,
              }))
              successRef.current.click()
              setTimeout(() => {
                history.push(approvalType === 'approve' ? '/mta-approvals/list/approved' : approvalType === 'reject' ? '/mta-approvals/list/rejected' : '/mta-approvals/list/pending')
              }, 1000)
            } else {
              setSubmitStatus((oStatus: any) => ({
                ...oStatus,
                loading: false,
                message: t('Some error occured!'),
                error: true,
              }))
            }
            setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false }))
          })
          .catch((e) => {
            // console.log(e, 'Error');
            setSubmitStatus((oStatus: any) => ({
              ...oStatus,
              loading: false,
              message: t('Some error occured!'),
              error: true,
            }))
          })
      }, 1000)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const handleSubmit = (appvovalType: any) => {
    setApprovalType(appvovalType)
    formik.handleSubmit()
  }

  return (
    <>
      <div className='row align-items-end'>
        <div className='col-lg-12 col-sm-12 mb-5'>
          <div className='row'>
            <div className='col-12'>
              <label className='font-14 fw-bold mb-3'>{t("Approver's Comment")}</label>
              <textarea
                className='form-control'
                data-kt-autosize='true'
                rows={3}
                placeholder={t('Type here...')}
                {...formik.getFieldProps('comment')}
              />
              {formik.touched.comment && formik.errors.comment ? (
                <small className='text-danger'>{formik.errors.comment}</small>
              ) : null}
            </div>
          </div>
        </div>
        <div className='col-12 text-end'>
          {submitStatus.loading ? (
            <button
              type='button'
              className='btn btn-primary fw-bold px-10 py-3'
              disabled
            >
              <span className='indicator-progress' style={{ display: 'block' }}>
                {t('Please wait...')}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          ) : (
            <>
              <button
                className='btn btn-outline-primary fw-bold px-15 py-3 mx-5'
                onClick={(e) => handleSubmit('reject')}
                disabled={approvalID === ''}
              >
                {t('Reject')}
              </button>
              <button className='btn btn-outline-primary request-more-info fw-bold px-10 py-3 me-5'
                onClick={(e) => handleSubmit('rmi')}
                disabled={approvalID === ''}
              >
                {t("Request More Info")}
              </button>
              <button
                className='btn btn-primary fw-bold px-12 py-3'
                onClick={(e) => handleSubmit('approve')}
                disabled={approvalID === ''}
              >
                {t('Approve')}
              </button>
            </>
          )}
        </div>

        {!submitStatus.loading && submitStatus.error && (
          <div className='col-12 text-center'>
            <div className='alert alert-danger w-100 mt-5 mb-0' role='alert'>
              {submitStatus.message}
            </div>
          </div>
        )}
      </div>
      <span
        ref={successRef}
        data-bs-target='#req-submitted-popup-redirect'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='d-none'
      ></span>
    </>
  )
}
