import React from 'react'
import { Link } from 'react-router-dom'
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';


export function Sales() {
  return (
   <>
    <div className="row">
        <div className="col-12">
            <div className="card mb-6">
                <div className="card-body p-7">
                    <div className="row ">
                        <div className="col-12">
                            <div className="border-bottom pb-2">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="font-14 fw-boldest text-black mt-1 mb-2"> Sales </h4>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <DateRangePicker size="sm" format="dd/MM/yyyy" placeholder="Select Date Range"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sales-section mt-5">
                        <div className=''>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row mb-5">
                                        <div className="col-md-3">
                                            <div>
                                                <Link to className="text-decoration-none ">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">102</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                    Total Units
                                                                    </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <Link to className="text-decoration-none">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">47</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                        Total Leads
                                                                    </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <Link to className="text-decoration-none">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">25</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                    Total Opportunities
                                                                    </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <Link to={'/pm-property-data/available'} className="text-decoration-none">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">23</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                    Total Offers
                                                                    </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-3">
                                            <div>
                                                <Link to className="text-decoration-none">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                        New Units
                                                                    </div>
                                                                    <p className='font-10 theme-color mt-1 text-black'> Past 7 Days</p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <Link to className="text-decoration-none">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">3</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                        New Leads
                                                                    </div>
                                                                    <p className='font-10 theme-color mt-1 text-black'> Past 7 Days</p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <Link to className="text-decoration-none">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">3</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                    New Opportunities
                                                                    </div>
                                                                    <p className='font-10 theme-color mt-1 text-black'> Past 7 Days</p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <Link to className="text-decoration-none">
                                                    <div className="card align-items-center h-100 text-center">
                                                        <div className="card-body py-7 px-3">
                                                            <div className="row g-0 align-items-center">
                                                                <div className="col-12">
                                                                    <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                                                                    <Link to className="text-decoration-none ">
                                                                    <div className='text-warning font-weight-bold mb-0 font-11 d-block text-decoration-none'>
                                                                    New Offers
                                                                    </div>
                                                                    <p className='font-10 theme-color mt-1 text-black'> Past 7 Days</p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>
               
   </>
  )
}
