import React, { useState, useEffect } from 'react'


export function ActiveTab() {
    return (      
        <>
            <div className='border p-4 my-5'>
                <div className='row'>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13">All</label>
                            <select className="form-control form-height w-100"  name="all">
                                <option value="">Select</option>
                                <option value="">All Stages</option>
                                <option value="">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13">All Type</label>
                            <select className="form-control form-height w-100"  name="all_type">
                                <option value="">Select</option>
                                <option value="">All Stages</option>
                                <option value="">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13">All Priority</label>
                            <select className="form-control form-height w-100"  name="all_priority">
                                <option value="">Select</option>
                                <option value="">All Stages</option>
                                <option value="">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-sm-6">
                        <div className="mb-7">
                            <label className="d-block w-100 text-uppercase mb-0 font-13">All Time</label>
                            <select className="form-control form-height w-100"  name="all_time">
                                <option value="">Select</option>
                                <option value="">All Stages</option>
                                <option value="">Other</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered gx-3 mb-0 text-nowrap">
                    <thead className="text-uppercase bg-projects-list font-weight-normal">
                        <tr>
                            <th>  </th>
                            <th className="">Title</th>
                            <th className="">Type</th>
                            <th className="">Status</th>
                            <th className="">Priority</th>
                            <th className="">Assigned To</th>
                            <th className="">Due Date</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        <tr>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="form-check-input" value="" />
                                </div>
                            </td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user.png" alt="Image" className="img-fluid me-2"/>
                                <span>Follow up with Johnson</span>
                            </td>
                            <td>Maintenance Required</td>
                            <td><span className="fa fa-times-circle text-danger"></span></td>
                            <td>High</td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user-ico.png" alt="Image" className="img-fluid me-2"/>
                                <span>Jane Simpletion</span>
                            </td>
                            <td className="text-danger">23 Jun 2023</td>
                        </tr>
                        <tr>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="form-check-input" value="" />
                                </div>
                            </td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user.png" alt="Image" className="img-fluid me-2"/>
                                <span>Follow up with Johnson</span>
                            </td>
                            <td>Inspection Required</td>
                            <td><i className="fas fa-times-circle text-danger"></i></td>
                            <td>Low</td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user-ico.png" alt="Image" className="img-fluid me-2"/>
                                <span>Jane Simpletion</span>
                            </td>
                            <td className="text-danger">15 Jun 2023</td>
                        </tr>
                        <tr>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="form-check-input" value="" />
                                </div>
                            </td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user.png" alt="Image" className="img-fluid me-2"/>
                                <span>Follow up with Johnson</span>
                            </td>
                            <td>Maintenance Required</td>
                            <td><i className="fas fa-check-circle text-success"></i></td>
                            <td>High</td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user-ico.png" alt="Image" className="img-fluid me-2"/>
                                <span>Jane Simpletion</span>
                            </td>
                            <td className="">20 Jul 2023</td>
                        </tr>
                        <tr>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="form-check-input" value="" />
                                </div>
                            </td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user.png" alt="Image" className="img-fluid me-2"/>
                                <span>Follow up with Johnson</span>
                            </td>
                            <td>Maintenance Required</td>
                            <td><i className="fas fa-check-circle text-success"></i></td>
                            <td>High</td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user-ico.png" alt="Image" className="img-fluid me-2"/>
                                <span>Jane Simpletion</span>
                            </td>
                            <td className="">23 Jul 2023</td>
                        </tr>
                        <tr>
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="form-check-input" value="" />
                                </div>
                            </td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user.png" alt="Image" className="img-fluid me-2"/>
                                <span>Follow up with Johnson</span>
                            </td>
                            <td>Maintenance Required</td>
                            <td><i className="fas fa-check-circle text-success"></i></td>
                            <td>High</td>
                            <td>
                                <img src="https://pm-crm.realcube.estate/img/lead-user-ico.png" alt="Image" className="img-fluid me-2"/>
                                <span>Jane Simpletion</span>
                            </td>
                            <td className="">30 Jul 2023</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </>
    )
}

