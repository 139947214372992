import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NoDataFound1 } from '../../../../constants/generics/customActios'

export function FinancePaymentSubTab() {
    const {t} = useTranslation()

    const location = useLocation();
    let s = location.pathname
    let link = s.substring(s.lastIndexOf("/list/") + 6, s.lastIndexOf("/"))
    // console.log("🚀 ~ file: FinancePaymentSubTab.tsx:13 ~ FinancePaymentSubTab ~ link", link)

    
    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className='d-flex overflow-hidden'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/finance-payments/list/${link}/creditCard-payment` && 'active')}
                                to={`/finance-payments/list/${link}/creditCard-payment`}>
                                {t("Credit Card Payment")}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/finance-payments/list/${link}/bank-transfer` && 'active')}
                                to={`/finance-payments/list/${link}/bank-transfer`}>
                                {t("Bank Transfer")}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === `/finance-payments/list/${link}/cheque` && 'active')}
                                to={`/finance-payments/list/${link}/cheque`}>
                                {t("Cheque")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

}
