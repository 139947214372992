import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function RescheduleTime() {
    const [selectedItem, setSelectedItem] = useState(0);
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow:2,
        slidesToScroll:2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow:2,
                    slidesToScroll:2,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };
    let timeData = [
        { id: 1, time: '01:00' },
        { id: 2, time: '01:30' },
        { id: 3, time: '02:00' },
        { id: 4, time: '02:30' },
        { id: 5, time: '03:00' },
        { id: 6, time: '03:30' },
        { id: 8, time: '04:00' },
        { id: 9, time: '04:30' },
        { id: 10, time: '05:00' },
        { id: 12, time: '05:30' },
        { id: 13, time: '06:00' },
        { id: 14, time: '06:30' },
        { id: 15, time: '07:00' },
        { id: 16, time: '07:30' },
        { id: 17, time: '08:00' },
        { id: 18, time: '08:30' },
        { id: 19, time: '09:00' },
        { id: 20, time: '09:30' },
        { id: 21, time: '10:00' },
        { id: 22, time: '10:30' },
        { id: 23, time: '11:00' },
        { id: 24, time: '11:30' },
        { id: 25, time: '12:00' },
        { id: 26, time: '12:30' },
    ];
    return (
        <div className="reschedule_time">
            <div className="SliderWrapper">
                <Slider {...settings}>
                    {timeData &&
                    timeData.map((value, key) => {
                        return (
                            <div
                                key={key}
                                className={
                                    value.id === selectedItem
                                        ? 'time-slot-item slick-current'
                                        : 'time-slot-item'
                                }
                                onClick={() => setSelectedItem(value.id)}
                            >
                                <h3>{value.time}</h3>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
}