import React, { useState, useEffect, useRef } from 'react';
import NoImage from "../../../../../../assets/images/House.png";
import { ImageViewerWithPopup } from '../../../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup';

export function BuildingDetails({userDetails, loading}:any) {
    let community = userDetails?.community
    let buildingList = new Array()
    community?.forEach((item:any)=>{
        if(Array.isArray(item.buildings)){
            item?.buildings?.forEach((buildingData:any)=>{
                buildingList.push({
                    community: item.community,
                    building: buildingData.building_name,
                })
            })
        }else{
            buildingList.push({
                community: item.community,
                building: item.buildings?.building_name,
            })
        }
    })
    return (
        <div className="com-card-section">
            <div className="row mb-1">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            {/* <h5 className="mb-5">Assigned Buildings</h5> */}
                            <div className="row">
                                {buildingList?.map((building:any, index:number)=>{

                                    return (
                                        <div key={index} className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                                            <div className="rounded p-4 bg-light">
                                                <div className="offer_main_details">
                                                    <div className="col-12">
                                                        <p className="text-black fw-bold font-14 text-short mb-0">{building.building}</p>
                                                    </div>
                                                    <div className="col-12 offer_main_details_div_3">
                                                        <p className="mb-0 pt-2">
                                                            {/* <img src={Place} className="img-fluid"/> */}
                                                            ({building.community})
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}