import React, { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../components/layout/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getMasterData } from '../../constants/axios/apis';
import { useTranslation } from 'react-i18next'
import { t } from 'i18next';
import { MarketPlaceListWrapper } from './components/MarketPlaceList/MarketPlaceListWrapper';
import { MarketPlaceViewWrapper } from './components/MarketPlaceView/MarketPlaceViewWrapper';
import { MarketPlaceCreateWrapper } from './components/MarketPlaceCreate/MarketPlaceCreateWrapper';

type Props  = {
    // categoryList:any
}

const MarketPlaceWrapper: FC<Props> = () => (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <Switch>
                    <Route path='/market-place/list'>
                        <MarketPlaceListWrapper />
                    </Route>
                    <Route path='/market-place/view'>
                        <MarketPlaceViewWrapper />
                    </Route>
                    {/* <Route path='/market-place/add'>
                        <MarketPlaceCreateWrapper />
                    </Route> */}
                    <Redirect to='/error/403' />
                </Switch>
            </div> 
        </div>
    </>
);


const MTAMarketPlaceWrapper: FC = () => {

    // useEffect(()=>{
    //     getMasterData()
    //         .then(({data}) => {
    //         const dataList = data?.find((el: any) => el.category_name === 'market_place')
    //         const formatedList = {
    //             dining_id: dataList?.master_data?.find((el:any)=>el.name === "Dining")?.value,
    //             lifestyle_id: dataList?.master_data?.find((el:any)=>el.name === "Lifestyle")?.value,
    //             entertainment_id: dataList?.master_data?.find((el:any)=>el.name === "Entertainment")?.value
    //         }
    //         setCategoryList(formatedList)
    //     })
    // },[])
    const { t } = useTranslation();

    return (
        <>
            <PageTitle breadcrumbs={[]}>Market Place</PageTitle>
            <MarketPlaceWrapper />
        </>
    )
};

export { MTAMarketPlaceWrapper }
