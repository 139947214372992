import React from 'react';
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import {UploadImage} from './UploadImage';
import { useTranslation } from 'react-i18next'

const EditPropertyDetails: React.FC = () => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-6">
                        <div className="col-sm-4">
                            <h4 className="card-title font-18 text-black mb-0">
                                <Link to={'/pm-availability/property-details'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {t("Edit Property Details")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row mx-1">
                        <div className="card">
                            <div className="card-body px-5 py-5">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <UploadImage />
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="mt-10">
                                                    <div className="col-12">
                                                        <div className="row form-add-design">
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10 position-relative">
                                                                    <label className="form-label fw-bolder">{t("Available From")}</label>
                                                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Number of Bathrooms")} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10">
                                                                    <div className="form-group">
                                                                        <label className="form-label fw-bolder w-100"> {t("Price")} </label>
                                                                        <div className="d-flex fdrr-rtl">
                                                                            <input type="text" name="" className="form-control expected-rent-input" value="" placeholder="90,000" />
                                                                            <select className="bg-lightgray expected-rent-selectbox font-14 ps-7" data-control="select2" data-allow-clear="true" >
                                                                                <option value="" selected>{t("AED")}</option>
                                                                                <option value="">{t("Dollar")}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-7">
                                                                    <label className="form-label fw-bolder"> {t("Location")} </label>
                                                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Location")}  value=""/>
                                                                    <button className='btn-outline-primary fw-bold px-10 py-3 mt-n12 float-end' id="update_bt">
                                                                        <i className="bi bi-geo-alt-fill font-15 me-2 text-primary"></i> {t("Map")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10 position-relative">
                                                                    <label className="form-label fw-bolder">{t("Bedrooms")}</label>
                                                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder="4" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10 position-relative">
                                                                    <label className="form-label fw-bolder">{t("Payments")}</label>
                                                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder="3" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10">
                                                                    <label className="form-label fw-bolder"> {t("Documents")} </label>
                                                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Documents")}  value=""/>
                                                                    <button className='btn-outline-primary fw-bold px-10 py-3 mt-n12 float-end' id="update_bt">
                                                                        {t("Add")} <i className="bi bi-plus-circle-fill font-15 ms-2 text-primary"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10 position-relative">
                                                                    <label className="form-label fw-bolder">{t("Floor Plan")}</label>
                                                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder="1" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10">
                                                                    <label className="form-label fw-bolder"> {t("Amenities")} </label>
                                                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder="3"  value=""/>
                                                                    <button className='btn-outline-primary fw-bold px-10 py-3 mt-n12 float-end' id="update_bt">
                                                                        {t("Add")} <i className="bi bi-plus-circle-fill font-15 ms-2 text-primary"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="mb-10 position-relative">
                                                                    <label className="form-label fw-bolder">{t("Description")} </label>
                                                                    <textarea
                                                                        className='form-control fw-bolder form-control-solid'
                                                                        data-kt-autosize='true'
                                                                        rows={4}
                                                                        placeholder='Bloom Heights is conveniently located in the centre of Jumeirah Village Circle,
                                                                        one of the fastest growing communities in Dubai, with '
                                                                        value=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="row ms-1 mt-n15">
                                                                    <div className="col-6 form-check">
                                                                        <input className="form-check-input me-4" type="checkbox" value="" id="flexCheckDefault" />
                                                                        <label className="form-check-label fw-bolder text-primary" >
                                                                            {t("View Floor Plan")}
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Link to={'/'} className='text-primary'>
                                                                            {t("View")}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-end">
                                                        <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                                            {t("Save")}
                                                        </button>
                                                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' data-bs-toggle="modal" data-bs-target="#req-submitted-popup">
                                                            {t("Publish")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export {EditPropertyDetails}
