import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { IState } from '../../../utils/interface';
import AnnounceOne from "../../../assets/images/announcement-one.png";
import AnnounceTwo from "../../../assets/images/announcement-two.png";
import AnnounceThree from "../../../assets/images/announcement-three.png";
import AnnounceFour from "../../../assets/images/announcement-four.png";
import AnnounceBackupImg from '../../../assets/images/announcement-img.png'
import RightArrow from "../../../assets/images/Right_Arrow_sm.png";
import ArrowRightBlue from "../../../assets/images/arrow-right-blue.png";

// import { getAnnouncementsLists } from '../../../utils/makeRequest';
import Loader from '../../../components/Loader';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getFCSAnnouncements } from '../../../constants/axios/apis'
import { formatTimeMeridiem, formatDate } from '../../../constants/generics/dateTimeFormater';
import { getUserRole } from '../../../constants/generics/userCheck';
import { RootState } from '../../../constants';
import { CustomLoader2, NoDataFound } from '../../../constants/generics/customActios';
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination';

import { useTranslation } from 'react-i18next';

// Used in FC, FCS, FCSS, FM, FMSM
export function AnnouncementsList() {
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    const isAddAllowed = user_role ? ['FMT', 'FMSM'].includes(user_role) : false

    const currentBuildingFC: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding) //All required modules except PM
    const currentBuildingPM: any = useSelector<RootState>(({ General }) => General.building)
    const currentBuilding = (user_role === 'PM' ? currentBuildingPM : currentBuildingFC )
    const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)

    // const state: any = useSelector(state => state);
    // const user_role = getUserRole(state?.auth?.user?.data);

    const pageSize = 10;
    // const location = useLocation();
    const [isShowMore, setIsShowMore] = useState(true);
    const [isShow, setIsShow] = useState(true);
    const [loading, setLoading] = useState(true);
    const [announcements, setAnnouncements] = useState<any>([])
    const [announcementsSliced, setSlicedAnnouncements] = useState<any>([])
    const [activeButton, setActiveButton] = useState<any>("open");

    // const toggleReadMore = () => {
    //     setIsShowMore(!isShowMore);
    //     setIsShow(!isShowMore);
    // };

    // const state:any = useSelector(state => state);
    // const userToken = state?.auth?.accessToken;
    // if(userToken === "undefined"){
    //     token undefined
    // }
    // const [announcementsLists, setAnnouncementsLists] = useState<IState['APIState']>({
    //     loading: false,
    //     response: [],
    //     error: ''
    // });
    // const {loading, response: announcementsList, error} = announcementsLists;
    useEffect(() => {
        // const validBuildingIdList = listBuilding?.map((item:any) => item.id)

        // const payload = '?status=' + activeButton;
        // let payload = (user_role === 'FC' || user_role ==='FCS' || user_role === 'FCSS') ? '?type=FacilityContractor'
        //     : (user_role === 'FMT' || user_role === 'FMSM') ? '?type=FacilityManager'
        //     : (user_role === 'T') ? '?type=Tenant' : null;  //component not used in tenant module

        const payload = (currentBuilding && currentBuilding !== '' ? '?building_id=' + currentBuilding : '?building_id=0') + '&id_sort=1&date_sort=1'
        // if(payload){
        setLoading(true)
        setAnnouncements([])
        getFCSAnnouncements(payload).then((response: any) => {
            // console.log(response);
            if (response?.errorCode === 0 && response?.data && response.data !== "") {
                let announcementsList = response.data

                // announcementsList = announcementsList?.filter((announcement: any) => { //Show only relevant data even for all building
                //     const temp = `${announcement.building_id}`
                //     return validBuildingIdList?.includes(temp)
                // })

                if (activeButton == "open") {
                    announcementsList = announcementsList?.filter((announcement: any) => {
                        const annpuncementEndTime = new Date(announcement.endDate + " " + announcement.end_time).getTime();
                        let currentTime = new Date().getTime();
                        return currentTime <= annpuncementEndTime;
                    })
                } else {
                    announcementsList = announcementsList?.filter((announcement: any) => {
                        const annpuncementEndTime = new Date(announcement.endDate + " " + announcement.end_time).getTime();
                        let currentTime = new Date().getTime();
                        return currentTime > annpuncementEndTime;
                    })
                }
                setAnnouncements(announcementsList)
            }
            setLoading(false)
        }).catch((e) => setLoading(false))
        // }else{
        //     setLoading(false)
        // }
        // getAnnouncementsLists(setAnnouncementsLists,userToken);
    }, [activeButton, currentBuilding, listBuilding]);

    // const [announcementUnit, setAnnouncementUnit] = useState<any>([]);


    // useEffect(()=>{
    //     if(activeButton == "open"){
    //         return now <= d;
    //     }else {
    //         return now > d;
    //     }
    //     const aUnit = announcementsList.reverse().filter((announcement: any, index: number) =>{
    //         //console.log("announcement.date: ",announcement.date+" "+announcement.end_time);
    //         const d = new Date(announcement.date+" "+announcement.end_time).getTime();
    //         let now = new Date().getTime();
    //         if(activeButton == "open"){
    //             return now <= d;
    //         }else {
    //             return now > d;
    //         }
    //     })        
    //     setAnnouncementUnit(aUnit)
    // },[activeButton, announcementsList]);
    const [page, setPage] = React.useState(1);
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    useEffect(() => {
        setPage(1)
    }, [announcements])

    useEffect(() => {
        const startPoint = (page - 1) * pageSize
        const endPoint = page * pageSize
        const sliceData = announcements?.slice(startPoint, endPoint);
        setSlicedAnnouncements(sliceData)
        // console.log('Start', startPoint, 'End',  endPoint, 'total', announcements.length);
        // console.log('pages', announcements.length%pageSize===0 ? announcements.length/pageSize : Math.ceil(announcements.length/pageSize));
    }, [announcements, page])

    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="row flex-stack announcement-sec mb-6">
                        <div className="col-7">
                            <h4 className="card-title font-18 text-black m-0"> {t("Community Post")}</h4>
                        </div>
                        {isAddAllowed &&
                            <div className="col-5 text-end">
                                <Link to={'/su-create-announcements'} className="btn btn-primary btn-sm font-16 ">
                                    <i className="bi bi-plus-circle-fill align-middle"></i> {t("Add")}</Link>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-12 col-12">
                    <div className="card mb-3">
                        <div className="card-body px-7 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-md-6 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <a
                                                    onClick={() => {
                                                        setActiveButton('open');
                                                    }}
                                                    className={`cursor-pointer nav-link position-relative text-active-primary px-5 ` +
                                                        (activeButton === 'open' && 'active')}>
                                                    {t("Open")}
                                                </a>
                                            </li>
                                            <li className='nav-item'>
                                                <a
                                                    onClick={() => {
                                                        setActiveButton('closed');
                                                    }}
                                                    className={`cursor-pointer nav-link position-relative text-active-primary px-5 ` +
                                                        (activeButton === 'closed' && 'active')}>
                                                    {t("Closed")}
                                                </a>
                                            </li>
                                            {/* <li className='nav-item'>
                                            <a
                                                onClick={()=>{
                                                    setActiveButton('approved');
                                                }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                (activeButton === 'approved' && 'active')}>
                                                Approved
                                            </a>
                                        </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card mb-7 announce-list ">
                        <div className="card-body p-7">
                            <div className="row">
                                {loading && (
                                    <CustomLoader2 />
                                )}
                                {/* {error && (
                                    <div className="alert alert-danger w-100 mt-2" role="alert">
                                        {error}
                                    </div>
                                )} */}
                                {announcementsSliced?.map((announcement: any, index: number) => {
                                    // const banner_img = announcement?.cover_image ? announcement?.cover_image : announcement?.categoryIcon 
                                    //categoryIcon is wrong, using local image directly
                                    const banner_img = announcement?.cover_image && (typeof announcement?.cover_image === 'string') ? announcement?.cover_image : AnnounceBackupImg
                                    // const buildingData = listBuilding?.find((el:any)=> el.id == announcement.building_id)
                                    return (
                                        <div className="col-12 col-lg-12" key={index}>
                                            <Link to={{ pathname: "/announcements-details", state: { item: announcement } }} >
                                                <div className="announcements-card c-pointer card mb-3 px-0">
                                                    <div className="announcements-card-img p-5 pe-0">
                                                        <img src={banner_img} className="img-fluid rounded-0 announce-banner" />
                                                    </div>
                                                    <div className="announcements-card-content px-5 d-flex align-items-center" style={{ width: "100%" }}>
                                                        <div className="row" style={{ width: "100%" }}>
                                                            <div className="col-12">
                                                                <div className="row mt-3">
                                                                    <div className="col-md-12">
                                                                        <h5 className="announcements-title text-black font-16 fw-bolder text-ellipsis mb-1">
                                                                            {announcement?.title?.slice(0, 50)}{announcement?.title?.length > 50 && <>&#8230;</>}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <p className="mb-0 fw-bolder font-12">{announcement?.building_name}</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <p className="fw-normal mt-3 mb-4 font-12 text-desc text-ellipsis">
                                                                    {isShowMore ? announcement.announcement.slice(0, 300) : announcement.announcement}
                                                                </p>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mb-3 align-items-center ">
                                                                    <div className="col-12">
                                                                        <div className="d-inline rounded-5 py-2 px-0">
                                                                            <span className="text-black fw-bolder font-12 pe-1"> {formatDate(announcement.date)} </span>
                                                                            <span className="fw-bolder font-12"><p className='p-0 m-0 d-inline'>  {formatTimeMeridiem(announcement.start_time)} to {formatTimeMeridiem(announcement.end_time)} </p></span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className={isShow ? "col-md-8 col-12" : "col-md-12 col-12"}>
                                                                        <div className={isShow ? "row align-items-center" : "row align-items-end"}>
                                                                            <div className="col-md-4 text-end">
                                                                                <Link to={{ pathname: "/announcements-details", state: { item: announcement } }} className="ShowMoreText mb-0 text-theme font-12 fw-bold">
                                                                                    <span>
                                                                                        {t("Read More")} <i className='bi bi-arrow-right-circle-fill text-theme fonts-17 ms-2'></i>
                                                                                    </span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="my-auto text-end pe-5">
                                                        <img src={ArrowRightBlue} className="img-fluid d-inline left-arrow-back" alt="go" />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                                {(!loading && announcements?.length) ?
                                    <div className="w-100 d-flex justify-content-end">
                                        {/* <Stack spacing={2}>
                                            <Pagination count={announcements.length % pageSize === 0 ? announcements.length / pageSize : Math.ceil(announcements.length / pageSize)} onChange={handlePageChange} />
                                        </Stack> */}
                                        <CompactPagination
                                            pageSize={pageSize}
                                            totalCount={parseInt(announcements.length)}
                                            defaultPage={page}
                                            onPageChange={setPage}
                                            loading={loading}
                                        />
                                    </div>
                                    : ''
                                }
                                {!loading && (!announcements || announcements?.length === 0) &&
                                    <>
                                        <NoDataFound />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
