import React, {useState, useEffect} from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import IncidentImg from '../../../../assets/images/incident-quick-link.svg'
import AccessCardImg from '../../../../assets/images/access-card-quick-link.svg'
import VisitorsImg from '../../../../assets/images/visitors-quick-link.svg'
import DocumentImg from '../../../../assets/images/documents-quick-link.svg'
import DirectoryImg from '../../../../assets/images/directory-quick-link.svg'
import ParkingImg from '../../../../assets/images/parking-quick-link.svg'
import MaintenanceImg from '../../../../assets/images/maintenance-quick-link.svg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'
import CalanderIcon from "../../../../assets/images/calendar-quick-link.svg";
import { useTranslation } from 'react-i18next';

export function QuickLinks() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }
  const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-5 pt-4 p-xl-7 pt-xl-5'>
              <div className='row'>
                <div className='col-md-6 col-12'>
                  <h4 className='card-title'>{t("Quick Links")}</h4>
                </div>
                {/*<div className="col-md-6 col-6 text-right">*/}
                {/*<Link to={''} className="text-decoration-none view-all"> */}
                {/*<span> View All </span>*/}
                {/*<img src={RightArrow} className="img-fluid" /> */}
                {/*</Link>*/}
                {/*</div>*/}
              </div>
              <div className='quick-link-sec'>
                <div>
                  <div className='row'>
                    <div className='col-12 p-0'>
                      <Slider {...settings}>
                        <div>
                          <Link to={'/incidents/open-requests'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={IncidentImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Incidents")} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/access-cards/open'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={AccessCardImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Access Cards")}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/visitors/all'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={VisitorsImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Visitors")} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={'/fc-ss-documents/open-documents/cheques'}
                            className='text-decoration-none'
                          >
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={DocumentImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Documents")} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/parking/all'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={ParkingImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Parking")} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/directory/residents'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={DirectoryImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Directory")} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/facility-maintenance/list/open'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={MaintenanceImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Maintenance")} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/fc-ss-calendar/all'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-xl-5 px-xl-5 py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4'>
                                    <img src={CalanderIcon} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <h6 className='card-title mb-0'> {t("Calendar")} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
