import React, { FC, useEffect, useRef, useState } from 'react'
import { CalenderCard } from '../CalenderCard/CalenderCard'
import { CalenderDetails } from '../CalenderDetails/CalenderDetails'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import { useTranslation } from 'react-i18next'
import { EventInput } from '@fullcalendar/react'
import {
  getFinanceServiceRequestList,
  getLeaseList,
  getMoveoutList,
  getPaymentList,
  getReceiptList,
  getRefundList,
} from '../../../../constants/axios/apis'
import moment from 'moment'
import { formatDate } from '../../../../constants/generics/dateTimeFormater'

export function CalenderAll() {
  const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
  const { t } = useTranslation()
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const id_role = state?.auth?.user?.data?.id_role

  const [loading, setLoading] = useState<any>({
    services: true,
    refund: true,
    moveout: true,
    invoice: true,
    lease: true,
    payment: true,
  })
  const [servicesList, setServicesList] = useState<any>([])
  const [refundsList, setRefundsList] = useState<any>([])
  const [moveOutList, setMoveOutList] = useState<any>([])
  const [invoiceList, setInvoiceList] = useState<any>([])
  const [leaseList, setLeaseList] = useState<any>([])
  const [paymentList, setPaymentList] = useState<any>([])
  const [parentSelectedCategory, setParentSelectedCategory] = useState<any>('services')
  const [counts, setCounts] = useState<any>({ services: 0, refund: 0, moveout: 0, invoice: 0, lease: 0, payment: 0 })
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  const [eventState, setEventState] = useState<EventInput[]>([])

  useEffect(() => {
    setLoading({
      services: true,
      refund: true,
      moveout: true,
      invoice: true,
      lease: true,
      payment: true,
    })
    setServicesList([])
    setRefundsList([])
    setMoveOutList([])
    setInvoiceList([])
    setLeaseList([])
    setPaymentList([])

    const newPayload = currentBuilding ? '?building_id=' + currentBuilding : '?building_id=0'

    getFinanceServiceRequestList(newPayload).then((response: any) => {
      if (response.errorCode === 0 && response.data)
        setServicesList(response.data)
      setLoading((oState: any) => ({ ...oState, services: false }))
    }).catch((e) => setLoading((oState: any) => ({ ...oState, services: false })))
    getRefundList(newPayload).then((response: any) => {
      if (response.errorCode === 0 && response.data)
        setRefundsList(response.data)
      setLoading((oState: any) => ({ ...oState, refund: false }))
    }).catch((e) => setLoading((oState: any) => ({ ...oState, refund: false })))
    getMoveoutList(newPayload).then((response: any) => {
      if (response.errorCode === 0 && response.data)
        setMoveOutList(response.data)
      setLoading((oState: any) => ({ ...oState, moveout: false }))
    }).catch((e) => setLoading((oState: any) => ({ ...oState, moveout: false })))
    getReceiptList(newPayload).then((response: any) => {
      if (response.errorCode === 0 && response?.data?.receiptList)
        setInvoiceList(response.data.receiptList)
      setLoading((oState: any) => ({ ...oState, invoice: false }))
    }).catch((e) => setLoading((oState: any) => ({ ...oState, invoice: false })))
    getLeaseList(newPayload).then((response: any) => {
      if (response.errorCode === 0 && response.data)
        setLeaseList(response.data)
      setLoading((oState: any) => ({ ...oState, lease: false }))
    }).catch((e) => setLoading((oState: any) => ({ ...oState, lease: false })))
    getPaymentList(newPayload).then((response: any) => {
      if (response.errorCode === 0 && response.data)
        setPaymentList(response.data)
      setLoading((oState: any) => ({ ...oState, payment: false }))
    }).catch((e) => setLoading((oState: any) => ({ ...oState, payment: false })))
  }, [currentBuilding])

  const sameDateCheck = (date1: any, date2: any) => {
    if (date1 && date2) {
      var d1 = new Date(date1)
      var d2 = new Date(date2)

      d1.setHours(0, 0, 0, 0)
      d2.setHours(0, 0, 0, 0)

      return d1.getTime() === d2.getTime()
    }
    return false
  }

  const [calendarVists, setCalendarVists] = useState<any>([])

  useEffect(() => {
    if (Object.keys(loading).every((el: any) => !loading[el])) {
      let indexCount = 1
      let eventList = new Array()
      let eventList2 = new Array()
      let eventList3 = new Array()
      let eventList4 = new Array()
      let eventList5 = new Array()
      let eventList6 = new Array()
      let filteredServiceList = new Array()
      let filteredRefundsList = new Array()
      let filteredMoveOutList = new Array()
      let filteredInvoiceList = new Array()
      let filteredLeaseList = new Array()
      let filteredPaymentList = new Array()
      if (Array.isArray(servicesList) && servicesList.length > 0) {
        eventList = servicesList.map((event: any, index: number) => {
          return {
            id: String(indexCount++),
            title: event.category,
            date: event.createdAt ? moment(event.createdAt).format('YYYY-MM-DD') : '',
          }
        })
        filteredServiceList = servicesList.filter((event: any, index: number) =>
          sameDateCheck(event.createdAt, selectedDate)
        )
      }
      if (Array.isArray(refundsList) && refundsList.length > 0) {
        eventList2 = refundsList.map((event: any, index: number) => {
          return {
            id: String(indexCount++),
            title: event.serviceCategory,
            date: event.createdAt ? moment(event.createdAt).format('YYYY-MM-DD') : '',
          }
        })
        filteredRefundsList = refundsList?.filter((event: any, index: number) =>
          sameDateCheck(event.createdAt, selectedDate)
        )
      }
      if (Array.isArray(moveOutList) && moveOutList.length > 0) {
        eventList3 = moveOutList.map((event: any, index: number) => {
          return {
            id: String(indexCount++),
            title: event.serviceCategory ? event.serviceCategory : 'Move-Out',
            date: event.createdAt ? moment(event.createdAt).format('YYYY-MM-DD') : '',
          }
        })
        filteredMoveOutList = moveOutList?.filter((event: any, index: number) =>
          sameDateCheck(event.createdAt, selectedDate)
        )
      }
      if (Array.isArray(invoiceList) && invoiceList.length > 0) {
        eventList4 = invoiceList.map((event: any, index: number) => {
          return {
            id: String(indexCount++),
            title: t("Invoice"),
            date: event.createdAt ? moment(event.createdAt).format('YYYY-MM-DD') : '',
          }
        })
        filteredInvoiceList = invoiceList?.filter((event: any, index: number) =>
          sameDateCheck(event.createdAt, selectedDate)
        )
      }
      if (Array.isArray(leaseList) && leaseList.length > 0) {
        eventList5 = leaseList.map((event: any, index: number) => {
          return {
            id: String(indexCount++),
            title: t("Lease Detail"),
            date: event.createdAt ? moment(event.createdAt).format('YYYY-MM-DD') : '',
          }
        })
        filteredLeaseList = leaseList?.filter((event: any, index: number) =>
          sameDateCheck(event.createdAt, selectedDate)
        )
      }
      if (Array.isArray(paymentList) && paymentList.length > 0) {
        eventList6 = paymentList.map((event: any, index: number) => {
          return {
            id: String(indexCount++),
            title: event.title,
            date: event.createdAt ? moment(event.createdAt).format('YYYY-MM-DD') : '',
          }
        })
        filteredPaymentList = paymentList?.filter((event: any, index: number) =>
          sameDateCheck(event.createdAt, selectedDate)
        )
      }

      setEventState([...eventList, ...eventList2, ...eventList3, ...eventList4, ...eventList5, ...eventList6])

      setCounts({
        services: filteredServiceList.length,
        refund: filteredRefundsList.length,
        moveout: filteredMoveOutList.length,
        invoice: filteredInvoiceList.length,
        lease: filteredLeaseList.length,
        payment: filteredPaymentList.length,
      })

      let todaysEventList = new Array()

      if (parentSelectedCategory === 'services') {
        todaysEventList = filteredServiceList?.map((event: any) => {
          return {
            title: event?.category,
            subTitle: event?.propertyCode,
            date: formatDate(event?.createdAt),
            // time: formatTimeMeridiem(event.createdAt),
            status: event?.status,
            user: event?.tenantName,
            code: event?.ticket,
            lease: event?.leaseNumber,
            stateData: event,
          }
        })
      } else if (parentSelectedCategory === 'refund') {
        todaysEventList = filteredRefundsList?.map((event: any) => {
          return {
            title: event?.serviceCategory,
            subTitle: event?.propertyCode,
            date: formatDate(event?.createdAt),
            // time: formatTimeMeridiem(event.createdAt),
            status: event?.statusText,
            user: event?.tenantName,
            code: event?.ticketNo,
            lease: event?.leaseNumber,
            stateData: event,
          }
        })
      } else if (parentSelectedCategory === 'moveout') {
        todaysEventList = filteredMoveOutList.map((event: any) => {
          return {
            title: event?.serviceCategory ? event.serviceCategory : 'Move-Out Request',
            subTitle: event?.propertyCode,
            date: formatDate(event?.createdAt),
            // time: formatTimeMeridiem(event.createdAt),
            status: event?.statusText,
            user: event?.tenantName,
            code: event?.ticketNo,
            lease: event?.leaseNumber, //TODO
            stateData: event,
          }
        })
      } else if (parentSelectedCategory === 'invoice') {
        todaysEventList = filteredInvoiceList.map((event: any) => {
          return {
            title: t("Invoice"),
            subTitle: event?.propertyCode,
            date: formatDate(event?.createdAt),
            // time: formatTimeMeridiem(event.createdAt),
            status: '',
            user: event?.tenantName,
            code: `0000${event?.id}`,
            lease: event?.leaseNumber,
            stateData: event,
          }
        })
      } else if (parentSelectedCategory === 'lease') {
        todaysEventList = filteredLeaseList.map((event: any) => {
          return {
            title: t("Lease Detail"),
            subTitle: event?.propertyCode,
            date: formatDate(event?.createdAt),
            // time: formatTimeMeridiem(event.createdAt),
            status: '',
            user: event?.tenantName,
            code: `0000${event?.id}`,
            lease: event?.leaseNumber,
            stateData: event,
          }
        })
      } else if (parentSelectedCategory === 'payment') {
        todaysEventList = filteredPaymentList.map((event: any) => {
          return {
            title: event?.title,
            subTitle: event?.propertyCode,
            // subTitle: event?.paymentTypeText,
            date: formatDate(event?.createdAt),
            // time: formatTimeMeridiem(event.createdAt),
            status: '',
            user: event?.tenantName,
            code: `0000${event?.id}`,
            lease: event?.leaseNumber,
            stateData: event,
          }
        })
      }
      setCalendarVists(todaysEventList)
    } else {
      setEventState([])
      setCounts({ services: 0, refund: 0, moveout: 0, invoice: 0, lease: 0, payment: 0 })
      setCalendarVists([])
    }
  }, [loading, selectedDate, parentSelectedCategory])
  // }, [servicesList, refundsList, moveOutList, invoiceList, leaseList, paymentList, selectedDate, parentSelectedCategory])

  return (
    <div className='com-card-section'>
      <div className='row flex-stack announcement-sec mb-6'>
        <div className='col-md-12'>
          <h4 className='card-title font-18 text-black'> {t('Calendar')} </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-7'>
          <CalenderCard eventState={eventState} setSelectedDate={setSelectedDate} />
        </div>
        <div className='col-md-5'>
          <CalenderDetails
            calendarVists={calendarVists}
            loading={loading.services || loading.refund || loading.moveout || loading.invoice || loading.lease || loading.payment}
            selectedDate={selectedDate}
            counts={counts}
            setParentSelectedCategory={setParentSelectedCategory}
          />
        </div>
      </div>
    </div>
  )
}
