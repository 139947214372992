import React, { useState, FC, useEffect, useRef } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../../../../assets/images/Left_Arrow.png'
import { MoveOutDetailsTab } from './MoveOutDetailsTab/MoveOutDetailsTab'
import { MainDoorKeys } from './MainDoorKeys/MainDoorKeys'

import { getAllotedAccessId } from '../../../../../../../constants/axios/apis'
import { BuildingAccessCard } from '../../../UpcomingApprovals/MoveIn/MoveInDetailsWrapper/BuildingAccessCard/BuildingAccessCard'
import { ParkingAccessCard } from '../../../UpcomingApprovals/MoveIn/MoveInDetailsWrapper/ParkingAccessCard/ParkingAccessCard'
import { Details } from '../../../UpcomingApprovals/MoveIn/MoveInDetailsWrapper/Details/Details'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../../../../../../constants/generics/userCheck'
import { OtherAccessCard } from '../../../UpcomingApprovals/MoveIn/MoveInDetailsWrapper/OtherAccessCard/OtherAccessCard'
import { useTranslation } from 'react-i18next'

const MoveOutDetailsWrapper1: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation<any>()
  const [accessCards, setAccessCards] = useState<any>()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  let approvalData = location?.state?.approval ? location?.state?.approval : {}
  const property_id = approvalData
  // const user_id = location?.state?.approval?.user_id;
  const id = approvalData
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [reloadData, setReloadData] = useState<any>(true)
  const [approvalDataDetails, setApprovalDataDetails] = useState<any>(approvalData)

  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  useEffect(() => {
    getAllotedAccessId().then(({ data }: any) => {
      setAccessCards(data?.find((element: any) => element?.propertyId === property_id))
    })
  }, [property_id])

  const bacCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.no_of_cards
  const pacCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.no_of_cards
  const mdkCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Main Door Key'
  )?.no_of_cards
  const oacCardData = approvalData?.card_details?.other_access_cards?.card_number
  const oacCount = oacCardData ? Object.keys(oacCardData)?.length : 0
  let bacCardDetails
  let pacCardDetails
  let oacCardDetails

  if (approvalData?.card_details?.building_access_card) {
    bacCardDetails = approvalData?.card_details?.building_access_card?.card_number
    bacCardDetails = Object.values(bacCardDetails)
    bacCardDetails = bacCardDetails?.map((item: any) => item.number)
  }

  if (approvalData?.card_details?.parking_access_card) {
    pacCardDetails = approvalData?.card_details?.parking_access_card?.card_number
    pacCardDetails = Object.values(pacCardDetails)
    pacCardDetails = pacCardDetails.map((item: any) => item.number)
  }

  if (approvalData?.card_details?.other_access_cards) {
    oacCardDetails = approvalData?.card_details?.other_access_cards?.card_number
    oacCardDetails = Object.values(oacCardDetails)
    oacCardDetails = oacCardDetails.map((item: any) => item.number)
  }

  const buildingSaved = approvalData?.card_details?.building_access_card?.signature?.length > 0
  const parkingSaved = approvalData?.card_details?.parking_access_card?.signature?.length > 0
  const otherSaved = approvalData?.card_details?.parking_access_card?.signature?.length > 0

  return (
    <>
      <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='card-title font-18 text-black mb-6'>
                <Link to={'/approvals/closed/moveout'} className=''>
                  <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                  {/* <span className='text-black'>Move Out Approval</span> */}
                </Link>
                <span className="text-black">{t("Collected Access Cards")}</span>
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mb-7'>
              <Details approval={approvalDataDetails} />
            </div>
          </div>
          <Switch>
            <Route path='/fcss-moveoutDetails1/main-door-keys'>
              <MoveOutDetailsTab
                buildingSaved={buildingSaved}
                parkingSaved={parkingSaved}
                otherSaved={otherSaved}
                isOthers={oacCount !== 0}
              />
              <MainDoorKeys />
            </Route>
            <Route path='/fcss-moveoutDetails1/building-access-card'>
              <MoveOutDetailsTab
                buildingSaved={buildingSaved}
                parkingSaved={parkingSaved}
                otherSaved={otherSaved}
                isOthers={oacCount !== 0}
              />
              <BuildingAccessCard
                approval={approvalDataDetails}
                count={bacCount}
                cardDetails={bacCardDetails}
                allotedAccessID={
                  approvalDataDetails?.card_details?.building_access_card?.allotted_access_id
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.building_access_card?.signature
                    : approvalDataDetails?.card_details?.building_access_card?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.building_access_card?.signature
                    : ''
                }
                setReloadData={setReloadData}
              />
            </Route>
            <Route path='/fcss-moveoutDetails1/parking-access-card'>
              <MoveOutDetailsTab
                buildingSaved={buildingSaved}
                parkingSaved={parkingSaved}
                otherSaved={otherSaved}
                isOthers={oacCount !== 0}
              />
              <ParkingAccessCard
                count={pacCount}
                approval={approvalDataDetails}
                cardDetails={pacCardDetails}
                allotedAccessID={
                  approvalDataDetails?.card_details?.parking_access_card?.allotted_access_id
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.parking_access_card?.signature
                    : approvalDataDetails?.card_details?.parking_access_card?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.parking_access_card?.signature
                    : ''
                }
                setReloadData={setReloadData}
              />
            </Route>
            <Route path='/fcss-moveoutDetails1/other-access-card'>
              <MoveOutDetailsTab
                buildingSaved={buildingSaved}
                parkingSaved={parkingSaved}
                otherSaved={otherSaved}
                isOthers={oacCount !== 0}
              />
              <OtherAccessCard
                approval={approvalDataDetails}
                count={oacCount}
                cardDetails={oacCardDetails}
                allotedAccessID={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.other_access_cards?.allotted_access_id
                    : approvalDataDetails?.card_details?.other_access_cards?.allotted_access_id
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.other_access_cards?.signature
                    : approvalDataDetails?.card_details?.other_access_cards?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.other_access_cards?.signature
                    : ''
                } //Only FMSM used for customer signature
                setReloadData={setReloadData}
                type={approvalDataDetails?.subReference === 5 ? 'movein' : 'moveout'}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export { MoveOutDetailsWrapper1 }
