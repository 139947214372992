import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import completedStep from '../../../../../../../../assets/images/completed-step.png'
import CurrentStep from '../../../../../../../../assets/images/current-step.png'
import { useTranslation } from 'react-i18next'

type Props = {
  buildingSaved: boolean
  parkingSaved: boolean
}
const MoveInDetailsTab: React.FC<Props> = ({buildingSaved, parkingSaved}) => {
  let params: any = {}
  params = useLocation()
  const {approval} = params.state

  const location = useLocation()
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-2'>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                     <li className='nav-item'>
                        <Link
                          className={
                            `nav-link position-relative text-active-primary ` +
                            (location.pathname === '/fcss-moveinDetails/building-access-card' &&
                              'active')
                          }
                          to={{
                            pathname: '/fcss-moveinDetails/building-access-card',
                            state: {approval},
                          }}
                        >
                          {t("Building Access Card")}
                          {buildingSaved ? (
                            <span className='completed-step ps-3 pe-3 d-inline-block'>
                              <img src={completedStep} alt='complete-step' />
                            </span>
                          ) : (
                            <span className='completed-step ps-3 pe-3 d-inline-block'>
                              <img src={CurrentStep} alt='current-step' />
                            </span>
                          )}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link position-relative text-active-primary ` +
                            (location.pathname === '/fcss-moveinDetails/parking-access-card' &&
                              'active')
                          }
                          to={{
                            pathname: '/fcss-moveinDetails/parking-access-card',
                            state: {approval},
                          }}
                        >
                          {t("Parking Access Card")}
                          {parkingSaved ? (
                            <span className='completed-step ps-3 pe-3 d-inline-block'>
                              <img src={completedStep} alt='complete-step' />
                            </span>
                          ) : (
                            <span className='completed-step ps-3 pe-3 d-inline-block'>
                              <img src={CurrentStep} alt='current-step' />
                            </span>
                          )}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={`nav-link position-relative text-active-primary ` +
                            (location.pathname === '/fcss-moveinDetails/main-door-keys' && 'active')}
                          to={{ pathname: '/fcss-moveinDetails/main-door-keys', state: { approval } }}
                        >
                          Main Door Keys
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {MoveInDetailsTab}
