import React, {  useState } from 'react'
import { Link } from 'react-router-dom';
import { Contacts } from '../Tabs/Contacts';
import { Security } from '../Tabs/Security';
import { AccountDefault } from '../Tabs/AccountDefault';
import { EmailSettings } from '../Tabs/Email';
import { Nortification } from '../Tabs/Nortification';
import { Conversation } from '../Tabs/Conversation';
import { Cookies } from '../Tabs/Cookies';
import { ImportExport } from '../Tabs/Import&Export';
import { LeadRules } from '../Tabs/LeadRules';
import { DashboardSettings } from '../Tabs/DashboardSettings';
import { Portal } from '../Tabs/Portal';

export function SettingsList() {
  const [tabPosition, setTabPosition] = useState("1");
  const opportunityTab = (i: any) => {
    setTabPosition(i)
  }

  return (
    <>
     
          <div className='d-flex overflow-auto background-white py-3'>
            <ul className=' nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-2 flex-nowrap'>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '1' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(1)}>
                  Email Settings
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '2' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(2)}>
                  Nortification
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '3' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(3)}>
                  Security
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '4' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(4)}>
                  Account Default
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '5' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(5)}>
                  Contacts & Companies
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '6' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(6)}>
                  Conversation
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '7' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(7)}>
                  Cookies
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '8' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(8)}>
                  Import & Export
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '9' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(9)}>
                  Lead Rules
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '10' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(10)}>
                  Dashboard Settings
                </Link>
              </li>
              <li className='nav-item settings-w'>
                <Link to className={`${tabPosition == '11' ? 'active' : ''}  nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(11)}>
                  Portal
                </Link>
              </li>
            </ul>
          </div>

          {tabPosition == '1' ? <EmailSettings /> :
            tabPosition == '2' ? <Nortification /> :
              tabPosition == '3' ? <Security /> :
                tabPosition == '4' ? <AccountDefault /> :
                  tabPosition == '5' ?  <Contacts /> : 
                  tabPosition == '6' ?  <Conversation /> :
                  tabPosition == '7' ?  <Cookies /> :
                  tabPosition == '8' ?  <ImportExport /> :
                  tabPosition == '9' ?  <LeadRules /> :
                  tabPosition == '10' ?  <DashboardSettings /> :
                  tabPosition == '11' ?  <Portal /> : ''
          }

     
    </>
  )
}
