import React from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { ClearanceWorkorderBasicDetails } from './ClearanceWorkorderBasicDetails';
import { ConfirmedMoveOut } from './ConfirmedMoveOut';
import { CompletedMoveOut } from './CompletedMoveOut';
import { ComplitionDetails } from '../WorkorderComplitionDetails/ComplitionDetails';

export function ClearanceWorkorderDetailsWrapper({workOrder, setPopupSuccessMsg}:any) {

    const history = useHistory();

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    

    return (
        <>
            <ClearanceWorkorderBasicDetails workOrder={workOrder} setPopupSuccessMsg={setPopupSuccessMsg}/>
            { (workOrder.status === "Confirmed" && user_role !== 'FMT') &&
                <ConfirmedMoveOut workOrder={workOrder} setPopupSuccessMsg={setPopupSuccessMsg}/>
            }
            { (workOrder.status === "Completed" || (workOrder.status === "Confirmed" && user_role === 'FMT')) ?
                <CompletedMoveOut workOrder={workOrder}/> :''
            }
            { ((workOrder.status === "Open" && user_role === 'FCS') || user_role === 'FMT') ?
                <ComplitionDetails workOrder={workOrder}/> : ''
            }

        </>
    );
}