import { Chart } from 'react-chartjs-2';

export function Viewing({ chartData }: any) {

    const data = {
        labels: [`The Sloan by Belgravia Heights(${chartData?.data?.data?.unit?.leased})`,
        `Belgravia Square(${chartData?.data?.data?.unit?.vacant})`],
       
        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                data: [chartData?.data?.data?.unit?.leased, chartData?.data?.data?.unit?.vacant],
                backgroundColor: [
                    '#458b00',
                    '#66cd00'
                ],
                borderColor: [
                    '#458b00',
                    '#66cd00'
                ],
                borderWidth: 1,
            },
        ]
    };

    const options = {
        plugins: {
            cutout: 70,
            legend: {
                display: true,
                position: 'right' as const,
                labels: {
                    color: '#000000',
                    lineDash: [12, 12],
                    boxWidth: 16,
                    boxHeight: 16,
                    padding: 15,
                    usePointStyle: true,
                    font: {
                        size: 12,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    }
                }
            },
        },

        maintainAspectRatio: true,
    };
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="unit-summary-sec mb-4">
                        <div className="p-7 pb-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <h4 className="font-14 fw-boldest text-black"> Community wise </h4>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="pm_unit_summary_cart">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="Doughnut-Chart text-center position-relative top-0" id="unitsumary">
                                                <div style={{ width: '80%', height: '80%' }}>                                                    
                                                    <Chart type='doughnut' data={data} options={options} width={30} height={30} />
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}