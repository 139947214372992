import React, {FC, useEffect, useRef, useState, Component} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import WorkOrdersImg from "../../../../../assets/images/ac-repair.png";
        import uploadImg from "../../../../../assets/images/file-upload.svg";
import { useTranslation } from 'react-i18next'

const Step5: FC = () => {
    const [file, setFile] = useState([] as any);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const { t } = useTranslation();

    function uploadSingleFile(e:any) {
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        console.log("file", file);
    }
    function upload(e:any) {
        e.preventDefault();
        console.log(file);
    }
    function deleteFile(e:any) {
        const s = file.filter((item:any, index:any) => index !== e);
        setFile(s);
        console.log(s);
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    const [visible, setVisible] = useState(true);
    function removeImg() {
        setVisible(false);
    }
    const [{alt, src}, setImg] = useState({
        src: WorkOrdersImg,
        alt: 'Upload an Image'
    });

    const handleImg = (e:any) => {
        if(e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name
            });
        }
    }

    const intl = useIntl()
  const location = useLocation()
  const [menu, setMenu] = useState('')

  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  useEffect(() => {
    if (window.location.pathname != menu) {
      setMenu(window.location.pathname)
      console.log(window.location.pathname)
    }
  })

    return (
        <>
            { userRole === 16 ? (
                <div className="com-card-section w-100 p-7">
                    <div className="row form-add-design">
                        <div className="col-12">
                            <div className="card shadow-none">
                                <div className="card-body p-0">
                                    <div className="row form-add-design movein-property-image">
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder"> {t("Media Upload")} </label>
                                                    <input ref={inputRef} onChange={uploadSingleFile} className="d-none" type="file" multiple/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                        <span>{uploadedFileName ? uploadedFileName : <span className="txt">
                                                            <img
                                                                    alt="File"
                                                                    src={uploadImg}
                                                            className="position-relative"
                                                            loading="lazy"/>
                                                            {t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">

                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder d-block">{t("Attach Images/Video")}</label>
                                                    <div className="d-flex">
                                                        {visible &&
                                                            <div className="position-relative">
                                                                <div className="upload-image">
                                                                    <img src={src} alt={alt} className="form-img__img-preview"/>
                                                                    <div className="close-icon" onClick={removeImg}>&times;</div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {file.length > 0 &&
                                                        <div className="position-relatives">
                                                            {file.map((item:any, index:any) => {
                                                                return (
                                                                    <div className="upload-image" key={item}>
                                                                        <img src={item} alt="" />
                                                                        <div className="close-icon" onClick={() => deleteFile(index)}>
                                                                            &times;
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ): userRole === 18 ? ( 
                <div className="com-card-section w-100 p-7">
                    <div className="row form-add-design">
                        <div className="col-12">
                            <div className="card shadow-none">
                                <div className="card-body p-0">
                                    <div className="row form-add-design movein-property-image">
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder"> {t("Media Upload")} </label>
                                                    <input ref={inputRef} onChange={uploadSingleFile} className="d-none" type="file" multiple/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                        <span>{uploadedFileName ? uploadedFileName : <span className="txt">
                                                            <img
                                                                    alt="File"
                                                                    src={uploadImg}
                                                            className="position-relative"
                                                            loading="lazy"/>
                                                            {t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">

                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder d-block">{t("Attach Images/Video")}</label>
                                                    <div className="d-flex">
                                                        {visible &&
                                                            <div className="position-relative">
                                                                <div className="upload-image">
                                                                    <img src={src} alt={alt} className="form-img__img-preview"/>
                                                                    <div className="close-icon" onClick={removeImg}>&times;</div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {file.length > 0 &&
                                                        <div className="position-relatives">
                                                            {file.map((item:any, index:any) => {
                                                                return (
                                                                    <div className="upload-image" key={item}>
                                                                        <img src={item} alt="" />
                                                                        <div className="close-icon" onClick={() => deleteFile(index)}>
                                                                            &times;
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ):null}
        </>
    )
}

export {Step5}
