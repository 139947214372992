import React, { useState, useEffect, useRef } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export function Editor() {
    return (
        <div className="">
            <CKEditor
                editor={ClassicEditor}
                data="<p>  </p>"
                onInit={(editor: any) => {
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                }}
                onBlur={(event: any, editor: any) => {
                    console.log('Blur.', editor);
                }}
                onFocus={(event: any, editor: any) => {
                    console.log('Focus.', editor);
                }}
            />
        </div>
    );
}