import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const DocumentTabs: React.FC = () => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-4 py-2">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary px-5 ` +
                                                    (location.pathname === '/documents/emirates-id' && 'active')}
                                                    to='/documents/emirates-id'>
                                                    {t("Emirates Id")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary px-5 ` +
                                                    (location.pathname === '/documents/passport' && 'active')}
                                                    to='/documents/passport'>
                                                    {t("Passport")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary px-5 ` +
                                                    (location.pathname === '/documents/driving-license' && 'active')}
                                                    to='/documents/driving-license'>
                                                    {t("Driving License")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {DocumentTabs}
