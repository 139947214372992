import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import { useTranslation } from 'react-i18next'
import moment from 'moment';

export function ApprovalHistory() {
    let contractApprovalData: any = []
    contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                {contractApprovalData?.approval_history?.length > 0 ? contractApprovalData?.approval_history.map((item: any, index: number) => {
                    return (
                        <div className="col-md-6 col-12 mb-5">
                            <div className="card payment-card-sec">
                                <div className="card-body px-8 py-6">
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Status")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">{item?.status}</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Approved By")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">{item?.approvedBy}</h3>
                                    </div>
                                    <div className="row">
                                        <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                            {t("Approved Date")}
                                        </label>
                                        <h3 className="mb-0 payment-txt col">{item?.approvedAt && item?.approvedAt != '' && item?.approvedAt != '--' && moment(item?.approvedAt).format('DD-MM-YYYY')}</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row align-items-center">
                                                <label className="font-14 fw-bold mb-3 text-theme-gray col">
                                                    {t("Recommendation")}
                                                </label>
                                                <h3 className="mb-0 payment-txt col">{item?.comment}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) :
                    <div className="col-12">
                        <div className="card payment-card-sec">
                            <div className="card-body px-8 py-6">
                                {t("No Approval History Found")}
                            </div>
                        </div>
                    </div>
}
            </div>
        </div>
    );
}