import React, {useState, FC, useEffect, useRef} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Link, useParams, useHistory, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../../../../assets/images/Left_Arrow.png'
import {MoveInDetailsTab} from './MoveInDetailsTab/MoveInDetailsTab'
import {MainDoorKeys} from './MainDoorKeys/MainDoorKeys'
import {getAllotedAccessId} from '../../../../../../../constants/axios/apis'
import {BuildingAccessCard} from '../../../UpcomingApprovals/MoveIn/MoveInDetailsWrapper/BuildingAccessCard/BuildingAccessCard'
import {ParkingAccessCard} from '../../../UpcomingApprovals/MoveIn/MoveInDetailsWrapper/ParkingAccessCard/ParkingAccessCard'
import {Details} from '../../../UpcomingApprovals/MoveIn/MoveInDetailsWrapper/Details/Details'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../../../../../../constants/generics/userCheck'
import { useTranslation } from 'react-i18next';

const MoveInDetailsWrapper1: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation<any>()
  const [accessCards, setAccessCards] = useState<any>()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  let approvalData = location?.state?.approval ? location?.state?.approval : {}
  const property_id = approvalData
  // const user_id = location?.state?.approval?.user_id;
  const id = approvalData
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [approvalDataDetails, setApprovalDataDetails] = useState<any>(approvalData)
  const [reloadData, setReloadData] = useState<any>(true)
  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  // useEffect(() => {
  //     getAllotedAccessId().then(({ data }: any) => {
  //         setAccessCards(data.find((element: any) => element.propertyId === property_id))
  //     })
  // }, [property_id])

  const bacCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.no_of_cards
  const pacCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.no_of_cards
  const mdkCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Main Door Key'
  )?.no_of_cards
  
  let bacCardDetails
  let pacCardDetails

  if (approvalData?.card_details?.building_access_card) {
    bacCardDetails = approvalData?.card_details?.building_access_card?.card_number
    bacCardDetails = Object.values(bacCardDetails)
    bacCardDetails = bacCardDetails?.map((item: any) => item.number)
  }

  if (approvalData?.card_details?.parking_access_card) {
    pacCardDetails = approvalData?.card_details?.parking_access_card?.card_number
    pacCardDetails = Object.values(pacCardDetails)
    pacCardDetails = pacCardDetails.map((item: any) => item.number)
  }

  const buildingSaved = 
    user_role === 'FMSM' ?
     approvalData?.card_details?.building_access_card?.staff_signature?.length ? true : false
     : approvalData?.card_details?.building_access_card?.signature?.length ? true : false
  const parkingSaved = 
    user_role === 'FMSM' ?
    approvalData?.card_details?.parking_access_card?.staff_signature?.length ? true : false
    : approvalData?.card_details?.parking_access_card?.signature?.length ? true : false
    
  return (
    <>
      <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='card-title font-18 text-black mb-6'>
                <Link to={'/approvals/closed/movein'} className=''>
                  <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                  {/* <span className='text-black'>{t("Move In Approval")}</span> */}
                </Link>
                <span className="text-black">{t("Issued Access Cards")}</span>
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mb-7'>
              <Details approval={approvalDataDetails} />
            </div>
          </div>
          <Switch>
            <Route path='/fcss-moveinDetails1/main-door-keys'>
              <MoveInDetailsTab buildingSaved={buildingSaved} parkingSaved={parkingSaved} />
              <MainDoorKeys
              // count={mdkCount}
              />
            </Route>
            <Route path='/fcss-moveinDetails1/building-access-card'>
              <MoveInDetailsTab buildingSaved={buildingSaved} parkingSaved={parkingSaved} />
              <BuildingAccessCard
                count={bacCount}
                cardDetails={bacCardDetails}
                allotedAccessID={
                  approvalDataDetails?.card_details?.building_access_card?.allotted_access_id
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.building_access_card?.signature
                    : approvalDataDetails?.card_details?.building_access_card?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.building_access_card?.signature
                    : ''
                }
                setReloadData={setReloadData}
              />
            </Route>
            <Route path='/fcss-moveinDetails1/parking-access-card'>
              <MoveInDetailsTab buildingSaved={buildingSaved} parkingSaved={parkingSaved} />
              <ParkingAccessCard
                count={pacCount}
                cardDetails={pacCardDetails}
                allotedAccessID={
                  approvalDataDetails?.card_details?.parking_access_card?.allotted_access_id
                }
                cardSignature={
                  user_role === 'FCSS'
                    ? approvalDataDetails?.card_details?.parking_access_card?.signature
                    : approvalDataDetails?.card_details?.parking_access_card?.staff_signature
                }
                cardSignature2={
                  user_role === 'FMSM'
                    ? approvalDataDetails?.card_details?.parking_access_card?.signature
                    : ''
                }
                setReloadData={setReloadData}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export {MoveInDetailsWrapper1}
