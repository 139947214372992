import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import {ReportCard} from './components/ReportCard/ReportCard'
import {Rent} from './components/Rent/Rent'
import { useSelector } from 'react-redux';
import { generateReportFC, getBuildingList } from '../../utils/makeRequest';
import { IState } from "../../utils/interface";

// import {ReportsPage} from '../fc-supervisor-report/FCSupervisorReportWrapper';
// import {Report}from'./components/Report/Report';

export const ReportsPage: FC = () => {
    const state: any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    if (userToken === "undefined") {
        // token undefined
    }
    const [reportType,setReportType] = useState<any>('');

    const [reportState, setReportState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    // const [buildingListState, setBuildingListState] = useState<IState['APIState']>({
    //     loading: false,
    //     response: [],
    //     error: ''
    // });
    
    const genReport = (params: any) => {
        generateReportFC(setReportState, userToken, params);
    }

    // useEffect(() => {
    //     getBuildingList(setBuildingListState,userToken);
    // }, []);

    return (
        <>
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>
                <ReportCard 
                    // reportState={reportState} 
                    // setReportState={setReportState} 
                    genReport={genReport} 
                    // buildingListState={buildingListState}
                    setReportType={setReportType}/>
                <Rent reportState={reportState} setReportState={setReportState} reportType={reportType}/>
            </div>
        </div>
        </>
    );
}

const FCReportsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}> Community Post </PageTitle>
        <ReportsPage />
        </>
    )
};

export {FCReportsWrapper}
