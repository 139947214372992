import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserModel } from '../../../../components/auth/models/UserModel'
import { RootState } from '../../../../constants'
import { Languages } from './Languages'
import { KTSVG } from '../../../../components/helpers'
import { Redirect } from 'react-router'
import { ssoUserDetailswithLocations } from '../../../../constants/generics/sso'
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../../constants/generics/userCheck'
import { getAllowedProfileRoutes } from '../../../../configs/roleUtils'
import PrivateRoutesConfigProfileMenu from '../../../../configs/PrivateRoutesConfigProfileMenu'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  const history = useHistory()
  const { t } = useTranslation();
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userRole = JSON.parse(userDetail).id_role
  }

  const onLogout = () => {
    let confirm = window.confirm('Are you sure you want to logout?')
    if (confirm) {
      window.localStorage.clear()
      history.push('/logout')
    }
  }
  const handleFind = () => {
    const location = `/properties-listing`
    window.location.assign(`${ssoUserDetailswithLocations(userDetail, accessToken, location)}`)
  }

  const state: any = useSelector(state => state);
  const userRoleCode = getUserRole(state?.auth?.user?.data);

  const allPermissions: any = useSelector<RootState>(({ General }) => General.permissions)
  var allowedProfileRoutes = allPermissions != undefined ? getAllowedProfileRoutes(PrivateRoutesConfigProfileMenu, userRoleCode, allPermissions) : [];

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-200px'
      data-kt-menu='true'
    >
      <div className='menu-item'>
        <Link to={'/profile'} className='menu-link px-3 py-4'>
         
          {t("My Profile")}
          <KTSVG path='' className='item-icon user-ioc' />
        </Link>
      </div>

      {/* {(userRole === 4 || userRole === 5 ||userRole === 6) && <div className='menu-item px-2'> */}
      {userRoleCode && ['PM', 'PE'].includes(userRoleCode) ?
        <div className='menu-item'>
          <a onClick={handleFind} className='menu-link px-3 py-4'>
           
            {t("Property Search")}
            <KTSVG path='' className='item-icon srch-ioc' />
          </a>
        </div> : ''}
      {/* {userRoleCode && ['BR', 'BA'].includes(userRoleCode) ?
        <div className='menu-item px-2'>
          <Link to={'/faq'} className='menu-link px-3 py-4'>
            <KTSVG path='' className='item-icon quest-ioc' />
            {t("FAQ's")}
          </Link>
        </div> : ''}
      {userRoleCode && ['BR', 'BA'].includes(userRoleCode) ?
        <div className='menu-item px-2'>
          <Link to='/pt-settings' className='menu-link px-3 py-4'>
            <KTSVG path='' className='item-icon settings-ioc' />
            {t("Settings")}
          </Link>
        </div> : ''} */}
      {
        allowedProfileRoutes?.map(({ path, menu }: any, index: number) => (
          <div className='menu-item' key={index}>
            <Link to={menu.menuPath} className='menu-link px-3 py-4'>
              {/* <KTSVG path='' className='item-icon doc-ioc' /> */}
              {t(menu.title)}
              <KTSVG path='' className={menu.profileIconClass} />
            </Link>
          </div>
        ))
      }
      <div className='menu-item'>
        <div
          // onClick={() => onLogout()}
          className='menu-link px-3 py-4'
          data-bs-toggle="modal"
          data-bs-target="#logout-modal"
        >
         
          {t("Log Out")}
          <KTSVG path='' className='item-icon logout-ioc' />
        </div>
      </div>
    </div>

  )
}

export { HeaderUserMenu }
