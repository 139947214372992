import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { Profile } from '../TeamMembersDetails/Profile/Profile';
import { Details } from '../TeamMembersDetails/Details/Details';
import { AssignedProperties } from '../TeamMembersDetails/AssignedProperties/AssignedProperties';
import { getMyTeamDeatils } from '../../../../constants/axios/apis';
import { CustomLoader } from './../../../../constants/generics/customLoader';
import { useTranslation } from 'react-i18next'

type LocationState = {
    id?: any
}

const PMTeamMembersDetails: React.FC = () => {
    const location = useLocation<LocationState>();
    const { id } = location.state || {};
    const { state } = useLocation();
	const searchType:any = state;
    const [teamDetailData, setTeamDetailData] = useState<any>([])
    const { t } = useTranslation();

    const uId = id != null ? id : searchType?.id
    useEffect(() => {
        if (uId) {
            const qryParams = "user_role=PE&user_id=" + uId
            getMyTeamDeatils(qryParams)
                .then((response: any) => {
                    setTeamDetailData(response);
                })
                .catch((e) => {
                })
        } else {
            // console.log("no id found");
        }
    }, [id])
    if (teamDetailData.length === 0 && teamDetailData.errorCode != 0) {
        return <CustomLoader />
    }
    return (
        <div className="col-12 mt-n6">
            <div className="row access-cd-tabs align-items-center">
                <div className="col-12">
                    <h4 className="card-title font-18 text-black mb-0">
                        <Link to={'/pm-myteam/all'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            <span className="text-black">{t("Details")}</span>
                        </Link>
                    </h4>
                </div>
                <div className="col-12 mt-7">
                    <div className="card">
                        <div className="card-body px-5 py-5">
                            <div className="row">
                                <div className="col-lg-3 col-12 mb-lg-0 mb-5">
                                    <Profile teamDetailData={teamDetailData}/>
                                </div>
                                <div className="col-lg-9 prof-det col-12">
                                    <Details teamDetailData={teamDetailData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="card">
                        <div className="card-body px-5 pt-5 pb-0">
                            <div className="row">
                                <AssignedProperties teamDetailData={teamDetailData}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { PMTeamMembersDetails }
