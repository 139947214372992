import React, {useEffect, useRef, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import Home from '../../../../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../../../../assets/images/Left_Arrow.png'
import {MainDoorKeys} from './MainDoorKeys/MainDoorKeys'
import {BuildingAccessCard} from './BuildingAccessCard/BuildingAccessCard'
import {ParkingAccessCard} from './ParkingAccessCard/ParkingAccessCard'
import SignatureCanvas from 'react-signature-canvas'
import {getAllotedAccessId, submitMoveOutApproval} from '../../../../../../../constants/axios/apis'
import {RequestFailedPopup} from '../../../../../../SuccessfulPopup/RequestFailedPopup'

export function MoveOutDetails() {
  const location = useLocation<any>()
  const [accessCards, setAccessCards] = useState<any>()
  // const property_id = location?.state?.approval?.propertyID;
  // const user_id = location?.state?.approval?.user_id;
  // const id = location?.state?.approval?.id;
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  let approvalData = location?.state?.approval ? location?.state?.approval : {}
  const property_id = approvalData
  const id = approvalData

  useEffect(() => {
    getAllotedAccessId().then(({data}: any) => {
      setAccessCards(data.find((element: any) => element.propertyId === property_id))
    })
  }, [property_id])

  const sigCanvas = useRef<any>()
  function clear() {
    sigCanvas.current.clear()
  }
  // const clear = () => sigCanvas.current.clear();

  const sendMoveOutApproval = () => {
    setTimeout(() => {
      submitMoveOutApproval(id)
        .then((response: any) => {
          setLoading(false)
          if (response.errorCode === 0) {
            successRef.current.click()
            history.push('/approvals/upcoming/moveout', {})
          } else {
            setMessage(response.errorDescription)
            failRef.current.click()
          }
        })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Invalid Data Provided')
        })
    }, 1000)
  }

  const bacCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Building Access Card'
  )?.no_of_cards
  const pacCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Parking Access Card'
  )?.no_of_cards
  const mdkCount = approvalData?.allotted_access_card?.find(
    (el: any) => el.name === 'Main Door Key'
  )?.no_of_cards

  return (
    <>
      <div className='com-card-section myunit-page'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/approvals/upcoming/moveout'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                <span className='text-black'>Move Out Approval</span>
              </Link>
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3 mb-md-0'>
                    <div className='approval-move'>
                      <img src={approvalData?.image} className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded'>
                      <div className='card-body px-0 py-0'>
                        <div className='row'>
                          <div className='col-md-12 col-12'>
                            <span className='text-black fw-bolder font-15 d-block mb-4'>
                              Tenant Name : {approvalData?.cutomerName}
                              {/* Lease Number : {location?.state?.approval?.details?.leaseNumber} */}
                            </span>
                          </div>
                          {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              Customer Name{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {location?.state?.approval?.customerName}{' '}
                            </span>
                          </div> */}
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                                {' '}
                                Unit Number{' '}
                              </h6>
                              <span className='text-black fw-bold font-15'>
                                {' '}
                                {approvalData?.propertyCode}{' '}
                              </span>
                            </div>
                          </div>
                          {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              Lease Start Date{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {location?.state?.approval?.details?.leaseStartDate}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              Lease End Date{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {location?.state?.approval?.details?.leaseEndDate}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> Move-out Date </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {location?.state?.approval?.details?.moveOutDate}{' '}
                            </span>
                          </div> */}
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12 mb-md-0 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                              {' '}
                              Main Door Keys{' '}
                            </h6>
                            <span className='text-black fw-bold font-15'>
                              {' '}
                              {mdkCount ? mdkCount : '--'}{' '}
                            </span>
                          </div>
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12 mb-md-0'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                              {' '}
                              Building Access Cards{' '}
                            </h6>
                            <span className='text-black fw-bold font-15'>
                              {' '}
                              {bacCount ? bacCount : '--'}{' '}
                            </span>
                          </div>
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12 mb-md-0'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                              Parking Access Cards{' '}
                            </h6>
                            <span className='text-black fw-bold font-15'>
                              {' '}
                              {pacCount ? pacCount : '--'}{' '}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='row form-add-design mt-5'>
                  <div className='col-md-12 col-12'>
                    <div className='row'>
                      <div className="col-md-3 col-12"></div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-0'>
                          <label className='form-label fw-bolder'> Customer Signature </label>
                          <div className='w-100 form-control'>
                            <SignatureCanvas
                              ref={sigCanvas}
                              penColor='black'
                              canvasProps={{ height: 60, width: 320, className: 'sigCanvas' }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 my-auto col-12'>
                        <div className='mt-7'>
                          <button className='btn btn-light fw-bold px-10 py-2' onClick={clear}>
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='accordion approval-accordion' id='accordionExample'>
              <MainDoorKeys
                // property_id={property_id}
                // user_id={user_id}
                // mdk_id={accessCards?.MDK}
                // approval_id={id}
                // count={accessCards?.main_door_keys}
                count={mdkCount}
              />
              <BuildingAccessCard
                // property_id={property_id}
                // user_id={user_id}
                // bac_id={accessCards?.BAC}
                // approval_id={id}
                // count={accessCards?.main_door_keys}
                count={bacCount}
              />
              <ParkingAccessCard
                // property_id={property_id}
                // user_id={user_id}
                // pac_id={accessCards?.PAC}
                // approval_id={id}
                // count={accessCards?.main_door_keys}
                count={pacCount}
              />
            </div>
          </div>
          <div className='col-12 text-end'>
            <button className='btn btn-outline-primary bg-white fw-bold px-10 py-3 me-5'>
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary fw-bold px-10 py-3'
              onClick={() => sendMoveOutApproval()}
            >
              HandOver
            </button>
          </div>
        </div>
      </div>
      <p
        ref={successRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <RequestFailedPopup message={message} />
    </>
  )
}
