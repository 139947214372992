import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'

export function MainDoorKeys({ count }: any) {
  let params: any = {};
  params = useLocation();
  const { t } = useTranslation();

  const sigCanvas = useRef<any>()
  function clearSignature() {
    sigCanvas.current.clear()
  }

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='accessCardRequestForm'
                >
                  <div className='row form-add-design'>
                    <>
                      <div className='col-sm-6 col-md-4 col-12 label_close'>
                        <div className='mb-5'>
                          <label className='form-label fw-bolder'>
                            {' '}
                            {t("Card Number")}
                          </label>
                          <div className='d-flex'>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid expected-rent-input bg-light'
                              autoComplete='off'
                              value="Main Door Keys"
                              required
                              maxLength={25}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className='row form-add-design'>
                    <div className='col-md-12 col-12'>
                      <div className='row'>
                        <div className='col-sm-8 col-md-4 col-12'>
                          <div className='mb-md-0 mb-4'>
                            <label className='form-label fw-bolder'>
                              Customer Signature
                            </label>
                            <div className='w-100 form-control'>
                              <SignatureCanvas
                                // ref={sigCanvas}
                                penColor='black'
                                canvasProps={{ height: 80, width: 320, className: 'sigCanvas' }}

                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-4 col-md-4 my-auto col-12'>
                          <div className='mt-0 mt-md-3 mb-4 mb-md-0'>
                            <button
                              type='button'
                              className='btn btn-primary fw-bold px-10 py-3'
                              onClick={clearSignature}
                            >
                              {t("Clear")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 text-end'>
                      <Link to='' className=''>
                        <button
                          type='button'
                          className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                        >
                          {t("Cancel")}
                        </button>
                      </Link>
                      <button
                        type='button'
                        className='btn btn-primary fw-bold px-10 py-3'

                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}