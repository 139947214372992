import React, {useState, useEffect} from 'react'
import MaintenanceIcon from '../../../../assets/images/Maintenance_Active.png'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { getUserRole } from '../../../../constants/generics/userCheck'
import { useTranslation } from 'react-i18next';

export function RecentOnHoldApprovalCard({approval}:any) {
  
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    const { t } = useTranslation();

    return (
        <div>
            <div className='card mt-4'>
                <div className='card-body px-5 py-5 px-md-8 py-md-7'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='mb-7'>
                                <img src={ MaintenanceIcon } className='img-fluid me-5 d-inline' />
                                <span className='card-title'>{t("On-Hold Workorder Approval")}</span>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mb-2">
                                    <p className="mb-0 text-muted">{t("Issue")}</p>
                                    <p className="mb-0 text-dark fcs-dashboard-request fcs-unit-number">{approval?.workOrderData?.[0]?.subCategory}</p>
                                </div>
                                <div className="col-sm-6 pe-0">
                                    <p className="mb-0 text-muted">{t("Unit Number")}</p>
                                    <p className="mb-0 text-dark font-12 fcs-unit-number">{approval?.propertyCode}</p>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-sm-12 pe-0">
                                    <p className="mb-0 text-muted">Unit Number</p>
                                    <p className="mb-0 text-dark font-12 fcs-unit-number">{approval?.propertyCode}</p>
                                </div>
                                <div className="col-sm-6 pe-0">
                                    <p className="mb-0 text-muted">Date/Time</p>
                                    <p className="mb-0 text-dark font-12 fcs-unit-number">
                                        {moment(recent.date).format("DD-MM-YYYY")} {' '}
                                        {recent.issueCategory === "Clearance" ? recent?.objectDetails?.referenceDetails?.time_slot : formatTimeMeridiem(recent.time)}

                                    </p>
                                </div>
                            </div> */}
                            {/* <h6 className='font-14 fw-normal text-black'>
                                Action Required
                            </h6> */}
                        </div>
                        <div className='col-lg-4 text-end mt-auto'>
                            <Link
                                to={{
                                    'pathname': '/fmteam-viewDetails/onhold',
                                    'state': {'approval': approval},
                                }}
                                className='btn btn-primary rounded w-100 py-2 mt-13'
                            >
                            {t("View")}
                            </Link>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    )
}