import React, { FC, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export function FeedbackDetails({workOrder}:any) {
    const { t } = useTranslation();

    if(workOrder?.rateUsData?.workmanship){
        const rates = [t('Very Satisfied'), t('Satisfied'), t('Not Satisfied')]
        const check1 = workOrder?.rateUsData?.workmanship
        const check2 = workOrder?.rateUsData?.punctuality
        const check3 = workOrder?.rateUsData?.quality_of_work
        return (
            <div className="col-12">
                <div className="mt-7 feed-design" id="feedback-popup">
                    <div className="card">
                        <div className="card-body">
                            <h5  className="mb-5">{t("Feedback")}</h5>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="satis-wdw">
                                        <label className="fw-normal text-black font-12 mb-1"> {t("Workmanship")} </label>
                                        
                                        <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                                            {rates.map((el:any, index:number)=>
                                            <>
                                                <input type="radio" className="btn-check" value={index} name="check1" id="verysatis" disabled defaultChecked={check1 === index+1}/>
                                                <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis">
                                                    <span className="very-satis-ioc comm-icon me-2"></span>
                                                    {el}
                                                </label>
                                            </>
                                            )}
                                        </div>
                                        <label className="fw-normal text-black font-12 mb-1"> {t("Punctuality")} </label>
                                        
                                        <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                                            {rates.map((el:any, index:number)=>
                                            <>
                                                <input type="radio" className="btn-check" value={index} name="check2" id="verysatis" disabled defaultChecked={check2 === index+1}/>
                                                <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis">
                                                    <span className="very-satis-ioc comm-icon me-2"></span>
                                                    {el}
                                                </label>
                                            </>
                                            )}
                                        </div>
                                        <label className="fw-normal text-black font-12 mb-1"> {t("Quality of work")} </label>
                                        
                                        <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                                            {rates.map((el:any, index:number)=>
                                            <>
                                                <input type="radio" className="btn-check" value={index} name="check3" id="verysatis" disabled defaultChecked={check3 === index+1}/>
                                                <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis">
                                                    <span className="very-satis-ioc comm-icon me-2"></span>
                                                    {el}
                                                </label>
                                            </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="">
                                        <label className="fw-normal text-black font-12 mb-1"> {t("Comment")} </label>
                                        <textarea
                                            className='form-control fw-bold font-12 form-control-solid bg-light border-0'
                                            data-kt-autosize='true'
                                            rows={12}
                                            disabled
                                            value={workOrder?.rateUsData?.additional_feedback}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return<></>
    }
}