import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import Loader from '../../../../components/Loader';
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    plugins: {
        cutout:80,
        legend: {
            display: true,
            position: 'right' as const,
            labels: {
                color: 'rgb(0, 44, 106)',
                lineDash: [12,12],
                boxWidth: 19,
                boxHeight: 18,
                padding: 30,
                usePointStyle: true,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                }
            }
        },
    },

    maintainAspectRatio: true,
};

export const UserDetailsChart:React.FC<any> = ({ workOrderList }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<any>(true);
    const [error, setError] = useState<any>('');
    const [dataCounts, setDataCounts] = useState<any>({
        open: 0,
        closed: 0,
        total: 0,
    });

    useEffect(()=>{
        const activeUserData = workOrderList;        
        setLoading(activeUserData.loading)
        setError(activeUserData.error)
        const workOrderData = workOrderList?.response
        
        if(workOrderData && Array.isArray(workOrderData)){
            let openItemsCount = 0;
            let closedItemsCount = 0;
            workOrderData.forEach((item:any)=>{
                if(['Completed','Under observation'].includes(item.status))
                    closedItemsCount++
                else
                    openItemsCount++;
            })
            setDataCounts({
                open: openItemsCount,
                closed: closedItemsCount,
                total: openItemsCount + closedItemsCount,
            })
        }
    }, [workOrderList])

    const chartData = {
        labels: [`${dataCounts.closed} Closed`, `${dataCounts.open} Open`],
        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                data: [dataCounts.closed, dataCounts.open],
                backgroundColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderWidth: 1,
            },
        ]
    }
    
    return (
        <div className="row myteam_cards">
            {loading ?
                <div className="col-12">
                    {/* <Loader /> */}
                    <CustomLoader2 height={'166'}/>
                </div>
            : error !== '' ?
                    <div className="col-12">{error}</div>
                :
                <div className="col-12">
                    <div className="Doughnut-Chart text-center position-relative">
                        <Chart type='doughnut' data={chartData} options={options} width={200} height={200}/>
                        <div className="inner-text">
                            <h4 className="font-12 text-primary fw-boldest">
                                {dataCounts.total}<br/>
                                {t("Total")}<br/>
                            </h4>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}