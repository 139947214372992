import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import ClearenceImg from "../../../../assets/images/clearance-quick-link.svg";
import CorrectiveImg from "../../../../assets/images/corrective-quick-link.svg";
import PreventiveImg from "../../../../assets/images/preventive-quick-link.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'
import CalanderIcon from "../../../../assets/images/calendar-quick-link.svg";
import { useTranslation } from 'react-i18next';

export function WorkOrders() {
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h4 className="card-title"> {t("Work Orders")} </h4>
                                </div>
                                {/*<div className="col-md-6 col-6 text-right">*/}
                                    {/*<Link to={''} className="text-decoration-none view-all"> */}
                                        {/*<span> {t("View All")} </span>*/}
                                        {/*<img src={RightArrow} className="img-fluid" /> */}
                                    {/*</Link>*/}
                                {/*</div>*/}
                            </div>
                            <div className="quick-link-sec work-orders-sec">
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <Slider {...settings}>
                                                <div>
                                                    <Link to={'/facility-workorder/list/corrective/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={CorrectiveImg} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Corrective")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/facility-workorder/list/clearance/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={ClearenceImg} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Clearance")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/facility-workorder/list/preventive/open'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={PreventiveImg} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Preventive")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/fc-su-calendar/all'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={CalanderIcon} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> {t("Calendar")} </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/* <div>
                                                    <Link to={'/facility-workorder/list/open/clearence'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={ClearenceImg} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> Clearance </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/facility-workorder/list/open/corrective'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={CorrectiveImg} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> Corrective </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={'/facility-workorder/list/open/clearence'} className="text-decoration-none">
                                                        <div className="card mt-4">
                                                            <div className="card-body py-7 px-7">
                                                                <div className="row g-0 align-items-center">
                                                                    <div className="col-4">
                                                                        <img src={ClearenceImg} className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="card-title mb-0"> Clearance </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div> */}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}