import React, { useState, useEffect } from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link, useLocation } from 'react-router-dom'
import { getMarketPlaceFiltered } from '../../../../constants/axios/apis'
import moment from 'moment'

export function MarketPlace() {
  const [marketPlaceItems, setMarketPlaceItems] = useState<any>([])
  const location = useLocation();

  useEffect(() => {
    const payload = '?flag=1&approvalStatus=approved'
    getMarketPlaceFiltered(payload).then(({ data }: any) => {
      if (data && Array.isArray(data)) {
        const futureEvents = data.filter((item: any) => (moment().diff(item?.end_date) < 0))
        setMarketPlaceItems(futureEvents)
      } else {
        setMarketPlaceItems([])
      }
    })
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  }

  let content = marketPlaceItems?.map((item: any) => (
    <div key={item.id}>
      <Link
        to={{
          pathname: '/marketPlace/marketPlaceDetailView',
          state: {
            'marketPlaceID': item.id,
            'prevPath': location.pathname,
            'redeem': false
          }
        }}
      >
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-4 col-lg-3 my-auto col-12'>
                <div className='tenant-dashboard-marketplace'>
                  {/* <img src={item.banner} className='img-fluid position-relative' /> */}
                  <img src={item?.image?.length && Array.isArray(item?.image) ? item?.image[0] : ''} alt="offer-img-01" className="img-fluid position-relative" />
                </div>
              </div>
              <div className='col-md-8 col-lg-9 col-12'>
                <div className='d-flex flex-nowrap justify-content-between fdrr-rtl'>
                  <h4 className='card-title mb-3'> {item?.name} </h4>
                  {/* <span className='count'> 2 For 1 </span> */}
                </div>
                <h6 className='sub-title'>{item?.building_name}</h6>
                <h6 className='category mb-1'>{item?.category_name}</h6>
                <div className='d-flex flex-nowrap justify-content-between fdrr-rtl align-items-center'>
                  <span className='sch-time'>
                    <span className='bullet bullet-dot bg-success h-10px w-10px translate-middle'></span>
                    {moment(item?.start_time, 'HH:mm').format('HH:mmA') + '-' + moment(item?.end_time, 'HH:mm').format('HH:mmA')}

                  </span>
                  View Detail{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <h4 className='card-title'> Market Place </h4>
                </div>
                <div className="offset-sm-3 col-md-3 col-6 text-end">
                  <Link to={'/marketPlace/offers/all'} className="text-decoration-none view-all">
                    <span> View All </span>
                    <img src={RightArrow} alt="view-all" className="img-fluid" />
                  </Link>
                </div>
              </div>
              <div className='market-place-sec'>
                <div className='row'>
                  <div className='col-md-12 col-12 col-lg-12'>
                    <div className='row'>
                      <div className='col-12'>
                        <Slider {...settings}>
                          {marketPlaceItems ? content : 'Loading...'}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
