import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ImageViewerWithPopup } from '../../../fc-workorder/components/ImageViewerWithPopup/ImageViewerWithPopup'
import { formatDate } from '../../../../constants/generics/dateTimeFormater'
import { currencyFormater } from '../../../../constants/generics/customActios'

export function BrokerInvoiceDetails({ serviceDetail }: any) {
    const { t } = useTranslation()
    let chequeImg = ''
    if (
        serviceDetail?.brokerInvoice?.invoice_file &&
        serviceDetail?.brokerInvoice?.invoice_file !== ''
    )
        chequeImg = serviceDetail?.brokerInvoice?.invoice_file
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-7 serv-data'>
                        <div className='card-body px-8 py-6'>
                            <div className='row mb-7'>
                                <div className='col-sm-6 card-title font-16 text-black'>
                                    <span className='text-black'>{t('Broker Invoice Details')}</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t('Tenant Name')}</h6>
                                    <span className='text-black fw-bold font-15'>
                                        {serviceDetail?.tenantName ? serviceDetail?.tenantName : '-'}
                                    </span>
                                </div>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'>
                                        {' '}
                                        {t('Eligible Commission')}{' '}
                                    </h6>
                                    <span className='text-black fw-bold font-15'>
                                        {serviceDetail?.brokerInvoice?.amount
                                            ? serviceDetail?.brokerInvoice?.amount
                                            : '-'}
                                    </span>
                                </div>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'>{t('Invoice')} </h6>
                                    <ImageViewerWithPopup
                                        imageFile={chequeImg}
                                        noImage={chequeImg === ''}
                                        imgID={'cheque'}
                                    />
                                </div>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'>
                                        {t('Supporting Documents')}
                                    </h6>
                                    <a
                                        className='btn btn-primary w-100 font-12 px-3 py-3'
                                        // className='text-decoration-underline text-primary fw-bolder view-all font-14'
                                        href={serviceDetail?.brokerInvoice?.supp_document}
                                        target='_blank'
                                    >
                                        {t('Download')}
                                    </a>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 col-md-3 col-lg-3 col-6 mb-10'>
                                    <h6 className='text-theme-gray mb-5 fw-normal font-13'> {t('Description')}</h6>
                                    <span className='text-black fw-bold font-15'>
                                        {serviceDetail?.brokerInvoice?.comments
                                            ? serviceDetail?.brokerInvoice?.comments
                                            : '-'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
