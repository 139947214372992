import React, { useState } from "react";

const MultipleImageUpload = () => {
  const [file, setFile] = useState([] as any);

  function uploadSingleFile(e:any) {
    let ImagesArray = Object.entries(e.target.files).map((e:any) =>
      URL.createObjectURL(e[1])
    );
    console.log(ImagesArray);
    setFile([...file, ...ImagesArray]);
    console.log("file", file);
  }

  // function upload(e:any) {
  //   e.preventDefault();
  //   console.log(file);
  // }

  function deleteFile(e:any) {
    const s = file.filter((item:any, index:any) => index !== e);
    setFile(s);
    console.log(s);
  }

  return (
    <>
      <div className="border-dashed-black px-4 pb-4 pt-3 mt-0 d-flex flex-wrap">
        {file.length > 0 &&
          file.map((item:any, index:any) => {
            return (
              <div className="me-3 position-relative previewimg" key={item}>
                <img src={item} alt="image" className="rounded"/>
                <span className="position-absolute top-0 right-0" onClick={() => deleteFile(index)}>
                  X
                </span>
              </div>
            );
          })}
          <div className="upload-btn-wrapper bg-white px-2 py-1">
            <span className="material-icons font-44">+</span>
            <input
            type="file"
            disabled={file.length === 5}
            className="community_images"
            onChange={uploadSingleFile}
            multiple
          />
          </div>
          
      </div>

      {/* <button
        type="button"
        className="btn btn-primary btn-block"
        onClick={upload}
      >
        Upload
      </button> */}
  </>
  );
};

export default MultipleImageUpload;