import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import { getApprovals } from '../../../../constants/axios/apis';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { formatDateTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';

export function MerchantApprovals({ userID , parentLoading=false}: any) {
    const location = useLocation();

    const { t } = useTranslation()
    const pageSize = 5
    const [approvalList, setApprovalList] = useState([]);
    const [loading, setLoading] = useState<any>(true)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [totalCount, setTotalCount] = useState<any>(0)
    const [activeButton, setActiveButton] = useState<any>('pending')

    useEffect(() => {
        setLoading(true)
        const newPayload = `?created_by=${userID}&currentPage=${currentPage}&pageSize=${pageSize}&date_sort=1&type=5&status=${activeButton}`
        getApprovals(newPayload).then((response: any) => {
            if (Array.isArray(response.data) && response.data.length > 0) {
                setApprovalList(response.data)
                setTotalCount(response.dataCount)
            } else {
                setApprovalList([])
                setTotalCount(0)
            }
            setLoading(false)
        }).catch((e) => setLoading(false))
    }, [currentPage, activeButton, userID])

    useEffect(() => {
        setCurrentPage(1)
    }, [activeButton])

    // if (!loading && (approvalList === undefined || approvalList?.length === 0)) {
    //     return <NoDataFound1 />
    // }

    return (
        <div className={`com-card-section ${parentLoading?'d-none':''}`}>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3 '>
                        <div className='card-body px-4 py-4'>
                            <div className="col-12">
                                <div className='d-flex overflow-auto h-55px px-4 mb-4'>
                                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                        <li className='nav-item'>
                                            <a onClick={() => setActiveButton('pending')}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'pending' && 'active')}>
                                                {t("Pending")}
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a onClick={() => setActiveButton('approve')}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'approve' && 'active')}>
                                                {t("Approved")}
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a onClick={() => setActiveButton('rejected')}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'rejected' && 'active')}>
                                                {t("Rejected")}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {!loading && (approvalList === undefined || approvalList?.length === 0) ?
                                <NoDataFound1 /> :
                                <>
                                    <div className='rec-payment-act-sec'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='table-responsive'>
                                                    <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th className=''> {t('No.')} </th>
                                                                <th className=''> {t('Event Name')}</th>
                                                                <th className=''> {t('Event Start Date/Time')} </th>
                                                                <th className=''> {t('Event End Date/Time')} </th>
                                                                {/* <th className=''> {t('Time')}</th> */}
                                                                <th className=''> {t('Action')} </th>
                                                            </tr>
                                                        </thead>
                                                        {loading ? (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7}>
                                                                        <CustomLoader2 height={'229'}/>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                {approvalList?.map((data: any, index: any) => (
                                                                    <tr key={`${data.id}${index}`}>
                                                                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                                        <td>{data?.other_data_json?.event_name ? data.other_data_json.event_name : '-'}</td>
                                                                        <td>{data?.other_data_json?.event_start_date ? formatDateTimeMeridiem(data?.other_data_json?.event_start_date + ' ' + data?.other_data_json?.event_start_time) : '-'}</td>
                                                                        <td>{data?.other_data_json?.event_end_date ? formatDateTimeMeridiem(data?.other_data_json?.event_end_date + ' ' + data?.other_data_json?.event_end_time) : '-'}</td>
                                                                        <td>
                                                                            <Link
                                                                                to={{ pathname: '/mta-approvals/view', state: { 'approvalData': data } }}
                                                                                className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
                                                                            >
                                                                                <span className='view-option text-theme fw-bolder'>{t('View')}</span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                }
                                                            </tbody>
                                                        )}
                                                    </table>
                                                    <CompactPagination
                                                        pageSize={pageSize}
                                                        totalCount={parseInt(totalCount)}
                                                        defaultPage={currentPage}
                                                        onPageChange={setCurrentPage}
                                                        loading={loading}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
