import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { SocialMediaListing } from './marketing-social-media/SocialMedialListing'
import { EditSocialMedia } from './marketing-social-media/edit-social-campaign/EditSocialMedia'
import { AddSocialMedia } from './marketing-social-media/create-social-campaign/addSocialMedia'
import { FacebookCampaign } from './marketing-social-media/FacebookCampaignList'
import { TwitterCampaign } from './marketing-social-media/TwitterCampaignList'
import { InstagramCampaign } from './marketing-social-media/InstagramCampaignList'
import { LinkedinCampaign } from './marketing-social-media/LinkedinCampaignList'

const PMSocialMedia: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/social-media/list'>
                            <SocialMediaListing/>
                        </Route>
                        <Route path='/social-media/add'>
                            <AddSocialMedia/>
                        </Route>  
                        <Route path='/social-media/edit'>
                            <EditSocialMedia/>
                        </Route>
                        <Route path='/social-media/facebook-campaign'>
                            <FacebookCampaign/>
                        </Route>
                        <Route path='/social-media/twitter-campaign'>
                            <TwitterCampaign/>
                        </Route>
                        <Route path='/social-media/instagram-campaign'>
                            <InstagramCampaign/>
                        </Route>
                        <Route path='/social-media/linkedin-campaign'>
                            <LinkedinCampaign/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMSocialMediaWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMSocialMedia />
        </>
    )
};

export { PMSocialMediaWrapper }

