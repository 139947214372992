import React, { FC, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
// import {RequestSuccessfulPopup} from '../../../SuccessfulPopup/RequestSuccessfulPopup';
import { CustomRequestSuccessfulPopup } from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import PhoneInput from 'react-phone-input-2';

import { useSelector } from 'react-redux';
import { addNewTechnician, getUserBuildingCommunityList } from '../../../../utils/makeRequest';
import { IState } from '../../../../utils/interface';
import Loader from '../../../../components/Loader';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRequestPost from '../../../../hooks/useRequestPost'

import {
    createContractorUser,
    getFacilityCompanyData,
    getFacilityUserType,
    updateTeamMember,
} from '../../../../constants/axios/apis'
import { MultiSelect } from 'react-multi-select-component';
import { getUserRole } from '../../../../constants/generics/userCheck';
import { useTranslation } from 'react-i18next';

export function EditMember() {
    const { t } = useTranslation();
        
    const validationSchemaFC = Yup.object({
        firstNameField: Yup.string()
            .required(t('First Name is required!'))
            .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        lastNameField: Yup.string()
            .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .max(25, t('Maximum 25 characters!')),
        emirateIDField: Yup.string()
            .required(t('Emirates ID is required!'))
            .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
            .matches( /^(\d+-?)+\d+$/, t("Emirates ID is not valid!"))
            .min(15, t('Minimum length is 15!'))
            .max(25, t('Maximum 25 characters!')),
        mobileNumberField: Yup.string()
            .min(4, t('Mobile No should be greater then 4 digits!'))
            .max(16, t('Mobile No should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
            .required(t('Mobile No is required!')),
        // company_id: Yup.string()
        //     .required('Company Name is required!'),
        property_id: Yup.array().min(1, t('Property is required!')),
        building_id: Yup.array().min(1, t('Building is required!')),
        user_type: Yup.string().required(t('User Type is required!')),
        designationField: Yup.string()
            .required(t('Designation is required!'))
            .matches(/^[^\s].*/, t('Designation cannot start with a blankspace!'))
            .matches(/^[a-z0-9]+$/i, t("Special characters and blankspace are not allowed for Designation!"))
            .min(4, t('Minimum length is 4!'))
            .max(25, t('Maximum 25 characters!')),
    });

    const validationSchemaFCS = Yup.object({
        firstNameField: Yup.string()
            .required(t('First Name is required!'))
            .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        lastNameField: Yup.string()
            .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .max(25, t('Maximum 25 characters!')),
        emirateIDField: Yup.string()
            .required(t('Emirates ID is required!'))
            .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
            .matches( /^(\d+-?)+\d+$/, t("Emirates ID is not valid!") )
            .min(15, t('Minimum length is 15!'))
            .max(25, t('Maximum 25 characters!')),
        mobileNumberField: Yup.string()
            .min(4, t('Mobile No should be greater then 4 digits!'))
            .max(16, t('Mobile No should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
            .required(t('Mobile No is required!')),
        // emailIDField: Yup.string()
        //     .required('Email ID is required!')
        //     .email('Enter a valid email!')
        //     .matches(/^[^\s].*/, 'Email Id cannot start with a blankspace!'),
        // company_id: Yup.string().required('Company Name is required!'),
        property_id: Yup.array().min(1, t('Property is required!')),
        building_id: Yup.array().min(1, t('Building is required!')),
        fmContractNumberField: Yup.string()
            .required(t('FM Contract Number is required!'))
            .matches(/^[^\s].*/, t('FM Contract Number cannot start with a blankspace!'))
            .max(25, t('Maximum 25 characters!'))
            .min(4, t('Minimum length is 4!')),
            // .matches(
            //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            //     "FM Contract Number is not valid" ),
            // property_id: Yup.string().required('Property is required'),
            // building_id: Yup.string().required('Building is required'),
        designationField: Yup.string()
            .required(t('Designation is required!'))
            .matches(/^[^\s].*/, t('Designation cannot start with a blankspace!'))
            .matches(/^[a-z0-9]+$/i, t("Special characters and blankspace are not allowed for Designation!"))
            .min(4, t('Minimum length is 4!'))
            .max(25, t('Maximum 25 characters!')),
    });

    const validationSchemaFCSS = Yup.object({
        firstNameField: Yup.string()
            .required(t('First Name is required!'))
            .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        lastNameField: Yup.string()
            .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .max(25, t('Maximum 25 characters!')),
        emirateIDField: Yup.string()
            .required(t('Emirates ID is required!'))
            .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
            .matches( /^(\d+-?)+\d+$/, t("Emirates ID is not valid!") )
            .min(15, t('Minimum length is 15!'))
            .max(25, t('Maximum 25 characters!')),
        mobileNumberField: Yup.string()
            .min(4, t('Mobile No should be greater then 4 digits!'))
            .max(16, t('Mobile No should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
            .required(t('Mobile No is required!')),
        // company_id: Yup.string()
        //     .required('Company Name is required!'),
        property_id: Yup.array().min(1, t('Property is required!')),
        building_id: Yup.array().min(1, t('Building is required!')),
        designationField: Yup.string()
            .required(t('Designation is required!'))
            .matches(/^[^\s].*/, t('Designation cannot start with a blankspace!'))
            .matches(/^[a-z0-9]+$/i, t("Special characters and blankspace are not allowed for Designation!"))
            .min(4, t('Minimum length is 4!'))
            .max(25, t('Maximum 25 characters!')),
    });


    const history = useHistory()

    let params: any = {};
    params = useLocation();
    const { user } = params.state || undefined


    let initBuildingID = new Array()
    let initCommunityID = new Array()
    user?.community?.forEach((item:any)=>{
        initCommunityID.push({label: item.community, value: `${item.community_id}`})
        if(Array.isArray(item.buildings)){
            item?.buildings?.forEach((buildingData:any)=>{
                initBuildingID.push({label: buildingData.building_name, value: `${buildingData.id}`})
            })
        }else{
            initBuildingID.push({label: item.buildings?.building_name, value: `${item.buildings?.id}`})
        }
    })

    // console.log(initBuildingID, initCommunityID, 'test');
    


    const buttonEl: any = useRef(null);
    const state: any = useSelector(state => state);
    const userId = state?.auth?.user?.data?.id;
    const user_role = getUserRole(state?.auth?.user?.data);

    const [companyList, setCompanyList] = useState<any>([])
    const [propertyList, setPropertyList] = useState<any>([])
    const [buildingList, setBuildingList] = useState<any>([])
    const [userTypeList, setUserTypeList] = useState<any>([])
    const [filteredBuildingList, setFilteredBuildingList] = useState<any>([])
    const [selectedProperties, setSelectedProperties] = useState(initCommunityID);
    const [selectedBuildings, setSelectedBuildings] = useState(initBuildingID);
    const [isSubmitting, setSubmitting] = useState(false)
    const [isSubmitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const [isLoading, setIsLoading] = useState<any>({
        // company: true,
        property: true,
        building: true,
        userType: true,
    })

    useEffect(() => {
        getFacilityCompanyData(userId).then(({ data }) => {
            setCompanyList((data && data.companies && Array.isArray(data.companies)) ? data.companies.map((item: any) => ({ id: `${item.company_id}`, name: item.company })) : [])
            setIsLoading((oState: any) => ({ ...oState, company: false }))
        }).catch((e) => { setIsLoading({ ...isLoading, company: false }) })

        getUserBuildingCommunityList(userId).then(({buildingListData, communityListData}) => {
            setPropertyList(communityListData)
            setBuildingList(buildingListData)
            setIsLoading((oState: any) => ({ ...oState, building: false, property: false }));
        }).catch((e)=>{setIsLoading({...isLoading, building:false, property:false })})

        getFacilityUserType().then(({ data }) => {
            setUserTypeList((data && Array.isArray(data)) ? data.map((item: any) => ({ id: `${item.id}`, name: item.name })) : [])
            setIsLoading((oState: any) => ({ ...oState, userType: false }))
        }).catch((e) => { setIsLoading({ ...isLoading, userType: false }) })
    }, [])

    const initialValuesFC = {
        firstNameField: user.first_name,
        lastNameField: user.last_name ? user.last_name : '',
        emirateIDField: user.emirates_id,
        mobileNumberField: user.mobile,
        emailIDField: user.email,
        companyName: user.companyName,
        property_id: initCommunityID,
        building_id: initBuildingID,
        designationField: user.user_designation ? user.user_designation : '',
        fmContractNumberField: '',  //Not needed
        user_type: user.user_type,
    }

    const initialValuesFCS = {
        firstNameField: user.first_name,
        lastNameField: user.last_name ? user.last_name : '',
        emirateIDField: user.emirates_id,
        mobileNumberField: user.mobile,
        emailIDField: user.email,
        companyName: user.companyName,
        property_id: initCommunityID,
        building_id: initBuildingID,
        designationField: user.user_designation ? user.user_designation : '',
        fmContractNumberField: user.FMContractNumber ? user.FMContractNumber : '',
        user_type: '',  //Not needed
    }

    const initialValuesFCSS = {
        firstNameField: user.first_name,
        lastNameField: user.last_name ? user.last_name : '',
        emirateIDField: user.emirates_id,
        mobileNumberField: user.mobile,
        emailIDField: user.email,
        companyName: user.companyName,
        property_id: initCommunityID,
        building_id: initBuildingID,
        designationField: user.user_designation ? user.user_designation : '',
        fmContractNumberField: '',  //Not needed
        user_type: '',  //Not needed
    }

    
    const onSubmit = (values: any) => {       
        setSubmitting(true)
        setSubmitError('')

        const formData = new FormData()
        formData.append("first_name", values.firstNameField.trim());
        formData.append("last_name", values.lastNameField.trim());
        // formData.append("email", values.emailIDField);
        formData.append("emirates_id", values.emirateIDField.trim());
        formData.append("phone", values.mobileNumberField);
        formData.append("designation", values.designationField.trim());
        // formData.append("company_id", values.company_id);
        if(user.user_role === 8)
            formData.append("user_type", values.user_type);
        if(user.user_role === 10)
            formData.append("fmContactNumber", values.fmContractNumberField);
        // formData.append("id_role", addRole);
        values?.building_id?.forEach((element: any, index: any) => {
            formData.append(`building_id[${index}]`, element);
        });

        // console.log(values);
        // console.log(user);
        setTimeout(() => {
            updateTeamMember(formData, user.id)
                .then((response: any) => {
                    setSubmitting(false)
                    if (response.errorCode === 1)
                        setSubmitError(response.errorDescription)
                    else if (response.errorCode === 0) {
                        formik.resetForm();
                        setSubmitting(false)
                        setSubmitted(true)
                        var ev = new Event('click', { bubbles: true });
                        buttonEl.current.dispatchEvent(ev);
                        setTimeout(() => {
                            if(user_role === 'FMT')
                                window.location.replace("/fm-myteam");
                            else if(user_role === 'FMSM')
                                window.location.replace("/fmsm-my-team/supervisor");
                            else if(user_role === 'FCS')
                                window.location.replace("/fc-su-myteam");
                            else
                                history.goBack()
                        }, 3000)
                    } else
                        setSubmitError(t("Some error occured. Please try again !"))
                })
                .catch((e) => {
                    // console.log(e, 'Error');
                    setSubmitting(false)
                    setSubmitError(t('Some error occured. Please try again !'))
                })
        }, 500)
    }

    const initialValues = user.user_role === 8 ? initialValuesFC
    : user.user_role === 9 ? initialValuesFCSS
    : user.user_role === 10 ? initialValuesFCS
    : initialValuesFC

    const validationSchema = user.user_role === 8 ? validationSchemaFC
        : user.user_role === 9 ? validationSchemaFCSS
        : user.user_role === 10 ? validationSchemaFCS
        : validationSchemaFC

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const onValueChange = (phoneNumber:any) => {
        // console.log(phoneNumber);
    	formik.setFieldValue('mobileNumberField', '+' + phoneNumber)
    	formik.setFieldTouched('mobileNumberField')
    };

    useEffect(() => {
        // console.log(selectedProperties, 'selectedProperties');
        // console.log(selectedBuildings, 'selectedBuildings');
        
        let selectedPropertIDList = Array.isArray(selectedProperties) ? selectedProperties.map((item: any) => item.value) : [];
        let selectedBuildingIDList = Array.isArray(selectedBuildings) ? selectedBuildings.map((item: any) => item.value) : [];

        if (selectedPropertIDList) {
            formik.setFieldValue('property_id', selectedPropertIDList)
            // formik.setFieldTouched('property_id', true)
        } else {
            formik.setFieldValue('property_id', [])
            // formik.setFieldTouched('property_id', false)
        }

        const filteredBuildingList = buildingList?.filter((item: any) => selectedPropertIDList.includes(item.communityId))
        setFilteredBuildingList(filteredBuildingList)
        // console.log(filteredBuildingList, 'filteredBuildingList');

        const updatedSelectedBuildingList = filteredBuildingList?.filter((item: any) => selectedBuildingIDList.includes(item.id))
        const updatedSelectedBuildingIDList = filteredBuildingList?.map((item: any) => item.id)
        if (updatedSelectedBuildingIDList) {
            formik.setFieldValue('building_id', updatedSelectedBuildingList)
            // formik.setFieldTouched('building_id', true)
        } else {
            formik.setFieldValue('building_id', [])
            // formik.setFieldTouched('building_id', false)
        }

        const formatedSelectedBuildingList = updatedSelectedBuildingList?.map((item: any) => ({ label: item.name, value: item.id }))
        if(buildingList.length)
            setSelectedBuildings(formatedSelectedBuildingList)
        // }, [formik.values.property_id])
    }, [selectedProperties, buildingList])

    useEffect(() => {
        let selectedBuildingIDList = Array.isArray(selectedBuildings) ? selectedBuildings.map((item: any) => item.value) : [];
        if (selectedBuildingIDList) {
            formik.setFieldValue('building_id', selectedBuildingIDList)
            // formik.setFieldTouched('building_id', true)
        } else {
            formik.setFieldValue('building_id', [])
            // formik.setFieldTouched('building_id', false)
        }
        // console.log(selectedBuildingIDList, 'selectedBuildingIDList');
    }, [selectedBuildings])

    const isError = 
        // companyList?.length === 0 ? 'No company data found.'
         propertyList?.length === 0 ? (t('No property data!'))
        : buildingList?.length === 0 ? (t('No building data found!'))
        : userTypeList?.length === 0 ? (t('No user-type data found!'))
        : '';
    const isLoadingData = ( isLoading.property || isLoading.building || isLoading.userType)
    // const isLoadingData = (isLoading.company || isLoading.property || isLoading.building || isLoading.userType)
    const propertyOptions = propertyList.map((item: any) => ({ label: item.name, value: item.id }))
    const buildingOptions = filteredBuildingList.map((item: any) => ({ label: item.name, value: item.id }))

    
    

    return (
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>

                <div className="com-card-section">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="card-title font-18 text-black mb-6">
                                {/* <Link to={prevPath} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link> */}
                                <span onClick={() => {history.goBack()}} style={{cursor:'pointer'}}><img src={LeftArrow} className="img-fluid me-5 left-arrow-back" /></span>
                                {t("Edit Member")}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body px-8 py-6">
                                    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                                        {isLoadingData ? (
                                            <div className="col-12 d-flex flex-column justify-content-center mt-5">
                                                <Loader />
                                            </div>
                                        ) : (isError !== '' && (
                                            <div className="col-12 mt-5">
                                                <div className="alert alert-danger mt-2 text-center w-100" role="alert">
                                                    {isError}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row form-add-design">
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("First Name")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('firstNameField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("First Name")}
                                                    />
                                                    {formik.touched.firstNameField && formik.errors.firstNameField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.firstNameField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Last Name")}
                                                        {/* <span className="text-danger"> * </span> */}
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('lastNameField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("Last Name")}
                                                    />
                                                    {formik.touched.lastNameField && formik.errors.lastNameField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.lastNameField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Emirates ID")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('emirateIDField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("Emirates ID")}
                                                    />
                                                    {formik.touched.emirateIDField && formik.errors.emirateIDField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.emirateIDField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Mobile Number")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <PhoneInput
                                                        country={'ae'}
                                                        preferredCountries={['ae']}
                                                        // onlyCountries={['ae']}
                                                        // priority={{ ae: 0, in: 1, us: 2 }}
                                                        value={formik.values.mobileNumberField}
                                                        onChange={onValueChange}
                                                        enableSearch={true}
                                                        enableAreaCodeStretch={true}
                                                        inputClass={"text-black"}
                                                        placeholder={t('Mobile Number')}
                                                    />
                                                    
                                                    {formik.touched.mobileNumberField && formik.errors.mobileNumberField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.mobileNumberField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Email ID")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('emailIDField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid bg-light text-muted"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-10">
                                                    <label className="form-label fw-bolder">{t("Company Name")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('companyName')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid bg-light text-muted"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-5 mb-md-7">
                                                    <label className='form-label fw-bolder'> {t("Property")} <span className="text-danger"> * </span> </label>
                                                    <MultiSelect
                                                        options={propertyOptions}
                                                        value={selectedProperties}
                                                        onChange={setSelectedProperties}
                                                        labelledBy="Select-property"
                                                        hasSelectAll={false}
                                                        // disabled={true}
                                                    />
                                                    {/* <div className='d-flex flex-column text-muted'>
                                                        <small className="text-danger">Read Only</small>
                                                    </div> */}
                                                    {formik.touched.property_id && formik.errors.property_id && (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.property_id}</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-2">
                                                    <label className='form-label fw-bolder'> {t("Building")} <span className="text-danger"> * </span> </label>
                                                    <MultiSelect
                                                        options={buildingOptions}
                                                        value={selectedBuildings}
                                                        onChange={setSelectedBuildings}
                                                        labelledBy="Select-Building"
                                                        hasSelectAll={false}
                                                        // disabled={true}
                                                    />
                                                    {/* <div className='d-flex flex-column text-muted'>
                                                        <small className="text-danger">Read Only</small>
                                                    </div> */}
                                                    {formik.touched.building_id && formik.errors.building_id && (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.building_id}</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder">{t("Designation")}
                                                        <span className="text-danger"> * </span>
                                                    </label>
                                                    <input
                                                        {...formik.getFieldProps('designationField')}
                                                        type="text"
                                                        className="form-control fw-bolder form-control-solid"
                                                        placeholder={t("Designation")}
                                                    />
                                                    {formik.touched.designationField && formik.errors.designationField ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.designationField}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            { user.user_role === 8 ?

                                                <div className="col-md-4 col-12">
                                                    <div className="mb-7">
                                                        <label className="form-label fw-bolder">{t("User Type")}
                                                            <span className="text-danger"> * </span>
                                                        </label>
                                                        <select
                                                            className="form-control form-select fw-bolder form-control-solid"
                                                            data-control="select2"
                                                            data-allow-clear="true"
                                                            onChange={formik.handleChange}
                                                            name='user_type'
                                                            value={formik.values.user_type}
                                                            onBlur={formik.handleBlur}
                                                        >
                                                            <option value="">Please Select</option>
                                                            {userTypeList?.map((item: any, index: number) =>
                                                            (<option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                            )
                                                            )}
                                                            {/* {userType ? userTypeOptions : ''} */}
                                                        </select>
                                                        {formik.touched.user_type && formik.errors.user_type ? (
                                                            <small className="text-danger">
                                                                {formik.errors.user_type}
                                                            </small>
                                                        ) : null}
                                                    </div>
                                                </div>

                                            : user.user_role === 10 ? 
                                                
                                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                    <div className="mb-2">
                                                        <label className="form-label fw-bolder">{t("FM Contract Number")} <span className="text-danger"> * </span></label>
                                                        <input
                                                            {...formik.getFieldProps('fmContractNumberField') }
                                                            type="text"
                                                            className="form-control fw-bolder form-control-solid"
                                                            placeholder={t("FM Contract Number")}
                                                        />
                                                        {formik.touched.fmContractNumberField && formik.errors.fmContractNumberField ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.fmContractNumberField}</small>
                                                            </div>
                                                        ): null }
                                                    </div>
                                                </div>
                                            
                                            :
                                                            ''
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-end d-flex flex-column align-items-end justify-content-end mt-5">
                            {isSubmitting && (
                                <Loader />
                            )}
                            {!isSubmitting && (
                                <div>
                                    <button
                                        onClick={() => { history.goBack() }}
                                        type='button' className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'>
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        onClick={formik.submitForm}
                                        type='submit'
                                        disabled={isSubmitted}
                                        className='btn btn-primary fw-bold px-10 py-3'>
                                        {t("Submit")}
                                    </button>
                                </div>
                            )}
                            {submitError && (
                                // <div style={{ width: 'fit-content' }} className="alert alert-danger mt-2" role="alert">
                                <div className="alert alert-danger w-100 text-center mt-5" role="alert">
                                    {submitError}
                                </div>
                            )}
                            <button ref={buttonEl} data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='button' className='invisible btn btn-primary fw-bold px-10 py-3'>
                                {t("Submit")}
                            </button>
                            {/* <RequestSuccessfulPopup /> */}
                            <CustomRequestSuccessfulPopup
                                successMessage={"Member Edited Successfully!"}
                                // href={'/fc-su-myteam'} //For FCS module
                                close={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}