import React, {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import {Report}from'./components/Report/Report';
import {Rent} from './components/Rent/Rent';
import {Redirect, Route, Switch} from 'react-router-dom'

import Loader from '../../components/Loader';
import { useSelector } from 'react-redux';
import { generateReportFC, getBuildingList } from '../../utils/makeRequest';
import { IState } from "../../utils/interface";
import { getUserRole } from "../../constants/generics/userCheck";
import moment from 'moment';
import { RentFMSM } from './components/Rent/RentFMSM';

export const ReportsPage: FC = () => {
    const state: any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    if (userToken === "undefined") {
        // token undefined
    }
    const user_role = getUserRole(state?.auth?.user?.data);

    const [reportType,setReportType] = useState<any>('');

    const [reportState, setReportState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    const [buildingListState, setBuildingListState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    
    const genReport = (params: any) => {
        generateReportFC(setReportState, userToken, params);
    }

    useEffect(() => {
        // const params = "?from_date=1970-01-01";
        // genReport(params);
        getBuildingList(setBuildingListState,userToken);
    }, []);

    
    return (
        <>
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>
                <Report
                    reportState={reportState}
                    setReportState={setReportState}
                    genReport={genReport}
                    buildingListState={buildingListState}
                    setReportType={setReportType} />
                {user_role === 'FMSM' ?
                    <RentFMSM reportState={reportState} setReportState={setReportState} reportType={reportType} />
                    :
                    <Rent reportState={reportState} setReportState={setReportState} reportType={reportType} />
                }
            </div>
        </div>
        </>
    );
}

const FMTeamReportsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}> Report </PageTitle>
        <ReportsPage />
        </>
    )
};

export {FMTeamReportsWrapper}

