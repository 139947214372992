import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {ActiveTab} from './sub_tab/ActiveTab'

export function Reminders() {
    const [tabactive, setTabactive] = useState("1");
    const activeTab = (i: any) => {
        setTabactive(i)
    }
    return (
        <>
        <div className='card'>
            <div className='card-body'>
                <div className='d-flex overflow-auto mb-4'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-2 flex-nowrap'>
                        <li className='nav-item'>
                            <Link to className={`${tabactive == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => activeTab(1)}>
                            Active Tasks
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to className={`${tabactive == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => activeTab(2)}>
                            In-Active Tasks
                            </Link>
                        </li>
                    </ul>
                </div>
                {  tabactive == '1' ? <ActiveTab /> : 
                tabactive == '2' ? <ActiveTab /> : '' }
            </div>
        </div>
        </>  
    )
}

