import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { getMyTeamChartData } from '../../../../constants/axios/apis'
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination'
import { useSelector } from 'react-redux'

export function MyTeamList({ }: any) {
    const { t } = useTranslation()
    const history = useHistory();

    const [loading, setLoading] = useState<any>(true)
    const [error, setError] = useState<any>('')
    const [teamList, setTeamList] = useState<any>([])
    const [totalCount, setTotalCount] = useState<any>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const pageSize = 10

    const state: any = useSelector(state => state);
    const user_id = state?.auth?.user?.data?.id;


    useEffect(() => {
        setLoading(true)
        // getPMmyTeam()
        const payload = '?user_role=MT&id_sort=1&currentPage=' + currentPage + '&pageSize=' + pageSize
        // const payload = '?user_role=MT&currentPage=' + currentPage + '&pageSize=' + pageSize + '&search=' + searchItem + (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')

        getMyTeamChartData(payload)
            .then((response: any) => {
                if (response?.errorCode === 0) {
                    if (response?.data?.teamList?.length) {
                        setTeamList(response?.data?.teamList)
                        setTotalCount(response.dataCount)
                    }
                } else {
                    setTeamList([])
                    setTotalCount(0)
                }
                setLoading(false)
            })
            .catch((e) => {
                setTeamList([])
                setTotalCount(0)
                setLoading(false)
            })
    }, [currentPage])

    return (
        <div className="com-card-section myunit-page">
            <div className="row flex-stack announcement-sec mb-6">
                <div className="col-md-6 col-12">
                    <h4 className="card-title font-18 text-black">
                        <span className='c-pointer' onClick={() => { history.goBack() }} >
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        <span className="font-18 text-black"> {t("Team Members")} </span>
                    </h4>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body p-0'>
                            <div className='rec-payment-act-sec'>

                                <div className='row'>
                                    <div className='col-md-12 col-12'>
                                        <div className='data_table'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {loading ?
                                                        <CustomLoader2 />
                                                        :
                                                        teamList?.length > 0 ?
                                                            <div className='table-responsive'>
                                                                <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className=''> {t("No")} </th>
                                                                            <th className=''> {t("Name")} </th>
                                                                            <th className=''> {t("Email")} </th>
                                                                            <th className=''> {t("Contact")} </th>
                                                                            <th className=''> {t("Status")} </th>
                                                                            <th className=''> {t("Action")} </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            teamList?.map((data: any, index: any) => (
                                                                                <tr key={index}>
                                                                                    <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                                                    <td>{data.name}</td>
                                                                                    <td>{data.email}</td>
                                                                                    <td>{data.mobile}</td>
                                                                                    <td className={ data?.status === 0 ? 'text-danger' : 'text-success' } >
                                                                                        { data?.status === 0 ? (t('Inactive')) : (t('Active'))}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Link
                                                                                            to={{ pathname: '/mta-myteam/view', state: { member_id: data.user_id } }}
                                                                                            className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
                                                                                        >
                                                                                            <span className='view-option text-theme fw-bolder'>{t("View")}</span>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <CompactPagination
                                                                    pageSize={pageSize}
                                                                    totalCount={parseInt(totalCount)}
                                                                    defaultPage={currentPage}
                                                                    onPageChange={setCurrentPage}
                                                                    loading={loading}
                                                                />
                                                            </div>
                                                            : <NoDataFound1 />

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
