import React, { useState, useEffect } from 'react'

export function QualifyModal() {
    return (
        <>
            <div className="modal fade" id="quality_modal">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h6 className="modal-title text-white font-16 font-weight-semibold">Do you want to convert this lead as an opportunity ?</h6>
                            {/* <button type="button" className="close text-warning bg-white rounded-circle" data-bs-dismiss="modal">×</button> */}
                        </div>
                        <div className="modal-body text-center">
                            <button type='button'  className="btn btn-outline-primary text-uppercase me-3" data-bs-dismiss="modal">Cancel</button>
                            <button type='button' className="btn btn-primary px-10 text-uppercase" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
