import React, { useState, useEffect } from 'react';
import Credit from "../../../../assets/images/pm-credit-card.svg";
import Bank from "../../../../assets/images/pm-bank.svg";
import Check from "../../../../assets/images/pm-cheque.svg";
import { useTranslation } from 'react-i18next'

export function Payment() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 pt-8 pb-3">
                            <div className="row">
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{t("Rent")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">12-11-2021</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">45,000 {t("AED")}</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt"><img src={Credit} className="img-fluid me-3" /> {t("Credit Card")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Cleared")}</h3>
                                                        </div>
                                                        <div className="">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{t("Service Charges")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">12-11-2021</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">45,000 {t("AED")}</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt"><img src={Bank} className="img-fluid me-3" /> {t("Credit Card")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Cleared")}</h3>
                                                        </div>
                                                        <div className="">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <div className="card shadow-sm payment-card-sec">
                                        <div className="card-body px-8 py-6">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6 col-12 mb-xl-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">{t("Other Changes")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Date")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">12-11-2021</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Amount")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt">45,000 {t("AED")}</h3>
                                                </div>
                                                <div className="col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                        {t("Payment Type")}
                                                    </label>
                                                    <h3 className="mb-0 payment-txt"><img src={Check} className="img-fluid me-3" /> {t("Credit Card")}</h3>
                                                </div>
                                                <div className="col-xl-2 col-md-3 col-sm-6 col-12 mb-md-0 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <label className="font-14 fw-bold mb-3 text-theme-gray">
                                                                {t("Status")}
                                                            </label>
                                                            <h3 className="mb-0 payment-txt">{t("Cleared")}</h3>
                                                        </div>
                                                        <div className="">
                                                            <i className="bi bi-chevron-down text-black"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}