import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {getMoveoutList} from '../../../../../constants/axios/apis'
import {useTranslation} from 'react-i18next'
import {CustomLoader2, NoDataFound1} from '../../../../../constants/generics/customActios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../constants'
import {CompactPagination} from '../../../../../components/partials/content/CompactPagination/CompactPagination'

export function OpenRequest({searchItem, setSearchItem}: any) {
  const {t} = useTranslation()
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)
  const pageSize = 10
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalRowCount, setTotalRowCount] = useState<any>(0)
  const [rowList, setRowList] = useState<any>([])

  useEffect(() => {
    setCurrentPage(1)
  }, [currentBuilding, searchItem])

  useEffect(() => {
    setLoading(true)
    // let newPayload = '?currentPage=' + currentPage + '&pageSize=' + pageSize + '&status=open'
    let newPayload =
      '?currentPage=' +
      currentPage +
      '&pageSize=' +
      pageSize +
      '&status=open&id_sort=1' +
      '&search=' +
      searchItem
    newPayload = currentBuilding
      ? newPayload + '&building_id=' + currentBuilding
      : newPayload + '&building_id=0'
    // newPayload += currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0'
    // getMoveoutList(newPayload).then((response: any) => {
    getMoveoutList(newPayload).then((response: any) => {
      if (response?.data && Array.isArray(response.data) && response.data.length > 0) {
        setRowList(response.data)
        setTotalRowCount(response.dataCount)
      } else {
        setRowList([])
        // setCurrentPage(1)
        setTotalRowCount(0)
      }
      setLoading(false)
    })
    // .catch((e)=>setLoading(false))
  }, [currentPage, currentBuilding, searchItem])

  if (!loading && (rowList === undefined || rowList?.length === 0)) {
    return <NoDataFound1 />
  }

  const rowData = rowList?.map((data: any, index: any) => (
    <tr key={data.id}>
      {/* <td>{(currentPage - 1) * pageSize + index + 1}</td> */}
      {/* <td>{index + 1}</td> */}
      <td>{data?.ticketNo ? data.ticketNo : '-'}</td>
      <td>{data?.serviceCategory ? data.serviceCategory : '-'}</td>
      <td>{data?.tenantName ? data.tenantName : '-'}</td>
      <td>{data?.propertyCode ? data.propertyCode : '-'}</td>
      {/* <td>{data.status}</td>             */}
      <td>
        <Link
          to={{
            pathname: '/finance-moveout-requests/view',
            state: {requestDetail: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body p-5'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='rec-payment-act-sec'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='table-responsive'>
                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                              <thead>
                                <tr>
                                  {/* <th className=''> {t('No')} </th> */}
                                  <th className=''> {t('Ticket')}</th>
                                  <th className=''> {t('Service Type')} </th>
                                  <th className=''> {t('Tenant Name')} </th>
                                  <th className=''> {t('Unit')} </th>
                                  {/* <th className=''> {t("Status")} </th> */}
                                  <th className=''> {t('Action')} </th>
                                </tr>
                              </thead>
                              {loading ? (
                                <tbody>
                                  <tr>
                                    <td colSpan={6} className='text-center pt-10 pb-0'>
                                      <CustomLoader2 />
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>{rowList ? rowData : ''}</tbody>
                              )}
                            </table>
                          </div>
                          <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalRowCount)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
