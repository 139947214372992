import React, { FC, useEffect, useRef, useState, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import IssueImg from "../../../../../../assets/images/issue-img-one.png";
import UploadIcon from "../../../../../../assets/images/upload-ioc.png";
import Propertie from '../../../../assets/images/latest-one.png';
import Place from "../../../../assets/images/place.svg";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../constants';
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import { useTranslation } from 'react-i18next'
import { CustomLoader1, fileUploader, validateRequired } from '../../../../../constants/generics/customActios';
import { pmPropertyEditAddMediaFloor, pmPropertyEditDeleteMedia } from '../../../../../constants/axios/apis';
import * as PM from '../../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { Spinner } from 'react-bootstrap-v5';

const Step1: FC = () => {
    const dispatch = useDispatch()
    let proprtyDetails: any
    proprtyDetails = useSelector<RootState>(({ PM }) => PM.proprtydetails)
    let propertyEditData: any
    propertyEditData = useSelector<RootState>(({ General }) => General.propertyEditData)
    const intl = useIntl()
    const location = useLocation()
    const [menu, setMenu] = useState('')
    const { t } = useTranslation();

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    const [loading, setLoading] = useState(false)
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(propertyEditData?.step1?.floorPlan != null ? propertyEditData?.step1?.floorPlan?.name : null);
    const [uploadedDocument, setUploadedDocument] = useState<string | null>(propertyEditData?.step1?.document != null ? propertyEditData?.step1?.document?.name : null);

    const [available, setAvailable] = useState(propertyEditData?.step1?.available != null ? propertyEditData?.step1?.available : proprtyDetails?.data?.isPropertyAvailable)
    const [title, setTitle] = useState(propertyEditData?.step1?.title != null ? propertyEditData?.step1?.title : proprtyDetails?.data?.propretyName)
    const [propertyType, setPropertyType] = useState(propertyEditData?.step1?.propertyType != null ? propertyEditData?.step1?.propertyType : proprtyDetails?.data?.propertySearchType)
    const [bedRooms, setBedRooms] = useState(propertyEditData?.step1?.bedRooms != null ? propertyEditData?.step1?.bedRooms : proprtyDetails?.data?.bedrooms)
    const [bathRooms, setBathRooms] = useState(propertyEditData?.step1?.bathRooms != null ? propertyEditData?.step1?.bathRooms : proprtyDetails?.data?.bathrooms)
    const [parking, setParking] = useState(propertyEditData?.step1?.parking != null ? propertyEditData?.step1?.parking : proprtyDetails?.data?.isParkingAvailable)
    const [parkingSlots, setParkingSlots] = useState(propertyEditData?.step1?.parkingSlots != null ? propertyEditData?.step1?.parkingSlots : proprtyDetails?.data?.no_of_parking)
    const [size, setSize] = useState(propertyEditData?.step1?.size != null ? propertyEditData?.step1?.size : proprtyDetails?.data?.builtInArea)
    const [furnish, setFurnish] = useState(propertyEditData?.step1?.furnish != null ? propertyEditData?.step1?.furnish : proprtyDetails?.data?.furnish)
    const [facing, setFacing] = useState(propertyEditData?.step1?.facing != null ? propertyEditData?.step1?.facing : proprtyDetails?.data?.facing)
    const [desc, setDesc] = useState<any | null>(propertyEditData?.step1?.desc != null ? propertyEditData?.step1?.desc : proprtyDetails?.data?.propertyDescription)
    const [age, setAge] = useState<any | null>(propertyEditData?.step1?.age != null ? propertyEditData?.step1?.age : proprtyDetails?.data?.property_age)
    const [filelist, setFilelist] = useState<any>(proprtyDetails?.data?.floors);


    const [floorPlanError, setFloorPlanError] = useState<string[]>(['']);
    const [floorPlan, setFloorPlan] = useState<any | null>(propertyEditData?.step1?.floorPlan != null ? propertyEditData?.step1?.floorPlan : null);
    const [documentError, setDocumentError] = useState<string[]>(['']);
    const [document, setDocument] = useState<any | null>(propertyEditData?.step1?.document != null ? propertyEditData?.step1?.document : null);
    const [coverImage, setCoverImage] = useState<any | null>(proprtyDetails?.data?.coverImage);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputRefDocument = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<any>();

    const handleUpload = () => {
        inputRef.current?.click();
    };

    function deleteFile1(id: any) {
        setLoading(true)
        const payload = `/floor/${id}`;
        pmPropertyEditDeleteMedia(payload).then((data) => {
            setLoading(false)
            dispatch(PM.actions.proprtydetails(proprtyDetails?.data?.id))
            setFilelist(proprtyDetails?.data?.floors)
        })
    }
    function handleDisplayFileDetails(e: any) {
        setLoading(true)
        const id = `/${proprtyDetails?.data?.id}`;
        var FormData = require('form-data')
        var propertyEditDetailsAddImg = new FormData()
        propertyEditDetailsAddImg.append('image[]', e.target.files[0]);
        pmPropertyEditAddMediaFloor(propertyEditDetailsAddImg, id).then((data) => {
            setLoading(false)
            setUploadedFileName('')
            dispatch(PM.actions.proprtydetails(proprtyDetails?.data?.id))
            setFilelist(proprtyDetails?.data?.floors)
        })
    }
    const handleDisplayFileDocument = () => {
        inputRefDocument.current?.files &&
            setUploadedDocument(inputRefDocument.current.files[0].name);
        setFile(inputRefDocument.current?.files && URL.createObjectURL(inputRefDocument.current.files[0]));
        inputRefDocument.current?.files &&
            setDocument(inputRefDocument.current?.files[0])
        if (inputRefDocument.current?.files) {
            setDocumentError([]);
            fileUploader(inputRefDocument.current?.files[0], setDocumentError, "Image");
            validateRequired(inputRefDocument.current?.files[0], setDocumentError, "Image");
        }
    };

    useEffect(() => {
        setFilelist(proprtyDetails?.data?.floors)
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
            console.log(window.location.pathname)
        }
    })
    useEffect(() => {
        const step1Data = {
            available: available,
            title: title,
            propertyType: propertyType,
            bedRooms: bedRooms,
            bathRooms: bathRooms,
            parking: parking,
            size: size,
            furnish: furnish,
            facing: facing,
            floorPlan: floorPlan,
            document: document,
            desc: desc,
            age: age
        }
        dispatch(General.actions.propertyEditData({ step1: step1Data }));
    }, [available, title, propertyType, bedRooms, bathRooms, parking, size, furnish, facing, floorPlan, document, desc, age])

    return (
        <>
            <div className="com-card-section w-100 p-7">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row form-add-design">
                            <div className="col-md-12 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block mb-5">{t("Property Available for Rent")}</label>
                                    <div className="btn-group" id="property_available">
                                        <input className="form-check-input me-5" type="radio" name="radio" id="btnradio1" disabled checked={available === 1 ? true : false} onClick={() => setAvailable(1)} />
                                        <label className="form-check-label me-5 text-black" htmlFor="btnradio1"> {t("Available")} </label>

                                        <input className="form-check-input me-5" type="radio" name="radio" id="btnradio2" disabled checked={available === 1 ? false : true} onClick={() => setAvailable(0)} />
                                        <label className="form-check-label text-black" htmlFor="btnradio2"> {t("Not Available")} </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10 position-relative">
                                    <label className="form-label fw-bolder">{t("Title")} </label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Title")} value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Property Type")}
                                        {/* <span className="text-danger"> * </span> */}
                                    </label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={propertyType == 1 ? t("Residential") : t("Commercial")} disabled />
                                    {/* <select className="form-control form-select fw-bolder form-control-solid" disabled data-control="select2" data-allow-clear="true" value={propertyType} onChange={(e) => (setPropertyType(e.target.value))}>
                                        <option selected disabled>{t("Please Select")}</option>
                                        <option value={1}>{t("Residential")}</option>
                                        <option value={2}>{t("Commercial")}</option>
                                    </select> */}
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("BHK Type")}
                                    </label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={bedRooms} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Bath Room")}
                                        {/* <span className="text-danger"> * </span> */}
                                    </label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={bathRooms} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Parking")}</label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={parking == 'Y' ? 'Yes' : 'No'} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Parking")} Slots</label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={parkingSlots} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Property Size")}
                                        {/* <span className="text-danger"> * </span> */}
                                    </label>
                                    <input type="text" disabled className="form-control fw-bolder form-control-solid" placeholder={t("Sqmt")}
                                        onKeyPress={(event) => {
                                            if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        value={size} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Furnishing")}</label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={furnish} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Facing")}</label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={facing} disabled />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Property Age")}</label>
                                    <input type="text" className="form-control fw-bolder form-control-solid" value={age} disabled />
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="mb-3">
                                    <label className="form-label">{t("Floor Plan")}</label>
                                    <div className="attachment-div">
                                        <label htmlFor="attachment">{uploadedFileName ? uploadedFileName : t("Upload")}</label>
                                        {loading && <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true" />}    <input type="file" id="attachment" className="form-control fw-bolder form-control-solid" ref={inputRef} onChange={handleDisplayFileDetails} />
                                    </div>
                                    {filelist?.length > 0 &&
                                        <div className="position-relatives">
                                            {filelist.map((item: any, index: any) => {
                                                return (
                                                    <div className="upload-image" key={item}>
                                                        <img src={item?.floorImage} alt="" />
                                                        <div className="close-icon" onClick={() => deleteFile1(item?.id)}>
                                                            &times;
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                    <div className='d-flex flex-column text-danger'>
                                        {floorPlanError.map((error, index) => {
                                            if (index === 0)
                                                return (
                                                    <small className="text-danger">{error}</small>
                                                )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="mb-3">
                                    <label className="form-label">{t("coverImage")}</label>
                                    <div className="attachment-div">
                                        <label htmlFor="attachment1">{uploadedDocument ? uploadedDocument : t("Upload")}</label>
                                        <input type="file" id="attachment1" className="form-control fw-bolder form-control-solid" ref={inputRefDocument} onChange={handleDisplayFileDocument} />
                                    </div>
                                    <div className="upload-image" key={1}>
                                        <img src={file != null ? file : coverImage} alt="" />
                                    </div>
                                    <div className='d-flex flex-column text-danger'>
                                        {documentError.map((error, index) => {
                                            if (index === 0)
                                                return (
                                                    <small className="text-danger">{error}</small>
                                                )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder">{t("Property Description")}</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={desc}
                                        onInit={(editor: any) => {
                                            const data = editor.getData();
                                            // console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            // console.log({ event, editor, data });
                                            setDesc(data)
                                        }}
                                        onBlur={(event: any, editor: any) => {
                                            // console.log('Blur.', editor);
                                        }}
                                        onFocus={(event: any, editor: any) => {
                                            // console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Step1 }
