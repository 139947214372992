import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export function PreviewContacts() {
    return (
        <>
             <div className="row align-items-center mb-5 community">
                <div className="col-md">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link>Contact Details
                    </h5>
                </div>
                <div className="col-auto">
                   
                </div>
            </div>

            <div className=''>
                <div className="card mb-7 w-100">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="lead-prof-img position-relative border rounded">
                                    <img src="http://pm-crm.realcube.estate/img/avatar-con.jpeg" className="img-fluid rounded" alt="Image" width="50"/>
                                </div>
                            </div>
                            <div className="col lead-media">
                                <div className="">
                                    <h6 className="card-title ellipsis font-weight-600 text-black mb-1 font-14">
                                        demo user
                                    </h6>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <span className="d-inline-block bg-warning2 rounded px-4 py-2 text-center font-12 text-white"><b>Contact Score<br/>0%</b></span>
                            </div>
                        </div>
                        <hr/>
                        <div className="row no-gutters">
                            <div className="col-md-12 lead-det-prof">
                                <div className="row">
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Ref</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">First Name</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Last Name</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Gender</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">DATE OF BIRTH</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">NATIONALITIES</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Religion</span> </h6>
                                        
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Languages</span></h6>
                                        
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Hobbies</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Mobile</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Alternate Mobile</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Email</span> </h6>
                                        <p className="font-12 text-muted">demouser@gmail.com</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Fax</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Po Box</span> </h6>
                                        <p className="font-12 text-muted mb-2">-</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-7 card-body w-100">
                    <div className="row no-gutters">
                        <div className="col-md-12 lead-det-prof">
                            <h6 className="theme-color font-22 mb-4"> Social Media </h6>
                            <div className="row">
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Facebook Profile</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Twitter Profile</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Linkedin Profile</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Skype ID</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Google+ ID</span></h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Instagram ID</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Wechat ID</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Website Name</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                                <div className="col-md-4 col-lg-3 col-sm-6">
                                    <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Website URL</span> </h6>
                                    <p className="font-12 text-muted">-</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-7 w-100">
                    <div className="card-body">
                        <div className="row no-gutters">
                            <div className="col-md-12 lead-det-prof">
                                <h6 className="theme-color font-22 mb-4"> Address Information </h6>
                                <div className="row">
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Address Line 1</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Address Line 2</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">City</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Zip Code</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">State</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Country</span> </h6>
                                        <p className="font-12 text-muted">-</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-7 w-100">
                    <div className="card-body">
                    <h6 className="theme-color font-22 mb-4"> Additional Information </h6>
                    <div className="row">
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Contact Source</span> </h6>
                            
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black"> Company Name </span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black"> Designation </span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black"> Website URL </span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black"> Contact Type </span></h6>
                                
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black"> Created By </span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </>
        )
}