import React, { FC, useEffect, useRef, useState } from 'react'
import Dateicon from "../../../../assets/images/Calendar.png";
import UploadIcon from "../../../../assets/images/upload-ioc.png";
        import uploadImg from "../../../../assets/images/file-upload.svg";
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { AddedSuccessfulPopup } from './AddedSuccessfulPopup';
import DatePicker from 'react-date-picker';

import { RequestSuccessfulPopup } from '../../../SuccessfulPopup/RequestSuccessfulPopup';
import { CustomRequestSuccessfulPopup } from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import Loader from '../../../../components/Loader';
import { useSelector } from 'react-redux';
import { addNewAnnouncement, getMasterData, getUserBuildingCommunityList } from '../../../../utils/makeRequest';
import { IState } from '../../../../utils/interface';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRequestPost from '../../../../hooks/useRequestPost'
import moment from 'moment';
import { getUserRole } from '../../../../constants/generics/userCheck';
import { RootState } from '../../../../constants';
import { PreventDatePickerInput } from '../../../../constants/generics/dateTimeFormater';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler';

export function SupervisorCreateAnnouncements() {
    const { t } = useTranslation();
    const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)
    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)
    let today_data_object = new Date();

    var _dd = String(today_data_object.getDate()).padStart(2, '0');
    var _mm = String(today_data_object.getMonth() + 1).padStart(2, '0'); //January is 0!
    var _yyyy = today_data_object.getFullYear();

    let todays_date = _yyyy + '-' + _mm + '-' + _dd;

    const [successMessage, setSuccessMessage] = useState<any>("An announcement has been added successfully");
    const [popupHref, setPopupHref] = useState<any>("/su-announcement");
    const [propertyList, setPropertyList] = useState<any>([])
    const [filteredBuildingList, setFilteredBuildingList] = useState<any>([])
    const [selectedProperties, setSelectedProperties] = useState([])
    const [selectedBuildings, setSelectedBuildings] = useState([])
    const [buildingList, setBuildingList] = useState<any>([])
    const [isLoading, setIsLoading] = useState<any>({
        property: true,
        building: true,
    })


    const history = useHistory()

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data)
    const userId = state?.auth?.user?.data?.id
    const announcement_type = (user_role === 'FC' || user_role === 'FCS' || user_role === 'FCSS') ? '2'
        : (user_role === 'FMT' || user_role === 'FMSM') ? '3' : null; //tenant do not have option to add

    const buttonEl: any = useRef(null);

    const initialValues = {
        // building_id: currentBuilding ? currentBuilding : "",
        title: "",
        category: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        description: "",
        property_id: [],
        building_id: "",
        mediaUpload: ""
    }
    const onSubmit = (values: any) => {
        console.log(values.mediaUpload, 'values.mediaUpload');

        const formData = new FormData();

        // formData.append("building_id", values.building_id);
        formData.append("title", values.title);
        formData.append("category_id", values.category);
        formData.append("date", values.startDate);
        formData.append("end_date", values.endDate);
        formData.append("announcement", values.description);
        formData.append("start_time", values.startTime);
        formData.append("end_time", values.endTime);
        // if(values.mediaUpload && values.mediaUpload !== '')
        formData.append("media", values.mediaUpload);
        formData.append("building_id", values.building_id);
        // values?.building_id?.forEach((element: any, index: any) => {
        //     formData.append(`building_id[${index}]`, element)
        // })
        if (announcement_type)
            formData.append("type", announcement_type);

            

        // const pairs = Array.from(formData.entries());
        // for (let pair of pairs) {
        //     console.log(pair);
        // }
        setRequestParams(formData);
        // console.log('Form: ', values);
    }
    const validationSchema = Yup.object({
        building_id: Yup.array().min(1, t('Building is required!')),
        title: Yup.string().required(t('Title is required!'))
            .matches(/^[^\s].*/, t('Title cannot start with a blankspace!'))
            .min(4, t('Min length is 4!'))
            .max(25, t('Max length is 25!'))
            .test('len', t('Title is required!'), (value) => {
                return value ? (value?.trim()).length > 0 : false
            }),
        category: Yup.string().required(t('Category is required!')),
        startDate: Yup.string().required(t('Please select a date!'))
            .test('futureDate', t('Selected date cannot be in the past!'), (item) => moment(item).diff(today_data_object, 'days') >= 0),
        endDate: Yup.string().required(t('Please select a date!'))
            .test('futureDate', t('Selected date cannot be in the past!'), (item) => moment(item).diff(today_data_object, 'days') >= 0),
        startTime: Yup.string().required(t('Start Time must be picked!')),
        /* endTime: Yup.string().required('End Time must be picked'), */
        endTime: Yup.string().required(t('End Time must be picked!')),
        description: Yup.string()
            .required(t('Description is required!'))
            .matches(/^[^\s].*/, t('Description cannot start with a blankspace!'))
            .max(1000, t('Max length is 1000!'))
            .test('len', t('Description is required!'), (value) => {
                return value ? (value?.trim()).length > 0 : false
            }),
        property_id: Yup.array().min(1, t('Property is required!')),
        // mediaUpload: Yup.mixed()
        //     .test('type', t('Only the following formats are acceptable: .jpeg, .jpg, .png'), (value) =>
        //         value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true)
        //     .test('fileSize', t('The file is too large!'), (value) => value ? value?.size <= 2000000 : true),
    });

    // const validateEndTime = (value: any) => {
    //     console.log('value: ', value)
    // }


    useEffect(() => {
        getUserBuildingCommunityList(userId)
            .then(({ buildingListData, communityListData }) => {
                setPropertyList(communityListData)
                setBuildingList(buildingListData)
                setIsLoading((oState: any) => ({ ...oState, building: false, property: false }))
            })
            .catch((e) => {
                setIsLoading({ ...isLoading, building: false, property: false })
            })
    }, [])

    useEffect(() => {
        // let selectedPropertIDList = Array.isArray(formik.values.property_id) ? formik.values.property_id: [formik.values.property_id] ;
        // let selectedBuildingIDList = Array.isArray(formik.values.building_id) ? formik.values.building_id: [formik.values.building_id] ;
        let selectedPropertIDList = Array.isArray(selectedProperties)
            ? selectedProperties.map((item: any) => item.value)
            : []
        let selectedBuildingIDList = Array.isArray(selectedBuildings)
            ? selectedBuildings.map((item: any) => item.value)
            : []

        // console.log(selectedPropertIDList, selectedBuildingIDList);

        if (selectedPropertIDList) {
            formik.setFieldValue('property_id', selectedPropertIDList)
            // formik.setFieldTouched('property_id', true)
        } else {
            formik.setFieldValue('property_id', [])
            // formik.setFieldTouched('property_id', false)
        }

        const filteredBuildingList = buildingList?.filter((item: any) =>
            selectedPropertIDList.includes(item.communityId)
        )
        setFilteredBuildingList(filteredBuildingList)
        // console.log(filteredBuildingList, 'filteredBuildingList');

        const updatedSelectedBuildingList = filteredBuildingList?.filter((item: any) =>
            selectedBuildingIDList.includes(item.id)
        )
        const updatedSelectedBuildingIDList = filteredBuildingList?.map((item: any) => item.id)
        if (updatedSelectedBuildingIDList) {
            formik.setFieldValue('building_id', updatedSelectedBuildingList)
            // formik.setFieldTouched('building_id', true)
        } else {
            formik.setFieldValue('building_id', [])
            // formik.setFieldTouched('building_id', false)
        }

        const formatedSelectedBuildingList = updatedSelectedBuildingList?.map((item: any) => ({
            label: item.name,
            value: item.id,
        }))
        setSelectedBuildings(formatedSelectedBuildingList)
        // }, [formik.values.property_id])
    }, [selectedProperties])

    useEffect(() => {
        let selectedBuildingIDList = Array.isArray(selectedBuildings)
            ? selectedBuildings.map((item: any) => item.value)
            : []
        if (selectedBuildingIDList) {
            formik.setFieldValue('building_id', selectedBuildingIDList)
            // formik.setFieldTouched('building_id', true)
        } else {
            formik.setFieldValue('building_id', [])
            // formik.setFieldTouched('building_id', false)
        }
        // console.log(selectedBuildingIDList, 'selectedBuildingIDList');
    }, [selectedBuildings])

    const isError =
        propertyList?.length === 0
            ? t('No property data!')
            : buildingList?.length === 0
                ? t('No building data found!')
                : ''

    const isLoadingData = isLoading.property || isLoading.building
    const propertyOptions = propertyList.map((item: any) => ({ label: item.name, value: item.id }))
    const buildingOptions = filteredBuildingList.map((item: any) => ({
        label: item.name,
        value: item.id,
    }))
    const validate = (values: any) => {
        let errors: any = {}

        // console.log('Error val: ', values);

        /* if(values.startTime !== "" && values.endTime !== ""){

            //errors.endTime = 'J Testing';
        } */

        var startDate = new Date(values.startDate);
        var endDate = new Date(values.endDate);


        var date1 = new Date("01/01/2011 " + values.startTime);
        var date2 = new Date("01/01/2011 " + values.endTime);

        var selectedDate = moment(values.startDate).startOf('day')
        var selectedDateFull = moment(values.startDate + (values.startTime ? ' ' + values.startTime + ':00' : ''))
        var todayDate = moment().startOf('day')
        var todayFull = moment()
        // console.log(values.date, selectedDateFull, todayFull , values.startTime);
        // console.log(selectedDateFull.diff(todayFull, 'minutes'));
        // console.log(values.date, selectedDate, todayDate);
        // console.log(selectedDate.diff(todayDate, 'days'));


        if (values.startDate && values.endDate) {
            if (startDate.getTime() > endDate.getTime()) {
                errors.startDate = '\'Start date\' must be greater then \'End date\' !';
            }
            else if (startDate.getTime() === endDate.getTime()) {
                if (values.startTime && (selectedDate.diff(todayDate, 'days') === 0) && (selectedDateFull.diff(todayFull) < 0)) {
                    errors.startTime = t('Please select the vaild time!');
                }
                if (values.startTime && values.endTime) {
                    if (date1.getTime() >= date2.getTime()) {
                        errors.endTime = t("'End Time' must be greater then 'Start Time'!");
                    }
                }
            } else {
                if (values.startTime && (selectedDate.diff(todayDate, 'days') === 0) && (selectedDateFull.diff(todayFull) < 0)) {
                    errors.startTime = t('Please select the vaild time!');
                }
            }
        }

        return errors;
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        validate
    });
    function successNewInvoice() {
        var ev = new Event('click', { bubbles: true });
        buttonEl.current.dispatchEvent(ev);
        //history.goBack();
        formik.resetForm();
    }
    

    const { requestState: addAnnouncementInvoice, setRequestParams } = useRequestPost("/announcements/create", true, successNewInvoice);

    const [masterDataState, setMasterDataState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    const [announcementCategoryState, setAnnouncementCategory] = useState<any>([]);
    useEffect(() => {
        getMasterData(setMasterDataState)
    }, []);

    useEffect(() => {
        if (Array.isArray(masterDataState.response)) {
            if (masterDataState.response.length > 0) {
                const announcementCategory = masterDataState.response.find((masterData: any) => {
                    return masterData.category_name === "announcement_category"
                });
                setAnnouncementCategory(announcementCategory.master_data);
            }
        }
    }, [masterDataState]);

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState(false)

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = (e: any) => {
        setUploading(true)
        formik.setFieldError('mediaUpload', undefined)
        try {
            validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
                if (compressResponse?.success) {
                    inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
                    formik.setFieldTouched('mediaUpload')
                    formik.setFieldValue('mediaUpload', compressResponse.file)
                } else {
                    setUploadedFileName(null)
                    formik.setFieldTouched('mediaUpload', true, false)
                    formik.setFieldValue('mediaUpload', '', false)
                    formik.setFieldError('mediaUpload', t(compressResponse?.message || 'File compression error!'))
                }
                setUploading(false)
            })
        } catch (error) {
            setUploadedFileName(null)
            formik.setFieldTouched('mediaUpload')
            formik.setFieldValue('mediaUpload', '', false)
            formik.setFieldError('mediaUpload', t('File upload error!'))
            setUploading(false)
        }
        // if (inputRef.current?.files && inputRef.current.files[0]) {
        //     setUploadedFileName(inputRef.current.files[0].name);
        //     formik.setFieldTouched('mediaUpload')
        //     formik.setFieldValue('mediaUpload', e.currentTarget.files[0])
        // }
    };

    const [dateValue, setDateValue] = useState<any>();
    const [endDateValue, setEndDateValue] = useState<any>();
    const handleDateChange = (e: any) => {
        // console.log(e, 'handleDateChange');
        setDateValue(e);
        // var menDate = new Date(e).toISOString().slice(0, 10);
        var menDate = e ? moment(e).format('YYYY-MM-DD') : ''
        formik.setFieldValue("startDate", menDate);
        // formik.setFieldTouched("date");
        // formik.setFieldValue("date", menDate);
    }
    const handleEndDateChange = (e: any) => {
        setEndDateValue(e);
        var menDate = e ? moment(e).format('YYYY-MM-DD') : ''
        formik.setFieldValue("endDate", menDate);
    }
    // console.log(formik.values, 'formik.values');

    // const propertyOptions = propertyList.map((item: any) => ({ label: item.name, value: item.id }))
    // const buildingOptions = filteredBuildingList.map((item: any) => ({
    //     label: item.name,
    //     value: item.id,
    // }))

    const backLink = (user_role === 'T') ? '/announcements'
        : (user_role === 'FC' || user_role === 'FCS' || user_role === 'FMT' || user_role === 'FMSM') ? '/su-announcement'
            : (user_role === 'FCSS' ) ? '/fc-ss-announcements/list' : '/';

    PreventDatePickerInput()

    return (
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="com-card-section">
                    <div className="row">
                        <div className="col-xl-12">
                            <h4 className="card-title font-18 text-black mb-6">
                                <Link to={backLink} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {t("Create Community Post")}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        {masterDataState.loading && (
                            <Loader />
                        )}
                        {!masterDataState.loading && (
                            <>
                                <div className="col-12">
                                    <div className="card mb-3">
                                        <div className="card-body px-8 py-6">
                                            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                                                <div className="row form-add-design">

                                                    <div className="col-md-4 col-sm-6 col-xl-3 col-12">
                                                        <div className="mb-10">
                                                            <label className="form-label fw-bolder">
                                                                {t("Title")} <small className="text-danger">* </small></label>
                                                            <input
                                                                type="text"
                                                                {...formik.getFieldProps('title')}
                                                                className="form-control fw-bolder form-control-solid"
                                                                placeholder={t("Title")} />

                                                            {formik.touched.title && formik.errors.title ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.title}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>


                                                    <div className='col-md-4 col-sm-6 col-xl-3 col-12'>
                                                        <div className='mb-10'>
                                                            <label className='form-label fw-bolder'>
                                                                {' '}
                                                                {t("Property")} <span className='text-danger'> * </span>{' '}
                                                            </label>
                                                            <MultiSelect
                                                                options={propertyOptions}
                                                                value={selectedProperties}
                                                                onChange={setSelectedProperties}
                                                                labelledBy='Select-property'
                                                                hasSelectAll={false}
                                                            />
                                                            {formik.touched.property_id && formik.errors.property_id && (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className='text-danger'>{formik.errors.property_id}</small>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>



                                                    {/* <div className="col-md-4 col-sm-6 col-xl-3 col-12">
                                                        <div className="mb-7">
                                                            <label className="form-label fw-bolder">{t("Building Name")}
                                                                <span className="text-danger"> * </span>
                                                            </label>
                                                            <select
                                                                {...formik.getFieldProps('building_id')}
                                                                className="form-control form-select fw-bolder form-control-solid">
                                                                <option value="">{t("Select")}</option>
                                                                {listBuilding?.map((el: any, index: number) => {
                                                                    return (
                                                                        <option key={index} value={el.id}>{el.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {formik.touched.building_id && formik.errors.building_id ? (
                                                                <small className="text-danger">
                                                                    {formik.errors.building_id}
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </div> */}

                                                    <div className='col-md-4 col-sm-6 col-xl-3 col-12'>
                                                        <div className='mb-10'>
                                                            <label className='form-label fw-bolder'>
                                                                {' '}
                                                                {t("Building")} <span className='text-danger'> * </span>{' '}
                                                            </label>
                                                            <MultiSelect
                                                                options={buildingOptions}
                                                                value={selectedBuildings}
                                                                onChange={setSelectedBuildings}
                                                                labelledBy='Select-Building'
                                                                hasSelectAll={false}
                                                            />
                                                            {formik.touched.building_id && formik.errors.building_id && (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className='text-danger'>{formik.errors.building_id}</small>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className="col-md-4 col-sm-6 col-xl-3 col-12">
                                                        <div className="mb-10">
                                                            <label className="form-label fw-bolder">
                                                                {t("Category")} <small className="text-danger">* </small></label>
                                                            <select
                                                                className="form-control form-select fw-bolder form-control-solid"
                                                                data-control="select2" data-allow-clear="true"
                                                                {...formik.getFieldProps('category')}
                                                            >
                                                                <option value="">{t("Please Select")}</option>
                                                                {announcementCategoryState.map((announcementCategory: any, index: number) => {
                                                                    return (
                                                                        <option key={index} value={announcementCategory.value}>{announcementCategory.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {formik.touched.category && formik.errors.category ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.category}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-3 col-12">
                                                        <div className="mb-10 date-picker">
                                                            <label className="form-label fw-bolder">
                                                                {t("Start Date")} <small className="text-danger">* </small></label>
                                                            {/* <input
                                                                type="date"
                                                                className="form-control fw-bolder form-control-solid" placeholder=""
                                                                min={todays_date}
                                                                {...formik.getFieldProps('date')} /> */}
                                                            <DatePicker
                                                                value={dateValue}
                                                                className='form-control fw-bolder form-control-solid'
                                                                format="dd-MM-y"
                                                                minDate={today_data_object}
                                                                onChange={handleDateChange}
                                                                dayPlaceholder="DD"
                                                                monthPlaceholder="MM"
                                                                yearPlaceholder="YYYY"
                                                            />
                                                            {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            {formik.touched.startDate && formik.errors.startDate ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.startDate}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-3 col-12">
                                                        <div className="mb-10 date-picker">
                                                            <label className="form-label fw-bolder">
                                                                {t("End Date")} <small className="text-danger">* </small></label>
                                                            {/* <input
                                                                type="date"
                                                                className="form-control fw-bolder form-control-solid" placeholder=""
                                                                min={todays_date}
                                                                {...formik.getFieldProps('date')} /> */}
                                                            <DatePicker
                                                                value={endDateValue}
                                                                className='form-control fw-bolder form-control-solid'
                                                                format="dd-MM-y"
                                                                minDate={today_data_object}
                                                                onChange={handleEndDateChange}
                                                                dayPlaceholder="DD"
                                                                monthPlaceholder="MM"
                                                                yearPlaceholder="YYYY"
                                                            />
                                                            {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            {formik.touched.endDate && formik.errors.endDate ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.endDate}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-3 col-12">
                                                        <div className="mb-10">
                                                            <label className="form-label fw-bolder">
                                                                {t("Start Time")} <small className="text-danger">* </small></label>
                                                            <input
                                                                type="time"
                                                                className="form-control fw-bolder form-control-solid" placeholder=""
                                                                {...formik.getFieldProps('startTime')} />
                                                            {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            {formik.touched.startTime && formik.errors.startTime ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.startTime}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-3 col-12">
                                                        <div className="mb-10">
                                                            <label className="form-label fw-bolder">
                                                                {t("End Time")} <small className="text-danger">* </small></label>
                                                            <input
                                                                type="time"
                                                                /* validate={validateEndTime} */
                                                                className="form-control fw-bolder form-control-solid" placeholder=""
                                                                {...formik.getFieldProps('endTime')} />
                                                            {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                            {formik.touched.endTime && formik.errors.endTime ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.endTime}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-10 upload-input">
                                                            <label className="form-label fw-bolder d-block">
                                                                {t("Media Upload")}</label>
                                                            <input
                                                                ref={inputRef}
                                                                className="d-none"
                                                                type="file"
                                                                accept=".png,.jpg,.jpeg"
                                                                onChange={(e) => handleDisplayFileDetails(e)}
                                                                // onBlur={formik.handleBlur}
                                                                 />
                                                            <button type="button" onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`} disabled={uploading}>
                                                                <span>{uploading ?
                                                                    <span className='indicator-progress d-block'>
                                                                        {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                    : uploadedFileName ? uploadedFileName : <span className="txt">
                                                                        <img
                                                                                alt="File"
                                                                                src={uploadImg}
                                                                        className="position-relative"
                                                                        loading="lazy"/>{t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                            </button>
                                                            {formik.touched.mediaUpload && formik.errors.mediaUpload ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.mediaUpload}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-sm-12 col-xl-12 col-12">
                                                        <div className="mb-10">
                                                            <label className="form-label fw-bolder">
                                                                {t("Description")} <small className="text-danger">* </small></label>
                                                            <textarea
                                                                className='form-control fw-bolder form-control-solid'
                                                                data-kt-autosize='true'
                                                                rows={3}
                                                                placeholder={t('Type here...')}
                                                                {...formik.getFieldProps('description')}
                                                            />
                                                            {formik.touched.description && formik.errors.description ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.description}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 text-end mt-5">
                                    <button ref={buttonEl} data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='button' className='invisible btn btn-primary fw-bold px-10 py-3'></button>

                                    {addAnnouncementInvoice.loading && (
                                        <Loader />
                                    )}
                                    {(!addAnnouncementInvoice.loading && announcement_type) && (
                                        <>
                                            <button
                                                onClick={() => {
                                                    history.goBack();
                                                }}
                                                type='button' className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'>
                                                {t("Cancel")}
                                            </button>
                                            <button onClick={formik.submitForm} type='button' className='btn btn-primary fw-bold px-10 py-3' disabled={uploading}>
                                                {t("Submit")}
                                            </button>
                                        </>
                                    )}
                                    {addAnnouncementInvoice.error && (
                                        <div className="alert alert-danger w-100 mt-2 text-center" role="alert">
                                            {addAnnouncementInvoice.error}
                                        </div>
                                    )}
                                    <CustomRequestSuccessfulPopup
                                        successMessage={successMessage}
                                        href={popupHref}
                                    />
                                </div>
                            </>
                        )}
                        <AddedSuccessfulPopup />
                    </div>
                </div>
            </div>
        </div>
    );
}