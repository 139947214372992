import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import { currencyFormater } from '../../../../../constants/generics/customActios';
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';

export function Payments({paymentData}: any) {
    const {t} = useTranslation()

    let otherCharges = []
    otherCharges.push({
        title: t('Access Card'),
        paid: paymentData?.Access_Card?.total_paid,
        pending: paymentData?.Access_Card?.total_pending,
    })
    otherCharges.push({
        title: t('Security Deposit'),
        paid: paymentData?.Security_Deposit?.total_paid,
        pending: paymentData?.Security_Deposit?.total_pending,
    })
    // paymentData.Security_Deposit.payments.forEach((element:any) => {
    //     otherCharges.push({
    //         title: element.title,
    //         paid: element.amount,
    //         pending: element.title,
    //     })
    // });

    let otherChargesTotalPaid = 0
    let otherChargesTotalPending = 0
    otherCharges.forEach((el:any)=>{
        otherChargesTotalPaid += el.paid
        otherChargesTotalPending += el.pending
    })

    const total_paid = parseInt(paymentData?.Other_Charges?.total_paid) + parseInt(paymentData?.Rent?.total_paid) + parseInt(paymentData?.Service_Charges?.total_paid)
    const total_pending = parseInt(paymentData?.Other_Charges?.total_pending) + parseInt(paymentData?.Rent?.total_pending) + parseInt(paymentData?.Service_Charges?.total_pending)    

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="move-out-payment-sec">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='table-responsive'>
                                            <table className='table align-middle gy-4 gx-4 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th className='ps-10 py-6'> {t("Payment Type")} </th>
                                                        <th className="text-end py-6"> {t("Paid")} </th>
                                                        <th className="text-end pe-10 py-6"> {t("Pending")} </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span
                                                                data-bs-target="#rent-details-popup"
                                                                data-bs-toggle="modal" data-bs-dismiss="modal"
                                                                className='c-pointer text-primary text-decoration-underline'
                                                                >
                                                                {t("Rent")}
                                                            </span>
                                                            {/* <button data-bs-target="#rent-details-popup"
                                                                data-bs-toggle="modal" data-bs-dismiss="modal"
                                                                type='button'
                                                                className='ms-3 px-10 py-3 text-start corrective_table_popup_bt btn btn-primary' >
                                                                {t("View")}
                                                            </button> */}
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(paymentData?.Rent?.total_paid)}
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(paymentData?.Rent?.total_pending)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span
                                                                data-bs-target="#sc-details-popup"
                                                                data-bs-toggle="modal" data-bs-dismiss="modal"
                                                                className='c-pointer text-primary text-decoration-underline'
                                                                >
                                                                {t("Service Charges")}
                                                            </span>
                                                            
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(paymentData?.Service_Charges?.total_paid)}
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(paymentData?.Service_Charges?.total_pending)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span
                                                                data-bs-target="#oc-details-popup"
                                                                data-bs-toggle="modal" data-bs-dismiss="modal"
                                                                className='c-pointer text-primary text-decoration-underline'
                                                                >
                                                                {t("Other Charges")}
                                                            </span>
                                                            
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(paymentData?.Other_Charges?.total_paid)}
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(paymentData?.Other_Charges?.total_pending)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-end">
                                                            {t("Total")}
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(total_paid)}
                                                        </td>
                                                        <td className="text-end">
                                                            {t("AED")} {currencyFormater(total_pending)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <>
                <div className="modal fade custom-modal" aria-hidden='true' id="rent-details-popup">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-10">
                            <div className='modal-header py-3 border-0'>
                                <h5 className='modal-title'>{t("Rent Details")}</h5>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                {paymentData?.Rent?.payments?.length > 0 ?
                                    <div 
                                        style={{
                                            overflowY: "scroll",
                                            // minHeight: "300px"
                                        }}       
                                        className="table-responsive fc-su-ser-tabel px-0">
                                        <table className="table align-middle table-striped gy-4 gx-4 mb-4 fcs-assign-to-technician">
                                            <thead>
                                            <tr>
                                                <th>{t("Type")}</th>
                                                <th>{t("Date")}</th>
                                                <th>{t("Status")}</th>
                                                <th>{t("Amount")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            
                                                {paymentData?.Rent?.payments?.map((payment: any, index: number)=>{
                                                    return (        
                                                        <tr key={index}>
                                                            <td>{payment.title}</td>
                                                            <td>{payment.statusText === 'paid' ? formatDate(payment.paid_date) : formatDate(payment.due_date)}</td>
                                                            <td>{payment.statusText === 'paid' ? t("Cleared") : t("Pending")}</td>
                                                            <td>{t("AED")} {currencyFormater(payment.amount)}</td>
                                                        </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className='text-center text-muted font-16 p-6'>
                                        {t("No data!")}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade custom-modal" aria-hidden='true' id="sc-details-popup">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-10">
                            <div className='modal-header py-3 border-0'>
                                <h5 className='modal-title'>{t("Service Charges Details")}</h5>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                {paymentData?.Service_Charges?.payments?.length > 0 ?
                                    <div 
                                        style={{
                                            overflowY: "scroll",
                                            // minHeight: "300px"
                                        }}       
                                        className="table-responsive fc-su-ser-tabel px-0">
                                        <table className="table align-middle table-striped gy-4 gx-4 mb-4 fcs-assign-to-technician">
                                            <thead>
                                            <tr>
                                                <th>{t("Type")}</th>
                                                <th>{t("Date")}</th>
                                                <th>{t("Status")}</th>
                                                <th>{t("Amount")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            
                                                {paymentData?.Service_Charges?.payments?.map((payment: any, index: number)=>{
                                                    return (        
                                                        <tr key={index}>
                                                            <td>{payment.title}</td>
                                                            <td>{payment.statusText === 'paid' ? formatDate(payment.paid_date) : formatDate(payment.due_date)}</td>
                                                            <td>{payment.statusText === 'paid' ? t("Cleared") : t("Pending")}</td>
                                                            <td>{t("AED")} {currencyFormater(payment.amount)}</td>
                                                        </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className='text-center text-muted font-16 p-6'>
                                        {t("No data!")}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade custom-modal" aria-hidden='true' id="oc-details-popup">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-10">
                            <div className='modal-header py-3 border-0'>
                                <h5 className='modal-title'>{t("Other Charges Details")}</h5>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                {paymentData?.Other_Charges?.payments?.length > 0 ?
                                    <div 
                                        style={{
                                            overflowY: "scroll",
                                            // minHeight: "300px"
                                        }}       
                                        className="table-responsive fc-su-ser-tabel px-0">
                                        <table className="table align-middle table-striped gy-4 gx-4 mb-4 fcs-assign-to-technician">
                                            <thead>
                                            <tr>
                                                <th>{t("Type")}</th>
                                                <th>{t("Date")}</th>
                                                <th>{t("Status")}</th>
                                                <th>{t("Amount")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            
                                                {paymentData?.Other_Charges?.payments?.map((payment: any, index: number)=>{
                                                    return (        
                                                        <tr key={index}>
                                                            <td>{payment.title}</td>
                                                            <td>{payment.statusText === 'paid' ? formatDate(payment.paid_date) : formatDate(payment.due_date)}</td>
                                                            <td>{payment.statusText === 'paid' ? t("Cleared") : t("Pending")}</td>
                                                            <td>{t("AED")} {currencyFormater(payment.amount)}</td>
                                                        </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className='text-center text-muted font-16 p-6'>
                                        {t("No data!")}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        </div>
    );
}