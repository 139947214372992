import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

export function ApproveButtons() {
    const { t } = useTranslation();

    return (
        <div className="com-card-section pm_approval_buttons">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body px-8 py-6">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-sm-12 mb-5">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="font-14 fw-bold mb-3">{t("Approver Comments")} </label>
                                            <textarea
                                                className='form-control fw-bolder'
                                                data-kt-autosize='true'
                                                rows={4}
                                                placeholder={t("Write Comments")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12 text-end">
                                    <div className="col-12 text-end">
                                        <button className='btn btn-outline-danger reject fw-bold px-10 py-3 me-5'>
                                            {t("Reject")}
                                        </button>

                                        <button className='btn btn-outline-primary request-more-info fw-bold px-10 py-3 me-5'>
                                            {t("Request More Info")}
                                        </button>

                                        <button className='btn btn-primary fw-bold px-10 py-3'>
                                            {t("Approve")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}