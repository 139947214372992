import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import avtaricon from '../../../assets/images/pm/avatar-icon.jpeg'
import { Notes } from '../Tabs/Notes'
import { Reminders } from '../Tabs/Reminder'
import { Activities } from '../Tabs/Activities'
import { Offers } from '../Tabs/offers'
import { Doccuments } from '../Tabs/Doccuments'
import avatar from "../../../assets/images/pm/avatar-icon.jpeg"
export function OpportunitesPreview() {
    const [tabPosition, setTabPosition] = useState("1");
    const opportunityTab = (i: any) => {
        setTabPosition(i)
    }


    return (
        <>
            <div className="row align-items-center mb-5 community opportunity">
                <div className="col-md">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <Link to={'/opportunites/list'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </Link> Opportunity Details
                    </h5>
                </div>
                <div className="col-auto">
                    <a href="" type='submit' className='btn btn-outline-primary fw-bold px-10 py-3 text-uppercase me-2'>
                        Edit
                    </a>
                    <Link to={"/opportunites/send/offer"} type='submit' className='btn btn-primary fw-bold px-10 py-3 text-uppercase'>
                        Send Offer
                    </Link>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-md-12 col-lg-4">
                            <div className="mb-3 mb-md-3 mb-lg-0 h-100">
                                <div className="">
                                    <div className="row no-gutters align-items-center">
                                    <div className="col-md-3 col-2">
                                            <div className="lead-prof-img position-relative border rounded">
                                                <img src={avtaricon} className="img-fluid rounded" alt="Image" />

                                            </div>
                                        </div>
                                        <div className="col-md-8 col-10">
                                            <div className="card-body py-0 px-2">
                                                <h6 className="card-title font-weight-600 text-black mb-1 font-14">
                                                    Radha Krishna
                                                </h6>
                                                <ul className="p-0 m-0 list-group list-group-horizontal d-inline-flex">
                                                    <li className="list-unstyled">
                                                        <a href="" target="_blank" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                                            <i className="fab fa-facebook-f"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-unstyled">
                                                        <a href="" target="_blank" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                                            <i className="fab fa-linkedin-in"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-unstyled">
                                                        <a href="" target="_blank" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                                            <i className="fab fa-twitter"></i>
                                                        </a>
                                                    </li>
                                                    <li className="list-unstyled">
                                                        <a href="javascript:void(0);" className="text-decoration-none text-black border rounded-circle d-block me-3 text-center">
                                                            <i className="fab fa-whatsapp"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row form-add-design">
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0">
                                <span className="text-uppercase text-black">Opportunity Status</span>
                            </h6>
                            <a href="javascript:void(0);" data-toggle="modal" data-target="#lead-status-modal">
                                <p className="font-10 text-muted badge badge-blue border-0 py-1" id="updated_status">
                                    Active                                                </p>
                            </a>
                        </div>
                        <input type="hidden" name="id_contact" className="id_contact" value="1" />
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Opportunity Sub Status</span> </h6>
                            <p className="font-12 text-muted" id="updated_sub_status">Offer Made</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Opportunity Type</span> </h6>
                            <p className="font-12 text-muted">Buyer</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">First Name</span> </h6>
                            <p className="font-12 text-muted">Radha</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Last Name</span> </h6>
                            <p className="font-12 text-muted">Krishna</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Designation</span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Email</span> </h6>
                            <p className="font-12 text-muted">radha.k@exalogic.co</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Phone Number</span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Alternate Phone</span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Addresses</span></h6>
                            <p className="font-12 text-muted">139-A, 1st FloorJanapriya apartments saroornagar</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">City</span> </h6>
                            <p className="font-12 text-muted">Hyderabad</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">State</span> </h6>
                            <p className="font-12 text-muted">Telangana</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Country</span> </h6>
                            <p className="font-12 text-muted">United Arab Emirates</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Zip</span> </h6>
                            <p className="font-12 text-muted">300065</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Select Language</span> </h6>
                            <p className="font-12 text-muted">-</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercas text-black">Source</span> </h6>
                            <p className="font-12 text-muted">Google</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-sm-6">
                            <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Assigned To</span> </h6>
                            <p className="font-12 text-muted mt-2"><img src={avatar} alt="Image" className="mr-1 img-fluid rounded-circle" width="35px" /> -</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-5">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                <h6 className="font-14 text-black m-0 font-weight-600">Interested Units </h6>
                            </div>
                            <div className="row">
                                <div className="col-md-12 lead-det-prof pr-2">
                                    <div className="row no-gutters req-card bg-ec rounded p-2 mb-3">
                                        <div className="col-md-12">
                                            <div className="p-3 theme-color font-14 font-weight-semibold bg-transparent d-flex flex-wrap align-items-center">
                                                <div className="req-collapse-icon" data-toggle="collapse" data-target="#req-item1">
                                                    <i className="fa fa-angle-right mr-2"></i> Requirement 1
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div id="req-item1" className="collapse show bg-white p-3 mt-2 property1-text">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="card radius-15 bg-light">
                                                            <div className="card-body p-3">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 border-bottom pb-2">
                                                                                    Property Requirements
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">CATEGORY</span></h6>
                                                                                    <p className="font-12 mb-0">---</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">MIN BEDS</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis">--- </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">MAX BEDS</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis">---</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">EMIRATE</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis">Abu Dhabi</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">MIN PRICE</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis">---</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">MAX PRICE</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis">---</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">COMMUNITY</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis">WIlton Park Residencies</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">MIN AREA</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis"></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">Max AREA</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis"></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">UNITS</span></h6>
                                                                                    <p className="font-12 mb-0 ellipsis">---</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">LOCATION</span></h6>
                                                                                    <p className="font-12 ellipsis mb-0">Akoya Oxygen</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                                <div className="">
                                                                                    <h6 className="font-12 text-black m-0"><span className="text-uppercase">FURNISHED TYPE</span></h6>
                                                                                    <p className="font-12 ellipsis mb-0">---</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card radius-15 bg-light">
                                                <div className="card-body p-3">
                                                    <div className="row mb-5">
                                                        <div className="col-md-12">

                                                            <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 border-bottom pb-2">
                                                                ss                                                                                    </h6>

                                                            <div className="row">
                                                                <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                    <div className="">
                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Property Ref No</span></h6>

                                                                        <p className="font-12 mb-0">#REF-UN-1689243911</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                    <div className="">
                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Community</span></h6>

                                                                        <p className="font-12 mb-0 ellipsis">WIlton Park Residencies</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                    <div className="">
                                                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase">Building</span></h6>

                                                                        <p className="font-12 mb-0 ellipsis"></p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card radius-15 bg-light">
                                                    <div className="card-body p-3">
                                                        <div className="row mb-5">
                                                            <div className="col-md-12">

                                                                <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 border-bottom pb-2">
                                                                    a                                                                                    </h6>

                                                                <div className="row">
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Property Ref No</span></h6>

                                                                            <p className="font-12 mb-0">#REF-UN-1689239308</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Community</span></h6>

                                                                            <p className="font-12 mb-0 ellipsis">WIlton Park Residencies</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Building</span></h6>

                                                                            <p className="font-12 mb-0 ellipsis"></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Price</span></h6>

                                                                            <p className="font-12 mb-0">AED 0</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Created at</span></h6>

                                                                            <p className="font-12 mb-0">13/07/2323</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Recent Action</span></h6>

                                                                            <p className="font-12 mb-0">17/07/2323</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card radius-15 bg-light">
                                                    <div className="card-body p-3">
                                                        <div className="row">
                                                            <div className="col-md-12">

                                                                <h6 className="font-weight-bold ellipsis text-warning mb-2 font-13 border-bottom pb-2">
                                                                    aaa
                                                                </h6>

                                                                <div className="row">
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Property Ref No</span></h6>

                                                                            <p className="font-12 mb-0">#REF-UN-1689228769</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Community</span></h6>

                                                                            <p className="font-12 mb-0 ellipsis">WIlton Park Residencies</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Building</span></h6>

                                                                            <p className="font-12 mb-0 ellipsis"></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Price</span></h6>

                                                                            <p className="font-12 mb-0">AED 0</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Created at</span></h6>

                                                                            <p className="font-12 mb-0">13/07/2323</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-3 col-sm-6 mb-3">
                                                                        <div className="">
                                                                            <h6 className="font-12 text-black m-0"><span className="text-uppercase">Recent Action</span></h6>

                                                                            <p className="font-12 mb-0">17/07/2323</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className='card '>
            <div className="card-body">
            <div className='d-flex overflow-auto border-bottom '>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs border-transparent mb-4 flex-nowrap'>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(1)}>
                            Offers
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(2)}>
                            Reminder
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(3)}>
                            Note
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(4)}>
                            Doccuments
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to className={`${tabPosition == '5' ? 'active' : ''} nav-link pb-1 pt-0 position-relative me-8 bg-transparent`} onClick={() => opportunityTab(5)}>
                            Activities
                        </Link>
                    </li>
                </ul>
            </div>

            {tabPosition == '1' ? <Offers /> :
            tabPosition == '2' ? <Reminders /> :
            tabPosition == '3' ? <Notes /> :
            tabPosition == '4' ? <Doccuments /> :
            tabPosition == '5' ? <Activities /> : ''
            }

            </div>
        </div>
            
        </>
    )
}
