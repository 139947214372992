import React, {FC, useEffect, useRef, useState} from 'react'
import {StepOne} from './Steps/StepOne/StepOne'
import {StepTwo} from './Steps/StepTwo/StepTwo'
import {StepThree} from './Steps/StepThree/StepThree'
import {StepSuccessful} from './Steps/StepSuccessful/StepSuccessful'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
    'Ads',
    'Targeting',
    'Budget & Schedule'
];

export function ExtendOfferStepperTwo() {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleSubmit = () => {

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className="row">
            <div className="col-md-12 px-lg-6">
                <Stepper activeStep={activeStep} className="multiform-stepper">
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        // if (isStepOptional(index)) {
                        //     labelProps.optional = (
                        //         <p>Optional</p>
                        //     );
                        // }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step className={activeStep == index ? "active" : ""} key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <React.Fragment>
                    <div>
                        {
                            activeStep === 0 ?
                            <StepOne /> :
                            activeStep === 1 ?
                            <StepTwo /> :
                            activeStep === 2 ?
                            <StepThree /> :
                            <StepSuccessful />
                        }
                    </div>
                    {/*<div>*/}
                    {/*{isStepOptional(activeStep) && (*/}
                    {/*<button onClick={handleSkip}>*/}
                    {/*Skip*/}
                    {/*</button>*/}
                    {/*)}*/}
                    {/*</div>*/}
                    <div className='row stepper-actions'>
                        <div className='col-lg-12 text-end'>
                            {activeStep === 3 ?
                                null
                                :
                                <>
                                    {activeStep === 0 ?
                                        null :
                                        <button
                                            onClick={handleBack}
                                            className='btn btn-outline-primary btn-lg px-12 py-3 font-12 me-3 btn-back'
                                        >
                                            {activeStep === 0 ? 'Cancel' : 'Back'}
                                        </button>
                                    }
                                    <button
                                        onClick={activeStep === 3 ? handleSubmit : handleNext}
                                        className='btn btn-primary fw-bold px-12 py-3 font-12'
                                    >
                                        {activeStep === 3 ? 'Submit' : 'Save'}
                                    </button>
                                    <div className="row">
                                        <div className="col-md-12 step-one">
                                            <div className="lead-selected">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div
                                                            className="card campaign-card rounded-5 mt-7 mb-0"
                                                        >
                                                            <div className="card-body p-6">
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-xl-12">
                                                                        <p className="font-12 text-gray-700 fw-normal mb-0 text-start">
                                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                                                            passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
}