import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getApprovalCommentList, postApprovalSubmit } from '../../../../constants/axios/apis'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ApprovalActions } from './ApprovalActions'
import { ApprovalComments } from './ApprovalComments'
import { ApprovalReply } from './ApprovalReply'
import Loader from '../../../../components/Loader'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../../../constants/generics/userCheck'

export function ApprovalActionWrapper({ approvalDetail, setPopupSuccessMsg }: any) {
    const { t } = useTranslation()
    // let userID: any = ''
    // let userDetail = localStorage.getItem('userDetail')
    // if (userDetail) {
    //     userID = JSON.parse(userDetail).id
    // }
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    const userID = state?.auth?.user?.data?.id

    const [reload, setReload] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [commentList, setCommentList] = useState<any>([]);

    useEffect(() => {
        if (reload && approvalDetail?.id) {
            setLoading(true)
            setError('')
            getApprovalCommentList('?approval_id=' + approvalDetail?.id)
                .then((response: any) => {
                    if (response.errorCode === 0) {
                        setCommentList(response?.data)
                    } else if (response.errorCode === 1) {
                        setCommentList([])
                        if (response.errorDescription !== 'No data found.')
                            setError(response.errorDescription)
                    } else {
                        setCommentList([])
                        setError(t("Some error occured!"))
                    }
                    setLoading(false)
                }).catch((e: any) => {
                    setLoading(false)
                    setCommentList([])
                    setError(t("Some error occured"))
                })
            setReload(false)
        }
    }, [approvalDetail, reload]);


    if (error !== '' || commentList.length > 0 || user_role === 'MA')
        return (
            <>
                <div className='pm_approval_buttons'>
                    <div className=''>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body px-8 py-6'>
                                    <ApprovalComments commentList={commentList} loading={loading} error={error} />

                                    {approvalDetail?.status === 'Pending'?
                                        user_role === 'MA' ?
                                            <ApprovalActions approvalDetail={approvalDetail} setPopupSuccessMsg={setPopupSuccessMsg} />
                                        : commentList.length > 0 ?
                                            <ApprovalReply approvalDetail={approvalDetail} setReload={setReload} /> : ''
                                        : ''
                                    }
                                    {/* {(approvalDetail.createdBy === userID && commentList.length > 0) ?
                                    <ApprovalReply approvalDetail={approvalDetail} setReload={setReload} /> : ''
                                }

                                {approvalDetail.createdBy !== userID &&
                                    <ApprovalActions approvalDetail={approvalDetail} setPopupSuccessMsg={setPopupSuccessMsg} />
                                } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    else return <></>
}
