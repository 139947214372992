import React, {useState, useEffect, useRef, FC} from 'react'
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {Link} from 'react-router-dom'
import { PageTitle } from '../../components/layout/core';
import { FinanceLeaseDetailScreen } from './FinanceLeaseDetailsScreen/FinanceLeaseDetailScreen';
import { FinanceLeaseListWrapper } from './FinanceLeaseList/FinanceLeaseListWrapper';

const FinanceServiceRequestLeaseRoutes: FC = () => {

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/finance-services-lease/list'>
                            <FinanceLeaseListWrapper />
                        </Route>
                        <Route path='/finance-services-lease/view'>
                            <FinanceLeaseDetailScreen />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
        </>
    );
}

const FinanceServiceRequestLeaseWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <FinanceServiceRequestLeaseRoutes />
        </>
    )
};

export {FinanceServiceRequestLeaseWrapper}
