export const ssoUserDetails = (userDetail: any, accessToken: any) => {
    const t = JSON.parse(userDetail)?.access_token != undefined ? JSON.parse(userDetail)?.access_token : accessToken
    const d = JSON.parse(userDetail)?.id_role
    const token = btoa(t)
    const roleId = btoa(d)
    const URL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_WEB_URL}?t=${token}&d=${roleId}` : `${process.env.REACT_APP_WEB_URL_LOCAL}?t=${token}&d=${roleId}`
    return URL
}
export const ssoUserDetailswithLocations = (userDetail: any, accessToken: any, location:any) => {
    const t = JSON.parse(userDetail)?.access_token != undefined ? JSON.parse(userDetail)?.access_token : accessToken
    const d = JSON.parse(userDetail)?.id_role
    const l = btoa(location)
    const token = btoa(t)
    const roleId = btoa(d)
    const URL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_WEB_URL}?t=${token}&d=${roleId}&l=${l}` : `${process.env.REACT_APP_WEB_URL_LOCAL}?t=${token}&d=${roleId}&l=${l}`
    return URL
}