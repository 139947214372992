import React from 'react'
import ban01 from '../../../../assets/images/lease-banner.png'
import { Link } from 'react-router-dom'
import moment from 'moment'
import SampleBanner from "../../../../assets/images/lease-banner.png";

export function WelcomeBanner({ contract }: any) {
  let userName = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
  }

  return (
    <div className='row mb-md-0 mb-7'>
      <div className='col-12 col-md-6 col-lg-5'>
        <div className='card mb-7'>
          <div className='card-body px-8 py-0 bg-gradient-primary-card'>
            <div className='row p-4 p-xl-0'>
              <div className='col-xl-8 col-12'>
                <div className='my-6 move-in-main-div'>
                  <h6 className='fs-6 text-capitalize text-white fw-bold mb-xl-10 mb-6'>
                    Welcome, <span className='fw-700'> {userName} </span>
                  </h6>
                  <h6 className='fs-5 text-capitalize text-gray-400 fw-bolder'>
                    {' '}
                    Action Required{' '}
                  </h6>
                  <h2 className='text-white mb-0 fw-bold'>Request For Move-in</h2>
                </div>
              </div>
              <div className='col-xl-4 col-12 text-right my-auto px-lg-0'>
                <Link to={'/move-in'} className='btn btn-lg btn-light rounded w-100 move-in-link'>
                  Move in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-6 col-lg-7'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='lease-banner'>
              <img
                src={contract?.propertyImage? contract?.propertyImage : SampleBanner}
                alt='Banner' 
                className='img-fluid w-100' 
              />
            </div>
          </div>
          <div className='col-xl-9 offset-xl-3 col-md-12'>
            <div className='card lease-card mt-n20 mx-3'>
              <div className='card-body px-5 pt-4 pb-3'>
                <div className='row'>
                  <div className='col-md-4 col-6 mb-5'>
                    <h6 className='text-theme-gray mb-2 fw-bold fs-7'> Lease Number </h6>
                    <span className='text-black fw-bolder fs-7'> {contract?.contractNo} </span>
                  </div>
                  <div className='col-md-4 col-6 mb-5'>
                    <h6 className='text-theme-gray mb-2 fw-bold fs-7'> Lease Start Date </h6>
                    <span className='text-black fw-bolder fs-7'>
                      {' '}
                      {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                    </span>
                  </div>
                  <div className='col-md-4 col-6 mb-5'>
                    <h6 className='text-theme-gray mb-2 fw-bold fs-7'> Lease End Date </h6>
                    <span className='text-black fw-bolder fs-7'>
                      {' '}
                      {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                    </span>
                  </div>
                  <div className='col-md-4 col-6'>
                    <h6 className='text-theme-gray mb-2 fw-bold fs-7'> Status </h6>
                    <span className='text-black fw-bolder fs-7'> {contract?.contractStatus} </span>
                  </div>
                  <div className='col-md-4 col-6'>
                    <h6 className='text-theme-gray mb-2 fw-bold fs-7'> Annual Rent </h6>
                    <span className='text-black fw-bolder fs-7'> AED {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                  </div>
                  <div className='col-md-4 col-6'>
                    <h6 className='text-theme-gray mb-2 fw-bold fs-7'> Security Deposit </h6>
                    <span className='text-black fw-bolder fs-7'> AED {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
