import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import Loader from '../../../../../components/Loader'
import {updateAccountStatus} from '../../../../../constants/axios/apis'
import {useTranslation} from 'react-i18next'

export function UpdateAccountStatus({teamDetailData, setAccountStatusMsg}: any) {
  const {t} = useTranslation()
  const [initAccountStatus, setInitAccountStatus] = useState<any>(false)
  const [accountStatus, setAccountStatus] = useState<any>(false)
  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    message: '',
  })
  const successRef: any = useRef(null)
  const history = useHistory()

  useEffect(() => {
    setSubmitStatus({
      loading: false,
      error: false,
      message: '',
    })
    setInitAccountStatus(!(teamDetailData?.data.status === 0))
    setAccountStatus(!(teamDetailData?.data.status === 0))
  }, [teamDetailData?.data])

  const handelStatusChange = (e: any) => {
    const updatedStatus = e.target.checked ? '1' : '0'
    setAccountStatus(e.target.checked)
    setSubmitStatus((oStatus: any) => ({...oStatus, loading: true, error: false}))

    const formData = new FormData()
    formData.append('id', teamDetailData?.data?.id)
    formData.append('status', updatedStatus)

    updateAccountStatus(formData)
      .then((response: any) => {
        if (response.errorCode === 1) {
          setAccountStatus(initAccountStatus)
          setSubmitStatus((oStatus: any) => ({
            ...oStatus,
            loading: false,
            error: true,
            message: response.errorDescription,
          }))
        } else if (response.errorCode === 0) {
          setSubmitStatus((oStatus: any) => ({
            ...oStatus,
            loading: false,
            message: response.description,
          }))
          successRef.current.click()
          setTimeout(() => {}, 3000)
        } else {
          setAccountStatus(initAccountStatus)
          setSubmitStatus((oStatus: any) => ({
            ...oStatus,
            loading: false,
            message: t('Some error occured!'),
            error: true,
          }))
        }
      })
      .catch((e) => {
        setAccountStatus(initAccountStatus)
        setSubmitStatus((oStatus: any) => ({
          ...oStatus,
          loading: false,
          message: t('Some error occured!'),
          error: true,
        }))
      })
  }

  useEffect(() => {
    setAccountStatusMsg(submitStatus.message)
  }, [submitStatus.message])

  return (
    <>
      <div className='mt-2 d-flex justify-content-center'>
        <div className='form-check form-switch d-inline'>
          <input
            className='form-check-input mt-3'
            type='checkbox'
            id='status'
            checked={accountStatus}
            onChange={(e) => handelStatusChange(e)}
          />
          {!accountStatus ? (
            <label
              className='form-check-label font-16 bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
              htmlFor='status'
            >
              {t('Inactive')}
            </label>
          ) : (
            <label
              className='form-check-label font-16 bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
              htmlFor='status'
            >
              {t('Active')}
            </label>
          )}{' '}
        </div>
      </div>
      {submitStatus.loading ? (
        <Loader />
      ) : submitStatus.error ? (
        <p className='text-danger font-12 mt-3 mb-0 text-center'>{submitStatus.message}</p>
      ) : (
        ''
      )}
      <p
        ref={successRef}
        data-bs-target='#req-submitted-popup-account'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='d-none m-0 p-0'
      ></p>
    </>
  )
}
