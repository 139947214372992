import React, { useState, useEffect } from 'react'

export function Doccuments() {
    return (      
        <>
       
            <div className="row mt-4 form-add-design">
                <div className="col-3">
                    <div className="mb-7">
                        <div className="form-group">
                            <label className="d-block w-100 text-uppercase mb-0 font-13">&nbsp; </label>
                            <div className="w-100">
                                <button className="upload-button px-20 py-3 rounded font-12 d-block bg-white border mt-3"> Choose a file </button>
                                <input className="form-control file-ss" type="file" id="formFile"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="mb-7">
                        <label className="form-label fw-bolder text-uppercase">Expiry Date</label>
                        <input type="date" className="form-control fw-bolder form-control-solid" placeholder="Community Name" />
                    </div>
                </div>
                <div className="col-md-3">
                <div className="mb-7">
                    <label className="form-label fw-bolder text-uppercase">Doccuments Type</label>
                    <select className="form-control form-select fw-bolder form-control-solid"
                        >
                        <option > Driving Lisence</option>
                        <option>Emrites ID </option>
                    </select>
                </div>
            </div>
            <div className="col-12 text-end mb-4">
                <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                <i className="fas fa-cloud-upload-alt me-2"></i>Upload
                </button>
            </div>
                <div className="col-md-12 border">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center py-4">
                            <i className="fas fa-file-alt text-gray-400 font-44"></i>
                            <h3 className="text-gray-400 mt-4 mb-0"> No Doccuments found </h3>
                        </div>
                    </div>
                </div>

            </div>        
       
    </>
    )
}

