import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {QuickLinks} from './components/QuickLinks/QuickLinks'
import { useTranslation } from 'react-i18next'
import { Sales } from './components/Sales/Sales'
import { OffersPipeline } from './components/OffersPipeline/OffersPipeline'
import { Properties } from './components/Properties/Properties'
import { RecentActivity } from './components/RecentActivity/RecentActivity'
import { CalenderCard } from '../pm-calender/components/CalenderCard/CalenderCard'
import { GraphWrapper } from './components/Graphs/GraphWrapper'
const PMDashboardPage: FC = () => (
  
  <>
    <div className='row dashboard-page'>
        <div className="row">
            <div className="col-md-12 col-12">
                <h4 className="card-title font-18 text-black mb-3"> Dashboard </h4>
                <h2 className="font-22 text-dark font-weight-bold mb-5">Welcome, Property Manager</h2>
            </div>
        </div>
           
        {/* <PersonalInformation /> */}
        {/* <AnnouncementsAndMyTeam /> */}
        <div className="row mb-5">
          <div className="col-8">
            <Sales/>
            <QuickLinks />
            <OffersPipeline/>
            <GraphWrapper/>
          </div>
          <div className="col-4">
            <Properties />
            <RecentActivity/>
          </div>
        </div>
        <div className='row'>
          <div className="col-12">
              <CalenderCard />
          </div>
        </div>
    </div>
  </>
);

const PropertyManagerDashboardWrapper: FC = () => {
  const intl = useIntl();
  const { t } = useTranslation();
  return (
    <>
      <PageTitle breadcrumbs={[]}> Property Manager Dashboard </PageTitle>
      <PMDashboardPage />
    </>
  )
};

export {PropertyManagerDashboardWrapper}
