import React, {FC} from 'react'
import { useTranslation } from 'react-i18next'

const StepTwo: FC = () => {
    const { t } = useTranslation();

    return (
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-md-12 mb-10">
                    <div className="row form-add-design">
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder">{t("Name")}</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Enter")} />
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder">{t("Contact Number")}</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Enter")} />
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder">{t("Relation")}</label>
                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder={t("Enter")} />
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder">{t("Nationality")}</label>
                                <select className="form-control form-select fw-bolder form-control-solid">
                                    <option selected disabled>{t("Please Select")}</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export {StepTwo}
