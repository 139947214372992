import Map from "./Map";

export function NearBy() {
    return (      
        <>
        <div id="near-by-loc" className="near-by-loc">
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-3">
                        <div className="card-header px-5">
                            <div className="row align-items-center">
                                <div className="col-xl">
                                    <h4 className="font-16 mb-0 font-weight-semibold text-black"> Near by Locations & Places </h4>
                                </div>
                                <div className="col-xl-auto">
                                    <div className="place-names nearByPlace overflow-auto py-2">
                                        <ul className="nav nav-tabs m-content border-bottom-0 pl-1" id="nav-tab">
                                            <li className="nav-item m-0 cr-pointer">
                                                <div className="custom-control custom-checkbox me-4">
                                                    <input type="checkbox" className="form-check-input nearByPlace me-2" value="school" name="nearByPlace" id="school"/>
                                                    <label className="custom-control-label font-weight-600 font-14" htmlFor="school"> School </label>
                                                </div>
                                            </li>
                                            <li className="nav-item m-0 cr-pointer">
                                                <div className="custom-control custom-checkbox me-4">
                                                    <input type="checkbox" className="form-check-input nearByPlace me-2" value="shopping_mall" name="nearByPlace" id="malls"/>
                                                    <label className="custom-control-label font-weight-600 font-14" htmlFor="malls"> Malls </label>
                                                </div>
                                            </li>
                                            <li className="nav-item m-0 cr-pointer">
                                                <div className="custom-control custom-checkbox me-4">
                                                    <input type="checkbox" className="form-check-input nearByPlace me-2" value="amusement_park" name="nearByPlace" id="outdoor"/>
                                                    <label className="custom-control-label font-weight-600 font-14" htmlFor="outdoor"> Outdoors & Recreation </label>
                                                </div>
                                            </li>
                                            <li className="nav-item m-0 cr-pointer">
                                                <div className="custom-control custom-checkbox me-4">
                                                    <input type="checkbox" className="form-check-input nearByPlace me-2" value="restaurant" name="nearByPlace" id="restaurant"/>
                                                    <label className="custom-control-label font-weight-600 font-14" htmlFor="restaurant"> Restaurant </label>
                                                </div>
                                            </li>
                                            <li className="nav-item m-0 cr-pointer">
                                                <div className="custom-control custom-checkbox me-4">
                                                    <input type="checkbox" className="form-check-input nearByPlace me-2" value="hospital" name="nearByPlace" id="hospital"/>
                                                    <label className="custom-control-label font-weight-600 font-14" htmlFor="hospital"> Hospital </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-5">
                            <div className="row my-3">
                                <div className="col-12">
                                    <Map/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

