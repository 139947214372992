import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {PMButtons} from '../../../pm-unrenewed-leases/components/PMButtons/PMButtons';
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { useTranslation } from 'react-i18next'

const OffersAndRenewalsTab: React.FC = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center mb-6">
                        <div className="col-lg-3 col-sm-12">
                            <h4 className="card-title font-18 text-black mb-0">
                                {/* <Link to={'/'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link> */}
                                {t("Offers and Renewals")}
                            </h4>
                        </div>
                        {/* Todo check */}
                        {/* <div className="col-lg-9 col-sm-12">
                            <PMButtons />
                        </div> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-7 py-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex h-55px overflow-x'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-offers-and-renewals/offer' && 'active')}
                                                    to='/pm-offers-and-renewals/offer'>
                                                    {t("Offer")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/pm-offers-and-renewals/renewals' && 'active')}
                                                    to='/pm-offers-and-renewals/renewals'>
                                                    {t("Renewals")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {OffersAndRenewalsTab}
