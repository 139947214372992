
import React, { FC, useState } from 'react';
import { Basic } from './Basic';
import { Unit } from './Unit';
import { Price } from './Price';
import { Media } from './Media';
import { AdditionalInfo } from './AdditionalInfo';
import { Link } from 'react-router-dom';

export function CreateUnit() {
    const [tabPosition, setTabPosition] = useState("1");
    const chooseMessage = (message:any) => {
        console.log(message);
        setTabPosition(message);
    };
    const buildingTab = (i: any) => {
        setTabPosition(i)
    }
    return (
        <>            
            <div className="row">
                <div className="col-md-12">
                    <div className="col-12">
                        <div className='d-flex step_tab overflow-auto'>
                            <ul className='nav justify-content-between mb-4 w-100 flex-nowrap border bg-white rounded-3'>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '1' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(1)}>
                                        Basic 
                                    </Link>
                                </li>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '2' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(2)}>
                                        Unit
                                    </Link>
                                </li>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '3' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(3)}>
                                        Price
                                    </Link>
                                </li>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '4' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(4)}>
                                        Media
                                    </Link>
                                </li>
                                <li className='nav-item w-100 text-center position-relative'>
                                    <Link to className={`${tabPosition == '5' ? 'active' : ''} nav-link py-5 text-dark `} onClick={() => buildingTab(5)}>
                                        Additional Info 
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {   tabPosition == '1' ? <Basic chooseMessage={chooseMessage}/> : 
                            tabPosition == '2' ? <Unit chooseMessage={chooseMessage}/> :
                            tabPosition == '3' ? <Price chooseMessage={chooseMessage}/> :
                            tabPosition == '4' ? <Media chooseMessage={chooseMessage}/> :
                            tabPosition == '5' ? <AdditionalInfo chooseMessage={chooseMessage}/> : ''
                        }

                    </div>
                </div>
            </div>         
                        
        </>
    )
}