import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
export const actionTypes = {
  OfferDetails: '[OD] Action',
  ModalMessage: '[MM] Action',
}
// ** Define initialOffer **
const initialOfferState: IOfferState = {
  offerdetailsdata: undefined,
  modalmessage: undefined,
}
export interface IOfferState {
  offerdetailsdata?: ''
  modalmessage?: ''
}
export const reducer = persistReducer(
  {storage, key: 'offer', blacklist: ['offerdetailsdata', 'modalmessage']},

  (state: IOfferState = initialOfferState, action: ActionWithPayload<IOfferState>) => {
    switch (action.type) {
      case actionTypes.OfferDetails: {
        const offerdetailsdata = action.payload?.offerdetailsdata
        return {...state, offerdetailsdata: offerdetailsdata}
      }
      case actionTypes.ModalMessage: {
        const modalmessage = action.payload?.modalmessage
        return {...state, modalmessage: modalmessage}
      }
      default:
        return state
    }
  }
)
export const actions = {
  offerdetailsdata: (offerdetailsdata: any) => ({
    type: actionTypes.OfferDetails,
    payload: {offerdetailsdata},
  }),
  modalmessage: (modalmessage: any) => ({
    type: actionTypes.ModalMessage,
    payload: {modalmessage},
  }),
}
