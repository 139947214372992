import React, { useState, useEffect } from 'react'

export function EmailSettings() {
    return (
        <>
            <div className="col-md-12 mt-7">
                <h6 className="text-black mb-3"> Set Email Access </h6>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">Account<i className="fas fa-info-circle ps-1"></i></label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select" required>

                                    <option selected> Outlook</option>
                                    <option > Gmail</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> USERNAME  <i className="fas fa-info-circle ps-1"></i></label>
                                    <input type="text" className="form-control form-height font-13" placeholder="" value="shrikara.s@exalogic.co" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Password  <i className="fas fa-info-circle ps-1"></i></label>
                                    <input type="text" className="form-control form-height font-13" placeholder="" value="*******" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> READ MAILS FROM  <i className="fas fa-info-circle ps-1"></i></label>
                                    <input type="text" className="form-control form-height font-13" placeholder="" value="noreply@email.teams.microsoft.com" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

