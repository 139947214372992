import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {UpcomingApprovalTabs} from './UpcomingApprovalTabs/UpcomingApprovalTabs'
import {MoveIn} from './MoveIn/MoveIn'
import {MoveOut} from './MoveOut/MoveOut'

export function UpcomingApprovals({
  approvals,
  totalApprovals,
  handleStatus,
  handleSubType,
  loading,
  currentPage, setCurrentPage
}: any) {
  handleStatus('pending')
  // handleApproveStatus("Approved");
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3 movein-moveout'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <Switch>
                    <Route path='/approvals/upcoming/movein'>
                      <UpcomingApprovalTabs />
                      <MoveIn
                        // approvals={approvals?.filter(
                        //   (approval: any) => approval.subReference === 5
                        // )}
                        approvals={approvals}
                        totalApprovals={totalApprovals}
                        handleSubType={handleSubType}
                        loading={loading}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Route>
                    <Route path='/approvals/upcoming/moveout'>
                      <UpcomingApprovalTabs />
                      <MoveOut
                        // approvals={approvals?.filter(
                        //   (approval: any) => approval.subReference === 6
                        // )}
                        approvals={approvals}
                        totalApprovals={totalApprovals}
                        handleSubType={handleSubType}
                        loading={loading}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
