import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CompactPagination } from '../../../components/partials/content/CompactPagination/CompactPagination'

const opportunitiesData = [{
    "refrence": "REF-L-1627018342",
    "stats": "Active",
    "substatus": "Offer Made",
    "leadtype": "Buyer",
    "firstName": "Radha",
    "lastName": "Krishna",
    "mobileNo": "9900607232",
    "sorce": "Goggle",
    "endate": "23/07/2121",
    "redate": "23/07/2121"
},
{   
    "refrence": "REF-L-1627019194",
    "stats": "Active",
    "substatus": "Offer Made",
    "leadtype": "Buyer",
    "firstName": "	Sam",
    "lastName": "	smith",
    "mobileNo": "9900607232",
    "sorce": "Goggle",
    "endate": "23/07/2121",
    "redate": "23/07/2121"
},
{
    "refrence": "REF-UN-1606102703",
    "stats": "Active",
    "substatus": "Offer Made",
    "leadtype": "Buyer",
    "firstName": "	shiva",
    "lastName": "k",
    "mobileNo": "9900607232",
    "sorce": "Facebook",
    "endate": "23/07/2121",
    "redate": "23/07/2121"
},
{
    "refrence": "REF-UN-1606119822",
    "stats": "Active",
    "substatus": "Offer Made",
    "leadtype": "Buyer",
    "firstName": "Keerthana",
    "lastName": "P",
    "mobileNo": "Goggle",
    "sorce": "Wilton Park Residencies 3",
    "endate": "23/07/2121",
    "redate": "23/07/2121"
},
{
    "refrence": "REF-UN-1606137807",
    "stats": "Active",
    "substatus": "Qualified",
    "leadtype": "	Lease",
    "firstName": "Rakshit",
    "lastName": "AS",
    "mobileNo": "9900607232",
    "sorce": "Goggle",
    "endate": "23/07/2121",
    "redate": "23/07/2121"
},
{
    "refrence": "REF-UN-1606137885",
    "stats": "Active",
    "substatus": "Qualified",
    "leadtype": "	Lease",
    "firstName": "	Ketan",
    "lastName": "D",
    "mobileNo": "9900607232",
    "sorce": "Goggle",
    "endate": "23/07/2121",
    "redate": "23/07/2121"
},
];

export function OpportunitiesListing() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    return (
        <>
            <div className='row align-items-center mb-5'>
                <div className="col">
                    <h3 className=''>Opportunities List</h3>
                </div>
                <div className="col-auto text-end">
                    <Link to='/opportunites/add' className='btn btn-primary fw-bold px-10 py-3'>
                        Add New Opportunities
                    </Link>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">20</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Under Offer</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">2</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">offer Accepted</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">6</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Qualified</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Lost</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <div className="card unit-box overflow-hidden d-flex flex-wrap align-items-center h-100">
                        <div className="bg-white border-0 text-center my-auto">
                            <h3 className="d-inline-block theme-color font-weight-bold mb-0">0</h3>
                            <a href="javascript:void(0);" className="text-warning font-weight-bold mb-0 font-13 d-block text-decoration-none">Won</a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="card">
                <div className="card-body">
                    <div className="row form-add-design">
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> REF </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Ref" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">Status</label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select" required>
                                    <option selected> Please Select </option>
                                    <option> Open</option>
                                    <option > Qualified</option>
                                    <option> In Progress </option>
                                    <option> Hold</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">Lead Type</label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select" required>
                                    <option selected> Please Select </option>
                                    <option> Buyer</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Fist Name </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Fist Name" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Last Name </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Last Name" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <div className="form-group">
                                    <label className="d-block w-100 text-uppercase font-13"> Mobile No </label>
                                    <input type="text" className="form-control form-height font-13" placeholder="Mobile No" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-7">
                                <label className="d-block w-100 text-uppercase font-13">Source</label>
                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select" required>
                                    <option selected> Please Select </option>
                                    <option >Other</option>
                                    <option>Face Book</option>
                                    <option>Twitter</option>
                                    <option>Instagram</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className='btn btn-outline-primary fw-bold px-10 py-3 me-3'>
                                Reset
                            </button>
                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row align-items-center my-5 table-drop'>
                <div className="col-10"></div>
                <div className="col-2 text-end d-flex align-items-center">
                    <span className="me-2">Show</span>
                    <select className="form-control form-select">
                        <option>10 </option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                    <span className="ms-2">entries</span>
                </div>
            </div>

            <div className='com-card-section'>
                <div className='table-crm'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='table-responsive'>
                                <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                    <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                        <tr>
                                            <th> Ref</th>
                                            <th> Status </th>
                                            <th> Sub Status </th>
                                            <th> Lead Type</th>
                                            <th> Fist Name  </th>
                                            <th> Last Name </th>
                                            <th> Mobile No </th>
                                            <th> Source </th>
                                            <th> ENQUIRY DATE </th>
                                            <th> RECENT ACTION ON </th>
                                            <th> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {opportunitiesData.length > 0 ? opportunitiesData.map((item, i) => (
                                            <tr key={i}>
                                                <td className="text-warning">{item.refrence}</td>
                                                <td>{item.stats}</td>
                                                <td>{item.substatus}</td>
                                                <td>{item.leadtype}</td>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.mobileNo}</td>
                                                <td>{item.sorce}</td>
                                                <td>{item.endate} </td>
                                                <td>{item.redate}</td>
                                                <td className="action-button text-end">
                                                    <div className="dropdown">
                                                        <button type="button" className="btn border-0 rounded-0 p-0 dropdown-toggle dropdown-toggle-split font-16" data-bs-toggle="dropdown">
                                                            <i className="fa fa-ellipsis-v text-black"></i>
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <ul className="d-flex list-unstyled m-0 justify-content-center">
                                                                
                                                                <li>
                                                                    <Link to="/opportunites/edit" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-edit"></i>
                                                                        <p className="m-0">Edit</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/opportunites/send/offer" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fas fa-paper-plane"></i>
                                                                        <p className="m-0">Send</p>
                                                                    </Link>
                                                                </li>
                                                               
                                                                <li>
                                                                    <Link to className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-share"></i>
                                                                        <p className="m-0">Share</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/opportunites/preview" className="d-inline-block text-decoration-none text-center px-sm-4 px-1 py-2">
                                                                        <i className="fa fa-eye"></i>
                                                                        <p className="m-0">Preview</p>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr><td colSpan={3}>No data..</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='w-100 d-flex justify-content-end'>
                                <CompactPagination
                                    pageSize={pageSize}
                                    totalCount={opportunitiesData.length}
                                    defaultPage={currentPage}
                                    onPageChange={setCurrentPage}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
