import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
// import MyPic from '../../../../assets/images/avatar.jpg';
// import Avatar from '../../../../assets/images/SquareAvatar.png';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import MyPic from '../../../../assets/images/DefaultUser.png';

import { useTranslation } from 'react-i18next';
import { getMyTeamChartData, getMyTeamUserList } from '../../../../constants/axios/apis';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { useSelector } from 'react-redux';

export function TeamMember({teamRow, totalTeamList, loading, error}:any) {
    const { t } = useTranslation()

    return (
        <div className='col-xl-7 col-sm-12 col-12'>
            <div className='card min-height-card'>
                <div className='card-body px-5 py-2'>
                    <div className='row gx-3'>
                        <div className='col-12'>
                            <div className='row mt-5 align-items-center'>
                                <div className='col-6'>
                                    <h4 className='card-title font-18 text-black'>{t("Team Members")} </h4>
                                </div>
                                {totalTeamList > 3 ?
                                    <div className='col-6 text-end'>
                                        <Link to={'/mta-myteam/list-all'} className="text-decoration-none view-all">
                                            <span className='font-14'> {t("View All")} </span>
                                            <img src={RightArrow} className="img-fluid" />
                                        </Link>
                                    </div>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className='col-12 mt-5'>
                            <div className='row'>
                                {loading ?
                                    <div className="col-12">
                                        {/* <Loader /> */}
                                        <CustomLoader2 height={'254'} />
                                    </div> :
                                    <>
                                        {teamRow?.length ?
                                            teamRow?.map((member: any, index:number) => (
                                                <div className="col-lg-4 col-sm-12 col-md-4 col-12" key={index}>
                                                    <Link to={{ pathname: '/mta-myteam/view', state: { member_id: member.user_id } }}>
                                                        <div className="mta_team_members_card mb-5">
                                                            <div className="card px-3 py-5" id="team_member" style={{ minHeight: '260px' }}>
                                                                <div className="row gx-2">
                                                                    <div className="col-sm-4 col-4">
                                                                        <div className="offer_main_img_new">
                                                                            <img src={member.profileImage ? member.profileImage : MyPic} alt="profile_img" className="img-fluid rounded-circle" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-8 col-8">
                                                                        <div className="offer_main_details_new">
                                                                            <div className="">
                                                                                <p className="text-black fw-bolder font-12 mb-0 mt-3">{member.first_name + ' ' + member.last_name} </p>
                                                                                {/* <p className="member-status active mb-0">{t("Status")}</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            <div className="col-12 mt-5">
                                                                                <p className="mb-0 text-normal">{t("Email ID")} :</p>
                                                                                <p className="mb-0 text-black">{member.email}</p>
                                                                            </div>
                                                                            <div className="col-12 mt-3">
                                                                                <p className="mb-0 text-normal">{t("Contact No.")} :</p>
                                                                                <p className="mb-0 text-black">{member?.mobile}</p>
                                                                            </div>
                                                                            <div className="col-12 mt-3">
                                                                                <p className="mb-0 text-normal">{t("Status")} :</p>
                                                                                {/* <p className="mb-0 text-black">-</p> */}
                                                                                <p className={`member-status ${member.status === 0 ? 'inactive' : 'active'} mb-0`}>
                                                                                    {member?.status === 0 ? (t('Inactive')) : (t('Active'))}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                            :
                                            <NoDataFound1 height={'197'} />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}