import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import MyPic from '../../../../assets/images/my_img.png';
import { getMyTeamChartData } from '../../../../constants/axios/apis';
import MyPic from '../../../../assets/images/DefaultUser.png';


export function MyTeam() {
    const { t } = useTranslation();

    const [chartData, setChartData] = useState<any>({ active: 0, inactive: 0, total: 0 })
    const [teamRow, setTeamRow] = useState<any>([]);
    // const [totalTeamList, setTotalTeamList] = useState<any>(0);
    const [loading, setLoading] = useState<any>(true)
    // const [error, setError] = useState<any>('')

    useEffect(() => {
        getMyTeamChartData('?user_role=MT&id_sort=1&currentPage=1&pageSize=4')
            .then((response: any) => {
                if (response?.errorCode === 0) {
                    if (response?.data?.teamList?.length) {
                        // setTotalTeamList(response?.dataCount)
                        setTeamRow(response?.data?.teamList)
                    }
                }
                setLoading(false)
            })
            .catch((e) => setLoading(false))
    }, [])

    return (
        <div className="market-team-myteam">
            <div className="col-12 mt-3 mb-5 p-0">
                <div className="row align-items-flex-end">
                    <div className="col-6">
                        <h4 className="card-title font-18 text-black mb-0">{t("My Team")}</h4>
                    </div>
                    <div className="col-6">
                        <Link to={'/mta-myteam/list'} className="text-decoration-none text-end text-dark font-13 d-block mt-2">
                            <span> {t("View All")} </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    {teamRow?.length ?
                        teamRow?.map((member: any, index: number) => (
                            <div className="col-sm-6 col-12 ps-sm-0" key={index}>
                                <Link to={{ pathname: '/mta-myteam/view', state: { member_id: member.user_id } }}>
                                    <div className="market-team-myteam-card mb-5">
                                        <div className="card py-2 px-2">
                                            <div className="row g-0">
                                                <div className="col-2">
                                                    <div className="mt-2">
                                                        <img src={member?.profileImage ? member.profileImage : MyPic} alt="image" className="img-fluid rounded-circle" />
                                                    </div>
                                                </div>
                                                <div className="col-10">
                                                    <div className="ps-2">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h4 className="card-title font-12 font-bolder text-black">{member.first_name + ' ' + member.last_name}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <p className="mb-0 text-black">{t("Email ID")} : {member.email}</p>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <p className="mb-0 text-black">{t("Contact No.")} : {member?.mobile}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                        : <></>
                    }
                    {/* <div className="col-sm-6 col-12 ps-sm-0">
                        <Link to={'/'}>
                            <div className="market-team-myteam-card mb-5">
                                <div className="card py-2 px-2">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <div className="cardimg mt-2">
                                                <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="ps-2">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="card-title font-12 font-bolder text-black">Mohammad</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-5 pe-0">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Member ID")} :</h4>
                                                                <p className="mb-0 text-black">546454</p>
                                                            </div>
                                                            <div className="col-7">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Contact No.")}</h4>
                                                                <p className="mb-0 text-black">+971 6655855555</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-sm-6 col-12 pe-sm-0">
                        <Link to={'/'}>
                            <div className="market-team-myteam-card mb-5">
                                <div className="card py-2 px-2">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <div className="cardimg">
                                                <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="ps-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="font-12 font-bolder text-black">Mohammad</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-5 pe-0">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Member ID")} :</h4>
                                                                <p className="mb-0 text-black">546454</p>
                                                            </div>
                                                            <div className="col-7">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Contact No.")}</h4>
                                                                <p className="mb-0 text-black">+971 6655855555</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-sm-6 col-12 ps-sm-0">
                        <Link to={'/'}>
                            <div className="market-team-myteam-card mb-5">
                                <div className="card py-2 px-2">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <div className="cardimg">
                                                <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="ps-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="font-12 font-bolder text-black">Mohammad</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-5 pe-0">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Member ID")} :</h4>
                                                                <p className="mb-0 text-black">546454</p>
                                                            </div>
                                                            <div className="col-7">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Contact No.")}</h4>
                                                                <p className="mb-0 text-black">+971 6655855555</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-sm-6 col-12 pe-sm-0">
                        <Link to={'/'}>
                            <div className="market-team-myteam-card mb-5">
                                <div className="card py-2 px-2">
                                    <div className="row g-0">
                                        <div className="col-3">
                                            <div className="cardimg">
                                                <img src={MyPic} alt="Work Orders" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="ps-3">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="font-12 font-bolder text-black">Mohammad</h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-5 pe-0">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Member ID")}:</h4>
                                                                <p className="mb-0 text-black">546454</p>
                                                            </div>
                                                            <div className="col-7">
                                                                <h4 className="fw-normal font-12 text-black heading-card">{t("Contact No.")}</h4>
                                                                <p className="mb-0 text-black">+971 6655855555</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
}