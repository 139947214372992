import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

export function Google(props:any) {
    const proprtyDetails = props.proprtyDetails
    const containerStyle = {
        width: '100',
        height: '200px',
        borderRadius:'10px'
    };

    // const center = {
    //     lat: proprtyDetails?.latitude,
    //     lng:  proprtyDetails?.longitude
    // };
    const center = {
        lat: -3.745,
        lng: -38.523
      };
      
    return (
        <div className="mt-3">
            <LoadScript
                googleMapsApiKey='https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyACeJypAEzMS6WJRSd2S5iHjY-LeVMwUbs&libraries=geometry,drawing,places'
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={14}
                >
                    <Marker
                        draggable={true}
                        position={ center }
                    />
                </GoogleMap>                
            </LoadScript>
        </div>
    )
};