import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { AddAccounts } from './Accounts/AddAccounts'
import { AccountsList } from './Accounts/AccountsList'
import { EditAccounts } from './Accounts/EditAccounts'

const PMAccountsList: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                    <Route path='/accounts/add'>
                            <AddAccounts/>
                        </Route>
                        <Route path='/accounts/list'>
                            <AccountsList />
                        </Route>
                        <Route path='/accounts/edit'>
                            <EditAccounts />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMAccountsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMAccountsList/>
        </>
    )
};

export { PMAccountsWrapper }

