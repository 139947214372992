import React, { useState, useEffect } from 'react';
import clsx from 'clsx'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Excel from "../../../../assets/images/xls.svg";
import PDF from "../../../../assets/images/pdf.svg";
import Links from "../../../../assets/images/External_Link.svg";
import {Search} from '../Search/Search';
import {DeportmentBt} from '../DeportmentBt/DeportmentBt';
import {useLayout} from '../../../../components/layout/core'
import {SearchDetails} from '../SearchDetails/SearchDetails';
import {PMFilter} from '../PMFilter/PMFilter';
import {PMFilterPopup} from '../PMFilterPopup/PMFilterPopup';
import { useTranslation } from 'react-i18next'

const toolbarButtonMarginClass = 'btn btn-primary fw-bold px-5 py-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1'

export function PMButtons() {
    const {config} = useLayout()
    const { t } = useTranslation();

    let userEmail = "";
    let userDetail = localStorage.getItem("userDetail");
    if(userDetail){
        userEmail = JSON.parse(userDetail).email;
    };

    const intl = useIntl()
    const location = useLocation()
    const[menu, setMenu] = useState('');

    useEffect(() => {
        if (window.location.pathname != menu){
            setMenu(window.location.pathname)
        }
    });

    return (
        <div>
            { menu === '/pm-unrenewed-leases'
            || menu == "/pm-refunds/open-requests"
            || menu == "/pm-refunds/closed-requests"
            || menu == "/pm-leads/new-lead"
            || menu == "/pm-leads/qualified"
            || menu == "/pm-leads/rejected"
            || menu == "/pm-offers-and-renewals/renewals"
            || menu == "/pm-offers-and-renewals/offer" ?
                <div className="d-flex pm-topbar-buttons float-end">
                    {/* <div className="me-3">
                        <button className='btn btn-primary fw-bold px-2 py-2 font-12 h-100'>
                            <img src={Excel} className="img-fluid me-2"/> Share as Excel
                        </button>
                    </div>
                    <div className="me-3">
                        <button className='btn btn-primary fw-bold px-2 py-2 font-12 h-100'>
                            <img src={PDF} className="img-fluid me-2"/> Share as PDF
                        </button>
                    </div>
                    <div className="me-3">
                        <button className='btn btn-primary fw-bold px-2 py-2 font-12 h-100'>
                            <img src={Links} className="img-fluid me-2"/> Share as Link
                        </button>
                    </div> */}
                    <div className="">
                        <Search/>
                        <SearchDetails/>
                    </div>
                    <div className="">
                        <div
                            className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                            id='kt_header_user_menu_toggle'
                        >
                            <div
                                className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                                data-kt-menu-trigger='click'
                                data-kt-menu-attach='parent'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='bottom'
                            >
                            <span className="text-white font-14 fw-bold user-name"> {t("Department")}
                                <i className="bi bi-chevron-down text-white ms-5 ms-d-5"></i></span>
                            </div>
                            <DeportmentBt/>
                        </div>
                    </div>
                </div>
                :
                <div className="d-flex pm-topbar-buttons float-end">
                    <div>
                        <Search/>
                        <SearchDetails/>
                    </div>
                    <div className="me-3">
                        <div
                            className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                            id='kt_header_user_menu_toggle'
                        >
                            <div
                                className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                                data-kt-menu-trigger='click'
                                data-kt-menu-attach='parent'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='bottom'
                            >
                            <span className="text-white font-12 fw-bold user-name"> {t("Department")}
                                <i className="bi bi-chevron-down text-white ms-5 ms-d-5"></i></span>
                            </div>
                            <DeportmentBt/>
                        </div>
                    </div>
                    <div className="">
                        <PMFilter />
                        <PMFilterPopup />
                    </div>
                </div>
            }
        </div>
    );
}