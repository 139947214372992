import moment from 'moment';
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { createMarketingOffer, getMarketPlaceFiltered, marketPlaceList } from '../../../../constants/axios/apis';
import { getUserRole } from '../../../../constants/generics/userCheck';
import { Discounts } from '../Discounts/Discounts';
import { Redeemed } from '../Redeemed/Redeemed';
import { Total } from '../Total/Total';
import { Vouchers } from '../Vouchers/Vouchers';
import { MarketPlaceHeader } from './MarketPlaceHeader/MarketPlaceHeader';
import { MarketPlaceListing } from './MarketPlaceListing';
import { MarketPlaceStats } from './MarketPlaceStats/MarketPlaceStats';
import { MarketPlaceTabPrimary } from './MarketPlaceTabs/MarketPlaceTabPrimary';
import { MarketPlaceTabSecondary } from './MarketPlaceTabs/MarketPlaceTabSecondary';

export function MarketPlaceListWrapper() {
    const location = useLocation();
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    const [loading, setLoading] = useState<any>(true);
    const [dataCount, setDataCount] = useState<any>({
        event: '0',
        offer: '0',
        voucher: '0',
        total: '-',
        redeemed: '-',
        loading: true
    });

    const pageSize = 9
    const [totalCount, setTotalCount] = useState<any>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [payloadPage, setPayloadPage] = useState<any>(1)

    // useEffect(() => {
    //     // setLoading(true)
    //     const payload = ''
    //     getMarketPlaceFiltered(payload).then(({ data }: any) => {
    //         if (data && Array.isArray(data)) {
    //             // const futureEvents = data.filter((item:any)=> (moment().diff(item?.end_date) < 0))
    //             // const notRedemedEvents = futureEvents.filter((item:any)=> !(item?.redeemed_users && Array.isArray(item?.redeemed_users) && item?.redeemed_users.length && item?.redeemed_users.includes(user_id)))
    //             const offerList = data.filter((item: any) => item.type === 'Offer')
    //             const voucherList = data.filter((item: any) => item.type === 'Voucher')
    //             const eventList = data.filter((item: any) => item.type === 'Event')

    //             setDataCount((oState: any) => ({
    //                 ...oState,
    //                 event: eventList?.length,
    //                 offer: offerList?.length,
    //                 voucher: voucherList?.length,
    //                 total: data.length,
    //             }));

    //         } else {

    //         }
    //         // setLoading(false)
    //     })
    // }, []);


    const [allList, setAllList] = useState<any>([]);

    useEffect(() => {
        let newFilters = ''
        const isAdminUser = user_role ? ['MA', 'MT'].includes(user_role) : false

        let activeLink = location.pathname
        let mainLink = activeLink.substring(activeLink.lastIndexOf("/list/") + 6, activeLink.lastIndexOf("/"))
        let subLink = activeLink.substring(activeLink.lastIndexOf("/") + 1, activeLink.length)

        const mpType = (mainLink === 'events' ? 3 : mainLink === 'offers' ? 1 : mainLink === 'vouchers' ? 2 : mainLink === 'redeemed' ? 4 : undefined)
        const isRedeemed = (mainLink === 'redeemed' ? 1 : undefined)
        const category = (subLink === 'dining' ? 20 : subLink === 'lifestyle' ? 21 : subLink === 'entertainment' ? 22 : undefined)
        const approvalStatus = (subLink === 'active' || category !== undefined || !isAdminUser ? 'approved' : subLink === 'inactive' ? 'pending' : undefined)
        const isExpired = (category !== undefined || approvalStatus !== undefined ? 0 : subLink === 'expired' ? 1 : undefined)

        if (mpType !== undefined)
            newFilters += '&flag=' + mpType
        if (isRedeemed !== undefined)
            newFilters += '&is_redeemed=' + isRedeemed
        if (category !== undefined)
            newFilters += '&category=' + category
        if (approvalStatus !== undefined)
            newFilters += '&approvalStatus=' + approvalStatus
        if (isExpired !== undefined)
            newFilters += '&is_expired=' + isExpired

        setLoading(true)
        setAllList([])
        setTotalCount(0)
        const payload = '?currentPage=' + currentPage + '&pageSize=' + pageSize + '&id_sort=0' + newFilters
        marketPlaceList(payload)
            .then(({ data, dataCount }: any) => {
                if (data && Array.isArray(data) && dataCount) {
                    setAllList(data)
                    setTotalCount(dataCount?.dataCount ? dataCount?.dataCount : 0)
                    setDataCount((oState: any) => ({
                        ...oState,
                        event: dataCount?.events,
                        offer: dataCount?.offers,
                        voucher: dataCount?.vouchers,
                        total: dataCount?.dataCount,
                        loading: false
                    }));
                }
                setLoading(false)
            })
            .catch((e) => setLoading(false))
    }, [location.pathname, currentPage]);

    useEffect(() => {
        setPayloadPage(1)
        // setCurrentPage(1)
    }, [location.pathname]);

    // useEffect(() => {
    //     setPayloadPage(currentPage)
    // }, [currentPage]);

    return (
        <>
            <MarketPlaceHeader dataCount={dataCount} />
            <MarketPlaceTabPrimary />
            <div className="com-card-section">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3 movein-moveout">
                            <div className="card-body px-8 py-6">
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <Switch>
                                            <Route path='/market-place/list/events'>
                                                <MarketPlaceTabSecondary />
                                                {/* <Total /> */}
                                                <MarketPlaceListing marketPlaceList={allList} loading={loading} totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                            </Route>
                                            <Route path='/market-place/list/offers'>
                                                <MarketPlaceTabSecondary />
                                                <MarketPlaceListing marketPlaceList={allList} loading={loading} totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                {/* <Discounts /> */}
                                            </Route>
                                            <Route path='/market-place/list/vouchers'>
                                                <MarketPlaceTabSecondary />
                                                <MarketPlaceListing marketPlaceList={allList} loading={loading} totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                {/* <Vouchers /> */}
                                            </Route>
                                            <Route path='/market-place/list/redeemed'>
                                                <MarketPlaceTabSecondary />
                                                <MarketPlaceListing marketPlaceList={allList} loading={loading} totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                {/* <Redeemed /> */}
                                            </Route>
                                            <Redirect to='/error/403' />
                                        </Switch>
                                        {/* <RedeemOfferPopup />
                                    <RedeemOfferSubmitPopup /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

