import React, {useState, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {getLeaseDetail} from '../../../constants/axios/apis'
import {CustomLoader2} from '../../../constants/generics/customActios'
import {BasicDetails} from './components/BasicDetails'
import { PaymentDetails } from './components/PaymentDetails'

export function FinanceLeaseDetailScreen() {
  let params: any = {}
  params = useLocation()
  let leaseID = ''
  if (params?.state?.leaseDetail?.id) leaseID = params.state.leaseDetail.id

  const [loading, setLoading] = useState<any>(true)
  const [leaseDetail, setleaseDetail] = useState<any>()

  useEffect(() => {
    setLoading(true)
    setleaseDetail({})
    getLeaseDetail(leaseID)
      .then((response: any) => {
        if (response.data) setleaseDetail(response.data)
        setLoading(false)
      })
      .catch((e) => {})
  }, [leaseID])

  return (
    <>
      <div className='com-card-section myunit-page'>
        {loading ? (
          <CustomLoader2 />
        ) : (
          <>
            <BasicDetails leaseDetail={leaseDetail} />
            <PaymentDetails leaseDetail={leaseDetail} />
          </>
        )}
      </div>

      {/* {!loading && <Actions leaseDetail={leaseDetail}/> } */}
    </>
  )
}
