import React, { useState, useEffect, useRef } from 'react';
import WorkOrdersImg from "../../../../assets/images/ac-repair.png";

export function Uploaded() {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const [visible, setVisible] = useState(true);
    function removeImg() {
        setVisible(false);
    }
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };
    const [{alt, src}, setImg] = useState({
        src: WorkOrdersImg,
        alt: 'Upload an Image'
    });

    const handleImg = (e:any) => {
        if(e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name
            });
        }
    }
    return (
        <div className="d-flex flex-wrap">
            <div className="upload-input">
                {visible &&
                <div className="position-relative">
                    <div className="upload-image">
                        <img src={src} alt={alt} className="form-img__img-preview"/>
                        <div className="close-icon" onClick={removeImg}>&times;</div>
                    </div>
                </div>
                }
            </div>
            <div className="upload-input">
                {visible &&
                <div className="position-relative">
                    <div className="upload-image">
                        <img src={src} alt={alt} className="form-img__img-preview"/>
                        <div className="close-icon" onClick={removeImg}>&times;</div>
                    </div>
                </div>
                }
            </div>
            <div className="upload-input">
                {visible &&
                <div className="position-relative">
                    <div className="upload-image">
                        <img src={src} alt={alt} className="form-img__img-preview"/>
                        <div className="close-icon" onClick={removeImg}>&times;</div>
                    </div>
                </div>
                }
            </div>
            <div className="upload-input">
                {visible &&
                <div className="position-relative">
                    <div className="upload-image">
                        <img src={src} alt={alt} className="form-img__img-preview"/>
                        <div className="close-icon" onClick={removeImg}>&times;</div>
                    </div>
                </div>
                }
            </div>
            <div className="upload-input">
                {visible &&
                <div className="position-relative">
                    <div className="upload-image">
                        <img src={src} alt={alt} className="form-img__img-preview"/>
                        <div className="close-icon" onClick={removeImg}>&times;</div>
                    </div>
                </div>
                }
            </div>
            <div className="upload-input">
                {visible &&
                <div className="position-relative">
                    <div className="upload-image">
                        <img src={src} alt={alt} className="form-img__img-preview"/>
                        <div className="close-icon" onClick={removeImg}>&times;</div>
                    </div>
                </div>
                }
            </div>
            <div className="upload-input">
                {visible &&
                <div className="position-relative">
                    <div className="upload-image">
                        <img src={src} alt={alt} className="form-img__img-preview"/>
                        <div className="close-icon" onClick={removeImg}>&times;</div>
                    </div>
                </div>
                }
            </div>

        </div>
    );
}