import React, {useState, useEffect, useRef} from 'react'
import { useHistory } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import Home from '../../../../assets/images/my-unit.png'
import {currencyFormater } from '../../../../constants/generics/customActios'
import { formatDate } from '../../../../constants/generics/dateTimeFormater'
import {useTranslation} from 'react-i18next'

export function BasicDetails({serviceDetail}:any) {
    const {t} = useTranslation()
    const history = useHistory()
    // const building_img = serviceDetail?.buildingIcon ? serviceDetail?.buildingIcon : Home
    const building_img = serviceDetail?.communityLogo ? serviceDetail?.communityLogo : Home

    return (
        <>
            <div className='row'>
                <div className='col-md-12 col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <span
                            className='c-pointer'
                            onClick={() => { history.goBack() }}
                        >
                            <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                        </span>
                        <span className='text-black'>
                            {serviceDetail?.category ? serviceDetail?.category : '-'}
                        </span>
                    </h4>
                </div>
                <div className='col-12'>
                    <div className='card mb-7'>
                        <div className='card-body p-7'>
                            <div className='row align-items-center'>
                                <div className='col-md-4 col-lg-3 text-xxl-center mb-6 mb-sm-0'>
                                    {/* <img src={building_img} className='img-fluid' /> */}
                                    <div className='approval-move'>
                                        <img src={building_img} className="img-fluid rounded-5" />
                                    </div>
                                </div>
                                <div className='col-md-8 col-lg-9'>
                                    <div className='card lease-card bg-white shadow-none rounded-0'>
                                        <div className='card-body p-0'>
                                            <div className='row'>
                                                <div className='col-12 mb-5 text-end'>
                                                    <a
                                                        href={serviceDetail?.agreement}
                                                        className='text-decoration-underline text-primary fw-bolder view-all font-14'
                                                        target='_blank'
                                                    >
                                                        <span> {t("View Agreement")} </span>
                                                    </a>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-6 mb-7'>
                                                    <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                        {t("Lease Number")}
                                                    </h6>
                                                    <span className='text-black fw-bold font-14'>
                                                        {serviceDetail?.leaseNumber ? serviceDetail?.leaseNumber : '-'}
                                                    </span>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-6 mb-7'>
                                                    <div className=''>
                                                        <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                            {t("Lease Start Date")}
                                                        </h6>
                                                        <span className='text-black fw-bold font-14'>
                                                            {serviceDetail?.leaseStart ? formatDate(serviceDetail?.leaseStart) : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-6 mb-7'>
                                                    <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                        {t("Lease End Date")}
                                                    </h6>
                                                    <span className='text-black fw-bold font-14'>
                                                        {serviceDetail?.leaseEnd ? formatDate(serviceDetail?.leaseEnd) : '-'}
                                                    </span>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-6 mb-7'>
                                                    <h6 className='text-theme-gray mb-3 fw-normal font-13'>{t("Documents")}</h6>
                                                    <span className='text-black fw-bold font-14'>
                                                        {serviceDetail?.validDocumentCount !== undefined ? serviceDetail?.validDocumentCount : '-'}
                                                    </span>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-6 mb-7'>
                                                    <h6 className='text-theme-gray mb-3 fw-normal font-13'>{t("No of Installments")}</h6>
                                                    <span className='text-black fw-bold font-14'>
                                                        {(serviceDetail?.noOfInstallment !== undefined && serviceDetail.noOfInstallment !== null)  ? serviceDetail?.noOfInstallment : '-'}
                                                    </span>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-6 mb-7'>
                                                    <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                        {t("Annual Rent")}
                                                    </h6>
                                                    <span className='text-black fw-bold font-14'>
                                                        {t('AED')}{' '}
                                                        {serviceDetail?.annualRent ? currencyFormater(serviceDetail?.annualRent) : '-'}
                                                    </span>
                                                </div>
                                                <div className='col-sm-6 col-md-4 col-6 mb-7'>
                                                    <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                                        {t("Security Deposit")}
                                                    </h6>
                                                    <span className='text-black fw-bold font-14'>
                                                        {t('AED')}{' '}
                                                        {serviceDetail?.securityDeposit ? currencyFormater(serviceDetail?.securityDeposit) : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}