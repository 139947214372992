import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/white-logo.svg'

export function EditTemplatePreview() {
    
    return (
        <>
        <div className="row align-items-center mb-5 community">
            <div className="col-md-5">
                <h5 className="theme-color font-22 d-inline-block float-left">
                    <Link to={'email-templates'} className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                        <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                    </Link> Email Template Details
                </h5>
            </div>
        </div>
        <div className="row mb-4">
                    <div className="col-md-12">
                        <div className="card radius-15 mb-3 mb-md-3 mb-lg-0 h-100">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Template Name</span> </h6>
                                        <p className="font-12 text-muted">agency_rejected</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Subject</span> </h6>
                                        <p className="font-12 text-muted">Administrator has rejected your Agency</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Attachment</span> </h6>
                                        <p className="font-12 text-muted">p</p>
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Status</span> </h6>
                                        <h6 className="rounded font-11 m-0 d-inline py-1 px-2 text-white bg-success">Active</h6>
                                      
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-sm-6">
                                        <h6 className="font-12 text-black m-0"><span className="text-uppercase text-black">Dynamic Value</span> </h6>
                                        <p className="font-12 text-muted"> agent_name </p>
                                    </div>
                                </div>
                                <div className="row email-table">
                                    <div className="col-12">
                                        <table className="main-table" style={{"width" : "100% "}}>
                                            <tbody><tr>
                                                <td colSpan={2} className='header-td'>
                                                    <table style={{"width" : "100% ","padding" : "0 15px 0"}}>
                                                        <tbody><tr>
                                                            <td style={{"padding": "15px"}}>
                                                                <img src={logo} alt="Image"/>
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{"padding" : "20px" , "color" : "#070707"}}>
                                                    <div className="template">
                                                        <div className="">
                                                            <h3 style={{ "backgroundColor" : "#1cc88a" , "textAlign" : "center" , "fontSize" : "14px" ,  "color" : "#fff" , "padding" : "10px 30px" , "margin" : "0 auto 30px" , "display" : "table" , "fontWeight" : "600"}}>Your Booking is Confirmed.</h3>
                                                        </div>
                                                        <div className="body" style={{"fontSize" : "14px"}}>
                                                            <p>
                                                                <b style={{ "display" : "inline-block" , "width" : "140px" , "fontWeight" : "600"}}>Name</b>
                                                                <span style={{"display" : "inline-block"}}>: </span>
                                                                <span>Name</span>
                                                            </p>
                                                            <p>
                                                                <b style={{ "display" : "inline-block" , "width" : "140px" , "fontWeight" : "600"}}>Email</b>
                                                                <span style={{"display" : "inline-block"}}>: </span>
                                                                <span>Email</span>
                                                            </p>
                                                            <p>
                                                                <b style={{ "display" : "inline-block" , "width" : "140px" , "fontWeight" : "600"}}>Booked For</b>
                                                                <span style={{"display" : "inline-block"}}>: </span>
                                                                <span>Booked For</span>
                                                            </p>
                                                            <p>
                                                                <b style={{ "display" : "inline-block" , "width" : "140px" , "fontWeight" : "600"}}>From</b>
                                                                <span style={{"display" : "inline-block"}}>: </span>
                                                                <span>From</span>
                                                            </p>
                                                            <p>
                                                                <b style={{ "display" : "inline-block" , "width" : "140px" , "fontWeight" : "600"}}>To</b>
                                                                <span style={{"display" : "inline-block"}}>: </span>
                                                                <span>To</span>
                                                            </p>
                                                            <p>
                                                                <b style={{ "display" : "inline-block" , "width" : "140px" , "fontWeight" : "600"}}>Booking Reason</b>
                                                                <span style={{"display" : "inline-block"}}>: </span>
                                                                <span>Booking Reason</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="p-3">
                                                <td style={{"padding" : "20px"}}>
                                                    <table >
                                                        <tbody>
                                                            <tr>
                                                                <td className="" valign="top" align="left" >
                                                                    Thank You,
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="" valign="top" align="left">
                                                                    Realcube CRM Team
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="copy-write">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td >
                                                                    <table   >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td >
                                                                                    <table  >
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className='txt-copy'>
                                                                                                    © Copyright 2023 All rights reserved.
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        

        
    </>
    )
}