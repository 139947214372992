import React,{useState, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postWorkOrderAssign } from '../../../../constants/axios/apis';
import { getUserRole } from '../../../../constants/generics/userCheck';
import { IState } from '../../../../utils/interface';
import { assignWorkOrders, getTechnicianList } from '../../../../utils/makeRequest';
//import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const AssignWorkOrderPopup:React.FC<any> = ({ workOrder, setPopupSuccessMsg}) =>{
    const { t } = useTranslation();

    const history = useHistory();
    const successRef: any = useRef(null)
    const [selectedTechnician, setSelectedTechnician] = useState<any>({});
    const [technicianList, setTechnicianList] = useState<any>([]);
    const [searchTech, setSearchTech] = useState<any>('');
    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        isSubmitted: false,
        message: '',
    })
    const state: any = useSelector(state => state);
    // const user_role = getUserRole(state?.auth?.user?.data);
    const userToken = state?.auth?.accessToken;
    // console.log(state?.auth?.user?.data, 'state?.auth?.user?.data');
    
    
    const buildingData = workOrder?.buildings
    const isValidBuilding = (buildingData && buildingData.length >0)

    const [fcsMyTeamList, setFcsMyTeamList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    useEffect(() => {
        getTechnicianList(setFcsMyTeamList, userToken);
    }, []);
    const { loading, response: technicians, error } = fcsMyTeamList;
    useEffect(()=>{
        if(isValidBuilding){
            if(typeof technicians !== "undefined" && Object.keys(technicians).length !== 0 && technicians.teamList){
                const userList = technicians.teamList
                const formatedUserList = userList.map((user:any)=>{
                    let buildingIdList = new Array()
                    user.community?.forEach((property:any)=>{
                        if(property.buildings){
                            if(Array.isArray(property.buildings)){
                                property.buildings?.forEach((building:any)=>{
                                    buildingIdList.push(building.id)
                                })
                            }else{
                                buildingIdList.push(property.buildings.id)
                            }
                        }
                    })
                    return {
                        user_id: user.user_id,
                        name: user.name,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        mobile: user.mobile,
                        buildingIdList: buildingIdList,
                        isActive: (user.isFmApproved === 1 && user.status === 1)
                    }
                })
                //Filter by assigned building && approved & active
                let validUserList = formatedUserList.filter((user:any)=> user.isActive && user.buildingIdList.includes(buildingData[0].id))
                
                validUserList.unshift({
                    user_id: state?.auth?.user?.data.id,
                    name: state?.auth?.user?.data.first_name +' '+state?.auth?.user?.data.last_name,
                    first_name: state?.auth?.user?.data.first_name,
                    last_name: state?.auth?.user?.data.last_name,
                    mobile: state?.auth?.user?.data.phone,
                    buildingIdList: []
                });
                setTechnicianList(validUserList);
                // setTechnicianList(technicians.teamList);
            }  
        }else{
            setTechnicianList([])
        }
    },[technicians]);

    // console.log(technicians, 'technicians');
    
   

    const handleAssign = () => {
        //console.log('selectedTechnician',selectedTechnician);
        if (Object.keys(selectedTechnician).length === 0) {
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Please select a technician!"), error: true}))
        }else if (
            (workOrder?.assignedToUserId && selectedTechnician.user_id === workOrder.assignedToUserId) &&
            !(workOrder?.status === "On hold" && workOrder.is_FCSreassigned !== 1)
            ) {
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Already assigned!"), error: true}))
        } else {
            setSubmitStatus((oStatus:any)=>({...oStatus, loading: true, message: '', error: false}))
            const requestObject = workOrder?.status === "On hold" ?
                {
                    work_order_id: workOrder.id,
                    user_id: selectedTechnician.user_id,
                    status_type: "onHold"
                }
                : {
                    work_order_id: workOrder.id,
                    user_id: selectedTechnician.user_id
                }
            postWorkOrderAssign(requestObject)
                .then((response: any) => {
                    if (response.errorCode === 1) {
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, error: true, message: response.errorDescription}))
                    } else if(response.errorCode === 0) {
                        // console.log(response, 'test');
                        setPopupSuccessMsg(response.description)
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: response.description, isSubmitted: true}))
                        successRef.current.click()
                        setTimeout(() => {
                            if(workOrder?.issueCategory === 'Clearance')
                                history.push("/facility-workorder/list/clearance/open")
                            else
                                history.push("/facility-workorder/list/corrective/open")

                        }, 500)
                    } else{
                        setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                    }
                })
                .catch((e) => {
                    setSubmitStatus((oStatus:any)=>({...oStatus, loading: false, message: t("Some error occured!"), error: true}))
                })
        }
    }

    const handleTechnicianChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubmitStatus((oStatus:any)=>({...oStatus, message: "", error: false}))
        setSelectedTechnician(JSON.parse(e.target.value));
    }
    
    const filteredTech = (searchTech === '') ? technicianList : technicianList.filter((technician: any) => {
        const searchRegex = new RegExp(searchTech, 'i');
        return technician.name.match(searchRegex) || technician.mobile.match(searchRegex)
    })
    
    return (
        <>
            <div className="modal fade add-broker-modal text-start" aria-hidden='true' id="assign-popup">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header px-7 py-7 pop_model_c">
                            <div className="form-group has-search">
                                <span className="fa fa-search pt-2 form-control-feedback"></span>
                                <input
                                    onChange={(e:any)=>{ setSearchTech(e.target.value) }}
                                    type="text" className="form-control ps-10" placeholder={t("Search")}/>
                            </div>
                        </div>
                        <div className="modal-body p-7 pt-0">
                            {/* <div className="row"> */}
                                <div 
                                    style={{
                                        overflowY: "scroll",
                                        height: "400px"
                                    }}       
                                    className="table-responsive fc-su-ser-tabel px-0">
                                    <table className="table align-middle table-striped gy-4 gx-4 mb-4 fcs-assign-to-technician">
                                        <thead>
                                        <tr>
                                            <th>{t("No.")}</th>
                                            <th>{t("Name")}</th>
                                            <th>{t("Mobile Number")}</th>
                                            <th className="tab-action-select">{t("Action")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {filteredTech.length === 0 ? (
                                            <tr>
                                                <td colSpan={4}>{t("No technician found!")}</td>
                                            </tr>
                                        ):null}
                                        {filteredTech.map((technician: any, index: number)=>{
                                            return (        
                                                <tr key={index+1}>
                                                    <td>{index+1}</td>
                                                    <td>{technician.first_name + ' ' + technician.last_name}</td>
                                                    <td>{technician.mobile}</td>
                                                    <td className="tab-action-select"><input name="technician" onChange={handleTechnicianChange} className="form-check-input fc-wo-checkbox c-pointer" value={JSON.stringify(technician)} type="radio" id={technician.name} /></td>
                                                </tr>
                                            )})
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {(!submitStatus.loading && submitStatus.error) && (
                                    <div className="alert alert-danger w-100 mt-2 text-center py-2" role="alert">
                                        {submitStatus.message}
                                    </div>
                                )}
                                <button 
                                    // data-bs-target="#filter-popup"
                                    // data-bs-toggle="modal" data-bs-dismiss="modal"
                                    onClick={handleAssign}
                                    type='button'
                                    disabled={submitStatus.isSubmitted || submitStatus.loading }
                                    className='d-block w-100 btn btn-primary fw-bold px-10 py-3'>
                                    {submitStatus.loading ? 
                                        <span className='indicator-progress d-block'>
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>{' '}
                                            {t("Assign")}
                                        </span>
                                        :
                                        <span className='indicator-label'>{t("Assign")}</span>
                                    }
                                </button>
                                <p
                                    ref={successRef}
                                    data-bs-target='#req-submitted-popup-redirect'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                    className='invisible d-none'
                                    />
                            {/* </div> */}
                        </div>
                                
                    </div>
                </div>
            </div>
            
        </>
    )
}
