import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap-v5'
import { getRefundsList } from '../../../../constants/axios/apis'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import {
    CustomLoader1,
    CustomLoader2,
    NoDataFound1,
} from '../../../../constants/generics/customActios'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination'
import { RootState } from '../../../../constants'
import { useSelector } from 'react-redux'

export function ClosedRequests() {
    const currentBuilding: any = useSelector<RootState>(({ General }) => General.building)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const pageSize = 10
    const [refundList, setRefundList] = useState<any>([])
    const [totalRefundList, setTotalRefundList] = useState<any>(0)
    const [loading, setLoading] = useState<any>(true)

    useEffect(() => {
        setCurrentPage(1)
    }, [currentBuilding])

    useEffect(() => {
        const type = 'closed'
        const id_sort = '1'
        const building = currentBuilding && currentBuilding !== '' ? currentBuilding : '0'
        setLoading(true)
        getRefundsList(type, id_sort, currentPage, pageSize, building)
            .then((response: any) => {
                setLoading(false)
                if (response?.errorCode === 0) {
                    setRefundList(response)
                    setTotalRefundList(response.dataCount)
                } else {
                    setRefundList(response)
                    setTotalRefundList(0)
                }
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                setRefundList('')
                setTotalRefundList(0)
            })
    }, [currentPage, currentBuilding])

    if (refundList.length === 0 || loading) {
        return <CustomLoader2 />
    }
    if (refundList?.errorDescription === 'No data found.') {
        return <NoDataFound1 />
    }

    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body p-0'>
                            <div className='row'>
                                <div className='col-md-12 col-12'>
                                    <div className='rec-payment-act-sec'>
                                        <div className='row'>

                                            <div className='col-12'>
                                                <div className='table-responsive'>
                                                    <table className='table table-striped gy-4 gx-4 mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <input
                                                                        className='form-check-input fc-wo-checkbox'
                                                                        data-bs-target='#CheckBoxPopup'
                                                                        data-bs-toggle='modal'
                                                                        type='checkbox'
                                                                        id='flexCheckDefault'
                                                                    />
                                                                </th>
                                                                <th> No. </th>
                                                                <th> Ticket No. </th>
                                                                <th> Service Type </th>
                                                                <th> Tenant Name </th>
                                                                <th> Unit </th>
                                                                <th> Status </th>
                                                                <th> Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {refundList?.errorCode != 1 &&
                                                                refundList?.data?.map((item: any, index: number) => {
                                                                    const sl = pageSize * (currentPage - 1) + index + 1
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <input
                                                                                    className='form-check-input fc-wo-checkbox'
                                                                                    type='checkbox'
                                                                                    id='flexCheckDefault'
                                                                                />
                                                                            </td>
                                                                            <td>{sl}</td>
                                                                            <td>{item?.ticketNo ? item?.ticketNo : '-'}</td>
                                                                            <td>{item?.serviceType ? item?.serviceType : '-'}</td>
                                                                            <td>{item?.tenantName ? item?.tenantName : '-'}</td>
                                                                            <td>{item?.propertyCode ? item?.propertyCode : '-'}</td>
                                                                            <td className='text-success text-capitalize'>
                                                                                {item?.statusText}
                                                                            </td>

                                                                            <td>
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: '/pm-refunds/cheque-delay-request-details',
                                                                                        state: { id: item.id },
                                                                                    }}
                                                                                >
                                                                                    <span className='view-option text-theme fw-bolder'>
                                                                                        View
                                                                                    </span>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                        </tbody>
                                                    </table>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        {/* <Stack spacing={2}>
                                                            <Pagination count={totalRefundList % pageSize === 0 ? totalRefundList / pageSize : Math.ceil(totalRefundList / pageSize)} onChange={handlePageChange} />
                                                        </Stack> */}
                                                        <CompactPagination
                                                            pageSize={pageSize}
                                                            totalCount={parseInt(totalRefundList)}
                                                            defaultPage={currentPage}
                                                            onPageChange={setCurrentPage}
                                                            loading={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
