import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Loader from '../../../../../../components/Loader';
import { CustomLoader2, NoDataFound1 } from '../../../../../../constants/generics/customActios';
import { formatDate, formatTimeMeridiem } from '../../../../../../constants/generics/dateTimeFormater';
import { useTranslation } from 'react-i18next';



export function Upcoming({approvalList, loading}:any) {
    const { t } = useTranslation();

    const columns = [
        {
            field: 'no',
            headerName: t('No.'),
            // sortable: false,
            disableColumnMenu: true,
            width: 80
        },
        {
            field: 'name',
            headerName: t('Name'),
            minWidth:180,
            flex: 1
        },
        {
            field: 'email',
            headerName: t('Email'),
            minWidth:200,
            flex: 1
        },
        {
            field: 'mob',
            headerName: t('Mobile No.'),
            minWidth:180,
            flex: 1
        },
        {
            field: 'emirateId',
            headerName: t('Emirates ID'),
            minWidth:180,
            flex: 1
        },
        {
            field: 'itemState',
            headerName: t('Action'),
            sortable: false,
            disableColumnMenu: true,
            minWidth: 150,
            renderCell: (val:any) => {
                return <Link to={{
                            'pathname': '/fmteam-viewDetails/technician',
                            'state': {'approval': val.value},
                        }} className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'>
                            <span className='view-option text-theme fw-bolder'>{t("View")}</span>
                        </Link>
            },
        },
    ];

    const dataRow = approvalList?.map((item: any, index: number) => {
        return {
            no: index+1,
            name: item.details?.firstName + ' ' + item.details?.lastName,
            email: item.details?.email,
            mob: item.details?.phone,
            emirateId: item.details?.emiratesId,
            itemState :item
        }
    });
    // const tableRows = approvalList?.map((item:any, index:number)=>{
    //     return (
    //         <tr key={index}>
    //             <td>{index+1}</td>
    //             <td>{item.details?.firstName + ' ' + item.details?.lastName}</td>
    //             <td>{item.details?.email}</td>
    //             <td>{item.details?.phone}</td>
    //             <td>{item.details?.emiratesId}</td>
    //             <td>
    //                 <Link to={{
    //                     // 'pathname': '/fmteam-viewDetails/issue-details'
    //                     'pathname': '/fmteam-viewDetails/technician',
    //                     'state': {approval:item},
                        
    //                 }} className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'>
    //                     <span className='view-option text-theme fw-bolder'>View</span>
    //                 </Link>
    //             </td>
    //         </tr>
    //     )
        
    // })

    return (
        <div className="row">
            <div className="col-12 pt-5">
                <div className="rec-payment-act-sec">
                    <div className="row">
                        <div className="col-12">
                            <div className="data_table">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='table-responsive'>
                                            <div style={{ height: 650 }}>
                                                <DataGrid
                                                    rows={dataRow}
                                                    getRowId={(row) => row.no}
                                                    columns={columns}
                                                    pageSize={10}
                                                    rowsPerPageOptions={[10]}
                                                    loading={loading}
                                                    disableColumnMenu
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="row">
                        <div className="col-12">
                            {loading ?
                                <CustomLoader2 height={'166'} />
                                : tableRows?.length ?
                                    <div className='table-responsive'>
                                        <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                            <thead>
                                            <tr>
                                                <th className=''> No </th>
                                                <th className=''> Name </th>
                                                <th className=''> Email </th>
                                                <th className=''> Mobile </th>
                                                <th className=''> Emirate ID </th>
                                                <th className=''> Action </th>
                                            </tr>
                                            </thead>
                                            <tbody>{tableRows}</tbody>
                                        </table>
                                    </div>
                                : <NoDataFound1 height={'110'} />
                            }
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}