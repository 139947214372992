import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { PageTitle } from '../../components/layout/core'
import { MyTeam } from './components/MyTeam/MyTeam'
import { NewProperty } from './components/NewProperty/NewProperty'
import { TeamMembersTabs } from './components/TeamMembers/TeamMembersTabs'
import { All } from './components/TeamMembers/All/All'
import { Week } from './components/TeamMembers/Week/Week'
import { Month } from './components/TeamMembers/Month/Month'
import { GroupChat } from './components/GroupChat/GroupChat'
import { getPMmyTeam } from '../../constants/axios/apis'
import { CustomLoader } from './../../constants/generics/customLoader';
import { useTranslation } from 'react-i18next'
import RightArrow from "../../assets/images/Right_Arrow.png";

const PMMyTeamWrapper: FC = () => {

    const location = useLocation()
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(4)
    const [totalTeamList, setTotalTeamList] = useState<any>([]);
    const [searchItem, setSearchItem] = useState<any>('')
    const [chartData, setChartData] = useState<any>({})
    const [myTeamData, setMyTeamData] = useState<any>([])
    const intl = useIntl()
    const { t } = useTranslation();

    useEffect(() => {
        const roleType = 'PE'
        getPMmyTeam(roleType, currentPage, pageSize, searchItem)
            .then((response: any) => {
                if (response?.errorCode === 0) {
                    // console.log(response?.data);
                    setMyTeamData(response)
                    setChartData({
                        active: response?.data?.active,
                        inactive: response?.data?.inactive,
                        leave: response?.data?.leaves,
                        total: response?.data?.totalMembers,
                    });
                } else {
                    setMyTeamData(response)
                }
            })
            .catch((e) => {
            })
    }, [])
    if (myTeamData.length === 0 && myTeamData?.data?.errorCode != 0) {
        return <CustomLoader />
    }
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='col-12'>
                                <div className='row grid-match'>
                                    <div className='col-lg-5 col-sm-12 col-12'>
                                        <div className='card'>
                                            <div className='card-body px-8'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='row align-items-center position-relative z-index-2'>
                                                            <div className='col-xl-5 col-lg-12 col-sm-5 col-12'>
                                                                <h4 className='card-title font-18 text-black'> {t("My Team")} </h4>
                                                            </div>
                                                            <div className='col-xl-7 col-lg-12 col-sm-7 col-12 text-end'>
                                                                <Link
                                                                    to={'/pm-myteam-add-member'}
                                                                    className='btn btn-primary btn-sm font-12'
                                                                >
                                                                    <i className='bi bi-plus-circle-fill align-middle'></i> {t("Add Member")}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <MyTeam chartData={chartData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-sm-12 col-12'>
                                        <div className='card'>
                                            <div className='card-body px-8'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='row align-items-center'>
                                                            <div className='col-sm-6 col-12'>
                                                                <h4 className='card-title font-18 text-black'>{t("New Property")}</h4>
                                                            </div>
                                                            <div className='col-sm-6 col-12 text-end'>
                                                                <Link to={{ pathname: '/pm-myteam-action-required', state: { type: 'all', from: location.pathname } }} className="text-decoration-none view-all text-black font-12">
                                                                    <span> {t("View All")} </span>
                                                                    <img src={RightArrow} className="img-fluid ms-1" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 mt-5'>
                                                        <NewProperty />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12 mb-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-body px-0 py-0'>
                                        <GroupChat />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        <div className='col-12'>
                            <div className='card mt-6'>
                                <div className='card-body px-8 py-2'>
                                    <div className='row'>
                                        <Switch>
                                            <Route path='/pm-myteam/all'>
                                                {/* <TeamMembersTabs myTeamData={myTeamData}/> */}
                                                <All myTeamData={myTeamData} />
                                            </Route>
                                            <Route path='/pm-myteam/week'>
                                                <TeamMembersTabs />
                                                <Week />
                                            </Route>
                                            <Route path='/pm-myteam/month'>
                                                <TeamMembersTabs />
                                                <Month />
                                            </Route>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { PMMyTeamWrapper }
