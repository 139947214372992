import React, { useState, useEffect } from 'react';
import Home from "../../../../assets/images/nation-towers-new.png";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next'

export function Details() {
	let contractApprovalData: any = []
	contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-7">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-3 text-xxl-center mb-md-0 mb-4">
                                    <img src={contractApprovalData?.image?.length >0 ? contractApprovalData?.image :  Home} className="img-fluid" />
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card bg-white shadow-none rounded-0">
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-sm-6 col-12 mb-7">
                                                    <h6 className="text-black mb-0 fw-bold font-15"> {contractApprovalData?.building} </h6>
                                                </div>
                                                <div className="col-sm-6 col-12 mb-7 text-end">
                                                    <h6 className="mb-0 fw-bold text-theme font-14"> {t("Lease Number")} : {contractApprovalData?.unitNo} </h6>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease Start Date")} </h6>
                                                    <span className="text-black fw-bold font-14"> {moment(contractApprovalData?.details?.leaseStartDate).format('DD-MM-YYYY')} </span>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                                                    <div className="">
                                                        <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease End Date")} </h6>
                                                        <span className="text-black fw-bold font-14"> {moment(contractApprovalData?.details?.leaseEndDate).format('DD-MM-YYYY')} </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Annual Rent")} </h6>
                                                    <span className="text-black fw-bold font-14"> {t("AED")} {contractApprovalData?.annualRent} </span>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Security Deposit")} </h6>
                                                    <span className="text-black fw-bold font-14"> {t("AED")} {contractApprovalData?.security_deposit}  </span>
                                                </div>
                                                <div className="col-lg-6 col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mb-md-0">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Total Cost Impact")} </h6>
                                                    <span className="text-black fw-bold font-14"> {t("AED")} {contractApprovalData?.annualRent} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}