import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {CompactPagination} from '../../../../components/partials/content/CompactPagination/CompactPagination'
import {RootState} from '../../../../constants'
import {getRefundList} from '../../../../constants/axios/apis'
import {currencyFormater, CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'

export function FinanceRefundsOpenRequest({searchItem, vat}: any) {
  const {t} = useTranslation()
  const pageSize = 10
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalServiceRefundCount, setTotalServiceRefundCount] = useState<any>(0)
  const [serviceRefundList, setServiceRefundList] = useState<any>([])
  const currentBuilding: any = useSelector<RootState>(({General}) => General.building)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchItem, currentBuilding])

  useEffect(() => {
    setLoading(true)
    // let page = currentPage
    // let newPayload = '?currentPage=' + page + '&pageSize=' + pageSize + '&status=open'
    let newPayload =
      '?currentPage=' +
      currentPage +
      '&pageSize=' +
      pageSize +
      '&status=open&id_sort=1' +
      '&search=' +
      searchItem
    newPayload = currentBuilding
      ? newPayload + '&building_id=' + currentBuilding
      : newPayload + '&building_id=0'

    getRefundList(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setServiceRefundList(response.data)
        // setCurrentPage(page)
        setTotalServiceRefundCount(response.dataCount)
      } else {
        setServiceRefundList([])
        // setCurrentPage(1)
        setTotalServiceRefundCount(0)
        // setLoading(false);
      }
      setLoading(false)
    })
    // .catch((e)=>setLoading(false))
  }, [currentPage, currentBuilding, searchItem])

  if (!loading && (serviceRefundList === undefined || serviceRefundList?.length === 0)) {
    return <NoDataFound1 />
  }

  const rowData = serviceRefundList?.map((data: any, index: any) => {
    let subTotal = 0
    if(data?.is_clearance_paid === 0)
      data.cCharge?.forEach((el:any)=>{
        if(el.amount && el.quantity)
          subTotal +=  (parseFloat(el.amount) * parseInt(el.quantity))
      })
    data.oCharge?.forEach((el:any)=>{
      if(el.amount )
        subTotal +=  parseFloat(el.amount)
    })
    const totalCharge = subTotal + ((parseFloat(vat)*subTotal)/100)
    const sd = data.securityDepost ? parseFloat(data.securityDepost) : 0
    const refund = sd - totalCharge
    return (
    <tr key={data.id}>
      {/* <td>{(currentPage - 1) * pageSize + index + 1}</td> */}
      <td>{data.ticketNo ? data.ticketNo : '-'}</td>
      {/* <td>{data.serviceType ? data.serviceType : '-'}</td> */}
      <td>{data.tenantName ? data.tenantName : '-'}</td>
      <td>{data.propertyCode ? data.propertyCode : '-'}</td>
      {/* <td>{t("AED")} {currencyFormater(totalCharge)}</td> */}
      <td>{vat !== '' ? `${t("AED")} ${currencyFormater(totalCharge)}` : '-'}</td>
      <td>{data.securityDepost ? `${t("AED")} ${currencyFormater(data.securityDepost)}` : '-'}</td>
      <td className={refund >= 0 ? 'text-success' : 'text-danger'}>{vat !== '' ? `${t("AED")} ${currencyFormater(refund)}` : '-'}</td>
      {/* <td>{t("AED")} {currencyFormater(refund)}</td> */}
      <td>
        <Link
          to={{
            pathname: '/finance-services-request-refunds/view',
            state: {refundDetail: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  )})

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <div className='row'>
                  <div className='col-12 pt-5'>
                    <div className='rec-payment-act-sec'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='table-responsive'>
                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                              <thead>
                                <tr>
                                  {/* <th className=''> {t('No')} </th> */}
                                  <th className=''> {t('Ticket No.')}</th>
                                  {/* <th className=''> {t('Service Type')}</th> */}
                                  <th className=''> {t('Tenant Name')} </th>
                                  <th className=''> {t('Unit')} </th>
                                  <th className=''> {t('Total')} </th>
                                  <th className=''> {t('Security Deposit')} </th>
                                  <th className=''> {t('Refund')} </th>
                                  <th className=''> {t('Action')} </th>
                                </tr>
                              </thead>
                              {loading ? (
                                <tbody>
                                  <tr>
                                    <td colSpan={7} className='text-center pt-10 pb-0'>
                                      <CustomLoader2 />
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  {serviceRefundList?.length > 0 ? rowData: ''}
                                   {/* (
                                    <tr>
                                      <td colSpan={6} className='text-center pt-10 pb-0'>
                                        <NoDataFound1 />
                                      </td>
                                    </tr>
                                  )} */}
                                </tbody>
                              )}
                            </table>
                          </div>
                          <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalServiceRefundCount)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
