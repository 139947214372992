import MultipleImageUploadComponent from "../imageUploader";

export function Media({chooseMessage}:any) {
    return (
        <>
        <h3>Documents & settingss</h3>
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> FLOOR PLAN </label>
                            </div>
                        </div>
                        <MultipleImageUploadComponent/>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> OTHER MEDIA </label>
                            </div>
                        </div>
                        <MultipleImageUploadComponent/>
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> PHOTOS </label>
                            </div>
                        </div>
                        <MultipleImageUploadComponent/>
                    </div>
                    {/* <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="d-block text-uppercase mb-0 font-13"> VIRTUAL VIDEO </label>
                            </div>
                        </div>
                        <MultipleImageUploadComponent/>
                    </div> */}
                    <div className='col-12'>
                        <label className='d-block text-uppercase mb-0 font-13'>VIRTUAL VIDEO</label>
                        <textarea
                            className='form-control border-dashed-black'
                            data-kt-autosize='true'
                            rows={4}
                            placeholder={'Virtual Video'}
                            />
                    </div>
                </div>
            </div>
        </div>
        <div className="text-end my-6">
            <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('3')}>Previous</button>
            <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('5')}>Next</button>
        </div>
        </>
    )
}