import React, { useState, useEffect, useRef } from 'react';
import {Link, useHistory, useLocation, } from 'react-router-dom'
import NoImage from "../../../../../assets/images/House.png";
import {
    createClearanceCharge,
    getFacilityWorkOrderDetails,
    getServiceRequestsCategories,
    updateClearanceCharge,
} from '../../../../../constants/axios/apis'
import { currencyFormater } from '../../../../../constants/generics/customActios';
import Loader from '../../../../../components/Loader';
import { useTranslation } from 'react-i18next';

export const ConfirmedMoveOut = ({workOrder, setPopupSuccessMsg}:any) => {
    const { t } = useTranslation();
    const history = useHistory();

    // let params: any = {};
    // params = useLocation();
    // const { workOrder } = params.state;

    const [isSubmitting, setSubmitting] = useState(false)
    const [isSubmitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const [submitSuccessMsg, setSubmitSuccessMsg] = useState('')
    const [loadingCharges, setLoadingCharges] = useState(true)
    const [workOrderData, setWorkOrderData] = useState<any>(workOrder)
    const [chargesList, setChargesList] = useState<any>()
    const [submitType, setSubmitType] = useState<any>(1)
    const [forceReload, setForceReload] = useState<any>(false)
    const [serviceCategoryID, setServiceCategoryID] = useState<any>()

    // Fetch workOrder by id to get details, reload on add new issue
    useEffect(() => {
        setForceReload(false)
        getFacilityWorkOrderDetails(workOrder?.id).then((response:any) => {
            if(response?.data && response.data[0]){
                setWorkOrderData(response.data[0])
                const formatedChargeData = response.data[0].clearanceChargeDetails?.map((inspection: any)=>{
                    return {
                        image: inspection.image,
                        category: inspection.category,
                        clearanceChargeId: inspection.clearanceChargeId,
                        rate: inspection.amount,
                        quantity: inspection.quantity,
                        total: inspection.amount !== null ? (Number(inspection.amount) * Number(inspection.quantity)) : null
                    }
                })
                setChargesList(formatedChargeData)
                setLoadingCharges(false)
            }
        })
    }, [ workOrder, forceReload ])

    useEffect(() => {
        getServiceRequestsCategories().then(({ data: categoriesData }) => {
            categoriesData = categoriesData?.find((category: any) => category?.categoryCode === 'CC')
            const serviceCategoryData = categoriesData?.service_category_types;
            // setServiceCategories(serviceCategoryData)
            setServiceCategoryID(categoriesData.id)

        })
        // getPreciseLocations(workOrder?.propertyId).then(({ data: preciseLocationList }) => {
        //     setPreciseLocations(preciseLocationList['preciseLocationList'])
        // })
    }, [workOrder])

    const successRef:any = useRef(null);

    const handleRateChange = (value:any, clearanceChargeId:any) => {
        let updatedCharge = chargesList.map((el:any)=> {
            if(el.clearanceChargeId === clearanceChargeId)
                return { ...el, rate: value, total: value * el.quantity }
            else
                return el
        })
        setChargesList(updatedCharge)
    }



    const handleSubmit = (type:any) => {
        setSubmitType(type)
        setSubmitting(true)
        setSubmitError('')
        setSubmitSuccessMsg('')
        let rateError = false
        const formData = new FormData()
        formData.append("work_order_id", workOrder.id);
        if(type === 2)
            formData.append("status", '1');
        chargesList?.forEach((element:any, index:any) => {
            formData.append(`ccharge[${index}][id]`, element.clearanceChargeId);
            formData.append(`ccharge[${index}][amount]`, element.rate);
            formData.append(`ccharge[${index}][category]`, serviceCategoryID);
            // formData.append(`clearanceData[${index}][issue_id]`, element.clearanceChargeId);
            // formData.append(`clearanceData[${index}][amound]`, element.rate);
            if(Number(element.rate) <= 0)
                rateError = true
        });
        if(rateError){
            setSubmitError("Please enter the Rate!")
            setSubmitting(false)
            setTimeout(() => { setSubmitError('') }, 5000)
        }else{
            setTimeout(() => {
                createClearanceCharge(formData)
                // updateClearanceCharge(formData)
                    .then((response: any) => {
                        setSubmitting(false)
                        if (response.errorCode === 1) {
                            setSubmitError(response.errorDescription)
                            setTimeout(() => {
                                setSubmitError('')
                            }, 10000)
                        } else if(response.errorCode === 0) {
                            setPopupSuccessMsg(response.description)
                            setSubmitSuccessMsg(response.description)
                            setSubmitting(false)
                            successRef.current.click();
                            if(type === 1){
                                setForceReload(true)
                            }else{
                                setSubmitted(true)
                                setTimeout(() => {
                                    history.push("/facility-workorder/list/clearance/completed")
                                    // window.location.replace('/facility-workorder/list/clearance/completed');
                                }, 500)
                            }

                        } else{
                            setSubmitError(t("Some error occured!"))
                            console.log(response, 'response');
                            setTimeout(() => {
                                setSubmitError('')
                            }, 10000)
                        }
                    })
                    .catch((e) => {
                        console.log(e, 'Error');
                        setSubmitting(false)
                        setSubmitError(t('Some error occured!'))
                        setTimeout(() => {
                            setSubmitError('')
                        }, 10000)
                    })
            }, 1000)
        }

        
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="row mb-6 align-items-center">
                        <div className="col-12 text-end">
                            <Link to={{'pathname':'/facility-workorder/inspection-update', state: {clearanceChargeId: null, workOrder: workOrder, addedDataList: workOrderData?.clearanceChargeDetails }}} className='btn btn-primary fw-bold px-4 py-2'>
                                <i className="bi bi-plus-circle-fill font-17 me-2"></i> {t("Add")}
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="moveout-inspection">
                                <div className="row">
                                    {loadingCharges && 
                                        <Loader />
                                    }
                                    {!loadingCharges && chargesList?.map((charge: any, index: number) => {
                                        return (
                                            <div className="col-12" key={index}>
                                                <div className="card mb-5">
                                                    <div className="card-body p-5">
                                                        <div key={index} className="d-flex moveout-inspection-main-div align-items-center">
                                                            <div className="moveout-inspection-div divimg w-auto">
                                                                <img src={charge.image ? charge.image : NoImage} alt="" className="img-fliud me-20"/>
                                                            </div>
                                                            <div className="moveout-inspection-div">
                                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {charge.category} </h6>
                                                                <span className="text-black fw-bold font-15"> {t("Req. Quantity")} : {charge.quantity} </span>
                                                            </div>
                                                            <div className="moveout-inspection-div">
                                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Rate")} </h6>
                                                                <div className="height-19">
                                                                    <p className="mb-0 d-inline text-black fw-bold font-15"> {t("AED")} </p>
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        className="form-control d-inline text-black fw-bold font-15 form-control-solid w-75 px-1 py-0" placeholder=""
                                                                        value={charge.rate}
                                                                        onChange={(e) => handleRateChange(e.target.value, charge.clearanceChargeId)}
                                                                        />

                                                                </div>
                                                            </div>
                                                            <div className="moveout-inspection-div">
                                                                <h6 className="text-theme-gray mb-3 fw-normal font-14"> {t("Total Amount")} </h6>
                                                                <span className="text-black fw-bold font-15"> {t("AED")} {currencyFormater(charge.total)} </span>
                                                            </div>
                                                            <div className="moveout-inspection-div text-end my-auto">
                                                                <div className="row justify-content-end">
                                                                    <div className="col-lg-8 col-md-3 col-6">
                                                                        <Link to={{'pathname':'/facility-workorder/inspection-update', state: {clearanceChargeId: charge.clearanceChargeId, workOrder: workOrderData, addedDataList: workOrderData?.clearanceChargeDetails}}} className='btn btn-primary fw-bold btn-block py-3'>
                                                                            {t("Edit")}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {!loadingCharges && chargesList && isSubmitting ?
                            <div className="col-12 text-end">
                                {/* <button ref={successRef} data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='button' className='invisible btn btn-primary fw-bold px-10 py-3'></button> */}
                                {/* <button onClick={history.goBack} className="btn btn-outline-primary fw-bold px-10 py-3 me-5">Cancel</button> */}
                                <button type='button' className='btn btn-primary fw-bold px-15 py-3' disabled>
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        {t("Please wait...")}{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </button>
                            </div>
                        :
                            <div className="col-12 text-end">
                                <div className="row justify-content-end">
                                    {/* <button ref={successRef} data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='button' className='invisible btn btn-primary fw-bold px-10 py-3'></button> */}
                                    <div className="col-lg-2 col-md-3 col-6">
                                        <button onClick={history.goBack} className="btn btn-outline-primary fw-bold px-10 py-3 btn-block">{t("Cancel")}</button>
                                    </div>
                                    { isSubmitted ?
                                        <>
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <button disabled type="button" className="btn btn-primary fw-bold px-lg-10 py-3 btn-block">{t("Save Rates")}</button>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-12 mt-3 mt-md-0">
                                                <button disabled type="button" className="btn btn-primary fw-bold px-lg-10 py-3 btn-block">{t("Save & Submit")}</button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <button onClick={(e)=> handleSubmit(1)} type="button" className="btn btn-primary fw-bold px-lg-10 py-3 btn-block">{t("Save Rates")}</button>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-12 mt-3 mt-md-0">
                                                <button onClick={(e)=> handleSubmit(2)} type="button" className="btn btn-primary fw-bold px-lg-10 py-3 btn-block">{t("Save & Submit")}</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }

                        {submitError !== '' && (
                            <div className="col-12 text-end mt-5">
                                <div
                                    // style={{width: 'fit-content'}}
                                    className="alert alert-danger mt-2 text-center" role="alert">
                                    {submitError}
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
            <p
                ref={successRef}
                data-bs-target='#req-submitted-popup-redirect'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
                className='invisible d-none'
                />   
        </>
    );
}