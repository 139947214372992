/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { getUserRole } from '../../constants/generics/userCheck'

const RedirectHandler: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const state: any = useSelector(state => state);
    const userRoleCode = getUserRole(state?.auth?.user?.data);

    let redirectPath = '/'
    if (location.pathname === '/')
        redirectPath = ( userRoleCode && ['FC', 'FCS', 'FMT', 'FCSS', 'FMSM', 'FT', 'PE', 'PM', 'MT', 'MA'].includes(userRoleCode) ? '/dashboard' :
                '/auth/login')
    else
        redirectPath = '/error/403'

    history.push(redirectPath)

    return (
        <></>
    )
}

export { RedirectHandler }
