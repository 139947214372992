import React, { useState, useEffect } from 'react'

export function NoteModal() {
    return (
        <>
            <div className="modal fade" id="note_modal">
                <div className="modal-dialog overflow-hidden modal-lg">
                    <div className="modal-content radius-15 overflow-hidden">
                        <div className="modal-header bg-primary text-white">
                            <h6 className="modal-title text-white font-16 font-weight-semibold">Add Note</h6>
                            <button type="button" className="close text-warning bg-white rounded-circle" data-bs-dismiss="modal">×</button>
                        </div>
                        <div className="modal-body">
                            <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-7">
                                        <label className="d-block w-100 text-uppercase mb-0 font-13">Note</label>
                                        <textarea rows={5} className="form-control font-13 click-add" id="activity-description" placeholder="Start typing..."></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12 text-end">
                                    <button type="button" className="btn btn-primary font-weight-semibold px-4">Create</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
