import React from 'react';
import { useTranslation } from 'react-i18next'
import { PromotionCreatePopup } from '../../PromotionCreate/PromotionCreatePopup';

export function PromotionHeader(){
    const { t } = useTranslation();

    return (
        <>
            <div className="com-card-section">
                <div className="row align-items-center mb-6">
                    <div className="col-sm-4">
                        <h4 className="card-title font-18 text-black mb-0">{t("Promotions")}</h4>
                    </div>
                    <div className="col-sm-8 text-end">
                        <PromotionCreatePopup />
                    </div>
                </div>
            </div>
        </>
    );
};