import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { getFaq } from '../../constants/axios/apis';
import { CustomLoader2, NoDataFound1 } from '../../constants/generics/customActios';

const Faq: FC = () => {

    const { t } = useTranslation()
    const [faqData, setFaqData] = useState<any>({
        loading: false,
        error: '',
        response: []
    })


    // useEffect(() => {
    //     setFaqData({ loading: true, error: '', response: [] })
    //     getFaq('').then((response: any) => {
    //         console.log(response, 'getFaq');

    //         if (response.errorCode === 1) {
    //             // console.log(response);
    //             setFaqData({ loading: false, error: response.errorDescription, response: [] })
    //         } else if (response.errorCode === 0) {
    //             if (Array.isArray(response.data) && response.data.length > 0)
    //                 setFaqData({ loading: false, error: '', response: response.data })
    //             else
    //                 setFaqData({ loading: false, error: '', response: [] })
    //         } else {
    //             setFaqData({ loading: false, error: t("Some error occured!"), response: [] })
    //         }
    //     }).catch((e: any) => setFaqData({ loading: false, error: t("Some error occured!"), response: [] }))
    // }, [])

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h4 className="card-title font-18 text-black mb-6 fw-bolder"> {t("Frequently Asked Questions")} </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='card mb-5 mb-xl-10 rounded-10'>
                                <div className='card-body py-9 px-md-14'>
                                    {
                                        faqData?.loading ?
                                            <CustomLoader2 height={'166'} />
                                            :
                                            faqData.error !== '' ?
                                                <div className="col-12">
                                                    <div className='alert alert-danger w-100 text-center mt-5' role='alert'>{faqData.error}</div>
                                                </div>
                                                :
                                                faqData?.response?.length > 0 ?
                                                <div className="accordion faq-accordian" id="accordionExample">
                                                    {
                                                        faqData?.response?.map((item: any, index: number) => {

                                                            return (
                                                                <div className="accordion-item" key={index}>
                                                                    <h2 className="accordion-header" id="headingOne">
                                                                        <button className={`accordion-button${index === 0 ? '' : ' collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                                            {item.question}
                                                                        </button>
                                                                    </h2>
                                                                    <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            {item.answer}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {/* <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elitireland national dayunited square
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elitireland national dayunited square
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elitireland national dayunited square
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elitireland national dayunited square
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing?
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elitireland national dayunited square
                                                                lorem ipsum dolor sit amet consectetuer adipiscing elitireland national dayunited square
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                :
                                                <NoDataFound1 />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};
export { Faq }