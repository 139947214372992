import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { Overview } from './marketing-overview/Overview'


const PMOverview: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/marketing/overview'>
                            <Overview/>
                        </Route> 
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMOverviewWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMOverview />
        </>
    )
};

export { PMOverviewWrapper }

