import React, {useState, useEffect} from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import MoveOutIcon from '../../../../assets/images/move-out-icon.svg'
import MoveInIcon from '../../../../assets/images/move-in-icon.svg'
import MaintenanceIcon from '../../../../assets/images/Maintenance_Active.svg'
import TeamIcon from '../../../../assets/images/mta_myteam_active.svg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'
import {CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../constants'
import { getApprovals } from '../../../../constants/axios/apis'
import { getUserRole } from '../../../../constants/generics/userCheck'
import { RecentOnHoldApprovalCard } from './RecentOnHoldApprovalCard'
import { RecentTechnitianApprovalCard } from './RecentTechnitianApprovalCard'

import { useTranslation } from 'react-i18next';


const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
      },
    },
  ],
}
export function RecentRequests() {
  const currentBuilding: any = useSelector<RootState>(({FCDashboard}) => FCDashboard.selectedBuilding)
  
  const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);

  const [approvals, setApprovals] = useState<any>([])
  // const [loading, setLoading] = useState(true)
  const [loading, setLoading] = useState({ data1: true, data2: true, })

  // console.log(approvals, 'approvals');
  

  useEffect(() => {
    setLoading({ data1: true, data2: true })
    setApprovals([])
    // setApprovals([])
    if(user_role === 'FMT'){
      let payload = '?status=pending&sub_type=18' +
        (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
      getApprovals(payload)
        .then(({data}: any) => {
          const filteredData= (Array.isArray(data) && data?.length > 0) ?
            data?.filter((el:any)=>el.subReference === 18) : [];
          // console.log(filteredData, 'filteredData');
          setApprovals((oState:any)=> oState.concat(filteredData.slice(0, 10)) )
          setLoading((oState:any)=>({ ...oState, data1: false }))
        })
        .catch((e) => {
          // setApprovals([])
          setLoading((oState:any)=>({ ...oState, data1: false }))
        })

      let payload2 = '?status=pending&sub_type=21' +
        (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
      getApprovals(payload2)
        .then(({data}: any) => {
          const filteredData= (Array.isArray(data) && data?.length > 0) ?
            data?.filter((el:any)=>el.subReference === 21) : [];
          setApprovals((oState:any)=> oState.concat(filteredData.slice(0, 5)) )
          setLoading((oState:any)=>({ ...oState, data2: false }))
        })
        .catch((e) => {
          // setApprovals([])
          setLoading((oState:any)=>({ ...oState, data2: false }))
        })
    }else{

      let payload = '?currentPage=1&pageSize=5&id_sort=1&sub_type=5&status=pending&is_card_data_added=open' +
        (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
      getApprovals(payload)
        .then(({data}: any) => {
          const filteredData= (Array.isArray(data) && data?.length > 0) ? data : [];
          setApprovals((oState:any)=> oState.concat(filteredData) )
          setLoading((oState:any)=>({ ...oState, data1: false }))
        })
        .catch((e) => {
          setLoading((oState:any)=>({ ...oState, data1: false }))
        })

      let payload2 = '?currentPage=1&pageSize=5&id_sort=1&sub_type=6&status=pending&is_card_data_added=1' +
        (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
      getApprovals(payload2)
        .then(({data}: any) => {
          const filteredData= (Array.isArray(data) && data?.length > 0) ? data : [];
          setApprovals((oState:any)=> oState.concat(filteredData) )
          setLoading((oState:any)=>({ ...oState, data2: false }))
        })
        .catch((e) => {
          setLoading((oState:any)=>({ ...oState, data2: false }))
        })


      // const newPayload =
      //   '?status=pending' +
      // //   '&is_card_data_added=1' +
      //   (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
      // getApprovals(newPayload)
      //   .then((response: any) => {
      //     const filterApprovals = response?.data?.filter(
      //       (approval: any) => approval.status === 'Pending'
      //     )
      //     setApprovals(
      //       Array.isArray(filterApprovals) && filterApprovals?.length > 0
      //         ? filterApprovals.slice(0, 15)
      //         : []
      //     )
      //     setLoading((oState:any)=>({data1: false, data2: false }))
      //   })
      //   //   if (Array.isArray(response.data) && response.data.length > 0) {
      //   //     setApprovals(response.data.filter((approval: any) => approval.status === 'Pending'))
      //   //     setLoading(false)
      //   //   } else {
      //   //     setApprovals([])
      //   //     setLoading(false)
      //   //   }
      //   // })
      //   .catch((e) => {
      //     setApprovals([])
      //     setLoading((oState:any)=>({data1: false, data2: false }))
      //   })
    }
    
  }, [currentBuilding])

  
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-12'>
                  {/* <h4 className='card-title'> Recent Requests </h4> */}
                  <h4 className='card-title'> {t("Action Required")} </h4>
                </div>
                {/*<div className="col-md-6 col-6 text-right">*/}
                {/*<Link to={''} className="text-decoration-none view-all"> */}
                {/*<span> View All </span>*/}
                {/*<img src={RightArrow} className="img-fluid" /> */}
                {/*</Link>*/}
                {/*</div>*/}
              </div>
              { user_role === 'FMT' ? (
                <div className='rec-request-sec'>
                  <div>
                    <div className='row'>
                      {(loading.data1 || loading.data2) ?
                        <CustomLoader2 height={'130'} />
                        : (
                        <div className='col-12 p-0'>
                          {approvals?.length ? (
                            <Slider {...settings}>
                              {approvals?.map((approval: any, index: number) =>
                                approval.subReference === 18 ? <RecentOnHoldApprovalCard approval={approval} key={index}/>
                                : approval.subReference === 21 ?<RecentTechnitianApprovalCard approval={approval} key={index}/>
                                : ''
                              )}
                              {/* <div>
                                <Link
                                  to={'fmteam-approvals/permit-work/upcoming'}
                                  className='text-decoration-none'
                                >
                                  <div className='card mt-4'>
                                    <div className='card-body px-5 py-5 px-md-8 py-md-7'>
                                      <div className='row'>
                                        <div className='col-lg-8'>
                                          <div className='mb-7'>
                                            <img
                                              src={MoveOutIcon}
                                              className='img-fluid me-5 d-inline'
                                            />
                                            <span className='card-title'>Move Out Inspection</span>
                                          </div>
                                          <h6 className='font-14 fw-normal text-black'>
                                            Action Required
                                          </h6>
                                        </div>
                                        <div className='col-lg-4 text-end mt-auto'>
                                          <Link
                                            to={'fmteam-approvals/permit-work/upcoming'}
                                            className='btn btn-primary rounded w-100 py-2 mt-13'
                                          >
                                            View
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div> */}
                              
                            </Slider>
                          ) : (
                            <NoDataFound1 height={'110'} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                // <div className="rec-request-sec">
                //     <div>
                //         <div className="row">
                //             <div className="col-12 p-0">
                //                 <Slider {...settings}>
                //                     <div>
                //                         <Link to={'fmteam-approvals/permit-work/upcoming'} className="text-decoration-none">
                //                             <div className="card mt-4">
                //                                 <div className="card-body px-5 py-5 px-md-8 py-md-7">
                //                                     <div className="row">
                //                                         <div className="col-lg-8">
                //                                             <div className="mb-7">
                //                                                 <img src={MoveOutIcon}
                //                                                      className="img-fluid me-5 d-inline"/>
                //                                                 <span className="card-title">
                //                                                 Permit to Work Approvals
                //                                             </span>
                //                                             </div>
                //                                             <h6 className="font-14 fw-normal text-black">
                //                                                 Action Required
                //                                             </h6>
                //                                         </div>
                //                                         <div className="col-lg-4 text-end mt-auto">
                //                                             <button
                //                                                 className="btn btn-primary rounded w-100 py-2 mt-13">
                //                                                 View
                //                                             </button>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                         </Link>
                //                     </div>
                //                     <div>
                //                         <Link to={'fmteam-approvals/permit-work/upcoming'} className="text-decoration-none">
                //                             <div className="card mt-4">
                //                                 <div className="card-body px-5 py-5 px-md-8 py-md-7">
                //                                     <div className="row">
                //                                         <div className="col-lg-8">
                //                                             <div className="mb-7">
                //                                                 <img src={MoveOutIcon}
                //                                                      className="img-fluid me-5 d-inline"/>
                //                                                 <span className="card-title">
                //                                                 Permit to Work Approvals
                //                                             </span>
                //                                             </div>
                //                                             <h6 className="font-14 fw-normal text-black">
                //                                                 Action Required
                //                                             </h6>
                //                                         </div>
                //                                         <div className="col-lg-4 text-end mt-auto">
                //                                             <button
                //                                                 className="btn btn-primary rounded w-100 py-2 mt-13">
                //                                                 View
                //                                             </button>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                         </Link>
                //                     </div>
                //                     <div>
                //                         <Link to={'fmteam-approvals/permit-work/upcoming'} className="text-decoration-none">
                //                             <div className="card mt-4">
                //                                 <div className="card-body px-5 py-5 px-md-8 py-md-7">
                //                                     <div className="row">
                //                                         <div className="col-lg-8">
                //                                             <div className="mb-7">
                //                                                 <img src={MoveOutIcon}
                //                                                      className="img-fluid me-5 d-inline"/>
                //                                                 <span className="card-title">
                //                                                 Permit to Work Approvals
                //                                             </span>
                //                                             </div>
                //                                             <h6 className="font-14 fw-normal text-black">
                //                                                 Action Required
                //                                             </h6>
                //                                         </div>
                //                                         <div className="col-lg-4 text-end mt-auto">
                //                                             <button
                //                                                 className="btn btn-primary rounded w-100 py-2 mt-13">
                //                                                 View
                //                                             </button>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                         </Link>
                //                     </div>
                //                     <div>
                //                         <Link to={'fmteam-approvals/permit-work/upcoming'} className="text-decoration-none">
                //                             <div className="card mt-4">
                //                                 <div className="card-body px-5 py-5 px-md-8 py-md-7">
                //                                     <div className="row">
                //                                         <div className="col-lg-8">
                //                                             <div className="mb-7">
                //                                                 <img src={MoveOutIcon}
                //                                                      className="img-fluid me-5 d-inline"/>
                //                                                 <span className="card-title">
                //                                                 Permit to Work Approvals
                //                                             </span>
                //                                             </div>
                //                                             <h6 className="font-14 fw-normal text-black">
                //                                                 Action Required
                //                                             </h6>
                //                                         </div>
                //                                         <div className="col-lg-4 text-end mt-auto">
                //                                             <button
                //                                                 className="btn btn-primary rounded w-100 py-2 mt-13">
                //                                                 View
                //                                             </button>
                //                                         </div>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                         </Link>
                //                     </div>
                //                 </Slider>
                //             </div>
                //         </div>
                //     </div>
                // </div>
                <div className='rec-request-sec'>
                  <div>
                    <div className='row'>
                      {(loading.data1 || loading.data2) ? <CustomLoader2 height={'166'} />
                        : (
                        <div className='col-12 p-0'>
                          {approvals?.length ? (
                            <Slider {...settings}>
                              {/* {approvals ? recentRequests : ''} */}
                              {approvals?.map((approval: any, index: number) => {
                                const viewLink =
                                  approval?.subReference === 5
                                    ? '/fcss-moveinDetails/building-access-card'
                                    : '/fcss-moveoutDetails/building-access-card'
                                return (
                                  <div key={index}>
                                    <Link
                                      to={{pathname: viewLink, state: {approval}}}
                                      className='text-decoration-none'
                                    >
                                      <div className='card mt-4'>
                                        <div className='card-body px-8 py-7'>
                                          <div className='row'>
                                            <div className='col-lg-8'>
                                              <div className='mb-7'>
                                                <img
                                                  src={
                                                    approval?.subReference === 5
                                                      ? MoveInIcon
                                                      : MoveOutIcon
                                                  }
                                                  className='img-fluid me-8 d-inline'
                                                />
                                                <span className='card-title'>
                                                  {approval?.subReference === 5
                                                    ? (t('Move In Approval'))
                                                    : (t('Move Out Approval'))}
                                                </span>
                                              </div>
                                            </div>
                                            <div className='col-lg-4 text-end'>
                                              <button className='btn btn-primary rounded w-100 py-2 mb-2'>
                                                {t("View")}
                                              </button>
                                            </div>
                                          </div>
                                          <div className='row'>
                                            <div className='col-sm-6 pe-0'>
                                              <p className='mb-0 text-muted'>{t("Unit Number")}</p>
                                              <p className='mb-0 text-dark font-12'>
                                                {approval?.propertyCode}
                                              </p>
                                            </div>
                                            <div className='col-sm-6 pe-0'>
                                              <p className='mb-0 text-muted'>{t("Buiding Name")}</p>
                                              <p className='mb-0 text-dark font-12 building-name-fcss'>
                                                {approval?.building}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                )
                              })}
                              {/* <div>
                          <Link to={'/approvals/movein/details'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body px-8 py-7'>
                                <div className='row'>
                                  <div className='col-lg-8'>
                                    <div className='mb-7'>
                                      <img src={MoveOutIcon} className='img-fluid me-8 d-inline' />
                                      <span className='card-title'>Move In Approval</span>
                                    </div>
                                    <h6 className='font-14 fw-normal text-black'>
                                      Action Required
                                    </h6>
                                  </div>
                                  <div className='col-lg-4 text-end mt-auto'>
                                    <button className='btn btn-primary rounded w-100 py-2 mt-13'>
                                      View
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/approvals/moveout/details'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body px-8 py-7'>
                                <div className='row'>
                                  <div className='col-lg-8'>
                                    <div className='mb-7'>
                                      <img src={MoveOutIcon} className='img-fluid me-8 d-inline' />
                                      <span className='card-title'>Move Out Approval</span>
                                    </div>
                                    <h6 className='font-14 fw-normal text-black'>
                                      Action Required
                                    </h6>
                                  </div>
                                  <div className='col-lg-4 text-end mt-auto'>
                                    <button className='btn btn-primary rounded w-100 py-2 mt-13'>
                                      View
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div>
                          <Link to={'/approvals/movein/details'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body px-8 py-7'>
                                <div className='row'>
                                  <div className='col-lg-8'>
                                    <div className='mb-7'>
                                      <img src={MoveOutIcon} className='img-fluid me-8 d-inline' />
                                      <span className='card-title'>Move In Approval</span>
                                    </div>
                                    <h6 className='font-14 fw-normal text-black'>
                                      Action Required
                                    </h6>
                                  </div>
                                  <div className='col-lg-4 text-end mt-auto'>
                                    <button className='btn btn-primary rounded w-100 py-2 mt-13'>
                                      View
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                            </Slider>
                          ) : (
                            <NoDataFound1 height={'110'} />
                            // <div className='text-center'>No Upcoming Request.</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
