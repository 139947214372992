import { Link } from "react-router-dom";
import one from '../../../assets/images/1.png'
import two from '../../../assets/images/2.png'
import three from '../../../assets/images/3.png'
import four from '../../../assets/images/4.png'
import { CompactPagination } from "../../../components/partials/content/CompactPagination/CompactPagination";
import { useEffect, useState } from "react";
import { Email } from "./EmailGraph";
import { useSelector } from "react-redux";
import { getInvoiceList } from "../../../utils/makeRequest";
import { IState } from "../../../utils/interface";
import { myDashboard } from "../../../constants/axios/apis";



const SocialData = [{


    "metrics": "SENT105",
    "one": "OPENED1.0%",
    "two": "CLICKED 0%",
    "three": "REPLIED 0%",
    "four": "UNSUBSCRIBED 0%",
    "five": "SHARED yes"
},
{


    "metrics": "SENT105",
    "one": "OPENED1.0%",
    "two": "CLICKED 0%",
    "three": "REPLIED 0%",
    "four": "UNSUBSCRIBED 0%",
    "five": "SHARED yes"
},
{


    "metrics": "SENT105",
    "one": "OPENED1.0%",
    "two": "CLICKED 0%",
    "three": "REPLIED 0%",
    "four": "UNSUBSCRIBED 0%",
    "five": "SHARED yes"
},
{


    "metrics": "SENT105",
    "one": "OPENED1.0%",
    "two": "CLICKED 0%",
    "three": "REPLIED 0%",
    "four": "UNSUBSCRIBED 0%",
    "five": "SHARED yes"

},
{


    "metrics": "SENT105",
    "one": "OPENED1.0%",
    "two": "CLICKED 0%",
    "three": "REPLIED 0%",
    "four": "UNSUBSCRIBED 0%",
    "five": "SHARED yes"

},
{


    "metrics": "SENT105",
    "one": "OPENED1.0%",
    "two": "CLICKED 0%",
    "three": "REPLIED 0%",
    "four": "UNSUBSCRIBED 0%",
    "five": "SHARED yes"

},
];

export function EmailList() {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState<any>(1)

    const state: any = useSelector(state => state);
    const [chartData, setChartData] = useState<any>([])
    const [loading, setLoading] = useState<any>(true)
    const userToken = state?.auth?.accessToken;

    useEffect(() => {
        myDashboard()
            .then((response: any) => {
                setLoading(false)
                if (response?.status === 200 && response?.data?.errorCode != 1) {
                    setChartData(response)
                } else {
                    setChartData(response)
                }
            })
            .catch((e) => {
            })
    }, [])

    if (userToken === "undefined") {
        // token undefined
    }

    const [invoiceList, setInvoiceList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    const { response: invoices, error } = invoiceList;
    useEffect(() => {
        getInvoiceList(setInvoiceList, userToken);
    }, []);
    function yearMonths() {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    function getInvoiceMonths(invoices: any[], status: string) {
        const months = yearMonths();

        let invoiceMonth = new Set(
            invoices.filter(invoice => invoice.status === status).map(invoice => {
                let date = new Date(invoice.invoiceDate);
                return date.getMonth()
            }).sort().map(d => {
                return months[d];
            })
        );
        let invoiceMonths: any[] = [];

        invoiceMonth.forEach(function (month: any) {
            invoiceMonths.push(month);
        });

        return invoiceMonths;
    }

    function getTotalInvoiceByMonth(invoices: any[], status: string, invoiceMonths: any[]) {
        const months = yearMonths();
        const returnArray: any[] = [];

        let invoice = invoices.filter(invoice => invoice.status === status)

        invoiceMonths.forEach(function (month: any) {
            let totalInvoice = invoice.filter(inv => {
                let d = new Date(inv.invoiceDate);

                return months[d.getMonth()] === month;
                //let invoiceMonth = d.getMonth();
            });
            returnArray.push(totalInvoice.length);
        })
        return returnArray;
    }

    const [chartDataPending, setChartDataPending] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'In-Progress',
                data: [1, 2, 3, 4],
                backgroundColor: '#002C6A',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });

    useEffect(() => {
        if (Array.isArray(invoices)) {
            if (invoices.length > 0) {
                let pendingInvoiceMonth = getInvoiceMonths(invoices, "Pending");
                setChartData(setChartDataPending);

            }
        }
    }, [invoices]);
    return (
        <>
            <div className="row my-7 form-add-design">
                <div className="col-md-6">
                    <h3 className="theme-color font-22 mb-4">
                        E-mail Campaigns
                    </h3>
                </div>
                <div className="col-md-6 text-end">
                    <Link to='/email/add' className="btn btn-primary radius-10  px-4 font-12"> Create Email Campaign </Link>
                    <Link to='/email/template' className="btn btn-primary ms-2 font-12 radius-10 font-weight-semibold d-inline-flex flex-wrap align-items-center px-4 float-right mb-2 mb-md-0">Create Email Template</Link>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <div className="card radius-12 h-100 border-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Emails Sent </h6>
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <img src={one} className="img-fluid" width="35px" />
                                        </div>
                                        <div className="col-md-8 col-6 d-flex align-items-center">
                                            <h5 className="font-weight-bold mb-0 text-end ml-auto"> 5,000 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3 mb-md-0">
                    <div className="card radius-12 h-100 border-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Opened </h6>
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <img src={two} className="img-fluid" width="35px" />
                                        </div>
                                        <div className="col-md-8 col-6 d-flex align-items-center">
                                            <h5 className="font-weight-bold mb-0 text-end ml-auto"> 2,000 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3 mb-md-0">
                    <div className="card radius-12 h-100 border-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Clicked </h6>
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <img src={three} className="img-fluid" width="35px" />
                                        </div>
                                        <div className="col-md-8 col-6 d-flex align-items-center">
                                            <h5 className="font-weight-bold mb-0 text-end ml-auto"> 500 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card radius-12 h-100 border-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className="font-weight-semibold text-theme-black font-12 mb-4"> Contacted </h6>
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <img src={four} className="img-fluid" width="35px" />
                                        </div>
                                        <div className="col-md-8 col-6 d-flex align-items-center">
                                            <h5 className="font-weight-bold mb-0 text-end ml-auto"> 75 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-8">
                    <div className="card">
                        <div className="card-body">
                            <div className='row align-items-center mb-5 table-drop'>
                                {/* <div className="col-8">
                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#Filter-popup">
                                        <i className="fas fa-filter"></i> Filter
                                    </button>
                                </div> */}
                                <div className="col-4 text-end d-flex align-items-center">
                                    <span className="me-2">Show</span>
                                    <select className="form-control form-select">
                                        <option>10 </option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </select>
                                    <span className="ms-2">entries</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 col-12'>
                                        <div className='row'>
                                            <div className='col-md-12 col-12'>
                                                <div className='table-crm-payment'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='table-responsive'>
                                                                <table className='table gy-4 gx-4 mb-0 table-bordered'>
                                                                    <thead className='text-uppercase bg-projects-list font-weight-normal'>
                                                                        <tr>
                                                                            <th> Name</th>
                                                                            <th> Type</th>
                                                                            <th> Metrics </th>
                                                                            <th> </th>
                                                                            <th> </th>
                                                                            <th> </th>
                                                                            <th> </th>
                                                                            <th> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SocialData.length > 0 ? SocialData.map((item, i) => (
                                                                            <tr key={i}>
                                                                                <td >
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="form-check form-switch">
                                                                                            <input className="form-check-input" type="checkbox" />
                                                                                        </div>
                                                                                        <div className="ms-5">
                                                                                            <span className="font-weight-semibold font-12 mb-1 d-block"> Reminder to follow up.... </span>
                                                                                            <span className="font-10 mb-1 d-block"> Created by: You </span>
                                                                                            <span className="font-10 mb-1 d-block"> Last Sent: 30 minutes ago </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td><span className="lead-badge  d-inline-block"> Lead </span></td>
                                                                                <td>{item.metrics}</td>
                                                                                <td> {item.one}</td>
                                                                                <td>{item.two}</td>
                                                                                <td>{item.three}</td>
                                                                                <td>{item.four}</td>
                                                                                <td>{item.five}</td>

                                                                            </tr>
                                                                        )) : <tr><td colSpan={3}>No data..</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100 d-flex justify-content-end'>
                                                                    <CompactPagination
                                                                        pageSize={pageSize}
                                                                        totalCount={SocialData.length}
                                                                        defaultPage={currentPage}
                                                                        onPageChange={setCurrentPage}
                                                                        loading={false}
                                                                    />
                                                                </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="card-body p-3">

                            <Email chartData={chartData} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}