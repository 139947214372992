import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import {getMyTeamUserList} from '../../../../../constants/axios/apis'
import {Link, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../constants'
import {useTranslation} from 'react-i18next'
import {CustomLoader2, NoDataFound1} from '../../../../../constants/generics/customActios'
import {CompactPagination} from '../../../../../components/partials/content/CompactPagination/CompactPagination'

// export const MyTeamTechniciansTable:React.FC<any> = ({fcsMyTeamList}) => {
export function MyTeamTechniciansTable({filterRole, searchItem, setSearchItem}: any) {
  const {t} = useTranslation()
  const location = useLocation()
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const state: any = useSelector((state) => state)
  const pageSize = 10
  const [teamTechniciansList, setTeamTechniciansList] = useState<any>()
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalTeamTechniciansList, setTotalTeamTechniciansList] = useState<any>(0)
  const [page, setPage] = React.useState(1)
  // const columns = [
  //     {
  //         field: 'no',
  //         headerName: t('No'),
  //         sortable: false,
  //         disableColumnMenu: true,
  //         width: 100,
  //     },
  //     {
  //         field: 'name',
  //         headerName: t('Name'),
  //         minWidth: 250,
  //         flex: 1
  //     },
  //     {
  //         field: 'mobile',
  //         headerName: t('Mobile Number'),
  //         minWidth: 150,
  //         flex: 1
  //     },
  //     {
  //         field: 'company',
  //         headerName: t('Company Name'),
  //         minWidth: 180,
  //         flex: 1
  //     },
  //     // {
  //     //     field: 'fm_contact_number',
  //     //     headerName: t('FM Contract Number'),
  //     //     minWidth: 200,
  //     //     flex: 1
  //     // },
  //     {
  //         field: 'status',
  //         headerName: t('Account Status'),
  //         width: 150,
  //         renderCell: (val: any) => {
  //             return val.row.status===10 ? <span className='text-warning'>{t("Pending")}</span>
  //              : val.row.status===0 ? <span className='text-danger'>{t("Inactive")}</span> : <span className='text-success'>{t("Active")}</span>
  //         }
  //     },
  //     {
  //         field: 'user_data',
  //         headerName: 'Action',
  //         sortable: false,
  //         disableColumnMenu: true,
  //         width: 100,
  //         renderCell: (val:any) => {
  //             return <Link to={{'pathname':'/fc-su-myteam-details', state: {'user':val.row.user_data, 'prevPath':val.row.prev_path} }}>
  //                 <span className="view-option text-theme fw-bolder">
  //                     {t("View")}
  //                 </span>
  //             </Link>
  //         },
  //     },
  // ];

  const handleClick = (newCurrentPage: any) => {
    setCurrentPage(newCurrentPage)
  }

  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding])

  useEffect(() => {
    setLoading(true)
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&user_role=' +
      filterRole +
      '&created_by=' +
      state?.auth?.user?.data.id +
      '&id_sort=1' +
        '&search=' +
        searchItem +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getMyTeamUserList(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setTeamTechniciansList(response.data)
        setTotalTeamTechniciansList(response.dataCount)
      } else {
        setTeamTechniciansList([])
        setTotalTeamTechniciansList(0)
      }
      setLoading(false)
    }).catch((e)=>setLoading(false))
  }, [filterRole, currentPage, currentBuilding, searchItem])

  if (!loading && (teamTechniciansList === undefined || teamTechniciansList?.length === 0)) {
    return <NoDataFound1 />
  }

  // const {loading, response: teamListData, error} = fcsMyTeamList;
  // const [teamRow, setTeamRow] = useState<any>([]);
  // const [error, setError] = useState<any|null>();

  // console.log(state?.auth?.user?.data.id, 'state?.auth?.user?.data.id');

  // useEffect(()=>{
  //     setTeamRow([])
  //     setLoading(true)
  //     setError(null)
  //     // let payload = '?user_role=' + filterRole + '&type=staff'
  //     let payload = '?user_role=' + filterRole + '&created_by=' + state?.auth?.user?.data.id
  //     if(currentBuilding)
  //         payload = currentBuilding ? payload + '&building_id=' + currentBuilding : ''
  //     getMyTeamUserList(payload)
  //         .then((response:any) => {
  //             if(response.errorCode === 0){
  //                 // setFcsMyTeamUsers(response?.data)

  //                 const teamRow = response?.data?.reverse().map((member: any, index: number) => {
  //                     return {
  //                         no: index + 1,
  //                         name: member.first_name + ' ' + member.last_name,
  //                         mobile: member.mobile,
  //                         company: member.companyName,
  //                         fm_contact_number: member.FMContractNumber,
  //                         user_data: member,
  //                         status: member.isFmApproved === 0 ? 10 : member.status,
  //                         prev_path: location.pathname,
  //                     }
  //                 });
  //                 setTeamRow(teamRow);

  //             }else if(response.errorCode === 1){
  //                 setError(response.errorDescription)
  //             }else if(response === 400){
  //                 //TODO: api should return 200 even if there is no data but api call is success
  //                 // setErrorFCSS('No data')
  //             }else{
  //                 setError("Some error occured")
  //             }
  //             setLoading(false)
  //         }).catch((e:any)=>{
  //             setLoading(false)
  //             setError("Some error occured")
  //             console.log(e, 'error in team list');
  //         })
  // },[filterRole, currentBuilding]);

  const teamTechniciansData = teamTechniciansList?.map((data: any, index: any) => (
    <tr key={data.id}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td>
        {data.first_name + ' ' + data.last_name ? data.first_name + ' ' + data.last_name : '--'}
      </td>
      <td>{data.mobile ? data.mobile : '--'}</td>
      <td>{data.companyName ? data.companyName : '--'}</td>
      <td>{data.FMContractNumber ? data.FMContractNumber : '--'}</td>
      <td
        className={
          data?.isFmApproved===0 ? 'text-warning' : data?.status === 0 ? 'text-danger' : 'text-success'
        }
      >
        {data?.isFmApproved===0 ? (t('Pending')) : data?.status === 0 ? (t('Inactive')) : (t('Active'))}
      </td>

      <td>
        <Link
          to={{
            pathname: '/fc-su-myteam-details',
            state: {user: data, prevPath: location.pathname},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t('View')}</span>
        </Link>
      </td>
    </tr>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-4 py-4'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                        <thead>
                          <tr>
                            <th className=''> {t('No.')} </th>
                            <th className=''> {t('Name')}</th>
                            <th className=''> {t('Mobile Number')} </th>
                            <th className=''> {t('Company Name')} </th>
                            <th className=''> {t('FM Contract Number')}</th>
                            <th className=''> {t('Account Status')}</th>
                            <th className=''> {t('Action')} </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={7}>
                                <CustomLoader2 />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>{teamTechniciansList ? teamTechniciansData : ''}</tbody>
                        )}
                      </table>
                      <CompactPagination
                        pageSize={pageSize}
                        totalCount={parseInt(totalTeamTechniciansList)}
                        defaultPage={currentPage}
                        onPageChange={setCurrentPage}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
