import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Nortification from '../../../assets/images/nortificationbell.svg'
import {getUserNotifications, postReadNotifications} from '../../../constants/axios/apis'
import moment from 'moment'
import { getNotificationLinks } from '../../../constants/generics/notificationLinks'
import { useDispatch, useSelector } from 'react-redux'
import { getUserRole } from '../../../constants/generics/userCheck'
import {useTranslation} from 'react-i18next'
import { CustomLoader2, NoDataFound1 } from '../../../constants/generics/customActios'
import * as General from '../../../constants/redux/Sagas/GeneralSaga'
import { RootState } from '../../../constants'

export function NotificationsList() {
  const {t} = useTranslation()
  const [notifications, setNotifications] = useState<any>([])
  const [readNotifs, setReadNotifs] = useState<any>([])
  const linkState:any = getNotificationLinks()
  const [loading, setLoading] = useState<any>(true)
  const dispatch = useDispatch()

  const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);

  let notifCount: any
    notifCount = useSelector<RootState>(({ General }) => General.notif)

  // const handleRead = (notification:any) =>{
  //   if(notification?.is_read === 0){
  //     const formData = new FormData()
  //     formData.append('notification_id', notification?.id)
  //     postReadNotifications(formData).then((response: any) => {
  //       //no action required
  //       // console.log(response, 'response');
  //       const notifCountTemp = isNaN(notifCount) ? '' : Number(notifCount) - 1
  //       dispatch(General.actions.notif(notifCountTemp))
        
  //     })
  //   }
  // }

  const handleRead = (notification: any) => {
    if (notification?.is_read === 0 && !(readNotifs.includes(notification.id))) {
      const formData = new FormData()
      formData.append('notification_id', notification?.id)
      postReadNotifications(formData).then((response: any) => {
        //no action required
        // console.log(response, 'response');
        const notifCountTemp = isNaN(notifCount) ? '' : Number(notifCount) - 1
        dispatch(General.actions.notif(notifCountTemp))
      })
      setReadNotifs((oState: any) => {
        let tempArr = [...oState]
        tempArr.push(notification?.id)
        return tempArr
      })
    }
  }


  useEffect(() => {
    setLoading(true)
    getUserNotifications().then((response: any) => {
      if (response.data) {
        setNotifications(response.data)
        dispatch(General.actions.notif(response.unreadCount))
      }
      setLoading(false)
    }).catch(()=>{setLoading(false)})
  }, [])

  // console.log('notifications', notifications)

  const notificationList = notifications?.map((notification: any) => {
    let targetLink = ''
    let targetStateKey = ''
    let targetStateValName = ''
    let stateVal = ''
    let viewBtnText

    linkState.every((element:any) => {
      if(element.notif_type.includes(notification.notification_type) && (element.permission === undefined || element.permission.includes(user_role))){
        targetLink = element.link;
        targetStateKey = element.stateKey
        targetStateValName = element.stateValName
        stateVal = (element.keyname ? notification?.other_data?.[element.keyname] : notification.notification_id)
        viewBtnText = element.viewBtn ? element.viewBtn : (t("View"))
        return false
      }
      return true
    });

    const isAlreadyRead = notification.is_read === 1 || (readNotifs.includes(notification.id))
    return(
    <div key={notification.id} className='col-12'>
      <div className='card notification-my-profile mb-3'>
        {/* <div className='card-body p-5'> */}
      <div className={isAlreadyRead ? 'card-body p-5' : 'card-body p-5 c-pointer'} onClick={() => handleRead(notification)}>
          {/* <div className='row'>
            <div className='notification_inner_div'>
              <div className=''> */}
                <div className='row align-items-center'>
                  <div className='col-1'>
                    <div className='notification_Star'>
                    <img src={Nortification} className='img-fluid' />
                  </div>
                  </div>
                  <div className='col-md-9'>
                    <div className='d-flex'>
                      <h4 className='notif-title font-17 fw-bold mb-3'>
                        {notification.title}
                      </h4>
                      <span className='notif-time font-14 fw-bold ml-3'>
                        {moment(notification.created_at).fromNow()}
                      </span>
                      {!isAlreadyRead &&
                            <span className='ms-2 bullet bullet-dot bg-danger h-6px w-6px translate-middle top-5 start-70 animation-blink'></span>
                          }
                    </div>
                    <h6 className='notif-desc fw-normal font-14'>{notification.description}</h6>
                  </div>
                  <div className='col-2 text-end'>
                  {
                  user_role && targetLink !== '' && ['FC', 'FCS', 'FCSS', 'FMT', 'FMSM','FT','MA','MT', 'PM', 'PE'].includes(user_role) && stateVal?
                    <>
                     
                        <Link
                          to={{
                            pathname: targetLink,
                            // state: targetStateKey !== "" ? {[targetStateKey]: {[targetStateValName]: stateVal}} : '',
                            state: targetStateKey !== "" ?
                                      {[targetStateKey]: targetStateValName ? {[targetStateValName]: stateVal} : stateVal} : '',
                          }}
                          onClick={(e)=>handleRead(notification)}
                          // data-bs-dismiss='modal'
                          className='btn btn-primary fw-bold px-10 py-3'
                          // className='btn btn-secondary fw-bold px-10 py-3 me-5 mb-3 mb-md-0'
                        >
                          {viewBtnText}
                        </Link>
                     
                    </>
                    :
                    ''
                }
                </div>
                </div>
              {/* </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )})

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-md-12'>
            <h4 className='card-title font-18 text-black mb-6'>{t("Notifications")}</h4>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card rounded-10'>
              <div className='card-body px-md-8 px-5 py-6'>
                <div className='row'>
                  {loading ?
                    <CustomLoader2 /> :
                    <div className='notification_main_card'>
                      {notifications && notifications.length ? notificationList :
                        <NoDataFound1 />}
                      {/* <div className='col-12'>
                        <div className='card notification-my-profile mb-3'>
                          <div className='card-body p-5'>
                            <div className='row'>
                              <div className='notification_inner_div'>
                                <div className='notification_Star'>
                                  <img src={StarActive} className='img-fluid' />
                                </div>
                                <div className='notification_details'>
                                  <div className='row gx-10'>
                                    <div className='col-md-9'>
                                      <h4 className='notif-title font-17 fw-bold mb-3'>
                                        Upcoming Service Request
                                      </h4>
                                    </div>
                                    <div className='col-md-3 text-end'>
                                      <span className='notif-time font-14 fw-bold'>4:15pm</span>
                                    </div>
                                    <div className='col-12'>
                                      <h6 className='notif-desc fw-normal font-14'>
                                        Upcoming Service Request
                                      </h6>
                                    </div>
                                    <div className='col-12 mt-5'>
                                      <button
                                        type='submit'
                                        className='btn btn-primary fw-bold px-10 py-3 me-5'
                                      >
                                        Renew Contract
                                      </button>
                                      <button
                                        type='submit'
                                        className='btn btn-outline-danger fw-bold px-10 py-3 bg-white'
                                      >
                                        Terminate Contract
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='card notification-my-profile mb-3'>
                          <div className='card-body p-5'>
                            <div className='row'>
                              <div className='notification_inner_div'>
                                <div className='notification_Star'>
                                  <img src={StarInactive} className='img-fluid' />
                                </div>
                                <div className='notification_details'>
                                  <div className='row gx-10'>
                                    <div className='col-md-9'>
                                      <h4 className='notif-title font-17 fw-bold mb-3'>
                                        New Property Added
                                      </h4>
                                    </div>
                                    <div className='col-md-3 text-end'>
                                      <span className='notif-time font-14 fw-bold'>4:15pm</span>
                                    </div>
                                    <div className='col-12'>
                                      <h6 className='notif-desc fw-normal font-14'>
                                        Lorem Ipsum és un text de farciment usatper la indústria de la
                                        tipografia i la impremta. Lorem Ipsum ha estat el text
                                        estàndard de la tres tipogràfiques.
                                      </h6>
                                    </div>
                                    <div className='col-12 mt-5'>
                                      <button
                                        data-bs-target='#feedback-popup'
                                        data-bs-toggle='modal'
                                        className='btn btn-outline-secondary fw-bold px-10 py-3 me-5'
                                      >
                                        Rate Us
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='card notification-my-profile mb-3'>
                          <div className='card-body p-5'>
                            <div className='row'>
                              <div className='notification_inner_div'>
                                <div className='notification_Star'>
                                  <img src={StarInactive} className='img-fluid' />
                                </div>
                                <div className='notification_details'>
                                  <div className='row gx-10'>
                                    <div className='col-md-9'>
                                      <h4 className='notif-title font-17 fw-bold mb-3'>
                                        New Property Added
                                      </h4>
                                    </div>
                                    <div className='col-md-3 text-end'>
                                      <span className='notif-time font-14 fw-bold'>4:15pm</span>
                                    </div>
                                    <div className='col-12'>
                                      <h6 className='notif-desc fw-normal font-14'>
                                        Lorem Ipsum és un text de farciment usatper la indústria de la
                                        tipografia i la impremta. Lorem Ipsum ha estat el text
                                        estàndard de la tres tipogràfiques.
                                      </h6>
                                    </div>
                                    <div className='col-12 mt-5'>
                                      <button
                                        data-bs-target='#feedback-popup'
                                        data-bs-toggle='modal'
                                        className='btn btn-outline-secondary fw-bold px-10 py-3 me-5'
                                      >
                                        Rate Us
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='card notification-my-profile mb-3'>
                          <div className='card-body p-5'>
                            <div className='row'>
                              <div className='notification_inner_div'>
                                <div className='notification_Star'>
                                  <img src={StarInactive} className='img-fluid' />
                                </div>
                                <div className='notification_details'>
                                  <div className='row gx-10'>
                                    <div className='col-md-9'>
                                      <h4 className='notif-title font-17 fw-bold mb-3'>
                                        New Property Added
                                      </h4>
                                    </div>
                                    <div className='col-md-3 text-end'>
                                      <span className='notif-time font-14 fw-bold'>4:15pm</span>
                                    </div>
                                    <div className='col-12'>
                                      <h6 className='notif-desc fw-normal font-14'>
                                        Lorem Ipsum és un text de farciment usatper la indústria de la
                                        tipografia i la impremta. Lorem Ipsum ha estat el text
                                        estàndard de la tres tipogràfiques.
                                      </h6>
                                    </div>
                                    <div className='col-12 mt-5'>
                                      <button
                                        data-bs-target='#feedback-popup'
                                        data-bs-toggle='modal'
                                        className='btn btn-outline-secondary fw-bold px-10 py-3 me-5'
                                      >
                                        Rate Us
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='card notification-my-profile mb-3'>
                          <div className='card-body p-5'>
                            <div className='row'>
                              <div className='notification_inner_div'>
                                <div className='notification_Star'>
                                  <img src={StarInactive} className='img-fluid' />
                                </div>
                                <div className='notification_details'>
                                  <div className='row gx-10'>
                                    <div className='col-md-9'>
                                      <h4 className='notif-title font-17 fw-bold mb-3'>
                                        New Property Added
                                      </h4>
                                    </div>
                                    <div className='col-md-3 text-end'>
                                      <span className='notif-time font-14 fw-bold'>4:15pm</span>
                                    </div>
                                    <div className='col-12'>
                                      <h6 className='notif-desc fw-normal font-14'>
                                        Lorem Ipsum és un text de farciment usatper la indústria de la
                                        tipografia i la impremta. Lorem Ipsum ha estat el text
                                        estàndard de la tres tipogràfiques.
                                      </h6>
                                    </div>
                                    <div className='col-12 mt-5'>
                                      <button
                                        data-bs-target='#feedback-popup'
                                        data-bs-toggle='modal'
                                        className='btn btn-outline-secondary fw-bold px-10 py-3 me-5'
                                      >
                                        Rate Us
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
