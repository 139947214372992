import React, { useState, useEffect } from 'react';
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import WorkOrdersImg from "../../../../assets/images/ac-repair.png";
import MarkedIcon from "../../../../assets/images/green-dot.png";
import LocationIcon from "../../../../assets/images/loc-mark-icon.png";
import NoImage from "../../../../assets/images/House.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'

import Loader from '../../../../components/Loader'
import { useSelector } from 'react-redux';
import { formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import moment from 'moment';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';

import { useTranslation } from 'react-i18next';


export const UpcomingWorkOrders:React.FC<any> = ({ fcsWorkOrderList, noncompletedWorkOrders }) => {
    const {loading, response, error} = fcsWorkOrderList;

    const state:any = useSelector(state => state);
    const id_role = state?.auth?.user?.data?.id_role;

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            }
        ]
    };

    // const [workOrderImage, setWorkOrderImage] = useState<any>("");
    // useEffect(()=>{
    //     if(Array.isArray(workOrder?.objectDetails?.attachments_json?.photo)){
    //         if(workOrder?.objectDetails?.attachments_json?.photo.length > 0){
    //             setWorkOrderImage(workOrder?.objectDetails?.attachments_json?.photo[0]);
    //         }
    //     }
    // },[]);
    const { t } = useTranslation();
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-7">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h4 className="card-title"> {t("Upcoming Work Orders")} </h4>
                                </div>
                                {noncompletedWorkOrders?.length > 2 ? (
                                    <div className="col-md-6 col-12 d-flex justify-content-end align-items-center pe-md-20">
                                        <Link to="/facility-workorder/list/corrective/open" className="text-decoration-none view-all">
                                            <span> {t("View All")} </span>
                                            <img src={RightArrow} className="img-fluid" />
                                        </Link>
                                    </div>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            <div className="upcoming-work-order-sec">
                                <div className="row">
                                    <div className="col-md-12 col-12 col-lg-12">
                                        <div className="row">
                                            {loading && (
                                                <CustomLoader2 height={'166'}/>
                                            )}
                                            {!loading && (
                                                <div className="col-12">
                                                    {noncompletedWorkOrders?.length ? 
                                                        (
                                                            <Slider {...settings}>
                                                                {noncompletedWorkOrders.map((recent: any, index: number)=>{
                                                                    let workOrderImage = recent.buildings && recent.buildings[0] && recent.buildings[0].communityLogo ? recent.buildings[0].communityLogo : NoImage
                                                                    // if(recent.issueCategory === 'Clearance'){
                                                                    //     workOrderImage = recent.buildings && recent.buildings[0] ? recent.buildings[0].cover_image : NoImage
                                                                    // }else{
                                                                    //     workOrderImage = recent?.objectDetails?.attachments_json?.photo && recent.objectDetails.attachments_json.photo[0] ? recent?.objectDetails?.attachments_json?.photo[0] : NoImage;
                                                                    // }
                                                                    // const workOrderImag/fc-workorder/moveoutinspection/confirmede = recent?.objectDetails?.attachments_json?.photo && recent.objectDetails.attachments_json.photo[0] ? recent?.objectDetails?.attachments_json?.photo[0] : NoImage;
                                                                    // const viewLink = recent.issueCategory === "Clearance" ?
                                                                    // ( recent.status === 'Completed' ? '/fc-workorder/moveoutinspection/completed'
                                                                    // : recent.status === 'Confirmed' ? '/fc-workorder/moveoutinspection/confirmed'
                                                                    // : recent.status === 'Open' ? '/fc-workorder/moveoutinspection/open'
                                                                    // : '/'
                                                                    // ): '/fc-workorder/work-order-details'
                                                                    return (
                                                                    <div key={index}>
                                                                        {/* <Link to={'/fc-workorder/work-order-details'}> */}
                                                                        <Link to={{'pathname':'/facility-workorder/view', state: {'workOrder': recent}}}>
                                                                            <div className="card mt-4">
                                                                                <div className="card-body">
                                                                                    <div className="row">
                                                                                        <div className="col-md-4 col-lg-3 my-auto col-12">
                                                                                            <div className="img-bg">
                                                                                                <img src={workOrderImage} className="img-fluid position-relative" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-8 col-lg-9 col-12">
                                                                                            <div className="d-flex flex-nowrap justify-content-between fdrr-rtl">
                                                                                                <h4 className="card-title mb-2"> {recent.issueCategory === 'Clearance'? recent.typeText === 'Move Out' ? 'Move Out Inspection' : recent.typeText : recent.subCategory} </h4>
                                                                                                <span className="count">{recent.status}</span>
                                                                                            </div>
                                                                                            <h6 className="sub-title">
                                                                                                {recent.property_code} 
                                                                                            </h6>
                                                                                            <h6 className="ref-no mb-1">
                                                                                                {recent.issueCategory}
                                                                                            </h6>
                                                                                            <div className="d-flex flex-nowrap justify-content-between align-items-center fdrr-rtl">
                                                                                                <span className="sch-status">
                                                                                                    <img src={MarkedIcon} className="img-fluid me-1 d-inline" />
                                                                                                    {moment(recent.date).format("DD-MM-YYYY")}{' '}
                                                                                                    {recent.issueCategory === "Clearance" ? recent?.objectDetails?.referenceDetails?.time_slot : formatTimeMeridiem(recent.time)}
                                                                                                    {/* {formatTimeMeridiem(recent.time)} */}
                                                                                                </span>
                                                                                                <span className="text-primary">{t("View Detail")}</span>
                                                                                                {/* <Link to={{'pathname':'/fc-workorder/work-order-details', state: {'workOrder': recent}}}> View Detail </Link> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                    )
                                                                })}
                                                            </Slider>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <NoDataFound1 height={'110'} />
                                                            </>
                                                        )
                                                    }
                                                    
                                                </div>
                                                
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
    );
}