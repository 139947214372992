import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom'

import Avatar from '../../../../../assets/images/SquareAvatar.png';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../constants';
import { getMyTeamUserList } from '../../../../../constants/axios/apis';
import { CustomLoader2, NoDataFound1 } from '../../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';
import { CompactPagination } from '../../../../../components/partials/content/CompactPagination/CompactPagination';

export function SubMembers({userID, filterRole}:any) {
    const { t } = useTranslation();

    const currentBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.selectedBuilding)

    const location = useLocation()
    const pageSize = 6
    // const {loading, response: teamListData, error} = fcsMyTeamList;
    const [teamRow, setTeamRow] = useState<any>([]);
    const [flteredTeamRow, setFilteredTeamRow] = useState<any>([]);
    const [searchKey, setSearchKey] = useState<any>('');
    const [loading, setLoading] = useState<any>(true);
    const [error, setError] = useState<any|null>();
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalTeamList, setTotalTeamList] = useState<any>(0);

    useEffect(()=>{
        setTeamRow([])
        setLoading(true)
        setError(null)
        // let payload = '?type=staff'
        let page = currentPage
        let payload = '?user_role=' + filterRole + '&created_by=' + userID+'&currentPage=' + page + '&pageSize=' + pageSize 
        // if(currentBuilding)
        //     payload = currentBuilding ? payload + '&building_id=' + currentBuilding : ''
        if(searchKey !== '')
            payload += '&search=' + searchKey
        getMyTeamUserList(payload)
            .then((response:any) => {
                if(response.errorCode === 0){
                    const filteredData = response?.data?.filter((el:any)=>
                        el.staff_type==='Facility Contractor'
                        // && el.createdBy?.[0]?.userId===userID
                    )
                    const formatedData = filteredData?.reverse().map((member:any)=>{
                        return({
                            name: member.first_name + ' ' + member.last_name,
                            email: member.email,
                            mobile: member.mobile,
                            memberData: member
                        })
                    })
                    //TODO - add team lead based filter & FC filter
                    setTeamRow(formatedData);
                    setTotalTeamList(response.dataCount);                    
                    
                }else if(response.errorCode === 1){
                    setError(response.errorDescription)
                }else{
                    setError(t("Some error occured!"))
                }
                setLoading(false)
            }).catch((e:any)=>{
                setLoading(false)
                setError(t("Some error occured!"))
            })
    },[currentBuilding, currentPage, searchKey]);

    useEffect(()=>{
        setCurrentPage(1)
    },[searchKey, currentBuilding]);
    
    // useEffect(()=>{
    //     if(searchKey !== ''){
    //         const searchRegex = new RegExp(searchKey, 'i');
    //         const filteredList = teamRow.filter((item:any)=>item?.name?.match(searchRegex)
    //             || item?.emirates_id?.match(searchRegex) || item?.mobile?.match(searchRegex))
    //         setFilteredTeamRow(filteredList)
    //     }else{
    //         setFilteredTeamRow(teamRow)
    //     }
    // },[searchKey, teamRow]);
    
    

    return (
        <div className='col-12 mt-5'>
            <div className='card'>
                <div className='card-body px-5 py-2'>
                    <div className='row'>
                        <div className="col-12 mt-4">
                            <div className="row access-cd-tabs align-items-center">
                                <div className="col-sm-4">
                                    <h4 className="card-title font-18 text-black">{t("Team Members")}</h4>
                                </div>
                                <div className="col-sm-6">
                                    <div className='d-flex overflow-auto h-55px float-end me-6 me-sm-10'></div>
                                </div>
                                <div className="col-sm-2">
                                    <div className='row'>
                                        <div className='col-lg-12 pe-3 my-auto'>
                                            <div className="input-group custom-search-form">
                                                <input type="text" 
                                                    className="form-control search-input"
                                                    placeholder='Search'
                                                    onChange={(e)=>setSearchKey(e.target.value)}
                                                />
                                                <span className="input-group-btn">
                                                    <button className="search_icon" type="button">
                                                        <span className=""> </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mta_my_team_team_members mt-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            {loading ? <CustomLoader2 height={'166'}/>
                                                : teamRow?.length ?
                                                <>
                                                    { teamRow?.map((member:any)=>{
                                                        const profileImg = member.profile_img && member.profile_img !== ''? member.profile_img : Avatar
                                                        return (
                                                            <div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                                                <Link 
                                                                    to={{'pathname':'/fc-su-myteam-details', state: {'user':member.memberData, 'prevPath':location.pathname} }}
                                                                    >
                                                                    <div className="mta_team_members_card mb-5">
                                                                        <div className="card px-3 py-3" id="team_member">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-sm-3 col-4">
                                                                                    <div className="offer_main_img">
                                                                                        <img src={profileImg} alt="image" className="img-fluid me-3" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-9 col-8">
                                                                                    <div className="offer_main_details">
                                                                                        <div className="col-12">
                                                                                            <p className="text-black fw-bolder font-14 mb-2">{member.name} </p>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6 col-12">
                                                                                                    <p className="mb-0 text-black">{t("Email Id")}:</p>
                                                                                                    <p className="mb-0 text-black">{member.email}</p>
                                                                                                </div>
                                                                                                <div className="col-sm-6 col-12">
                                                                                                    <p className="mb-0 text-black">{t("Contact No.")}: </p>
                                                                                                    <p className="mb-0 text-black">{member.mobile}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                        })
                                                    }
                                                </>
                                                : <NoDataFound1 height={'110'} />
                                            }
                                        </div>
                                        <div className="w-100 d-flex justify-content-end">
                                            {/* <Stack spacing={2}>
                                                <Pagination 
                                                count={totalTeamList % pageSize === 0 ? totalTeamList / pageSize : Math.ceil(totalTeamList / pageSize)} 
                                                onChange={handlePageChange}
                                                />
                                            </Stack> */}
                                            <CompactPagination
                                                pageSize={pageSize}
                                                totalCount={parseInt(totalTeamList)}
                                                defaultPage={currentPage}
                                                onPageChange={setCurrentPage}
                                                loading={loading}
                                            />
                                        </div>
                                        {/* <div className="row">
                                            {myTeamData?.errorCode != 1 && myTeamData?.data?.teamList?.length > 0 ? myTeamData?.data?.teamList?.map((item: any, index: number) => {
                                                const sl = (pageSize * (currentPage - 1)) + index + 1;
                                                return (<><div className="col-xl-4 col-sm-12 col-md-6 col-lg-6 col-12">
                                                    <Link to={{
                                                        pathname: '/pm-myteam-team-members-details',
                                                        state: { id: item?.user_id }
                                                    }}>
                                                        <div className="mta_team_members_card mb-5">
                                                            <div className="card px-3 py-3" id="team_member">
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-3 col-4">
                                                                        <div className="offer_main_img">
                                                                            {item?.profileImage != null ? <img src={item?.profileImage} alt="Work Orders" className="img-fluid me-3" /> :
                                                                                <DefaultAvatar classname={"img-fluid me-3"} height={100} alt={item?.name} />}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-9 col-8">
                                                                        <div className="offer_main_details">
                                                                            <div className="col-12">
                                                                                <p className="text-black fw-bolder font-14 mb-2">{item?.name} </p>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    <div className="col-sm-6 col-12">
                                                                                        <p className="mb-0 text-black">Emirates Id:</p>
                                                                                        <p className="mb-0 text-black">{item?.emiratesId}</p>
                                                                                    </div>
                                                                                    <div className="col-sm-6 col-12">
                                                                                        <p className="mb-0 text-black">Contact No.: </p>
                                                                                        <p className="mb-0 text-black">{item?.mobile}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div></>);
                                            })
                                                : <NoDataFound />}
                                        </div>
                                        <div className="w-100 d-flex justify-content-end">
                                            <Stack spacing={2}>
                                                <Pagination count={flteredtotalTeamList % pageSize === 0 ? totalTeamList / pageSize : Math.ceil(totalTeamList / pageSize)} onChange={handlePageChange} />
                                            </Stack>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}