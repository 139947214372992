import React, {useState, useEffect, useRef} from 'react'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import VisitorAvatar from '../../../../../assets/images/SquareAvatar.png'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {getFacilityVisitors} from '../../../../../constants/axios/apis'
import {PopupModel} from './PopupModel'
import {
  formatDateTimeMeridiem,
  formatTimeMeridiem,
} from '../../../../../constants/generics/dateTimeFormater'
import moment from 'moment'
import {format} from 'path'
import {formatDate} from '@formatjs/intl'
import {useSelector} from 'react-redux'
import {getUserRole} from '../../../../../constants/generics/userCheck'
import {CustomLoader2} from '../../../../../constants/generics/customActios'

export function VisitorDetails() {
  let params: any = {}
  params = useLocation()

  let visitorsId = ''
  if (params.state.visitorsData) {
    visitorsId = params.state.visitorsData.id
  }
  // const {visitorsData} = params.state
  const [visitorsData, setVisitorsData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [newDate, setNewDate] = useState<any>()
  const successRef = useRef<any>()
  const {t} = useTranslation()
  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)

  const handlePopup = () => {
    setNewDate(new Date())
    successRef.current.click()
  }

  useEffect(() => {
    setLoading(true)
    setVisitorsData({})
    getFacilityVisitors(`?id=${visitorsId}`).then((response: any) => {
      if (response?.data && Array.isArray(response.data) && response.data.length > 0) 
        setVisitorsData(response.data[0])
      setLoading(false)
    })
  }, [visitorsId])

  return (
    <>
      <div className='com-card-section'>
        {loading ? 
          <CustomLoader2 />
         : 
          <div className='row'>
            <div className='col-md-12 col-12'>
              <h4 className='card-title font-18 text-black mb-6'>
                {/* <Link to={'/visitors/all'} className=''> */}
                <div
                  className='me-5 c-pointer'
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                  <span className='text-black'>{t('Visitor Details')}</span>
                </div>
                {/* </Link> */}
              </h4>
            </div>
            <div className='col-12'>
              <div className='card mb-3'>
                <div className='card-body px-6 py-6'>
                  <div className='row'>
                    <div className='col-md-4 col-lg-3 col-12'>
                      <div className='visitor-avatar'>
                        <img src={VisitorAvatar} className='img-fluid rounded' alt='Avatar' />
                      </div>
                    </div>
                    <div className='col-md-8 col-lg-9 col-12'>
                      <div className='row'>
                        {/* <div className='col-md-12 col-12'>
                      <span className='text-black fw-bolder font-15 d-block mb-5'>
                        {visitorsData?.status === 2
                          ? 'Checked Out Details'
                          : visitorsData?.status === 0
                          ? 'Upcoming Details'
                          : visitorsData?.status === 4
                          ? 'Not Visted Details'
                          : visitorsData?.status === 1
                          ? 'Checked In Details'
                          : visitorsData?.status}                        
                      </span>
                    </div> */}

                        <div className='col-md-12 my-5 my-md-3 my-lg-0 col-12 text-end'>
                          <span
                            className={
                              visitorsData?.status === 2
                                ? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                                : visitorsData?.status === 0
                                ? 'bg-info bg-opacity-10 text-info px-5 py-3 rounded fw-bolder'
                                : visitorsData?.status === 4
                                ? 'bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                                : visitorsData?.status === 1
                                ? 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                                : 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                            }
                          >
                            {' '}
                            {visitorsData?.status === 2
                              ? t('Checked Out')
                              : visitorsData?.status === 0
                              ? t('Upcoming')
                              : visitorsData?.status === 4
                              ? t('Not Visted')
                              : visitorsData?.status === 1
                              ? t('Checked In')
                              : visitorsData?.status}
                          </span>
                        </div>

                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t('Name')} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {visitorsData?.first_name + ' ' + visitorsData?.last_name}{' '}
                          </span>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                              {' '}
                              {t('Mobile Number')}{' '}
                            </h6>
                            <span className='text-black fw-bold font-15'>
                              {' '}
                              {visitorsData?.mobile_no ? visitorsData?.mobile_no : '--'}{' '}
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t('Email ID')}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {visitorsData?.email ? visitorsData?.email : '--'}{' '}
                          </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t('Emirates ID')}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {visitorsData?.emirates_id ? visitorsData?.emirates_id : '--'}{' '}
                          </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                            {' '}
                            {t('Building')}{' '}
                          </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {visitorsData?.building_name ? visitorsData?.building_name : '--'}
                          </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t('Unit')} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {visitorsData?.property_code ? visitorsData?.property_code : '--'}
                          </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <div className='mt-4'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                              {' '}
                              {t('Visitor Type')}{' '}
                            </h6>
                            <span className='text-black fw-bold font-15'>
                              {visitorsData?.visitor_type_name
                                ? visitorsData?.visitor_type_name
                                : '--'}
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-12'>
                          {(visitorsData?.status !== 4 || visitorsData?.status !== 0) && (
                            <div className='mt-4'>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {visitorsData?.status === 2
                                  ? t('Checked In Date/Time')
                                  : visitorsData?.status === 0
                                  ? t('Check In Date/Time')
                                  : visitorsData?.status === 4
                                  ? t('Check In Date/Time')
                                  : visitorsData?.status === 1
                                  ? t('Check In Date/Time')
                                  : visitorsData?.status}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {visitorsData?.check_in
                                  ? formatDateTimeMeridiem(visitorsData?.check_in)
                                  : '--'}
                              </span>
                            </div>
                          )}
                        </div>
                        {visitorsData?.status === 2 && (
                          <div className='col-md-4 col-12'>
                            <div className='mt-4'>
                              <h6 className='text-theme-gray mb-3 fw-normal font-14'>
                                {' '}
                                {t('Checked Out Date/Time')}
                              </h6>
                              <span className='text-black fw-bold font-15'>
                                {' '}
                                {visitorsData?.check_out
                                  ? formatDateTimeMeridiem(visitorsData?.check_out)
                                  : '--'}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {user_role === 'FCSS' && (visitorsData?.status === 0 || visitorsData?.status === 4) && (
              <div className='col-12 text-end mt-5'>
                <button
                  type='submit'
                  className='btn btn-primary fw-bold px-6 py-3'
                  data-bs-toggle='modal'
                  data-bs-target='#PopupModel'
                >
                  {t('Check In')}
                </button>
              </div>
            )}
            {user_role === 'FCSS' && visitorsData?.status === 1 && (
              <div className='col-12 text-end mt-5'>
                <button
                  type='button'
                  className='btn btn-primary fw-bold px-6 py-3'
                  onClick={handlePopup}
                >
                  {t('Check Out')}
                </button>
              </div>
            )}
          </div>
}
        <p
          ref={successRef}
          className='d-none'
          data-bs-toggle='modal'
          data-bs-target='#PopupModel'
        ></p>
        <PopupModel visitorsData={visitorsData} today={newDate} />
            
      </div>
    </>
  )
}
