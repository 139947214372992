import React, {useState, useEffect} from 'react'
import Loader from '../../../../../components/Loader'
import {getFacilityVisitors} from '../../../../../constants/axios/apis'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import {ActionMenu} from '../ActionMenu/ActionMenu'
import ActionDotsIoc from '../../../../../assets/images/Action_dots.png'
import {Link} from 'react-router-dom'
import {RootState} from '../../../../../constants'
import {useSelector} from 'react-redux'
import {CustomLoader2, NoDataFound1} from '../../../../../constants/generics/customActios'
import { CompactPagination } from '../../../../../components/partials/content/CompactPagination/CompactPagination'
import { useTranslation } from 'react-i18next';

export function CheckOutList({searchItem, setSearchItem}: any) {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const { t } = useTranslation();
  const pageSize = 10
  const [visitorList, setVisitorList] = useState<any>()
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalVisitorList, setTotalVisitorList] = useState<any>(0)
  const [page, setPage] = React.useState(1)
  setTimeout(() => {
    setLoading(false)
  }, 1500)

  const handleClick = (newCurrentPage: any) => {
    setCurrentPage(newCurrentPage)
  }

  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding])

  useEffect(() => {
    setLoading(true)
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&id_sort=1' +
      '&search=' + searchItem +
      '&status=out' +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getFacilityVisitors(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setVisitorList(response.data)
        // setCurrentPage(page);
        setTotalVisitorList(response.dataCount)
        setLoading(false)
      } else {
        setVisitorList([])
        setTotalVisitorList(0)
      }
    })
  }, [currentPage, currentBuilding,searchItem])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleClick(value)
  }

  if (!loading && (visitorList === undefined || visitorList.length === 0)) {
    return <NoDataFound1 />
  }

  const visitorData = visitorList?.map((data: any, index: any) => (
    <tr key={data.id}>
      <td>{(currentPage - 1) * pageSize + index + 1}</td>
      <td>{data.first_name + " " + data.last_name}</td>
      <td>{data.property_code ? data.property_code : '--'}</td>
      <td>{data.mobile_no ? data.mobile_no : '--'}</td>
      {/* <td>{data.emirates_id ? data.emirates_id : "--"}</td> */}
      <td>{data.visitor_type_name ? data.visitor_type_name : '--'}</td>
      <td className={data?.status === 2 ? 'text-success' : 'text-success'}>
        {data?.status === 2 ? (t('Checked Out')) : data?.status}
      </td>
      <td>
        <Link
          to={{
            pathname: '/visitors/details',
            state: {visitorsData: data},
          }}
          className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'
        >
          <span className='view-option text-theme fw-bolder'>{t("View")}</span>
        </Link>
      </td>
      {/* <td>
        <>
          <div className="dropdown">
            <button className="btn border-0 p-0 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <img src={ActionDotsIoc} className="img-fluid" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li className='pb-2'>
                <Link to={'/visitors/add'} className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'>
                  <i className="bi bi-pencil me-4"></i>
                  Edit
                </Link>
              </li>
              <li className='pb-2'>
                <Link to={'/visitors/details'} className='menu-link text-black fs-12 w-100 ps-2 pe-0 py-2'>
                  <i className="bi bi-eye me-4"></i>
                  View
                </Link>
              </li>
            </ul>
          </div>
        </>
      </td> */}
    </tr>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body p-0'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                        <thead>
                          <tr>
                            <th className=''> {t("No.")} </th>
                            <th className=''> {t("Name")}</th>
                            <th className=''> {t("Unit")} </th>
                            <th className=''> {t("Mobile Number")} </th>
                            {/* <th className=''> Emirates Id </th> */}
                            <th className=''> {t("Visitor Type")} </th>
                            <th className=''> {t("Status")} </th>
                            <th className=''>{t("Action")}</th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={7}>
                                <CustomLoader2 />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>{visitorList ? visitorData : ''}</tbody>
                        )}
                      </table>
                      <div className='w-100 d-flex justify-content-end my-5'>
                        {/* <Stack spacing={2}>
                          <Pagination
                            count={
                              totalVisitorList % pageSize === 0
                                ? totalVisitorList / pageSize
                                : Math.ceil(totalVisitorList / pageSize)
                            }
                            onChange={handlePageChange}
                          />
                        </Stack> */}
                        <CompactPagination
                          pageSize={pageSize}
                          totalCount={parseInt(totalVisitorList)}
                          defaultPage={currentPage}
                          onPageChange={setCurrentPage}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
