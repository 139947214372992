import React, {useState, useEffect, useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {postApprovalSubmit} from '../../../../constants/axios/apis'
import {CustomRequestSuccessfulPopup} from '../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import * as Yup from 'yup'
import {useFormik} from 'formik'

export function Actions({serviceDetail, setPopupSuccessMsg}: any) {
  const {t} = useTranslation()
  const approvalID =
    serviceDetail?.approvalId && serviceDetail?.approvalId !== '' ? serviceDetail?.approvalId : ''
  const history = useHistory()
  const successRef: any = useRef(null)
  const [approvalType, setApprovalType] = useState()
  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    message: '',
  })

  const initialValues = {
    comment: '',
  }
  const validationSchema = Yup.object().shape({
    comment: Yup.string()
      .required(t('Comment is required!'))
      .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!'))
      .max(250, t('Max length is 250!'))
      .test('len', t('Comment is required!'), (value) => {
        return value ? (value?.trim()).length > 0 : false
      }),
  })

  useEffect(() => {
    if (approvalID === '')
      setSubmitStatus((oStatus: any) => ({
        ...oStatus,
        loading: false,
        message: t('Incomplete approval data!'),
        error: true,
      }))
  }, [])

  const onSubmit = (values: any) => {
    if (approvalID !== '') {
      setSubmitStatus((oStatus: any) => ({...oStatus, loading: true}))

      var formData = new FormData()
      formData.append('approval_id', approvalID)
      formData.append('comment', values.comment)

      setTimeout(() => {
        postApprovalSubmit(formData, approvalType)
          .then((response: any) => {
            if (response.errorCode === 1) {
              setSubmitStatus((oStatus: any) => ({
                ...oStatus,
                loading: false,
                message: response.errorDescription,
                error: true,
              }))
            } else if (response.errorCode === 0) {
              setPopupSuccessMsg(response.description)
              setSubmitStatus((oStatus: any) => ({
                ...oStatus,
                loading: false,
                message: response.description,
              }))
              successRef.current.click()
              setTimeout(() => {
                // window.location.replace(`/fmteam-approvals/${linkType}/closed`);
                history.push(approvalType === 'rmi' ? '/finance-services-request/list/open' : '/finance-services-request/list/closed')
                // window.location.reload();
              }, 1000)
            } else {
              setSubmitStatus((oStatus: any) => ({
                ...oStatus,
                loading: false,
                message: t('Some error occured!'),
                error: true,
              }))
            }
            setSubmitStatus((oStatus: any) => ({...oStatus, loading: false}))
          })
          .catch((e) => {
            // console.log(e, 'Error');
            setSubmitStatus((oStatus: any) => ({
              ...oStatus,
              loading: false,
              message: t('Some error occured!'),
              error: true,
            }))
          })
      }, 1000)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const handleSubmit = (appvovalType: any) => {
    setApprovalType(appvovalType)
    formik.handleSubmit()
  }

  return (
    <>
      <div className='com-card-section pm_approval_buttons'>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body px-8 py-6'>
                {serviceDetail?.status === 'Open' ? (
                  <div className='row align-items-end'>
                    <div className='col-lg-12 col-sm-12 mb-5'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='font-14 fw-bold mb-3'>{t("Approver's Comment")}</label>
                          <textarea
                            className='form-control'
                            data-kt-autosize='true'
                            rows={3}
                            placeholder={t('Type here...')}
                            {...formik.getFieldProps('comment')}
                          />
                          {formik.touched.comment && formik.errors.comment ? (
                            <small className='text-danger'>{formik.errors.comment}</small>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className='col-12 text-end'>
                      {submitStatus.loading ? (
                        <button
                          type='button'
                          className='btn btn-primary fw-bold px-10 py-3'
                          disabled
                        >
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {t('Please wait...')}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      ) : (
                        <>
                          {/* <button
                            className='btn btn-outline-primary fw-bold px-15 py-3 mx-5'
                            onClick={(e) => handleSubmit('reject')}
                            disabled={approvalID === ''}
                          >
                            {t('Reject')}
                          </button> */}
                          <button className='btn btn-outline-primary request-more-info fw-bold px-10 py-3 me-5'
                            onClick={(e) => handleSubmit('rmi')}
                            disabled={approvalID === ''}
                          >
                            {t("Request More Info")}
                          </button>
                          <button
                            className='btn btn-primary fw-bold px-12 py-3'
                            onClick={(e) => handleSubmit('approve')}
                            disabled={approvalID === ''}
                          >
                            {t('Approve')}
                          </button>
                        </>
                      )}
                    </div>

                    {!submitStatus.loading && submitStatus.error && (
                      <div className='col-12 text-center'>
                        <div className='alert alert-danger w-100 mt-5 mb-0' role='alert'>
                          {submitStatus.message}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='row align-items-end'>
                    <div className='col-12'>
                      <label className='font-14 fw-bold mb-3 text-theme-gray'>
                        {t("Approver's Comment")}
                      </label>
                      <div className='data-contain h-75 px-5 py-4 rounded-10'>
                        <p className='font-14 fw-bold d-block mb-0'>
                          {serviceDetail?.approvalComment ? (
                            serviceDetail?.approvalComment
                          ) : (
                            <i>{t('No comment !')}</i>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal"></p> */}
      {/* <p ref={successRef}
        data-bs-target='#req-submitted-popup-approve'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='invisible d-none'
      />
      <CustomRequestSuccessfulPopup
        successMessage={submitStatus.message}
        close={false}
        customID={'-approve'}
      /> */}
      <span
        ref={successRef}
        data-bs-target='#req-submitted-popup-redirect'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='d-none'
      ></span>
    </>
  )
}
