import React, { useEffect, useState } from 'react'
import UserIcon from '../../../../assets/images/plumber_img.png'
import RevertIcon from '../../../../assets/images/revet_icon.png'
import MsgIcon from '../../../../assets/images/msg_icon.png'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import moment from 'moment'
import Loader from '../../../../components/Loader'
import { formatDate, formatDateTimeMeridiem, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'
import { useSelector } from 'react-redux'
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios'
import { useTranslation } from 'react-i18next'

Chart.register(ChartDataLabels)

const labels = ['Open', 'Close']
export const data = {
  labels,

  datasets: [
    {
      datalabels: {
        color: '#EAEFF5',
        font: {
          size: 16,
          family: "'Poppins', sans-serif",
          lineHeight: 0.5,
        },
      },
      label: 'Corrective',
      data: [140, 100],
      backgroundColor: '#002C6A',
      barThickness: 55,
      maxBarThickness: 55,
      borderRadius: {
        bottomRight: 5,
        bottomLeft: 5,
        topRight: 5,
        topLeft: 5,
      },
    },
    {
      datalabels: {
        color: '#EAEFF5',
        font: {
          size: 16,
          family: "'Poppins', sans-serif",
          lineHeight: 0.5,
        },
      },
      label: 'Corrective',
      data: [160, 200],
      backgroundColor: '#EAEFF5',
      barThickness: 55,
      maxBarThickness: 55,
      borderRadius: {
        bottomRight: 0,
        bottomLeft: 0,
        topRight: 5,
        topLeft: 5,
      },
    },
  ],
}
export const options = {
  showTooltips: false,
  // hover: {mode: null},
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Corrective(20)',
      padding: {
        top: 10,
        bottom: 30,
      },
      color: '#A6A6A6',
      font: {
        size: 14,
        family: "'Poppins', sans-serif",
        lineHeight: 0.5,
      },
    },
  },

  layout: {
    padding: {
      left: 50,
    },
  },
  responsive: true,

  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
        zeroLineColor: 'transparent',
      },
      ticks: {
        color: 'rgb(0, 0, 0)',
        font: {
          size: 14,
          family: "'Poppins', sans-serif",
          lineHeight: 0.5,
          weight: '500',
        },
      },
    },
    y: {
      stacked: true,

      display: false,
      grid: {
        display: false,
        drawBorder: false,
        ticks: {
          display: false,
        },
      },
    },
  },
}

export const CalenderDetails: React.FC<any> = ({ scheduledVists, loading, selectedDate, counts, setParentSelectedCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState<any>('visit');
  const state: any = useSelector(state => state);
  let isFCSSUser = (state?.auth?.user?.data?.id_role && state?.auth.user?.data?.id_role == 9) ? true : false;
  const { t } = useTranslation();

  const handelCategorySelect = (type: any) => {
    setSelectedCategory(type)
    setParentSelectedCategory(type)
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card rounded-10 calendar-details-sec calender-card-sec'>
            <div className='card-body px-5 py-6'>
              {/* <div className="row">
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-6'>
                      <h4 className='fw-bolder font-14'>{t("Today")}</h4>
                    </div>
                    <div className='col-6 text-end'>
                      <h4 className='fw-bolder font-14'>{formatDate(selectedDate)}</h4>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row border-btm">
                <div className="col-12">
                  <div className='event-request mb-3'>
                    <div
                      className={'event-sec cursor-pointer ' + (selectedCategory === 'visit' && 'border bg-gray')}
                      onClick={(e) => handelCategorySelect('visit')}>
                      <p>{t("Scheduled Visits")}</p>
                      <h4>{counts?.visit}</h4>
                    </div>
                    <div
                      className={'event-sec cursor-pointer ' + (selectedCategory === 'movein' && 'border bg-gray')}
                      onClick={(e) => handelCategorySelect("movein")}>
                      <p>{t("Move-in")}</p>
                       <h4>{counts?.moveIn}</h4>
                    </div>
                    <div
                      className={'event-sec cursor-pointer ' + (selectedCategory === 'moveout' && 'border bg-gray')}
                      onClick={(e) => handelCategorySelect("moveout")}>
                      <p>{t("Move-out")}</p>
                       <h4>{counts?.moveOut}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className='invoice-card'>
                    {loading &&
                      <div className="mt-5">
                        <CustomLoader2 height={'166'} />
                      </div>
                    }
                    {!loading && (!scheduledVists || scheduledVists.length === 0) &&
                      <NoDataFound1 height={'110'} />
                    }
                    {scheduledVists.map((visits: any, index: number) => {
                      let viewLink = '/scheduled-visit/view'
                      let stateData
                      if (selectedCategory === 'movein') {
                        stateData = { 'approval': visits.stateData }
                        viewLink = visits?.status === 'Pending' ? '/fcss-moveinDetails/building-access-card' : visits?.status === 'Approved' ? '/fcss-moveinDetails1/building-access-card' : '/'
                      }
                      else if (selectedCategory === 'moveout') {
                        stateData = { 'approval': visits?.stateData }
                        viewLink = visits?.status === 'Pending' ? '/fcss-moveoutDetails/building-access-card' : visits?.status === 'Approved' ? '/fcss-moveinDetails1/building-access-card' : '/'
                      }
                      else {
                        stateData = { 'scheduledData': visits?.stateData }
                        viewLink = '/scheduled-visit/view'
                      }


                      return (
                        <div className='invoice-box calender_card px-4 mb-5' key={index + 1}>
                          <div className='row'>
                            <Link to={{ 'pathname': viewLink, state: stateData }}>
                              <div className='col-12'>
                                <div className='calender_card_right_div3'>
                                  <div className='row'>
                                    <div className='col-sm-6'>
                                      <div className='calender-section'>
                                        <div className='calender_card_right_div3_img'>
                                          <img src={UserIcon} className='img-fluid' />
                                        </div>
                                        <div className='calender_card_right_div3_text ms-3'>
                                          <h5 className='font-14 fw-normal text-color'>
                                            {visits?.user ? visits?.user : '--'}
                                          </h5>
                                          {/* <p className='mb-0 font-12 fw-normal text-red'>PRV00023</p> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-sm-6 text-end'>
                                      <p className='btn btn-success in-progress mb-0 font-12'>{visits?.status ? visits?.status : "--"}</p>

                                      {/* <div className='icons_msg float-end'>
                                          <div className='icon_1'>
                                            <img src={MsgIcon} className='img-fluid ps-1' />
                                          </div>
                                          <Link to={'/chat/all'}>
                                          <div className='icon_1'>
                                            <img src={MsgIcon} className='img-fluid ps-1' />
                                          </div>
                                        </Link>
                                        <div className='icon_1 icon_2 ms-2'>
                                          <img src={RevertIcon} className='img-fluid' />
                                        </div>
                                      </div> */}
                                  </div>
                                  </div>
                                </div>
                              </div>
                              <hr className='px-4' />

                              <div className='col-12 mb-5'>
                                <div className='calender_card_right_div1'>
                                  <h5 className='font-17 fw-normal'>{visits?.title ? visits?.title : "--"}</h5>
                                  <p className='mb-0 font-14 fw-normal calendar_heading'>{visits?.subTitle ? visits?.subTitle : "--"}</p>
                                </div>
                              </div>
                              <div className='col-12 mb-5'>
                                <div className='calender_card_right_div2'>
                                  <div className='row'>
                                    <div className='col-sm-4'>
                                      <h5 className='font-14 fw-normal text-color'>{t("Emirates ID")}</h5>
                                      <p className='mb-0 font-12 fw-normal text-color'>
                                        {visits?.code ? visits?.code : "--"}
                                      </p>
                                    </div>
                                    <div className='col-sm-4'>
                                      <h5 className='font-14 fw-normal text-color'>{t("Date")}</h5>
                                      <p className='mb-0 font-12 fw-normal text-color'>
                                        {visits?.date ? visits?.date : "--"}
                                      </p>
                                    </div>
                                    <div className='col-sm-4 text-end'>
                                      <h5 className='font-14 fw-normal text-color'>{t("Time")}</h5>
                                      <p className='mb-0 font-12 fw-normal text-red'> {visits?.time ? visits?.time : "--"}</p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              {/*<div className='col-12 mb-5'>
                                <div className='calender_card_right_div2'>
                                  <div className='row'>
                                  </div>
                                </div>
                              </div>*/}

                            </Link>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
