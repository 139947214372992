import React, { useState, useEffect } from 'react';
import Home from "../../../../../../assets/images/my-unit.png";

export function RaiseProformaDetails() {
    return (
        <div className="com-card-section">
            <div className="col-12">
                <div className="card">
                    <div className="card-body p-7">
                        <div className="row">
                            <div className="offset-md-3 col-md-9 col-12">
                                <div className="row align-items-baseline">
                                    <div className="col-md-6">
                                        <a href="#" className="text-decoration-none text-black fw-bolder view-all font-16">
                                            <span> Lease Number : 25895615651 </span>
                                        </a>
                                    </div>
                                    <div className="col-md-6 ps-lg-20 text-end">
                                        <a href="#" className="text-decoration-underline text-primary fw-bolder view-all font-14">
                                            <span> View Agreement </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <img src={Home} className="img-fluid" />
                            </div>
                            <div className="col-md-8 col-lg-9">
                                <div className="card lease-card bg-white rounded">
                                    <div className="card-body px-0 pt-4 pb-2">
                                        <div className="row">
                                            <div className="col-md-4 col-6 mb-7">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> Tenant Name </h6>
                                                <span className="text-black fw-bold font-14"> John </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-7">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> Unit Number </h6>
                                                <span className="text-black fw-bold font-14"> N-21 </span>
                                            </div>
                                            <div className="col-md-4 col-6 mb-7">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> Lease Start Date </h6>
                                                <span className="text-black fw-bold font-14"> 21-June-2020 </span>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> Lease End Date </h6>
                                                <span className="text-black fw-bold font-14"> 21-June-2021 </span>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> Annual Rent </h6>
                                                <span className="text-black fw-bold font-14"> AED 12,350 </span>
                                            </div>
                                            <div className="col-md-4 col-6">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> Security Deposit </h6>
                                                <span className="text-black fw-bold font-14"> AED 70,000 </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}