import React, { useState, FC, useRef, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from "../../../../../assets/images/Left_Arrow.png";
import {Details} from "./Details/Details";
import {ViewDetailsTab} from "./ViewDetailsTab/ViewDetailsTab";
import {IssueDetails} from "./IssueDetails/IssueDetails";
import {WorkInformation} from "./WorkInformation/WorkInformation";
import {Undertaking} from "./Undertaking/Undertaking";
import {Documents} from "./Documents/Documents";
import { RootState } from '../../../../../constants';
import { useSelector } from 'react-redux';
import { ApprovalWrapper } from './Details/ApprovalWrapper/ApprovalWrapper';

const ViewDetailsFullWrapper: React.FC = () => {
    const [activeTab, setActiveTab] = useState<any>(1);
    const [approvalData, setApprovalData] = useState<any>({});
    //fetch and set approval data
    let contractApprovalData = useSelector<RootState>(({ PM }) => PM.contractsApprovalData)

    useEffect(() => {
        setApprovalData(contractApprovalData ? contractApprovalData : {})
    }, [contractApprovalData]);

    return (
        <>
            <div className="col-md-12 col-12">
                <div className="card mb-3">
                    <div className="card-body px-8 py-0">
                        <div className="row access-cd-tabs">
                            <div className="col-md-12 col-12">
                                <div className='d-flex overflow-auto h-55px'>
                                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                        <li className='nav-item'>
                                            <a onClick={() => { setActiveTab(1); }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                (activeTab === 1 && 'active')}>
                                                Issue Details
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a onClick={() => { setActiveTab(2); }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                (activeTab === 2 && 'active')}>
                                                Work Information
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a onClick={() => { setActiveTab(3); }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                (activeTab === 3 && 'active')}>
                                                Documents
                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a onClick={() => { setActiveTab(4); }}
                                                className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                (activeTab === 4 && 'active')}>
                                                Undertaking
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                activeTab === 1 ?
                <IssueDetails approvalData={approvalData} />
                : activeTab === 2 ?
                <WorkInformation approvalData={approvalData}/>
                : activeTab === 3 ?
                <Documents approvalData={approvalData}/>
                : activeTab === 4 ?
                <Undertaking approvalData={approvalData}/>
                :''

            }
            {/* <ApprovalWrapper /> */}
        </>
    );
}

export { ViewDetailsFullWrapper }

