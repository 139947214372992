import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { ContactsListing } from './Contacts/ContactsListing'
import { AddContacts } from './Contacts/AddContacts'
import { ImportContacts } from './Contacts/ImportContacts'
import { EditContacts } from './Contacts/EditContacts'
import { PreviewContacts } from './Contacts/PreviewContacts'

const PMContactPage: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                    <Route path='/contacts/list'>
                            <ContactsListing />
                        </Route>
                        <Route path='/contacts/add'>
                            <AddContacts />
                        </Route>
                        <Route path='/contacts/imports'>
                            <ImportContacts />
                        </Route>
                        <Route path='/contacts/edit'>
                            <EditContacts />
                        </Route>
                        <Route path='/contacts/preview'>
                            <PreviewContacts />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const PMContactWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PMContactPage />
        </>
    )
};

export { PMContactWrapper }
