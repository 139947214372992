import React, {useState, useEffect} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FinanceServiceClosedRequest } from './FinanceServiceTable/FinanceServiceClosedRequest';
import { FinanceServiceOpenRequest } from './FinanceServiceTable/FinanceServiceOpenRequest'
import { FinanceServiceRequestTabs } from './FinanceRequestTabs/FinanceServiceRequestTabs'

export function FinanceServiceListWrapper() {
  const [searchItem, setSearchItem] = useState<any>('');

  return(
    <>
      <FinanceServiceRequestTabs searchItem={searchItem} setSearchItem={setSearchItem} />
      <Switch>
        <Route path='/finance-services-request/list/open'>
            <FinanceServiceOpenRequest searchItem={searchItem} setSearchItem={setSearchItem} />          
        </Route>
        <Route path='/finance-services-request/list/closed'>
          <FinanceServiceClosedRequest searchItem={searchItem} setSearchItem={setSearchItem} />
        </Route>
        <Redirect to='/error/403' />
      </Switch>
    </>
  )
}