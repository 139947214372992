import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

import {useTranslation} from 'react-i18next'

export function ApprovalTabs({searchItem, setSearchItem}: any) {
  const location = useLocation()
  const {t} = useTranslation()
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='card-title mb-6 font-18 text-black'> {t('Approvals')} </h4>
            </div>
          </div>
          <div className='card mb-3'>
            <div className='card-body px-4 py-2'>
              <div className='row'>
                <div className='col-md-6 col-12'>
                  <div className='d-flex'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link position-relative text-active-primary ` +
                            (location.pathname === '/approvals/upcoming/movein'
                              ? 'active'
                              : location.pathname === '/approvals/upcoming/moveout'
                              ? 'active'
                              : '')
                          }
                          to='/approvals/upcoming/movein'
                        >
                          {t('Upcoming')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link position-relative text-active-primary ` +
                            (location.pathname === '/approvals/closed/movein'
                              ? 'active'
                              : location.pathname === '/approvals/closed/moveout'
                              ? 'active'
                              : '')
                          }
                          to='/approvals/closed/movein'
                        >
                          {t('Closed')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                  <div className='d-flex pm-topbar-buttons float-end'>
                    <div className='form-group has-search'>
                      <span className='fa fa-search pt-2 form-control-feedback'></span>
                      <input
                        type='text'
                        className='form-control ps-10'
                        onChange={(e) => setSearchItem(e.target.value)}
                        placeholder={t('Search')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
