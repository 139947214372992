import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {AnnouncementsList} from './components/AnnouncementsList/AnnouncementsList'
import {AddAnnouncement} from './components/AddAnnouncement/AddAnnouncement'
import {AddedSuccessfulPopup} from '../SuccessfulPopup/AddedSuccessfulPopup';
import {Redirect, Route, Switch} from 'react-router-dom'

import {SupervisorAnnouncementListPage} from '../fc-supervisor-announcements/FCSupervisorAnnouncements';

const FCSoftServicesAnnouncementsPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
            <Switch>
                <Route path='/fc-ss-announcements/list'>
                    <AnnouncementsList />
                </Route>
                <Redirect to='/error/403' />
                {/* <Route path='/fc-ss-announcements/add'>
                    <AddAnnouncement />
                    <AddedSuccessfulPopup />
                </Route> */}
            </Switch>
        </div>
    </div>
    </>
);

const FCSoftServicesAnnouncementsWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}> Community Post </PageTitle>
        {/* <FCSoftServicesAnnouncementsPage /> */}
        <SupervisorAnnouncementListPage />
        </>
    )
};

export {FCSoftServicesAnnouncementsWrapper}
