import React, { FC, useEffect, useRef, useState, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Spinner } from 'react-bootstrap-v5'
import WorkOrdersImg from "../../../../../assets/images/ac-repair.png";
import uploadImg from "../../../../../assets/images/file-upload.svg";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../constants';
import * as General from '../../../../../constants/redux/Sagas/GeneralSaga'
import { pmPropertyEditAddMedia, pmPropertyEditDeleteMedia } from '../../../../../constants/axios/apis';
import * as PM from '../../../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { CustomLoader1 } from '../../../../../constants/generics/customActios';

const Step5: FC = () => {
    const dispatch = useDispatch()
    let proprtyDetails: any
    proprtyDetails = useSelector<RootState>(({ PM }) => PM.proprtydetails)
    let propertyEditData: any
    propertyEditData = useSelector<RootState>(({ General }) => General.propertyEditData)

    const [file, setFile] = useState<any>([]);
    const [fileSelected, setFileSelected] = useState<any>([]);
    const [loading, setLoading] = useState(false)
    const [filelist, setFilelist] = useState<any>(proprtyDetails?.data?.property_images);

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const { t } = useTranslation();

    function uploadSingleFile(e: any) {
        setLoading(true)
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        setFileSelected([...fileSelected, (e.target.files[0])]);

        const id = `/${proprtyDetails?.data?.id}`;
        var FormData = require('form-data')
        var propertyEditDetailsAddImg = new FormData()
        propertyEditDetailsAddImg.append('image[]', e.target.files[0]);
        pmPropertyEditAddMedia(propertyEditDetailsAddImg, id).then((data) => {
            setLoading(false)
            setFile([])
            setFileSelected([])
            dispatch(PM.actions.proprtydetails(proprtyDetails?.data?.id))
            setFilelist(proprtyDetails?.data?.property_images)
        })
    }
    function upload(e: any) {
        e.preventDefault();
    }
    function deleteFile(e: any) {
        const s = file.filter((item: any, index: any) => index !== e);
        const s1 = fileSelected.filter((item: any, index: any) => index !== e);
        setFile(s);
        setFileSelected(s1);
    }

    function deleteFile1(id: any) {
        setLoading(true)
        const payload = `/image/${id}`;
        pmPropertyEditDeleteMedia(payload).then((data) => {
            setLoading(false)
            dispatch(PM.actions.proprtydetails(proprtyDetails?.data?.id))
        })
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
            setUploadedFileName(inputRef.current.files[0].name);
    };

    const [visible, setVisible] = useState(true);
    function removeImg() {
        setVisible(false);
    }
    const [{ alt, src }, setImg] = useState({
        src: WorkOrdersImg,
        alt: 'Upload an Image'
    });

    const handleImg = (e: any) => {
        if (e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name
            });
        }
    }

    const intl = useIntl()
    const location = useLocation()
    const [menu, setMenu] = useState('')

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }

    useEffect(() => {
        setFilelist(proprtyDetails?.data?.property_images)
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
            console.log(window.location.pathname)
        }
    }, [proprtyDetails])

    useEffect(() => {
        const step5Data = {
            filesBlob: file,
            filesSelected: fileSelected,
        }
        dispatch(General.actions.propertyEditData({
            step1: propertyEditData.step1,
            step2: propertyEditData.step2,
            step3: propertyEditData.step3,
            step4: propertyEditData.step4,
            step5: step5Data
        }));
    }, [file, fileSelected])

    useLayoutEffect(() => {
        if (loading) {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100%";
            document.body.style.pointerEvents = "none";
        }
        if (!loading) {
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
            document.body.style.pointerEvents = "all";
        }
    }, [loading]);
    return (
        <>
            {userRole === 16 ? (
                <div className="com-card-section w-100 p-7">
                    <div className="row form-add-design">
                        <div className="col-12">
                            <div className="card shadow-none">
                                <div className="card-body p-0">
                                    <div className="row form-add-design movein-property-image">
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder"> {t("Media Upload")} </label>
                                                    <input ref={inputRef} onChange={uploadSingleFile} className="d-none" type="file" multiple />
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                        <span>{uploadedFileName ? uploadedFileName : <span className="txt">
                                                            <img
                                                                    alt="File"
                                                                    src={uploadImg}
                                                            className="position-relative"
                                                            loading="lazy"/>
                                                            {t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">

                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder d-block">{t("Attach Images/Video")}</label>
                                                    <div className="d-flex">

                                                        {filelist.length > 0 &&
                                                            <div className="position-relatives">
                                                                {filelist.map((item: any, index: any) => {
                                                                    return (
                                                                        <div className="upload-image" key={item}>
                                                                            <img src={item?.propertyImage} alt="" />
                                                                            <div className="close-icon" onClick={() => deleteFile(index)}>
                                                                                &times;
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : userRole === 18 ? (
                <div className="com-card-section w-100 p-7">
                    <div className="row form-add-design">
                        <div className="col-12">
                            <div className="card shadow-none">
                                <div className="card-body p-0">
                                    <div className="row form-add-design movein-property-image">
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder"> {t("Media Upload")} </label>
                                                    <input ref={inputRef} onChange={uploadSingleFile} className="d-none" type="file" />
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                        {loading && <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true" />} <span>{uploadedFileName ? uploadedFileName : <span className="txt">
                                                    <img
                                                            alt="File"
                                                            src={uploadImg}
                                                    className="position-relative"
                                                    loading="lazy"/>
                                                    {loading ? 'Uploading' : t("Upload")} <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">

                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder d-block">{t("Attach Images/Video")}</label>
                                                    <div className="d-flex">

                                                        {file.length > 0 &&
                                                            <div className="position-relatives">
                                                                {file.map((item: any, index: any) => {
                                                                    return (
                                                                        <div className="upload-image" key={item}>
                                                                            <img src={item} alt="" />
                                                                            <div className="close-icon" onClick={() => deleteFile(index)}>
                                                                                &times;
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-12">
                                                <div className="mb-7 upload-input">
                                                    <label className="form-label fw-bolder d-block">Existing Images</label>
                                                    <div className="d-flex">
                                                        {filelist?.length > 0 &&
                                                            <div className="position-relatives">
                                                                {filelist.map((item: any, index: any) => {
                                                                    if (item?.is_video == 0)
                                                                        return (
                                                                            <div className="upload-image" key={item}>
                                                                                <img src={item?.propertyImage} alt="" />
                                                                                <div className="close-icon" onClick={() => deleteFile1(item?.id)}>
                                                                                    &times;
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                })}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}

export { Step5 }
