import React, { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { Report } from './components/Report/Report';
import { Rent } from './components/Rent/Rent';
import { generateReportMt } from '../../utils/makeRequest';
import { IState } from "../../utils/interface";
import { useSelector } from 'react-redux';

export const MTAReportPage: FC = () => {
    const state: any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    if (userToken === "undefined") {
        // token undefined
    }
    const [reportType, setReportType] = useState<any>('');

    const [reportState, setReportState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    const [categoryState, setCategoryState] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });

    const genReport = (params: any) => {
        generateReportMt(setReportState, userToken, params);
    }
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Report
                        reportState={reportState}
                        setReportState={setReportState}
                        genReport={genReport}
                        setReportType={setReportType}
                        setCategoryState={setCategoryState}
                    />
                    <Rent reportState={reportState} setCategoryState={setCategoryState} setReportState={setReportState} reportType={reportType} />
                </div>
            </div>
        </>
    );
}

const MTAReportWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}> Report </PageTitle>
            <MTAReportPage />
        </>
    )
};

export { MTAReportWrapper }
