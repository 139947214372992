import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react';
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import PlusIcon from "../../../assets/images/plus-circle.svg";

const Add: React.FC = () => {

    const location = useLocation();

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <Link to={'/directory/residents'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            <span className="text-black">
                                Add Documents
                            </span>
                        </Link>
                    </h4>
                </div>
                <div className="row form-add-design">
                    <div className="col-12">
                        <div className="card documents-card mb-3">
                            <div className="card-body px-10">
                                <div className="row">
                                    <div className="col-12">
                                        <p className="font-14 fw-bold mb-8">
                                            Documents to be Uploaded
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> Document 1 </label>
                                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                                        <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> Document 2 </label>
                                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                                        <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> Document 3 </label>
                                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                                        <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> Document 4 </label>
                                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                                        <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> Document 5 </label>
                                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                                        <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> Document 6 </label>
                                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                                    <button onClick={handleUpload} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                                        <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-12 text-end mt-5">
                                                <button data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {Add}
