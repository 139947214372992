import React, {useState, useEffect} from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import MoveOutIcon from '../../../../assets/images/move-out-icon.svg'
import MoveInIcon from '../../../../assets/images/move-in-icon.svg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'
import {getApprovals} from '../../../../constants/axios/apis'
import Loader from '../../../../components/Loader'
import {RootState} from '../../../../constants'
import {useSelector} from 'react-redux'
import {CustomLoader2, NoDataFound1} from '../../../../constants/generics/customActios'
import {useTranslation} from 'react-i18next'

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
      },
    },
  ],
}

export function RecentRequests() {
  const {t} = useTranslation()
  const [approvals, setApprovals] = useState<any>([])
  // const [loading, setLoading] = useState(true)
  const [loading, setLoading] = useState({ data1: true, data2: true, })
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )

  useEffect(() => {
    // setLoading(true)
    // setApprovals([])
    setLoading({ data1: true, data2: true })

    let payload = '?currentPage=1&pageSize=5&id_sort=1&sub_type=5&status=pending&is_card_data_added=1' +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getApprovals(payload)
      .then(({ data }: any) => {
        const filteredData = (Array.isArray(data) && data?.length > 0) ? data : [];
        setApprovals((oState: any) => oState.concat(filteredData))
        setLoading((oState: any) => ({ ...oState, data1: false }))
      })
      .catch((e) => {
        setLoading((oState: any) => ({ ...oState, data1: false }))
      })

    let payload2 = '?currentPage=1&pageSize=5&id_sort=1&sub_type=6&status=pending' +
      (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    getApprovals(payload2)
      .then(({ data }: any) => {
        const filteredData = (Array.isArray(data) && data?.length > 0) ? data : [];
        setApprovals((oState: any) => oState.concat(filteredData))
        setLoading((oState: any) => ({ ...oState, data2: false }))
      })
      .catch((e) => {
        setLoading((oState: any) => ({ ...oState, data2: false }))
      })

    // const newPayload =
    //   '?status=pending' +
    //   '&is_card_data_added=1' +
    //   (currentBuilding ? '&building_id=' + currentBuilding : '&building_id=0')
    // getApprovals(newPayload)
    //   .then((response: any) => {
    //     const filterApprovals = response?.data?.filter(
    //       (approval: any) => approval.status === 'Pending'
    //     )
    //     setApprovals(
    //       Array.isArray(filterApprovals) && filterApprovals?.length > 0
    //         ? filterApprovals.slice(0, 15)
    //         : []
    //     )
    //     setLoading(false)
    //   })
    //   //   if (Array.isArray(response.data) && response.data.length > 0) {
    //   //     setApprovals(response.data.filter((approval: any) => approval.status === 'Pending'))
    //   //     setLoading(false)
    //   //   } else {
    //   //     setApprovals([])
    //   //     setLoading(false)
    //   //   }
    //   // })
    //   .catch((e) => {
    //     setApprovals([])
    //     setLoading(false)
    //   })
  }, [currentBuilding])

  

  
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-5 pt-4 p-xl-7 pt-xl-5'>
              <div className='row'>
                <div className='col-md-6 col-12'>
                  <h4 className='card-title'> {t('Action Required')} </h4>
                </div>
                {approvals?.length > 2 ? (
                  <div className='col-md-6 col-12 d-flex justify-content-end align-items-center pe-md-20'>
                    <Link
                      to={'/approvals/upcoming/movein'}
                      className='text-decoration-none view-all'
                    >
                      <span> {t('View All')} </span>
                      <img src={RightArrow} className='img-fluid' />
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className='rec-request-sec'>
                <div>
                  <div className='row'>
                    {(loading.data1 || loading.data2) ?
                      <CustomLoader2 height={'166'} />
                      : (
                      <div className='col-12 p-0'>
                        {approvals?.length ? (
                          <Slider {...settings}>
                            {/* {approvals ? recentRequests : ''} */}
                            {approvals?.map((approval: any, index: number) => {
                              const viewLink =
                                approval?.subReference === 5
                                  ? '/fcss-moveinDetails/building-access-card'
                                  : '/fcss-moveoutDetails/building-access-card'
                              return (
                                <div>
                                  <Link
                                    to={{pathname: viewLink, state: {approval}}}
                                    className='text-decoration-none'
                                  >
                                    <div className='card mt-4'>
                                      <div className='card-body px-8 py-7'>
                                        <div className='row'>
                                          <div className='col-lg-8'>
                                            <div className='mb-7'>
                                              <img
                                                src={
                                                  approval?.subReference === 5
                                                    ? MoveInIcon
                                                    : MoveOutIcon
                                                }
                                                className='img-fluid me-8 d-inline'
                                              />
                                              <span className='card-title'>
                                                {approval?.subReference === 5
                                                  ? 'Move In Approval'
                                                  : 'Move Out Approval'}
                                              </span>
                                            </div>
                                          </div>
                                          <div className='col-lg-4 text-end'>
                                            <button className='btn btn-primary rounded w-100 py-2 mb-2'>
                                              {t('View')}
                                            </button>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className='col-sm-6 pe-0'>
                                            <p className='mb-0 text-muted'>{t('Unit Number')}</p>
                                            <p className='mb-0 text-dark font-12'>
                                              {approval?.propertyCode}
                                            </p>
                                          </div>
                                          <div className='col-sm-6 pe-0'>
                                            <p className='mb-0 text-muted'>{t('Buiding Name')}</p>
                                            <p className='mb-0 text-dark font-12 building-name-fcss'>
                                              {approval?.building}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              )
                            })}
                          </Slider>
                        ) : (
                          <NoDataFound1 height={'110'} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
