import React from 'react';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { useTranslation } from 'react-i18next';

export function ProfileDetails({userData}:any) {
    const { t } = useTranslation();
    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    return (
        <div className="row">
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("First Name")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                    {userData?.first_name}
                  </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Last Name")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                    {userData?.last_name}
                  </span>
                </div>
            </div>
            
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Mobile Number")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                    {userData?.mobile}
                  </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Emirate ID")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                    {userData?.emirates_id}
                  </span>
                </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                <div className="border rounded-5 p-4 h-100">
                    <label className="font-14 fw-bold d-block mb-2">
                        {t("Email ID")}
                    </label>
                    <span className="text-black font-15 fw-bold">
                        {userData?.email}
                    </span>
                </div>
            </div>
            {/* { userData?.staff_type === "Tenant" &&
                <>
                    <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                        <div className="border rounded-5 p-4 h-100">
                            <label className="font-14 fw-bold d-block mb-2">
                                Building
                            </label>
                            <span className="text-black font-15 fw-bold">
                                {userData?.building_name}
                        </span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                        <div className="border rounded-5 p-4 h-100">
                            <label className="font-14 fw-bold d-block mb-2">
                                Unit
                            </label>
                            <span className="text-black font-15 fw-bold">
                                {userData?.property_code}
                        </span>
                        </div>
                    </div>
                </>
            } */}
            { userData?.staff_type !== "Tenant" && 
                <>
                    <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                        <div className="border rounded-5 p-4 h-100">
                            <label className="font-14 fw-bold d-block mb-2">
                                {t("Designation")}
                            </label>
                            <span className="text-black font-15 fw-bold">
                                {userData?.user_designation ? userData?.user_designation : '-'}
                        </span>
                        </div>
                    </div>
                    {
                        userData?.staff_type === "Facility Contractor Supervisor" &&

                            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                                <div className="border rounded-5 p-4 h-100">
                                    <label className="font-14 fw-bold d-block mb-2">
                                        {t("FM Contract Number")}
                                    </label>
                                    <span className="text-black font-15 fw-bold">
                                        {userData?.FMContractNumber ? userData?.FMContractNumber : '-'}
                                </span>
                                </div>
                            </div>
                    }
                    {
                        userData?.staff_type === "Facility Contractor" &&
                            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                                <div className="border rounded-5 p-4 h-100">
                                    <label className="font-14 fw-bold d-block mb-2">
                                        {t("User Type")}
                                    </label>
                                    <span className="text-black font-15 fw-bold">
                                        {userData?.user_type_name ? userData?.user_type_name : '-'}
                                </span>
                                </div>
                            </div>
                    }
                    {
                        ['Facility Contractor', 'Facility Contractor Supervisor', 'Facility Contractor Soft Services'].includes(userData?.staff_type) &&

                            <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                                <div className="border rounded-5 p-4 h-100">
                                    <label className="font-14 fw-bold d-block mb-2">
                                        {t("Company")}
                                    </label>
                                    <span className="text-black font-15 fw-bold">
                                        {userData?.companyName ? userData?.companyName : '-'}
                                </span>
                                </div>
                            </div>
                    }
                    {/* <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                        <div className="border rounded-5 p-4 h-100">
                            <label className="font-14 fw-bold d-block mb-2">
                                Staff Type
                            </label>
                            <span className="text-black font-15 fw-bold">
                                {userData?.staff_type}
                        </span>
                        </div>
                    </div> */}
                    { user_role==='FMT' && userData?.staff_type === "Facility Contractor" ?
                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-8">
                            <div className="border rounded-5 p-4 h-100">
                                <label className="font-14 fw-bold d-block mb-2">
                                    {t("Supervisor Name")}
                                </label>
                                <span className="text-black font-15 fw-bold">
                                    {userData?.createdBy?.[0]?.first_name + ' ' + userData?.createdBy?.[0]?.last_name}
                            </span>
                            </div>
                        </div>
                        : ''
                    }
                </>
            }
        </div>
    );
}