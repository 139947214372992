/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import logo from "../../../../assets/icons/logo-alt.svg";
import PlayStoreIoc from "../../../../assets/images/Playstore_image.png";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const AsideDefault: FC = () => {
  const {config, classes} = useLayout();
  const {aside} = config;

    // Email and Name
    let userRole: number = 0;
    let userDetail = localStorage.getItem("userDetail");
    if(userDetail){
        userRole = JSON.parse(userDetail).id_role;
    };

  return (
    <div
      id='kt_aside'
      className={clsx('aside', 'bg-white', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      {/* <div className='aside-logo flex-column-auto bg-white justify-content-center mt-6 mb-3' id='kt_aside_logo'>
    
        {aside.theme === 'dark' && (
          <Link to={userRole === 6 ? '/broker-admin-dashboard/available-properties' :
              '/dashboard'}>
            <img
              alt='Logo'
              className='logo text-center h-35'
              src={logo}
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='logo text-center'
              src={logo}
            />
          </Link>
        )}
      
      </div> */}
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      {/*<div className='aside-footer pt-0 pb-7 px-5' id='kt_aside_footer'>*/}
        {/*<div className="row justify-content-center g-0">*/}
          {/*<div className="col-12 text-center">*/}
            {/*<h6 className="text-black font-12 fw-bold mb-4"> */}
              {/*Enter Your Number <br/>*/}
              {/*<span className="fw-bold link-theme mt-1 d-block">*/}
                {/*to get the Link*/}
              {/*</span>*/}
            {/*</h6>*/}
              {/*<IntlTelInput*/}
                  {/*preferredCountries={['ae','in','us']}*/}
                  {/*containerClassName="intl-tel-input"*/}
                  {/*separateDialCode={true}*/}
                  {/*placeholder="Enter Your Number"*/}
                  {/*inputClassName="form-control form-control-lg mb-4 form-control-solid w-100"*/}
              {/*/>*/}
            {/*/!*<input placeholder='+971 570 875585' className='form-control form-control-lg mb-4 form-control-solid' type='text' id='phone'/>*!/*/}
            {/*<img src={PlayStoreIoc} className="img-fluid" /> */}
          {/*</div>*/}
        {/*</div>*/}
      {/*</div>*/}
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
