import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PageTitle } from '../../components/layout/core'
import { AddTeamMember } from './add/AddTeamMember'
// import { MyTeamChart } from './add/MyTeamChart'
// import { EventStatusTabs } from './components/TeamApproval/EventStatusTabs/EventStatusTabsabs'
// import { PendingList } from './add/PendingList/PendingList'
// import { ApprovedList } from './add/ApprovedList/ApprovedList'
// import { RejectedList } from './add/RejectedList/RejectedList'
// import { TeamMembersDetails } from './add/TeamMembersDetails'
// import { EventStatusDetails } from './add/EventStatusDetails'
import { RequestSuccessfulPopup } from '../SuccessfulPopup/RequestSuccessfulPopup'
import { getMarketingTeamPromotions } from '../../constants/axios/apis'
import { TeamDashboard } from './myteam/TeamDashboard/TeamDashboard'
import { MyTeamList } from './myteam/MyTeamList/MyTeamList'
import { MembersDetails } from './myteam/MembersDetails/MembersDetails'
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { TeamApprovalWrapper } from './components/TeamApproval/TeamApprovalWrapper'



const MTAMyteamWrapperNew: FC = () => {
  const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

  const intl = useIntl()
  // const [promotions, setPromotions] = useState<any>([])

  // useEffect(() => {
  //   getMarketingTeamPromotions().then((response) => {
  //     if (response.errorCode === 0 && response.data.length) {
  //       setPromotions(response.data)
  //     }
  //   })
  // }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/mta-myteam/list'>
              <TeamDashboard />
              {/* <TeamApprovalWrapper /> */}
              {/* <PendingList promotions={promotions?.filter((promotion: any) => promotion?.status === 0)} /> */}
            </Route>
            <Route path='/mta-myteam/list-all'>
              <MyTeamList />
            </Route>
            <Route path='/mta-myteam/add'>
              <AddTeamMember setPopupSuccessMsg={setPopupSuccessMsg} />
              {/* <RequestSuccessfulPopup /> */}
            </Route>
            <Route path='/mta-myteam/view'>
              <MembersDetails />
            </Route>
            <Redirect to='/error/403' />
          </Switch>

          {/* <Switch>
            <Route path='/mta-myteam-new/pending'>
              <MyTeamChart />
              <EventStatusTabs />
              <PendingList promotions={promotions?.filter((promotion: any) => promotion?.status === 0)} />
            </Route>
            <Route path='/mta-myteam-new/approved'>
              <MyTeamChart />
              <EventStatusTabs />
              <ApprovedList promotions={promotions?.filter((promotion: any) => promotion?.status === 1)} />
            </Route>
            <Route path='/mta-myteam-new/rejected'>
              <MyTeamChart />
              <EventStatusTabs />
              <RejectedList promotions={promotions?.filter((promotion: any) => promotion?.status === 2)} />
            </Route>
            <Route path='/mta-myteam-new/add-team'>
              <AddTeamMember />
              <RequestSuccessfulPopup />
            </Route>
            <Route path='/mta-myteam-new/event-status-details'>
              <EventStatusDetails />
            </Route>
            <Route path='/mta-myteam-new/team-member-details'>
              <TeamMembersDetails />
            </Route>

            //<Route path='/mta-myteam/approved'>
            //  <TeamMembersTabs />
            //  <Week />
            //</Route>
            //<Route path='/mta-myteam/rejected'>
            //  <TeamMembersTabs />
            //  <Month />
            //</Route>
          </Switch> */}
        </div>
      </div>
      <CustomRequestSuccessfulPopup 
          successMessage={popupSuccessMsg}
          showOkBtn={true}
          customID={'-redirect'}
      />
    </>
  )
}

export { MTAMyteamWrapperNew }
