import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const MoveOutRequestTabs = ({searchItem, setSearchItem}: any) => {
  const location = useLocation()
  const {t} = useTranslation()

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='row flex-stack announcement-sec mb-6'>
            <div className='col-md-12'>
              <h4 className='card-title font-18 text-black'> {t('Moveout Requests')} </h4>
            </div>
          </div>
          <div className='card mb-3'>
            <div className='card-body px-8 py-0'>
              <div className='row access-cd-tabs'>
                <div className='col-md-6 col-12'>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/finance-moveout-requests/list/open' &&
                              'active')
                          }
                          to='/finance-moveout-requests/list/open'
                        >
                          {t('Open Requests')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/finance-moveout-requests/list/closed' &&
                              'active')
                          }
                          to='/finance-moveout-requests/list/closed'
                        >
                          {t('Closed Requests')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                  <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                    <div className='form-group has-search'>
                      <span className='fa fa-search pt-2 form-control-feedback'></span>
                      <input
                        type='text'
                        className='form-control ps-10'
                        onChange={(e) => setSearchItem(e.target.value)}
                        placeholder={t('Search')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {MoveOutRequestTabs}
