import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Home from '../../../../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../../../../assets/images/Left_Arrow.png'
import { MainDoorKeys } from './MainDoorKeys/MainDoorKeys'
import { BuildingAccessCard } from './BuildingAccessCard/BuildingAccessCard'
import { ParkingAccessCard } from './ParkingAccessCard/ParkingAccessCard'
import {
  getAllotedAccessId,
  submitMoveInApproval,
} from '../../../../../../../constants/axios/apis'
import { RequestFailedPopup } from '../../../../../../SuccessfulPopup/RequestFailedPopup'
import { useTranslation } from 'react-i18next'

export function MoveInDetails() {
  const location = useLocation<any>()
  const [accessCards, setAccessCards] = useState<any>()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  let approvalData = location?.state?.approval ? location?.state?.approval : {};
  const property_id = approvalData
  // const user_id = location?.state?.approval?.user_id;
  const id = approvalData;
  const [loading, setLoading] = useState(false);
  const history = useHistory()
  const { t } = useTranslation();

  useEffect(() => {
    getAllotedAccessId().then(({ data }: any) => {
      setAccessCards(data.find((element: any) => element.propertyId === property_id))
    })
  }, [property_id])

  const sigCanvas = useRef<any>()
  function clear() {
    sigCanvas.current.clear()
  }

  const sendMoveInApproval = () => {
    setTimeout(() => {
      submitMoveInApproval(
        id
      )
        .then((response: any) => {
          setLoading(false)
          if (response.errorCode === 0) {
            successRef.current.click();
            history.push('/approvals/upcoming/movein', {})
          } else {
            setMessage(response.errorDescription)
            failRef.current.click()
          }
        })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(t("Invalid Data Provided"))
        })
    }, 1000)
  }

  const bacCount = (approvalData?.allotted_access_card?.find((el: any) => el.name === "Building Access Card"))?.no_of_cards
  const pacCount = (approvalData?.allotted_access_card?.find((el: any) => el.name === "Parking Access Card"))?.no_of_cards
  const mdkCount = (approvalData?.allotted_access_card?.find((el: any) => el.name === "Main Door Key"))?.no_of_cards

  return (
    <>
      <div className='com-card-section myunit-page'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/approvals/upcoming/movein'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </Link>
              {t("Move In Approval")}
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3 mb-md-0 mb-5'>
                    <img src={approvalData?.image} className='img-fluid' />
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded'>
                      <div className='card-body px-0 py-0'>
                        <div className='row'>
                          <div className='col-md-12 col-12'>
                            <span className='text-black fw-bolder font-14 d-block mb-4'>
                              {t("Tenant Name")} : {approvalData?.cutomerName}
                              {/* Lease Number : {approvalData?.details?.leaseNumber} */}
                            </span>
                          </div>
                          {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              Tenant Name{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {approvalData?.cutomerName}{' '}
                            </span>
                          </div> */}
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Unit Number")}{' '}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {approvalData?.propertyCode}{' '}
                              </span>
                            </div>
                          </div>
                          {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              Lease Start Date{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {approvalData?.details?.leaseStartDate}{' '}
                            </span>
                          </div> */}
                          {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              Lease End Date{' '}
                            </h6>
                            <span className='text-black fw-bold font-14 '>
                              {' '}
                              {approvalData?.details?.leaseEndDate}{' '}
                            </span>
                          </div> */}
                          {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> Move In Date </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {approvalData?.details?.moveInDate}{' '}
                            </span>
                          </div> */}
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12 mb-md-0 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> Main Door Keys </h6>
                            <span className='text-black fw-bold font-14'> {mdkCount ? mdkCount : "--"} </span>
                          </div>
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12 mb-md-0 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> Building Access Cards </h6>
                            <span className='text-black fw-bold font-14'> {bacCount ? bacCount : "--"} </span>
                          </div>
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12 mb-md-0 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>Parking Access Cards </h6>
                            <span className='text-black fw-bold font-14'> {pacCount ? pacCount : "--"} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='accordion approval-accordion' id='accordionExample'>
              <MainDoorKeys
                // property_id={property_id}
                // user_id={user_id}
                // mdk_id={accessCards?.MDK}
                // approval_id={id}
                // count={accessCards?.main_door_keys}
                count={mdkCount}
              />
              <BuildingAccessCard
                // property_id={property_id}
                // user_id={user_id}
                // bac_id={accessCards?.BAC}
                // approval_id={id}
                // count={accessCards?.main_door_keys}
                // count={2}
                count={bacCount}
              />
              <ParkingAccessCard
                // property_id={property_id}
                // user_id={user_id}
                // pac_id={accessCards?.PAC}
                // approval_id={id}
                // count={accessCards?.main_door_keys}
                count={pacCount}
              />
            </div>
          </div>
          <div className='col-12 text-end'>
            <button className='btn btn-outline-primary bg-white fw-bold px-10 py-3 me-5'>
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary fw-bold px-10 py-3'
              onClick={() => sendMoveInApproval()}
            >
              HandOver
            </button>
          </div>
        </div>
      </div>
      <p
        ref={successRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <RequestFailedPopup message={message} />
    </>
  )
}
