import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import PlusIcon from '../../../../../assets/images/plus-circle-icon.png'
import { useTranslation } from 'react-i18next';

const DirectoryTabs: React.FC<any> = ({searchItem, setSearchItem,residentsCount, staffCount }) => {
  const location = useLocation()
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='row mb-7 align-items-center'>
            <div className='col-md-6 col-6'>
              <h4 className='card-title font-18 text-black mb-0'> {t("Directory")} </h4>
            </div>
            <div className='col-md-6 col-6 text-end'>
              {/*<Link to={'/directory/add'} className='btn btn-primary py-2'>*/}
              {/*<i className="bi bi-plus-circle-fill me-3 font-16"></i>*/}
              {/*Add*/}
              {/*</Link>*/}
            </div>
          </div>
          <div className='card mb-3'>
            <div className='card-body px-4 py-0'>
              <div className='row access-cd-tabs'>
                <div className='col-md-6 col-12'>
                  <div className='d-flex py-2'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/directory/residents' && 'active')
                          }
                          to='/directory/residents'
                        >
                          {t("Residents")} ({residentsCount})
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/directory/staff' && 'active')
                          }
                          to='/directory/staff'
                        >
                          {t("Staff")} ({staffCount})
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                  <div className='form-group has-search'>
                    <span className='fa fa-search pt-2 form-control-feedback'></span>
                    <input
                      type='text'
                      className='form-control ps-10'
                      onChange={(e) => setSearchItem(e.target.value)}
                      placeholder={t('Search')}
                    />
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DirectoryTabs}
