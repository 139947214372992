import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
export function Selectlead() {


    return (
        <div className="modal fade" aria-hidden='true' id="SelectLeadPopup">
            <div className="modal-dialog modal-lg modal-dialog-centered radius-15">
                <div className="modal-content radius-15 overflow-hidden border-0">
                    <div className="modal-header badge-dark">
                        <h5 className="modal-title font-16 font-weight-semibold text-white">Select Leads</h5>
                        <button type="button" className="close text-black rounded-circle" data-bs-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body p-4">
                        
                        <div className="col-md-12">
                            <div className="prop-detail-tab">

                                <div className="">
                                    <div id="default-count-list" className="default-count-list">
                                        <div className="row">
                                            <div className="col-md-12 mb-4 ox-auto table-res-contain remove-abs">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered mb-0 font-12 text-nowrap" id="unit-listing-table">
                                                        <thead className="text-uppercase bg-projects-list font-weight-normal">
                                                            <tr>
                                                                <th>  </th>
                                                                <th className="">Ref</th>
                                                                <th>	LEAD TYPE</th>
                                                                <th className="">FIRST NAME</th>
                                                                <th className="">LAST NAME</th>
                                                                <th className="">PHONE NO</th>
                                                                <th className="">EMAIL</th>
                                                                <th className="">DATE ADDED</th>
                                                               
                                                            </tr>
                                                        
                                                        </thead>
                                                        <tbody className="bg-white">
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                    </div>
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td>Buyer</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                    </div>
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td>Buyer</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                    </div>
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td>Buyer</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                    </div>
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td>Buyer</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                    </div>
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td>Buyer</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                                <td>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" className="custom-control-input modal-check-box" value="" />
                                                                    </div>
                                                                </td>
                                                                <td className="sorting_1">REF-C-1627538099</td>
                                                                <td>Buyer</td>
                                                                <td className="sorting_2">Sanjeev</td>
                                                                <td>B</td>
                                                                <td>7411502041</td>
                                                                <td>sanjeev.b@exalogic.co</td>
                                                                <td>29/07/2021</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="text-end">
                                <button data-bs-dismiss="modal" type="button" className="btn btn-primary px-4 font-12 font-weight-semibold radius-10" id="button-apply-property">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}