import React, { FC, useState } from 'react'


export const CardPayment: React.FC = () => {

    return (
		<div className=' '>
			<div className='row'>
				<div className='col-12'>
					<div className='row form-add-design'>
						<div className="col-12">
							<div className="mb-5 position-relative p-5 bg-white rounded">
								<input className="form-check-input me-5" type="radio" name="period" id="inlineRadio1" defaultChecked/>
								<label className="form-check-label text-black fw-bolder font-14" htmlFor="inlineRadio1">Credit Card</label>
							</div>
						</div>
						<div className="col-12">
							<div className="mb-5 position-relative p-5 bg-white rounded">
								<input className="form-check-input me-5" type="radio" name="period" id="inlineRadio2"/>
								<label className="form-check-label text-black fw-bolder font-14" htmlFor="inlineRadio2">Debit Card</label>
							</div>
						</div>
                        <div className="col-12">
							<div className="mb-5 position-relative p-5 bg-white rounded">
							<div className="row">
								<div className="col-12 mb-5">
									<label className="font-14 fw-bold mb-3 text-black d-block">Enter Credit Card Number
										<span className="text-danger"> * </span>
									</label>
									<input type="text" className="form-control fw-bolder bg-secon" placeholder="" />
								</div>
								<div className="col-6 mb-5">
									<label className="font-14 fw-bold mb-3 text-black d-block">Expiry Date</label>
									<input type="text" className="form-control fw-bolder bg-secon" placeholder="" />
								</div>
								<div className="col-6 mb-5">
									<label className="font-14 fw-bold mb-3 text-black d-block">CVV  <span className="float-end text-secondary">Help?</span></label>
									<input type="text" className="form-control fw-bolder bg-secon" placeholder="" />
								</div>
							</div>
							</div>
                        </div>
						<div className='col-12'>
							<div className='mb-5 form-check'>
								<input
									className='form-check-input fc-wo-checkbox'
									type='checkbox'
									id='flexCheckDefault'
								/>
								<label className='form-check-label' htmlFor='flexCheckDefault'>
									Accept Terms and Conditions
								</label>
							</div>
						</div>
						<div className='col-12'>
							<button
								className='btn btn-primary fw-bold px-10 py-3 w-100'>
								Pay Now
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}
