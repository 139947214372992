import React, { useState, useEffect, useRef } from 'react'
import LeftArrow from '../../../../../../assets/images/Left_Arrow.png'
import uploadImg from "../../../../../../assets/images/file-upload.svg";
import { Link, useHistory, useLocation } from 'react-router-dom'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import useRequestPost from '../../../../../../hooks/useRequestPost'
import Loader from '../../../../../../components/Loader'
import {
  createIncident,
  getFacilityBuildingList,
  getCommonArea,
  getIncidentLists,
} from '../../../../../../constants/axios/apis'
import { RequestFailedPopup } from '../../../../../SuccessfulPopup/RequestFailedPopup'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../constants'
import { CustomRequestSuccessfulPopup } from '../../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { Select } from 'antd'

import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler'

export function NewIncident() {
  const { t } = useTranslation()
  const listBuilding: any = useSelector<RootState>(({ FCDashboard }) => FCDashboard.listBuilding)
  const currentBuilding: any = useSelector<RootState>(
    ({ FCDashboard }) => FCDashboard.selectedBuilding
  )

  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const successRef = useRef<any>()
  const failRef = useRef<any>()
  // const [message, setMessage] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [buildingList, setBuildingList] = useState<any>([])
  // const [levelList, setLevelList] = useState<any>([])
  const [areaList, setAreaList] = useState<any>([])
  // const [categoryList, setCategoryList] = useState<any>([])
  const [selectedBuilding, setSelectedBuilding] = useState<any>()
  const [formError, setFormError] = useState<any>()
  const [formloading, setFormloading] = useState<any>(false)
  const [mainCategoryList, setMainCategoryList] = useState<any>([])
  //     const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  //     const state: any = useSelector(state => state);
  //   const userId = state?.auth?.user?.data?.id;

  //     const quickLinkCategory = location?.state?.quickLinkCategory || undefined
  let params: any = {}
  params = useLocation()
  const { quickLinkCategory } = params.state
  const [selectedCategoryType, setSelectedCategoryType] = useState<any>(quickLinkCategory?.id)
  const [submitError, setSubmitError] = useState('')
  const buttonEl: any = useRef(null)

  const { Option } = Select

  const initialValues = {
    isFireAlarm: quickLinkCategory?.id == '1' ? true : false,
    category_id: quickLinkCategory ? quickLinkCategory.id : '',
    // category_id: '',
    // area: '',
    level: '',
    buildingLocation: currentBuilding ? currentBuilding : '',
    action_taken: '',
    device: '',
    root_cause: '',
    // buildingLocation: "",
    comments: '',
    photo: '',
  }

  // const onSubmit = (values:any) => {
  //     const formData = new FormData();
  //     formData.append("area", values.area);
  //     formData.append("level", values.level);
  //     formData.append("buildingLocation", values.buildingLocation);
  //     formData.append("action_taken", values.action_taken);
  //     formData.append("root_cause", values.root_cause);
  //     formData.append("comments", values.comments);

  //     /* const pairs = Array.from(formData.entries());
  //     for(const pair of pairs) {
  //         console.log(pair[0]+ ', '+ pair[1]);
  //     } */
  //     setFormloading(true);
  //     setFormError('');
  //     setTimeout(()=>{
  //         setFormError("Server error");
  //         setFormloading(false);
  //     },2000)

  // }
  const {
    requestState: checklistQuestionsPost,
    setRequestParams,
  } = useRequestPost('/facility/incident/create', false, () => { })

  const savecreateIncidentRequest = Yup.object().shape({
    isFireAlarm: Yup.boolean(),
    // category_type_id: Yup.string().required('Select a Area'),
    category_id: Yup.string().required(t('Select Sub Area!')),
    // area: Yup.string().required('Select a area'),
    level: Yup.string().required(t('Select Level!')),
    buildingLocation: Yup.string().required(t('Select Building Name!')),
    action_taken: Yup.string()
      .when('isFireAlarm', {
        is: true,
        then: Yup.string().required(t('Please Enter Action Taken!'))
      })
      .matches(/^[^\s].*/, t('Action Taken Cannot Start With A Blankspace!')),
    root_cause: Yup.string()
      .when('isFireAlarm', {
        is: true,
        then: Yup.string().required(t('Please Enter Root Cause!')),
      })
      .max(50, t('Max Length is 50 Characters!'))
      .matches(/^[^\s].*/, t('Root Cause Cannot Start With A Blankspace!')),
    device: Yup.string()
      .when('isFireAlarm', {
        is: true,
        then: Yup.string().required(t('Please Enter Device!'))
      })
      .matches(/^[^\s].*/, t('Device Cannot Start With A Blankspace!')),
    comments: Yup.string()
      .required(t('Please Enter Description!'))
      .max(250, t('Max Length Is 250 Characters!'))
      .matches(/^[^\s].*/, t('Description Cannot Start With A Blankspace!')),
    // photo: Yup.mixed()
    //   .test('type', t('Only the following formats are acceptable: .jpeg, .jpg, .png'), (value) => {
    //     if (value) {
    //       return (
    //         value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png'
    //       )
    //     }
    //     return true
    //   })
    //   .test('fileSize', t('The File Is Too Large!'), (value) => {
    //     if (value) {
    //       return value?.size <= 2000000
    //     }
    //     return true
    //   }),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: savecreateIncidentRequest,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setSubmitError('')
      setTimeout(() => {
        createIncident(
          // values.area,
          values.level,
          values.buildingLocation,
          values.comments,
          values.photo,
          values.category_id,
          values.action_taken,
          values.device,
          values.root_cause
        )
          .then((response: any) => {
            setLoading(false)
            if (response.errorCode === 1) setSubmitError(response.errorDescription)
            else if (response.errorCode === 0) {
              formik.resetForm()
              setLoading(false)
              var ev = new Event('click', { bubbles: true })
              buttonEl.current.dispatchEvent(ev)
            } else setSubmitError(t('Some error occured. Please try again!'))
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t('Invalid Data Provided'))
          })
      }, 1000)
    },
  })
  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('photo', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('photo')
          formik.setFieldValue('photo', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('photo', true, false)
          formik.setFieldValue('photo', '', false)
          formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', '', false)
      formik.setFieldError('photo', t('File upload error!'))
      setUploading(false)
    }
    // inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
    // formik.setFieldTouched('photo')
    // formik.setFieldValue('photo', e.currentTarget.files[0])
  }

  // const buildingOptions = buildingList?.map((building: any) => (
  //     <option key={building.id} value={building.id}>
  //         {building.building}
  //     </option>
  // ))

  const buildingOptions = listBuilding?.map((building: any) => (
    <option key={building.id} value={building.id}>
      {building.name}
    </option>
  ))

  // const onSelectedBuilding = (e: any) => {
  //     console.log('buildingOptions--', e, buildingOptions);

  // }

  useEffect(() => {
    getIncidentLists().then(({ data }) => {
      setMainCategoryList(data)
    })

    getFacilityBuildingList().then(({ data }) => {
      setBuildingList(data)
    })

    getCommonArea().then(({ data }) => {
      setAreaList(data)
    })
  }, [])
  useEffect(() => {
    setSelectedBuilding(buildingList.find((item: any) => item.id == formik.values.buildingLocation))
  }, [buildingList, formik.values.buildingLocation])

  // const handleSelect = (e: any) => {
  //     formik.handleChange(e)
  //     setSelectedBuilding(buildingList.find((item: any) => item.id == e.target.value))
  //     // formik.setFieldValue('category_id', buildingList?.id[0]?.id)
  // }

  const handleSelect = (val: any) => {
    formik.setFieldValue('buildingLocation', val)
    formik.setFieldValue('level', '')
    formik.setFieldTouched('level', false)
  }

  useEffect(() => {
    formik.setFieldValue('isFireAlarm', formik.values.category_id == '1' ? true : false)
  }, [formik.values.category_id])

  // const handleSelectMainCategory = (e: any) => {
  //   formik.handleChange(e)
  //   setSelectedCategoryType(e.target.value)
  //   formik.setFieldValue('category_type_id', e.target.value)
  //   formik.setFieldValue('category_id', '')
  //   formik.setFieldTouched('category_id', false)
  // }

  // const levelOptions = selectedBuilding?.floor?.map((floor: any) => (
  //   <option key={floor.id} value={floor.id}>
  //     {floor.floor_number}
  //   </option>
  // ))

  // const areaOptions = areaList?.map((area: any) => (
  //   <option key={area.id} value={area.id}>
  //     {area.name}
  //   </option>
  // ))

  // const mainCategoryOptions = mainCategoryList?.map((maincategory: any) => (
  //   <option key={maincategory.id} value={maincategory.id}>
  //     {maincategory.name}
  //   </option>
  // ))

  // const categoryOptions = mainCategoryList
  //   ?.find((el: any) => el.id == selectedCategoryType)
  //   ?.incidents_categories?.map((subCategory: any) => (
  //     <option key={subCategory.id} value={subCategory.id}>
  //       {subCategory.name}
  //     </option>
  //   ))

  return (
    <div>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/incidents/open-requests'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                <span className='text-black'>{t('New Incident')}</span>
              </Link>
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                  <div className='row form-add-design'>
                    <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t('Area')}
                          <span className='text-danger'> * </span>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select Area')}
                          optionFilterProp='children'
                          defaultValue={
                            formik.values.category_id !== '' ? formik.values.category_id : null
                          }
                          onChange={(val) => formik.setFieldValue('category_id', val)}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {/* <option value='' selected>
                            Please Select
                          </option> */}
                          {/* {mainCategoryList ? mainCategoryOptions : ''} */}
                          {mainCategoryList?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.category_id && formik.errors.category_id ? (
                          <small className='text-danger'>{formik.errors.category_id}</small>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          Sub Category
                          <span className='text-danger'> * </span>
                        </label>
                        <select
                          className='form-control form-select fw-bolder form-control-solid'
                          data-control='select2'
                          data-allow-clear='true'
                          // onChange={handleSelect}
                          onChange={formik.handleChange}
                          name='category_id'
                          value={formik.values.category_id}
                          onBlur={formik.handleBlur}
                          // {...formik.getFieldProps('category_id')}
                        >
                          <option selected>Please Select</option>
                          {categoryOptions ? categoryOptions : ''}
                        </select>
                        {formik.touched.category_id && formik.errors.category_id ? (
                          <small className='text-danger'>{formik.errors.category_id}</small>
                        ) : null}
                      </div>
                    </div> */}
                    {/* <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          Area
                          <span className='text-danger'> * </span>
                        </label>
                        <select
                          className='form-control form-select fw-bolder form-control-solid'
                          data-control='select2'
                          data-allow-clear='true'
                          {...formik.getFieldProps('area')}
                        >
                          <option selected>Please Select</option>
                          {areaList ? areaOptions : ''}
                        </select>
                        {formik.touched.area && formik.errors.area ? (
                          <small className='text-danger'>{formik.errors.area}</small>
                        ) : null}
                      </div>
                    </div> */}

                    <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t('Building Name')}
                          <span className='text-danger'> * </span>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select a building')}
                          optionFilterProp='children'
                          defaultValue={
                            formik.values.buildingLocation !== ''
                              ? formik.values.buildingLocation
                              : null
                          }
                          onChange={handleSelect}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {/* <option value=''>Please Select</option>
                          {listBuilding ? buildingOptions : ''} */}
                          {listBuilding?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.name}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.buildingLocation && formik.errors.buildingLocation ? (
                          <small className='text-danger'>{formik.errors.buildingLocation}</small>
                        ) : null}
                      </div>
                    </div>

                    <div className='col-md-4 col-12'>
                      <div className='mb-7'>
                        <label className='form-label fw-bolder'>
                          {t('Level')}
                          <span className='text-danger'> * </span>
                        </label>
                        <Select
                          className='form-select form-select-lg form-select-solid p-1 fcss-header-select bg-white border-secondary'
                          showSearch
                          placeholder={t('Select Level')}
                          optionFilterProp='children'
                          value={formik.values.level !== '' ? formik.values.level : null}
                          onChange={(val) => {
                            formik.setFieldValue('level', val)
                          }}
                          onBlur={formik.handleBlur}
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        // name='level'
                        // onChange={formik.handleChange}
                        // value={formik.values.level}
                        // onBlur={formik.handleBlur}
                        >
                          {/* <option selected>Please Select</option>
                          {selectedBuilding ? levelOptions : ''} */}
                          {/* </select> */}
                          {selectedBuilding?.floor?.map((el: any, index: number) => (
                            <Option key={index} value={el.id}>
                              {el.floor_number}
                            </Option>
                          ))}
                        </Select>
                        {formik.touched.level && formik.errors.level ? (
                          <small className='text-danger'>{formik.errors.level}</small>
                        ) : null}
                      </div>
                    </div>
                    {formik.values.isFireAlarm &&
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-5 mb-md-7'>
                          <label className='form-label fw-bolder'>
                            {t('Action Taken')} <small className='text-danger'>* </small>
                          </label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            {...formik.getFieldProps('action_taken')}
                            placeholder={t('Action Taken')}
                          />
                          {formik.touched.action_taken && formik.errors.action_taken && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.action_taken}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    {formik.values.isFireAlarm &&
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-5 mb-md-7'>
                          <label className='form-label fw-bolder'>
                            {t('Device')} <small className='text-danger'>* </small>
                          </label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            {...formik.getFieldProps('device')}
                            placeholder={t('Device')}
                          />
                          {formik.touched.device && formik.errors.device && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.device}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    }

                    {formik.values.isFireAlarm &&
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-5 mb-md-7'>
                          <label className='form-label fw-bolder'>
                            {t('Root Cause')}
                            <small className='text-danger'>* </small>

                          </label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            {...formik.getFieldProps('root_cause')}
                            placeholder={t('Root Cause')}
                          />
                          {formik.touched.root_cause && formik.errors.root_cause && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.root_cause}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    }

                    <div className='col-md-8 col-12'>
                      <div className='mb-0'>
                        <label className='form-label fw-bolder'>
                          {t('Description')}
                          <span className='text-danger'> * </span>
                        </label>
                        <textarea
                          className='form-control fw-bolder form-control-solid'
                          data-kt-autosize='true'
                          rows={3}
                          placeholder={t('Type here...')}
                          {...formik.getFieldProps('comments')}
                        />
                        {formik.touched.comments && formik.errors.comments ? (
                          <small className='text-danger'>{formik.errors.comments}</small>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 upload-input'>
                        <label className='form-label fw-bolder d-block mb-3'>
                          {t('Upload Image')}
                        </label>
                        <input
                          ref={inputRef}
                          onChange={(e) => handleDisplayFileDetails(e)}
                          className='d-none'
                          type='file'
                          accept=".png,.jpg,.jpeg"
                          // onBlur={formik.handleBlur}
                        />
                        <button
                          type='button'
                          onClick={handleUpload}
                          disabled={uploading}
                          className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                            }`}
                        >
                          <span>
                            {uploading ?
                              <span className='indicator-progress d-block'>
                                {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                              : uploadedFileName ? (
                                uploadedFileName
                              ) : (
                            <span className="txt">
                              <img
                                      alt="File"
                                      src={uploadImg}
                              className="position-relative"
                              loading="lazy"/>
                                  {t('Upload')}{' '}
                                  <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                </span>
                              )}{' '}
                          </span>
                        </button>
                        {formik.touched.photo && formik.errors.photo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.photo}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <button type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                      {!loading && <span className='indicator-label'>Save</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button> */}
          <div className='col-12 text-end mt-5'>
            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                onClick={() => {
                  formik.resetForm()
                  history.push('/incidents/open-requests')
                }}
              >
                {t('Cancel')}
              </button>
              <button
                type='submit'
                onClick={formik.submitForm}
                className='btn btn-primary fw-bold px-10 py-3'
                disabled={loading || uploading}
              >
                {!loading && <span className='indicator-label'>{t('Submit')}</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {t('Please wait...')}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {submitError && (
              <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                {submitError}
              </div>
            )}
            <button
              ref={buttonEl}
              data-bs-target='#req-submitted-popup'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
              type='submit'
              className='invisible d-none btn btn-primary fw-bold px-10 py-3'
            ></button>
            <CustomRequestSuccessfulPopup
              successMessage={t('Incident Added Successfully')}
              href={'/incidents/open-requests'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
