import React, { useState, useEffect, useRef } from 'react';

export function AddChargesPopup() {
    return (
        <div className="modal fade add-broker-modal" id="add-charges">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header px-8 py-8">
                        <h5 className="modal-title text-black font-18 fw-bolder">Add Charges</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-8">
                        <form>
                            <div className="row form-add-design">
                                <div className="col-12">
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> Charge Type </label>
                                        <select className="form-control form-select fw-bolder bg-white form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> Cleaning </option>
                                            <option> Common Areas </option>
                                            <option> Elevators </option>
                                        </select>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> Amount </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> VAT </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> Total </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="f-name" className="form-label fw-bolder mb-3"> Comments </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer px-8 pb-8">
                        <button type="button" className="btn btn-primary fw-bold px-14 py-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#added-success-modal">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}