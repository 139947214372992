import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const MoveOutRequestTabs = ({activeButton, setActiveButton}:any) => {
    const location = useLocation();
    const {t} = useTranslation()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                            <li className='nav-item'>
                                                <a onClick={() => { setActiveButton('lease'); }}
                                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'lease' && 'active')}>
                                                    {t("Lease Details")}
                                                </a>
                                            </li>
                                            <li className='nav-item'>
                                                <a onClick={() => { setActiveButton('payment'); }}
                                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'payment' && 'active')}>
                                                    {t("Payments")}
                                                </a>
                                            </li>
                                            <li className='nav-item'>
                                                <a onClick={() => { setActiveButton('doc'); }}
                                                    className={`cursor-pointer nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                    (activeButton === 'doc' && 'active')}>
                                                    {t("Documents")}
                                                </a>
                                            </li>
                                            {/* <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/finance-services/moveout-request-details/lease-details/'+id && 'active')}
                                                    to={`/finance-services/moveout-request-details/lease-details/${id}`}
                                                    >
                                                    {t("Lease Details")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/finance-services/moveout-request-details/payments/'+id && 'active')}
                                                    to={`/finance-services/moveout-request-details/payments/${id}`}>
                                                    {t("Payments")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                                    (location.pathname === '/finance-services/moveout-request-details/documents/'+id && 'active')}
                                                    to={`/finance-services/moveout-request-details/documents/${id}`}>
                                                    {t("Documents")}
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {MoveOutRequestTabs}
