import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { DataGrid, GridColDef, GridValueGetterParams, GridValueOptionsParams } from '@mui/x-data-grid';
import TableData from './TableData'
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { formatDate, formatTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater';
import { useTranslation } from 'react-i18next';

export const ConfirmedTableData:React.FC<any> = ({fcsWorkOrderList, workOrder, loading}) => {
    const { t } = useTranslation();

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    
    const columns = [
        {
            field: 'ticket',
            headerName: (t('Req/Ticket No.')),
            sortable: false,
            disableColumnMenu: true,
            width: 150
        },
        {
            field: 'workorderno',
            headerName: (t('Work Order No.')),
            width:150
        },
        {
            field: 'issueType',
            headerName: (t('Issue Type')),
            width:200
        },
        {
            field: 'priority',
            headerName: (t('Priority')),
            width:150
        },
        {
            field: 'unit',
            headerName: (t('Unit')),
            width:180
        },
        {
            field: 'appointmentDate',
            headerName: (t('Appointment Date')),
            width:180
        },
        {
            field: 'time',
            headerName: (t('Time')),
            width: 150
        },
        {
            field: 'workOrderState',
            headerName: (t('Action')),
            sortable: false,
            disableColumnMenu: true,
            width: 180,
            renderCell: (val:any) => {
                // if(location.pathname === '/fc-workorder/clearance/confirmed' || location.pathname === '/workorders/clearance/confirmed'){
                // if(user_role === 'FC' || user_role === 'FCS'){
                //     /* return <Link to={{'pathname':'/fc-workorder/inspection', state: {'workOrder': val.value}}}> */
                //     // return <Link to={{'pathname':'/fc-workorder/moveoutinspection/step1', state: {'workOrder': val.value}}}>
                //     return <Link to={{'pathname':'/fc-workorder/moveoutinspection/confirmed', state: {'workOrder': val.value}}}>
                //         <span className="view-option text-theme fw-bolder">
                //             {t("Add Pricing")}
                //         </span>
                //     </Link>
                // }else if(user_role === 'FMT'){
                //     return <Link to={{'pathname':'/workorder/details', state: {'workOrder': val.value}}}>
                //         <span className="view-option text-theme fw-bolder"> {t("View")} </span>
                //     </Link>
                // }else{
                //     return <Link to={{'pathname':'/fc-workorder/work-order-details', state: {'workOrder': val.value}}}>
                //         <span className="view-option text-theme fw-bolder">
                //             {t("View")}
                //         </span>
                //     </Link>
                // }
                return <Link to={{'pathname':'/facility-workorder/view', state: {'workOrder': val.value}}}>
                        <span className="view-option text-theme fw-bolder">
                            { user_role === 'FC' || user_role === 'FCS' ? (t("Add Pricing")) : (t("View")) }
                        </span>
                    </Link>
            },
        },
    ];

    const [workOrderRow,setWorkOrderRow] = useState<any>([]);

    useEffect(()=>{
        if(Array.isArray(workOrder)){
            if(workOrder.length > 0){
                const openWorkOrderVal =  workOrder.filter((wOrder: any, index: number)=>{
                    return wOrder.status === "Confirmed";
                });

                /* Open
                On hold
                Completed
                SLA */
                const workOrderRow = openWorkOrderVal.map((workOrder: any, index: number) => {
                    return {
                        no: index+1,
                        ticket: workOrder.objectDetails?.ticketNo,
                        workorderno: workOrder.WorkorderNo,
                        // issueType: isFCUser ? workOrder.typeText : workOrder.subCategory,
                        issueType: workOrder.typeText === "Move Out" ? 'Move Out Inspection' : workOrder.typeText,
                        subIssue: workOrder.subCategory,
                        priority: workOrder.Priority,
                        unit: workOrder.property_code,
                        appointmentDate: formatDate(workOrder.date),
                        // appointmentDate: moment(workOrder.date).format("DD-MM-YYYY"),
                        time: workOrder.issueCategory === "Clearance" ? workOrder?.objectDetails?.referenceDetails?.time_slot : formatTimeMeridiem(workOrder.time),
                        workOrderState :workOrder
                    }
                });
                setWorkOrderRow(workOrderRow);
            }
            else{
                setWorkOrderRow([])
            }
        }
    },[fcsWorkOrderList, workOrder]);


    return (
        <div className="row">
            <div className="col-12 pt-5">
                <div className="rec-payment-act-sec">
                    <div className="row">
                        <div className="col-12">
                            <div className="data_table">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='table-responsive'>
                                            <div style={{ height: 400 }}>
                                                <DataGrid
                                                    rows={workOrderRow}
                                                    getRowId={(row) => row.no}
                                                    columns={columns}
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    loading={fcsWorkOrderList.loading || loading}
                                                    disableColumnMenu
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}