import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'

export function OutOfOfficePopUp() {
    const { t } = useTranslation();

    return (
        <div className="modal fade add-broker-modal" aria-hidden='true' id="out-of-offer-popUp">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-8">
                    <div className="modal-header p-0">
                        <h5 className="modal-title">{t("Out of Office")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-0 pb-0 pt-5">
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Details")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">{t("Vacation")}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("From Date")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">29-Apr-2021</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("To Date")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">29-Apr-2021</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-10">
                                        <label className="text-black fw-bolder">{t("Emergency Contact")}</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Contact person Name")} </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">29-Apr-2021</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Mobile Number")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">29-Apr-2021</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Email ID")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">asss@gmail.com</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-10">
                                        <label className="form-label fw-bolder">{t("Note")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                Lorem Ipsum is simply dummy text of the
                                                printing and typesetting industry. Lorem Ipsum
                                                has been the industry's standard dummy text
                                                ever since the 1500s,
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}