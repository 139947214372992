import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export function NOCTabs() {

    const location = useLocation();
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className='d-flex subtabs'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === '/fmteam-approvals/noc/upcoming' && 'actives')}
                                to='/fmteam-approvals/noc/upcoming'>
                                {t("Open")}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                (location.pathname === '/fmteam-approvals/noc/closed' && 'actives')}
                                to='/fmteam-approvals/noc/closed'>
                                {t("Closed")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}