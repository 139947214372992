import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getIncidentList } from '../../../constants/axios/apis';
import { formatTimeMeridiem } from '../../../constants/generics/dateTimeFormater';

export function LinkedIncidentDetails({activityDetails}:any) {
    const { t } = useTranslation();
    const [incidentDetails, setIncidentDetails] = useState<any>('');


    useEffect(()=>{
        const payload = '?id=' + activityDetails?.details_json?.incident_id
        getIncidentList(payload).then((response:any) => {
            if(response.errorCode === 0 && response?.data?.[0]){
                // console.log();
                
                setIncidentDetails(response.data[0])
            }
            // setIsLoading((oState: any) => ({...oState, details: false}))
        // }).catch((e) => {setIsLoading((oState: any) => ({...oState, details: false}))})
        })
    },[]);

    return (
        <div className='row'>
            <div className="col-12 border-top pt-10 mt-10"></div>
            <div className='col-md-12 col-12'>
                <span className='text-black fw-bolder font-15 d-block mb-4'>
                {t("Incident Details")}
                </span>
            </div>
            <div className="col-12">
                <div className="row form-add-design">
                    {/* <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t("Status")} </label>
                            <span className="font-14 text-black fw-bold d-block"> {activityDetails?.status === "closed" ? t('Closed') : t('Open')} </span>
                        </div>
                    </div> */}
                    <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t('Tickect No.')} </label>
                            <span className="font-14 text-black fw-bold d-block"> {incidentDetails?.ticket_no ? incidentDetails?.ticket_no : '-'} </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t('Building')} </label>
                            <span className="font-14 text-black fw-bold d-block"> {incidentDetails?.buildingLocation ? incidentDetails?.buildingLocation : '-'} </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t('Level')} </label>
                            <span className="font-14 text-black fw-bold d-block"> {incidentDetails?.level ? incidentDetails?.level : '-'} </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t('Action Taken')} </label>
                            <span className="font-14 text-black fw-bold d-block"> {incidentDetails?.actionTaken ? incidentDetails?.actionTaken : '-'} </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t('Device')} </label>
                            <span className="font-14 text-black fw-bold d-block"> {incidentDetails?.device ? incidentDetails?.device : '-'} </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t("Incident Time")} </label>
                            <span className="font-14 text-black fw-bold d-block">
                                {formatTimeMeridiem( incidentDetails?.time ? incidentDetails?.time : '--' )}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-5">
                        <div className="">
                            <label className="form-label fw-bolder"> {t('Root Cause')}{' '} </label>
                            <span className="font-14 text-black fw-bold d-block"> {incidentDetails?.rootCause ? incidentDetails?.rootCause : '-'} </span>
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <label className="form-label fw-bolder"> {t('Description')} </label>
                        <div className="form-control bg-light border-0">
                            <span className="font-14 text-black fw-bold d-block"> {incidentDetails?.comments ? incidentDetails?.comments : '-'} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}