// Form screen of web this page of inactive list
import React, {useState, useEffect} from 'react'
import Loader from '../../../../../components/Loader'
import {getApprovals, getFacilityAccessCards} from '../../../../../constants/axios/apis'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import {ActionMenu} from '../ActionMenu/ActionMenu'
import ActionDotsIoc from '../../../../../assets/images/Action_dots.png'
import {Link} from 'react-router-dom'
import {CompactPagination} from '../../../../../components/partials/content/CompactPagination/CompactPagination'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../constants'
import {CustomLoader2, NoDataFound1} from '../../../../../constants/generics/customActios'
import { useTranslation } from 'react-i18next';

export function ActiveList({searchItem, setSearchItem}: any) {
  const currentBuilding: any = useSelector<RootState>(
    ({FCDashboard}) => FCDashboard.selectedBuilding
  )
  const { t } = useTranslation();
  const pageSize = 10
  const [accessList, setAccessList] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [page, setPage] = React.useState(1)
  const [totalaccessList, setTotalaccessList] = useState<any>(0)
  setTimeout(() => {
    setLoading(false)
  }, 1500)

  const handleClick = (newCurrentPage: any) => {
    setCurrentPage(newCurrentPage)
  }

  useEffect(()=>{ setCurrentPage(1) },[searchItem])
  useEffect(() => { setCurrentPage(1) }, [currentBuilding])
  
  useEffect(() => {
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&id_sort=1' +
      '&search=' +
       searchItem +
      '&status=approve' +
      '&sub_type=6'
    getApprovals(newPayload).then((response: any) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setAccessList(response.data)
        setTotalaccessList(response.dataCount)
      } else {
        setAccessList([])
        setTotalaccessList(0)
      }
    })
  }, [currentPage, currentBuilding, searchItem])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleClick(value)
  }

  if (!loading && (accessList === undefined || accessList.length === 0)) {
    return <NoDataFound1 />
  }

  const accessData = accessList?.map((data: any, index: any) => {
    const dataNumber = (currentPage - 1) * pageSize + index + 1
    return (
      <tr key={dataNumber}>
        <td>{dataNumber}</td>
        <td>{data?.cutomerName ? data?.cutomerName : '--'}</td>
        <td>{data?.propertyCode ? data?.propertyCode : '--'}</td>
        {/* <td>{moment(data?.leaseStartDate).format('DD-MM-YYYY')}</td>
        <td>{moment(data?.leaseEndDate).format('DD-MM-YYYY')}</td> */}
        {/* <td>{moment(data?.details_json?.addc_clearance_date).format('DD-MM-YYYY')}</td> */}
        <td>{moment(data?.moveOutDate).format('DD-MM-YYYY')}</td>
        <td>
          <Link
            to={{pathname: '/fcss-viewDetails-inactive', state: {accessData: data}}}
            className='menu-link text-info fs-12 ps-2 pe-0 py-4'
          >
            <span className='view-option text-theme fw-bolder'>{t("View")}</span>
          </Link>
        </td>
      </tr>
    )
  })

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3 '>
            <div className='card-body p-0'>
              <div className='rec-payment-act-sec'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                        <thead>
                          <tr>
                            <th className=''> {t("No.")} </th>
                            <th className=''> {t("Tenant Name")}</th>
                            <th className=''> {t("Unit")}</th>
                            {/* <th className=''> Lease Start Date </th>
                            <th className=''> Lease End Date </th>
                            <th className=''> ADDC Clearance Date </th> */}
                            <th className=''> {t("Move-out Date")} </th>
                            <th className=''> {t("Action")} </th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tbody>
                            <tr>
                              <td colSpan={8}>
                                <CustomLoader2 />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>{accessList ? accessData : ''}</tbody>
                        )}
                      </table>
                      <div className='w-100 d-flex justify-content-end my-5'>
                        {/* <Stack spacing={2}>
                          <Pagination
                            count={
                              totalaccessList % pageSize === 0
                                ? totalaccessList / pageSize
                                : Math.ceil(totalaccessList / pageSize)
                            }
                            onChange={handlePageChange}
                          />
                        </Stack> */}
                        <CompactPagination
                          pageSize={pageSize}
                          totalCount={parseInt(totalaccessList)}
                          defaultPage={currentPage}
                          onPageChange={setCurrentPage}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
