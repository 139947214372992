import React, {FC, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Approved } from './Approved/Approved';
import { MTAApprovalsTabs } from './MTAApprovalsTabs/MTAApprovalsTabs';
import { Pending } from './Pending/Pending';
import { Rejected } from './Rejected/Rejected';

export function MTAApprovalListWrapper(){
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/mta-approvals/list/pending'>
                            <MTAApprovalsTabs />
                            <Pending />
                        </Route>
                        <Route path='/mta-approvals/list/approved'>
                            <MTAApprovalsTabs />
                            <Approved />
                        </Route>
                        <Route path='/mta-approvals/list/rejected'>
                            <MTAApprovalsTabs />
                            <Rejected />
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div>
            </div>
        </>
    );
}
