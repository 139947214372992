import React, { useState, FC, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { Link, useParams, useLocation } from 'react-router-dom'
import LeftArrow from "../../../assets/images/Left_Arrow.png";
import { ApprovalHistory } from './ApprovalHistory/ApprovalHistory';
import { Details } from './Details/Details';
import { Notes } from './Notes/Notes';
import { OfferDetailsTabs } from './OfferDetailsTabs/OfferDetailsTabs';
import { Payment } from './Payment/Payment';
import { RequestDetails } from './RequestDetails/RequestDetails';
import { ApproveButtons } from './ApproveButtons/ApproveButtons';
import { getPMapprovalList } from './../../../constants/axios/apis';
import { useDispatch } from 'react-redux'
import * as PM from './../../../constants/redux/Sagas/PropertyManager/propertyManagerSaga'
import { useTranslation } from 'react-i18next'

type LocationState = {
    id?: any
}

const OfferDetailsWrapper: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation<LocationState>();
    const { id } = location.state || {};// const [detailData, setDetailData] = useState<any>([])
    useEffect(() => {
        if (id) {
            const config = "status=pending&id=" + id
            getPMapprovalList(config)
                .then((response: any) => {
                    if (response?.errorCode === 0) {
                        // setDetailData(''); //TODO after api ready
                        dispatch(PM.actions.contractsApprovalData(response?.data[0]))
                    }
                })
                .catch((e) => {
                })
        } else {
            // console.log("no id found");
        }
    }, [id])

    const { t } = useTranslation();

    return (
        <>
            <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="card-title font-18 text-black mb-6">
                                <Link to={'/pm-approvals/open-requests'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                    <span className='text-black'>{t("Offer")}</span>
                                </Link>
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-7">
                            <Details />
                        </div>
                    </div>
                    <Switch>
                        <Route path='/offer-details/request-details'>
                            <OfferDetailsTabs />
                            <RequestDetails />
                        </Route>
                        <Route path='/offer-details/payment'>
                            <OfferDetailsTabs />
                            <Payment />
                        </Route>
                        <Route path='/offer-details/approval-history'>
                            <OfferDetailsTabs />
                            <ApprovalHistory />
                        </Route>
                        <Route path='/offer-details/notes'>
                            <OfferDetailsTabs />
                            <Notes />
                        </Route>
                    </Switch>
                    <div className="row">
                        <div className="col-12 mt-1">
                            <ApproveButtons />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { OfferDetailsWrapper }

