import React, {useState, useEffect} from 'react'
import { getFacilityParkingSlots } from '../../../../constants/axios/apis'
import { ContactPopup } from '../Contact/Contact'
import { ParkedSlotPopup } from '../ParkedSlotPopup/ParkedSlotPopup'
import { ParkingInfo } from '../ParkingInfo/ParkingInfo'
import { ParkingSlots } from '../ParkingSlots/ParkingSlots'

export function ParkingStatusWrapper({
  selectedBuilding, setSelectedBuilding,
  selectedBlock, setSelectedBlock
}:any) {

  const [parkingSlotList, setParkingSlotList] = useState<any>([])
  const [selectedParkingSlot, setSelectedParkingSlot] = useState<any>()
  const [loading, setLoading] = useState<any>(true)

  useEffect(() => {
    setLoading(true)
    if (selectedBuilding && selectedBuilding !== '') {
      getFacilityParkingSlots(selectedBuilding, '').then(({data}) => {
        setParkingSlotList(data)
        setLoading(false)
      }).catch((e:any)=>setLoading(false))
    } else {
      setParkingSlotList([])
      setLoading(false)
    }
  }, [ selectedBuilding])

  return (
    <>
      <ParkingInfo count={parkingSlotList.count?.[0]} />      
      <ParkingSlots
        setSelectedParkingSlot={setSelectedParkingSlot}
        parkingSlots={parkingSlotList}
        selectedBuilding={selectedBuilding}
        setSelectedBuilding={setSelectedBuilding}
        selectedBlock={selectedBlock}
        setSelectedBlock={setSelectedBlock}
        loading={loading}
      />
      <ParkedSlotPopup selectedSlot={selectedParkingSlot} />
      <ContactPopup selectedSlot={selectedParkingSlot} />
    </>
  )
}
